import React from "react";
import style from "./CustomerPreviewCardsColumn.module.scss";
import { propsForColumn } from "./types";
import CustomerPreviewCard from "./CustomerPreviewCard";
export default function CustomerPreviewCardsColumn(props: propsForColumn) {
  return (
    <div className={style.wrapper}>
      {props.data.map((each) => {
        return (
          <CustomerPreviewCard
            id={each.id}
            date={each.date}
            name={each.name}
            previewMessage={each.previewMessage}
            status={each.status}
            tooltip={each.tooltip}
            key={each.id}
            sessionId={each.sessionId}
            onClick={props.onClick}
            selected={props.selectedId === each.id}
          ></CustomerPreviewCard>
        );
      })}
    </div>
  );
}
