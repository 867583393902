import React, { useEffect, useRef, useState } from "react";
import styles from "./OrderSummary.module.scss";
import Button from "../../../generic/button/Button";
import { caratDown } from "../../../../theme/assets/genericSvg/index";
import useOnClickOutside from "../../../../utils/useOnClickOutside";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import PaymentSuccessfull from "../paymentSuccessfull/PaymentSuccessfull";
import { useNavigate } from "react-router-dom";
import DisplayRazorPay from "../razorPayIntegration/DisplayRazorPay";
import {
  generateOrderId,
  verifyOrderPayment,
  getCouponApplied,
  getValidCouponCode,
} from "../../../../redux/profile/billing/orderSummary/action/orderSummary.action";
import moment from "moment";
import { setPaidForPlan } from "../../../../redux/profile/billing/pricingPlan/actions";
import { getMyPlanAPIData } from "../../../../redux/profile/billing/myPlan/actions";
import { getLeftsideModuleProfileDetails } from "../../../../redux/profile/navigation/action/navigation.action";
import { toast } from "react-toastify";
import { createTelephonyData } from "../../../../redux/integration_V2.0/telephony/actions";
import {
  getTeamsData,
  getTeamTabs,
  getTeamChannels,

} from "../../../../redux/onboarding/login/actions";
import {
  setIsShowTopBanner,
} from "../../../../redux/baseScreen/baseScreenState/actions";


type Props = {
  data: any;
  addon: any;
};

const OrderSummary = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [couponCode, setCouponCode] = useState("");
  const [appliedCouponCode, setAppliedCouponCode] = useState("");
  const [isCouponApply, setIsCouponApply] = useState(false);
  const [isAddonClick, setIsAddonClick] = useState(false);
  const [planAmount, setPlanAmount] = useState<number>(0);
  const [subTotalAmount, setSubTotalAmount] = useState<number>(0);
  const [cgst, setCgst] = useState<number>(0);
  const [sgst, setSgst] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [totalAddons, setTotalAddons] = useState<number>(0);
  const [couponRatio, setCouponRatio] = useState<number>(0);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [firstTimeDiscount, setFirstTimeDiscount] = useState<number>(0);
  const [discountFirstTime, setDiscountFirstTime] = useState<number>();

  const selectedAddons = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.orderSummary?.selectedAddons
  );

  const durationString = useSelector(
    (store: RootState) => store?.profile?.billingReducer?.orderSummary?.duration
  );
  const loginDetails = useSelector((state: RootState) => {
    return state.loginReducer?.userLoginInfo;
  });

  const userId = useSelector(
    (state: RootState) => state.loginReducer.userLoginInfo.userDetail._id
  );

  const duration = parseInt(durationString?.split(" ")[0]);

  const taxData = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.pricingPlan?.pricingPlanDetails?.data
        ?.taxData
  );
  const teamsData = useSelector(
    (store: RootState) => store.loginReducer.teamData?.data
  );
  const selectedPlanAllDetails = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.pricingPlan?.selectedPlan
  );

  const allPlansDetails: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.pricingPlan?.pricingPlanDetails?.data
  );

  const myPlan: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  const accessToken = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const generateOrderDetailsData = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.orderSummary?.generateOrderDetails
  );
  const couponCodeValid = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.orderSummary?.validCouponCode
  );
  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
  );
  const accountDetails = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]
  );

  const discountCoupanApi: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.pricingPlan?.couponPlanDetails?.data
  );

  const currentTeamId = useSelector(
    (state: RootState) => state.loginReducer?.currentTeam?.id
  );
  const currentSelectedTeamId = useSelector(
    (store: RootState) => store.loginReducer?.currentTeam?.id
  );

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": accessToken,
  };

  const dicountCodeDetails = useSelector(
    (store: RootState) =>
      store.profile?.billingReducer?.orderSummary?.discountCoupon
  );

  const convertToRupees = (number: number, currency: string) => {
    const rupees = number?.toLocaleString("en-IN", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: number % 1 === 0 ? 0 : 2,
      maximumFractionDigits: number % 1 === 0 ? 0 : 2,
    });
    // const formattedRupees = rupees?.replace(/\.\d+/, ""); // Remove decimal part if present
    return rupees?.replace("₹", "");
  };

  const isWithinNext7Days = (planStartDate: string): boolean => {
    // to ignore time
    // const startDate = moment(planStartDate).startOf("day");
    // const currentDate = moment().startOf("day");
    const startDate = moment(planStartDate);
    const currentDate = moment();
    const next7Days = startDate.clone().add(6, "days");
    // console.log(next7Days, "hhhhhh");
    return (
      currentDate.isSameOrAfter(startDate) &&
      currentDate.isSameOrBefore(next7Days)
    );
  };

  // const planStartDate = myPlan?.planStartDate;
  // const result = isWithinNext7Days(planStartDate);
  // console.log(result, planStartDate, "kkkkkkkkkk");

  const handleChangeCoupon = (e: any) => {
    setCouponCode(e.target.value);
  };

  const handleApplyCodeClick = () => {
    if (couponCode !== "") {
      setIsCouponApply(true);
      // setCouponRatio(10);
      setAppliedCouponCode(couponCode);

      const body = {
        couponCode: couponCode,
        token: accessToken,
      };
      dispatch(getCouponApplied(body));
    }
  };
  /** for setting Telephony First Time */
  const handleAddTelephony = () => {
    const uniqueDid = Number(
      selectedPlanAllDetails?.maiaConfiguration?.uniqueDIDs
    );
    const didRange = `8048678500-${8048678500 + uniqueDid - 1}`;
    const cluster = {
      clusters: [
        {
          cluster_name: "teleindia",
          capacity:
            selectedPlanAllDetails?.maiaConfiguration?.maximumConcurrentCall,
          weightage: 1,
          gateways: [
            {
              name: "ti_airtel2",
              did_range: didRange,
            },
          ],
        },
      ],
    };
    const data = {
      body: {
        name: accountDetails?.name,
        accountId: accountDetails?.id,
        telephony: "Epicode",
        transactional: cluster,
      },
      headers: headers,
      // mode: mode,
    };

    dispatch(createTelephonyData(data));
  };
  const handleClickPayment = () => {
    setShowSuccessPopUp(true);
    handleAddTelephony();
    dispatch(setIsShowTopBanner(false))
    // getTeamsData({
    //   accountId: accountId
    // })
  };


  const handleOnClick = () => {
    navigate("/profile/billing/invoices");
    getLeftsideModuleProfileDetails({ id: userId, teamId: currentTeamId });
    dispatch(setPaidForPlan(selectedPlanAllDetails));
    dispatch(getMyPlanAPIData({ accountId: accountId }));
    dispatch(getTeamsData({
      accountId: accountId
    }))
    dispatch(getTeamTabs(currentSelectedTeamId));
    dispatch(getTeamChannels(currentSelectedTeamId));
    // location.reload()
  };

  const handleClosePayment = (data?: boolean) => {
    console.log("close");
  };

  //payment verify handler
  const handlePaymentVerifyApi = (body: object) => {
    const data = {
      body: body,
      headers: headers,
      func: handleClickPayment,
    };

    dispatch(verifyOrderPayment(data));
  };

  //function to generate orderId

  const handleGenerateOrderId = (totalAmount: number) => {
    if (totalAmount < 0) {
      toast.error("Envalid Amount ");
      return;
    }
    const durationPeriod = `${durationString?.split(" ")[0]} ${durationString
      ?.split(" ")[1]
      ?.toUpperCase()}`;
    if (
      myPlan?.planId === selectedPlanAllDetails?.id &&
      myPlan?.duration === durationPeriod
    ) {
      toast.error("This plan is already your current Plan ");
      return;
    }

    const valuAddOnsDetails = selectedAddons?.map((data: any) => {
      return {
        valueAddonID: data?.valueAddonID,
        quantity: data?.quantity,
      };
    });
    const body: Record<string, any> = {
      userId: loginDetails?.userDetail["_id"],
      accountId: loginDetails?.accountDetails?.[0]?.id,
      planId: selectedPlanAllDetails?.id,
      selectedDuration: durationPeriod,
      valueAddons: valuAddOnsDetails,
    };
    if (couponCodeValid?.length) {
      body["couponCode"] = couponCodeValid;
    }
    const data = {
      body: body,
      func: (data: any) => DisplayRazorPay(data, handlePaymentVerifyApi),
      headers: headers,
    };
    dispatch(generateOrderId(data));
  };

  // for getting
  useEffect(() => {
    const discountFirstTimeDetail = allPlansDetails?.couponsData?.find(
      (e: any) => e?.month === duration
    );
    setFirstTimeDiscount(
      discountCoupanApi?.percentage
        ? discountCoupanApi?.percentage
        : discountFirstTimeDetail?.discountRatio
    );

  }, [discountCoupanApi]);

  useEffect(() => {
    if (couponCode == "") {
      setIsCouponApply(false);
    }
  }, [couponCode]);

  function getTotalOrderSummary(
    itemPrice: number,
    duration: number,
    discountPercentage: number,
    totalAddon: number,
    currentPlanAmt: number,
    firstTimeDiscount?: number
  ) {
    const planAmount = itemPrice * duration;

    let subTotal = planAmount;

    if (totalAddon) {
      subTotal += totalAddon;
    }

    let discountAmount = 0;
    if (discountPercentage) {
      discountAmount = (subTotal * discountPercentage) / 100;
    }
    let discountFirstTime = 0;
    if (firstTimeDiscount) {
      discountFirstTime = (subTotal * firstTimeDiscount) / 100;
    }
    subTotal -= discountFirstTime;
    subTotal -= discountAmount;

    const cgstTaxValue: number = taxData?.taxValue?.CGST
      ? parseFloat(taxData.taxValue.CGST) / 100
      : 0.09;
    const sgstTaxValue: number = taxData?.taxValue?.SGST
      ? parseFloat(taxData.taxValue.SGST) / 100
      : 0.09;
    const cgst = subTotal * cgstTaxValue;
    const sgst = subTotal * sgstTaxValue;

    const total =
      myPlan &&
        isWithinNext7Days(myPlan?.planStartDate) &&
        Object.keys(myPlan).length > 0 &&
        myPlan?.price !== "Free"
        ? subTotal + cgst + sgst - currentPlanAmt
        : subTotal + cgst + sgst;

    // const total = subTotal + cgst + sgst;

    return {
      planAmount: parseFloat(planAmount.toFixed(2)),
      subTotal: parseFloat(subTotal.toFixed(2)),
      cgst: parseFloat(cgst.toFixed(2)),
      sgst: parseFloat(sgst.toFixed(2)),
      total: parseFloat(total.toFixed(2)),
      discountAmount: parseFloat(discountAmount.toFixed(2)),
      discountFirstTime: parseFloat(discountFirstTime?.toFixed(2)),
    };
  }

  function getTotalAddonsAmount(array: any, duration: number) {
    let total = 0;

    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      const itemTotal = item.price * item.quantity;
      total += itemTotal;
    }

    return total;
  }

  useEffect(() => {
    if (selectedAddons) {
      const totalValue = getTotalAddonsAmount(selectedAddons, duration);
      setTotalAddons(totalValue);
    }
  }, [duration, selectedAddons]);

  useEffect(() => {
    if (props.data) {
      const cartItem = getTotalOrderSummary(
        props.data?.price,
        duration,
        couponRatio,
        totalAddons,
        myPlan?.paidAmount,
        firstTimeDiscount
      );
      setPlanAmount(cartItem?.planAmount);
      setCgst(cartItem?.cgst);
      setSgst(cartItem?.sgst);
      setSubTotalAmount(cartItem?.subTotal);
      setTotalAmount(cartItem?.total);
      setDiscountAmount(cartItem?.discountAmount);
      setDiscountFirstTime(cartItem?.discountFirstTime);
    }
  }, [props.data, duration, totalAddons, couponRatio, firstTimeDiscount]);

  console.log("dada", firstTimeDiscount, discountCoupanApi);
  useEffect(() => {
    if (appliedCouponCode !== couponCode) {
      setIsCouponApply(false);
    }
  }, [appliedCouponCode, couponCode]);

  useEffect(() => {
    if (isCouponApply == false) {
      setCouponRatio(0);
    }

    if (
      dicountCodeDetails &&
      !dicountCodeDetails?.isLoading &&
      dicountCodeDetails?.data &&
      Object.keys(dicountCodeDetails?.data)?.length > 0 &&
      isCouponApply
    ) {
      setCouponRatio(dicountCodeDetails?.data?.discountRatio);
      dispatch(getValidCouponCode(dicountCodeDetails?.data?.code));
    } else {
      dispatch(getValidCouponCode(""));
      setCouponRatio(0);
    }
  }, [dicountCodeDetails, isCouponApply]);

  const addonref = useRef<any>();
  useOnClickOutside(addonref, () => setIsAddonClick(false));

  return (
    <div className={styles.orderSummaryWrapper}>
      <div className={styles.orderTitle}>Order Summary</div>
      <div className={styles.itemsNumber}>
        {selectedAddons?.length > 0 ? `${2} Items` : `${1} Item`}
      </div>
      <hr className={styles.horizontalLine} />
      <div className={styles.discountCodeClass}>
        <p className={styles.discount}>Discount Code</p>
        <div className={styles.couponClass}>
          <input
            className={styles.inputDiscountCode}
            onChange={(e) => handleChangeCoupon(e)}
            value={couponCode}
            maxLength={30}
          />
          <div
            className={styles.applyCodeClass}
            onClick={() => (isCouponApply ? "" : handleApplyCodeClick())}
            style={{
              color: `${isCouponApply ? "#C4C4C4" : "#0074FF"}`,
              cursor: `${isCouponApply ? "not-allowed" : ""}`,
            }}
          >
            Apply
          </div>
        </div>
        {isCouponApply && !dicountCodeDetails?.isLoading && (
          <p
            className={styles.discountCodeMessage}
            style={{
              color: `${dicountCodeDetails?.error ? "red" : "#06B464"}`,
            }}
          >
            {dicountCodeDetails?.error
              ? dicountCodeDetails?.error
              : "Success! Discount code has been applied."}
          </p>
        )}
      </div>
      <div className={styles.orderDetailsTopClass}>
        <div className={styles.orderDetail}>
          <div>
            {props.data?.planName} X {duration} months
          </div>
          <div className={styles.numberClass}>
            {planAmount?.toLocaleString("en-IN")}
          </div>
        </div>
        {selectedAddons?.length > 0 && (
          <div className={styles.addonTopClass}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setIsAddonClick(!isAddonClick)}
            >
              <span>Add ons</span> <span>({selectedAddons?.length})</span>
              <img src={caratDown} style={{ marginLeft: "5px" }} />
            </div>
            <div className={styles.totalAddonAmount}>
              {totalAddons?.toLocaleString("en-IN")}
            </div>
          </div>
        )}
        {isAddonClick && selectedAddons && selectedAddons?.length > 0 && (
          <div>
            {selectedAddons?.map((each: any, i: number) => {
              return (
                <div className={styles.orderDetail} key={i}>
                  <div>{each.valueName}</div>
                  <div className={styles.numberClass}>
                    <span>{each?.price}</span> X <span>{each.quantity}</span>
                    {/* X{" "}
                    <span>{duration}</span> */}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {isCouponApply &&
          !dicountCodeDetails?.isLoading &&
          Object.keys(dicountCodeDetails?.data)?.length > 0 && (
            <div className={styles.orderDetail}>
              <div>
                Discount ({`${dicountCodeDetails?.data?.discountRatio}%`})
              </div>
              <div className={styles.discountedClass}>
                <span>&#8722;</span>
                {discountAmount?.toLocaleString("en-IN")}
              </div>
            </div>
          )}

        {firstTimeDiscount > 0 && (
          <div className={styles.orderDetail}>
            <div>Discount ({`${firstTimeDiscount}%`})</div>
            <div className={styles.discountedClass}>
              <span>&#8722;</span>
              {discountFirstTime?.toLocaleString("en-IN")}
            </div>
          </div>
        )}
        <div className={styles.orderDetail}>
          <div>Subtotal</div>
          <div className={styles.numberClass}>
            {subTotalAmount?.toLocaleString("en-IN")}
          </div>
        </div>
        <div className={styles.orderDetail}>
          <div>CGST({taxData?.taxValue["CGST"]}%)</div>
          <div className={styles.numberClass}>
            {cgst?.toLocaleString("en-IN")}
          </div>
        </div>
        <div className={styles.orderDetail}>
          <div>SGST({taxData?.taxValue["SGST"]}%)</div>
          <div className={styles.numberClass}>
            {sgst?.toLocaleString("en-IN")}
          </div>
        </div>
        {isWithinNext7Days(myPlan?.planStartDate) &&
          myPlan &&
          Object.keys(myPlan).length > 0 &&
          myPlan?.price !== "Free" ? (
          <div className={styles.orderDetail}>
            <div>Current Paid Amount</div>
            <div className={styles.numberClass}>
              - {convertToRupees(myPlan?.paidAmount, myPlan?.currency)}
            </div>
          </div>
        ) : (
          ""
        )}
        <hr className={styles.dashedLine} />
        <div className={styles.totalAmountClass}>
          <div>Total</div>
          <div style={{ color: "#067D78" }}>
            INR {totalAmount?.toLocaleString("en-IN")}
          </div>
        </div>
      </div>
      <Button
        text="I’m Ready to Pay"
        extraClass={styles.payAmountbutton}
        disabled={
          generateOrderDetailsData?.isLoading ||
          (couponCode?.length > 0 && dicountCodeDetails?.error) ||
          (couponCode?.length > 0 && couponCodeValid?.length === 0)
        }
        onClick={() => {
          handleGenerateOrderId(totalAmount);
        }}
      />
      {/* {
        showSuccessPopUp && <DisplayRazorPay/>
      } */}
      <PaymentSuccessfull
        open={showSuccessPopUp}
        setOpen={handleClosePayment}
        onClickOk={handleOnClick}
      />
    </div>
  );
};

export default OrderSummary;
