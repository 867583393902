import React, { useState, useEffect, useRef } from "react";
import styles from "./MultiSelectWithBtn.module.scss";

interface DropdownProps {
  droplist: {
    optionList: string[];
    imgSrcLeft?: string;
    imgSrcRight?: string;
    placeHolderText: string;
    imgSrcLeftHide: boolean;
    selectedOptions: string[];
  };
  isFilter?: boolean;
  isDisabled?: boolean;
  extraClassSelectedArea?: string;
  extraClassToBeSelectedArea?: string;
  img?: string;
  errorMsg?: string;
  extraClassPlaceHolder?: string;
  extraClassPlaceHolderStyling?: string;
  extraClassDropDownUl?: string;
  extraClassDropDownLi?: string;
  onSubmit: (selectedItems: string[]) => void;
}

const MultiSelectDropDown: React.FC<DropdownProps> = (props) => {
  const {
    optionList,
    imgSrcLeft,
    imgSrcRight: ImgSrcRight,
    placeHolderText,
    imgSrcLeftHide,
    selectedOptions,
  } = props.droplist;

  const [isDropdownShow, setIsDropdownShow] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [submitClicked, setSubmitClicked] = useState(false); // New state to track submit button click
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selectedItems?.length === 0 && selectedOptions.length > 0) {
      setSelectedItems(selectedOptions);
    }
  }, [selectedOptions]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        isDropdownShow &&
        ref.current &&
        !ref.current.contains(e.target as Node)
      ) {
        setIsDropdownShow(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownShow]);

  const hideAndShowDropdown = () => {
    setIsDropdownShow(!isDropdownShow);
  };

  const handleCheckboxChange = (item: string) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleSubmit = () => {
    props.onSubmit(selectedItems);
    setIsDropdownShow(false);
    setSubmitClicked(true);
  };

  return (
    <>
      <div className={`${styles.dropdownDiv}`} ref={ref}>
        <div
          className={`${styles.dropdownTopDiv} ${props.extraClassSelectedArea}`}
          onClick={() => !props.isDisabled && hideAndShowDropdown()}
        >
          {imgSrcLeft ? (
            <img src={imgSrcLeft} alt="Dropdown left icon" />
          ) : imgSrcLeftHide ? (
            ""
          ) : (
            <p></p>
          )}
          <p
            className={`${styles.dropDownTitleText} ${
              !selectedItems.length && props.extraClassPlaceHolder
                ? props.extraClassPlaceHolder
                : ""
            }
            ${props.extraClassPlaceHolderStyling}`}
            title={
              submitClicked || selectedOptions?.length > 0
                ? selectedItems.join(", ")
                : placeHolderText
            }
          >
            {submitClicked || selectedOptions?.length > 0
              ? selectedItems.join(", ")
              : placeHolderText}
          </p>
          {ImgSrcRight ? (
            <img src={ImgSrcRight} alt="Dropdown left icon" />
          ) : (
            <p></p>
          )}
        </div>
        {isDropdownShow && (
          <div
            className={`${styles.dropdownToBeSelected} ${props.extraClassToBeSelectedArea}`}
          >
            <ul
              className={`${styles.dropdownUl} ${
                props.isFilter ? styles.filterUl : ""
              } ${props.extraClassDropDownUl}`}
            >
              {optionList.length > 0
                ? optionList.map((item, indx) => (
                    <li
                      className={`${styles.dropdownLi} ${
                        props.img ? styles.imgSpace : ""
                      } ${
                        selectedItems.includes(item) ? styles.selected : ""
                      } ${props.extraClassDropDownLi}`}
                      key={item}
                      onClick={() => handleCheckboxChange(item)}
                    >
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                      <span>
                        {props.img ? <img src={props.img} alt="img" /> : ""}
                      </span>
                      <span id={`values_${indx}`}> {item} </span>
                    </li>
                  ))
                : ""}
            </ul>
            <button onClick={handleSubmit} className={styles.submitButton}>
              Apply
            </button>
          </div>
        )}
        {props.errorMsg && (
          <div
            className={styles.errorMsgStyling}
            id={`auto_${props.droplist.placeHolderText}_errMsgDD`}
          >
            {props.errorMsg}
          </div>
        )}
      </div>
    </>
  );
};

export default MultiSelectDropDown;
