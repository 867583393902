import React from "react";
import styles from "./TermsOfService.module.scss";

export const TermsOfService = () => {
  return (
    <div className={styles.termsOfServiceWrapper}>
      <div>
        Thanks for using GAMUT ANALYTICS PRIVATE LIMITED (“
        <span className={styles.boldStyling}>Gamut</span>”) Products and
        Services! These GAMUT Terms of Service (these “
        <span className={styles.boldStyling}>Terms</span>”) describe your rights
        and responsibilities as a customer of our Cloud Products. As applicable
        to the specific Cloud Product, if you are being invited or added to a
        Cloud Product set up by an GAMUT customer, the{" "}
        <a
          className={styles.linkStyling}
          href="https://saarthi.ai/user-notice"
          target="_blank"
          rel="noreferrer"
        >
          User Notice
        </a>
        &nbsp;&nbsp;governs your access and use of the Cloud Product (and not
        these Terms). These Terms are between you and the GAMUT entity that owns
        or operates all its Cloud Product and Services that you are using or
        accessing (“
        <span className={styles.boldStyling}>Gamut</span>”, “
        <span className={styles.boldStyling}>we</span>” or “
        <span className={styles.boldStyling}>us</span>”). “
        <span className={styles.boldStyling}>You</span>” means the entity you
        represent in accepting these Terms or, if that does not apply, you
        individually. If you are accepting on behalf of your employer or another
        entity, you represent and warrant that: (i) you have full legal
        authority to bind your employer or such entity to these Terms; (ii) you
        have read and understand these Terms; and (iii) you agree to these Terms
        on behalf of the party that you represent. If you don’t have the legal
        authority to bind your employer or the applicable entity please do not
        click “I agree” (or similar button or checkbox or signature field) that
        is presented to you.
      </div>
      <div className={styles.capStyling}>
        PLEASE NOTE THAT IF YOU SIGN UP FOR A CLOUD PRODUCT USING AN EMAIL
        ADDRESS FROM YOUR EMPLOYER OR ANOTHER ENTITY, THEN (A) YOU WILL BE
        DEEMED TO REPRESENT SUCH PARTY, (B) YOUR CLICK TO ACCEPT OR SIGNATURE
        WILL BIND YOUR EMPLOYER OR THAT ENTITY TO THESE TERMS, AND (C) THE WORD
        “YOU” IN THESE TERMS WILL REFER TO YOUR EMPLOYER OR THAT ENTITY.
      </div>
      <div className={styles.capItalicStyling}>
        These Terms are effective as of the date you first click “I agree” (or
        similar button or checkbox or signature field) or use or access a Cloud
        Product, whichever is earlier (the “Effective Date”). These Terms do not
        have to be signed in order to be binding. You indicate your assent to
        these Terms by clicking “I agree” (or similar button or checkbox or
        signature field) at the time you register for a Cloud Product or
        Service, create a Cloud Product or Service account, or place an Order or
        sign a Statement of Work (“SoW”). For No-Charge Products and Services,
        you also indicate your assent to these Terms by accessing or using the
        applicable No-Charge Product or Service.
      </div>

      <div className={styles.listStyling}>
        <div className={styles.listHeaderStyling}>
          <span className={styles.numStyling}>1. What these Terms cover.</span>
        </div>
        <div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>1.1. </span>
            Cloud Products and Services. These Terms govern our Cloud Products,
            related Support, and Additional Services. These Terms include Our
            Policies (including our Privacy Policy), the Product/Service
            Specific Terms, and your Orders.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>1.2. </span>
            Product/Service Specific Terms. Some Cloud Products and Services may
            be subject to additional terms specific to that product as set forth
            in the Product/Service Specific Terms. By accessing or using a
            product covered by the Product/Service Specific Terms, you also
            agree to the Product/Service Specific Terms.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>1.3. </span>
            Software Products Not Covered. These Terms do not apply to our
            downloadable software products, use of which requires a separate
            license agreement with us. For clarity, however, any client software
            (e.g., a desktop or mobile application) we provide as part of the
            Cloud Products and Services themselves remains subject to these
            Terms.
          </div>
        </div>
      </div>
      <div className={styles.listStyling}>
        <div className={styles.listHeaderStyling}>
          <span className={styles.numStyling}>
            2. How Cloud Products and Services are administered.
          </span>
        </div>
        <div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>2.1. </span>
            Administrators. Through the Cloud Products, you may be able to
            specify certain End Users as Administrators, who will have important
            rights and controls over your use of Cloud Products and Services and
            End User Accounts. This may include making Orders for Cloud Products
            and Services or enabling Apps (which may incur fees); creating,
            de-provisioning, monitoring or modifying End User Accounts, and
            setting End User usage permissions; and managing access to Your Data
            by End Users or others. Administrators may also take over management
            of accounts previously registered using an email address belonging
            to your domain (which become “managed accounts”, as would be
            described in our Documentation). Without limiting Section 2.4
            (Responsibility for End Users), which fully applies to
            Administrators, you are responsible for whom you allow to become
            Administrators and any actions they take, including as described
            above. You agree that our responsibilities do not extend to the
            internal management or administration of the Cloud Products and
            Services for you.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>2.2. </span>
            Reseller as Administrator. If you order Cloud Products and Services
            through a Reseller, then you are responsible for determining whether
            the Reseller may serve as an Administrator and for any related
            rights or obligations in your applicable agreement with the
            Reseller. As between you and GAMUT, you are solely responsible for
            any access by Reseller to your accounts or your other End User
            Accounts.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>2.3. </span>
            End User Consent. You will provide all required disclosures to and
            will obtain and maintain all required consents from End Users to
            allow: (i) Administrators to have the access described in these
            Terms and the{" "}
            <a
              className={styles.linkStyling}
              href="https://saarthi.ai/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            ; and (ii) GAMUT’s provision of the Cloud Products and Services to
            Administrators and End Users. You will provide evidence of such
            consents upon our reasonable request.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>2.4. </span>
            Responsibility for End Users. Our Cloud Products and Services have
            various user onboarding flows. Some Cloud Products and Services
            require users to be designated by Administrators; some allow users
            to sign up for individual accounts which can become associated with
            teams or organizations at a later time; and some may allow users to
            invite other users. You are responsible for understanding the
            settings and controls for each Cloud Product you use and for
            controlling whom you allow to become an End User. If payment is
            required for End Users to use or access a Cloud Product, then we are
            only required to provide the Cloud Products and Services to those
            End Users for whom you have paid the applicable fees, and only such
            End Users are permitted to access and use the Cloud Products. Some
            Cloud Products and Services may allow you to designate different
            types of End Users, in which case pricing and functionality may vary
            according to the type of End User. You are responsible for
            compliance with these Terms by all End Users, including for any
            payment obligations. Please note that you are responsible for the
            activities of all your End Users, including Orders they may place
            and how End Users use Your Data, even if those End Users are not
            from your organization or domain. We may display our{" "}
            <a
              className={styles.linkStyling}
              href="https://saarthi.ai/user-notice"
              target="_blank"
              rel="noreferrer"
            >
              User Notice
            </a>{" "}
            to End Users at sign up, account creation, Cloud Product or Service
            registration, while supplying you the SoW or this document, or
            in-product. If you use single sign-on (SSO) for identity management
            of your Cloud Product(s) such that End Users will bypass these
            screens and our
            <a
              className={styles.linkStyling}
              href="https://saarthi.ai/user-notice"
              target="_blank"
              rel="noreferrer"
            >
              User Notice
            </a>
            , you are responsible for displaying our &nbsp;
            <a
              className={styles.linkStyling}
              href="https://saarthi.ai/user-notice"
              target="_blank"
              rel="noreferrer"
            >
              User Notice
            </a>{" "}
            to End Users and for any damages resulting from your failure to do
            so.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>2.5. </span>
            Credentials. You must require that all End Users keep their user IDs
            and passwords for the Cloud Products and Services strictly
            confidential and do not share such information with any unauthorized
            person. User IDs are granted to individual, named persons and may
            not be shared. You are responsible for any and all actions taken
            using End User Accounts and passwords, and you agree to immediately
            notify us of any unauthorized use of which you become aware. 2.6.
          </div>
          <div>
            <span className={styles.numStyling}>2.6. </span>
            Domain Name Ownership. Where you are required to specify a domain
            for the operation of a Cloud Product or Service or certain Cloud
            Product or Service features, we may verify that you own or control
            that domain. If you do not own or control the domain you specify,
            then we will have no obligation to provide you with the Cloud
            Product or Service or Cloud Product or Service features.
          </div>
        </div>
      </div>
      <div className={styles.listStyling}>
        <div className={styles.listHeaderStyling}>
          <span className={styles.numStyling}>
            3. What’s included in your Cloud Product subscriptions; what are the
            restrictions.
          </span>
        </div>
        <div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>3.1. </span>
            Access to Cloud Products. Subject to these Terms and during the
            applicable Subscription Term, you may access and use the Cloud
            Products and Services for your own business purposes or personal
            use, as applicable, all in accordance with these Terms, the
            applicable Order and the Documentation. This includes the right, as
            part of your authorized use of the Cloud Products, to download and
            use the client software associated with the Cloud Products and
            Services, if necessary. The rights granted to you in this Section
            3.1 are non-exclusive, non-sublicensable and non-transferable.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>3.2. </span>
            Support. During the Subscription Term, we will provide Support for
            the Cloud Products and Services in accordance with the Service Level
            (to the extent applicable) as agreed on the applicable Order or SoW.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>3.3. </span>
            Restrictions. Except as otherwise expressly permitted in these
            Terms, you will not: (a) reproduce, modify, adapt or create
            derivative works of the Cloud Products; (b) rent, lease, distribute,
            sell, sublicense, transfer or provide access to the Cloud Products
            and Services to a third party; (c) use the Cloud Products and
            Services for the benefit of any third party; (d) incorporate any
            Cloud Products and Services into a product or service you provide to
            a third party; (e) interfere with or otherwise circumvent mechanisms
            in the Cloud Products and Services intended to limit your use; (f)
            reverse engineer, disassemble, decompile, translate or otherwise
            seek to obtain or derive the source code, underlying ideas,
            algorithms, file formats or non-public APIs to any Cloud Products,
            except to the extent expressly permitted by applicable law (and then
            only upon advance notice to us); (g) remove or obscure any
            proprietary or other notices contained in any Cloud Product; (h) use
            the Cloud Products and Services for competitive analysis or to build
            competitive products; (i) publicly disseminate information regarding
            the performance of the Cloud Products; or (j) encourage or assist
            any third party to do any of the foregoing.
          </div>
        </div>
      </div>
      <div className={styles.listStyling}>
        <div className={styles.listHeaderStyling}>
          <span className={styles.numStyling}>
            4. Our security and data privacy policies.
          </span>
        </div>
        <div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>4.1. </span>
            Security. We implement security procedures designed to help protect
            Your Data from security attacks.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>4.2. </span>
            Privacy. We collect certain data and information about you and your
            End Users in connection with your and your End Users’ use of the
            Cloud Products and Services and otherwise in connection with these
            Terms. We collect and use all such data and information in
            accordance with our{" "}
            <a
              className={styles.linkStyling}
              href="https://saarthi.ai/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            , which you acknowledge.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>4.3. </span>
            Improving Cloud Products and Services. We are always striving to
            improve the Cloud Products and Services. In order to do so, we use
            analytics techniques to better understand how our Cloud Products and
            Services are being used. For more information on these techniques
            and the type of data collected, please read our{" "}
            <a
              className={styles.linkStyling}
              href="https://saarthi.ai/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>4.4. </span>
            Subpoenas. Nothing in these Terms prevents us from disclosing Your
            Data to the extent required by law, subpoenas or court orders, but
            we will use commercially reasonable efforts to notify you where
            permitted to do so. GAMUT strives to balance your privacy rights
            with other legal requirements.
          </div>
        </div>
      </div>
      <div className={styles.listStyling}>
        <div className={styles.listHeaderStyling}>
          <span className={styles.numStyling}>
            5. Terms that apply to Your Data.
          </span>
        </div>
        <div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>5.1. </span>
            Using Your Data to provide Cloud Products and Services to You. You
            retain all right, title and interest in and to Your Data in the form
            submitted to the Cloud Products and Services. Subject to these
            Terms, and solely to the extent necessary to provide the Cloud
            Products and Services to you, you grant us a worldwide, limited term
            license to access, use, process, copy, distribute, perform, export,
            and display Your Data. Solely to the extent that reformatting Your
            Data for display in a Cloud Product or Service constitutes a
            modification or derivative work, the foregoing license also includes
            the right to make modifications and derivative works. We may also
            access your accounts, End User Accounts, and your Cloud Products and
            Services with End User permission in order to respond to your
            support requests.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>5.2. </span>
            Your Data Compliance Obligations. You and your use of Cloud Products
            and Services (including use by your End Users) must comply at all
            times with these Terms, the Acceptable Use Policy and all Laws. You
            represent and warrant that: (i) you have obtained all necessary
            rights, releases and permissions to submit all Your Data to the
            Cloud Products and Services and to grant the rights granted to us in
            these Terms and (ii) Your Data and its submission and use as you
            authorize in these Terms will not violate (1) any Laws, (2) any
            third-party intellectual property, privacy, publicity or other
            rights, or (3) any of your or third-party policies or terms
            governing Your Data. Other than our express obligations under
            Section 4 (Our security and data privacy policies), we assume no
            responsibility or liability for Your Data, and you are solely
            responsible for Your Data and the consequences of submitting and
            using it with the Cloud Products and Services.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>5.3. </span>
            No Sensitive Data. You may not submit to the Cloud Products and
            Services (or use the Cloud Products and Services to collect) any
            Sensitive Data. You also acknowledge that we are not acting as your
            Business Associate or subcontractor (as such terms are defined and
            used in HIPAA). The Cloud Products and Services may neither be HIPAA
            nor PCI DSS compliant. Notwithstanding any other provision to the
            contrary, we have no liability under these Terms for Sensitive Data.
            <div className={styles.breakStyling}> </div>
            However, GAMUT shall adopt privacy measures according to its Privacy
            Policy and adopt all commercially reasonable security measures for
            protection of End User Data according to industry standards.
            <div className={styles.breakStyling}> </div>
            In case you took the Cloud Product or Service from a Reseller (or
            Partner), the Reseller will at all times during the term of the
            subscription maintain appropriate technical and organizational
            measures to protect any End User data that it collects, accesses or
            processes in connection with the Order or subscription against
            unauthorized or unlawful use, disclosure, processing or alteration.
            <div className={styles.breakStyling}> </div>
            Reseller bears the responsibility to promptly pass on such and all
            other applicable information concerning you or the End User in case
            any unauthorized access to End User Data is observed, in event
            notifications to persons included in such data is required.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>5.4. </span>
            Your Indemnity. You will defend, indemnify and hold harmless us (and
            our Affiliates, officers, directors, agents and employees) from and
            against any and all claims, costs, damages, losses, liabilities and
            expenses (including reasonable attorneys’ fees and costs) resulting
            from any claim arising from or related to (i) your breach of Section
            2.3 (End User Consent) or any claims or disputes brought by your End
            Users arising out of their use of Cloud Products, (ii) your breach
            (or alleged breach) of Sections 5.2 (Your Data Compliance
            Obligations) or 5.3 (No Sensitive Data); or (iii) Your Materials.
            This indemnification obligation is subject to you receiving (a)
            prompt written notice of such claim (but in any event notice in
            sufficient time for you to respond without prejudice); (b) the
            exclusive right to control and direct the investigation, defense or
            settlement of such claim and (c) all reasonable necessary
            cooperation by us at your expense.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>5.5. </span>
            Removals and Suspension. We have no obligation to monitor any
            content uploaded to the Cloud Products or Services. Nonetheless, if
            we deem such action necessary based on your violation of these
            Terms, including Our Policies, or in response to takedown requests
            that we receive from any third party or entity, we may (1) remove
            Your Data from the Cloud Products and Services or (2) suspend your
            access to the Cloud Products and Services. We will use reasonable
            efforts to provide you with advance notice of removals and
            suspensions when practicable, but if we determine that your actions
            endanger the operation of the Cloud Product or Service or other
            users, we may suspend your access or remove Your Data immediately
            without notice. We have no liability to you for removing or deleting
            Your Data from or suspending your access to any Cloud Products and
            Services as described in this Section 5.5.
          </div>
        </div>
      </div>
      <div className={styles.listStyling}>
        <div className={styles.listHeaderStyling}>
          <span className={styles.numStyling}>
            6. Using third-party products with the Cloud Products. .
          </span>
        </div>
        <div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>6.1. </span>
            Third-Party Products. You (including your End Users) may choose to
            use or procure other third party products or services in connection
            with the Cloud Products or Services, or implementation,
            customization, training or other services. Your receipt or use of
            any third party products or services (and the third parties’ use of
            any of Your Data) is subject to a separate agreement between you and
            the third party provider. If you enable or use third party products
            or services with the Cloud Products and Services, we will allow the
            third party providers to access or use Your Data as required for the
            interoperation of their products and services with the Cloud
            Products and Services. This may include transmitting, transferring,
            modifying or deleting Your Data, or storing Your Data on systems
            belonging to the third party providers or other third parties. Any
            third party provider’s use of Your Data is subject to the applicable
            agreement between you and such third party provider.
          </div>
        </div>
      </div>
      <div className={styles.capStyling}>
        We are not responsible for any access to or use of Your Data by third
        party providers or their products or services, or for the security or
        privacy practices of any third party provider or its products or
        services. You are solely responsible for your decision to permit any
        third party provider or third party product or service to use Your Data.
        It is your responsibility to carefully review the agreement between you
        and the third party provider, as provided by the applicable third party
        provider. WE DISCLAIM ALL LIABILITY AND RESPONSIBILITY FOR ANY THIRD
        PARTY PRODUCTS OR SERVICES (WHETHER SUPPORT, AVAILABILITY, SECURITY OR
        OTHERWISE) OR FOR THE ACTS OR OMISSIONS OF ANY THIRD PARTY PROVIDERS OR
        VENDORS.
      </div>
      <div className={styles.listStyling}>
        <div className={styles.listHeaderStyling}>
          <span className={styles.numStyling}>8. Additional Services.</span>
        </div>
        <div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>8.1. </span>
            Additional Services. Subject to these Terms, you may purchase
            Additional Services that we will provide to you pursuant to the
            applicable Order. Additional Services may be subject to additional
            policies and terms as specified by us.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>8.2. </span>
            Our Deliverables. We will retain all right, title and interest in
            and to Our Deliverables. You may use any of Our Deliverables
            provided to you only in connection with the Cloud Products and
            Services, subject to the same usage rights and restrictions as for
            the Cloud Products and Services. For clarity, Our Deliverables are
            not considered Cloud Products and Services, and any Cloud Products
            and Services are not considered to be Our Deliverables.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>8.3. </span>
            Your Materials. You agree to provide us with reasonable access to
            Your Materials as reasonably necessary for our provision of
            Additional Services. If you do not provide us with timely access to
            Your Materials, our performance of Additional Services will be
            excused until you do so. You retain your rights in Your Materials,
            subject to our ownership of any Cloud Products and Services, any of
            Our Deliverables or any of Our Technology underlying Your Materials.
            We will use Your Materials solely for purposes of performing the
            Additional Services. You represent and warrant that you have all
            necessary rights in Your Materials to provide them to us for such
            purposes.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>8.4. </span>
            Training Not Covered. Your purchase, and our provision, of Training
            is subject to a separate agreement to be produced if needed.
          </div>
        </div>
      </div>

      <div className={styles.listStyling}>
        <div className={styles.listHeaderStyling}>
          <span className={styles.numStyling}>
            9. Billing, renewals, and payment.
          </span>
        </div>
        <div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>9.1. </span>
            Monthly and Annual Plans. Except for No-Charge Products and
            Services, all Cloud Products and Services are offered either on a
            monthly subscription basis or an annual subscription basis or on a
            one-time fee basis.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>9.2. </span>
            Renewals. Except as otherwise specified in your Order, unless either
            party cancels your subscription prior to expiration of the current
            Subscription Term, your subscription will automatically renew for
            another Subscription Term of a period equal to your initial
            Subscription Term. You will provide any notice of non-renewal
            through the means we designate, which may include account settings
            in the Cloud Products and Services or contacting our support team.
            Cancelling your subscription means that you will not be charged for
            the next billing cycle, but you will not receive any refunds or
            credits for amounts that have already been charged. All renewals are
            subject to the applicable Cloud Product and Services continuing to
            be offered and will be charged at the then-current rates.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>9.3. </span>
            Adding Users. You may add users, increase storage limits, or
            otherwise increase your use of Cloud Products and Services by
            placing a new Order or modifying an existing Order. Unless otherwise
            specified in the applicable Order or SoW, we will charge you for any
            increased use at our then-current rates, prorated for the remainder
            of the then-current Subscription Term.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>9.4. </span>
            Payment. You will pay all fees in accordance with each Order, by the
            due dates and in the currency specified in the Order. If a PO number
            is required in order for an invoice to be paid, then you must
            provide such PO number to GAMUT by emailing the PO number to the
            assigned Point of Contact or Account Manager or Engagement Manager
            or hello@saarthi.ai. For Additional Services provided at any
            non-GAMUT location, unless otherwise specified in your Order, you
            will reimburse us for our pre-approved travel, lodging and meal
            expenses, which we may charge as incurred. Other than as expressly
            set forth in Section 10 (Our return policy), 18.3 (Warranty Remedy),
            Section 20 (IP Indemnification) or Section 24 (Changes to these
            Terms), all amounts are non-refundable, non-cancelable and
            non-creditable. You agree that we may bill your credit card or other
            payment method for renewals, additional users, overages to set
            limits or scopes of use, expenses, and unpaid fees, as applicable.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>9.5. </span>
            Delivery. We will deliver the login instructions for Cloud Products
            and Services to your account or through other reasonable means no
            later than when we have received payment of the applicable fees. You
            are responsible for accessing your account to determine that we have
            received payment and that your Order has been processed. All
            deliveries under these Terms will be electronic.
          </div>
        </div>
      </div>
      <div className={styles.listStyling}>
        <div className={styles.listHeaderStyling}>
          <span className={styles.numStyling}>10. Our return policy.</span>
        </div>
        <div>
          <div className={styles.subListStyling}>
            As part of our commitment to customer satisfaction and without
            limiting the Performance Warranty in Section 18 (Warranties and
            Disclaimer), you may terminate your initial Order of a Cloud Product
            under these Terms, for no reason or any reason, by providing notice
            of termination to us no later than thirty (30) days after the Order
            date for such Cloud Product. In the event you terminate your initial
            Order under this Section 10, at your request (which may be made
            through your account with us), we will refund you the amount paid
            under such Order. This termination and refund right applies only to
            your initial Order of the Cloud Product and Service and only if you
            exercise your termination right within the period specified above,
            and does not apply to Additional Services. You understand that we
            may change this practice in the future in accordance with Section 24
            (Changes to these Terms).
          </div>
        </div>
      </div>
      <div className={styles.listStyling}>
        <div className={styles.listHeaderStyling}>
          <span className={styles.numStyling}>11. Taxes not included.</span>
        </div>
        <div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>11.1. </span>
            Taxes. Your fees under these Terms exclude any taxes or duties
            payable in respect of the Cloud Products and Services in the
            jurisdiction where the payment is either made or received. To the
            extent that any such taxes or duties are payable by us, you must pay
            to us the amount of such taxes or duties in addition to any fees
            owed under these Terms. Notwithstanding the foregoing, if you have
            obtained an exemption from relevant taxes or duties as of the time
            such taxes or duties are levied or assessed, you may provide us with
            such exemption information, and we will use reasonable efforts to
            provide you with invoicing documents designed to enable you to
            obtain a refund or credit from the relevant revenue authority, if
            such a refund or credit is available.
          </div>
          <div className={styles.subListStyling}>
            <span className={styles.numStyling}>11.2. </span>
            Withholding Taxes. You will pay all fees net of any applicable
            withholding taxes. You and we will work together to avoid any
            withholding tax if exemptions, or a reduced treaty withholding rate,
            are available. If we qualify for a tax exemption, or a reduced
            treaty withholding rate, we will provide you with reasonable
            documentary proof. You will provide us reasonable evidence that you
            have paid the relevant authority for the sum withheld or deducted.
          </div>
        </div>

        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>
              12. If you purchased through a Reseller.
            </span>
          </div>
          <div>
            <div className={styles.subListStyling}>
              If you make any purchases through an authorized partner or
              reseller of GAMUT (“Reseller”): (a) Instead of paying us, you will
              pay the applicable amounts to the Reseller, as agreed between you
              and the Reseller. We may suspend or terminate your rights to use
              Cloud Products and Services if we do not receive the corresponding
              payment from the Reseller. (b) Your order details (e.g., the Cloud
              Products and Services you are entitled to use, the number of End
              Users, the Subscription Term, etc.) will be as stated in the Order
              placed with us by the Reseller on your behalf, and Reseller is
              responsible for the accuracy of any such Order as communicated to
              us. (c) If you are entitled to a refund under these Terms, then
              unless we otherwise specify, we will refund any applicable fees to
              the Reseller and the Reseller will be solely responsible for
              refunding the appropriate amounts to you. (d) Resellers are not
              authorized to modify these Terms or make any promises or
              commitments on our behalf, and we are not bound by any obligations
              to you other than as set forth in these Terms.
            </div>
          </div>
        </div>
        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>
              13. No contingencies on other products of future functionality.
            </span>
          </div>
          <div>
            <div className={styles.subListStyling}>
              You acknowledge that the Cloud Products and Services and
              Additional Services referenced in an Order are being purchased
              separately from any of our other products or services. Payment
              obligations for any products or services are not contingent on the
              purchase or use of any of our other products (and for clarity, any
              purchases of Cloud Products and Services and Additional Services
              are separate and not contingent on each other, even if listed on
              the same Order). You agree that your purchases are not contingent
              on the delivery of any future functionality or features (including
              future availability of any Cloud Products and Services beyond the
              current Subscription Term), or dependent on any oral or written
              public comments we make regarding future functionality or
              features.
            </div>
          </div>
        </div>
        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>
              14. Evaluations, trials, and betas.
            </span>
          </div>
          <div>
            <div className={styles.subListStyling}>
              We may offer certain Cloud Products and Services to you at no
              charge, including free accounts, trial use and Beta Versions as
              defined below (collectively,{" "}
              <span className={styles.numStyling}>
                “No-Charge Productsand Services”
              </span>
              ). Your use of No-Charge Products and Services is subject to any
              additional terms that we specify and is only permitted during the
              Subscription Term we designate (or, if not designated, until
              terminated in accordance with these Terms). Except as otherwise
              set forth in this Section 14, the terms and conditions of these
              Terms governing Cloud Products and Services, including Section 3.3
              (Restrictions), fully apply to No-Charge Products and Services. We
              may modify or terminate your right to use No-Charge Products and
              Services at any time and for any reason in our sole discretion,
              without liability to you. You understand that any pre-release and
              beta Cloud Products and Services, and any pre-release and beta
              features within generally available Cloud Products and Services,
              that we make available (collectively,
              <span className={styles.numStyling}>“Beta Versions”</span>) are
              still under development, may be inoperable or incomplete and are
              likely to contain more errors and bugs than generally available
              Cloud Products and Services. We make no promises that any Beta
              Versions will ever be made generally available. In some
              circumstances, we may charge a fee in order to allow you to access
              Beta Versions, but the Beta Versions will still remain subject to
              this Section 14. All information regarding the characteristics,
              features or performance of any No-Charge Products and Services
              (including Beta Versions) constitutes our Confidential
              Information. To the maximum extent permitted by applicable law, we
              disclaim all obligations or liabilities with respect to No-Charge
              Products and Services, including any Support, warranty and
              indemnity obligations. NOTWITHSTANDING ANYTHING ELSE IN THESE
              TERMS, OUR MAXIMUM AGGREGATE LIABILITY TO YOU IN RESPECT OF
              NO-CHARGE PRODUCTS WILL BE US$100.
            </div>
          </div>
        </div>
        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>
              15. IP Rights in the Cloud Products and Services and Feedback.{" "}
            </span>
          </div>
          <div>
            <div className={styles.subListStyling}>
              Cloud Products and Services are made available on a limited access
              basis, and no ownership right is conveyed to you, irrespective of
              the use of terms such as “purchase” or “sale”. We and our
              licensors have and retain all right, title and interest, including
              all intellectual property rights, in and to Our Technology
              (including the Cloud Products and Services). From time to time,
              you may choose to submit Feedback to us. We may in connection with
              any of our products or services freely use, copy, disclose,
              license, distribute and exploit any Feedback in any manner without
              any obligation, royalty or restriction based on intellectual
              property rights or otherwise. No Feedback will be considered your
              Confidential Information, and nothing in these Terms limits our
              right to independently use, develop, evaluate, or market products
              or services, whether incorporating Feedback or otherwise.
            </div>{" "}
          </div>
        </div>
        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>16. Confidentiality.</span>
          </div>
          <div>
            <div className={styles.subListStyling}>
              Except as otherwise set forth in these Terms, each party agrees
              that all code, inventions, know-how and business, technical and
              financial information disclosed to such party (
              <span className={styles.numStyling}>“Receiving Party”</span>) by
              the disclosing party ({" "}
              <span className={styles.numStyling}>“Disclosing Party”</span>)
              constitute the confidential property of the Disclosing Party ({" "}
              <span className={styles.numStyling}>
                “Confidential Information”
              </span>
              ), provided that it is identified as confidential at the time of
              disclosure or should be reasonably known by the Receiving Party to
              be confidential or proprietary due to the nature of the
              information disclosed and the circumstances surrounding the
              disclosure. Any of Our Technology and any performance information
              relating to the Cloud Products and Services will be deemed our
              Confidential Information without any marking or further
              designation. Except as expressly authorized herein, the Receiving
              Party will (1) hold in confidence and not disclose any
              Confidential Information to third parties and (2) not use
              Confidential Information for any purpose other than fulfilling its
              obligations and exercising its rights under these Terms. The
              Receiving Party may disclose Confidential Information to its
              employees, agents, contractors and other representatives having a
              legitimate need to know, provided that they are bound to
              confidentiality obligations no less protective of the Disclosing
              Party than this Section 16 and that the Receiving Party remains
              responsible for compliance by them with the terms of this Section
              16. The Receiving Party’s confidentiality obligations will not
              apply to information which the Receiving Party can document: (i)
              was rightfully in its possession or known to it prior to receipt
              of the Confidential Information; (ii) is or has become public
              knowledge through no fault of the Receiving Party; (iii) is
              rightfully obtained by the Receiving Party from a third party
              without breach of any confidentiality obligation; or (iv) is
              independently developed by employees of the Receiving Party who
              had no access to such information. The Receiving Party may also
              disclose Confidential Information if so required pursuant to a
              regulation, law or court order (but only to the minimum extent
              required to comply with such regulation or order and with advance
              notice to the Disclosing Party). The Receiving Party acknowledges
              that disclosure of Confidential Information would cause
              substantial harm for which damages alone would not be a sufficient
              remedy, and therefore that upon any such disclosure by the
              Receiving Party the Disclosing Party will be entitled to
              appropriate equitable relief in addition to whatever other
              remedies it might have at law.
            </div>
          </div>
        </div>
        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>17. Term and Termination.</span>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>17.1. </span>
              Term. These Terms are effective as of the Effective Date and
              expire on the date of expiration or termination of all
              Subscription Terms.
            </div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>17.2. </span>
              Termination for Cause. Either party may terminate these Terms
              (including all related Orders) if the other party (a) fails to
              cure any material breach of these Terms within thirty (30) days
              after notice; (b) ceases operation without a successor; or (c)
              seeks protection under any bankruptcy, receivership, trust deed,
              creditors’ arrangement, composition or comparable proceeding, or
              if any such proceeding is instituted against that party (and not
              dismissed within sixty (60) days thereafter).
            </div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>17.3. </span>
              Termination for Convenience. You may choose to stop using the
              Cloud Products and Services and terminate these Terms (including
              all Orders) at any time for any reason upon written notice to us,
              but, unless you are exercising your right to terminate early
              pursuant to Section 10 (Our return policy), upon any such
              termination (i) you will not be entitled to a refund of any
              pre-paid fees and (ii) if you have not already paid all applicable
              fees for the then-current Subscription Term or related services
              period (as applicable), any such fees that are outstanding will
              become immediately due and payable.
            </div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>17.4. </span>
              Effects of Termination. Upon any expiration or termination of
              these Terms, you must cease using all Cloud Products and Services
              and delete (or at our request, return) all Confidential
              Information or other materials of ours in your possession,
              including on any third-party systems operated on your behalf. You
              will certify such deletion upon our request. You will not have
              access to Your Data (and we may delete all of Your Data unless
              legally prohibited) after expiration or termination of these Terms
              (or its applicable Subscription Term), so you should make sure to
              export Your Data using the functionality of the Cloud Products and
              Services during the applicable Subscription Term. If you terminate
              these Terms in accordance with Section 17.2 (Termination for
              Cause), we will refund you any prepaid fees covering the remainder
              of the then-current Subscription Term after the effective date of
              termination. If we terminate these Terms in accordance with
              Section 17.2 (Termination for Cause), you will pay any unpaid fees
              covering the remainder of the then-current Subscription Term after
              the effective date of termination. In no event will termination
              relieve you of your obligation to pay any fees payable to us for
              the period prior to the effective date of termination. Except
              where an exclusive remedy may be specified in these Terms, the
              exercise by either party of any remedy, including termination,
              will be without prejudice to any other remedies it may have under
              these Terms, by law or otherwise.
            </div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>17.5. </span>
              Survival. The following provisions will survive any termination or
              expiration of these Terms: Sections 3.3 (Restrictions), 5.4 (Your
              Indemnity), 6.1 (Third-Party Products), 9.4 (Payment), 11 (Taxes
              not included), 14 (Evaluations, trials, and betas) (disclaimers
              and use restrictions only), 15 (IP Rights in the Cloud Products
              and Services and Feedback), 16 (Confidentiality), 17 (Term and
              Termination), 18.4 (Warranty Disclaimer), 19 (Limitations of
              Liability), 20 (IP Indemnification) (but solely with respect to
              claims arising from your use of Cloud Products and Services during
              the Subscription Term), 22 (Dispute Resolution) and 26 (General
              Provisions).
            </div>
          </div>
        </div>
        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>
              18. Warranties and Disclaimer.
            </span>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>18.1. </span>
              Mutual Warranties. Each party represents and warrants that it has
              the legal power and authority to enter into these Terms.
            </div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>18.2. </span>
              Our Warranties. We warrant, for your benefit only, that we use
              commercially reasonable efforts to prevent introduction of
              viruses, Trojan horses or similar harmful materials into the Cloud
              Products and Services (but we are not responsible for harmful
              materials submitted by you or End Users) (the{" "}
              <span className={styles.numStyling}>“Performance Warranty”</span>
              ).
            </div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>18.3. </span>
              Warranty Remedy. We will use commercially reasonable efforts, at
              no charge to you, to correct reported non-conformities with the
              Performance Warranty. If we determine corrections to be
              impracticable, either party may terminate the applicable
              Subscription Term. In this case, you will receive a refund of any
              fees you have pre-paid for use of the Cloud Product for the
              terminated portion of the applicable Subscription Term. The
              Performance Warranty will not apply: (i) unless you make a claim
              within thirty (30) days of the date on which you first noticed the
              non-conformity, (ii) if the non-conformity was caused by misuse,
              unauthorized modifications or third-party products, software,
              services or equipment or (iii) to No-Charge Products. Our sole
              liability, and your sole and exclusive remedy, for any breach of
              the Performance Warranty are set forth in this Section 18.
            </div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>18.4. </span>
              WARRANTY DISCLAIMER. EXCEPT AS EXPRESSLY PROVIDED IN THIS SECTION
              18, ALL CLOUD PRODUCTS, SUPPORT AND ADDITIONAL SERVICES ARE
              PROVIDED “AS IS,” AND WE AND OUR SUPPLIERS EXPRESSLY DISCLAIM ANY
              AND ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND, INCLUDING ANY
              WARRANTY OF NON-INFRINGEMENT, TITLE, FITNESS FOR A PARTICULAR
              PURPOSE, FUNCTIONALITY OR MERCHANTABILITY, WHETHER EXPRESS,
              IMPLIED OR STATUTORY. WITHOUT LIMITING OUR EXPRESS OBLIGATIONS IN
              THESE TERMS, WE DO NOT WARRANT THAT YOUR USE OF THE CLOUD PRODUCTS
              WILL BE UNINTERRUPTED OR ERROR-FREE, THAT WE WILL REVIEW YOUR DATA
              FOR ACCURACY OR THAT WE WILL PRESERVE OR MAINTAIN YOUR DATA
              WITHOUT LOSS. YOU UNDERSTAND THAT USE OF THE CLOUD PRODUCTS
              NECESSARILY INVOLVES TRANSMISSION OF YOUR DATA OVER NETWORKS THAT
              WE DO NOT OWN, OPERATE OR CONTROL, AND WE ARE NOT RESPONSIBLE FOR
              ANY OF YOUR DATA LOST, ALTERED, INTERCEPTED OR STORED ACROSS SUCH
              NETWORKS. WE CANNOT GUARANTEE THAT OUR SECURITY PROCEDURES WILL BE
              ERROR-FREE, THAT TRANSMISSIONS OF YOUR DATA WILL ALWAYS BE SECURE
              OR THAT UNAUTHORIZED THIRD PARTIES WILL NEVER BE ABLE TO DEFEAT
              OUR SECURITY MEASURES OR THOSE OF OUR THIRD PARTY SERVICE
              PROVIDERS. WE WILL NOT BE LIABLE FOR DELAYS, INTERRUPTIONS,
              SERVICE FAILURES OR OTHER PROBLEMS INHERENT IN USE OF THE INTERNET
              AND ELECTRONIC COMMUNICATIONS OR OTHER SYSTEMS OUTSIDE OUR
              REASONABLE CONTROL. YOU MAY HAVE OTHER STATUTORY RIGHTS, BUT THE
              DURATION OF STATUTORILY REQUIRED WARRANTIES, IF ANY, WILL BE
              LIMITED TO THE SHORTEST PERIOD PERMITTED BY LAW.
            </div>
          </div>
        </div>
        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>
              19. Limitation of Liability.
            </span>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>19.1. </span>
              Consequential Damages Waiver. EXCEPT FOR EXCLUDED CLAIMS (AS
              DEFINED BELOW), NEITHER PARTY (NOR ITS SUPPLIERS) WILL HAVE ANY
              LIABILITY ARISING OUT OF OR RELATED TO THESE TERMS FOR ANY LOSS OF
              USE, LOST OR INACCURATE DATA, LOST PROFITS, FAILURE OF SECURITY
              MECHANISMS, INTERRUPTION OF BUSINESS, COSTS OF DELAY, OR ANY
              INDIRECT, SPECIAL, INCIDENTAL, RELIANCE OR CONSEQUENTIAL DAMAGES
              OF ANY KIND, EVEN IF INFORMED OF THE POSSIBILITY OF SUCH DAMAGES
              IN ADVANCE.
            </div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>19.2. </span>
              Liability Cap. EXCEPT FOR EXCLUDED CLAIMS, EACH PARTY’S AND ITS
              SUPPLIERS’ AGGREGATE LIABILITY TO THE OTHER ARISING OUT OF OR
              RELATED TO THESE TERMS WILL NOT EXCEED THE AMOUNT ACTUALLY PAID OR
              PAYABLE BY YOU TO US UNDER THESE TERMS IN THE TWELVE (12) MONTHS
              IMMEDIATELY PRECEDING THE CLAIM.
            </div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>19.3. </span>
              Excluded Claims.{" "}
              <span className={styles.numStyling}>“Excluded Claims”</span> means
              (1) amounts owed by you under any Orders, (2) either party’s
              express indemnification obligations in these Terms, and (3) your
              breach of Section 3.3 (Restrictions) or of Section 2 (Combining
              the Products with Open Source Software) of a separate document,
              Third Party Code in GAMUT Products, produced upon request or if
              applicable.
            </div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>19.4. </span>
              Nature of Claims and Failure of Essential Purpose. The parties
              agree that the waivers and limitations specified in this Section
              19 apply regardless of the form of action, whether in contract,
              tort (including negligence), strict liability or otherwise and
              will survive and apply even if any limited remedy specified in
              these Terms is found to have failed of its essential purpose.
            </div>
          </div>
        </div>
        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>20. IP Indemnification.</span>
          </div>
          <div>
            <div className={styles.subListStyling}>
              We will defend you against any claim brought against you by a
              third party alleging that the Cloud Products and Services, when
              used as authorized under these Terms, infringe a patent right
              granted in India or a copyright registered in such a jurisdiction
              (a “Claim”), and we will indemnify you and hold you harmless
              against any damages and costs finally awarded on the Claim by a
              court of competent jurisdiction or agreed to via settlement
              executed by us (including reasonable attorneys’ fees), provided
              that we have received from you: (a) prompt written notice of the
              Claim (but in any event notice in sufficient time for us to
              respond without prejudice); (b) reasonable assistance in the
              defense and investigation of the Claim, including providing us a
              copy of the Claim, all relevant evidence in your possession,
              custody, or control, and cooperation with evidentiary discovery,
              litigation, and trial, including making witnesses within your
              employ or control available for testimony; and (c) the exclusive
              right to control and direct the investigation, defense, and
              settlement (if applicable) of the Claim. If your use of the Cloud
              Products and Services is (or in your opinion is likely to be)
              enjoined, whether by court order or by settlement, or if we
              determine such actions are reasonably necessary to avoid material
              liability, we may, at our option and in our discretion: (i)
              procure the right for your continued use of the Cloud Product and
              Services in accordance with these Terms; (ii) substitute a
              substantially functionally similar Cloud Product or Service; or
              (iii) terminate your right to continue using the Cloud Product and
              Service and refund any prepaid amounts for the terminated portion
              of the Subscription Term. Our indemnification obligations above do
              not apply: (1) if the total aggregate fees we receive with respect
              to your subscription to a Cloud Product or Service in the twelve
              (12) month period immediately preceding the Claim is less than
              US$50,000; (2) if the Cloud Product is modified by any party other
              than us, but solely to the extent the alleged infringement is
              caused by such modification; (3) if the Cloud Product is used in
              combination with any non-GAMUT product, software, service or
              equipment, but solely to the extent the alleged infringement is
              caused by such combination; (4) to unauthorized use of Cloud
              Products; (5) to any Claim arising as a result of (y) Your Data or
              circumstances covered by your indemnification obligations in
              Section 5.4 (Your Indemnity) or (z) any third-party deliverables
              or components contained with the Cloud Products and Services or
              (6) if you settle or make any admissions with respect to a Claim
              without our prior written consent. THIS SECTION 20 (IP
              INDEMNIFICATION) STATES OUR SOLE LIABILITY AND YOUR EXCLUSIVE
              REMEDY FOR ANY INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS IN
              CONNECTION WITH ANY CLOUD PRODUCT OR OTHER ITEMS WE PROVIDE UNDER
              THESE TERMS.
            </div>
          </div>
        </div>
        <div className={styles.listStyling}>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>21. Publicity Rights.</span>{" "}
              We may identify you as an GAMUT customer in our promotional
              materials. We will promptly stop doing so upon your request sent
              to{" "}
              <a
                className={styles.linkStyling}
                href="mailto:sales@saarthi.ai"
                target="_blank"
                rel="noreferrer"
              >
                sales@saarthi.ai
              </a>
              .
            </div>
          </div>
        </div>
        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>22. Dispute Resolution. .</span>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>22.1. </span>
              Informal Resolution. In the event of any controversy or claim
              arising out of or relating to these Terms, the parties will
              consult and negotiate with each other and, recognizing their
              mutual interests, attempt to reach a solution satisfactory to both
              parties. If the parties do not reach settlement within a period of
              sixty (60) days, either party may pursue relief as may be
              available under these Terms pursuant to Section 22.2 (Governing
              Law; Jurisdiction). All negotiations pursuant to this Section 22.1
              will be confidential and treated as compromise and settlement
              negotiations for purposes of all rules and codes of evidence of
              applicable legislation and jurisdictions.
            </div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>22.2. </span> Governing Law;
              Jurisdiction. These Terms will be governed by and construed in
              accordance with the applicable laws of the India, without giving
              effect to the principles of that State relating to conflicts of
              laws. Each party irrevocably agrees that any legal action, suit or
              proceeding arising out of or related to these Terms must be
              brought solely and exclusively in, and will be subject to the
              service of process and other applicable procedural rules of, the
              State or Federal court in India, and each party irrevocably
              submits to the sole and exclusive personal jurisdiction of the
              courts in India, generally and unconditionally, with respect to
              any action, suit or proceeding brought by it or against it by the
              other party. In any action or proceeding to enforce a party’s
              rights under these Terms, the prevailing party will be entitled to
              recover its reasonable costs and attorneys’ fees.
            </div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>22.3. </span>
              Injunctive Relief; Enforcement. Notwithstanding the provisions of
              Section 22.1 (Informal Resolution) and 22.2 (Governing Law;
              Jurisdiction), nothing in these Terms will prevent us from seeking
              injunctive relief with respect to a violation of intellectual
              property rights, confidentiality obligations or enforcement or
              recognition of any award or order in any appropriate jurisdiction.
            </div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>22.4. </span>
              Exclusion of UN Convention and UCITA. The terms of the United
              Nations Convention on Contracts for the Sale of Goods do not apply
              to these Terms. The Uniform Computer Information Transactions Act
              (UCITA) will not apply to these Terms regardless of when or where
              adopted.
            </div>
          </div>
        </div>
        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>23. Export Restrictions.</span>
          </div>
          <div>
            <div className={styles.subListStyling}>
              The Cloud Products and Services are subject to export restrictions
              by the Indian government and may be subject to import restrictions
              by certain foreign or local governments where the Cloud Products
              and Services, and you agree to comply with all applicable export
              and import laws and regulations in your access to, use of, and
              download of the Cloud Products and Services (or any part thereof).
              You shall not (and shall not allow any third-party to) remove or
              export from India or allow the export or re-export of any part of
              the Cloud Products and Services or any direct product thereof: (a)
              into (or to a national or resident of) any embargoed or
              terrorist-supporting country; (b) to anyone on the India or US
              list of Denied Persons, Entity, or Unverified Lists or the India
              or US Treasury Department’s list of Specially Designated Nationals
              and Consolidated Sanctions list (collectively,{" "}
              <span className={styles.numStyling}>“Prohibited Persons”</span>
              ); (c) to any country to which such export or re-export is
              restricted or prohibited, or as to which the Indian or United
              States government or any agency thereof requires an export license
              or other governmental approval at the time of export or re-export
              without first obtaining such license or approval; or (d) otherwise
              in violation of any export or import restrictions, laws or
              regulations of any India or United States or foreign agency or
              authority. You represent and warrant that (i) you are not located
              in, under the control of, or a national or resident of any such
              prohibited country and (ii) none of Your Data is controlled under
              the U.S. International Traffic in Arms Regulations or similar Laws
              in other jurisdictions. You also certify that you are not a
              Prohibited Person nor owned, controlled by, or acting on behalf of
              a Prohibited Person. You agree not to use or provide the Cloud
              Products and Services for any prohibited end use, including to
              support any nuclear, chemical, or biological weapons
              proliferation, or missile technology, without the prior permission
              of the Indian government.
            </div>
          </div>
        </div>
        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>
              24. Changes to these Terms
            </span>
            We may modify the terms and conditions of these Terms (including Our
            Policies) from time to time, with notice to you in accordance with
            Section 26.1 (Notices) or by posting the modified Terms on our
            website. Together with notice, we will specify the effective date of
            the modifications.
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>24.1.</span>
              No-Charge Products. You must accept the modifications to continue
              using the No-Charge Products. If you object to the modifications,
              your exclusive remedy is to cease using the No-Charge Products.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>24.2.</span>
              Paid Products and Services. Except as otherwise indicated below,
              modifications to these Terms will take effect at the next renewal
              of your Subscription Term and will automatically apply as of the
              renewal date unless you elect not to renew pursuant to Section 9.2
              (Renewals). Notwithstanding the foregoing, in some cases (e.g., to
              address compliance with Laws, or as necessary for new features) we
              may specify that such modifications become effective during your
              then-current Subscription Term. If the effective date of such
              modifications is during your then-current Subscription Term and
              you object to the modifications, then (as your exclusive remedy)
              you may terminate your affected Orders upon notice to us, and we
              will refund you any fees you have pre-paid for use of the affected
              Cloud Products and Services for the terminated portion of the
              applicable Subscription Term. To exercise this right, you must
              provide us with notice of your objection and termination within
              thirty (30) days of us providing notice of the modifications. For
              the avoidance of doubt, any Order is subject to the version of
              these Terms in effect at the time of the Order.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>24.3.</span>
              Our Policies. We may modify Our Policies to take effect during
              your then-current Subscription Term in order to respond to changes
              in our products, our business, or Laws. In this case, unless
              required by Laws, we agree not to make modifications to Our
              Policies that, considered as a whole, would substantially diminish
              our obligations during your then-current Subscription Term.
              Modifications to Our Policies will take effect automatically as of
              the effective date specified for the updated policies.
            </div>
          </div>
        </div>
        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>
              25. Changes to the Cloud Products.
            </span>
            You acknowledge that the Cloud Products and Services are on-line,
            subscription-based products, and that in order to provide improved
            customer experience we may make changes to the Cloud Products and
            Services, and we may update the applicable Documentation
            accordingly. Subject to our obligation to provide Cloud Products and
            Services and Additional Services under existing Orders, we can
            discontinue any Cloud Products or Services, any Additional Services,
            or any portion or feature of any Cloud Products and Services for any
            reason at any time without liability to you.
          </div>
        </div>
        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>26. General Provisions.</span>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>26.1. </span>
              Notices. Any notice under these Terms must be given in writing. We
              may provide notice to you through your Notification Email Address,
              your account or in-product notifications. You agree that any
              electronic communication will satisfy any applicable legal
              communication requirements, including that such communications be
              in writing. Any notice to you will be deemed given upon the first
              business day after we send it. You will provide notice to us by
              post to #C-401, Swapnalok, Kalepadal Road, Hadapsar, Pune,
              Maharashtra, India – 412038. Your notices to us will be deemed
              given upon receipt.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>26.2.</span>
              Force Majeure. Neither party will be liable to the other for any
              delay or failure to perform any obligation under these Terms
              (except for a failure to pay fees) if the delay or failure is due
              to events which are beyond the reasonable control of such party,
              such as a strike, blockade, war, act of terrorism, riot, natural
              disaster, failure or diminishment of power or telecommunications
              or data networks or services, or refusal of a license by a
              government agency.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>26.3.</span>
              Assignment. You may not assign or transfer these Terms without our
              prior written consent. As an exception to the foregoing, you may
              assign these Terms in their entirety (including all Orders) to
              your successor resulting from a merger, acquisition, or sale of
              all or substantially all of your assets or voting securities,
              provided that you provide us with prompt written notice of the
              assignment and the assignee agrees in writing to assume all of
              your obligations under these Terms. Any attempt by you to transfer
              or assign these Terms except as expressly authorized above will be
              null and void. We may assign our rights and obligations under
              these Terms (in whole or in part) without your consent. We may
              also permit our Affiliates, agents and contractors to exercise our
              rights or perform our obligations under these Terms, in which case
              we will remain responsible for their compliance with these Terms.
              Subject to the foregoing, these Terms will inure to the parties’
              permitted successors and assigns.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>26.4.</span>
              Entire Agreement. These Terms are the entire agreement between you
              and us relating to the Cloud Products and Services and any other
              subject matter covered by these Terms, and supersede all prior or
              contemporaneous oral or written communications, proposals and
              representations between you and us with respect to the Cloud
              Products and Services or any other subject matter covered by these
              Terms. No provision of any purchase order or other business form
              employed by you will supersede or supplement the terms and
              conditions of these Terms, and any such document relating to these
              Terms will be for administrative purposes only and will have no
              legal effect.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>26.5.</span>
              Conflicts. In event of any conflict between the main body of these
              Terms and either Our Policies or Product-Specific Terms, Our
              Policies or Product-Specific Terms (as applicable) will control
              with respect to their subject matter.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>26.6.</span>
              Waivers; Modifications. No failure or delay by the injured party
              to these Terms in exercising any right, power or privilege
              hereunder will operate as a waiver thereof, nor will any single or
              partial exercise thereof preclude any other or further exercise
              thereof or the exercise of any right, power or privilege hereunder
              at law or equity. Except as set forth in Section 24 (Changes to
              these Terms), any amendments or modifications to these Terms must
              be executed in writing by an authorized representative of each
              party.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>26.7.</span>
              Interpretation. As used herein, “including” (and its variants)
              means “including without limitation” (and its variants). Headings
              are for convenience only. If any provision of these Terms is held
              to be void, invalid, unenforceable or illegal, the other
              provisions will continue in full force and effect.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>26.8.</span>
              Independent Contractors. The parties are independent contractors.
              These Terms will not be construed as constituting either party as
              a partner of the other or to create any other form of legal
              association that would give either party the express or implied
              right, power or authority to create any duty or obligation of the
              other party.
            </div>
          </div>
        </div>
        <div className={styles.listStyling}>
          <div className={styles.listHeaderStyling}>
            <span className={styles.numStyling}>27. Definitions.</span>
            Certain capitalized terms are defined in this Section 27, and others
            are defined contextually in these Terms.
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Additional Services” </span>
              means Account Manager (AM) services, premier or priority support
              or other services related to the Cloud Products and Services we
              provide to you, as identified in an Order. For the avoidance of
              doubt, Additional Services do not include the standard level of
              support included in your subscription.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Administrators” </span>
              mean the personnel designated by you who administer the Cloud
              Products and Services to End Users on your behalf.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Affiliate” </span>
              means an entity which, directly or indirectly, owns or controls,
              is owned or is controlled by or is under common ownership or
              control with a party, where “control” means the power to direct
              the management or affairs of an entity, and “ownership” means the
              beneficial ownership of greater than 50% of the voting equity
              securities or other equivalent voting interests of the entity.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>
                “Cloud Products and Services”{" "}
              </span>
              means our hosted or cloud-based solutions, including any client
              software we provide as part of the Cloud Products and Services.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Documentation” </span>
              means our standard published documentation for the Cloud Products
              and Services, that will be provided as applicable with the order.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“End User” </span>
              means an individual you or an Affiliate permits or invites to use
              the Cloud Products and Services. For the avoidance of doubt: (a)
              individuals invited by your End Users, (b) individuals under
              managed accounts, and (c) individuals interacting with a Cloud
              Product or Service as your customer are also considered End Users.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“End User Account” </span>
              means an account established by you or an End User to enable the
              End User to use or access a Cloud Product or Service.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Feedback”</span>
              means comments, questions, ideas, suggestions or other feedback
              relating to the Cloud Products and Services, Support or Additional
              Services.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}> “Laws”</span>
              means all applicable local, state, federal and international laws,
              regulations and conventions, including those related to data
              privacy and data transfer, international communications and the
              exportation of technical or personal data.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>
                “Notification Email Address”
              </span>
              means the email address(es) you used to register for a Cloud
              Product or Service account or otherwise sign up for a Cloud
              Product or Service. It is your responsibility to keep your email
              address(es) valid and current so that we are able to send notices,
              statements, and other information to you.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Order” </span>
              means GAMUT’s applicable online order page(s), flows, in-product
              screens or SoW or other GAMUT-approved ordering document or
              process describing the products and services you are ordering from
              us and, as applicable, their permitted scope of use. As
              applicable, the Order will identify: (i) the Cloud Products, (ii)
              the number of End Users, Subscription Term, domain(s) associated
              with your use of Cloud Products, storage capacity or limits, or
              other scope of use parameters and (iii) (for paid Orders) the
              amount or rate you will be charged, the billing and renewal terms,
              applicable currency, and form of payment. Orders may also include
              Additional Services and No-Charge Products.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Our Deliverables”</span>
              means any materials, deliverables, modifications, derivative works
              or developments that we provide in connection with any Additional
              Services.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Our Policies”</span>
              means our Acceptable Use Policy, Privacy Policy and (unless
              specified) any other policies or terms referenced in these Terms.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Our Technology” </span>
              means the Cloud Products and Services (including all No-Charge
              Products), Our Deliverables, their “look and feel”, any and all
              related or underlying technology and any modifications or
              derivative works of the foregoing, including as they may
              incorporate Feedback.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“PO” </span>
              means a purchase order.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>
                “Product-Specific Terms”{" "}
              </span>
              means additional terms that apply to certain Cloud Products and
              Services and Additional Services, to be supplied with the Order.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Sensitive Data” </span>
              means any (i) categories of data enumerated in European Union
              Regulation 2016/679, Article 9(1) or any successor legislation;
              (ii) patient, medical or other protected health information
              regulated by HIPAA; (iii) credit, debit or other payment card data
              subject to PCI DSS; (iv) other information subject to regulation
              or protection under specific laws such as the Gramm-Leach-Bliley
              Act (or related rules or regulations); (v) social security
              numbers, driver’s license numbers or other government ID numbers;
              or (vi) any data similar to the foregoing that is protected under
              foreign or domestic laws or regulations.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Subscription Term” </span>
              means your permitted subscription period for a Cloud Product or
              Service, as set forth in the applicable Order.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Support” </span>
              means support for the Cloud Products and Services, as in the
              Service Level (to the extent applicable). Your Support level will
              be specified in the applicable Order.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Training” </span>
              means GAMUT-provided training and certification services.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Your Data”</span>
              means any data, content, code, video, images or other materials of
              any type that you (including any of your End Users) submit to
              Cloud Products or Services. In this context, “submit” (and any
              similar term) includes submitting, uploading, transmitting or
              otherwise making available Your Data to or through the Cloud
              Products.
            </div>
          </div>
          <div>
            <div className={styles.subListStyling}>
              <span className={styles.numStyling}>“Your Materials”</span>
              means your materials, systems, personnel or other resources.
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.numStyling}> Contact Information</div>
        Gamut Analytics Private Limited: Indiqube octagon, No.643, 80 Feet Rd,
        opp. Swathi Maharaja Hotel, 4th Block, Koramangala, Bengaluru, Karnataka
        560034g
      </div>
    </div>
  );
};
