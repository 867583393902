import React, { useState } from "react";
import styles from "./SaveSaveAs.module.scss";
import { save, saveas, saveGray } from "../../../theme/assets/svg";
import { useDispatch } from "react-redux";
import {
  createNudge,
  updateNudge,
  setTypeOfStrategyMode,
  setIsScriptSelectVisited,
} from "../../../redux/strategy/nudgeCreation/actions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { UpdateStrategyModal } from "../updateStrategyModal/UpdateStrategyModal";
import { setShowRightsideAll } from "../../../redux/strategy/eachNudgeBox/actions/actionCreators";
import { CONVERSATION_URL } from "../../../services/ApiRoutes";
import _ from "lodash";
export default function SaveSaveAs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedStrategy, setSelectedStrategy] = useState<any>({});
  const allNudgeDetail = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.allNudgeDetail;
  });
  console.log("data", allNudgeDetail)
  const hasNudgeBoxes: any = allNudgeDetail.some(
    (obj: any) => "nugeBoxes" in obj || obj?.ranges?.some((e: any) => "nugeBoxes" in e)
  );
  console.log(hasNudgeBoxes, "somee");

  const strategy = useSelector((store: RootState) => {
    return store.strategyModuleReducer.strategyReducer.strategyDetails;
  });
  const typeOfMode = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.typeOfstrategyMode;
  });
  const [createScreenStrategyModal, setCreateScreenStrategyModal] =
    useState(false);
  const handleNudgeSave = () => {

    const deepCopyData = _.cloneDeep(allNudgeDetail);
    console.log("Anana", allNudgeDetail, deepCopyData)
    if (typeOfMode === "edit") {
      dispatch(updateNudge([...deepCopyData]));
    } else {
      dispatch(createNudge([...deepCopyData]));
      // dispatch(setTypeOfStrategyMode("edit"))
    }
    dispatch(setShowRightsideAll(false));
    dispatch(setIsScriptSelectVisited(false));
  };
  const handleSaveAs = () => {
    setCreateScreenStrategyModal(true);
    const sid = allNudgeDetail[0].strategyId;
    let foundObj = strategy.strategies?.find((each: any) => sid == each.id);
    if (!foundObj) {
      foundObj = strategy.recommendedStrategy?.find(
        (each: any) => sid == each.id
      );
    }
    if (foundObj) {
      setSelectedStrategy(foundObj);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <button
          className={hasNudgeBoxes ? styles.btn1 : styles.disabled}
          onClick={handleNudgeSave}
          disabled={!hasNudgeBoxes}
        >
          <img
            src={hasNudgeBoxes ? save : saveGray}
            alt=""
            id="auto_save_icon"
          />
          <p>Save</p>
        </button>
        {/* <div className={styles.middiv}></div>
        <button className={styles.btn2} onClick={handleSaveAs}>
          <img src={saveas} alt="" />
          <p>Save as</p>
        </button> */}
      </div>
      {createScreenStrategyModal && Object.keys(selectedStrategy).length && (
        <UpdateStrategyModal
          show={createScreenStrategyModal}
          close={() => {
            setCreateScreenStrategyModal(false);
          }}
          confirm={() => {
            handleNudgeSave();
            dispatch(setShowRightsideAll(false));
          }}
          editStrategyPopUp={true}
          data={selectedStrategy}
          dontShow={"ok"}
        />
      )}
    </>
  );
}
