import { takeLatest } from "redux-saga/effects";
import actionTypes from "../customIntegration.actionTypes";
import {
  getSecretKeyDetailsAPIWorker,
  sendToDevEmailInfoDetailsAPIWorker,
} from "./customIntegration.saga";

export default function* whatsAppDataWatcher(): any {
  yield takeLatest(
    actionTypes.GET_SECRET_API_REQUEST,
    getSecretKeyDetailsAPIWorker
  );
  yield takeLatest(
    actionTypes.SEND_INFO_TO_DEV_API_REQUEST,
    sendToDevEmailInfoDetailsAPIWorker
  );
}
