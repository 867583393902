import DialTimeAccord from "../../../moduleComponents/campaign_V2.0/diallerSection/schedulerSections/DialTimeAccord";
import PreferredTimeAccord from "../../../moduleComponents/campaign_V2.0/diallerSection/schedulerSections/PreferredTimeAccord";
import CallingConditionAccord from "../../../moduleComponents/campaign_V2.0/diallerSection/schedulerSections/CallingConditionAccord";
import DCBAccord from "../../../moduleComponents/campaign_V2.0/diallerSection/schedulerSections/DCBAccord";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mixpannelCampaignDetails } from "../../../../redux/mixpanel/actions";
import { Mixpanel } from "../../../../utils/mixpanelSetup";
import Checkbox from "../../../generic/checkbox/Checkbox";
import ToggleSwitch from "../../../generic/toggleSwitch/ToggleSwitch";
import PriorityDropdown from "../../../generic/priorityDropdown/PriorityDropdown";
import CallingCondition from "../../campaign_V2.0/diallerSection/callingCondition/callingCondition";
import DispositionBasedCalling from "../../campaign_V2.0/diallerSection/dispositionBasedCalling/DispositionBasedCalling";
import DialTimeModal from "../../../moduleComponents/campaign_V2.0/dialTimeModal/DialTimeModal";
import SameDayComponent from "../../../moduleComponents/campaign/samedayComponent/SameDayComponent";
import { SERVER_URL, SCHEDULER_URL } from "../../../../services/ApiRoutes";
import axios from "axios";
import { saveSchedularAPIData } from "../../../../redux/campaign_V2.0/schedularSetting/actions";
import {
  setSelectedCallingData,
  setSelectedDispositionData,
  setSelectedDispositionDataParent,
} from "../../../../redux/breadcrum/actions/breadcrum.actions";
import { setSelectedAgent } from "../../../../redux/campaign_V2.0/agentConfiguration/actions/agentConfiguration.action";
import "./CallDiallerPage.css";
import { format } from "date-fns";

export const CallDiallerPage = () => {
 
  const schedulerDetails = useSelector((store) => {
    return store.campaignModuleReducer?.schedulerData?.getSchedularDetails;
  });

  const dialTimeData = schedulerDetails?.dialTimeData?.data
  
  console.log("naraj", dialTimeData);
  // const dispositionDetails = {
  //   campaignId: schedulerDetails?.dispositionData?.campaignId,
  //   data: {
  //     connected: schedulerDetails?.dispositionData?.data?.connected,
  //     succeeding_connected: schedulerDetails?.dispositionData?.data?.connected,
  //     notConnected: schedulerDetails?.dispositionData?.data?.notConnected,
  //     succeeding_not_connected:
  //       schedulerDetails?.dispositionData?.data?.notConnected,
  //   },
  // };

  const getCallingConditionData = useSelector((store) => {
    return store.breadcrumReducer.CallingCondition?.condition;
  });

  const selectedCallingConditionData = schedulerDetails.callingConditionData?.data;
  

  const isScheduledSuccess = useSelector((store) => {
    return store.campaignModuleReducer?.schedulerData?.schedularDetails
      ?.isScheduledSuccess;
  });

  const accountNameLogIn = useSelector(
    (store) => store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );

  useEffect(() => {
    const selectedText = _.map(selectedCallingConditionData, "text");
    setSelectedCallingData(selectedText)(dispatch);
  }, selectedCallingConditionData);

  useEffect(() => {
    const selectedText = _.map(
      schedulerDetails?.dispositionData?.data?.connected,
      "respKey"
    );
    setSelectedDispositionData(selectedText)(dispatch);
    setSelectedDispositionDataParent(selectedText)(dispatch);
  }, schedulerDetails?.dispositionData);

  const token = useSelector(
    (store) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );


  const campaignData = useSelector((store) => {
    return store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
  });

  const mixpannelCampaignDetail = useSelector((store) => {
    return store?.mixpanelReducer?.campaignDetailds;
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [listData, setListData] = useState([]);
  const [prefered, setPrefed] = useState();
  const [toggleData, setToggleData] = useState(false);
  const [dispositionData, setDispositionData] = useState([]);
  const [callingListData, setCallingListData] = useState([]);
  const [selectedPer, setSelectedPer] = useState();
  const [callingData, setCallingData] = useState([]);
  const [port, setPort] = useState(0);
  const [toggle, setToggle] = useState(
    dialTimeData?.preferredTime ? dialTimeData?.preferredTime : false
  );

  const checkBoxChecked = () => {};

  const [accordData, setAccordData] = useState([
    {
      title: "Dial Time",
      component: <DialTimeModal setCheckBox={checkBoxChecked} />,
      isChecked: false,
    },

    {
      title: "Preferred Time",
      component: null,
      subComponent: (
        <>
          <ToggleSwitch
            id="preferedTime"
            checked={
              dialTimeData && dialTimeData.preferedTime
                ? dialTimeData.preferedTime
                : toggle
            }
            optionLabels={["on", "off"]}
            small={true}
            onChange={(checked) => handleDisableToggleSwitch(checked)}
          />
          <span>{toggle ? "On" : "Off"}</span>
        </>
      ),
    },
    {
      title: "Calling Condition",
      component: <CallingCondition callingListData={getCallingConditionData} />,
      isChecked: true,
    },
    {
      title: "Disposition Based Calling",
      component: (
        <DispositionBasedCalling
          selectedData={dispositionData}
          listData={listData}
          sendToggle={(value) => {
            getToggleData(value);
            setToggleData(value);
          }}
        />
      ),
      isChecked: true,
    },
  ]);

  useEffect(() => {
    setAccordData([
      {
        title: "Dial Time",
        component: <DialTimeModal setCheckBox={checkBoxChecked} />,
        isChecked: false,
      },
      // { title: "Preferred Timing", component:null },

      {
        title: "Preferred Time",
        component: null,
        subComponent: (
          <>
            <ToggleSwitch
              id="preferedTime"
              // checked={toggle}
              checked={
                dialTimeData && dialTimeData.preferedTime
                  ? dialTimeData.preferedTime
                  : toggle
              }
              optionLabels={["on", "off"]}
              small={true}
              onChange={(checked) => handleDisableToggleSwitch(checked)}
            />
            <span>{toggle ? "On" : "Off"}</span>
          </>
        ),
        isChecked: "true",
      },
      {
        title: "Calling Condition",
        component: (
          <CallingCondition
            callingData={getCallingConditionData}
            isDisabled={prefered === true ? true : false}
          />
        ),
        isChecked: "true",
      },
      {
        title: "Disposition Based Calling",
        component: (
          <DispositionBasedCalling
            selectedData={dispositionData}
            listData={listData}
            sendToggle={(value) => {
              getToggleData(value);
              setToggleData(value);
            }}
          />
        ),
        isChecked: "true",
      },
    ]);
  }, [prefered]);

  const handleCallingChange = (e, name, i) => {
    let tempVal = callingData;
    if (!tempVal.includes(name)) {
      tempVal.push(name);
    } else {
      const index = tempVal.indexOf(name);
      if (index > -1) {
        tempVal.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    setCallingData((prev) => tempVal);
    settingAccordData(listData);
  };

  const settingAccordData = (result) => {
    var cList = getCallingConditionData?.condition?.map((each, i) => {
      return {
        condition: each.text,
        respKey: each.respKey,
        component:
          each.type == "Dropdown" ? (
            <PriorityDropdown
              droplist={{
                optionList: each.value,
                parentData: each,
                imgSrcRight: downArrow,
                placeHolderText:
                  each.value.toString().length > 16 ? (
                    <>
                      <span className={`data-section`}>{each.value[0]}</span>
                      <span>...</span>
                    </>
                  ) : (
                    each.value.map((er) => {
                      return (
                        <span key={i} className={`data-section`}>
                          {er}
                        </span>
                      );
                    })
                  ),
              }}
              // isFilter={true}
              searchUi={false}
              handleSearchItem={selectedPer}
              selectedItem={(item) => onChangePer(item)}
              extraClassSelectedArea={"preEmidropdown"}
              extraClassToBeSelectedArea={"dropdowndListArea"}
            />
          ) : (
            <SameDayComponent />
          ),
        selectComponent: (
          <Checkbox
            extraSpan={
              callingData?.includes(each.text)
                ? "multi-border"
                : "multi-border-bs"
            }
            checked={callingData?.includes(each.text) ? true : false}
            onChange={(e) => {
              handleCallingChange(e, each.text, i);
              // checkBoxChecked("Calling Condition");
            }}
          />
        ),
      };
    });
    setAccordData((prev) => [
      {
        title: "Dial Time",
        component: <DialTimeModal setCheckBox={checkBoxChecked} />,
        isChecked: false,
      },
      // { title: "Preferred Timing", component:null },
      {
        title: "Preferred Time",
        component: null,
        subComponent: (
          <>
            <ToggleSwitch
              id="preferedTime"
              checked={
                schedulerStoreData?.["dialtimeData"] &&
                schedulerStoreData?.["dialtimeData"]?.["preferedTime"]
                  ? schedulerStoreData?.["dialtimeData"]?.["preferedTime"]
                  : toggle
              }
              optionLabels={["on", "off"]}
              small={true}
              onChange={(checked) => handleDisableToggleSwitch(checked)}
            />
            <span>{toggle ? "On" : "Off"}</span>
          </>
        ),
        isChecked: "true",
      },
      {
        title: "Calling Condition",
        component: (
          <CallingCondition
            selectedData={callingData}
            callingData={cList}
            checkBoxChecked={(value) => {
              setCallingData(value);
            }}
            isDisabled={prefered === true ? true : false}
          />
        ),
        isChecked: "true",
      },
      {
        title: "Disposition Based Calling",
        component: (
          <DispositionBasedCalling
            selectedData={dispositionData}
            listData={result}
            sendToggle={(value) => {
              getToggleData(value);
              setToggleData(value);
            }}
          />
        ),
        isChecked: "true",
      },
    ]);
  };

  const submitData = () => {
    let dataToSubmit = {
      campaignId: campaignData?.id,
      preferredTime:
        dialTimeData && dialTimeData.preferedTime
          ? dialTimeData.preferedTime
          : toggle,
      //   dialTimeData: schedulerDetails,
      dialTimeData: dialTimeData,
      callingConditionData: schedulerDetails?.callingConditionData.data,
      dispositionBasedCallingData: schedulerDetails?.dispositionData.data,
      channel: campaignData?.channels[0].toLowerCase(),
      parentLabel: campaignData?.parentLabel,
    };

    dispatch(
      saveSchedularAPIData({
        token: token,
        ...dataToSubmit,
      })
    );

    //mixpannel
    const createCount = mixpannelCampaignDetail?.campaignSchedulerSettingSubmit
      ? mixpannelCampaignDetail?.campaignSchedulerSettingSubmit
      : 0;
    const increasedCount = Number(createCount) + 1;
    const newCopyObj = mixpannelCampaignDetail ? mixpannelCampaignDetail : {};
    newCopyObj["campaignSchedulerSettingSubmit"] = increasedCount;
    dispatch(mixpannelCampaignDetails(newCopyObj));
    Mixpanel.track("Campaign | Campaign Scheduler settings | Visits", {
      "user Name": accountNameLogIn,
      Date: format(new Date(), "dd-MM-yyyy"),
      Click: "Submit Button",
      Count: increasedCount,
    });
  };

  useEffect(() => {
    if (isScheduledSuccess === true) {
      dispatch(setSelectedAgent({}));
      navigate("/campaignmanager");
    }
  }, [isScheduledSuccess]);

  // const allOmniChannelArray =
  //   campaignData?.strategyData?.mode &&
  //   Object.keys(campaignData?.strategyData?.mode);

  const allOmniChannelArray = campaignData?.channels;

  allOmniChannelArray &&
    allOmniChannelArray?.sort((a, b) => {
      return a?.length - b?.length;
    });

  useEffect(() => {
    axios
      .get(`${SERVER_URL}${SCHEDULER_URL.GET_ALL_PORT}`, {
        params: { id: campaignData?.accountId },
      })
      .then((res) => {
        if (res?.data?.data?.port) {
          setPort((prev) => res?.data?.data?.port);
        }
      });
  }, [campaignData?.accountId]);

  useEffect(() => {
    if (isScheduledSuccess === true) {
      navigate("/campaignmanager");
      dispatch(setSelectedAgent({}));
    }
  }, [isScheduledSuccess]);

  return (
    <div className="portNew">
      <div className="port-section">
        <div className="port-area">
          <div className="campaign-title">
            Campaign Name - {campaignData?.parentLabel}
          </div>
          <div className="allocated-port">Allocated Ports : {port}</div>
        </div>
      </div>
      <div className="accord-section">
        <div className="accord-card">
          <DialTimeAccord />
          <PreferredTimeAccord />
          <CallingConditionAccord />
          <DCBAccord />
        </div>
        <div className="accord-footer">
          {dialTimeData?.preferedTime ? (
            <span className="note-tag">
              <strong className="note-strong-tag">Note: </strong>You have
              selected preferred time based calling, Therefore current campaign
              disposition setting and calling condition will be disabled.
            </span>
          ) : (
            <span className="note-tag"></span>
          )}

          <button
            className={`accord-submit-button nextStyling ${
              dialTimeData?.s_time != null && dialTimeData?.e_time != null
                ? ""
                : "disabled"
            }`}
            onClick={submitData}
            disabled={
              dialTimeData?.s_time != null && dialTimeData?.e_time != null
                ? false
                : true
            }
            id="dialtime_schedule_btn"
          >
            Schedule
          </button>
        </div>
      </div>
    </div>
  );
};
