import actionTypes from "../personalDetails.actionTypes";

export interface stateInterface {
  personalDetails: any;
  profilePicLoading: boolean;
  profilePicDeleting: boolean;
  isLoadingPersonalDetails: boolean;
  personalDetailsError: string;
  userName: string;
  profilePicPath: string | undefined;
  email: string;
  phoneNumber: string;
  profilePictureError: string;
}

const initialState: stateInterface = {
  personalDetails: {},
  profilePicLoading: false,
  profilePicDeleting: false,
  isLoadingPersonalDetails: false,
  personalDetailsError: "",
  userName: "",
  profilePicPath: undefined,
  email: "",
  phoneNumber: "",
  profilePictureError: "",
};

export default function personalReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.PROFILE_PERSONAL_GET_DETAILS_API_CALL: {
      return {
        ...state,
        isLoadingPersonalDetails: true,
        personalDetails: {},
        personalDetailsError: "",
        // profilePicLoading: true,
        // profilePicPath: "",
      };
    }
    case actionTypes.PROFILE_PERSONAL_GET_DETAILS_API_SUCCESS: {
      return {
        ...state,
        isLoadingPersonalDetails: false,
        profilePicLoading: false,
        profilePicDeleting: false,
        personalDetails: action.payload,
      };
    }
    case actionTypes.PROFILE_PERSONAL_GET_DETAILS_API_FAIL: {
      return {
        ...state,
        isLoadingPersonalDetails: false,
        profilePicLoading: false,
        profilePicDeleting: false,
        personalDetailsError: action.payload,
      };
    }
    case actionTypes.DELETE_PROFILE_PIC: {
      return {
        ...state,
        profilePicDeleting: true,
      };
    }
    case actionTypes.UPDATE_PROFILE_PIC: {
      return {
        ...state,
        profilePicLoading: true,
        profilePictureError: "",
      };
    }
    case actionTypes.UPDATE_PROFILE_PIC_FAILURE: {
      return {
        ...state,
        profilePicLoading: false,
        profilePicDeleting: false,
        profilePictureError: action.payload,
      };
    }
    case actionTypes.USER_LOGOUT: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
//
