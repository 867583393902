export default {
  SET_METHOD_TAB: "SET_METHOD_TAB",

  UPLOAD_CSV_FILE_API_REQUEST: "UPLOAD_CSV_FILE_API_REQUEST",
  UPLOAD_CSV_FILE_API_SUCCESS: "UPLOAD_CSV_FILE_API_SUCCESS",
  UPLOAD_CSV_FILE_API_FAILURE: "UPLOAD_CSV_FILE_API_FAILURE",

  UPLOAD_PAYMENT_CSV_FILE_API_REQUEST: "UPLOAD_PAYMENT_CSV_FILE_API_REQUEST",
  UPLOAD_PAYMENT_CSV_FILE_API_SUCCESS: "UPLOAD_PAYMENT_CSV_FILE_API_SUCCESS",
  UPLOAD_PAYMENT_CSV_FILE_API_FAILURE: "UPLOAD_PAYMENT_CSV_FILE_API_FAILURE",

  UPLOAD_DUNNIG_CSV_FILE_API_REQUEST: "UPLOAD_DUNNIG_CSV_FILE_API_REQUEST",
  UPLOAD_DUNNIG_CSV_FILE_API_SUCCESS: "UPLOAD_DUNNIG_CSV_FILE_API_SUCCESS",
  UPLOAD_DUNNIG_CSV_FILE_API_FAILURE: "UPLOAD_DUNNIG_CSV_FILE_API_FAILURE",

  GET_UPLOADED_INFO_API_REQUEST: "GET_UPLOADED_INFO_API_REQUEST",
  GET_UPLOADED_INFO_API_SUCCESS: "GET_UPLOADED_INFO_API_SUCCESS",
  GET_UPLOADED_INFO_API_FAILURE: "GET_UPLOADED_INFO_API_FAILURE",

  GET_API_CSV_DATA_REQUEST: "GET_API_CSV_DATA_REQUEST",
  GET_API_CSV_DATA_SUCCESS: "GET_API_CSV_DATA_SUCCESS",
  GET_API_CSV_DATA_FAILURE: "GET_API_CSV_DATA_FAILURE",

  GET_SALESFORCE_CSV_DATA_REQUEST: "GET_SALESFORCE_CSV_DATA_REQUEST",
  GET_SALESFORCE_CSV_DATA_SUCCESS: "GET_SALESFORCE_CSV_DATA_SUCCESS",
  GET_SALESFORCE_CSV_DATA_FAILURE: "GET_SALESFORCE_CSV_DATA_FAILURE",

  POST_SELECTED_CSV_DATA_REQUEST: "POST_SELECTED_CSV_DATA_REQUEST",
  POST_SELECTED_CSV_DATA_SUCCESS: "POST_SELECTED_CSV_DATA_SUCCESS",
  POST_SELECTED_CSV_DATA_FAILURE: "POST_SELECTED_CSV_DATA_FAILURE",

  POST_SELECTED_API_DATA_REQUEST: "POST_SELECTED_API_DATA_REQUEST",
  POST_SELECTED_API_DATA_SUCCESS: "POST_SELECTED_API_DATA_SUCCESS",
  POST_SELECTED_API_DATA_FAILURE: "POST_SELECTED_API_DATA_FAILURE",

  SET_CSV_DATA_SELECTED: "SET_CSV_DATA_SELECTED",

  GET_EDIT_SCREEN_CSV_REQUEST: "GET_EDIT_SCREEN_CSV_REQUEST",
  GET_EDIT_SCREEN_CSV_SUCCESS: "GET_EDIT_SCREEN_CSV_SUCCESS",
  GET_EDIT_SCREEN_CSV_FAILURE: "GET_EDIT_SCREEN_CSV_FAILURE",

  SET_SHOW_EDIT_SCREEN: "SET_SHOW_EDIT_SCREEN",

  POST_UPDATED_CSV_API_REQUEST: "POST_UPDATED_CSV_API_REQUEST",
  POST_UPDATED_CSV_API_SUCCESS: "POST_UPDATED_CSV_API_SUCCESS",
  POST_UPDATED_CSV_API_FAILURE: "POST_UPDATED_CSV_API_FAILURE",

  DELETE_CSV_API_REQUEST: "DELETE_CSV_API_REQUEST",
  DELETE_CSV_API_SUCCESS: "DELETE_CSV_API_SUCCESS",
  DELETE_CSV_API_FAILURE: "DELETE_CSV_API_FAILURE",

  CLEAR_UPLOADED_DATA: "CLEAR_UPLOADED_DATA",
};
