import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import styles from "./Products.module.scss";
import Icon from "../../../../generic/icon/Icon";
import Button from "../../../../generic/button/Button";
import { ProductCard } from "./productCard/ProductCard";

import {
  addProductToPortfolio,
  currentProductData,
  getAllProductTypesData,
  getAllUseCasesData,
  updateProductCategory,
} from "../../../../../redux/signUpFlow/productPage/actions/productPage.actions";
import { has, isEmpty } from "lodash";
import { toast } from "react-toastify";
import _ from "lodash";
import Loader from "../../../../generic/loader/Loader";

export const Products = () => {
  const productsData = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productPageReducer?.productDetails?.sort((a:any,b:any) => a?.position -b?.position)
  );

  const currentProduct = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productPageReducer?.currentProductDetails
  );

  const productPortflios = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productPageReducer?.productPortfolioDetails
  );

  const productTypesData = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productPageReducer?.productTypeDetails
  );

  const allUseCasesData = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productPageReducer?.useCaseDetails
  );

  const isLoading = useSelector(
    (store: RootState) => store?.signUpModuleReducer?.productPageReducer
  );

  const useCasesData = allUseCasesData?.filter((data: any) => {
    if (
      data.productTypes?.includes(currentProduct?.productType?.id) &&
      data?.isSignUpDisabled === "false"
    ) {
      return data;
    }
  });
   useCasesData?.sort((a:any, b:any) => a?.useCasename?.localeCompare(b?.useCasename))
  
  // console.log(useCasesData, "ppp");

  const [productSelected, setProductSelected] = useState(productsData?.[0]);

  const [productTypeSelected, setProductTypeSelected] = useState({
    type: "",
    description: "",
    icon: "",
    id: "",
    category: "",
  });
  const [useCaseSelected, setUseCaseSelected] = useState({
    useCasename: "",
    usecase: "",
    usecaseDiscription: "",
    id: "",
    icon: "",
  });
  const [productPortflio, setProductPortfolio] = useState({
    domain: {},
    product: {},
    productType: {},
    useCase: {},
  });
  const [selectedCategory, setSelectedCategory] = useState("");

  const dispatch = useDispatch();

  const handleInputChange = (val: any) => {
    setSelectedCategory(val);
  };

  const handleProductSelection = (data: any) => {
    if (
      currentProduct?.product &&
      Object.keys(currentProduct?.product).length !== 0
    ) {
      delete currentProduct.useCase;
      delete currentProduct.productType;
    }
    setProductSelected(data);
    dispatch(currentProductData({ ...currentProduct, product: data }));
    dispatch(getAllProductTypesData({ productId: data?.id }));
  };

  const handleProductTypeSelection = (data: any) => {
    if (
      currentProduct?.useCase &&
      Object.keys(currentProduct?.useCase).length !== 0
    ) {
      delete currentProduct.useCase;
    }
    setProductTypeSelected(data);

    if (currentProduct?.product) {
      dispatch(currentProductData({ ...currentProduct, productType: data }));
    } else {
      setProductSelected(productsData?.[0]);
      dispatch(
        currentProductData({
          ...currentProduct,
          product: productsData?.[0],
          productType: data,
        })
      );
    }
    dispatch(getAllUseCasesData());
  };

  const handleClickUseCaseType = (data: any) => {
    setUseCaseSelected(data);
    setProductPortfolio({ ...currentProduct, useCase: data });
    dispatch(currentProductData({ ...currentProduct, useCase: data }));
  };

  const isProductValid = (product: any): boolean => {
    const requiredKeys = ["domain", "product", "productType", "useCase"];
    for (const key of requiredKeys) {
      if (!has(product, key) || isEmpty(product[key])) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (currentProduct?.productType?.id) {
      if (selectedCategory !== productTypeSelected?.category) {
        setProductTypeSelected((prev) => {
          return {
            ...prev,
            category: selectedCategory,
          };
        });
        dispatch(
          currentProductData({
            ...currentProduct,
            productType: {
              ...currentProduct.productType,
              category: selectedCategory,
            },
          })
        );
        dispatch(
          updateProductCategory({
            category: selectedCategory,
            id: currentProduct?.productType?.id,
            productId: currentProduct?.product?.id
              ? currentProduct?.product?.id
              : productsData?.[0]?.id,
          })
        );
      }
    }
  }, [selectedCategory, productTypeSelected]);

  // console.log("jjjkk", productTypeSelected, selectedCategory);

  useEffect(() => {
    setProductSelected((prev: any) => {
      return {
        ...prev,
        name: currentProduct?.product?.name
          ? currentProduct?.product?.name
          : productsData?.[0]?.name,
        domain: currentProduct?.product?.domain
          ? currentProduct?.product?.domain
          : productsData?.[0]?.domain,
        id: currentProduct?.product?.id
          ? currentProduct?.product?.id
          : productsData?.[0]?.id,
      };
    });
  }, [currentProduct, productsData]);

  useEffect(() => {
    setProductTypeSelected((prev) => {
      return {
        ...prev,
        type: currentProduct?.productType?.type
          ? currentProduct?.productType?.type
          : "",
        description: currentProduct?.productType?.description
          ? currentProduct?.productType?.description
          : "",
        icon: currentProduct?.productType?.icon
          ? currentProduct?.productType?.icon
          : "",
        id: currentProduct?.productType?.id
          ? currentProduct?.productType?.id
          : "",
        category: currentProduct?.productType?.category
          ? currentProduct?.productType?.category
          : "",
      };
    });

    setUseCaseSelected((prev) => {
      return {
        ...prev,
        useCasename: currentProduct?.useCase?.useCasename
          ? currentProduct?.useCase?.useCasename
          : "",
        usecase: currentProduct?.useCase?.usecase
          ? currentProduct?.useCase?.usecase
          : "",
        id: currentProduct?.useCase?.id ? currentProduct?.useCase?.id : "",
        icon: currentProduct?.useCase?.icon
          ? currentProduct?.useCase?.icon
          : "",
        usecaseDiscription: currentProduct?.useCase?.usecaseDiscription
          ? currentProduct?.useCase?.usecaseDiscription
          : "",
      };
    });
  }, [currentProduct, currentProduct?.productType]);

  // console.log("vall", isProductValid(productPortflio));

  useEffect(() => {
    if (isProductValid(productPortflio) == true) {
      // const isDuplicate =
      //   productPortflios.findIndex((obj: any) =>
      //     _.isEqual(obj, productPortflio)
      //   ) !== -1;
      // if (!isDuplicate) {
      setTimeout(function () {
        dispatch(addProductToPortfolio([...productPortflios, productPortflio]));
        //To remove highlight for selected product type, product and useCase
        const data = currentProduct;
        dispatch(
          currentProductData({
            domain: data?.domain,
            product: data?.product,
            productType: data?.productType,
          })
        );
        toast.dismiss();
        toast.success("Product added to Portfolio Successfully");
      }, 1000);
      // }
      // if (isDuplicate) {
      //   toast.error("Product already added to Portfolio Successfully");
      // }
    }
  }, [productPortflio]);

  useEffect(() => {
    // console.log("nayak", currentProduct);
    if (
      currentProduct?.useCase?.useCasename.length > 0 &&
      !currentProduct?.productType?.type
    ) {
      toast.error("Please select product type");
    }
  }, [currentProduct]);

  return (
    <div className={styles.productsWrapper}>
      {isLoading?.isLoadingProductDetails ? (
        //||
        // isLoading?.isLoadingProductTypeDetails ||
        //isLoading.isLoadingUseCaseDetails
        <div className={styles.productsLoader}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.productsHeading}>Products</div>
          <div className={styles.productsDiv}>
            {productsData?.map((data: any, index: number) => {
              return (
                <div key={index}>
                  <Button
                    text={data?.name}
                    id={`auto_product_type_${data?.name}`}
                    extraClass={`${styles.productStyling} ${
                      productSelected?.name === data?.name
                        ? styles.selectedProductStyling
                        : ""
                    }`}
                    onClick={() => {
                      handleProductSelection(data);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className={styles.productTypeOptionsDiv}>
            {productTypesData?.map((data: any, index: number) => {
              return (
                <div key={index} className={styles.productOptionDiv}>
                  <ProductCard
                    productData={data}
                    productIndex={index}
                    onClickProduct={() => {
                      handleProductTypeSelection(data);
                    }}
                    isSelected={productTypeSelected.type}
                    isSelectedCategory={data.category}
                    onClickCategory={(val: any) => {
                      handleInputChange(val);
                    }}
                  />
                </div>
              );
            })}
          </div>

          <div className={styles.productsHeading}>Usecases</div>
          <div className={styles.useCaseOptionsDiv}>
            {useCasesData?.map((data: any, index: number) => {
              return (
                <div key={index} className={styles.useCaseOptionDiv}>
                  <ProductCard
                    productData={data}
                    productIndex={index}
                    onClickProduct={() => {
                      handleClickUseCaseType(data);
                    }}
                    isSelected={useCaseSelected?.useCasename}
                    isUseCase={true}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
