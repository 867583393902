import actionTypes from "../nudge.actionTypes";

export const setAllNudgeData = (data: any) => {
  return {
    type: actionTypes.SET_ALL_NUDGE_DATA,
    payload: data,
  };
};

export const updateNudgeExpected = (
  data: any,
  index: number,
  updateData: any,
  key: string
) => {
  return {
    type: actionTypes.UPDATE_EXPECTED_DATA,
    payload: { data, index, updateData, key },
  };
};
export const createNudge = (data: any) => {
  return {
    type: actionTypes.CREATE_NUDGE_API_REQUEST,
    payload: data,
  };
};
export const updateNudge = (data: any) => {
  return {
    type: actionTypes.UPDATE_NUDGE_API_REQUEST,
    payload: data,
  };
};

export const storeConnectedNudge = (data: any) => {
  return {
    type: actionTypes.SET_CONNECTED_NUDGE,
    payload: data,
  };
};
export const updateConnectedNudgeId = (start: any, end: any) => {
  return {
    type: actionTypes.SET_CONNECTED_NUDGE_ID,
    payload: { start, end },
  };
};
export const updateConnectedNudgeStart = (data: any) => {
  return {
    type: actionTypes.SET_CONNECTED_NUDGE_START,
    payload: data,
  };
};

export const getAllNudgeDetails = (data: any) => {
  return {
    type: actionTypes.GET_NUDGE_API_REQUEST,
    payload: data,
  };
};
export const showConnectionDot = (data: any) => {
  return {
    type: actionTypes.SHOW_DOT,
    payload: data,
  };
};
export const setLoadingCreate = (data: any) => {
  return {
    type: actionTypes.CREATE_NUDGE_LODDING,
    payload: data,
  };
};

export const setIsScriptSelectVisited = (data: boolean) => {
  return {
    type: actionTypes.SET_IS_SCRIPT_SELECT_VISITED,
    payload: data,
  };
};

export const setTypeOfStrategyMode = (data: any) => {
  return {
    type: actionTypes.SET_TYPE_OF_STRATEGY_MODE,
    payload: data,
  };
};

export const removeConnectedNudgeLine = (data: any) => {
  return {
    type: actionTypes.REMOVE_CONNECTION_LINE,
    payload: data,
  };
};

export const removeConnectedIdsDelete = (data: any) => {
  return {
    type: actionTypes.REMOVE_ID_DELETE,
    payload: data,
  };
};
export const selectChannel = (data: any) => {
  return {
    type: actionTypes.SET_CHANNEL_SELECTED,
    payload: data,
  };
};
export const setWorkingNudgeData = (data: any) => {
  return {
    type: actionTypes.SET_WORKING_NUDGE_DATA,
    payload: data,
  };
};

export const getConditionOutcomes = (data: any) => {
  return {
    type: actionTypes.GET_CONDITION_API_REQUEST,
    payload: data,
  };
};
export const getConditionEditOutcomes = (data: any) => {
  return {
    type: actionTypes.GET_CONDITION_EDIT_API_REQUEST,
    payload: data,
  };
};

export const setVariation = (data: any) => {
  return {
    type: actionTypes.SET_VARIATION_SELECTED,
    payload: data,
  };
};

export const setMindMapData = (data: any) => {
  return {
    type: actionTypes.SET_SHOW_MINDMAP,
    payload: data,
  };
};

export const getdispositionForAlternate = (data: any) => {
  return {
    type: actionTypes.GET_DISPOSITION_ALTERNATE_API_REQUEST,
    payload: data,
  };
};

export const getFollowUpsDispositions = (data: any) => {
  return {
    type: actionTypes.GET_FOLLOWUPS_DISPOSITION_API_REQUEST,
    payload: data,
  };
};

export const getOutComesDispositions = (data: any) => {
  return {
    type: actionTypes.GET_OUTCOMES_DISPOSITIONS_API_REQUEST,
    payload: data,
  };
};

export function getScriptPaymentModebyFlow(payload: object) {
  return {
    type: actionTypes.GET_PAYMENT_MODE_API_REQUEST,
    payload: payload,
  };
}
export function setSelectedFlowRange(payload: string) {
  return {
    type: actionTypes.SET_SELECTED_FLOW_RANGE,
    payload: payload,
  };
}
