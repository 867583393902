import actionTypes from "../customIntegration.actionTypes";

export interface stateInterface {
  secretApiData: object;
  isLoadingSecretApiData: boolean;
  secretApiDataError: string;
  sendInfoToDevData: object;
  isLoadingsendInfoToDevData: boolean;
  sendInfoToDevDataError: string;
}

const initialState: stateInterface = {
  secretApiData: {},
  isLoadingSecretApiData: false,
  secretApiDataError: "",
  sendInfoToDevData: {},
  isLoadingsendInfoToDevData: false,
  sendInfoToDevDataError: "",
};

export default function customIntegrationReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case actionTypes.GET_SECRET_API_REQUEST: {
      return {
        ...state,
        secretApiData: {},
        isLoadingSecretApiData: true,
        secretApiDataError: "",
      };
    }
    case actionTypes.GET_SECRET_API_SUCCESS: {
      return {
        ...state,
        secretApiData: action.payload,
        isLoadingSecretApiData: false,
        secretApiDataError: "",
      };
    }
    case actionTypes.GET_SECRET_API_FAILURE: {
      return {
        ...state,
        secretApiData: {},
        isLoadingSecretApiData: false,
        secretApiDataError: action.payload,
      };
    }
    case actionTypes.SEND_INFO_TO_DEV_API_REQUEST: {
      return {
        ...state,
        sendInfoToDevData: {},
        isLoadingsendInfoToDevData: true,
        sendInfoToDevDataError: "",
      };
    }
    case actionTypes.SEND_INFO_TO_DEV_API_SUCCESS: {
      return {
        ...state,
        sendInfoToDevData: action.payload,
        isLoadingsendInfoToDevData: false,
        sendInfoToDevDataError: "",
      };
    }
    case actionTypes.SEND_INFO_TO_DEV_API_FAILURE: {
      return {
        ...state,
        sendInfoToDevData: {},
        isLoadingsendInfoToDevData: false,
        sendInfoToDevDataError: action.payload,
      };
    }
    case actionTypes.CLEAR_CUSTOM_INTEGRATION_DATA: {
      return {
        ...initialState,
      };
    }
    case actionTypes.CLEAR_CUSTOM_INTEGRATION_API_DATA: {
      return {
        ...state,
        sendInfoToDevData: {},
        isLoadingsendInfoToDevData: false,
        sendInfoToDevDataError: "",
      };
    }
    default:
      return state;
  }
}
