// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qfQOboNkFy057vHL1ow0{background:#fff;border:1px solid #e1e2e9;border-radius:5px;position:relative}.qfQOboNkFy057vHL1ow0 .tPzUjhiXEVMmspjFN6gQ{font-family:"Roboto";font-style:normal;font-weight:500;font-size:18px;line-height:21px;color:#676767;padding:15px}.OOm1NQZ_Pjnb56IYEKJ9{display:flex;justify-content:space-between;margin-top:1em;margin-bottom:1em}`, "",{"version":3,"sources":["webpack://./src/components/crm/moduleComponent/customerProfileModel/CustomerProfile.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,wBAAA,CACA,iBAAA,CACA,iBAAA,CAGA,4CACE,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,YAAA,CAGJ,sBACE,YAAA,CACA,6BAAA,CACA,cAAA,CACA,iBAAA","sourcesContent":[".customerProfileDiv {\n  background: #ffffff;\n  border: 1px solid #e1e2e9;\n  border-radius: 5px;\n  position: relative;\n  //   margin: 3% 5% 3% 3%;\n\n  .customerProfileHeaderDiv {\n    font-family: \"Roboto\";\n    font-style: normal;\n    font-weight: 500;\n    font-size: 18px;\n    line-height: 21px;\n    color: #676767;\n    padding: 15px;\n  }\n}\n.wrapers {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 1em;\n  margin-bottom: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customerProfileDiv": `qfQOboNkFy057vHL1ow0`,
	"customerProfileHeaderDiv": `tPzUjhiXEVMmspjFN6gQ`,
	"wrapers": `OOm1NQZ_Pjnb56IYEKJ9`
};
export default ___CSS_LOADER_EXPORT___;
