import React, { useEffect, useState } from "react";
import styles from "./AnalyticsScreen.module.scss";

// For Maia Pop Up Timer
export const AnalyticsMaiaPopText = (props: any) => {
  const [timerFromProps, setTimerFromProps]: any = useState("");
  const [timer, setTimer]: any = useState(0);

  useEffect(() => {
    if (typeof props.baseTimer !== "string" || !props.baseTimer) return;

    const timeDifference =
      (new Date(props.baseTimer).getTime() - new Date().getTime()) / 1000;

    setTimerFromProps(timeDifference);
  }, [props.baseTimer]);

  useEffect(() => {
    if (!timerFromProps) return;

    setTimer(timerFromProps);

    const interval = setInterval(() => {
      setTimer((prevTimer: any) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timerFromProps]);

  const hours = Math.floor(timer / 3600);
  const minutes = Math.floor((timer % 3600) / 60);
  const seconds = Math.floor(timer % 60);

  // const [time, setTime] = useState(86400); // 24 hours in seconds

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTime((prevTime) => prevTime - 1);
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  // const hours = Math.floor(time / 3600);
  // const minutes = Math.floor((time % 3600) / 60);
  // const seconds = time % 60;

  return (
    <div className={styles.analyticsContentTextDiv}>
      <span>
        {" "}
        I noticed that you have not completed your profile yet. You can get free
        credits to make calls or send texts/emails by adding your organisation
        details.
      </span>
      {/* <span className={styles.timerStyling}>{hours}</span>Hr&nbsp;
      <span className={styles.timerStyling}>
        {minutes < 10 ? `0${minutes}` : minutes}
      </span>
      Min&nbsp;
      <span className={styles.timerStyling}>
        {seconds < 10 ? `0${seconds}` : seconds}
      </span> */}
      {/* Sec. */}
    </div>
  );
};
