import React, { useState, useRef, useEffect } from "react";
import styles from "./PersonalComponent.module.scss";
import Avatar from "react-avatar";
import Button from "../../../../components/generic/button/Button";
import InputField from "../../../../components/generic/inputField/InputField";
import ErrorMessage from "../../../../components/generic/errorMessage/ErrorMessage";
import ToolTip from "../../../../components/generic/toolTip/ToolTip";
import { deleteIcon } from "../../../../theme/assets/profileSectionSvg";
import { uploadGif } from "../../../../theme/gif";
import {
  getPersonalDetails,
  updateUserDetails,
  updateProfilePic,
  deleteProfilePic,
} from "../../../../redux/profile/yourAccount/personalDetails/action/personalDetails.action";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import {
  nameValidation,
  phoneNumberValidation,
} from "../../../../utils/Validation";

type User = {
  id?: string;
  image?: string;
  name?: string;
  email?: string;
  phone?: string;
};

export default function PersonalComponent() {
  const [isDisabled, setDisabled] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [personalData, setPersonalData] = useState<User>({
    image: "",
    name: "",
    email: "",
    phone: "",
  });

  const [isImageUrl, setImageUrl] = useState("");
  const hiddenFileInput = useRef() as React.MutableRefObject<HTMLInputElement>;

  const dispatch = useDispatch();

  const personalInfo = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.personal?.personalDetails?.data?.data
  );

  const userId = useSelector(
    (state: RootState) => state.loginReducer.userLoginInfo.userDetail._id
  );

  const nameRedux = personalInfo?.name;
  const phoneNumberRedux = personalInfo?.phoneNo;
  const pic = personalInfo?.profilePicPath;

  const picLoading = useSelector(
    (state: RootState) =>
      state.profile.yourAccountReducer.personal.profilePicLoading
  );

  const picDeleting = useSelector(
    (state: RootState) =>
      state.profile.yourAccountReducer.personal.profilePicDeleting
  );

  const picError = useSelector(
    (state: RootState) =>
      state.profile.yourAccountReducer.personal.profilePictureError
  );

  useEffect(() => {
    if (pic) {
      setImageUrl(pic);
    }
  }, [pic]);

  useEffect(() => {
    setPersonalData((prev) => {
      return {
        ...prev,
        name: nameRedux ? nameRedux : "",
        phone: phoneNumberRedux ? phoneNumberRedux : "",
      };
    });
  }, [nameRedux, phoneNumberRedux]);

  useEffect(() => {
    if (!personalInfo || (userId && Object.keys(personalInfo).length === 0)) {
      dispatch(getPersonalDetails({ userId }));
    }
  }, [userId, personalInfo]);

  const handleClickUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChangeDelete = () => {
    setImageUrl("");
    dispatch(deleteProfilePic({ id: userId }));
  };

  const handleFileChangeUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      // you can display the error to the user
      console.error("Select a file");
      return;
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      reader.readAsDataURL(file);
      reader.onload = () => {
        dispatch(
          updateProfilePic({
            file: (e.target.files as FileList)[0],
            id: userId,
          })
        );
        e.target.value = "";
      };
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersonalData((prev) => {
      return { ...prev, name: event?.target?.value };
    });
    nameValidation(personalData.name);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersonalData((prev) => {
      return { ...prev, phone: event?.target?.value.replace(/[^0-9]/g, "") };
    });
    phoneNumberValidation(personalData.phone);
  };

  function updateUser() {
    dispatch(
      updateUserDetails({
        id: userId,
        name: personalData.name,
        phoneNo: personalData.phone,
      })
    );
  }

  const handleCancel = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setPersonalData({
      image: "",
      name: nameRedux ? nameRedux : "",
      email: "",
      phone: phoneNumberRedux ? phoneNumberRedux : "",
    });
  };

  return (
    <>
      <div className={styles.personalComponentWrapper}>
        <div className={styles.personalProfilePictureDiv}>
          <div className={styles.personalLabelText}>Profile Picture</div>
          <div className={styles.personalPictureInfo}>
            <div className={styles.personalPicture}>
              {picLoading ? (
                <img
                  src={uploadGif}
                  id="profile_pic_loading"
                  data-testid="profile_pic_loading"
                  className={styles.profilePictureStyling}
                />
              ) : pic && pic !== "" ? (
                <img
                   // src={`${pic}?${process.env.REACT_APP_ACCESS_TOKEN_USERUPLOADS}`}
                   src={`${pic}`}
                  id="auto_profile_pic"
                  data-testid="auto_profile_pic"
                  className={styles.profilePictureStyling}
                />
              ) : (
                <Avatar
                  name={personalData.name?.split(" ").slice(0, 2).join(" ")}
                  size="100"
                  round="7px"
                  color={"#DDECFF"}
                  fgColor={"#0174FF"}
                />
              )}
            </div>

            <div>
              <div className={styles.personalPictureInfoText}>
                <p className={styles.personalImageText}>
                  Add a picture, max file size 5 MB
                </p>
                <div id="auto_image_tooltip">
                  <ToolTip
                    about="Resolution 256px X 256px"
                    extraTextStylingClass={styles.toolTipTextStyling}
                    extraAboutStylingClass={styles.toolTipAboutStyling}
                    extraTextDivStylingClass={styles.toolTipTextDivStylingClass}
                  />
                </div>
              </div>
              <div className={styles.personalPictureButton}>
                <Button
                  text="Upload Picture"
                  extraClass={`${styles.buttonStyling} ${styles.uploadButtonStyle}`}
                  onClick={handleClickUpload}
                  disabled={
                    picLoading || picDeleting ? !isDisabled : isDisabled
                  }
                />

                <InputField
                  ref={hiddenFileInput}
                  onChange={handleFileChangeUpload}
                  extraClass={styles.inputUploadStyling}
                  type="file"
                  accept=".jpeg, .png, .jpg"
                />

                <Button
                  text="Delete"
                  extraClass={`${styles.buttonStyling} ${styles.deleteButtonStyle}`}
                  image_src_left={deleteIcon}
                  onClick={handleFileChangeDelete}
                  disabled={
                    isImageUrl === ""
                      ? !isDisabled
                      : picLoading || picDeleting
                      ? !isDisabled
                      : picError && !pic
                      ? !isDisabled
                      : isDisabled
                  }
                  imgClass={styles.ImageClassStyling}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.personalProfileInfoDiv}>
          <InputField
            label="Name"
            type="text"
            placeholder="Name"
            className={`${styles.inputFieldStyling} ${styles.nameStyling} ${
              personalInfo?.name === undefined || personalInfo?.name === ""
                ? styles.enabledInputStyling
                : styles.disabledInputStyling
            }`}
            maxLength="100"
            onChange={handleNameChange}
            onFocus={() => {
              // setIsNameFocused(true);
              setIsReadOnly(false);
            }}
            onBlur={() => {
              // setIsNameFocused(false);
              setIsReadOnly(true);
            }}
            value={
              nameRedux && nameRedux !== "" ? nameRedux : personalData?.name
            }
            disabled={
              personalInfo?.name === undefined || personalInfo?.name === ""
                ? isDisabled
                : !isDisabled
            }
          />
          {personalData.name &&
          personalData.name.length > 0 &&
          !nameValidation(personalData.name).isValid ? (
            <ErrorMessage
              errorMessage={nameValidation(personalData.name).errors.message}
            />
          ) : null}

          <div className={styles.personalProfileContactInfo}>
            <div className={styles.leftHalf}>
              <InputField
                label="Email"
                type="text"
                placeholder="Email"
                className={`${styles.inputFieldStyling} ${
                  isDisabled
                    ? styles.enabledInputStyling
                    : styles.disabledInputStyling
                }`}
                value={personalInfo?.email}
                disabled={!isDisabled}
              />
            </div>
            <div className={styles.rightHalf}>
              <InputField
                label="Phone no."
                type="text"
                placeholder="Phone Number"
                maxLength="10"
                onChange={handlePhoneChange}
                onFocus={() => {
                  // setIsPhoneFocused(true);
                  setIsReadOnly(false);
                }}
                onBlur={() => {
                  // setIsPhoneFocused(false);
                  setIsReadOnly(true);
                }}
                className={`${styles.inputFieldStyling} ${
                  personalInfo?.phoneNo === undefined ||
                  personalInfo?.phoneNo === ""
                    ? styles.enabledInputStyling
                    : styles.disabledInputStyling
                }`}
                value={
                  phoneNumberRedux && phoneNumberRedux !== ""
                    ? phoneNumberRedux
                    : personalData.phone
                }
                disabled={
                  personalInfo?.phoneNo === undefined ||
                  personalInfo?.phoneNo === ""
                    ? isDisabled
                    : !isDisabled
                }
              />
              {personalData.phone &&
              personalData.phone.length > 0 &&
              !phoneNumberValidation(personalData.phone).isValid ? (
                <ErrorMessage
                  errorMessage={
                    phoneNumberValidation(personalData.phone).errors.message
                  }
                />
              ) : null}
            </div>
          </div>

          {!personalInfo?.phoneNo ||
          personalInfo?.phoneNo === "" ||
          !personalInfo?.name ||
          personalInfo?.name === "" ? (
            <div className={styles.personalProfileContactInfoButtons}>
              <Button
                text="Cancel"
                extraClass={`${styles.buttonStyling} ${styles.cancelButtonStyle}`}
                onClick={
                  personalData?.name !== "" || personalData?.phone !== ""
                    ? handleCancel
                    : null
                }
                disabled={
                  personalData?.name === "" || personalData?.phone === ""
                    ? !isDisabled
                    : isDisabled
                }
              />

              {nameValidation(personalData.name).isValid &&
              phoneNumberValidation(personalData.phone).isValid ? (
                <Button
                  text="Save"
                  extraClass={`${styles.buttonStyling} ${styles.saveButtonActiveStyle}`}
                  onClick={updateUser}
                /> // save active
              ) : (
                <Button
                  text="Save"
                  extraClass={`${styles.buttonStyling} ${styles.saveButtonInActiveStyle}`}
                  disabled={!isDisabled}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
