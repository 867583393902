import React, { useEffect, useState } from "react";
import ExperienceCampaign from "../experienceCampaign/ExperienceCampaign";
import { OTPComponent } from "../otpComponent/OTPComponent";
import { VerifyPopUp } from "../verifyPopUp/VerfiyPopUp";
import {
  experiencePravid,
  getCallStatus,
  resetGetCallStatus,
  pushOnTranscript,
  getFlowExperienceNow,
  getLanguageExperienceNow,
  setComeFromExperienceNow,
} from "../../../../redux/signUpFlow/productTour/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../redux";
import { maiaWithOutBg, verifyProductIcon } from "../../../../theme/assets/svg";
import { experienceNow } from "../../../../theme/gif";
import style from "./ExperienceNow.module.scss";
import { useSelector } from "react-redux";
import {
  sendOTPSignUPRequest,
  verifyOTPSignUPRequest,
  resetExperienceNow,
  callExperienceNow,
} from "../../../../redux/signUpFlow/signUp/action";
import { toast } from "react-toastify";

interface experienceProps {
  show?: boolean;
  close?: CallableFunction;
  onClick?: CallableFunction;
}

function ExperienceNow({ show, close, onClick }: experienceProps) {
  const [language, setSelectedLanguage] = useState<string>();
  const [flow, setFlow] = useState<string>();
  const [showOTP, setShowOTP] = useState<boolean>(false);
  const [OTP, setOTP] = useState("");
  const [confirmOTP, setConfirmOTP] = useState(false);
  const [count, setCount] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //initial setUp
  useEffect(() => {
    dispatch(resetExperienceNow());
    dispatch(getFlowExperienceNow());
    dispatch(getLanguageExperienceNow());
  }, []);

  //redux
  const loginClientData = useSelector(
    (store: RootState) => store.loginReducer?.userLoginInfo
  );
  const loginUserId = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.userDetail?._id
  );

  const experienceNowCount = useSelector(
    (store: RootState) => store?.signUpModuleReducer?.signUp?.expNowCount
  );

  const sendOTPDetail = useSelector(
    (store: RootState) =>
      store.signUpModuleReducer?.signUp?.sendOTPExperienceNow
  );
  const verifyOTPDetail = useSelector(
    (store: RootState) =>
      store.signUpModuleReducer?.signUp?.verifyOTPExperienceNow
  );
  const callExperienceNowDetail = useSelector(
    (store: RootState) => store.signUpModuleReducer?.signUp?.callExperienceNow
  );

  const callStatus = useSelector(
    (store: RootState) =>
      store.signUpModuleReducer?.productTourReducer?.getExperienceCallStatus
  );

  //handle changes
  const handleClickButton = () => {
    const data = {
      phoneNumber: loginClientData?.userDetail?.phoneNo,
      userId: loginUserId,
    };
    if (experienceNowCount < 10) {
      dispatch(sendOTPSignUPRequest(data));
    } else {
      toast.dismiss();
      toast.error("You have exceeded the limit of experience !!");
    }
    setCount(false);
  };
  const handleChangeOTP = (enteredOtp: string) => {
    setOTP(enteredOtp);
    dispatch(resetExperienceNow());
  };
  const handleConfirmOtp = () => {
    const data = {
      userId: loginUserId,
      otp: OTP,
    };
    dispatch(verifyOTPSignUPRequest(data));
  };
  const handleResendOTP = () => {
    // setShowOTP(false)
    const data = {
      phoneNumber: loginClientData?.userDetail?.phoneNo,
      userId: loginUserId,
    };
    dispatch(sendOTPSignUPRequest(data));
    setOTP("");
  };
  // useEffect
  //--for time
  // useEffect(
  //   () => {
  //     if(confirmOTP === true){
  //       setTimeout(
  //         () => {
  //           dispatch(experiencePravid(false))
  //           navigate("/calllogger")
  //         },5000
  //       )
  //     }
  //   },[confirmOTP]
  // )
  useEffect(() => {
    if (count === true) {
      handleClickButton();
    }
  }, [count]);

  useEffect(() => {
    if (sendOTPDetail?.isShowNextStep) {
      setShowOTP(true);
    }
  }, [sendOTPDetail?.isShowNextStep]);
  useEffect(() => {
    if (verifyOTPDetail?.isNextStep) {
      const data = {
        accountId: loginClientData?.accountDetails?.[0]?.id,
        userId: loginUserId,
        isWhatsapp: true,
        isCall: true,
        isEmail: true,
        isSms: true,
        language: language,
        flow: flow,
        key: verifyOTPDetail?.data,
      };
      dispatch(callExperienceNow(data));
    }
  }, [verifyOTPDetail?.isNextStep]);

  useEffect(() => {
    if (callExperienceNowDetail?.isNextStep) {
      const data = {
        phoneNumber: `${loginClientData?.userDetail?.phoneNo}_12345`,
      };
      dispatch(pushOnTranscript(true));
      dispatch(setComeFromExperienceNow(true));
      dispatch(getCallStatus(data));
      //to rempve
      setConfirmOTP(true);
      setShowOTP(false);
      dispatch(resetExperienceNow());
    }
  }, [callExperienceNowDetail?.isNextStep]);
  // for status
  useEffect(() => {
    dispatch(resetGetCallStatus());
  }, []);
  useEffect(() => {
    if (callStatus?.data?.status) {
      const id = setTimeout(() => {
        if (callStatus?.data?.status === "Completed") {
          // clearTimeout(id);
          // dispatch(resetExperienceNow())
          // dispatch(resetGetCallStatus())
        } else if (callStatus?.data?.status === "Not-Initiated") {
          const data = {
            phoneNumber: `${loginClientData?.userDetail?.phoneNo}_12345`,
          };
          dispatch(getCallStatus(data));
          setConfirmOTP(true);
          setShowOTP(false);
          //  dispatch(resetExperienceNow())
          //--for time
          // clearTimeout(id);
          // // setConfirmOTP(true);
          // dispatch(resetExperienceNow())
          // dispatch(resetGetCallStatus())
          // // dispatch(experiencePravid(false))
          // navigate("/calllogger")
        }
      }, 3000);
      if (callStatus?.data?.status === "Completed") {
        clearTimeout(id);
        // setConfirmOTP(true);
        dispatch(resetExperienceNow());
        dispatch(resetGetCallStatus());
        dispatch(experiencePravid(false));
        navigate("/calllogger");
      }
    }
  }, [callStatus?.data]);

  return (
    <VerifyPopUp
      show={show}
      extraStyleImg={confirmOTP ? style.iconStylingRadius : style.iconStyling}
      img={!confirmOTP && verifyProductIcon}
      isGif={confirmOTP ? true : false}
      imgGif={confirmOTP && experienceNow}
      imgIcon={confirmOTP && maiaWithOutBg}
      close={() => {
        close && close();
      }}
      contentToDisplay={
        showOTP ? (
          <OTPComponent
            otpTextHeading={`One last step!`}
            otpText={`I just want to make sure the phone number you entered is correct. You would have received an OTP on XXXXXXX${loginClientData?.userDetail?.phoneNo.slice(
              -3
            )}. Please enter the OTP to confirm.`}
            otpValue={OTP}
            otpOnChange={handleChangeOTP}
            otpOnSubmit={handleConfirmOtp}
            otpOnResend={handleResendOTP}
            isOTPInValid={verifyOTPDetail?.error?.message ? true : false}
            phoneNo={1234344}
            disableConfirmBtn={
              verifyOTPDetail?.isLoading ||
              callExperienceNowDetail?.isLoading ||
              sendOTPDetail?.isLoading
            }
          />
        ) : (
          <ExperienceCampaign
            selectedFlow={setFlow}
            selectedLanguage={setSelectedLanguage}
            onClickBtn={() => setCount(true)}
            outerSelectedLanguage={language}
            outerSelectedFlow={flow}
            toShowInprogress={confirmOTP}
            disableBtn={sendOTPDetail?.isLoading}
            closeFunc={() => {
              close && close();
            }}
          />
        )
      }
    />
  );
}

export default ExperienceNow;
