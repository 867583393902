export const chartDetails = [
  {
    legendData: ["High Risk", "Medium Risk", "Low Risk"],
    keys: [
      {
        name: "Months",
        icon: "",
        referenceKeyName: "timeSlot",
        shortKey: "",
        fillColor: "#9ADBF9",
        position: 1,
      },
      {
        name: "Interactions",
        icon: "",
        referenceKeyName: "highRisk",
        shortKey: "",
        fillColor: "#F94144",
        position: 2,
      },
      {
        name: "",
        icon: "",
        referenceKeyName: "mediumRisk",
        shortKey: "",
        fillColor: "#F8961E",
        position: 2,
      },
      {
        name: "",
        icon: "",
        referenceKeyName: "lowRisk",
        shortKey: "",
        fillColor: "#07B464",
        position: 2,
      },
    ],
    chartTitle: "Average Number of Interactions for 1 successful payment",
    chartType: "multiLineChart",
    chartStyle: {
      width: "700px",
      height: "400px",
    },
    isSlider: false,
    isCardData: false,
    data: [
      {
        highRisk: 6,
        mediumRisk: 5,
        lowRisk: 2,
        timeSlot: "Jan",
      },
      {
        highRisk: 6,
        mediumRisk: 4,
        lowRisk: 2,
        timeSlot: "Feb",
      },
      {
        highRisk: 5,
        mediumRisk: 4,
        lowRisk: 3,
        timeSlot: "Mar",
      },
      {
        highRisk: 7,
        mediumRisk: 5,
        lowRisk: 2,
        timeSlot: "Apr",
      },
      {
        highRisk: 7,
        mediumRisk: 3,
        lowRisk: 3,
        timeSlot: "May",
      },
    ],
  },
  {
    chartTitle: "KYC Status",
    chartType: "donutChart",
    chartStyle: {
      width: "390px",
      height: "400px",
    },
    isSlider: false,
    isCardData: false,
    data: [
      {
        name: "Completed",
        value: 1253,
        fillColor: "#90BE6D",
      },
      {
        name: "In Progress",
        value: 1392,
        fillColor: "#F9C74F",
      },
      {
        name: "Pending",
        value: 278,
        fillColor: "#F94144",
      },
    ],
  },
  {
    legendData: ["2 Jan 2022", "10 Feb 2022"],
    keys: [
      {
        name: "Risk Category",
        icon: "",
        referenceKeyName: "risk",
        shortKey: "",
        fillColor: "#9ADBF9",
        position: 1,
      },
      {
        name: "No. of Customer",
        icon: "",
        referenceKeyName: "june22",
        shortKey: "",
        fillColor: "#90BE6D",
        position: 2,
      },
      {
        name: "",
        icon: "",
        referenceKeyName: "june23",
        shortKey: "",
        fillColor: "#2D9CDB",
        position: 2,
      },
    ],
    chartTitle: "Customers by Risk",
    chartType: "biaxialBarChart",
    chartStyle: {
      width: "600px",
      height: "400px",
      barSize:"20"
    },
    isSlider: false,
    isCardData: false,
    data: [
      {
        risk: "low",
        june22: 100,
        june23: 200,
      },
      {
        risk: "medium",
        june22: 150,
        june23: 350,
      },
      {
        risk: "high",
        june22: 200,
        june23: 500,
      },
    ],
  },
  {
    legendData: ["Internet Banking", "Agent", "UPI"],
    keys: [
      {
        name: "Months",
        icon: "",
        referenceKeyName: "timeSlot",
        shortKey: "",
        fillColor: "#9ADBF9",
        position: 1,
      },
      {
        name: "No. of Customer",
        icon: "",
        referenceKeyName: "internetBanking",
        shortKey: "",
        fillColor: "#24BEE5",
        position: 2,
      },
      {
        name: "",
        icon: "",
        referenceKeyName: "agent",
        shortKey: "",
        fillColor: "#F8961E",
        position: 2,
      },
      {
        name: "",
        icon: "",
        referenceKeyName: "upi",
        shortKey: "",
        fillColor: "#07B464",
        position: 2,
      },
    ],
    chartTitle: "Preferred Payment mode",
    chartType: "multiLineChart",
    chartStyle: {
      width: "600px",
      height: "400px",
    },
    isSlider: false,
    isCardData: false,
    data: [
      {
        internetBanking: 162, 
        agent: 63,
        upi: 562,
        timeSlot: "Jan",
      },
      {
        internetBanking: 160,
        agent: 93,
        upi: 621,
        timeSlot: "Feb",
      },
      {
        internetBanking: 153,
        agent: 87,
        upi: 589,
        timeSlot: "Mar",
      },
      {
        internetBanking: 239,
        agent: 173,
        upi: 782,
        timeSlot: "Apr",
      },
      {
        internetBanking: 113,
        agent: 43,
        upi: 297,
        timeSlot: "May",
      },
    ],
  },
  {
    legendData: ["Customer Range", "Time in Hour"],
    keys: [
      {
        name: "Time in Hour",
        icon: "",
        referenceKeyName: "timeSlot",
        shortKey: "",
        fillColor: "#9ADBF9",
        position: 1,
      },
      {
        name: "Customer Range",
        icon: "",
        referenceKeyName: "total",
        shortKey: "",
        fillColor: "#9ADBF9",
        position: 2,
      },
    ],
    chartTitle: "Preferred Interaction Time",
    chartType: "lineChart",
    chartStyle: {
      width: "600px",
      height: "400px",
      barSize: "20"
    },
    isSlider: true,
    isCardData: false,
    dataCall: [
      {
        total: 1763,
        timeSlot: "10-12PM",
      },
      {
        total: 3832,
        timeSlot: "12 PM -2 PM",
      },
      {
        total: 2682,
        timeSlot: "2 PM - 4 PM",
      },
      {
        total: 427,
        timeSlot: "4 PM - 6 PM",
      },
      {
        total: 721,
        timeSlot: "8 AM - 10 AM",
      },
    ],
    dataWhatsApp: [
      {
        total: 567,
        timeSlot: "10-12PM",
      },
      {
        total: 932,
        timeSlot: "12 PM -2 PM",
      },
      {
        total: 354,
        timeSlot: "2 PM - 4 PM",
      },
      {
        total: 513,
        timeSlot: "4 PM - 6 PM",
      },
      {
        total: 382,
        timeSlot: "8 AM - 10 AM",
      },
    ],
  },
    {
      chartTitle: "Preferred Language",
      chartType: "pieChart",
      chartStyle: {
        width: "600px",
        height: "400px",
      },
      isSlider: false,
      isCardData: false,
      data: [
        {
          name: "English",
          value: 45,
        },
        {
          name: "Hindi",
          value: 50,
        },
        {
          name: "Tamil",
          value: 10,
        },
        {
          name: "Kanada",
          value: 20,
        },
        {
          name: "Odia",
          value: 15,
        },
        {
          name: "Bangla",
          value: 10,
        },
        {
          name: "Telugu",
          value: 40,
        },
        {
          name: "Marathi",
          value: 5,
        },
      ],
    },
    {
      chartTitle: "Channel Distribution",
      chartType: "pieChart",
      chartStyle: {
        width: "600px",
        height: "400px",
      },
      isSlider: false,
      isCardData: false,
      data: [
        {
          name: "Call",
          value: 45,
        },
        {
          name: "WhatsApp",
          value: 40,
        },
        {
          name: "WhatsApp + Call",
          value: 70,
        },
      ],
    },
    {
      chartTitle: "Loan Distribution",
      chartType: "pieChart",
      chartStyle: {
        width: "600px",
        height: "400px",
      },
      isSlider: false,
      isCardData: false,
      data: [
        {
          name: "Vehicle",
          value: 45,
        },
        {
          name: "Home",
          value: 50,
        },
        {
          name: "Personal",
          value: 10,
        },
        {
          name: "Education",
          value: 20,
        },
      ],
    },
   
    
    // {
    //   chartTitle: "Funnel Analysis",
    //   chartType: "funnelChart",
    //   chartStyle: {
    //     width: "400px",
    //     height: "400px",
    //   },
    //   data: [
    //     {
    //       name: "Total Calls Initiated",
    //       value: 3523,
    //     },
    //     {
    //       name: "Number Connected Customers",
    //       value: 2076,
    //     },
    //     {
    //       name: "Payment Date Given",
    //       value: 1230,
    //     },
    //     {
    //       name: "Payment Done",
    //       value: 523,
    //     },
    //   ],
    // },
   
   
   
    
  ];
  