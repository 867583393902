import React from "react";
import styles from "./HelpModal.module.scss";
import Modal from "../../../generic/modal/Modal";
import Button from "../../../generic/button/Button";
import Icon from "../../../generic/icon/Icon";
import { createStrategyCloseIcon } from "../../../../theme/assets/svg/strategySvg";

interface helpModalProps {
  show?: boolean;
  close?: CallableFunction;
  modalIcon?: string;
  modalContent?: any;
  isClose?: boolean;
  extraContentClass?: any;
  extraClassModalStyling?: string;
  extraClassContentModalStyling?: string;
  extraIconStyling?: string;
  extraModalStyling?: string;
  extraClassModalBackDrop?: string;
  extraContentCloseClass?: string;
}

export const HelpModal = ({
  show,
  modalIcon,
  modalContent,
  isClose,
  extraContentClass,
  extraClassModalStyling,
  extraClassContentModalStyling,
  extraIconStyling,
  extraModalStyling,
  extraClassModalBackDrop,
  extraContentCloseClass,
  close,
}: helpModalProps) => {
  return (
    <div>
      <Modal
        shown={show}
        extraClassStyleModalContent={`${
          extraModalStyling ? extraModalStyling : styles.helpModalStyling
        }
        `}
        extraClassStyleModalBackDrop={`${
          extraClassModalBackDrop ? extraClassModalBackDrop : ""
        } ${styles.helpBackDropStyling}
        }
        `}
      >
        <div
          className={`${
            extraClassModalStyling
              ? extraClassModalStyling
              : styles.helpModalContentPositionStyling
          }
          `}
          data-testid="help-modal"
        >
          <div
            className={`${
              extraClassContentModalStyling
                ? extraClassContentModalStyling
                : styles.helpModalContentStyling
            }
          `}
          >
            <Icon
              img_src={modalIcon}
              extraClass={`${
                extraIconStyling
                  ? extraIconStyling
                  : styles.noProductPageIconStyling
              }`}
              id={"auto_noProductPage_icon"}
            />

            <div
              className={`${
                extraContentClass ? extraContentClass : styles.contentDiv
              }`}
            >
              {isClose && (
                <div
                  className={`${
                    extraContentCloseClass
                      ? extraContentCloseClass
                      : styles.contentDivHelp
                  }`}
                >
                  <div className={styles.modalHelpCloseIcon}>
                    <img
                      src={createStrategyCloseIcon}
                      onClick={() => {
                        close && close();
                      }}
                      className={styles.iconStyling}
                      id="auto_modal_close_icon"
                    />
                  </div>
                </div>
              )}
              <div className={styles.modalHelpContent}>{modalContent}</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
