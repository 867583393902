export default {
  INVOICES_DATA_API_SUCCESS: "INVOICES_DATA_API_SUCCESS",
  INVOICES_DATA_API_FAILURE: "INVOICES_DATA_API_FAILURE",
  INVOICES_DATA_API_REQUEST: "INVOICES_DATA_API_REQUEST",
  SORT_INVOICES_DATA: "SORT_INVOICES_DATA",
  USER_LOGOUT: "USER_LOGOUT",
  CLEAR_DOWNLOAD_INVOICE: "CLEAR_DOWNLOAD_INVOICE",
  DOWNLOAD_INVOICES_API_SUCCESS: "DOWNLOAD_INVOICES_API_SUCCESS",
  DOWNLOAD_INVOICES_API_FAILURE: "DOWNLOAD_INVOICES_API_FAILURE",
  DOWNLOAD_INVOICES_API_REQUEST: "DOWNLOAD_INVOICES_API_REQUEST",
};
