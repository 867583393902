import React, { useEffect, useState } from "react";
import Icon from "../../../generic/icon/Icon";
import { backIcon } from "../../../../theme/assets/svg/strategySvg";
import "./DiallerSection.css";
import PageToggler from "../../../generic/pageToggler/PageToggler";
import WpSettingPage from "../../../../screens/campaign_V2.0/schedulerSettingPageWp/WpSettingPage";
import { CallDiallerPage } from "../callDiallerPage/CallDiallerPage";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  saveSchedularAPIData,
  getSchedularAPIData,
} from "../../../../redux/campaign_V2.0/schedularSetting/actions";
import { setSelectedAgent } from "../../../../redux/campaign_V2.0/agentConfiguration/actions/agentConfiguration.action";
import { RootState } from "../../../../redux";

export default function DiallerSection() {
  const dispatch = useDispatch();

  const campaignData = useSelector(
    (store: RootState) =>
      store?.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
  );

  const channels = campaignData?.channels

  const stepsTotal: string[] = useSelector(
    (store: RootState) =>
      store?.schedulerReducer?.accountInfoDetails?.campaignProgressBar
  );

  // const stepsTotal = [
  //   "Campaign Details",
  //   "Data Upload",
  //   "Strategy Selection",
  //   "Agent Configuration",
  //   "Scheduler Setting",
  // ];

  const schedulerDetails = useSelector((store : RootState) => {
    return store.campaignModuleReducer?.schedulerData?.getSchedularDetails;
  });

  const dialTimeData = schedulerDetails?.dialTimeData?.data;

  const token = useSelector(
    (store : RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  
  const isScheduledSuccess = useSelector((store : RootState) => {
    return store.campaignModuleReducer?.schedulerData?.schedularDetails
      ?.isScheduledSuccess;
  });

  /** for time being */
  const submitDatawhatsApp = () => {
    const  dataToSubmit = {
      campaignId: campaignData?.id,
      preferredTime:
        dialTimeData && dialTimeData.preferedTime
          ? dialTimeData.preferedTime
          : false,
      //   dialTimeData: schedulerDetails,
      dialTimeData: dialTimeData,
      callingConditionData: schedulerDetails?.callingConditionData?.data,
      dispositionBasedCallingData: schedulerDetails?.dispositionData?.data,
      channel: campaignData?.channels[0]?.toLowerCase(),
      parentLabel: campaignData?.parentLabel,
    };

    dispatch(
      saveSchedularAPIData({
        token: token,
        ...dataToSubmit,
      })
    );
    navigate("/campaignmanager");
    dispatch(setSelectedAgent({}));
  };
  useEffect(() => {
    dispatch(
      getSchedularAPIData({
        token: token,
        campaignId: campaignData?.id,
        strategyId: campaignData?.strategyId,
      })
    );
  }, [campaignData?.id, campaignData?.strategyId, token]);
  const NewWhatSApp = () => {
    return (
      <>
        <WpSettingPage onSubmit={submitDatawhatsApp} />
        {/* <button
            className={`accord-submit-button nextStyling forwhatsAppBtn`}
             onClick={submitDatawhatsApp}
            id="dialtime_schedule_btn"
          >
            Schedule
          </button> */}
      </>
    );
  };
  /****/
  const handleModes = (data : any) => {
    const channelsArr = [];
    const modesArr = data;
    if (modesArr && modesArr.includes("Call")) {
      channelsArr.push({
        name: "Voice",
        element: <CallDiallerPage />,
      });
    }
    if (modesArr && modesArr.includes("Whatsapp")) {
      channelsArr.push({
        name: "Whatsapp",
        element: <NewWhatSApp />,
      });
    }
    return channelsArr;
  };

  const [currentTab, setCurrentTab] = useState<any>(handleModes(channels)[0]?.name);
  const [tab, setTab] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (tab.length > 0) {
      setCurrentTab(tab[0].name);
    }
  }, [tab]);

  return (
    <div className="diallerNew">
      <div className="dialler-section-img">
        <Icon
          img_src={backIcon}
          extraClass={"backIconStyling"}
          onClick={() => {
            if (stepsTotal.includes("Agent Configuration")) {
              navigate("/campaignmanager/allagents");
            }
            if (stepsTotal.includes("Strategy Selection")) {
              navigate("/campaignmanager/strategyselection");
            }
            if (stepsTotal.includes("Data Upload")) {
              navigate("/campaignmanager/campaigncreation");
            }
          }}
          id="auto_dialler_section_back_icon"
        />
      </div>
      <div className="dialler-section">
        <div className="dialler-section-navbar">
          <PageToggler
            current={currentTab}
            innerComponents={handleModes(channels)}
            switchTab={(e : any) => {
              setCurrentTab(e);
            }}
            extraHeaderStyling={"diallerExtraStyling"}
            blueStyles={true}
            tabStyling={"tabExtraStyling"}
          />
        </div>
      </div>
    </div>
  );
}
