// import React from "react";
import React, { useEffect, useRef, useCallback, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import HeaderComponent from "../../components/moduleComponents/navigationBar/NavigationBar";
import LeftMenu from "../../components/moduleComponents/leftSideMenu/LeftSideMenu";
import RightSideBarRoute from "../../router/subRoutes/RightSideBarRoute";
import SubHeaderRoute from "../../router/subRoutes/SubHeaderRoute";
import { useScreenshot } from "use-screenshot-hook";
import {
  refreshApi,
  refreshFilters,
  setDownloadViewAll,
} from "../../redux/filters/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/rootStore";
import {
  setIsShowTopBanner,
  setRootPortalScreen,
} from "../../redux/baseScreen/baseScreenState/actions";
import { experiencePravid } from "../../redux/signUpFlow/productTour/actions";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";
import "./BaseScreen.scss";
import { useIdleTimer } from "react-idle-timer";
import { current } from "@reduxjs/toolkit";
import { GetCompletedCampaignApi } from "../../redux/completedCampaign/actions/completedCampaign.action";
import screenType from "../../constants/screenType";
import { AgreeToTerms } from "../../components/moduleComponents/signUpFlow/agreeToTermsPopUp/AgreeToTermsPopUp";
import { termsIcon } from "../../theme/assets/svg";
import Icon from "../../components/generic/icon/Icon";
import { agreeToTermsPopUp } from "../../redux/signUpFlow/signUp/action/signUp.action";
import {
  updateTourModuleWise,
  resetGetCallStatus,
} from "../../redux/signUpFlow/productTour/actions";
import { setIsScriptSelectVisited } from "../../redux/strategy/nudgeCreation/actions";
import Button from "../../components/generic/button/Button";
import { experienceNowIcon, redCrossNew } from "../../theme/assets/svg";
import ExperienceNow from "../../components/moduleComponents/signUpFlow/experienceNow/ExperienceNow";
import Loader from "../../components/agentDesktop/generic/loader/Loader";
import {
  getTeamSchema,
  getTeamsData,
  getTeamTabs,
  getUserData,
  getTeamChannels,
  getSidebarModules,
} from "../../redux/onboarding/login/actions";
import { setCurrentTeam } from "../../redux/profile/navigation/action/navigation.action";
import RootPortal2 from "../../utils/rootPortal2/RootPortal2";
import { resetExperienceNow } from "../../redux/signUpFlow/signUp/action";
import { TermsOfService } from "../../components/moduleComponents/signUpFlow/agreeToTermsPopUp/termsOfService/TermsOfService";
import { PrivacyPolicy } from "../../components/moduleComponents/signUpFlow/agreeToTermsPopUp/privacyPolicy/PrivacyPolicy";
import { getMyPlanAPIData } from "../../redux/profile/billing/myPlan/actions";
import { INACTIVE_AUTO_LOGOUT_TIME } from "../../constants";
import {
  setIsMaiaPopUp,
  setInitialExperienceNowCount,
  setExperienceNowCount,
} from "../../redux/signUpFlow/signUp/action/signUp.action";
import { ACCOUNT_TYPE } from "../../constants";
import { useCurrentPageTab } from "../../hooks";
import { Alert, Button as AlertBtn, Space } from "antd";
import { getMaiaPaymentStatus } from "../../redux/profile/billing/orderSummary/action/orderSummary.action";
import { TopBanner } from "../../components/generic/topBanner/TopBanner";
export default function BaseScreen() {
  /*****************************  redux hooks  *****************************/
  const dispatch = useDispatch();
  const defaultuser = useSelector((state: any) => {
    return state.loginReducer.userLoginInfo.accountDetails?.[0]?.name;
  });
  const currentUser = useSelector((state: RootState) => {
    return state.filterReducers.selectedFilterOptions?.clientName;
  });
  const userId = useSelector((state: any) => {
    return state.loginReducer.userLoginInfo.userDetail._id;
  });
  const teamsData = useSelector(
    (store: RootState) => store.loginReducer.teamData?.data
  );
  const downloadAnalyticsState = useSelector(
    (state: RootState) => state.filterReducers.downloadViewAll
  );
  const loginUserId = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.userDetail?._id
  );
  const startTour = useSelector((state: any) => {
    return state.signUpModuleReducer?.productTourReducer?.startTour;
  });
  const activePage = useSelector(
    (store: RootState) => store?.loginReducer?.isActivePageType
  );
  const rootPortal = useSelector(
    (store: RootState) => store?.baseScreen?.baseScreenState?.rootPortalScreen
  );
  const agreeToTerms = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.signUp["agreeToTermsState"]
  );
  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );
  const accountRole = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo?.userDetail?.role
  );
  const showExpNowModal = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productTourReducer["experiencePravid"]
  );
  const currentSelectedTeamId = useSelector(
    (store: RootState) => store.loginReducer?.currentTeam?.id
  );
  // initial data required
  const teamData = useSelector(
    (store: RootState) => store.loginReducer.teamData
  );
  const teamSchema = useSelector(
    (store: RootState) => store.loginReducer.teamSchemaData
  );
  const teamTab = useSelector(
    (store: RootState) => store.loginReducer.teamTabs
  );
  const teamChannel = useSelector(
    (store: RootState) => store.loginReducer.teamChannels
  );
  const sideBarModuleData = useSelector(
    (store: RootState) => store.loginReducer.sidebarModules
  );
  const accessToken = useSelector(
    (store: any) =>
      store?.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  // console.log(sideBarModuleData, "kkkk");
  const organizationInfo: any = useSelector(
    (store: RootState) => store?.profile?.yourAccountReducer?.organization
  );
  const loginUserDetail = useSelector(
    (store: any) => store.loginReducer?.userData
  );
  const isSignUpFlow = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo.accountDetails?.[0].isSignUpFlow
  );
  const isMaiaPopUpOpenTrue = useSelector(
    (store: RootState) => store?.signUpModuleReducer?.signUp["isMaiaPopUpOpen"]
  );
  const { channelTab, subModuleTab, isActivePageType } = useCurrentPageTab();

  const isLoadingOrgData = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization
        .isLoadingOrganizationDetails
  );

  const initialExperienceNowCount = useSelector(
    (store: any) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0].experienceCount
  );
  const accountType = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo?.accountDetails?.[0]?.type
  );

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
  );

  const { showBanner, bannerMsg } = useSelector(
    (store: RootState) => store?.baseScreen?.baseScreenState
  );
  const getMaiPaymentStatus: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.orderSummary?.getmaiaPaymentStatus
  );
 
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": accessToken,
  };
  const isScriptSelectVisited = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.isScriptSelectVisted;
  });

  /*****************************  useState  *****************************/
  const [isProductPage, setIsProductPage] = useState<boolean>(false);
  const [termsModalShow, setTermsModalShow] = useState<any>(true);
  const [isHideExperienceBtn, setIsHideExperienceBtn] = useState(false);
  const [gigaLoader, setGigaLoader] = useState(true);

  /*****************************  other hooks  *****************************/
  const screenRef = useRef(null);
  const refDash = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: INACTIVE_AUTO_LOGOUT_TIME,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500,
  });
  const { image, takeScreenshot, isLoading, clear } = useScreenshot({
    ref: screenRef,
  });

  /*****************************  useEffects  *****************************/
  useEffect(() => {
    dispatch(refreshApi(accountName));
  }, [accountName]);

  useEffect(() => {
    const interval = setInterval(getCampaingApiCall, 1000 * 60 * 60);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // call every hour
  useEffect(() => {
    if (downloadAnalyticsState === "InActive") {
      //
    } else {
      handleCaptureClick();
      dispatch(setDownloadViewAll("InActive"));
      // takeScreenshot()
      //   .then(downloadAnalytics)
      //   .then(() => dispatch(setDownloadViewAll("InActive")));
    }
  }, [downloadAnalyticsState]);

  useEffect(() => {
    dispatch(getSidebarModules());
  }, []);

  useEffect(() => {
    if (currentUser?.[0]?.id) {
      // console.log("62623eca3b3b877936e3bcf2", "username", currentUser?.[0]?.id);
      dispatch(
        getTeamsData({
          accountId: currentUser?.[0]?.id,
        })
      );

      /**getting Plan Details */
      dispatch(getMyPlanAPIData({ accountId: currentUser?.[0]?.id }));
      /** */
      //  dispatch(getTeamsData({ userId: userId as string }));
    } else dispatch(getTeamsData({ userId: userId as string }));
  }, [currentUser?.[0]?.id]);

  useEffect(() => {
    let teamExist;
    if (currentSelectedTeamId) {
      teamExist = teamsData?.find(
        (item: Record<string, any>) => item.id === currentSelectedTeamId
      );
    }
    if (teamsData?.length > 0 && !teamExist) {
      dispatch(setCurrentTeam(teamsData?.[0]));
    }
  }, [teamsData]);

  useEffect(() => {
    if (currentSelectedTeamId) {
      if (currentUser?.[0]?.id) {
        dispatch(
          getTeamSchema(
            "team",
            currentSelectedTeamId,
            currentSelectedTeamId,
            accountType === ACCOUNT_TYPE.internal
          )
        );
      } else
        dispatch(
          getTeamSchema(
            "user",
            userId,
            currentSelectedTeamId,
            accountType === ACCOUNT_TYPE.internal
          )
        );
      dispatch(getTeamTabs(currentSelectedTeamId));
      dispatch(getTeamChannels(currentSelectedTeamId));
    }
  }, [currentSelectedTeamId]);

  useEffect(() => {
    if (
      !teamData.isLoading &&
      !teamSchema.isLoading &&
      !teamTab.isLoading &&
      !teamChannel.isLoading &&
      !sideBarModuleData.isLoading
    ) {
      setGigaLoader(false);
    } else {
      setGigaLoader(true);
    }
  }, [
    teamData.isLoading,
    teamSchema.isLoading,
    teamTab.isLoading,
    teamChannel.isLoading,
    sideBarModuleData.isLoading,
  ]);

  useEffect(() => {
    if (rootPortal) {
      dispatch(setRootPortalScreen(""));
    }
  }, [activePage]);

  useEffect(() => {
    getCampaingApiCall();
  }, [currentUser, defaultuser]);

  useEffect(() => {
    if (initialExperienceNowCount === undefined) {
      dispatch(setInitialExperienceNowCount(0));
      dispatch(setExperienceNowCount(0));
    } else {
      dispatch(setInitialExperienceNowCount(initialExperienceNowCount));
      dispatch(setExperienceNowCount(initialExperienceNowCount));
    }
  }, [initialExperienceNowCount]);

  useEffect(() => {
    if (location.pathname == "/productPage") {
      setIsProductPage(true);
    } else {
      setIsProductPage(false);
    }
  }, [isProductPage, location]);

  useEffect(() => {
    if (
      !location.pathname.includes("/nudgecreation") &&
      !location.pathname.includes("/scriptselection") &&
      !location.pathname.includes("/scriptconfigscreen") &&
      isScriptSelectVisited
    ) {
      dispatch(setIsScriptSelectVisited(false));
    }
  }, [isScriptSelectVisited, location]);

  useEffect(() => {
    setTermsModalShow(agreeToTerms);
  }, [agreeToTerms]);

  useEffect(() => {
    if (
      location.pathname.includes("/profile") ||
      location.pathname === "/productPage"
    ) {
      setIsHideExperienceBtn(true);
    } else {
      setIsHideExperienceBtn(false);
    }
    if (location.pathname === "/userlist") {
      if (
        isMaiaPopUpOpenTrue === true ||
        !loginUserDetail?.productShowcase?.includes("Team Page")
      ) {
        setIsHideExperienceBtn(true);
      } else {
        setIsHideExperienceBtn(false);
      }
    }
    if (location.pathname === "/dashboard") {
      if (isMaiaPopUpOpenTrue === true) {
        setIsHideExperienceBtn(true);
      } else {
        setIsHideExperienceBtn(false);
      }
    }
    // if (location.pathname === "/calllogger") {
    //   if (isMaiaPopUpOpenTrue === true) {
    //     setIsHideExperienceBtn(true);
    //   } else {
    //     if (!loginUserDetail?.productShowcase?.includes("Logger")) {
    //       setIsHideExperienceBtn(true);
    //     } else {
    //       setIsHideExperienceBtn(false);
    //     }
    //     setIsHideExperienceBtn(false);
    //   }
    // }
    if (location.pathname === "/campaignmanager") {
      if (isMaiaPopUpOpenTrue === true) {
        setIsHideExperienceBtn(true);
      } else {
        setIsHideExperienceBtn(false);
      }
    }
  }, [isHideExperienceBtn, location, isMaiaPopUpOpenTrue, loginUserDetail]);

  useEffect(() => {
    if (window.location.pathname === "/userlist" && !teamData.data?.length) {
      dispatch(getTeamsData({ userId: userId as string }));
    }
  }, [window.location.pathname]);

  // rightSide bar control
  useEffect(() => {
    if (isActivePageType === "Campaign") return;
    if (channelTab?.name && subModuleTab?.name) {
      if (
        refDash?.current?.channelTab !== channelTab?.name ||
        refDash?.current?.subModuleTab !== subModuleTab?.name ||
        refDash?.current?.selectedClient !== currentUser?.[0].name
      ) {
        dispatch(refreshFilters());
      }
      refDash.current = {
        channelTab: channelTab?.name,
        subModuleTab: subModuleTab?.name,
        selectedClient: currentUser?.[0]?.name,
      };
    }
  }, [channelTab?.name, subModuleTab?.name, currentUser?.[0]?.name]);
  /*****************************  handlers  *****************************/
  function handleOnActive(event: any) {
    getRemainingTime();
  }

  const handleShowExperienceModal = (data: boolean) => {
    dispatch(experiencePravid(data));
    dispatch(resetExperienceNow());
    dispatch(resetGetCallStatus());
  };

  function handleOnIdle() {
    getLastActiveTime();
    navigate("/signout");
  }

  const handleCaptureClick = useCallback(async () => {
    const fullScreenElement = document.getElementById("fullscreen")!;
    if (!fullScreenElement) return;
    const copiedFullScreenElement = fullScreenElement.cloneNode(
      true
    ) as HTMLElement;
    copiedFullScreenElement.style.position = "fixed";
    copiedFullScreenElement.style.height = "auto";

    const printChart = copiedFullScreenElement.querySelectorAll(
      'div[data-id="chartViewOnPrint"]'
    );
    printChart.forEach((x: any) => {
      x.style.width = "100%";
      x.style.paddingLeft = "20px";
      x.style.paddingRight = "20px";
      x.style.background = "#f8f9fa";
      x.style.height = "100%";
    });

    document.body.append(copiedFullScreenElement);
    const canvas = await html2canvas(copiedFullScreenElement, {
      useCORS: true,
      scale: 3,
    });

    copiedFullScreenElement.remove();

    const dataURL = canvas.toDataURL("image/png", 1.0);
    const pdf = new jsPDF();
    const imgProps = pdf.getImageProperties(dataURL);
    const width = pdf.internal.pageSize.getWidth();
    const ratio = width / imgProps.width;
    const height = ratio * imgProps.height;
    pdf.internal.pageSize.height = height;
    pdf.addImage(dataURL, "PNG", 0, 0, width, height);
    pdf.save("AnalyticsScreen.pdf");
  }, []);

  /*****************************  helper function  *****************************/
  // get calender data to highlight
  function getCampaingApiCall() {
    dispatch(
      GetCompletedCampaignApi({
        clientName: currentUser ? currentUser[0]?.name : defaultuser,
      })
    );
  }

  // for updating accept terms and condition
  const onClickUpdateTermsAndCondition = () => {
    setTermsModalShow(false);
    dispatch(agreeToTermsPopUp(false));
    const body = {
      userId: loginUserId,
      isTermsAndConditionsAccepted: true,
    };
    dispatch(updateTourModuleWise(body));
  };

  useEffect(() => {
    dispatch(getMyPlanAPIData({ accountId: accountId }));
  }, []);

  useEffect(() => {
    const body = {
      params: {
        accountId: accountId,
      },
      headers: headers,
    };
    accountId && dispatch(getMaiaPaymentStatus(body));
  }, [accountId]);

  const handlePayNow = () => {
    navigate("/profile/billing");
  };
  console.log("hey", organizationInfo , gigaLoader);
  /*****************************  JSX  *****************************/
  return (
    <>
      {gigaLoader ||
      (window.location.pathname === "/userlist" && !teamData.data?.length) ? (
        <div className="baseScreenLoader">
          <Loader></Loader>
        </div>
      ) : (
        <>
          <div className="wrapper__base-screen">
            <div className="header">
              <HeaderComponent />
            </div>

            <div className="base-screen__body">
              {isProductPage ? null : (
                <div className="base-screen__left-menu">
                  <LeftMenu />
                </div>
              )}
              <div
                id="fullscreen"
                className={`${"base-screen__center"}`}
                ref={screenRef}
              >
                {showBanner && (
                  <TopBanner
                    message={`${bannerMsg} Your Plan needs to be upgraded.`}
                    onClickClose={() => dispatch(setIsShowTopBanner(false))}
                  />
                )}
                {getMaiPaymentStatus?.data?.length > 0 ? (
                  <div className="notePayment">
                    <img className="imgCross" src={redCrossNew}></img>
                    We have not received payment for your invoice. Please *
                    <b className="payNow" onClick={handlePayNow}>
                      <u>pay now</u>
                    </b>
                    *
                  </div>
                ) : (
                  ""
                )}

                <div className="sub-header">
                  <SubHeaderRoute />
                </div>
                <div
                  className="dynamic-screen"
                  // style={
                  //   activePage !== screenType.analytics.name ||
                  //   activePage !== screenType.logger.name
                  //     ? { maxHeight: "90%" }
                  //     : {}
                  // }
                >
                  <Outlet />
                  {/* {!isHideExperienceBtn &&
                  isSignUpFlow &&
                  !showExpNowModal &&
                  !loginUserDetail?.isInvited &&
                  !startTour &&
                  !isLoadingOrgData &&
                 ( organizationInfo?.organizationDetails?.organizationName ===
                    undefined || organizationInfo?.organizationDetails?.gstNumber === undefined) ? (
                    <div className={"experienceNowBtnDiv"}>
                      <Button
                        text="Experience Now"
                        image_src_left={experienceNowIcon}
                        extraClass={"experienceBtnStyling"}
                        imgClass={"expImgStyling"}
                        onClick={() => handleShowExperienceModal(true)}
                      />
                    </div>
                  ) : null} */}
                </div>
              </div>
              <div className="base-screen__right-menu">
                <RightSideBarRoute />
              </div>
            </div>
          </div>
          {showExpNowModal && (
            <ExperienceNow
              show={showExpNowModal}
              close={() => {
                handleShowExperienceModal(false);
              }}
            />
          )}
          {!loginUserDetail?.isTermsAndConditionsAccepted && termsModalShow && (
            <AgreeToTerms
              show={termsModalShow}
              onClick={() => {
                onClickUpdateTermsAndCondition();
              }}
              headingContent={
                <>
                  <Icon img_src={termsIcon} extraClass={"iconExtraStyling"} />
                  <div className={"termsHeadingStyling"}>
                    Have you read our terms of service &amp; privacy policy?
                  </div>
                </>
              }
              termstoAgreeContent={
                <>
                  <div className={"termsSubHeaderStyling"}>
                    Terms of Service
                  </div>
                  <div className={"termsTextStyling"}>
                    <TermsOfService />
                  </div>
                  <div className={"termsSubHeaderStyling"}>Privacy Policy</div>
                  <div className={"termsTextStyling"}>
                    <PrivacyPolicy />
                  </div>
                </>
              }
            />
          )}
        </>
      )}
    </>
  );
}
