import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import styles from "./IntegrationScreen.module.scss";
import { IntegrationsComponent } from "../../components/moduleComponents/integration_V2.0/integrationsComponent/IntegrationsComponent";
import { CategoryComponent } from "../../components/moduleComponents/integration_V2.0/categoryComponent/CategoryComponent";
import { getAllIntegrations } from "../../redux/integration_V2.0/allIntegrations/actions";
import { SKELTON_DATA } from "../../constants/skeletonDummyData";
import { getAllCategories } from "../../redux/integration_V2.0/trueCaller/actions/trueCaller.actions";
import { getAllIndustry } from "../../redux/integration_V2.0/whatsApp/actions/whatsApp.actions";

export const IntegrationScreen = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer
        ?.isLoadingAllIntegrationsData
  );

  const integrationsData = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.allIntegrationsData
  );

  const integrationsDataError = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer
        ?.allIntegrationsDataError
  );

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const categories = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.trueCallerReducer?.allCategoryData
  );
  const industryList = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.whatsAppReducer?.allIndustryData
  );

  useEffect(() => {
    dispatch(getAllIntegrations({ accountId: accountId }));
    if (Object.keys(categories).length === 0) {
      dispatch(getAllCategories());
    }
    if (Object.keys(industryList).length === 0) {
      dispatch(getAllIndustry());
    }
  }, []);

  return (
    <div className={styles.integrationScreenWrapper}>
      {integrationsDataError?.length > 0 ? (
        <div className={styles.comingSoonDiv} id="auto_comingSoonDiv">
          <h1>This Feature is not available for your current plan.</h1>
        </div>
      ) : (
        <>
          <CategoryComponent
            isLoading={isLoading}
            categories={
              isLoading ? SKELTON_DATA.integrationCategory : integrationsData
            }
          />
          <IntegrationsComponent isLoading={isLoading} />
        </>
      )}
    </div>
  );
};
