import { parse, getHours, getMinutes } from "date-fns";

// Function to convert the input time to total minutes
export const convertToMinutes = (inputTime: string) => {
  const parsedTime = parse(inputTime, "HH:mm", new Date());
  const totalMinutes = getHours(parsedTime) * 60 + getMinutes(parsedTime);
  return totalMinutes;
};

// Function to convert total minutes back to time format
export const convertToTimeFormat = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
  return formattedTime;
};
