import React, { useState, useEffect } from "react";
import "./CampaignTable.scss";
import styles from "./Campaign.module.scss";
import clearCacheData from "../../../utils/clearCacheData";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jsonToCsv } from "../../../utils/jsonToCsv.js";
import { downloadCsvFile } from "../../../utils/downloadCsvFile";
import DeleteModal from "../../../components/generic/deleteModal/DeleteModal";
import {
  searchIcon,
  downloadIconForProgress,
  exclusionsListIcon,
} from "../../../theme/assets/svg";
import {
  campaignIcon,
  unArchiveBlueIcon,
  archiveBlackIcon,
} from "../../../theme/assets/svg/campaign_v3/index";
import { setCampaignAPIData } from "../../../redux/campaign_V2.0/createCampaign/actions";
import { clearAllCampaignData, terminateCampaign } from "../../../redux/campaign_V2.0/campaignTable/actions";
import closeIcon from "../../../theme/assets/svg/campaign_V2.0/closeIcon.svg";
import Button from "../../../components/generic/button/Button";
import TableSaarthiJp from "../../../components/moduleComponents/campaign_V2.0/CampaignTable/TableSaarthi";
import { tableConstants } from "../../../components/moduleComponents/campaign_V2.0/CampaignTable/tableConstant";
import DropDown from "../../../components/generic/dropdownsaarthi2/DropdownSaarthi";
import { setIsMaiaPopUp } from "../../../redux/signUpFlow/signUp/action/signUp.action";
import {
  getAllCampaignsData,
  setSelectedCampaignData,
} from "../../../redux/campaign_V2.0/campaignTable/actions/campaignTable.actions";
import {
  getAllClientData,
  setAllClientData,
} from "../../../redux/allClient/actions/allClient.action";
import {
  SERVER_URL_CONNECTOR,
  CAMPAIGN_URL,
  SCHEDULER_URL,
  SERVER_URL_BUCKETIZE,
} from "../../../services/ApiRoutes";
import { downArrowIconDown } from "../../../theme/assets/svg/campaign";
import DiallerSection from "../../../components/moduleComponents/campaign/diallerSection/DiallerSection";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import CallingListTable from "../../../components/moduleComponents/campaign_V2.0/callingListTable/CallingListTable";
import PauseCampaignReason from "../../../components/moduleComponents/campaign/pauseCampaignReason/PauseCampaignReason";
import ScheduleCampaignConfirm from "../../../components/moduleComponents/campaign/scheduleCampaignConfirm/ScheduleCampaignConfirm";
import InProgressPopup from "../../../components/moduleComponents/campaign_V2.0/inProgressPopup/InProgressPopup";
import "../../../components/moduleComponents/table/Table.css";
import { useDispatch, useSelector } from "react-redux";
import { mixpannelCampaignDetails } from "../../../redux/mixpanel/actions";
import Modal from "../../../components/generic/modal/Modal";
import PopUpModal from "../../../components/moduleComponents/campaign/popUpMoadal/PopUpModal";
import { Mixpanel } from "../../../utils/mixpanelSetup";
import SearchInputBox from "../../../components/generic/searchInputBox/SearchInputBox";
import Icon from "../../../components/generic/icon/Icon";
import { maxCompletedStep } from "../../../redux/campaign_V2.0/progressBar/actions";
import { getUploadedCSVInfoAPIData } from "../../../redux/campaign_V2.0/dataUpload/actions";
import { getCountOfStep } from "../../../utils/validators/validators";
import { PersonaPopUpModal } from "../../../components/moduleComponents/campaign_V2.0/personaPopUpModal/PersonaPopUpModal";
import { RootState } from "../../../redux";
import TourCampaign from "../../../components/moduleComponents/signUpFlow/tour/TourCampaign";
import {
  teamInviteGray,
  lockLight,
  campaignPageIcon,
} from "../../../theme/assets/svg";
import { HelpModal } from "../../../components/moduleComponents/signUpFlow/helpModal/HelpModal";
import { experiencePravid } from "../../../redux/signUpFlow/productTour/actions";
import { getCurrentTab } from "../../../redux/profile/yourAccount/organizationDetails/actions/organizationDetails.actions";
import {
  setSelectedFilterOptions,
  refreshFilters,
} from "../../../redux/filters/actions";
import {
  setSelectedChannelTab,
  setSelectedSubmoduleTab,
} from "../../../redux/dashboard/actions";
import { store } from "../../../redux/rootStore";
import { ROLES } from "../../../constants";
import ReactPaginate from "react-paginate";
import { checkTechHealth } from "../../../redux/campaign/actions";
import { CalendarComponent } from "../../../components/moduleComponents/campaign_V2.0/calendarComponent/CalendarComponent";
import { DemoDropdownComp } from "../../../components/moduleComponents/demoDropdownComponent/DemoDropdownComp";
import {
  setLoggedInUserInfo,
  setIsPageType,
} from "../../../redux/onboarding/login/actions";
import {
  storeDataForAnalytics,
  storeAccountInfo,
  storeSelectedUsecase,
} from "../../../redux/campaign/scheduler/actions";
import { setDateFilterData } from "../../../redux/filter/actions";
import {
  setFilteredDateRangeCamapign,
  setSelectedCampaignCredentials,
  setCreatedCampaignIdNameEdit,
  setIsCampaignCreate,
  setCampaignEditOrCreateType,
  setUpdateModeOmni,
  setCamapignSelectedData,
  setAllCampaignChannelData,
  setCampaignClientName,
  setParentCampaignListId,
  moveToAnalyticsWithCampaign,
  setCallingListData,
  setCallingListScreen,
} from "../../../redux/campaign/actions";
import {
  tabSelected,
  setSelectedCallingData,
} from "../../../redux/breadcrum/actions/breadcrum.actions";
import { getAllIntegrations } from "../../../redux/integration_V2.0/allIntegrations/actions";
import { getMyPlanAPIData } from "../../../redux/profile/billing/myPlan/actions";
import ManyFileStatus from "../../../components/moduleComponents/campaign_V2.0/manyFileUploadStatus/ManyFileStatus";

let cancelTokenRD: any;
function Campaign() {
  const [bodyData, setBodyData] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
  const [editData, setEditData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>("");
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [paginationData, setPaginationData] = useState<any>([]);
  const [searchData, setSearchData] = useState("");
  const [showScreen, setShowScreen] = useState<any>(false);
  const [selecteDetails, setSelectDetails] = useState<any>({});
  const [clientList, setClientList] = useState<any>([]);
  const [selectedClientName, setSelectedClientName] = useState<any>(null);
  const [resetClientList, setResetClientList] = useState<any>([]);
  const [searchClientName, setSearchClientName] = useState<any>([]);
  const [initialBodyData, setInitialBodyData] = useState([]);
  const [load, setLoad] = useState<any>("Loaded");
  const [showEditPage, setShowEditPage] = useState(false);
  const [showChildId, setShowChildId] = useState<any>([]);
  const [originalData, setOriginalData] = useState([]);
  const [clickedArchieved, setClickedArchieved] = useState(false);
  const [showClient, setShowClient] = useState(false);
  const [pauseModalShow, setPauseModalShow] = useState(false);
  const [pauseId, setPauseId] = useState<any>();
  const [scheduleModal, setScheduleModal] = useState(false);
  const [callingListInfo, setCallingListInfo] = useState();
  const [progressModal, setProgressModal] = useState(false);
  const [scheduleCampaignData, setScheduleCampaignData] = useState();
  const [infoId, setInfoId] = useState();
  const [closeArrowList, setCloseArrowList] = useState(false);
  const [dateFil, setDateFil] = useState<any>();
  const [labelParentId, setlabelParentId] = useState<any>([]);
  const [datauploadModalId, setDatauploadModalId] = useState();
  const [datauploadModalData, setDatauploadModalData] = useState<any>();
  const [storeUploadData, setStoreUploadData] = useState<any>();
  const [disableBtnAddPause, setDisableBtnAddPause] = useState<boolean>();
  const [personaModal, setPersonaModal] = useState(false);
  const [dataStatus, setdataStatus] = useState<any>();
  const [lockedCreateBtn, setLockedCreateBtn] = useState(false);
  const [showMaiaPopUp, setShowMaiaPopUp] = useState(false);
  const [isExpNowPopUp, setIsExpNowPopUp] = useState(false);
  const [allClientDataWithId, setAllClientDataWithId] = useState<any[]>([]);
  const [clientIdFromFilter, setClientIdFilter] = useState<any>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSignUpFlow = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo.accountDetails?.[0].isSignUpFlow
  );
  const currentUser = useSelector((state: RootState) => {
    return state.filterReducers.selectedFilterOptions?.clientName;
  });

  const accountAllInfo = useSelector(
    (store: RootState) => store?.schedulerReducer?.accountInfoDetails
  );

  const organizationInfo = useSelector(
    (store: RootState) => store?.profile?.yourAccountReducer?.organization
  );

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const loginUserDetail = useSelector(
    (store: RootState) => store.loginReducer?.userData
  );

  const mainClientName = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userDetail?.accountDetails[0]?.name
  );

  const accountType = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.accountDetails[0]?.type
  );

  const logInrole = useSelector(
    (store: RootState) => store.loginReducer?.userLoginInfo?.userDetail?.role
  );

  const teamUseCaseId = useSelector(
    (store: RootState) => store?.loginReducer?.currentTeam?.useCaseId
  );
  const currentSelectedTeamId = useSelector(
    (store: RootState) => store.loginReducer?.currentTeam?.id
  );

  const healthData: any = useSelector(
    (store: RootState) => store?.campaignReducer?.techHealthData?.data?.data
  );

  const newHealth = healthData?.map((e: any) => {
    return e?.["_settledValueField"];
  });

  const filteredDateRangeData = useSelector(
    (store: RootState) => store.filterReducer?.filteredDateRangeData
  );

  const moveToCallingList = useSelector(
    (store: RootState) => store.breadcrumReducer?.goToCallingList
  );

  const {
    campaignIdNameEdit: campaignIdNameEdit,
    campaignClientName: clientNameRedux,
    datefiltercampaign: dateFilterCampaign,
    showCallingListScreen: showCallingListScreen,
    callingListData: callingListDataAll,
  } = useSelector((store: RootState) => store.campaignReducer);

  const userLoginInfo = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo
  );
  const integrationsData: any = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.allIntegrationsData
  );
  const accountDetailsRedux = userLoginInfo?.accountDetails[0];
  const integartionStatus = integrationsData
    ?.filter((d: any) => d?.categoryName === "Others")?.[0]
    ?.categoryData?.filter(
      (e: any) => e?.integrationName === "Telephony, Ports & DID"
    )?.[0];

  console.log("integration Data ", integartionStatus, accountDetailsRedux);

  /**** for schema update based on client ***/
  const clientName = clientNameRedux ? clientNameRedux : mainClientName;

  const headers: any = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const stepsTotal: string[] = useSelector(
    (state: RootState) =>
      state.loginReducer.userLoginInfo.accountDetails[0].campaignProgressBar
  );

  // const stepsTotal = [
  //   "Campaign Details",
  //   "Data Upload",
  //   "Strategy Selection",
  //   "Agent Configuration",
  //   "Scheduler Setting",
  // ];

  // redux data
  const mixpannelCampaignDetail = useSelector((store: any) => {
    return store?.mixpanelReducer?.campaignDetailds;
  });

  // for showing maia pop up during tour
  useEffect(() => {
    setShowMaiaPopUp(true);
    dispatch(setIsMaiaPopUp(true));

    const body = {
      params: {
        useCaseId: teamUseCaseId,
        type: "Campaign Manager",
      },
      headers: headers,
    };
    dispatch(checkTechHealth(body));
    //for schema client name
    if (currentUser?.[0]?.name?.length) {
      setSelectedClientName(currentUser?.[0]?.name);
      const clientWithIDName = allClientDataWithId?.find(
        (e) => e?.name === currentUser?.[0]?.name
      );
      console.log("client", clientWithIDName);
      setClientIdFilter(clientWithIDName?.id);
    }
  }, []);

  // for  showing Locked Campaign create btn
  useEffect(() => {
    if (organizationInfo) {
      setLockedCreateBtn(
        !isSignUpFlow
          ? true
          : Object.keys(organizationInfo?.organizationDetails)?.includes(
            "gstNumber"
          )
            ? true
            : false
      );
    }
  }, [organizationInfo]);

  useEffect(() => {
    if (accountType === "External") {
      setSelectedClientName(mainClientName);
    } else if (
      logInrole !== ROLES.campaignManager.name &&
      accountType === "Internal"
    ) {
      setSelectedClientName(mainClientName);
    }
  }, [mainClientName]);

  //for accountId
  useEffect(() => {
    if (selectedClientName?.length && allClientDataWithId?.length) {
      const clientWithIDName = allClientDataWithId?.find(
        (e) => e?.name === selectedClientName
      );
      setClientIdFilter(clientWithIDName?.id);
    }
  }, [allClientDataWithId, selectedClientName]);

  useEffect(() => {
    if (clientNameRedux?.length) {
      const clientWithIDName = allClientDataWithId?.find(
        (e) => e?.name === clientNameRedux
      );
      setClientIdFilter(clientWithIDName?.id);
      setSelectedClientName(() => clientNameRedux);
    }
  }, [
    clientNameRedux,
    !showCreate,
    !showScreen,
    !showDeleteModal,
    !showEditPage,
    !showClient,
  ]);

  useEffect(() => {
    if (moveToCallingList == "true") {
      setShowCreate(false);
      setShowEditPage(false);
      setTimeout(() => {
        getAllTableDataCampaignupdatedStatus();
      }, 3000);
    }
  }, [moveToCallingList]);

  useEffect(() => {
    getAllTableDataCampaignupdatedStatus();
    setSearchData("");
  }, [!showCreate]);

  useEffect(() => {
    getAllTableDataCampaign();
  }, [
    selectedClientName,
    !showScreen,
    !showDeleteModal,
    filteredDateRangeData?.fromDate,
    filteredDateRangeData?.toDate,
    !showEditPage,
    pageNo,
    searchData?.length,
    !clickedArchieved,
    !scheduleModal,
    clientIdFromFilter,
  ]);

  //to maintained filter Date
  useEffect(() => {
    if (dateFil) {
      setFilteredDateRangeCamapign({
        fromDate: dateFil?.fromDate,
        toDate: dateFil?.toDate,
      })(dispatch);
      setPageNo(1);
    }
  }, [dateFil]);

  useEffect(() => {
    if (!showCallingListScreen) {
      if (!showCreate) {
        if (dateFilterCampaign) {
          const from_date = format(
            new Date(dateFilterCampaign?.fromDate),
            "yyyy-MM-dd"
          );

          const to_date = format(
            new Date(dateFilterCampaign?.toDate),
            "yyyy-MM-dd"
          );
          setDateFilterData({ fromDate: from_date, toDate: to_date })(dispatch);
        } else {
          const today = format(new Date(), "yyy-MM-dd");
          const from_date = today;
          const to_date = today;
          setDateFilterData({ fromDate: from_date, toDate: to_date })(dispatch);
        }
      }
    }
  }, [showCreate]);

  useEffect(() => {
    getPagination();
  }, [bodyData, pageNo]);

  useEffect(() => {
    if (!showChildId?.length) {
      setCloseArrowList((prev) => false);
    }
  }, [showChildId]);

  useEffect(() => {
    if (labelParentId?.length) {
      const temp: any = [];
      paginationData?.map((e: any) => {
        if (labelParentId?.includes(e.id)) {
          const arrId = paginationData?.map((e: any) => e.id);
          temp.push(e);
          if (e?.children) {
            const child = e?.children;
            child.map((id: any) => {
              if (showChildId?.includes(id.id)) {
                temp.push(id);
                if (id?.children) {
                  const childsub = id?.children;
                  childsub?.map((d: any) => {
                    temp.push(d);
                  });
                }
              } else if (!arrId?.includes(id?.id)) {
                temp.push(id);
              }
            });

            // temp.push(...e.children)
          }
        } else {
          temp.push(e);
        }
      });
      setPaginationData(temp);
    } else {
      setPaginationData(originalData);
    }
  }, [labelParentId, showChildId, originalData]);

  useEffect(() => {
    if (clickedArchieved) {
      getAllArchievedTableData();
    }
  }, [clickedArchieved, searchData, selectedClientName, pageNo]);

  // resetDate
  useEffect(() => {
    if (!showCallingListScreen) {
      //   let  start =  new Date()
      //  let end = new Date()
      // props.setDateFilterData({ fromDate: start, toDate: end });
      if (dateFilterCampaign) {
        const from_date = format(
          new Date(dateFilterCampaign?.fromDate),
          "yyyy-MM-dd"
        );

        const to_date = format(
          new Date(dateFilterCampaign?.toDate),
          "yyyy-MM-dd"
        );

        setDateFilterData({ fromDate: from_date, toDate: to_date })(dispatch);
      } else {
        const today = format(new Date(), "yyy-MM-dd");
        const from_date = today;
        const to_date = today;
        setDateFilterData({ fromDate: from_date, toDate: to_date })(dispatch);
      }
    }
  }, [showCallingListScreen]);

  useEffect(() => {
    const url = `${SCHEDULER_URL.GET_ALL_DETAILS_CLIENT}${clientName}`;
    axios.get(url).then((res) => {
      storeAccountInfo(res?.data?.data)(dispatch);
    });
  }, [clientName]);

  useEffect(() => {
    /***for getting all infos  */
    dispatch(getAllIntegrations({ accountId: accountAllInfo?.id }));
    /**for getting Plan details */
    dispatch(getMyPlanAPIData({ accountId: accountAllInfo?.id }));

    if (clientNameRedux?.length && clientNameRedux !== currentUser?.[0]?.name) {
      dispatch(
        setSelectedFilterOptions({
          type: "clientName",
          options: [
            {
              id: accountAllInfo?.id,
              name: accountAllInfo?.name,
              type: accountAllInfo?.type,
            },
          ],
        })
      );
    }
  }, [accountAllInfo]);

  //===========

  useEffect(() => {
    let arrData: any[] = [];
    axios
      .get(
        `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/account/v1/distinctAccount`,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.data) {
          arrData = res.data.data;
          let filteredData = arrData.map((each: any) => {
            return each?.name;
          });
          setAllClientDataWithId(arrData);
          setClientList(["Select All", ...filteredData]);
          setResetClientList(["Select All", ...filteredData]);
          dispatch(setAllClientData(res.data.data));
        }
      });
    tabSelected("")(dispatch);
    if (currentUser?.[0]?.name) {
      const clientWithIDName = arrData?.find(
        (e) => e?.name === currentUser?.[0]?.name
      );
      console.log("client --", clientWithIDName);
      setClientIdFilter(clientWithIDName?.id);
    }
  }, [teamUseCaseId]);

  useEffect(() => {
    if (!showCallingListScreen) {
      if (!showEditPage) {
        if (dateFilterCampaign) {
          const from_date = format(
            new Date(dateFilterCampaign?.fromDate),
            "yyyy-MM-dd"
          );

          const to_date = format(
            new Date(dateFilterCampaign?.toDate),
            "yyyy-MM-dd"
          );
          setDateFilterData({ fromDate: from_date, toDate: to_date })(dispatch);
        } else {
          const today = format(new Date(), "yyy-MM-dd");
          const from_date = today;
          const to_date = today;
          setDateFilterData({ fromDate: from_date, toDate: to_date })(dispatch);
        }
      }
    }
  }, [showEditPage]);

  useEffect(() => {
    dispatch(getAllCampaignsData(bodyData));
  }, [bodyData]);

  //==================

  const handleClickLockedButton = () => {
    setShowMaiaPopUp(true);
    dispatch(setIsMaiaPopUp(true));
  };

  const handleExclusionListBtnClick = () => {
    navigate("/exclusions-list-client");
  };

  const getPagination = () => {
    bodyData?.sort((a: any, b: any) => {
      return a.index - b.index;
    });
    const tempData = bodyData;
    setPaginationData(tempData);
    setOriginalData(tempData);
  };

  const pushToCreate = () => {
    setSelectedCallingData([])(dispatch);
    setIsCampaignCreate(true)(dispatch);
    setCampaignEditOrCreateType("create")(dispatch);

    let temp = showCreate;
    setShowCreate(!temp);

    setUpdateModeOmni("create")(dispatch);
    setCamapignSelectedData()(dispatch);
    setAllCampaignChannelData()(dispatch);
    storeSelectedUsecase({ id: teamUseCaseId })(dispatch);
    navigate("/campaignmanager/campaigncreation");

    // for mixpannel
    const createCount = mixpannelCampaignDetail?.createCampaignButtonCount
      ? mixpannelCampaignDetail?.createCampaignButtonCount
      : 0;
    const increasedCount = Number(createCount) + 1;
    const newCopyObj = mixpannelCampaignDetail ? mixpannelCampaignDetail : {};
    newCopyObj["createCampaignButtonCount"] = increasedCount;
    dispatch(mixpannelCampaignDetails(newCopyObj));
    Mixpanel.track(
      "Campaign | Campaign Manager_Create Campaign buttton | Clicks",
      {
        "user Name": mainClientName,
        Date: format(new Date(), "dd-MM-yyyy"),
        Click: "Create Campaign Button",
        Count: increasedCount,
      }
    );
  };

  //for getting child Campaign
  const getChildCampaign = (id: any) => {
    if (showChildId?.includes(id)) {
      const tempArr: any = [...showChildId];
      const indexr = tempArr.indexOf(id);
      if (indexr > -1) {
        tempArr.splice(indexr, 1);
      }
      setShowChildId([...tempArr]);
      setCloseArrowList(false);
      setPaginationData(originalData);
    } else {
      setCloseArrowList((prev) => true);
      setPaginationData(originalData);
      setShowChildId([id]);
    }
  };

  const downloadCampaignData = () => {
    axios
      .get(
        `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/customerUploadInfo/v1/get?campaignManagerId=${campaignIdNameEdit.id}`,
        { headers: headers }
      )
      .then((res) => {
        if (res.status == 401) {
          navigate("/login");
          dispatch(setLoggedInUserInfo(userLoginInfo));
        }
        if (res.data && res.data.data) {
          const iddata = res.data.data;
          if (iddata.downloadablePath) {
            const data = { fileName: iddata.downloadablePath };
            axios
              .post(
                `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/callingInfo/v1/downloadCallingInfo`,
                data,
                headers
              )
              .then((res) => {
                if (res.status == 401) {
                  navigate("/login");
                  dispatch(setLoggedInUserInfo(userLoginInfo));
                }
                if (res.data) {
                  const dataToDownload = jsonToCsv(res.data);
                  downloadCsvFile(dataToDownload, "Campaign details.csv");
                }
              })
              .catch((err) => {
                if (err?.response?.status == 401) {
                  navigate("/login");
                  clearCacheData();
                  window.location.reload();
                  dispatch(setLoggedInUserInfo(userLoginInfo));
                }
              });
          }
        }
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          navigate("/login");
          clearCacheData();
          window.location.reload();
          dispatch(setLoggedInUserInfo(userLoginInfo));
        }
      });
  };

  const moveToEdit = (data: any) => {
    //for channel and two ids ----
    console.log(data, "00");
    dispatch(clearAllCampaignData());

    dispatch(setSelectedCampaignData(data));
    setEditData((prev) => data);

    dispatch(
      getUploadedCSVInfoAPIData({
        campaignManagerId: data?.id,
        userType: accountType,
        token: token,
      })
    );

    const res = {
      ...data,
      uploadedSuccessfully: data?.isFilePicked ? true : false,
    };
    dispatch(maxCompletedStep(getCountOfStep(data, stepsTotal)));
    dispatch(setCampaignAPIData({ data: res }));
    storeSelectedUsecase({ id: data?.useCaseId })(dispatch);
    navigate("/campaignmanager/campaigncreation");

    //mixpannel
    const createCount = mixpannelCampaignDetail?.editCampaignButtonCount
      ? mixpannelCampaignDetail?.editCampaignButtonCount
      : 0;
    const increasedCount = Number(createCount) + 1;
    const newCopyObj = mixpannelCampaignDetail ? mixpannelCampaignDetail : {};
    newCopyObj["editCampaignButtonCount"] = increasedCount;
    dispatch(mixpannelCampaignDetails(newCopyObj));
    Mixpanel.track("Campaign | Campaign Manager_Edit Campaign  | Clicks", {
      "user Name": mainClientName,
      Date: format(new Date(), "dd-MM-yyyy"),
      Click: "Edit Campaign Button",
      Count: increasedCount,
    });
    //
  };

  const deleteCampaignData = (data: any) => {
    setShowDeleteModal(true);
    setDeleteId({
      id: data.id,
      campaignId: data.campaignName ? data.campaignName : data.campaignId,
    });
    // setPageNo(pgno)
  };

  const showManualScreen = (data: any) => {
    const temp = showScreen;
    setShowScreen(!temp);
    setSelectDetails(data);
    setSelectedCampaignCredentials(data)(dispatch);
    setCreatedCampaignIdNameEdit({ id: data.id })(dispatch);
  };

  const moveToAnnalyticPage = (data: any) => {
    const obj = {
      id: data?.id,
      campaignName: data?.campaignName ? data?.campaignName : data?.campaignId,
      startDate: data?.startDate,
      endDate: data?.endDate,
      allInfo: data,
    };
    storeDataForAnalytics(obj)(dispatch);
    const channel = obj.allInfo?.channels?.[0];
    const channelsArr = store.getState().loginReducer.teamChannels.data;
    const channelObj = channelsArr.find((item: any) => item.name === channel);
    const moduleArr = store.getState().loginReducer.teamTabs.data;
    const moduleObj = moduleArr.find((item: any) => item.name === "Campaign");
    dispatch(setSelectedChannelTab(channelObj));
    dispatch(setSelectedSubmoduleTab(moduleObj));
    dispatch(setIsPageType("analytics dashboard"));
    setTimeout(() => {
      navigate("/dashboard");
      setTimeout(() => dispatch(moveToAnalyticsWithCampaign(obj)), 500);
    }, 0);
    window.sessionStorage?.setItem("isActive", "map");
    sessionStorage?.setItem("pageType", "analytics dashboard");
    dispatch(refreshFilters(true));
    //  props.setIsActivePageType("analytics dashboard")
    //  props.setIsPageType("analytics dashboard")
  };
  const moveToCallingListPage = (data: any) => {
    setCallingListInfo((prev) => data);
    setShowClient((prev) => true);
    setCallingListData(data)(dispatch);
    setCallingListScreen(true)(dispatch);
    setDateFilterData({ fromDate: null, toDate: null })(dispatch);
    navigate("/campaignmanager/callinglist");
  };

  const getAllTableDataCampaign = () => {
    let fromDate = format(
      filteredDateRangeData?.fromDate
        ? new Date(filteredDateRangeData?.fromDate)
        : new Date(),
      "yyyy-MM-dd"
    );
    let toDate = format(
      filteredDateRangeData?.toDate
        ? new Date(filteredDateRangeData?.toDate)
        : new Date(),
      "yyyy-MM-dd"
    );
    if (
      !selectedClientName?.length &&
      !clientNameRedux?.length &&
      accountType === "Internal" &&
      logInrole === ROLES.campaignManager.name &&
      !clickedArchieved
    ) {
      console.log("call if ");
      !showDeleteModal && setLoad("");
      if (typeof cancelTokenRD != typeof undefined) {
        cancelTokenRD.cancel("Operation canceled due to new request.");
      }
      cancelTokenRD = axios.CancelToken.source();
      const getCampaignListUrl = `${SERVER_URL_CONNECTOR}${CAMPAIGN_URL.ALL_CAMPAIGN_LIST}`;
      axios
        .get(getCampaignListUrl, {
          cancelToken: cancelTokenRD.token,
          params: {
            after: fromDate,
            before: toDate,
            limit: 11,
            offset: pageNo,
            search: searchData,
            teamId: currentSelectedTeamId
          },
          headers: headers,
        })
        .then((res: any) => {
          if (res.data) {
            setBodyData(res.data.data?.campaigns);
            let arrSize = Math.ceil(res.data.data?.totalCount / 11);
            setTotalPage((prev) => arrSize);
            setInitialBodyData(res.data.data?.campaigns);
            setLoading(false);
            setLoad("Loaded");
          }
        })
        .catch((err: any) => {
          setLoad("Loaded");
          if (err?.response?.status == 401) {
            navigate("/login");
            clearCacheData();
            window.location.reload();
            dispatch(setLoggedInUserInfo(userLoginInfo));
          }
        });
    } else {
      if (selectedClientName == "Select All" && !clickedArchieved) {
        const getCampaignListUrl = `${SERVER_URL_CONNECTOR}${CAMPAIGN_URL.ALL_CAMPAIGN_LIST}`;
        !showDeleteModal && setLoad("");
        console.log("call else selectAll ");
        if (typeof cancelTokenRD != typeof undefined) {
          cancelTokenRD.cancel("Operation canceled due to new request.");
        }
        cancelTokenRD = axios.CancelToken.source();
        axios
          .get(getCampaignListUrl, {
            cancelToken: cancelTokenRD.token,
            params: {
              after: fromDate,
              before: toDate,
              limit: 11,
              offset: pageNo,
              search: searchData,
              teamId: currentSelectedTeamId
            },
            headers: headers,
          })
          .then((res: any) => {
            if (res.status == 401) {
              navigate("/login");
              dispatch(setLoggedInUserInfo(userLoginInfo));
            }

            if (res.data) {
              setBodyData(res.data.data?.campaigns);
              let arrSize = Math.ceil(res.data.data?.totalCount / 11);
              setTotalPage((prev) => arrSize);
              setInitialBodyData(res.data.data?.campaigns);
              setLoad("Loaded");
            }
          })
          .catch((err: any) => {
            setLoad("Loaded");
            if (err?.response?.status == 401) {
              navigate("/login");
              clearCacheData();
              window.location.reload();
              dispatch(setLoggedInUserInfo(userLoginInfo));
            }
          });
      } else {
        if (
          selectedClientName?.length &&
          clientIdFromFilter?.length &&
          !clickedArchieved
        ) {
          console.log("call else selected client ");
          const getCampaignListUrl = `${SERVER_URL_CONNECTOR}${CAMPAIGN_URL.ALL_CAMPAIGN_LIST}`;
          !showDeleteModal && setLoad("");
          if (typeof cancelTokenRD != typeof undefined) {
            cancelTokenRD.cancel("Operation canceled due to new request.");
          }
          cancelTokenRD = axios.CancelToken.source();
          axios
            .get(getCampaignListUrl, {
              cancelToken: cancelTokenRD.token,
              params: {
                after: fromDate,
                before: toDate,
                limit: 11,
                offset: pageNo,
                search: searchData,
                accountId: clientIdFromFilter,
                teamId: currentSelectedTeamId
              },
              headers: headers,
            })
            .then((res: any) => {
              if (res.status == 401) {
                navigate("/login");
                dispatch(setLoggedInUserInfo(userLoginInfo));
              }

              if (res.data) {
                setBodyData((prev) => res.data.data?.campaigns);
                let arrSize = Math.ceil(res.data.data?.totalCount / 11);
                setTotalPage((prev) => arrSize);
                setInitialBodyData((prev) => res.data.data?.campaigns);
                setLoad("Loaded");
              }
            })
            .catch((err: any) => {
              setLoad("Loaded");
              if (err?.response?.status == 401) {
                navigate("/login");
                clearCacheData();
                window.location.reload();
                dispatch(setLoggedInUserInfo(userLoginInfo));
              }
            });
        }
      }
    }
  };
  // for getting updated mapping status

  const getAllTableDataCampaignupdatedStatus = () => {
    let fromDate = format(
      filteredDateRangeData?.fromDate
        ? new Date(filteredDateRangeData?.fromDate)
        : new Date(),
      "yyyy-MM-dd"
    );
    let toDate = format(
      filteredDateRangeData?.toDate
        ? new Date(filteredDateRangeData?.toDate)
        : new Date(),
      "yyyy-MM-dd"
    );
    if (
      !selectedClientName?.length &&
      !clientNameRedux?.length &&
      accountType === "Internal" &&
      logInrole === ROLES.campaignManager.name &&
      !clickedArchieved
    ) {
      const getCampaignListUrl = `${SERVER_URL_CONNECTOR}${CAMPAIGN_URL.ALL_CAMPAIGN_LIST}`;
      if (typeof cancelTokenRD != typeof undefined) {
        cancelTokenRD.cancel("Operation canceled due to new request.");
      }
      cancelTokenRD = axios.CancelToken.source();
      axios
        .get(getCampaignListUrl, {
          cancelToken: cancelTokenRD.token,
          params: {
            after: fromDate,
            before: toDate,
            limit: 11,
            offset: pageNo,
            search: searchData,
            teamId: currentSelectedTeamId
          },
          headers: headers,
        })
        .then((res: any) => {
          if (res.data) {
            setBodyData(res.data.data?.campaigns);
            let arrSize = Math.ceil(res.data.data?.totalCount / 11);
            setTotalPage((prev) => arrSize);
            setInitialBodyData(res.data.data?.campaigns);
            // setLoading(false);
            // setLoad("Loaded");
          }
        })
        .catch((err: any) => {
          if (err?.response?.status == 401) {
            navigate("/login");
            clearCacheData();
            window.location.reload();
            dispatch(setLoggedInUserInfo(userLoginInfo));
          }
        });
    } else {
      if (selectedClientName == "Select All" && !clickedArchieved) {
        const getCampaignListUrl = `${SERVER_URL_CONNECTOR}${CAMPAIGN_URL.ALL_CAMPAIGN_LIST}`;
        if (typeof cancelTokenRD != typeof undefined) {
          cancelTokenRD.cancel("Operation canceled due to new request.");
        }
        cancelTokenRD = axios.CancelToken.source();

        // !showDeleteModal && setLoad();
        axios
          .get(getCampaignListUrl, {
            cancelToken: cancelTokenRD.token,
            params: {
              after: fromDate,
              before: toDate,
              limit: 11,
              offset: pageNo,
              search: searchData,
              teamId: currentSelectedTeamId
            },
            headers: headers,
          })
          .then((res: any) => {
            if (res.status == 401) {
              navigate("/login");
              dispatch(setLoggedInUserInfo(userLoginInfo));
            }

            if (res.data) {
              setBodyData(res.data.data?.campaigns);
              let arrSize = Math.ceil(res.data.data?.totalCount / 11);
              setTotalPage((prev) => arrSize);
              setInitialBodyData(res.data.data?.campaigns);
              // setLoad("Loaded");
            }
          })
          .catch((err: any) => {
            if (err?.response?.status == 401) {
              navigate("/login");
              clearCacheData();
              window.location.reload();
              dispatch(setLoggedInUserInfo(userLoginInfo));
            }
          });
      } else {
        if (selectedClientName?.length && !clickedArchieved) {
          const getCampaignListUrl = `${SERVER_URL_CONNECTOR}${CAMPAIGN_URL.ALL_CAMPAIGN_LIST}`;
          // !showDeleteModal && setLoad();
          if (typeof cancelTokenRD != typeof undefined) {
            cancelTokenRD.cancel("Operation canceled due to new request.");
          }
          cancelTokenRD = axios.CancelToken.source();
          axios
            .get(getCampaignListUrl, {
              cancelToken: cancelTokenRD.token,
              params: {
                after: fromDate,
                before: toDate,
                limit: 11,
                offset: pageNo,
                search: searchData,
                accountId: clientIdFromFilter,
                teamId: currentSelectedTeamId
              },
              headers: headers,
            })
            .then((res: any) => {
              if (res.status == 401) {
                navigate("/login");
                dispatch(setLoggedInUserInfo(userLoginInfo));
              }

              if (res.data) {
                setBodyData((prev) => res.data.data?.campaigns);
                let arrSize = Math.ceil(res.data.data?.totalCount / 11);
                setTotalPage((prev) => arrSize);
                setInitialBodyData((prev) => res.data.data?.campaigns);
                // setLoad("Loaded");
              }
            })
            .catch((err: any) => {
              if (err?.response?.status == 401) {
                navigate("/login");
                clearCacheData();
                window.location.reload();
                dispatch(setLoggedInUserInfo(userLoginInfo));
              }
            });
        }
      }
    }
  };

  // To get All Archieved Data table

  const getAllArchievedTableData = () => {
    const fromDate = format(
      new Date(filteredDateRangeData?.fromDate),
      "yyyy-MM-dd"
    );
    const toDate = format(
      new Date(filteredDateRangeData?.toDate),
      "yyyy-MM-dd"
    );
    let getCampaignListUrl =
      SERVER_URL_CONNECTOR + "" + CAMPAIGN_URL.ALL_CAMPAIGN_LIST + "?";

    if (selectedClientName?.length && selectedClientName !== "Select All") {
      getCampaignListUrl = `${getCampaignListUrl}accountId=${clientIdFromFilter}`;
    }

    getCampaignListUrl = `${getCampaignListUrl}&showArchived=true`;
    setLoad("");

    if (typeof cancelTokenRD != typeof undefined) {
      cancelTokenRD.cancel("Operation canceled due to new request.");
    }
    cancelTokenRD = axios.CancelToken.source();
    axios
      .get(getCampaignListUrl, {
        cancelToken: cancelTokenRD.token,
        params: {
          after: fromDate,
          before: toDate,
          limit: 11,
          offset: pageNo,
          search: searchData,
          teamId: currentSelectedTeamId
        },
        headers: headers,
      })
      .then((res) => {
        if (res.data) {
          setBodyData((prev) => res.data.data?.campaigns);
          console.log(res.data.data?.campaigns, "kkkk");
          const arrSize = Math.ceil(res.data.data?.totalCount / 11);
          setTotalPage((prev) => arrSize);
          setInitialBodyData((prev) => res.data.data?.campaigns);
          setLoad("Loaded");
        }
      });
  };

  //TO make archieve
  const handleArchieve = (id: string, data: any) => {
    const obj = {
      campaignId: id,
      archive: !data,
    };
    axios
      .patch(`${SERVER_URL_CONNECTOR}${SCHEDULER_URL.ARCHIEVE_API}`, obj, {
        headers: headers,
      })
      .then((res) => {
        if (!data) {
          getAllTableDataCampaign();
          toast.success(`Campaign archieved successfully!
                                        Note - This can be restore from archived tab. `);
        } else {
          toast.success("Campaign Unarchieved successfully!");
          getAllArchievedTableData();
        }
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          navigate("/login");
          clearCacheData();
          window.location.reload();
          dispatch(setLoggedInUserInfo(userLoginInfo));
        }
        toast.error("Campaign archieved  error!");
      });
  };

  //To Play and Pause the Campaign
  //for play
  const handlePlayCampaign = (id: string) => {
    const objPlay = {
      campaignId: id,
      pause: false,
    };
    const objPlayCamp = {
      campaignId: id,
      isActive: true,
    };
    axios
      .post(
        `${SERVER_URL_BUCKETIZE}${SCHEDULER_URL.PLAY_PAUSE_CAMPAIGN}`,
        objPlayCamp
      )
      .catch((err) => console.log(err));
    axios
      .patch(
        `${SERVER_URL_CONNECTOR}${SCHEDULER_URL.PLAY_PAUSE_API}`,
        objPlay,
        { headers: headers }
      )
      .then((res) => {
        toast.success("Campaign Resumed Successfully!");
        getAllTableDataCampaign();
      })
      .catch((err) => {
        toast.error("Campaign Resumed error!");
        if (err?.response?.status == 401) {
          navigate("/login");
          clearCacheData();
          window.location.reload();
          dispatch(setLoggedInUserInfo(userLoginInfo));
        }
      });
  };
  //for pause
  const handlePauseCampaign = (id: string) => {
    setPauseModalShow(true);
    setPauseId(id);
  };
  //To show persona Modal

  const toShowPersonaModal = (data: any) => {
    console.log("nayak", data);
    setPersonaModal((prev) => data);
  };

  //To schedule Campaign
  const toShowScheduleModal = (data: any) => {
    setScheduleModal((prev) => true);

    setScheduleCampaignData((prev) => data);
  };

  // To show Modal of progress
  const toshowProgressModal = (data: any) => {
    setInfoId(data?.id);
    setdataStatus(data);
    setProgressModal(true);
  };

  // To show data upload modal

  const toshowUploadDataModal = async (data: any) => {
    await setStoreUploadData([]);
    console.log("mm", data);
    const id = data?.parentCampaignId ? data?.parentCampaignId : data?.id;
    setDatauploadModalId(id);
    setDatauploadModalData(true);
    if (id) {
      axios
        .get(`${SERVER_URL_CONNECTOR}${CAMPAIGN_URL.DOWNLOAD_CAMPAIGN}${id}`, {
          headers: headers,
        })
        .then((res) => setStoreUploadData(res?.data?.data));
    }
  };

  // get parent id
  const getParentLabelId = (id: any) => {
    if (labelParentId?.includes(id)) {
      const tempArr = [...labelParentId];
      const indexr = tempArr.indexOf(id);
      if (indexr > -1) {
        tempArr.splice(indexr, 1);
      }
      setlabelParentId([...tempArr]);
      setParentCampaignListId([...tempArr])(dispatch);
      setShowChildId([]);
    } else {
      setPaginationData(originalData);
      setlabelParentId([id]);
      setParentCampaignListId([id])(dispatch);
    }
  };

  const handleClickPageNo = (newPageNo: any) => {
    const tempNewPage = newPageNo + 1;
    setPageNo((prev) => tempNewPage);
  };

  // for come back from calling List
  const backToCallingListPage = (data: any) => {
    setShowClient((prev) => data);
    setCallingListScreen(data)(dispatch);
  };

  const moveToPrevious = () => {
    handleClickPageNo(0);
    setShowCreate(false);
    setEditData([]);
    // getCampaignList()
  };

  const handleSearchChange = (e: any) => {
    setPageNo((prev) => 1);
    setSearchData((prev) => e.target.value.trim());
  };

  const getSearchItem = (e: any) => {
    setSearchClientName(e.target.value);
    const toSearchSaarthi = e.target.value.toLowerCase();
    const searchSaarthiData = resetClientList;
    if (toSearchSaarthi.length > 0) {
      const filteredSaarthiData = searchSaarthiData.map((d: any, i: any) => {
        if (d == "Select All") {
          return d;
        } else {
          if (d?.toLowerCase().match(toSearchSaarthi) && toSearchSaarthi) {
            return d;
          }
        }
      });
      const finalSaarthiData = filteredSaarthiData.filter((e: any) => {
        return e;
      });

      if (finalSaarthiData.length > 1) {
        setClientList(finalSaarthiData);
      } else {
        setClientList(resetClientList);
      }
    } else {
      setClientList(resetClientList);
    }
  };

  const onChangeClientName = (data: any) => {
    if (data?.toLowerCase() != "no matches found") {
      const clientWithIDName = allClientDataWithId?.find(
        (e) => e?.name === data
      );
      console.log("client", clientWithIDName);
      setClientIdFilter(clientWithIDName?.id);
      setSelectedClientName(data);
      if (data == "Select All") {
        setSearchClientName("");
        setCampaignClientName("")(dispatch);
        setClientList(resetClientList);
      } else setCampaignClientName(data)(dispatch);
    }
  };

  const deleteCampaign = (isDelete: any) => {
    const deleteCampaignUrl = `${SERVER_URL_CONNECTOR}${CAMPAIGN_URL.DELETE_CAMPAIGN}`;

    if (isDelete) {
      axios({
        method: "delete",
        url: deleteCampaignUrl,
        data: { id: deleteId.id },
        headers: headers,
      })
        .then((res) => {
          if (res?.data?.data?.Status === "Deleted Successfully") {
            toast.success("Campaign deleted successfully!");
          } else {
            toast.error("Campaign deletion failed as it has mapped files");
          }

          // getCampaignList("delete")
          // "Campaign deletion failed as it has mapped files"
          setShowDeleteModal(false);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            navigate("/login");
            clearCacheData();
            window.location.reload();
            dispatch(setLoggedInUserInfo(userLoginInfo));
          }
        });
      // setShowDeleteModal(false)
    } else {
      setShowDeleteModal(false);
    }
  };

  const onSubmitPause = (value: any) => {
    const objPlay = {
      campaignId: pauseId,
      pause: true,
      reason: value,
    };
    const objPauseCamp = {
      campaignId: pauseId,
      isActive: false,
    };
    axios
      .post(
        `${SERVER_URL_BUCKETIZE}${SCHEDULER_URL.PLAY_PAUSE_CAMPAIGN}`,
        objPauseCamp
      )
      .catch((err) => console.log(err));
    axios
      .patch(
        `${SERVER_URL_CONNECTOR}${SCHEDULER_URL.PLAY_PAUSE_API}`,
        objPlay,
        { headers: headers }
      )
      .then((res) => {
        if (res.status == 401) {
          navigate("/login");
          dispatch(setLoggedInUserInfo(userLoginInfo));
        }
        toast.success("Campaign Paused Successfully!");
        setPauseModalShow(false);
        getAllTableDataCampaign();
      })
      .catch((err) => {
        toast.error("Campaign Paused error!");
        setDisableBtnAddPause(false);
        if (err?.response?.status == 401) {
          navigate("/login");
          clearCacheData();
          window.location.reload();
          dispatch(setLoggedInUserInfo(userLoginInfo));
        }
      });
  };

  const clickDownloadUploadCsv = () => {
    console.log("clicked", storeUploadData);
    if (storeUploadData) {
      if (storeUploadData?.validatedFile) {
        const link = document.createElement("a");
        link.download = storeUploadData?.uploadCsvFileName[0];
        link.href =
          storeUploadData?.downloadablePath;
        document.body.appendChild(link);
        link.click();
      }
    }
  };

  const handlePageChange = (page: any) => {
    if (pageNo !== page) {
      setPageNo(Number(page.selected) + 1);
    }
  };

  /** Handle Terminate Campaign  */
  const handleTerminate = (id: string) => {
    console.log("terminate Id", id)
    const body = {
      isTerminated: true,
      campaignId: id
    }
    const data = {
      body: body,
      headers: headers,
      func: getAllTableDataCampaign,
    };

    dispatch(terminateCampaign(data));
  }

  //============
  // /******   -----end -------******/
  return (
    <>
      <div className={styles.newCampaigPageWrapper}>
        <div className={styles.newCampaigPageDiv}>
          {/* {showCreate ? (
            <DiallerSection
              previous={() => {
                showEditPage ? setShowEditPage(false) : moveToPrevious();
              }}
              EditData={[]}
              isCreate={true}
            />
          ) : showEditPage ? (
            <DiallerSection
              previous={() => {
                showEditPage ? setShowEditPage(false) : moveToPrevious();
              }}
              EditData={editData}
              isCreate={true}
            />
          ) : (
             : showCallingListScreen ? (
              <CallingListTable
                data={callingListDataAll}
                back={backToCallingListPage}
              />
            ) */}
          <div id="campaignTour" className={styles.newCampaignTableWrapper}>
            <div className={styles.newCampaignBtnContainer}>
              <div className={styles.newCampaignRightBtnDiv}>
                <SearchInputBox
                  onChange={handleSearchChange}
                  searchIcon={searchIcon}
                  placeholder={"Campaign Name"}
                  extraClassWrapper={styles.userListSearchWrapper}
                  extraClassInput={styles.userListSearchInput}
                  value={searchData}
                  inputType={"text"}
                />

                <Button
                  text="Archived"
                  image_src_left={
                    clickedArchieved ? unArchiveBlueIcon : archiveBlackIcon
                  }
                  extraClass={`${styles.archivedBtn} ${clickedArchieved ? styles.archiveBtnBlue : ""
                    }`}
                  id="archiveBtn"
                  onClick={() => {
                    return setClickedArchieved((prev) => !prev);
                  }}
                />

                <Button
                  text="Exclusions"
                  image_src_left={exclusionsListIcon}
                  extraClass={styles.exclusionListBtn}
                  extraBtnImageClass={styles.exclusionListBtnIcon}
                  id="exclusionListBtn"
                  onClick={handleExclusionListBtnClick}
                />

                {accountType?.toLowerCase() == "internal" &&
                  logInrole === ROLES.campaignManager.name && (
                    <div
                      className={styles.clientDropdownDiv}
                      id="client_2_dropDownDiv"
                    >
                      <DropDown
                        droplist={{
                          optionList: clientList ? clientList : "",
                          placeHolderText: selectedClientName
                            ? selectedClientName
                            : "Select Client",
                          imgSrcRight: downArrowIconDown,
                        }}
                        // isFilter={true}
                        searchVal={searchClientName}
                        searchUi={true}
                        handleSearchItem={(data: any) => {
                          getSearchItem(data);
                        }}
                        selectedItem={(item: any) => onChangeClientName(item)}
                        extraClassSelectedArea={styles.dropDownSelectedStyling}
                        extraClassToBeSelectedArea={
                          styles.dropDownToBeSelectedStyling
                        }
                        extraClassPlaceHolderStyling={
                          styles.dropDownPlaceHolderStyling
                        }
                        extraSearchInputWrapper={
                          styles.searchInputWrapperStyling
                        }
                        extraClassDropdownSearchWrapper={
                          styles.searchInputWrapper
                        }
                        extraClassDropdownSearchArea={styles.searchInputStyling}
                        extraClassPlaceHolder={styles.defaultPlaceHolderStyling}
                        extraClassDropDownUl={styles.dropDownUlStyling}
                        extraClassDropDownLi={styles.dropDownLiStyling}
                        outerSelected={selectedClientName}
                        extraHighlight={styles.styleHighlightLi}
                        editedItem={selectedClientName}
                        ignoreLeftAlign={true}
                        id="auto_client_dropdown"
                      />
                    </div>
                  )}

                <div>
                  <DemoDropdownComp data={newHealth} />
                </div>
              </div>
              <div className={styles.newCampaignLeftBtnDiv}>
                <div className={styles.dateFilter}>
                  <CalendarComponent
                    id="dateRangeschedulers"
                    showDefaultTime={true}
                    onChangeValue="true"
                    getonChangeValue={setDateFil}
                  />
                </div>

                <div className={styles.campaignBtnDiv}>
                  {process.env.REACT_APP_ENV === "production" &&
                    !lockedCreateBtn ? (
                    <div className={styles.disabledBtnDiv}>
                      <Button
                        text=" Create Campaign "
                        image_src_left={teamInviteGray}
                        extraClass={styles.disabledInviteBtnStyling}
                        id="auto_invite_btn_disabled"
                        onClick={() => handleClickLockedButton()}
                      />
                      <Icon
                        img_src={lockLight}
                        extraClass={styles.iconDisabledStyling}
                      />
                    </div>
                  ) : (
                    <Button
                      text=" Create Campaign "
                      image_src_left={campaignIcon}
                      extraClass={styles.createCampaignButtonStyle}
                      onClick={() => {
                        pushToCreate();
                        dispatch(clearAllCampaignData());
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.newCampaignTableContainer}>
              <div className={"campaignTableDiv"}>
                <TableSaarthiJp
                  cols={tableConstants(
                    getChildCampaign,
                    accountType,
                    pageNo,
                    downloadCampaignData,
                    moveToEdit,
                    deleteCampaignData,
                    showManualScreen,
                    moveToAnnalyticPage,
                    moveToCallingListPage,
                    handleArchieve,
                    handlePlayCampaign,
                    handlePauseCampaign,
                    toShowScheduleModal,
                    toshowProgressModal,
                    closeArrowList,
                    showChildId,
                    getParentLabelId,
                    toshowUploadDataModal,
                    toShowPersonaModal,
                    accountDetailsRedux,
                    handleTerminate
                  )}
                  data={paginationData}
                  pageNo={pageNo}
                  isLoading={load}
                  hideBorderArray={labelParentId}
                  trThExtraStyle={"thAligment"}
                  selectedCampaignStyling={"selectedCampaignStyling"}
                />
              </div>
              {/* </div> */}
            </div>
            <div>
              {totalPage > 1 && (
                <div className={styles.campaignPaginationDiv}>
                  {/* <div className={styles.pageTextStyling}>
                        Showing <strong>{(pageNo - 1) * 11 + 1}</strong>{" "}
                        to <strong>{Math.min(pageNo * 11, 397)}</strong>{" "}
                        of <strong>{397}</strong> results
                      </div> */}
                  <div className={styles.camapaignPagination}>
                    <ReactPaginate
                      nextLabel={">"}
                      onPageChange={handlePageChange}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={totalPage}
                      forcePage={pageNo - 1}
                      previousLabel="<"
                      pageClassName={styles["page-item"]}
                      pageLinkClassName={styles["page-link"]}
                      previousClassName={styles["prev"]}
                      previousLinkClassName={styles["page-link"]}
                      nextClassName={styles["next"]}
                      nextLinkClassName={styles["page-link"]}
                      breakLabel="..."
                      breakClassName={styles["page-item"]}
                      breakLinkClassName={styles["page-link"]}
                      containerClassName={"pagination"}
                      activeClassName={styles["active"]}
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* )} */}
        </div>
        {showDeleteModal && (
          <div className={styles.deleteModalCenter}>
            <DeleteModal
              deleteData={(data: any) => {
                deleteCampaign(data);
              }}
              elementId={deleteId.campaignId}
              closeDeleteModal={() => {
                setShowDeleteModal(false);
              }}
            />
          </div>
        )}
        {pauseModalShow && (
          <div className={styles.deleteModalCenter}>
            <PauseCampaignReason
              close={setPauseModalShow}
              submit={onSubmitPause}
              addDisable={disableBtnAddPause}
              setAddDisable={setDisableBtnAddPause}
            />
          </div>
        )}
        {scheduleModal && (
          <div className={styles.deleteModalCenter}>
            <ScheduleCampaignConfirm
              close={setScheduleModal}
              scheduleData={scheduleCampaignData}
            />
          </div>
        )}
        {personaModal && (
          <Modal
            shown={personaModal}
            extraClassStyleModalContent={styles.modalContentPersonaDiv}
            close={() => setPersonaModal(false)}
          >
            <PersonaPopUpModal
              close={() => setPersonaModal(false)}
              data={personaModal}
            />
          </Modal>
        )}
        {progressModal && (
          <div className={styles.deleteModalCenter}>
            <InProgressPopup
              hide={setProgressModal}
              id={infoId}
              jwt={headers}
              data={dataStatus}
            />
          </div>
        )}
        {datauploadModalData && (
          <div className={styles.deleteModalCenter}>
            <Modal
              shown={datauploadModalData}
              extraClassStyleModalContent={styles.modalContentDiv}
              close={() => setDatauploadModalData(false)}
            >
              <PopUpModal
                close={() => setDatauploadModalData(false)}
                MsgType={storeUploadData?.status}
                MsgData={storeUploadData?.popUpDetails}
                headingName={`Data Upload - ${storeUploadData?.status}`}
                btnIcon={downloadIconForProgress}
                btnName={
                  storeUploadData?.validatedFile ? "Download .CSV" : ""
                }
                btnClick={clickDownloadUploadCsv}
                errorShow={
                  storeUploadData?.status === "Completed"
                    ? false
                    : storeUploadData?.status === "Pending"
                      ? false
                      : true
                }
                closeIcon={closeIcon}
                data={storeUploadData}
                animationTrue
              />

              <div className={styles.multiFileStatus}>
                {
                  storeUploadData?.fileWiseDetails?.map((detail: any, i: number) => {
                    return (
                      <div key={i} className={styles.eachPopUp} >
                        <ManyFileStatus
                          fileName={detail?.fileName}
                          failMessage={detail?.errorRespose}
                          data={detail?.details}
                        /></div>
                    )
                  })
                }
              </div>
            </Modal>
          </div>
        )}

        {lockedCreateBtn &&
          !loginUserDetail?.productShowcase?.includes("Campaign") && (
            <TourCampaign
              nextClickFunc={() => {
                setShowEditPage(true);
                setCampaignEditOrCreateType("create")(dispatch);
                storeSelectedUsecase({ id: teamUseCaseId })(dispatch);
              }}
            />
          )}

        {showMaiaPopUp && !lockedCreateBtn && !loginUserDetail?.isInvited && (
          <HelpModal
            show={true}
            modalIcon={campaignPageIcon}
            isClose={true}
            close={() => {
              setShowMaiaPopUp(false);
              dispatch(setIsMaiaPopUp(false));
            }}
            extraContentClass={styles.extraContentCampaignStyling}
            extraClassModalBackDrop={styles.extraClassCampaignModalBackDrop}
            extraModalStyling={styles.extraModalStylingCampaign}
            extraContentCloseClass={styles.extraContentCloseClassCampaign}
            extraClassModalStyling={styles.extraClassModalStylingCampaign}
            modalContent={
              <>
                <div className={styles.teamContentTextDiv}>
                  Oops! This content will be unlocked once you complete your
                  profile setup. In the meantime, you can explore other features
                  or finish setting up your organisation details.
                </div>
                <div className={styles.teamContentBtnDiv}>
                  <Button
                    text="Explore Pravid"
                    extraClass={`${styles.teamExploreBtnStyling} ${styles.teamBtnStyling}`}
                    onClick={() => {
                      setShowMaiaPopUp(false);
                      dispatch(setIsMaiaPopUp(false));
                      setIsExpNowPopUp(true);
                      dispatch(experiencePravid(true));
                    }}
                    id="auto_team_explore_btn"
                  />
                  <Button
                    text="Complete Account Setup"
                    extraClass={`${styles.teamSetUpBtnStyling} ${styles.teamBtnStyling}`}
                    onClick={() => {
                      setShowMaiaPopUp(false);
                      dispatch(setIsMaiaPopUp(false));
                      dispatch(getCurrentTab("Organization"));
                      navigate("/profile/your-account");
                    }}
                    id="auto_team_setup_btn"
                  />
                </div>
              </>
            }
          />
        )}
      </div>
    </>
  );
}

export default Campaign;
