export default {
  REPORT_DATA_API_REQUEST: "REPORT_DATA_API_REQUEST",
  REPORT_DATA_API_SUCCESS: "REPORT_DATA_API_SUCCESS",
  REPORT_DATA_API_FAILURE: "REPORT_DATA_API_FAILURE",
  SET_TABLE_SORTING_COLUMN: "SET_TABLE_SORTING_COLUMN",
  SET_REPORT_TABLE_DATA: "SET_REPORT_TABLE_DATA",
  SORT_REPORT_TABLE_DATA: "SORT_REPORT_TABLE_DATA",

  REPORT_DATA_NEXT_API_REQUEST: "REPORT_DATA_NEXT_API_REQUEST",
  REPORT_DATA_NEXT_API_SUCCESS: "REPORT_DATA_NEXT_API_SUCCESS",
  REPORT_DATA_PREVIOUS_API_SUCCESS: "REPORT_DATA_PREVIOUS_API_SUCCESS",
  REPORT_DATA_PREVIOUS_SLOT_API_SUCCESS:
    "REPORT_DATA_PREVIOUS_SLOT_API_SUCCESS",
  REPORT_DATA_NEXT_API_FAILURE: "REPORT_DATA_NEXT_API_FAILURE",

  REPORT_DATA_DOWNLOAD_API_REQUEST: "REPORT_DATA_DOWNLOAD_API_REQUEST",
  REPORT_DATA_DOWNLOAD_API_SUCCESS: "REPORT_DATA_DOWNLOAD_API_SUCCESS",
  REPORT_DATA_DOWNLOAD_API_FAILURE: "REPORT_DATA_DOWNLOAD_API_FAILURE",

  REPORT_DATA_CUSTOMISE_DOWNLOAD_API_REQUEST:
    "REPORT_DATA_CUSTOMISE_DOWNLOAD_API_REQUEST",
  REPORT_DATA_CUSTOMISE_DOWNLOAD_API_SUCCESS:
    "REPORT_DATA_CUSTOMISE_DOWNLOAD_API_SUCCESS",
  REPORT_DATA_CUSTOMISE_DOWNLOAD_API_FAILURE:
    "REPORT_DATA_CUSTOMISE_DOWNLOAD_API_FAILURE",
};
