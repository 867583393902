import React, { useEffect, useRef, useState } from "react";
import styles from "./NewToolTip.module.scss";
import { getCoords } from "../../../../utils/document/html.util";
const NewToolTip = (props: {
  children: JSX.Element;
  delay?: number;
  direction?: "left" | "right" | "top" | "bottom";
  content: string | JSX.Element;
  extraWrapper?: string;
  makeWrapperAbsolute?: boolean;
  extraTooltipClass?: string;
  makeTooltipFixed?: { left: number; top: number };
}) => {
  const tooltipRef = useRef(null);
  const bodyRef = useRef(null);
  let timeout: any;
  const [active, setActive] = useState(false);

  const showTip = () => {
    if (props.delay) {
      timeout = setTimeout(() => {
        setActive(true);
      }, props.delay);
    } else {
      setActive(true);
    }
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };
  // function moveTooltip(e: MouseEvent) {
  //   if (tooltipRef.current && bodyRef.current && props.makeTooltipFixed) {
  //     const Element = tooltipRef.current as HTMLElement;
  //     const bodyElement = bodyRef.current as HTMLElement;
  //     const x = e.clientX;
  //     const y = e.clientY;
  //     const width = bodyElement.offsetWidth;
  //     const heignt = bodyElement.offsetHeight;
  //     Element.style.position = "fixed";
  //     Element.style.left = String(x + width + 35) + "px";
  //     Element.style.top = String(y + 20) + "px";
  //     console.log(x, y, "nithin mouse");
  //     // Element.style.left = "100px";
  //     // Element.style.top = "100px";
  //     Element.style.left = "120";
  //   }
  // }
  useEffect(() => {
    if (
      active &&
      tooltipRef.current &&
      bodyRef.current &&
      props.makeTooltipFixed
    ) {
      const Element = tooltipRef.current as HTMLElement;
      const bodyElement = bodyRef.current as HTMLElement;
      const coord = getCoords(bodyElement);
      const x = coord.left;
      const y = coord.top;
      const width = bodyElement.offsetWidth;
      const heignt = bodyElement.offsetHeight;
      Element.style.position = "fixed";
      Element.style.left = String(x + props.makeTooltipFixed.left) + "px";
      Element.style.top = String(y + props.makeTooltipFixed.top) + "px";
      console.log(x, y, "nithin mouse");
      // Element.style.left = "100px";
      // Element.style.top = "100px";
      Element.style.left = "120";
    }
  }, [active, tooltipRef, bodyRef]);
  return (
    <div
      className={`${styles.TooltipWrapper} ${props.extraWrapper}`}
      // When to show the tooltip
      onMouseEnter={(e: any) => {
        showTip();
        // moveTooltip(e);
      }}
      onMouseLeave={hideTip}
      style={props.makeWrapperAbsolute ? { position: "absolute" } : {}}
      ref={bodyRef}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        <div
          className={`${styles.TooltipTip} ${
            props.direction ? styles[props.direction] : styles.top
          } ${props.extraTooltipClass}`}
          ref={tooltipRef}
        >
          {/* Content */}
          {props.content}
        </div>
      )}
    </div>
  );
};

export default NewToolTip;
