// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mQVgEXYJEE4HuhlyD8EQ .IDbr7uSWhiZvpD4e9yw9{width:341.69px;padding:3%;background:#fff;border-radius:5.07712px;position:relative}.mQVgEXYJEE4HuhlyD8EQ .ee7MwFLsS7wNVQtyKDVX{color:#676767;line-height:30px}.mQVgEXYJEE4HuhlyD8EQ .dQmEQXv9IAvnB6ZnomzP{display:flex;justify-content:flex-end;padding-right:4%}.mQVgEXYJEE4HuhlyD8EQ .Nd1DeSdffgqPlgz3Yu1K{background:#0174ff;border:1px solid #0174ff;border-radius:2px;color:#fff;padding:1% 5%;font-weight:400;font-size:15px;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/signUpFlow/tourPopUp/TourModal.module.scss"],"names":[],"mappings":"AACE,4CACE,cAAA,CACA,UAAA,CACA,eAAA,CACA,uBAAA,CACA,iBAAA,CAEF,4CACE,aAAA,CACA,gBAAA,CAEF,4CACE,YAAA,CACA,wBAAA,CACA,gBAAA,CAEF,4CACE,kBAAA,CAEA,wBAAA,CACA,iBAAA,CACA,UAAA,CACA,aAAA,CACA,eAAA,CACA,cAAA,CACA,cAAA","sourcesContent":[".tourModalWrapper {\n  .tourModalOuterDiv {\n    width: 341.69px;\n    padding: 3%;\n    background: #ffffff;\n    border-radius: 5.07712px;\n    position: relative;\n  }\n  .contentPara {\n    color: #676767;\n    line-height: 30px;\n  }\n  .buttonContainer {\n    display: flex;\n    justify-content: flex-end;\n    padding-right: 4%;\n  }\n  .btnNext {\n    background: #0174ff;\n\n    border: 1px solid #0174ff;\n    border-radius: 2px;\n    color: #ffffff;\n    padding: 1% 5%;\n    font-weight: 400;\n    font-size: 15px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tourModalWrapper": `mQVgEXYJEE4HuhlyD8EQ`,
	"tourModalOuterDiv": `IDbr7uSWhiZvpD4e9yw9`,
	"contentPara": `ee7MwFLsS7wNVQtyKDVX`,
	"buttonContainer": `dQmEQXv9IAvnB6ZnomzP`,
	"btnNext": `Nd1DeSdffgqPlgz3Yu1K`
};
export default ___CSS_LOADER_EXPORT___;
