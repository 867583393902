export default {
  GET_ALL_CAMPAIGNS_DATA: "GET_ALL_CAMPAIGNS_DATA",
  SET_SELECTED_CAMPAIGN_DATA: "SET_SELECTED_CAMPAIGN_DATA",
  CLEAR_ALL_CAMPAIGN_DATA:"CLEAR_ALL_CAMPAIGN_DATA",


  POST_CAMPAIGN_TERMINATE_REQUEST: "POST_CAMPAIGN_TERMINATE_REQUEST",
  POST_CAMPAIGN_TERMINATE_SUCCESS: "POST_CAMPAIGN_TERMINATE_SUCCESS",
  POST_CAMPAIGN_TERMINATE_FAILURE: "POST_CAMPAIGN_TERMINATE_FAILURE",
};
