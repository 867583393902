import { Reducer } from "redux";
import actionType from "../strategySelection.actionTypes";
import { StrategySelectInterface } from "../interface";

const initialState: StrategySelectInterface = {
  strategySelection: {
    data: null,
    status: "idle",
    error: null,
  },
};

const strategySelectionReducer: Reducer<StrategySelectInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionType.ADD_STRATEGY_TO_CAMPAIGN_API_REQUEST: {
      return {
        ...state,
        strategySelection: {
          data: null,
          status: "loading",
          error: null,
        },
      };
    }

    case actionType.ADD_STRATEGY_TO_CAMPAIGN_API_SUCCESS: {
      return {
        ...state,
        strategySelection: {
          data: action.payload,
          status: "success",
          error: null,
        },
      };
    }

    case actionType.ADD_STRATEGY_TO_CAMPAIGN_API_FAILURE: {
      return {
        ...state,
        strategySelection: {
          data: action.payload,
          status: "failure",
          error: null,
        },
      };
    }

    case actionType.CLEAR_STRATEGY_DATA:
      return initialState;

    default: {
      return state;
    }
  }
};

export default strategySelectionReducer;
