/* eslint-disable no-console */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import Loader from "../../components/agentDesktop/generic/loader/Loader";
import { RootState } from "../../redux";
import {
  getAgentDesktopSchema,
  getAgentStatus,
} from "../../redux/agentDesktop/BaseScreenAD/action";
import { setShowCallModule } from "../../redux/agentDesktop/callLogger/action";
import CallWebSocket from "./callWebSocket/CallWebSocket";
import ChatWebSocket from "./ChatWebSocket";
import screenType from "../../constants/screenType";
import { INACTIVE_AUTO_LOGOUT_TIME, ROLES } from "../../constants";
import AdminWebSocket from "./AdminCallWebSocket";
import AdminChatWebSocket from "./AdminChatWebSocket";
import HeaderComponent from "../../components/moduleComponents/navigationBar/NavigationBar";
import LeftMenu from "../../components/moduleComponents/leftSideMenu/LeftSideMenu";
import { useIdleTimer } from "react-idle-timer";
import RightSideBarRoute from "../../router/subRoutes/RightSideBarRoute";

export default function BaseScreenAD() {
  const schemaLoading = useSelector(
    (store: any) => store.agentDesktopReducer?.baseScreenAD?.schemaLoading
  );
  const role = useSelector(
    (store: any) => store.loginReducer.userLoginInfo?.userDetail?.role
  );

  const isActivePageType = useSelector(
    (store: RootState) => store.loginReducer?.isActivePageType
  );
  const agentStatus = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD?.status?.currentState
  );
  const messageCategoryCount = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.chatLogger.status.data.agentChatStatusDetails
  );
  const activePage = useSelector(
    (store: RootState) => store?.loginReducer?.isActivePageType
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* useEffects ------ */
  useEffect(() => {
    dispatch(getAgentDesktopSchema(role));
    dispatch(getAgentStatus());
  }, []);

  useEffect(() => {
    if (isActivePageType !== screenType.callLoggerAgent.name) {
      dispatch(setShowCallModule(false));
    }
  }, [isActivePageType]);

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: INACTIVE_AUTO_LOGOUT_TIME,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500,
  });

  /* handlers */
  function handleOnIdle() {
    getLastActiveTime();
    navigate("/signout");
  }
  function handleOnActive(event: any) {
    getRemainingTime();
  }

  return (
    <div className="Base-screen-ad" style={{ width: "100vw", height: "100vh" }}>
      {role === ROLES.agentDesktop_admin.name && (
        <>
          <AdminWebSocket />
        </>
      )}
      {schemaLoading ? (
        <Loader />
      ) : (
        <div className="wrapper__base-screen">
          <div className="header">
            <HeaderComponent />
          </div>
          <div className="base-screen__body">
            <div className="base-screen__left-menu">
              <LeftMenu />
            </div>
            <div id="fullscreen" className="base-screen__center">
              <div
                className="dynamic-screen"
                style={
                  activePage !== screenType.analytics.name ||
                  activePage !== screenType.logger.name
                    ? { height: "100%" }
                    : {}
                }
              >
                <Outlet />
              </div>
            </div>
            <div className="base-screen__right-menu">
              <RightSideBarRoute />
            </div>
          </div>
        </div>
      )}
      <>
        {role === ROLES.agentDesktop_agent.name && (
          <>
            {isActivePageType !== screenType.chatLoggerAgent.name &&
              agentStatus === "Available" && <CallWebSocket />}
            {isActivePageType !== screenType.callLoggerAgent.name &&
              agentStatus === "Available" && <ChatWebSocket />}
          </>
        )}
      </>
    </div>
  );
}
