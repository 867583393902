export default {
  ADD_CUSTOMER: "ADD_CUSTOMER",
  DELETE_CUSTOMER: "DELETE_CUSTOMER",
  ADD_FETCHED_CUSTOMERS: "ADD_FETCHED_CUSTOMERS",
  SET_DISABLE_BUTTON: "SET_DISABLE_BUTTON",
  UPDATE_CUSTOMER_DATA: "UPDATE_CUSTOMER_DATA",
  PUSH_DATA_API: "PUSH_DATA_API",
  CAMPAIGN_TESTING_GET_SCHEMA_API_REQUEST:
    "CAMPAIGN_TESTING_GET_SCHEMA_API_REQUEST",
  CAMPAIGN_TESTING_GET_SCHEMA_API_SUCCESS:
    "CAMPAIGN_TESTING_GET_SCHEMA_API_SUCCESS",
  CAMPAIGN_TESTING_GET_SCHEMA_API_FAILURE:
    "CAMPAIGN_TESTING_GET_SCHEMA_API_FAILURE",
  CAMPAIGN_TESTING_UPDATE_CUSTOMER_DATA_OBJECT:
    "CAMPAIGN_TESTING_UPDATE_CUSTOMER_DATA_OBJECT",
  MAKE_SCHEMA_BY_AGENT_CAMPAIGN_TESTING:
    "MAKE_SCHEMA_BY_AGENT_CAMPAIGN_TESTING",
  UPDATE_DATA_BY_AGENT_CAMPAIGN_TESTING:
    "UPDATE_DATA_BY_AGENT_CAMPAIGN_TESTING",
  SET_SELECTED_AGENT_CAMPAIGN_TESTING: "SET_SELECTED_AGENT_CAMPAIGN_TESTING",
  MODIFY_CUSTOMER_DATA_DEMO_CAMPAIGN_TESTING:
    "MODIFY_CUSTOMER_DATA_DEMO_CAMPAIGN_TESTING",
};
