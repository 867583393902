import { createReducer } from "@reduxjs/toolkit";
import actionTypes from "../actionTypes/login.actionTypes";
import navigationAction from "../../../profile/navigation/navigation.actionTypes";
import { stateInterface } from "../interface";
import { schema } from "../../../../components/moduleComponents/onBoarding//login/loginField/schema";
const initialState: stateInterface = {
  userLoginInfo: {},
  isActivePageType: "",
  isPageType: "",
  isSchemaUpdating: false,
  isSuccess: false,
  gettingClientSchema: false,
  isRequestFeature: "",
  teamData: { data: [], isLoading: false, error: {} },
  teamSchemaData: { data: {}, isLoading: false, error: {} },
  currentTeam: {},
  teamTabs: { data: [], isLoading: false, error: {} },
  teamChannels: { data: [], isLoading: false, error: {} },
  sidebarModules: { data: [], isLoading: false, error: {} },
  logOut: { data: [], isLoading: false, error: {} },
  userData: {},
  refreshToken: {
    data: {},
    isLoading: false,
    error: {},
    timestamp: new Date(),
  },
  inviteTokenVerification: { data: {}, isLoading: false, error: {} },
};

const loginReducer = (
  state: stateInterface = initialState,
  action: { payload: any; type: string }
) => {
  switch (action.type) {
    case actionTypes.SET_LOGGEDIN_USER_INFO: {
      return {
        ...state,
        userLoginInfo: action.payload,
      };
    }

    case actionTypes.SET_PAGE_TYPE: {
      return {
        ...state,
        isPageType: action.payload,
      };
    }

    case actionTypes.SET_ACTIVE_PAGE_TYPE: {
      return {
        ...state,
        isActivePageType: action.payload,
      };
    }

    case actionTypes.SET_SCHEMA_UPDATE_REQUEST: {
      return {
        ...state,
        isSchemaUpdating: true,
      };
    }
    case actionTypes.SET_SCHEMA_UPDATE_SUCCESS: {
      //console.log(action.payload,"FROM REDUCER");
      return {
        ...state,
        teamSchemaData: { ...state.teamSchemaData, data: action.payload },
      };
    }
    case actionTypes.SET_SCHEMA_UPDATE_FAILURE: {
      return { ...state, error: action.payload, isSchemaUdating: false };
    }

    case actionTypes.SET_REQUEST_FEATURE_API_REQUEST: {
      return {
        ...state,
        isRequestFeature: action.payload,
        isSuccess: false,
      };
    }
    case actionTypes.SET_REQUEST_FEATURE_SUCCESS: {
      return {
        ...state,
        isRequestFeatureSuccess: action.payload,
        isSuccess: true,
      };
    }
    case actionTypes.SET_REQUEST_FEATURE_FAILURE: {
      return {
        ...state,
        isRequestFeatureFailure: action.payload,
        isSuccess: false,
      };
    }
    case actionTypes.SET_REQUEST_FEATURE_STATE: {
      return { ...state, isSuccess: action.payload };
    }
    case actionTypes.GET_SCHEMA_OF_CLIENT_REQUEST: {
      return {
        ...state,
        gettingClientSchema: true,
      };
    }
    case actionTypes.GET_SCHEMA_OF_CLIENT_REQUEST_SUCCESS: {
      return {
        ...state,
        gettingClientSchema: false,
        // userLoginInfo: {
        //   ...(state.userLoginInfo as never as object),
        //   schema: action.payload.schema,
        //   sourceDetails: action.payload.sourceDetails,
        // },
      };
    }
    case actionTypes.GET_SCHEMA_OF_CLIENT_REQUEST_FAILURE: {
      return {
        ...state,
        gettingClientSchema: false,
      };
    }

    // case actionTypes.USER_LOGOUT: {
    //   return {
    //     ...initialState,
    //   };
    // }

    case actionTypes.USER_LOGOUT: {
      return {
        ...state,
        logOut: {
          ...state.logOut,
          data: [],
          isLoading: true,
          error: {},
        },
      };
    }

    case actionTypes.USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        logOut: {
          ...state.logOut,
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }

    case actionTypes.USER_LOGOUT_FAILURE: {
      return {
        ...state,
        logOut: {
          ...state.logOut,
          data: [],
          isLoading: false,
          error: action.payload,
        },
      };
    }

    /* team data --------------- */
    case actionTypes.GET_TEAM_DATA_REQUEST: {
      return {
        ...state,
        teamData: { ...state.teamData, data: [], isLoading: true, error: {} },
      };
    }
    case actionTypes.GET_TEAM_DATA_SUCCESS: {
      return {
        ...state,
        teamData: { ...state.teamData, data: action.payload, isLoading: false },
      };
    }
    case actionTypes.GET_TEAM_DATA_FAILURE: {
      return {
        ...state,
        teamData: {
          ...state.teamData,
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.GET_ALL_CLIENT_DATA_SUCCESS: {
      return {
        ...state,
        allClientDataByname: action.payload,
      };
    }
    case actionTypes.GET_ALL_CLIENT_DATA_ERROR: {
      return {
        ...state,
        allClientDataBynameError: action.payload,
      };
    }
    case actionTypes.GET_USER_DATA_SUCCESS: {
      return {
        ...state,
        userData: action.payload,
      };
    }
    case actionTypes.GET_USER_DATA_ERROR: {
      return {
        ...state,
        userDataError: action.payload,
      };
    }

    /* setting current team */
    case navigationAction.SET_CURRENT_TEAM_PROFILE: {
      return {
        ...state,
        currentTeam: action.payload,
      };
    }

    /* team schema data ------------- */
    case actionTypes.GET_TEAM_SCHEMA_REQUEST: {
      return {
        ...state,
        teamSchemaData: {
          ...state.teamSchemaData,
          data: {},
          isLoading: true,
          error: {},
        },
      };
    }
    case actionTypes.GET_TEAM_SCHEMA_SUCCESS: {
      return {
        ...state,
        teamSchemaData: {
          ...state.teamSchemaData,
          isLoading: false,
          data: action.payload,
        },
      };
    }
    case actionTypes.GET_TEAM_SCHEMA_FAILURE: {
      return {
        ...state,
        teamSchemaData: {
          ...state.teamSchemaData,
          isLoading: false,
          error: action.payload,
        },
      };
    }

    /* team tabs ------------- */
    case actionTypes.GET_TEAM_TAB_REQUEST: {
      return {
        ...state,
        teamTabs: { ...state.teamTabs, data: [], isLoading: true, error: {} },
      };
    }
    case actionTypes.GET_TEAM_TAB_SUCCESS: {
      return {
        ...state,
        teamTabs: { ...state.teamTabs, data: action.payload, isLoading: false },
      };
    }
    case actionTypes.GET_TEAM_TAB_FAILURE: {
      return {
        ...state,
        teamTabs: {
          ...state.teamTabs,
          isLoading: false,
          error: action.payload,
        },
      };
    }

    /* team channels ------------- */
    case actionTypes.GET_TEAM_CHANNEL_REQUEST: {
      return {
        ...state,
        teamChannels: {
          ...state.teamChannels,
          data: [],
          isLoading: true,
          error: {},
        },
      };
    }
    case actionTypes.GET_TEAM_CHANNEL_SUCCESS: {
      return {
        ...state,
        teamChannels: {
          ...state.teamChannels,
          data: action.payload,
          isLoading: false,
        },
      };
    }
    case actionTypes.GET_TEAM_CHANNEL_FAILURE: {
      return {
        ...state,
        teamChannels: {
          ...state.teamChannels,
          isLoading: true,
          error: action.payload,
        },
      };
    }

    /* sidebar Modules */
    case actionTypes.GET_SIDEBAR_MODULE_REQUEST: {
      return {
        ...state,
        sidebarModules: {
          ...state.sidebarModules,
          data: [],
          isLoading: true,
          error: {},
        },
      };
    }
    case actionTypes.GET_SIDEBAR_MODULE_SUCCESS: {
      return {
        ...state,
        sidebarModules: {
          ...state.sidebarModules,
          data: action.payload,
          isLoading: false,
        },
      };
    }
    case actionTypes.GET_SIDEBAR_MODULE_FAILURE: {
      return {
        ...state,
        sidebarModules: {
          ...state.sidebarModules,
          error: action.payload,
          isLoading: false,
        },
      };
    }

    /* ------- refresh token  ---------*/
    case actionTypes.REFRESH_TOKEN_API_REQUEST: {
      return {
        ...state,
        refreshToken: {
          ...state.refreshToken,
          data: {},
          error: {},
          isLoading: true,
        },
      };
    }
    case actionTypes.REFRESH_TOKEN_API_SUCCESS: {
      return {
        ...state,
        refreshToken: {
          ...state.refreshToken,
          data: action.payload,
          isLoading: false,
        },
      };
    }
    case actionTypes.REFRESH_TOKEN_API_FAILURE: {
      return {
        ...state,
        refreshToken: {
          ...state.refreshToken,
          error: action.payload,
          isLoading: false,
        },
      };
    }

    /*****************************  Verify Invite token  *****************************/
    case actionTypes.VERIFY_INVITE_REQUEST: {
      return {
        ...state,
        inviteTokenVerification: {
          ...state.inviteTokenVerification,
          data: {},
          isLoading: true,
          error: {},
        },
      };
    }
    case actionTypes.VERIFY_INVITE_SUCCESS: {
      return {
        ...state,
        inviteTokenVerification: {
          ...state.inviteTokenVerification,
          data: action.payload,
          isLoading: false,
        },
      };
    }
    case actionTypes.VERIFY_INVITE_FAILURE: {
      return {
        ...state,
        inviteTokenVerification: {
          ...state.inviteTokenVerification,
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case navigationAction.UPDATE_ROLE_BY_TEAM: {
      // console.log("update role", action.payload);
      const newUserDetails = structuredClone(state.userLoginInfo.userDetail);
      newUserDetails.role = action.payload;

      return {
        ...state,
        userLoginInfo: { ...state.userLoginInfo, userDetail: newUserDetails },
      };
    }

    /* default ------------------ */
    default: {
      return state;
    }
  }
};

export default loginReducer;
