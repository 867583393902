import actionTypes from "../customIntegration.actionTypes";

export function getSecretAPIDetails(payload: object) {
  return {
    type: actionTypes.GET_SECRET_API_REQUEST,
    payload: payload,
  };
}

export function sendInfoToDevEmailData(payload: object) {
  return {
    type: actionTypes.SEND_INFO_TO_DEV_API_REQUEST,
    payload: payload,
  };
}

export function clearCustomIntegrationData() {
  return {
    type: actionTypes.CLEAR_CUSTOM_INTEGRATION_DATA,
  };
}

export function clearCustomIntegrationAPIData() {
  return {
    type: actionTypes.CLEAR_CUSTOM_INTEGRATION_API_DATA,
  };
}
