// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AAp4brh10ZINuilaKyv5{padding:20px;background:#fff;border-radius:8px}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/exclusionsList/exclusionsListContainer/exclusionsListContainer.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".exclusionListContainer {\n  padding: 20px;\n  background: #ffffff;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exclusionListContainer": `AAp4brh10ZINuilaKyv5`
};
export default ___CSS_LOADER_EXPORT___;
