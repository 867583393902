export default {
  GET_SUMMARY_REQUEST_AGENT_DESKTOP_CHAT:
    "GET_SUMMARY_REQUEST_AGENT_DESKTOP_CHAT",
  GET_SUMMARY_SUCCESS_AGENT_DESKTOP_CHAT:
    "GET_SUMMARY_SUCCESS_AGENT_DESKTOP_CHAT",
  GET_SUMMARY_FAILURE_AGENT_DESKTOP_CHAT:
    "GET_SUMMARY_FAILURE_AGENT_DESKTOP_CHAT",
  GET_TRANSCRIPT_REQUEST_AGENT_DESKTOP_CHAT:
    "GET_TRANSCRIPT_REQUEST_AGENT_DESKTOP_CHAT",
  GET_TRANSCRIPT_SUCCESS_AGENT_DESKTOP_CHAT:
    "GET_TRANSCRIPT_SUCCESS_AGENT_DESKTOP_CHAT",
  GET_TRANSCRIPT_FAILURE_AGENT_DESKTOP_CHAT:
    "GET_TRANSCRIPT_FAILURE_AGENT_DESkTOP_CHAT",
  GET_SESSION_ID_REQUEST_WITHOUT_REFRESH_AGENT_DESKTOP_CHAT:
    "GET_SESSION_ID_REQUEST_WITHOUT_REFRESH_AGENT_DESKTOP_CHAT",
  GET_SESSION_ID_REQUEST_AGENT_DESKTOP_CHAT:
    "GET_SESSION_ID_REQUEST_AGENT_DESKTOP_CHAT",
  GET_SESSION_ID_SUCCESS_AGENT_DESKTOP_CHAT:
    "GET_SESSION_ID_SUCCESS_AGENT_DESKTOP_CHAT",
  SET_SESSION_ID_LOADING_AGENT_DESKTOP_CHAT:
    "SET_SESSION_ID_LOADING_AGENT_DESKTOP_CHAT",
  GET_SESSION_ID_FAILURE_AGENT_DESKTOP_CHAT:
    "GET_SESSION_ID_FAILURE_AGENT_DESKTOP_CHAT",
  SET_CHAT_STATUS_AGENT_DESKTOP_CHAT: "SET_CHAT_STATUS_AGENT_DESKTOP_CHAT",
  SET_SELECTED_SESSION_ID_AGENT_DESKTOP_CHAT:
    "SET_SELECTED_SESSION_ID_AGENT_DESKTOP_CHAT",
  GET_STATUS_OF_CHAT_AGENT_DESKTOP_API_CALL:
    "GET_STATUS_OF_CHAT_AGENT_DESKTOP_API_CALL",
  GET_STATUS_OF_CHAT_AGENT_DESKTOP_API_SUCCESS:
    "GET_STATUS_OF_CHAT_AGENT_DESKTOP_API_SUCCESS",
  GET_STATUS_OF_CHAT_AGENT_DESKTOP_API_FAILURE:
    "GET_STATUS_OF_CHAT_AGENT_DESKTOP_API_FAILURE",
  UPDATE_SUMMARY_CHAT_REQUEST_AGENT_DESKTOP:
    "UPDATE_SUMMARY_CHAT_REQUEST_AGENT_DESKTOP",
  UPDATE_SUMMARY_CHAT_SUCCESS_AGENT_DESKTOP:
    "UPDATE_SUMMARY_CHAT_SUCCESS_AGENT_DESKTOP",
  UPDATE_SUMMARY_CHAT_FAILURE_AGENT_DESKTOP:
    "UPDATE_SUMMARY_CHAT_FAILURE_AGENT_DESKTOP",
  SET_SUMMARY_CHAT_UPDATE_SUCCESS_AGENT_DESKTOP:
    "SET_SUMMARY_CHAT_UPDATE_SUCCESS_AGENT_DESKTOP",
  APPEND_RECENT_MESSAGE_FROM_SOCKETS: "APPEND_RECENT_MESSAGE_FROM_SOCKETS",
  APPEND_CHAT_TRANSCRIPT_AGENT_DESKTOP: "APPEND_CHAT_TRANSCRIPT_AGENT_DESKTOP",
  SET_MESSAGE_STATUS_GROUP_AGENT_DESKTOP:
    "SET_MESSAGE_STATUS_GROUP_AGENT_DESKTOP",
  GET_STATUS_FOR_WHATSAPP_CHAT_API_CALL:
    "GET_STATUS_FOR_WHATSAPP_CHAT_API_CALL",
  GET_STATUS_FOR_WHATSAPP_CHAT_API_SUCCESS:
    "GET_STATUS_FOR_WHATSAPP_CHAT_API_SUCCESS",
  GET_STATUS_FOR_WHATSAPP_CHAT_API_FAILURE:
    "GET_STATUS_FOR_WHATSAPP_CHAT_API_FAILURE",
  GET_CANNED_MESSAGES_API_CALL: "GET_CANNED_MESSAGES_API_CALL",
  GET_CANNED_MESSAGES_API_SUCCESS: "GET_CANNED_MESSAGES_API_SUCCESS",
  GET_CANNED_MESSAGES_API_FAILURE: "GET_CANNED_MESSAGES_API_FAILURE",
  HANDLE_CHAT_PAGE_LEAVE_AD: "HANDLE_CHAT_PAGE_LEAVE_AD",
};
