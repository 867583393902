import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Accord from "./Accord/Accord";
import CallingConditionTableData from "./CallingConditionTableData";
import { setCallingCondition } from "../../../../../redux/breadcrum/actions/breadcrum.actions";

export default function CallingConditionAccord(props) {
  const [shuffleData, setShuffleData] = useState([]);
  const dispatch = useDispatch();

  const checkBoxChecked = (data) => {
    console.log(data);
  };

  const selectedCallingData = useSelector((store) => {
    return store.breadcrumReducer.selectedCallingData;
  });

  const shuffledCallingCondition = useSelector((store) => {
    return store.breadcrumReducer.shuffledCallingConditionData;
  });

  const breadCrumReducerData = useSelector((store) => {
    return store.breadcrumReducer;
  });

  const preferedTime = useSelector((store) => {
    return store?.campaignModuleReducer?.schedulerData?.getSchedularDetails?.dialtimeData?.data?.preferedTime;
  });

  useEffect(() => {
    if (selectedCallingData?.length > 0) {
      let shuffledData = selectedCallingData.map((each) => {
        return each.text;
      });
      setShuffleData((prev) => shuffledData);
    }
  }, [shuffledCallingCondition, selectedCallingData]);

  useEffect(() => {
    if (selectedCallingData?.length > 0) {
      let val = selectedCallingData.map((et) => {
        return et.text;
      });
      let tempop = [];
      let tempOpOut = [];
      let testVal = breadCrumReducerData?.callingCondition?.condition.map(
        (ew) => {
          let index = val.indexOf(ew.text);
          if (index > -1) {
            if (ew.text !== "EMI Value") {
              ew.value = selectedCallingData
                .filter((em) => em.text == ew.text)[0]
                .values.map((ed) => {
                  return ed.value;
                });
            }
            tempop[index] = ew;
          } else {
            tempOpOut.push(ew);
          }
        }
      );
      let callingSet = Object.assign(
        {},
        { ...breadCrumReducerData.callingCondition }
      );
      callingSet.condition = [...tempop, ...tempOpOut];

      setCallingCondition(callingSet)(dispatch);
    }
  }, []);

  return (
    <Accord
      title={"Calling Condition"}
      content={
        preferedTime ? (
          ""
        ) : (
          <CallingConditionTableData
            selectedCallingData={
              selectedCallingData?.length > 0 ? [...selectedCallingData] : []
            }
            isDisabled={true}
          />
        )
      }
      isChecked={selectedCallingData?.length > 0 ? true : false}
      isToggle={false}
      isHideContent={preferedTime ? true : false}
    />
  );
}
