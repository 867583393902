import React from "react";
import "./ShowText.scss";

interface props {
  fieldName: string;
  value: string;
  separator: string;
  extraWrapperClass?: string;
  noDataMessage: string;
  isLoading?: boolean;
}

export default function ShowText(props: props) {
  return (
    <div
      className={`show-text-field ${
        props.extraWrapperClass ? props.extraWrapperClass : ""
      }`}
    >
      <span className="show-text-field__key">{props.fieldName}</span>
      <span className="show-text-field__separator">{props.separator}</span>
      <span
        className="show-text-field__value"
        style={props.value ? {} : { color: "red" }}
      >
        {props.value || (!props.isLoading && props.noDataMessage)}
      </span>
    </div>
  );
}
