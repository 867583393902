// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r50ZHEjcGeLcuWNCKKhz .llIk_UC8M67y30WASOg_{width:100%;height:25px;display:flex;align-items:center;font-family:"Roboto";border-radius:3px;border:.393673px solid #d9d9d9;background:#f6f6f6}.r50ZHEjcGeLcuWNCKKhz .v3OtVOyI3GM9xrkgd7kk{font-weight:400;font-size:14px}.r50ZHEjcGeLcuWNCKKhz .K0b6Pfhy7nN82zNwRm2e{width:50%;height:80%;cursor:pointer;display:flex;flex-direction:column;justify-content:center;align-items:center;margin:2px !important;border-radius:3px !important;font-family:"Roboto";font-style:normal;font-weight:400;font-size:14px;color:#676767}.r50ZHEjcGeLcuWNCKKhz .WrgfN_JAmz1y5fUIaGil{background:#fff}`, "",{"version":3,"sources":["webpack://./src/components/generic/toggleComponent/ToggleComponent.module.scss"],"names":[],"mappings":"AAAA,4CACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,oBAAA,CACA,iBAAA,CACA,8BAAA,CACA,kBAAA,CAGF,4CACE,eAAA,CACA,cAAA,CAGF,4CACE,SAAA,CACA,UAAA,CACA,cAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,4BAAA,CACA,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,aAAA,CAGF,4CACE,eAAA","sourcesContent":[".toggleButtonWrapper .toggleButtonOuterDiv {\n  width: 100%;\n  height: 25px;\n  display: flex;\n  align-items: center;\n  font-family: \"Roboto\";\n  border-radius: 3px;\n  border: 0.393673px solid #d9d9d9;\n  background: #f6f6f6;\n}\n\n.toggleButtonWrapper .notActiveDiv {\n  font-weight: 400;\n  font-size: 14px;\n}\n\n.toggleButtonWrapper .buttonStyling {\n  width: 50%;\n  height: 80%;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin: 2px !important;\n  border-radius: 3px !important;\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  color: #676767;\n}\n\n.toggleButtonWrapper .activeDiv {\n  background: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleButtonWrapper": `r50ZHEjcGeLcuWNCKKhz`,
	"toggleButtonOuterDiv": `llIk_UC8M67y30WASOg_`,
	"notActiveDiv": `v3OtVOyI3GM9xrkgd7kk`,
	"buttonStyling": `K0b6Pfhy7nN82zNwRm2e`,
	"activeDiv": `WrgfN_JAmz1y5fUIaGil`
};
export default ___CSS_LOADER_EXPORT___;
