import React from "react";
import styles from "./FreePlanPage.module.scss";
import BeginnerPlan from "../../../../components/moduleComponents/paymentModuleComponents/beginnerPlan/BeginnerPlan";
import OrderSummaryFree from "../../../../components/moduleComponents/paymentModuleComponents/orderSummaryFree/OrderSummaryFree";
import { useNavigate } from "react-router-dom";
import { setHighlightedModule } from "../../../../redux/baseScreen/leftMenu";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { generateOrderId } from "../../../../redux/profile/billing/orderSummary/action/orderSummary.action";

const FreePlanPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedPurchasePlan: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.pricingPlan?.selectedPlan
  );

  const isCompare = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.pricingPlan?.selectedRoute
  );

  const generateOrderDetailsData = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.orderSummary?.generateOrderDetails
  );

  const loginDetails = useSelector((state: RootState) => {
    return state.loginReducer?.userLoginInfo;
  });

  const accessToken = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": accessToken,
  };

  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);
  const options: any = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = futureDate.toLocaleDateString("en-US", options);

  const handleClickFree = () => {
    navigate("/campaignmanager");
    dispatch(setHighlightedModule("Campaign"));
  };

  const handleGenerateOrderId = () => {
    const body = {
      userId: loginDetails?.userDetail["_id"],
      accountId: loginDetails?.accountDetails?.[0]?.id,
      planId: selectedPurchasePlan?.id,
      selectedDuration: "1 MONTH",
    };
    const data = {
      body: body,
      func: () => handleClickFree(),
      headers: headers,
    };
    dispatch(generateOrderId(data, true));
  };

  return (
    <div className={styles.wrapperheader}>
      <div className={styles.header}>Billing</div>
      <div className={styles.hr}></div>
      <div className={styles.wrapper}>
        <BeginnerPlan
          planName={selectedPurchasePlan?.planName}
          renewDate={formattedDate}
          status={selectedPurchasePlan?.price}
          onClickDelete={() => {
            if (isCompare === "detailsPage") {
              navigate("/profile/billing/plandetails");
            } else {
              navigate("/profile/billing/compareplans");
            }
          }}
        />
        <OrderSummaryFree
          numberOfItem={1}
          planName={selectedPurchasePlan?.planName}
          planPrice={selectedPurchasePlan?.price}
          currency={selectedPurchasePlan?.currency}
          onClick={() => {
            handleGenerateOrderId();
          }}
          buttonDisable={generateOrderDetailsData?.isLoading}
        />
      </div>
    </div>
  );
};

export default FreePlanPage;
