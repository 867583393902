import { status } from "./types";
const option: { status: status; color: string }[] = [
  { status: "Unavailable", color: "#F5222D" },
  { status: "Available", color: "#07B464" },
  { status: "In a Training", color: "#B91DF0" },
  { status: "On Lunch Break", color: "#DEB215" },
  { status: "On Coffee Break", color: "#DEB215" },
  { status: "In a Meeting", color: "#1DCAF0" },
  { status: "On Call", color: "#0174FF" },
];
export default option;
