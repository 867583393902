import React from "react";
import CustomIntegration from "../customIntegrationModel/CustomIntegration";
import { TrueCallerScreen } from "../trueCallerScreen/TrueCallerScreen";
import { WhatsAppScreen } from "../whatsAppScreen/WhatsAppScreen";
import { EmailScreen } from "../emailScreen/EmailScreen";
import { SMSScreen } from "../smsScreen/SMSScreen";
import { TelephonyScreen } from "../telephony/TelephonyScreen";

export const SelectedIntegrationDisplay = ({
  integrationName,
  integrationData,
  shown,
  close,
}: any) => {
  switch (integrationName?.toLowerCase()) {
    case "custom integration api":
      return (
        <CustomIntegration
          shown={shown}
          close={close}
          integrationIcon={integrationData?.icon}
        />
      );
    case "custom crm webhook":
      return null;
    case "salesforce":
      return null;
    case "email":
      return (
        <EmailScreen
          shown={shown}
          close={close}
          integrationName={integrationName}
          integrationData={integrationData}
        />
      );
    case "custom email webhook":
      return null;
    case "truecaller":
      return (
        <TrueCallerScreen
          shown={shown}
          close={close}
          integrationName={integrationName}
          integrationData={integrationData}
        />
      );
    case "sms":
      return (
        <SMSScreen
          shown={shown}
          close={close}
          integrationIcon={integrationData?.icon}
        />
      );
    case "custom sms webhook":
      return null;
    case "whatsapp":
      return (
        <WhatsAppScreen
          shown={shown}
          close={close}
          integrationName={integrationName}
          integrationData={integrationData}
        />
      );
    case "telephony, ports & did":
      return (
        <TelephonyScreen
          shown={shown}
          close={close}
          integrationIcon={integrationData?.icon}
        />
      );
    default:
      return (
        <CustomIntegration
          shown={shown}
          close={close}
          integrationIcon={integrationData?.icon}
        />
      );
  }
};
