import React from "react";
import { useSelector } from "react-redux";
import Summary from "./summary/Summary";
import FeedbackHistory from "./feedbackHistory/FeedbackHistory";
import ReportIssueUi from "../../transcript/reportissueui/ReportIssueUi";
import NewReportIssue from "../../transcript/newReportIssue/NewReportIssue";
import NoDatamodel from "../../../generic/noDatamodel/NoDatamodel";
import { noPhoneDataIcon } from "../../../../theme/assets/svg";
import "./SummaryFeedback.scss";
import { useCurrentPageTab } from "../../../../hooks";
import { loggerTab } from "../../../../constants/screenType";
import { ROLES } from "../../../../constants";
interface props {
  summary: any;
}

export default function SummaryFeedback(props: props) {
  const userRole: any = useSelector((state: any) => {
    return state.loginReducer.userLoginInfo.userDetail.role;
  });
  const reduxPhoneList: [] = useSelector((state: any) => {
    return state.loggerReducer?.transcriptReducer?.allPhoneNumberList;
  });
  const { currentLoggerPage } = useCurrentPageTab();

  return (
    <div className="wrapper__summaryFeedback">
      {reduxPhoneList.length > 0 || currentLoggerPage === loggerTab.report ? (
        <>
          <div className="summary__div">
            <Summary summary={props.summary} />
          </div>
          <div className="feedback-history__div">
            {userRole.toString().toLowerCase() ===
            ROLES.campaignAnalyst.name.toLowerCase() ? (
              // <ReportIssueUi />
              <NewReportIssue />
            ) : (
              <FeedbackHistory summary={props.summary} />
            )}
          </div>
        </>
      ) : (
        <div className="no-data-model-div">
          {/* <NoDatamodel srcImg={noPhoneDataIcon}></NoDatamodel> */}
        </div>
      )}
    </div>
  );
}
