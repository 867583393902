import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Dialer from "../../../generic/dialer/Dialer";
import { setCallStatus } from "../../../../../redux/agentDesktop/BaseScreenAD/action";
import "./DialerWrapper.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import rootPortalType from "../../../../../screens/rootPortal/portalTypes";
import { getCallSummaryAgentDesktop } from "../../../../../redux/agentDesktop/callLogger/action";

export default function DialerWrapper() {
  /* redux hooks */
  const selectedSessionId = useSelector(
    (store: RootState) => store.agentDesktopReducer.callLogger.selectedSessionId
  );
  const selectedConversationId = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.callLogger.selectedConversationId
  );
  const communicationType = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.communicationType
  );
  const callerInfo = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.callNotification
  );
  const rootPortal = useSelector(
    (store: RootState) => store.baseScreen.baseScreenState.rootPortalScreen
  );

  const dispatch = useDispatch();

  const [dialerData, setDialerData] = useState({
    name: callerInfo.callerName,
    number: `XXXXXX${callerInfo?.callerNumber?.slice(-4)}`,
    isOnHold: false,
    isOnMute: false,
  });

  /* handlers ---------- */
  const handleDialerBtnClick = (event: React.MouseEvent<HTMLElement>) => {
    // console.log((event.target as HTMLElement).dataset.buttonType);
    switch ((event.target as HTMLElement).dataset.buttonType) {
      case "hold": {
        if (dialerData.isOnHold) {
          dispatch(setCallStatus("unHold"));
          setDialerData((prev) => {
            return { ...prev, isOnHold: false };
          });
        } else {
          dispatch(setCallStatus("hold"));
          setDialerData((prev) => {
            return { ...prev, isOnHold: true };
          });
        }
        break;
      }
      case "mute": {
        if (dialerData.isOnMute) {
          setDialerData((prev) => {
            return { ...prev, isOnMute: false };
          });
          dispatch(setCallStatus("unMute"));
        } else {
          setDialerData((prev) => {
            return { ...prev, isOnMute: true };
          });
          dispatch(setCallStatus("mute"));
        }
        break;
      }
      case "end": {
        dispatch(setCallStatus("disconnect"));
        dispatch(getCallSummaryAgentDesktop(selectedConversationId));
        setDialerData((prev) => {
          return { ...prev, isOnHold: false, isOnMute: false };
        });
        break;
      }
    }
  };

  return (
    <div className="dialer-wrapper">
      {communicationType === "call" &&
        rootPortal !== rootPortalType.CALL_NOTIFICATION_AGENT_DESKTOP && (
          <Dialer
            callerData={dialerData}
            handleDialerBtnClick={handleDialerBtnClick}
          />
        )}
    </div>
  );
}
