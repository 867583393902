// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Vc7anZKFXtPbbNhYk17m{display:flex;align-items:center}.Vc7anZKFXtPbbNhYk17m img{width:14px}.Vc7anZKFXtPbbNhYk17m p{color:red;font-size:12px;position:relative;top:1px}`, "",{"version":3,"sources":["webpack://./src/components/generic/errorMessage/ErrorMessage.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CAEA,kBAAA,CAEJ,0BACI,UAAA,CAIJ,wBACI,SAAA,CACA,cAAA,CACA,iBAAA,CACA,OAAA","sourcesContent":[".errorTopDiv{\n    display: flex;\n    /* justify-content: center; */\n    align-items: center;\n}\n.errorTopDiv img{\n    width: 14px;\n\n}\n\n.errorTopDiv p{\n    color: red;\n    font-size: 12px;\n    position: relative;\n    top: 1px;  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorTopDiv": `Vc7anZKFXtPbbNhYk17m`
};
export default ___CSS_LOADER_EXPORT___;
