import { takeLatest } from "redux-saga/effects";
import actionTypes from "../schedularSetting.actionTypes";
import {
  saveSchedularDataAPIWorker,
  getSchedularDataAPIWorker,
} from "./schedularSetting.saga";

export default function* campaignV2DetailsWatcher(): any {
  yield takeLatest(
    actionTypes.SCHEDULAR_DATA_API_REQUEST,
    saveSchedularDataAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_SCHEDULAR_DATA_API_REQUEST,
    getSchedularDataAPIWorker
  );
}
