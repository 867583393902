import { Reducer } from "redux";
import actionType from "../actionTypes";

export interface stateInterface {
  invoiceDetails: any;
  isLoadingInvoiceDetails: boolean;
  invoiceDetailsError: string;
  downloadInvoiceDetail: { data: any; isLoading: boolean; error: any };
  sortInvoicesData: object;
}
const initialState: stateInterface = {
  invoiceDetails: [],
  isLoadingInvoiceDetails: false,
  invoiceDetailsError: "",
  downloadInvoiceDetail: { data: {}, isLoading: false, error: {} },
  sortInvoicesData: {},
};

const invoicesReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionType.INVOICES_DATA_API_REQUEST: {
      return {
        ...state,
        isLoadingInvoiceDetails: true,
        invoiceDetails: [],
        invoiceDetailsError: "",
      };
    }
    case actionType.INVOICES_DATA_API_SUCCESS: {
      return {
        ...state,
        isLoadingInvoiceDetails: false,
        invoiceDetails: action.payload,
      };
    }
    case actionType.INVOICES_DATA_API_FAILURE: {
      return {
        ...state,
        isLoadingInvoiceDetails: false,
        invoiceDetailsError: action.payload,
      };
    }
    case actionType.SORT_INVOICES_DATA: {
      return {
        ...state,
        sortInvoicesData: action.payload,
      };
    }
    case actionType.USER_LOGOUT: {
      return { ...initialState };
    }
    case actionType.DOWNLOAD_INVOICES_API_REQUEST: {
      return {
        ...state,
        downloadInvoiceDetail: { data: {}, isLoading: true, error: {} },
      };
    }
    case actionType.DOWNLOAD_INVOICES_API_SUCCESS: {
      return {
        ...state,
        downloadInvoiceDetail: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionType.DOWNLOAD_INVOICES_API_FAILURE: {
      return {
        ...state,
        downloadInvoiceDetail: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }

    case actionType.CLEAR_DOWNLOAD_INVOICE: {
      return {
        ...state,
        downloadInvoiceDetail: { data: {}, isLoading: false, error: {} },
      };
    }
    case actionType.USER_LOGOUT: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
};

export default invoicesReducer;
