import React, { useState, useEffect } from "react";
import styles from "./DateSelectionModal.module.scss";
import Modal from "../../../generic/modal/Modal";
import Icon from "../../../generic/icon/Icon";
import Button from "../../../generic/button/Button";
import DropDown from "../../../generic/dropdownsaarthi2/DropdownSaarthi";
import InputField from "../../../generic/inputField/InputField";
import {
  dropDownArrowIcon,
  rightArrowLineIcon,
  leftArrowLineIcon,
} from "../../../../theme/assets/svg/strategySvg";
import {
  setLoadingCreate,
  setAllNudgeData,
} from "../../../../redux/strategy/nudgeCreation/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import {
  addWorkflowAPIData,
  createdStrategy,
} from "../../../../redux/strategy/strategyCreation/actions";
import _, { filter } from "lodash";
import { Navigate, useNavigate } from "react-router-dom";

interface DateSelectionModalProps {
  show?: boolean;
  close?: CallableFunction;
}
interface workFlow {
  originalName: string;
  displayName: string;
  min: number;
  max: number;
  type?: string
}

export const DateSelectionModal = ({
  show,
  close,
}: DateSelectionModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const clientName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );

  const workspaceFlowData = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.workflowDetails
  );

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
  );

  const moduleSelectedScreen = useSelector(
    (store: RootState) => store?.baseScreen?.leftMenu?.highlightedModule
  );

  const selectedStrategy = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.createdStrategy
  );
  // console.log(selectedStrategy, "addd");

  const resultOfAddFlows = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.createdStrategy?.flows
  );
  console.log("lala 1", resultOfAddFlows, workspaceFlowData)

  const isLoadingAddFlow = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.isLoadingAddWorkflowDetails
  );
  const clientNameByDropdown = useSelector(
    (store: RootState) => store.campaignReducer?.campaignClientName
  );
  const campaignData = useSelector((store: RootState) => {
    return store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo;
  });

  const teamUseCaseId = useSelector(
    (store: RootState) => store?.loginReducer?.currentTeam?.useCaseId
  );
  const currentTeamId = useSelector(
    (state: RootState) => state.loginReducer.currentTeam.id
  );

  const typeOfMode = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.typeOfstrategyMode;
  });
  // console.log("add", resultOfAddFlows);

  const minValuesFromAddedFlows = _.map(resultOfAddFlows, "min");
  const maxValuesFromAddedFlows = _.map(resultOfAddFlows, "max");
  const selectedMinValue = Math.min(...minValuesFromAddedFlows);
  const selectedMaxValue = Math.max(...maxValuesFromAddedFlows);
  const selectedRadioOptionFromFlow = resultOfAddFlows?.length ? resultOfAddFlows?.[0]?.type || "dueDate" : "dueDate"

  // console.log("selected", selectedMinValue, selectedMaxValue);

  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
  const [isBucketNameValid, setIsBucketNameValid] = useState<string[]>([]);
  const [isBucketEmptyValid, setIsBucketEmptyValid] = useState<string[]>([]);
  const [isBucketRepeatValid, setIsBucketRepeatValid] = useState<string[]>([]);
  const [isBucketInValid, setIsBucketInValid] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<string>("");
  const [selectRadio, setSelectedRadio] = useState("")

  const [selectedDayBefore, setSelectedDayBefore] = useState<number>(
    resultOfAddFlows?.length != 0 ? selectedMinValue : -15
  );
  const [selectedDayAfter, setSelectedDayAfter] = useState<number>(
    resultOfAddFlows?.length != 0 ? selectedMaxValue : 90
  );

  const [flowWiseWorkSpace, setFlowWiseWorkSpace] = useState<any>([])

  function range(start: number, end: number) {
    const arr = [];
    for (let i = start; i <= end; i++) {
      arr.push(i);
    }
    return arr;
  }

  const daysBeforeList = workspaceFlowData?.min
    ? range(workspaceFlowData?.min[0], workspaceFlowData?.min[1])
    : [];

  const daysAfterList = workspaceFlowData?.max
    ? range(workspaceFlowData?.max[0], workspaceFlowData?.max[1])
    : [];

  const getAllWithSelectedFlows = workspaceFlowData?.data?.filter((d: any) => d?.type === selectRadio)?.map(
    (x: workFlow) => {
      if (x.originalName) {
        const item = selectedStrategy?.flows?.find(
          ({ originalName }: workFlow) => originalName === x.originalName
        );
        return item
          ? {
            originalName: x.originalName,
            displayName: item.displayName,
            min: item.min,
            max: item.max,
            type: item?.type || x?.type
          }
          : {
            originalName: x.originalName,
            displayName: x.displayName,
            min: x.min,
            max: x.max,
            type: x?.type
          };
      }
    }
  );
  // console.log("adddd", getAllWithSelectedFlows);
  console.log("gggg", getAllWithSelectedFlows)

  const resAddedFlows = resultOfAddFlows?.filter(
    (data: workFlow) => data !== undefined
  );

  const ressMax =
    resAddedFlows && Math.max(...resAddedFlows.map((o: workFlow) => o.max));
  const ressMin =
    resAddedFlows && Math.min(...resAddedFlows.map((o: workFlow) => o.min));

  function between(x: number, min: number, max: number) {
    return x >= min && x <= max;
  }

  const [dateSelectionInput, setDateSelectionInput] = useState<workFlow[]>(
    resultOfAddFlows?.length != 0
      ? getAllWithSelectedFlows?.filter(
        (data: workFlow) =>
          between(data.max, ressMin, ressMax) && data?.type === selectRadio ||
          between(data.min, ressMin, ressMax) && data?.type === selectRadio
      )
      : [
        {
          displayName: "",
          originalName: "",
          max: 0,
          min: 0,
        },
      ]
  );

  const selectedWorkflow =
    resultOfAddFlows?.length != 0
      ? getAllWithSelectedFlows?.filter(
        (data: workFlow) =>
          between(data.max, selectedDayBefore, selectedDayAfter) && data?.type === selectRadio ||
          between(data.min, selectedDayBefore, selectedDayAfter) && data?.type === selectRadio
        // data?.max <= selectedDayAfter && data?.min >= selectedDayBefore
      )
      : workspaceFlowData?.data?.filter(
        (data: workFlow) =>
          between(data.max, selectedDayBefore, selectedDayAfter) && data?.type === selectRadio ||
          between(data.min, selectedDayBefore, selectedDayAfter) && data?.type === selectRadio
        // data?.max <= selectedDayAfter && data?.min >= selectedDayBefore
      );

  const onChangeDayBefore = (date: number) => {
    setSelectedDayBefore(date);
  };

  const onChangeDayAfter = (date: number) => {
    setSelectedDayAfter(date);
  };

  const handleCancel = () => {
    // setDateSelectionInput(workspaceFlowData?.data);
    // setSelectedDayBefore(-10);
    // setSelectedDayAfter(90);
    close && close();
  };

  const dateSelectionInputLen =
    dateSelectionInput && dateSelectionInput?.length;

  const finalFlows =
    dateSelectionInput &&
    dateSelectionInput?.map((x: workFlow, index: number) => {
      if (x.displayName == "") {
        const item = selectedWorkflow?.find(
          ({ originalName }: workFlow) => originalName === x.originalName
        );
        return item
          ? {
            ...item,
            displayName: item.displayName,
          }
          : {
            ...item,
          };
      }
      // if (x.displayName == "") {
      //   const item = selectedWorkflow?.find(
      //     ({ originalName }: any) => originalName === x.originalName
      //   );
      //   return item
      //     ? {
      //         originalName: x.originalName,
      //         displayName: item.displayName,
      //         min: x.min,
      //         max: x.max,
      //       }
      //     : {
      //         originalName: x.originalName,
      //         displayName: x.displayName,
      //         min: x.min,
      //         max: x.max,
      //       };
      // }
      if (index == 0) {
        const item = selectedWorkflow?.find(
          ({ originalName }: workFlow) => originalName === x.originalName
        );
        return (
          item && {
            originalName: x.originalName,
            displayName: x.displayName,
            min: selectedDayBefore,
            max: x.max,
            type: x?.type
          }
        );
      }
      if (dateSelectionInputLen === index + 1) {
        const item = selectedWorkflow?.find(
          ({ originalName }: workFlow) => originalName === x.originalName
        );
        return (
          item && {
            originalName: x.originalName,
            displayName: x.displayName,
            min: x.min,
            max: selectedDayAfter,
            type: x?.type
          }
        );
      }
      return {
        originalName: x.originalName,
        displayName: x.displayName,
        min: x.min,
        max: x.max,
        type: x?.type
      };
    });

  const finalFlowsForSet = dateSelectionInput?.map((x: workFlow) => {
    if (x.displayName == "") {
      const item = selectedWorkflow?.find(
        ({ originalName }: workFlow) => originalName === x.originalName
      );
      return item
        ? {
          ...item,
          displayName: item.displayName,
        }
        : {
          ...item,
        };
    } else {
      return {
        originalName: x.originalName,
        displayName: x.displayName,
        min: x.min,
        max: x.max,
        type: x?.type
      };
    }
  });

  // const maxSelected = range(0, selectedDayAfter);
  // const minSelected = range(selectedDayBefore, 0);
  // const dateRangeSelected = {
  //   min: [Math.min(...minSelected), Math.max(...minSelected)],
  //   max: [Math.min(...maxSelected), Math.max(...maxSelected)],
  // };

  const handleAddWorkflow = () => {
    // dispatch(
    //   setWorkflowAPIData({
    //     token: token,
    //     accountId: accountId,
    //     data: finalFlowsForSet,
    //     clientName: clientName,
    //   })
    // );
    console.log("flows Abhi", finalFlows, finalFlowsForSet, selectedWorkflow)
    if (moduleSelectedScreen !== "Strategy") {
      navigate("/campaignmanager/nudgecreation");
    }
    if (selectRadio === "flow") {
      const finalFlowRange = flowWiseWorkSpace?.map((details: any) => {
        return {
          ...details,
          min: selectedDayBefore,
          max: selectedDayAfter
        }
      })

      dispatch(
        addWorkflowAPIData({
          token: token,
          strategyId: selectedStrategy?.id,
          addFlows: finalFlowRange,
          setFlows: finalFlowRange,
          // clientName: clientNameByDropdown ? clientNameByDropdown : clientName,
          clientName:
            clientNameByDropdown?.length > 0
              ? clientNameByDropdown
              : campaignData?.accountName?.length > 0
                ? campaignData?.accountName
                : clientName,
          accountId:
            moduleSelectedScreen !== "Strategy"
              ? campaignData?.accountId
              : accountId,
          bucketCreationSuccess: false,
          useCaseId: teamUseCaseId,
          teamId: currentTeamId
        })
      );
      dispatch(setLoadingCreate(true));
      dispatch(setAllNudgeData([]));

    } else {
      dispatch(
        addWorkflowAPIData({
          token: token,
          strategyId: selectedStrategy?.id,
          addFlows: finalFlows,
          setFlows: finalFlowsForSet,
          // clientName: clientNameByDropdown ? clientNameByDropdown : clientName,
          clientName:
            clientNameByDropdown?.length > 0
              ? clientNameByDropdown
              : campaignData?.accountName?.length > 0
                ? campaignData?.accountName
                : clientName,
          accountId:
            moduleSelectedScreen !== "Strategy"
              ? campaignData?.accountId
              : accountId,
          bucketCreationSuccess: false,
          useCaseId: teamUseCaseId,
          teamId: currentTeamId
        })

      );
      dispatch(setLoadingCreate(true));
      dispatch(setAllNudgeData([]));
      // if (moduleSelectedScreen == "Campaign") {
      //   const data_to_send: any = {
      //     clientName: clientName,
      //   };
      //   dispatch(
      //     getStrategiesAPIData({
      //       ...data_to_send,
      //     })
      //   );
      // }
    }
  };

  const handleDisplayNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: workFlow
  ) => {
    const newData = dateSelectionInput?.map((e: workFlow) => {
      // if (isFocused !== e.originalName) {
      //   handleBucketNameValidation(event.target.value);
      // }
      if (data.originalName !== e.originalName) {
        return e;
      } else {
        return {
          ...e,
          displayName: event.target.value,
        };
      }
    });
    setDateSelectionInput(newData);
  };

  const handleBucketNameValidation = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: string
  ) => {
    if (event.target.value.trim().length > 30) {
      for (const each of dateSelectionInput) {
        const arrr: string[] = [...isBucketNameValid, data];
        setIsBucketNameValid(arrr);
      }
    }
    if (event.target.value.trim().length < 31) {
      for (const each of dateSelectionInput) {
        const index = isBucketNameValid.indexOf(data);
        if (index > -1) {
          isBucketNameValid.splice(index, 1);
        }
        setIsBucketNameValid(isBucketNameValid);
      }
    }
  };

  const handleBucketEmptyValidation = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: string
  ) => {
    if (event.target.value != "" && event.target.value.trim().length == 0) {
      for (const each of dateSelectionInput) {
        const arrr: string[] = [...isBucketEmptyValid, data];
        const unique = arrr.filter(
          (item: string, i: number, ar: string[]) => ar.indexOf(item) === i
        );
        setIsBucketEmptyValid(unique);
      }
    }
    if (event.target.value == "" || event.target.value.trim().length > 0) {
      for (const each of dateSelectionInput) {
        const index = isBucketEmptyValid.indexOf(data);
        if (index > -1) {
          isBucketEmptyValid.splice(index, 1);
        }
        setIsBucketEmptyValid(isBucketEmptyValid);
      }
    }
  };

  const handleBucketNameRepeatValidation = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: string
  ) => {
    const orgNames = _.map(workspaceFlowData?.data, "originalName");
    const dispalyNames = _.map(dateSelectionInput, "displayName");
    if (
      event.target.value.trim().length > 0 &&
      orgNames.includes(event.target.value.trim())
    ) {
      for (const each of workspaceFlowData.data) {
        // if (isFocused != each.originalName) {
        const arrr: string[] = [...isBucketRepeatValid, data];
        setIsBucketRepeatValid(arrr);
        //}
      }
    }
    if (
      event.target.value.trim().length > 0 &&
      !orgNames.includes(event.target.value.trim())
    ) {
      for (const each of workspaceFlowData.data) {
        const index = isBucketRepeatValid.indexOf(data);
        if (index > -1) {
          isBucketRepeatValid.splice(index, 1);
        }
        setIsBucketRepeatValid(isBucketRepeatValid);
      }
    }
    if (
      event.target.value.trim().length > 0 &&
      dispalyNames.includes(event.target.value.trim())
    ) {
      for (const each of workspaceFlowData.data) {
        // if (isFocused != each.originalName) {
        const arrr: string[] = [...isBucketRepeatValid, data];
        setIsBucketRepeatValid(arrr);
        // }
      }
    }
    if (
      event.target.value.trim().length > 0 &&
      !dispalyNames.includes(event.target.value.trim())
    ) {
      for (const each of workspaceFlowData.data) {
        const index = isBucketRepeatValid.indexOf(data);
        if (index > -1) {
          isBucketRepeatValid.splice(index, 1);
        }
        setIsBucketRepeatValid(isBucketRepeatValid);
      }
    }
  };

  // const handleBucketValidation: any = () => {
  //   if (isBucketRepeatValid.length > 0) {
  //     return true;
  //   }
  //   if (isBucketEmptyValid.length > 0) {
  //     return true;
  //   }
  //   if (isBucketNameValid.length > 0) {
  //     return false;
  //   }
  //   return false;
  // };

  // useEffect(() => {
  //   setIsBucketInValid(handleBucketValidation());
  // }, [isBucketRepeatValid, isBucketEmptyValid, isBucketNameValid]);

  // useEffect(() => {
  //   if (isFocused.trim().length == 0) {
  //     setIsBucketInValid(false);
  //   } else {
  //     setIsBucketInValid(true);
  //   }
  // }, [isFocused]);

  // console.log(
  //   isBucketNameValid,
  //   isBucketEmptyValid,
  //   isBucketRepeatValid,
  //   dateSelectionInput,
  //   //isBucketInValid,
  //   // isFocused,
  //   // isFocused.trim().length === 0,
  //   "lll"
  // );
  useEffect(() => {
    selectedRadioOptionFromFlow?.length && setSelectedRadio(selectedRadioOptionFromFlow)
  }, [selectedRadioOptionFromFlow])
  useEffect(() => {
    setDateSelectionInput(selectedWorkflow);
  }, [selectedWorkflow?.length]);

  useEffect(() => {
    if (resultOfAddFlows?.length !== 0 && moduleSelectedScreen !== "Strategy") {
      setDateSelectionInput(selectedWorkflow);
      setSelectedDayAfter(selectedMaxValue);
      setSelectedDayBefore(selectedMinValue);
    }
  }, [selectedStrategy, moduleSelectedScreen]);

  useEffect(() => {
    if (resultOfAddFlows?.length === 0 && moduleSelectedScreen !== "Strategy") {
      setDateSelectionInput(workspaceFlowData?.data?.filter((e: any) => e?.type === selectRadio));
      setSelectedDayAfter(90);
      setSelectedDayBefore(-15);
    }
  }, [selectedStrategy, moduleSelectedScreen]);

  useEffect(() => {
    if (selectedStrategy.bucketCreationSuccess) {
      if (moduleSelectedScreen !== "Strategy") {
        dispatch(
          createdStrategy({ ...selectedStrategy, bucketCreationSuccess: false })
        );
        navigate("/campaignmanager/nudgecreation");
      } else {
        dispatch(
          createdStrategy({ ...selectedStrategy, bucketCreationSuccess: false })
        );
        navigate("/strategy/nudgecreation");
      }
    }
  }, [selectedStrategy.bucketCreationSuccess]);
  const handleRadioChange = (data: any) => {
    setSelectedRadio(data)
  }
  console.log("checkgghh", selectedDayBefore)
  useEffect(
    () => {
      if (resultOfAddFlows?.length > 0 && resultOfAddFlows?.[0]?.type == "flow") {
        setFlowWiseWorkSpace([...resultOfAddFlows])
      }
    }, [resultOfAddFlows]
  )
  useEffect(() => {
    if (selectRadio === "flow") {
      setSelectedDayBefore(0)
    }
  }, [selectRadio])
  // handleChange flowWise work space 

  const handleFlowWise = (e: any, data: any) => {
    const { checked } = e?.target
    let selectedData: any = [...flowWiseWorkSpace]
    if (checked) {
      selectedData.push(data);
    } else {
      selectedData = selectedData.filter((option: any) => option?.originalName !== data?.originalName);
    }
    setFlowWiseWorkSpace([...selectedData])
  }
  const selectedFlowWiseName = flowWiseWorkSpace?.map((data: any) => data?.originalName)
  console.log("ha ji ", flowWiseWorkSpace, selectedFlowWiseName, resultOfAddFlows)
  return (
    <div>
      <Modal
        shown={show}
        close={() => {
          handleCancel();
          close && close();
        }}
        extraClassStyleModalContent={styles.dateSelectionModalStyling}
      >
        <div
          className={styles.modalInfoDiv}
          id="auto_dateSelectionModalInfoDiv"
        >
          <div
            className={styles.dateSelectionModalHeading}
            id="auto_dateSelectionModalHeading"
          >
            Setup Workspace
            <div className={styles.radioContainer}>
              {[{ displayName: "Due Date", name: "dueDate" },
              { displayName: "Flow", name: "flow" }]?.map((data: any) => {
                return (
                  <div className={styles.radioDivs}>
                    <input
                      name="callBack"
                      value=""
                      type="radio"
                      checked={selectRadio === data?.name}
                      onChange={() => { handleRadioChange(data?.name) }}
                    />
                    <p>{data?.displayName}</p>
                  </div>
                )
              })}

            </div>
          </div>
        </div>

        <div
          className={styles.dateSelectionFormDiv}
          id="auto_dateSelectionFormDiv"
        >
          {
            <div
              className={styles.dateSelectionTopDiv}
              id="auto_dateSelectionTopDiv"
            >
              <div className={styles.dayDropDown} id="auto_dayBeforeDropDownDiv">
                <div
                  className={styles.dayDropDownHeading}
                  id="auto_dayBeforeDropDownLabel"
                >
                  Days Before
                </div>
                <DropDown
                  id="auto_dayBeforeDropDown"
                  droplist={{
                    optionList: selectRadio === "flow" ? [0] : daysBeforeList,
                    placeHolderText:
                      resultOfAddFlows?.length != 0
                        ? selectedDayBefore
                        : selectedDayBefore,
                    imgSrcRight: dropDownArrowIcon,
                  }}
                  selectedItem={(item: number) => onChangeDayBefore(item)}
                  extraClassSelectedArea={styles.dropDownSelectedStyling}
                  extraClassToBeSelectedArea={styles.dropDownToBeSelectedStyling}
                  extraClassPlaceHolderStyling={styles.dropDownPlaceHolderStyling}
                  extraClassDropDownUl={styles.dropDownUlStyling}
                  outerSelected={selectedDayBefore}
                />
              </div>
              <div className={styles.dueDateDiv} id="auto_dueDateDiv">
                <Icon
                  img_src={leftArrowLineIcon}
                  extraClass={styles.arrowImageStyling}
                  id="auto_leftArrowLineIcon"
                />
                <p className={styles.dueDateTextStyling}>{selectRadio === "flow" ? "Current Date" : "Due_Date"}</p>
                <Icon
                  img_src={rightArrowLineIcon}
                  extraClass={styles.arrowImageStyling}
                  id="auto_rightArrowLineIcon"
                />
              </div>
              <div className={styles.dayDropDown} id="auto_dayAfterDropDownDiv">
                <div
                  className={styles.dayDropDownHeading}
                  id="auto_dayAfterDropDownLabel"
                >
                  Days After
                </div>
                <DropDown
                  id="auto_dayAfterDropDown"
                  droplist={{
                    optionList: daysAfterList,
                    placeHolderText:
                      resultOfAddFlows?.length != 0
                        ? selectedDayAfter
                        : selectedDayAfter,
                    imgSrcRight: dropDownArrowIcon,
                  }}
                  selectedItem={(item: number) => onChangeDayAfter(item)}
                  extraClassSelectedArea={styles.dropDownSelectedStyling}
                  extraClassToBeSelectedArea={styles.dropDownToBeSelectedStyling}
                  extraClassPlaceHolderStyling={styles.dropDownPlaceHolderStyling}
                  extraClassDropDownUl={styles.dropDownUlStyling}
                  // outerSelected={
                  //   resultOfAddFlows?.length != 0
                  //     ? selectedMaxValue
                  //     : selectedDayAfter
                  // }
                  outerSelected={selectedDayAfter}
                />
              </div>
            </div>
          }

          <div className={styles.dateSelectionDiv}>

            {selectRadio === "flow" ?

              workspaceFlowData?.data?.filter((e: any) => e?.type === "flow")?.map((detail: any, index: number) => {
                return (
                  <div key={index} className={styles.workSpaceSelcetionDiv}>
                    <input type={"checkbox"}
                      checked={selectedFlowWiseName?.includes(detail?.originalName)}
                      onChange={(e) => {
                        handleFlowWise(e, detail)
                      }}

                    />
                    <span className={styles.workSpaceName}>{detail?.displayName}</span>
                  </div>
                )
              })
              :

              dateSelectionInput?.map((data: workFlow, index: number) => {
                return (
                  <div key={data?.originalName} id="auto_dateSelectionInputDiv">
                    <InputField
                      id="auto_dateSelectionInput"
                      label={
                        data?.originalName === data?.displayName
                          ? ""
                          : data?.originalName
                      }
                      extraClass={styles.dateInputDivStyling}
                      extraLabelClass={styles.dateLabelFieldStyling}
                      className={styles.dateInputFieldStyling}
                      type="text"
                      placeholder={
                        selectedWorkflow && selectedWorkflow[index]?.displayName
                      }
                      onChange={(event) => {
                        handleDisplayNameChange(event, data);
                        handleBucketEmptyValidation(event, data?.originalName);
                        handleBucketNameValidation(event, data?.originalName);
                        handleBucketNameRepeatValidation(
                          event,
                          data?.originalName
                        );
                      }}
                      onFocus={() => {
                        setIsReadOnly(false);
                        setIsFocused(data?.originalName);
                      }}
                      onBlur={() => {
                        setIsReadOnly(true);
                      }}
                      value={
                        selectedWorkflow &&
                          selectedWorkflow[index]?.displayName !== data?.displayName
                          ? data?.displayName
                          : resultOfAddFlows?.length != 0
                            ? data?.displayName
                            : ""
                      }
                      maxLength={31}
                    />
                    {isBucketRepeatValid.includes(data?.originalName) ? (
                      <div
                        className={styles.errorMsgStyling}
                        id="auto_errMsg_bucketName"
                      >
                        2 buckets cannot have the same name.
                      </div>
                    ) : isBucketNameValid.includes(data?.originalName) ? (
                      <div
                        className={styles.errorMsgStyling}
                        id="auto_errMsg_bucketName_length"
                      >
                        A bucket name can be a maximum of 30 characters.
                      </div>
                    ) : null}
                  </div>
                );
              })}
          </div>
          <div className={styles.dateSelectionButtonDiv}>
            <Button
              text="Cancel"
              extraClass={`${styles.buttonStyling} ${styles.cancelButtonStyle}`}
              onClick={handleCancel}
              id="auto_date_cancel_btn"
            />
            <Button
              text={isLoadingAddFlow ? "Creating ..." : "Create"}
              extraClass={`${styles.buttonStyling} ${styles.createButtonActiveStyle}`}
              onClick={handleAddWorkflow}
              disabled={
                isBucketNameValid.length > 0 ||
                isBucketRepeatValid.length > 0 ||
                isBucketEmptyValid.length > 0 ||
                isLoadingAddFlow
              }
              id="auto_date_create_btn"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
