import { Reducer } from "redux";
import actionType from "../createCampaign.actionTypes";
import { CreateCampaignInterface } from "../interface";
import { LeftOutlined } from "@ant-design/icons";

const initialState: CreateCampaignInterface = {
  campaignASROptions: {
    data: null,
    status: "idle",
    error: null,
  },
  campaignTTSOptions: {
    data: null,
    status: "idle",
    error: null,
  },
  campaignOptions: {
    data: null,
    status: "idle",
    error: null,
  },
  campaignData: {
    data: null,
    status: "idle",
    error: null,
    isNavigate: false,
  },
  savedCampaignInfo: {
    ASR: "",
    TTS: "",
    accountId: "",
    accountName: "",
    agentId: "",
    callType: "",
    campaignId: "",
    campaignName: "",
    campaignType: [],
    channels: [],
    customerUploadStatus: "",
    description: "",
    domainId: "",
    endDate: "",
    flowType: {},
    id: "",
    isCompleted: false,
    isFilePicked: false,
    language: [],
    leadType: "",
    mappingStatus: "",
    parentLabel: "",
    personas: [{}],
    startDate: "",
    strategyId: "",
    teamId: null,
    telephonyId: null,
    telephonyName: "",
    updatedAt: "",
    uploadedDate: "",
    useCaseId: "",
    wasDataUploadedThroughApi: false,
    uploadedSuccessfully: false,
    isPaymentLink : false
  },
};

const createCampaignReducer: Reducer<CreateCampaignInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionType.GET_CAMPAIGN_ASR_API_REQUEST: {
      return {
        ...state,
        campaignASROptions: {
          data: null,
          status: "loading",
          error: null,
        },
      };
    }
    case actionType.GET_CAMPAIGN_ASR_API_SUCCESS: {
      return {
        ...state,
        campaignASROptions: {
          data: action.payload,
          status: "success",
          error: null,
        },
      };
    }
    case actionType.GET_CAMPAIGN_ASR_API_FAILURE: {
      return {
        ...state,
        campaignASROptions: {
          data: null,
          status: "failure",
          error: action.payload,
        },
      };
    }

    case actionType.GET_CAMPAIGN_TTS_API_REQUEST: {
      return {
        ...state,
        campaignTTSOptions: {
          data: null,
          status: "loading",
          error: null,
        },
      };
    }
    case actionType.GET_CAMPAIGN_TTS_API_SUCCESS: {
      return {
        ...state,
        campaignTTSOptions: {
          data: action.payload,
          status: "success",
          error: null,
        },
      };
    }
    case actionType.GET_CAMPAIGN_TTS_API_FAILURE: {
      return {
        ...state,
        campaignTTSOptions: {
          data: null,
          status: "failure",
          error: action.payload,
        },
      };
    }

    case actionType.GET_CAMPAIGN_OPTIONS_API_REQUEST: {
      return {
        ...state,
        campaignOptions: {
          data: null,
          status: "loading",
          error: null,
        },
      };
    }
    case actionType.GET_CAMPAIGN_OPTIONS_API_SUCCESS: {
      return {
        ...state,
        campaignOptions: {
          data: action.payload,
          status: "success",
          error: null,
        },
      };
    }
    case actionType.GET_CAMPAIGN_OPTIONS_API_FAILURE: {
      return {
        ...state,
        campaignOptions: {
          data: null,
          status: "failure",
          error: action.payload,
        },
      };
    }

    case actionType.CREATE_CAMPAIGN_DATA_API_REQUEST:
    case actionType.UPDATE_CAMPAIGN_DATA_API_REQUEST: {
      return {
        ...state,
        campaignData: {
          data: null,
          status: "loading",
          error: null,
          isNavigate: false,
        },
      };
    }

    case actionType.CREATE_CAMPAIGN_DATA_API_SUCCESS:
    case actionType.UPDATE_CAMPAIGN_DATA_API_SUCCESS: {
      return {
        ...state,
        campaignData: {
          data: action.payload,
          status: "success",
          error: null,
          isNavigate: true,
        },
      };
    }

    case actionType.CREATE_CAMPAIGN_DATA_API_FAILURE:
    case actionType.UPDATE_CAMPAIGN_DATA_API_FAILURE: {
      return {
        ...state,
        campaignData: {
          data: null,
          status: "failure",
          error: action.payload,
          isNavigate: false,
        },
      };
    }

    case actionType.SET_CAMPAIGN_DATA: {
      const { data, isNavigate } = action.payload;
      console.log("data" ,data)
      const updatedSavedCampaignInfo: any = { ...state.savedCampaignInfo };

      if (data !== undefined) {
        const channels = ["Call", "WhatsApp", "SMS", "Mail"];
        const lowerCaseArray = data?.channels?.map((item: string) =>
          item.toLowerCase()
        );
        const formattedChannels = lowerCaseArray?.map((item: string) => {
          const index = channels?.findIndex(
            (secondItem) => secondItem?.toLowerCase() === item
          );
          if (index !== -1) {
            return channels[index] === "Mail" ? "mail" : channels[index];
          } else {
            return item;
          }
        });

        let result: any;

        if (!Array.isArray(data?.flowType)) {
          const formattedFlows = new Set<any>();

          if (data?.flowType?.maiaFlow) {
            data?.flowType?.maiaFlow?.forEach((flow: any) =>
              formattedFlows.add(JSON.stringify(flow))
            );
          }
          const flowTypeArr = Array.from(formattedFlows).map((flowStr) =>
            JSON.parse(flowStr)
          );
          result = {
            ...data,
            channels: formattedChannels,
            flowType: flowTypeArr,
          };
        } else {
          result = {
            ...data,
            channels: formattedChannels,
          };
        }

        Object.keys(result).forEach((key) => {
          if (updatedSavedCampaignInfo[key] !== undefined) {
            updatedSavedCampaignInfo[key] = result[key];
          }
        });
      }

      return {
        ...state,
        campaignData: {
          ...state.campaignData,
          ...(isNavigate !== undefined ? { isNavigate } : {}),
        },
        savedCampaignInfo:
          data !== undefined
            ? updatedSavedCampaignInfo
            : state.savedCampaignInfo,
      };
    }

    case actionType.CLEAR_CAMPAIGN_DATA:
      return initialState;
    default: {
      return state;
    }
  }
};

export default createCampaignReducer;
