import React, { useEffect, useState } from "react";
import styles from "./PlanSummary.module.scss";
import { SingleSelectDD } from "../../../agentDesktop/generic/fields";
import { deleteIcon } from "../../../../theme/assets/genericSvg/index";
import moment from "moment";
import AddButton from "../../../generic/addButton/AddButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import {
  setSelectedAddons,
  setSelectedDuration,
} from "../../../../redux/profile/billing/orderSummary/action/orderSummary.action";
import { getCoupanPlan } from "../../../../redux/profile/billing/pricingPlan/actions";
type Props = {
  addon: any;
  onClickDelete: () => void;
  data: any;
};

const PlanSummary = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const planDurationsData: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.pricingPlan?.pricingPlanDetails?.data
        ?.planDuration
  );

  const isCompare = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.pricingPlan?.selectedRoute
  );
  const accessToken = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
  );

  const [month, setMonth] = useState("");
  const [date, setDate] = useState();
  const [addons, setAddons] = useState<any>([]);

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": accessToken,
  };

  const handleOnChange = (values: any) => {
    const value = values?.split(" ")[0];
    setMonth(values);
    const currentDate = moment();
    const futureDate = currentDate.add(value, "months");
    setDate(futureDate.format("DD MMM YYYY"));
  };

  useEffect(() => {
    const currentDate = moment();
    const futureDate = currentDate.add(3, "months");
    setDate(futureDate.format("DD MMM YYYY"));
  }, []);

  const convertToRupees = (number: string) => {
    const rupees = parseInt(number).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    const formattedRupees = rupees.replace(/\.\d+/, ""); // Remove decimal part if present
    return formattedRupees.replace("₹", "");
  };

  const handleDeleteButton = () => {
    if (isCompare === "detailsPage") {
      navigate("/profile/billing/plandetails");
    } else {
      navigate("/profile/billing/compareplans");
    }
  };

  const handleAddons = (count: number, value: any) => {
    if (count > 0) {
      const temp = [...addons];
      const existingIndex = temp.findIndex(
        (addon) => addon.valueName === value.valueName
      );

      if (existingIndex !== -1) {
        temp[existingIndex].quantity = count;
      } else {
        const data = {
          valueName: value.valueName,
          price: value.price,
          quantity: count,
          valueAddonID: value.id,
        };
        temp.push(data);
      }
      setAddons(temp);
      dispatch(setSelectedAddons(temp));
    } else {
      const temp = addons.filter(
        (addon: { valueName: any }) => addon.valueName !== value.valueName
      );
      setAddons(temp);
      dispatch(setSelectedAddons(temp));
    }
  };
  const getCouponForPlan = () => {
    const obj = {
      params: {
        accountId: accountId,
        planName: props.data?.planName,
        duration: `${month?.split(" ")[0]} ${month
          ?.split(" ")[1]
          ?.toUpperCase()}`,
      },
      headers: headers,
    };
    dispatch(getCoupanPlan(obj));
  };
  useEffect(() => {
    if (month) {
      getCouponForPlan();
      dispatch(setSelectedDuration(month));
    }
  }, [month]);

  useEffect(() => {
    if (planDurationsData) {
      setMonth(planDurationsData?.[0]);
    }
  }, [planDurationsData]);
  console.log("plan Id", props.data?.id, month);
  return (
    <div className={styles.planSummaryWrapper}>
      <div className={styles.planDetailsClass}>
        <div className={styles.planNameClass}>
          <span>{props?.data?.planName}</span> x <span>{month}</span>
        </div>
        <div className={styles.planPriceClass}>
          {props?.data?.currency}{" "}
          <span>{convertToRupees(props?.data?.price)}</span> X{" "}
          <span>{month?.split(" ")[0]}</span>
        </div>
      </div>
      <div className={styles.planExpireDateClass}>
        <div className={styles.dropdownClass}>
          <SingleSelectDD
            data={planDurationsData}
            handleChange={handleOnChange}
            hideSearchBox={true}
            selectedItem={month}
            title={""}
            extraWrapperClass={styles.extraDropdownWrapper}
            extraDropdownClass={styles.extraDropdownClass}
          />
        </div>
        <div className={styles.renewPackClass}>
          <span>Expires on</span> <span>{date}</span>
        </div>
      </div>
      <div className={styles.deleteImageClass}>
        <img
          src={deleteIcon}
          onClick={handleDeleteButton}
          className={styles.deleteImgClass}
          // onClick={() => {

          //   props.onClickDelete();
          // }}
        />
      </div>
      <hr className={styles.dashedLine} />
      <div className={styles.addonClass}>
        <p className={styles.addonTitle}>Add ons</p>
        {props.addon &&
          props.addon?.map((each: any, i: number) => {
            return (
              <div key={i}>
                <div className={styles.addonDetails}>
                  <div className={styles.addonValueDetails}>
                    <p className={styles.valueNameClass}>{each?.valueName}</p>
                    <p className={styles.valueEntityClass}>
                      {props?.data?.currency}{" "}
                      <span>{convertToRupees(each?.price)}</span>/
                      <span>{each?.addonType}</span>
                      <span>{each?.duration === "" ? "" : "/"}</span>
                      <span>{each?.duration?.toLowerCase()}</span>
                    </p>
                  </div>
                  <div>
                    <AddButton
                      text={"Get it"}
                      onCountChange={(e) => handleAddons(e, each)}
                      // getCount={each.quantity ? each.quantity : 0}
                    />
                  </div>
                </div>
                <hr className={styles.dashedLine} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PlanSummary;
