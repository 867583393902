import React, { useEffect, useState } from "react";
import styles from "./DataUploadScreen.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { useNavigate } from "react-router-dom";
import StepProgress from "../../../generic/stepProgress/StepProgress";
import {
  maxCompletedStep,
  storeCurrentStep,
} from "../../../../redux/campaign_V2.0/progressBar/actions";
import { DataUploadComp } from "./dataUploadComp/DataUploadComp";

export const DataUploadScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accountDetails = useSelector(
    (store: RootState) => store?.schedulerReducer?.accountInfoDetails
  );

  const campaignData = useSelector((store: RootState) => {
    return store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo;
  });

  const stepsTotal = accountDetails?.campaignProgressBar;

  const maxStateCompleted = useSelector(
    (state: RootState) =>
      state?.campaignModuleReducer?.progressBar?.maxCompletedStep
  );

  useEffect(() => {
    if (
      campaignData &&
      campaignData.id?.length > 0 &&
      campaignData?.uploadedSuccessfully
    ) {
      if (maxStateCompleted <= 2) dispatch(maxCompletedStep(2));
    }
  }, [campaignData?.uploadedSuccessfully, campaignData, maxStateCompleted]);

  useEffect(() => {
    if (location.pathname.includes("dataupload")) {
      dispatch(storeCurrentStep(stepsTotal?.indexOf("Data Upload") + 1));
    }
  }, [location.pathname, stepsTotal]);

  return (
    <div className={styles.dataUploadScreenDiv}>
      <div className={styles.stepProgressDiv}>
        <StepProgress
          color={"#07B464"}
          arr={[
            {
              name: "Campaign Details",
              fun: () => {
                navigate("/campaignmanager/campaigncreation");
              },
            },
            {
              name: "Data Upload",
              fun: () => {
                navigate("/campaignmanager/dataupload");
              },
            },
            {
              name: "Strategy Selection",
              fun: () => {
                navigate("/campaignmanager/strategyselection");
              },
            },
            {
              name: "Agent Configuration",
              fun: () => {
                navigate("/campaignmanager/allagents");
              },
            },
          ].filter((each) => stepsTotal?.includes(each.name))}
          maxStateCompleted={maxStateCompleted}
          currentStepComplete={
            campaignData &&
            campaignData?.id?.length > 0 &&
            campaignData?.uploadedSuccessfully
              ? true
              : false
          }
          inProgressStepFromOut={stepsTotal?.indexOf("Data Upload") + 1}
        />
      </div>
      <DataUploadComp />
    </div>
  );
};
