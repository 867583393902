// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HDST0hIcz6Kyb9voFQAq{width:430px;display:flex}.HDST0hIcz6Kyb9voFQAq .FmxvsVOA4h0Lfvlj6qpz{display:flex;justify-content:center;width:215px;border-bottom:2px solid rgba(208,208,208,.5921568627);cursor:pointer}.HDST0hIcz6Kyb9voFQAq .FmxvsVOA4h0Lfvlj6qpz p{font-family:Roboto;font-size:18px;font-weight:400;line-height:23px;color:#979797;margin-bottom:.5em}.HDST0hIcz6Kyb9voFQAq .FmxvsVOA4h0Lfvlj6qpz .XiK6eUDM_X_kpHThqEMw{font-weight:500;color:#000}.HDST0hIcz6Kyb9voFQAq .Sy5iSbPM6enHzv7xG68A{border-bottom:2px solid #000}`, "",{"version":3,"sources":["webpack://./src/components/crm/moduleComponent/crmHeader/CrmHeader.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CAEA,4CACE,YAAA,CACA,sBAAA,CACA,WAAA,CACA,qDAAA,CACA,cAAA,CACA,8CACE,kBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,aAAA,CAEA,kBAAA,CAEF,kEACE,eAAA,CACA,UAAA,CAGJ,4CACE,4BAAA","sourcesContent":[".wrapperx {\n  width: 430px;\n  display: flex;\n\n  .each_div {\n    display: flex;\n    justify-content: center;\n    width: 215px;\n    border-bottom: 2px solid #d0d0d097;\n    cursor: pointer;\n    p {\n      font-family: Roboto;\n      font-size: 18px;\n      font-weight: 400;\n      line-height: 23px;\n      color: #979797;\n\n      margin-bottom: 0.5em;\n    }\n    .sp_para {\n      font-weight: 500;\n      color: black;\n    }\n  }\n  .speacial_div {\n    border-bottom: 2px solid #000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperx": `HDST0hIcz6Kyb9voFQAq`,
	"each_div": `FmxvsVOA4h0Lfvlj6qpz`,
	"sp_para": `XiK6eUDM_X_kpHThqEMw`,
	"speacial_div": `Sy5iSbPM6enHzv7xG68A`
};
export default ___CSS_LOADER_EXPORT___;
