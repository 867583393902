import React, { useEffect, useState } from "react";
import "./MaxAttempt.css";
import minusIcon from "../../../../../theme/assets/svg/campaign/minusSign.svg";
import plusIcon from "../../../../../theme/assets/svg/campaign/plusSign.svg";
import minusGrayIcon from "../../../../../theme/assets/svg/campaign_V2.0/minusGrayIcon.svg";
import plusGrayIcon from "../../../../../theme/assets/svg/campaign_V2.0/plusGrayIcon.svg";
import { validateInputMeta } from "../../../../../utils/validators/validators";

function MaxAttempt(props) {
  const [inputData, setInputData] = useState(
    props.MaxAttempt ? props.MaxAttempt : 1
  );
  const decreceData = () => {
    if (inputData > 1) {
      setInputData(inputData - 1);
      if (props.setCallingDay) {
        props.setCallingDay(Number(inputData) - 1);
      }
    }
  };
  const increaseData = () => {
    if (inputData < 99) {
      setInputData(Number(inputData) + 1);
      if (props.setCallingDay) {
        props.setCallingDay(Number(inputData) + 1);
      }
    }
  };
  const setDataByInput = (data) => {
    if (!validateInputMeta(data)) return;
    if (data?.length <= 2 && data !== "0") {
      setInputData(Number(data));
    }
  };
  useEffect(() => {
    props.setAttempt(inputData);
  }, [inputData]);
  // useEffect(
  //   () => {
  //     setInputData(prev => props.MaxAttempt)
  //   },[props.MaxAttempt]
  // )
  return (
    <div className={"maxAttemptsWrapper"}>
      <div
        className={`${
          props.wrapperStyling ? props.wrapperStyling : ""
        } outerMaxDiv `}
      >
        <div
          className="imgContainer cursorPointer"
          onClick={() => {
            !props.isDisabled && decreceData();
          }}
        >
          <img src={props.isDisabled ? minusGrayIcon : minusIcon}></img>
        </div>
        <div className="imgContainer">
          <input
            className="inputDiv"
            type="number"
            value={props?.attemptData ? props.attemptData : inputData}
            onChange={(e) => {
              if (!props.isDataActive) {
                setDataByInput(e.target.value);
                if (props.setCallingDay) {
                  props.setCallingDay(e.target.value);
                }
              }
            }}
            onKeyDown={(e) =>
              [
                "e",
                "E",
                "+",
                "-",
                ".",
                "<",
                ">",
                "?",
                ";",
                ":",
                '"',
                "'",
                "\\",
              ].includes(e.key) && e.preventDefault()
            }
            onWheel={(e) => {
              return e.target.blur();
            }}
            disabled={props.isDataActive}
          />
        </div>
        <div
          className="imgContainer cursorPointer"
          onClick={() => {
            !props.isDisabled && increaseData();
          }}
        >
          <img
            src={props.isDisabled ? plusGrayIcon : plusIcon}
            className="cursorPointer"
          ></img>
        </div>
      </div>
    </div>
  );
}

export default MaxAttempt;
