import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { RootState } from "../../redux";
import { setWebSocketAdminData } from "../../redux/agentDesktop/dashboard/actions";

let interval: any;
export default function AdminWebSocket() {
  const adminId = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.userDetail?._id
  );
  const [socketUrl, setSocketUrl] = useState<string>(
    process.env.REACT_APP_SOCKET_URL as string
  );
  const accountId = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo?.userDetail?.accountDetails?.[0]?.id
  );
  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );
  const dispatch = useDispatch();

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => console.info("🫡🫡🫡🫡🫡 admin call socket open"),
    onClose: () => console.info("🎃🎃🎃🎃🎃 admin call socket closed"),
    shouldReconnect: (closeEvent) => true,
  });

  function handleSocketEvent(event: Record<string, any>) {
    let data = JSON.parse(event.data);
    data = JSON.parse(data);
    if (data.event === "updateData") {
      console.log(data);
      dispatch(setWebSocketAdminData({ ...data.card_details }));
    }
  }

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    if (lastMessage !== null) {
      handleSocketEvent(lastMessage);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (connectionStatus === "Open") {
      sendMessage(
        JSON.stringify({
          adminId: adminId,
          source: "ui",
          event: "connect",
          accountId: accountId,
          accountName: accountName,
        })
      );
      interval = setInterval(() => {
        sendJsonMessage({
          adminId: adminId,
          source: "ui",
          event: "getData",
          accountId: accountId,
          accountName: accountName,
        });
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [connectionStatus]);
  return <></>;
}
