export default {
  UPDATE_EMAIL_DNS_DETAILS_API_REQUEST: "UPDATE_EMAIL_DNS_DETAILS_API_REQUEST",
  UPDATE_EMAIL_DNS_DETAILS_API_SUCCESS: "UPDATE_EMAIL_DNS_DETAILS_API_SUCCESS",
  UPDATE_EMAIL_DNS_DETAILS_API_FAILURE: "UPDATE_EMAIL_DNS_DETAILS_API_FAILURE",
  VERIFY_EMAIL_DNS_DETAILS_API_REQUEST: "VERIFY_EMAIL_DNS_DETAILS_API_REQUEST",
  VERIFY_EMAIL_DNS_DETAILS_API_SUCCESS: "VERIFY_EMAIL_DNS_DETAILS_API_SUCCESS",
  VERIFY_EMAIL_DNS_DETAILS_API_FAILURE: "VERIFY_EMAIL_DNS_DETAILS_API_FAILURE",
  GET_EMAIL_DNS_DETAILS_API_REQUEST: "GET_EMAIL_DNS_DETAILS_API_REQUEST",
  GET_EMAIL_DNS_DETAILS_API_SUCCESS: "GET_EMAIL_DNS_DETAILS_API_SUCCESS",
  GET_EMAIL_DNS_DETAILS_API_FAILURE: "GET_EMAIL_DNS_DETAILS_API_FAILURE",
  GET_DEV_EMAIL_DETAILS_API_REQUEST: "GET_DEV_EMAIL_DETAILS_API_REQUEST",
  GET_DEV_EMAIL_DETAILS_API_SUCCESS: "GET_DEV_EMAIL_DETAILS_API_SUCCESS",
  GET_DEV_EMAIL_DETAILS_API_FAILURE: "GET_DEV_EMAIL_DETAILS_API_FAILURE",
  SEND_DNS_TO_DEV_EMAIL_API_REQUEST: "SEND_DNS_TO_DEV_EMAIL_API_REQUEST",
  SEND_DNS_TO_DEV_EMAIL_API_SUCCESS: "SEND_DNS_TO_DEV_EMAIL_API_SUCCESS",
  SEND_DNS_TO_DEV_EMAIL_API_FAILURE: "SEND_DNS_TO_DEV_EMAIL_API_FAILURE",
  SET_CURRENT_SCREEN_EMAIL: "SET_CURRENT_SCREEN_EMAIL",
  CLEAR_EMAIL_DATA: "CLEAR_EMAIL_DATA",
  CLEAR_VERIFY_DATA: "CLEAR_VERIFY_DATA",
};
