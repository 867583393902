import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../generic/loader/Loader";
import AnalyticsCard from "../analyticsCard/AnalyticsCard";
import StackedBarChart from "../charts/StackedBarChart";
import PravidIcons from "../../generic/pravidIcons/PravidIcons";
import styles from "./ChartModel.module.scss";
import ChartDownloadComponent from "../chartDownloadComponent/ChartDownloadComponent";
import DonutChartComponent from "../donutChartComponent/DonutChartComponent";
import SimpleBarChart from "../charts/SimpleBarChart";
import LineChart from "../charts/lineChart";
import BarLineChart from "../charts/BarLineChart";
import Disposition from "../disposition/Disposition";
// import { useScreenshot } from "use-screenshot-hook";
import {
  getExpandedChart,
  getSortedChart,
} from "../../../redux/analytics/actions";
import { RootState } from "../../../redux";
import { getAnalyticsChartDownloadCSV } from "../../../redux/analytics/actions";
// import Html2Pdf from "js-html2pdf";
import moment from "moment";
import useOnClickOutside from "../../../utils/useOnClickOutside";
import BiaxialBarChart from "../charts/BiaxialBarChart";
import { toast, ToastContainer } from "react-toastify";
// import jsPDF from "jspdf";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import { Mixpanel } from "../../../utils/mixpanelSetup";
import ResponseRate from "../responseRate/ResponseRate";
import SimpleLineChart from "../charts/SimpleLineChart";
import FunnelChartComponent from "../charts/FunnelChart";
import SingleLineChart from "../charts/SingleLineChart";
import ClubbedBarChart from "../charts/ClubbedBarChart";
import _ from "lodash";
import {
  cardInterface,
  chartInterface,
} from "../../../screens/analytics/analyticsScreen.types";
import HorizontalBarChart from "../charts/HorizontalBarChart";
import { ROLES } from "../../../constants";
import CustomBodySkeleton from "../../generic/customSkeleton/CustomBodySkeleton";

interface props {
  chartData: any;
  cards: cardInterface[];
  charts: chartInterface[];
  useCaseId: string;
  channel: any;
  whatsappChannel: any;
  isLoading: any;
  isLoadingCard?: any;
}

export default function ChartModel(props: props) {
  const [downloadChartName, setDownloadChartName] = useState<string>();
  const [isDownload, setIsDownload] = useState<boolean>(false);
  const [expandTitle, setExpandedTitle] = useState<any | undefined>([]);
  const [deepChartData, setDeepChartData] = useState<Record<string, any>>({});

  const dispatch = useDispatch();
  // const deepChartData = JSON.parse(JSON.stringify(props.chartData));

  // const testRef = useRef(null);
  const downloadRef = useRef<any>();
  useOnClickOutside(downloadRef, () => setIsDownload(false));

  // const { image, takeScreenshot, isLoading, clear } = useScreenshot({
  //   ref: testRef,
  // });
  // const download = (image: any = {}) => {
  //   const a = document.createElement("a");
  //   a.href = image;
  //   a.download = "image.jpg";
  //   a.click();
  // };

  //To Capture Image with Scroll
  const handleCaptureClick = async (i: number) => {
    const printChartElement: any = document.querySelector(`#printChart${[i]}`);

    if (!printChartElement) return;
    const copiedPrintChartElement = printChartElement?.cloneNode(
      true
    ) as HTMLElement;

    copiedPrintChartElement.style.position = "fixed";
    copiedPrintChartElement.style.height = "auto";
    copiedPrintChartElement.style.width = "auto";
    copiedPrintChartElement.style.padding = "20px";
    copiedPrintChartElement.style.background = "#000";
    const printChartClone = copiedPrintChartElement.querySelectorAll(
      'div[data-id="chartViewOnPrint"]'
    );
    printChartClone.forEach((x: any) => {
      // x.style.maxWidth = "100%";
      x.style.width = "100%";
      x.style.height = "100%";
    });
    document.body.append(copiedPrintChartElement);
    const canvas = await html2canvas(copiedPrintChartElement, {
      useCORS: true,
      scale: 3,
    });
    copiedPrintChartElement.remove();
    const dataURL = canvas.toDataURL("image/png", 1.0);
    downloadjs(dataURL, "download.png", "image/png");
    Mixpanel.track("Analytics | Download Graph Data | Clicks", {
      module: "Analytics",
      name: i,
      fileType: "png",
    });
  };

  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );

  const filterSelectedDate = useSelector(
    (store: RootState) => store?.filterReducers?.calenderData
  );

  const isExpand = useSelector(
    (store: RootState) => store?.analyticsReducer?.isExpand
  );

  const expandedChart = useSelector(
    (store: RootState) => store?.analyticsReducer?.expandedChart
  );

  const isSortedView = useSelector(
    (store: RootState) => store?.analyticsReducer?.isSort
  );
  const currentUseCaseName = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseName
  );
  const sortedChart = useSelector(
    (store: RootState) => store?.analyticsReducer?.sortedChart
  );

  const selectedFilters = useSelector(
    (state: RootState) => state?.filterReducers?.selectedFilterOptions
  );

  const isViewMore = useSelector(
    (store: RootState) => store?.analyticsReducer?.isViewMore
  );

  const viewMoreChart = useSelector(
    (store: RootState) => store?.analyticsReducer?.viewMoreChart
  );

  const campaignstartDate = useSelector(
    (state: RootState) => state.schedulerReducer?.analyticsIdName?.startDate
  );

  const campaignEndDate = useSelector(
    (state: RootState) => state.schedulerReducer?.analyticsIdName?.endDate
  );

  const campaignNameId = useSelector(
    (state: RootState) => state.schedulerReducer?.analyticsIdName?.allInfo?.id
  );

  const userRole: any = useSelector((state: any) => {
    return state?.loginReducer?.userLoginInfo?.userDetail?.role;
  });
  const accountDetails = useSelector(
    (store: any) => store.loginReducer?.userLoginInfo?.accountDetails[0]
  );
  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const currentUser = useSelector((state: RootState) => {
    return state.filterReducers.selectedFilterOptions.clientName;
  });
  const isLoadingAnalyticsCard = useSelector(
    (state: RootState) => state.analyticsReducer.isLoadingAnalyticsCard
  );

  // const propsKpi = JSON.parse(JSON.stringify(props?.kpiDetails));
  const cardsDeep = JSON.parse(JSON.stringify(props?.cards));
  const chartsDeep = JSON.parse(JSON.stringify(props?.charts));
  const kpiDetailsArr = ["cards", "charts"];

  useEffect(() => {
    setDeepChartData(_.cloneDeep(props.chartData));
  }, [props.chartData]);

  const chartData =
    chartsDeep && Object.values(chartsDeep).length > 0
      ? Object.values(
          chartsDeep.sort((a: any, b: any) => {
            return a.position - b.position;
          })
        )
      : [];

  const getIsExpandView = (title: string) => {
    if (title) {
      const temp = expandTitle;
      const index = temp.indexOf(title);
      if (index > -1) {
        temp.splice(index, 1);
        dispatch(getExpandedChart(false, temp));
        setExpandedTitle(temp);
      } else {
        temp.push(title);
        dispatch(getExpandedChart(true, temp));
        Mixpanel.track("Analytics | Expand Graph |Clicks", {
          module: "Analytics",
          name: title,
        });
        setExpandedTitle(temp);
      }
    }
  };

  const getIsDownload = (title: string) => {
    if (title) {
      setIsDownload(!isDownload);
      setDownloadChartName(title);
    }
  };

  const getIsSort = (title: string) => {
    if (title) {
      dispatch(getSortedChart(!isSortedView, title));
      Mixpanel.track("Analytics | Sort Feature |Clicks", {
        module: "Analytics",
        name: title,
      });
    }
  };

  const getDownloadCsvData = (data: any) => {
    const bodyData = {
      start_date:
        filterSelectedDate.formatedStartDate === undefined ||
        filterSelectedDate.formatedStartDate === ""
          ? moment(new Date()).format("YYYY-MM-DD")
          : filterSelectedDate.formatedStartDate,

      end_date:
        filterSelectedDate.formatedEndDate === undefined ||
        filterSelectedDate.formatedEndDate === ""
          ? moment(new Date()).format("YYYY-MM-DD")
          : filterSelectedDate.formatedEndDate,
      useCaseId: [props.useCaseId],
      source: [currentUseCaseName.split(" ")[0]],
      channel: [props.channel],
      ...(props.whatsappChannel && {
        communicationType: [props.whatsappChannel],
      }),
      ...selectedFilters,
      username:
        (userRole.toString().toLowerCase() ===
          ROLES.campaignAnalyst.name.toLowerCase() ||
          userRole.toString().toLowerCase() ===
            ROLES.campaignManager.name.toLowerCase()) &&
        currentUser?.[0]?.name
          ? currentUser[0].name
          : accountName,
      clientName: undefined,
      role: userRole,
    };
    dispatch(
      getAnalyticsChartDownloadCSV(
        {
          kpiDetails: data,
          filters: bodyData,
          // ...(campaignNameId && (moment(campaignstartDate).format("YYYY-MM-DD") ===
          //     moment(filterSelectedDate?.startDate).format("YYYY-MM-DD")) &&
          //     (moment(campaignEndDate).format("YYYY-MM-DD") ===
          //     moment(filterSelectedDate?.endDate).format("YYYY-MM-DD")) &&  {
          //       campaignId: [campaignNameId],
          //     }),
        },
        token
      )
    );
    Mixpanel.track("Analytics | Download Graph Data | Clicks", {
      module: "Analytics",
      name: data?.chartTitle,
      fileType: "CSV",
    });
  };

  function scrollToRightView(e: any, i: any) {
    document.getElementById("chartModel" + i)!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  useEffect(() => {
    // if(isViewMore){
    getIsExpandView(viewMoreChart);
    // }
  }, [isViewMore]);
  console.log("xchart" , chartData ,deepChartData)
  // console.log(props?.isLoadingCard, "load");
  return (
    <>
      <div className={styles.chartModelTopDiv}>
        {kpiDetailsArr?.map((each: any, i: any) => {
          return (
            <div key={i}>
              {each === "cards" && cardsDeep?.length > 0 ? (
                <div>
                  {deepChartData ? (
                    <div>
                      <AnalyticsCard
                        cardDetails={props.cards}
                        data={deepChartData}
                        isLoadingObject={isLoadingAnalyticsCard}
                      />
                      {/* <div className="html2pdf__page-break"></div> */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : each === "charts" && chartsDeep?.length > 0 ? (
                <div className={styles.allChartBody} id="allChartBody">
                  {chartData.map((each: any, i: any) => {
                    if (!each.isActive) return null;
                    return (
                      <CustomBodySkeleton
                        isLoading={props.isLoading[each?.kpiDetails?.uniqueId]}
                        style={{
                          transform: "scale(0.97, 0.9) translateX(10px)",
                        }}
                      >
                        <div
                          // ref={
                          //   isDownload && each.chartTitle === downloadChartName
                          //     ? testRef
                          //     : null
                          // }
                          key={i}
                          // id={
                          //   isDownload && each.chartTitle === downloadChartName
                          //     ? "printChart"
                          //     : undefined
                          // }
                          id={`printChart${i}`}
                          className="printEachChart"
                        >
                          {/* <div className="html2pdf__page-break"></div> */}
                          {/* {i !== 0 ? (
                          <div className="html2pdf__page-break"></div>
                        ) : (
                          ""
                        )} */}

                          <div
                            key={i}
                            className={styles.chartView}
                            id={"chartModel" + i}
                            data-id="chartViewOnPrint"
                            // style={
                            //   expandTitle.includes(each.chartTitle)
                            //     ? each.expand.expandStyle
                            //     : each.chartStyle
                            // }
                            style={{
                              width: expandTitle.includes(
                                each?.kpiDetails?.chartTitle
                              )
                                ? each?.kpiDetails?.expand.expandStyle.width
                                : each?.kpiDetails?.chartStyle.width,
                              height: expandTitle.includes(
                                each?.kpiDetails?.chartTitle
                              )
                                ? each?.kpiDetails?.expand.expandStyle.height
                                : each?.kpiDetails?.chartStyle.height,
                              marginBottom: "30px",
                            }}
                          >
                            <div className={styles.chartHeader}>
                              <div className={styles.chartTitle}>
                                {/* custom name */}
                                {each?.customName}
                              </div>
                              <div className={styles.chartIcons}>
                                {each?.kpiDetails?.download
                                  ?.showDownloadButton &&
                                  !props.isLoading?.[
                                    each?.kpiDetails?.["uniqueId"]
                                  ] && (
                                    <div
                                      onClick={() => {
                                        if (
                                          userRole.toString().toLowerCase() ===
                                            ROLES.campaignAnalyst.name.toLowerCase() &&
                                          accountDetails.type === "Internal"
                                        ) {
                                          return;
                                        }
                                        getIsDownload(
                                          each?.kpiDetails?.chartTitle
                                        );
                                      }}
                                      id="auto_analytics_download"
                                    >
                                      {/* <img
                                      className={styles.icons}
                                      src={downloadIcon}
                                    /> */}
                                      <PravidIcons
                                        extraClass={styles.icons}
                                        activeIcon={"downloadIcon"}
                                      />
                                      {isDownload &&
                                        each?.kpiDetails?.chartTitle ===
                                          downloadChartName && (
                                          <div
                                            ref={downloadRef}
                                            className={styles.downloadComponent}
                                          >
                                            <ChartDownloadComponent
                                              onClickScreenshot={() => {
                                                toast.success(
                                                  "Please Wait ... Downloading "
                                                );
                                                // takeScreenshot().then(download)
                                                setTimeout(() => {
                                                  handleCaptureClick(i);
                                                  // console.log("sfdjhfgku");
                                                }, 500);
                                              }}
                                              onClickCsvData={() => {
                                                toast.success(
                                                  "Please Wait ... Downloading "
                                                );
                                                getDownloadCsvData(
                                                  each?.kpiDetails
                                                );
                                              }}
                                            />
                                          </div>
                                        )}
                                    </div>
                                  )}

                                {each?.kpiDetails?.sort?.showSortButton && (
                                  <div
                                    onClick={() =>
                                      getIsSort(each?.kpiDetails?.chartTitle)
                                    }
                                    id="auto_analytics_sort"
                                  >
                                    {isSortedView &&
                                    each?.kpiDetails?.chartTitle ===
                                      sortedChart ? (
                                      <PravidIcons
                                        extraClass={styles.icons}
                                        activeIcon={"asc"}
                                      />
                                    ) : (
                                      <PravidIcons
                                        extraClass={styles.icons}
                                        activeIcon={"desc"}
                                      />
                                    )}
                                  </div>
                                )}

                                {each?.kpiDetails?.expand?.showExpandButton && (
                                  <div
                                    onClick={() => {
                                      getIsExpandView(
                                        each?.kpiDetails?.chartTitle
                                      );
                                      setTimeout(() => {
                                        scrollToRightView(each?.kpiDetails, i);
                                      }, 50);
                                    }}
                                    id="auto_analytics_expand"
                                  >
                                    {expandTitle.includes(
                                      each?.kpiDetails?.chartTitle
                                    ) ? (
                                      // <img
                                      //   className={styles.icons}
                                      //   src={contractIcon}
                                      //   alt="Contract"
                                      // />
                                      <PravidIcons
                                        extraClass={styles.icons}
                                        activeIcon={"contract"}
                                      />
                                    ) : (
                                      // <img
                                      //   className={styles.icons}
                                      //   src={expandIcon}
                                      //   alt="expand"
                                      // />
                                      <PravidIcons
                                        extraClass={styles.icons}
                                        activeIcon={"expand"}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className={styles.chartBody}
                              // style={
                              //   isExpandedView && each.chartTitle === expandedChart
                              //     ? each.expand.expandStyle
                              //     : each.chartStyle
                              // }
                            >
                              {each?.kpiDetails?.chartType ===
                              "stackedBarChart" ? (
                                deepChartData &&
                                !props.isLoading[each?.kpiDetails?.uniqueId] ? (
                                  <StackedBarChart
                                    chartDetails={each?.kpiDetails}
                                    // data={props.chartData}
                                    chartTitle={each?.kpiDetails?.chartTitle}
                                    data={deepChartData}
                                  />
                                ) : (
                                  <Loader />
                                )
                              ) : each?.kpiDetails?.chartType === "donut" ? (
                                deepChartData &&
                                !props.isLoading[each?.kpiDetails?.uniqueId] ? (
                                  <DonutChartComponent
                                    chartDetails={each?.kpiDetails}
                                    // data={props.chartData}
                                    chartTitle={each?.kpiDetails?.chartTitle}
                                    data={deepChartData}
                                  />
                                ) : (
                                  <Loader />
                                )
                              ) : each?.kpiDetails?.chartType === "barChart" ? (
                                deepChartData &&
                                !props.isLoading[each?.kpiDetails?.uniqueId] ? (
                                  <SimpleBarChart
                                    chartDetails={each?.kpiDetails}
                                    //  data={props.chartData}
                                    chartTitle={each?.kpiDetails?.chartTitle}
                                    data={deepChartData}
                                  />
                                ) : (
                                  <Loader />
                                )
                              ) : each?.kpiDetails?.chartType ===
                                "horizontalBarChart" ? (
                                deepChartData &&
                                !props.isLoading[each?.kpiDetails?.uniqueId] ? (
                                  <HorizontalBarChart
                                    chartDetails={each?.kpiDetails}
                                    //  data={props.chartData}
                                    chartTitle={each?.kpiDetails?.chartTitle}
                                    data={deepChartData}
                                  />
                                ) : (
                                  <Loader />
                                )
                              ) : each?.kpiDetails?.chartType ===
                                "lineChart" ? (
                                deepChartData &&
                                !props.isLoading[each?.kpiDetails?.uniqueId] ? (
                                  <LineChart
                                    chartDetails={each?.kpiDetails}
                                    // data={props.chartData}
                                    chartTitle={each?.kpiDetails?.chartTitle}
                                    data={deepChartData}
                                  />
                                ) : (
                                  <Loader />
                                )
                              ) : each?.kpiDetails?.chartType ===
                                "barlineChart" ? (
                                deepChartData &&
                                !props.isLoading[each?.kpiDetails?.uniqueId] ? (
                                  <BarLineChart
                                    chartDetails={each?.kpiDetails}
                                    // data={props.chartData}
                                    chartTitle={each?.kpiDetails?.chartTitle}
                                    data={deepChartData}
                                  />
                                ) : (
                                  <Loader />
                                )
                              ) : each?.kpiDetails?.chartType === "pieChart" ? (
                                deepChartData &&
                                !props.isLoading[each?.kpiDetails?.uniqueId] ? (
                                  <Disposition
                                    chartDetails={each?.kpiDetails}
                                    data={props.chartData}
                                    chartTitle={each?.kpiDetails?.chartTitle}
                                  />
                                ) : (
                                  <Loader />
                                )
                              ) : each?.kpiDetails?.chartType ===
                                "biaxialBarChart" ? (
                                deepChartData &&
                                !props.isLoading[each?.kpiDetails?.uniqueId] ? (
                                  <BiaxialBarChart
                                    chartDetails={each?.kpiDetails}
                                    data={props.chartData}
                                    chartTitle={each?.kpiDetails?.chartTitle}
                                  />
                                ) : (
                                  <Loader />
                                )
                              ) : each?.kpiDetails?.chartType ===
                                "responseRateChart" ? (
                                deepChartData &&
                                !props.isLoading[each?.kpiDetails?.uniqueId] ? (
                                  <ResponseRate
                                    chartDetails={each?.kpiDetails}
                                    data={props.chartData}
                                    chartTitle={each?.kpiDetails?.chartTitle}
                                  />
                                ) : (
                                  <Loader />
                                )
                              ) : each?.kpiDetails?.chartType ===
                                "biaxialLineChart" ? (
                                deepChartData &&
                                !props.isLoading[each?.kpiDetails?.uniqueId] ? (
                                  <SimpleLineChart
                                    chartDetails={each?.kpiDetails}
                                    data={props.chartData}
                                    chartTitle={each?.kpiDetails?.chartTitle}
                                  />
                                ) : (
                                  <Loader />
                                )
                              ) : each?.kpiDetails?.chartType ===
                                "funnelChart" ? (
                                deepChartData &&
                                !props.isLoading[each?.kpiDetails?.uniqueId] ? (
                                  <FunnelChartComponent
                                    chartDetails={each?.kpiDetails}
                                    data={props.chartData}
                                    chartTitle={each?.kpiDetails?.chartTitle}
                                  />
                                ) : (
                                  <Loader />
                                )
                              ) : each?.kpiDetails?.chartType ===
                                "singleLineChart" ? (
                                deepChartData &&
                                !props.isLoading[each?.kpiDetails?.uniqueId] ? (
                                  <SingleLineChart
                                    chartDetails={each?.kpiDetails}
                                    data={props.chartData}
                                    chartTitle={each?.kpiDetails?.chartTitle}
                                  />
                                ) : (
                                  <Loader />
                                )
                              ) : each?.kpiDetails?.chartType ===
                                "clubbedBarChart" ? (
                                deepChartData &&
                                !props.isLoading[each?.kpiDetails?.uniqueId] ? (
                                  <ClubbedBarChart
                                    chartDetails={each?.kpiDetails}
                                    data={props.chartData}
                                    chartTitle={each?.kpiDetails?.chartTitle}
                                  />
                                ) : (
                                  <Loader />
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </CustomBodySkeleton>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}
