import { combineReducers } from "@reduxjs/toolkit";
import invoicesReducer from "./invoiceDetails/reducer";
import pricingPlanReducer from "./pricingPlan/reducer";
import orderSummaryReducer from "./orderSummary/reducer";
import myPlanReducer from "./myPlan/reducer";

const billingReducer = combineReducers({
  invoices: invoicesReducer,
  pricingPlan: pricingPlanReducer,
  orderSummary: orderSummaryReducer,
  myPlanReducer: myPlanReducer,
});

export default billingReducer;
