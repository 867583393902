import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import {
  DASHBOARD_URL,
  SERVER_URL2,
  ANALYTICS_URL,
  config,
  SAARTHIDEMO_PAYMENT_DASHBOARD_URL,
  SAARTHIDEMO_CUSTOMER_DASHBOARD_URL,
} from "../../../services/ApiRoutes";
import actionTypes from "../actionTypes";
import { downloadCsvFile } from "../../../utils/downloadCsvFile";
import axios from "axios";
import moment from "moment";

const cancelTokenRD: Record<string, any> = {};
let cancelTokSp: any;
export function* getAnalyticsDataAPIWorker(action: any): any {
  // console.log(ANALYTICS_URL.DASHBOARD_URL_NEW_KPI, "lll");
  const accountName = sessionStorage.getItem("accountName");
  const subModule = sessionStorage.getItem("subModule");
  // if (typeof cancelTokSp!= typeof undefined) {
  //   cancelTokSp.cancel("Operation canceled due to new request.");
  // }
  // cancelTokSp=axios.CancelToken.source();

  if (
    typeof cancelTokenRD?.[action.payload.bodyData.uniqueId] != typeof undefined
  ) {
    cancelTokenRD?.[action.payload.bodyData.uniqueId].cancel(
      "Operation canceled due to new request."
    );
  }
  cancelTokenRD[action.payload.bodyData?.uniqueId] = axios.CancelToken.source();
  const bodyData = action.payload.bodyData;
  if (bodyData?.startDueDate) {
    bodyData["startDueDate"] = `${moment(bodyData.startDueDate).format(
      "YYYY-MM-DD"
    )}T00:00:00`;
    bodyData["endDueDate"] = `${moment(bodyData?.endDueDate).format(
      "YYYY-MM-DD"
    )}T23:59:59`;
  }

  try {
    const res = yield call(
      config.POST_WITH_HEADERS_BODY_CANCEL_TOKEN,
      `${
        (accountName?.includes("Saarthi Demo") ||
          accountName?.includes("Sphot")) &&
        subModule === "Payment"
          ? SAARTHIDEMO_PAYMENT_DASHBOARD_URL
          : (accountName?.includes("Saarthi Demo") ||
              accountName?.includes("Sphot")) &&
            subModule === "Customer Behaviour"
          ? SAARTHIDEMO_CUSTOMER_DASHBOARD_URL
          : action.payload?.newApi
          ? ANALYTICS_URL.DASHBOARD_URL_CARDS
          : // ? ANALYTICS_URL.DASHBOARD_URL_NEW_KPI
            ANALYTICS_URL.DASHBOARD_URL_CARDS
      }`,
      bodyData,
      { "x-access-token": action.payload.token },
      (accountName?.includes("Saarthi Demo") ||
        accountName?.includes("Sphot")) &&
        (subModule === "Customer Behaviour" || subModule === "Payment")
        ? ""
        : cancelTokenRD[action.payload.bodyData?.uniqueId].token
    );
    // console.log(ANALYTICS_URL.DASHBOARD_URL_NEW_KPI, "after");

    yield put({
      type: actionTypes.ANALYTICS_API_DATA_SUCCESS,
      payload: res?.data?.data,
      uniqueId: action.payload.bodyData.uniqueId,
    });
    if (res?.data?.data?.card_details) {
      yield put({
        type: actionTypes.ANALYTICS_API_CARD_DATA_SUCCESS,
        payload: res.data?.data?.card_details,
        uniqueId: action.payload.bodyData.uniqueId,
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.ANALYTICS_API_DATA_FAILURE,
      payload: error,
      uniqueId: action.payload.bodyData.uniqueId,
    });
    yield put({
      type: actionTypes.ANALYTICS_API_DATA_FAILURE,
      payload: error,
      uniqueId: action.payload.bodyData.uniqueId,
    });
  }
}

export function* getAnalyticsChartDownloadCsvAPIWorker(action: any): any {
  const accountName = sessionStorage.getItem("accountName");
  const subModule = sessionStorage.getItem("subModule");

  try {
    const headers = {
      "x-access-token": action.payload.token,
    };
    const res = yield call(
      config.POST_WITH_HEADERS_BODY_CANCEL_TOKEN,
      `${
        (accountName?.includes("Saarthi Demo") ||
          accountName?.includes("Sphot")) &&
        subModule === "Payment"
          ? `${ANALYTICS_URL.PAYMENT_DOWNLOAD_CSV}`
          : (accountName?.includes("Saarthi Demo") ||
              accountName?.includes("Sphot")) &&
            subModule === "Customer Behaviour"
          ? `${ANALYTICS_URL.CUSTOMER_BEHAVIOUR_DOWNLOAD_CSV}`
          : `${SERVER_URL2}${ANALYTICS_URL.DOWNLOAD_CSV}`
      }`,
      action.payload.bodyData,
      headers
      //  cancelTokenRD[action.payload.bodyData?.uniqueId].token
    );
    yield put({
      type: actionTypes.ANALYTICS_CHARTS_DOWNLOAD_CSV_SUCCESS,
      payload: res.data,
    });

    downloadCsvFile(
      res.data,
      action.payload?.bodyData
        ? action.payload?.bodyData?.kpiDetails?.chartTitle
        : "Download"
    );
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.ANALYTICS_CHARTS_DOWNLOAD_CSV_FAILURE,
      payload: error,
    });
  }
}
// for getting all cards data
export function* getAnalyticsCardDataAPIWorker(action: any): any {
  const accountName = sessionStorage.getItem("accountName");
  const subModule = sessionStorage.getItem("subModule");
  // if (typeof cancelTokSp!= typeof undefined) {
  //   cancelTokSp.cancel("Operation canceled due to new request.");
  // }
  // cancelTokSp=axios.CancelToken.source();
  if (
    typeof cancelTokenRD?.[action.payload.bodyData?.uniqueId] !=
    typeof undefined
  ) {
    cancelTokenRD[action.payload.bodyData?.uniqueId].cancel(
      "Operation canceled due to new request."
    );
  }
  cancelTokenRD[action.payload.bodyData?.uniqueId] = axios.CancelToken.source();

  const bodyData = action.payload.bodyData;
  if (bodyData?.startDueDate) {
    bodyData["startDueDate"] = `${moment(bodyData.startDueDate).format(
      "YYYY-MM-DD"
    )}T00:00:00`;
    bodyData["endDueDate"] = `${moment(bodyData?.endDueDate).format(
      "YYYY-MM-DD"
    )}T23:59:59`;
  }

  try {
    const res = yield call(
      config.POST_WITH_HEADERS_BODY_CANCEL_TOKEN,
      `${
        (accountName?.includes("Saarthi Demo") ||
          accountName?.includes("Sphot")) &&
        subModule === "Payment"
          ? SAARTHIDEMO_PAYMENT_DASHBOARD_URL
          : (accountName?.includes("Saarthi Demo") ||
              accountName?.includes("Sphot")) &&
            subModule === "Customer Behaviour"
          ? SAARTHIDEMO_CUSTOMER_DASHBOARD_URL
          : action.payload?.newApi
          ? // ? ANALYTICS_URL.DASHBOARD_URL_NEW_KPI
            ANALYTICS_URL.DASHBOARD_URL_CARDS
          : ANALYTICS_URL.DASHBOARD_URL_CARDS
      }`,
      bodyData,
      { "x-access-token": action.payload.token },
      // accountName?.includes("Saarthi Demo") &&
      //   (subModule === "Customer Behaviour" || subModule === "Payment")
      //   ? ""
      //   :
      cancelTokenRD[action.payload.bodyData?.uniqueId].token
    );

    yield put({
      type: actionTypes.ANALYTICS_API_CARD_DATA_SUCCESS,
      payload: res.data?.data?.card_details,
      uniqueId: action.payload.bodyData.uniqueId,
    });
    yield put({
      type: actionTypes.ANALYTICS_API_DATA_SUCCESS,
      payload: {},
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.ANALYTICS_API_CARD_DATA_FAILURE,
      payload: error,
      uniqueId: action.payload.bodyData.uniqueId,
    });
  }
}
