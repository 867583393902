import React, { useEffect } from "react";
import {
  incomingCallGreen,
  callEnd,
} from "../../../../../theme/assets/agentDesktopSvg";
import { setCallStatus ,setRingTime} from "../../../../../redux/agentDesktop/BaseScreenAD/action";
import "./IncomingCallCard.scss";
import { useDispatch } from "react-redux";
import { setRootPortalScreen } from "../../../../../redux/baseScreen/baseScreenState/actions";
import portalTypes from "../../../../../screens/rootPortal/portalTypes";
import moment from "moment";

interface props {
  callerName: string;
  callerNumber: string | number;
  handleCallPickUpClick: () => void;
  handleCallRejectClick: () => void;
}

export default function ComponentName(props: props) {
  /* redux hooks */
  const dispatch = useDispatch();

  /* useEffect ------ */
  useEffect(() => {
    const missedCallTimeOut = setTimeout(() => {
      dispatch(setCallStatus("missed"));
      dispatch(setRootPortalScreen(portalTypes.NULL_PORTAL));
    }, 30000);
    const ringTime=new Date()
    dispatch(setRingTime(moment(ringTime).toString()))
    return () => clearTimeout(missedCallTimeOut);
  }, []);

  return (
    <div className="incoming-call-card">
      <div className="caller-info">
        <span className="caller-info__name">{props.callerName}</span>
        <span className="caller-info__number">{props.callerNumber}</span>
      </div>
      <div className="incoming-call-icon">
        <img
          src={callEnd}
          alt=""
          className="call-reject-icon"
          onClick={props.handleCallRejectClick}
        />
        <img
          src={incomingCallGreen}
          alt=""
          className="call-pick-icon"
          onClick={props.handleCallPickUpClick}
        />
        <audio
          autoPlay
          loop
          src="https://assets.mixkit.co/sfx/preview/mixkit-waiting-ringtone-1354.mp3"
        ></audio>
      </div>
    </div>
  );
}
