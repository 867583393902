export const ROLES: Record<
  string,
  { name: string; displayName: string; useCaseId?: string; display: boolean }
> = {
  agentDesktop_agent: {
    name: "Agent Desktop",
    displayName: "Support Specialist",
    display: true,
  },
  agentDesktop_admin: {
    name: "Admin Desktop",
    displayName: "Support Supervisor",
    display: true,
  },
  admin: { name: "Admin", displayName: "Admin", display: true },
  // collectionManager: {
  //   name: "Collection Manager",
  //   displayName: "Collection Specialist",
  // },
  // salesManager: { name: "Sales Manager", displayName: "Sales Specialist" },
  campaignManager: {
    name: "Campaign Manager",
    displayName: "Campaign Specialist",
    display: true,
  },
  campaignAnalyst: {
    name: "Campaign Analyst",
    displayName: "Quality Specialist",
    display: true,
  },
  chiefRiskOfficer: {
    // this role is not displayed in frontEnd
    name: "Chief Risk Officer",
    displayName: "Chief Risk Officer",
    display: false,
  },
  collectionSpecialist: {
    name: "Chief Risk Officer",
    displayName: "Collection Specialist",
    useCaseId: "62c81c4a4a0affe91342aaaa",
    display: true,
  },
  salesSpecialist: {
    name: "Chief Risk Officer",
    displayName: "Sales Specialist",
    useCaseId: "639053fb52d33c75af54947a",
    display: true,
  },
  guest: { name: "Guest", displayName: "Guest", display: true },
  financialSpecialist: {
    name: "Financial Specialist",
    displayName: "Financial Specialist",
    display: true,
  },
};

export const ACCOUNT_TYPE = { internal: "Internal", external: "External" };

// export const OLD_TO_NEW_ROLE = {
//   "Agent Desktop": "Support Specialist",
//   "Admin Desktop": "Support Supervisor",
//   Admin: "Admin",
//   "Collection Manager": "Collection Specialist",
//   "Sales Manager": "Sales Specialist",
//   "Campaign Manager": "Campaign Specialist",
//   "Campaign Analyst": "Campaign Specialist",
// };
