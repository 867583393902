import { takeLatest } from "redux-saga/effects";
import actionTypes from "../sms.actionTypes";
import {
  setConnectedExistingAccountAPIWorker,
  setNewAccountAPIWorker,
  updateNewAccountAPIWorker,
} from "./sms.saga";
export default function* smsDataWatcher(): any {
  yield takeLatest(
    actionTypes.SET_CONNECT_EXISTING_ACCOUNT_REQUEST,
    setConnectedExistingAccountAPIWorker
  );
  yield takeLatest(actionTypes.SET_NEW_ACCOUNT_REQUEST, setNewAccountAPIWorker);
  yield takeLatest(
    actionTypes.UPDATE_NEW_ACCOUNT_REQUEST,
    updateNewAccountAPIWorker
  );
}
