import styles from "./CounterComponent.module.scss";
import React, { useState, useEffect } from 'react';

interface props{
  time: any,
  endDate: Date
  startDate: Date
  pauseTimer: boolean
}

export default function CounterComponent(props: props) {
  const [seconds, setSeconds] = useState<number>();

  useEffect(()=>{
    if(props.time !== undefined){
      setSeconds(props.time)
    }
  },[props.time])

  
  useEffect(() => {
    let interval: any;
    if (new Date(props.endDate).getDate() >= new Date().getDate()  &&  new Date(props.startDate).getDate() <= new Date().getDate() && !props.pauseTimer) {
      if (seconds) {
        interval = setInterval(() => {
          setSeconds(seconds + 1);
        }, 1000);
        return () => clearInterval(interval);
      } else if (seconds == 0) {
        interval = setInterval(() => {
          setSeconds(0 + 1);
        }, 1000);
      }
    } else {
      // console.log("clearing");
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconds,props.endDate,props.startDate,props.pauseTimer]);

  const hours =  seconds && Math.floor(seconds / 3600);
  const minutes = seconds && Math.floor((seconds % 3600) / 60);
  const sec =seconds && seconds % 60;


  
  return (
      <div className={styles.cardDataWrapper}>
           <p className={styles.cardValueDiv}>
             {/* {seconds ? */}
           {`${hours}h ${minutes}m ${sec}s`}
          {/* : 0
           } */}
           </p> 
      </div>
  );
}
