import React from "react";
import { expandArrow } from "../../../../theme/assets/svg/campaignTestingInfo";
import InputFields from "./InputFields";

interface props {
  customerData: Record<string, any>;
  rowSchema: Record<string, any>;
  rowPosition: number;
  handleShowMoreToggle: (index: any, e: any) => void;
  customerIndex: number;
  showExtraFieldsIndex: number | null;
  showExpandOption?: boolean;
  handleInputChange: (value: any, key: any, index: any) => void;
  handleDropdownClick: (e: any) => void;
  handleCalenderClick: (e: any) => void;
  handleCalenderBlur: (e: any) => void;
  dropdownCallMapping: Record<string, any>;
  showFieldsByFlow: Record<string, string[]>;
  showFieldsByDisposition: Record<string, string[]>;
  showLanguageByFlow: Record<string, any>;
}

export default function DataRow(props: props) {
  let allInputFields = props.rowSchema.data.filter(
    (item: any) => item.isVisible
  );
  allInputFields = [...allInputFields].sort(
    (a, b) => a?.position - b?.position
  );
  const fieldsToShow =
    props.showFieldsByFlow[props.customerData?.["primaryInfo.flow"]] ||
    props.showFieldsByFlow[""]; // options for selected flow
  const fieldsToShowByDisposition =
    props.showFieldsByDisposition[
      props.customerData?.["behaviourDetails.callDisposition"]
    ];
  return (
    <div className={`row__part${props.rowPosition}`}>
      <div className="centerName centerFirst">
        <div className="row__title">{props.rowSchema.name}</div>
      </div>
      <div className="part__inner-div">
        {/* dynamic */}
        {allInputFields.map((inputFieldSchema: any, i: number) => {
          return (
            <InputFields
              type={inputFieldSchema.type}
              inputFieldSchema={inputFieldSchema}
              key={i}
              handleInputChange={props.handleInputChange}
              customerIndex={props.customerIndex}
              customerData={props.customerData}
              handleDropdownClick={props.handleDropdownClick}
              handleCalenderClick={props.handleCalenderClick}
              handleCalenderBlur={props.handleCalenderBlur}
              dropdownCallMapping={props.dropdownCallMapping}
              showLanguageByFlow={props.showLanguageByFlow}
              shouldShow={
                (!fieldsToShow && !fieldsToShowByDisposition) ||
                fieldsToShow?.includes(inputFieldSchema.name) ||
                fieldsToShowByDisposition?.includes(inputFieldSchema.name) ||
                (inputFieldSchema.name === "Paid Date" &&
                  props.customerData[
                    "loanAccountDetails.currentPaymentStatus"
                  ] === "Paid") // should display all fields if no flow is selected
              }
            />
          );
        })}
        {props.showExpandOption && (
          <div className="extra-fields-toggle">
            <img
              className={`expand-arrow ${
                props.showExtraFieldsIndex === props.customerIndex
                  ? "expanded-arrow"
                  : ""
              }`}
              onClick={(e) =>
                props.handleShowMoreToggle(props.customerIndex, e)
              }
              src={expandArrow}
              alt="expandArrow"
              id={"auto_expandArrow"}
            />
          </div>
        )}
      </div>
    </div>
  );
}
