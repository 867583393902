import { Reducer } from "redux";
import actionType from "../actionTypes";
import { stateInterface, actionInterface } from "../interface";

const initialState: stateInterface = {
  analyticsChartDetails: {},
  isLoadingAnalyticsChart: {},
  analyticsChartError: "",
  isExpand: false,
  expandedChart: [],
  isSort: false,
  sortedChart: "",
  analyticsChartDownloadCsvError: "",
  analyticsChartDownloadCsvData: {},
  viewMoreChart: "",
  isViewMore: false,
  analyticsCardDetails: {},
  isLoadingAnalyticsCard: {},
  analyticsCardError: "",
  cardName: "Total Customers",
};

const analyticsReducer: Reducer<stateInterface> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case actionType.ANALYTICS_API_DATA_REQUEST: {
      const loadingkey: any = {};
      loadingkey[action.payload.bodyData.uniqueId] = true;
      return {
        ...state,
        isLoadingAnalyticsChart: {
          ...state.isLoadingAnalyticsChart,
          ...loadingkey,
        },
      };
    }
    // for making all cards details and charts in single object
    case actionType.ANALYTICS_API_DATA_SUCCESS: {
      const loadingkey: any = {};
      loadingkey[action.uniqueId] = false;
      return {
        ...state,
        isLoadingAnalyticsChart: {
          ...state.isLoadingAnalyticsChart,
          ...loadingkey,
        },
        analyticsChartDetails: {
          ...state.analyticsChartDetails,
          ...action.payload,
          ...state.analyticsCardDetails,
        },
      };
    }
    case actionType.ANALYTICS_API_DATA_FAILURE: {
      const loadingkey: any = {};
      loadingkey[action.uniqueId] = false;
      return {
        ...state,
        isLoadingAnalyticsChart: {
          ...state.isLoadingAnalyticsChart,
          ...loadingkey,
        },
        analyticsChartError: action.payload,
      };
    }

    case actionType.SET_EXPAND_CHART:
      return {
        ...state,
        isExpand: action.payload.isExpand,
        expandedChart: action.payload.expandedChart,
      };

    case actionType.SET_SORT_CHART:
      return {
        ...state,
        isSort: action.payload.isSort,
        sortedChart: action.payload.sortedChart,
      };

    case actionType.ANALYTICS_CHARTS_DOWNLOAD_CSV_API_REQUEST:
      return {
        ...state,
      };

    case actionType.ANALYTICS_CHARTS_DOWNLOAD_CSV_SUCCESS: {
      return {
        ...state,
        analyticsChartDownloadCsvData: action.payload,
      };
    }

    case actionType.ANALYTICS_CHARTS_DOWNLOAD_CSV_FAILURE: {
      return {
        ...state,
        analyticsChartDownloadCsvError: action.payload,
      };
    }

    case actionType.SET_VIEW_MORE:
      return {
        ...state,
        isViewMore: action.payload.isViewMore,
        viewMoreChart: action.payload.viewMoreChart,
      };
    case actionType.ANALYTICS_API_CARD_DATA_REQUEST: {
      const loadingCardkey: any = {};
      loadingCardkey[action.payload.bodyData.uniqueId] = true;
      return {
        ...state,
        isLoadingAnalyticsCard: {
          ...state.isLoadingAnalyticsCard,
          [action.payload.bodyData.uniqueId]: true,
        },
      };
    }
    // for making single object for cardDetails
    case actionType.ANALYTICS_API_CARD_DATA_SUCCESS: {
      const loadingCardkey: any = {};
      loadingCardkey[action.uniqueId] = false;
      return {
        ...state,
        isLoadingAnalyticsCard: {
          ...state.isLoadingAnalyticsCard,
          [action.uniqueId]: false,
        },
        analyticsCardDetails: {
          card_details: {
            ...state.analyticsCardDetails?.card_details,
            ...action.payload,
          },
        },
      };
    }
    case actionType.ANALYTICS_API_CARD_DATA_FAILURE: {
      const loadingCardkey: any = {};
      loadingCardkey[action.uniqueId] = false;
      return {
        ...state,
        isLoadingAnalyticsCard: {
          ...state.isLoadingAnalyticsCard,
          [action.uniqueId]: false,
        },
        analyticsCardError: action.payload,
      };
    }
    case actionType.SET_CARD_NAME:
      return {
        ...state,
        cardName: action.payload.cardName,
      };

    default: {
      return state;
    }
  }
};

export default analyticsReducer;
