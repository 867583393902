import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux";
import { setRootPortalScreen } from "../../../../redux/baseScreen/baseScreenState/actions";
import {
  setCallStatus,
  handleChatFinishAction,
} from "../../../../redux/agentDesktop/BaseScreenAD/action";
import {
  setTimestampCallAccept,
  setShowCallModule,
  getSessionIdAgentDesktop,
  setCallSessionIdLoader,
} from "../../../../redux/agentDesktop/callLogger/action";
import portalTypes from "../../../../screens/rootPortal/portalTypes";
import IncomingCallCard from "../../generic/notifications/incomingCallCard/IncomingCallCard";
import ChatNotification from "../../generic/notifications/chatNotification/ChatNotification";
import "./CallChatNotificationPopup.scss";
import { useNavigate } from "react-router-dom";
import screenType from "../../../../constants/screenType";
import { setMessageStatusGroup } from "../../../../redux/agentDesktop/chatLogger/action";
import moment from "moment";
interface props {
  children: any;
}

export default function callChatNotification() {
  /* Redux hooks */
  const rootPortalScreen: string = useSelector(
    (store: RootState) => store.baseScreen.baseScreenState.rootPortalScreen
  );
  const callNotification = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.callNotification
  );
  const chatNotification = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.chatNotification
  );
  const accountName: any = useSelector<RootState>(
    (state) =>
      state.loginReducer?.userLoginInfo?.userDetail?.accountDetails[0]?.name
  );
  const agentId: any = useSelector<RootState>(
    (state) => state.loginReducer.userLoginInfo.userDetail?._id
  );
  const callerNumber = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.callNotification.callerNumber
  );
  const dispatch = useDispatch();
  /* other hooks */
  const ref = useRef(null);
  const navigate = useNavigate();

  /* useEffects */
  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        (ref.current as HTMLElement).classList.add("showNotification");
      }
    }, 100);
  }, []);

  /* helper function */
  function getSessionIds() {
    dispatch(
      getSessionIdAgentDesktop({
        accountName,
        channel: ["Call"],
        phoneNo: callerNumber || "xxxxxxxxxx",
        limit: 4,
        page: 1,
        // agentIds: [agentId],
      })
    );
  }

  /* handlers -------- */
  const handleCallPickUpClick = () => {
    dispatch(setCallSessionIdLoader(true));
    setTimeout(() => getSessionIds(), 3000);
    dispatch(setRootPortalScreen(portalTypes.NULL_PORTAL));
    dispatch(setCallStatus("accept"));
    dispatch(setShowCallModule(true));
    navigate(screenType.callLoggerAgent.routeName);
    dispatch(setTimestampCallAccept(moment(new Date())));
  };
  const handleCallRejectClick = () => {
    dispatch(setCallStatus("decline"));
    dispatch(setRootPortalScreen(portalTypes.NULL_PORTAL));
    setTimeout(() => dispatch(setCallStatus("")), 1000);
  };
  const handleChatBtnClick = () => {
    dispatch(setRootPortalScreen(portalTypes.NULL_PORTAL));
    // action to se caller details
    navigate(screenType.chatLoggerAgent.routeName);
    dispatch(setMessageStatusGroup("Open"));
  };
  const handleCloseBtnClick = () => {
    dispatch(setRootPortalScreen(portalTypes.NULL_PORTAL));
    dispatch(handleChatFinishAction());
  };

  return (
    <div className="call-chat__popup-notification">
      <div className={"popup-notification"} ref={ref}>
        {rootPortalScreen === portalTypes.CALL_NOTIFICATION_AGENT_DESKTOP && (
          <IncomingCallCard
            handleCallPickUpClick={handleCallPickUpClick}
            handleCallRejectClick={handleCallRejectClick}
            callerName={callNotification.callerName}
            callerNumber={
              callNotification.callerNumber
                ? `XXXXXX${callNotification.callerNumber?.slice(-4)}`
                : ""
            }
          />
        )}
        {rootPortalScreen === portalTypes.CHAT_NOTIFICATION_AGENT_DESKTOP && (
          <ChatNotification
            handleChatBtnClick={handleChatBtnClick}
            handleCloseBtnClick={handleCloseBtnClick}
            header={"New chat"}
            buttonText={"Go To Chat"}
            contactMessage={chatNotification.contactMessage}
            contactName={chatNotification.contactName}
          />
        )}
      </div>
    </div>
  );
}
