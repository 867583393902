import React, { useEffect } from "react";
import styles from "./SMSScreen.module.scss";
import {
  // SMSIntegration,
  closeIntegrationIcon,
} from "../../../../theme/assets/svg/integration_V2.0/index";
import Icon from "../../../generic/icon/Icon";
import Modal from "../../../generic/modal/Modal";
import Button from "../../../generic/button/Button";
import { ExisitingSMSScreen } from "./exisitingSMSScreen/ExisitingSMSScreen";
import { NewSMSScreen } from "./newSMSScreen/NewSMSScreen";
import { clearIntegration } from "../../../../redux/integration_V2.0/allIntegrations/actions/allIntegrations.actions";
import {
  setClearSMSData,
  setNewAccountClean,
  setSMSScreen,
} from "../../../../redux/integration_V2.0/sms/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { backIcon } from "../../../../theme/assets/svg/strategySvg";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";

interface SMSScreenProps {
  shown?: boolean;
  close?: any;
  integrationIcon?: string;
}

export const SMSScreen = ({
  shown,
  close,
  integrationIcon,
}: SMSScreenProps) => {
  const dispatch = useDispatch();

  const selectedIntegrationSMS = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.selectedIntegrationData
  );

  const screenName = useSelector(
    (store: RootState) => store?.integrationReducer?.smsReducer?.currentScreen
  );

  useEffect(() => {
    if (selectedIntegrationSMS?.integrationDetails) {
      if (Object.keys(selectedIntegrationSMS?.integrationDetails)?.length > 2) {
        dispatch(setSMSScreen("newAccScreen"));
      } else if (
        Object.keys(selectedIntegrationSMS?.integrationDetails)?.length === 2
      ) {
        dispatch(setSMSScreen("exisitingAccScreen"));
      } else {
        dispatch(setSMSScreen(""));
      }
    }
  }, [selectedIntegrationSMS]);

  const handleCreateAccount = () => {
    dispatch(setSMSScreen("newAccScreen"));
  };

  const handleExisitngAccount = () => {
    dispatch(setSMSScreen("exisitingAccScreen"));
  };

  const handleCloseScreen = () => {
    dispatch(setSMSScreen(""));
    dispatch(clearIntegration());
    dispatch(setClearSMSData());
    dispatch(setNewAccountClean());
    close();
  };

  return (
    <Modal
      shown={shown}
      close={handleCloseScreen}
      extraClassStyleModalBackDrop={styles.integrationModalStyling}
      extraClassStyleModalContent={styles.integrationModalContentStyling}
    >
      <div className={styles.smsWrapper}>
        <div className={styles.smsHeaderDiv}>
          <div className={styles.smsLeftHeaderDiv}>
            {/* <Icon
              img_src={SMSIntegration}
              extraClass={styles.headerIconStyling}
            /> */}
            <PravidIcons
              activeIcon={integrationIcon}
              extraClass={styles.headerIconStyling}
            />
            <div className={styles.headerTextStyling}>SMS</div>
          </div>
          <div className={styles.smsRightHeaderDiv}>
            {screenName === "newAccScreen" ||
            screenName === "exisitingAccScreen" ? (
              <div className={styles.backIconStyling}>
                <Icon
                  img_src={backIcon}
                  extraClass={styles.iconBackStyling}
                  onClick={() => {
                    dispatch(setSMSScreen(""));
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <div className={styles.closeIconStyling}>
              <Icon
                img_src={closeIntegrationIcon}
                extraClass={styles.iconCloseStyling}
                onClick={handleCloseScreen}
              />
            </div>
          </div>
        </div>
        <div className={styles.subHeaderDiv}>
          We can quickly set up a new DLT account for you to start sending SMS
          or you can connect your existing account to Pravid.
        </div>

        {screenName === "exisitingAccScreen" ? (
          <ExisitingSMSScreen onClickClose={handleCloseScreen} />
        ) : screenName === "newAccScreen" ? (
          <NewSMSScreen onClickClose={handleCloseScreen} />
        ) : (
          <div className={styles.contentDiv}>
            <div className={styles.subDiv}>
              <Button
                text="Connect Existing Account"
                extraClass={`${styles.buttonStyling} ${styles.activeBtnStyling}`}
                onClick={handleExisitngAccount}
              />
            </div>
            <div className={styles.subDiv}>
              <Button
                text="Setup New Account"
                extraClass={`${styles.buttonStyling} ${styles.activeBtnStyling}`}
                onClick={handleCreateAccount}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
