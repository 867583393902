export default {
  SET_ALL_NUDGE_DATA: "SET_ALL_NUDGE_DATA",
  UPDATE_EXPECTED_DATA: "UPDATE_EXPECTED_DATA",
  SET_CONNECTED_NUDGE: "SET_CONNECTED_NUDGE",
  SET_CONNECTED_NUDGE_ID: "SET_CONNECTED_NUDGE_ID",
  SET_CONNECTED_NUDGE_START: "SET_CONNECTED_NUDGE_START",
  SET_CONNECTED_NUDGE_END: "SET_CONNECTED_NUDGE_END",
  SHOW_DOT: "SHOW_DOT",
  CREATE_NUDGE_LODDING: "CREATE_NUDGE_LODDING",
  SET_TYPE_OF_STRATEGY_MODE: "SET_TYPE_OF_STRATEGY_MODE",
  REMOVE_CONNECTION_LINE: "REMOVE_CONNECTION_LINE",
  REMOVE_ID_DELETE: "REMOVE_ID_DELETE",

  CREATE_NUDGE_API_REQUEST: "CREATE_NUDGE_API_REQUEST",
  CREATE_NUDGE_API_SUCCESS: "CREATE_NUDGE_API_SUCCESS",
  CREATE_NUDGE_API_FAILURE: "CREATE_NUDGE_API_FAILURE",

  GET_NUDGE_API_REQUEST: "GET_NUDGE_API_REQUEST",
  GET_NUDGE_API_SUCCESS: "GET_NUDGE_API_SUCCESS",
  GET_NUDGE_API_FAILURE: "GET_NUDGE_API_FAILURE",

  UPDATE_NUDGE_API_REQUEST: "UPDATE_NUDGE_API_REQUEST",
  UPDATE_NUDGE_API_SUCCESS: "UPDATE_NUDGE_API_SUCCESS",
  UPDATE_NUDGE_API_FAILURE: "UPDATE_NUDGE_API_FAILURE",

  SET_CHANNEL_SELECTED: "SET_CHANNEL_SELECTED",
  SET_WORKING_NUDGE_DATA: "SET_WORKING_NUDGE_DATA",
  SET_IS_SCRIPT_SELECT_VISITED: "SET_IS_SCRIPT_SELECT_VISITED",

  GET_CONDITION_API_REQUEST: "GET_CONDITION_API_REQUEST",
  GET_CONDITION_API_SUCCESS: "GET_CONDITION_API_SUCCESS",
  GET_CONDITION_API_FAILURE: "GET_CONDITION_API_FAILURE",

  GET_CONDITION_EDIT_API_REQUEST: "GET_CONDITION_EDIT_API_REQUEST",
  GET_CONDITION_EDIT_API_SUCCESS: "GET_CONDITION_EDIT_API_SUCCESS",
  GET_CONDITION_EDIT_API_FAILURE: "GET_CONDITION_EDIT_API_FAILURE",

  GET_OUTCOMES_DISPOSITIONS_API_REQUEST:
    "GET_OUTCOMES_DISPOSITIONS_API_REQUEST",
  GET_OUTCOMES_DISPOSITIONS_API_SUCCESS:
    "GET_OUTCOMES_DISPOSITIONS_API_SUCCESS",
  GET_OUTCOMES_DISPOSITIONS_API_FAILURE:
    "GET_OUTCOMES_DISPOSITIONS_API_FAILURE",

  SET_VARIATION_SELECTED: "SET_VARIATION_SELECTED",
  SET_SHOW_MINDMAP: "SET_SHOW_MINDMAP",

  GET_DISPOSITION_ALTERNATE_API_REQUEST:
    "GET_DISPOSITION_ALTERNATE_API_REQUEST",
  GET_DISPOSITION_ALTERNATE_API_SUCCESS:
    "GET_DISPOSITION_ALTERNATE_API_SUCCESS",
  GET_DISPOSITION_ALTERNATE_API_FAILURE:
    "GET_DISPOSITION_ALTERNATE_API_FAILURE",

  GET_FOLLOWUPS_DISPOSITION_API_REQUEST:
    "GET_FOLLOWUPS_DISPOSITION_API_REQUEST",
  GET_FOLLOWUPS_DISPOSITION_API_SUCCESS:
    "GET_FOLLOWUPS_DISPOSITION_API_SUCCESS",
  GET_FOLLOWUPS_DISPOSITION_API_FAILURE:
    "GET_FOLLOWUPS_DISPOSITION_API_FAILURE",

  GET_PAYMENT_MODE_API_REQUEST: "GET_PAYMENT_MODE_API_REQUEST",
  GET_PAYMENT_MODE_API_SUCCESS: "GET_PAYMENT_MODE_API_SUCCESS",
  GET_PAYMENT_MODE_API_FAILURE: "GET_PAYMENT_MODE_API_FAILURE",

  SET_SELECTED_FLOW_RANGE :  "SET_SELECTED_FLOW_RANGE"
};
