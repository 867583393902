import actionTypes from "../email.actionTypes";

export interface stateInterface {
  currentScreen: string;
  emailsDNSData: object;
  isLoadingemailsDNSData: boolean;
  emailsDNSDataError: string;
  verifyDNSData: object;
  isLoadingDNSData: boolean;
  verifyDNSDataError: string;
  sendDNSToDevData: object;
  isLoadingsendDNSToDevData: boolean;
  sendDNSToDevDataError: string;
  getDevEmailDetails: object;
  isLoadingDevEmailDetails: boolean;
  getDevEmailDetailsError: string;
}

const initialState: stateInterface = {
  currentScreen: "",
  emailsDNSData: {},
  isLoadingemailsDNSData: false,
  emailsDNSDataError: "",
  verifyDNSData: {},
  isLoadingDNSData: false,
  verifyDNSDataError: "",
  sendDNSToDevData: {},
  isLoadingsendDNSToDevData: false,
  sendDNSToDevDataError: "",
  getDevEmailDetails: {},
  isLoadingDevEmailDetails: false,
  getDevEmailDetailsError: "",
};

export default function emailReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_EMAIL_DNS_DETAILS_API_REQUEST ||
      actionTypes.UPDATE_EMAIL_DNS_DETAILS_API_REQUEST: {
      return {
        ...state,
        emailsDNSData: {},
        isLoadingemailsDNSData: true,
        emailsDNSDataError: "",
      };
    }
    case actionTypes.GET_EMAIL_DNS_DETAILS_API_SUCCESS ||
      actionTypes.UPDATE_EMAIL_DNS_DETAILS_API_SUCCESS: {
      return {
        ...state,
        emailsDNSData: action.payload,
        isLoadingemailsDNSData: false,
        emailsDNSDataError: "",
      };
    }
    case actionTypes.GET_EMAIL_DNS_DETAILS_API_FAILURE ||
      actionTypes.UPDATE_EMAIL_DNS_DETAILS_API_FAILURE: {
      return {
        ...state,
        emailsDNSData: {},
        isLoadingemailsDNSData: false,
        emailsDNSDataError: action.payload,
      };
    }
    case actionTypes.VERIFY_EMAIL_DNS_DETAILS_API_REQUEST: {
      return {
        ...state,
        verifyDNSData: {},
        isLoadingDNSData: true,
        verifyDNSDataError: "",
      };
    }
    case actionTypes.VERIFY_EMAIL_DNS_DETAILS_API_SUCCESS: {
      return {
        ...state,
        verifyDNSData: action.payload,
        isLoadingDNSData: false,
        verifyDNSDataError: "",
      };
    }
    case actionTypes.VERIFY_EMAIL_DNS_DETAILS_API_FAILURE: {
      return {
        ...state,
        verifyDNSData: {},
        isLoadingDNSData: false,
        verifyDNSDataError: action.payload,
      };
    }
    case actionTypes.SEND_DNS_TO_DEV_EMAIL_API_REQUEST: {
      return {
        ...state,
        sendDNSToDevData: {},
        isLoadingsendDNSToDevData: true,
        sendDNSToDevDataError: "",
      };
    }
    case actionTypes.SEND_DNS_TO_DEV_EMAIL_API_SUCCESS: {
      return {
        ...state,
        sendDNSToDevData: action.payload,
        isLoadingsendDNSToDevData: false,
        sendDNSToDevDataError: "",
      };
    }
    case actionTypes.SEND_DNS_TO_DEV_EMAIL_API_FAILURE: {
      return {
        ...state,
        sendDNSToDevData: {},
        isLoadingsendDNSToDevData: false,
        sendDNSToDevDataError: action.payload,
      };
    }
    case actionTypes.GET_DEV_EMAIL_DETAILS_API_REQUEST: {
      return {
        ...state,
        getDevEmailDetails: {},
        isLoadingDevEmailDetails: true,
        getDevEmailDetailsError: "",
      };
    }
    case actionTypes.GET_DEV_EMAIL_DETAILS_API_SUCCESS: {
      return {
        ...state,
        getDevEmailDetails: action.payload,
        isLoadingDevEmailDetails: false,
        getDevEmailDetailsError: "",
      };
    }
    case actionTypes.GET_DEV_EMAIL_DETAILS_API_FAILURE: {
      return {
        ...state,
        getDevEmailDetails: {},
        isLoadingDevEmailDetails: false,
        getDevEmailDetailsError: action.payload,
      };
    }

    case actionTypes.SET_CURRENT_SCREEN_EMAIL: {
      return {
        ...state,
        currentScreen: action.payload,
      };
    }
    case actionTypes.CLEAR_EMAIL_DATA: {
      return { ...initialState };
    }
    case actionTypes.CLEAR_VERIFY_DATA: {
      return {
        ...state,
        verifyDNSData: {},
        isLoadingDNSData: false,
        verifyDNSDataError: "",
      };
    }
    default:
      return state;
  }
}
