import React, { useEffect, useState, useRef } from "react";

import "./DialTimeModal.css";

import DateFilter from "../../../generic/datefilter/DateFilter";
import ToggleSwitch from "../../../generic/toggleSwitch/ToggleSwitch";
import DropDown from "../../../generic/dropdownsaarthi2/DropdownSaarthi";
import ErrorMessage from "../../../generic/errorMessage/ErrorMessage";

import clockIcon from "../../../../theme/assets/svg/campaign/clockIcon.svg";
import arrowTime from "../../../../theme/assets/svg/campaign/arrowTime.svg";
import timeZoneIcon from "../../../../theme/assets/svg/campaign/timeZone.svg";
import downArrow from "../../../../theme/assets/svg/campaign/dropdownIconDown.svg";
import CalendarIcon from "../../../../theme/assets/genericSvg/calendarIcon.svg";

import MaxAttempt from "../../campaign/schedulerAllComponent/attempModal/MaxAttempt";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { getSchedularAPIData } from "../../../../redux/campaign_V2.0/schedularSetting/actions/schedularSetting.actions";
import {
  SERVER_URL_CONNECTOR,
  CAMPAIGN_URL,
  config,
} from "../../../../services/ApiRoutes";
import axios from "axios";
import moment from "moment";

export default function DialTimeModal(props) {
  const campaignData = useSelector(
    (store) => store?.campaignModuleReducer?.schedulerData?.createdCampaign
  );

  const dialTimeData = useSelector((store) => {
    return store.campaignModuleReducer?.schedulerData?.getSchedularDetails
      ?.dialTimeData?.data;
  });

  const token = useSelector(
    (store) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const [optionLabels, setOptionLabels] = useState(["On", "Off"]);
  const [isNavToggleActive, setIsNavToggleActive] = useState(
    String(dialTimeData?.autoDial)?.length !== 9 ? dialTimeData?.autoDial : true
  );
  const [selectedPer, setSelectedPer] = useState();
  const [startTime, setStartTime] = useState(
    dialTimeData?.startTime ? dialTimeData?.startTime : null
  );
  const [endTime, setEndTime] = useState(
    dialTimeData?.endTime ? dialTimeData?.endTime : null
  );
  const [maxAttempt, setmaxAttempt] = useState();
  const [sTime, setSTime] = useState();
  const [eTime, setETime] = useState();
  const [startMinutes, setStartMinutes] = useState(null);
  const [endMinutes, setEndMinutes] = useState(null);
  const [startPlaceholder, setStartPlaceholder] = useState();
  const [endPlaceholder, setEndPlaceholder] = useState();
  const [datechange, setDateChange] = useState();
  // const [datechange, setDateChange] = useState({
  //   startDate:
  //     dialTimeData?.start_date_range &&
  //     moment(dialTimeData?.start_date_range).toDate(),
  //   // : format(new Date(), "yyy-MM-dd"),
  //   endDate:
  //     dialTimeData?.end_date_ranfge &&
  //     moment(dialTimeData?.end_date_ranfge).toDate(),
  //   // : format(new Date(), "yyy-MM-dd"),
  // });
  // useEffect(() => {
  //   console.log(startPlaceholder, endPlaceholder, "aaa");
  //   console.log(moment(new Date(startPlaceholder)).format("hh:mm A"), "aaa");
  //   console.log(
  //     moment(new Date(dialTimeData?.e_time)).format("hh:mm A"),
  //     "aaa"
  //   );
  // });

  const [errorStartTime, setErrorStartTime] = useState();
  const [errorEndTime, setErrorEndTime] = useState();

  const handleDisableToggleSwitch = (checked) => {
    setIsNavToggleActive((prev) => checked);
  };

  const propsForPre = {
    optionList: ["Day", "Hour", "Campaign"],
    imgSrcRight: downArrow,
    placeHolderText: dialTimeData?.frequency
      ? dialTimeData?.frequency
      : selectedPer
      ? selectedPer
      : "select",
  };

  const dispatch = useDispatch();

  const onChangePer = (item) => {
    setSelectedPer((prev) => item);
  };

  useEffect(() => {
    setSelectedPer("Day");
  }, []);

  useEffect(() => {
    dispatch(
      getSchedularAPIData({
        token: token,
        campaignId: campaignData?.id,
        strategyId: campaignData?.strategyId,
      })
    );
  }, [campaignData?.id, campaignData?.strategyId, token]);

  // let fromDate = moment(dialTimeData?.start_date_range).format("YYYY-MM-DD");
  // let toDate = moment(dialTimeData?.end_date_ranfge).format("YYYY-MM-DD");

  // let fromDate = props.filteredDateRangeData?.fromDate;
  // let toDate = props.filteredDateRangeData?.toDate;

  // console.log(fromDate, toDate, "jjj");

  //For getting time in minute

  //GET ALL DISP LIST FOR ALTERNATE NUMBER
  useEffect(() => {
    const getDispurl = CAMPAIGN_URL.GET_DISPLIST_ALTERNATENUMBER;
    config
      .GET(getDispurl)
      ?.then((res) => setAlternateDispDropdowndata(res?.data?.data));
  }, []);

  useEffect(() => {
    if (startTime) {
      let start = new Date(startTime)
        ?.toLocaleString(undefined, { timeZone: "Asia/Kolkata" })
        ?.split(",")[1];

      let startArray = start?.split(":");
      let startMinute =
        new Date(startTime)?.getHours() * 60 +
        Number(new Date(startTime)?.getMinutes());

      setStartMinutes((prev) => startMinute);
      if (startMinute >= 0) {
        let endMinute;
        if (endTime) {
          let end = new Date(endTime)
            ?.toLocaleString(undefined, { timeZone: "Asia/Kolkata" })
            ?.split(",")[1];
          let endArray = end?.split(":");
          endMinute =
            new Date(endTime)?.getHours() * 60 +
            Number(new Date(endTime)?.getMinutes());
        }
        if (startMinute < (endMinute || dialTimeData?.e_time)) {
          setStartMinutes((prev) => startMinute);
          setErrorStartTime((prev) => false);
          setErrorEndTime((prev) => false);
        } else {
          if (endMinute || dialTimeData?.e_time) {
            setErrorStartTime((prev) => true);
          }
        }
      } else {
        setStartMinutes((prev) => startMinute);
      }
    } else {
      setStartMinutes((prev) => null);
    }
  }, [startTime]);

  useEffect(() => {
    if (endTime) {
      let end = new Date(endTime)
        ?.toLocaleString(undefined, { timeZone: "Asia/Kolkata" })
        ?.split(",")[1];
      let endArray = end?.split(":");
      let endMinute =
        new Date(endTime)?.getHours() * 60 +
        Number(new Date(endTime)?.getMinutes());
      // setEndMinutes(prev => endMinute)
      if (endMinute <= 1439) {
        let startMinute;
        if (startTime) {
          let start = new Date(startTime)
            ?.toLocaleString(undefined, { timeZone: "Asia/Kolkata" })
            ?.split(",")[1];
          let startArray = start?.split(":");
          startMinute =
            new Date(startTime)?.getHours() * 60 +
            Number(new Date(startTime)?.getMinutes());
        }

        if (endMinute > (startMinute || dialTimeData?.s_time)) {
          setEndMinutes((prev) => endMinute);
          setErrorEndTime((prev) => false);
          setErrorStartTime((prev) => false);
        } else {
          if (startMinute || dialTimeData?.s_time) {
            setErrorEndTime((prev) => true);
          }
        }
      } else {
        setEndMinutes((prev) => endMinute);
      }
    }
    // else{
    //   if(props.schedulerData?.dialTimeData?.e_time){
    //     setEndMinutes(prev => props.schedulerData?.dialTimeData?.e_time)
    //   }
    else {
      setEndMinutes((prev) => null);
    }
    // }
  }, [endTime]);

  useEffect(() => {
    const urlUpdate = `${SERVER_URL_CONNECTOR}${CAMPAIGN_URL.UPDATE_CAMPAIGN}`;
    let fromDate = datechange?.fromDate;
    let toDate = datechange?.toDate;
    let obj = {
      id: [campaignData?.id],
      startDate: fromDate,
      endDate: toDate,
      mode: "update",
    };

    let headers = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    };
    if (datechange) {
      axios
        .post(urlUpdate, obj, headers)
        .then((res) => {
          if (res?.data) {
            toast.success("Campaign Date Range updated successfully!");
          }
          if (res.status !== 200) {
            toast.success("Campaign  Date Range Updation not successful!");
          }
        })
        .catch((err) => {
          toast.error("Campaign  Date Range Updation not successful");
          if (err.status !== 200) {
          }
        });
    }
    // }
    //  }
  }, [datechange]);

  //converting minutes time in hour
  let ans;
  useEffect(() => {
    if (dialTimeData?.s_time) {
      setStartMinutes((prev) => dialTimeData?.s_time);
      let hours = Math.floor(dialTimeData?.s_time / 60);
      let minutes = dialTimeData?.s_time % 60;
      let hh = hours < 10 ? `0${hours}` : hours;

      ans = `${hours}:${minutes}:00`;
      setSTime((prev) => `${hh}:${minutes}:00`);
    }
    if (dialTimeData?.e_time) {
      setEndMinutes((prev) => dialTimeData?.e_time);
      let hoursE = Math.floor(dialTimeData?.e_time / 60);
      let he = hoursE < 10 ? `0${hoursE}` : hoursE;
      let minutesE = dialTimeData?.e_time % 60;
      setETime((prev) => `${he}:${minutesE}:00`);
    }
  }, [dialTimeData]);

  useEffect(() => {
    if (sTime?.length > 0) {
      setStartPlaceholder(
        (prev) =>
          new Date(`Tue Jul 12 2022 ${sTime} GMT+0530 (India Standard Time)`)
      );
    }
    if (eTime?.length > 0) {
      setEndPlaceholder(
        (prev) =>
          new Date(`Tue Jul 12 2022 ${eTime} GMT+0530 (India Standard Time)`)
      );
    }
  }, [sTime, eTime]);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div className="dialTimeWrapperDiv">
      <div className="outerDivDialTime">
        <div className="dialRowDiv">
          <div className="dialTimeColumnDiv">
            <div className="headingDivDial">Date Range:</div>
            <div className="calender">
              {/* <DateFilter
                id="dateRangeOne"
                dateHeader={"show"}
                disableRangeMin={true}
                typeText="update"
                onChangeValue="true"
                getonChangeValue={setDateChange}
              /> */}{" "}
              <div className={`fdCalendarDateRangeIcon`}>
                <img
                  src={CalendarIcon}
                  style={{ width: "15px" }}
                  alt="Calendar Icon"
                />
              </div>
              <div className={`fdCalendarDateRange`}>
                <span className={`dateRangeAreafltr `} id="auto_date_range">
                  {" "}
                  {dialTimeData?.start_date_range
                    ? moment(dialTimeData?.start_date_range).format("DD MMM YY")
                    : moment(new Date()).format("DD MMM YY")}
                  -{" "}
                  {dialTimeData?.end_date_ranfge
                    ? moment(dialTimeData?.end_date_ranfge).format("DD MMM YY")
                    : moment(new Date()).format("DD MMM YY")}
                </span>
              </div>
            </div>
          </div>
          <div className="dialTimeColumnDiv">
            <div className="headingDivDial">Start Time:</div>
            <div>
              <div className="inputBorderForm">
                <img src={clockIcon} alt="Email Id Icon" />
                <div className="timeStyling" id="auto_start_time">
                  {dialTimeData?.s_time == 0
                    ? "12:00 AM"
                    : moment(new Date(startPlaceholder)).format("hh:mm A")}
                </div>
                {/* <DatePicker
                  selected={
                    startTime
                      ? startTime
                      : startPlaceholder
                      ? new Date(startPlaceholder)
                      : startTime
                  }
                  filterTime={filterPassedTime}
                  onChange={(date) => setStartTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  onKeyDown={(e) => {
                    if (e.key === "Backspace" || e.key === "Delete") {
                      return false;
                    } else {
                      return e.preventDefault();
                    }
                  }}
                /> */}
              </div>
              <div className="errorDiv" id="auto_error_msg_start_time">
                {errorStartTime ? (
                  <ErrorMessage errorMessage="Start Time should be smaller than End Time" />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="arrow">
            <img src={arrowTime}></img>
          </div>
          <div className="dialTimeColumnDiv">
            <div className="headingDivDial">End Time:</div>
            <div>
              <div className="inputBorderForm">
                <img src={clockIcon} alt="Email Id Icon" />
                <div className="timeStyling" id="auto_end_time">
                  {dialTimeData?.e_time == 0
                    ? "12:00 AM"
                    : moment(new Date(endPlaceholder)).format("hh:mm A")}
                </div>
                {/* <DatePicker
                  //  selected={  endTime ? endTime : endPlaceholder ? new  Date(endPlaceholder): endTime }
                  selected={
                    endTime
                      ? endTime
                      : endPlaceholder
                      ? new Date(endPlaceholder)
                      : endTime
                  }
                  onChange={(date) => setEndTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  onKeyDown={(e) => {
                    if (e.key === "Backspace" || e.key === "Delete") {
                      return false;
                    } else {
                      return e.preventDefault();
                    }
                  }}
                />*/}
              </div>
              <div className="errorDiv" id="auto_error_msg_end_time">
                {errorEndTime ? (
                  <ErrorMessage errorMessage="End Time should be greater than Start Time " />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="dialRowDiv">
          <div className="dialTimeColumnDiv">
            <div className="headingDivDial">Time Zone:</div>
            <div>
              <div className="timeZoneDiv">
                <img src={timeZoneIcon}></img>
                <p className="paraTime">UTC +5:30 IST</p>
              </div>
              {/* <DateFilter  id="dateRangeOne" dateHeader={'show'} /> */}
            </div>
          </div>
          <div className="dialTimeColumnDiv">
            <div className="headingDivDial">Max no. of attempts:</div>
            <div className="containerDropDown" id="auto_max_attempt">
              <MaxAttempt
                setAttempt={setmaxAttempt}
                MaxAttempt={dialTimeData?.max_attempts}
                isDataActive={true}
                isDisabled={true}
              />
              <p className="perDial">Per</p>
              <DropDown
                droplist={propsForPre}
                // isFilter={true}
                searchUi={false}
                handleSearchItem={selectedPer}
                selectedItem={(item) => onChangePer(item)}
                extraClassSelectedArea={"preEmidropdown"}
                extraClassToBeSelectedArea={"dropdowndListArea"}
                isDisabled={true}
                id="auto_frequency_dropdown"
              />
            </div>
          </div>

          <div className="dialTimeColumnDiv">
            <div className="headingDivDial">Auto Dial:</div>
            <div>
              <ToggleSwitch
                id="dialTimeModal_toggleswitch"
                checked={isNavToggleActive}
                optionLabels={optionLabels}
                small={true}
                onChange={(checked) => handleDisableToggleSwitch(checked)}
                data={isNavToggleActive}
                isDisabled={true}
              />
              <span className="togglePara">
                {isNavToggleActive ? "ON" : "OFF"}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer
        position="top-center"
        type="success"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={true}
      /> */}
    </div>
  );
}
