import rightSidePopupActionTypes from "../actionTypes/rightSidePopup.actionTypes";

export function setSelectedIconAgent(icon: string) {
  return {
    type: rightSidePopupActionTypes.SET_SELECTED_ICON,
    payload: icon,
  };
}
export function setShowRightSideBarAgent(show: boolean) {
  return {
    type: rightSidePopupActionTypes.SET_SHOW_RIGHT_SIDE_BAR,
    payload: show,
  };
}
