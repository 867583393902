import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { INTEGRATION_API, config } from "../../../../services/ApiRoutes";
import actionTypes from "../whatsApp.actionTypes";
import integrationActionTypes from "../../allIntegrations/allIntegrations.actionTypes";
import { store } from "../../../rootStore";

export function* getAllIndustryDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_ALL_INDUSTRY}`
      );

      yield put({
        type: actionTypes.GET_ALL_INDUSTRY_DATA_SUCCESS,
        payload: result?.data.data,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ALL_INDUSTRY_DATA_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}

export function* uploadWhatsAppProfilePicAPIWorker(action: any): any {
  const picture = action.payload.file;
  try {
    if (action) {
      const formData = new FormData();
      formData.append("file", picture);
      formData.append("destination", "images");
      const result = yield call(
        config.PUT_WITH_PARAMS,
        INTEGRATION_API.UPLOAD_PIC_WHATSAPP +
          action.payload.id +
          "/" +
          action.payload.integrationName,
        formData
      );
      const getResult = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_ALL_INTEGRATIONS}`,
        {
          accountId: action.payload.id,
        }
      );
      yield put({
        type: actionTypes.UPDATE_WHATSAPP_PROFILE_PIC_SUCCESS,
        payload: result?.data.data?.integrationDetails?.profilePicPath,
      });
      yield put({
        type: integrationActionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS,
        payload: getResult?.data.data,
      });
      yield put({
        type: integrationActionTypes.SET_SELECTED_INTEGRATION_DATA,
        payload: result?.data.data,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_WHATSAPP_PROFILE_PIC_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}

export function* deleteWhatsAppProfilePicAPIWorker(action: any): any {
  try {
    const response = yield call(
      config.DELETE,
      INTEGRATION_API.DELETE_PIC_WHATSAPP +
        action.payload.id +
        "/" +
        action.payload.integrationName
    );
    const getResult = yield call(
      config.GET_WITH_PARAMS,
      `${INTEGRATION_API.GET_ALL_INTEGRATIONS}`,
      {
        accountId: action.payload.id,
      }
    );
    yield put({
      type: actionTypes.DELETE_WHATSAPP_PROFILE_PIC_SUCCESS,
      payload: response?.data.data?.integrationDetails?.profilePicPath,
    });
    yield put({
      type: integrationActionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS,
      payload: getResult?.data.data,
    });
    const picResponse =
      store.getState().integrationReducer?.allIntegrationsReducer
        ?.selectedIntegrationData;
    yield put({
      type: integrationActionTypes.SET_SELECTED_INTEGRATION_DATA,
      payload: {
        ...picResponse,
        integrationDetails: {
          ...picResponse.integrationDetails,
          profilePicPath: "",
        },
      },
    });
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_WHATSAPP_PROFILE_PIC_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}
