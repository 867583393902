import React, { useState } from "react";
import styles from "./AdvancedSettings.module.scss";
import Button from "../../../../generic/button/Button";
import Modal from "../../../../generic/modal/Modal";
import { closeIntegrationIcon } from "../../../../../theme/assets/svg/integration_V2.0/index";

export const AdvancedSettings = () => {
  const [showPopUp, setShowPopUp] = useState("");

  return (
    <div>
      <div className={styles.AdvancedSettingsWrapper}>
        <div className={styles.TopContainerDiv}>
          <div className={styles.TopLeftContainerDiv}>
            <div className={styles.headerText}>Pause all Campaigns</div>
            <div className={styles.subHeaderText}>
              Pause all the live running campaigns
            </div>
          </div>
          <div className={styles.TopRightContainerDiv}>
            <Button
              text={"Pause"}
              extraClass={`${styles.buttonStyling} ${styles.pauseBtnStyling}`}
              onClick={() => {
                setShowPopUp("pause");
              }}
            />
          </div>
        </div>
        <div className={styles.TopContainerDiv}>
          <div className={styles.TopLeftContainerDiv}>
            <div className={styles.headerText}>Purge Calling Queue</div>
            <div className={styles.subHeaderText}>
              Remove all the scheduled phone numbers from your calling queue.
            </div>
          </div>
          <div className={styles.TopRightContainerDiv}>
            <Button
              text={"Purge"}
              extraClass={`${styles.buttonStyling} ${styles.purgeBtnStyling}`}
              onClick={() => {
                setShowPopUp("purge");
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <Modal
          shown={showPopUp?.length > 0}
          close={() => {
            setShowPopUp("");
          }}
          extraClassStyleModalContent={styles.modalAdvSettingsDiv}
        >
          <div className={styles.modalAdvSettingsInfoDiv}>
            <div className={styles.modalAdvSettingsInfoText}>
              Are you sure you want to {showPopUp} all your live campaigns?
            </div>
            <div className={styles.modalCloseIcon}>
              <img
                src={closeIntegrationIcon}
                onClick={() => {
                  setShowPopUp("");
                }}
                className={styles.iconStyling}
                id="auto_strategy_modal_close_icon"
              />
            </div>
          </div>

          <div className={styles.modalButtonDiv}>
            <Button
              text="Yes"
              extraClass={`${styles.buttonStyling} ${styles.yesButtonStyle}`}
              onClick={() => {
                setShowPopUp("");
              }}
              id="auto_strategy_yes_button"
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};
