import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import Button from '../../../generic/button/Button'
import Modal from '../../../generic/modal/Modal'
import style from "./WorkSpaceSalesOnBoarding.module.scss"
import { RootState } from "../../../../redux";
import { useNavigate } from "react-router-dom";
import {
    addWorkflowAPIData,
    createdStrategy,
} from "../../../../redux/strategy/strategyCreation/actions";
import {
    setLoadingCreate,
    setAllNudgeData,
} from "../../../../redux/strategy/nudgeCreation/actions";
interface props {
    show?: boolean,
    close?: CallableFunction;
}

function WorkSpaceSalesOnBoarding(props: props) {
    const [selectedWorkSpace, setSelectedWorkSpace] = useState<any>({})
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const workspaceFlowData = useSelector(
        (store: RootState) =>
            store?.strategyModuleReducer?.strategyReducer?.workflowDetails
    );
    const selectedStrategy = useSelector(
        (store: RootState) =>
            store?.strategyModuleReducer?.strategyReducer?.createdStrategy
    );
    const moduleSelectedScreen = useSelector(
        (store: RootState) => store?.baseScreen?.leftMenu?.highlightedModule
    );
    const token = useSelector(
        (store: RootState) =>
            store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
    );

    const clientName = useSelector(
        (store: RootState) =>
            store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
    );

    const accountId = useSelector(
        (store: RootState) =>
            store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
    );


    const resultOfAddFlows = useSelector(
        (store: RootState) =>
            store?.strategyModuleReducer?.strategyReducer?.createdStrategy?.flows
    );

    const isLoadingAddFlow = useSelector(
        (store: RootState) =>
            store?.strategyModuleReducer?.strategyReducer?.isLoadingAddWorkflowDetails
    );
    const clientNameByDropdown = useSelector(
        (store: RootState) => store.campaignReducer?.campaignClientName
    );
    const campaignData = useSelector((store: RootState) => {
        return store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo;
    });

    const teamUseCaseId = useSelector(
        (store: RootState) => store?.loginReducer?.currentTeam?.useCaseId
    );
    const currentTeamId = useSelector(
        (state: RootState) => state.loginReducer.currentTeam.id
    );


    console.log("work space data", workspaceFlowData, selectedWorkSpace, selectedStrategy)



    const handleCheckSelecrWorkSpace = (name: string) => {
        setSelectedWorkSpace(name)
    }

    const handleClickCancel = () => {
        props.close && props.close()
    }


    const handleClickCreate = () => {
        console.log("hh")
        if (moduleSelectedScreen !== "Strategy") {
            navigate("/campaignmanager/nudgecreation");
        }
        dispatch(
            addWorkflowAPIData({
                token: token,
                strategyId: selectedStrategy?.id,
                addFlows: [{ ...selectedWorkSpace }],
                setFlows: [{ ...selectedWorkSpace }],
                clientName:
                    clientNameByDropdown?.length > 0
                        ? clientNameByDropdown
                        : campaignData?.accountName?.length > 0
                            ? campaignData?.accountName
                            : clientName,
                accountId:
                    moduleSelectedScreen !== "Strategy"
                        ? campaignData?.accountId
                        : accountId,
                bucketCreationSuccess: false,
                useCaseId: teamUseCaseId,
                teamId: currentTeamId
            })
        );
        dispatch(setLoadingCreate(true));
        dispatch(setAllNudgeData([]));
    }


    useEffect(() => {
        if (selectedStrategy.bucketCreationSuccess) {
            if (moduleSelectedScreen !== "Strategy") {
                dispatch(
                    createdStrategy({ ...selectedStrategy, bucketCreationSuccess: false })
                );
                navigate("/campaignmanager/nudgecreation");
            } else {
                dispatch(
                    createdStrategy({ ...selectedStrategy, bucketCreationSuccess: false })
                );
                navigate("/strategy/nudgecreation");
            }
        }
    }, [selectedStrategy.bucketCreationSuccess]);

    useEffect(
        () => {
            if (selectedStrategy?.flows?.length) {

                setSelectedWorkSpace({ ...selectedStrategy?.flows?.[0] })
            }
        },
        [selectedStrategy]
    )

    return (
        <Modal
            shown={props.show}
            extraClassStyleModalContent={style.workSpaceModalDivSalesOnBoarding}
            close={() => handleClickCancel()}
        >
            <div className={style.workSpaceSalesOnBoardingDiv}>
                <div className={style.headingText}>
                    SetUp Workspace
                </div>
                {
                    workspaceFlowData?.data?.map((detail: any, index: number) => {
                        return (
                            <div className={style.workSpaceSelcetionDiv} key={index}>
                                <input type={"checkbox"}
                                    onChange={() => {
                                        handleCheckSelecrWorkSpace(detail)
                                    }}
                                    checked={(detail?.displayName) === selectedWorkSpace?.displayName}
                                />
                                <span className={style.workSpaceName}>{detail?.displayName}</span>
                            </div>
                        )
                    })
                }
                <div className={style.buttonContainer}>
                    <Button
                        text="Cancel"
                        extraClass={`${style.buttonStyling} ${style.cancelButtonStyle}`}
                        onClick={() => { handleClickCancel() }}

                    />
                    <Button
                        text={isLoadingAddFlow ? "Creating ..." : "Create"}
                        extraClass={`${style.buttonStyling} ${style.createButtonActiveStyle}`}
                        onClick={() => { handleClickCreate() }}
                        disabled={selectedWorkSpace?.displayName ? false : true}
                    />
                </div>

            </div>
        </Modal>
    )
}

export default WorkSpaceSalesOnBoarding