import React, { useState, useEffect, useRef } from "react";
import "./CannedMessageComponent.scss";
import { upArrow } from "../../../../theme/assets/agentDesktopSvg/genericSvg/index";
import { SingleSelectDD } from "../../generic/fields";

// interface props {
//   default: string;
// }

export default function CannedMessageComponent(props: {
  data: { messageKey: string; messageValue: string }[];
  onChange: (messageKey: string, messageValue: string) => void;
}) {
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);
  const [data, setData] = useState<string[]>([]);
  useEffect(() => {
    setData(props.data?.map((e) => e.messageKey));
  }, [props.data]);
  function handleCannedMsgDropdown() {
    setIsShowDropdown(!isShowDropdown);
  }

  const handleOnChange = (e: any) => {
    const msg = props.data?.find((each) => each.messageKey === e);
    if (msg?.messageKey && msg.messageValue) {
      props.onChange(msg?.messageKey, msg?.messageValue);
    }
  };

  return (
    <>
      {/* <div
        className="chatBoxTopComponent"
        onClick={
          handleCannedMsgDropdown}
      >
        <p className="cannedMsgText">Canned Message</p>
        <img src={upArrow} alt="upArrow" />
      </div> */}
      {/* {isShowDropdown && ( */}
      <div>
        <SingleSelectDD
          data={data}
          handleChange={handleOnChange}
          selectedItem={""}
          title={"Canned Message"}
          // hasOtherOption={props.data.hasOtherOption}
          // objectKey={"objectKey"}
          limitSelectedTextLength={25}
          icon={upArrow}
          extraWrapperClass={"chatBoxTopComponent"}
          extraDropdownClass={"cannedDropdownClass"}
        />
      </div>
      {/* )} */}
    </>
  );
}
