import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { PRICING_PLAN_API, config } from "../../../../../services/ApiRoutes";
import { orderSummaryActionTypes } from "../orderSummary.actionTypes";

export function* generateOrderIdAPIWorker(action: any): any {
  // toast.loading("Please wait ...");
  try {
    if (action) {
      if(action.isBeginerPlan === true){
     const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${PRICING_PLAN_API.GENERATE_ORDERID}`,
        action.payload?.body,
        action.payload.headers

      );
      yield put({
        type: orderSummaryActionTypes.GENERATE_ORDERID_SUCCESS,
        payload: result.data.data,
      });
      if(result){
        action.payload.func(result.data.data)
      }
      }
      else{
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${PRICING_PLAN_API.GENERATE_ORDERID}`,
        action.payload?.body,
        action.payload.headers

      );
      yield put({
        type: orderSummaryActionTypes.GENERATE_ORDERID_SUCCESS,
        payload: result.data.data,
      });
      if(result){
        action.payload.func(result.data.data)
      }
      }
  }

  } catch (error:any) {
    console.error(error);
    yield put({
      type: orderSummaryActionTypes.GENERATE_ORDERID_FAILURE,
      payload: error,
    });
    console.log("err" , error?.response)
    toast.error(error?.response?.data?.error?.message || error?.response?.data?.message);
  
}
}

export function* verifyOrderPaymentAPIWorker(action: any): any {
  // toast.loading("Please wait ...");
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${PRICING_PLAN_API.VERIFY_PAYMENT_ORDER}`,
        action.payload?.body,
        action.payload.headers

      );
      yield put({
        type: orderSummaryActionTypes.VERIFY_ORDERID_SUCCESS,
        payload: result.data.data,
      });
      if(result){
        action.payload.func()
      }
    }
    // toast.dismiss();
  } catch (error :any) {
    console.error(error);
    yield put({
      type: orderSummaryActionTypes.VERIFY_ORDERID_FAILURE,
      payload: error,
    });
    toast.error(error?.response?.data?.error);
  }
}
export function* postApplyDiscountCouponCodeAPIWorker(action: any): any {

  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
         `${PRICING_PLAN_API.APPLY_DISCOUNT_COUPON}`,
        {
          couponCode: action?.payload?.couponCode
        },
        {
          "x-access-token": action?.payload?.token,
          "Content-Type": "application/json",
        }
      );
      
      yield put({
        type: orderSummaryActionTypes.APPLY_COUPON_CODE_SUCCESS,
        payload: result.data.data,
      });
    }

  } catch (error:any) {
    console.error(error);
    yield put({
      type: orderSummaryActionTypes.APPLY_COUPON_CODE_FAILURE,
      payload: error.response.data.error.message,
    });
  }
}

export function* maiPaymentAPIWorker(action: any): any {
  // toast.loading("Please wait ...");
  try {
    if (action) {
      if(action.isBeginerPlan === true){
     const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${PRICING_PLAN_API.GENERATE_ORDERID}`,
        action.payload?.body,
        action.payload.headers

      );
      yield put({
        type: orderSummaryActionTypes.MAIA_PAYMENT_SUCCESS,
        payload: result.data.data,
      });
      if(result){
        action.payload.func(result.data.data)
      }
      }
      else{
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${PRICING_PLAN_API.GENERATE_ORDERID}`,
        action.payload?.body,
        action.payload.headers

      );
      yield put({
        type: orderSummaryActionTypes.GENERATE_ORDERID_SUCCESS,
        payload: result.data.data,
      });
      if(result){
        action.payload.func(result.data.data)
      }
      }
  }

  } catch (error:any) {
    console.error(error);
    yield put({
      type: orderSummaryActionTypes.MAIA_PAYMENT_FAILURE,
      payload: error,
    });
    console.log("err" , error?.response)
    toast.error(error?.response?.data?.error?.message || error?.response?.data?.message);
  
}
}

export function* getMaiaPaymentStatusAPIWorker(action: any): any {

  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
         `${PRICING_PLAN_API.GET_MAI_PAYMENT_STATUS }`,
         action.payload
      );
      
      yield put({
        type: orderSummaryActionTypes.GET_MAIA_PAYMENT_STATUS_SUCCESS,
        payload: result.data.data,
      });
    }

  } catch (error:any) {
    yield put({
      type: orderSummaryActionTypes.GET_MAIA_PAYMENT_STATUS_FAILURE,
      payload: error?.message,
    });
  }
}