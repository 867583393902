import React, { useState, useRef } from "react";
import styles from "./CopyToolTip.module.scss";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

interface copyToolTipProps {
  data: any;
}

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#B6B6B6",
    borderRadius: "1.20455px",
    color: "#fff",
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: 400,
    maxWidth: "210px",
    overflowWrap: "break-word",
  },
  customArrow: {
    color: "#B6B6B6",
    fontSize: 9,
  },
}));

export const CopyToolTip = ({ data }: copyToolTipProps) => {
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClick = (value: string): void => {
    setClicked(true);
    navigator.clipboard.writeText(value);
  };

  const handleMouseLeave = (): void => {
    setClicked(false);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target as Node)
    ) {
      setClicked(false);
    }
  };

  return (
    <Tooltip
      title={clicked ? "Copied" : "Click to copy"}
      placement={"top"}
      classes={{
        arrow: classes.customArrow,
        tooltip: classes.customTooltip,
      }}
      arrow={true}
    >
      <div
        className={`${styles.copyTooltipStylingWrapper}`}
        ref={containerRef}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        <div
          className={`${clicked ? styles.tickIcon : styles.copyIcon} ${
            styles.iconStyling
          }`}
          onClick={() => handleClick(data)}
        ></div>
      </div>
    </Tooltip>
  );
};
