import { takeLatest } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import {
  signUpAPIRequestWorker,
  createPasswordAPIRequestWorker,
  sendOTPExperienceNowWorker,
  verifyOTPExperienceNowWorker,
  initiatCallExperienceNow,
  resetPasswordRequestWorker,
} from "./signUp.saga";

export default function* organizationDetailsWatcher(): any {
  yield takeLatest(actionTypes.SIGN_UP_API_REQUEST, signUpAPIRequestWorker);
  yield takeLatest(
    actionTypes.CREATE_PASSWORD_REQUEST_SIGNUP,
    createPasswordAPIRequestWorker
  );
  yield takeLatest(
    actionTypes.SEND_OTP_SING_UP_REQUEST,
    sendOTPExperienceNowWorker
  );

  yield takeLatest(
    actionTypes.VERIFY_OTP_SING_UP_REQUEST,
    verifyOTPExperienceNowWorker
  );
  yield takeLatest(
    actionTypes.CALL_EXPERIENCE_NOW_REQUEST,
    initiatCallExperienceNow
  );
  yield takeLatest(
    actionTypes.RESET_PASSWORD_REQUEST,
    resetPasswordRequestWorker
  );
}
