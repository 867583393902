import React from "react";
import styles from "./RadioButton.module.scss";

interface props {
  default?: string;
  id?: string;
  value?: string;
  isSelected?: boolean;
  label?: string;
  changed?: any;
  testid?: string;
  onClick?: (id: string) => void;
}

export default function RadioButton(props: props) {
  return (
    <>
      <div className={styles.RadioButton}>
        <input
          style={{ cursor: "pointer" }}
          data-testid={props.testid}
          id={props.id}
          onChange={props.changed}
          value={props.value}
          type="radio"
          onClick={() => {
            props.onClick && props.onClick(props.id || "");
          }}
          checked={props.isSelected ? true : false}
          readOnly
        />
        <label className={styles.LabelDiv} htmlFor={props.id}>
          {props.label}
        </label>
      </div>
    </>
  );
}
