import actionTypes from "../trueCaller.actionTypes";

export interface stateInterface {
  uploadProfilePic: string;
  isLoadingUploadProfilePic: boolean;
  uploadProfilePicError: string;
  isLoadingDeleteProfilePic: boolean;
  deleteProfilePicError: string;
  trueCallerData: object;
  allCategoryData: object;
  allCategoryDataError: string;
  isLoadingallCategory: boolean;
  // createTrueCaller: object;
  // isLoadingTrueCaller: boolean;
  // createTrueCallerError: string;
}

const initialState: stateInterface = {
  uploadProfilePic: "",
  isLoadingUploadProfilePic: false,
  uploadProfilePicError: "",
  isLoadingDeleteProfilePic: false,
  deleteProfilePicError: "",
  trueCallerData: {},
  allCategoryData: {},
  allCategoryDataError: "",
  isLoadingallCategory: false,
  // createTrueCaller: {},
  // isLoadingTrueCaller: false,
  // createTrueCallerError: "",
};

export default function trueCallerReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_ALL_CATEGORIES_REQUEST: {
      return {
        ...state,
        allCategoryData: {},
        allCategoryDataError: "",
        isLoadingallCategory: true,
      };
    }
    case actionTypes.GET_ALL_CATEGORIES_SUCCESS: {
      return {
        ...state,
        allCategoryData: action.payload,
        allCategoryDataError: "",
        isLoadingallCategory: false,
      };
    }
    case actionTypes.GET_ALL_CATEGORIES_FAILURE: {
      return {
        ...state,
        allCategoryData: {},
        allCategoryDataError: action.payload,
        isLoadingallCategory: false,
      };
    }

    case actionTypes.TRUE_CALLER_DATA: {
      return { ...state, trueCallerData: action.payload };
    }
    case actionTypes.UPDATE_TRUECALLER_PROFILE_PIC_REQUEST: {
      return {
        ...state,
        uploadProfilePic: "",
        isLoadingUploadProfilePic: true,
        uploadProfilePicError: "",
      };
    }
    case actionTypes.UPDATE_TRUECALLER_PROFILE_PIC_SUCCESS: {
      return {
        ...state,
        uploadProfilePic: action.payload,
        isLoadingUploadProfilePic: false,
        uploadProfilePicError: "",
      };
    }
    case actionTypes.UPDATE_TRUECALLER_PROFILE_PIC_FAILURE: {
      return {
        ...state,
        uploadProfilePic: "",
        isLoadingUploadProfilePic: false,
        uploadProfilePicError: action.payload,
      };
    }
    case actionTypes.DELETE_TRUECALLER_PROFILE_PIC_REQUEST: {
      return {
        ...state,
        isLoadingDeleteProfilePic: true,
        deleteProfilePicError: "",
      };
    }
    case actionTypes.DELETE_TRUECALLER_PROFILE_PIC_SUCCESS: {
      return {
        ...state,
        uploadProfilePic: "",
        isLoadingDeleteProfilePic: false,
        deleteProfilePicError: "",
      };
    }
    case actionTypes.DELETE_TRUECALLER_PROFILE_PIC_FAILURE: {
      return {
        ...state,
        isLoadingDeleteProfilePic: false,
        deleteProfilePicError: action.payload,
      };
    }
    // case actionTypes.CREATE_TRUECALLER_DATA_REQUEST ||
    //   actionTypes.UPDATE_TRUECALLER_DATA_REQUEST: {
    //   return {
    //     ...state,
    //     createTrueCaller: {},
    //     isLoadingTrueCaller: true,
    //     createTrueCallerError: "",
    //   };
    // }
    // case actionTypes.CREATE_TRUECALLER_DATA_FAILURE ||
    //   actionTypes.UPDATE_TRUECALLER_DATA_FAILURE: {
    //   return {
    //     ...state,
    //     createTrueCaller: {},
    //     isLoadingTrueCaller: false,
    //     createTrueCallerError: action.payload,
    //   };
    // }
    // case actionTypes.CREATE_TRUECALLER_DATA_SUCCESS ||
    //   actionTypes.UPDATE_TRUECALLER_DATA_SUCCESS: {
    //   return {
    //     ...state,
    //     createTrueCaller: action.payload,
    //     isLoadingTrueCaller: false,
    //     createTrueCallerError: "",
    //   };
    // }
    case actionTypes.CLEAR_TRUECALLER_DATA: {
      return {
        ...state,
        uploadProfilePic: "",
        isLoadingUploadProfilePic: false,
        uploadProfilePicError: "",
        isLoadingDeleteProfilePic: false,
        deleteProfilePicError: "",
        trueCallerData: {},
      };
    }
    default:
      return state;
  }
}
