import actionTypes from "../actionTypes";

export const getLoggerReportDataAPI = (bodyData: any, token: any) => {
  return {
    type: actionTypes.REPORT_DATA_API_REQUEST,
    payload: { bodyData, token },
  };
};

export const setTableSortingColumn = (
  columnKey: string,
  sortingDirection: "asc" | "desc"
) => {
  return {
    type: actionTypes.SET_TABLE_SORTING_COLUMN,
    payload: { columnKey, sortingDirection },
  };
};

// export const setReportTableData = (tableData: Array<any>) => {
//   return {
//     type: actionTypes.SET_REPORT_TABLE_DATA,
//     payload: tableData,
//   };
// };

export const sortReportTableData = (
  tableData: Array<any>,
  tableSortingColumn: Record<string, "asc" | "desc">
) => {
  return {
    type: actionTypes.SORT_REPORT_TABLE_DATA,
    payload: { tableData, tableSortingColumn },
  };
};

/** RE CALL  */
export const getLoggerReportNextDataAPI = (
  bodyData: any,
  token: any,
  slot?: boolean
) => {
  return {
    type: actionTypes.REPORT_DATA_NEXT_API_REQUEST,
    payload: { bodyData, token, slot },
  };
};
/** DOWNLOAD REPORT **/

export const setdurationDownloadReport = (data: any, token: string) => {
  return {
    type: actionTypes.REPORT_DATA_DOWNLOAD_API_REQUEST,
    payload: { data, token },
  };
};

export const setCustomiseDownloadReport = (data: any, token: string) => {
  return {
    type: actionTypes.REPORT_DATA_CUSTOMISE_DOWNLOAD_API_REQUEST,
    payload: { data, token },
  };
};
