import React, { useEffect, useState, useRef } from "react";

import styles from "./TranscriptLeft.module.scss";
import NewPagination from "../../generic/pagination/NewPagination";
import DisplayDataTranscript from "./displayDataTranscript/DisplayDataTranscript";
import { searchIcon } from "../../../theme/assets/svg";
//import { sampleData } from "./sampledata";
import { data } from "./displayDataTranscript/types";
import { useDebounce } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { transcriptPhoneApi } from "../../../redux/logger/transcript/actions/actions";
import {
  getMessages,
  getSummaryByAPI,
  cleanSummaryData,
  getAudioURLToPlay,
  clearAudioURL
} from "../../../redux/logger/loggerState/action";
import SearchInputBox from "../../generic/searchInputBox/SearchInputBox";
import { RootState } from "../../../redux";
import TRANSCRIPT_ACTION from "../../../redux/logger/transcript/actionTypes";
import SelectClient from "../transcript/selectClient/SelectClient";
// import session from "redux -persist/lib/storage/session""
import Loader from "../../generic/loader/Loader";
import moment from "moment";
import { noPhoneDataIcon } from "../../../theme/assets/svg";
import NoDatamodel from "../../generic/noDatamodel/NoDatamodel";
import { Mixpanel } from "../../../utils/mixpanelSetup";
import { format } from "date-fns";
import { mixpannelTranscriptSessionIdDetails } from "../../../redux/mixpanel/actions";
import { ROLES, SKELTON_DATA } from "../../../constants";

import { validateInputMeta } from "../../../utils/validators/validators";
import CustomBodySkeleton from "../../generic/customSkeleton/CustomBodySkeleton";

export default function TranscriptLeft() {
  const reduxPhoneList: void | [] = useSelector((state: any) => {
    return state.loggerReducer?.transcriptReducer?.allPhoneNumberList;
  });
  const totalNoOfPages = useSelector((state: any) => {
    return state.loggerReducer?.transcriptReducer?.totalPages || 0;
  });
  const channel = useSelector((state: any) => {
    return state.dashboardReducer?.channelTab;
  });
  const defaultuser = useSelector((state: any) => {
    return state.loginReducer.userLoginInfo.accountDetails[0].name;
  });
  // const user = "Maia Testing";
  const currentUser = useSelector((state: RootState) => {
    return state.filterReducers.selectedFilterOptions?.clientName;
  });
  // const disposition = useSelector((state: RootState) => {
  //   return state.filterReducers?.Disposition;
  // });
  // const flowType = useSelector((state: RootState) => {
  //   return state.filterReducers?.Flow;
  // });
  const channelTab = useSelector((state: RootState) => {
    return state.dashboardReducer?.subChannelTab;
  });
  const calender = useSelector((state: any) => {
    return state.filterReducers.calenderData;
  });
  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const userRole: any = useSelector((state: any) => {
    return state.loginReducer.userLoginInfo.userDetail.role;
  });
  // my Filters
  const filter = useSelector((state: any) => {
    return state?.filterReducers?.filterOptions?.data?.filters;
  });
  const schemaFilter = useSelector((state: any) => {
    return state?.loginReducer?.userLoginInfo?.schema?.logger?.transcript;
  });
  const dashboard = useSelector((state: any) => {
    return state?.dashboardReducer;
  });
  //search the phone number
  const isSignUpFlow = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo.accountDetails?.[0].isSignUpFlow
  );
  const refreshPage = useSelector((state: RootState) => {
    return state.filterReducers?.refreshPage;
  });
  // const language = useSelector((state: any) => {
  //   return state.filterReducers?.Language;
  // });
  const isPhoneNumberLoading = useSelector((state: any) => {
    return state.loggerReducer?.transcriptReducer?.isPhoneNumberLoading;
  });
  const selectedFilters = useSelector(
    (state: RootState) => state?.filterReducers?.selectedFilterOptions
  );
  const sessionIdDetails = useSelector((state: any) => {
    return state?.mixpanelReducer?.transcriptSessionIdDetails;
  });
  const currentUseCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseId
  );
  const currentUseCaseName = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseName
  );
  const pushOnTranscript = useSelector((state: any) => {
    return state.signUpModuleReducer?.productTourReducer?.pushOnTranscriptPage;
  });
  const loginClientData = useSelector(
    (store: any) => store.loginReducer?.userLoginInfo
  );
  const organizationInfo = useSelector(
    (store: RootState) => store?.profile?.yourAccountReducer?.organization
  );
  const accountType = useSelector((state: any) => {
    return state.loginReducer?.userLoginInfo?.accountDetails[0]?.type;
  });
  const allSessionPhoneList = useSelector((state: any) => {
    return state.loggerReducer?.transcriptReducer?.allSessionPhonNumberList;
  });
  const currentSelectedTeamId = useSelector(
    (store: RootState) => store.loginReducer?.currentTeam?.id
  );
  console.log("trans", allSessionPhoneList, reduxPhoneList);
  // const language = useSelector((state: any) => {
  //   return state.filterReducers?.Language;
  // });
  const dispatch = useDispatch();
  const [user, setUser] = useState(defaultuser);
  const [currentSession, setCurrentSession] = useState<{
    phoneNo: string;
    sessionId: string;
    id: string;
  }>({ phoneNo: "", sessionId: "", id: "" });
  const [filteredData, setFilteredData] = useState<data[]>([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [clusterID, setclusterID] = useState("");
  const [sessionDate, setsessionDate] = useState("");
  const [sessionID, setsessionID] = useState("");
  const paginationRef = useRef<any>();
  useEffect(() => {
    if (currentUser) {
      if (currentUser.length > 0) {
        setUser(currentUser[0].name);
      }
    }
  }, [currentUser]);
  useEffect(() => {
    if (user) {
      dispatch({
        type: TRANSCRIPT_ACTION.SET_CURRENT_SESSION,
        payload: {
          phoneNo: currentSession.phoneNo,
          sessionId: currentSession.sessionId,
          conversationId: currentSession.id,
          accountName: user,
          accountType: accountType,
        },
      });
    }
  }, [currentSession, user]);

  //set messages

  //set the phone lists
  useEffect(() => {
    if (reduxPhoneList) {
      const filterdList: data[] = reduxPhoneList.map((e: any, index) => {
        const dropdown = e.sessionIds.map((sessions: any) => {
          if (
            userRole.toString().toLowerCase() ===
            ROLES.campaignAnalyst.name.toLowerCase() &&
            sessions.status !== undefined &&
            sessions.status !== "not seen" &&
            sessions.status !== "seen"
          ) {
            return {
              time: sessions.createdAt,
              sessionId: sessions.sessionId,
              id: sessions.id,
              issue: sessions.status === "no issue" ? false : true,
            };
          } else {
            return {
              time: sessions.createdAt,
              sessionId: sessions.sessionId,
              id: sessions.id,
            };
          }
        });
        return {
          phoneNo: e.phoneNo,
          index: (page - 1) * limit + index + 1,
          dropDown: dropdown,
        };
      });
      setFilteredData(filterdList);
      if (filterdList.length > 0) {
        setCurrentSession({
          phoneNo: filterdList[0]?.phoneNo || "",
          sessionId: filterdList[0]?.dropDown[0]?.sessionId || "",
          id: filterdList[0]?.dropDown[0]?.id,
        });
      } else {
        setCurrentSession({ phoneNo: "", sessionId: "", id: "" });
      }
    }
  }, [reduxPhoneList]);
  /*************************************************** */
  //
  //set current issue
  //whenever the status of the current conversation's issue is updated, summary api will get call
  //that summary will constain latest status. instead of getting all the phone data just change the particular issue data got from summary
  const summaryData = useSelector(
    (state: any) => state?.loggerReducer?.loggerState?.summaryData[0]
  );
  useEffect(() => {
    if (
      userRole.toString().toLowerCase() ===
      ROLES.campaignAnalyst.name.toLowerCase() &&
      summaryData?.information?.sessionId &&
      summaryData?.status
    ) {
      setIsIssueCurrent(
        summaryData?.information?.sessionId,
        summaryData?.status
      );
    }
    
  }, [summaryData, userRole]);
  function setIsIssueCurrent(sessionId: string, status: string) {
    // console.log({ sessionId, status }, "transcript nithin");
    setFilteredData((prev) => {
      const filteredData = prev.map((each) => {
        const dropDown = each.dropDown.map((eachId) => {
          if (
            eachId.sessionId === sessionId &&
            status !== undefined &&
            status !== "not seen" &&
            status !== "seen"
          ) {
            //  console.log("transcript nithin ", { eachId });
            return { ...eachId, issue: status === "no issue" ? false : true };
          } else {
            return { ...eachId };
          }
        });
        return { ...each, dropDown };
      });
      return [...filteredData];
    });
  }
  /******************************************************* */
  // useEffect(() => {
  //   console.log(page, "OK-OGT PAGE");
  // }, [page]);
  // sget page number // get the page info also
  function getPageInfo(e: number) {
    // console.log(e);
    // console.log(e, "GETIING FROM NEW PAGINATIO");
    setPage(e);

    const data: any = {
      limit: limit,
      page: e,
      channel: channel?.name,
      useCaseId: [currentUseCaseId],
      source: [currentUseCaseName.split(" ")[0]],
      startDate: `${moment(calender?.startDate).format("YYYY-MM-DD")}T00:00:00`,
      endDate: `${moment(calender?.endDate).format("YYYY-MM-DD")}T23:59:59`,
      //language: selectedFilters.language,
      selectedFilters,
      user: user,
      accountType: accountType,
      role: userRole,
      teamId: currentSelectedTeamId
    };

    if (
      channel?.name?.toString().toLowerCase() === "whatsapp" ||
      channel?.name?.toString().toLowerCase() === "chat"
    ) {
      if (channelTab?.name) {
        data.communicationType = channelTab.name;
        dispatch(transcriptPhoneApi(data, token));
      }
    } else dispatch(transcriptPhoneApi(data, token));
  }
  // select the phone number and sessionId
  function getPhoneDetails(phoneNo: string, sessionId: string, id: string) {
    // console.log({ phoneNo, sessionId });
     setCurrentSession({ phoneNo, sessionId: sessionId || "", id });
    // mixpanel --
    const copyCount = sessionIdDetails?.clickCount
      ? sessionIdDetails?.clickCount
      : 0;
    const increasedCopy = Number(copyCount) + 1;
    const newCopyObj = sessionIdDetails ? sessionIdDetails : {};
    dispatch(mixpannelTranscriptSessionIdDetails(newCopyObj));
    Mixpanel.track("Logger | Transcript Session IDs | Clicks", {
      "user Name": defaultuser,
      Date: format(new Date(), "dd-MM-yyyy"),
      Click: "Session IDs",
      Count: increasedCopy,
    });
    //--end
  }
  //search the phone number

  function handleSearchPhoneNo(e: any) {
    // console.log(e);
    setPage(e);
    if (!validateInputMeta(e.target.value)) return;
    const data: any = {
      limit: limit,
      page: 1,
      phoneNo: e.target.value,
      channel: channel?.name,
      useCaseId: [currentUseCaseId],
      source: [currentUseCaseName.split(" ")[0]],
      startDate: `${moment(calender?.startDate).format("YYYY-MM-DD")}T00:00:00`,
      endDate: `${moment(calender?.endDate).format("YYYY-MM-DD")}T23:59:59`,
      //language: selectedFilters.language,
      selectedFilters,
      user: user,
      accountType: accountType,
      role: userRole,
      teamId: currentSelectedTeamId
    };

    if (
      channel?.name?.toString().toLowerCase() === "whatsapp" ||
      channel?.name?.toString().toLowerCase() === "chat"
    ) {
      if (channelTab?.name) {
        data.communicationType = channelTab.name;
        dispatch(transcriptPhoneApi(data, token));
      }
    } else dispatch(transcriptPhoneApi(data, token));
    if (paginationRef.current) {
      paginationRef.current.resetCurrentPage();
    }
    setPage(1);
    //--mixpannel code
    const regmm = "^([0|+[0-9]{1,5})?([7-9][0-9]{9})$";
    const regmob = new RegExp(regmm);
    const correctMobileNumber = regmob?.test(e.target.value);
    const typeOfSearch = correctMobileNumber ? "phone number" : "session ID";
    Mixpanel.track("Logger | Transcript | Search Bar Usage", {
      "user Name": defaultuser,
      Date: format(new Date(), "dd-MM-yyyy"),
      "Type of search": typeOfSearch,
    });
    //--end
  }
  useEffect(() => {
    if (currentSession?.id) {
      console.log("currentsession:",currentSession)
      dispatch(getMessages(currentSession?.id));
      dispatch(getSummaryByAPI({ id: currentSession?.id, role: userRole }));
    } else {
      dispatch(cleanSummaryData());
    }
  }, [currentSession.id]);

  useEffect(() => {
    const data: any = {
      limit: limit,

      page: 1,
      channel: channel?.name,
      useCaseId: [currentUseCaseId],
      source: [currentUseCaseName.split(" ")[0]],
      startDate: `${moment(calender?.startDate).format("YYYY-MM-DD")}T00:00:00`,
      endDate: `${moment(calender?.endDate).format("YYYY-MM-DD")}T23:59:59`,
      // language: selectedFilters.language,
      selectedFilters,
      accountType: accountType,
      user: user,
      phoneNo:
        isSignUpFlow &&
          process.env.REACT_APP_ENV === "production" &&
          !Object.keys(organizationInfo?.organizationDetails)?.includes(
            "gstNumber"
          )
          ? loginClientData?.userDetail?.phoneNo
          : "",
      role: userRole,
      teamId: currentSelectedTeamId
      // phoneNo : loginClientData?.userDetail?.phoneNo
    };

    if (
      channel?.name?.toString().toLowerCase() === "whatsapp" ||
      channel?.name?.toString().toLowerCase() === "chat"
    ) {
      if (channelTab?.name) {
        data.communicationType = channelTab.name;
        setPage(1);
        dispatch(transcriptPhoneApi(data, token));
      }
    } else {
      setPage(1);
      // console.log("calling Api inside transcript on login", data);
      // callApi();
      dispatch(transcriptPhoneApi(data, token));
    }
  }, [
    limit,
    user,
    channel?.name,
    calender,
    selectedFilters,
    refreshPage,
    channelTab?.name,
  ]);

  const debouncedHandleSearchPhoneNo = useDebounce(handleSearchPhoneNo, 300);

  return (
    <div className={styles.wrapper}>
      {/* {userRole.toString().toLowerCase() === ROLES.campaignAnalyst.name.toLowerCase()  &&
      user !== "Demo" ? (
        <div className={styles.allClients}>
          <SelectClient></SelectClient>
        </div>
      ) : (
        ""
      )} */}
      <SearchInputBox
        onChange={debouncedHandleSearchPhoneNo}
        searchIcon={searchIcon}
        placeholder={"Search"}
        extraClassWrapper={styles.extraClassSearchWrapper}
        value={searchValue}
        inputType={"text"}
      />

      <div className={styles.phoneList}>
        {filteredData.length === 0 && !isPhoneNumberLoading ? (
          <div className={styles.noData}>
            {/* <NoDatamodel srcImg={noPhoneDataIcon}></NoDatamodel> */}
          </div>
        ) : (
          <DisplayDataTranscript
            data={
              isPhoneNumberLoading
                ? SKELTON_DATA.transcriptSessionsData
                : filteredData
            }
            handleSelectOption={getPhoneDetails}
            selected={currentSession}
            isLoading={isPhoneNumberLoading}
          ></DisplayDataTranscript>
        )}
      </div>
      <div
        className={styles.pagination}
        style={isPhoneNumberLoading ? { pointerEvents: "none" } : {}}
      >
        <CustomBodySkeleton
          isLoading={isPhoneNumberLoading}
          style={{ height: "40px", marginBottom: "10px" }}
          width={"150px"}
        >
          <>
            {reduxPhoneList?.length && !isPhoneNumberLoading ? (
              <NewPagination
                ref={paginationRef}
                getPage={page - 1}
                handlePageChange={(e: any) => {
                  getPageInfo(e);
                }}
                totalNoOfPage={totalNoOfPages}
                hideArrow
              ></NewPagination>
            ) : null}
          </>
        </CustomBodySkeleton>
      </div>
    </div>
  );
}
