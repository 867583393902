// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o79mB8dt3aUO0QHR1LiX{display:flex;justify-content:flex-start;color:var(--primary-color);padding-left:1em}.ooLqCDJ7cv6nHfKfk43M{color:inherit;margin:0;margin-left:.5em;margin-top:7px;margin-bottom:7px}.uBIM53Ow72njvw2Io82h{border:.5px solid var(--secondary-grey)}`, "",{"version":3,"sources":["webpack://./src/components/agentDesktop/generic/newDataPicker/NewDatePicker.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,0BAAA,CACA,0BAAA,CACA,gBAAA,CAEA,sBACE,aAAA,CACA,QAAA,CACA,gBAAA,CACA,cAAA,CACA,iBAAA,CAGF,sBACE,uCAAA","sourcesContent":[".titleofilter{\n    display: flex;\n    justify-content: flex-start;\n    color:var(--primary-color);\n    padding-left: 1em;\n    }\n    .titletext{\n      color:inherit;\n      margin: 0;\n      margin-left: .5em;\n      margin-top:7px;\n      margin-bottom: 7px;\n     \n    }\n    .divide{\n      border: .5px solid var(--secondary-grey);\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleofilter": `o79mB8dt3aUO0QHR1LiX`,
	"titletext": `ooLqCDJ7cv6nHfKfk43M`,
	"divide": `uBIM53Ow72njvw2Io82h`
};
export default ___CSS_LOADER_EXPORT___;
