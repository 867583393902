import actionTypes from "../dataUpload.actionTypes";

export function storeSelectedMethod(payload: object) {
  return {
    type: actionTypes.SET_METHOD_TAB,
    payload: payload,
  };
}

export function postUploadedCSVFile(payload: object) {
  return {
    type: actionTypes.UPLOAD_CSV_FILE_API_REQUEST,
    payload: payload,
  };
}

export function postPaymentUploadedCSVFile(payload: object) {
  return {
    type: actionTypes.UPLOAD_PAYMENT_CSV_FILE_API_REQUEST,
    payload: payload,
  };
}
export function postDunningCSVFile(payload: object) {
  return {
    type: actionTypes.UPLOAD_DUNNIG_CSV_FILE_API_REQUEST,
    payload: payload,
  };
}

export function getUploadedCSVInfoAPIData(payload: object) {
  return {
    type: actionTypes.GET_UPLOADED_INFO_API_REQUEST,
    payload: payload,
  };
}

export function getCSVAPIData(payload: object) {
  return {
    type: actionTypes.GET_API_CSV_DATA_REQUEST,
    payload: payload,
  };
}

export function getCSVSalesForceData(payload: object) {
  return {
    type: actionTypes.GET_SALESFORCE_CSV_DATA_REQUEST,
    payload: payload,
  };
}

export function postSelectedCSVData(payload: object) {
  return {
    type: actionTypes.POST_SELECTED_CSV_DATA_REQUEST,
    payload: payload,
  };
}
export function postSelectedAPICSVData(payload: object) {
  return {
    type: actionTypes.POST_SELECTED_API_DATA_REQUEST,
    payload: payload,
  };
}

export function setSelectedCSVAPIData(payload: object) {
  return {
    type: actionTypes.SET_CSV_DATA_SELECTED,
    payload: payload,
  };
}

export function getEditCSVScreen(payload: object) {
  return {
    type: actionTypes.GET_EDIT_SCREEN_CSV_REQUEST,
    payload: payload,
  };
}

export function setShowEditCSVScreen(payload: object) {
  return {
    type: actionTypes.SET_SHOW_EDIT_SCREEN,
    payload: payload,
  };
}

export function postUpdatedCSVInfo(payload: object) {
  return {
    type: actionTypes.POST_UPDATED_CSV_API_REQUEST,
    payload: payload,
  };
}

export function deleteUploadedCSV(payload: object) {
  return {
    type: actionTypes.DELETE_CSV_API_REQUEST,
    payload: payload,
  };
}
