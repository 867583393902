// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YQENfXKoU9h2N9NjqKto ._81V8rVpjGLmstG_Lf8G{display:inline-flex;justify-content:center;align-items:center;font-family:"Roboto";font-style:normal;font-weight:400;font-size:14px;line-height:16px;color:#838383;margin:2% 4%}.YQENfXKoU9h2N9NjqKto ._81V8rVpjGLmstG_Lf8G .daYsv6NAO1y0Ofowxq3s{height:12px;width:12px;border-radius:50%}.YQENfXKoU9h2N9NjqKto ._81V8rVpjGLmstG_Lf8G .XTqSYUvKwJwI_37EGU2B{text-align:left;padding:0 8px 0 10px;text-transform:capitalize}`, "",{"version":3,"sources":["webpack://./src/components/crm/moduleComponent/crmdonutChartComponent/CRMDonutChartComp.module.scss"],"names":[],"mappings":"AACI,4CACI,mBAAA,CACA,sBAAA,CACA,kBAAA,CACA,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,YAAA,CAEA,kEACI,WAAA,CACA,UAAA,CACA,iBAAA,CAGJ,kEACI,eAAA,CACA,oBAAA,CACA,yBAAA","sourcesContent":[".crmDonutComponentWrapper {\n    .donutbottomComp {\n        display: inline-flex;\n        justify-content: center;\n        align-items: center;\n        font-family: 'Roboto';\n        font-style: normal;\n        font-weight: 400;\n        font-size: 14px;\n        line-height: 16px;\n        color: #838383;\n        margin: 2% 4%;\n\n        .legendDot {\n            height: 12px;\n            width: 12px;\n            border-radius: 50%;\n        }\n\n        .legendName {\n            text-align: left;\n            padding: 0 8px 0 10px;\n            text-transform: capitalize;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crmDonutComponentWrapper": `YQENfXKoU9h2N9NjqKto`,
	"donutbottomComp": `_81V8rVpjGLmstG_Lf8G`,
	"legendDot": `daYsv6NAO1y0Ofowxq3s`,
	"legendName": `XTqSYUvKwJwI_37EGU2B`
};
export default ___CSS_LOADER_EXPORT___;
