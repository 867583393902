import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/rootStore";
import AgentDashboardModel from "../../../components/agentDesktop/moduleComponents/agentDashboardModel/AgentDashboardModel";
import Card from "../../../components/agentDesktop/moduleComponents/cardComponent/card/Card";

import styles from "./AgentDashboardScreen.module.scss";
import CardImg from "../../../theme/assets/agentDesktopSvg/dashboardSvg/cardSvg/dummyImg.svg";
import NewRightSidebar from "../../../components/agentDesktop/moduleComponents/newRightsideBarFilter/NewRightSidebar";
// import { getDashoardApiCards } from "../../../redux/agentDesktop/dashboard/actions";
// interface props {
//   default: any;
// }

export default function AgentDashboardScreen(props: any) {
  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );

  const agentId = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo?.userDetail?._id
  );

  const channelTab = useSelector(
    (store: any) => store?.agentDesktopReducer?.tabReducer?.backendRefenceKey
  );

  const startDate = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer?.rootFilterReducer?.calenderFilterReducer
        ?.formatedStartDate
  );

  const endDate = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer?.rootFilterReducer?.calenderFilterReducer
        ?.formatedEndDate
  );

  const cardSchema = useSelector(
    (state: any) => state.agentDesktopReducer?.baseScreenAD?.schema?.cards
  );

  const schema = useSelector(
    (state: any) => state.agentDesktopReducer?.baseScreenAD?.schema
  );

  const loading = useSelector(
    (store: RootState) =>
      store?.agentDesktopReducer?.dashboardReducer?.isLoadingDashboardChart
  );

  const cardDetails =
    cardSchema && Object.values(cardSchema)?.length > 0
      ? cardSchema.sort((a: any, b: any) => {
          return a?.position - b?.position;
        })
      : [];

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (channelTab) {
  //     schema?.cards?.map((e: any) => {
  //       dispatch(
  //         getDashoardApiCards(
  //           {
  //             username: accountName,
  //             start_date: startDate,
  //             end_date: endDate,
  //             channel: [channelTab],
  //             uniqueId: e?.cardData?.uniqueId,
  //             agentIds: [agentId],
  //           },
  //           token
  //         )
  //       );
  //     });
  //   }
  // }, [startDate, endDate, channelTab, schema]);

  return (
    <>
      <div className={styles.agentDashboardScreenDiv}>
        <div className={styles.agentScreenTopDiv}>
          <Card
            cardDetails={cardDetails}
            width={"257px"}
            height={"120px"}
            fontSize={"18px"}
          />
        </div>
        <div>
          <AgentDashboardModel schemaDetails={schema} isLoading={loading} />
        </div>
      </div>
    </>
  );
}
