import React, { useState } from "react";
import CallDispositionTable from "../../../components/crm/moduleComponent/maiaCallTable/callDispositionTable/CallDispositionTable";
import DropRateAnalysisTable from "../../../components/crm/moduleComponent/maiaCallTable/dropRateAnalysisTable/DropRateAnalysisTable";
import styles from "./MaiaChat.module.scss";
import HorizontallProgress from "../../../components/crm/generic/horizontalProgress/HorizontalProgress";
import CrmCardComponent from "../../../components/crm/moduleComponent/crmCardComp/CrmCardComponent";
import CRMDonutChartComponent from "../../../components/crm/moduleComponent/crmdonutChartComponent/CRMDonutChartComp";
import BiaxialBarChart from "../../../components/crm/moduleComponent/charts/BiaxialBarChart";
import Slide from "../../../components/generic/slide/Slide";

const blueColor = "#24BEE5";
const yellowColor = "#F9C74F";
const cardData = [
  { data: { name: "Total Unique Accounts Connected", value: "4372",isCard:false } },
  { data: { name: "Total Messages Sent", value: "7847",isCard:false } },
  { data: { name: "Average Interactiveness Score", value: "82.6%",isCard:false } },
  { data: { name: "Total Unhappy Path", value: "843",isCard:false } },
  { data: { name: "No. Of Fallback", value: "1290",isCard:false } },
];
const donutChat = {
  chartTitle: "KYC Status",
  chartType: "donutChart",
  chartStyle: {
    width: "700px",
    height: "400px",
  },
  isSlider: true,
  isCardData: false,
  dataPredue: [
    {
      name: "Acceptable",
      value: 700,
      fillColor: blueColor,
    },
    {
      name: "Unacceptable",
      value: 1500,
      fillColor: yellowColor,
    },
  ],
  dataPostdue: [
    {
      name: "Acceptable",
      value: 1652,
      fillColor: blueColor,
    },
    {
      name: "Unacceptable",
      value: 832,
      fillColor: yellowColor,
    },
  ],
};

const barChat = {
  legendData: ["Total Connected", "Negative to positive disposition change"],
  keys: [
    {
      name: "",
      icon: "",
      referenceKeyName: "risk",
      shortKey: "",
      fillColor: "#9ADBF9",
      position: 1,
    },
    {
      name: "Total Connected",
      icon: "",
      referenceKeyName: "june22",
      shortKey: "",
      fillColor: blueColor,
      position: 2,
    },
    {
      name: "Negative to positive disposition change",
      icon: "",
      referenceKeyName: "june23",
      shortKey: "",
      fillColor: yellowColor,
      position: 2,
    },
  ],
  chartStyle: {
    width: "500px",
    height: "400px",
    barSize: "55"
  },
  isSlider: false,
  isCardData: false,
  data: [
    {
      risk: "",
      june22: 6743,
      june23: 987,
    },
  ],
};
export default function MaiaWhatsappPage() {
  const [flow, setFlow] = useState<any>("Pre Due");
  return (
    <div className={styles.wrapper}>
      <div className={styles.cards}>
        {cardData.map((e, index) => {
          return (
            <div key={index} className={styles.eachCard}>
              {" "}
              <CrmCardComponent data={e.data} />
            </div>
          );
        })}
      </div>
      <div className={styles.objective}>
        <div className={styles.title}>Chat Objective</div>
        <div className={styles.graph}>
          <div className={styles.preDue}>
            <HorizontallProgress
              title="Pre Due"
              left={{ title: "Acheived", value: 2143 }}
              right={{ title: "Unacheived", value: 3354 }}
            ></HorizontallProgress>
          </div>
          <div className={styles.postDue}>
            <HorizontallProgress
              title="Post Due"
              left={{ title: "Acheived", value: 1574 }}
              right={{ title: "Unacheived", value: 774 }}
            ></HorizontallProgress>
          </div>
        </div>
      </div>
      <div className={styles.charts}>
        
        <div className={styles.eachChartBar}>
          <div className={styles.title}>Disposition Outcome</div>
          <hr className={styles.horizontalLine} />
          <BiaxialBarChart data={barChat.data} chartDetails={barChat} />
        </div>
        <div className={styles.eachChartDonut}>
          <div className={styles.title}>
            <p>Payment Behaviour Change</p>
            <div style={{padding: "0 10px"}}>
            <Slide
            text={["Pre Due", "Post Due"]}
            defaultIndex={0}
            onChange={(a: string | number) => {
              setFlow(a);
            }}
            disabled={false}
          />
            </div>
          </div>
          <hr className={styles.horizontalLine} />
          <CRMDonutChartComponent
            data={flow == "Pre Due" ? donutChat.dataPredue : donutChat.dataPostdue}
            chartDetails={donutChat}
          />
        </div>
        </div>
      <div className={styles.table}>
        <div>
          <div className={styles.title}>Chat Disposition</div>
          <CallDispositionTable />
        </div>
      </div>
    </div>
    
  );
}
