import { all, fork } from "redux-saga/effects";
import * as allIntegrationsSaga from "./allIntegrations/saga";
import * as trueCallerSaga from "./trueCaller/saga";
import * as whatsAppSaga from "./whatsApp/saga";
import * as emailSaga from "./email/saga";
import * as smsSaga from "./sms/saga";
import * as customIntegrationSaga from "./customIntegration/saga";
import * as telephonySaga from "./telephony/saga";

export default function* IntegrationRootSaga() {
  yield all([...Object.values(allIntegrationsSaga)].map(fork));
  yield all([...Object.values(trueCallerSaga)].map(fork));
  yield all([...Object.values(whatsAppSaga)].map(fork));
  yield all([...Object.values(emailSaga)].map(fork));
  yield all([...Object.values(smsSaga)].map(fork));
  yield all([...Object.values(customIntegrationSaga)].map(fork));
  yield all([...Object.values(telephonySaga)].map(fork));
}
