import axios from "axios";
import { head } from "lodash";

export const DASHBOARD_URL = `https://${process.env.REACT_APP_DASHBOARD_URL}`;
export const AVAIL = "avail";
// export const ACCOUNT_NAME= accountName;
export const FILTER_URL = `https://${process.env.REACT_APP_FILTER_URL}`;
export const ATTEMPT_URL = `https://${process.env.REACT_APP_SERVER_URL2}/api/conversations/dashboard/v1/attempt/count`;

// /api/accounts/user/v1/updateResetPassword
// Old - https://connectors.saarthi.ai/conversation
// New - https://conversationlogger.saarthi.ai/accounts

// Old - https://connectors.saarthi.ai/messages
// New - https://conversationlogger.saarthi.ai/conversations
// export const MANAPPURAM_DASHBOARD_URL="https://calllogger.saarthi.ai/testing"
export const TUNETALK_DASHBOARD_URL = `https://${process.env.REACT_APP_TUNETALK_DASHBOARD_URL}`;
export const LIVE_CHAT_URL = "https://calllogger.saarthi.ai/live";
export const LAST_UPDATED_URL = `https://${process.env.REACT_APP_SERVER_URL2}/api/conversations/summary/v1/latest?accountName=`;

export const SERVER_URL = `https://${process.env.REACT_APP_SERVER_URL}`;
export const SERVER_URL2 = `https://${process.env.REACT_APP_SERVER_URL2}`;
export const SERVER_URL_CONNECTOR = `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}`;
export const SERVER_URL_BUCKETIZE = `https://${process.env.REACT_APP_BUCKETIZE}`;
export const SERVER_REACT_APP_MAPPING_CSV = `https://${process.env.REACT_APP_MAPPING_CSV}`;
export const PROJECT_URL = "https://chathistory.saarthi.ai";
export const WHATSAPP_ONEWAY_URL =
  "https://streaming.saarthi.ai/api/notification/summary/v1/get";
export const WHATSAPP_ONEWAY_ANALYTICS_URL =
  "https://streaming.saarthi.ai/api/dashboard/v1/kpis";

export const PUSH_CSV_DOWNLOAD =
  "https://streaming.saarthi.ai/api/notification/summary/v1/download";
export const SAARTHIDEMO_PAYMENT_DASHBOARD_URL = `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/paymentDetails/v1/getkpis`;
export const SAARTHIDEMO_CUSTOMER_DASHBOARD_URL = `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/customerResponseInfo/v1/getkpis`;

//https://conversationlogger.saarthi.ai/accounts/api/accounts/account/v1/all

export const ANALYTICS_URL = {
  DOWNLOAD_CSV: "/api/conversations/dashboard/v1/download/kpi",
  PAYMENT_DOWNLOAD_CSV: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/paymentDetails/v1/download/kpi`,
  CUSTOMER_BEHAVIOUR_DOWNLOAD_CSV: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/customerResponseInfo/v1/download/kpi`,
  DASHBOARD_URL_CARDS: `https://${process.env.REACT_APP_SERVER_URL2}/api/conversations/dashboard/v1/dashboard/kpi`,
  DASHBOARD_URL_NEW_KPI: `https://${process.env.REACT_APP_BUCKETIZE}/conversation/api/conversations/dashboard/v1/dashboard/kpi`,
};

export const ONBOARDING_URL = {
  SIGN_IN: "/api/accounts/v2/login",
  LOGIN2: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/auth/v1/login`,
  UPDATE_USER_DATA: "/api/accounts/user/v1/update",
  UPDATE_RESET_PASSWORD: "/api/accounts/user/v1/updateResetPassword",
  SIGN_UP_API: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/signup/v1`,
  CREATE_PASSWORD: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/signup/v1/createPassword`,
  TEAMS_DATA: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/team/v1/all`,
  TEAM_SCHEMA: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/userKpi/v1`,
  GET_TEAM_TABS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/schemaTab/v1/all`,
  GET_TEAM_CHANNELS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/channel/v1/all`,
  GET_SIDEBAR_MODULES: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/sidebarModule/v1/all`,
  SIGN_UP: "/api/accounts/user/v1/register",
  UPDATE_SCHEMA: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/userKpi/v1`,
  All_USERS: "/api/accounts/user/v1/all?",
  SEARCH_USERS: "/api/accounts/user/v1/user?",
  REPROT_BUG: "/api/accounts/reportBug/v1/create",
  REQUEST_FEATURE: "/api/accounts/featureRequest/v1/create",
  ALL_CLIENT_INFO: "/api/accounts/account/v1/all",
  SCHEMA_UPDATE: "/api/accounts/v1/updateSchema",
  GET_SCHEMA: "/api/accounts/v1/getSchema?id=",
  GET_ALL_DETAILS_CLIENT: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/account/v1/getByName`,
  UPDATE_ACCOUNT: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/account/v1/update`,
  FETCH_USER_DETAIL: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/user/v1/get`,
  UPDATE_USER: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/user/v1/updateUser`, //POST
  UNLOCK_ICONS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/user/v1/update`,
  GET_UPDATED_MODULES: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/moduleDetails/v1/all`,
  LOG_OUT: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/auth/v1/logout`,
  REFRESH_TOKEN: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/auth/v1/refresh-token`,
  INVITE_TOKEN_VERIFICATION: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/signup/v1/verifyLink`,
};

export const EDITING_URL = {
  UPDATE_FEEDBACK: "/api/conversations/feedback/v1/create",
  FEEDBACK_HISTORY: "/api/conversations/feedback/v1/all",
  FEEDBACK_HISTORY_DOWNLOAD_CSV: "/api/conversations/feedback/v2/download",
  FETCH_FEEDBACK_TYPEWISE:
    "/api/conversations/feedback/v1/getFeedbackOnType?type=",
  GET_FEEDBACK_HISTORY_COLUMNS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/feedbackColumn/v2/getFeedbackColumnByUserId`,
};

export const CONVERSATION_URL = {
  FETCH_PHONE_NO: "/api/conversations/conversation/v1/all?",
  FETCH_SESSION_ID: "/api/conversations/summary/v1/filter/phoneNo/sessionId",
  MESSAGE_URL: "/api/conversations/message/v1/all?",
  SUMMARY_URL: "/api/conversations/summary/v1/all?",
  DOWNLOAD_CSV_TRANSCRIPT: "/api/conversations/summary/v2/downloadTranscript",
  UPDATE_SUMMARY: "/api/conversations/summary/v1/update/",
  FETCH_STATUS: "/api/conversations/summary/v1/filter/agent/chatStatusCount",
};

export const CALL_REPORT_URL = {
  FILTER_URL: "/api/conversations/summary/v1/filter",
  // FILTER_CSV_URL:"/api/conversations/summary/v1/filtercsv",
  FILTER_CSV_URL: "/api/conversations/summary/v3/download",
  PHONE_CONVERSATION_LIST_URL: "/api/conversations/summary/v1/conversation",
  STATUS_CSV_FILE: "/api/conversations/summary/v1/report/status?sessionId=",
  DOWNLOAD_DURATION_REPORT: "/api/conversations/summary/v3/download",
  REPORT_MAIL_CUSTOMISE_DATA: "/api/conversations/summary/v1/sendReportMail",
};

export const REPORT_ISSUE_URL = {
  CREATE_ISSUE: "/api/conversations/reportissue/v1/create",
  UPDATE_ISSUE: "/api/conversations/reportissue/v1/update",
  FETCH_ALL_ISSUE: "/api/conversations/reportissue/v1/all",
  FETCH_ISSUE: "/api/conversations/reportissue/v1/issue?conversationId=",
  REPORT_ISSUE_DOWNLOAD_CSV: "/api/conversations/reportissue/v2/download",
  FETCH_ISSUE_TYPEWISE:
    "/api/conversations/reportissue/v1/getIssuesOnType?type=",
};

//https://connectors.saarthi.ai/campaign/api/campaignManagement/customerUploadInfo/v1/getDetails?campaignManagerId=62209959cb448a280183089c

export const CAMPAIGN_URL = {
  CREATE_CAMPAIGN: "/api/campaignManagement/campaignManagerInfo/v1/create",
  UPLOAD_CSV_CAMPAIGN: "/api/campaignManagement/customerInfo/v2/create",
  UPDATE_CAMPAIGN: "/api/campaignManagement/campaignManagerInfo/v1/update",
  ALL_CAMPAIGN_LIST: "/api/campaignManagement/campaignManagerInfo/v1/all",
  DELETE_CAMPAIGN: "/api/campaignManagement/campaignManagerInfo/v1/delete",
  DOWNLOAD_CAMPAIGN:
    "/api/campaignManagement/customerUploadInfo/v1/get?parentCampaignManagerId=",
  DOWNLOAD_CAMPAIGN_INFO:
    "/api/campaignManagement/callingInfo/v1/downloadCallingInfo",
  SAARTHI_HEADER_FIELD: "/api/campaignManagement/customerInfo/v1/fields?id=",
  GET_UPLOADED_INFO:
    "/api/campaignManagement/customerUploadInfo/v1/getDetails?campaignManagerId=",
  CSV_HEADER: `https://${process.env.REACT_APP_CONNECTOR}/api/tools/v2/fetchCsvData`,
  GET_UPLOADED_CSV: "api/push/v1/list_api_pushed_data_in_buckets?accountId=",
  CSV_MAPPED_DATA: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/campaignManagerInfo/v1/getCampaingMapping`,
  PUSHED_API_DATA_CSV_DOWNLOAD:
    "api/push/v1/download/list_api_pushed_data_in_buckets",
  GET_AGENT_CONFIGURATION: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/agentConfig/v2/get`, //Get
  SALES_ONBOARDING_AGENT_CONFIG: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/agentConfigSalesOnboarding/v2/get`,
  GET_DISPLIST_ALTERNATENUMBER: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/campaignManagerInfo/v1/getDispList`, //GET
  SAMPLE_CSV_DOWNLOAD_URL: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/usecase/v1/getHeaders`, //GET
  TECH_HEALTH: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/campaignManagerInfo/v1/getHealthApis`, //GET
  GET_PORT: `https://${process.env.REACT_APP_CONNECTOR}/api/telephony/v1/getport`, //GET
  SAMPLE_CSV_PAYMENT_DOWNLOAD_URL: `https://d35x5gnb5oxwmy.cloudfront.net/sample_allocation_files/SamplePaidFileFormat.csv`, //GET

  /**
   * it gets all the completed campaigns
   * * post api
   * clientName
   * month : 1-12
   * year
   */
  GET_ALL_COMPLETED_CAMPAIGN: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/campaignManagerInfo/v1/getDates`,
};

export const PAYMENT_URL = {
  // UPLOAD_CSV_PAYMENT:
  //   "/campaign/api/campaignManagement/paymentDetails/v2/update",
  UPLOAD_CSV_PAYMENT: "/api/campaignManagement/paymentDetails/v2/update",
  SAARTHI_HEADER_PAYMENT_FIELD:
    "/api/campaignManagement/paymentDetails/v1/fields",
  CREATE_DUNNY_NOTICES: "/api/campaignManagement/dunnyNotice/v1/create",
};

export const RESETLINK_URL = {
  GET_USER_ID: "/api/accounts/user/v1/fetchUserDetails?",
  PASSWORD_RESET_LINK: "/api/accounts/v1/resetPassword",
};

export const CLIENTNAME_URL = `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/account/v1/all`;
export const SAVE_FETCH_RECORDS_URL = `https://${process.env.REACT_APP_CONNECTOR}/api/connectors/salesforce/v1/saveFetchMapping`;
export const SAVE_PUSH_RECORDS_URL = `https://${process.env.REACT_APP_CONNECTOR}/api/connectors/salesforce/v1/saveFetchMapping`;
//export const RUN_SAVE_DATA_URL ='https://connectors.saarthi.ai/api/connectors/salesforce/v1/fetchRecords'

export const TABLESALESFORCE_URL = `https://${process.env.REACT_APP_CONNECTOR}/api/connectors/job/v1/job/all`;

export const SALESFORCE_SERVER_URL = `https://${process.env.REACT_APP_CONNECTOR}/api`;
// salesforce Api
export const SALESFORCE_URL = {
  SFOBJECT_URL: "/connectors/salesforce/v1/fetchObjects?",
  SAARTHIOBJECT_URL: "/connectors/v1/fetchObjects?",
  SFOBJECT_FIELD_URL: "/connectors/salesforce/v1/fetchObjectFields?",
  SAARTHIOBJECT_FIELD_URL: "/connectors/v1/fetchObjectFields?",
  SAVE_FETCH_RECORDS_URL: "/connectors/salesforce/v1/saveFetchMapping",
  SAVE_PUSH_RECORDS_URL: "/connectors/salesforce/v1/savePushMapping",
  RUN_SAVE_DATA_URL: "//connectors/salesforce/v1/fetchRecords",
};

//Payment Info API Razorpay
//export const CONNECTOR_PAYMENT_INFO = "https://connectors.saarthi.ai/campaign"
export const CONNECTOR_PAYMENT_INFO = `https://${process.env.REACT_APP_CONNECTOR}/api/tools/v1/razorPay/`;
export const PAYMENT_INFO_URL = {
  FETCH_PAYMENT_INFO: "/api/campaignManagement/paymentDetails/v1/payment/all", //POST API
  SEND_PAYMENT_INFO_EMAIL: "sendEmail", //POST API
  PAYMENT_INFO_STATUS: "fetchStatus?", //GET API
  PAYMENT_INFO_DOWNLOAD:
    "/api/campaignManagement/paymentDetails/v1/payment/download", //POST API
};

//Api for demo
export const DEMO_SERVER_URL = `https://${process.env.REACT_APP_SERVER_URL}`;
export const DEMO_URL = {
  FETCH_ALL_ROLE: "/api/accounts/role/v1/all", //GET API
  REGISTER_USER: "/api/accounts/user/v1/register", //POST API
  FETCH_POSTEMI: "/api/accounts/flow/v1/filter", //post API
};

export const DEMO_SIGNUP_URL = {
  REQUEST_DEMO: "/api/accounts/requestDemo//v1/create", //POST API
  DEMO_SIGN_UP: "/api/accounts/user/v1/signup", //POST API
  DEMO_SEND_OTP: `https://${process.env.REACT_APP_CONNECTOR}/api/sms/v1/sendOtp`,
  DEMO_VERIFY_OTP: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/user/v1/verifyOtp`,
  GET_CALL_STATUS: `https://${process.env.REACT_APP_CALL_STATUS}/dataCollection/getCallStatus`,
  EXPERIENCE_NOW: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/account/v1/experience`,
  GET_FLOW_EXPERIENCENOW: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/flow/v1/all`,
  GET_LANGUAGE_EXPERIENCENOW: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/language/v1/all`,
};

export const OPERATION = {
  GET_DATA: "/api/accounts/account/v1/operation/get",
  DOWNLOAD_DATA: "/api/accounts/account/v1/operation/download ", //POST
  GET_ALL_CLIENT: "/api/accounts/account/v1/all/distinctAccount", // GET
  SEND_EMAIL: `https://${process.env.REACT_APP_CONNECTOR}/api/tools/v2/sendEmail`, //POST
};
// Api for scheduler
export const SCHEDULER_URL = {
  ARCHIEVE_API: "/api/campaignManagement/campaignManagerInfo/v1/archive", // PATCH
  PLAY_PAUSE_API: "/api/campaignManagement/campaignManagerInfo/v1/pause", // PATCH
  GET_USECASE_API: "/api/accounts/usecase/v1/all", //GET
  GET_CALLINGLIST_API: "/api/campaignManagement/callingInfo/v1/getCallingList", //GET
  CALLINGLIST_DISABLE_API:
    "/api/campaignManagement/callingInfo/v1/changeActiveStatus", //POST
  GET_CALLINGLIST_FILTERS: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/callingInfo/v1/getFilters`, //GET
  GET_ALL_PORT: "/api/accounts/account/v1/getById", //GET
  SCHEDULE_CAMPAIGN: process.env.REACT_APP_SCHEDULE, //POST
  CALLING_INFO: "/api/campaignManagement/callingInfo/v1/getAllocationCount", //POST
  GET_CAMPAIGN_DAETAIL:
    "/api/campaignManagement/campaignManagerInfo/v1/getCampaignDetails", //POST
  MAX_CALL_ATTEMPS:
    "/api/campaignManagement/campaignManagerInfo/v1/callingCount", // POST
  PLAY_PAUSE_CAMPAIGN: "/api/campaignManagement/callingCron/v1/pauseCampaign", //POST
  GET_ALL_DETAILS_CLIENT: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/account/v1/getByName?accountName=`, //GET
};

export const SCHEDULERSECTION = {
  GET_SCHEDULER_DATA: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/callingCondition/v1/all`,
  GET_DIALTIME_DATA: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/dialTimeTemplate/v1/get?accountId=`,
  GET_CAMPAIGN_CONDITION: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/callingCondition/v1/all`,
  GET_DISPOSITION_CONDITION: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/dispositionBasedCallingTemplate/v1/get?accountId=`,
  SAVE_SCHEDULER_DATA: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/saveCampaignDetails/v1/save`,
  GET_ALL_DISPOSITION_DATA_TO_EDIT: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/saveCampaignDetails/v1/get?campaignId=`,
  SUBMIT_DIAL_TIME_DATA: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/saveCampaignDetails/v1/save`,
};

export const OMNICHANNEL = {
  GET_TEMPLATE_DROPDOWN_URL: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/template/v1/get`,
  GET_SELECTED_TEMPLATE: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/template/v1/getByIdList`,
  GET_MINDMAP: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/demoTemplate/v1/filter`,
  HIDE_INITIAL_SCREEN: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/user/v1/addDemoModule`, //POST ,
};
export const CAMPAIGN_TESTING_URL = {
  GET_CUSTOMERS_DATA: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/campaignTestingInfo/v1/all?clientName=`,
  SAVE_CUSTOMERS_DATA: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/campaignTestingInfo/v1/save`,
  EXECUTE_CALL: `https://staging-connectors.saarthi.ai/dialer/api/dialer/epicode/v1/initiateVoiceCall`,
  CAMPAIGNTESTING: `https://staging-connectors.saarthi.ai/pythonCalling/call`,
  CAMPAIGNTESTINGWHATAPP: `https://${process.env.REACT_APP_WHATSAPP}/whatsapp/v1/triggerMessage`,
  PUSH_DATA_API: `https://${process.env.REACT_APP_CONNECTOR}/api/push/v1/push_to_system`,
  GET_SCHEMA: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/campaignTestingInfoSchema/v1/get`,
};

export const DUMMYAPI = {
  GET_DUMMY_RESPONSE: "https://reqres.in/api/users?page=2",
};

var headers = {
  "Content-Type": "application/json",
};

export const PROFILE_API = {
  /**pass name at last */
  get_by_name: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/user/v1/get`,
  /** pass id */
  update_name_and_phone: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/user/v1/update-profile/`,
  /** pass user id */
  upload_profile_picture: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/user/v1/update-profile-image/`,
  /** pass user id */
  delete_profile_picture: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/user/v1/delete-profile-image/`,
  /** Pass id at last
   * @example
   * url+ id
   */
  GET_MODULE_DETAILS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/setting/v1/getmodule`,
  /**
   * https://${process.env.REACT_APP_SERVER_URL}/api/accounts/setting/v1/getdropdown?id=62dfe47331a56d32a437ea43&screenName=Setting
   * axios
   * - params:{id:"string",screenName="Setting" | "Home"}
   * @example
   * axios.get(`url`, {
        params: { id: '48394898', screenName: 'Home' }},
   */
  GET_TOP_DROPDOWN: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/setting/v1/getdropdown`,
};

export const ORGANIZATION_API = {
  GET_ORGANIZATION_DATA: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/account/v1/account/`,
  //CREATE_ORGANIZATION_DATA: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/account/v1/create`,
  UPDATE_ORGANIZATION_DATA: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/account/v1/update`,
  VERIFY_GST: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/verifyOrg/gst`,
  VERIFY_PAN: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/verifyOrg/pan`,
  SEND_MAIL_SIGN: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/account/v1/signrequest`,
};

export const INVOICES_API = `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/billing/v1/invoice/account/`;

export const PRICING_PLAN_API = {
  GET_BY_ACCOUNT_API: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/account/v1/getSubscription`,
  UPDATE_PRICING_PLAN: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/account/v1/update`,
  GET_PRICING_PLAN_DETAILS: `https://${process.env.REACT_APP_STAGE_SERVER}/payments/api/plans`,
  GENERATE_ORDERID: `https://${process.env.REACT_APP_STAGE_SERVER}/payments/api/cart`,
  VERIFY_PAYMENT_ORDER: `https://${process.env.REACT_APP_STAGE_SERVER}/payments/api/transactions/verify`,
  APPLY_DISCOUNT_COUPON: `https://${process.env.REACT_APP_STAGE_SERVER}/payments/api/coupon/apply`,
  MY_PLAN_DETAILS: `https://${process.env.REACT_APP_STAGE_SERVER}/payments/api/subscription/myplan`,
  GET_INVOICES_API: `https://${process.env.REACT_APP_STAGE_SERVER}/payments/api/transactions/invoicelist`,
  DOWNLOAD_INVOICE_API: `https://${process.env.REACT_APP_STAGE_SERVER}/payments/api/transactions/invoice`,
  UPDATE_INVITEMEMBER_STATUS: `https://${process.env.REACT_APP_STAGE_SERVER}/payments/api/subscription//usage/`,
  CUSTOM_PLAN_REQUEST: `https://${process.env.REACT_APP_SERVER_URL}/api//accounts/sendMail/v1/sendContactusEmail`,
  GET_COUPAN_PLAN: `https://${process.env.REACT_APP_STAGE_SERVER}/payments/api/cart/getcoupon`,
  GET_MAI_PAYMENT_STATUS: `https://${process.env.REACT_APP_STAGE_SERVER}/payments/api/transactions/checkpendinginvoice`,
};

export const SETTINGS_API = {
  UPDATE_SECURITY: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/user/v1/update-password/`,
};

export const REPORT_RULE_API = {
  CREATE_REPORT_RULE_BY_ACCOUNT_ID: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/reportrule/v1/create/`,
  GET_REPORT_RULES_BY_ACCOUNT_ID: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/reportrule/v1/get/account/`,
  GET_SPECIFIC_REPORT_RULE_BY_ID: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/reportrule/v1/get/`,
  UPDATE_REPORT_RULE_BY_ID: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/reportrule/v1/update/`,
  DELETE_REPORT_RULE_BY_ID: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/reportrule/v1/delete/`,
};

/* Agent Desktop --------- */
export const AGENT_DESKTOP_API = {
  GET_AGENT_SCHEMA: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/agent/v1/agentSchema`,
  GET_ADMIN_SCHEMA: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/agentadmin/v1/agentSchema`,
  GET_DISPOSITIONS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/agent/v1/getDisposition`,
  GET_REASONS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/agent/v1/getReason`,
  GET_DASHBOARD_DATA: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/agent/v1/dashboardDetails`,
  SET_CALL_STATUS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/agentstatus/v1/calleventupdate`,
  GET_RING_DATA: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/agentstatus/v1/ring`,
  // GET_DASHBOARD_DATA:"https://localhost:6025/api/accounts/agent/v1/dashboardDetails",
  // GET_DASHBOARD_DATA: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/agent/v1/dashboardDetails`,
  UPDATE_STATUS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/agentstatus/v1/update`,
  UPDATE_SUMMARY: `https://${process.env.REACT_APP_SERVER_URL2}/api/conversations/summary/v1/agentSummary/update`,
  GET_SUMMARY: `https://${process.env.REACT_APP_SERVER_URL2}/api/conversations/summary/v1/all`,
  GET_TRANSCRIPT: `https://${process.env.REACT_APP_SERVER_URL2}/api/conversations/message/v1/all`,
  GET_STATUS_LIST: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/statuslist/v1/getall`,
  DASHBOARD_DATA_URL: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/agentstatus/v1/agentdashdata`,
  GET_SESSION_ID_CHAT: `https://${process.env.REACT_APP_SERVER_URL2}/api/conversations/summary/v1/agent/phoneNo/sessionId`,
  PERFORMANCE_TABLE_URL: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/agentstatus/v1/agentlog`,

  /**
   * # this is to get whatsapp status
   * curl --location --request POST 'https://staging-conversationlogger.saarthi.ai/conversations/api/conversations/summary/v1/message/status' \
--header 'Content-Type: application/json' \
--data-raw '{
    "conversationId":"63c904cc541f82784ef0f74b",
    "messageId": "63c904d06d623b6f79fd83ab"
}'
   */
  GET_WHATSAPP_MESSAGE_STATUS: `https://${process.env.REACT_APP_SERVER_URL2}/api/conversations/summary/v1/message/status`,
  /**
   * curl --location --request GET 'https://staging-conversationlogger.saarthi.ai/accounts/api/accounts/agent/v1/getTypedCannedMessages?messageKey' \
--header 'Content-Type: application/json'
   */
  GET_CANNED_MESSAGES: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/agent/v1/getTypedCannedMessages`,
};

/* Strategy ------------ */
export const STRATEGY_API = {
  CREATE_STRATEGY_BY_CLIENT_NAME: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/strategy/v1/create`,
  GET_STRATEGIES_BY_CLIENT_NAME: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/strategy/v1/get`,
  GET_USER_PERSONAS_BY_ID: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/userPersonas/v1/get`,
  ADD_USER_PERSONAS_BY_ID: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/strategy/v1/addPersonas`,
  UPDATE_STRATEGY_BY_ID: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/strategy/v1/update`,
  DELETE_STRATEGY_BY_ID: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/strategy/v1/delete`,
  GET_WORK_FLOWS: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/strategy/v1/getWorkspaceFlows`,
  ADD_WORK_FLOW: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/strategy/v1/addFlows`,
  SET_WORK_FLOW: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/strategy/v1/setFlowByAccount`,
  GET_AGENTS: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/agentConfig/v2/get`,
  GET_UPLOADED_STRATEGY: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/customerUploadInfo/v1/getDetails?strategyId=`,
  UPLOAD_CSV_STRATEGY: `https://${process.env.REACT_APP_MAPPING_CSV}/api/campaignManagement/customerUploadInfo/v2/getStrategyPersonas`,
  GET_STRATEGY_OPTIONS: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/strategy/v1/getFilterList`,
  GET_USER_PERSONA_OPTIONS: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/userPersonas/v1/getFilters`,

  CREATE_NUDGE: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/nudges/v1/create`, //POST
  GET_NUDGE: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/nudges/v1/get`, //GET
  UPDATE_NUDGE: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/nudges/v1/update`, //PUT
  GET_CONDITION_OUTCOMES: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/strategy/v1/getDisposition`, //GET

  GET_AGENTS_NEW: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/agents/v1/filter`, //POST
  CREATE_AGENTS: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/agents/v1/create`, //POST
  UPDATE_AGENT: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/agents/v1/update`, //PUT
  DELETE_AGENT: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/agents/v1/delete`, //delete
  GET_PAYMENTMODE_FLOW: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/flow/v1/get/scripts?`, //GET
};

export const CAMPAIGN_NEW_API = {
  GET_ASR_OPTIONS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/asr/v1/get`,
  GET_TTS_OTPIONS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/tts/v1/get`,
  GET_CAMPAIGN_OPTIONS: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/campaignManagerInfo/v1/getOptions`,
  CREATE_CAMPAIGN: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/campaignManagerInfo/v1/create`,
  UPDATE_CAMPAIGN: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/campaignManagerInfo/v1/update`,
  UPLOAD_CSV_API: `https://${process.env.REACT_APP_MAPPING_CSV}/api/campaignManagement/customerUploadInfo/v2/uploadCsv`,
  UPLOAD_PAYMENT_CSV_API: `https://${process.env.REACT_APP_MAPPING_CSV}/api/campaignManagement/paymentDetails/v1/update`,
  UPLOAD_DUNNING_CSV_API: `https://${process.env.REACT_APP_MAPPING_CSV}/api/campaignManagement/dunnyNotice/v2/create`,
  DELETE_CSV_API: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/customerUploadInfo/v2/delete`,
  TERMINATE_CAMPAIGN: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/frontend/campaignManagerInfo/v2/teminate/campaign`,


  SET_SELECTED_CSV_CAMPAIGN: `https://${process.env.REACT_APP_MAPPING_CSV}/api/campaignManagement/customerUploadInfo/v2/fileUpload/update`,
  SET_SELECTED_API_CAMPAIGN: `https://${process.env.REACT_APP_CONNECTOR}/api/push/v1/fileSelected/update`,
  GET_UPLOADED_CSV_INFO: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/customerUploadInfo/v1/getDetails`,
  GET_SELECTED_PAYMENT_CSV_DATA_API: `https://${process.env.REACT_APP_MAPPING_CSV}/api/campaignManagement/failedpaymentdetails/v1/get`,
  POST_EDITED_PAYMENT_CSV_API: `https://${process.env.REACT_APP_MAPPING_CSV}/api/campaignManagement/failedpaymentdetails/v1/update`,
  GET_SELECTED_CSV_DATA_API: `https://${process.env.REACT_APP_MAPPING_CSV}/api/campaignManagement/failedcustomerinfo/v1/get`,
  POST_EDITED_CSV_API: `https://${process.env.REACT_APP_MAPPING_CSV}/api/campaignManagement/failedcustomerinfo/v1/update`,
  ADD_STRATEGY_TO_CAMPAIGN: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/strategy/v1/addStrategyToCampaign`,
  SAVE_SCHEDULER_DATA: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/saveCampaignDetails/v1/save`,
  GET_SCHEDULER_DATA: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/nudges/v1/getDialTime`,
  UPDATE_AGENT_CAMPAIGN: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/campaignManagerInfo/v1/addAgentToCampaign`,
  GET_API_CSV_DATA: `https://${process.env.REACT_APP_CONNECTOR}/api/push/v1/list_api_pushed_data_in_buckets`,
  GET_FOLLOWUPS_DISPOSITIONS: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/dispositionBasedCallingTemplate/v1/get`,
  GET_CAMPAIGN_SUMMARY_DATA: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/campaignManagerInfo/v1/get/campaignSummary`,
  GET_CSV_ZIP_DATA: `https://${process.env.REACT_APP_CONNECTOR_CAMPAIGN}/api/campaignManagement/campaignManagerInfo/v1/get/uploadedZipFile`,
};

export const PRODUCT_PAGE_API = {
  GET_ALL_DOMAINS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/domain/v1/all`,
  //GET_ALL_PRODUCTS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/product/v1/all`,
  GET_ALL_PRODUCTS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/product/v1/getProduct`,
  GET_ALL_PRODUCT_TYPES: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/producttype/v1/getProducts`,
  UPDATE_PRODUCT_TYPE: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/producttype/v1/update`,
  GET_ALL_USE_CASE: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/usecase/v1/allUseCase`,
  CREATE_PRODUCT_TEAM: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/team/v1/create`,
};

export const TEAMS_PAGE_API = {
  GET_ALL_TEAMS: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/team/v1/all`,
  UPDATE_PRODUCT_TEAM: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/team/v1/update`,
  GET_TEAM_MEMBER_DATA: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/inviteTeam/v1/all`,
  GET_ALL_ROLES: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/role/v2/getRole`,
};

export const INVITE_USER_API = {
  SEND_INVITE_USER: `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/inviteTeam/v1/create`,
};

export const INTEGRATION_API = {
  GET_ALL_INTEGRATIONS: `https://${process.env.REACT_APP_CONNECTOR}/api/category/v1/allIntegration`,
  CREATE_INTEGRATION: `https://${process.env.REACT_APP_CONNECTOR}/api/integration/v1/createIntegration`,
  UPDATE_INTEGRATION: `https://${process.env.REACT_APP_CONNECTOR}/api/integration/v1/editIntegration`,
  UPLOAD_PIC_TRUECALLER: `https://${process.env.REACT_APP_CONNECTOR}/api/integration/v1/update-truecaller-profile-image/`,
  DELETE_PIC_TRUECALLER: `https://${process.env.REACT_APP_CONNECTOR}/api/integration/v1/delete-truecaller-profile-image/`,
  GET_ALL_CATEGORIES: `https://${process.env.REACT_APP_CONNECTOR}/api/integration/v1/gettruecallercategory`,
  UPLOAD_PIC_WHATSAPP: `https://${process.env.REACT_APP_CONNECTOR}/api/integration/v1/update-whatsapp-profile-image/`,
  DELETE_PIC_WHATSAPP: `https://${process.env.REACT_APP_CONNECTOR}/api/integration/v1/delete-whatsapp-profile-image/`,
  GET_ALL_INDUSTRY: `https://${process.env.REACT_APP_CONNECTOR}/api/integration/v1/getWhatsappCategory`,
  GET_EMAIL_DNS_API: `https://${process.env.REACT_APP_CONNECTOR}/api/integrations/email/v1/authenticate-domain`,
  VERIFY_EMAIL_DNS_API: `https://${process.env.REACT_APP_CONNECTOR}/api/integrations/email/v1/verify-domain`,
  GET_DEV_EMAIL_API: `https://${process.env.REACT_APP_CONNECTOR}/api/integrations/email/v1/getIntegrationDetails`,
  SEND_DNS_TO_DEV_EMAIL_API: `https://${process.env.REACT_APP_CONNECTOR}/api/integrations/email/v1/send-to-devs`,
  CONNECT_EXISTING_ACCOUNT_SMS: `https://${process.env.REACT_APP_CONNECTOR}/api/integrations/v1/existingsmsaccount`,
  UPDATE_EMAIL_API: `https://${process.env.REACT_APP_CONNECTOR}/api/integrations/email/v1/update-email`,
  GET_CUSTOM_INTEGRATION_API: `https://${process.env.REACT_APP_CONNECTOR}/api/integrations/custom-api/v1/generate-secrets`,
  SEND_INFO_TO_DEV_EMAIL_API: `https://${process.env.REACT_APP_CONNECTOR}/api/integrations/custom-api/v1/send-to-devs`,
  CREATE_SMS_INTEGRATION: `https://${process.env.REACT_APP_CONNECTOR}/api/integration/v1/createSMSIntegration`,
  UPDATE_NEW_SMS_INTEGRATION: `https://${process.env.REACT_APP_CONNECTOR}/api/integration/v1/editSMSIntegration`, //POST
  GET_TELEPHONY_DETAILS: `https://${process.env.REACT_APP_CONNECTOR}/api/telephony/v1/get`, //GET
  CRETE_TELEPHONY_DETAILS: `https:///${process.env.REACT_APP_CONNECTOR}/api/telephony/v1/create`, // POST
  CALL_RECORDING:  `https:///${process.env.REACT_APP_BASE_URL}/preprocessing/getAudioSession/`
};

export const EXCLUSIONS_LIST_API = {
  GET_PHONE_NUMBER: `https://${process.env.REACT_APP_MAPPING_CSV}/api/campaignManagement/exclusionList/v1/get`,
  ADD_PHONE_NUMBER: `https://${process.env.REACT_APP_MAPPING_CSV}/api/campaignManagement/exclusionList/v1/create`,
  ADD_PHONE_NUMBER_CSV: `https://${process.env.REACT_APP_MAPPING_CSV}/api/campaignManagement/exclusionList/v1/uploadFile`,
  REMOVE_PHONE_NUMBER: `https://${process.env.REACT_APP_MAPPING_CSV}/api/campaignManagement/exclusionList/v1/delete`,
};

export const config = {
  POST: (url, params) => {
    return axios.post(url, params, { headers: headers });
  },

  POST_WITH_CANCEL_TOKEN: (url, params, cancelToken) => {
    return axios.post(url, params, {
      cancelToken: cancelToken.token,
      headers: headers,
    });
  },
  POST_WITH_CANCEL_TOKEN_HEADER: (url, params, cancelToken, headerfrom) => {
    return axios.post(url, params, {
      cancelToken: cancelToken.token,
      headers: headerfrom,
    });
  },
  PUT: (url, params) => {
    return axios.put(url, params);
  },
  GET: (url) => {
    return axios.get(url);
  },
  GET_WITH_PARAMS_RES_TYPE: (url, data) => {
    return axios.get(url, {
      params: data.params,
      responseType: data.responseType,
    });
  },

  GET_WITH_PARAMS_HEADER: (url, data) => {
    return axios.get(url, { params: data.params, headers: data.headers });
  },

  GET_WITH_HEADER: (url, headers) => {
    return axios.get(url, headers);
  },
  GET_WITH_PARAMS: (url, params) => {
    return axios.get(url, { params: params, headers: headers });
  },

  POST_WITH_HEADER: (url, params, headerParams = headers) => {
    return axios.post(
      url,
      params,
      { headers: headerParams },
      { timeout: 1000 * 60 * 3 }
    );
  },
  POST_WITH_ACCESS_TOKEN_HEADER: (url, params, headerfrom) => {
    return axios.post(url, params, {
      headers: headerfrom,
    });
  },
  POST_WITH_PARAMS: (url, params) => {
    return axios.post(url, params);
  },

  at: (url, params, headerParams = headers) => {
    return axios.post(url, null, { params: params }, { headers: headerParams });
  },
  PUT_WITH_HEADER: (url, params) => {
    return axios.put(url, params, { headers: headers });
  },
  PUT_WITH_CUSTOM_HEADER: (url, params, headers) => {
    return axios.put(url, params, headers);
  },
  PUT_WITH_PARAMS: (url, params) => {
    return axios.put(url, params);
  },
  PUT_WITH_BODY: (url, body) => {
    return axios.put(url, body);
  },
  PUT_WITH_HEADER_BODY: (url, headers, body) => {
    return axios.put(url, headers, body);
  },
  DELETE: (url, params) => {
    return axios.delete(url);
  },
  DELETE_WITH_BODY: (url, body) => {
    return axios.delete(url, { data: body });
  },

  DELETE_WITH_HEADER: (url, body, headers) => {
    return axios({ method: "delete", url: url, data: body, headers: headers });
  },

  GET_WITH_PARAMS_CANCEL_TOKEN: (url, params, cancelToken) => {
    return axios.get(url, {
      params: params,
      headers: headers,
      cancelToken: cancelToken,
    });
  },
  POST_WITH_HEADERS_BODY_CANCEL_TOKEN: (url, body, headers, cancelToken) => {
    return axios({
      method: "post",
      url: url,
      cancelToken: cancelToken,
      data: body,
      headers: headers,
    });
  },
  POST_WITH_HEADERS_BODY: (url, body, headers) => {
    return axios({
      method: "post",
      url: url,
      data: body,
      headers: headers,
    });
  },

  POST_WITH_BODY: (url, body, headers) => {
    return axios({
      method: "post",
      url: url,
      data: body,
    });
  },
  PATCH: (url, params) => {
    return axios.patch(url, params, { headers: headers });
  },
};
