import { Reducer, AnyAction } from "redux";
import actionTypes from "../exclusionsList.actionTypes";

export interface stateInterface {
  phoneNumberData: any;
  isLoading: boolean;
  error: any;
  isPhoneNumberRemovedSuccessfully: boolean;
  selectedRowsFromCheckbox: any;
  isPhoneNumberAdditionSuccessful: boolean;
  exclusionsListType: string;
}

export const initialState: stateInterface = {
  phoneNumberData: {},
  isLoading: false,
  error: {},
  isPhoneNumberRemovedSuccessfully: false,
  selectedRowsFromCheckbox: [],
  isPhoneNumberAdditionSuccessful: false,
  exclusionsListType: "Global",
};

const exclusionsListReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.GET_PHONE_NUMBER_REQUEST: {
      return {
        ...state,
        phoneNumberData: {},
        isLoading: true,
        error: {},
      };
    }
    case actionTypes.GET_PHONE_NUMBER_SUCCESS: {
      return {
        ...state,
        phoneNumberData: action.payload,
        isLoading: false,
        error: {},
      };
    }
    case actionTypes.GET_PHONE_NUMBER_FAILURE: {
      return {
        ...state,
        phoneNumberData: {},
        isLoading: false,
        error: action.payload,
      };
    }

    case actionTypes.ADD_PHONE_NUMBER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: {},
        isPhoneNumberAdditionSuccessful: false,
      };
    }
    case actionTypes.ADD_PHONE_NUMBER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: {},
        isPhoneNumberAdditionSuccessful:
          action.payload.data &&
          action.payload.message !==
            "The phone number you entered already exists"
            ? true
            : false,
      };
    }
    case actionTypes.ADD_PHONE_NUMBER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isPhoneNumberAdditionSuccessful: false,
      };
    }

    case actionTypes.ADD_PHONE_NUMBER_CSV_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: {},
        isPhoneNumberAdditionSuccessful: false,
      };
    }
    case actionTypes.ADD_PHONE_NUMBER_CSV_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: {},
        isPhoneNumberAdditionSuccessful: true,
      };
    }
    case actionTypes.ADD_PHONE_NUMBER_CSV_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isPhoneNumberAdditionSuccessful: false,
      };
    }

    case actionTypes.REMOVE_PHONE_NUMBER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: {},
        isPhoneNumberRemovedSuccessfully: false,
      };
    }
    case actionTypes.REMOVE_PHONE_NUMBER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: {},
        isPhoneNumberRemovedSuccessfully: true,
      };
    }
    case actionTypes.REMOVE_PHONE_NUMBER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isPhoneNumberRemovedSuccessfully: false,
      };
    }

    case actionTypes.SET_SELECTED_ROWS_FROM_CHECKBOX: {
      return {
        ...state,
        selectedRowsFromCheckbox: action.payload,
      };
    }

    case actionTypes.SET_EXCLUSIONS_LIST_TYPE: {
      return {
        ...state,
        exclusionsListType: action.payload,
      };
    }

    case actionTypes.SET_CLEAR_ADD_PHONE_NUMBER_ERROR: {
      return {
        ...state,
        error: {},
      };
    }

    default: {
      return state;
    }
  }
};

export default exclusionsListReducer;
