import React, { useEffect, useState } from "react";
import DropDown from "../../../../components/generic/dropdownSaarthi/dropdownsaarthi2/DropdownSaarthi";
import ErrorMessage from "../../../../components/generic/errorMessage/ErrorMessage";
import ToggleButton from "../../../../components/generic/toggelButton/ToggleButton";
import { downArrow } from "../../../../theme/assets/svg/demo";
import "./InputFields.scss";
import { RootState } from "../../../../redux";
import { useSelector } from "react-redux";
import { DOMAINS, USECASE } from "../../../../constants";

interface propsInterface {
  type: string;
  inputFieldSchema: any;
  handleInputChange: (value: any, key: any, index: any) => void;
  customerIndex: number;
  customerData: Record<string, any>;
  handleDropdownClick: (e: any) => void;
  handleCalenderClick: (e: any) => void;
  handleCalenderBlur: (e: any) => void;
  dropdownCallMapping: Record<string, any>;
  shouldShow: boolean;
  showLanguageByFlow: Record<string, any>;
}

export default function InputFields(props: propsInterface) {
  const useCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseId
  );
  const [dropListState, setDropListState] = useState<any>({ optionList: [] });
  useEffect(() => {
    if (
      currentDomainId === DOMAINS.automobile.id &&
      props.inputFieldSchema.BackendKey === "primaryInfo.channel"
    ) {
      const backendList = Object.keys(
        props.dropdownCallMapping[props.inputFieldSchema.BackendKey]
      );
      let options;
      if (props.customerData["primaryInfo.flow"] === "status_update") {
        if (
          props.customerData["primaryInfo.status"] === "Appointment Confirmed"
        ) {
          options = backendList.filter((item: string) =>
            ["Email", "WhatsApp", "SMS"].includes(item)
          );
        } else {
          options = backendList.filter((item: string) =>
            ["WhatsApp", "SMS"].includes(item)
          );
        }
      } else {
        options = backendList.filter((item: string) =>
          ["Voice", "WhatsApp"].includes(item)
        );
      }
      setDropListState({
        ...dropListState,
        optionList: options,
      });
    }
  }, [
    props.customerData["primaryInfo.flow"],
    props.customerData["primaryInfo.status"],
  ]);

  useEffect(() => {
    if (props.type === "dropdown") {
      let dropList: any;
      if (props.inputFieldSchema.name === "Language") {
        dropList = {
          optionList:
            props.showLanguageByFlow?.[
              props.customerData?.["primaryInfo.flow"]
            ] ||
            Object.keys(
              props.dropdownCallMapping[props.inputFieldSchema.BackendKey]
            ) ||
            [],
          placeHolderText: props.inputFieldSchema.placeHolderValue,
          imgSrcRight: downArrow,
        };
      } else {
        dropList = {
          optionList: Object.keys(
            props.dropdownCallMapping[props.inputFieldSchema.BackendKey]
          ),
          placeHolderText: props.inputFieldSchema.placeHolderValue,
          imgSrcRight: downArrow,
        };
      }
      setDropListState(dropList);
    }
  }, []);
  const currentDomainId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.domainId
  );
  if (props.shouldShow === false) return <></>;
  switch (props.type) {
    case "input": {
      let restrictedCharArr = [
        "+",
        "-",
        ".",
        "<",
        ">",
        ";",
        ":",
        "?",
        "/",
        "\\",
        "%",
        '"',
        "'",
      ];
      let placeholder = props.inputFieldSchema.placeHolderValue;
      if (
        props.inputFieldSchema.name === "Agent Name" &&
        currentDomainId !== DOMAINS.automobile.id &&
        useCaseId !== USECASE.salesAndOnboarding.id
      ) {
        placeholder =
          props.customerData["primaryInfo.gender"] === "Male"
            ? "Female agent name"
            : "Male agent name";
      }
      if ("number" === props.inputFieldSchema.inputType) {
        restrictedCharArr = [...restrictedCharArr, "e"];
      } else if ("alphabet" === props.inputFieldSchema.inputType) {
        restrictedCharArr = [
          ...restrictedCharArr,
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "0",
          "@",
        ];
      } else if ("email" === props.inputFieldSchema.inputType) {
        restrictedCharArr = restrictedCharArr.filter((item) => item !== ".");
      }
      const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.handleInputChange(
          props.inputFieldSchema.maxLength
            ? e.target.value.slice(0, props.inputFieldSchema.maxLength)
            : e.target.value,
          props.inputFieldSchema.BackendKey,
          props.customerIndex
        );
      };
      return (
        <div
          className={`form-field__${props.inputFieldSchema.name} form-field`}
        >
          {props.inputFieldSchema.isRequire && props.shouldShow && (
            <span className="required-indicator">*</span>
          )}
          <input
            type={
              props.inputFieldSchema.inputType
                ? props.inputFieldSchema.inputType
                : "text"
            }
            className={`${props.inputFieldSchema.name}Input input-field`}
            name={props.inputFieldSchema.BackendKey}
            placeholder={placeholder}
            onChange={handleInputChange}
            value={props.customerData[props.inputFieldSchema.BackendKey]}
            onKeyDown={(e) =>
              restrictedCharArr.includes(e.key) && e.preventDefault()
            }
            onWheel={(e) => {
              return (e.target as HTMLElement).blur();
            }}
          />
          {(props.inputFieldSchema.BackendKey === "contactInfo.primary" ||
            props.inputFieldSchema.BackendKey ===
              "contactInfo.salesManager") && (
            <div className="errorDiv">
              {props.customerData[props.inputFieldSchema.BackendKey]?.length !==
                10 &&
              props.customerData[props.inputFieldSchema.BackendKey]?.length !==
                0 ? (
                <ErrorMessage errorMessage="Enter 10 Digit Valid Mobile Number" />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      );
    }
    case "dropdown": {
      let dropList;
      if (props.inputFieldSchema.name === "Language") {
        dropList = {
          optionList:
            props.showLanguageByFlow?.[
              props.customerData?.["primaryInfo.flow"]
            ] ||
            Object.keys(
              props.dropdownCallMapping[props.inputFieldSchema.BackendKey]
            ) ||
            [],
          placeHolderText: props.inputFieldSchema.placeHolderValue,
          imgSrcRight: downArrow,
        };
      } else {
        dropList = {
          optionList: Object.keys(
            props.dropdownCallMapping[props.inputFieldSchema.BackendKey]
          ),
          placeHolderText: props.inputFieldSchema.placeHolderValue,
          imgSrcRight: downArrow,
        };
      }
      return (
        <div
          className={`form-field__${props.inputFieldSchema.name} form-field`}
          onClick={props.handleDropdownClick}
        >
          {props.inputFieldSchema.isRequire && props.shouldShow && (
            <span className="required-indicator">*</span>
          )}
          {(currentDomainId === DOMAINS.automobile.id &&
          props.inputFieldSchema.BackendKey === "primaryInfo.channel"
            ? dropListState
            : dropList) && (
            <DropDown
              droplist={
                currentDomainId === DOMAINS.automobile.id &&
                props.inputFieldSchema.BackendKey === "primaryInfo.channel"
                  ? dropListState
                  : dropList
              }
              editedItem={props.customerData[props.inputFieldSchema.BackendKey]}
              selectedItem={(value: any) =>
                props.handleInputChange(
                  value,
                  props.inputFieldSchema.BackendKey,
                  props.customerIndex
                )
              }
              searchUi={false}
              id ={props.inputFieldSchema.name}
              extraClassPlaceHolder={"extraClassPlaceHolderDD"}
              extraClassSelectedArea={`${props.inputFieldSchema.name}Dropdown general-dropdown`}
              extraClassToBeSelectedArea={"dropdowndListAreaCampaignTesting"}
            />
          )}
        </div>
      );
    }
    case "calender": {
      let minDate;
      let maxDate;
      if (props.inputFieldSchema.minDate) {
        minDate =
          props.inputFieldSchema.minDate === "today"
            ? new Date()
            : new Date(props.inputFieldSchema.minDate);
        minDate.setHours(0, 0, 0, 0);
      }

      if (props.inputFieldSchema.maxDate) {
        maxDate =
          props.inputFieldSchema.maxDate === "today"
            ? new Date()
            : new Date(props.inputFieldSchema.maxDate);
        maxDate.setHours(0, 0, 0, 0);
      }
      return (
        <div className={`form-field-${props.inputFieldSchema.name} form-field`}>
          {props.inputFieldSchema.isRequire && props.shouldShow && (
            <span className="required-indicator">*</span>
          )}
          <input
            type={
              props.customerData[props.inputFieldSchema.BackendKey]
                ? "date"
                : "text"
            }
            className={`${props.inputFieldSchema.name}-input input-field`}
            name={props.inputFieldSchema.BackendKey}
            onClick={props.handleCalenderClick}
            onBlur={props.handleCalenderBlur}
            placeholder={props.inputFieldSchema.placeHolderValue}
            min={minDate ? minDate.toISOString().split("T")[0] : ""}
            max={maxDate ? maxDate.toISOString().split("T")[0] : ""}
            onChange={(e) =>
              props.handleInputChange(
                e.target.value,
                props.inputFieldSchema.BackendKey,
                props.customerIndex
              )
            }
            // min={new Date().toISOString().slice(0, 10)}
            value={props.customerData[props.inputFieldSchema.BackendKey]}
          />
        </div>
      );
    }
    case "toggleButton": {
      const options = Object.keys(
        props.dropdownCallMapping[props.inputFieldSchema.BackendKey]
      );
      return (
        <div
          className={`form-field__${props.inputFieldSchema.name} toggle-field form-field`}
        >
          {props.inputFieldSchema.isRequire && props.shouldShow && (
            <span className="required-indicator">*</span>
          )}
          <ToggleButton
            options={options}
            img1={[
              `${props.inputFieldSchema.img1}`,
              `${props.inputFieldSchema.img1}`,
            ]}
            img2={[
              `${props.inputFieldSchema.img2}`,
              `${props.inputFieldSchema.img2}`,
            ]}
            outSideSelected={
              props.customerData[props.inputFieldSchema.BackendKey]
            }
            getSelectedData={(value: any) =>
              props.handleInputChange(
                value,
                props.inputFieldSchema.BackendKey,
                props.customerIndex
              )
            }
          />
        </div>
      );
    }
  }

  return <div className="form-field__mobile">THis is Input Fields</div>;
}
