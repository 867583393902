import React, { useState } from "react";
import styles from "./CrmCustomerTableScreen.module.scss";
import { search } from "../../../../theme/assets/svg";
import {
  datas,
  bodyTransformer,
  headTranformer,
} from "./dataAndTableTranformer";
import SimpleTable, { headTransType } from "../simpleTable/SimpleTable";
import { useNavigate } from "react-router-dom";
import DropDownThin from "../dropdownThin/DropDownThin";
export default function CrmCustomerTableScreen() {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_top_search}>
        <p className={styles.title_para}>Customers</p>
        <div>
          <img src={search} alt="" />
          <input type="text" placeholder="Search" />
        </div>
      </div>

      {
        <SimpleTable
          dataToShowOnTable={datas}
          bodyTranformer={bodyTransformer}
          headingTranformer={headTranformer as headTransType}
          onClickOfRow={(data: any) => navigate("/customer")}
        />
      }
      <div className={styles.justify_limit}>
        <DropDownThin
          options={[5, 10, 15, 20]}
          setDataOut={setLimit}
          defaultSelected={10}
          openUp={false}
        />{" "}
        <p className={styles.textbottom}>Items per page</p>
      </div>
    </div>
  );
}
