// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WBB2VSn7swkTtXeVroNv{width:100%;height:100%;justify-content:center;align-items:center;display:flex;flex-direction:column}.WBB2VSn7swkTtXeVroNv .aE72jvFRORdxE0JFbJsg{width:100%;height:100%}.WBB2VSn7swkTtXeVroNv .b3NwASbDuBtv_Vr8KVJp{font-family:Roboto;font-size:13px;font-weight:400;line-height:19px;letter-spacing:0em}.WBB2VSn7swkTtXeVroNv .b3NwASbDuBtv_Vr8KVJp,.WBB2VSn7swkTtXeVroNv .bPLKBqDY2bzaXYos1Up8{width:100%;text-align:center}.WBB2VSn7swkTtXeVroNv .bPLKBqDY2bzaXYos1Up8{cursor:pointer;color:var(--primary-color)}`, "",{"version":3,"sources":["webpack://./src/components/agentDesktop/generic/noDatamodel/NoDatamodel.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,4CACE,UAAA,CACA,WAAA,CAEF,4CACE,kBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CAEF,wFAEE,UAAA,CACA,iBAAA,CAEF,4CACE,cAAA,CACA,0BAAA","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  .img {\n    width: 100%;\n    height: 100%;\n  }\n  .message {\n    font-family: Roboto;\n    font-size: 13px;\n    font-weight: 400;\n    line-height: 19px;\n    letter-spacing: 0em;\n  }\n  .message,\n  .a {\n    width: 100%;\n    text-align: center;\n  }\n  .a {\n    cursor: pointer;\n    color: var(--primary-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `WBB2VSn7swkTtXeVroNv`,
	"img": `aE72jvFRORdxE0JFbJsg`,
	"message": `b3NwASbDuBtv_Vr8KVJp`,
	"a": `bPLKBqDY2bzaXYos1Up8`
};
export default ___CSS_LOADER_EXPORT___;
