import navigationActionTypes from "../navigation.actionTypes";
export function getLeftsideModuleProfileDetails(payload: object) {
  return {
    type: navigationActionTypes.PROFILE_MODULE_GET_API_CALL,
    payload: payload,
  };
}
export function getDropDownHomeDetails(payload: object) {
  return {
    type: navigationActionTypes.DROPDOWN_HOME_GET_API_CALL,
    payload: payload,
  };
}
export function getDropDownSettingDetails(payload: object) {
  return {
    type: navigationActionTypes.DROPDOWN_SETTING_GET_API_CALL,
    payload: payload,
  };
}

export const setCurrentTeam = (teamData: Record<string, any>) => {
  return {
    type: navigationActionTypes.SET_CURRENT_TEAM_PROFILE,
    payload: teamData,
  };
};

//
