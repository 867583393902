import actionTypes from "../allIntegrations.actionTypes";

export const getAllIntegrations = (payload: object) => {
  return {
    type: actionTypes.GET_ALL_INTEGRATIONS_DATA_REQUEST,
    payload: payload,
  };
};

export const filterByCategory = (categoryName: string) => {
  return {
    type: actionTypes.FILTER_BY_CATEGORY_DATA,
    payload: categoryName,
  };
};

export const createIntegration = (integrationData: object) => {
  return {
    type: actionTypes.CREATE_INTEGRATION_DATA_REQUEST,
    payload: integrationData,
  };
};

export const updateIntegration = (integrationData: object) => {
  return {
    type: actionTypes.UPDATE_INTEGRATION_DATA_REQUEST,
    payload: integrationData,
  };
};

export const clearIntegration = () => {
  return {
    type: actionTypes.CLEAR_INTEGRATION_DATA,
  };
};

export const clearCreateIntegration = () => {
  return {
    type: actionTypes.CLEAR_CREATED_INTEGRATION_DATA,
  };
};

export const setSelectedIntegrationInfo = (payload: object) => {
  return {
    type: actionTypes.SET_SELECTED_INTEGRATION_DATA,
    payload: payload,
  };
};
