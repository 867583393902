import actionTypes from "../actionTypes";

export const getInvoicesAPIData = (payload: object) => {
  return {
    type: actionTypes.INVOICES_DATA_API_REQUEST,
    payload: payload,
  };
};

export const getDownloadInvoiceApiData = (payload: object) => {
  return {
    type: actionTypes.DOWNLOAD_INVOICES_API_REQUEST,
    payload: payload,
  };
};

export const clearDownloadInvoiceApiData = () => {
  return {
    type: actionTypes.CLEAR_DOWNLOAD_INVOICE,
  };
};

export const getSortedInvoicesAPIData = (payload: object) => {
  return {
    type: actionTypes.SORT_INVOICES_DATA,
    payload: payload,
  };
};
