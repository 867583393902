import React, { useState } from "react";
import { useEffect } from "react";
import "./TimePicker.css";
import clocktp from "../../../theme/assets/svg/generic/clocktp.svg";
import { useSelector } from "react-redux";
import { validateInputMeta } from "../../../utils/validators/validators";
import moment from "moment";
import _ from "lodash";
//usage
//<TimePicker twentyFourHour={true|false}/>

export default function TimePicker(props) {
  // all data
  const [data, setData] = useState(
    props.dataOutside
      ? props.dataOutside
      : {
          hasdata: true,
          am: true,
          hour: "9",
          minute: "00",
        }
  );

  //For Comparing the string format and data

  useEffect(() => {
    if (props.reportData?.hasdata) {
      if (_.isEqual(data, props?.reportData) === false) {
        props.reportData && setData(props.reportData);
      }
    }
  }, [props.reportData]);

  // console.log(props.reportData, data, "gg");

  // For Converting 12hr to 24hr string format
  let dt = moment(`${data?.hour}:${data?.minute} ${data?.am ? "AM" : "PM"}`, [
    "h:mm A",
  ]).format("HH:mm");

  // const storedDataScheduler = useSelector((store) =>
  //   props.isWhatsapp
  //     ? store?.omniChannelReducer?.storedschedulerSettingwhatsApp
  //     : store?.omniChannelReducer?.storedschedulerSettingSmsAndMail
  // );
  const storedDataScheduler = useSelector((store) =>
    props.isWhatsapp
      ? store?.omniChannelReducer?.storedschedulerSettingwhatsApp
      : props.channelData
  );
  // Abhishek sended outside data
  useEffect(() => {
    if (props.storedDataScheduler?.time) {
      setData((prev) => props.storedDataScheduler?.time);
    }
  }, [
    props.storedDataScheduler?.time?.am,
    props.storedDataScheduler?.time?.hour,
    props.storedDataScheduler?.time?.minute,
  ]);

  //
  const handleHourChange = (e) => {
    let val = parseInt(e.target.value);
    if (val <= 12 && val >= 0) {
      if (val > 12) {
        val = val % 12;
        if (val == 0) val = 1;
      }
      val = val + [];
      val = val.padStart(2, "0");

      setData({ ...data, hour: val });
    }
  };
  const handleMinChange = (e) => {
    if (!validateInputMeta(e.target.value)) return;
    let val = parseInt(e.target.value);
    if (val <= 60 && val >= 0) {
      if (val >= 60) {
        val = val % 60;
        if (val == 0) val = 0;
      }
      val = val + [];
      val = val.padStart(2, "0");

      setData({ ...data, hour: val });

      setData({ ...data, minute: val });
    }
  };
  const handleHourChange24 = (e) => {
    if (!validateInputMeta(e.target.value)) return;
    let val = parseInt(e.target.value);

    if (val <= 23) {
      if (val >= 24) {
        val = val % 24;
      }

      val = val + [];
      val = val.padStart(2, "0");

      setData({ ...data, hour: val });
    }
  };
  const handleMinChange24 = (e) => {
    if (!validateInputMeta(e.target.value)) return;
    let val = parseInt(e.target.value);
    if (val <= 59) {
      if (val >= 60) {
        val = val % 60;
        if (val == 0) val = 0;
      }
      val = val + [];
      val = val.padStart(2, "0");

      setData({ ...data, hour: val });

      setData({ ...data, minute: val });
    }
  };

  useEffect(() => {
    if (props.sendTime) {
      props?.sendTime(data);
    }
  }, [data]);

  // To send time in string format for report
  useEffect(() => {
    if (props.sendReportTime) {
      props?.sendReportTime(dt);
      props?.sendObjTime(data);
    }
  }, [dt, data]);

  return (
    <div
      className={`${
        props?.timePickerComponent ? props?.timePickerComponent : null
      } ${"timePickercont"}`}
      style={props?.twentyFourHour ? { width: "165px" } : { width: "227px" }}
    >
      <div
        // className={`${
        //   props?.twentyFourHour ? "time-select-sm" : "time-select"
        // }`}
        className={`${props?.twentyFourHour ? "timeSelectSm" : "timeSelect"} ${
          props?.timePickerInnerComponent
            ? props?.timePickerInnerComponent
            : null
        } ${props.hideBorder ? props.hideBorder : ""}`}
      >
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "7px",
            marginLeft: "0px",
          }}
        > */}
        {/* <img src={clocktp} alt="T" /> */}
        <img
          src={clocktp}
          alt="T"
          className={` ${props.timePickerIcon ? props.timePickerIcon : null}`}
        />
        {/* </div> */}
        <div className="inputGrp">
          {/* {console.log(("0" + data.hour).slice(-2), "hour")} */}
          <input
            type="number"
            onChange={(e) => {
              props?.twentyFourHour
                ? handleHourChange24(e)
                : handleHourChange(e);
              setData((prev) => {
                return { ...prev, hasdata: true };
              });
            }}
            className={`${data?.hasdata ? "hrselact" : "hrsel"} ${
              props.inputStyling ? props.inputStyling : null
            }`}
            value={("0" + data.hour).slice(-2)}
            onKeyDown={(e) =>
              [
                "<",
                ">",
                ";",
                ":",
                "?",
                "/",
                "\\",
                "%",
                "e",
                "E",
                "+",
                "-",
                ".",
              ].includes(e.key) && e.preventDefault()
            }
            // onWheel={(e) => {
            //   return e.target.blur();
            // }}
            // max="2"
            data-testid="hour"
          />
          <span className={`${props.spanStyling ? props.spanStyling : null} `}>
            :
          </span>
          <input
            type="number"
            onChange={(e) => {
              props?.twentyFourHour ? handleMinChange24(e) : handleMinChange(e);
              setData((prev) => {
                return { ...prev, hasdata: true };
              });
            }}
            className={`${data?.hasdata ? "minselact" : "minsel"} ${
              props.inputStyling ? props.inputStyling : null
            }`}
            value={data?.minute}
            onKeyDown={(e) =>
              [
                "<",
                ">",
                ";",
                ":",
                "?",
                "/",
                "\\",
                "%",
                "e",
                "E",
                "+",
                "-",
                ".",
              ].includes(e.key) && e.preventDefault()
            }
            // onWheel={(e) => {
            //   return e.target.blur();
            // }}
            // max="2"
            data-testid="minute"
          ></input>
        </div>
        {!props?.twentyFourHour && (
          <div className={`btngrp`}>
            <button
              onClick={() => {
                setData((prev) => {
                  return { ...prev, am: true, hasdata: true };
                });
              }}
              className={` ${
                data?.hasdata
                  ? data?.am
                    ? "ambtnact"
                    : "ambtninc"
                  : "ambtninc"
              }`}
            >
              AM
            </button>
            <button
              onClick={() => {
                setData((prev) => {
                  return { ...prev, am: false, hasdata: true };
                });
              }}
              className={` ${
                data?.hasdata
                  ? !data?.am
                    ? "pmbtnact"
                    : "pmbtninc"
                  : "pmbtninc"
              }`}
            >
              PM
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
