// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BXne29DcJwy2Z8zWeB3l{flex:1}.BXne29DcJwy2Z8zWeB3l .n7KH13V067VFpJIsuG61{font-size:12px;color:#6e7079;margin-bottom:5px}.BXne29DcJwy2Z8zWeB3l .ZPoKS7YWfEYuRpBSkcBk{font-size:14px;color:#333}`, "",{"version":3,"sources":["webpack://./src/components/generic/labelValue/LabelValue.module.scss"],"names":[],"mappings":"AAAA,sBACE,MAAA,CACA,4CACE,cAAA,CACA,aAAA,CACA,iBAAA,CAEF,4CACE,cAAA,CACA,UAAA","sourcesContent":[".labelValue {\n  flex: 1;\n  .label {\n    font-size: 12px;\n    color: #6e7079;\n    margin-bottom: 5px;\n  }\n  .value {\n    font-size: 14px;\n    color: #333333;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelValue": `BXne29DcJwy2Z8zWeB3l`,
	"label": `n7KH13V067VFpJIsuG61`,
	"value": `ZPoKS7YWfEYuRpBSkcBk`
};
export default ___CSS_LOADER_EXPORT___;
