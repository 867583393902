import React from "react";
import { useSelector } from "react-redux";
import Accord from "./Accord/Accord";

export default function PreferredTimeAccord(props) {
  const checkBoxChecked = (data) => {
    console.log(data);
  };

  const schedularDetails = useSelector((store) => {
    return store.campaignModuleReducer?.schedulerData?.getSchedularDetails;
  });

  const getPreferredTime = (checked) => {
    let data = { ...schedularDetails.dialtimeData.data };
    data.preferedTime = checked;
    props.storeSelectedDialTime(data);
  };
  return (
    <Accord
      title={"Preferred Time"}
      content={""}
      isChecked={false}
      isToggle={true}
      isTogglChecked={(checked) => {
        getPreferredTime(checked);
      }}
      isHideContent={true}
      isDisabled={true}
    />
  );
}
