import React, { ReactFragment } from "react";
import styles from "./LabelValue.module.scss";

interface props {
  label: string;
  value: string | any;
  extraClass?: string;
}

const LabelValue = (props: props) => {
  return (
    <div className={`${styles.labelValue} ${props.extraClass}`}>
      <div className={styles.label}>{props.label}</div>
      <div className={styles.value}>{props.value}</div>
    </div>
  );
};

export default LabelValue;
