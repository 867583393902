import React, { useCallback, useEffect } from "react";
import { usePubNub } from "../../hooks/useContextHooks";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootStore";
import { useNavigate } from "react-router-dom";
import { screenType } from "../../constants";

interface statusEventInterface {
  category: string;
}
interface messageEventInterface {
  message: { action: string; sessionId: string };
}

const GlobalPubNub = () => {
  /*****************************  reduxHooks  *****************************/
  const userId = useSelector(
    (state: RootState) => state.loginReducer.userLoginInfo?.userDetail?._id
  );
  const sessionId = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo?.userSessionDetails?.sessionId
  );
  /*****************************  otherHooks  *****************************/
  const navigate = useNavigate();
  const pubNub = usePubNub();

  /*****************************  handlers  *****************************/
  const handleStatusEvent = useCallback((statusEvent: statusEventInterface) => {
    if (statusEvent.category === "PNConnectedCategory") {
      console.info("PUBNUB Connected...");
    }
  }, []);

  const handleMessageEvent = useCallback(
    (messageEvent: messageEventInterface) => {
      console.info("pubNub message event", messageEvent);
      if (messageEvent?.message?.action === "LOGOUT") {
        if (messageEvent?.message?.sessionId !== sessionId) {
          navigate(screenType.singOut.routeName);
        }
      }
    },
    []
  );

  /*****************************  useEffect  *****************************/
  useEffect(() => {
    const listener = {
      status: handleStatusEvent,
      message: handleMessageEvent,
    };
    if (userId) {
      pubNub.subscribe({ channels: [userId] });
      pubNub.addListener(listener);
    }
    return () => {
      pubNub.removeListener(listener);
      pubNub.unsubscribeAll();
      pubNub.destroy();
    };
  }, [userId, pubNub]);

  /*****************************  JSX  *****************************/
  return <></>;
};

export default GlobalPubNub;
