// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RmzRm09MxPCndF65Fgyf .AUF8ngy0u11Akz5sDhvQ{width:auto;border-radius:8px;border:.5px solid #b6b6b6;background:#fff;color:#333;min-height:40px;display:flex;flex-direction:row;align-items:center;justify-content:center;padding:0em 1em;cursor:pointer}.RmzRm09MxPCndF65Fgyf .g2lSrEWICzNoPb8ziNHd{font-size:16px;color:#b6b6b6;font-family:"Roboto";display:flex;font-weight:400}.RmzRm09MxPCndF65Fgyf .DYvXodl47sLdtvyc6m8X{position:relative}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/campaign_V2.0/calendarComponent/CalendarComponent.module.scss"],"names":[],"mappings":"AAGE,4CACE,UAAA,CACA,iBAAA,CACA,yBAAA,CACA,eAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,cAAA,CAGF,4CACE,cAAA,CACA,aAAA,CACA,oBAAA,CACA,YAAA,CACA,eAAA,CAGF,4CACE,iBAAA","sourcesContent":[".calendarComponentWrapper {\n  // position: relative;\n\n  .dateFilterHeaderDiv {\n    width: auto;\n    border-radius: 8px;\n    border: 0.5px solid #b6b6b6;\n    background: #fff;\n    color: #333;\n    min-height: 40px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    padding: 0em 1em;\n    cursor: pointer;\n  }\n\n  .placeHolder {\n    font-size: 16px;\n    color: #b6b6b6;\n    font-family: \"Roboto\";\n    display: flex;\n    font-weight: 400;\n  }\n\n  .calendarWrapper {\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendarComponentWrapper": `RmzRm09MxPCndF65Fgyf`,
	"dateFilterHeaderDiv": `AUF8ngy0u11Akz5sDhvQ`,
	"placeHolder": `g2lSrEWICzNoPb8ziNHd`,
	"calendarWrapper": `DYvXodl47sLdtvyc6m8X`
};
export default ___CSS_LOADER_EXPORT___;
