export default {
  GET_PHONE_NUMBER_REQUEST: "GET_PHONE_NUMBER_REQUEST",
  GET_PHONE_NUMBER_SUCCESS: "GET_PHONE_NUMBER_SUCCESS",
  GET_PHONE_NUMBER_FAILURE: "GET_PHONE_NUMBER_FAILURE",

  REMOVE_PHONE_NUMBER_REQUEST: "REMOVE_PHONE_NUMBER_REQUEST",
  REMOVE_PHONE_NUMBER_SUCCESS: "REMOVE_PHONE_NUMBER_SUCCESS",
  REMOVE_PHONE_NUMBER_FAILURE: "REMOVE_PHONE_NUMBER_FAILURE",

  ADD_PHONE_NUMBER_REQUEST: "ADD_PHONE_NUMBER_REQUEST",
  ADD_PHONE_NUMBER_SUCCESS: "ADD_PHONE_NUMBER_SUCCESS",
  ADD_PHONE_NUMBER_FAILURE: "ADD_PHONE_NUMBER_FAILURE",

  ADD_PHONE_NUMBER_CSV_REQUEST: "ADD_PHONE_NUMBER_CSV_REQUEST",
  ADD_PHONE_NUMBER_CSV_SUCCESS: "ADD_PHONE_NUMBER_CSV_SUCCESS",
  ADD_PHONE_NUMBER_CSV_FAILURE: "ADD_PHONE_NUMBER_CSV_FAILURE",

  SET_SELECTED_ROWS_FROM_CHECKBOX: "SET_SELECTED_ROWS_FROM_CHECKBOX",

  SET_EXCLUSIONS_LIST_TYPE: "SET_EXCLUSIONS_LIST_TYPE",

  SET_CLEAR_ADD_PHONE_NUMBER_ERROR: "SET_CLEAR_ADD_PHONE_NUMBER_ERROR",
};
