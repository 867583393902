import React from "react";
import styles from "./AddOnsProgress.module.scss";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled, keyframes } from "@mui/system";

interface AddOnsProgressProps {
  planData: any;
}

const progressAnimationKeyframes = keyframes`
  0% {
    left: -35%;
    right: 100%;
  }
  100% {
    left: 0%;
    right: 0%;
  }
`;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "7px",
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#e6e6e6",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#06B464",
    animation: `${progressAnimationKeyframes} 1s linear forwards`,
  },
}));

export const AddOnsProgress = ({ planData }: AddOnsProgressProps) => {
  const calculateProgress = (num: number, den: number) => {
    const totalProgress = (num / den) * 100;
    return totalProgress;
  };

  return (
    <div className={styles.addOnDetailsWrapper}>
      {planData?.valueAddons?.map((data: any, index: number) => {
        return (
          <div className={styles.addOnDetailsDiv} key={index}>
            <div className={styles.addOnDetailsHeaderDiv}>
              {data?.valueName}
            </div>

            <BorderLinearProgress
              variant="determinate"
              value={calculateProgress(data?.usage, data?.quantity)}
            />

            <div className={styles.planDetailsNormal}>
              <span className={styles.planDetailsHighlighted}>
                {data?.usage}&nbsp;
              </span>
              of {data?.quantity}&nbsp;{data?.addonType}
            </div>
          </div>
        );
      })}
    </div>
  );
};
