function loadScript(src: string) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
async function DisplayRazorPay(orderData?: any, responseFunction?: any) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  console.log("oreder Detail", orderData);
  // creating a new order
  // Getting the order details back
  const { amount, orderId, currency } = orderData;

  const options = {
    key: "rzp_test_nIUDXiDnZiYLJC", // Enter the Key ID generated from the Dashboard
    amount: amount,
    currency: currency,
    name: "Saarthi.ai",
    description: "Test Transaction",
    image: "",
    order_id: orderId,
    handler: async function (response: any) {
      const data = {
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_order_id: response.razorpay_order_id,
        razorpay_signature: response.razorpay_signature,
      };

      //   const result = await axios.post(
      //     "http://localhost:5000/payment/success",
      //     data
      //   );

      //   alert(result.data.msg);
      responseFunction(data);
    },
    // prefill: {
    //   name: "Abhishek Nayak",
    //   email: "abhishek.nayak@saarthi.ai",
    //   contact: "9876543215",
    // },
    notes: {
      address: "Saarthi.ai",
    },
    // theme: {
    //   color: "#61dafb",
    // },
  };

  const paymentObject = new (window as any).Razorpay(options);
  paymentObject.open();
}

export default DisplayRazorPay;
