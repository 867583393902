import React from "react";
import styles from "./ReportRuleModal.module.scss";
import Modal from "../../generic/modal/Modal";
import Button from "../../generic/button/Button";
import { TagsInput } from "../../generic/tagsInput/TagsInput";
import { crossIcon } from "../../../theme/assets/profileSectionSvg";

interface ReportRuleModal {
  show?: boolean;
  close?: CallableFunction;
  email?: any;
  selectedTags?: any;
  tags?: any;
  onclick?: CallableFunction;
  deletePopUp?: boolean;
  emailPopUp?: boolean;
  updatePopUp?: boolean;
  ruleName?: string;
  disabled?: boolean;
  errorTags?: { repeated: boolean; invalid: boolean };
}
export const ReportRuleModal = ({
  show,
  close,
  email,
  selectedTags,
  tags,
  onclick,
  deletePopUp,
  emailPopUp,
  updatePopUp,
  ruleName,
  errorTags,
  disabled,
}: ReportRuleModal) => {
  return (
    <div>
      <Modal
        shown={show}
        close={() => {
          close && close();
        }}
        extraClassStyleModalContent={styles.ModalEmailContent}
      >
        <div className={styles.closeIcon}>
          <img
            src={crossIcon}
            onClick={() => {
              close && close();
            }}
            className={styles.IconStyling}
          />
        </div>
        <div className={styles.modalPopUp}>
          {deletePopUp ? (
            <div data-testid="delete-modal">
              <p
                className={`${styles.warningPopUpTextInfo} ${styles.inputModalTextStyling}`}
              >
                Do you really want to delete {ruleName} ?
              </p>
            </div>
          ) : updatePopUp ? (
            <div data-testid="update-modal">
              <p
                className={`${styles.warningPopUpTextInfo} ${styles.inputModalTextStyling}`}
              >
                Are you sure you want to update the existing rule ?
              </p>
            </div>
          ) : emailPopUp ? (
            <div data-testid="email-modal">
              <p
                className={`${styles.emailPopUpTextHeading} ${styles.inputModalTextStyling} `}
              >
                Are you sure you want to proceed with the following emails :
              </p>

              <div className={`${styles.emailPopUpInfo}`}>
                <TagsInput
                  validEmailDomain={email}
                  selectedTags={selectedTags}
                  tags={tags}
                  placeholder={"Enter emails"}
                  extraClass={styles.emailPopUpTextInfo}
                />
              </div>
              {errorTags && errorTags.repeated ? (
                <p className={styles.errorMessage}>Email entered is repeated</p>
              ) : null}
              {errorTags && errorTags.invalid ? (
                <p className={styles.errorMessage}>
                  Report can be shared only to Email IDs from your company
                </p>
              ) : null}
            </div>
          ) : (
            <div className={styles.frequencyDiv}>
              <p
                className={`${styles.warningPopUpTextHeading} ${styles.inputModalTextStyling}`}
              >
                A rule with the same Frequency, Time &amp; Report type already
                exists.
              </p>
              <p
                className={`${styles.warningPopUpTextInfo} ${styles.inputModalTextStyling}`}
              >
                Do you want to overwrite the existing rule ?
              </p>
            </div>
          )}
          <div className={styles.buttonDiv}>
            <Button
              text="Yes"
              extraClass={`${styles.buttonStyling} ${styles.yesButtonStyle}`}
              onClick={onclick}
              disabled={disabled}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
