// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c1mJESBSSXNHSV7d4Zq8{padding:0 4%;display:flex;justify-content:center;align-items:center}.c1mJESBSSXNHSV7d4Zq8 .VJ735Vtn3jPStAwS8efJ{font-family:"Roboto";font-style:normal;font-weight:500;font-size:21px;line-height:23px;text-align:center;letter-spacing:.157331px;color:#333}`, "",{"version":3,"sources":["webpack://./src/components/agentDesktop/moduleComponents/counter/CounterComponent.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,4CAEI,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,wBAAA,CACA,UAAA","sourcesContent":[".cardDataWrapper {\n    padding: 0 4%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    .cardValueDiv {\n        // width: 90%;\n        font-family: 'Roboto';\n        font-style: normal;\n        font-weight: 500;\n        font-size: 21px;\n        line-height: 23px;\n        text-align: center;\n        letter-spacing: 0.157331px;\n        color: #333333;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardDataWrapper": `c1mJESBSSXNHSV7d4Zq8`,
	"cardValueDiv": `VJ735Vtn3jPStAwS8efJ`
};
export default ___CSS_LOADER_EXPORT___;
