import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux";
import styles from "./AddonsScreen.module.scss";
import { getCurrentTab } from "../../../../redux/profile/yourAccount/organizationDetails/actions/organizationDetails.actions";
import AddonComponent from "../../../../components/moduleComponents/paymentModuleComponents/addonComponent/AddonComponent";
import AddonOrderCart from "../../../../components/moduleComponents/paymentModuleComponents/addonOrderCart/AddonOrderCart";
import { useNavigate } from "react-router-dom";

// type Props = {}

const AddonsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedPurchasePlan: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.pricingPlan?.pricingPlanDetails?.data
        ?.planData
  );
  const mySelectedPlan: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  const allDetailsPlans = selectedPurchasePlan?.filter(
    (e: any) => e?.planName === mySelectedPlan?.planName
  );

  return (
    <div className={styles.addonScreenWrapper}>
      <div className={styles.orderScreenHeader}>
        <div className={styles.orderScreenHeaderText}>Billing</div>
      </div>
      <div className={styles.orderSummaryScreen}>
        <div className={styles.plansummaryClass}>
          <AddonComponent
            data={allDetailsPlans}
            onClickDelete={() => {
              dispatch(getCurrentTab("My Plan"));
              navigate("/profile/billing/invoices");
            }}
            addon={allDetailsPlans?.[0]?.addons}
          />
        </div>
        <div className={styles.ordersummaryClass}>
          <AddonOrderCart />
        </div>
      </div>
    </div>
  );
};

export default AddonsScreen;
