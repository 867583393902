import React, { forwardRef } from "react";
import styles from "./InputField.module.scss";
import { bar } from "../../../theme/assets/genericSvg";
import ErrorMessage from "../errorMessage/ErrorMessage";

interface InputProps {
  disabled?: any;
  accept?: string;
  className?: string;
  label?: any;
  placeholder?: string;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void | any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | any;
  value?: any;
  extraClass?: any;
  extraLabelClass?: any;
  ref?: any;
  onClick?: any;
  onFocus?: any;
  onBlur?: any;
  maxLength?: any;
  name?: string;
  type?: string;
  dataTestId?: string;
  checked?: boolean;
  maxLimit?: string | number;
  minLimit?: string | number;
  rightSideText?: string | number;
  rightSideTextClass?: string | number;
  onKeyDown?: (event: any) => void | any;
  id?: string;
  inputIcon?: string;
  autoComplete?: string;
  required?: boolean;
  errorMsg?: string;
}

const InputField = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    id,
    className,
    label,
    placeholder,
    onChange,
    onInput,
    onClick,
    value,
    accept,
    disabled,
    extraClass,
    extraLabelClass,
    onFocus,
    onBlur,
    maxLength,
    name,
    type,
    checked,
    maxLimit,
    minLimit,
    rightSideText,
    rightSideTextClass,
    onKeyDown,
    inputIcon,
    autoComplete,
    required,
    errorMsg,
  } = props;
  return (
    <div
      className={extraClass ? extraClass : styles.inputDiv}
      data-testid="form-field"
    >
      <label
        data-testid="label-id"
        className={extraLabelClass ? extraLabelClass : styles.labelText}
      >
        {label}
      </label>

      {inputIcon && (
        <img src={inputIcon} alt={label} id={`auto_${inputIcon}`} />
      )}

      <input
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (onKeyDown) onKeyDown(event);
        }}
        id={id}
        data-testid={props.dataTestId || "input-field"}
        ref={ref}
        disabled={disabled}
        accept={accept}
        className={`${className} ${styles.inputTextStyle}`}
        type={type}
        placeholder={placeholder}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (rightSideText && maxLimit && minLimit) {
            if (event.target.value > maxLimit) return;
            else if (event.target.value < minLimit) return;
          }
          if (onChange) {
            onChange(event);
          }
        }}
        onInput={onInput}
        onClick={onClick}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={maxLength}
        name={name}
        checked={checked}
        autoComplete={autoComplete}
        required={required}
      />
      {rightSideText && (
        <div className={styles.rightside_text_div}>
          <img src={bar} alt="|" />
          <p
            className={
              rightSideTextClass ? rightSideTextClass : styles.rightside_text
            }
          >
            {rightSideText}
          </p>
        </div>
      )}
      {errorMsg && (
        <div className={styles.errorMsgStyling}>
          <ErrorMessage errorMessage={errorMsg} />
        </div>
      )}
    </div>
  );
});

InputField.displayName = "InputField";

export default InputField;
