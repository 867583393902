import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./TableSaarthiJp.scss";
import LoaderSaarthi from "../../../generic/loader/Loader";
import CustomBodySkeleton from "../../../generic/customSkeleton/CustomBodySkeleton";


interface props {
  cols: any;
  data: any;
  idOutside?: any;
  bordered?: any;
  hoverable?: any;
  striped?: any;
  isDark?: any;
  pageNo?: any;
  isLoading?: any;
  extraTableClass?: any;
  extraTableTheadClass?: any;
  theadTrExtraStyle?: any;
  trThExtraStyle?: any;
  tbodytrExtraStyle?: any;
  tbodytrtdExtraStyle?: any;
  selectedCampaignStyling?: string;
  extraStyleTableDiv?: any;
  noDataUploaded?: any;
  hideBorderArray?: any;
}

const TableByJp = (
  {
    cols,
    data,
    idOutside,
    bordered,
    hoverable,
    striped,
    isDark,
    pageNo,
    isLoading,
    extraTableClass,
    extraTableTheadClass,
    theadTrExtraStyle,
    trThExtraStyle,
    tbodytrExtraStyle,
    tbodytrtdExtraStyle,
    selectedCampaignStyling,
    extraStyleTableDiv,
    noDataUploaded,
    hideBorderArray,
  }: props,
  props: props
) => {
  const [tableData, setTableData] = useState<any>();
  useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <div
      className={`new-repsonsive-table ${
        extraStyleTableDiv ? extraStyleTableDiv : ""
      }`}
    >
      <table
        className={`table ${bordered ? "table-bordered" : "table-borderless"} ${
          hoverable && "table-hover"
        } ${striped && "table-striped"} ${isDark && "table-dark"}
        ${extraTableClass ? extraTableClass : ""} 
        `}
        id={`${idOutside ? idOutside : ""}`}
      >
        <thead
          className={`saarthiTableThead ${
            extraTableTheadClass ? extraTableTheadClass : ""
          }`}
        >
          <CustomBodySkeleton
            isLoading={!isLoading}
            width={"100%"}
            animation="wave"
          >
          <tr className={`${theadTrExtraStyle ? theadTrExtraStyle : ""}`}>
            {cols.map((headerItem: any, index: any) => (
              <th
                className={`${trThExtraStyle ? trThExtraStyle : ""}`}
                key={index}
              >
                {headerItem.title}
              </th>
            ))}
          </tr>
          </CustomBodySkeleton>
        </thead>
        {/* {isLoading ? ( */}
          <tbody>
            {tableData?.map((item: any, index: any) => (
               <CustomBodySkeleton
               isLoading={!isLoading}
               width={"100%"}
               animation="wave"
             >
              <tr
                key={index}
                className={`${tbodytrExtraStyle ? tbodytrExtraStyle : ""} ${
                  hideBorderArray?.includes(item?.id) ||
                  item?.isCampaign ||
                  item?.isChildCampaign
                    ? "borderforOmni"
                    : "borderforomnitop"
                } 
                ${
                  tableData?.length - 1 === index
                    ? "borderforomniLastcampaign"
                    : null
                }`}
              >
                {cols.map((col: any, key: any) => (
                  <td
                    className={`${
                      tbodytrtdExtraStyle ? tbodytrtdExtraStyle : ""
                    } ${
                      !item?.isParent && selectedCampaignStyling
                        ? selectedCampaignStyling
                        : ""
                    }`}
                    key={key}
                  >
                    {col.render(item, index, pageNo)}
                  </td>
                ))}
              </tr>
              </CustomBodySkeleton>
            ))}
          </tbody>
        
        {/* : (
          <>
            <div style={{ display: "none" }}>
              <LoaderSaarthi />
            </div>
          </>
        )} */}
      </table>
      {isLoading && tableData?.length === 0 && (
        // props.imgNoData ?
        <div style={{ textAlign: "center", position: "relative", top: "40%" }}>
          {noDataUploaded ? noDataUploaded : "No Data Found"}
        </div>
        // : <img src= {props.imgNoData} alt="img"></img>
      )}
      {/* {!isLoading && (
        <div style={{ marginLeft: "Auto", marginRight: "auto" }}>
          <LoaderSaarthi />
        </div>
      )} */}
    </div>
  );
};

TableByJp.propTypes = {
  cols: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  bordered: PropTypes.bool,
  hoverable: PropTypes.bool,
  striped: PropTypes.bool,
  isDark: PropTypes.bool,
};

TableByJp.defaultProps = {
  bordered: true,
  hoverable: false,
  striped: false,
  isDark: false,
};

export default TableByJp;
