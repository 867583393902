import React, { useEffect, useState } from "react";
import "../../configurecampaign/ConfigureCampaign.css";
import languageIcon from "../../../../../theme/assets/svg/demo/template.svg";
import dateRangeIcon from "../../../../../theme/assets/svg/demo/clockOmni.svg";
import EditIcon from "../../../../../theme/assets/svg/demo/editIcon.svg";
import channelIcon from "../../../../../theme/assets/svg/demo/channelIcon.svg";
import campaignNameIcon from "../../../../../theme/assets/svg/demo/campaignNameIcon.svg";
import whatsAppActive from "../../../../../theme/assets/svg/campaign/whatsAppActive.svg";
import emailunActive from "../../../../../theme/assets/svg/campaign/emailunActive.svg";
import smsunActive from "../../../../../theme/assets/svg/campaign/smsunActive.svg";
import dateActive from "../../../../../theme/assets/svg/demo/dateIcon.svg";
import clockActive from "../../../../../theme/assets/svg/demo/clockActive.svg";
import MultiSelectDropdown from "../../schedulerAllComponent/MultiSelectDropdown";
import MultiSelectDrop from "../multiDropdownOmni/MultiSelectDropdown";
import downArrow from "../../../../../theme/assets/svg/campaign/dropdownIconDown.svg";
import moment from "moment";
import ChannelOmni from "../channelBtnOmni/channelOmni";
import Button from "../../../../generic/button/Button";
import "./ChannelSummary.css";
import { useSelector } from "react-redux";
import {
  setSchedulerPhase,
  storeSelectedBreadcrum,
  storeSelectedstep,
} from "../../../../../redux/breadcrum/actions";
import { isArray } from "lodash";
import { validateKeys } from "../../../../../utils/validators/validators";
import { useDispatch } from "react-redux";
import { RootState } from "../../../../../redux";
import { sendWhatsappDetails } from "../../../../../redux/breadcrum/actions";

function ChannelSummary(props: any) {
  const [selectCommunication, setSelectCommunication] = useState();
  const [selectTemplate, setSelectTemplate]: any = useState();
  const [newCampaignName, setNewCampaignName] = useState("");
  const [errMessageCamp, setErrMessageCamp] = useState();
  const [selectedChannel, setSelectedChannel] = useState([""]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [whatsappChannelData, setWhatsappChannelData]: any = useState([]);

  const dispatch = useDispatch();

  const breadCrumData = useSelector((store: RootState) => {
    return store.breadcrumReducer.breadCrumData;
  });

  const loginDetails = useSelector((store: RootState) => {
    return store.loginReducer;
  });

  // const templateOptions = {
  //   imgSrcRight: downArrow,
  //   imgSrcleft: "",
  //   // placeHolderText:  selectedLanguage?.length ?  selectedLanguage?.[0]+(selectedLanguage?.length>1 ? ("+".concat(selectedLanguage?.length-1)) : ""): "Select"
  //   placeHolderText: "Select Template",
  // };

  const langOptions = {
    imgSrcRight: downArrow,
    imgSrcleft: "",
    placeHolderText: props.language?.length
      ? props.language > 16
        ? props.language.substring(1, 8)
        : props.language
      : "Select Language",
  };
  const onclickGoBack = () => (
    dispatch(storeSelectedstep(breadCrumData[0])), props.onEdit()
  );

  // const omniChannelDetails = useSelector((store: RootState) => {
  //   return store.omniChannelReducer.storedschedulerSettingSmsAndMail;
  // });

  const campaignData = useSelector((store: RootState) => {
    return store.campaignReducer.campaignAllCampaignChannelData;
  });

  const summaryPhase = useSelector((store: RootState) => {
    return store.breadcrumReducer.selectedPhase;
  });

  useEffect(() => {
    setSelectCommunication(props.omniChannelDetails?.communication);
    setSelectTemplate(props.omniChannelDetails?.template);
  }, [props.omniChannelDetails]);

  useEffect(() => {
    if (campaignData?.length > 0) {
      campaignData?.map((er: any) => {
        if (isArray(er?.channels) && er?.channels?.[0] == props.channel) {
          setSelectedChannel([er.channels[0]]);
          setNewCampaignName(er?.campaignName);
          setSelectedLanguage(er?.language);
          setWhatsappChannelData(er);
        }
      });
    }
  }, [campaignData]);

  const templateOptions = {
    imgSrcRight: downArrow,
    imgSrcleft: "",
    // placeHolderText:  selectedLanguage?.length ?  selectedLanguage?.[0]+(selectedLanguage?.length>1 ? ("+".concat(selectedLanguage?.length-1)) : ""): "Select"
    placeHolderText: selectTemplate?.length
      ? selectTemplate?.toString()?.length > 16
        ? selectTemplate[0].length > 16
          ? selectTemplate[0].substring(0, 16) + "..."
          : selectTemplate[0]
        : selectTemplate
      : "Select Template",
  };

  const sendDetails = () => {
    const tokenZx =
      loginDetails?.userLoginInfo?.userSessionDetails?.accessToken;
    const payload = {
      campaignId: whatsappChannelData?.id,
      channel: whatsappChannelData?.channels?.[0].toLowerCase(),
      preferredTime: false,
      dialTimeData: {
        languages: whatsappChannelData?.language,
        agent_type: whatsappChannelData?.campaignType[0],
        communication: props.omniChannelDetails?.communication,
        templates: props.omniChannelDetails?.templatesId,
        mindmap_url: props.omniChannelDetails?.mindMapLink,
        schedule_date_start: props.omniChannelDetails?.startDate,
        schedule_date_end: props.omniChannelDetails?.endDate,
        schedule_time: props.omniChannelDetails?.time?.am
          ? Number(props.omniChannelDetails.time.hour) * 60 +
            Number(props.omniChannelDetails.time.minute)
          : (Number(props.omniChannelDetails.time.hour) + 12) * 60 +
            Number(props.omniChannelDetails.time.minute),
        templateName: props.omniChannelDetails?.template,
      },
    };
    sendWhatsappDetails(tokenZx, payload)(dispatch);
  };

  return (
    <div className="channelOmniWrapperModal">
      <div className="OuterDiv">
        <div className="HeaderDiv">
          <div className="header-name">{`${props.channel} Campaign Summary`}</div>
          <div className="edit-icon">
            <img
              src={EditIcon}
              onClick={() => {
                // props.hideSummaryScreen(false)
                onclickGoBack();
              }}
            />
          </div>
        </div>

        <div className="ContainDiv">
          <div className="logoNameDiv">
            <img src={campaignNameIcon} width={"50px"} alt="img"></img>
            <h2 className="heading">Campaign Name:</h2>
          </div>
          <div className="heightCampName">
            <input
              type="text"
              className={`inputNameCamp ${
                !props.campaignName?.length ? "colorPlaceholder" : ""
              }`}
              placeholder="Campaign Name"
              value={props.campaignName}
              disabled={true}
              onKeyDown={validateKeys}
            />
          </div>
        </div>
        <div className="ContainDiv">
          <div className="logoNameDiv">
            <img src={channelIcon} width={"50px"} alt="img"></img>
            <h2 className="heading">Channels:</h2>
          </div>
          <div className="dropDownDiv dropDownDivChannel">
            {/* {arrChannelOmni?.map((e) => {
              return ( */}
            {/* selectedChannel?.includes(e.name?.toLowerCase()) && ( */}
            <div className="channelBtns">
              <ChannelOmni
                name={props.channel}
                imgActive={whatsAppActive}
                imgUnActive={
                  props.channel === "SMS" ? smsunActive : emailunActive
                }
                // onselect={clickedBtn}
                //   outSideSelect={selectedChannel?.includes("WhatsApp")}
                disable={true}
              />
            </div>
          </div>
        </div>
        <div className="ContainDiv">
          <div className="logoNameDiv">
            <img src={languageIcon} width={"50px"} alt="img"></img>
            <h2 className="heading">Language:</h2>
          </div>
          <div className="dropDownDiv">
            <MultiSelectDropdown
              options={langOptions}
              toBeFilterData={["abhishek", "sss"]}
              extraSelectedClass="languageDropdown"
              // getFilteredData={(value) => onChangeselectTemplate(value)}
              key="dispositionMultiSelectOne"
              selectedDataOutside={props.language}
              extraPlaceHolderStyle="placeholderMultiStyleAgent"
              isDisable={true}
            />
          </div>
        </div>

        {/* <div className="ContainDiv">
          <div className="logoNameDiv">
            <img src={campaignTypeicon} width={"50px"} alt="img"></img>
            <h2 className="heading">Communication</h2>
          </div>
          <div className="dropDownDiv">
            {selectCommunication == "One way" && (
              <div
                className={
                  selectCommunication == "One way"
                    ? "staticDiv"
                    : "staticDivHuman"
                }
                // onClick={() => onChangeCommunication("One way")}
              >
                <p>One way</p>
              </div>
            )}
            {selectCommunication == "Two way" && (
              <div
                className={`${
                  selectCommunication == "Two way"
                    ? "staticDiv"
                    : "staticDivHuman"
                } gapBtn `}
                // onClick={() => onChangeCommunication("Two way")}
              >
                <p>Two way</p>
              </div>
            )}
          </div>
        </div> */}
        <div className="ContainDiv">
          <div className="logoNameDiv">
            <img src={languageIcon} width={"50px"} alt="img"></img>
            <h2 className="heading">Template</h2>
          </div>
          <div className="dropDownDiv">
            <MultiSelectDrop
              options={templateOptions}
              toBeFilterData={["abhishek", "sss"]}
              extraSelectedClass="languageDropdown"
              // getFilteredData={(value) => onChangeselectTemplate(value)}
              key="dispositionMultiSelectOne"
              selectedDataOutside={selectTemplate}
              extraPlaceHolderStyle="extraPlaceHolderStyle"
              isDisable={true}
            />
          </div>
        </div>
        <div className="ContainDiv">
          <div className="logoNameDiv">
            <img src={dateRangeIcon} width={"50px"} alt="img"></img>
            <h2 className="heading">When to Schedule</h2>
          </div>
          <div className="dropDownDiv">
            <div className="midDate time-clr">
              <img src={dateActive} />
              &nbsp;&nbsp;
              {moment(props.date?.startDate).format("MMM DD") +
                " - " +
                moment(props.date?.endDate).format("MMM DD")}
            </div>
            <div className={"midDate gapBtn time-clr"}>
              <img src={clockActive} />
              &nbsp;
              {props?.time.hour + " " + ":" + " " + props?.time.minute}{" "}
              {props?.time.am ? "AM" : "PM"}
            </div>
          </div>
        </div>
      </div>

      <div className="buttonSubmit">
        <Button
          text="Submit"
          extraClass={"submitOmni"}
          // onClick={() => props.onSubmitSummary()}
          onClick={() => {
            const data = summaryPhase;
            data?.[props.channel]?.push("Scheduler Settings");
            sendDetails();
            setSchedulerPhase(data)(dispatch);
            storeSelectedBreadcrum("Data Upload")(dispatch);
          }}
        />
      </div>
    </div>
  );
}

export default ChannelSummary;
