// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CGN7BQ1klXiioEcaKkQy{width:100%}.CGN7BQ1klXiioEcaKkQy .hRVYnYpYOWUUWzv9oXM6{padding:3px;width:100%;display:flex;align-items:center;color:#07b464;justify-content:space-around;border:.5px solid #07b464;border-radius:16px;white-space:nowrap}.CGN7BQ1klXiioEcaKkQy .A550xWTiUfHEC2AvxQdO{width:auto}.CGN7BQ1klXiioEcaKkQy .ihayLA3qXT1Bf_c3BtLv{font-weight:400;font-size:14px}.CGN7BQ1klXiioEcaKkQy .sCfAGiv_vo_K3L1Uwyoh{cursor:pointer}.CGN7BQ1klXiioEcaKkQy .GcC1n4TI1yBKjhg8AdFh{padding-right:.25em;width:1.25em}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/campaign_V2.0/CampaignTableComponent/statusButton/StatusButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAEA,4CACE,WAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,aAAA,CACA,4BAAA,CACA,yBAAA,CACA,kBAAA,CACA,kBAAA,CAGF,4CACE,UAAA,CAGF,4CACE,eAAA,CACA,cAAA,CAEF,4CACE,cAAA,CAGF,4CACE,mBAAA,CACA,YAAA","sourcesContent":[".statusButtonWrapper {\n  width: 100%;\n\n  .styleOuter {\n    padding: 3px;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    color: #07b464;\n    justify-content: space-around;\n    border: 0.5px solid #07b464;\n    border-radius: 16px;\n    white-space: nowrap;\n  }\n\n  .noOutline {\n    width: auto;\n  }\n\n  .styleName {\n    font-weight: 400;\n    font-size: 14px;\n  }\n  .styleImg {\n    cursor: pointer;\n  }\n\n  .styleInfoImg {\n    padding-right: 0.25em;\n    width: 1.25em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusButtonWrapper": `CGN7BQ1klXiioEcaKkQy`,
	"styleOuter": `hRVYnYpYOWUUWzv9oXM6`,
	"noOutline": `A550xWTiUfHEC2AvxQdO`,
	"styleName": `ihayLA3qXT1Bf_c3BtLv`,
	"styleImg": `sCfAGiv_vo_K3L1Uwyoh`,
	"styleInfoImg": `GcC1n4TI1yBKjhg8AdFh`
};
export default ___CSS_LOADER_EXPORT___;
