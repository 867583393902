export default {
  SET_CHANNEL_BY_NAV: "SET_CHANNEL_BY_NAV",
  SET_SCHEDULERSETTING_WHATSAPP: "SET_SCHEDULERSETTING_WHATSUP",
  SET_SCHEDULERSETTING_SMS: "SET_SCHEDULERSETTING_SMS",
  SET_SCHEDULERSETTING_MAIL: "SET_SCHEDULERSETTING_MAIL",
  SET_TEMPLATE_SUCCESS: "SET_TEMPLATE_SUCCESS",
  SET_TEMPLATE_FAILURE: "SET_TEMPLATE_FAILURE",
  SET_TEMPLATE_REQUEST: "SET_TEMPLATE_REQUEST",
  SET_TEMPLATE_DROPDOWN: "SET_TEMPLATE_DROPDOWN",
  SET_MINDMAP: "SET_MINDMAP",
  SET_DCBDATA_WHATSAPP: "SET_DCBDATA_WHATSAPP",
  SET_DCBDATA_WHATSAPP_FLOWUP: "SET_DCBDATA_WHATSAPP_FLOWUP",
  SET_SELECTED_DISPOSITION_DATAWP: "SET_SELECTED_DISPOSITION_DATAWP",
  SET_SEPARATE_SELECTED_DATAWP: "SET_SEPARATE_SELECTED_DATAWP",
  SET_SELECTED_DISPOSITION_DATA_CHILDWP:
    "SET_SELECTED_DISPOSITION_DATA_CHILDWP",
  SET_SHUFFLED_CONNECTED_SUCCEDING_DATAWP:
    "SET_SHUFFLED_CONNECTED_SUCCEDING_DATAWP",
  SET_SHUFFLED_NOTCONNECTED_SUCCEDING_DATAWP:
    "SET_SHUFFLED_NOTCONNECTED_SUCCEDING_DATAWP",
  SET_NUMBER_OF_FOLLOWUP: "SET_NUMBER_OF_FOLLOWUP",
};
