import { call, put } from "redux-saga/effects";
import { PRICING_PLAN_API, config } from "../../../../../services/ApiRoutes";
import actionTypes from "../actionTypes";
import _ from "lodash";
import { toast } from "react-toastify";

export function* getInvoicesDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${PRICING_PLAN_API.GET_INVOICES_API}`,
        {
          params: _.omit(action.payload, "token"),
          headers: { "x-access-token": action.payload.token },
        }
      );
      yield put({
        type: actionTypes.INVOICES_DATA_API_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error: any) {
    console.error(error);
    yield put({
      type: actionTypes.INVOICES_DATA_API_FAILURE,
      payload: error.response?.data?.error?.message,
    });
  }
}

export function* getDownloadAPIWorker(action: any): any {
  toast.dismiss();
  toast.loading("Please wait ...");
  try {
    if (action) {
      yield put({
        type: actionTypes.CLEAR_DOWNLOAD_INVOICE,
      });
      const result = yield call(
        config.GET_WITH_PARAMS_RES_TYPE,
        `${PRICING_PLAN_API.DOWNLOAD_INVOICE_API}`,
        { params: { id: action.payload.id }, responseType: "blob" }
      );

      const file = new Blob([result.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      yield put({
        type: actionTypes.DOWNLOAD_INVOICES_API_SUCCESS,
        payload: { url: fileURL, isDownload: action.payload.isDownload },
      });

      if (action.payload.isDownload === true) {
        // Create a link element
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", `${action.payload.id}.pdf`);

        // Programmatically trigger the click event on the link
        link.click();

        // Clean up the temporary URL and remove the link from the DOM
        URL.revokeObjectURL(fileURL);
        link.parentNode?.removeChild(link);
        yield put({
          type: actionTypes.CLEAR_DOWNLOAD_INVOICE,
        });
      }
    }
    toast.dismiss();
  } catch (error: any) {
    console.error(error);
    toast.dismiss();
    toast.error(error.response?.data?.error?.message);
    yield put({
      type: actionTypes.DOWNLOAD_INVOICES_API_FAILURE,
      payload: error.response?.data?.error?.message,
    });
  }
}

export function* getSortedInvoicesDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${PRICING_PLAN_API.GET_INVOICES_API}`,
        {
          params: _.omit(action.payload, "token"),
          headers: { "x-access-token": action.payload.token },
        }
      );
      yield put({
        type: actionTypes.INVOICES_DATA_API_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error: any) {
    console.error(error);
    yield put({
      type: actionTypes.INVOICES_DATA_API_FAILURE,
      payload: error.response?.data?.error?.message,
    });
  }
}
