import React from "react";
import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { refreshTokenApiRequest } from "../redux/onboarding/login/actions";
import { ACCESS_TOKEN_REFRESH_TIME } from "../constants";
import { RootState } from "../redux";

const RouterUtil = () => {
  const userId = useSelector(
    (state: RootState) => state.loginReducer?.userLoginInfo?.userDetail?._id
  );
  const dispatch = useDispatch();

  /****************** JWT token & refresh token ***********************/
  const jwt_Token = secureLocalStorage.getItem("jwt_token");
  if (jwt_Token && userId) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${jwt_Token}`;
  }
  /*****************************  useEffects  *****************************/
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    let interval: ReturnType<typeof setInterval>;

    if (jwt_Token && userId) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const jwtTokenTime = secureLocalStorage.getItem("jwt_token_time")!;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const refresh_token = secureLocalStorage.getItem("refresh_token")!;
      const diff = moment().diff(moment(jwtTokenTime));
      const timeSpan = ACCESS_TOKEN_REFRESH_TIME;

      timeout = setTimeout(() => {
        dispatch(
          refreshTokenApiRequest({
            userId,
            refreshToken: refresh_token as string,
          })
        );
        interval = setInterval(() => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const refresh_token = secureLocalStorage.getItem("refresh_token")!;
          dispatch(
            refreshTokenApiRequest({
              userId,
              refreshToken: refresh_token as string,
            })
          );
        }, timeSpan);
      }, Math.max(timeSpan - diff, 0));
      // axios response interceptors to redirect on token expire implemented in AxiosUtil.tsx
    }
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [userId]);

  return <></>;
};

export default RouterUtil;
