// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.waPHkQ8LGccUK6iddIMJ{position:fixed;left:0;top:0;z-index:9999;width:100%;height:100%;background-color:#000;background-color:rgba(0,0,0,.4);display:flex !important;justify-content:center !important;align-items:center !important}.WKClq4a_BZOYxtZ3DWSg{background-color:#fff;padding:25px}`, "",{"version":3,"sources":["webpack://./src/components/generic/modal/Modal.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,MAAA,CACA,KAAA,CACA,YAAA,CACA,UAAA,CACA,WAAA,CAEA,qBAAA,CACA,+BAAA,CACA,uBAAA,CACA,iCAAA,CACA,6BAAA,CAGF,sBAGE,qBAAA,CACA,YAAA","sourcesContent":[".modalBackdrop {\n  position: fixed;\n  left: 0;\n  top: 0;\n  z-index: 9999;\n  width: 100%;\n  height: 100%;\n  /* padding-top: 100px; display: block; */\n  background-color: black;\n  background-color: rgba(0, 0, 0, 0.4);\n  display: flex !important;\n  justify-content: center !important;\n  align-items: center !important;\n}\n\n.modalContent {\n  //   width: 90%;\n  //   height: auto;\n  background-color: white;\n  padding: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBackdrop": `waPHkQ8LGccUK6iddIMJ`,
	"modalContent": `WKClq4a_BZOYxtZ3DWSg`
};
export default ___CSS_LOADER_EXPORT___;
