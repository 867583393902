import React from "react";
import style from "./TourModal.module.scss";

function TourModal(props: any) {
  return (
    <div {...props.tooltipProps} className={style.tourModalWrapper}>
      <div className={style.tourModalOuterDiv}>
        <p className={style.contentPara}> {props.step?.content} </p>
        <div className={style.buttonContainer}>
          <button {...props.primaryProps} className={style.btnNext} 
          >
            Next
          </button>
        </div>
      </div>
      <img style={props.step?.styleMiaa} src={props.step?.img}></img>
    </div>
  );
}

export default TourModal;
