import React, { useEffect, useRef, useState } from "react";
import NudgeBox from "../nudgeBox/NudgeBox";
import style from "./NudgeContainer.module.scss";
import { useDrop } from "react-dnd";
import {
  setAllNudgeData,
  setWorkingNudgeData,
} from "../../../../../redux/strategy/nudgeCreation/actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Xarrow, { useXarrow, Xwrapper } from "react-xarrows";

interface props {
  days?: number | string;
  ref?: any;
  nudeBoxes?: any;
  index?: any;
  flow?: string;
  data?: any;
  addArrowFun?: any;
}

function NudgeContainer(props: props) {
  const [nudgeBox, setNudgeBox] = useState<any>([]);
  const [propsData, setPropsData] = useState<any>();
  const [originalNudgeBox, setOriginalNudgeBox] = useState<any>([]);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "Box",
      drop: () => addNewNudge(props.data),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [props.data]
  );
  const dispatch = useDispatch();
  const allNudgeDetail = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.allNudgeDetail;
  });

  const addNewNudge = (data: any) => {
    console.log("nayak", data);
    const arr = data?.nugeBoxes ? [...data.nugeBoxes] : [];
    console.log("ggg1", arr, data?.nugeBoxes);
    // arr.push({flow : props.flow})
    //  setNudgeBox(arr)
    console.log("ggg", arr);
    addingNudge(data);
    dispatch(setWorkingNudgeData(data));
  };

  // useEffects --

  useEffect(() => {
    if (props.nudeBoxes) {
      setNudgeBox(props.nudeBoxes);
      setOriginalNudgeBox(props.nudeBoxes);
    } else {
      setNudgeBox([]);
      setOriginalNudgeBox([]);
    }
  }, [props.nudeBoxes]);
  useEffect(() => {
    setPropsData(props.data);
  }, [props.data]);
  console.log("hhh", props.days);

  // useEffect(
  //   () => {
  //    const arr = originalNudgeBox
  //    if(nudgeBox?.length !== originalNudgeBox?.length ){
  //     arr.push({flow : props.flow})
  //     setOriginalNudgeBox(arr)
  //    }

  //   },[nudgeBox?.length]
  // )

  // modification for redux
  const addingNudge = (data: any) => {
    console.log("bhai 1", data);
    if (data.isDaywise) {
      const uniqueId = uuidv4();
      const toupdate = allNudgeDetail;
      const newData = toupdate?.map((e: any, i: number) => {
        if (data.flow === e?.flow) {
          const rangesArr = e?.ranges;
          rangesArr?.map((d: any) => {
            if (d?.days?.[0] === data.days?.[0]) {
              const nudgeBoxedit = d?.nugeBoxes ? d?.nugeBoxes : [];
              console.log("nayak02", nudgeBoxedit);
              nudgeBoxedit?.push({
                flow: data.flow,
                days: data.days,
                uniqueId: uniqueId,
                isDaywise: data.isDaywise,
              });
              console.log("nayak01", nudgeBoxedit);
              d["nugeBoxes"] = nudgeBoxedit;
              return d;
            }
          });
          return e;
        } else {
          return e;
        }
      });
      console.log("nanu", newData);
      dispatch(setAllNudgeData(newData));
    } else {
      const uniqueId = uuidv4();
      const toupdate = allNudgeDetail;
      const newUpdate = allNudgeDetail?.map((e: any, i: number) => {
        if (e?.flow === data.flow) {
          const nudgeBox = e?.nugeBoxes ? e?.nugeBoxes : [];
          if (nudgeBox?.length < 2) {
            nudgeBox.push({
              flow: e?.flow,
              uniqueId: uniqueId,
              days: data?.arrayOfDays,
              isDaywise: data.isDaywise,
            });
            e["nugeBoxes"] = nudgeBox;
          } else {
            toast.error("Only Two Nudge Boxes  Allowed");
          }
          return e;
        } else {
          return e;
        }
      });
      console.log("nanu flow", newUpdate);
      dispatch(setAllNudgeData(newUpdate));
    }
  };

  return (
    <div
      ref={drop}
      className={style.nudgeContainerwrapper}
      id={`nudgeBoxContainerTesting_${props.index}`}
    >
      <div className={style.nudgeContainer}>
        {props.data.isDaywise ? (
          <div className={style.rangeDivcontainer}>
            <div
              className={style.rangeDiv}
              id={`auto_nudgeBoxContainer_${props.index}_days`}
            >
              {props.days}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className={style.lineContainer}></div>
        <div className={style.mainContainerNudge}>
          <div className={style.linecenter}></div>
          <div className={style.box}>
            {nudgeBox?.map((e: any, i: number) => {
              return (
                <div
                  key={i}
                  className={style.boxSingle}
                  id={`auto_nudgeBox_${i}`}
                >
                  {" "}
                  <NudgeBox data={e} index={i} addFun={props.addArrowFun} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NudgeContainer;
