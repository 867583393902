// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.C3i4HDTxFpu1A3WdvWgw{display:flex;justify-content:space-between;align-items:center;width:100%;margin-top:1em}.C3i4HDTxFpu1A3WdvWgw .PrPf9H1JSfUcnELWLSn1{font-family:Roboto;font-size:14px;font-weight:400;line-height:19px;text-align:left;color:#838383;border:.5px solid #b6b6b6;padding:5px 10px;border-radius:4px;margin-left:1em}.C3i4HDTxFpu1A3WdvWgw ._2pHn7MexIvijCA2XuD7{font-family:Roboto;font-size:14px;font-weight:400;line-height:19px;text-align:center;color:#0174ff;border:.5px solid #0174ff;padding:5px 10px;border-radius:4px;margin-right:1em}`, "",{"version":3,"sources":["webpack://./src/components/crm/moduleComponent/crmHeaderFull/CrmHeaderFull.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,UAAA,CACA,cAAA,CACA,4CACE,kBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CACA,aAAA,CACA,yBAAA,CACA,gBAAA,CACA,iBAAA,CACA,eAAA,CAEF,4CACE,kBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,aAAA,CACA,yBAAA,CACA,gBAAA,CACA,iBAAA,CACA,gBAAA","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  margin-top: 1em;\n  .overallcustomer_div {\n    font-family: Roboto;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 19px;\n    text-align: left;\n    color: #838383;\n    border: 0.5px solid #b6b6b6;\n    padding: 5px 10px;\n    border-radius: 4px;\n    margin-left: 1em;\n  }\n  .date_div {\n    font-family: Roboto;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 19px;\n    text-align: center;\n    color: #0174ff;\n    border: 0.5px solid #0174ff;\n    padding: 5px 10px;\n    border-radius: 4px;\n    margin-right: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `C3i4HDTxFpu1A3WdvWgw`,
	"overallcustomer_div": `PrPf9H1JSfUcnELWLSn1`,
	"date_div": `_2pHn7MexIvijCA2XuD7`
};
export default ___CSS_LOADER_EXPORT___;
