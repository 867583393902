/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { RootState } from "../../redux";
import {
  setCommunicationType,
  setMessengerData,
  setInQueueChat,
  setOngoingCallChatConversationId,
} from "../../redux/agentDesktop/BaseScreenAD/action";
import {
  setOngoingCallChatSessionId,
  setSendChatSignal,
} from "../../redux/agentDesktop/BaseScreenAD/action";
import { setRootPortalScreen } from "../../redux/baseScreen/baseScreenState/actions";
import {
  appendChatTranscript,
  getSessionIdWithoutAgentDesktopChat,
  getStatusForChat,
} from "../../redux/agentDesktop/chatLogger/action";
import portalTypes from "../rootPortal/portalTypes";
import { screenType } from "../../constants";

export default function ChatWebSocket() {
  const agentId = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.userDetail?._id
  );
  const onGoingCallChatSessionId = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.onGoingCallChatSessionId
  );
  const isActivePageType = useSelector(
    (store: RootState) => store.loginReducer?.isActivePageType
  );
  const sendChatSignal = useSelector(
    (store: RootState) => store.agentDesktopReducer.baseScreenAD.sendChatSignal
  );
  const chatCurrentSessionId = useSelector(
    (store: RootState) => store.agentDesktopReducer.chatLogger.selectedSessionId
  );
  const chatTranscript = useSelector(
    (store: RootState) => store.agentDesktopReducer.chatLogger.transcript
  );
  const inQueueChat = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.chatNotification.inQueue
  );
  const accountName: any = useSelector<RootState>(
    (state) =>
      state.loginReducer?.userLoginInfo?.userDetail?.accountDetails[0]?.name
  );
  const messageStatusGroup = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.chatLogger.messageStatusGroup
  );
  const accountId = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo?.userDetail?.accountDetails?.[0]?.id
  );
  const dispatch = useDispatch();

  /* useState ----- */
  const [socketUrl] = useState<string>(
    process.env.REACT_APP_SOCKET_URL_CHAT as string
  );
  /* other hooks */
  const didUnmount = useRef(false);
  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => console.info("chat socket open"),
    onClose: () => console.info("chat socket closed"),
    shouldReconnect: (closeEvent) => {
      return didUnmount.current === false;
    },
    reconnectAttempts: 5000,
    reconnectInterval: 1000,
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  // socket Events handler
  function handleSocketEvent(event: Record<string, any>) {
    const data = JSON.parse(event.data);
    if (data.event === "chat") {
      if (isActivePageType !== screenType.chatLoggerAgent.name) {
        if (!onGoingCallChatSessionId) {
          dispatch(
            setMessengerData(data.customerName, data.message, data.sessionId)
          );
          dispatch(setOngoingCallChatConversationId(data.conversationId));
        }
        dispatch(setCommunicationType(data.type)); // ***** callType should come in event
        dispatch(
          setRootPortalScreen(portalTypes.CHAT_NOTIFICATION_AGENT_DESKTOP)
        );
      }
      if (data.sessionId === onGoingCallChatSessionId) {
        dispatch(
          appendChatTranscript(
            data.message,
            data.timeStamp,
            "User",
            data.interactionType,
            data.fileName
          )
        );
      } else {
        setTimeout(() => {
          dispatch(
            getSessionIdWithoutAgentDesktopChat({
              accountName,
              channel: ["Whatsapp"],
              // dategte: "sd",
              // datelte: "",
              limit: 20,
              page: 1,
              agentIds: [agentId],
              chatStatus:
                messageStatusGroup === "Closed"
                  ? ["closed"]
                  : messageStatusGroup === "In-progress"
                  ? ["in-progress"]
                  : messageStatusGroup === "Open"
                  ? ["open"]
                  : [],
            })
          );
          dispatch(
            getStatusForChat({
              accountName,
              agentIds: [agentId],
              channel: ["Whatsapp"],
            })
          );
        }, 2000);
      }
      // ***** additional actions for left chat list
    }
    data.inQueue !== inQueueChat && dispatch(setInQueueChat(data.inQueue));
  }

  /* useEffects ------ */
  // listens for every message
  useEffect(() => {
    if (lastMessage !== null) {
      handleSocketEvent(lastMessage);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (connectionStatus === "Open") {
      sendJsonMessage({
        agentId: agentId,
        source: "agent",
        event: "connect",
        accountId: accountId,
      });
    }
  }, [connectionStatus]);

  // handles chat status.
  useEffect(() => {
    if (sendChatSignal === "close") {
      sendJsonMessage({
        sessionId: onGoingCallChatSessionId,
        event: "end",
        agentId: agentId,
        source: "agent",
        accountId: accountId,
      });
      setTimeout(() => {
        dispatch(
          getSessionIdWithoutAgentDesktopChat({
            accountName,
            channel: ["Whatsapp"],
            // dategte: "sd",
            // datelte: "",
            limit: 20,
            page: 1,
            agentIds: [agentId],
            chatStatus:
              messageStatusGroup === "Closed"
                ? ["closed"]
                : messageStatusGroup === "In-progress"
                ? ["in-progress"]
                : messageStatusGroup === "Open"
                ? ["open"]
                : [],
          })
        );
        dispatch(
          getStatusForChat({
            accountName,
            agentIds: [agentId],
            channel: ["Whatsapp"],
          })
        );
      }, 2000);
      dispatch(setSendChatSignal(""));
    } else if (sendChatSignal === "send" || sendChatSignal === "sendAgain") {
      sendJsonMessage({
        sessionId: onGoingCallChatSessionId,
        event: "chat",
        message: chatTranscript[chatTranscript.length - 1]?.text[0].replace(
          /<[^>]+>/g,
          ""
        ),
        agentId: agentId,
        source: "agent",
        accountId: accountId,
      });
    }
  }, [sendChatSignal]);

  useEffect(() => {
    return () => {
      getWebSocket()?.close();
      didUnmount.current === false;
    };
  }, []);

  useEffect(() => {
    // setOngoingCallChatSessionId(chatCurrentSessionId);
  }, [chatCurrentSessionId]);
  return <></>;
}
