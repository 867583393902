// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LhftYBPTJmBJJoxV0PkM{background:#fff;display:block;height:100%;width:100%;overflow-y:scroll;padding:0px}.LhftYBPTJmBJJoxV0PkM .wwY6xYaV0euprg8swD76{margin:1% 0}.LhftYBPTJmBJJoxV0PkM .HmMXcbuxtCXbgofR45wK{display:flex;justify-content:flex-start;align-items:center}`, "",{"version":3,"sources":["webpack://./src/components/agentDesktop/moduleComponents/agentDashboardModel/AgentDashboardModel.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,aAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,WAAA,CAIA,4CAKI,WAAA,CAGJ,4CACI,YAAA,CACA,0BAAA,CACA,kBAAA","sourcesContent":[".dashboardModelDiv {\n    background: #FFFFFF;\n    display: block;\n    height: 100%;\n    width: 100%;\n    overflow-y: scroll;\n    padding: 0px;\n\n    \n\n    .cardBodyDiv {\n        // display: flex;\n        // flex-wrap: wrap;\n        // justify-content: space-between;\n        // align-items: center;\n        margin: 1% 0;\n    }\n\n    .chartBodyDiv {\n        display: flex;\n        justify-content: flex-start;\n        align-items: center;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardModelDiv": `LhftYBPTJmBJJoxV0PkM`,
	"cardBodyDiv": `wwY6xYaV0euprg8swD76`,
	"chartBodyDiv": `HmMXcbuxtCXbgofR45wK`
};
export default ___CSS_LOADER_EXPORT___;
