import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Routers from "./router/Routers";
import "./theme/Colors.css"; // initialize colors
import "./theme/Fonts.css"; // initialize fonts
import RootPortal from "./screens/rootPortal/rootPortal";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <div>
      <>
        <Router>
          <Routers />
          <RootPortal />
        </Router>
        <ToastContainer
          position="top-center"
          // type="success"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick={false}
          draggable={false}
          rtl={true}
        />
      </>
    </div>
  );
};

export default App;
