// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.C214JTMnMkjuR6nR_1NR{background-color:#f6f6f6;width:100%;padding:8px 16px;border-radius:8px;display:flex;flex-direction:row;justify-content:space-between}.C214JTMnMkjuR6nR_1NR .Gvmn4yhSQ1fyJKyoorG5{border-right:.5px solid #c4c4c4;flex:1;font-size:12.5px}.C214JTMnMkjuR6nR_1NR .Gvmn4yhSQ1fyJKyoorG5 .OthcawQDasuQi3lcHoXT{color:#333;margin-bottom:4px}.C214JTMnMkjuR6nR_1NR .Gvmn4yhSQ1fyJKyoorG5 .js3a4B72TRZkOuGa9q50{color:#6e7079}.C214JTMnMkjuR6nR_1NR .jQTgTSUE35ONQqwyPR2F{padding-left:16px;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/generic/fileviewDownlaod/FileViewDownload.module.scss"],"names":[],"mappings":"AAAA,sBACI,wBAAA,CACA,UAAA,CACA,gBAAA,CACA,iBAAA,CAEA,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,4CACI,+BAAA,CACA,MAAA,CACA,gBAAA,CACA,kEACI,UAAA,CACA,iBAAA,CAEJ,kEACI,aAAA,CAIR,4CACI,iBAAA,CACA,cAAA","sourcesContent":[".fileViewDownload{\n    background-color: #F6F6F6;\n    width: 100%;\n    padding: 8px 16px;\n    border-radius: 8px;\n    \n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n\n    .fileInfo{\n        border-right: 0.5px solid #C4C4C4;\n        flex: 1;\n        font-size: 12.5px;\n        .fileName{\n            color: #333333;\n            margin-bottom: 4px;\n        }\n        .date{\n            color: #6E7079;\n        }\n    }\n\n    .downloadBtn{\n        padding-left: 16px;\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileViewDownload": `C214JTMnMkjuR6nR_1NR`,
	"fileInfo": `Gvmn4yhSQ1fyJKyoorG5`,
	"fileName": `OthcawQDasuQi3lcHoXT`,
	"date": `js3a4B72TRZkOuGa9q50`,
	"downloadBtn": `jQTgTSUE35ONQqwyPR2F`
};
export default ___CSS_LOADER_EXPORT___;
