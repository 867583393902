import { takeLatest } from "redux-saga/effects";
import actionTypes from "../whatsApp.actionTypes";
import {
  uploadWhatsAppProfilePicAPIWorker,
  deleteWhatsAppProfilePicAPIWorker,
  getAllIndustryDataAPIWorker,
} from "./whatsApp.saga";

export default function* whatsAppDataWatcher(): any {
  yield takeLatest(
    actionTypes.GET_ALL_INDUSTRY_DATA_REQUEST,
    getAllIndustryDataAPIWorker
  );

  yield takeLatest(
    actionTypes.UPDATE_WHATSAPP_PROFILE_PIC_REQUEST,
    uploadWhatsAppProfilePicAPIWorker
  );

  yield takeLatest(
    actionTypes.DELETE_WHATSAPP_PROFILE_PIC_REQUEST,
    deleteWhatsAppProfilePicAPIWorker
  );
}
