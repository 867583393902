import React, { useEffect, useRef, useState } from "react";
import ChannelContainer from "../../../components/moduleComponents/strategyComponents/nudgeCreation/channelContainer/ChannelContainer";
import HeaderFlows from "../../../components/moduleComponents/strategyComponents/nudgeCreation/headerFlows/HeaderFlows";
import NudgeContainer from "../../../components/moduleComponents/strategyComponents/nudgeCreation/nudgeContainer/NudgeContainer";
import ToolsContainer from "../../../components/moduleComponents/strategyComponents/nudgeCreation/toolsContainer/ToolsContainer";
import style from "./NudgeCanvasScreen.module.scss";
import { useDrop } from "react-dnd";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import {
  setAllNudgeData,
  createNudge,
  getAllNudgeDetails,
  showConnectionDot,
  getConditionOutcomes,
} from "../../../redux/strategy/nudgeCreation/actions";
import Xarrow, { useXarrow, Xwrapper } from "react-xarrows";
import ReverseMultiSelect from "../../../components/generic/reverserMultiSelect/ReverseMultiSelect";
import EachNudgeRightSidePop from "../../../components/moduleComponents/rightSidebarStrategy/EachNudgeRightSidePop";
import { ArcherContainer, ArcherElement } from "react-archer";
import { RootState } from "../../../redux";
const acceptedOutcome = ["Agree To Pay", "Paid", "Acceptable Promise to Pay"];
const unacceptedOutcome = [
  "Refuse To Pay",
  "Promise to Pay",
  "Future Promise To Pay",
  "Payment Delay",
  "Customer Hangup",
];
interface props {
  render?: any;
}
function NudgeCanvasScreen(props: props) {
  const [toolSelected, setToolSelected] = useState("");
  const [selectedFlow, setSelectedFlow] = useState("");
  const [listOfNudge, setListOfNudge] = useState<any>([]);
  const [windowWidth, setWindowWidth] = useState<any>("100");
  const [windowHeight, setWindowHeight] = useState<any>("100");
  const [range, setRange] = useState<any>([]);
  const [value, setValue] = useState<any>(0);

  const navigate = useNavigate();

  const ref = useRef<any>();
  const dispatch = useDispatch();

  const refLine = useRef<any>();
  const renderFunc = () => {
    refLine?.current?.refreshScreen();
    setTimeout(() => {
      refLine?.current?.refreshScreen(), 3000;
    });
  };
  const arr = [-10, -9, -8, -7, -6, "-5"];
  const newArray: any = [
    {
      flow: "Pre-Due",
      ranges: [
        {
          flow: "Pre-Due",
          days: [-10],
          nugeBoxes: [
            {
              flow: "Pre-Due",
              days: [-10],
            },
          ],
        },
        {
          flow: "Pre-Due",
          days: [-9],
          nugeBoxes: [
            {
              flow: "Pre-Due",
              days: [-9],
            },
          ],
        },
        {
          flow: "Pre-Due",
          days: [-8],
          nugeBoxes: [
            {
              flow: "Pre-Due",
              days: [-8],
            },
          ],
        },
        {
          flow: "Pre-Due",
          days: [-7],
          nugeBoxes: [
            {
              flow: "Pre-Due",
              days: [-7],
            },
          ],
        },
      ],
    },
    {
      flow: "Due-Date",
      ranges: [
        {
          flow: "Due-Date",
          days: [-10],
          nugeBoxes: [
            {
              flow: "Due-Date",
              days: [-10],
            },
          ],
        },
      ],
      // range : [-10, -9 ,-8 ,-7 ,-6 , "-5"],
    },
    {
      flow: "Post-Due",
      // range : [-10, -9 ],
    },
    {
      flow: "DPD-12",
      // range : [-10, -9 ,-8 ,-7 ,-6],
    },
  ];
  //redux data

  const allNudgeDetail = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.allNudgeDetail;
  });

  const creatApiLoading = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.isLoadingCreateNudge;
  });

  const moduleSelectedScreen = useSelector(
    (store: any) => store?.baseScreen?.leftMenu?.highlightedModule
  );

  const createdBucketData = useSelector((store: any) => {
    return store?.strategyModuleReducer?.strategyReducer?.createdStrategy;
  });
  const nudgeConnectedData = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.connectedNudgeData;
  });
  const showConnection = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.showDot;
  });
  const typeOfMode = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.typeOfstrategyMode;
  });
  const disposition = useSelector((store: RootState) => {
    return store?.strategyModuleReducer?.nudgeReducer?.outComesDispositions
      ?.data;
  });

  const isScriptSelectVisited = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.isScriptSelectVisted;
  });


  const myPlanData: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  console.log("abhi", typeOfMode);

  //modifing data from bucket
  useEffect(() => {
    if (allNudgeDetail?.length) {
      dispatch(setAllNudgeData(allNudgeDetail));
    }
  }, [allNudgeDetail]);
  useEffect(() => {
    const finalBody: any = [];
    createdBucketData?.flows?.map((data: any, index: number) => {
      const obj: any = {};
      obj["flow"] = data?.originalName;
      const rangesArray: any = [];
      const daysArray: any = [];
      for (let i: any = data?.min; i <= data.max; i++) {
        const newObj: any = {};
        daysArray.push(i);
        newObj["flow"] = data?.originalName;
        newObj["days"] = [i];
        newObj["isDaywise"] = true;
        rangesArray.push(newObj);
      }
      obj["ranges"] = rangesArray;
      obj["strategyId"] = createdBucketData?.id;
      obj["arrayOfDays"] = daysArray;
      obj["isDaywise"] = false;

      return finalBody.push(obj);
    });
    console.log("newBody", finalBody);
    if (finalBody?.length !== allNudgeDetail?.length) {
      dispatch(setAllNudgeData(finalBody));
    }
  }, [createdBucketData]);

  useEffect(() => {
    if (isScriptSelectVisited === false) {
      if (typeOfMode === "edit") {
        dispatch(getAllNudgeDetails({ strategyId: createdBucketData?.id }));
      }
    }
  }, [isScriptSelectVisited, createdBucketData?.id, typeOfMode]);

  //---end

  // for initail setup

  console.log("sel", selectedFlow);
  //for getting ranges as per flow
  useEffect(() => {
    const newChange = allNudgeDetail?.filter((e: any) => {
      if (e?.flow === selectedFlow && e?.ranges) {
        setRange([...e.ranges]);
        return e?.ranges;
      } else if (selectedFlow === "") {
        setRange([]);
      }
    });
    console.log("ggg", newChange, selectedFlow, range);
  }, [selectedFlow]);

  // for list of nudge
  useEffect(() => {
    if (range?.length) {
      setListOfNudge(range);
    } else {
      setListOfNudge(allNudgeDetail);
    }
  }, [range, allNudgeDetail]);

  console.log("hh", listOfNudge, value);

  //handle clicks
  const handleZoomPlus = () => {
    if (windowWidth < 150 && windowHeight < 150) {
      const incHeight =
        Number(ref.current?.style?.height.replace("%", "")) + 10;
      const incwidth = Number(ref.current?.style?.width.replace("%", "")) + 10;
      console.log("zoom", incHeight, ref.current?.style?.height);
      setWindowWidth(incwidth);
      setWindowHeight(incHeight);
      setValue((value: any) => value + 1);
      props?.render();
      renderFunc();
    }
  };
  const handleZoomMinus = () => {
    if (windowWidth > 40 && windowHeight > 40) {
      const incHeight =
        Number(ref.current?.style?.height.replace("%", "")) - 10;
      const incwidth = Number(ref.current?.style?.width.replace("%", "")) - 10;
      console.log("zoom", incHeight, ref.current?.style?.height);
      setWindowWidth(incwidth);
      setWindowHeight(incHeight);
      setValue((value: any) => value + 1);
      props?.render();
      renderFunc();
    }
  };

  const handleNudgeSave = async () => {
    console.log("hello");
    // const data = await dispatch(createNudge(allNudgeDetail));
    // console.log("bhiya" , data)
  };
  const handleConnectNudge = () => {
    const data = !showConnection;
    console.log("nayak", data);
    dispatch(showConnectionDot(data));
  };

  useEffect(() => {
    if (creatApiLoading === false) {
      if (moduleSelectedScreen !== "Strategy") {
        navigate("/campaignmanager/strategyselection");
      }
      if (moduleSelectedScreen == "Strategy") {
        navigate("/strategy");
      }
    }
  }, [creatApiLoading]);
  //----
  const [arrows, setArrows] = useState<any>([]);

  const addArrow = (data: any) => {
    setArrows([...arrows, data]);
    console.log("jai ho", arrows);
  };
  // const ref1 = useRef<any>();
  // const ref2 = useRef<any>();

  //-----
  return (
    <div className={style.nudgeCanvasWrapper} id="auto_nudgeCanvasWrapper">
      <div
        ref={ref}
        style={{ width: `${windowWidth}%`, height: `${windowHeight}%` }}
      >
        <ArcherContainer strokeColor="red" ref={refLine}>
          <div className={style.headerStatic}>
            <HeaderFlows
              flowList={allNudgeDetail}
              selectedFlow={setSelectedFlow}
            />
          </div>

          <div
            className={style.containerDiv}
            id="auto_nudgeCanvas_containerDiv"
          >
            {listOfNudge?.map((e: any, i: number) => {
              return (
                <div
                  key={i}
                  className={style.nudgeContainerComponent}
                  id={`auto_nudgeContainerComponent_${i}`}
                >
                  <NudgeContainer
                    days={e?.days?.length === 1 ? e?.days?.[0] : null}
                    nudeBoxes={e?.nugeBoxes}
                    flow={e?.flow}
                    index={i}
                    data={e}
                    addArrowFun={addArrow}
                  />
                </div>
              );
            })}
          </div>
        </ArcherContainer>
      </div>
      <div className={`${style.toolContainerBox} ${myPlanData?.isStrategyToShow === true ? "" : style.disableEventsBox}`}>
        <ToolsContainer
          clickTool={setToolSelected}
          handleZoomPlus={handleZoomPlus}
          handleZoomMinus={handleZoomMinus}
          handleCreateNudge={handleNudgeSave}
          handleConnectDot={handleConnectNudge}
        />
      </div>
      <div>
        {toolSelected === "channel" && (
          <div className={style.toolOptionCard}>
            {" "}
            <div className={style.channelDiv}>
              {" "}
              <ChannelContainer />{" "}
            </div>{" "}
          </div>
        )}
      </div>
      <div>
        {toolSelected === "condition" && (
          <div className={style.toolOptionCard}>
            <div className={style.conditionDiv}>
              <ReverseMultiSelect
                optionsDefaultSelected={[]}
                filterOptions={disposition?.["Accepted Outcome"]}
                setDataOut={(x: any) => console.log(x, "0000")}
                title={"Accepted Outcome"}
                disabled={false}
                tiptext="This denote positive outcomes. Will be denoting the expected goals from user side"
              />
              <ReverseMultiSelect
                optionsDefaultSelected={[]}
                filterOptions={disposition?.["Unaccepted Outcome"]}
                setDataOut={(x: any) => console.log(x, "0000")}
                title={"Unaccepted Outcome"}
                disabled={false}
                tiptext={
                  "This denote negative outcomes. Will be denoting the not expected goals from user side"
                }
              />
            </div>
          </div>
        )}
      </div>
      <div>
        {nudgeConnectedData.map((ar: any, i: number) => {
          console.log("haha", ar);
          return (
            <Xwrapper key={i + 1}>
              {" "}
              <Xarrow
                start={ar?.start}
                end={ar?.end}
                key={ar?.start + "-." + ar?.start}
              />
            </Xwrapper>
          );
        })}
      </div>
    </div>
  );
}

export default NudgeCanvasScreen;
