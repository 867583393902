/**
 * getDataFromSchema is use to get data from schema in cleaner way.
 * get require data by useSelector hook from reducer and pass in function.
 * currentLoggerPage?: string; it is report/ transcript.
 * It will return schema according to tab selected if all data is valid.
 * Otherwise will return string with error message.
 */

// export interface argsInterface {
//   schema: any;
//   isActivePageType: string;
//   sourceTab: string;
//   subModuleTab: string;
//   channelTab: string;
//   whatsappChannelTab?: string;
//   currentLoggerPage?: string;
// }

// export const getDataFromSchema = ({
//   schema,
//   isActivePageType,
//   sourceTab,
//   subModuleTab,
//   channelTab,
//   whatsappChannelTab,
//   currentLoggerPage,
// }: argsInterface) => {
//   let dataToReturn = schema;
//   if (!schema) return null;

//   // level 1
//   if (!isActivePageType) return null;
//   dataToReturn = dataToReturn[isActivePageType.toLowerCase()];

//   // level 2
//   if (isActivePageType === "Logger") {
//     if (!currentLoggerPage || !dataToReturn) return null;
//     dataToReturn = dataToReturn[currentLoggerPage.toLowerCase()];
//   }

//   // level 3
//   if (!sourceTab || !dataToReturn) return null;
//   dataToReturn = dataToReturn[sourceTab];
//   // level 4
//   if (!channelTab || !dataToReturn) return null;
//   dataToReturn = dataToReturn[channelTab];

//   if (channelTab === "Whatsapp" || channelTab === "Chat") {
//     if (!whatsappChannelTab || !dataToReturn) return null;
//     dataToReturn = dataToReturn[whatsappChannelTab];
//   }

//   if (!subModuleTab) {
//     dataToReturn = dataToReturn?.["Campaign"];
//     sessionStorage.setItem("subModule", "Campaign");
//   } else {
//     dataToReturn = dataToReturn?.[subModuleTab];
//     sessionStorage.setItem("subModule", subModuleTab);
//   }

//   if (dataToReturn instanceof Array) dataToReturn = [...dataToReturn];

//   return dataToReturn;

//   // return schema["analytics"]["Debt"]["WhatsApp"]["One Way"]["Campaign"];
// };

export interface argsInterface {
  schemaData: Record<string, Array<Record<string, any>>>;
  dataType: "cards" | "charts" | "filters" | "reportColumns" | "summaries";
  channel: Record<string, any>;
  subChannel: Record<string, any> | undefined;
  tab: Record<string, any>;
  screen: string;
  subScreen: string | undefined;
}
export const getDataFromSchema = ({
  schemaData,
  dataType,
  channel,
  subChannel,
  tab,
  screen,
  subScreen,
}: argsInterface) => {
  screen = screen?.toLowerCase();
  subScreen = subScreen?.toLowerCase();

  // validation
  if (!schemaData || !Object.keys(schemaData)?.length) {
    throw new Error(`GetDataFromSchema: Invalid Schema`);
  }
  if (!dataType) throw new Error(`GetDataFromSchema: Invalid dataType`);
  if (!channel || !Object.keys(channel)?.length) {
    throw new Error(`GetDataFromSchema: Invalid channel`);
  }
  if (channel.childChannel?.length && !subChannel) {
    throw new Error(`GetDataFromSchema: Invalid subChannel`);
  }
  if (!tab || !Object.keys(tab)?.length) {
    throw new Error(`GetDataFromSchema: Invalid tab`);
  }

  // type of data required
  let requiredData = schemaData[dataType];
  if (!requiredData) {
    throw new Error(
      `GetDataFromSchema: DataType - ${dataType} is not present in schema`
    );
  }

  // decide channelId
  let channelId = channel.id;
  if (channel.childChannel?.length) {
    if (!subChannel?.id) {
      throw new Error(
        `GetDataFromSchema: provided subChannel - ${subChannel?.name} is invalid`
      );
    }
    channelId = subChannel.id;
  }
  // filter by screen && channelId && tabId && activeness
  if (screen === "analytics") {
    requiredData = requiredData.filter(
      (item) =>
        item.screen === screen &&
        item.channelId === channelId &&
        item.tabId === tab.id
    );
  } else if (screen === "logger") {
    if (subScreen === "report" || subScreen === "transcript") {
      requiredData = requiredData.filter(
        (item) =>
          item?.subScreen === subScreen &&
          item.channelId === channelId &&
          item.tabId === tab.id
      );
    } else
      throw new Error(
        `GetDataFromSchema: provided subScreen - ${subScreen} is invalid`
      );
  } else
    throw new Error(
      `GetDataFromSchema: provided screen - ${screen} is invalid`
    );

  // sorting of data
  requiredData.sort(
    (a: Record<string, any>, b: Record<string, any>) =>
      a?.position - b?.position
  );
  return requiredData;
};
