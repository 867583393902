import React, { useEffect, useState } from "react";
import progressShoutOutIcon from "../../../../theme/assets/svg/campaign/progressShoutOutIcon.svg";
import CrossIcon from "../../../../theme/assets/genericSvg/crossIcon.svg"
import "./InProgressPopup.css"
import ProgressBarComponent from "../../responseRate/progressBarComponent/ProgressBarComponent";
import { SERVER_URL_CONNECTOR, SCHEDULER_URL} from "../../../../services/ApiRoutes";
import axios from "axios";


function InProgressPopup(props) {
    const[responceData , setResponceData] = useState()

    const cancelPopUp =() =>{
        props.hide(false)
    }
    console.log(props.id)

    useEffect(
        () => {
            let url = `${SERVER_URL_CONNECTOR}${SCHEDULER_URL.CALLING_INFO}`
           let obj = {
                "campaignId": props.id
            }
            let jwtHeader = {headers : props.jwt}
            console.log("nn" , jwtHeader)
            axios.post(url ,obj ,jwtHeader).then(
                res => {
                    setResponceData(res?.data?.data)
                      }
            ).catch(
                err => console.log(err)
            )
        },[props.id]
    )
    
    
    return (<div className="in-progress-popup-wrapper">
        <div className="in-progress-popup-container">

            <div className="head-container" >
                <p className="heading-popup">{props.data.campaignStatus}</p>
                <img className="cross-pos" onClick={() => cancelPopUp()} src={CrossIcon} alt="cut" />
            </div>

            <div>
                <img className="shout-icon" src={progressShoutOutIcon} alt="details" />
            </div>
            <div className="progress-area">
                <div className="progress-text">
                    <p className="c-text">Connection Rate</p>
                    <p className="c-text">{responceData?.connectionRate}%</p>
                </div>
                <ProgressBarComponent isLoading={false}
                    percent={responceData?.connectionRate}
                    size={"large"}
                    showInfo={false}
                    extraClass="green-bar"
                />

            </div>
            <div className="details">
              
                  {
                      responceData?.rows?.sort((a,b) => a?.id -b?.id)?.map((e ,i)=> {
                          return (
                            <p className="para">{e?.title}:<span className = { e?.title?.includes("Not Connected" || "Failed" || "Not Delivered ") ? "span4" : e?.title?.includes("Connected" || "Delivered") ? "span3" : e?.title?.includes("Collected") ? "span2" : "span1" } >{e?.value}</span></p>
                          )
                      })
                  } 
            
            </div>



        </div>


    </div>)
}

export default InProgressPopup;