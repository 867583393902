import React, { useEffect, useState } from "react";
import ChoosePlans, { IPlan } from "../choosePlans/ChoosePlans";
import styles from "./SelectPlansPage.module.scss";
import { Br } from "../../../../generic/br/Br";
import BillingFeatures, { IFeatures } from "../features/BillingFeatures";
import { pravid_logo } from "../../../../../theme/assets/svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setSelectedPlanData,
  setAddedToCartRoute,
  getCoupanPlan,
} from "../../../../../redux/profile/billing/pricingPlan/actions";

export default function SelectPlansPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const paymentDetails = useSelector(
    (state: RootState) =>
      state.profile?.billingReducer?.pricingPlan?.pricingPlanDetails?.data
        ?.planData
  );
  const paymentRedux = useSelector((state: RootState) => {
    return state.profile.billingReducer.pricingPlan;
  });

  const [allPlans, setAllPlans] = useState<IPlan[]>([]);
  const [currentPlanId, setCurrentPlanId] = useState("");
  const [allFeatures, setAllFeatures] = useState<
    { id: string; features: IFeatures[] }[]
  >([]);
  const [currentFeatures, setCurrentFeatures] = useState<IFeatures[]>([]);

  useEffect(() => {
    if (paymentDetails && paymentDetails?.length > 0) {
      setAllFeatures(() => {
        return paymentDetails?.map((plan: any) => {
          return {
            id: plan.id,
            features: plan?.features?.map((feature: any) => {
              return {
                name: feature.heading,
                icon: feature.icon,
                features: feature.description,
              };
            }),
          };
        });
      });

      const sortedData: IPlan[] | undefined = paymentDetails
        ?.sort((a: any, b: any) => {
          return a.position - b.position;
        })
        .map((e) => {
          return {
            name: e.planName,
            id: e.id,
            price: e.price,
            duration: e.duration,
          };
        });
      setAllPlans(sortedData || []);
    } else {
      setAllFeatures(() => {
        return [];
      });
      setAllPlans([]);
    }
  }, [paymentDetails]);

  //select plans
  useEffect(() => {
    if (paymentRedux.selectedPlan) {
      setCurrentPlanId(paymentRedux?.selectedPlan.id);
      return;
    } else {
      setCurrentPlanId(allPlans?.length > 0 ? allPlans?.[0]?.id : "");
      dispatch(setSelectedPlanData(allPlans?.[0]));
    }
  }, [allPlans, paymentRedux.selectedPlan]);

  //filter current plan details
  useEffect(() => {
    setCurrentFeatures(() => {
      const filter = allFeatures
        ?.filter((e: any) => e.id === currentPlanId)
        ?.map((e: any) => e.features);
      if (filter.length > 0) {
        return filter[0];
      } else return [];
    });
  }, [allFeatures, currentPlanId]);

  function setCurrentPlanIdInRedux(id: string) {
    const currentPlan: any = paymentDetails?.find(
      (plan: any) => plan.id === id
    );

    dispatch(setSelectedPlanData(currentPlan));
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.select}>Select your plan</div>
      <Br />
      <div className={styles.section}>
        <div className={styles.left}>
          <div className={styles.coreFeatures}>Core Features</div>
          <div className={styles.planDetail}>
            {paymentRedux.selectedPlan?.coreFeature}
          </div>
          <Br />
          <BillingFeatures features={currentFeatures} />
        </div>
        <div className={styles.right}>
          <ChoosePlans
            onClickChoosePlan={(id, plan) => {
              setCurrentPlanId(id);
              setCurrentPlanIdInRedux(id);
              dispatch(setAddedToCartRoute("detailsPage"));
              navigate("/profile/billing/addtocart");
            }}
            onClickComparePlan={() => {
              navigate("/profile/billing/compareplans");
            }}
            currentPlanId={currentPlanId}
            plans={allPlans}
            onChangePlan={function (id: string, plan): void {
              setCurrentPlanId(id);
              setCurrentPlanIdInRedux(id);
            }}
          />
        </div>
      </div>
    </div>
  );
}
