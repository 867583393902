// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrzG7iNOg7l6KCG2KNrH{position:absolute;z-index:0;right:-2px;top:calc(5em + 3px);height:90vh;background-color:#fff;width:3em;box-shadow:0px 2px 4px rgba(0,0,0,.1)}.PrzG7iNOg7l6KCG2KNrH .WJYdB_gBYVUB3zAHewNj{display:flex;flex-direction:column;align-items:center}.PrzG7iNOg7l6KCG2KNrH .WJYdB_gBYVUB3zAHewNj img{margin-top:1em;cursor:pointer}.PrzG7iNOg7l6KCG2KNrH .WJYdB_gBYVUB3zAHewNj img:nth-child(2){margin-top:1.5em}.PrzG7iNOg7l6KCG2KNrH .WJYdB_gBYVUB3zAHewNj img:nth-child(3){margin-top:1.5em}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/rightsideStrategy/RightsideStrategy.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,SAAA,CACA,UAAA,CACA,mBAAA,CACA,WAAA,CACA,qBAAA,CACA,SAAA,CACA,qCAAA,CACA,4CACE,YAAA,CAEA,qBAAA,CACA,kBAAA,CACA,gDACE,cAAA,CACA,cAAA,CACA,6DACE,gBAAA,CAEF,6DACE,gBAAA","sourcesContent":[".wrapper_rightss {\n  position: absolute;\n  z-index: 0;\n  right: -2px;\n  top: calc(5em + 3px);\n  height: 90vh;\n  background-color: white;\n  width: 3em;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n  .iconlist {\n    display: flex;\n\n    flex-direction: column;\n    align-items: center;\n    img {\n      margin-top: 1em;\n      cursor: pointer;\n      &:nth-child(2) {\n        margin-top: 1.5em;\n      }\n      &:nth-child(3) {\n        margin-top: 1.5em;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper_rightss": `PrzG7iNOg7l6KCG2KNrH`,
	"iconlist": `WJYdB_gBYVUB3zAHewNj`
};
export default ___CSS_LOADER_EXPORT___;
