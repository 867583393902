import React, { useState, useEffect, useRef } from "react";

import "./DropdownSaarthi.css";

import MultiSelect from "./multiSelect/MultiSelect";
import InputBox from "../inputBox/InputBox";
import SearchIcon from "../../../theme/assets/svg/onboarding/searchIcon.svg";

const DropdownSaarthi = (props) => {
  const {
    optionList,
    imgSrcLeft,
    imgSrcRight: ImgSrcRight,
    placeHolderText,
    imgSrcLeftHide,
  } = props.droplist;
  const [isDropdownShow, setIsDropdownShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const ref = useRef();

  //To close dropdown on click anywhere
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isDropdownShow && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownShow(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownShow]);

  // function for hide and show dropdowm
  const hideAndShowDropdown = () => {
    let temp = isDropdownShow;
    temp = !temp;
    setIsDropdownShow(temp);
  };

  const handleClickSelecteItem = (item) => {
    let temp = item;
    setSelectedItem(temp);
    setIsDropdownShow(false);
    props.selectedItem(item);
  };

  const closeDropdown = () => {
    setIsDropdownShow(false);
  };

  // useEffect(() => {

  // }, [selectedItem]);

  useEffect(() => {
    // console.log("editedItem", props.editedItem)
    setSelectedItem(props.editedItem);
  }, [props.editedItem]);
  useEffect(() => {
    if (props.showInitialList) {
      setIsDropdownShow((prev) => true);
    }
  }, [props.showInitialList]);

  const displayText = (selectedItem, placeHolderText) => {
    if (props.maxDisplayLength) {
      if (
        (selectedItem ? selectedItem : placeHolderText)?.length >
        props.maxDisplayLength
      ) {
        return (
          (selectedItem ? selectedItem : placeHolderText).slice(
            0,
            props.maxDisplayLength
          ) + "..."
        );
      } else {
        return selectedItem ? selectedItem : placeHolderText;
      }
    } else {
      return selectedItem ? selectedItem : placeHolderText;
    }
  };

  return (
    <>
      <div
        className="dropdownTopDiv  dropdownJpWrapper"
        ref={ref}
        id={props?.id ? props?.id : "auto_dropdownWrapper"}
      >
        <div
          className={`selecteditem 
           ${props.isFilter ? "filter-section-drop" : ""}
            ${selectedItem ? "selectedValidated" : "selectedNotValidated"}
            ${props.extraClassSelectedArea ? props.extraClassSelectedArea : ""}
            `}
          onClick={() => !props.isDisabled && hideAndShowDropdown()}
          id="auto_selecteditem"
          // style={props.isCallDuration?{width:"220px"}:{width:"170px"}}
        >
          {imgSrcLeft ? (
            <img
              className={`${
                props.extraStyleLeftImg
                  ? props.extraStyleLeftImg
                  : "styleLeftImg"
              }`}
              src={imgSrcLeft}
              alt="Dropdown left icon"
              id="auto_imgSrcLeft"
            />
          ) : imgSrcLeftHide ? (
            ""
          ) : (
            <p id="auto_imgSrcHide"></p>
          )}
          <p
            className={` ddSelectedItemPara ${
              !selectedItem && props.extraClassPlaceHolder
                ? props.extraClassPlaceHolder
                : ""
            } ${
              props.extraClassPlaceHolderStyling
                ? props.extraClassPlaceHolderStyling
                : ""
            }`}
            id="auto_ddSelectedItemPara"
            style={{ textOverflow: "ellipsis" }}
            title={displayText(selectedItem, placeHolderText)}
          >
            {" "}
            {displayText(selectedItem, placeHolderText)}{" "}
          </p>
          {ImgSrcRight ? (
            <img
              className={`${
                props.extraStyleLeftImg ? props.extraStyleLeftImg : ""
              }`}
              src={ImgSrcRight}
              alt="Dropdown left icon"
              id="auto_ImgSrcRight"
            />
          ) : (
            <p id="auto_ImgSrcRightHide"></p>
          )}
        </div>
        {isDropdownShow && (
          <div
            className={`dropdownToBeSelected  ${
              props.extraClassToBeSelectedArea
                ? props.extraClassToBeSelectedArea
                : ""
            }`}
            id="auto_dropdownToBeSelected"
          >
            {props.searchUi && (
              <div
                className={`dropdownSearchWrapper
              ${
                props.extraClassDropdownSearchWrapper
                  ? props.extraClassDropdownSearchWrapper
                  : ""
              }
              `}
                id="auto_dropdownSearchWrapper"
              >
                <InputBox
                  className={`dropDownSearchInput 
                  ${
                    props.extraClassDropdownSearchArea
                      ? props.extraClassDropdownSearchArea
                      : ""
                  }`}
                  type="text"
                  imgSrc={SearchIcon}
                  value={props.searchVal}
                  onChangeValue={(e) => props.handleSearchItem(e)}
                  imageClick={{}}
                  id="dropdown_input"
                  parentClass={
                    props.extraSearchInputWrapper
                      ? props.extraSearchInputWrapper
                      : ""
                  }
                />
              </div>
            )}
            <ul
              className={`dropdownUl  ${props.isFilter ? "filter-ul" : ""}   ${
                props.extraClassDropDownUl ? props.extraClassDropDownUl : ""
              }`}
              id="auto_dropdownUl"
            >
              {optionList == "filterData" ? (
                <MultiSelect
                  onCancel={closeDropdown}
                  selectedItems={props.selectedItems ? props.selectedItems : ""}
                  idData={props?.idData ? props?.idData : 0}
                />
              ) : optionList?.length > 0 ? (
                optionList?.map((item, indx) => {
                  return (
                    <>
                      <li
                        id={`auto_list_${indx}`}
                        className={`dropdownLi ${
                          props.img ? "imgSpace" : null
                        } ${
                          props?.outerSelected === item
                            ? "higlightSelected"
                            : ""
                        }${
                          props.extraClassDropDownLi
                            ? props.extraClassDropDownLi
                            : ""
                        } ${
                          props?.outerSelected === item &&
                          props.extraClassDropDownLi
                            ? props.extraHighlight
                              ? props.extraHighlight
                              : "higlightSelectedWithLeftAlign"
                            : props?.ignoreLeftAlign
                            ? ""
                            : "leftAlignStyling"
                        } 
      
                        `}
                        key={item}
                        onClick={() => handleClickSelecteItem(item)}
                      >
                        <span>
                          {props.img ? (
                            <img
                              src={props.img}
                              alt="img"
                              id={`auto_${props.img}`}
                            ></img>
                          ) : (
                            ""
                          )}
                        </span>
                        <span id={`values_${indx}`} className="">
                          {" "}
                          {item}{" "}
                        </span>
                      </li>
                    </>
                  );
                })
              ) : (
                ""
              )}
            </ul>
          </div>
        )}
        {props.errorMsg && (
          <div
            className={"errorMsgStyling"}
            id={`auto_${props.droplist.placeHolderText}_errMsgDD`}
          >
            {props.errorMsg}
          </div>
        )}
      </div>
    </>
  );
};

export default DropdownSaarthi;

/*
  maxDisplayLength: number (optional) - to restrict the length of string displayed in selected bo 
  extraClassPlaceHolder: string (optional) - to add css for place holder.
*/
