// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AHGRxqKUbbXRoJ0DQlpV{width:100%;background:#fff;box-shadow:0px 71.3652px 57.0921px rgba(36,69,152,.05),0px 36.1286px 24.8886px rgba(36,69,152,.03375),0px 14.273px 9.27747px rgba(36,69,152,.025),0px 3.12223px 3.30064px rgba(36,69,152,.01625);border-radius:4px;display:flex;align-items:center;justify-content:space-between;padding-left:1%;padding-right:1%}.AHGRxqKUbbXRoJ0DQlpV img{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/strategyComponents/nudgeCreation/channelContainer/ChannelContainer.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,eAAA,CACA,gMAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,eAAA,CACA,gBAAA,CACA,0BACI,cAAA","sourcesContent":[".channelContainerout{\n    width: 100%;\n    background: #FFFFFF;\n    box-shadow: 0px 71.3652px 57.0921px rgba(36, 69, 152, 0.05), 0px 36.1286px 24.8886px rgba(36, 69, 152, 0.03375), 0px 14.273px 9.27747px rgba(36, 69, 152, 0.025), 0px 3.12223px 3.30064px rgba(36, 69, 152, 0.01625);\n    border-radius: 4px; \n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding-left: 1%;\n    padding-right: 1%;\n    img{\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"channelContainerout": `AHGRxqKUbbXRoJ0DQlpV`
};
export default ___CSS_LOADER_EXPORT___;
