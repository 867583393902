export default {
  logger: { name: "Logger", routeName: "calllogger" },
  analytics: { name: "Analytics", routeName: "dashboard" },
  integration: { name: "Connector", routeName: "ConnectorPage" },
  campaign: { name: "Campaign", routeName: "campaignmanager" },
  strategy: { name: "Strategy", routeName: "strategy" },
  billing: { name: "Billing", routeName: "billing" },
  userList: { name: "User List", routeName: "userlist" },
  operation: { name: "Operation", routeName: "operation" },
  demo: { name: "Demo", routeName: "demo" },
  dashboardAgent: {
    name: "Dashboard",
    routeName: "agent-desktop/agent-dashboard",
  },
  callLoggerAgent: {
    name: "Call log",
    routeName: "agent-desktop/agent-call",
  },
  chatLoggerAgent: {
    name: "Chat log",
    routeName: "agent-desktop/agent-chat",
  },
  login: { name: "Login", routeName: "login" },
  productPage: { name: "productPage", routeName: "/productPage" },
  singOut: { name: "SignOut", routeName: "/signout" },
};

export const loggerTab = { report: "Report", transcript: "Transcript" };
export const parentChannels = ["Whatsapp", "Chat"];
