import React, { useEffect, useState } from "react";
import styles from "./PiechartComponent.module.scss";
import { noPhoneDataIcon } from "../../../../theme/assets/svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import CRMPieChartComponent from "../charts/PieChart";
import { Colors } from "../charts/colors";

// interface props {
//   default: string;
// }


export default function PieChartComponent(props: any) {
  // const [noData, setNoData] = useState<boolean>(true);

  //   const referenceKey = props.chartDetails?.refenceKeyForData;
  // console.log(dotDetails,"8888")
  //   useEffect(() => {
  //     if (props.data && props.data[referenceKey]) {
  //       props.data && props.data[referenceKey]
  //         ? Object.values(props.data[referenceKey]).every((item) => item === 0)
  //           ? setNoData(true)
  //           : setNoData(false)
  //         : null;
  //     }
  //   }, [props.data[referenceKey], props.donutChartDetails]);

  return (
    <>
      {props.data.length > 0 ? (
        <div className={styles.pieChartComponentWrapper}>
          <div className={styles.leftSideDiv}>
            <CRMPieChartComponent data={props.data} />
          </div>

          <div className={styles.rightSideDiv}>
          {props.data && props.data.map((each:any, i: number)=>{
              return(
                <div className={styles.legandsDiv} key={i}>
                <span className={styles.dot} style={{background:`${Colors[i % Colors.length]}`}}></span>
                <p className={styles.dataName}>{each.name}</p>
            </div>
              ); 
          })
              }
          </div>
        </div>
      ) : (
        <div className={styles.noDataDiv}>
          <img className={styles.noDataImgClass} src={noPhoneDataIcon} />
        </div>
      )}
    </>
  );
}
