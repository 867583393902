import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
// import PravidIcons from "../../../../components/generic/pravidIcons/PravidIcons";
import styles from "./InvoiceTab.module.scss";
import NoDatamodel from "../../../generic/noDatamodel/NoDatamodel";
import Loader from "../../../generic/loader/Loader";
import { getInvoicesAPIData } from "../../../../redux/profile/billing/invoiceDetails/actions/invoiceDetails.actions";
import { noInvoiceIcon } from "../../../../theme/assets/profileSectionSvg";
import { InvoiceTable } from "./invoiceTable/InvoiceTable";
import ReactPaginate from "react-paginate";
import { getMaiaPaymentStatus } from "../../../../redux/profile/billing/orderSummary/action/orderSummary.action";

export const InvoiceTab = () => {
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
 
  const accessToken = useSelector(
    (store: any) =>
      store?.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  )
  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
  );

  const invoicesInfo = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.invoices?.invoiceDetails
  );

  const isLoading = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.invoices?.isLoadingInvoiceDetails
  );

  const userId = useSelector(
    (state: RootState) => state?.loginReducer?.userLoginInfo?.userDetail?._id
  );

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": accessToken,
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (accountId && userId) {
      dispatch(
        getInvoicesAPIData({
          userId: userId,
          accountId: accountId,
          limit: limit,
          page: currentPage,
          token: token,
          // planExpireDate: 1,
          // planStartDate: 1,
          // price: 1,
        })
      );
    }
  }, [accountId, userId, limit, currentPage, token]);

  const handlePageChange = (page: any) => {
    setCurrentPage(Number(page.selected) + 1);
  };

  // handle function for  invoice api call 
  const invoiceApiCall  = () => {
    dispatch(
      getInvoicesAPIData({
        userId: userId,
        accountId: accountId,
        limit: limit,
        page: currentPage,
        token: token,
        // planExpireDate: 1,
        // planStartDate: 1,
        // price: 1,
      })
    );
    const body ={
      params: {
        accountId: accountId,
      },
      headers: headers
    }
    accountId && dispatch(getMaiaPaymentStatus(body))
  }
  

  return (
    <div className={styles.invoiceTableWrapper}>
      <div className={styles.invoiceList}>
        {isLoading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : invoicesInfo && Object.keys(invoicesInfo)?.length === 0 ? (
          <div
            className={styles.NoDataDiv}
            data-show={
              invoicesInfo &&
              Object.keys(invoicesInfo)?.length <= 0 &&
              !isLoading
            }
            data-testid="dataModel"
          >
            <NoDatamodel
              message="We haven't yet generated an invoice for you. Keep using Pravid."
              srcImg={noInvoiceIcon}
              extraCss={{ img: styles.imgNoData, message: styles.msgStyling }}
            />
          </div>
        ) : invoicesInfo &&
          Object.keys(invoicesInfo)?.length > 0 &&
          !isLoading ? (
          <InvoiceTable
            invoicesInfo={invoicesInfo?.invoiceDetails}
            userId={userId}
            accountId={accountId}
            limit={limit}
            page={currentPage}
            token={token}
            funApiCall ={invoiceApiCall}
          />
        ) : null}
      </div>
      {invoicesInfo?.totalPage > 1 && (
        <div className={styles.paginationDiv}>
          <div className={styles.pageTextStyling}>
            Showing <strong>{(currentPage - 1) * limit + 1}</strong> to{" "}
            <strong>
              {Math.min(currentPage * limit, invoicesInfo?.totalCount)}
            </strong>{" "}
            of <strong>{invoicesInfo?.totalCount}</strong> results
          </div>
          <div className={styles.pagination}>
            {/* <Pagination count={10} shape="rounded" color="primary" style={{}} /> */}
            <div className={styles.ok}></div>
            <div>
              <ReactPaginate
                nextLabel={">"}
                onPageChange={handlePageChange}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={invoicesInfo?.totalPage}
                forcePage={currentPage - 1}
                previousLabel="<"
                pageClassName={styles["page-item"]}
                pageLinkClassName={styles["page-link"]}
                previousClassName={styles["prev"]}
                previousLinkClassName={styles["page-link"]}
                nextClassName={styles["next"]}
                nextLinkClassName={styles["page-link"]}
                breakLabel="..."
                breakClassName={styles["page-item"]}
                breakLinkClassName={styles["page-link"]}
                containerClassName={"pagination"}
                activeClassName={styles["active"]}
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
