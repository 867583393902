import React from "react";
import styles from "./MaiaSummaryScreen.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux";
import { useNavigate } from "react-router-dom";
import { setSelectedPlanData } from "../../../../redux/profile/billing/pricingPlan/actions";
import MaiaOrderSummary from "../../../../components/moduleComponents/paymentModuleComponents/maiaSummary/orderSummary/MaiaOrderSummary";
import MaiaPaymentSummary from "../../../../components/moduleComponents/paymentModuleComponents/maiaSummary/paymentDetails/MaiaPaymentSummary";

// type Props = {}

const MaiaSummaryScreen = () => {
  const selectedPurchasePlan: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.pricingPlan?.selectedPlan
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className={styles.orderSummaryScreenWrapper}>
      <div className={styles.orderScreenHeader}>
        <div className={styles.orderScreenHeaderText}>Billing</div>
      </div>
      <div className={styles.orderSummaryScreen}>
        <div className={styles.plansummaryClass}>
          <MaiaPaymentSummary
            onClickDelete={() => {
              if (
                selectedPurchasePlan &&
                Object.keys(selectedPurchasePlan).length
              ) {
                navigate("/profile/billing/compareplans");
              } else {
                setTimeout(() => {
                  dispatch(setSelectedPlanData(undefined));
                }, 0);
                navigate("/profile/billing/plandetails");
              }
            }}
            data={selectedPurchasePlan}
            addon={selectedPurchasePlan?.addons}
          />
        </div>
        <div className={styles.ordersummaryClass}>
          <MaiaOrderSummary
            data={selectedPurchasePlan}
            addon={selectedPurchasePlan?.addOns}
          />
        </div>
      </div>
    </div>
  );
};

export default MaiaSummaryScreen;
