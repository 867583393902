import React, { useState } from "react";
import styles from "./AllNudgeOfFlow.module.scss";
import NudgeWithChannel from "../nudgeWithChannel/NudgeWithChannel";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";

export default function AllNudgeOfFlow(props: any) {
  const [selectedOne, setSelectedOne] = useState<string>("");
  const selectedNudgeData = useSelector(
    (store: RootState) =>
      store.strategyModuleReducer?.individualNudgeReducer?.selectedNudgeData
  );

  return (
    <>
      {props.data.nugeBoxes
        ? props.data.nugeBoxes.map((each: any, i: number) => {
            console.log(selectedOne, each, "uniqueeeee");
            return (
              <>
                <NudgeWithChannel
                  selectedOne={selectedOne}
                  setSelectedOne={setSelectedOne}
                  data={each}
                  key={i}
                  index={i}
                />
              </>
            );
          })
        : props.data.ranges?.map((eachT: any, idx: number) => {
            return "nugeBoxes" in eachT
              ? eachT.nugeBoxes.map((each: any, i: number) => {
                  console.log(selectedOne, each, "uniqueeeee1111");
                  return (
                    <>
                      <NudgeWithChannel
                        selectedOne={selectedOne}
                        setSelectedOne={setSelectedOne}
                        data={each}
                        key={i}
                        index={i}
                      />
                    </>
                  );
                })
              : null;
          })}
    </>
  );
}
