import actionTypes from "../createCampaign.actionTypes";

export function getCampaignASROptionsAPIData(payload: object) {
  return {
    type: actionTypes.GET_CAMPAIGN_ASR_API_REQUEST,
    payload: payload,
  };
}

export function getCampaignTTSOptionsAPIData(payload: object) {
  return {
    type: actionTypes.GET_CAMPAIGN_TTS_API_REQUEST,
    payload: payload,
  };
}

export function getCampaignOptionsAPIData() {
  return {
    type: actionTypes.GET_CAMPAIGN_OPTIONS_API_REQUEST,
  };
}

export function createCampaignAPIData(payload: object) {
  return {
    type: actionTypes.CREATE_CAMPAIGN_DATA_API_REQUEST,
    payload: payload,
  };
}

export function updateCampaignAPIData(payload: object) {
  return {
    type: actionTypes.UPDATE_CAMPAIGN_DATA_API_REQUEST,
    payload: payload,
  };
}

export function setCampaignAPIData(payload: object) {
  return {
    type: actionTypes.SET_CAMPAIGN_DATA,
    payload: payload,
  };
}

export function clearCampaignAPIData() {
  return {
    type: actionTypes.CLEAR_CAMPAIGN_DATA,
  };
}
