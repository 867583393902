import secureLocalStorage from "react-secure-storage";
const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
  window.localStorage.clear();
  window.sessionStorage.clear();
  window.location.reload();
  secureLocalStorage.clear();
};
export default clearCacheData;
