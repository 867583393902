import { Reducer } from "redux";
import actionTypes from "../allIntegrations.actionTypes";
import { integrationCardInterface } from "../interface";

export interface stateInterface {
  allIntegrationsData: object[];
  isLoadingAllIntegrationsData: boolean;
  allIntegrationsDataError: string;
  filteredData: object[];
  createIntegrationData: object;
  isCreateLoading: boolean;
  createIntegrationError: string;
  updateIntegrationData: object;
  isUpdateLoading: boolean;
  updateIntegrationError: string;
  selectedIntegrationData: any;
}

export const initialState: stateInterface = {
  allIntegrationsData: [],
  isLoadingAllIntegrationsData: false,
  allIntegrationsDataError: "",
  filteredData: [],
  createIntegrationData: {},
  isCreateLoading: false,
  createIntegrationError: "",
  updateIntegrationData: {},
  isUpdateLoading: false,
  updateIntegrationError: "",
  selectedIntegrationData: {},
};

const allIntegrationsReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.GET_ALL_INTEGRATIONS_DATA_REQUEST: {
      return {
        ...state,
        allIntegrationsData: [],
        isLoadingAllIntegrationsData: true,
        allIntegrationsDataError: "",
      };
    }
    case actionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS: {
      const integrationsData = action.payload;
      const groupedIntegrationsData = integrationsData?.reduce(
        (result: any, integration: any) => {
          const category = integration.category[0];
          if (!result[category]) {
            result[category] = [];
          }
          result[category].push(integration);
          return result;
        },
        {}
      );

      const categoriesData = Object.entries(groupedIntegrationsData).map(
        ([category, data]) => ({
          categoryName: category,
          categoryData: data,
        })
      );

      categoriesData.unshift({
        categoryName: "All",
        categoryData: integrationsData,
      });

      return {
        ...state,
        allIntegrationsData: categoriesData,
        isLoadingAllIntegrationsData: false,
        allIntegrationsDataError: "",
      };
    }

    case actionTypes.GET_ALL_INTEGRATIONS_DATA_FAILURE: {
      return {
        ...state,
        allIntegrationsData: [],
        isLoadingAllIntegrationsData: false,
        allIntegrationsDataError: action.payload,
      };
    }

    case actionTypes.FILTER_BY_CATEGORY_DATA: {
      const filteredData: any = state.allIntegrationsData?.filter(
        (item: any) => item.categoryName === action.payload
      );
      const sortedData = filteredData?.[0]?.categoryData.sort(
        (a: any, b: any) => {
          return a.position - b.position;
        }
      );
      // console.log("sort", sortedData);
      // console.log(filteredData?.[0]?.categoryData, "dddd");
      return { ...state, filteredData: sortedData };
    }

    case actionTypes.CREATE_INTEGRATION_DATA_REQUEST: {
      return {
        ...state,
        createIntegrationData: {},
        isCreateLoading: true,
        createIntegrationError: "",
      };
    }
    case actionTypes.CREATE_INTEGRATION_DATA_SUCCESS: {
      return {
        ...state,
        createIntegrationData: action.payload,
        isCreateLoading: false,
        createIntegrationError: "",
      };
    }

    case actionTypes.CREATE_INTEGRATION_DATA_FAILURE: {
      return {
        ...state,
        createIntegrationData: {},
        isCreateLoading: false,
        createIntegrationError: action.payload,
      };
    }

    case actionTypes.UPDATE_INTEGRATION_DATA_REQUEST: {
      return {
        ...state,
        updateIntegrationData: {},
        isUpdateLoading: true,
        updateIntegrationError: "",
      };
    }

    case actionTypes.UPDATE_INTEGRATION_DATA_SUCCESS: {
      return {
        ...state,
        updateIntegrationData: action.payload,
        isUpdateLoading: false,
        updateIntegrationError: "",
      };
    }

    case actionTypes.UPDATE_INTEGRATION_DATA_FAILURE: {
      return {
        ...state,
        updateIntegrationData: {},
        isUpdateLoading: false,
        updateIntegrationError: action.payload,
      };
    }

    case actionTypes.SET_SELECTED_INTEGRATION_DATA: {
      return {
        ...state,
        selectedIntegrationData: action.payload,
      };
    }

    case actionTypes.CLEAR_INTEGRATION_DATA: {
      return {
        ...state,
        selectedIntegrationData: {},
        createIntegrationData: {},
        isCreateLoading: false,
        createIntegrationError: "",
        updateIntegrationData: {},
        isUpdateLoading: false,
        updateIntegrationError: action.payload,
      };
    }

    case actionTypes.CLEAR_CREATED_INTEGRATION_DATA: {
      return {
        ...state,
        createIntegrationData: {},
        isCreateLoading: false,
        createIntegrationError: "",
        updateIntegrationData: {},
        isUpdateLoading: false,
        updateIntegrationError: "",
      };
    }

    default: {
      return state;
    }
  }
};

export default allIntegrationsReducer;
