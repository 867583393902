import { Reducer } from "redux";
import actionTypes from "../productPage.actionTypes";
import _ from "lodash";

export interface stateInterface {
  selectedProductDetails: any;
  currentProductDetails: any;
  productPortfolioDetails: any;
  domainDetails: any;
  isLoadingDomainDetails: boolean;
  domainDetailsError: string;
  productDetails: any;
  isLoadingProductDetails: boolean;
  productDetailsError: string;
  productTypeDetails: any;
  isLoadingProductTypeDetails: boolean;
  productTypeDetailsError: string;
  useCaseDetails: any;
  isLoadingUseCaseDetails: boolean;
  useCaseDetailsError: string;
  updateCategoryDetails: any;
  isLoadingUpdateCategoryDetails: any;
  updateCategoryDetailsError: string;
  productPortfolioTeams: any;
  isLoadingProductPortfolioTeams: boolean;
  productPortfolioTeamsError: string;
  showMaiaPopUp: boolean;
}

export const initialState: stateInterface = {
  selectedProductDetails: [],
  currentProductDetails: {},
  productPortfolioDetails: [],
  domainDetails: [],
  isLoadingDomainDetails: false,
  domainDetailsError: "",
  productDetails: [],
  isLoadingProductDetails: false,
  productDetailsError: "",
  productTypeDetails: [],
  isLoadingProductTypeDetails: false,
  productTypeDetailsError: "",
  useCaseDetails: [],
  isLoadingUseCaseDetails: false,
  useCaseDetailsError: "",
  updateCategoryDetails: {},
  isLoadingUpdateCategoryDetails: false,
  updateCategoryDetailsError: "",
  productPortfolioTeams: [],
  isLoadingProductPortfolioTeams: false,
  productPortfolioTeamsError: "",
  showMaiaPopUp: false,
};

const productPageReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.SAVE_SELECTED_PRODUCT_DATA: {
      return {
        ...state,
        selectedProductDetails: action.payload,
      };
    }
    case actionTypes.CURRENT_PRODUCT_DETAILS: {
      return {
        ...state,
        currentProductDetails: action.payload,
      };
    }
    case actionTypes.ADD_CURRENT_PRODUCT_TO_PORTFOLIO: {
      if (Object.keys(action.payload).length === 0) {
        return {
          ...state,
          productPortfolioDetails: [],
        };
      } else {
        return {
          ...state,
          productPortfolioDetails: action.payload,
        };
      }
    }
    case actionTypes.GET_ALL_DOMAIN_DATA_API_REQUEST: {
      return {
        ...state,
        domainDetails: [],
        isLoadingDomainDetails: true,
        domainDetailsError: "",
      };
    }
    case actionTypes.GET_ALL_DOMAIN_DATA_API_SUCCESS: {
      return {
        ...state,
        domainDetails: action.payload,
        isLoadingDomainDetails: false,
        domainDetailsError: "",
      };
    }
    case actionTypes.GET_ALL_DOMAIN_DATA_API_FAILURE: {
      return {
        ...state,
        domainDetails: [],
        domainDetailsError: action.payload,
        isLoadingDomainDetails: false,
      };
    }
    case actionTypes.GET_ALL_PRODUCT_DATA_API_REQUEST: {
      return {
        ...state,
        productDetails: [],
        isLoadingProductDetails: true,
        productDetailsError: "",
      };
    }
    case actionTypes.GET_ALL_PRODUCT_DATA_API_SUCCESS: {
      return {
        ...state,
        productDetails: action.payload,
        isLoadingProductDetails: false,
        productDetailsError: "",
        useCaseDetails: [],
      };
    }
    case actionTypes.GET_ALL_PRODUCT_DATA_API_FAILURE: {
      return {
        ...state,
        productDetails: [],
        productDetailsError: action.payload,
        isLoadingProductDetails: false,
      };
    }
    case actionTypes.GET_ALL_PRODUCT_TYPE_DATA_API_REQUEST: {
      return {
        ...state,
        productTypeDetails: [],
        isLoadingProductTypeDetails: true,
        productTypeDetailsError: "",
      };
    }
    case actionTypes.GET_ALL_PRODUCT_TYPE_DATA_API_SUCCESS: {
      return {
        ...state,
        productTypeDetailsError: "",
        productTypeDetails: action.payload,
        isLoadingProductTypeDetails: false,
        useCaseDetails: [],
      };
    }
    case actionTypes.GET_ALL_PRODUCT_TYPE_DATA_API_FAILURE: {
      return {
        ...state,
        productTypeDetails: [],
        productTypeDetailsError: action.payload,
        isLoadingProductTypeDetails: false,
      };
    }
    case actionTypes.GET_ALL_USE_CASE_DATA_API_REQUEST: {
      return {
        ...state,
        useCaseDetails: [],
        isLoadingUseCaseDetails: true,
        useCaseDetailsError: "",
      };
    }
    case actionTypes.GET_ALL_USE_CASE_DATA_API_SUCCESS: {
      return {
        ...state,
        useCaseDetails: action.payload,
        isLoadingUseCaseDetails: false,
        useCaseDetailsError: "",
      };
    }
    case actionTypes.GET_ALL_USE_CASE_DATA_API_FAILURE: {
      return {
        ...state,
        useCaseDetails: [],
        useCaseDetailsError: action.payload,
        isLoadingUseCaseDetails: false,
      };
    }
    case actionTypes.UPDATE_PRODUCT_CATEGORY_REQUEST: {
      return {
        ...state,
        updateCategoryDetails: {},
        isLoadingUpdateCategoryDetails: true,
        updateCategoryDetailsError: "",
      };
    }
    case actionTypes.UPDATE_PRODUCT_CATEGORY_SUCCESS: {
      return {
        ...state,
        updateCategoryDetails: action.payload,
        isLoadingUpdateCategoryDetails: false,
        updateCategoryDetailsError: "",
      };
    }
    case actionTypes.UPDATE_PRODUCT_CATEGORY_FAILURE: {
      return {
        ...state,
        updateCategoryDetails: {},
        updateCategoryDetailsError: action.payload,
        isLoadingUpdateCategoryDetails: false,
      };
    }
    case actionTypes.CREATE_PRODUCT_PORTFOLIO_TEAM_REQUEST: {
      return {
        ...state,
        productPortfolioTeams: [],
        isLoadingProductPortfolioTeams: true,
        productPortfolioTeamsError: "",
      };
    }
    case actionTypes.CREATE_PRODUCT_PORTFOLIO_TEAM_SUCCESS: {
      return {
        ...state,
        isLoadingProductPortfolioTeams: false,
        productPortfolioTeams: action.payload,
        productPortfolioTeamsError: "",
        productDetails: [],
        productTypeDetails: [],
        useCaseDetails: [],
        domainDetails: [],
      };
    }
    case actionTypes.CREATE_PRODUCT_PORTFOLIO_TEAM_FAILURE: {
      return {
        ...state,
        productPortfolioTeams: [],
        isLoadingProductPortfolioTeams: false,
        productPortfolioTeamsError: action.payload,
      };
    }

    case actionTypes.CLEAR_CREATED_PRODUCT_TEAMS: {
      return {
        ...state,
        productPortfolioTeams: [],
      };
    }

    case actionTypes.CLEAR_ALL_PRODUCT_DATA: {
      //except domain details clear all
      return {
        ...initialState,
        domainDetails: state.domainDetails,
      };
    }

    case actionTypes.PRODUCT_MAIA_POP_UP_CLOSE: {
      return { ...state, showMaiaPopUp: action.payload };
    }

    default: {
      return state;
    }
  }
};

export default productPageReducer;
