import React, { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import styles from "./UploadedDataTable.module.scss";
import {
  noUploadedData,
  editIcon,
  deleteIcon,
  downloadCSV,
} from "../../../../../theme/assets/svg/campaign_v3/index";
import Icon from "../../../../generic/icon/Icon";
import InputField from "../../../../generic/inputField/InputField";
import moment from "moment";
import {
  getEditCSVScreen,
  deleteUploadedCSV,
} from "../../../../../redux/campaign_V2.0/dataUpload/actions";
import Skeleton from "@mui/material/Skeleton";
import { saveAs } from "file-saver";

interface FileData {
  uploadCsvFileName: string[];
  uploadCsvPath: string[];
  validatedFile :string;
  updatedAt: string;
  failureReason: string[];
  totalCount: number;
  id: string;
  campaignManagerId?: string;
  strategyId?: string;
  isSheetError: boolean;
  isFilePicked: boolean;
  personas: any;
  language: any;
  flowType: any;
  mode:string;
}

interface UploadedDataTableProps {
  fileInfo: FileData[];
  selectedCSV: FileData[];
  sendSelectedCSVData: (method: FileData[]) => void;
  isLoading: boolean;
  showSelectedCSV?: boolean;
  isStrategy: boolean;
  selectedTab: string;
}

const TableHeader = () => {
  return (
    <thead className={styles.tableHeaderStyling}>
      <tr>
        <th>File Name</th>
        <th>Upload Date & Time</th>
        <th>Allocation Count</th>
        <th>Action</th>
      </tr>
    </thead>
  );
};

const SkeletonContainer = ({ count }: { count: number }) => {
  const skeletonElements = Array.from({ length: count }, (_, index) => (
    <Skeleton key={index} width={1000} height={50} />
  ));

  return <div>{skeletonElements}</div>;
};

export const UploadedDataTable = ({
  fileInfo,
  selectedCSV,
  sendSelectedCSVData,
  isLoading,
  showSelectedCSV,
  isStrategy,
  selectedTab,
}: UploadedDataTableProps) => {
  console.log(selectedTab, "selectedTab");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkedCSV, setCheckedCSV] = useState<FileData[]>([]);
  const [csvFilesList, setCsvFilesList] = useState<FileData[]>([]);


  const { isNavigate: isShowEditScreen } = useSelector((store: RootState) => {
    return store.campaignModuleReducer?.dataUploadData?.editScreenData;
  });

  const accountType = useSelector((state: RootState) => {
    return state.loginReducer?.userLoginInfo?.accountDetails[0]?.type;
  });

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const campaignData = useSelector(
    (store: RootState) =>
      store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
  );

  const strategyId = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.createdStrategy?.id
  );

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const isChecked = e.target.checked;
    const itemIndex = checkedCSV.findIndex((item) => item.id === id);

    if (itemIndex !== -1) {
      const updatedCSV = checkedCSV.map((item) => {
        if (item.id === id) {
          item.isFilePicked = isChecked;
        }
        return item;
      });
      setCheckedCSV(updatedCSV);
      sendSelectedCSVData(updatedCSV);
    } else {
      const fileData = fileInfo.find((item) => item.id === id);
      if (fileData) {
        fileData.isFilePicked = isChecked;
        setCheckedCSV([...checkedCSV, fileData]);
        sendSelectedCSVData([...checkedCSV, fileData]);
      }
    }
  };

  const formatDate = (inputDateStr: string) => {
    const formattedDate = moment(inputDateStr).format("DD MMM YYYY | hh:mmA");
    return formattedDate;
  };

  const handleOnClickEditIcon = (
    fileName: string,
    id: string,
    campaignManagerId: string
  ) => {
    const payloadData = {
      name: fileName,
      customerUploadInfoId: id,
      ...(isStrategy ? { strategyId } : { campaignManagerId }),
      selectedTab: selectedTab?.toLocaleLowerCase(),
    };
    dispatch(getEditCSVScreen(payloadData));
  };

  const handleOnClickDownloadIcon = (filePath: string, fileName: string) => {
    saveAs(filePath, fileName);
  };

  const handleDeleteCSV = (id: string) => {
    if (csvFilesList.length > 0) {
      dispatch(
        deleteUploadedCSV({
          csvId: id,
          campaignId: campaignData?.id,
          accountType: accountType,
          token: token,
          isStrategy: isStrategy,
          strategyId: strategyId,
        })
      );
    }
  };
 

  useEffect(() => {
    if (showSelectedCSV && selectedCSV && selectedCSV?.length > 0) {
      setCheckedCSV(selectedCSV);
    } else {
      setCheckedCSV([]);
    }
  }, [selectedCSV, showSelectedCSV]);

  useEffect(() => {
    setCsvFilesList(fileInfo);
  }, [fileInfo]);

  useEffect(() => {
    if (isShowEditScreen) {
      navigate("/campaignmanager/dataupload/editscreen");
    }
  }, [isShowEditScreen]);

  return (
    <div className={styles.uploadedDataWrappper}>
      {isLoading ? (
        <table className={styles.tableStyling}>
          <TableHeader />
          <SkeletonContainer count={6} />
        </table>
      ) : !csvFilesList?.length ? (
        <div className={styles.noDataWrapper}>
          <table className={styles.tableStyling}>
            <TableHeader />
          </table>
          <div className={styles.noDataDiv}>
            <Icon img_src={noUploadedData} extraClass={styles.iconStyling} />
            <div className={styles.noDataText}>
              Currently, no data has been added
            </div>
          </div>
        </div>
      ) : (
        <table className={styles.tableStyling}>
          <TableHeader />
          <tbody className={styles.tableBodyStyling}>
            {csvFilesList?.map((data: FileData) => (
              <tr key={data.id}>
                <td>
                  <div className={styles.fileNameDiv}>
                    {isStrategy ||
                    selectedTab?.toLocaleLowerCase() !== "allocation" ? (
                      <></>
                    ) : (
                      <InputField
                        type="checkbox"
                        className={styles.inputCheckStyling}
                        extraClass={styles.inputCheckBoxStyling}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleCheckboxChange(e, data.id);
                        }}
                        checked={data.isFilePicked}
                      />
                    )}
                    <div>
                      {data.uploadCsvFileName}{" "}
                      {data.isSheetError && (
                        <span className={styles.errorMsgStyling}>Error</span>
                      )}
                    </div>
                  </div>
                </td>
                <td>{formatDate(data.updatedAt)}</td>
                <td>{data?.totalCount}</td>
                <td className={styles.actionItems}>
                  {isStrategy ? (
                    <></>
                  ) : data.isSheetError ? (
                    <Icon
                      img_src={editIcon}
                      alt="editIcon"
                      extraClass={styles.actionIconStyling}
                      onClick={() =>
                        handleOnClickEditIcon(
                          data?.uploadCsvFileName?.[0],
                          data?.id,
                          isStrategy
                            ? data?.strategyId ?? ""
                            : data?.campaignManagerId ?? ""
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                  <Icon
                    img_src={downloadCSV}
                    alt="downloadIcon"
                    extraClass={styles.actionIconStyling}
                    onClick={() =>
                      handleOnClickDownloadIcon(
                        data?.validatedFile,
                        data?.uploadCsvFileName?.[0]
                      )
                    }
                  />
             {isStrategy ? null : (
                   data.mode !== "api" && (
                    <Icon
                      img_src={deleteIcon}
                      alt="deleteIcon"
                      extraClass={styles.actionIconStyling}
                      onClick={() => {
                        if (!data.isFilePicked) { 
                          handleDeleteCSV(data.id);
                        }
                      }}
                      disabled={data.isFilePicked}
                    />
                  )
                )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
