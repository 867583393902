import { act } from "react-dom/test-utils";
import crmActionType from "../crmActionType";
export function crmReducer(
  state: any = { screenType: "Analytics" },
  action: any
) {
  switch (action.type) {
    case crmActionType.SET_SCREEN_TYPE:
      return { ...state, screenType: action.payload };

    default:
      return state;
  }
}
