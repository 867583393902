import React, { useState, useRef, useEffect } from "react";
import styles from "./InputComponent.module.scss";
import { Switch } from "antd";
import Dropdown from "react-multilevel-dropdown";
import { arrowDDIcon } from "../../../../../theme/assets/svg/integration_V2.0/index";

interface ScriptInputProps {
  header: string;
  options?: any[];
  isDropDown: boolean;
  onValueChange?: (data: boolean) => void;
  onInputValueChange?: (data: string) => void;
  value?: boolean;
  inputValue?: string;
  placeHolderText?: string;
  noIcons?: boolean;
}

export const InputComponent = ({
  header,
  options,
  isDropDown,
  onValueChange,
  onInputValueChange,
  inputValue,
  value,
  placeHolderText,
  noIcons,
}: ScriptInputProps) => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [internalValue, setInternalValue] = useState(false);
  const dropdownRef = useRef<any>(null);

  const onChange = (checked: boolean) => {
    setInternalValue(checked); // Update the internal state when the switch changes
    if (onValueChange) {
      onValueChange(checked);
    }
  };

  const handleInputSelect = (data: string) => {
    if (onInputValueChange) {
      onInputValueChange(data);
      setIsOpen(false);
      dropdownRef.current?.toggle(false);
    }
  };

  const finalLabel = options?.find((data) => data.value === inputValue);

  useEffect(() => {
    setInternalValue(value || false);
  }, [value]);

  return (
    <div className={styles.scriptInputWrapper}>
      <div className={styles.headerText}>{header}</div>
      <div className={styles.inputDiv}>
        {isDropDown ? (
          <Dropdown
            title={
              <div className={styles.dropDownTitle}>
                {inputValue?.length === 0 ? (
                  <span className={styles.dropDownTitleText}>
                    {placeHolderText}
                  </span>
                ) : (
                  <div className={styles.dropDownSelectedText}>
                    {!noIcons ? (
                      <img src={finalLabel?.inActiveIcon} alt="icon" />
                    ) : null}
                    <span className={styles.dropDownTitleText}>
                      {finalLabel?.label}
                    </span>
                  </div>
                )}
                <img src={arrowDDIcon} alt="dropdown-arrow-icon" />
              </div>
            }
            wrapperClassName={styles.dropDownWrapper}
            buttonClassName={styles.dropDownButton}
            menuClassName={styles.dropDownMenu}
            ref={dropdownRef}
            onClick={(isOpen: boolean) => setIsOpen(isOpen)}
          >
            {options?.map((data: any, index: number) => (
              <div
                onMouseEnter={() => setHoveredItem(data?.value)}
                onMouseLeave={() => setHoveredItem(null)}
                onClick={() => handleInputSelect(data?.value)}
                key={index}
              >
                <Dropdown.Item>
                  {!noIcons ? (
                    <img
                      src={
                        hoveredItem === data?.value
                          ? data?.activeIcon
                          : data?.inActiveIcon
                      }
                      alt="icon"
                      id={"auto_img_" + data?.value}
                    />
                  ) : null}
                  <span id={"auto_" + data?.value}>{data?.label}</span>
                </Dropdown.Item>
              </div>
            ))}
          </Dropdown>
        ) : (
          <Switch
            defaultChecked={false}
            checked={internalValue}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
};
