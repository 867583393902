import React, { useEffect, useState } from "react";
import styles from "./PaymentScreen.module.scss";
import { Routes, Route } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux";
import SelectPlansPage from "../../../components/moduleComponents/paymentModuleComponents/selectPlans/selectPlansPage/SelectPlansPage";
import { BillingInvoiceScreen } from "./billingInvoiceScreen/BillingInvoiceScreen";
import { getPricingPlansAPIData } from "../../../redux/profile/billing/pricingPlan/actions/pricingPlan.actions";
import { getMyPlanAPIData } from "../../../redux/profile/billing/myPlan/actions";
import { getInvoicesAPIData } from "../../../redux/profile/billing/invoiceDetails/actions";

export const PaymentScreen = () => {
  const dispatch = useDispatch();
  const [showInvoice, setShowInvoice] = useState(false);

  const myPlanData = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  const userId = useSelector(
    (state: RootState) => state?.loginReducer?.userLoginInfo?.userDetail?._id
  );

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
  );

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  useEffect(() => {
    dispatch(
      getPricingPlansAPIData({
        currency: "INR",
        accountId: accountId,
        userId: userId,
      })
    );
  }, [userId, accountId]);

  useEffect(() => {
    dispatch(getMyPlanAPIData({ accountId: accountId }));
    dispatch(
      getInvoicesAPIData({
        userId: userId,
        accountId: accountId,
        limit: 10,
        page: 1,
        token: token,
      })
    );
  }, []);

  useEffect(() => {
    if (myPlanData && Object.keys(myPlanData)?.length !== 0) {
      setShowInvoice(true);
    } else {
      setShowInvoice(false);
    }
  }, [myPlanData]);

  return (
    <div className={styles.paymentScreenWrapper}>
      <Outlet />
      <Routes>
        <Route
          path="/"
          element={showInvoice ? <BillingInvoiceScreen /> : <SelectPlansPage />}
        ></Route>
      </Routes>
    </div>
  );
};
