import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  addPhoneNumberIcon,
  backArrowIcon,
  phoneBlue,
  removeSelectedIcon,
  removeSelectedLightIcon,
  searchIcon,
  uploadDataIcon,
} from "../../../../theme/assets/svg";
import styles from "./header.module.scss";
import Button from "../../../generic/button/Button";
import { deleteIcon } from "../../../../theme/assets/svg";
import AddPhoneNumberModal from "../addPhoneNumberModal/addPhoneNumberModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import {
  addPhoneNumberCsv,
  getPhoneNumber,
  removePhoneNumber,
  setSelectedRowsFromCheckbox,
} from "../../../../redux/exclusionsList/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import SearchInputBoxControlled from "../../../generic/searchInputBoxControlled/SearchInputBoxControlled";
import Icon from "../../uiKit/iconComponent/Icon";
import { useNavigate } from "react-router-dom";
import RemovePhoneNumberModal from "../removePhoneNumberModal/removePhoneNumberModal";

interface HeaderProps {
  paginationModel: any;
  setPaginationModel: (prevState: any) => any;
  isRemoveNumberModalVisible?: boolean;
  handleRemovePhoneNumberModal?: () => void;
}

const Header = ({ paginationModel, setPaginationModel }: HeaderProps) => {
  // USESTATE SECTION
  const [searchValue, setSearchValue] = useState("");

  const [isAddNumberModalVisible, setIsAddNumberModalVisible] = useState(false);

  const [csvFileInput, setCsvFileInput]: any = useState(null);

  const [isRemoveSelectedBtnOnHover, setIsRemoveSelectedBtnOnHover] =
    useState(false);

  const [isRemoveNumberModalVisible, setIsRemoveNumberModalVisible] =
    useState(false);

  // USESELECTOR SECTION
  const selectedRowsFromCheckbox: any = useSelector(
    (store: RootState) => store?.exclusionsListReducer?.selectedRowsFromCheckbox
  );

  const isPhoneNumberRemovedSuccessfully: any = useSelector(
    (store: RootState) =>
      store?.exclusionsListReducer?.isPhoneNumberRemovedSuccessfully
  );

  const exclusionsListType = useSelector((store: RootState) => {
    return store?.exclusionsListReducer?.exclusionsListType;
  });

  const accountId = useSelector(
    (store: any) => store.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const accountDetails = useSelector(
    (store: RootState) => store?.schedulerReducer?.accountInfoDetails?.id
  );

  // USEEFFECT SECTION
  useEffect(() => {
    if (isPhoneNumberRemovedSuccessfully) {
      dispatch(setSelectedRowsFromCheckbox([]));
    }
  }, [isPhoneNumberRemovedSuccessfully]);

  useEffect(() => {
    if (csvFileInput !== null) handleUploadCsv();
  }, [csvFileInput]);

  useEffect(() => {
    getPhoneNumberApiCall();
  }, [searchValue]);

  // HANDLERS
  //   const handleSearchInput = debounce((phoneNumber: string) => {
  //     // if (!/^\d+$/.test(phoneNumber) && phoneNumber.length) return;
  //     // if (phoneNumber.length > 10) return;
  //     // setSearchValue(phoneNumber);
  //     getPhoneNumberApiCall();
  //   }, 1000);

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = e.target.value;
    if (!/^\d+$/.test(phoneNumber) && phoneNumber.length) return;
    if (phoneNumber.length > 10) return;
    setSearchValue(phoneNumber);
  };

  const handleRemoveSelectedClick = () => {
    handleRemovePhoneNumberModal();
    // const phoneNumbers = selectedRowsFromCheckbox.map(
    //   (row: any) => row.phoneNumber
    // );
    // const data: any = {
    //   data: [...phoneNumbers],
    // };
    // if (exclusionsListType === "Global") data["isGlobal"] = true;
    // else data["accountId"] = accountId;
    // dispatch(removePhoneNumber(data));
  };

  const handleUploadCsv = () => {
    const formData = new FormData();
    if (exclusionsListType === "Global") formData.append("isGlobal", "true");
    else formData.append("accountId", accountDetails);
    formData.append("file", csvFileInput, "[PROXY]");
    dispatch(addPhoneNumberCsv(formData));
  };

  const handleAddPhoneNumberModal = () => {
    setIsAddNumberModalVisible((prevState) => !prevState);
  };

  const handleCsvBtnClick = () => {
    fileUploadRef.current.value = null;
    const node = document.getElementById("uploadCsvFileInput");
    if (node === null) return;
    node.click();
  };

  const handleAddCsvAttachment = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setCsvFileInput(e.target.files[0]);
  };

  const handleBackBtnClick = () => {
    navigate("/campaignmanager");
  };

  const handleRemovePhoneNumberModal = () => {
    setIsRemoveNumberModalVisible((prevState) => !prevState);
  };

  // MISC
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const fileUploadRef: any = useRef();

  function debounce<T extends unknown[]>(
    func: (...args: T) => void,
    delay: number
  ): (...args: T) => void {
    let timer: ReturnType<typeof setTimeout> | null = null;
    return (...args: T) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func.call(null, ...args);
      }, delay);
    };
  }

  const getPhoneNumberApiCall = () => {
    const data: any = {
      pageNumber: String(paginationModel?.page + 1),
      pageLimit: String(paginationModel?.pageSize),
      ...(searchValue?.length && { searchParam: searchValue }),
    };
    if (exclusionsListType === "Global") data["isGlobal"] = true;
    else data["accountId"] = accountDetails;
    dispatch(getPhoneNumber(data));
  };

  return (
    <>
      {exclusionsListType === "Client" && (
        <Icon
          img_src={backArrowIcon}
          extraClass={styles.backArrowIcon}
          onClick={handleBackBtnClick}
        />
      )}
      <p
        className={`${styles.exclusionsListType} ${
          exclusionsListType === "Global" ? "" : styles.extraMargin
        }`}
      >{`${exclusionsListType === "Global" ? "Global" : ""} Exclusions`}</p>
      <div className="exclusions_list_header">
        <SearchInputBoxControlled
          searchIcon={searchIcon}
          onChange={
            // (e) => {
            //   // console.log("phoneNumber 0", e.target.value);
            //   // console.log(!/^\d+$/.test(e.target.value));
            //   // console.log("searchValue", searchValue);
            //   const phoneNumber = e.target.value;
            //   if (!/^\d+$/.test(phoneNumber) && phoneNumber.length) return;
            //   if (phoneNumber.length > 10) return;
            //   setSearchValue(phoneNumber);
            //   // if (!/^\d+$/.test(phoneNumber)) {
            //   //   e.stopPropagation();
            //   //   return;
            //   // }
            //   // if (phoneNumber.length > 10) return;
            //   // console.log("phoneNumber 1", phoneNumber);
            //   handleSearchInput(phoneNumber);
            // }
            handleSearchInput
          }
          placeholder={"Search"}
          extraClassWrapper={styles.extraClassSearchWrapper}
          value={searchValue}
          inputType={"text"}
        />
        {selectedRowsFromCheckbox?.length ? (
          <span
            onMouseOver={() => setIsRemoveSelectedBtnOnHover(true)}
            onMouseOut={() => setIsRemoveSelectedBtnOnHover(false)}
          >
            <Button
              text={selectedRowsFromCheckbox.length === paginationModel.pageSize ? "Remove All" : "Remove Select"}
              image_src_left={
                !isRemoveSelectedBtnOnHover
                  ? removeSelectedIcon
                  : removeSelectedLightIcon
              }
              extraClass={styles.removeSelectedBtn}
              extraBtnImageClass={styles.removeSelectedBtnIcon}
              id="remove_selecetd_btn"
              onClick={handleRemoveSelectedClick}
            />
          </span>
        ) : null}
        <Button
          text="Add Phone Number"
          image_src_left={addPhoneNumberIcon}
          extraClass={styles.addPhoneNumberBtn}
          extraBtnImageClass={styles.addPhoneNumberBtnIcon}
          id="add_phone_number_btn"
          onClick={handleAddPhoneNumberModal}
        />
        <a onClick={handleCsvBtnClick} href="javascript:undefined">
          <Button
            text="Upload CSV"
            image_src_left={uploadDataIcon}
            extraClass={styles.uploadCsvBtn}
            extraBtnImageClass={styles.uploadCsvBtnIcon}
            id="upload_csv_btn"
          />
        </a>
        <input
          className={styles.uploadCsvInput}
          type="file"
          id="uploadCsvFileInput"
          name="uploadCsvFileInput"
          accept=".csv"
          onChange={handleAddCsvAttachment}
          ref={fileUploadRef}
        />
        <AddPhoneNumberModal
          isAddNumberModalVisible={isAddNumberModalVisible}
          handleAddPhoneNumberModal={handleAddPhoneNumberModal}
        />
        <RemovePhoneNumberModal
          isRemoveNumberModalVisible={isRemoveNumberModalVisible}
          handleRemovePhoneNumberModal={handleRemovePhoneNumberModal}
          selectedRowsFromCheckbox={selectedRowsFromCheckbox}
          type={"bulk"}
        />
      </div>
    </>
  );
};

export default Header;
