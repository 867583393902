import React, { useEffect, useState, useRef } from "react";
import styles from "./ClusterComponent.module.scss";
// import Dropdown from "react-multilevel-dropdown";
import { InfoToolTip } from "../../../../generic/infoToolTip/InfoToolTip";
import {
  arrowDDIcon,
  darkArrowDDIcon,
  addCluster,
  deleteCluster,
} from "../../../../../theme/assets/svg/integration_V2.0/index";
import Icon from "../../../../generic/icon/Icon";
import InputContainer from "../../../../generic/inputContainer/InputContainer";
import { useSelector } from "react-redux";
import { Dropdown, Menu, Space } from "antd";
import type { MenuProps } from "antd";

interface props {
  dataCenterOptions: Array<string>;
  clientTelephonyDetails: any;
  schemaDefault?: any;
  getAllDetails?: any;
  addMoreCluster?: any;
  removeCluster?: any;
  allClusterData?: any;
  clustersDetails?: any;
  onChangeStatus?: any;
}

export const ClusterComponent = (props: props) => {
  const [cluserInfo, setCluserInfo] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenGW, setIsOpenGW] = useState(false);
  const dropdownRefGW = useRef<any>(null);
  const [dataCenter, setDataCenters] = useState<any>([]);

  const [sipGateways, setSipGateways] = useState([{}]);

  const dropdownDCRef = useRef<any>(null);
  const targetRef = useRef<HTMLDivElement | null>(null);

  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  const onClickDataCenter: MenuProps["onClick"] = ({ key, domEvent }) => {
    domEvent.preventDefault();
    // message.info(`Click on item ${key}`);
    handleDataCenterSelect(key);
  };

  const getDataCenterMenu = () => (
    <Menu onClick={onClickDataCenter}>
      {dataCenter?.map((item: any) => (
        <Menu.Item key={item} className={styles.dropDownButtonDiv}>
          {item}
        </Menu.Item>
      ))}
    </Menu>
  );

  const onClickGateway = (item: any, index: number, range: string) => () => {
    handleGatewaySelect(item, index, range);
  };

  const getGateWayMenu = (idx: number) => (
    <Menu>
      {sipGateways?.map((item: any) => (
        <Menu.Item
          key={idx}
          onClick={onClickGateway(item, idx, item.did_range)}
        >
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  // const sipGateways = props.sipGatewaysoptions;

  const ConvertAvailableRanges = (
    dataInput: string,
    availableRange: string,
    name: string
  ) => {
    const dataInputRange = dataInput
      ?.split(",")
      ?.map((s) => s?.trim()?.split("-")?.map(Number));

    const ranges = (availableRange || didRangesObj[name])
      ?.split(",")
      ?.map((s) => s?.trim()?.split("-")?.map(Number))
      ?.map((a) => a?.filter((n) => !isNaN(n)));

    let isInRange = false;

    if (ranges) {
      for (const range of ranges) {
        if (
          dataInputRange[0][0] >= range[0] &&
          dataInputRange[0][1] <= range[1] &&
          dataInputRange[0][0] < dataInputRange[0][1]
        ) {
          isInRange = true;
          break;
        }
      }
    }

    return isInRange;
  };

  const handleDataCenterSelect = (data: string) => {
    props.onChangeStatus(true);
    const body = {
      ...cluserInfo,
      cluster_name: data,
      gateways: [
        { name: "", did_range: "", available_ranges: "", isValid: false },
      ],
    };
    setCluserInfo(body);
    props.getAllDetails(body);

    // setCluserInfo((prev) => ({
    //   ...prev,
    //   cluster_name: data,
    //   gateways: [
    //     { name: "", did_range: "", available_ranges: "", isValid: false },
    //   ],
    // }));
    setIsOpen(false);
    if (dropdownDCRef.current) {
      dropdownDCRef.current?.toggle(false);
    }
  };

  const handleGatewaySelect = (data: any, index: number, range: string) => {
    const body = {
      ...cluserInfo,
      gateways: cluserInfo?.gateways?.map((obj: any, i: number) => {
        if (i === index) {
          return {
            ...obj,
            name: data?.name,
            available_ranges: range,
            did_range: "",
          };
        }
        return obj;
      }),
    };

    props.onChangeStatus(true);
    setCluserInfo(body);

    props.getAllDetails(body);
    // setCluserInfo((prevState) => ({
    //   ...prevState,
    //   gateways: prevState.gateways.map((obj, i) => {
    //     if (i === index) {
    //       return { ...obj, name: data?.name, available_ranges: range };
    //     }
    //     return obj;
    //   }),
    // }));
    setIsOpenGW(false);
    dropdownRefGW.current?.toggle(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;

    if (e.target.name === "did_range") {
      // setCluserInfo((prevState) => ({
      //   ...prevState,
      //   gateways: [{ ...prevState.gateways[0], did_range: value }],
      // }));
      const body = {
        ...cluserInfo,
        gateways: cluserInfo?.gateways?.map((obj: any, i: number) => {
          if (i === index) {
            return {
              ...obj,
              did_range: value?.replace(/[^0-9/-]/g, ""),
              isValid: ConvertAvailableRanges(
                value?.replace(/[^0-9/-]/g, ""),
                obj?.available_ranges,
                obj?.name
              ),
            };
          }
          return obj;
        }),
      };
      props.onChangeStatus(true);
      setCluserInfo(body);
      props.getAllDetails(body);
      // setCluserInfo((prevState) => ({
      //   ...prevState,
      //   gateways: prevState.gateways.map((obj, i) => {
      //     if (i === index) {
      //       return {
      //         ...obj,
      //         did_range: value,
      //         isValid: ConvertAvailableRanges(value, obj?.available_ranges),
      //       };
      //     }
      //     return obj;
      //   }),
      // }));
    } else {
      const body = {
        ...cluserInfo,
        [e.target.name]: value?.replace(/[^0-9]/g, ""),
      };
      props.onChangeStatus(true);
      setCluserInfo(body);
      props.getAllDetails(body);

      // setCluserInfo((prevState) => ({
      //   ...prevState,
      //   [e.target.name]: value,
      // }));
    }
  };

  const handleAddSipGateway = () => {
    if (cluserInfo?.cluster_name?.length > 0) {
      const newSipGatewayObj = {
        name: "",
        did_range: "",
        available_ranges: "",
        isValid: false,
      };
      const body = {
        ...cluserInfo,
        gateways: [...cluserInfo.gateways, newSipGatewayObj],
      };
      props.onChangeStatus(true);
      setCluserInfo(body);
      props.getAllDetails(body);
      // setCluserInfo((prevState) => ({
      //   ...prevState,
      //   gateways: [...prevState.gateways, newSipGatewayObj],
      // }));
      scrollToTarget();
    }
  };

  const handleDeleteSIPObject = (objectToDelete: any) => {
    const indexToDelete = cluserInfo?.gateways.findIndex(
      (obj: any) => obj === objectToDelete
    );
    if (indexToDelete > -1) {
      cluserInfo?.gateways.splice(indexToDelete, 1);
    }
    const body = {
      ...cluserInfo,
      gateways: cluserInfo?.gateways,
    };
    props.onChangeStatus(true);
    setCluserInfo(body);
    props.getAllDetails(body);

    // setCluserInfo((prevState) => ({
    //   ...prevState,
    //   gateways: cluserInfo?.gateways,
    // }));
  };

  /** useEffects **/
  /**  set client cluster Detail  */
  // useEffect(() => {
  //   setCluserInfo(props.clientTelephonyDetails);
  // }, [props.clientTelephonyDetails]);

  useEffect(() => {
    if (props.dataCenterOptions !== undefined) {
      setDataCenters(props.dataCenterOptions);
    }
  }, [props.dataCenterOptions]);

  useEffect(() => {
    getDataCenterMenu();
  }, [dataCenter]);

  useEffect(() => {
    const dataUpdated = JSON.parse(JSON.stringify(cluserInfo));
    dataUpdated?.gateways?.map((e: any) => {
      if (e?.available_ranges) {
        delete e?.available_ranges;
        delete e?.isValid;
        return e;
      }
    });
    // props.getAllDetails(dataUpdated);
  }, [cluserInfo]);

  /** onchange Data center gateway option **/
  useEffect(() => {
    props.schemaDefault?.clusters?.map((e: any) => {
      if (e?.cluster_name === cluserInfo?.cluster_name) {
        setSipGateways(e?.gateways);
      }
    });
  }, [cluserInfo, props.schemaDefault]);

  // useEffect(() => {
  //   getGateWayMenu();
  // }, [sipGateways]);

  useEffect(() => {
    if (
      props.clientTelephonyDetails &&
      Object.keys(props.clientTelephonyDetails)?.length
    ) {
      if (Object.keys(props.clientTelephonyDetails)?.length === 1) {
        const body = { ...props.clientTelephonyDetails };
        body.gateways = [
          { name: "", did_range: "", available_ranges: "", isValid: false },
        ];
        body.cluster_name = "";
        setCluserInfo(body);
      } else {
        setCluserInfo(props.clientTelephonyDetails);
      }
    } else {
      setCluserInfo({
        cluster_name: "",
        capacity: "",
        weightage: "",
        gateways: [
          { name: "", did_range: "", available_ranges: "", isValid: false },
        ],
      });
    }
  }, [props.clientTelephonyDetails]);

  /** for getting dropdown Data center**/

  const didRangesObj: Record<string, string> = {};
  sipGateways.map((e: any) => {
    didRangesObj[e.name] = e.did_range;
  });

  return (
    <div className={styles.clusterWrapper}>
      <div className={styles.clusterTopWrapper}>
        <div className={styles.dataCenterDropDown}>
          <div className={styles.ddLabelStyling}>
            <div className={styles.ddLabelNameText}>Data Center</div>
            <InfoToolTip
              message={"Data Center Info"}
              position="top-end"
              isError={false}
            />
          </div>
          <div
            className={` ${
              props.schemaDefault?.showFields?.cluster_name
                ? styles.dataCenterDDBtnStyling
                : styles.dataCenterDDBtndisableStyling
            } `}
          >
            <Dropdown
              overlay={getDataCenterMenu()}
              overlayClassName={styles.dataCenterMenuStylingDiv}
              // trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space className={styles.dropDownButtonDiv}>
                  <div className={styles.dropDownTitle}>
                    <div className={styles.dropDownTitleText}>
                      {cluserInfo?.cluster_name?.length === 0 ? (
                        <span className={styles.dataCenterPlaceholder}>
                          Select{" "}
                        </span>
                      ) : (
                        <span>{cluserInfo?.cluster_name}</span>
                      )}
                    </div>
                    <Icon
                      img_src={arrowDDIcon}
                      extraClass={styles.buttonIconStyling}
                    />
                  </div>
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
        <div className={styles.portDiv}>
          <InputContainer
            label="Ports"
            inputType="text"
            inputPlaceholder="Enter"
            inputValue={cluserInfo?.capacity ? cluserInfo?.capacity : ""}
            inputName="capacity"
            inputOnChange={handleInputChange}
            showLabel={true}
            showToolTipWithLabel={true}
            showNewToolTip
            hideInputToolTip={true}
            showNewToolTipPosition={"top-end"}
            showNewToolTipMsg={"Port Info"}
            toolTipSize={false}
            extraInputClass={`${styles.inputDivStyling} ${
              props?.schemaDefault?.showFields?.capacity
                ? ""
                : styles.disabledInputDivStyling
            }`}
            extraLabelClass={styles.inputLabelStyling}
            extraLabelDivClass={styles.labelDivStyling}
            extraUserInputClass={`${styles.inputMainDivStyling} ${
              props?.schemaDefault?.showFields?.capacity
                ? ""
                : styles.disabledInputDivStyling
            }`}
            inputOnKeyDown={(e: any) => {
              if (!props?.schemaDefault?.showFields?.weightage) {
                if (e.key === "Tab") {
                  e.preventDefault();
                }
              }
            }}
          />
        </div>
        <div className={styles.weightageDiv}>
          <InputContainer
            label="Weightage"
            inputType="text"
            inputPlaceholder="Enter"
            inputValue={cluserInfo?.weightage ? cluserInfo?.weightage : ""}
            inputName="weightage"
            inputOnChange={handleInputChange}
            showLabel={true}
            showToolTipWithLabel={true}
            showNewToolTip
            hideInputToolTip={true}
            showNewToolTipPosition={"top-end"}
            showNewToolTipMsg={"Weightage Info"}
            toolTipSize={false}
            extraInputClass={`${styles.inputDivStyling} ${
              props?.schemaDefault?.showFields?.weightage
                ? ""
                : styles.disabledInputDivStyling
            }`}
            extraLabelClass={styles.inputLabelStyling}
            extraLabelDivClass={styles.labelDivStyling}
            extraUserInputClass={`${styles.inputMainDivStyling}  ${
              props?.schemaDefault?.showFields?.weightage
                ? ""
                : styles.disabledInputDivStyling
            }`}
            inputOnKeyDown={(e: any) => {
              if (!props?.schemaDefault?.showFields?.weightage) {
                if (e.key === "Tab") {
                  e.preventDefault();
                }
              }
            }}
          />
        </div>
      </div>
      <div className={styles.clusterMidWrapper}>
        <div className={styles.gatewayLabel}>Gateways</div>
        <div
          className={`${styles.gatewaysGrpDiv} ${
            cluserInfo?.cluster_name?.length === 0 ? "" : ""
          }`}
        >
          {cluserInfo?.gateways?.map((data: any, index: number) => {
            return (
              <div className={styles.gatewayDiv} key={index}>
                <div
                  className={`${styles.gatewaysInputDiv} ${
                    cluserInfo?.cluster_name?.length === 0
                      ? styles.disabledInputDivStyling
                      : ""
                  }`}
                  ref={
                    index === cluserInfo?.gateways.length - 1 ? targetRef : null
                  }
                >
                  <div className={styles.gatewaysTopInputDiv}>
                    <div className={styles.gatewayDropDown}>
                      <Dropdown
                        overlay={getGateWayMenu(index)}
                        overlayClassName={styles.gateWayMenuStylingDiv}
                        // trigger={["click"]}
                      >
                        <a onClick={(e) => e.preventDefault()}>
                          <Space className={styles.dropDownButtonDiv}>
                            <div
                              className={`${styles.gwdropDownTitle} ${
                                cluserInfo?.cluster_name?.length === 0
                                  ? styles.disabledInputDivStyling
                                  : ""
                              }`}
                            >
                              <div
                                className={`${styles.gwdropDownTitleText} 
                              `}
                              >
                                {data?.name?.length === 0 ? (
                                  <span
                                    className={`${styles.gwPlaceholder}  ${
                                      cluserInfo?.cluster_name?.length === 0
                                        ? styles.disabledInputDivStyling
                                        : ""
                                    }`}
                                  >
                                    SIP Gateway{" "}
                                  </span>
                                ) : (
                                  <span>{data?.name}</span>
                                )}
                              </div>
                              <Icon
                                img_src={darkArrowDDIcon}
                                extraClass={styles.buttonIconStyling}
                              />
                            </div>
                          </Space>
                        </a>
                      </Dropdown>
                    </div>
                    <div className={styles.dividerIconStyling}></div>
                    <div
                      className={`${styles.inputGatewayDiv}  ${
                        cluserInfo?.cluster_name?.length === 0
                          ? styles.disabledInputDivStyling
                          : ""
                      }`}
                    >
                      <InputContainer
                        inputType="text"
                        inputPlaceholder="DID Range"
                        inputValue={data?.did_range ? data?.did_range : ""}
                        inputName="did_range"
                        inputOnChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleInputChange(event, index)}
                        showLabel={false}
                        showNewToolTip={false}
                        noErrorMsg={true}
                        extraInputClass={`${styles.gwInputDivStyling}
                       ${
                         cluserInfo?.cluster_name?.length === 0
                           ? styles.disabledInputDivStyling
                           : ""
                       } ${data?.isValid === false ? styles.errMsg : ""}`}
                        inputIconRight={
                          cluserInfo?.gateways.length !== 1 ? deleteCluster : ""
                        }
                        onClickRightIcon={() => handleDeleteSIPObject(data)}
                        extraLabelClass={styles.gwInputLabelStyling}
                        extraLabelDivClass={styles.gwLabelDivStyling}
                        extraUserInputClass={`${styles.gwInputMainDivStyling} 
                       ${
                         cluserInfo?.cluster_name?.length === 0
                           ? styles.disabledInputDivStyling
                           : ""
                       }`}
                      />
                    </div>
                  </div>
                  {data?.name?.length > 0 && (
                    <div className={styles.availableDidDiv}>
                      <div className={styles.availHeading}>Available DID</div>
                      <div className={styles.availText}>
                        {data?.available_ranges || didRangesObj[data?.name]}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.addClusterDiv}>
          {props?.schemaDefault?.showFields?.gateways && (
            <Icon
              img_src={addCluster}
              extraClass={styles.buttonIconStyling}
              onClick={handleAddSipGateway}
            />
          )}
        </div>
      </div>
      <div className={styles.addDelClusterButton}>
        <span onClick={() => props.addMoreCluster()}>Add</span>
        {props.allClusterData?.length > 1 && (
          <span onClick={() => props.removeCluster()}>/Delete</span>
        )}
      </div>
    </div>
  );
};
