import React, { useEffect, useRef } from "react";
import styles from "./AgentCard.module.scss";
import {
  threedot,
  callIcon,
  whatsAppIcon,
  emailIcon,
  smsIcon,
  edits,
  deletes,
} from "../../../../theme/assets/svg";
import {
  femaleAgent,
  maleAgent,
  whatsappGrey,
  mailGrey,
  smsGrey,
  callGrey,
  duplicateGrey,
} from "../../../../theme/assets/genericSvg";
import { useState } from "react";
import { threeDotVertical } from "../../../../theme/assets/genericSvg";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const preventDefault = (event: any) => {
  event.preventDefault();
};

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#F6F6F6",
    borderRadius: "1.20455px",
    padding: "10px",
    color: "#676767",
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: 400,
    overflowWrap: "break-word",
  },
  customArrow: {
    color: "#F6F6F6",
    fontSize: 9,
  },
}));

interface AgentCardProps {
  agentInfo: any;
  setClickedCard: (name: string) => void;
  clickedCard: string;
  index: number;
  editButtonFun: () => void;
  duplicateBtnFunc: () => void;
  deleteButtonFun: () => void;
  isStrategyToShow: boolean;
}

export default function AgentCard({
  agentInfo,
  setClickedCard,
  clickedCard,
  index,
  editButtonFun,
  duplicateBtnFunc,
  deleteButtonFun,
  isStrategyToShow,
}: AgentCardProps) {
  const refD = useRef<any>();
  const classes = useStyles();
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [xy, setXy] = useState({ x: 0, y: 0 });

  useEffect(() => {
    let sidebarToggleListener: (e: MouseEvent) => void;
    if (showOptions) {
      sidebarToggleListener = (event: MouseEvent) => {
        if (refD.current && !refD.current.contains(event.target)) {
          setShowOptions(false);
        }
      };

      document.addEventListener("wheel", preventDefault, { passive: false });
      document.addEventListener("touchmove", preventDefault, {
        passive: false,
      });

      window.addEventListener("click", sidebarToggleListener);

      return () => {
        window.removeEventListener("click", sidebarToggleListener);
        document.removeEventListener("wheel", preventDefault);
        document.removeEventListener("touchmove", preventDefault);
      };
    }
  }, [refD, showOptions]);

  const NameShowAgent = [];
  agentInfo.maleAgentName?.length &&
    NameShowAgent.push(agentInfo.maleAgentName);
  agentInfo.femaleAgentName?.length &&
    NameShowAgent.push(agentInfo.femaleAgentName);
  return (
    <>
      <div className={styles.agent_card_wrapper}>
        <div className={styles.agent_card__header}>
          <div className={styles.avatar}>
            {agentInfo.maleAgentName && <img src={maleAgent} alt="" />}
            {agentInfo.femaleAgentName && (
              <img
                src={femaleAgent}
                alt=""
                className={`${
                  agentInfo.maleAgentName ? styles.femaleAgentAvatar : null
                }`}
              />
            )}
          </div>
          <div className={styles.infoDiv}>
            <div className={styles.name}>
              {NameShowAgent?.length > 1
                ? NameShowAgent.join(" & ")
                : NameShowAgent}
            </div>
            <div
              className={styles.id}
              title={agentInfo.name}
            >{`Agent ID: ${agentInfo.name}`}</div>
          </div>

          {!isStrategyToShow ? (
            <></>
          ) : (
            <img
              ref={refD}
              onClick={(event) => {
                event.stopPropagation();
                setXy({ y: event.clientY, x: event.clientX });
                setClickedCard(agentInfo.name);
                setShowOptions(!showOptions);
              }}
              src={threeDotVertical}
              alt="s"
              id="auto_three_dot_img"
              className={styles.threeDotVertical}
            />
          )}
        </div>
        <div className={styles.cardBody}>
          <div className={styles.channels}>
            <span>Channels</span>
            <div className={styles.strategyModeDiv} id="auto_strategyModeDiv">
              <div className={styles.img_sections}>
                {agentInfo?.channels?.map((each: string) => {
                  if (each.toLowerCase() === "whatsapp") {
                    console.log("matched", each);
                    return (
                      <img
                        key={each}
                        src={whatsappGrey}
                        alt="whatsapp"
                        id="auto_whatsapp_icon"
                        className={styles.whatsappImage}
                      />
                    );
                  } else if (each.toLowerCase() === "sms") {
                    return (
                      <img
                        key={each}
                        src={smsGrey}
                        alt="whatsapp"
                        id="auto_whatsapp_icon"
                      />
                    );
                  } else if (each.toLowerCase() === "mail") {
                    return (
                      <img
                        key={each}
                        src={mailGrey}
                        alt="mail"
                        id="auto_mail_icon"
                      />
                    );
                  } else if (
                    each.toLowerCase() === "call" ||
                    each.toLowerCase() === "voice"
                  ) {
                    return (
                      <img
                        key={each}
                        src={callGrey}
                        alt="call"
                        id="auto_call_icon"
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
          <div className={styles.languages}>
            <span>Languages</span>
            <span className={styles.language_circle__wrapper}>
              {[...agentInfo.languages.slice(0, 3)].map((item) => (
                <span className={styles.language_circle}>{item}</span>
              ))}
              {agentInfo.languages.length > 3 ? (
                <Tooltip
                  title={agentInfo.languages
                    .slice(3, agentInfo.languages.length)
                    .join(", ")}
                  placement={"top-end"}
                  classes={{
                    arrow: classes.customArrow,
                    tooltip: classes.customTooltip,
                  }}
                  arrow={true}
                >
                  <span className={styles.language_circle}>{`+${
                    agentInfo.languages.length - 3
                  }`}</span>
                </Tooltip>
              ) : (
                <></>
              )}
            </span>
          </div>
          <div className={styles.desc}>{agentInfo.description}</div>
        </div>
      </div>
      {showOptions && clickedCard == agentInfo.name ? (
        <>
          <div
            className={styles.dropdown}
            style={
              index % 4 != 0
                ? { top: xy.y - 30, left: xy.x + 22 }
                : { top: xy.y - 30, left: xy.x - 100 }
            }
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                editButtonFun();
              }}
              id="auto_edit_btn"
            >
              <img src={edits} alt="" id="auto_edit_icon" />
              <p>Edit</p>
            </button>
            <div className={styles.divides}></div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                duplicateBtnFunc();
              }}
              id="auto_duplicate_btn"
            >
              <img
                src={duplicateGrey}
                alt=""
                id="auto_duplicate_icon"
                className={styles.duplicateIcon}
              />
              <p>Duplicate</p>
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                deleteButtonFun();
              }}
              id="auto_delete_btn"
            >
              <img src={deletes} alt="" id="auto_delete_icon" />
              <p>Delete</p>
            </button>
          </div>
        </>
      ) : null}
    </>
  );
}
