import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import loginReducer from "./onboarding/login/reducer";
import rootLoggerReducer from "./logger";
import rootBaseScreenReducer from "./baseScreen";
import integartionReducers from "./integration/reducer";
import breadcrumReducer from "./breadcrum/reducer";
import dashboardReducer from "./dashboard/reducer";
import mathFunctionReducer from "./reduxSagaExample/reducers";
import razorpayReducer from "./razorpay/reducer";
import filterReducer from "./filter/reducer";
import demoReducer from "./demo/reducer";
import operationReducer from "./adminView/operation/reducer";
import filterReducers from "./filters/reducer/filterReducer";
import campaignReducer from "./campaign/reducer";
import omniChannelReducer from "./omniChannel/reducer";
import schedulerReducer from "./campaign/scheduler/reducer";
import analyticsReducer from "./analytics/reducer";
import allClientReducer from "./allClient/allClient.RootReducer";
import campaignTestingInfoReducer from "./campaignTestingInfo/reducer";
import mixpanelReducer from "./mixpanel/reducer";
import profileReducer from "./profile/profile.rootReducer";
import completedCampaignReducer from "./completedCampaign/reducer/completedCampaign.reducer";
import agentDesktopReducer from "./agentDesktop/agentDesktop.rootReducer";
import rootStrategyReducer from "./strategy/strategy.rootReducer";
import rootCampaignReducer from "./campaign_V2.0/campaignV2.rootReducer";
import leftMenuReducer from "./baseScreen/leftMenu/leftMenu.reducer";
import { crmReducer } from "./crm/reducer/reducer.crm";
import rootSignUpFlowReducer from "./signUpFlow/signUpFlow.rootReducer";
import rootIntegrationReducer from "./integration_V2.0/integration.rootReducer";
import exclusionsListReducer from "./exclusionsList/reducer";

// const persistLoginConfig = {
//   key: "login",
//   storage: storage,
//   whitelist: ["userLoginInfo", "isActivePageType"],
// };
// const persistBreadcrumConfig = {
//   key: "breadcrum",
//   storage: storage,
//   whitelist: ["breadcrumName", "diallerTimeData", "callingCondition"],
// };
// const persistMixpanel = {
//   key: "mixpanel",
//   storage: storage,
//   whitelist: ["moduleClickCount", "transcriptSessionIdDetails"],
// };
// const profileConfig = {
//   key: "profile",
//   storage: storage,
//   whitelist: [
//     "navigationReducer",
//     "yourAccountReducer",
//     "billingReducer",
//     "settingsReducer",
//   ],
// };
// const persistCampaign = {
//   key: "campaign",
//   storage: storage,
//   whitelist: ["showCallingListScreen", "callingListData", "datefiltercampaign"],
// };
const allReducers = {
  agentDesktopReducer,
  loginReducer: loginReducer,
  integartionReducers,
  baseScreen: rootBaseScreenReducer,
  loggerReducer: rootLoggerReducer,
  mathFunctionReducer,
  breadcrumReducer: breadcrumReducer,
  razorpayReducer,
  filterReducer,
  demoReducer,
  allClientReducer,
  operationReducer,
  dashboardReducer,
  filterReducers,
  campaignReducer: campaignReducer,
  completedCampaignReducer,
  omniChannelReducer,
  schedulerReducer,
  campaignTestingInfoReducer,
  analyticsReducer,
  mixpanelReducer: mixpanelReducer,
  profile: profileReducer,
  strategyModuleReducer: rootStrategyReducer,
  campaignModuleReducer: rootCampaignReducer,
  leftMenuReducer: leftMenuReducer,
  signUpModuleReducer: rootSignUpFlowReducer,
  crmReducer,
  integrationReducer: rootIntegrationReducer,
  exclusionsListReducer,
};

// const sortObject = (o: { [x: string]: any }) =>
//   Object.keys(o)
//     .sort()
//     .reduce((r: any, k) => ((r[k] = o[k]), r), {});
// allReducers = sortObject(allReducers);

const topLevelReducer = combineReducers(allReducers);

const rootReducers = (state: any, action: any) => {
  if (action.type === "USER_LOGOUT") {
    // storage.removeItem("persist:root");
    return topLevelReducer(undefined, action);
  }
  return topLevelReducer(state, action);
};

export default rootReducers;
