import { call, put } from "redux-saga/effects";
import { config, EXCLUSIONS_LIST_API } from "../../../services/ApiRoutes";
import actionTypes from "../exclusionsList.actionTypes";
// import teamActionTypes from "../../teamsPage/teamsPage.actionTypes";
import { toast } from "react-toastify";

export function* getPhoneNumberAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${EXCLUSIONS_LIST_API.GET_PHONE_NUMBER}`,
        action.payload
      );

      yield put({
        type: actionTypes.GET_PHONE_NUMBER_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_PHONE_NUMBER_FAILURE,
      payload: error,
    });
    toast.error("Error fetching Phone Numbers");
    console.error(error);
  }
}

export function* addPhoneNumberAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${EXCLUSIONS_LIST_API.ADD_PHONE_NUMBER}`,
        action.payload
      );

      yield put({
        type: actionTypes.ADD_PHONE_NUMBER_SUCCESS,
        payload: result.data.data,
      });

      if (
        result?.data?.data?.data &&
        result?.data?.data?.message !==
          "The phone number you entered already exists"
      )
        yield toast.success(`${result.data.data.message}`);
      else
        yield put({
          type: actionTypes.ADD_PHONE_NUMBER_FAILURE,
          payload: result.data.data.message,
        });
      //   yield toast.error(`${result.data.data.message}`);
    }
  } catch (error) {
    yield put({
      type: actionTypes.ADD_PHONE_NUMBER_FAILURE,
      payload: error,
    });
    toast.error("Error adding Phone Numbers");
    console.error(error);
  }
}

export function* addPhoneNumberCsvAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${EXCLUSIONS_LIST_API.ADD_PHONE_NUMBER_CSV}`,
        action.payload
      );

      yield put({
        type: actionTypes.ADD_PHONE_NUMBER_CSV_SUCCESS,
        payload: result.data.data,
      });

      yield toast.success(
        `${result.data.data.message} ${result.data.data.passed} Phone Numbers`
      );
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.ADD_PHONE_NUMBER_CSV_FAILURE,
      payload: error,
    });
    toast.error(error?.response?.data?.message);
    console.error(error);
  }
}

export function* removePhoneNumberAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${EXCLUSIONS_LIST_API.REMOVE_PHONE_NUMBER}`,
        action.payload
      );

      yield put({
        type: actionTypes.REMOVE_PHONE_NUMBER_SUCCESS,
        payload: result.data.data,
      });

      yield toast.success(result.data.data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.REMOVE_PHONE_NUMBER_FAILURE,
      payload: error,
    });
    toast.error("Error removing Phone Number(s)");
    console.error(error);
  }
}
