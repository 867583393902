import React, { useEffect, useState } from "react";
import styles from "./MaiaAnalyticsPage.module.scss";
import CallDispositionTable from "../../components/crm/moduleComponent/maiaCallTable/callDispositionTable/CallDispositionTable";
import DropRateAnalysisTable from "../../components/crm/moduleComponent/maiaCallTable/dropRateAnalysisTable/DropRateAnalysisTable";
import CrmHeader from "../../components/crm/moduleComponent/crmHeader/CrmHeader";
import MaiaCallPage from "./call/MaiaCall";
import MaiaWhatsappPage from "./whatsapp/MaiaWhatsapp";
export default function MaiaAnalyticsPage() {
  const [screen, setScreen] = useState<string>("Call");
  return (
    <div className={styles.wrapper}>
      <header>
        <CrmHeader setDataOut={setScreen} options={["Call", "Whatsapp"]} />
      </header>

      {screen === "Call" ? <MaiaCallPage /> : <MaiaWhatsappPage />}
    </div>
  );
}
