import { takeLatest } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import {
  getCallLoggerSummaryWorker,
  getCallLoggerTranscriptWorker,
  getSessionID,
  getStatusForChat,
  updateSummaryWorker,
  getCannedMessage,
  getStatusForWhatsAppChatWorker,
} from "./chatLogger.saga";

export default function* chatLoggerAgentDesktopWatcher(): any {
  yield takeLatest(
    actionTypes.GET_SUMMARY_REQUEST_AGENT_DESKTOP_CHAT,
    getCallLoggerSummaryWorker
  );
  yield takeLatest(
    actionTypes.GET_TRANSCRIPT_REQUEST_AGENT_DESKTOP_CHAT,
    getCallLoggerTranscriptWorker
  );
  yield takeLatest(
    actionTypes.GET_SESSION_ID_REQUEST_AGENT_DESKTOP_CHAT,
    getSessionID
  );
  yield takeLatest(
    actionTypes.GET_SESSION_ID_REQUEST_WITHOUT_REFRESH_AGENT_DESKTOP_CHAT,
    getSessionID
  );
  yield takeLatest(
    actionTypes.GET_STATUS_OF_CHAT_AGENT_DESKTOP_API_CALL,
    getStatusForChat
  );
  yield takeLatest(
    actionTypes.UPDATE_SUMMARY_CHAT_REQUEST_AGENT_DESKTOP,
    updateSummaryWorker
  );
  yield takeLatest(actionTypes.GET_CANNED_MESSAGES_API_CALL, getCannedMessage);
  yield takeLatest(
    actionTypes.GET_STATUS_FOR_WHATSAPP_CHAT_API_CALL,
    getStatusForWhatsAppChatWorker
  );
}
