import axios from "axios";
import Moment from "moment";
import CAMPAIGN_TESTING_ACTION from "../actionTypes/campaignTestingInfo.actionTypes";
import { CAMPAIGN_TESTING_URL } from "../../../services/ApiRoutes";
import { ToastContainer, toast } from "react-toastify";
import actionType from "../actionTypes/campaignTestingInfo.actionTypes";
import { modifyNestedObject } from "../../../utils";
import { store } from "../../../redux/rootStore";
import { USECASE } from "../../../constants";
const { v4: uuidv4 } = require("uuid");
/* Constants */
// const configKeys = [
//   "isActive",
//   "contactInfo.primary",
//   "primaryInfo.flow",
//   "primaryInfo.language",
//   "behaviourDetails.callDisposition",
//   "primaryInfo.firstName",
//   "primaryInfo.clientname",
//   "loanAccountDetails.emiAmount",
//   "loanAccountDetails.emiDueDate",
//   "behaviourDetails.ptpDate",
//   "primaryInfo.region",
//   "primaryInfo.gender",
//   "primaryInfo.age",
//   "primaryInfo.collared",
//   "behaviourDetails.typology",
//   "primaryInfo.agentname",
// ];
// const apiResponseKeys = [
//   "isActive",
//   "mobileNumber",
//   "flow",
//   "language",
//   "disposition",
//   "firstName",
//   "clientName",
//   "emiamount",
//   "emiduedate",
//   "ptpDate",
//   "region",
//   "gender",
//   "age",
//   "occupation",
//   "typology",
//   "agentname",
// ];
//
// const apiResponseKeysUploadDataapi = [
//   "isActive",
//   "phone_number",
//   "flow_type",
//   "language",
//   "call_disposition",
//   "first_name",
//   "bank_name",
//   "amount",
//   "due_date",
//   "ptp_date",
//   "region",
//   "gender",
//   "age",
//   "collared",
//   "typology",
//   "agent_name",
// ];
//
// const maiaFlow = {
//   Pre_Due: "predue",
//   Due_Date: "duedate",
//   "DPD_1-3": "dpd_1_3",
//   "DPD_4-7": "dpd_4_7",
//   "DPD_8-15": "dpd_8_15",
//   "DPD_16-30": "dpd_16_30",
// };
// const maiaWhatsAppFlow = {
//   Pre_Due: "predue",
//   Due_Date: "duedate",
//   "DPD_1-3": "DPD_1_3",
//   "DPD_4-7": "DPD_4_7",
//   "DPD_8-15": "DPD_8_15",
//   "DPD_16-30": "DPD_16_30",
// };
//
// const maiTypology = {
//   "WAOR(Low-Risk_Polite)": "WAOR",
//   "WACR(Low-Risk_Polite)": "WACR",
//   "WIOR(Low-Risk_Polite)": "WIOR",
//   "WAOE(Low-Risk_Polite)": "WAOE",
//   "WIOE(Medium-Risk_Neutral)": "WIOE",
//   "WACE(Medium-Risk_Neutral)": "WACE",
//   "WICR(Medium-Risk_Neutral)": "WICR",
//   "WICE(Medium-Risk_Neutral)": "WICE",
//   "DAOR(Medium-Risk_Neutral)": "DAOR",
//   "DAOE(Medium-Risk_Neutral)": "DAOE",
//   "DACR(Medium-Risk_Neutral)": "DACR",
//   "DIOR(High-Risk_Firm)": "DIOR",
//   "DACE(High-Risk_Firm)": "DACE",
//   "DIOE(High-Risk_Firm)": "DIOE",
//   "DICR(High-Risk_Firm)": "DICR",
//   "DICE(High-Risk_Firm)": "DICE",
//   WAOR: "WAOR",
// };

export const campaignTestingInitialSetup = (
  clientName,
  agentIdDropdown,
  salesOnboardingIdDropdown
) => {
  return {
    type: actionType.CAMPAIGN_TESTING_GET_SCHEMA_API_REQUEST,
    payload: { clientName, agentIdDropdown, salesOnboardingIdDropdown },
  };
};

export const addCustomer = (dispatch, defaultInputFieldData) => {
  const useCaseId = store.getState().loginReducer.currentTeam.useCaseId;
  let accountName =
    store.getState().loginReducer.userLoginInfo.userDetail?.accountDetails?.[0]
      ?.name;
  const selectedClient =
    store.getState().filterReducers.selectedFilterOptions.clientName;
  if (selectedClient?.[0]?.name) {
    accountName = selectedClient?.[0]?.name;
  }
  const agentDataArr =
    store.getState().campaignTestingInfoReducer.schemaDataFiltered
      .agentsConfigData;
  const selectedAgent =
    store.getState().campaignTestingInfoReducer.selectedAgent;
  const agentData = agentDataArr.find((item) => item.agentId === selectedAgent);

  const customerData = { ...defaultInputFieldData };
  if (
    ((accountName.includes("Saarthi Demo") ||
      accountName.includes("International Demo")) &&
      useCaseId === USECASE.salesAndOnboarding.id) ||
    accountName.includes("SBA Lead Generation")
  ) {
    customerData["primaryInfo.agentName"] = agentData?.agentName || "";
    customerData["customerInfo.loanAccountDetails.bankName"] =
      agentData?.parentFlow?.[0]?.clientName || "";
    customerData["other.product"] = agentData?.productType || "";
  }
  dispatch({
    type: CAMPAIGN_TESTING_ACTION.ADD_CUSTOMER,
    payload: { customerData: customerData },
  });
};

export const deleteCustomer = (dispatch, index) => {
  dispatch({
    type: CAMPAIGN_TESTING_ACTION.DELETE_CUSTOMER,
    payload: { index: index },
  });
};

export const modifyCustomerDataDemo = (
  customersData,
  nameToBackEndKeyMapping,
  agentsConfigData,
  selectedAgent
) => {
  return {
    type: actionType.MODIFY_CUSTOMER_DATA_DEMO_CAMPAIGN_TESTING,
    payload: {
      customersData,
      nameToBackEndKeyMapping,
      agentsConfigData,
      selectedAgent,
    },
  };
};

export const updateCustomerData = (dispatch, value, key, index) => {
  // console.log("action", key, value, index);
  dispatch({
    type: CAMPAIGN_TESTING_ACTION.UPDATE_CUSTOMER_DATA,
    payload: { key, value, index },
  });
};

export const updateCustomerDataObject = (dataObject, index) => {
  return {
    type: actionType.CAMPAIGN_TESTING_UPDATE_CUSTOMER_DATA_OBJECT,
    payload: { dataObject, index },
  };
};

export const setDisableBtn = (dispatch, value) => {
  dispatch({
    type: CAMPAIGN_TESTING_ACTION.SET_DISABLE_BUTTON,
    payload: { data: value },
  });
};

export const updateSchemaByAgent = (
  selectedAgent,
  agentsConfig,
  schema,
  customerData,
  nameToBackEndKeyMapping
) => {
  return {
    type: actionType.MAKE_SCHEMA_BY_AGENT_CAMPAIGN_TESTING,
    payload: {
      selectedAgent,
      agentsConfig,
      schema,
      customerData,
      nameToBackEndKeyMapping,
    },
  };
};

export const setSelectedAgent = (selectedAgent) => {
  return {
    type: actionType.SET_SELECTED_AGENT_CAMPAIGN_TESTING,
    payload: { selectedAgent },
  };
};

export const getCustomersDataByApi = async (
  dispatch,
  clientName,
  defaultInputFieldData,
  reduxData,
  saveKey,
  backEndKey,
  handleAgentSelection,
  sidebarView,
  campaignSelectedAgent,
  nameToBackEndKeyMapping,
  agentsConfigData,
  handleCallTypeSelection,
  handleSelectedTelephonyChange
) => {
  const useCaseId = store.getState().loginReducer.currentTeam.useCaseId;
  const clientNameProper = clientName;
  clientName = clientName.replaceAll(" ", "%20");
  const URL = `${CAMPAIGN_TESTING_URL.GET_CUSTOMERS_DATA}${clientName}&useCaseId=${useCaseId}`;
  try {
    const res = await axios.get(URL);
    const tempCustomersData = res?.data?.data[0]?.userInfo;
    // console.log(res?.data?.data[0].callType,"9999")
    if (sidebarView && clientName !== "Demo") {
      handleAgentSelection(campaignSelectedAgent);
    } else if (res?.data?.data?.[0]?.selectedAgentID) {
      handleAgentSelection(res?.data?.data?.[0]?.selectedAgentID, true);
    }
    if (res?.data?.data[0]?.callType) {
      handleCallTypeSelection(res?.data?.data[0]?.callType);
    }
    handleSelectedTelephonyChange(
      res?.data?.data[0]?.telephonyName ||
        clientNameProper?.includes("ICICI Bank")
        ? "Exotel"
        : "Epicode"
    );
    let finalobj = [];
    if (tempCustomersData) {
      const dataArr = tempCustomersData.map((item) => {
        const newItem = { ...defaultInputFieldData };
        saveKey.forEach((key, index) => {
          if (item[key] || item[key] === false) {
            newItem[backEndKey[index]] = item[key];
          }
        });
        newItem["isActive"] = item["isActive"];
        if (
          clientNameProper === "Demo Testing" ||
          clientNameProper === "Demo"
        ) {
          newItem["demoLoanId"] = item["demoLoanId"];
        }
        // if (clientNameProper === "Aadhar Housing Testing") {
        //   newItem["linkStatus"] = item["linkStatus"];
        // }
        // if (clientNameProper === "Mannapuram Personal Loans Testing") {
        //   newItem["noOfLoans"] = item["noOfLoans"];
        // }
        // if (
        //   clientNameProper === "Capri Global Testing" ||
        //   clientNameProper === "Capri Azure Testing" ||
        //   clientNameProper === "Capri General Azure Testing"
        // ) {
        //   newItem["payment_method"] = item["payment_method"];
        // }
        return newItem;
      });

      if (dataArr?.length !== 0) {
        if (
          sidebarView &&
          clientName !== "Demo" &&
          res?.data?.data?.[0]?.selectedAgentID !== campaignSelectedAgent
        ) {
          dispatch(
            modifyCustomerDataDemo(
              dataArr,
              nameToBackEndKeyMapping,
              agentsConfigData,
              campaignSelectedAgent
            )
          );
        } else {
          dispatch({
            type: CAMPAIGN_TESTING_ACTION.ADD_FETCHED_CUSTOMERS,
            payload: { data: dataArr },
          });
        }
      } else if (
        reduxData?.length === 0 &&
        !res?.data?.data?.[0]?.selectedAgentID
      ) {
        const customerData = { ...defaultInputFieldData };
        dispatch({
          type: CAMPAIGN_TESTING_ACTION.ADD_CUSTOMER,
          payload: { customerData: customerData },
        });
      }

      dispatch({
        type: CAMPAIGN_TESTING_ACTION.SET_DISABLE_BUTTON,
        payload: { data: false },
      });
      finalobj = dataArr;
    } else if (reduxData?.length === 0) {
      const customerData = { ...defaultInputFieldData };
      dispatch({
        type: CAMPAIGN_TESTING_ACTION.ADD_CUSTOMER,
        payload: { customerData: customerData },
      });
    }
    return finalobj;
  } catch (e) {
    console.log(e);
  }
};

export const saveDataByApi = async (
  dispatch,
  clientName,
  data,
  saveKey,
  backEndKey,
  selectedAgent,
  selectedCallType,
  selectedTelephony
) => {
  const useCaseId = store.getState().loginReducer.currentTeam.useCaseId;
  const URL = CAMPAIGN_TESTING_URL.SAVE_CUSTOMERS_DATA;
  const modifiedData = data.map((item) => {
    const modifiedItem = {};
    backEndKey.forEach((key, index) => {
      modifiedItem[saveKey[index]] = item[key];
    });
    modifiedItem["isActive"] = item["isActive"];
    // if (clientName === "Aadhar Housing Testing") {
    //   modifiedItem["linkStatus"] = item["linkStatus"];
    // }
    // if (clientName === "Mannapuram Personal Loans Testing") {
    //   modifiedItem["noOfLoans"] = item["noOfLoans"];
    // }
    // if (
    //   clientName === "Capri Global Testing" ||
    //   clientName === "Capri Azure Testing" ||
    //   clientName === "Capri General Azure Testing"
    // ) {
    //   modifiedItem["payment_method"] = item["payment_method"];
    // }
    if (clientName === "Demo Testing" || clientName === "Demo") {
      let sessionId = uuidv4();
      console.log("demo id", sessionId, item["demoLoanId"]);
      let ans = item?.["demoLoanId"] ? item?.["demoLoanId"] : `${sessionId}`;
      console.log("demo 12", ans);
      modifiedItem["demoLoanId"] = item?.["demoLoanId"]
        ? item?.["demoLoanId"]
        : `${sessionId}`;
    }
    return modifiedItem;
  });
  const bodyData = {
    clientName,
    userInfo: modifiedData,
  };
  if (selectedAgent) {
    bodyData["selectedAgentID"] = selectedAgent;
  }
  if (selectedCallType) {
    bodyData["callType"] = selectedCallType;
  }
  if (selectedTelephony) {
    bodyData["telephonyName"] = selectedTelephony;
  }
  bodyData["useCaseId"] = useCaseId;
  const headers = { "Content-Type": "application/json" };

  try {
    setDisableBtn(dispatch, true);
    const res = await axios.post(URL, bodyData, { headers });
    setDisableBtn(dispatch, false);
    if (res.data) {
      toast.success("Saved Successfully");
      return true;
    }
  } catch (e) {
    console.error(e);
    toast.error(e?.response?.data?.error);
    setDisableBtn(dispatch, false);
    return false;
  }
};

export const executeCallByApi = async (
  dispatch,
  data,
  extraConfigFields,
  clientName,
  backEndKey,
  dropDownCallMapping,
  makeValueObject,
  agentsConfigData,
  selectedAgentId,
  createNestedObjectCalling,
  isVoiceBlast
) => {
  if (clientName.includes("Stellantis")) {
    createNestedObjectCalling = true;
  }
  let agentData;
  if (selectedAgentId) {
    agentData = agentsConfigData.find(
      (item) => item.agentId === selectedAgentId
    );
    agentData = JSON.parse(JSON.stringify(agentData));
    delete agentData["flowType"];
    // delete agentData["language"];
  }
  //  const URL = CAMPAIGN_TESTING_URL.CAMPAIGNTESTING; --old Abhishek added
  const URL = `${process.env.REACT_APP_EPICODE}`;
  data = structuredClone(data);
  data = data.filter((item) => item.isActive === true);
  // console.log("exe", data);
  const modifiedData = data.map((item) => {
    let modifiedItem = { ...extraConfigFields, customerInfo: {} };

    // if (modifiedItem["clientName"] === "Maia Testing") {
    //   modifiedItem["clientName"] = "Maia";
    // }
    backEndKey.forEach((key) => {
      if (item[key] !== "" && item[key] !== null) {
        // if (Moment(item[key], "YYYY-MM-DD", true).isValid()) {
        //   item[key] = Moment(item[key]).format("DD-MM-YYYY");
        // }
        // adding nested value
        if (makeValueObject[key] || createNestedObjectCalling) {
          let value = item[key];
          if (dropDownCallMapping[key]) {
            value = dropDownCallMapping[key][item[key]] || item[key];
          }
          modifyNestedObject(modifiedItem["customerInfo"], key, value);
          delete modifiedItem[key];
          // if (modifiedItem[key.split(".")[0]]) {
          //   placeInNestedObject(modifiedItem, key, value);
          // } else {
          //   addNestedValueInObject(modifiedItem, key, value);
          // }
          // if (Moment(item[key], "YYYY-MM-DD", true).isValid()) {
          //   modifiedItem[key] = Moment(item[key]).format("DD-MM-YYYY");
          // }
          return;
        }
        modifiedItem[key] = dropDownCallMapping[key]?.[item[key]] || item[key];

        // if (key === "primaryInfo.collared") {
        //   let toSend = item[key].split(" ");
        //   modifiedItem[key] = toSend?.[0];
        // } //????????
        if (key === "primaryInfo.firstName") {
          modifiedItem[key] = item[key]?.toLowerCase();
        }
        // if (key === "primaryInfo.gender") {
        //   if (item[key] == "Male") {
        //     modifiedItem[key] = "M";
        //   } else {
        //     modifiedItem[key] = "F";
        //   }
        // } //???
        if (!clientName.includes("Stellantis")) {
          if (Moment(item[key], "YYYY-MM-DD", true).isValid()) {
            modifiedItem[key] = Moment(item[key]).format("DD-MM-YYYY");
          }
        }
        // if (
        //   extraConfigFields["clientName"] === "Maia Testing" &&
        //   key === "primaryInfo.flow"
        // ) {
        //   modifiedItem[key] = maiaFlow[`${item[key]}`];
        // }
        // if (key === "behaviourDetails.typology") {
        //   modifiedItem[key] = maiTypology[`${item[key]}`];
        // }
        // if (dropDownCallMapping[key]) {
        //   modifiedItem[key] = dropDownCallMapping[key][item[key]] || item[key];
        // }
        // if (key === "primaryInfo.clientname") {
        //   modifiedItem["primaryInfo.bankname"] = item[key];
        //   delete modifiedItem["primaryInfo.clientname"];
        // }
      }
    });
    if (
      clientName.includes("Saarthi Demo") ||
      clientName.includes("International Demo")
    ) {
      backEndKey.forEach((key) => {
        if (item[key] !== "" && item[key] !== null) {
          if (Moment(item[key], "YYYY-MM-DD", true).isValid()) {
            item[key] = Moment(item[key]).format("DD-MM-YYYY");
          }
          // adding nested value
          if (true) {
            let value = item[key];
            if (dropDownCallMapping[key]) {
              value = dropDownCallMapping[key][item[key]] || item[key];
            }
            modifyNestedObject(modifiedItem["customerInfo"], key, value);
            return;
          }
          modifiedItem[key] =
            dropDownCallMapping[key]?.[item[key]] || item[key];
          if (key === "primaryInfo.firstName") {
            modifiedItem[key] = item[key]?.toLowerCase();
          }
          // if (key === "primaryInfo.gender") {
          //   if (item[key] == "Male") {
          //     modifiedItem[key] = "M";
          //   } else {
          //     modifiedItem[key] = "F";
          //   }
          // } //???
          // if (Moment(item[key], "YYYY-MM-DD", true).isValid()) {
          //   modifiedItem[key] = Moment(item[key]).format("DD-MM-YYYY");
          // }
          // if (
          //   extraConfigFields["clientName"] === "Maia Testing" &&
          //   key === "primaryInfo.flow"
          // ) {
          //   modifiedItem[key] = maiaFlow[`${item[key]}`];
          // }
          // if (key === "behaviourDetails.typology") {
          //   modifiedItem[key] = maiTypology[`${item[key]}`];
          // }
          // if (dropDownCallMapping[key]) {
          //   modifiedItem[key] = dropDownCallMapping[key][item[key]] || item[key];
          // }
          // if (key === "primaryInfo.clientname") {
          //   modifiedItem["primaryInfo.bankname"] = item[key];
          //   delete modifiedItem["primaryInfo.clientname"];
          // }
        }
      });
    }
    if (
      createNestedObjectCalling &&
      (!clientName.includes("Saarthi Demo") ||
        !clientName.includes("International Demo"))
    ) {
      Object.keys(modifiedItem).forEach((key) => {
        if (String(key).includes(".")) {
          modifyNestedObject(
            modifiedItem["customerInfo"],
            key,
            modifiedItem[key]
          );
          delete modifiedItem[key];
        }
      });
    }
    // if (clientName === "Aadhar Housing Testing") {
    //   modifiedItem["customerInfo"]["others"]["linkStatus"] = item["linkStatus"];
    // }
    // if (clientName === "Mannapuram Personal Loans Testing") {
    //   modifiedItem["loanAccountDetails.noOfLoans"] = item["noOfLoans"];
    // }
    // if (
    //   clientName === "Capri Global Testing" ||
    //   clientName === "Capri Azure Testing" ||
    //   clientName === "Capri General Azure Testing"
    // ) {
    //   modifiedItem["loanAccountDetails.nach"] =
    //     item["payment_method"] === "Nach" ? "yes" : "no";
    // }
    if (clientName === "Probus Insurance Testing") {
      modifiedItem["clientName"] = "Probus Testing";
    }

    modifiedItem["callingPartner"] = "epicode";
    modifiedItem["campaignId"] = "2345";

    if (selectedAgentId) {
      modifiedItem = {
        ...modifiedItem,
        agentConfig: { ...agentData },
      };
    }
    if (modifiedItem.leadType) {
      modifiedItem.customerInfo = {
        ...modifiedItem.customerInfo,
        leadType: modifiedItem.leadType,
      };
      delete modifiedItem.leadType;
    }
    modifiedItem["useCaseId"] =
      store.getState().loginReducer.currentTeam.useCaseId;
    modifiedItem["teamId"] = store.getState().loginReducer?.currentTeam?.id;
    if (isVoiceBlast) {
      modifiedItem["isVoiceBlast"] = isVoiceBlast;
    }
    if (
      store.getState().filterReducers?.selectedFilterOptions?.clientName?.[0]
        ?.id
    ) {
      modifiedItem["accountId"] =
        store.getState().filterReducers.selectedFilterOptions.clientName[0].id;
    } else {
      modifiedItem["accountId"] =
        store.getState().loginReducer.userLoginInfo.accountDetails[0].id;
    }

    return modifiedItem;
  });
  modifiedData[0]["isCampaignTesting"] = true;
  const bodyData = modifiedData;
  console.log("nn", bodyData);
  const headers = { "Content-Type": "application/json" };
  if (bodyData.length === 0) {
    toast.error("Please select at least one calling info ");
    return false;
  }

  try {
    setDisableBtn(dispatch, true);
    const res = await axios.post(URL, bodyData, { headers });
    setDisableBtn(dispatch, false);
    if (res.data) {
      toast.success("Call Executed Successfully");
      return res.data?.data?.message;
    }
  } catch (e) {
    console.error(e);
    setDisableBtn(dispatch, false);
    return false;
  }
};

export const executeWpByApi = async (
  dispatch,
  data,
  extraConfigFields,
  accountDetails,
  backEndKey,
  dropDownWhatsAppMapping,
  whatsAppToBackendKeyMapping,
  agentsConfigData,
  selectedAgentId
) => {
  let agentData;
  if (selectedAgentId) {
    agentData = agentsConfigData.find(
      (item) => item.agentId === selectedAgentId
    );
    agentData = JSON.parse(JSON.stringify(agentData));
    delete agentData["flowType"];
    // delete agentData["language"];
  }
  // console.log("bhai", accountDetails);
  let url = CAMPAIGN_TESTING_URL.CAMPAIGNTESTINGWHATAPP;
  let temp = [];
  data = data.filter((item) => item.isActive === true);
  let newArray1 = data?.map((item) => {
    const modifiedItem = { ...extraConfigFields };
    backEndKey.forEach((key) => {
      if (item[key] !== "" && item[key] !== null) {
        modifiedItem[key] =
          dropDownWhatsAppMapping[key]?.[item[key]] || item[key];

        if (
          key === "loanAccountDetails.emiDueDate" ||
          key === "behaviourDetails.ptpDate"
        ) {
          modifiedItem[key] = Moment(item[key]).format("DD-MM-YYYY");
        }
      }
    });
    // if (
    //   extraConfigFields.clientName === "Capri Global Testing" ||
    //   extraConfigFields.clientName === "Capri Azure Testing" ||
    //   extraConfigFields.clientName === "Capri General Azure Testing"
    // ) {
    //   modifiedItem["payment_method"] = item["payment_method"];
    // }
    return modifiedItem;
  });

  if (data?.length === 0) {
    toast.error("Please select at least one calling info ");
    return false;
  }

  newArray1?.map((customerData) => {
    let final = {
      // mobile: e["contactInfo.primary"],
      campaignInfo: {
        accountInfo: {
          name: customerData?.clientName,
          _id: accountDetails[0]?.id,
        },
        customerInfo: {
          others: {},
        },
        // customerInfo: {
        //   primaryInfo: {},
        //   others: {},
        //   contactInfo: {},
        //   loanAccountDetails: {},
        //   behaviourDetails: {},
        //   email: { primary: "" },
        // },
      },
      countryCode: customerData?.countryCode,
    };
    let whatsAppKeys = Object.keys(whatsAppToBackendKeyMapping);
    whatsAppKeys.forEach((key) => {
      let value = customerData[whatsAppToBackendKeyMapping[key]] || "";
      modifyNestedObject(final, key, value);
    });
    // let keys = Object.keys(e);
    // keys.map((d) => {
    //   if (d?.includes("primaryInfo")) {
    //     if (d?.split(".")[1] === "gender") {
    //       if (e[d] === "Male") {
    //         final["campaignInfo"]["customerInfo"]["primaryInfo"][
    //           d?.split(".")[1]
    //         ] = "M";
    //       } else {
    //         final["campaignInfo"]["customerInfo"]["primaryInfo"][
    //           d?.split(".")[1]
    //         ] = "F";
    //       }
    //     } else if (d?.split(".")[1] === "collared") {
    //       final["campaignInfo"]["customerInfo"]["primaryInfo"][
    //         d?.split(".")[1]
    //       ] = e[d]?.split(" ")[0];
    //     } else if (d?.split(".")[1] === "agentname") {
    //       final["campaignInfo"]["customerInfo"]["primaryInfo"]["agentName"] =
    //         e[d];
    //     }
    //     // else if (d?.split(".")[1] === "clientname") { //????????
    //     //   final["campaignInfo"]["customerInfo"]["primaryInfo"]["clientName"] =
    //     //     e[d];
    //     // }
    //     else if (d?.split(".")[1] === "flow") {
    //       const val = dropDownWhatsAppMapping?.[d]?.[e[d]];
    //       final["campaignInfo"]["customerInfo"]["primaryInfo"][
    //         d?.split(".")[1]
    //       ] = val ? val : e[d];
    //     } else {
    //       final["campaignInfo"]["customerInfo"]["primaryInfo"][
    //         d?.split(".")[1]
    //       ] = e[d] ? e[d]?.toString() : "25";
    //     }
    //   }
    //   if (d?.includes("callDisposition")) {
    //     final["campaignInfo"]["customerInfo"]["behaviourDetails"][
    //       "callDisposition"
    //     ] = e[d] === "normal" ? "" : e[d];
    //   }
    //   if (d === "customerInfo.loanAccountDetails.bankName") {
    //     final["campaignInfo"]["customerInfo"]["primaryInfo"]["clientName"] =
    //       e[d];
    //   }
    //   if (d?.includes("contactInfo")) {
    //     final["campaignInfo"]["customerInfo"]["contactInfo"][d?.split(".")[1]] =
    //       e[d];
    //   }
    //   if (d?.includes("loanAccountDetails")) {
    //     if (d === "loanAccountDetails.emiDueDate") {
    //       // console.log("abbb", e[d], Moment(`${e[d]}`).format("DD-MM-YYYY"));
    //       final["campaignInfo"]["customerInfo"]["loanAccountDetails"][
    //         d?.split(".")[1]
    //       ] = e[d];
    //     } else {
    //       if (d === "customerInfo.loanAccountDetails.bankName") return;
    //       const val = dropDownWhatsAppMapping?.[d]?.[e[d]];
    //       final["campaignInfo"]["customerInfo"]["loanAccountDetails"][
    //         d?.split(".")[1]
    //       ] = val ? val : e[d];
    //     }
    //   }
    //   if (d?.includes("behaviourDetails.typology")) {
    //     const val = dropDownWhatsAppMapping?.[d]?.[e[d]];
    //     final["campaignInfo"]["customerInfo"]["behaviourDetails"]["typology"] =
    //       // e[d] ? maiTypology[`${e[d]}`] : "";
    //       val ? val : e[d];
    //   }
    // });
    // if (
    //   extraConfigFields.clientName === "Capri Global Testing" ||
    //   extraConfigFields.clientName === "Capri Azure Testing" ||
    //   extraConfigFields.clientName === "Capri General Azure Testing"
    // ) {
    //   final["campaignInfo"]["customerInfo"]["loanAccountDetails"]["nach"] =
    //     e["payment_method"] === "Nach" ? "yes" : "no";
    // }
    if (selectedAgentId) {
      final = {
        ...final,
        campaignInfo: { ...final.campaignInfo, agentConfig: { ...agentData } },
      };
    }
    temp.push(final);
  });
  let bodywp = {
    data: temp,
    accountDetails: accountDetails[0],
    channel: "Whatsapp",
  };

  try {
    setDisableBtn(dispatch, true);
    const res = await axios.post(url, bodywp);
    setDisableBtn(dispatch, false);
    if (res.data) {
      toast.success("WhatsApp Executed Successfully");
    }
  } catch (e) {
    console.error(e);
    setDisableBtn(dispatch, false);
    return false;
  }
};

export const saveCustomerDataByApi = async (
  dispatch,
  data,
  token,
  accountIdt,
  clientName,
  backEndKey,
  uploadDataKey,
  dropDownCallMapping
) => {
  let URL = CAMPAIGN_TESTING_URL.PUSH_DATA_API;
  // console.log("data bank name", data);
  data = data.filter((item) => item.isActive === true);
  const modifiedData = data.map((item) => {
    const modifiedItem = {};
    backEndKey.forEach((key, index) => {
      if (!uploadDataKey[index]) return;
      if (key === "primaryInfo.flow") {
        const val = dropDownCallMapping?.[key]?.[item[key]];
        modifiedItem[uploadDataKey[index]] = val ? val : item[key];
      } else if (key === "behaviourDetails.typology") {
        const val = dropDownCallMapping?.[key]?.[item[key]];
        modifiedItem[uploadDataKey[index]] = val ? val : item[key];
      } else if (key === "primaryInfo.gender") {
        if (item[key] == "Male") {
          modifiedItem[uploadDataKey[index]] = "M";
        } else {
          modifiedItem[uploadDataKey[index]] = "F";
        }
      } else if (key === "primaryInfo.collared") {
        let toSend = item[key].split(" ");
        modifiedItem[uploadDataKey[index]] = toSend?.[0];
      } else {
        modifiedItem[uploadDataKey[index]] = item[key];
      }
    });
    //     const d = new Date();
    // let text = d.toISOString();
    // let sessionId = uuidv4();
    modifiedItem["loan_id"] = item["demoLoanId"];
    modifiedItem["email_id"] = "jai@gmail.com";
    modifiedItem["channel"] = "call";
    modifiedItem["interest_rate"] = "4";
    modifiedItem["late_fees"] = "100";
    modifiedItem["loan_amount_remaining"] = "400000";
    modifiedItem["loan_approval_date"] = "2022-06-09";
    modifiedItem["loan_end_date"] = "2023-10-09";
    modifiedItem["loan_application_date"] = "2022-06-09";
    modifiedItem["loan_tenure_left"] = "5";
    modifiedItem["total_emis_paid"] = "3";
    modifiedItem["total_loans_taken"] = "3";
    modifiedItem["no_of_loans"] = "3";
    modifiedItem["no_of_emis"] = "3";
    modifiedItem["principal_amount"] = "300000";
    modifiedItem["total_loan_amount"] = "300000";
    modifiedItem["geographic_location"] = "bengaluru";
    modifiedItem["barging_call"] = "true";
    modifiedItem["call_id"] = "12345";
    modifiedItem["clientName"] = item["primaryInfo.clientname"];
    delete modifiedItem["isActive"];
    //delete modifiedItem['clientName'];
    //delete modifiedItem['agentname'];
    if (modifiedItem["typology"]?.length === 0) {
      modifiedItem["typology"] = "WAOR";
    }
    if (modifiedItem["amount"]?.length === 0) {
      modifiedItem["amount"] = "40000";
    }
    if (modifiedItem["due_date"]?.length === 0) {
      modifiedItem["due_date"] = "2022-10-09";
    }
    if (modifiedItem["ptp_date"]?.length === 0) {
      modifiedItem["ptp_date"] = "2022-10-09";
    }
    if (modifiedItem["call_disposition"]?.length === 0) {
      modifiedItem["call_disposition"] = "normal";
    }
    if (modifiedItem["age"]?.length === 0) {
      modifiedItem["age"] = "45";
    }
    return modifiedItem;
  });
  // console.log("modifiedItem", modifiedData);
  let bodyData = { data: modifiedData };
  //const headers = { "Content-Type": "application/json","Authorization": `JWT ${token}`};

  try {
    let accessTokenUrl = `https://${process.env.REACT_APP_CONNECTOR}/api/accounts/v1/o-login`;
    let secretKeyUrl = `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/oauth/v1/get?accountId=${accountIdt}`;

    const resKey = await axios.get(secretKeyUrl);

    let accountId = accountIdt;
    let client_id = resKey.data.data[0].clientId;
    let client_secret = resKey.data.data[0].clientSecret;
    // if (accountId === "628f6d76d830f6455fa7a632") {
    //   client_id = "6282b61";
    //   client_secret = "4ec8830561da4581958d2056da23a14c";
    // } else if (accountId === "61e90dab9c1de558c3adb420") {
    //   client_id = "6319f3d";
    //   client_secret = "6319f3d36e682e15fc4f6d6319f3d29";
    // } else if (accountId === "628f6cef2554564553aa9824") {
    //   client_id = "dfdfc4f";
    //   client_secret = "6319f3d36e682e15dfdfdfc4f6d6319f3d29";
    // } else if (accountId === "6357ededbc771e3f0332e62f") {
    //   client_id = "cidtd";
    //   client_secret = "csec45454tsfst4434";
    // } else if (accountId === "6357e19818427181896d1232") {
    //   client_id = "6319f3dem";
    //   client_secret = "6319f3d36e682e15fc4fse6319f3d29";
    // }

    let accessTokenBody = {
      client_id: client_id,
      client_secret: client_secret,
    };
    let resAccessToken = await axios.post(accessTokenUrl, accessTokenBody);
    let accessToken = resAccessToken?.data?.data?.data?.access_token;
    setDisableBtn(dispatch, true);
    const res = await axios.post(URL, bodyData, {
      headers: { Authorization: `JWT ${accessToken}` },
    });
    setDisableBtn(dispatch, false);
    if (res.data) {
      toast.success("Data Pushed Successfully");
      return true;
    }
  } catch (e) {
    console.error(e);
    toast.error(e?.response?.data?.error);
    setDisableBtn(dispatch, false);
    return false;
  }
};

/* helper function */
const addNestedValueInObject = (object, InputKey, value) => {
  const keyArr = InputKey.split(".");
  const key = keyArr[0];
  object[key] = addNestedValueInObjectHelper(keyArr.slice(1), value);
};

const addNestedValueInObjectHelper = (keyArr, value) => {
  if (keyArr?.length === 1) {
    const key = keyArr[0];
    return { [key]: value };
  }
  const returnedValue = addNestedValueInObjectHelper(keyArr.slice(1), value);
  const key = keyArr[0];
  return { [key]: returnedValue };
};

function placeInNestedObject(obj, key, value) {
  const keyArr = key.split(".");
  const lastKey = keyArr.pop();
  keyArr.forEach((key) => {
    obj = obj[key];
  });
  obj[lastKey] = value;
}
