import actionTypes from "../sms.actionTypes";

export function setconnectExistingAccount(payload: object) {
  return {
    type: actionTypes.SET_CONNECT_EXISTING_ACCOUNT_REQUEST,
    payload: payload,
  };
}
export function setNewAccount(payload: object) {
  return {
    type: actionTypes.SET_NEW_ACCOUNT_REQUEST,
    payload: payload,
  };
}
export function updateNewAccount(payload: object) {
  return {
    type: actionTypes.UPDATE_NEW_ACCOUNT_REQUEST,
    payload: payload,
  };
}
export function setClearSMSData() {
  return {
    type: actionTypes.SET_CLEAR_SMS_DATA,
  };
}

export function setNewAccountClean() {
  return {
    type: actionTypes.SET_NEW_ACCOUNT_CLEAN,
  };
}

export function setSMSScreen(screenName: string) {
  return {
    type: actionTypes.SET_CURRENT_SCREEN_SMS,
    payload: screenName,
  };
}
