export const removeIfNoMailId = (
  data: Array<{ email: string; role: string }>
) => {
  const result = data.map((item) => {
    if (item.email === "" && item.role !== "") {
      return { ...item, role: "" };
    }
    return item;
  });
  return result;
};
