import React from "react";
import styles from "./Button.module.scss";
import Icon from "../../moduleComponents/uiKit/iconComponent/Icon";
import { InfoToolTip } from "../infoToolTip/InfoToolTip";

interface btnContent {
  text?: string;
  icon?: any;
  class?: string;
}
type btnContentType = "noAction" | "loading" | "success" | "error";

interface props {
  dataTest?: string;
  button_type?: string;
  extraClass?: string;
  extraBtnImageClass?: string;
  extraDisableClass?: string;
  disabled?: boolean | any;
  roundedBtn?: boolean;
  dataToggle?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  extraStyle?: React.CSSProperties;
  onClick?: CallableFunction | null;
  id?: string;
  image_src_left?: any;
  imgClass?: string;
  buttonTextClass?: string;
  text?: string;
  image_src?: any;
  shortLoader?: boolean;
  btnStatus?: "noAction" | "loading" | "success" | "error" | undefined;
  btnContents?: {
    noAction?: btnContent;
    loading?: btnContent;
    success?: btnContent;
    error?: btnContent;
  };
  extraBtnTextClass?: string;
  isShowToolTip?: any;
  showNewToolTipMsg?: any;
  showNewToolTipPosition?: any;
  extraToopTipDiv?: string;
}

export default React.forwardRef(function Button(props: props, ref: any) {
  /* helper function */
  const getBtnContent = () => {
    const btnActiveContent = props.btnContents?.[
      props.btnStatus!
    ] as btnContent;
    // console.log(btnActiveContent);
    if (btnActiveContent) {
      // console.log(btnActiveContxent);
      return (
        <>
          {btnActiveContent.icon && (
            <img
              className={`${props.extraBtnImageClass} ${styles.btnImageClass}`}
              src={btnActiveContent.icon}
            />
          )}
          <span className={`${props.extraBtnTextClass} `}>
            {btnActiveContent.text}
          </span>
        </>
      );
    } else if (props.text) return props.text;
    else return "";
  };

  return (
    <button
      data-testid={props?.dataTest}
      className={
        `${styles.basic} ${styles.button_}` +
        (props.button_type ? props.button_type : "") +
        " " +
        (props.extraClass ? props.extraClass : "") +
        " " +
        (props.disabled
          ? `${styles.disabled} ${props.extraDisableClass} disablePointerEventUniversaljp`
          : "") +
        " " +
        (props.roundedBtn ? styles.btn_radius : "") +
        props.btnContents?.[props.btnStatus as btnContentType]?.class
      }
      data-toggle={props.dataToggle ? props.dataToggle : ""}
      ref={ref}
      type={props.type ? props.type : "button"}
      style={props.extraStyle ? props.extraStyle : { width: "130px" }}
      disabled={props.disabled}
      onClick={(e) => {
        if (props.onClick && !props.disabled) {
          props.onClick(e);
        }
      }}
      id={props?.id ? props?.id : ""}
    >
      {props.image_src_left && (
        <Icon
          img_src={props.image_src_left}
          extraClass={props.imgClass ? props.imgClass : ""}
          extraStyle={{
            cursor: props.disabled ? "not-allowed" : "pointer",
            width: "auto !important",
          }}
          disabled={props.disabled}
        />
      )}
      <span
        className={
          // styles["btn-text"] +
          props.buttonTextClass ? props.buttonTextClass : ""
        }
      >
        {getBtnContent()}
      </span>
      {props.image_src && (
        <Icon
          img_src={props.image_src}
          extraClass={props.imgClass ? props.imgClass : ""}
          extraStyle={{
            cursor: props.disabled ? "not-allowed" : "pointer",
          }}
          disabled={props.disabled}
        />
      )}
      {props.isShowToolTip && (
        <div className={props.extraToopTipDiv ? props.extraToopTipDiv : ""}>
          <InfoToolTip
            message={props.showNewToolTipMsg}
            isError={false}
            position={props.showNewToolTipPosition}
            isIconSmall={false}
          />
        </div>
      )}
      {props.shortLoader ? (
        <span
          className={`${styles.short_loader} ${
            (styles.ml as never as number) - 2
          }`}
        ></span>
      ) : (
        ""
      )}
    </button>
  );
});
