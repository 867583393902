import React from "react";
import { sort } from "../../../../theme/assets/svg";
export const datas = [
  {
    customerName: "Ashish Kabade",
    phone: "+91 8638570914",
    loanID: "57191860763929",
    loanAmount: "49096",
    emiAmount: "10009",
    emiDate: "Jan 1, 2023",
    risk: "Low",
  },
  {
    customerName: "Manoj Singh",
    phone: "+91 6738570541",
    loanID: "14130297532635",
    loanAmount: "64746",
    emiAmount: "12640",
    emiDate: "Jan 3, 2023",
    risk: "Medium",
  },
  {
    customerName: "Dinesh Mamdpure",
    phone: "+91 7661447144",
    loanID: "41063126673437",
    loanAmount: "116472",
    emiAmount: "10739",
    emiDate: "Jan 5, 2023",
    risk: "Low",
  },
  {
    customerName: "Saroj Badoria",
    phone: "+91 8134104075",
    loanID: "72360744182761",
    loanAmount: "83366",
    emiAmount: "11326",
    emiDate: "Jan 1, 2023",
    risk: "Medium",
  },
  {
    customerName: "Dev Lama",
    phone: "+91 7053960979",
    loanID: "74677570249519",
    loanAmount: "130603",
    emiAmount: "7118",
    emiDate: "Jan 5, 2023",
    risk: "Low",
  },
  {
    customerName: "Mahesh Kalita",
    phone: "+91 6206914247",
    loanID: "43006364835946 ",
    loanAmount: "72568",
    emiAmount: "10445",
    emiDate: "Jan 5, 2023",
    risk: "Low",
  },
  {
    customerName: "Mallikarjun K",
    phone: "+91 9218666473 ",
    loanID: "14257446872883",
    loanAmount: "63589",
    emiAmount: "8805",
    emiDate: "Jan 5, 2023",
    risk: "Low",
  },
  {
    customerName: "Subhash Singh",
    phone: "+91 7829896167",
    loanID: "93779578386745",
    loanAmount: "132415",
    emiAmount: "13923",
    emiDate: "Jan 5, 2023",
    risk: "High",
  },
  {
    customerName: "Gaurav Kushwaha",
    phone: "+91 7356723661",
    loanID: "29464149518382",
    loanAmount: "121744",
    emiAmount: "9078",
    emiDate: "Jan 5, 2023",
    risk: "High",
  },
  {
    customerName: "Pooja Maheshwari",
    phone: "+91 6604001754 ",
    loanID: "36021586056552",
    loanAmount: "59302",
    emiAmount: "11766",
    emiDate: "Jan 1, 2023",
    risk: "Low",
  },

  {
    customerName: "Namu Krishnamurti",
    phone: "+91 8081973066",
    loanID: "92107273276577",
    loanAmount: "62296",
    emiAmount: "8520",
    emiDate: "Jan 1, 2023",
    risk: "Low",
  },

  {
    customerName: "Abhinash Chandra",
    phone: "+91 6478592970",
    loanID: "46412034524821",
    loanAmount: "112886",
    emiAmount: "9907",
    emiDate: "Jan 1, 2023",
    risk: "Low",
  },
];

export const bodyTransformer = [
  { key: "customerName", transform: (value: any) => value },
  { key: "phone", transform: (value: any) => value },
  { key: "loanID", transform: (value: any) => value },
  { key: "loanAmount", transform: (value: any) => <>&#8377;{value}</> },
  { key: "emiAmount", transform: (value: any) => <>&#8377;{value}</> },
  { key: "emiDate", transform: (value: any) => value },
  {
    key: "risk",
    transform: (value: any) => {
      if (value == "Low")
        return (
          <span
            style={{
              color: "#07B464",
              background: "#32936F29",
              padding: " 4px 5px ",
              borderRadius: "3px",
              display: "inline-block",

              width: "65px",
              textAlign: "center",
            }}
          >
            Low
          </span>
        );
      if (value == "High")
        return (
          <span
            style={{
              color: "#ED2121",
              background: "#ED212129",
              padding: " 4px 5px ",
              textAlign: "center",
              display: "inline-block",
              width: "65px",
              borderRadius: "3px",
            }}
          >
            High
          </span>
        );
      if (value == "Medium")
        return (
          <span
            style={{
              color: "#DCA10B",
              background: "#DCA10B29",

              padding: " 4px 5px ",
              display: "inline-block",
              borderRadius: "3px",
              width: "65px",
              textAlign: "center",
            }}
          >
            Medium
          </span>
        );
    },
  },
];
export const headTranformer = [
  {
    key: "customerName",
    displayName: "Customer Name",
    transform: (value: any) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {value}
        <img style={{ marginLeft: "7px" }} src={sort} alt=">" />
      </div>
    ),
    sort: true,
    type: "string",
  },
  { key: "phone", displayName: "Phone" },
  {
    key: "loanID",
    displayName: "Laon Id",
    transform: (value: any) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {value}
        <img style={{ marginLeft: "7px" }} src={sort} alt=">" />
      </div>
    ),
    sort: true,
    type: "number",
  },
  {
    key: "loanAmount",
    displayName: "Loan Amount",
    transform: (value: any) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {value}
        <img style={{ marginLeft: "7px" }} src={sort} alt=">" />
      </div>
    ),
  },
  {
    key: "emiAmount",
    displayName: "EMI Amount",
    transform: (value: any) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {value}
        <img style={{ marginLeft: "7px" }} src={sort} alt=">" />
      </div>
    ),
  },
  {
    key: "emiDate",
    displayName: "EMI Date",
    transform: (value: any) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {value}
        <img style={{ marginLeft: "7px" }} src={sort} alt=">" />
      </div>
    ),
  },
  {
    key: "risk",
    displayName: "Risk",
    transform: (value: any) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {value}
        <img style={{ marginLeft: "7px" }} src={sort} alt=">" />
      </div>
    ),
  },
];
