export default {
  GET_ALL_TELEPHONY_SYSTEM_REQUEST: "GET_ALL_TELEPHONY_SYSTEM_REQUEST",
  GET_ALL_TELEPHONY_SYSTEM_SUCCESS: "GET_ALL_TELEPHONY_SYSTEM_SUCCESS",
  GET_ALL_TELEPHONY_SYSTEM_FAILURE: "GET_ALL_TELEPHONY_SYSTEM_FAILURE",

  GET_DATA_CENTER_REQUEST: "GET_DATA_CENTER_REQUEST",
  GET_DATA_CENTER_SUCCESS: "GET_DATA_CENTER_SUCCESS",
  GET_DATA_CENTER_FAILURE: "GET_DATA_CENTER_FAILURE",

  GET_SIP_GATEWAY_REQUEST: "GET_SIP_GATEWAY_REQUEST",
  GET_SIP_GATEWAY_SUCCESS: "GET_SIP_GATEWAY_SUCCESS",
  GET_SIP_GATEWAY_FAILURE: "GET_SIP_GATEWAY_FAILURE",

  ADD_TRANSACTION_DETAIL: "ADD_TRANSACTION_DETAIL",
  ADD_PROMOTIONAL_DETAIL: "ADD_PROMOTIONAL_DETAIL",

  GET_DEFAULT_TELEPHONY_DATA_REQUEST: "GET_DEFAULT_TELEPHONY_DATA_REQUEST",
  GET_DEFAULT_TELEPHONY_DATA_SUCCESS: "GET_DEFAULT_TELEPHONY_DATA_SUCCESS",
  GET_DEFAULT_TELEPHONY_DATA_FAILURE: "GET_DEFAULT_TELEPHONY_DATA_FAILURE",

  GET_CLIENT_TELEPHONY_DATA_REQUEST: "GET_CLIENT_TELEPHONY_DATA_REQUEST",
  GET_CLIENT_TELEPHONY_DATA_SUCCESS: "GET_CLIENT_TELEPHONY_DATA_SUCCESS",
  GET_CLIENT_TELEPHONY_DATA_FAILURE: "GET_CLIENT_TELEPHONY_DATA_FAILURE",

  SET_TELEPHONY_DATA_REQUEST: "SET_TELEPHONY_DATA_REQUEST",
  SET_TELEPHONY_DATA_SUCCESS: "SET_TELEPHONY_DATA_SUCCESS",
  SET_TELEPHONY_DATA_FAILURE: "SET_TELEPHONY_DATA_FAILURE",

  SET_DEFAULT_TELEPHONY_DATA_REQUEST: "SET_DEFAULT_TELEPHONY_DATA_REQUEST",
  SET_DEFAULT_TELEPHONY_DATA_SUCCESS: "SET_DEFAULT_TELEPHONY_DATA_SUCCESS",
  SET_DEFAULT_TELEPHONY_DATA_FAILURE: "SET_DEFAULT_TELEPHONY_DATA_FAILURE",

  SET_SELECTED_TELEPHONY_TAB: "SELECTED_TELEPHONY_TAB",
  CLEAR_ALL_TELEPHONY_DATA: "CLEAR_ALL_TELEPHONY_DATA",
  SET_SELECTED_TELEPHONY_DATA: "SET_SELECTED_TELEPHONY_DATA",
  CLEAR_SET_TELEPHONY_DATA : "CLEAR_SET_TELEPHONY_DATA",

  GET_DEFAULT_TELEPHONY_SYSTEM_REQUEST: "GET_DEFAULT_TELEPHONY_SYSTEM_REQUEST",
  GET_DEFAULT_TELEPHONY_SYSTEM_SUCCESS: "GET_DEFAULT_TELEPHONY_SYSTEM_SUCCESS",
  GET_DEFAULT_TELEPHONY_SYSTEM_FAILURE: "GET_DEFAULT_TELEPHONY_SYSTEM_FAILURE",
};
