import React, { useEffect } from "react";
import { useLocation, useNavigate, matchPath } from "react-router-dom";
import GetInfo from "./getHelp/GetInfo";
import { useState } from "react";
import { optionType } from "./leftSideMenu.interface";
import { RootState, AppDispatch } from "../../../redux/rootStore";
import { setIsActivePageType } from "../../../redux/onboarding/login/actions";
import {
  setHighlightedModule,
  setLeftSideModuleDetails,
  unlockAPIData,
} from "../../../redux/baseScreen/leftMenu";
import * as profile_icons from "../../../theme/assets/svg/profile_new_Icons";
import Option from "./option/Option";
import styles from "./LeftSide.module.scss";
import optionStyles from "./option/option.module.scss";
import { useDispatch, useSelector } from "react-redux";
import SubModules from "./subModules/SubModules";
import { getLeftSideModuleDetails } from "../onBoarding/login/loginField/LoginFields.functions";
import { clearAllStrategyData } from "../../../redux/strategy/strategyCreation/actions";
import { clearAllCampaignData } from "../../../redux/campaign_V2.0/campaignTable/actions";
import {
  setShowRightSideBar,
  setSelectedFilterType,
} from "../../../redux/filters/actions";
import { setSubHeaderTabs } from "../../../redux/dashboard/actions";
import {
  profileBillingIcon,
  profileUser,
  profileSettings,
  questx,
} from "../../../theme/assets/svg";
import { getLeftsideModuleProfileDetails } from "../../../redux/profile/navigation/action/navigation.action";
import { getWorkflowsAPIData } from "../../../redux/strategy/strategyCreation/actions";
import { screenType } from "../../../constants";
import axios from "axios";
import { AGENT_DESKTOP_API } from "../../../services/ApiRoutes";
import {
  clearTeamsData,
  getAllTeamsData,
  getAllRolesData,
} from "../../../redux/signUpFlow/teamsPage/actions/teamsPage.actions";
import _ from "lodash";
import { setCampaignClientName } from "../../../redux/campaign/actions";

export default function LeftSideMenu() {
  /* useSelector , dispatch */
  const isOnlyIcons = useSelector(
    (store: RootState) => store.baseScreen?.leftMenu?.isOnlyIcons
  );
  const activePage = useSelector(
    (store: RootState) => store.loginReducer?.isActivePageType
  );
  const highlightedModule = useSelector(
    (store: RootState) => store.baseScreen?.leftMenu?.highlightedModule
  );

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const currentTeamId = useSelector(
    (state: RootState) => state.loginReducer?.currentTeam?.id
  );

  const orgData = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization.organizationDetails
  );
  const moduleDetails: any = useSelector(
    (store: RootState) => store.baseScreen.leftMenu.moduleDetails
  );

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const selectedLocked: any = moduleDetails?.filter((data: any) => {
    return data.name === "Strategy" || data.name === "Connector";
  });

  const loginUserId = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.userDetail?._id
  );

  const userName = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.userDetail?.userName
  );
  const isSignUpFlow = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo.accountDetails?.[0].isSignUpFlow
  );


  const teamUseCaseId = useSelector(
    (store: RootState) => store?.loginReducer?.currentTeam?.useCaseId
  );

  const currentAccountId = useSelector(
    (state: RootState) => state.loginReducer.currentTeam?.accountId
  );
  const [profileModules, setProfileModules] = useState<
    {
      name: string;
      displayName: string;
      routeName: string;
      active: boolean;
      childModules: any[];

      activeIcon: string;
      inActiveIcon: string;
      activeColor: string; // #F6F6F6
      color: string; //black
    }[]
  >([
    // {
    //   name: "Your Account",
    //   routeName: "your-account",
    //   displayName: "Your Account",
    //   active: false,
    //   childModules: [],
    //   activeIcon: "user_profile",
    //   inActiveIcon: "user_profile",
    //   activeColor: "#F6F6F6",
    //   color: "black",
    // },
    // {
    //   name: "Settings",
    //   displayName: "Settings",
    //   routeName: "settings",
    //   active: false,
    //   childModules: [],
    //   activeIcon: "settings",
    //   inActiveIcon: "settings",
    //   activeColor: "#F6F6F6",
    //   color: "black",
    // },
    // {
    //   name: "Billing",
    //   displayName: "Billing",
    //   routeName: "billing",
    //   active: false,
    //   childModules: [],
    //   activeIcon: "profile_billing",
    //   inActiveIcon: "profile_billing",
    //   activeColor: "#F6F6F6",
    //   color: "black",
    // },
  ]);
  const deepCopyModuleDetails = _.cloneDeep(moduleDetails);
  //update array for Changing Icons
  selectedLocked?.map(function (data: any) {
    if (data.name === "Strategy") {
      deepCopyModuleDetails.find(
        (item: any) => item.name == "Strategy"
      ).isLocked = false;
    }
    if (data.name === "Connector") {
      deepCopyModuleDetails.find(
        (item: any) => item.name == "Connector"
      ).isLocked = false;
    }
  });

  useEffect(() => {
    if (
      (orgData?.organizationName?.length > 0 && orgData?.gstNumber) ||
      process.env.REACT_APP_ENV !== "production" ||
      !isSignUpFlow
    ) {
      if (selectedLocked?.length > 0) {
        if (selectedLocked[0]?.isLocked || selectedLocked[1]?.isLocked) {
          dispatch(
            unlockAPIData({
              id: loginUserId,
              moduleDetails: deepCopyModuleDetails,
            })
          );
        }
      }
    }
  }, [orgData]);

  // const navbarOptions = useSelector(
  //   (store: RootState) => store.baseScreen?.leftMenu?.menuOptions
  // );
  // const moduleDetails_redux = useSelector(
  //   (store: RootState) => store.loginReducer?.userLoginInfo?.moduleDetails
  // );
  // let moduleDetails = [...moduleDetails_redux];
  // moduleDetails = moduleDetails?.sort((a: any, b: any) =>
  //   a?.moduleId?.localeCompare(b?.moduleId)
  // );

  const subModuleMapping = useSelector(
    (store: RootState) => store.baseScreen.leftMenu.subModuleMapping
  );
  const urlToModuleMapping = useSelector(
    (store: RootState) => store.baseScreen.leftMenu.urlToModuleMapping
  );
  const sourceDetails = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo?.sourceDetails
  );
  const userId = useSelector(
    (state: any) => state.loginReducer.userLoginInfo.userDetail._id
  );
  const communicationType = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.communicationType
  );
  const agentId = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo.userDetail["_id"]
  );
  const leftsideProfileDetails = useSelector(
    (state: any) => state.profile.navigationReducer.leftSideNavigation.data
  );

  // const accountId = useSelector(
  //   (store: RootState) =>
  //     store.loginReducer.userLoginInfo.userDetail.accountDetails[0]["id"]
  // );

  const dispatch: AppDispatch = useDispatch();
  // path for profile
  useEffect(() => {
    if (leftsideProfileDetails?.length === 0) {
      dispatch(
        getLeftsideModuleProfileDetails({ id: userId, teamId: currentTeamId })
      );
    }
  }, []);
  const [pathValidity, setPathValidity] = useState<{ profile: boolean }>({
    profile: false,
  });

  // route name
  const ROUTE_NAME_PROFILE = new Map([
    ["Your Account", "your-account"],
    ["Billing", "billing"],
    ["Settings", "settings"],
  ]);
  const location = useLocation();

  // profile routings

  useEffect(() => {
    let profileModulesTemp: {
      name: string;
      displayName: string;
      routeName: string;
      active: boolean;
      childModules: any[];

      activeIcon: string;
      inActiveIcon: string;
      activeColor: string; // #F6F6F6
      color: string; //black
      indexNo: number;
    }[] = [];
    profileModulesTemp = leftsideProfileDetails.flatMap((each: any) => {
      if (!each.isVisible) {
        return [];
      }
      return [
        {
          name: each.name,
          displayName: each.name,
          routeName: each.routeName,
          active: false,
          childModules: [],

          activeIcon: each.icon,
          inActiveIcon: each.icon,
          activeColor: "#F6F6F6",
          color: "black",
          indexNo: each.indexNo,
        },
      ];
    });
    //sort the menu
    const sortedArray = profileModulesTemp
      .sort((a, b) => {
        return a.indexNo - b.indexNo;
      })
      .map((e) => {
        return { ...e, indexNo: undefined };
      });
    // const yourAccount = profileModulesTemp.filter(
    //   (e) => e.name === "Your Account"
    // );
    // if (yourAccount.length > 0) sortedArray.push(yourAccount[0]);
    // const settings = profileModulesTemp.filter((e) => e.name === "Settings");
    // if (settings.length > 0) sortedArray.push(settings[0]);
    // const billing = profileModulesTemp.filter((e) => e.name === "Billing");
    // if (billing.length > 0) sortedArray.push(billing[0]);
    // setProfileModules(sortedArray);
    const path = location.pathname.split("/").filter((e) => e !== "");
    // console.log(location, "location");
    // console.log(path, "location left");
    switch (path[0]) {
      case "profile":
        if (path.length > 1) {
          setProfileModules((prev) => {
            prev = sortedArray.map((e) => {
              return {
                ...e,
                active: location.pathname.includes(e.routeName),
              };
            });
            // console.log(prev, "location");
            return prev;
          });
        }
        setPathValidity((prev) =>
          prev?.profile === true ? prev : { profile: true }
        );
        break;
      default:
        setPathValidity((prev) =>
          prev?.profile === false ? prev : { profile: false }
        );
    }
  }, [leftsideProfileDetails, location]);
  useEffect(() => { }, [location]);
  // useEffect(() => {
  //   console.log("nithinn", { profileModules });
  // }, [profileModules]);
  // const [currentProfile, setCurrentProfile] = useState("Your Account");
  function setProfileActiveTab(name: string) {
    navigate(name);
  }

  // setProfileModules((prev) => {
  //   prev = prev.map((e) => {
  //     return { ...e, active: false };
  //   });
  //   prev[index].active = true;
  //   console.log({ prev }, "nithinn");
  //   return [...prev];
  // });
  //}
  //setActive Tab With Location

  //****************************************** */
  /* useState */
  // const [navbarOptionsToShow, setNavbarOptionsToShow] =
  //   useState<optionType[]>();

  // const moduleName: string[] = moduleDetails.map((item: any) =>
  //   item.name.toLowerCase()
  // );
  // moduleName.push("dashboard");

  // useEffect(() => {
  //   const optionsToShow = navbarOptions.filter((item: optionType) =>
  //     moduleName?.includes(item.pageName)
  //   );
  //   setNavbarOptionsToShow(optionsToShow);
  //   // console.log(moduleName);
  // }, []);

  const navigate = useNavigate();

  const [activeSubModule, setActiveSubModule] = useState<string>("");

  /* useEffect */
  useEffect(() => {
    const urlPath = location.pathname.substring(
      location.pathname.indexOf("/") + 1
    );
    if (urlToModuleMapping[urlPath]) {
      dispatch(setIsActivePageType(urlToModuleMapping[urlPath]));
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(setHighlightedModule(subModuleMapping[activePage]));
  }, [activePage]);

  // handlers
  const setActiveOption = (moduleData: any) => {
    const url = AGENT_DESKTOP_API.UPDATE_STATUS;
    const data = {
      previousState: "refresh",
      currentState: "refresh",
      agentId: agentId,
      accountId: accountId,
    };
    const res = axios.post(url, data);
    if (activePage !== moduleData.name && moduleData.routeName) {
      // dispatch(setSelectedSourceTab(sourceDetails?.analytics[0]?.name));
      // dispatch(setSubHeaderTabs("", "", ""));
    }
    setActiveSubModule(moduleData.name);
    if (moduleData.isModuleShown) {
      const { dataModules: newModuleDetails } = getLeftSideModuleDetails(
        _.cloneDeep(moduleDetails)
      ); // make only isShownModules visible
      dispatch(setLeftSideModuleDetails(newModuleDetails));
      // console.log("module", newModuleDetails);
    }
    if (moduleData.routeName && !moduleData.childModules?.length) {
      setActiveSubModule("");
      // dispatch(setIsActivePageType(moduleData.name));
      setTimeout(() => navigate(`/${moduleData.routeName}`));
    }
    dispatch(setShowRightSideBar(false));
    dispatch(setSelectedFilterType(""));

    if (moduleData.routeName == "campaignmanager") {
      dispatch(
        getWorkflowsAPIData({
          token: token,
          useCaseId: teamUseCaseId,
          teamId: currentTeamId,
          accountId: currentAccountId
        })
      );
    }

    if (moduleData.routeName == "strategy") {
      dispatch(clearAllStrategyData());
      dispatch(clearAllCampaignData());
      setCampaignClientName("")(dispatch);
    }

    // if (moduleData.routeName == "userlist") {
    //   dispatch(clearTeamsData());
    //   dispatch(getAllTeamsData({ userId: loginUserId }));
    // }

    // console.log(moduleData.routeName, "route");
  };

  /* handle */
  const [showGetHelp, setShowGetHelp] = useState(false);

  // console.log("modules", moduleDetails);

  return (
    <>
      <div
        className={`${styles.leftMenu__container} ${isOnlyIcons
            ? `${styles.onlyIcon} ${optionStyles.onlyIcon} onlyIcon`
            : ""
          } ${communicationType === "call" ? "disablePointerEventUniversaljp" : ""
          }`}
        id="auto_main_leftMenu_1"
      >
        <div className={styles.innerDiv} id="auto_main_leftMenu_2">
          {pathValidity.profile ? (
            <div className={styles.profile}>
              {profileModules.map((option, index) => {
                return (
                  <div
                    className={styles.leftMenuOption__container}
                    data-is-down={true}
                    key={index}
                    id={"auto_left_" + option.name.trim().replace(/\s/g, "")}
                  >
                    <div className={styles.leftMenuOption__innerDiv}>
                      <Option
                        optionData={option}
                        setActiveOption={() => {
                          setProfileActiveTab(option.routeName);
                        }}
                        isOnlyIcons={false}
                        highlightedModule={option.active ? option.name : ""}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              {moduleDetails
                ?.filter((option: any, index: number) => {
                  if (option.name !== "Exclusions") return true;
                  else if (userName === "sudhanshu.shekhar@saarthi.ai")
                    return true;
                  else return false;
                })
                .map((option: any, index: number) => {
                  return (
                    option.shouldFrontEndShow && (
                      <div
                        className={styles.leftMenuOption__container}
                        data-is-down={option.isDown}
                        key={index}
                        id={
                          "auto_left_" + option.name.trim().replace(/\s/g, "")
                        }
                      >
                        <div
                          className={`${styles.leftMenuOption__innerDiv} ${option.name === screenType.callLoggerAgent.name
                              ? "disablePointerEventUniversalLowOpacity"
                              : ""
                            }`}
                        >
                          {activeSubModule === option.name &&
                            option?.childModules?.length > 0 && (
                              <SubModules
                                subModulesArr={option.childModules}
                                setActiveOption={setActiveOption}
                                activeSubModule={activeSubModule}
                                setActiveSubModule={setActiveSubModule}
                              />
                            )}
                          <Option
                            optionData={option}
                            setActiveOption={setActiveOption}
                            isOnlyIcons={isOnlyIcons}
                            highlightedModule={highlightedModule}
                          />
                        </div>
                      </div>
                    )
                  );
                })}
              <div
                className={`${styles.leftMenuOption__container} `}
                data-is-down={true}
                id={"auto_left_info"}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://help.pravid.io/docs"
                  className={styles.link}
                >
                  <div className={styles.divxx}>
                    <img src={questx} alt="" />
                    {!isOnlyIcons && <p>Help</p>}
                  </div>
                </a>
              </div>
            </>
          )}
          {/* <div
            className={`${styles.leftMenuOption__container} ${styles.getHelpWrapper}`}
            onClick={() => {
              setShowGetHelp((prev) => !prev);
            }}
          >
            <div className={styles.getHelp}>
              <PravidIcons activeIcon={"get_info"} />
              {!isOnlyIcons && <span>Get Help</span>}
            </div>
          </div> */}
          {/* {showGetHelp && (
            <div className={styles.popUp}>
              <GetInfo
                close={() => {
                  setShowGetHelp(false);
                }}
              ></GetInfo>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
}
