import React, { useEffect } from "react";
import { useState } from "react";
import SummaryContent from "./subComponents/SummaryContent";
import { useCurrentPageTab } from "../../../../../hooks";
import { getDataFromSchema } from "../../../../../utils";
import { useSelector } from "react-redux";
import {
  getValueByReferenceKey,
  getValueByReferenceKey2,
} from "../../../../../utils";
import { parentChannels } from "../../../../../constants";

interface props {
  summary: any;
}
type summaryObjectType = {
  mainKey: string;
  subKey: { [key: string]: string };
};

export default function Summary(props: props) {
  /*****************************  constants  *****************************/
  const callSummaryData: { [key: string]: any } = props.summary;

  /*****************************  redux hooks  *****************************/
  const transcriptData: any[] = useSelector((state: any) => {
    return state.loggerReducer?.loggerState?.transcriptData;
  });
  const {
    schema,
    isActivePageType,
    subChannelTab,
    subModuleTab,
    channelTab,
    currentLoggerPage,
  } = useCurrentPageTab();

  /*****************************  useStates  *****************************/
  const [summaryBody, setSummaryBody] =
    useState<Record<string, Record<string, string>[]>>();
  const [dataToBeShown, setDataToBeShown] = useState<any>([]);

  /*****************************  useEffects  *****************************/
  useEffect(() => {
    if (
      channelTab?.name &&
      currentLoggerPage &&
      isActivePageType &&
      schema &&
      Object.keys(schema)?.length &&
      (subChannelTab?.name || !parentChannels.includes(channelTab?.name)) &&
      subModuleTab?.name
    ) {
      setDataToBeShown(
        getDataFromSchema({
          channel: channelTab,
          dataType: "summaries",
          schemaData: schema,
          subChannel: subChannelTab,
          tab: subModuleTab,
          screen: isActivePageType,
          subScreen: currentLoggerPage,
        })
      );
    }
  }, [
    schema,
    subChannelTab?.name,
    subModuleTab?.name,
    channelTab?.name,
    isActivePageType,
    currentLoggerPage,
  ]);

  useEffect(() => {
    if (callSummaryData && dataToBeShown.length > 0) {
      const summaryBody: Record<string, Record<string, string>[]> = {};
      dataToBeShown.forEach((category: any) => {
        // category.mainKey;
        const categoryBody = category?.kpiDetails?.subKey?.map(
          (item: Record<string, string>) => {
            //   item;
            return {
              [item?.keyName]: getValueByReferenceKey2(
                callSummaryData,
                item?.referenceKeyName?.substring(
                  8,
                  item?.referenceKeyName?.length
                )
              ),
            };
          }
        );
        // categoryBody
        summaryBody[category.customName] = categoryBody;
      });

      setSummaryBody(summaryBody);
    }
  }, [callSummaryData, transcriptData, dataToBeShown]);

  /*****************************  JSX  *****************************/
  return (
    <div>
      <SummaryContent summaryBody={summaryBody} />
    </div>
  );
}
