import React, { useEffect, useState } from "react";
import "./ResponseRate.css";
import ProgressBarComponent from "./progressBarComponent/ProgressBarComponent";
import NoDatamodel from "../../generic/noDatamodel/NoDatamodel";
import { noPhoneDataIcon } from "../../../theme/assets/svg";
import PravidIcons from "../../generic/pravidIcons/PravidIcons";
const ResponseRate = (props) => {
  const [nodataFound, setNoDataFound] = useState(true);

  let refenceKeyForData = props.chartDetails?.refenceKeyForData;
  let total = props.data[refenceKeyForData] && props.data[refenceKeyForData]["totalRecordsCount"];
  // var rateArr = [
  //   {
  //     icon: config?.responseRateChart?.chart?.icon1,
  //     title: config?.responseRateChart?.chart?.title1,
  //     value: ((100 * config?.responseRateChart?.chart?.value1) / total).toFixed(
  //       0
  //     ),
  //   },
  //   {
  //     icon: config?.responseRateChart?.chart?.icon2,
  //     title: config?.responseRateChart?.chart?.title2,
  //     value: ((100 * config?.responseRateChart?.chart?.value2) / total).toFixed(
  //       0
  //     ),
  //   },
  //   {
  //     icon: config?.responseRateChart?.chart?.icon3,
  //     title: config?.responseRateChart?.chart?.title3,
  //     value: ((100 * config?.responseRateChart?.chart?.value3) / total).toFixed(
  //       0
  //     ),
  //   },
  //   {
  //     icon: config?.responseRateChart?.chart?.icon4,
  //     title: config?.responseRateChart?.chart?.title4,
  //     value: ((100 * config?.responseRateChart?.chart?.value4) / total).toFixed(
  //       0
  //     ),
  //   },
  // ];

  const rateArr =
      props.chartDetails && props.chartDetails?.keys.length > 0
      ? props.chartDetails?.keys.sort((a, b) => {
            return a.position - b.position;
          })
      : [];
      // console.log(props.chartDetails,rateArr,"999")

      useEffect(() => {
        let temp =
          props.data &&
          props.data[refenceKeyForData] &&
          Object.values(props.data[refenceKeyForData])?.map((each) => {
            if (each > 0) {
              setNoDataFound(false);
            }
          });
      }, []);

  return (
    <div className="responseRateMainDiv">
      {!nodataFound ?  (
        <div>
          {rateArr.map((each, i) => {
            // console.log(each.referenceKeyName,"88")
            return (
              <div key={i} className="responseRate">
                <div className="icons">
                <PravidIcons
                  activeIcon={each.icon}
                />
                </div>
                <div className="name">{each.name}</div>
                <div style={{ width: "50%" }}>
                  <ProgressBarComponent
                    isLoading={false}
                    percent={((100*props.data[refenceKeyForData][each.referenceKeyName])/total).toFixed(0)}
                    // percent={80}
                    size={"large"}
                    showInfo={true}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="notFound">
          <NoDatamodel srcImg={noPhoneDataIcon}
          extraCss={{ width:"200px" }}></NoDatamodel>
        </div>
      )}
    </div>
  );
};


export default ResponseRate;
