import "./DefaultStyles.css";

export const mergeWithDefaultData = (
  uploadedData: any,
  defaultRows: number,
  defaultCols: number
): any[][] => {
  const rows = Math.max(defaultRows, uploadedData.length);
  const columns = Math.max(defaultCols, uploadedData[0]?.length || 0);

  // Create an array to keep track of the number of unique values in each column
  const uniqueValueCounts: number[] = Array(columns).fill(0);

  // Calculate the number of unique values in each column
  for (let i = 0; i < uploadedData.length && i < rows; i++) {
    for (let j = 0; j < uploadedData[i].length && j < columns; j++) {
      const cell = uploadedData[i][j].value;
      if (cell) {
        uniqueValueCounts[j] += 1;
      }
    }
  }

  // Create an array to store the indices of columns to keep
  const columnsToKeep: number[] = [];
  for (let j = 0; j < columns; j++) {
    if (uniqueValueCounts[j] > 1) {
      columnsToKeep.push(j);
    }
  }

  // Create the mergedData array with only the columns to keep
  const mergedData: any[][] = Array.from({ length: rows }, () =>
    Array(columnsToKeep.length).fill({ value: "", className: "" })
  );

  for (let i = 0; i < uploadedData.length && i < rows; i++) {
    let k = 0; // Index for the mergedData columns
    for (let j = 0; j < uploadedData[i].length && j < columns; j++) {
      if (columnsToKeep.includes(j)) {
        const cell = uploadedData[i][j];
        mergedData[i][k] = cell;
        k++;
      }
    }
  }

  return mergedData;
};
