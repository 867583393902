import { takeLatest } from "redux-saga/effects";
import actionTypes from "../telephony.actionTypes";
import {
  getAllTelephonySystemAPIWorker,
  getDefaultTelephonySystemAPIWorker,
  getAllSIPGatewayAPIWorker,
  getClientTelephonyDataAPIWorker,
  getDefaultTelephonyDataAPIWorker,
  setTelephonyDataAPIWorker,
  setDefaultTelephonyDataAPIWorker,
} from "./telephony.saga";
export default function* telephonyDataWatcher(): any {
  yield takeLatest(
    actionTypes.GET_ALL_TELEPHONY_SYSTEM_REQUEST,
    getAllTelephonySystemAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_DEFAULT_TELEPHONY_SYSTEM_REQUEST,
    getDefaultTelephonySystemAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_DATA_CENTER_REQUEST,
    getAllSIPGatewayAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_DEFAULT_TELEPHONY_DATA_REQUEST,
    getDefaultTelephonyDataAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_CLIENT_TELEPHONY_DATA_REQUEST,
    getClientTelephonyDataAPIWorker
  );
  yield takeLatest(
    actionTypes.SET_TELEPHONY_DATA_REQUEST,
    setTelephonyDataAPIWorker
  );
  yield takeLatest(
    actionTypes.SET_DEFAULT_TELEPHONY_DATA_REQUEST,
    setDefaultTelephonyDataAPIWorker
  );
}
