import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./HubspotDemo.css";
import HubspotForm from "react-hubspot-form";
import { useMediaQuery } from "../../../../../screens/onBoarding/mediaQuery";
const HubspotDemo = (props) => {
  let isPageWide = useMediaQuery("(max-width: 480px)");
  return (
    <div className="HubSpotDemoWrapper">
      <div className="HubspotDemoDiv">
        {!isPageWide ? (
          <div className="firstDivHeading">
            <p className="paraBold">
              Pravid is here to give you complete control
            </p>
            <p className="paraBold">over your business communications</p>
            <p className="paraSmall">
              Don't take our word for it -{" "}
              <span className="spanColor"> see it yourself</span>
            </p>
          </div>
        ) : (
          <div className="firstDivHeading">
            <p className="paraBold">Pravid is here to give you</p>
            <p className="paraBold">complete control over your</p>
            <p className="paraBold">business communications</p>
            <p className="paraSmall">
              Don't take our word for it -{" "}
              <span className="spanColor"> see it yourself</span>
            </p>
          </div>
        )}
        <div className="secondDivContainer">
          <div className="secondInner"></div>
          <HubspotForm
            portalId="20107629"
            formId="1712e859-f655-43e0-abf6-6c3b7d8ccd6c"
            onSubmit={() => console.log("Submit!")}
            onReady={(form) => console.log("Form ready!")}
            loading={<div>Loading...</div>}
          />
        </div>
      </div>
      {/* <ToastContainer
        position="top-center"
        type="success"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={true}
      /> */}
    </div>
  );
};

export default HubspotDemo;
