import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { config, PROFILE_API } from "../../../../services/ApiRoutes";
import navigationActionTypes from "../navigation.actionTypes";
import {
  getLeftSideModuleDetails,
  mapSubModulesToModulesFunc,
  mapUrlToModulesFunc,
} from "../../../../components/moduleComponents/onBoarding/login/loginField/LoginFields.functions";
import _ from "lodash";
import {
  setDefaultSelectedModule,
  setHighlightedModule,
  setLeftSideModuleDetails,
  setSubModuleMappingToModule,
  setUrlToModuleMapping,
} from "../../../baseScreen/leftMenu";
import {
  setIsActivePageType,
  setIsPageType,
} from "../../../onboarding/login/actions";
import { store } from "../../../rootStore";
import actionTypes from "../../../dashboard/actionTypes";

export function* getLeftsideModuleNavigationDetailsSaga(action: {
  type: string;
  payload: any;
}): any {
  try {
    if (action) {
      const response = yield call(
        config.GET_WITH_PARAMS,
        PROFILE_API.GET_MODULE_DETAILS,
        {
          id: action.payload.id,
          teamId: action.payload.teamId,
        }
      );
      yield put({
        type: navigationActionTypes.PROFILE_MODULE_GET_API_SUCCESS,
        payload: response.data.data,
      });
    }
    // console.log(response, "nithin saga responses");
  } catch (Err: any) {
    // toast.error(Err.response?.data?.error);
    yield put({
      type: navigationActionTypes.PROFILE_MODULE_GET_API_FAILURE,
      payload: Err,
    });
    console.error(Err);
  }
}
export function* getDropDownHomeDetails(action: {
  type: string;
  payload: any;
}): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        PROFILE_API.GET_TOP_DROPDOWN,

        {
          id: action.payload.id,
          teamId: action.payload.teamId,
          screenName: "Home",
        }
      );
      //console.log("nithin saga home", { result });
      yield put({
        type: navigationActionTypes.DROPDOWN_HOME_GET_API_SUCCESS,
        payload: result?.data?.data?.[0]?.itemList,
      });
    }
  } catch (error) {
    yield put({
      type: navigationActionTypes.DROPDOWN_HOME_GET_API_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}

export function* getDropDownSettingDetails(action: {
  type: string;
  payload: any;
}): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        PROFILE_API.GET_TOP_DROPDOWN,
        {
          id: action.payload.id,
          teamId: action.payload.teamId,
          screenName: "Setting",
        }
      );
      yield put({
        type: navigationActionTypes.DROPDOWN_SETTING_GET_API_SUCCESS,
        payload: result.data.data[0].itemList,
      });
    }
    //console.log("nithin saga setting", { result });
  } catch (error) {
    yield put({
      type: navigationActionTypes.DROPDOWN_SETTING_GET_API_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}

export function* setCurrentTeamProfile(action: any): any {
  // console.log(action?.payload, "payload");
  const userEmailId =
    store.getState().loginReducer.userLoginInfo.userDetail.email;
  const moduleDetails = action?.payload?.moduleDetails;
  if (moduleDetails) {
    const { dataModules, defaultSelected } = getLeftSideModuleDetails(
      _.cloneDeep(moduleDetails)
    );
    // console.log("teamModules", action?.payload?.moduleDetails);
    yield put(setLeftSideModuleDetails(dataModules));
    yield put(setDefaultSelectedModule(defaultSelected));
    yield put(setIsActivePageType(defaultSelected.name));
    yield put(setHighlightedModule(defaultSelected.name));
    const subModuleMapping = mapSubModulesToModulesFunc(moduleDetails);
    yield put(setSubModuleMappingToModule(subModuleMapping));
    const urlToModulesMapping = mapUrlToModulesFunc(moduleDetails);
    console.log(urlToModulesMapping);
    yield put(setUrlToModuleMapping(urlToModulesMapping));
    yield put(setIsPageType("dashboard"));
  }

  const role = action?.payload?.teamMembers.find(
    (item: Record<string, any>) => item.email === userEmailId
  )?.role;
  if (role) {
    // console.log("update role");
    yield put({
      type: navigationActionTypes.UPDATE_ROLE_BY_TEAM,
      payload: role,
    });
  }
}
