import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine
} from "recharts";
import { useDispatch, useSelector } from "react-redux";

// import { Colors } from "./colors";
import moment from "moment";
import NoDatamodel from "../../../generic/noDatamodel/NoDatamodel";
import { noPhoneDataIcon } from "../../../../theme/assets/svg";
function CrmSimpleBarChart(props) {
  const [data, setData] = useState([]);
  const [year, setYear] = useState([]);

  const expandedChart = useSelector(
    (store) => store?.analyticsReducer?.expandedChart
  );

  const isSort = useSelector((store) => store?.analyticsReducer?.isSort);

  const sortedChart = useSelector(
    (store) => store?.analyticsReducer?.sortedChart
  );

  
  let key = props.chartDetails?.legendData[0];
  let barWidth =
    parseInt(props.chartDetails?.chartStyle?.width) < 600
      ? parseInt(props.chartDetails?.chartStyle?.width)
      : parseInt(props.chartDetails?.chartStyle?.width) - 100;
  let barHeight = parseInt(props.chartDetails?.chartStyle?.height) - 80;
//   let expandbarHeight =
//     parseInt(props.chartDetails?.expand?.expandStyle?.height) - 60;
  let value1 = props.chartDetails?.keys[0].referenceKeyName;
  let value2 = props.chartDetails?.keys[1].referenceKeyName;
//   let refenceKeyForData = props.chartDetails?.refenceKeyForData;
let barSize = parseInt(props.chartDetails?.chartStyle?.barSize);
  let fill = props.chartDetails?.keys[1]?.fillColor

  useEffect(() => {
    if (props.chartDetails && props.data) {
      let temp = props?.data 
      
      let finalData = temp?.map((each, i) => {
       
        return {
          name: each[value1],
          [key]: each[value2],
          tooltipName1: props.chartDetails?.keys[0]?.name,
          tooltipName2: props.chartDetails?.keys[1]?.name,
        };
      });
      setData(finalData);
    }
  }, [props.chartDetails, props.data]);

//   const setSortedData = (data) => {
//     let tempArr = year;
//     let finalData = data?.map((each, i) => {
//       var getDate = moment(each[value1], "DD/MM/YYYY").format("MM/DD/YYYY");
//       var dates = moment(getDate).format("DD MMM").toString();
//       let yrData = moment(getDate).format("YYYY");
//       tempArr.push(yrData);
//       setYear(tempArr);
//       return {
//         name:
//           props.chartDetails?.keys[0]?.name === "Date" ? dates : each[value1],
//         [key]: each[value2],
//         [RespondentPercentage]: `${each[value2]}(${(
//           (each[value2] / perValue) *
//           100
//         ).toFixed(2)}%)`,
//         amt:
//           props.chartDetails?.keys[0]?.name === "No. of Attempt"
//             ? `${((each[value2] / perValue) * 100).toFixed(0)}%`
//             : 0,
//         tooltipName1: props.chartDetails?.keys[0]?.name,
//         tooltipName2: props.chartDetails?.keys[1]?.name,
//       };
//     });
//     setData(finalData);
//   };

//   useEffect(() => {
//     if (isSort && props.chartTitle === sortedChart) {
//       let tempdata = props?.data && props?.data[refenceKeyForData];
//       props.chartDetails?.keys[0]?.name === "Date"
//         ? tempdata?.sort((a, b) =>
//             new Date(moment(a[value1], "DD/MM/YYYY").format("YYYY/MM/DD")) <
//             new Date(moment(b[value1], "DD/MM/YYYY").format("YYYY/MM/DD"))
//               ? 1
//               : new Date(moment(b[value1], "DD/MM/YYYY").format("YYYY/MM/DD")) <
//                 new Date(moment(a[value1], "DD/MM/YYYY").format("YYYY/MM/DD"))
//               ? -1
//               : 0
//           )
//         : props.chartDetails?.sort?.showSortButton === false ?
//           tempdata
//         : tempdata?.sort((a, b) =>
//             a[value1] < b[value1] ? 1 : b[value1] < a[value1] ? -1 : 0
//           );

//       setSortedData(tempdata);
//     } else {
//       let tempdata = props?.data && props?.data[refenceKeyForData];
//       props.chartDetails?.keys[0]?.name === "Date"
//         ? tempdata?.sort((a, b) =>
//             new Date(moment(a[value1], "DD/MM/YYYY").format("YYYY/MM/DD")) >
//             new Date(moment(b[value1], "DD/MM/YYYY").format("YYYY/MM/DD"))
//               ? 1
//               : new Date(moment(b[value1], "DD/MM/YYYY").format("YYYY/MM/DD")) >
//                 new Date(moment(a[value1], "DD/MM/YYYY").format("YYYY/MM/DD"))
//               ? -1
//               : 0
//           )
//           : props.chartDetails?.sort?.showSortButton === false ?
//           tempdata
//         : tempdata?.sort((a, b) =>
//             a[value1] > b[value1] ? 1 : b[value1] > a[value1] ? -1 : 0
//           );

//       setSortedData(tempdata);
//     }
//   }, [isSort, props.data[refenceKeyForData]]);

  

//   const CustomTooltipSaarthi = ({ active, payload, label }) => {
//     const tempPos =
//       props.chartDetails && props.chartDetails?.keys.length > 0
//         ? props.chartDetails?.keys.sort((a, b) => {
//             return a.position - b.position;
//           })
//         : [];
//     if (active) {
//       return (
//         <div
//           className="custom-tooltip"
//           style={{
//             background: "#ffffff",
//             height: "120px",
//             width: "250px",
//             textAlign: "center",
//             paddingTop: "20px",
//             // fontSize: "15px",
//           }}
//         >
//           {tempPos &&
//             tempPos.map((each) => {
//               return (
//                 <>
//                   {each.referenceKeyName === payload[0]?.payload?.name && (
//                     <p className="label">{`${each.name}`}</p>
//                   )}
//                   {each.referenceKeyName === payload[0]?.payload?.name && (
//                     <p className="label">{`Customer Count in ${payload[0]?.payload?.name}: ${payload[0]?.value}`}</p>
//                   )}
//                 </>
//               );
//             })}
//         </div>
//       );
//     }

//     return null;
//   };
console.log(data,"999")
  return (
    // props.isLoading === true && props.isselectedTab !== "payment" && props.isselectedTab !== "customer" ?
    //   <Loading />
    //   :
    data?.length > 0 ? (
      <div
        style={{
          minWidth: `${data.length * 80}px`,
          height: `${barHeight}px`,
          width: `${barWidth}px`,
        }}
      >
        <ResponsiveContainer height="100%">
          <BarChart
            width={barWidth}
            height={barHeight}
            data={data}
            margin={{
              top: 20,
              right: 20,
              bottom: 30,
              left: 30,
            }}
            barCategoryGap={10}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              style={{
                fontSize: `${
                  parseInt(props.chartDetails?.chartStyle?.width) < 600
                    ? "10px"
                    : "15px"
                }`,
              }}
            >
              <Label
                // angle={200}
                position="insideBottomMiddle"
                style={{
                  textAnchor: "middle",
                  fill: "#000000",
                  padding: "20px",
                  margin: "20px",
                }}
                dy={30}
                offset={-50}
              >
                {props.chartDetails?.keys[0]?.name}
              </Label>
              {/* } */}
            </XAxis>
            <YAxis interval={1}>
              <Label
                angle={270}
                offset={40}
                dx={30}
                position="left"
                style={{ textAnchor: "middle", fill: "#000000" }}
              >
                {props.chartDetails?.keys[1]?.name}
              </Label>
              {/* } */}
            </YAxis>
            
            {/* <Tooltip 
            // formatter={function (value, name, props, index) {
            //     return ;
            //   }}
              formatter={function (value, name, props, index) {
                 
                return (
                    <>
                    <p>`${props.chartDetails?.keys[0]?.name}: ${value}`</p>
                    <p>`${props.chartDetails?.keys[1]?.name}: ${props.value}`</p>
                    </>
                    );
              }}
            /> */}
           
            {/* <Bar dataKey="pv" fill="#8884d8" /> */}
            <ReferenceLine x={20} stroke="#0047FF" strokeDasharray="5 5"  />
            <ReferenceLine x={40} stroke="#F94144" strokeDasharray="5 5"  />
            <Bar
              isAnimationActive={false}
              dataKey={key}
              style={{ width: "100px" }}
              barSize={barSize}
              fill={fill}
            >
              <LabelList
                dataKey={key}
                position="top"
                style={{ fontSize: "10px", fontWeight: "500", fill: "#000000" }}
              />
            </Bar>
           
          </BarChart>
        </ResponsiveContainer>
      </div>
    ) : (
      <div
        className="no-data"
        style={{
          height: `${
            expandedChart && expandedChart.includes(props.chartTitle)
              ? "455px"
              : "310px"
          }`,
        }}
      >
        
          <NoDatamodel srcImg={noPhoneDataIcon}></NoDatamodel>
      </div>
    )
  );
}

export default CrmSimpleBarChart;
