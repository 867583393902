import React from "react";
import styles from "./BeginnerPlan.module.scss";
import { deletebtn } from "../../../../theme/assets/genericSvg";

interface Props {
  children?: React.ReactNode;
  planName: string;
  renewDate: string;
  status: string;
  onClickDelete?: CallableFunction;
}
const BeginnerPlan: React.FC<Props> = (props: Props) => {
  return (
    <div className={styles.wrapaper}>
      <div className={styles.left}>
        <p> {props.planName}</p>
      </div>
      <div className={styles.right}>
        <p>{props.status}</p>
        <p>Expires on {props.renewDate}</p>
        <img
          onClick={() => {
            props.onClickDelete && props.onClickDelete();
          }}
          src={deletebtn}
          alt=""
        />
      </div>
    </div>
  );
};

export default BeginnerPlan;
