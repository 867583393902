export default {
  GET_CAMPAIGN_OPTIONS_API_REQUEST: "GET_CAMPAIGN_OPTIONS_API_REQUEST",
  GET_CAMPAIGN_OPTIONS_API_SUCCESS: "GET_CAMPAIGN_OPTIONS_API_SUCCESS",
  GET_CAMPAIGN_OPTIONS_API_FAILURE: "GET_CAMPAIGN_OPTIONS_API_FAILURE",

  GET_CAMPAIGN_ASR_API_REQUEST: "GET_CAMPAIGN_ASR_API_REQUEST",
  GET_CAMPAIGN_ASR_API_SUCCESS: "GET_CAMPAIGN_ASR_API_SUCCESS",
  GET_CAMPAIGN_ASR_API_FAILURE: "GET_CAMPAIGN_ASR_API_FAILURE",

  GET_CAMPAIGN_TTS_API_REQUEST: "GET_CAMPAIGN_TTS_API_REQUEST",
  GET_CAMPAIGN_TTS_API_SUCCESS: "GET_CAMPAIGN_TTS_API_SUCCESS",
  GET_CAMPAIGN_TTS_API_FAILURE: "GET_CAMPAIGN_TTS_API_FAILURE",

  CREATE_CAMPAIGN_DATA_API_REQUEST: "CREATE_CAMPAIGN_DATA_API_REQUEST",
  CREATE_CAMPAIGN_DATA_API_SUCCESS: "CREATE_CAMPAIGN_DATA_API_SUCCESS",
  CREATE_CAMPAIGN_DATA_API_FAILURE: "CREATE_CAMPAIGN_DATA_API_FAILURE",

  UPDATE_CAMPAIGN_DATA_API_REQUEST: "UPDATE_CAMPAIGN_DATA_API_REQUEST",
  UPDATE_CAMPAIGN_DATA_API_SUCCESS: "UPDATE_CAMPAIGN_DATA_API_SUCCESS",
  UPDATE_CAMPAIGN_DATA_API_FAILURE: "UPDATE_CAMPAIGN_DATA_API_FAILURE",

  SET_CAMPAIGN_DATA: "SET_CAMPAIGN_DATA",
  CLEAR_CAMPAIGN_DATA: "CLEAR_CAMPAIGN_DATA",
};
