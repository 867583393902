// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sc0vJ0XUtmrvoOeQh3qd{display:flex;align-items:center;justify-content:center;height:50%;color:var(--primary-grey);width:100%;height:439.7px}.sc0vJ0XUtmrvoOeQh3qd .UsVHC6dksSQFTtZ1zusx{font-family:"Roboto";font-style:normal;font-weight:400;font-size:18px;line-height:26px}`, "",{"version":3,"sources":["webpack://./src/components/generic/conversationCards/wrapper.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,yBAAA,CACA,UAAA,CACA,cAAA,CAEA,4CACE,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".noConversation {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 50%;\n  color: var(--primary-grey);\n  width: 100%;\n  height: 439.7px;\n\n  .message {\n    font-family: \"Roboto\";\n    font-style: normal;\n    font-weight: 400;\n    font-size: 18px;\n    line-height: 26px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noConversation": `sc0vJ0XUtmrvoOeQh3qd`,
	"message": `UsVHC6dksSQFTtZ1zusx`
};
export default ___CSS_LOADER_EXPORT___;
