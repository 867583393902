import actionTypes from "../actionTypes";
const initialState = {
  show: false,
  id: "",
  setting: {},
  showRight: false,
  itemSelected: "",
  isBackBtnClicked: false,
  selectedNudgeData: {},
  tempScriptData: {},
};
export default function individualNudgeReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case actionTypes.CLEAR_SETTING_OF_NUDGE:
      return { ...initialState };

    case actionTypes.SET_EACH_PROP_OF_NUDGE: {
      return { ...state, setting: { ...state.setting, ...action.payload } };
    }
    case actionTypes.SET_SHOW_INDIVIDUAL_NUDGE_SETTING: {
      if (action.payload) {
        return { ...state, show: action.payload, showRight: false };
      }
      return { ...state, show: action.payload };
    }
    case actionTypes.TOGGLE_SHOW_INDIVIDUAL_NUDGE_SETTING: {
      return { ...state, show: !state.show };
    }
    case actionTypes.SET_NUDGE_ID: {
      return { ...state, id: action.payload };
    }
    case actionTypes.SET_EACH_PROP_OF_NUDGE_ALL: {
      return { ...state, setting: { ...action.payload } };
    }
    case actionTypes.CLEAR_SETTING_DATA: {
      return { ...state, setting: {} };
    }
    case actionTypes.SET_SHOW_EDIT_ALL_POP: {
      if (action.payload) {
        return { ...state, showRight: action.payload, show: false };
      }
      return { ...state, showRight: action.payload };
    }
    case actionTypes.SET_SELECTED_ITEM_ON_RIGHT: {
      return {
        ...state,
        itemSelected: action.payload,
      };
    }
    case actionTypes.SET_IS_BACK_FROM_SCRIPT_SELECT: {
      return {
        ...state,
        isBackBtnClicked: action.payload,
      };
    }
    case actionTypes.SET_SELECTED_NUDGE_DATA: {
      return {
        ...state,
        selectedNudgeData: { ...state.selectedNudgeData, ...action.payload },
      };
    }
    case actionTypes.CLEAR_SELECTED_NUDGE_DATA: {
      return {
        ...state,
        selectedNudgeData: {},
      };
    }

    case actionTypes.SET_TEMP_SCRIPT_DATA: {
      return {
        ...state,
        tempScriptData: action.payload,
      };
    }
    default:
      return state;
  }
}
