import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import styles from "./WhatsAppScreen.module.scss";
import {
  // whatsAppIntegration,
  closeIntegrationIcon,
} from "../../../../theme/assets/svg/integration_V2.0/index";
import { backIcon } from "../../../../theme/assets/svg/strategySvg";
import Icon from "../../../generic/icon/Icon";
import Modal from "../../../generic/modal/Modal";
import Button from "../../../generic/button/Button";
import { InitialWhatsAppScreen } from "./initialWhatsAppScreen/InitialWhatsAppScreen";
import { clearIntegration } from "../../../../redux/integration_V2.0/allIntegrations/actions/allIntegrations.actions";
import {
  setWhatsAppScreen,
  clearWhatsAppData,
  // setBackIconClicked,
  setWhatsAppInputData,
} from "../../../../redux/integration_V2.0/whatsApp/actions/whatsApp.actions";
import { FinalWhatsAppScreen } from "./finalWhatsAppScreen/FinalWhatsAppScreen";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";

interface WhatsAppProps {
  shown?: boolean;
  close?: any;
  integrationName?: string;
  integrationData?: any;
}

export const WhatsAppScreen = ({
  shown,
  close,
  integrationName,
  integrationData,
}: WhatsAppProps) => {
  const dispatch = useDispatch();

  const screenName = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.whatsAppReducer?.currentScreen
  );

  const selectedIntegrationWA = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.selectedIntegrationData
  );

  const userInputData = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.whatsAppReducer?.whatsAppInputData
  );

  const [whatsAppData, setWhatsAppData] = useState({});

  function handleShowFinalCreateScreenChange(value: object) {
    setWhatsAppData(value);
  }

  const handleCreateAccount = () => {
    dispatch(setWhatsAppScreen("step1"));
  };

  // const [isHovered, setIsHovered] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    // setIsHovered(true);
    if (ref.current) {
      ref.current.style.display = "none";
    }
  };

  const handleMouseLeave = () => {
    // setIsHovered(false);
    if (ref.current) {
      ref.current.style.display = "block";
    }
  };

  const handleCloseScreen = () => {
    dispatch(clearWhatsAppData());
    dispatch(clearIntegration());
    close();
  };

  useEffect(() => {
    if (userInputData && Object.keys(userInputData)?.length === 0) {
      if (
        selectedIntegrationWA?.integrationDetails &&
        Object.keys(selectedIntegrationWA?.integrationDetails)?.length > 0
      ) {
        dispatch(
          setWhatsAppInputData(selectedIntegrationWA?.integrationDetails)
        );
        dispatch(setWhatsAppScreen("step1"));
      } else {
        if (
          selectedIntegrationWA?.integrationDetails &&
          Object.keys(selectedIntegrationWA?.integrationDetails).length > 1
        ) {
          dispatch(
            setWhatsAppInputData(selectedIntegrationWA?.integrationDetails)
          );
          dispatch(setWhatsAppScreen("step1"));
        }
      }
    } else {
      dispatch(
        setWhatsAppInputData({
          ...userInputData,
          profilePicPath:
            selectedIntegrationWA?.integrationDetails?.profilePicPath,
        })
      );
    }
  }, [selectedIntegrationWA]);

  return (
    <Modal
      shown={shown}
      close={handleCloseScreen}
      extraClassStyleModalBackDrop={styles.integrationModalStyling}
      extraClassStyleModalContent={styles.integrationModalContentStyling}
    >
      <div className={styles.whatsAppWrapper}>
        <div className={styles.whatsAppHeaderDiv}>
          <div className={styles.whatsAppLeftHeaderDiv}>
            {/* <Icon
              img_src={whatsAppIntegration}
              extraClass={styles.headerIconStyling}
            /> */}
            <PravidIcons
              activeIcon={integrationData?.icon}
              extraClass={styles.headerIconStyling}
            />
            <div className={styles.headerTextStyling}>WhatsApp</div>
          </div>
          <div className={styles.whatsAppRightHeaderDiv}>
            {screenName === "step2" && (
              <div className={styles.backIconStyling}>
                <Icon
                  img_src={backIcon}
                  extraClass={styles.iconBackStyling}
                  onClick={() => {
                    dispatch(setWhatsAppScreen("step1"));
                    // dispatch(clearCreateIntegration());
                    dispatch(setWhatsAppInputData(whatsAppData));
                  }}
                />
              </div>
            )}

            <div className={styles.closeIconStyling}>
              <Icon
                img_src={closeIntegrationIcon}
                extraClass={styles.iconCloseStyling}
                onClick={handleCloseScreen}
              />
            </div>
          </div>
        </div>
        <div className={styles.subHeaderDiv}>
          You can connect or set up a new whatsapp business account to start
          sending message based nudges to your customers.
        </div>
        {screenName === "step1" ? (
          <InitialWhatsAppScreen />
        ) : screenName === "step2" ? (
          <FinalWhatsAppScreen
            closeScreenFunc={handleCloseScreen}
            onShowFinalCreateScreenData={handleShowFinalCreateScreenChange}
          />
        ) : (
          <div className={styles.contentDiv}>
            <div className={styles.subDiv}>
              <div className={styles.comingSoonDiv}>
                <div className={styles.comingSoonText} ref={ref}>
                  Coming Soon
                </div>
              </div>
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Button
                  text="Connect Existing Account"
                  extraClass={`${styles.buttonStyling} ${styles.inActiveBtnStyling}`}
                />
              </div>
            </div>
            <div className={styles.subDiv}>
              <Button
                text="Create New Account"
                extraClass={`${styles.buttonStyling} ${styles.activeBtnStyling}`}
                onClick={handleCreateAccount}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
