// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FJik0N71ThzsGBrfup6R{background-color:#fff;border:1px solid #e8e8e8;width:453.02px;height:202.13px;left:45.73px;top:.25px;border-radius:8px;padding:20px 20px}.FJik0N71ThzsGBrfup6R th{border:none}.FJik0N71ThzsGBrfup6R td{font-weight:400;font-size:16px;line-height:24px;color:#585757}.FJik0N71ThzsGBrfup6R .ZX0OwXZmnZkUSOrx6wdR{width:80px;margin-right:4px;text-align:left}.FJik0N71ThzsGBrfup6R .nahBvvYk8ndc2_4siFL5{width:70%;height:36px;max-height:30px;display:block;text-align:left;overflow:hidden;text-overflow:ellipsis}`, "",{"version":3,"sources":["webpack://./src/components/agentDesktop/moduleComponents/chatTextBox/slashComponent/SlashComponent.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,wBAAA,CACA,cAAA,CACA,eAAA,CACA,YAAA,CACA,SAAA,CACA,iBAAA,CACA,iBAAA,CAEA,yBACE,WAAA,CAEF,yBAGE,eAAA,CACA,cAAA,CACA,gBAAA,CAKA,aAAA,CAEF,4CACE,UAAA,CACA,gBAAA,CACA,eAAA,CAEF,4CACE,SAAA,CACA,WAAA,CACA,eAAA,CACA,aAAA,CAEA,eAAA,CAEA,eAAA,CACA,sBAAA","sourcesContent":[".wrapper {\n  background-color: white;\n  border: 1px solid #e8e8e8;\n  width: 453.02px;\n  height: 202.13px;\n  left: 45.73px;\n  top: 0.25px;\n  border-radius: 8px;\n  padding: 20px 20px;\n\n  th {\n    border: none;\n  }\n  td {\n    /* Paragraph / P2 - 16 */\n\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24px;\n\n    /* identical to box height, or 150% */\n\n    /* Light/Grayscale/200 */\n    color: #585757;\n  }\n  .c1 {\n    width: 80px;\n    margin-right: 4px;\n    text-align: left;\n  }\n  .c2 {\n    width: 70%;\n    height: 36px;\n    max-height: 30px;\n    display: block;\n\n    text-align: left;\n    // white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FJik0N71ThzsGBrfup6R`,
	"c1": `ZX0OwXZmnZkUSOrx6wdR`,
	"c2": `nahBvvYk8ndc2_4siFL5`
};
export default ___CSS_LOADER_EXPORT___;
