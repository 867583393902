import actionTypes from "../telephony.actionTypes";

export interface stateInterface {
  allTelephonySystem: { data: any; isLoading: boolean; error: any };
  deafultTelephonySystem: { data: any; isLoading: boolean; error: any };
  allSIPGateway: { data: any; isLoading: boolean; error: any };
  transactionDetail: object;
  promotionalDetail: object;
  telephonyDataDefault: { data: any; isLoading: boolean; error: any };
  telephonyDataClient: { data: any; isLoading: boolean; error: any };
  setTelephonyDataClient: { data: any; isLoading: boolean; error: any };
  selectedTelephonyTab: string;
  setSelectedTelephonySystem: object;
  defaultTelephonyData: { data: any; isLoading: boolean; error: any };
}

const initialState: stateInterface = {
  allTelephonySystem: { data: {}, isLoading: false, error: {} },
  deafultTelephonySystem: { data: [], isLoading: false, error: {} },
  allSIPGateway: { data: {}, isLoading: false, error: {} },
  transactionDetail: {},
  promotionalDetail: {},
  telephonyDataDefault: { data: {}, isLoading: false, error: {} },
  telephonyDataClient: { data: {}, isLoading: false, error: {} },
  setTelephonyDataClient: { data: {}, isLoading: false, error: {} },
  selectedTelephonyTab: "Transactional",
  defaultTelephonyData: { data: {}, isLoading: false, error: {} },
  setSelectedTelephonySystem: {},
};

export default function emailReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_ALL_TELEPHONY_SYSTEM_REQUEST: {
      return {
        ...state,
        allTelephonySystem: { data: {}, isLoading: true, error: {} },
      };
    }
    case actionTypes.GET_ALL_TELEPHONY_SYSTEM_SUCCESS: {
      return {
        ...state,
        allTelephonySystem: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.GET_ALL_TELEPHONY_SYSTEM_FAILURE: {
      return {
        ...state,
        allTelephonySystem: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.GET_DEFAULT_TELEPHONY_SYSTEM_REQUEST: {
      return {
        ...state,
        deafultTelephonySystem: { data: [], isLoading: true, error: {} },
      };
    }
    case actionTypes.GET_DEFAULT_TELEPHONY_SYSTEM_SUCCESS: {
      return {
        ...state,
        deafultTelephonySystem: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.GET_DEFAULT_TELEPHONY_SYSTEM_FAILURE: {
      return {
        ...state,
        deafultTelephonySystem: {
          data: [],
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.GET_SIP_GATEWAY_REQUEST: {
      return {
        ...state,
        allSIPGateway: { data: {}, isLoading: true, error: {} },
      };
    }
    case actionTypes.GET_SIP_GATEWAY_SUCCESS: {
      return {
        ...state,
        allSIPGateway: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.GET_SIP_GATEWAY_FAILURE: {
      return {
        ...state,
        allSIPGateway: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.ADD_TRANSACTION_DETAIL: {
      return {
        ...state,
        transactionDetail: action.payload,
      };
    }
    case actionTypes.ADD_PROMOTIONAL_DETAIL: {
      return {
        ...state,
        promotionalDetail: action.payload,
      };
    }
    case actionTypes.GET_CLIENT_TELEPHONY_DATA_REQUEST: {
      return {
        ...state,
        telephonyDataClient: { data: {}, isLoading: true, error: {} },
      };
    }
    case actionTypes.GET_CLIENT_TELEPHONY_DATA_SUCCESS: {
      return {
        ...state,
        telephonyDataClient: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.GET_CLIENT_TELEPHONY_DATA_FAILURE: {
      return {
        ...state,
        telephonyDataClient: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.GET_DEFAULT_TELEPHONY_DATA_REQUEST: {
      return {
        ...state,
        telephonyDataDefault: { data: {}, isLoading: true, error: {} },
      };
    }
    case actionTypes.GET_DEFAULT_TELEPHONY_DATA_SUCCESS: {
      return {
        ...state,
        telephonyDataDefault: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.GET_DEFAULT_TELEPHONY_DATA_FAILURE: {
      return {
        ...state,
        telephonyDataDefault: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.SET_TELEPHONY_DATA_REQUEST: {
      return {
        ...state,
        setTelephonyDataClient: { data: {}, isLoading: true, error: {} },
      };
    }
    case actionTypes.SET_TELEPHONY_DATA_SUCCESS: {
      return {
        ...state,
        setTelephonyDataClient: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.SET_TELEPHONY_DATA_FAILURE: {
      return {
        ...state,
        setTelephonyDataClient: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.CLEAR_SET_TELEPHONY_DATA: {
      return {
        ...state,
        setTelephonyDataClient: {
          data: {},
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.SET_DEFAULT_TELEPHONY_DATA_FAILURE: {
      return {
        ...state,
        defaultTelephonyData: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.SET_DEFAULT_TELEPHONY_DATA_REQUEST: {
      return {
        ...state,
        defaultTelephonyData: { data: {}, isLoading: true, error: {} },
      };
    }
    case actionTypes.SET_DEFAULT_TELEPHONY_DATA_SUCCESS: {
      return {
        ...state,
        defaultTelephonyData: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.SET_SELECTED_TELEPHONY_TAB: {
      return {
        ...state,
        selectedTelephonyTab: action.payload,
      };
    }
    case actionTypes.SET_SELECTED_TELEPHONY_DATA: {
      return {
        ...state,
        setSelectedTelephonySystem: action.payload,
      };
    }
    case actionTypes.CLEAR_ALL_TELEPHONY_DATA: {
      return {
        ...state,
        setSelectedTelephonySystem: {},
        selectedTelephonyTab: "Transactional",
        telephonyDataDefault: { data: {}, isLoading: false, error: {} },
        telephonyDataClient: { data: {}, isLoading: false, error: {} },
      };
    }
    default:
      return state;
  }
}
