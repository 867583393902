import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { PRICING_PLAN_API, config } from "../../../../../services/ApiRoutes";
import actionTypes from "../actionTypes";

export function* getMyPlanDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        `${PRICING_PLAN_API.MY_PLAN_DETAILS}`,
        { accountId: action.payload.accountId }
      );
      yield put({
        type: actionTypes.MY_PLAN_DATA_API_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error: any) {
    console.error(error);
    toast.error(error.response?.data?.error?.message);
    yield put({
      type: actionTypes.MY_PLAN_DATA_API_FAILURE,
      payload: error.response?.data?.error?.message,
    });
  }
}
