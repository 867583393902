import { Reducer } from "redux";
import actionType from "../pricingPlan.actionTypes";

export interface pravidConfiguration {
  dataStorage: {
    storage: number;
    availableLimit: number;
  };
  limitRoles: { id: string; roles: string; limit: number }[];
  campaignStrategy: string;
  campaignAnalytics: string;
  customization: string;
  crmIntegration: boolean;
  whatsappIntegration: boolean;
}
export interface maiaConfiguration {
  conversationCredits: {
    callingMinutes: number;
    sms: number;
    email: number;
  };
  dataStorage: {
    storage: number;
    availableLimit: number;
  };
  maximumConcurrentCall: number;
  uniqueDIDs: number;
}
export interface supportConfiguration {
  communitySupport: number;
  dedicatedCSM: boolean;
  premiumSupport24_7: boolean;
}
export interface feature {
  description: string[];
  _id: string;
  icon: string;
  heading: string;
}
export interface supportConfiguration {
  communitySupport: number;
  dedicatedCSM: boolean;
  premiumSupport24_7: boolean;
}
export interface IPlanData {
  pravidConfiguration: pravidConfiguration; //
  maiaConfiguration: maiaConfiguration; //
  supportConfiguration: supportConfiguration; //
  comparisonFeature: string[]; //
  addons: [];
  id: string;
  planName: string;
  price: string;
  currency: string;
  duration: string;
  coreFeature: string;
  features: string[]; //
  position: number;
  isAddonActive: boolean;
  tag: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  reportAutomation: boolean;
}
export interface pricingPlanData {
  planData: IPlanData[];
  taxData: {
    _id: string;
    currency: string;
    createdAt: string;
    updatedAt: string;
    taxValue: {
      SGST: string;
      CGST: string;
    };
  };
  planDuration: string[];
}
export interface stateInterface {
  pricingPlanDetails: {
    data: pricingPlanData | undefined;
    error: string;
    isLoading: boolean;
  };
  updatePricingPlanDetails: { data: any; error: string; isLoading: boolean };
  selectedPlan: IPlanData | undefined;
  selectedRoute: string;
  customPlanRequest: { data: any; isLoading: boolean; error: any };
  couponPlanDetails: { data: any; isLoading: boolean; error: any };
}

const initialState: stateInterface = {
  pricingPlanDetails: { data: undefined, error: "", isLoading: false },
  updatePricingPlanDetails: { data: [], error: "", isLoading: false },
  selectedPlan: undefined,
  selectedRoute: "",
  customPlanRequest: { data: {}, isLoading: false, error: {} },
  couponPlanDetails: { data: {}, isLoading: false, error: {} },
};

const pricingPlanReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionType.GET_PRICING_DATA_API_REQUEST: {
      return {
        ...state,
        pricingPlanDetails: {
          data: undefined,
          error: "",
          isLoading: true,
        },
      };
    }
    case actionType.GET_PRICING_DATA_API_SUCCESS: {
      return {
        ...state,
        pricingPlanDetails: {
          data: action.payload,
          error: "",
          isLoading: false,
        },
      };
    }
    case actionType.GET_PRICING_DATA_API_FAILURE: {
      return {
        ...state,
        pricingPlanDetails: {
          data: undefined,
          error: action.payload,
          isLoading: false,
        },
      };
    }
    case actionType.UPDATE_PRICING_DATA_API_REQUEST: {
      return {
        ...state,
        updatePricingPlanDetails: {
          data: [],
          error: "",
          isLoading: true,
        },
      };
    }
    case actionType.UPDATE_PRICING_DATA_API_SUCCESS: {
      return {
        ...state,
        updatePricingPlanDetails: {
          data: action.payload,
          error: "",
          isLoading: false,
        },
      };
    }
    case actionType.UPDATE_PRICING_DATA_API_FAILURE: {
      return {
        ...state,
        updatePricingPlanDetails: {
          data: [],
          error: action.payload,
          isLoading: false,
        },
      };
    }
    case actionType.SET_SELECTED_PLAN: {
      return { ...state, selectedPlan: action.payload };
    }
    case actionType.SET_ADDED_TO_CARD_ROUTER: {
      return { ...state, selectedRoute: action.payload };
    }
    case actionType.SET_SELECTED_PAID_PLAN: {
      return { ...state, selectedPaidPlan: action.payload };
    }
    case actionType.CUSTOM_PLAN_API_REQUEST: {
      return {
        ...state,
        customPlanRequest: { data: {}, isLoading: true, error: {} },
      };
    }
    case actionType.CUSTOM_PLAN_API_SUCCESS: {
      return {
        ...state,
        customPlanRequest: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionType.CUSTOM_PLAN_API_FAILURE: {
      return {
        ...state,
        customPlanRequest: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionType.COUPAN_PLAN_API_REQUEST: {
      return {
        ...state,
        couponPlanDetails: { data: {}, isLoading: true, error: {} },
      };
    }
    case actionType.COUPAN_PLAN_API_SUCCESS: {
      return {
        ...state,
        couponPlanDetails: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionType.COUPAN_PLAN_API_FAILURE: {
      return {
        ...state,
        couponPlanDetails: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default pricingPlanReducer;
