import React, { useState, SetStateAction, useEffect, useRef } from "react";
import styles from "./ReverseMultiSelect.module.scss";
import { dropDownArrowIcon } from "../../../theme/assets/svg";
import { setSelectedAgent } from "../../../redux/campaignTestingInfo/actions";
import { infoLightIcon, infoLightWhite } from "../../../theme/assets/svg";
import { useDrag } from "react-dnd";
interface Obj {
  optionsDefaultSelected: any;
  filterOptions: any;
  setDataOut: any;
  title: string | React.ReactNode;
  disabled: boolean;
  tiptext: string;
}
function showData(data: string[]) {
  if (data.length <= 3) return data.join(", ");
  else
    return (
      data[0] +
      ", " +
      data[1] +
      ", " +
      data[2] +
      "  +" +
      (data.length - 3) +
      " more"
    );
}
function ReverseMultiSelect({
  optionsDefaultSelected,
  filterOptions,
  setDataOut,
  title,
  disabled,
  tiptext,
}: Obj) {
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [show, setShow] = useState(!false);
  const refD = useRef() as React.MutableRefObject<HTMLDivElement>;

  // for drag

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: `${title}`,
      item: { data: selectedData },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [selectedData]
  );

  //

  const getChecked = (data: string, index: number) => {
    if (disabled) return;
    if (selectedData?.includes(data)) {
      const tempArr = [...selectedData];
      const indexr = tempArr.indexOf(data);
      if (indexr > -1) {
        tempArr.splice(indexr, 1);
      }
      setSelectedData([...tempArr]);

      setDataOut([...tempArr]);
    } else {
      setSelectedData([...selectedData, data]);
      setDataOut([...selectedData, data]);
    }
  };

  useEffect(() => {
    let sidebarToggleListener: (e: MouseEvent) => void;
    if (show) {
      sidebarToggleListener = (event: MouseEvent) => {
        if (
          refD.current &&
          !refD.current.contains(event.target as Node | null)
        ) {
          setShow(false);
        }
      };
      document.addEventListener("click", sidebarToggleListener);
    }
    return () => document.removeEventListener("click", sidebarToggleListener);
  }, [refD, show]);

  useEffect(() => {
    if (optionsDefaultSelected !== undefined) {
      setSelectedData(optionsDefaultSelected);
    } else {
      setSelectedData([]);
    }
  }, [optionsDefaultSelected]);

  return (
    <>
      <div ref={refD} className={styles.filterwrappers}>
        {show ? (
          <div className={styles.operall_wrapper_pop}>
            <div className={styles.popup_wrapper}>
              {filterOptions?.map((each: string, i: number) => {
                return (
                  <>
                    <div className={styles.each_div}>
                      <p
                        className={styles.each_para}
                        onClick={() => getChecked(each, i)}
                      >
                        {each}
                      </p>
                      <input
                        type="checkbox"
                        checked={selectedData?.includes(each) ? true : false}
                        onChange={() => getChecked(each, i)}
                        readOnly
                      />
                    </div>
                  </>
                );
              })}
            </div>
            <div className={styles.dispostioncoutn_div}>
              <p> {selectedData.length} Disposition Selected</p>
            </div>
          </div>
        ) : null}

        <div
          onClick={() => setShow(!show)}
          className={`
        ${styles.title_wrapper} 
         ${selectedData.length > 0 && show ? styles.colordeepblue : ""}
        ${selectedData.length == 0 && show ? styles.lightdeepblue : ""}
        `}
          ref={selectedData.length > 0 ? drag : null}
          style={{
            opacity: isDragging ? 0.5 : 1,
            cursor: "move",
          }}
        >
          <p> {title}</p>
          <div className={styles.tipimage}>
            <img
              src={
                selectedData.length > 0 && show ? infoLightWhite : infoLightIcon
              }
              alt=""
            />
            {!(selectedData.length > 0) && (
              <div className={styles.tipdiv}>
                <p>{tiptext}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(ReverseMultiSelect);
{
  /* <div style={{ position: "absolute", top: "50%", width: "300px" }}>
                <ReverseMultiSelect
                  optionsDefaultSelected={["Promise_to_pay"]}
                  filterOptions={[
                    "Promise_to_pay",
                    "Agree_to_pays",
                    "Willing_to_pay",
                    "Agree_to_pay",
                    "Front_row_pay",
                    "Kok_love_pay",
                  ]}
                  setDataOut={(x: any) => console.log(x, "0000")}
                  title={"Expected Outcome"}
                  disabled={false}
                />
              </div> */
}
