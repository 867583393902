import React, { useEffect, useRef, useState } from "react";

import styles from "./NewRightSidebarpop.module.scss";
// import {
//   arrow,
//   blueFilter,
//   blueCalender,
// } from "../../../theme/assets/svg/rightSideIcon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";

import NewDatePicker from "../../../generic/newDataPicker/NewDatePicker";
// import DropdownFilter from "../rightSidebar/dropdownFilter/DropdownFilter";
// import DownloadSection from "../rightSidebar/downloadSection/DownloadSection";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";
interface props {
  selectedIcon: string | null;
  // show: boolean;
  // setShow: Dispatch<SetStateAction<boolean>>;
}
import {
  setSelectedIconAgent,
  setShowRightSideBarAgent,
} from "../../../../../redux/agentDesktop/filterReducer/rightSidePopup/action/rightSidePopup.action";

// import {
//   getAllFiltersOptionsForAccount,
//   setSelectedFilterOptions,
//   setSelectedFilterType,
//   setShowRightSideBar,
// } from "../../../redux/filters/actions";
// import KPIWrapper from "../kpiWrapper/KPIWapper";
// import { useCurrentPageTab } from "../../../hooks";
// import { getSchemaOfClientRequest } from "../../../redux/onboarding/login/actions";
import { useNavigate } from "react-router-dom";
// import {
//   everyCampaingIsInOptions,
//   getOptionsRemaining,
// } from "../../../utils/validators/validators";

const NewRightSidebarpop = React.forwardRef((props: any, ref: any) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const refP = useRef<any>(null);
  const refId = useRef<any>();

  const show = useSelector(
    (state: RootState) =>
      state?.agentDesktopReducer.rootFilterReducer.rightSidePopupReducer
        .showRightSideBar
  );

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const calender = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.rootFilterReducer.calenderFilterReducer
  );
  const clientName = useSelector(
    (store: RootState) => store.filterReducers.selectedFilterOptions.clientName
  );
  const selectedFilterOptions = useSelector(
    (store: RootState) => store.filterReducers.selectedFilterOptions
  );
  function process() {
    // history("/dashboard");
  }
  //user based filter
  //   useEffect(() => {
  //     dispatch(
  //       getAllFiltersOptionsForAccount(
  //         {
  //           username: accountName?.name,
  //           type: accountDetails?.type,
  //           role: userDetail?.role,
  //           start_date: calender.startDate,
  //           end_date: calender.endDate,
  //         },
  //         token
  //       )
  //     );
  // if(accountDetails?.type==="Internal"&&userDetail?.role=="Campaign Analyst" &&clientName&&clientName?.length>0){
  //   dispatch(
  //     setSelectedFilterOptions({
  //       type: 'clientName',
  //       options: [{id:"62623fda3b3b877936e3bd16",
  //       name:"Cred Testing",
  //       type:"Internal"}],
  //     })
  //   )
  // }
  // accountDetails?.type === "Internal" &&
  //   userRole.toString().toLowerCase() === "campaign analyst" &&
  //   dispatch(
  //     setSelectedFilterOptions({
  //       type: "clientName",
  //       options: [
  //         {
  //           id: "62623fda3b3b877936e3bd16",
  //           name: "Cred Testing",
  //           type: "Internal",
  //         },
  //       ],
  //     })
  //   );
  //   }, []);
  //   useEffect(() => {
  //     if (
  //       accountDetails?.type === "Internal" &&
  //       userDetail?.role == "Campaign Analyst" &&
  //       clientName &&
  //       clientName?.length > 0
  //     ) {
  //       dispatch(
  //         getAllFiltersOptionsForAccount(
  //           {
  //             username: clientName[0]?.name,
  //             type: clientName[0]?.type,
  //             role: clientName[0]?.role,
  //             start_date: calender.startDate,
  //             end_date: calender.endDate,
  //           },
  //           token
  //         )
  //       );
  //     } else {
  //       dispatch(
  //         getAllFiltersOptionsForAccount(
  //           {
  //             username: accountName?.name,
  //             type: accountDetails?.type,
  //             role: userDetail?.role,
  //             start_date: calender.startDate,
  //             end_date: calender.endDate,
  //           },
  //           token
  //         )
  //       );
  //       // dispatch({type:"CLEAR_CAMPAING_FILTER"})
  //     }
  //   }, [calender]);

  //client based
  //   useEffect(() => {
  //     if (
  //       accountDetails?.type === "Internal" &&
  //       userDetail?.role == "Campaign Analyst" &&
  //       clientName &&
  //       clientName?.length > 0
  //     ) {
  //       if (clientName[0]?.id !== refId.current?.id) {
  //         dispatch(
  //           getAllFiltersOptionsForAccount(
  //             {
  //               username: clientName[0]?.name,
  //               type: clientName[0]?.type,
  //               role: clientName[0]?.role,
  //               start_date: calender.startDate,
  //               end_date: calender.endDate,
  //             },
  //             token
  //           )
  //         );
  //         // dispatch(
  //         //   getSchemaOfClientRequest({
  //         //     id: clientName[0]?.id,
  //         //     originalId: userDetail?._id,
  //         //     fun: process,
  //         //   })
  //         // );
  //         refId.current = { id: clientName[0]?.id };
  //       }
  //     }
  //   }, [clientName]);

  useEffect(() => {
    let sidebarToggleListener: (e: MouseEvent) => void;
    if (show) {
      sidebarToggleListener = (event: MouseEvent) => {
        if (
          ref.current &&
          refP.current &&
          !refP.current.contains(event.target) &&
          !ref.current.contains(event.target)
        ) {
          dispatch(setShowRightSideBarAgent(false));
          props.setSelectedIcon("");
        }
      };
      document.addEventListener("click", sidebarToggleListener);
    }
    return () => document.removeEventListener("click", sidebarToggleListener);
  }, [show]);

  //   useEffect(() => {
  //     if (
  //       selectedFilterOptions?.campaignName &&
  //       filterOptions?.data?.filters?.campaignName &&
  //       !everyCampaingIsInOptions(
  //         filterOptions?.data?.filters?.campaignName,
  //         selectedFilterOptions?.campaignName
  //       )
  //     ) {
  //       dispatch(
  //         setSelectedFilterOptions({
  //           type: "campaignName",
  //           options: getOptionsRemaining(
  //             filterOptions?.data?.filters?.campaignName,
  //             selectedFilterOptions?.campaignName
  //           ),
  //         })
  //       );
  //     }
  //   }, [filterOptions]);

  //console.log(props.selectedIcon,"SELCTED ICOn")
  return (
    <>
      <div
        ref={refP}
        className={`${styles.rightsidepopwrapper} ${show ? styles.show : ""}`}
      >
        {/* {props?.selectedIcon === "Filter" && (
          <>
            <DropdownFilter selectedIcon={props.selectedIcon} />
          </>
        )} */}
        {props?.selectedIcon === "Calendar" && (
          <>
            <NewDatePicker
              setSelectedIcon={props.setSelectedIcon}
              selectedIcon={props.selectedIcon}
            />
          </>
        )}
        {/* {props?.selectedIcon === "Download" && (
          <>
            <DownloadSection
              selectedIcon={props?.selectedIcon}
              setSelectedIcon={props.setSelectedIcon}
            />
          </>
        )} */}
        {/* {props?.selectedIcon === "KPI customisation" && (
          <>
            <div style={{ textAlign: "center" }}>
              <KPIWrapper selectedIcon={props?.selectedIcon} />
            </div>
          </>
        )}
        {props?.selectedIcon === "Shuffle Column" && (
          <>
            <div style={{ textAlign: "center" }}>{props?.selectedIcon}</div>
          </>
        )} */}
        <div
          className={styles.closeicon}
          onClick={() => {
            dispatch(setShowRightSideBarAgent(false));
            dispatch(setSelectedIconAgent(""));
            props.setSelectedIcon("");
          }}
        >
          {/* <img src={arrow} alt="" /> */}
          <PravidIcons activeIcon={"arrow"} />
        </div>
      </div>
    </>
  );
});

NewRightSidebarpop.displayName = "NewRightSidebarpop";
export default React.memo(NewRightSidebarpop);
