import { takeLatest } from "redux-saga/effects";
import actionTypes from "../actionTypes/campaign.actionTypes";
import {
  getSalesOnboardingAgentConfigWorker,
  getDebtCollectionAgentConfigWorker,
  setClientNameWorker,
  getSampleCsvdownloadWorker,
  getCheckTechApiHealthWorker,
  getPortTelephonyWorker,
  getSampleCsvPaymentdownloadWorker
} from "./campaign.saga";

export default function* campaignWatcher(): any {
  yield takeLatest(
    actionTypes.CAMPAIGN_SALES_ONBOARDING_AGENT_CONFIG_API_REQUEST,
    getSalesOnboardingAgentConfigWorker
  );
  yield takeLatest(
    actionTypes.CAMPAIGN_DEBT_AGENT_CONFIG_API_REQUEST,
    getDebtCollectionAgentConfigWorker
  );
  yield takeLatest(actionTypes.SET_CLIENT_NAME, setClientNameWorker);
  yield takeLatest(
    actionTypes.SAMPLE_CSV_DOWNLOAD_API_REQUEST,
    getSampleCsvdownloadWorker
  );
  yield takeLatest(
    actionTypes.CHECK_TECH_HEALTH_API_REQUEST,
    getCheckTechApiHealthWorker
  );
  yield takeLatest(
    actionTypes.GET_PORT_API_REQUEST,
    getPortTelephonyWorker
  );
  yield takeLatest(
    actionTypes.SAMPLE_CSV_PAYMENT_DOWNLOAD_API_REQUEST,
    getSampleCsvPaymentdownloadWorker
  );
}
