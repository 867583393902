import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RadioButton from "../../../../generic/radioButton/RadioButton";
import styles from "./ChoosePlans.module.scss";
import { RootState } from "../../../../../redux";
import { toast } from "react-toastify";
export interface IPlan {
  name: string;
  id: string;
  price: string;
  duration: string;
}
interface IProps {
  currentPlanId: string;
  plans: IPlan[];
  onChangePlan: (id: string, plan: IPlan) => void;
  onClickChoosePlan: (id: string, plan: IPlan) => void;
  onClickComparePlan: () => void;
}
export default function ChoosePlans(props: IProps) {
  const myPlan: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );
  const convertToRupees = (number: string) => {
    const rupees = parseInt(number).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    const formattedRupees = rupees.replace(/\.\d+/, ""); // Remove decimal part if present
    return formattedRupees.replace("₹", "");
  };
  const handleAddToCart = (id: string, plan: any) => {
    if (myPlan?.planName === plan?.name) {
      toast.error("You can not purchase same plan");
    } else {
      props.onClickChoosePlan(id, plan);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <span className={styles.plans}>Plans</span>
        <span
          className={styles.comparePlans}
          onClick={() => {
            props.onClickComparePlan();
          }}
        >
          Compare Plans
        </span>
      </div>
      {props.plans?.map((plan) => {
        return (
          <div key={plan.id} className={styles.eachPlan}>
            <div className={styles.left}>
              <span className={styles.radioButton}>
                <RadioButton
                  isSelected={plan.id === props.currentPlanId}
                  onClick={() => {
                    props.onChangePlan(plan.id, plan);
                  }}
                />
              </span>
              <div className={styles.details}>
                <div className={styles.name}>{plan.name}</div>
                <div className={styles.fee}>
                  {plan.price !== "Free"
                    ? convertToRupees(plan.price)
                    : plan.price}
                  {plan.price !== "Free" && `/30 days`}
                </div>
              </div>
            </div>
            <div className={styles.right}>
              {props.currentPlanId === plan.id && (
                <div
                  className={styles.button}
                  onClick={() => {
                    handleAddToCart(plan.id, plan);
                  }}
                >
                  Add to cart
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
