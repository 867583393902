// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O3_JVPtd_bCwicV8H1An{width:150px !important;position:absolute;top:-14%;left:40%}.jjmKgaacMleyysSUw9uO{width:150px !important;position:absolute;top:-14% !important;left:40% !important;border-radius:50% !important}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/signUpFlow/experienceNow/ExperienceNow.module.scss"],"names":[],"mappings":"AAAA,sBACE,sBAAA,CACA,iBAAA,CACA,QAAA,CACA,QAAA,CAGF,sBACE,sBAAA,CACA,iBAAA,CACA,mBAAA,CACA,mBAAA,CACA,4BAAA","sourcesContent":[".iconStyling {\n  width: 150px !important;\n  position: absolute;\n  top: -14%;\n  left: 40%;\n}\n\n.iconStylingRadius {\n  width: 150px !important;\n  position: absolute;\n  top: -14% !important;\n  left: 40% !important;\n  border-radius: 50% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconStyling": `O3_JVPtd_bCwicV8H1An`,
	"iconStylingRadius": `jjmKgaacMleyysSUw9uO`
};
export default ___CSS_LOADER_EXPORT___;
