import React, { Children, ReactNode, useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./Channel.module.scss";
import {
  arrow,
  downArrow,
  info,
  whatsapp,
  whatsappblue,
  callsx,
  callsblue,
  message,
  messageblue,
  emails,
  emailxblue,
  quest,
  greyL,
  blueL,
  clock,
} from "../../../theme/assets/svg";
import lineDotIcon from "../../../theme/assets/svg/campaign/lineDotIcon.svg";
import {
  blackDownArrow,
  scriptConfigIcon,
  tickIconGreen,
  blackUpArrow,
  dropdownIcon,
} from "../../../theme/assets/svg/campaign_v3/index";

import {
  convertTo12Hour,
  convertTo24Hour,
  genenerateObjects,
  validateInteger,
} from "../../../utils/validators/validators";
// import ToolTip from "../../generic/toolTip/ToolTip";
import {
  humanHandOffRight,
  humanHandOffHighlightRight,
} from "../../../theme/assets/svg/strategySvg";
import { InputDays } from "../inputDays/InputDays";
import Icon from "../icon/Icon";
import {
  convertToMinutes,
  convertToTimeFormat,
} from "../../../utils/convertTimeForDBC";
import Item from "antd/es/list/Item";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#333",
    borderRadius: "4px",
    color: "#fff",
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: 400,
    overflowWrap: "break-word",
  },
  customArrow: {
    color: "#333",
    fontSize: 9,
  },
}));

interface TitleProps {
  open: boolean;
  warningIcon: boolean;
  children: ReactNode;
  title: string;
  onClick: any;
  actualKey: string | number;
  selectedKey: string | number;
  count?: number;
  hideArrowIcon?: boolean;
  infoIcon?: string;
  onClickExtraFunc?: any;
}
const channelList = [
  {
    channel: "Whatsapp",
    firstIcon: whatsapp,
    secondIcon: whatsappblue,
    value: "whatsApp",
  },
  {
    channel: "Call",
    firstIcon: callsx,
    secondIcon: callsblue,
    value: "call",
  },
  {
    channel: "Message",
    firstIcon: message,
    secondIcon: messageblue,
    value: "sms",
  },
  {
    channel: "Email",
    firstIcon: emails,
    secondIcon: emailxblue,
    value: "mail",
  },
  {
    channel: "Human",
    firstIcon: humanHandOffRight,
    secondIcon: humanHandOffHighlightRight,
    value: "human",
  },
];
// const CampaignNameLabel = ({ title, tip }: any) => (
//   <div className={styles.LabelDivStyling}>
//     <div className={styles.LabelStyling}>{title}</div>
//     <ToolTip about={tip} customIcon={true} />
//   </div>
// );

const variationList = [
  "v1",
  "v2",
  "v3",
  "v4",
  "v5",
  "v6",
  "v7",
  "v8",
  "v9",
  "v10",
];
const agentDesktopList = ["On", "Off"];

interface CallBackItem {
  name: string;
  count: number;
  isSelected: boolean;
}

const callBackList: CallBackItem[] = [
  { name: "Due Date", count: 0, isSelected: false },
  { name: "Current Date", count: 0, isSelected: false },
];

const promiseToPayList: CallBackItem[] = [
  { name: "Due Date", count: 0, isSelected: false },
  { name: "Current Date", count: 0, isSelected: false },
];
export function Title({
  open,
  warningIcon,
  children,
  title,
  onClick,
  actualKey,
  selectedKey,
  hideArrowIcon,
  infoIcon,
  onClickExtraFunc,
}: TitleProps) {
  const classes = useStyles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.title_div}>
        <div
          className={`${styles.title_left_div} ${hideArrowIcon
            ? warningIcon
              ? styles.script_config
              : styles.script_config_green
            : ""
            }`}
          onClick={() => {
            if (actualKey === "Script Configuration" && onClickExtraFunc) {
              onClickExtraFunc();
            }
          }}
        >
          {hideArrowIcon ? (
            warningIcon ? (
              <img className={styles.infoicon} src={scriptConfigIcon} alt="" />
            ) : (
              <img className={styles.infoicon} src={tickIconGreen} alt="" />
            )
          ) : null}
          <p
            onClick={() => {
              selectedKey != actualKey ? onClick(actualKey) : onClick("");
              if (onClickExtraFunc) {
                onClickExtraFunc();
              }
            }}
            className={`${styles.noramltilte}  ${hideArrowIcon
              ? warningIcon
                ? styles.script_config_text
                : styles.script_config_text_green
              : ""
              } ${open ? styles.blue : ""}`}
          >
            {" "}
            {title} &nbsp;
          </p>{" "}
          {infoIcon ? (
            <Tooltip
              title={
                "Your customer data contained 4 Languages which are not a part of your language plan for this channel. Please replace it with an available language to proceed further."
              }
              placement={"top-end"}
              classes={{
                arrow: classes.customArrow,
                tooltip: classes.customTooltip,
              }}
              arrow={true}
            >
              <img className={styles.questIcon} src={infoIcon} alt="" />
            </Tooltip>
          ) : null}
          {!hideArrowIcon && warningIcon ? (
            <img className={styles.infoicon} src={info} alt="" />
          ) : null}
        </div>

        {!hideArrowIcon && (
          <img
            className={styles.downArrowIcon}
            src={!open ? blackDownArrow : blackUpArrow}
            alt=">"
            onClick={() => {
              selectedKey != actualKey ? onClick(actualKey) : onClick("");
            }}
          />
        )}
      </div>
      {open ? children : null}
    </div>
  );
}
export function Channel(props: any) {
  const [active, setActive] = useState<string>("");
  const myPlanData: any = useSelector(
    (store: any) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );
  const changeChannel = (eachChannel: string) => {
    setActive(eachChannel);
  };
  useEffect(() => {
    props.disp({ channel: active });
  }, [active]);
  useEffect(() => {
    setActive(props.defaultValue);
  }, []);
  return (
    <div className={`${styles.wrapperc} ${myPlanData?.isStrategyToShow === true ? "" : "disablePointerEventWithoutOpacity"}`}>
      {channelList.map((each) => {
        return (
          <div
            key={each.channel}
            className={`${styles.each_div} ${active == each.value ? styles.backgroundlightblue : ""
              }`}
            onClick={() => changeChannel(each.value)}
          >
            <img
              src={active != each.value ? each.firstIcon : each.secondIcon}
              alt=""
            />
            <p
              className={`${styles.each_para} ${active == each.value ? styles.linexss : ""
                }`}
            >
              {each.channel}
            </p>
          </div>
        );
      })}
    </div>
  );
}
export function Variation(props: any) {
  const [active, setActive] = useState<string>("");
  const changeChannel = (variation: string) => {
    setActive(variation);
  };
  useEffect(() => {
    props.disp({ variation: active });
  }, [active]);
  useEffect(() => {
    setActive(props.defaultValue);
  }, []);
  return (
    <div className={styles.wrapperc}>
      {variationList.map((each) => {
        return (
          <div
            className={`${styles.each_div} ${each == active ? styles.each_div_blue : ""
              } `}
          >
            <input
              name="variation"
              value=""
              type="radio"
              checked={active === each}
              onChange={() => changeChannel(each)}
            />
            <p className={styles.each_para}>{each}</p>
          </div>
        );
      })}
    </div>
  );
}
export function AgentDesktop(props: any) {
  const [active, setActive] = useState<string>("");
  const changeChannel = (variation: string) => {
    setActive(variation);
  };
  useEffect(() => {
    props.disp({ agentDesktop: active === "On" ? true : false });
  }, [active]);
  useEffect(() => {
    setActive(props.defaultValue === true ? "On" : "Off");
  }, []);
  return (
    <div className={styles.wrapperc}>
      {agentDesktopList.map((each) => {
        return (
          <div
            className={`${styles.each_div} ${each == active ? styles.each_div_blue : ""
              } `}
          >
            <input
              name="agentDesktop"
              value=""
              type="radio"
              checked={active === each}
              onChange={() => changeChannel(each)}
            />
            <p className={styles.each_para}>{each}</p>
          </div>
        );
      })}
    </div>
  );
}
export function CommunicationWays(props: any) {
  const [change, Changex] = useState<string>("");
  function handleChange(event: any) {
    if (props.disp) props.disp({ communicationWays: event.target.value });
    Changex(event.target.value);
  }
  const handleClick: React.MouseEventHandler<HTMLDivElement> = function (
    event: React.MouseEvent<HTMLDivElement>
  ) {
    const dataId = event.currentTarget.getAttribute("data-id");
    if (dataId == change) {
      if (props.disp) props.disp({ communicationWays: "" });
      Changex("");
    } else {
      if (dataId) {
        if (props.disp) props.disp({ communicationWays: dataId });
        Changex(dataId);
      }
    }
  };
  useEffect(() => {
    if (props.defaultValue && props.disp) {
      props.disp({ communicationWays: props.defaultValue });
      Changex(props.defaultValue);
    }
  }, []);
  return (
    <>
      <div className={styles.wrapper_commm}>
        <div
          className={`${styles.each_div} ${change == "One Way" ? styles.each_div_blue : ""
            } `}
          onClick={handleClick}
          data-id="One Way"
        >
          <input
            name="way"
            value="One Way"
            type="radio"
            checked={change == "One Way"}
            onChange={handleChange}
          />
          <p className={styles.each_para}>One Way</p>
        </div>
        <div
          className={`${styles.each_div} ${change == "Two Way" ? styles.each_div_blue : ""
            } `}
          onClick={handleClick}
          data-id="Two Way"
        >
          <input
            value="Two Way"
            name="way"
            type="radio"
            checked={change == "Two Way"}
            onChange={handleChange}
          />
          <p className={styles.each_para}>Two Way</p>
        </div>
      </div>
    </>
  );
}

const acceptedOutcome = ["Agree To Pay", "Paid", "Acceptable Promise to Pay"];
const unacceptedOutcome = [
  "Refuse To Pay",
  "Promise to Pay",
  "Future Promise To Pay",
  "Payment Delay",
  "Customer Hangup",
];
export function Subcondition({
  open,
  warningIcon,
  children,
  title,
  onClick,
  actualKey,
  selectedKey,
  count,
}: TitleProps) {
  return (
    <div className={styles.wrappergfg}>
      <div className={styles.title_div}>
        <div className={styles.title_left_div}>
          <img
            className={styles.lefticon}
            src={open ? downArrow : arrow}
            alt=">"
            onClick={() => {
              selectedKey != actualKey ? onClick(actualKey) : onClick("");
            }}
          />
          <p
            onClick={() => {
              selectedKey != actualKey ? onClick(actualKey) : onClick("");
            }}
            className={`${styles.noramltilte} ${open ? styles.blue : ""}`}
          >
            {title}
          </p>
        </div>
        <div className={!open ? styles.colorGreybg : styles.bluecolorbg}>
          <img src={!open ? greyL : blueL} alt="" />
          <p className={!open ? styles.colorGrey : styles.bluecolor}>{count}</p>
        </div>
      </div>
      {open ? children : null}
    </div>
  );
}

export function MultiSelect(props: any) {
  const classes = useStyles();
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const myPlanData: any = useSelector(
    (store: any) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  const getChecked = (data: string, index: number) => {
    const tempArr = [...selectedData];
    const indexr = tempArr.indexOf(data);
    if (indexr > -1) {
      tempArr.splice(indexr, 1);
    } else {
      tempArr.push(data);
    }
    setSelectedData(tempArr);
    props.setDataOut(tempArr);

    const allSelected = props.allOptions?.every((item: any) =>
      tempArr.includes(item)
    );
    setSelectAll(allSelected || false);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const updatedData = newSelectAll ? props.allOptions || [] : [];
    setSelectedData(updatedData);
    props.setDataOut(updatedData);
  };

  useEffect(() => {
    if (props.defaultValue) {
      setSelectedData(props.defaultValue);

      const allSelected = props.allOptions?.every((item: any) =>
        props.defaultValue.includes(item)
      );
      setSelectAll(allSelected || false);
    }
  }, [props.defaultValue, props.allOptions]);

  return (
    <>
      <div className={`${styles.multiwrapper} ${myPlanData?.isStrategyToShow === true ? "" : "disablePointerEventWithoutOpacity"}`}>
        {props.allOptions?.length > 0 ? (
          <div
            className={`${styles.each_div} ${styles.each_div_line}`}
            onClick={handleSelectAll}
          >
            <img className={styles.iconStyling} src={lineDotIcon} />
            <p>Select All</p>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
          </div>
        ) : null}
        {props.allOptions?.map((each: string, i: number) => {
          return (
            <div
              className={`${styles.each_div} ${styles.each_div_line}`}
              key={each}
            >
              <img className={styles.iconStyling} src={lineDotIcon} />
              <Tooltip
                title={each}
                placement={"top-start"}
                classes={{
                  arrow: classes.customArrow,
                  tooltip: classes.customTooltip,
                }}
                arrow={true}
              >
                <p onClick={() => getChecked(each, i)}>{each}</p>
              </Tooltip>
              <input
                type="checkbox"
                checked={selectedData.includes(each)}
                onChange={() => getChecked(each, i)}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}

let keyAccept: any = "";
let keyUnAccept: any = "";
export function Conditions(props: any) {
  const [selectedOne, setSelectedOne] = useState("");

  const [selectAccept, setSelectedAccept] = useState<string[]>([]);
  const [selectunAccept, setSelectedunAccept] = useState<string[]>([]);
  useEffect(() => {
    if (props.disp)
      props.disp(
        genenerateObjects(selectAccept, selectunAccept, keyAccept, keyUnAccept)
      );
  }, [selectAccept, selectunAccept]);

  useEffect(() => {
    if (props.defaultValue.acceptedOutcome) {
      keyAccept = Object.values(props.defaultValue.acceptedOutcome)[0];
      setSelectedAccept(Object.keys(props.defaultValue.acceptedOutcome));
    }
    if (props.defaultValue.unacceptedOutcome) {
      keyUnAccept = Object.values(props.defaultValue.unacceptedOutcome)[0];
      setSelectedunAccept(Object.keys(props.defaultValue.unacceptedOutcome));
    }
  }, []);
  return (
    <>
      <Subcondition
        onClick={setSelectedOne}
        open={selectedOne == "Accepted  Outcome"}
        warningIcon={true}
        title={"Accepted  Outcome"}
        actualKey={"Accepted  Outcome"}
        selectedKey={selectedOne}
        count={selectAccept.length}
      >
        <MultiSelect
          allOptions={props.acceptedOutcome ? props.acceptedOutcome : []}
          setDataOut={setSelectedAccept}
          defaultValue={selectAccept}
        />
      </Subcondition>
      <Subcondition
        onClick={setSelectedOne}
        open={selectedOne == "Unaccepted  Outcome"}
        warningIcon={true}
        title={"Unaccepted  Outcome"}
        actualKey={"Unaccepted  Outcome"}
        selectedKey={selectedOne}
        count={selectunAccept.length}
      >
        <MultiSelect
          allOptions={props.unacceptedOutcome ? props.unacceptedOutcome : []}
          setDataOut={setSelectedunAccept}
          defaultValue={selectunAccept}
        />
      </Subcondition>
    </>
  );
}

export function Timing(props: any) {
  const [amPm, setAmPm] = useState("AM");
  const [time, setTime] = useState<any>({ hour: "12", minute: "00" });
  const [amPm2, setAmPm2] = useState("AM");
  const [time2, setTime2] = useState<any>({ hour: "12", minute: "00" });
  const myPlanData: any = useSelector(
    (store: any) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );
  function handleChange(value: string, type: string) {
    console.log(value, "kkkkkkkk");
    const newVal = value.slice(-2);
    if (Number(newVal) > 12 && type == "hour") {
      setTime({ ...time, [type]: "00" });
      return;
    }
    if (Number(newVal) >= 60 && type == "minute") {
      setTime({ ...time, [type]: "00" });
      return;
    }
    setTime({ ...time, [type]: newVal });
  }
  function handleChange2(value: string, type: string) {
    const newVal = value.slice(-2);
    if (Number(newVal) > 12 && type == "hour") {
      setTime2({ ...time2, [type]: "00" });
      return;
    }
    if (Number(newVal) >= 60 && type == "minute") {
      setTime2({ ...time2, [type]: "00" });
      return;
    }
    setTime2({ ...time2, [type]: newVal });
  }
  useEffect(() => {
    if (props.disp) {
      props.disp({
        timing: [
          convertTo24Hour(time.hour, time.minute, amPm),
          convertTo24Hour(time2.hour, time2.minute, amPm2),
        ],
      });
    }
  }, [time, time2, amPm, amPm2]);

  useEffect(() => {
    //from outside
    if (props.defaultValue) {
      // const [h1, m1] = props.defaultValue[0].split(":");
      // const [h2, m2] = props.defaultValue[1].split(":");
      // setTime({ hour: h1, minute: m1 });
      // setTime2({ hour: h2, minute: m2 });
      const x = convertTo12Hour(props.defaultValue[0]);
      const y = convertTo12Hour(props.defaultValue[1]);
      const [h1, m1] = x[0].split(":");
      const [h2, m2] = y[0].split(":");
      const a1 = x[1];
      const a2 = y[1];
      setTime({ hour: h1, minute: m1 });
      setTime2({ hour: h2, minute: m2 });
      setAmPm(a1);
      setAmPm2(a2);
    }
  }, []);
  return (
    <>
      <div className={`${styles.timechanges}  ${myPlanData?.isStrategyToShow === true ? "" : "disablePointerEventWithoutOpacity"} `}>
        <div className={styles.timeall}>
          <p>Start Time</p>
          <div className={styles.timewrapper}>
            <div className={styles.clock_div}>
              <img src={clock} alt="" />
            </div>
            <div className={styles.inputdiv}>
              <input
                name="hour"
                type="text"
                onChange={(e) => handleChange(e.target.value, "hour")}
                value={time.hour}
                onKeyDown={validateInteger}
                placeholder="HH"
              // maxLength={2}
              />
              <p>:</p>
              <input
                name="minute"
                type="text"
                onChange={(e) => handleChange(e.target.value, "minute")}
                value={time.minute}
                onKeyDown={validateInteger}
                placeholder="MM"
              // maxLength={2}
              />
            </div>
            <div className={styles.ampm_div}>
              <button
                className={`${styles.leftbutton}  ${amPm == "AM" ? styles.bluebuton : ""
                  }`}
                onClick={() => setAmPm("AM")}
              >
                AM
              </button>
              <p></p>
              <button
                className={`${styles.rightbutton}  ${amPm == "PM" ? styles.bluebuton : ""
                  }`}
                onClick={() => setAmPm("PM")}
              >
                PM
              </button>
            </div>
          </div>
        </div>
        <div className={styles.timeall}>
          <p>End Time</p>
          <div className={styles.timewrapper}>
            <div className={styles.clock_div}>
              <img src={clock} alt="" />
            </div>
            <div className={styles.inputdiv}>
              <input
                name="hour"
                type="text"
                onChange={(e) => handleChange2(e.target.value, "hour")}
                value={time2.hour}
                onKeyDown={validateInteger}
                placeholder="HH"
              // maxLength={2}
              />
              <p>:</p>
              <input
                name="minute"
                type="text"
                onChange={(e) => handleChange2(e.target.value, "minute")}
                value={time2.minute}
                onKeyDown={validateInteger}
                placeholder="MM"
              // maxLength={2}
              />
            </div>
            <div className={styles.ampm_div}>
              <button
                className={`${styles.leftbutton}  ${amPm2 == "AM" ? styles.bluebuton : ""
                  }`}
                onClick={() => setAmPm2("AM")}
              >
                AM
              </button>
              <p></p>
              <button
                className={`${styles.rightbutton}  ${amPm2 == "PM" ? styles.bluebuton : ""
                  }`}
                onClick={() => setAmPm2("PM")}
              >
                PM
              </button>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
}
const allPayment = [
  "Agree To Pay",
  "Acceptable Promise to Pay",
  "Customer Hangup",
];

export function PaymentLink(props: any) {
  const classes = useStyles();
  const [selectedData, setSelected] = useState<string[]>([]);
  const [countAccepted, setCountAccepted] = useState(0)
  const [countUnAccepted, setCountUnAccepted] = useState(0)
  // By condition 
  const [selectedOne, setSelectedOne] = useState("");
  const [selectAccept, setSelectedAccept] = useState<string[]>([]);
  const [selectunAccept, setSelectedunAccept] = useState<string[]>([]);

  const myPlanData: any = useSelector(
    (store: any) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );
  console.log("bb", props.paymentLink)
  function getSelected(each: string) {
    if (selectedData.includes(each)) {
      setSelected(selectedData.filter((e) => e !== each));
    } else {
      setSelected([...selectedData, each]);
    }
  }
  useEffect(() => {
    props.disp({ paymentLinkDisposition: [...new Set([...selectAccept, ...selectunAccept])] });
  }, [selectAccept, selectunAccept]);

  // useEffect(() => {
  //   if (props.defaultValue) {
  //     setSelected(props.defaultValue);
  //   }
  // }, []);

  useEffect(() => {
    if (props.defaultValue) {
      setSelectedAccept(props.defaultValue);
      let acceptedCount = getLength(props.acceptedOutcome, props.defaultValue)
      setCountAccepted(acceptedCount)
    }
    if (props.defaultValue) {
      setSelectedunAccept(props.defaultValue);
      let unAcceptedCount = getLength(props.unacceptedOutcome, props.defaultValue)
      setCountUnAccepted(unAcceptedCount)
    }
  }, []);

  function getLength(dataTocheck: any, defaultSelected: any) {
    let count = 0
    defaultSelected?.forEach((e: any) => {
      if (dataTocheck.includes(e)) {
        count++
      }
    });
    return count
  }

  return (
    <>
      {/* <div className={`${styles.paymetn_link} ${myPlanData?.isStrategyToShow === true ? "" : "disablePointerEventWithoutOpacity"}`}>
        <div className={styles.allOptions}>
          {allPayment.map((each) => {
            return (
              <div
                onClick={() => getSelected(each)}
                className={`${styles.each_div} ${
                  selectedData.includes(each) ? styles.bgblue : ""
                }`}
                key={each}
              >
                <Tooltip
                  title={each}
                  placement={"top-start"}
                  classes={{
                    arrow: classes.customArrow,
                    tooltip: classes.customTooltip,
                  }}
                  arrow={true}
                >
                  <p
                    className={`${
                      selectedData.includes(each) ? styles.colorwhite : ""
                    }`}
                    data-testid={
                      selectedData.includes(each) ? "selected-options" : ""
                    }
                  >
                    {each}
                  </p>
                </Tooltip>
              </div>
            );
          })}
        </div>
      </div> */}
      <div className={`${styles.paymetn_link} ${myPlanData?.isStrategyToShow === true ? "" : "disablePointerEventWithoutOpacity"}`}>
        <Subcondition
          onClick={setSelectedOne}
          open={selectedOne == "Accepted  Outcome"}
          warningIcon={true}
          title={"Accepted  Outcome"}
          actualKey={"Accepted  Outcome"}
          selectedKey={selectedOne}
          count={countAccepted > 0 ? countAccepted : selectAccept.length}
        >
          <MultiSelect
            allOptions={props.acceptedOutcome ? props.acceptedOutcome : []}
            setDataOut={setSelectedAccept}
            defaultValue={selectAccept}
          />
        </Subcondition>
        <Subcondition
          onClick={setSelectedOne}
          open={selectedOne == "Unaccepted  Outcome"}
          warningIcon={true}
          title={"Unaccepted  Outcome"}
          actualKey={"Unaccepted  Outcome"}
          selectedKey={selectedOne}
          count={countUnAccepted > 0 ? countUnAccepted : selectunAccept.length}
        >
          <MultiSelect
            allOptions={props.unacceptedOutcome ? props.unacceptedOutcome : []}
            setDataOut={setSelectedunAccept}
            defaultValue={selectunAccept}
          />
        </Subcondition>
      </div>
    </>
  );
}

export const Callback = (props: any) => {
  const [callBackData, setCallBackData] =
    useState<CallBackItem[]>(callBackList);
  const myPlanData: any = useSelector(
    (store: any) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );
  console.log(callBackData, "callll");

  const handleOnClick = (name: string) => {
    const updatedList = callBackData.map((obj) => ({
      ...obj,
      isSelected: obj.name === name,
    }));
    setCallBackData(updatedList);
  };

  const handleCountChange = (count: number, data: CallBackItem) => {
    const updatedList = callBackData.map((obj) =>
      obj.name === data.name
        ? { ...data, count, isSelected: true }
        : { ...obj, isSelected: false }
    );
    setCallBackData(updatedList);
  };

  const [amPm, setAmPm] = useState("AM");
  const [time, setTime] = useState<any>({ hour: "12", minute: "00" });

  function handleChange(value: string, type: string) {
    const newVal = value.slice(-2);
    if (Number(newVal) > 12 && type == "hour") {
      setTime({ ...time, [type]: "00" });
      return;
    }
    if (Number(newVal) >= 60 && type == "minute") {
      setTime({ ...time, [type]: "00" });
      return;
    }
    setTime({ ...time, [type]: newVal });
  }

  useEffect(() => {
    const selectedType = callBackData?.find((data) => data.isSelected === true);
    if (props?.disp) {
      props?.disp({
        callBackThreshold: {
          ...props.defaultValue,
          dateType: selectedType?.name,
          days: selectedType?.count,
          time: [convertTo24Hour(time.hour, time.minute, amPm)],
        },
      });
    }
  }, [time, amPm, callBackData]);

  useEffect(() => {
    //from outside
    if (props?.defaultValue) {
      const x = convertTo12Hour(props?.defaultValue?.time?.[0]);
      const [h1, m1] = x?.[0]?.split(":");
      const a1 = x[1];
      setTime({ hour: h1, minute: m1 });
      setAmPm(a1);
      const updatedList = callBackData.map((item) => {
        if (item.name === props?.defaultValue?.dateType) {
          return {
            ...item,
            isSelected: true,
            count: props?.defaultValue?.days,
          };
        }
        return item;
      });
      setCallBackData(updatedList);
    }
  }, []);

  return (
    <div className={`${styles.callBackWrapper} ${myPlanData?.isStrategyToShow === true ? "" : "disablePointerEventWithoutOpacity"}`}>
      <div className={styles.callBackData}>
        {" "}
        {callBackData.map((each) => (
          <div
            key={each.name}
            className={`${styles.each_callBack} ${each.isSelected ? styles.each_div_blue : ""
              }`}
          >
            <div
              className={`${styles.each_callBack_left}`}
              onClick={() => handleOnClick(each.name)}
            >
              <input
                name="callBack"
                value=""
                type="radio"
                checked={each.isSelected}
                onChange={() => handleOnClick(each.name)}
              />
              <p className={styles.each_callBack_info}>{each.name}</p>
            </div>
            <div className={`${styles.each_callBack_right}`}>
              <InputDays
                key={each.name} // Add a unique key based on the name
                getCount={each.count}
                onCountChange={(count) => handleCountChange(count, each)}
                minValue={0}
              />
            </div>
          </div>
        ))}
      </div>

      <div className={styles.timewrapper}>
        <div className={styles.clock_div}>
          <img src={clock} alt="" />
        </div>
        <div className={styles.inputdiv}>
          <input
            name="hour"
            type="text"
            onChange={(e) => handleChange(e.target.value, "hour")}
            value={time.hour}
            onKeyDown={validateInteger}
            placeholder="HH"
          // maxLength={2}
          />
          <p>:</p>
          <input
            name="minute"
            type="text"
            onChange={(e) => handleChange(e.target.value, "minute")}
            value={time.minute}
            onKeyDown={validateInteger}
            placeholder="MM"
          // maxLength={2}
          />
        </div>
        <div className={styles.ampm_div}>
          <button
            className={`${styles.leftbutton}  ${amPm == "AM" ? styles.bluebuton : ""
              }`}
            onClick={() => setAmPm("AM")}
          >
            AM
          </button>
          <p></p>
          <button
            className={`${styles.rightbutton}  ${amPm == "PM" ? styles.bluebuton : ""
              }`}
            onClick={() => setAmPm("PM")}
          >
            PM
          </button>
        </div>
      </div>
    </div>
  );
};

export const PromiseToPayThreshold = (props: any) => {
  const [promiseToPay, setPromiseToPay] =
    useState<CallBackItem[]>(promiseToPayList);
  const myPlanData: any = useSelector(
    (store: any) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );
  const handleOnClick = (name: string) => {
    const updatedList = promiseToPay.map((obj) => ({
      ...obj,
      isSelected: obj.name === name,
    }));
    setPromiseToPay(updatedList);
  };

  const handleCountChange = (count: number, data: CallBackItem) => {
    const updatedList = promiseToPay.map((obj) =>
      obj.name === data.name
        ? { ...data, count, isSelected: true }
        : { ...obj, isSelected: false }
    );
    setPromiseToPay(updatedList);
  };

  const [amPm, setAmPm] = useState("AM");
  const [time, setTime] = useState<any>({ hour: "12", minute: "00" });

  function handleChange(value: string, type: string) {
    const newVal = value.slice(-2);
    if (Number(newVal) > 12 && type == "hour") {
      setTime({ ...time, [type]: "00" });
      return;
    }
    if (Number(newVal) >= 60 && type == "minute") {
      setTime({ ...time, [type]: "00" });
      return;
    }
    setTime({ ...time, [type]: newVal });
  }

  useEffect(() => {
    const selectedType: any = promiseToPay?.find(
      (data) => data.isSelected === true
    );
    if (props?.disp) {
      props?.disp({
        promiseToPayThreshold: {
          ...props.defaultValue,
          dateType: selectedType?.name,
          days: selectedType?.count,
          time: [convertTo24Hour(time.hour, time.minute, amPm)],
        },
      });
    }
  }, [time, amPm, promiseToPay]);

  useEffect(() => {
    //from outside

    if (props?.defaultValue) {
      const x = convertTo12Hour(props?.defaultValue?.time?.[0]);
      const [h1, m1] = x?.[0]?.split(":");
      const a1 = x[1];
      setTime({ hour: h1, minute: m1 });
      setAmPm(a1);
      const updatedList = promiseToPay.map((item) => {
        if (item.name === props?.defaultValue?.dateType) {
          return {
            ...item,
            isSelected: true,
            count: props?.defaultValue?.days,
          };
        }
        return item;
      });
      setPromiseToPay(updatedList);
    }
  }, []);

  return (
    <div className={`${styles.callBackWrapper} ${myPlanData?.isStrategyToShow === true ? "" : "disablePointerEventWithoutOpacity"}`}>
      <div className={styles.callBackData}>
        {promiseToPay.map((each) => (
          <div
            key={each.name}
            className={`${styles.each_callBack} ${each.isSelected ? styles.each_div_blue : ""
              }`}
          >
            <div
              className={`${styles.each_callBack_left}`}
              onClick={() => handleOnClick(each.name)}
            >
              <input
                name="callBack"
                value=""
                type="radio"
                checked={each.isSelected}
                onChange={() => handleOnClick(each.name)}
              />
              <p className={styles.each_callBack_info}>{each.name}</p>
            </div>
            <div className={`${styles.each_callBack_right}`}>
              <InputDays
                key={each.name} // Add a unique key based on the name
                getCount={each.count}
                onCountChange={(count) => handleCountChange(count, each)}
                minValue={0}
              />
            </div>
          </div>
        ))}
      </div>

      <div className={styles.timewrapper}>
        <div className={styles.clock_div}>
          <img src={clock} alt="" />
        </div>
        <div className={styles.inputdiv}>
          <input
            name="hour"
            type="text"
            onChange={(e) => handleChange(e.target.value, "hour")}
            value={time.hour}
            onKeyDown={validateInteger}
            placeholder="HH"
          // maxLength={2}
          />
          <p>:</p>
          <input
            name="minute"
            type="text"
            onChange={(e) => handleChange(e.target.value, "minute")}
            value={time.minute}
            onKeyDown={validateInteger}
            placeholder="MM"
          // maxLength={2}
          />
        </div>
        <div className={styles.ampm_div}>
          <button
            className={`${styles.leftbutton}  ${amPm == "AM" ? styles.bluebuton : ""
              }`}
            onClick={() => setAmPm("AM")}
          >
            AM
          </button>
          <p></p>
          <button
            className={`${styles.rightbutton}  ${amPm == "PM" ? styles.bluebuton : ""
              }`}
            onClick={() => setAmPm("PM")}
          >
            PM
          </button>
        </div>
      </div>
    </div>
  );
};

interface alternateProp {
  defaultValue: any;
  onChangeValue: (data: any) => void;
  options: Array<string>;
}
export const AlternateNumber = (props: alternateProp) => {
  const alternateNumber = [
    {
      isEnabled: false,
      name: "Enable",
      options: [
        {
          name: "Same Day",
          value: "sameDay",
          dispositions: props.options,
        },
        {
          name: "Alternate Day",
          value: "alternateDay",
          dispositions: props.options,
        },
      ],
    },
    { isEnabled: false, name: "Disable" },
  ];

  const [alternateOption, setAlternateOption] = useState<any>("");
  const [dispositionSameDay, setDispositionSameDay] = useState<any>([]);
  const [dispositionAlternateDay, setDispositionAlternateDay] = useState<any>(
    []
  );
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  console.log(
    dispositionSameDay,
    dispositionAlternateDay,
    alternateOption,
    selectedOption,
    "hhhhhhhh"
  );

  // alternateCalling: true
  // alternateCallingFrequency: "alternateDay"
  // alternateStrategy: ["Failed"]
  const myPlanData: any = useSelector(
    (store: any) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );
  const handleOnClick = (name: string) => {
    setAlternateOption(name);
    setSelectedOption(null);
    if (name === "Enable" && !selectedOption?.length) {
      setSelectedOption("sameDay");
      props.onChangeValue({ alternateCallingFrequency: "sameDay" });
    }
    if (name === "Disable") {
      props.onChangeValue({ alternateCallingFrequency: "" });
      props.onChangeValue({ alternateStrategy: [] });
    }
    props.onChangeValue({ alternateCalling: name === "Enable" ? true : false });
  };

  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
    props.onChangeValue({ alternateCallingFrequency: value });
  };

  const handleCheckboxClick = (dispName: string, value: string) => {
    if (value === "sameDay") {
      if (dispositionSameDay?.includes(dispName)) {
        const newData = dispositionSameDay?.filter(
          (item: any) => item !== dispName
        );
        setDispositionSameDay(newData);
        // props.onChangeValue({alternateStrategy :newData})
      } else {
        const newData = [...dispositionSameDay, dispName];
        setDispositionSameDay(newData);
      }
    } else {
      if (dispositionAlternateDay?.includes(dispName)) {
        const newData = dispositionAlternateDay?.filter(
          (item: any) => item !== dispName
        );
        setDispositionAlternateDay(newData);
      } else {
        const newData = [...dispositionAlternateDay, dispName];
        setDispositionAlternateDay(newData);
      }
    }
  };

  useEffect(() => {
    if (selectedOption?.length) {
      selectedOption === "sameDay"
        ? props.onChangeValue({
          alternateStrategy: dispositionSameDay,
          alternateCallingFrequency: "sameDay",
        })
        : props.onChangeValue({
          alternateStrategy: dispositionAlternateDay,
          alternateCallingFrequency: "alternateDay",
        });
    }
  }, [selectedOption, dispositionAlternateDay, dispositionSameDay]);

  useEffect(() => {
    if (props?.defaultValue) {
      if (props?.defaultValue?.alternateCalling) {
        setAlternateOption("Enable");
      } else {
        setAlternateOption("Disable");
      }
      if (props?.defaultValue?.alternateCallingFrequency === "alternateDay") {
        setSelectedOption("alternateDay");
        setDispositionAlternateDay(
          props?.defaultValue?.alternateStrategy
            ? props?.defaultValue?.alternateStrategy
            : dispositionAlternateDay
        );
      } else {
        setSelectedOption("sameDay");
        setDispositionSameDay(
          props?.defaultValue?.alternateStrategy
            ? props?.defaultValue?.alternateStrategy
            : dispositionSameDay
        );
      }
    }
  }, [props?.defaultValue]);

  return (
    <div className={`${styles.alternateNoWrapper} ${myPlanData?.isStrategyToShow === true ? "" : "disablePointerEventWithoutOpacity"}`}>
      <div className={styles.each_altnum}>
        {alternateNumber.map((each, outerIndex) => (
          <div
            key={each.name}
            className={`${styles.each_altnum_header} ${each.name === alternateOption ? styles.each_div_blue : ""
              }`}
          // onClick={() => handleOnClick(each.name)}
          >
            <input
              name="altNumber"
              value=""
              type="radio"
              checked={each.name === alternateOption}
              onChange={() => handleOnClick(each.name)}
            />
            <p>{each.name}</p>
          </div>
        ))}
      </div>
      {alternateOption === "Enable" &&
        alternateNumber?.map((res, outerIndex) => (
          <div className={`${styles.each_altnum_info}`}>
            <div
              className={`${res?.options && res?.options?.length > 0
                ? styles.each_altnum_info_btn
                : ""
                }`}
            >
              {res.options?.map((info: any, innerIndex: number) => {
                return (
                  <button
                    className={`${styles.btnStyling} ${selectedOption !== null
                      ? selectedOption === info?.value
                        ? styles.btnStylingActive
                        : ""
                      : ""
                      }`}
                    key={innerIndex}
                    onClick={() => handleOptionClick(info?.value)}
                  >
                    {info?.name}
                  </button>
                );
              })}
            </div>

            {res.options?.map(
              (info: any, innerIndex: number) =>
                info?.value === selectedOption && (
                  <div key={innerIndex} className={styles.dispDiv}>
                    {info.dispositions?.map(
                      (disp: string, dispIndex: number) => (
                        <div
                          key={dispIndex}
                          className={styles.dispositionDiv}
                          onClick={() => handleCheckboxClick(disp, info?.value)}
                        >
                          <input
                            type="checkbox"
                            checked={
                              info?.value === "sameDay"
                                ? dispositionSameDay?.includes(disp)
                                : dispositionAlternateDay?.includes(disp)
                            }
                            onChange={() =>
                              handleCheckboxClick(disp, info?.value)
                            }
                          />
                          <p>{disp}</p>
                        </div>
                      )
                    )}
                  </div>
                )
            )}
          </div>
        ))}
    </div>
  );
};

interface FollowUp {
  name: string;
  time?: string;
  mins?: number;
  checked: boolean;
}

interface Props {
  options: any;
  optionsAccepted?: any;
  optionsUnAccepted?: any;
  defaultValue?: any;
  onChangeValue: (value: any) => void;
}

export const FollowUps = (props: Props) => {
  const classes = useStyles();
  const defaultMins = 120;
  const defaultTime = convertToTimeFormat(defaultMins);
  const myPlanData: any = useSelector(
    (store: any) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );
  const followUpOptions = [
    {
      name: "Accepted",
      value: "connected",
      dispositions: props.optionsAccepted,
    },
    {
      name: "Unaccepted",
      value: "disconnected",
      dispositions: props.optionsUnAccepted,
    },
  ];

  const defaultData = {
    connected: props.optionsAccepted?.map((data: string) => ({
      name: data,
      time: defaultTime,
      mins: defaultMins,
      checked: false,
    })),
    disconnected: props.optionsUnAccepted?.map((data: string) => ({
      name: data,
      time: defaultTime,
      mins: defaultMins,
      checked: false,
    })),
  };

  const [selectedOption, setSelectedOption] = useState<string>("connected");
  const [followUps, setFollowUps] = useState<{ [key: string]: FollowUp[] }>(
    defaultData
  );

  const [selectAll, setSelectAll] = useState<any>({
    connected: false,
    disconnected: false,
  });
  console.log("select all", selectAll, followUps)
  const [defaultTimeForFollowUps, setDefaultTimeForFollowUps] = useState<any>({
    connected: { time: defaultTime, mins: defaultMins },
    disconnected: { time: defaultTime, mins: defaultMins },
  });

  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
  };

  const handleSelectAll = (option: any) => {
    const updatedDispositions = { ...followUps };
    const currentDispositions = updatedDispositions[option.value];

    if (!selectAll[option.value]) {
      const res = currentDispositions.map((data: any) => ({
        ...data,
        checked: true,
        time: defaultTimeForFollowUps[option.value].time,
        mins: defaultTimeForFollowUps[option.value].mins,
      }));

      const updatedRes = {
        ...updatedDispositions,
        [option.value]: [...res],
      };

      setFollowUps(updatedRes);
      setSelectAll({
        ...selectAll,
        [option.value]: true,
      });
    } else {
      const res = currentDispositions.map((data: any) => ({
        ...data,
        time: defaultTimeForFollowUps[option.value].time,
        mins: defaultTimeForFollowUps[option.value].mins,
        checked: false,
      }));
      const updatedRes = {
        ...updatedDispositions,
        [option.value]: [...res],
      };
      setFollowUps(updatedRes);
      setSelectAll({
        ...selectAll,
        [option.value]: false,
      });

      if (props?.defaultValue) {
        const updatedDefaultValue = { ...props.defaultValue };

        currentDispositions.forEach((item: FollowUp) => {
          delete updatedDefaultValue[item.name];
        });

        props.onChangeValue({ followUps: updatedDefaultValue });
      }
    }
  };

  console.log(followUps, "checked");

  const handleChangeTimeForAll = (
    value: string,
    type: string,
    optionValue: string
  ) => {
    const newDefaultTime = { ...defaultTimeForFollowUps[optionValue] };
    let newHour = newDefaultTime?.time
      ? newDefaultTime?.time.split(":")[0]
      : "00";
    let newMinute = newDefaultTime?.time
      ? newDefaultTime?.time.split(":")[1]
      : "00";

    if (type === "hour") {
      newHour = value.slice(-2);
      if (parseInt(newHour, 10) > 23) {
        newHour = "00";
      }
    }

    if (type === "minute") {
      newMinute = value.slice(-2);
      if (parseInt(newMinute, 10) > 59) {
        newMinute = "00";
      }
    }

    const newTime = `${newHour}:${newMinute}`;
    const newMins = convertToMinutes(newTime);

    const updatedDispositions = { ...followUps };
    const currentDispositions = updatedDispositions[optionValue];

    const updatedItems = currentDispositions?.map((item: FollowUp) => ({
      ...item,
      time: newTime,
      mins: newMins,
    }));

    updatedDispositions[optionValue] = updatedItems;
    setFollowUps(updatedDispositions);

    setDefaultTimeForFollowUps({
      ...defaultTimeForFollowUps,
      [optionValue]: {
        time: newTime,
        mins: newMins,
      },
    });
  };

  const handleCheckboxClick = (dispName: string) => {
    const updatedDispositions = { ...followUps };
    const currentDispositions = updatedDispositions[selectedOption];

    const updatedItems = currentDispositions.map((item: FollowUp) => {
      if (item.name === dispName) {
        const checked = !item.checked;
        const updatedItem = { ...item, checked };
        if (!checked) {
          updatedItem.mins = defaultTimeForFollowUps[selectedOption].mins;
          updatedItem.time = defaultTimeForFollowUps[selectedOption].time;
        }
        return updatedItem;
      }
      return item;
    });

    updatedDispositions[selectedOption] = updatedItems;

    setFollowUps(updatedDispositions);
  };

  function handleChange(value: string, type: string, dispName: string) {
    const updatedDispositions = { ...followUps };
    const currentDispositions = updatedDispositions[selectedOption];
    const updatedItems = currentDispositions.map((item: FollowUp) => {
      if (item.name === dispName) {
        let newHour = item.time ? item.time.split(":")[0] : "00";
        let newMinute = item.time ? item.time.split(":")[1] : "00";

        if (type === "hour") {
          newHour = value.slice(-2);
          if (parseInt(newHour, 10) > 23) {
            newHour = "00";
          }
        }

        if (type === "minute") {
          newMinute = value.slice(-2);
          if (parseInt(newMinute, 10) > 59) {
            newMinute = "00";
          }
        }

        const newTime = `${newHour}:${newMinute}`;
        const newMins = convertToMinutes(newTime);
        return { ...item, time: newTime, mins: newMins };
      }
      return item;
    });
    updatedDispositions[selectedOption] = updatedItems;
    setFollowUps(updatedDispositions);
  }

  useEffect(() => {
    const isAllChecked =
      selectedOption === "connected"
        ? followUps.connected.every((data: any) => data.checked === true)
        : followUps.disconnected.every((data: any) => data.checked === true);

    setSelectAll({ ...selectAll, [selectedOption]: isAllChecked });

    let combinedFollowUps: any = {};

    const connectedObj: any = {};
    const disconnectedObj: any = {};

    followUps.connected.forEach((item) => {
      if (item.checked) {
        connectedObj[item.name] = item.mins;
      }
    });

    followUps.disconnected.forEach((item) => {
      if (item.checked) {
        disconnectedObj[item.name] = item.mins;
      }
    });

    combinedFollowUps = { ...connectedObj, ...disconnectedObj };

    if (combinedFollowUps) {
      props.onChangeValue({ followUps: combinedFollowUps });
    }
  }, [followUps, selectedOption]);

  useEffect(() => {
    // const data: any = {
    //   ATP: 120,
    //   Paid: 225,
    //   PIC: 120,
    //   "Unacceptable Promise To Pay": 280,
    //   "General - Wrong Loan": 120,
    // };
    if (props?.defaultValue) {
      const updatedFollowUps = { ...followUps };

      Object.keys(props?.defaultValue).forEach((searchValue) => {
        const matchingConnectedObject = updatedFollowUps.connected.find(
          (obj) => obj.name === searchValue
        );

        if (matchingConnectedObject) {
          matchingConnectedObject.checked = true;
          matchingConnectedObject.time = convertToTimeFormat(
            props?.defaultValue?.[searchValue]
          );
          matchingConnectedObject.mins = props?.defaultValue?.[searchValue];
        }

        const matchingObject = updatedFollowUps.disconnected.find(
          (obj) => obj.name === searchValue
        );

        if (matchingObject) {
          matchingObject.checked = true;
          matchingObject.time = convertToTimeFormat(
            props?.defaultValue?.[searchValue]
          );
          matchingObject.mins = props?.defaultValue?.[searchValue];
        }
      });

      setFollowUps(updatedFollowUps);
    }
  }, []);

  console.log(followUps, "followUps");

  return (
    <div className={`${styles.followUpsWrapper} ${myPlanData?.isStrategyToShow === true ? "" : "disablePointerEventWithoutOpacity"}`}>
      <div className={styles.eachFollowUp}>
        {followUpOptions.map((option) => (
          <div key={option.value} className={styles.eachFollowUpBtnContainer}>
            <button
              className={`${styles.eachFollowUpBtn} ${option.value === selectedOption
                ? styles.eachFollowUpBtnActive
                : ""
                }`}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.name}
            </button>
          </div>
        ))}
      </div>

      <div className={`${styles.dispDiv}`}>
        {followUpOptions.map((option) =>
          option.value === selectedOption ? (
            <div key={option.value} className={styles.dispOptionMainDiv}>
              <div className={styles.dispOptionDiv}>
                <div
                  className={`${styles.dispositionDiv} ${selectAll[option.value] ? "active" : ""
                    }`}
                  onClick={() => handleSelectAll(option)}
                >
                  <input type="checkbox" checked={selectAll[option.value]} />
                  <label>Select All</label>
                </div>

                <div className={styles.followUpInputdiv}>
                  <input
                    name="hour"
                    type="text"
                    onChange={(e) =>
                      handleChangeTimeForAll(
                        e.target.value,
                        "hour",
                        option.value
                      )
                    }
                    value={
                      selectAll[option.value] ?
                        followUps?.[option.value]?.[0].time?.split(":")[0] :
                        (defaultTimeForFollowUps[option.value].time?.split(
                          ":"
                        )[0] || "")
                    }
                    onKeyDown={validateInteger}
                    placeholder="HH"
                  // maxLength={2}
                  />
                  <p>:</p>
                  <input
                    name="minute"
                    type="text"
                    onChange={(e) =>
                      handleChangeTimeForAll(
                        e.target.value,
                        "minute",
                        option.value
                      )
                    }
                    value={
                      selectAll[option.value] ?
                        followUps?.[option.value]?.[0].time?.split(":")[1] :
                        (defaultTimeForFollowUps[option.value]?.time?.split(
                          ":"
                        )[1] || "")
                    }
                    onKeyDown={validateInteger}
                    placeholder="MM"
                  // maxLength={2}
                  />
                </div>
              </div>

              {followUps?.[option.value]?.map((disp: any, index: number) => (
                <div className={styles.dispOptionDiv} key={index}>
                  <div
                    className={`${styles.dispositionDiv} ${disp.checked ? "active" : ""
                      }`}
                    onClick={() => handleCheckboxClick(disp?.name)}
                  >
                    <input type="checkbox" checked={disp.checked} />
                    {/* <Tooltip
                      title={disp?.name}
                      placement={"top-start"}
                      classes={{
                        arrow: classes.customArrow,
                        tooltip: classes.customTooltip,
                      }}
                      arrow={true}
                    > */}
                    <label>{disp?.name}</label>
                    {/* </Tooltip> */}
                  </div>
                  {disp.checked ? (
                    <div className={styles.followUpInputdiv}>
                      <input
                        name="hour"
                        type="text"
                        onChange={(e) =>
                          handleChange(e.target.value, "hour", disp?.name)
                        }
                        value={disp.time?.split(":")[0] || ""}
                        onKeyDown={validateInteger}
                        placeholder="HH"
                      // maxLength={2}
                      />
                      <p>:</p>
                      <input
                        name="minute"
                        type="text"
                        onChange={(e) =>
                          handleChange(e.target.value, "minute", disp?.name)
                        }
                        value={disp?.time?.split(":")[1] || ""}
                        onKeyDown={validateInteger}
                        placeholder="MM"
                      // maxLength={2}
                      />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export const ChannelsMapping = () => {
  const [labels, setLabels] = useState([
    { orgLabel: "Tamil", newLabel: "", showOptions: false },
    { orgLabel: "Telugu", newLabel: "", showOptions: false },
  ]);

  const toggleOptions = (index: number) => {
    const updatedLabels = labels.map((label, idx) => {
      if (idx === index) {
        return { ...label, showOptions: !label.showOptions };
      } else {
        return { ...label, showOptions: false };
      }
    });
    setLabels(updatedLabels);
  };

  const selectLabel = (
    event: React.MouseEvent,
    index: number,
    newLabel: string
  ) => {
    event.stopPropagation();

    const updatedLabels = labels.map((label, idx) => {
      if (idx === index) {
        return { ...label, newLabel, showOptions: false };
      } else {
        return label;
      }
    });
    setLabels(updatedLabels);
  };

  return (
    <div className={styles.channelsMappingWrapper}>
      {labels.map((label, index) => (
        <div key={index}>
          {label.newLabel && label.newLabel.length > 0 ? (
            <div className={styles.labelStyling}>
              Replace {label.orgLabel} With
            </div>
          ) : null}

          <div
            className={styles.inputStyling}
            onClick={() => toggleOptions(index)}
          >
            <div>
              {label.newLabel
                ? `${label.newLabel}`
                : `Replace ${label.orgLabel} With`}
            </div>
            <Icon img_src={dropdownIcon} />
          </div>
          {label.showOptions && (
            <div>
              {labels.map((data, idx) => (
                <div
                  key={idx}
                  onClick={(event) => selectLabel(event, index, data.orgLabel)}
                  className={styles.optionsStyling}
                >
                  {data.orgLabel}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
export const AttemptCount = (props: any) => {
  const [alternateDayCount, setAlternateDayCount] = useState(0);
  const myPlanData: any = useSelector(
    (store: any) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  const handleCountChange = (count: number) => {
    console.log("hey", count);
    setAlternateDayCount(count);
    props.disp({ reattempt: count });
  };
  useEffect(() => {
    if (props.defaultValue) {
      setAlternateDayCount(props.defaultValue || 0);
    }
  }, []);

  return (
    <div className={`${styles.attemptCountWrapper}  ${myPlanData?.isStrategyToShow === true ? "" : "disablePointerEventWithoutOpacity"}`}>
      <div className={`${styles.days}`}>Count</div>
      <div className={`${styles.counter}`}>
        <InputDays
          getCount={alternateDayCount}
          onCountChange={(count) => handleCountChange(count)}
          minValue={0}
          text="Count"
        />
      </div>
    </div>
  );
};
