import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SmsSchedulerPage from "../../smsSchedulerSetting/SmsSchedulerPage";
import ChannelSummary from "../channelSummary/ChanneSummary";
import { smsSchedulerData } from "../../../../../redux/campaign/actions";
import { useDispatch } from "react-redux";
import { setDateFilterDataNew } from "../../../../../redux/filter/actions";
import { format } from "date-fns";

function SMSschedule() {
  const [toShowSummary, setShowSummary] = useState(false);
  const [allData, setAllData] = useState<any>();
  const [time, setTime] = useState("");
  const [template, setSelectedTemplate] = useState();

  const dispatch = useDispatch();

  const campaignData = useSelector((store: any) => {
    return store.campaignReducer.campaignAllCampaignChannelData;
  });

  const dateData = useSelector(
    (store: any) => store?.filterReducer?.filteredDateRangeData
  );
  const smsSchedulerAllData = useSelector(
    (store: any) => store.campaignReducer.smsSchedulerData
  );
  const storedDataScheduler = useSelector(
    (store: any) => store?.omniChannelReducer?.storedschedulerSettingSms
  );

  useEffect(() => {
    console.log("hhh", storedDataScheduler);
    dispatch(
      setDateFilterDataNew({
        fromDate: format(new Date(), "yyyy-MM-dd"),
        toDate: format(new Date(), "yyyy-MM-dd"),
      })
    );
  }, []);
  const onClickSubmit = () => {
    const data = {
      communication: "One Way",
      template: template,
      startDate: dateData?.fromDate,
      endDate: dateData?.toDate,
      templatesId: "",
      time: time,
    };
    dispatch(smsSchedulerData(data));
    setShowSummary(true);
  };
  const onclickEdit = () => {
    setShowSummary(false);
  };
  const onClickSubmitSummary = () => {
    console.log("click ");
  };
  const onChangeTime = (value: any) => {
    setTime((prev) => value);
  };
  const onChangeTemplate = (data: any) => {
    setSelectedTemplate(data);
  };
  useEffect(() => {
    if (campaignData?.length > 0) {
      campaignData?.map((data: any) => {
        if (data?.channels?.[0] == "SMS") {
          setAllData(data);
        }
      });
    }
  }, [campaignData]);
  useEffect(() => {
    console.log("hhh", storedDataScheduler);
    if (storedDataScheduler?.startDate) {
      dispatch(
        setDateFilterDataNew({
          fromDate: storedDataScheduler?.startDate,
          //  format(
          //   new Date(storedDataScheduler?.startDate),
          //   "yyyy-MM-dd"
          // ),
          toDate: storedDataScheduler?.endDate,
          // format(
          //   new Date(storedDataScheduler?.endDate),
          //   "yyyy-MM-dd"
          // )
        })
      );
    }
  }, [storedDataScheduler?.startDate?.length]);

  console.log("hh", storedDataScheduler, dateData);
  return (
    <>
      {toShowSummary ? (
        <ChannelSummary
          onEdit={onclickEdit}
          channel="SMS"
          campaignName={allData?.campaignName}
          language={allData?.language}
          Template=""
          date={{
            startDate: storedDataScheduler?.startDate,
            endDate: storedDataScheduler?.endDate,
          }}
          time={storedDataScheduler?.time}
          onSubmitSummary={onClickSubmitSummary}
          omniChannelDetails={storedDataScheduler}
        />
      ) : (
        <SmsSchedulerPage
          // templateDropdownArray={["abhi", "nayak"]}
          onChangeTemplate={(data: string) => onChangeTemplate(data)}
          template={template}
          onChangeTime={onChangeTime}
          channel="SMS"
          onSubmit={onClickSubmit}
          storedDataScheduler={storedDataScheduler}
        />
      )}
    </>
  );
}

export default SMSschedule;
