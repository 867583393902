import { takeLatest } from "redux-saga/effects";
import actionTypes from "../allIntegrations.actionTypes";
import {
  getAllIntegrationsDataAPIWorker,
  createAllIntegrationsDataAPIWorker,
  updateAllIntegrationsDataAPIWorker,
} from "./allIntegrations.saga";

export default function* allIntegrationsDataWatcher(): any {
  yield takeLatest(
    actionTypes.GET_ALL_INTEGRATIONS_DATA_REQUEST,
    getAllIntegrationsDataAPIWorker
  );
  yield takeLatest(
    actionTypes.CREATE_INTEGRATION_DATA_REQUEST,
    createAllIntegrationsDataAPIWorker
  );
  yield takeLatest(
    actionTypes.UPDATE_INTEGRATION_DATA_REQUEST,
    updateAllIntegrationsDataAPIWorker
  );
}
