import React, { useEffect, useState } from "react";
import styles from "./CRMDonutChartComp.module.scss";
import { noPhoneDataIcon } from "../../../../theme/assets/svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import CRMDonutChart from "../charts/DonutChart";

// interface props {
//   default: string;
// }

export default function CRMDonutChartComponent(props: any) {
  const [noData, setNoData] = useState<boolean>(true);

  //   const referenceKey = props.chartDetails?.refenceKeyForData;
  // console.log(dotDetails,"8888")
  //   useEffect(() => {
  //     if (props.data && props.data[referenceKey]) {
  //       props.data && props.data[referenceKey]
  //         ? Object.values(props.data[referenceKey]).every((item) => item === 0)
  //           ? setNoData(true)
  //           : setNoData(false)
  //         : null;
  //     }
  //   }, [props.data[referenceKey], props.donutChartDetails]);
  return (
    <>
      {props.data.length > 0 ? (
        <div className={styles.crmDonutComponentWrapper}>
          <div className={styles.donutTopComp}>
            <CRMDonutChart data={props.data} chartDetails={props.chartDetails} />
          </div>
          {props.data &&
            props.data.map((each: any, i: number) => {
              return (
                <div className={styles.donutbottomComp} key={i}>
                  <span
                    className={styles.legendDot}
                    style={{ backgroundColor: `${each.fillColor}` }}
                  ></span>
                  <p className={styles.legendName}>{each.name}</p>
                </div>
              );
            })}
        </div>
      ) : (
        <div className={styles.noDataDiv}>
          <img className={styles.noDataImgClass} src={noPhoneDataIcon} />
        </div>
      )}
    </>
  );
}
