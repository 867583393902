import actionTypes from "../actionTypes";

export const startLoggerTour = (payload: boolean) => {
  return {
    type: actionTypes.START_LOGGER_TOUR,
    payload: payload,
  };
};
export const experiencePravid = (payload: boolean) => {
  return {
    type: actionTypes.EXPERIENCE_PRAVID,
    payload: payload,
  };
};
export const claimAndPurchasePlan = (payload: boolean) => {
  return {
    type: actionTypes.PURCHASE_AND_CLAIM_REWARD,
    payload: payload,
  };
};
export const updateTourModuleWise = (
  payload: Record<string, string | boolean>,
  name?: string
) => {
  return {
    type: actionTypes.UPDATE_TOUR_SHOW_REQUEST,
    payload: { payload, name },
  };
};
export const getCallStatus = (payload: Record<string, string | boolean>) => {
  return {
    type: actionTypes.GET_CALL_STATUS_REQUEST,
    payload: payload,
  };
};
export const openFilterswithoutTransition = (payload: boolean) => {
  return {
    type: actionTypes.HIDE_FILTER_TRANSITION,
    payload: payload,
  };
};
export const pushOnTranscript = (payload: boolean) => {
  return {
    type: actionTypes.PUSH_ON_TRANSCRIPT,
    payload: payload,
  };
};
export const resetGetCallStatus = () => {
  return {
    type: actionTypes.GET_CALL_STATUS_RESET,
  };
};
export const getLanguageExperienceNow = () => {
  return {
    type: actionTypes.GET_LANGUAGE_EXPERIENCE_REQUEST,
  };
};
export const getFlowExperienceNow = () => {
  return {
    type: actionTypes.GET_FLOW_EXPERIENCE_REQUEST,
  };
};
export const setStartTourMain = (payload: boolean) => {
  return {
    type: actionTypes.START_TOUR,
    payload: payload,
  };
};

export const setComeFromExperienceNow = (payload: boolean) => {
  return {
    type: actionTypes.SET_COME_FROM_EXPERIENCE_NOW,
    payload: payload,
  };
};
