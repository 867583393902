export const getSideBarModules = (
  sidebarModuleData: Array<Record<string, any>>,
  activePage: string,
  activeLoggerPage: string
) => {
  activePage = activePage.toLowerCase();
  activeLoggerPage = activeLoggerPage.toLowerCase();
  let requiredData: Array<Record<string, any>>;

  if (!sidebarModuleData) {
    throw new Error(`getSidebarModules.ts: Invalid sidebarModuleData`);
  }

  if (activePage === "analytics") {
    requiredData = sidebarModuleData.filter((item) =>
      item.screens?.includes(activePage)
    );
  } else if (activePage === "logger") {
    if (activeLoggerPage === "report" || activeLoggerPage === "transcript") {
      requiredData = sidebarModuleData.filter((item) =>
        item.screens?.includes(activeLoggerPage)
      );
    } else {
      throw new Error(
        `getSidebarModules.ts:- Invalid activeLoggerPage: ${activeLoggerPage}`
      );
    }
  } else {
    throw new Error(`getSidebarModules.ts:- Invalid activePage: ${activePage}`);
  }

  return requiredData.sort((a, b) => a?.position - b?.position);
};
