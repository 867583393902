import React, { ChangeEvent, useEffect, useState } from "react";
import Modal from "../../../generic/modal/Modal";
import InputField from "../../../generic/inputField/InputField";
import { FilledInput, FormHelperText, InputAdornment } from "@mui/material";
import styles from "./addPhoneNumberModal.module.scss";
import Button from "../../../generic/button/Button";
import { useDispatch } from "react-redux";
import {
  AddPhoneNumber,
  setClearAddPhoneNumberError,
} from "../../../../redux/exclusionsList/actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import Icon from "../../uiKit/iconComponent/Icon";
import { crossIcon } from "../../../../theme/assets/svg";

interface AddPhoneNumberModalProps {
  isAddNumberModalVisible: boolean;
  handleAddPhoneNumberModal: () => void;
}

const AddPhoneNumberModal = ({
  isAddNumberModalVisible,
  handleAddPhoneNumberModal,
}: AddPhoneNumberModalProps) => {
  // USESTATE SECTION
  const [phoneNumberValue, setPhoneNumberValue] = useState("");

  // USESELECTOR SECTION
  const exclusionsListType: any = useSelector((store: RootState) => {
    return store?.exclusionsListReducer?.exclusionsListType;
  });

  const accountId = useSelector(
    (store: any) => store.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const accountDetails = useSelector(
    (store: RootState) => store?.schedulerReducer?.accountInfoDetails?.id
  );

  const errorAddingPhoneNumber = useSelector((store: RootState) => {
    return store?.exclusionsListReducer?.error;
  });

  const isPhoneNumberAdditionSuccessful = useSelector(
    (store: RootState) =>
      store?.exclusionsListReducer?.isPhoneNumberAdditionSuccessful
  );

  // USEEFFECT SECTION
  useEffect(() => {
    if (isPhoneNumberAdditionSuccessful && isAddNumberModalVisible) {
      handleAddPhoneNumberModal();
    }
  }, [isPhoneNumberAdditionSuccessful]);

  useEffect(() => {
    if (isAddNumberModalVisible) dispatch(setClearAddPhoneNumberError());
  }, [isAddNumberModalVisible]);

  // HANDLERS
  const handleInputPhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = e.target.value;
    if (!/^\d+$/.test(phoneNumber) && phoneNumber.length) return;
    if (phoneNumber.length > 10) return;
    setPhoneNumberValue(phoneNumber);
  };

  const handleSaveBtnClick = () => {
    if (!phoneNumberValue.length) return;
    const data: any = {
      phoneNumber: phoneNumberValue,
    };
    if (exclusionsListType === "Global") data["isGlobal"] = true;
    else data["accountId"] = accountDetails;
    dispatch(AddPhoneNumber(data));
    setPhoneNumberValue("");
  };

  const handleAddPhoneNumberModalClose = () => {
    setPhoneNumberValue("");
    handleAddPhoneNumberModal();
  };

  const handleCrossBtnClick = () => {
    handleAddPhoneNumberModal();
  };

  // MISC
  const dispatch = useDispatch();

  return (
    <Modal
      shown={isAddNumberModalVisible}
      close={handleAddPhoneNumberModalClose}
    >
      <div className={styles.modal}>
        <Icon
          img_src={crossIcon}
          extraClass={styles.crossArrowIcon}
          onClick={handleCrossBtnClick}
        />
        <p className={styles.modalHeader}>
          {`${exclusionsListType === "Global" ? "Global" : ""} Exclusions`}
        </p>
        <p className={styles.modalText}>
          Enter phone number to exclude from all of our communications
          campaigns.
        </p>
        <div className={styles.inputSectionForAddNumberModal}>
          <FormHelperText className={styles.label}>Phone Number</FormHelperText>
          <div className={styles.sectionForAddNumberInput}>
            <FilledInput
              id={styles.phoneNumberText}
              startAdornment={
                <InputAdornment position="start">+91 </InputAdornment>
              }
              aria-describedby="phoneNumberInputHelperText"
              inputProps={{
                "aria-label": "Phone Number",
              }}
              autoFocus
              onChange={handleInputPhoneNumber}
              placeholder={"00000 00000"}
              type={"string"}
              value={phoneNumberValue}
              disableUnderline
              className={styles.phoneNumberInput}
            />
            <Button
              text="Save"
              extraClass={`${styles.saveBtn} ${
                !phoneNumberValue.length ? styles.disabledBtn : ""
              }`}
              id="save_btn"
              onClick={handleSaveBtnClick}
            />
          </div>
          {typeof errorAddingPhoneNumber === "string" &&
          errorAddingPhoneNumber.length ? (
            <FormHelperText
              id="phoneNumberInputHelperText"
              className={styles.errorText}
            >
              {errorAddingPhoneNumber}
            </FormHelperText>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default AddPhoneNumberModal;
