import React, { useEffect } from "react";
import "./SummaryAD.scss";
import FieldsController from "./fieldsController/FieldsController";
import { RootState } from "../../../../redux";
import { useSelector, useDispatch } from "react-redux";
import { getSummaryDataEditableFunction } from "./summary.function";
import { setSummaryDataEditable } from "../../../../redux/agentDesktop/callLogger/action";
import Button from "../../generic/fields/button/Button";
import { editIcon } from "../../../../theme/assets/agentDesktopSvg/";
import screenType from "../../../../constants/screenType";

interface props {
  isSaving: boolean;
  updateSuccess: boolean;
  handleSaveClick: () => void;
  handleEditClick: () => void;
  error: string;
  setError: (value: string) => void;
  isNotEditable?: boolean;
}

export default function Summary(props: props) {
  /* Redux hooks */
  const summarySchema = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.summarySchemaModified
  );
  const summaryData = useSelector(
    (store: any) => store.agentDesktopReducer.callLogger.summary?.[0]
  );
  const summaryDataChat = useSelector(
    (store: any) => store.agentDesktopReducer.chatLogger.summary?.[0]
  );
  const summaryDataEditable = useSelector(
    (store: any) => store.agentDesktopReducer.callLogger.summaryDataEditable
  );
  const activePage = useSelector(
    (store: RootState) => store?.loginReducer?.isActivePageType
  );
  const summaryLoadingCall = useSelector(
    (store: RootState) => store.agentDesktopReducer.callLogger?.summaryLoading
  );
  const summaryLoadingChat = useSelector(
    (store: RootState) => store.agentDesktopReducer.chatLogger?.summaryLoading
  );
  const communicationType = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.communicationType
  );
  const dispatch = useDispatch();

  /* useStates ---------- */

  /* useEffect --------- */
  useEffect(() => {
    let summaryDataEditable: any;
    if (activePage === screenType.callLoggerAgent.name) {
      summaryDataEditable = getSummaryDataEditableFunction(
        summarySchema,
        summaryData
      );
    } else if (activePage === screenType.chatLoggerAgent.name) {
      summaryDataEditable = getSummaryDataEditableFunction(
        summarySchema,
        summaryDataChat
      );
    }

    dispatch(setSummaryDataEditable(summaryDataEditable));
  }, [summaryData, summarySchema, summaryDataChat]);

  /* handlers -------- */
  const handleSummaryFieldInput = (
    value: string | Date | null,
    key?: string
  ) => {
    if (key === "agentFeedback" && (value as string).length <= 1024) {
      props.setError("");
    }
    if (key) {
      const newData = { ...summaryDataEditable, [key]: value };
      dispatch(setSummaryDataEditable(newData));
    }
  };

  if (summaryDataEditable && Object.keys(summaryDataEditable)?.length <= 0)
    return <></>;
  return (
    <div
      className={`agent-summary ${
        communicationType === "call" ? "disablePointerEventUniversaljp" : ""
      }`}
    >
      {summarySchema.map((item: any, index: number) => {
        return (
          <div className="agent-summary__fields" key={index}>
            <FieldsController
              type={item.type}
              data={item.data}
              summaryData={summaryDataEditable}
              handleOnChange={handleSummaryFieldInput}
              icon={
                props.updateSuccess && item.data.headerName === "Summary"
                  ? editIcon
                  : ""
              }
              handleEditClick={props.handleEditClick}
              disableInputFields={props.updateSuccess}
              summaryLoading={summaryLoadingCall || summaryLoadingChat}
              // maxLength={1024}
            />
          </div>
        );
      })}
      {props.error ? (
        <div className="error-div">{props.error}</div>
      ) : (
        <Button
          text={props.updateSuccess ? "Saved" : "Save"}
          onClick={props.handleSaveClick}
          extraClass={`agent-button__extra-class ${
            props.updateSuccess && "success-btn"
          } ${props.updateSuccess ? "disablePointerEventWithoutOpacity" : ""} `}
        />
      )}
    </div>
  );
}
