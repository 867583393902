import React from "react";

import styles from "./HorizontalProgress.module.scss";
/**
 * @example
 *   <div style={{ width: "400px" }}>
        <HorizontallProgress
          title="After PTP Received"
          left={{ title: "paid on time", value: 90 }}
          right={{ title: "not paid on time", value: 90 }}
        />
      </div>
 * @param props 
 * @returns 
 */
export default function HorizontallProgress(props: {
  title: string;
  left: { title: string; value: number };
  right: { title: string; value: number };
}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.middle}>
        <div className={styles.left}>
          {middlePart(
            props.left.title,
            props.left.value,
            props.left.value + props.right.value,
            styles.leftClass
          )}
        </div>
        <div className={styles.break} />
        <div className={styles.right}>
          {middlePart(
            props.right.title,
            props.right.value,
            props.left.value + props.right.value,
            styles.rightClass
          )}
        </div>
      </div>
      <div className={styles.progress}>
        <div
          className={styles.leftProgress}
          style={{
            width: `${Math.round(
              (props.left.value * 100) / (props.left.value + props.right.value)
            )}%`,
          }}
        ></div>
        <div
          className={styles.rightProgress}
          style={{
            width: `${Math.round(
              (props.right.value * 100) / (props.left.value + props.right.value)
            )}%`,
          }}
        ></div>
      </div>
    </div>
  );
}

const middlePart = (
  title: string,
  value: number,
  total: number,
  extraClass: string
) => {
  return (
    <div className={`${styles.middlePart} ${extraClass}`}>
      <div className={styles.mTitle}>{title}</div>
      <div className={styles.progressText}>
        <span className={styles.percentage}>
          {" "}
          {Math.round((value * 100) / total) + "%"}
        </span>
        <span className={styles.value}>{value}</span>
      </div>
    </div>
  );
};
