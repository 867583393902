export default {
  SET_SELECTED_TAB: "SET_SELECTED_TAB",
  SET_TAB: "SET_TAB",
  SET_BREADCRUM: "SET_BREADCRUM",
  GET_SCHEDULER_DATA: "GET_SCHEDULER_DATA",
  SET_DIAL_DATA: "SET_DIAL_DATA",
  SET_CALLING_DATA: "SET_CALLING_DATA",
  SET_DISPOSITION_DATA: "SET_DISPOSITION_DATA",
  SET_SHUFFLED_DATA: "SET_SHUFFLED_DATA",
  SET_SELECTED_DISPOSITION_DATA: "SET_SELECTED_DISPOSITION_DATA",
  SET_SELECTED_CALLING_DATA: "SET_SELECTED_CALLING_DATA",
  SET_STATIC_DISPOSITION_DATA: "SET_STATIC_DISPOSITION_DATA",
  SET_SHUFFLED_DISPOSITION_DATA: "SET_SHUFFLED_DISPOSITION_DATA",
  SET_SHUFFLED_CONNECTED_DISPOSITION_DATA:
    "SET_SHUFFLED_CONNECTED_DISPOSITION_DATA",
  SET_SEPARATE_SELECTED_DATA: "SET_SEPARATE_SELECTED_DATA",
  SET_UPDATE_DATA: "SET_UPDATE_DATA",
  SET_SHOW_CALLING_LIST: "SET_SHOW_CALLING_LIST",
  SET_CALLING_DAY: "SET_CALLING_DAY",
  SET_SELECTED_DATA_RETRY_TIME: "SET_SELECTED_DATA_RETRY_TIME",
  SET_SAME_BTN: "SET_SAME_BTN",
  SET_EMI_VALUE: "SET_EMI_VALUE",
  SET_SHUFFLED_CONNECTED_SUCCEDING_DATA:
    "SET_SHUFFLED_CONNECTED_SUCCEDING_DATA",
  SET_SHUFFLED_NOTCONNECTED_SUCCEDING_DATA:
    "SET_SHUFFLED_NOTCONNECTED_SUCCEDING_DATA",
  SET_SELECTED_DISPOSITION_DATA_PARENT: "SET_SELECTED_DISPOSITION_DATA_PARENT",
  SET_SELECTED_DISPOSITION_DATA_CHILD: "SET_SELECTED_DISPOSITION_DATA_CHILD",
  SET_BREADCRUM_DATA: "SET_BREADCRUM_DATA",
  SET_PHASE: "SET_PHASE",
  SET_API_CSV_LIST: "SET_API_CSV_LIST",
  CLEAR_BREADCRUM_DATA: "CLEAR_BREADCRUM_DATA",
};
