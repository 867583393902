import React from "react";
import {
  sortingDescIcon,
  summaryIcon,
  playIcon,
  linkIcon,
  replayIcon,
  noSortingIcon,
  sortingAscIcon,
} from "../../../theme/assets/genericSvg";
import { columnInterface } from "./type";
import { getValueByReferenceKey } from "../../../utils";
import { pauseIcon } from "../../../theme/assets/svg";
import { useDispatch,useSelector} from "react-redux";
import { INTEGRATION_API, config } from "../../../services/ApiRoutes";
import axios from "axios";
import { getLoggerReportDataAPI } from "../../../redux/logger/report/actions";
import {getAudioURLToPlay,clearAudioURL} from "../../../redux/logger/loggerState/action";

interface argInterface {
  limit: number;
  columnSchema: Array<columnInterface>;
  handleAudioClick: (
    e: React.MouseEvent<HTMLElement>,
    url: any,
    tab: string
  ) => void;
  handleSummaryClick: any;
  handleSortClick: any;
  tableSortingColumn: Record<string, "desc" | "asc">;
  currentAudioState: {
    currentTab: string | undefined;
    isPlaying: boolean;
  };
}


export const tableConstant = ({
  limit,
  columnSchema,
  handleAudioClick,
  handleSummaryClick,
  handleSortClick,
  currentAudioState,
  tableSortingColumn,

}: argInterface) => {
  const sortingColumn = Object.keys(tableSortingColumn)?.[0];

  const columnsToShow: Array<any> = [];
  let totalColumnData = [...columnSchema];



  totalColumnData = totalColumnData.filter((item) => item.isActive);
  // totalColumnData = totalColumnData.sort((a, b) => a.position - b.position);  // already sorting in getDataFromSchema function

  const dispatch = useDispatch();
  const selectedSessionAudioURL = useSelector((state: any) => {
    return state.loggerReducer?.loggerState?.selectedSessionAudioURL.url;
  });
  console.log("SelectedAudioUrl tableConstant" , selectedSessionAudioURL);
  const getAudiourl = async (e: any, rowData: any, index: any) => {
    // try {
    //   const createdAtDate = new Date(rowData.information.created_at);
    //   const year = createdAtDate.getFullYear();
    //   const month = String(createdAtDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
    //   const day = String(createdAtDate.getDate()).padStart(2, '0');
    //   const formattedCreatedAt = `${year}-${month}-${day}`;
    //   console.log("formattedCreatedAt", formattedCreatedAt);
    //   const response = await axios.get<any>(`https://${process.env.REACT_APP_BASE_URL}/preprocessing/getAudioSession/${rowData.information.clusterId}/${formattedCreatedAt}/${rowData.information.sessionId}`);
    //   const audioUrl = response.data?.url;
    //   if (audioUrl) {
    //     handleAudioClick(e, audioUrl, index.toString());
    //   } else {
    //     console.error("Audio URL not found in response data");
    //   }
    // } catch (error) {
    //   console.error("Error fetching audio URL:", error);
    // }

      const createdAtDate = new Date(rowData.information.created_at);
      const year = createdAtDate.getFullYear();
      const month = String(createdAtDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
      const day = String(createdAtDate.getDate()).padStart(2, '0');
      const formattedCreatedAt = `${year}-${month}-${day}`;
      console.log("formattedCreatedAt", formattedCreatedAt);
    
    const audioUrl = `${process.env.REACT_APP_AZURE_BLOB_URL}${formattedCreatedAt}/${rowData.information.sessionId}.mp3${process.env.REACT_APP_CALL_RECORDING_AZURE_TOKEN}`;
    handleAudioClick(e, audioUrl, index.toString());

  };



  totalColumnData.forEach((item, currentRow) => {
    if (item.kpiDetails.originalName === "Sr. No") {
      const obj = {
        title: item.customName,
        render: (rowData: any, key: string, index: number, pageNo: number) => (
          <span>{(pageNo - 1) * limit + index + 1}</span>
        ),
      };
      columnsToShow.push(obj);
    } else if (
      item.kpiDetails.originalName === "Call Summary" ||
      item.kpiDetails.originalName === "Chat Summary"
    ) {
      const obj = {
        title: item.customName,
        key: item.kpiDetails.referancKeyName,
        render: (rowData: any, key: string) => (
          <span style={{ display: "flex", justifyContent: "center" }}>
            <img
              style={{ cursor: "pointer" }}
              src={summaryIcon}
              alt="Summary Icon"
              onClick={() =>
                handleSummaryClick(getValueByReferenceKey(rowData, key))
                
              }
              
            />
          </span>
        ),
      };
      
      columnsToShow.push(obj);
    } else if (item.kpiDetails.originalName === "Audio") {
      const obj = {
        title: item.customName,
        key: item.kpiDetails.referancKeyName,
        render: (rowData: any, key: string, index: number) => (
          <span
            data-row={index.toString()}
            style={{ cursor: "pointer" }}
            onClick={(e) =>
              // handleAudioClick(
              //   e,
              //   getValueByReferenceKey(rowData, key),
              //   index.toString()
              // )
              getAudiourl(
                e, rowData,
                index.toString()
              )
            }
          >
            {currentAudioState.currentTab === index.toString() ? (
              currentAudioState.isPlaying === true ? (
                <img
                  src={pauseIcon}
                  style={{ width: "11px" }}
                  alt="play icon"
                  data-type="play"
                />
              ) : (
                <img
                  src={playIcon}
                  style={{ width: "11px" }}
                  alt="play icon"
                  data-type="play"
                />
              )
            ) : (
              <img
                src={playIcon}
                style={{ width: "11px" }}
                alt="play icon"
                data-type="play"
              />
            )}

            <img
              src={replayIcon}
              style={{ width: "16px" }}
              alt="replay icon"
              data-type="replay"
            />
            <img src={linkIcon} alt="link icon" data-type="copy-link" />
          </span>
        ),
      };
      columnsToShow.push(obj);
    } else {
      const obj = {
        title: (
          <span>
            {item.kpiDetails.isSortBtn && (
              <img
                src={
                  sortingColumn === item.kpiDetails.referancKeyName
                    ? tableSortingColumn?.[sortingColumn] === "asc"
                      ? sortingAscIcon
                      : sortingDescIcon
                    : noSortingIcon
                }
                style={{ marginRight: "8px", cursor: "pointer" }}
                onClick={handleSortClick}
                data-reference-key={item.kpiDetails.referancKeyName}
                alt="sorting Decreasing Icon"
              />
            )}
            <span>{item.customName}</span>
          </span>
        ),
        key: item.kpiDetails.referancKeyName,
        render: (rowData: any, key: string) => (
          <span>{getValueByReferenceKey(rowData, key)}</span>
        ),
      };
      columnsToShow.push(obj);
    }
  });

  return columnsToShow;
};
