import { takeLatest } from "redux-saga/effects";
import actionTypes from "../trueCaller.actionTypes";
import {
  uploadTrueCallerProfilePicAPIWorker,
  deleteTrueCallerProfilePicAPIWorker,
  getAllCategoriesDataAPIWorker,
  // createTrueCallerAPIWorker,
  // updateTrueCallerAPIWorker,
} from "./trueCaller.saga";

export default function* trueCallerDataWatcher(): any {
  yield takeLatest(
    actionTypes.GET_ALL_CATEGORIES_REQUEST,
    getAllCategoriesDataAPIWorker
  );

  yield takeLatest(
    actionTypes.UPDATE_TRUECALLER_PROFILE_PIC_REQUEST,
    uploadTrueCallerProfilePicAPIWorker
  );

  // yield takeLatest(
  //   actionTypes.CREATE_TRUECALLER_DATA_REQUEST,
  //   createTrueCallerAPIWorker
  // );

  // yield takeLatest(
  //   actionTypes.UPDATE_TRUECALLER_DATA_REQUEST,
  //   updateTrueCallerAPIWorker
  // );

  yield takeLatest(
    actionTypes.DELETE_TRUECALLER_PROFILE_PIC_REQUEST,
    deleteTrueCallerProfilePicAPIWorker
  );
}
