import React, { useState } from "react";
import styles from "./CrmHeaderFull.module.scss";
import CrmHeader from "../crmHeader/CrmHeader";
import CrmCustomerTableScreen from "../crmCustomerTable/CrmCustomerTableScreen";
import CrmAnalytics from "../../../../screens/crm/crmAnalytics/CRMAnalytics";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { useDispatch } from "react-redux";
import { setScreenType } from "../../../../redux/crm/action/actionCreators";

export default function CrmHeaderFull() {
  const screen = useSelector((state: RootState) => state.crmReducer.screenType);
  const dispatch = useDispatch();
  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.overallcustomer_div}>
          Overall Customers: 129540
        </div>
        <CrmHeader
          setDataOut={(data) => dispatch(setScreenType(data))}
          options={["Analytics", "Customer Report"]}
          defaultSelected={screen}
        />
        <div className={styles.date_div}>2 Jan 2022 - 10 Feb 2022</div>
      </div>
      {screen == "Analytics" ? <CrmAnalytics /> : <CrmCustomerTableScreen />}
    </div>
  );
}
