import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { INTEGRATION_API, config } from "../../../../services/ApiRoutes";
import actionTypes from "../sms.actionTypes";
import integrationActionTypes from "../../allIntegrations/allIntegrations.actionTypes";
import { store } from "../../../rootStore";

export function* setConnectedExistingAccountAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_BODY,
        `${INTEGRATION_API.CONNECT_EXISTING_ACCOUNT_SMS}`,
        action.payload
      );
      const getResult = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_ALL_INTEGRATIONS}`,
        {
          accountId: action.payload.accountId,
        }
      );
      toast.success(result.data.data.message);
      yield put({
        type: actionTypes.SET_CONNECT_EXISTING_ACCOUNT_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: integrationActionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS,
        payload: getResult?.data.data,
      });
      const apiResponse =
        store.getState().integrationReducer?.allIntegrationsReducer
          ?.selectedIntegrationData;
      const filteredData: any = getResult?.data.data?.filter(
        (item: any) => item.integrationName === apiResponse?.integrationName
      );
      yield put({
        type: integrationActionTypes.SET_SELECTED_INTEGRATION_DATA,
        payload: {
          ...filteredData[0],
        },
      });
    }
  } catch (error: any) {
    const errorMessage = error?.response?.data?.error;
    yield put({
      type: actionTypes.SET_CONNECT_EXISTING_ACCOUNT_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}
export function* setNewAccountAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${INTEGRATION_API.CREATE_SMS_INTEGRATION}`,
        action.payload,
        {
          "content-type": "multipart/form-data",
        }
      );
      toast.success("New Account is Created Successfully");
      const getResult = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_ALL_INTEGRATIONS}`,
        {
          accountId: action.payload.accountId,
        }
      );
      yield put({
        type: actionTypes.SET_NEW_ACCOUNT_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: integrationActionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS,
        payload: getResult?.data.data,
      });
      const apiResponse =
        store.getState().integrationReducer?.allIntegrationsReducer
          ?.selectedIntegrationData;
      const filteredData: any = getResult?.data.data?.filter(
        (item: any) => item.integrationName === apiResponse?.integrationName
      );
      yield put({
        type: integrationActionTypes.SET_SELECTED_INTEGRATION_DATA,
        payload: {
          ...filteredData[0],
        },
      });
    }
  } catch (error: any) {
    const errorMessage = error?.response?.data?.error;
    toast.error(errorMessage);
    yield put({
      type: actionTypes.SET_NEW_ACCOUNT_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}
export function* updateNewAccountAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${INTEGRATION_API.UPDATE_NEW_SMS_INTEGRATION}`,
        action.payload,
        {
          "content-type": "multipart/form-data",
        }
      );

      const getResult = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_ALL_INTEGRATIONS}`,
        {
          accountId: action.payload.accountId,
        }
      );
      toast.success("New Account is Updated Successfully");
      yield put({
        type: actionTypes.UPDATE_NEW_ACCOUNT_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: integrationActionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS,
        payload: getResult?.data.data,
      });
      const apiResponse =
        store.getState().integrationReducer?.allIntegrationsReducer
          ?.selectedIntegrationData;
      const filteredData: any = getResult?.data.data?.filter(
        (item: any) => item.integrationName === apiResponse?.integrationName
      );
      yield put({
        type: integrationActionTypes.SET_SELECTED_INTEGRATION_DATA,
        payload: {
          ...filteredData[0],
        },
      });
    }
  } catch (error: any) {
    const errorMessage = error?.response?.data?.error;
    toast.error(errorMessage);
    yield put({
      type: actionTypes.UPDATE_NEW_ACCOUNT_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}
