import React, { useState, useEffect } from "react";
import { validateInputMeta } from "../../../../../utils/validators/validators";
import "./TextAreaInput.scss";

interface props {
  // eslint-disable-next-line no-unused-vars
  handleMessage: (value: string, key?: string) => void;
  defaultMessage: string;
  contSmall?: boolean;
  extraClass?: string;
  placeholder?: string;
  value?: string;
  objectKey?: string;
  disableField?: boolean;
  maxLength?: number;
}

const TextAreaInput = (props: props) => {
  const [inputMessage, setInputMessage] = useState<string>();
  const handleChangeInputMessage = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (!validateInputMeta(e.target.value)) return;
    const temp = e.target.value;
    setInputMessage(temp);
    props.handleMessage(temp, props.objectKey);
  };

  useEffect(() => {
    if (props?.defaultMessage) {
      setInputMessage(props?.defaultMessage);
    } else setInputMessage("");
  }, [props?.defaultMessage]);
  // console.log(props.handleMessage);

  return (
    <>
      <div
        className={`textAreaWrapper-agent ${
          props.disableField ? "disablePointerEventWithoutOpacity" : ""
        }`}
      >
        <div className="textAreaInputTopDiv">
          {props.defaultMessage && (
            <span className="single-select-dropdown__label">
              {props.placeholder}
            </span>
          )}

          <div
            className={`fdFormTextareajpp-agent ${
              props?.contSmall && props?.contSmall === true ? "smallify" : ""
            } `}
          >
            <textarea
              className={props.extraClass ? props.extraClass : ""}
              value={inputMessage}
              rows={4}
              placeholder={props.placeholder ? props.placeholder : ""}
              onChange={handleChangeInputMessage}
              style={{
                width: "97%",
                border: "none",
                outline: "none",
                padding: "5px 14px 5px 14px",
                resize: "none",
                borderRadius: "7px",
              }}
              maxLength={props?.maxLength}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TextAreaInput;
