// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lDB9zAbYE_Lkf6G2F9jx{font-family:Roboto;font-size:12px;font-weight:400;line-height:14px;padding-top:.6em;text-align:left;color:#000;padding-bottom:5px}.aZnbJBJm7OxTlyiCOZtN{margin-right:10px}.Pu24cD1_597OV3d8scvx{margin-right:10px;background-color:#f6f6f6;padding:1px;border-radius:4px}.XkYVBGs4faFqoy_cem4K{padding:3.5px !important}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/campaign_V2.0/collectionAgentConfig/chipDropdown/ChipDropdown.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,gBAAA,CACA,eAAA,CACA,UAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CAEF,sBACE,iBAAA,CACA,wBAAA,CACA,WAAA,CACA,iBAAA,CAGF,sBACE,wBAAA","sourcesContent":[".label {\n  font-family: Roboto;\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 14px;\n  padding-top: 0.6em;\n  text-align: left;\n  color: black;\n  padding-bottom: 5px;\n}\n\n.img_dropdown_icon {\n  margin-right: 10px;\n}\n.option_img {\n  margin-right: 10px;\n  background-color: #f6f6f6;\n  padding: 1px;\n  border-radius: 4px;\n}\n\n.extraPadding {\n  padding: 3.5px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `lDB9zAbYE_Lkf6G2F9jx`,
	"img_dropdown_icon": `aZnbJBJm7OxTlyiCOZtN`,
	"option_img": `Pu24cD1_597OV3d8scvx`,
	"extraPadding": `XkYVBGs4faFqoy_cem4K`
};
export default ___CSS_LOADER_EXPORT___;
