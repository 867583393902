import React, { useEffect, useState } from "react";

import styles from "./StatusDropdown.module.scss";
import { downArrow } from "../../../../theme/assets/agentDesktopSvg";
import {setSelectedStatus}from "../../../../redux/agentDesktop/BaseScreenAD/action/baseScreenAD.action";
// import {setDashboardCardDetails} from "../../../../redux/agentDesktop/dashboard/actions/dashboard.actions"
import option from "./config";
import { status } from "./types";
import { useDispatch,useSelector } from "react-redux";
import moment from "moment";

export interface props {
  /**
 - | "Unavailable"
 - | "Available"
 - | "On Lunch Break"
 - | "In a Training"
 - | "In a Meeting"
 - | "On Call";
   * @param e
   * @returns status
   */
  onSelect?: (e: status) => any;
  selected: status;
  options?: {
    status: status;
    color: string;
  }[];
}

import SingleDropdown from "../../generic/fields/singleSelect/SingleSelect";
import axios from "axios";
import { RootState } from "../../../../redux";
import { AGENT_DESKTOP_API } from "../../../../services/ApiRoutes";
export default function StatusDropdown(props: props) {
  const dispatch= useDispatch()
  const [defaultOption, setDefaultOption] = useState(
    props.options ? props.options : option
  );
  const [selectedOption, setSelectedOption] = useState<{
    status: status | "";
    color: string;
  }>({ status: "", color: "" });

  const storedStatusData= useSelector((store:RootState)=>store.agentDesktopReducer.baseScreenAD.status)
  const agentId = useSelector((store:RootState)=>store.loginReducer.userLoginInfo.userDetail["_id"])
  const accountId = useSelector((store:RootState)=>store.loginReducer.userLoginInfo.userDetail.accountDetails[0]["id"])
  const dashboardDetails = useSelector((store:RootState)=> store.agentDesktopReducer.dashboardReducer.dashboardCardDetails.card_details)

  useEffect(() => {

  if((storedStatusData?.currentState?.length>0 )&& (storedStatusData?.currentState !== "Available") ){
    const status=defaultOption.find((e) => e.status === storedStatusData?.currentState) || {
      status: "",
      color: ""
    }
    setSelectedOption(status);
    dispatch(setSelectedStatus({"previousState": storedStatusData?.previousState ,"currentState":storedStatusData?.currentState}))
    }else{
      const status=defaultOption.find((e) => e.status === props.selected) || {
        status: "",
        color: ""
      }
      setSelectedOption(status);
      dispatch(setSelectedStatus({"previousState": storedStatusData?.currentState ?storedStatusData?.currentState:"Login" ,"currentState":status?.status}))
    }
    }, [props.selected, props.options]);
  function getConvertedTime(date: number){
    return moment.utc(date * 1000).format("HH")+"h "+
    moment.utc(date * 1000).format("mm")+"m "+
    moment.utc(date * 1000).format("ss")+"s"
  }

  useEffect(()=>{
    if(storedStatusData?.currentState?.length>0){
      const url=AGENT_DESKTOP_API.UPDATE_STATUS

      const data={...storedStatusData,agentId:agentId, accountId:accountId}
       axios
        .post(url,data)
        .then((res: any) => {
          const agentData=res?.data?.data
          const loginSessionTime= getConvertedTime(agentData.loginduration)
          const availableDuration= getConvertedTime(agentData.availableDuration)
          const logDetails= {...dashboardDetails}
          logDetails.agentLoginSessionTime= loginSessionTime
          logDetails.agentAvailableDuration=availableDuration
          const data={card_details:logDetails}
          // dispatch(setDashboardCardDetails({dashboardCardDetails:{...data}}))
        })

    }
  },[storedStatusData])


  return (
    <>
      <div className={styles.wrapper}>
        <SingleDropdown
          data={defaultOption.flatMap((e) => {
            if (e.status === "On Call") {
              return [];
            }
            return e.status;
          })}
          handleChange={(e: any) => {
            const status=  defaultOption.find((eachoption) => eachoption.status === e) || {
              status: "",
              color: ""
            }
            console.log(status,"stATS")
             dispatch(setSelectedStatus({"previousState": storedStatusData?.currentState ?storedStatusData?.currentState:"Login" ,"currentState":status?.status}))
            setSelectedOption(status);
            props.onSelect && props.onSelect(e);
          }}
          selectedItem={props.selected}
          title={props.selected}
          hideSearchBox={true}
          extraWrapperClass={styles.extraWrapper}
          extraEachDropdownClass={styles.extraDropdown}
          extraDisplayElement={
            <div
              className={styles.dropdown}
              style={{
                borderColor: selectedOption.color,
              }}
            >
              <span
                className={styles.dot}
                style={{ backgroundColor: selectedOption.color }}
              ></span>
              <span
                className={styles.title}
                style={{ color: selectedOption.color }}
              >
                {selectedOption.status}
              </span>
              {props.selected !== "On Call" && (
                <img src={downArrow} alt="arrow" />
              )}
            </div>
          }
        ></SingleDropdown>
      </div>
    </>
  );
}
