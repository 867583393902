import { Reducer } from "redux";
import actionType from "../progressBar.actionTypes";
import { ProgressBarInterface } from "../interface";

const initialState: ProgressBarInterface = {
  maxCompletedStep: 0,
  currentStep: 0,
};

const progressBarReducer: Reducer<ProgressBarInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionType.MAX_STEP_COMPLETE: {
      return { ...state, maxCompletedStep: action.payload };
    }
    case actionType.STORE_CURRENT_STEP: {
      return { ...state, currentStep: action.payload };
    }

    case actionType.CLEAR_PROGRESS_BAR:
      return initialState;

    default: {
      return state;
    }
  }
};

export default progressBarReducer;
