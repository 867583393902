import actionTypes from "../security.actionTypes";

export interface stateInterface {
  securityDetails: any;
  isLoadingSecurityDetails: boolean;
  securityDetailsError: string;
}

const initialState: stateInterface = {
  securityDetails: {},
  isLoadingSecurityDetails: false,
  securityDetailsError: "",
};

export default function securityReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.SECURITY_UPDATE_DETAILS_API_CALL: {
      return {
        ...state,
        isLoadingSecurityDetails: true,
        // securityDetails: {},
        securityDetailsError: "",
      };
    }
    case actionTypes.SECURITY_UPDATE_DETAILS_API_SUCCESS: {
      return {
        ...state,
        isLoadingSecurityDetails: false,
        securityDetails: action.payload,
      };
    }
    case actionTypes.SECURITY_UPDATE_DETAILS_API_FAILURE: {
      return {
        ...state,
        isLoadingSecurityDetails: false,
        securityDetailsError: action.payload,
      };
    }
    case actionTypes.USER_LOGOUT: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
