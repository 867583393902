import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import "./LoginFields.css";
import {
  SERVER_URL,
  ONBOARDING_URL,
  RESETLINK_URL,
  PROJECT_URL,
} from "../../../../../services/ApiRoutes";
import ErrorMessage from "../../../../generic/errorMessage/ErrorMessage";
import usernameIcon from "../../../../../theme/assets/svg/login/usernameIcon.svg";
import passwordKeyIcon from "../../../../../theme/assets/svg/login/passwordKeyIcon.svg";
import Visiblity from "../../../../../theme/assets/svg/login/visibility.svg";
import Hidden from "../../../../../theme/assets/svg/login/hidden.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams } from "react-router-dom";
import { getAllFiltersOptionsForAccount } from "../../../../../redux/filters/actions";
import {
  mixpannelForgotPasswordCount,
  mixpannelLogin,
} from "../../../../../redux/mixpanel/actions";
import {
  getLeftSideModuleDetails,
  mapSubModulesToModulesFunc,
  mapUrlToModulesFunc,
} from "./LoginFields.functions";
import signUpStyles from "../../../../../screens/onBoarding/signUp/SignUp.module.scss";
import {
  setLeftSideModuleDetails,
  setHighlightedModule,
  setSubModuleMappingToModule,
  setUrlToModuleMapping,
  setDefaultSelectedModule,
  setLoginTime,
} from "../../../../../redux/baseScreen/leftMenu";
import {
  emailValidation,
  passwordValidation,
  userNameValidation,
  nameValidation,
} from "../../../../../utils/Validation";

import {
  setLoggedInUserInfo,
  setIsActivePageType,
  setIsPageType,
  setUserData,
  userLogoutAction,
} from "../../../../../redux/onboarding/login/actions";
import { schema } from "./schema";
import { sourceDetails } from "./sourceDetails";
import { Mixpanel } from "../../../../../utils/mixpanelSetup";
import { format } from "date-fns";
import {
  validateInputMeta,
  validateKeys,
} from "../../../../../utils/validators/validators";
import InputContainer from "../../../../generic/inputContainer/InputContainer";
import { setFreeCreditTimer } from "../../../../../redux/signUpFlow/signUp/action";
import secureLocalStorage from "react-secure-storage";
import clearCacheData from "../../../../../utils/clearCacheData";
import { ROLES } from "../../../../../constants";

const LoginFields = (props) => {
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [checkingCheckBox, setcheckingCheckBox] = useState(false);
  const navigation = useNavigate();
  let accountName = window.sessionStorage.getItem("accountName");
  accountName = accountName?.toString().toLowerCase();
  var typeOfUser = "";
  const [userCreditials, setUserCreditials] = useState({
    email: null,
    password: null,
    sendMeLinkEmail: null,
  });
  const [errorHandle, setErrorHandle] = useState({
    emailInvalid: null,
    passwordInvalid: null,
    allError: null,
  });

  const [toHandleLoginPageView, settoHandleLoginPageView] = useState({
    login: 0,
    forgetPassword: 0,
    forgetPasswordOne: 0,
  });
  const [showPassword, setShowPassword] = useState(false);
  /* other hooks ------- */
  const [searchParams, setSearchParams] = useSearchParams();
  //redux  Data
  const forgotPaswordCount = useSelector((state) => {
    return state?.mixpanelReducer?.forgotPasswordCount;
  });
  const mixpannellogInDetails = useSelector(
    (store) => store?.mixpanelReducer?.loginDetails
  );

  const agentId = useSelector(
    (store) => store.loginReducer.userLoginInfo.userDetail?.["_id"]
  );

  const refreshPage = () => {
    settoHandleLoginPageView((previousState) => {
      return {
        ...previousState,
        login: 0,
        forgetPassword: 0,
        forgetPasswordOne: 0,
      };
    });

    setErrorHandle((previousState) => {
      return {
        ...previousState,
        emailInvalid: null,
        passwordInvalid: null,
        allError: null,
      };
    });

    setUserCreditials((previousState) => {
      return {
        ...previousState,
        email: null,
        password: null,
        sendMeLinkEmail: null,
      };
    });
  };

  const history = useNavigate();
  const dispatch = useDispatch();
  const ttoken = sessionStorage.getItem("token");

  if (ttoken) {
    navigation("/");
  }

  /* useEffects ---------- */
  useEffect(() => {
    const password = searchParams.get("password");
    const email = searchParams.get("emailId");
    const linkExpired = searchParams.get("linkExpired");

    if (linkExpired) {
      toast.success("This Link has expired. Please Log In to continue.", {
        icon: false,
        closeButton: false,
      });
    }
    if (password && email) {
      setUserCreditials((prev) => {
        return { ...prev, email: email, password: password };
      });
    }
    history("/login", { replace: true });
    window.localStorage.clear();
    window.sessionStorage.clear();
    secureLocalStorage.clear();
    //To Clear Redux After Logout
    props.setToken(undefined);
    dispatch(
      userLogoutAction({
        userId: agentId,
        refreshToken: secureLocalStorage.getItem("refresh_token"),
      })
    );
  }, []);

  const handleChangeEmail = (e) => {
    const temp = e.target.value;
    if (!validateInputMeta(temp)) return;

    setUserCreditials((previousState) => {
      return {
        ...previousState,
        email: temp,
      };
    });
  };

  const handleChangeSendMeLinkEmail = (e) => {
    const emaill = e.target.value;
    if (!validateInputMeta(emaill)) return;
    setUserCreditials((previousState) => {
      return {
        ...previousState,
        sendMeLinkEmail: emaill,
      };
    });
  };

  const handleFocusOnEmail = () => {
    setErrorHandle((previousState) => {
      return {
        ...previousState,
        emailInvalid: null,
        allError: null,
      };
    });
  };

  const handleFocusOnPassword = () => {
    setErrorHandle((previousState) => {
      return {
        ...previousState,
        passwordInvalid: null,
        allError: null,
      };
    });
    const checkingEmailLP = emailValidation(userCreditials.email);
    if (!checkingEmailLP.isValid) {
      setErrorHandle((previousState) => {
        return {
          ...previousState,
          emailInvalid: "Please enter correct e-mail",
        };
      });
    }
  };

  const handleChangePassword = (e) => {
    const password = e.target.value;
    if (!validateInputMeta(password)) return;
    setUserCreditials((previousState) => {
      return {
        ...previousState,
        password: password,
      };
    });
  };

  const handleSubmit = async (e) => {
    let start = new Date().getTime();
    e.preventDefault();
    // const signInUrl = SERVER_URL + ONBOARDING_URL.SIGN_IN;
    const checkingEmailLP = emailValidation(userCreditials.email);
    const checkingPasswordLP = passwordValidation(userCreditials.password);
    if (!checkingEmailLP.isValid || !checkingPasswordLP.isValid) {
      setErrorHandle((previousState) => {
        return {
          ...previousState,
          emailInvalid: "Please enter correct email.",
          passwordInvalid: checkingPasswordLP.errors.message,
        };
      });
      return;
    }
    let data = {
      userName: userCreditials.email,
      password: userCreditials.password,
    };
    let headers = { "Content-Type": "application/json" };
    //console.log(signInUrl)
    setIsLoginLoading(true);
    const res = await axios
      .post(ONBOARDING_URL.LOGIN2, data, headers)
      .then((res) => {
        if (!res.data.data.userDetail.isActive) {
          setErrorHandle((previousState) => {
            return {
              ...previousState,
              allError: "Inactive User",
            };
          });
          return;
        }
        // console.log(res.data.data, "kkkk");
        if (
          res.data.data.userDetail.role === "Agent Desktop" ||
          res.data.data.userDetail.role === "Admin Desktop"
        ) {
          if (res.data.data.userDetail?.moduleDetails?.length) {
            res.data.data["moduleDetails"] =
              res.data.data.userDetail?.moduleDetails;
          }
        }

        //  //let tempresjj = { ...res.data.data, schema: schema };
        // dispatch(
        //   getAllFiltersOptionsForAccount(tempresjj?.accountDetails[0]?.name)
        // );
        // // tempresjj.schema = schema;
        // // //tempresjj["sourceDetails"] = sourceDetails;
        // //console.log("loginUserInfo", { ...res.data.data, schema: schema });
        const token = res.data.data.userSessionDetails.accessToken;
        const refreshToken = res.data.data.userSessionDetails.refreshToken;
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        secureLocalStorage.setItem("jwt_token", token);
        secureLocalStorage.setItem("refresh_token", refreshToken);
        secureLocalStorage.setItem("jwt_token_time", new Date());
        window.localStorage.setItem("userId", res.data.data.userDetail._id);

        dispatch(setLoggedInUserInfo(res.data.data));
        dispatch(setUserData(res.data.data.userDetail));
        if (res.data.data.accountDetails?.[0]?.experienceNowTimer) {
          console.warn("logg");
          dispatch(
            setFreeCreditTimer(
              res.data.data.accountDetails?.[0]?.experienceNowTimer
            )
          );
        }
        if (
          res.data.data.userDetail.role === "Agent Desktop" ||
          res.data.data.userDetail.role === "Admin Desktop"
        ) {
          const { dataModules, defaultSelected } = getLeftSideModuleDetails(
            _.cloneDeep(res.data.data.moduleDetails)
          );
          console.log("kkkk", res.data.data.moduleDetails, dataModules);
          dispatch(setLeftSideModuleDetails(dataModules));
          dispatch(setDefaultSelectedModule(defaultSelected));
          dispatch(setIsActivePageType(defaultSelected.name));
          dispatch(setHighlightedModule(defaultSelected.name));
          const subModuleMapping = mapSubModulesToModulesFunc(
            res.data.data.moduleDetails
          );
          dispatch(setSubModuleMappingToModule(subModuleMapping));
          const urlToModulesMapping = mapUrlToModulesFunc(
            res.data.data.moduleDetails
          );
          console.log(urlToModulesMapping);
          dispatch(setUrlToModuleMapping(urlToModulesMapping));
        }
        let curretTime = new Date();
        console.log(curretTime, "cccop");
        dispatch(setLoginTime(curretTime));

        let mixPanelUserobj = {
          $name: res.data.data.userDetail?.name,
          $id: res.data.data.userDetail?._id,
          $email: res.data.data.userDetail?.email,
        };

        Mixpanel.identify(res.data.data.userDetail?.accountDetails?.[0]?.id);
        Mixpanel.people.set({
          $name: res.data.data.userDetail?.name,
          $id: res.data.data.userDetail?._id,
          $email: res.data.data.userDetail?.email,
        });
        Mixpanel.track("Homepage | Login | Logged In", {
          "user Name": res.data.data.userDetail?.name,
          count: 1,
          Date: format(new Date(), "dd-MM-yyyy"),
        });
        const mixpannelLoginDetails = {
          startTime: new Date().getTime() / 1000,
          accountName: res.data.data.userDetail?.name,
        };
        dispatch(mixpannelLogin(mixpannelLoginDetails));

        let rrolr = res.data.data.userDetail.role;
        if (res.data.data.userDetail?.isProductPageShown === true) {
          history("/productPage");
        } else if (rrolr === "Camapign Manager") {
          // dispatch(setIsActivePageType("conversation logger"));
          dispatch(setIsPageType("conversation logger"));
          window.sessionStorage.setItem("isActive", "conversation logger");
          sessionStorage.setItem("pageType", "conversation logger");
          history("/calllogger");
        } else {
          if (
            rrolr.toString().toLowerCase() === "guest" ||
            rrolr.toString().toLowerCase() === "owner"
          ) {
            // dispatch(setIsActivePageType("demo"));
            dispatch(setIsPageType("demo"));
            window.sessionStorage.setItem("isActive", "demo");
            sessionStorage.setItem("pageType", "demo");
            history("/demo");
          } else {
            window.sessionStorage.setItem("isActive", "dashboard");
            sessionStorage.setItem("pageType", "Analytics Dashboard");
            // dispatch(setIsActivePageType("dashboard"));

            //console.log("abhishek 12222", tempresjj);
            // history(`/${defaultSelected.url}`);
            // dispatch(setIsPageType("dashboard"));
            //console.log("abhishek 12", tempresjj);
            history("/dashboard");
          }
        }

        props.setToken(res.data.data.userSessionDetails.accessToken);
        // console.log("ggg", res.data.data.userSessionDetails.accessToken);
        sessionStorage.setItem("Id", res.data.data.userSessionDetails.userId);
        sessionStorage.setItem("email", res.data.data.userDetail.email);
        sessionStorage.setItem("name", res.data.data.userDetail.name);
        sessionStorage.setItem("role", res.data.data.userDetail.role);
        // sessionStorage.setItem("isActive", res.data.data.userDetail.isActive);
        const accountNametemp = res.data.data.userDetail.accountDetails[0].name;
        sessionStorage.setItem("accountName", accountNametemp);

        // // // To get all types filters from store
        // props.getFilterDetails(accountNametemp);

        // // to get all client info
        // props.getClientDetails();

        sessionStorage.setItem(
          "accountId",
          res.data.data.userDetail.accountDetails[0].id
        );
        sessionStorage.setItem("language", res.data.data.userDetail.language);
        sessionStorage.setItem(
          "userType",
          res.data.data.accountDetails[0].type
        );
        // axios
        //   .get(
        //     `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/account/v1/all`
        //   )
        //   .then((response) => {
        //     let fdata = response?.data?.data;
        //     let type = fdata.filter((each) => {
        //       if (
        //         6=
        //         res?.data?.data?.userDetail?.accountDetails[0]?.name?.toLowerCase()
        //       ) {
        //         return each;
        //       }
        //     });
        //     sessionStorage.setItem(
        //       "userType",
        //       res.data.data.accountDetails[0].type
        //     );
        //     // sessionStorage.setItem("userType",type[0].type)
        //     typeOfUser = type[0].type;
        //     // props?.setUserType("SET_USER_TYPE", typeOfUser);
        //   });
      })
      .catch((e) => {
        console.error("err ", e);
        setErrorHandle((previousState) => {
          return {
            ...previousState,
            allError: e?.response?.data?.error || "Unable to process .",
          };
        });
      });
    let end = new Date().getTime();
    setIsLoginLoading(false);
  };

  const handleSubmitSendLink = async (e) => {
    e.preventDefault();
    const fetchUserIdUrl = SERVER_URL + RESETLINK_URL.GET_USER_ID;
    const sendLinkUrl = SERVER_URL + RESETLINK_URL.PASSWORD_RESET_LINK;
    // "http://connectors.saarthi.ai/conversation/api/accounts/v1/forgotPasswordLink" ;
    const checkingEmailLP = emailValidation(userCreditials.sendMeLinkEmail);
    if (!checkingEmailLP.isValid) {
      setErrorHandle((previousState) => {
        return {
          ...previousState,
          emailInvalid: checkingEmailLP.errors.message,
        };
      });
      return;
    }
    const finalFetchUserIdUrl = `${fetchUserIdUrl}userName=${userCreditials.sendMeLinkEmail}`;
    let resUserId;
    setIsLoginLoading(true);
    // await axios
    //   .get(finalFetchUserIdUrl)
    //   .then((res) => {
    //     resUserId = res?.data?.data?.userId;
    //   })
    //   .catch((err) => {
    //     setErrorHandle((previousState) => {
    //       return {
    //         ...previousState,
    //         allError: "User not exits",
    //       };
    //     });
    //     //return;
    //   });

    let resetPasswordLink = `${PROJECT_URL}/resetpassword?id=${resUserId}`;
    //let resetPasswordLink = `http://localhost:3000/resetpassword?id=${resUserId}`;
    let data = {
      userName: userCreditials.sendMeLinkEmail,
    };
    let headers = { "Content-Type": "application/json" };

    const res = await axios
      .post(sendLinkUrl, data, headers)
      .then((res) => {
        settoHandleLoginPageView((previousState) => {
          return {
            ...previousState,
            forgetPassword: 1,
          };
        });
      })
      .catch((e) => {
        setErrorHandle((previousState) => {
          return {
            ...previousState,
            allError: "User not exits",
          };
        });
      });
    setIsLoginLoading(false);
  };

  const HandleLoginPageView = (type) => {
    if (type === "forgetPassword") {
      settoHandleLoginPageView((previousState) => {
        return {
          ...previousState,
          login: 1,
        };
      });
      setErrorHandle((previousState) => {
        return {
          ...previousState,
          emailInvalid: null,
          passwordInvalid: null,
          confirmPasswordInvalid: null,
          allError: null,
        };
      });
      const countOfForgot = forgotPaswordCount ? forgotPaswordCount : 0;
      const increasedCount = Number(countOfForgot) + 1;
      dispatch(mixpannelForgotPasswordCount(increasedCount));
      Mixpanel.track("Homepage | Login Forgot Password| Clicks", {
        "user Name": "defaultuser",
        Date: format(new Date(), "dd-MM-yyyy"),
        Count: increasedCount,
      });
    } else if (type === "moveToLogin") {
      settoHandleLoginPageView((previousState) => {
        return {
          ...previousState,
          login: 0,
        };
      });
      setErrorHandle((previousState) => {
        return {
          ...previousState,
          emailInvalid: null,
          passwordInvalid: null,
          confirmPasswordInvalid: null,
          allError: null,
        };
      });
    }
  };

  const handlecheckBox = () => {
    setcheckingCheckBox(!checkingCheckBox);
  };
  //mixpannel
  useEffect(() => {
    if (mixpannellogInDetails?.startTime) {
      const loginSessionTime =
        new Date().getTime() / 1000 - Number(mixpannellogInDetails?.startTime);
      Mixpanel.track(`Platform | Overall Session Time`, {
        module: "LogIn",
        "user Name": mixpannellogInDetails?.accountName,
        Date: `${format(new Date(), "dd-MM-yyyy")}`,
        "Total Time Spent":
          Math.floor(Number(loginSessionTime) / 60) >= 1
            ? `${Math.floor(Number(loginSessionTime) / 60)} minutes`
            : `${Number(loginSessionTime)} second`,
      });
    }
  }, []);

  return (
    <div className="loginForm">
      <div className="loginTopDiv">
        {toHandleLoginPageView.login === 0 && (
          <div className="formAreaLP">
            <div className="loginHeading">
              <h2 className="wlcomeBacklg">Log In</h2>
              <p className="wlcomeBackMessage">
                Welcome to Pravid, please enter your login credentials to start
                using Pravid.
              </p>
            </div>
            <form autoComplete="off">
              <InputContainer
                showLabel={true}
                label={"E-mail"}
                inputAutoComplete="off"
                inputType="email"
                inputPlaceholder="E-mail"
                inputrequired={true}
                inputOnChange={handleChangeEmail}
                inputOnFocus={handleFocusOnEmail}
                inputOnKeyDown={validateKeys}
                inputIconLeft={usernameIcon}
                inputValue={userCreditials.email}
                maxLength={50}
                extraUserInputClass={"extraInputClass"}
                // isShowTooltip={true}
                // toolTipAbout={"SJDGHJSGK"}
                errorMsg={
                  errorHandle.emailInvalid ? errorHandle.emailInvalid : ""
                }
              />

              <InputContainer
                showLabel={true}
                label={"Password"}
                inputAutoComplete="off"
                inputType={showPassword ? "text" : "password"}
                inputPlaceholder="Password"
                inputrequired={true}
                inputOnChange={handleChangePassword}
                inputOnFocus={handleFocusOnPassword}
                inputOnKeyDown={validateKeys}
                inputIconLeft={passwordKeyIcon}
                inputIconRight={showPassword ? Hidden : Visiblity}
                extraUserInputClass={"extraInputClass"}
                onClickRightIcon={() => {
                  setShowPassword((prev) => !prev);
                }}
                inputValue={userCreditials.password}
                errorMsg={
                  errorHandle.passwordInvalid ? errorHandle.passwordInvalid : ""
                }
                maxLength={50}
                passwordMaskClass={"passwordMaskClass"}
              />

              <div className="checkBoxDiv">
                {/* <div className="checkBoxMsgDiv">
                  <input
                    type="checkbox"
                    checked={checkingCheckBox}
                    onChange={() => handlecheckBox()}
                  />
                  <p className="checkParaLogIn">
                    I agree with
                    <a
                      href="https://saarthi.ai/terms-of-service"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Terms of Service
                    </a>
                    &nbsp;and
                    <a
                      rel="noreferrer noopener"
                      href="https://saarthi.ai/privacy-policy"
                      target="_blank"
                    >
                      &nbsp;Privacy Policy.
                    </a>
                  </p>
                </div> */}
                <div>
                  <p
                    className="forgetPasswordLink"
                    onClick={() => HandleLoginPageView("forgetPassword")}
                  >
                    Forgot password?
                  </p>
                </div>
              </div>

              <div className="formLabelLP">
                {errorHandle.allError && (
                  <div className="">
                    <ErrorMessage
                      errorMessage={errorHandle.allError}
                      extraClass={"extraErrorMessageClassNameLPSL"}
                    />
                  </div>
                )}
              </div>

              <div className="formLabelLP">
                <div className="summitTopDivLP">
                  <input
                    autoComplete="off"
                    className="formInputSubmitLP"
                    type="button"
                    value={isLoginLoading ? "Logging in..." : "Log In"}
                    disabled={isLoginLoading}
                    onClick={(e) => handleSubmit(e)}
                    onKeyDown={validateKeys}
                  />
                </div>
              </div>
              <div
                className={`singUpRedirect ${signUpStyles["login-redirect"]}`}
              >
                <span>Don't have account? </span>
                <Link to="/signup">Sign Up</Link>
              </div>
            </form>
          </div>
        )}
        {toHandleLoginPageView.login === 1 && (
          <div className="formAreaLP">
            {toHandleLoginPageView.forgetPassword === 0 && (
              <div>
                <div className="forgetPasswordHeadingTopDiv">
                  {/* <div className="FPHeading">
                      <img src={WelcomeBackIcon} alt="Welcome back icon" />
                      <h2> Welcome back! </h2>
                    </div> */}
                </div>

                <div className="formLabelLP">
                  <div className="loginHeading">
                    <h2 className="wlcomeBacklg">Reset Password</h2>
                    <p className="wlcomeBackMessage">
                      Dont worry, happens to the best of us.
                    </p>
                  </div>
                  <div className="labelStyling">E-mail</div>
                  <div className="inputBorderForm">
                    <img src={usernameIcon} alt="Email Id Icon" />
                    <input
                      autoComplete="off"
                      className="formInputArea"
                      type="email"
                      placeholder="Enter your E-mail"
                      required
                      onChange={handleChangeSendMeLinkEmail}
                      onFocus={handleFocusOnEmail}
                      onKeyDown={validateKeys}
                      maxLength={50}
                    />
                  </div>
                  {errorHandle.emailInvalid && (
                    <div className="errorMessageLP">
                      <ErrorMessage errorMessage={errorHandle.emailInvalid} />
                    </div>
                  )}
                </div>

                {/* <div className="formLabelLP">
                    <p className="dontWorryMessage">
                      Dont worry, happens to the best of us.
                    </p>
                  </div> */}
                {errorHandle.allError && (
                  <div className="errorMessageLP">
                    <ErrorMessage
                      errorMessage={errorHandle.allError}
                      extraClass={"extraErrorMessageClassNameLPSL"}
                    />
                  </div>
                )}
                <div className="formLabelLP">
                  <div className="summitTopDivLP">
                    <input
                      className="formInputSubmitLP"
                      type="button"
                      value=" Get the Link"
                      onClick={(e) => handleSubmitSendLink(e)}
                    />
                  </div>
                </div>
                <div className="formLabelLP">
                  <p
                    className="forgetPasswordLink"
                    onClick={() => {
                      HandleLoginPageView("moveToLogin");
                      refreshPage();
                    }}
                  >
                    or Login
                  </p>
                </div>
              </div>
            )}

            {toHandleLoginPageView.forgetPassword === 1 && (
              <div className="forgetPasswordHeadingTopDiv">
                <div className="successfulMessageSentLink">
                  <h3> RESET PASSWORD LINK SENT </h3>
                  <p>
                    {" "}
                    Please check your email for a link to reset your password{" "}
                  </p>
                </div>
                <div className="mailSentLogin">
                  <Link to="/" onClick={() => refreshPage()}>
                    Click here to login{" "}
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}

        {/* <ToastContainer
          position="top-center"
          type="success"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick={false}
          draggable={false}
          rtl={true}
        /> */}
      </div>
    </div>
  );
};

export default LoginFields;
