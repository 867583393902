export default {
  SET_DIALTIME: "SET_DIALTIME",
  GET_SCHEDULER_DATA: "GET_SCHEDULER_DATA",
  SET_DISPOSITION_TYPE: "SET_DISPOSITION_TYPE",
  SET_USECASE: "SET_USECASE",
  SET_SELECTED_USECASE: "SET_SELECTED_USECASE",
  CHECKED_SCHEDULER_DATA: "CHECKED_SCHEDULER_DATA",
  SET_TIME: "SET_TIME",
  SET_ANALYTICS_CAMPAIGN: "SET_ANALYTICS_CAMPAIGN",
  SET_ACCOUNT_INFO: "SET_ACCOUNT_INFO",
  CLEAR_DIAL_TIME_DATA: "CLEAR_DIAL_TIME_DATA",
};
