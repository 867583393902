// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rlI_mzxczD4SMRKgJGw3{position:relative}.rlI_mzxczD4SMRKgJGw3 .ku53U7j0mXSHhdDzQPvb{width:100%;padding:3%;background:#fff;border-radius:5.07712px}.rlI_mzxczD4SMRKgJGw3 .u7_Hs_mzWlEehAlpXvLt{color:#676767;line-height:30px;min-height:10vh}.rlI_mzxczD4SMRKgJGw3 .Gu5knLjsSTM3WAzXkxYq{display:flex;justify-content:flex-end}.rlI_mzxczD4SMRKgJGw3 .ryGCBlFTHRQkWnjdtuAU{background:#0174ff;border:1px solid #0174ff;border-radius:2px;color:#fff;padding:1% 5%;font-weight:400;font-size:15px;cursor:pointer}.rlI_mzxczD4SMRKgJGw3 .zsOay4LmmRtOy0BrtTYe{position:absolute;left:-129px}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/signUpFlow/maiaMessageModal/MessageModal.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,4CACE,UAAA,CACA,UAAA,CACA,eAAA,CACA,uBAAA,CAGF,4CACE,aAAA,CACA,gBAAA,CACA,eAAA,CAEF,4CACE,YAAA,CACA,wBAAA,CAGF,4CACE,kBAAA,CAEA,wBAAA,CACA,iBAAA,CACA,UAAA,CACA,aAAA,CACA,eAAA,CACA,cAAA,CACA,cAAA,CAEF,4CACI,iBAAA,CACA,WAAA","sourcesContent":[".messageMaiaModalWrapper {\n    position: relative;\n    .messageMaiaModalOuterDiv {\n      width: 100%;\n      padding: 3%;\n      background: #ffffff;\n      border-radius: 5.07712px;\n      \n    }\n    .contentPara {\n      color: #676767;\n      line-height: 30px;\n      min-height: 10vh;\n    }\n    .buttonContainer {\n      display: flex;\n      justify-content: flex-end;\n      \n    }\n    .btnNext {\n      background: #0174ff;\n  \n      border: 1px solid #0174ff;\n      border-radius: 2px;\n      color: #ffffff;\n      padding: 1% 5%;\n      font-weight: 400;\n      font-size: 15px;\n      cursor: pointer;\n    }\n    .maiaLeftImg {\n        position: absolute;\n        left: -129px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageMaiaModalWrapper": `rlI_mzxczD4SMRKgJGw3`,
	"messageMaiaModalOuterDiv": `ku53U7j0mXSHhdDzQPvb`,
	"contentPara": `u7_Hs_mzWlEehAlpXvLt`,
	"buttonContainer": `Gu5knLjsSTM3WAzXkxYq`,
	"btnNext": `ryGCBlFTHRQkWnjdtuAU`,
	"maiaLeftImg": `zsOay4LmmRtOy0BrtTYe`
};
export default ___CSS_LOADER_EXPORT___;
