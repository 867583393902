import { Reducer } from "redux";
import actionType from "../actionTypes";
import { stateInterface, actionInterface } from "../interface";

const initialState: stateInterface = {
  audioUrl: "",
  reportTableDetails: {},
  isLoadingReportTable: true,
  reportTableError: "",
  tableSortingColumn: {},
  nextReportTableData: { data: {}, isLoading: false, error: {} },
  tableAllNextPreviousRecord: [],
  isHitNextPageApi: false,
};

const reportReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionType.REPORT_DATA_API_REQUEST: {
      return {
        ...state,
        isLoadingReportTable: true,
        reportTableDetails: {},
        nextReportTableData: { data: {}, isLoading: false, error: {} },
        tableAllNextPreviousRecord: [],
      };
    }
    case actionType.REPORT_DATA_API_SUCCESS: {
      return {
        ...state,
        reportTableDetails: action.payload,
        isLoadingReportTable: false,
        tableAllNextPreviousRecord: [action.payload],
      };
    }
    case actionType.REPORT_DATA_API_FAILURE: {
      return {
        ...state,
        isLoadingReportTable: false,
        reportTableError: action.payload,
      };
    }
    case actionType.SET_TABLE_SORTING_COLUMN: {
      return {
        ...state,
        tableSortingColumn: {
          [action.payload.columnKey]: action.payload.sortingDirection,
        },
      };
    }
    case actionType.SET_REPORT_TABLE_DATA: {
      return {
        ...state,
        reportTableDetails: {
          ...state.reportTableDetails,
          results: action.payload,
        },
      };
    }
    case actionType.REPORT_DATA_NEXT_API_REQUEST: {
      return {
        ...state,
        nextReportTableData: { data: {}, isLoading: true, error: {} },
        isHitNextPageApi: false,
      };
    }
    case actionType.REPORT_DATA_NEXT_API_SUCCESS: {
      return {
        ...state,
        reportTableDetails: {
          ...action.payload,
          results: [
            ...state.reportTableDetails?.results,
            ...action.payload?.results,
          ],
        },
        nextReportTableData: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
        tableAllNextPreviousRecord: [
          ...state.tableAllNextPreviousRecord,
          action.payload,
        ],
        isHitNextPageApi: false,
      };
    }
    case actionType.REPORT_DATA_PREVIOUS_API_SUCCESS: {
      return {
        ...state,
        nextReportTableData: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionType.REPORT_DATA_NEXT_API_FAILURE: {
      return {
        ...state,
        nextReportTableData: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionType.REPORT_DATA_PREVIOUS_SLOT_API_SUCCESS: {
      const newNextPreviousRecord = [...state.tableAllNextPreviousRecord];
      newNextPreviousRecord[action.payload?.previous?.page || 0] =
        action.payload;
      const newOne = newNextPreviousRecord.slice(
        0,
        action.payload?.previous?.page + 1 || 1
      );
      return {
        ...state,
        reportTableDetails: {
          ...action.payload,
          results: [
            ...state.reportTableDetails?.results?.slice(
              0,
              (action.payload?.previous?.page || 0) *
                action.payload?.previous?.limit || 0
            ),
            ...action.payload?.results,
          ],
        },
        nextReportTableData: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
        tableAllNextPreviousRecord: [...newOne],
        isHitNextPageApi: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default reportReducer;
