import React from "react";
import propTypes from "prop-types";
import "./Checkbox.css";

function Checkbox(props) {
  return (
    <label
      className={
        "checkbox-container " +
        (props.extraClass ? props.extraClass : "") +
        (props.disabled ? " disabled-checkbox" : "") +
        (props?.moduleFlow ? "disabledStyling" : "")
      }
      style={props.style ? props.style : {}}
      id="auto_label"
    >
      <span
        className={
          (props.disabled ? "opacity " : "") +
          (props.labelClass ? props.labelClass : "")
        }
        id="auto_label_span"
      >
        {props.text}
      </span>
      <input
        type="checkbox"
        disabled={props.disabled}
        checked={props.checked ? "checked" : ""}
        defaultChecked={props.defaultChecked}
        tabIndex={props.tabIndex ? props.tabIndex : 0}
        onChange={(e) => {
          e.stopPropagation();
          props.multiple
            ? props.onChange(e.target.checked, props.text, props.id)
            : // props.onChange(e.target.checked, e.target.checked === true ? props.text : "", props.id);
              props.onChange(e.target.checked, props.data, props.tabIndex);
        }}
        id="auto_input"
      />
      <span
        className={
          "custom-checkbox " + (props.extraSpan ? props.extraSpan : "")
        }
        style={{
          backgroundColor: `${props.background ? props.background : ""}`,
        }}
        id="auto_input_span"
      ></span>
    </label>
  );
}

Checkbox.propTypes = {
  extraClass: propTypes.string,
  disabled: propTypes.bool,
  checked: propTypes.bool | propTypes.string,
  defaultChecked: propTypes.bool,
  tabIndex: propTypes.number,
  extraSpan: propTypes.string,
  // onChange: propTypes.func.isRequired,
  text: propTypes.string,
  labelClass: propTypes.string,
  style: propTypes.object,
  multiple: propTypes.bool,
};

export default Checkbox;
