import { Reducer } from "redux";
import actionTypes from "../actionTypes";
import { stateInterface, actionInterface } from "../interface";

const initialState: stateInterface = {
  schema: {},
  schemaError: "",
  schemaLoading: false,
  summarySchemaModified: [],
  callNotification: {
    callerName: "",
    callerNumber: "",
    callStatus: "",
    inQueue: 0,
  },
  chatNotification: { contactMessage: "", contactName: "", inQueue: 0 },
  onGoingCallChatSessionId: "",
  onGoingCallChatConversationId: "",
  communicationType: "",
  sendChatSignal: "",
  agentStatus: {
    statusList: {
      data: [],
      isLoading: false,
      error: "",
    },
  },
  status: {
    currentState: "",
    previousState: "",
  },
  ringTime: new Date(),
  callStatusData: {},
};

const baseScreenReducer = (
  state = initialState,
  action: actionInterface
): stateInterface => {
  switch (action.type) {
    case actionTypes.GET_AGENT_DESKTOP_SCHEMA_REQUEST: {
      return { ...state, schema: {}, schemaError: "", schemaLoading: true };
    }
    case actionTypes.GET_AGENT_DESKTOP_SCHEMA_SUCCESS: {
      return { ...state, schema: action.payload, schemaLoading: false };
    }
    case actionTypes.GET_AGENT_DESKTOP_SCHEMA_FAILURE: {
      return { ...state, schemaError: action.payload, schemaLoading: false };
    }
    case actionTypes.SET_SUMMARY_SCHEMA_AGENT_DESKTOP: {
      return { ...state, summarySchemaModified: action.payload };
    }
    case actionTypes.SET_CALL_STATUS_AGENT_DESKTOP: {
      return {
        ...state,
        callNotification: {
          ...state.callNotification,
          callStatus: action.payload,
        },
      };
    }
    case actionTypes.SET_CALLER_DATA_AGENT_DESKTOP: {
      return {
        ...state,
        onGoingCallChatSessionId: action.payload.sessionId,
        callNotification: {
          ...state.callNotification,
          callerName: action.payload.callerName,
          callerNumber: action.payload.callerNumber,
        },
      };
    }
    case actionTypes.SET_COMMUNICATION_TYPE_AGENT_DESKTOP: {
      return { ...state, communicationType: action.payload };
    }
    case actionTypes.SET_IN_QUEUE_CALL_AGENT_DESKTOP: {
      return {
        ...state,
        callNotification: {
          ...state.callNotification,
          inQueue: action.payload,
        },
      };
    }
    case actionTypes.SET_CALL_STATUS_DATA: {
      return { ...state, callStatusData: action.payload };
    }
    case actionTypes.SET_IN_QUEUE_CHAT_AGENT_DESKTOP: {
      return {
        ...state,
        chatNotification: {
          ...state.chatNotification,
          inQueue: action.payload,
        },
      };
    }
    case actionTypes.SET_MESSENGER_DATA_AGENT_DESKTOP: {
      return {
        ...state,
        onGoingCallChatSessionId: action.payload.sessionId,
        chatNotification: {
          ...state.chatNotification,
          contactMessage: action.payload.message,
          contactName: action.payload.name,
        },
      };
    }
    case actionTypes.SET_SEND_CHAT_SIGNAL_AGENT_DESKTOP: {
      return { ...state, sendChatSignal: action.payload };
    }
    case actionTypes.SET_ONGOING_CALL_CHAT_SESSION_ID: {
      return { ...state, onGoingCallChatSessionId: action.payload };
    }
    case actionTypes.HANDLE_CALL_FINISH_AGENT_DESKTOP: {
      return {
        ...state,
        callNotification: { ...state.callNotification, callStatus: "" },
        communicationType: "",
      };
    }
    case actionTypes.HANDLE_CHAT_FINISH_AGENT_DESKTOP: {
      return {
        ...state,
        chatNotification: initialState.chatNotification,
        onGoingCallChatSessionId: "",
        communicationType: "",
      };
    }
    case actionTypes.SET_UPDATED_STATUS: {
      return {
        ...state,
        status: { ...action.payload },
      };
    }
    case actionTypes.SET_RING_TIME: {
      return {
        ...state,
        ringTime: action.payload,
      };
    }
    case actionTypes.GET_AGENT_DESKTOP_STATUS_API_CALL: {
      return {
        ...state,
        agentStatus: {
          ...state.agentStatus,
          statusList: { data: [], isLoading: true, error: "" },
        },
      };
    }
    case actionTypes.GET_AGENT_DESKTOP_STATUS_API_SUCCESS: {
      return {
        ...state,
        agentStatus: {
          ...state.agentStatus,
          statusList: { data: action.payload, isLoading: false, error: "" },
        },
      };
    }
    case actionTypes.GET_AGENT_DESKTOP_STATUS_API_FAILURE: {
      return {
        ...state,
        agentStatus: {
          ...state.agentStatus,
          statusList: { data: [], isLoading: false, error: action.payload },
        },
      };
    }
    case actionTypes.SET_ONGOING_CALL_CHAT_CONVERSATION_ID: {
      return { ...state, onGoingCallChatConversationId: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default baseScreenReducer;
