import { takeLatest } from "redux-saga/effects";
import actionTypes from "../nudge.actionTypes";
import {
  createNudgeAPIWorker,
  getNudgeAPIWorker,
  updateNudgeAPIWorker,
  getConditionAPIWorker,
  getConditionEditAPIWorker,
  getDispositionAlternateAPIWorker,
  getDispositionFollowUpsAPIWorker,
  getPaymentModebyflowAPIWorker,
  getOutComesDispositionAPIWorker,
} from "./nudge.saga";

export default function* nudgeDetailsWatcher(): any {
  yield takeLatest(actionTypes.CREATE_NUDGE_API_REQUEST, createNudgeAPIWorker);
  yield takeLatest(actionTypes.GET_NUDGE_API_REQUEST, getNudgeAPIWorker);
  yield takeLatest(actionTypes.UPDATE_NUDGE_API_REQUEST, updateNudgeAPIWorker);
  yield takeLatest(
    actionTypes.GET_CONDITION_API_REQUEST,
    getConditionAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_CONDITION_EDIT_API_REQUEST,
    getConditionEditAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_DISPOSITION_ALTERNATE_API_REQUEST,
    getDispositionAlternateAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_FOLLOWUPS_DISPOSITION_API_REQUEST,
    getDispositionFollowUpsAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_OUTCOMES_DISPOSITIONS_API_REQUEST,
    getOutComesDispositionAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_PAYMENT_MODE_API_REQUEST,
    getPaymentModebyflowAPIWorker
  );
}
