import React, { useEffect, useState, useRef } from "react";
import styles from "./ClustersScreen.module.scss";
import { ClusterComponent } from "../clusterComponent/ClusterComponent";
import ToggleSwitch from "../../../../generic/toggleSwitch/ToggleSwitch";
import Button from "../../../../generic/button/Button";
import {
  createTelephonyData,
  setDefaultTelephonyData,
  clearErrorMsgSetTelephony,
  //getdefaultTelephonySystem,
} from "../../../../../redux/integration_V2.0/telephony/actions";
import { getCurrentTab } from "../../../../../redux/profile/yourAccount/organizationDetails/actions/organizationDetails.actions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";

export const ClustersScreen = () => {
  const navigate = useNavigate();
  const [clustersData, setClustersData] = useState<any>([
    {
      cluster_name: "",
      capacity: "",
      weightage: "",
      gateways: [{ name: "", did_range: "" }],
    },
  ]);
  const [isDefault, setDefault] = useState(false);
  const [defaultTelephonyDatas, setDefaultTelephonyDatas] = useState<any>({});
  const [dataCenterArray, setDataCenterArray] = useState<any>([]);
  const [isValid, setIsValid] = useState(false);
  const [onChangeStatus, setOnChangeStatus] = useState(false);
  const [mode, setMode] = useState("update");

  const targetRef = useRef<any>(null);

  const scrollToTarget = () => {
    targetRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const scrollToBtm = () => {
    targetRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const dispatch = useDispatch();

  /**clear**/
  useEffect(() => {
    if (onChangeStatus === false) {
      if (updateBtnError && updateBtnError?.length > 0) {
        dispatch(clearErrorMsgSetTelephony());
      }
    }
  }, [onChangeStatus]);

  /** Redux data  **/
  const accessToken = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const clientTelephonyData = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.telephonyReducer?.telephonyDataClient?.data
  );
  const selectedTab = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.telephonyReducer?.selectedTelephonyTab
  );
  const accountDetails = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]
  );

  const selectedTelephonySystem = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.telephonyReducer?.setSelectedTelephonySystem
  );
  const defaultTelephonyData = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.telephonyReducer?.telephonyDataDefault?.data
  );

  const clientAllTelephonySystem = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.telephonyReducer?.deafultTelephonySystem?.data
  );
  const updateButtonIsLoading = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.telephonyReducer?.setTelephonyDataClient
  );

  const updateBtnError = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.telephonyReducer?.setTelephonyDataClient?.error
  );

  /** constants**/
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": accessToken,
  };

  /**handle chnage status  **/
  const handleOnchangeStatus = (data: boolean) => {
    setOnChangeStatus(data);
    if (updateBtnError && updateBtnError?.length > 0) {
      dispatch(clearErrorMsgSetTelephony());
    }
  };

  const handleDefaultSwitch = (data: boolean) => {
    setDefault(data);
    dispatch(
      setDefaultTelephonyData({
        name: accountDetails?.name,
        accountId: accountDetails?.id,
        telephony: selectedTelephonySystem?.telephony,
        isDefault: data,
      })
    );
    // dispatch(
    //   getdefaultTelephonySystem({
    //     params: { name: `${accountDetails?.name}` },
    //     headers: headers,
    //     name: "default",
    //   })
    // );
  };

  /** onchange Data center gateway option **/

  /** onClick update  **/
  const handleUpdate = () => {
    const defaultKeys = defaultTelephonyDatas?.showFields;
    const updatedBody = [...clustersData];
    const newCluster = updatedBody?.map((value: any) => {
      Object.keys(defaultKeys)?.map((e: any) => {
        if (!defaultKeys[e]) {
          delete value[e];
        }
      });
      return value;
    });

    newCluster?.map((value: any) => {
      if (value?.gateways) {
        value.gateways?.map((e: any) => {
          if (
            e?.available_ranges ||
            e?.isValid === true ||
            e?.isValid === false
          ) {
            delete e?.available_ranges;
            delete e?.isValid;
            return e;
          }
        });
      }
    });

    const data = {
      body: {
        name: accountDetails?.name,
        accountId: accountDetails?.id,
        telephony: selectedTelephonySystem?.telephony,
        [selectedTab.toLowerCase()]: {
          clusters: newCluster,
        },
      },
      headers: headers,
      mode: mode,
    };

    dispatch(createTelephonyData(data));
  };

  const toGetAllFilledData = (data: any, index: any) => {
    clustersData[index] = data;
    setClustersData([...clustersData]);
  };

  const addCluster = () => {
    const data = {
      cluster_name: "",
      capacity: "",
      weightage: "",
      gateways: [{ name: "", did_range: "" }],
    };
    setClustersData([...clustersData, data]);
    scrollToTarget();
    setOnChangeStatus(true);
  };

  const removeCluster = (index: number) => {
    const clusters = [...clustersData];
    clusters.splice(index, 1);
    setClustersData(clusters);
    scrollToBtm();
    setOnChangeStatus(true);
  };

  const isCheckValidation = (data: Array<any>) => {
    let isValid = true;
    const defaultKeys = defaultTelephonyDatas?.showFields;
    const updatedBody = [...data];
    const newCluster = updatedBody?.map((value: any) => {
      defaultKeys &&
        Object.keys(defaultKeys)?.map((e: any) => {
          if (defaultKeys[e] && e !== "gateways") {
            if (value[e]?.length == 0 || value[e]?.length < 1) {
              isValid = false;
            }
          }
          if (defaultKeys[e] && e === "gateways") {
            value[e]?.map((gateway: any) => {
              if (gateway?.name?.length <= 0) {
                isValid = false;
              }
              if (gateway?.did_range?.trim()?.length !== 21) {
                isValid = false;
              }
              if (
                gateway?.did_range?.trim()?.length === 21 &&
                gateway?.did_range?.includes("-")
              ) {
                const str = gateway?.did_range?.trim();
                const didRange = str?.trim()?.split("-");
                if (didRange?.length !== 2) {
                  isValid = false;
                } else {
                  if (
                    didRange?.[0]?.trim()?.length !== 10 ||
                    didRange?.[1]?.trim()?.length !== 10
                  ) {
                    isValid = false;
                  }
                }
              }
            });
          }
        });
      return value;
    });
    return isValid;
  };

  /** useEffects */
  useEffect(() => {
    const isValidData = isCheckValidation(clustersData);

    setIsValid(isValidData);
  }, [clustersData]);

  /** set initial client Data  **/

  useEffect(() => {
    const str = selectedTab.toLowerCase();

    if (clientTelephonyData?.[str]?.clusters?.length) {
      setClustersData(clientTelephonyData?.[str]?.clusters);
      setMode("update");
    } else {
      setClustersData([
        {
          cluster_name: "",
          capacity: "",
          weightage: "",
          gateways: [{ name: "", did_range: "" }],
        },
      ]);
      setMode("create");
    }

    setOnChangeStatus(false);
  }, [clientTelephonyData, selectedTab]);

  /** for setting default schema data **/
  useEffect(() => {
    const str = selectedTab.toLowerCase();
    if (defaultTelephonyData?.schema?.[str]?.clusters?.length) {
      setDefaultTelephonyDatas(defaultTelephonyData?.schema?.[str]);
    } else {
      defaultTelephonyData?.schema?.[str]
        ? setDefaultTelephonyDatas(defaultTelephonyData?.schema?.[str])
        : setDefaultTelephonyDatas({});
    }
  }, [defaultTelephonyData, selectedTab]);

  /** set Data Center **/
  useEffect(() => {
    const dataCenterArray = defaultTelephonyDatas?.clusters?.map(
      (e: any) => e?.cluster_name
    );
    // const dataCenterArray = [
    //   "sdjgsdhsd",
    //   "sdkjshdksd",
    //   "sdkjsdhjsghj",
    //   "sdjhgsdghsjdfghj",
    // ];
    dataCenterArray
      ? setDataCenterArray(dataCenterArray)
      : setDataCenterArray([]);
  }, [defaultTelephonyDatas]);

  useEffect(() => {
    clientAllTelephonySystem &&
      clientAllTelephonySystem?.map((e: any) => {
        if (e?.telephony === selectedTelephonySystem?.telephony) {
          setDefault(e?.isDefault);
        }
      });
  }, [selectedTelephonySystem, clientAllTelephonySystem]);

  // console.log(
  //   "ghgh",
  //   isValid,
  //   onChangeStatus,
  //   !updateButtonIsLoading?.isLoading,
  //   selectedTelephonySystem?.telephony
  // );

  useEffect(() => {
    if (updateButtonIsLoading?.isLoading === false) {
      setOnChangeStatus(false);
    }
    if (
      updateButtonIsLoading?.error &&
      Object.keys(updateButtonIsLoading?.error)?.length > 0
    ) {
      setOnChangeStatus(true);
    }
  }, [updateButtonIsLoading]);

  return (
    <div className={styles.clustersScreenWrapper}>
      <div className={styles.clustersGrpDiv}>
        {clustersData?.map((data: any, index: number) => {
          return (
            <div key={index}>
              <div ref={index === clustersData.length - 1 ? targetRef : null}>
                <ClusterComponent
                  dataCenterOptions={dataCenterArray}
                  clientTelephonyDetails={data}
                  schemaDefault={defaultTelephonyDatas}
                  getAllDetails={(data: any) => toGetAllFilledData(data, index)}
                  addMoreCluster={addCluster}
                  removeCluster={() => removeCluster(index)}
                  allClusterData={clustersData}
                  clustersDetails={clustersData}
                  onChangeStatus={handleOnchangeStatus}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.btmContainerDiv}>
        <div className={styles.btmLeftContainerDiv}>
          <div className={styles.defaultLabel}>Set as default</div>
          <ToggleSwitch
            id={`auto_priority_value`}
            checked={isDefault}
            small={true}
            onChange={handleDefaultSwitch}
          />
        </div>
        <div className={styles.btmRightContainerDiv}>
          <Button
            text={"Update"}
            extraClass={`${styles.buttonStyling} ${
              isValid &&
              onChangeStatus &&
              !updateButtonIsLoading?.isLoading &&
              selectedTelephonySystem?.telephony
                ? styles.activeBtnStyling
                : styles.inActiveBtnStyling
            }`}
            onClick={handleUpdate}
          />
        </div>
      </div>
      {updateBtnError && updateBtnError?.length && (
        <div
          className={styles.defaultText}
          onClick={() => {
            dispatch(getCurrentTab("My Plan"));
            navigate("/profile/billing/invoices");
          }}
        >
          {updateBtnError && updateBtnError?.length && (
            <p className={styles.errorMsgPara}>{updateBtnError}</p>
          )}
          {"Your Plan needs to be upgraded. Go to My Plan"}
        </div>
      )}
    </div>
  );
};
