import styles from "./ColumnMultiselect.module.scss";
import React, { useState, SetStateAction, useEffect } from "react";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";
// import {
//   searchIcon,
// } from "../../../../theme/assets/svg/rightSideIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  setReportColumnInDnd,
  setSelectedFilterOptions,
} from "../../../../redux/filters/actions";
import { setSelectedFilterType } from "../../../../redux/filters/actions";
import { RootState } from "../../../../redux";
import { validateKeys } from "../../../../utils/validators/validators";
import { columnInterface } from "../../../../screens/logger/report/type";

interface props {
  options: {
    options: columnInterface[];
    name: string;
  };
}

export default function ColumnMultiselect(props: props) {
  // const [closeDropDown, setCloseDropDown] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [filterOptions, setFilterOptions] = useState<columnInterface[]>([]);
  const [selectedData, setSelectedData] = useState<columnInterface[]>([]);
  const dispatch = useDispatch();
  const optionsAvailableInDnd = useSelector(
    (state: RootState) => state.filterReducers.allSelectedReportingColumn
  );

  useEffect(() => {
    const sle = props.options.options.filter((each) => {
      if (each.kpiDetails.originalName === "Sr. No") return false;
      return each.isActive;
    });
    setFilterOptions(props.options.options);
    setSelectedData(sle);

    if (sle.length === props.options.options.length - 1) {
      setIsSelectAll(true);
    }
  }, [props.options.options]);

  useEffect(() => {
    setSelectedData(optionsAvailableInDnd);
  }, [optionsAvailableInDnd]);

  // const selectedFilterType = useSelector(
  //   (state: RootState) => state.filterReducers?.selectedFilterType
  // );
  // const dispatch=useDispatch();
  // function handleCloseOnDropdown() {
  //   (selectedFilterType===props.options.name) &&setCloseDropDown(!closeDropDown);
  //   dispatch(setSelectedFilterType(props?.options?.name));
  // }
  //manange slelcte filter
  // useEffect(() => {
  //   if (selectedFilterType === props.options.name) {
  //     if (closeDropDown) {
  //       setCloseDropDown(false);
  //     } else {
  //       setCloseDropDown(true);
  //     }
  //   } else {
  //     setCloseDropDown(true);
  //   }
  // }, [selectedFilterType]);
  // const optionsDefaultSelected = useSelector(
  //   (state: RootState) =>
  //     state.filterReducers?.selectedFilterOptions[props.options.name]
  // );
  const getChecked = (data: columnInterface, index: number) => {
    if (
      data.kpiDetails.originalName === "Contact Number" ||
      data.kpiDetails.originalName === "Sr. No"
    )
      return;
    if (selectedData?.find((ex) => ex.kpiDetails._id === data.kpiDetails._id)) {
      const tempArr = [...selectedData];
      const indexr = tempArr.findIndex(
        (ex) => ex.kpiDetails._id === data.kpiDetails._id
      );
      if (indexr > -1) {
        tempArr.splice(indexr, 1);
      }
      setSelectedData([...tempArr]);
      if (tempArr.length === props.options.options.length - 1) {
        setIsSelectAll(true);
      } else {
        setIsSelectAll(false);
      }
    } else {
      if (data.kpiDetails.originalName === "Sr. No") return;
      setSelectedData([...selectedData, data]);
      if (selectedData.length + 1 === props.options.options.length - 1) {
        setIsSelectAll(true);
      } else {
        setIsSelectAll(false);
      }
    }
  };
  const getSearchData = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 3 && !(e.target.value.length === 0)) return;
    const searchTerm = e.target.value.toLowerCase();
    const searchedData: columnInterface[] = [];
    props.options.options.forEach((each: columnInterface) => {
      if (each?.customName.toLowerCase().includes(searchTerm)) {
        searchedData.push(each);
      }
    });
    setFilterOptions(searchedData);
  };
  const getIsAllChecked = () => {
    if (isSelectAll) {
      setIsSelectAll(false);
      let newOptions = filterOptions.filter((e) => {
        if (e.kpiDetails.originalName === "Contact Number") {
          return true;
        } else {
          return false;
        }
      });
      if (
        !newOptions.find(
          (e) => e?.kpiDetails?.originalName === "Contact Number"
        )
      ) {
        const x = props.options.options.find(
          (e) => e?.kpiDetails?.originalName === "Contact Number"
        );
        if (x) newOptions = [...newOptions, x];
      }

      setSelectedData([...newOptions]);
    } else {
      setIsSelectAll(true);
      let newOptions = filterOptions.filter((e) => {
        return !(e?.kpiDetails?.originalName === "Sr. No");
      });
      if (
        !newOptions.find(
          (e) => e?.kpiDetails?.originalName === "Contact Number"
        )
      ) {
        const x = props.options.options.find(
          (e) => e?.kpiDetails?.originalName === "Contact Number"
        );
        if (x) newOptions = [...newOptions, x];
      }

      setSelectedData(newOptions);
    }
  };
  // function handleCloseButton() {
  //   setSelectedData([]);
  //   setIsSelectAll(false);
  //   setCloseDropDown(true);
  //   //  dispatch(setSelectedFilterOptions({type:props.options.name,options:[]}))
  // }
  // function handleTickButton() {
  //   setCloseDropDown(true);

  //   //  dispatch(setSelectedFilterOptions({type:props.options.name,options:selectedData}))
  // }
  // function handleClickOutsideButtons(e: any) {
  //   e.stopPropagation();
  //   setCloseDropDown(false);
  // }
  // useEffect(() => {
  //   if (optionsDefaultSelected !== undefined)
  //     setSelectedData(optionsDefaultSelected);
  // }, []);
  useEffect(() => {
    dispatch(setReportColumnInDnd(selectedData));
  }, [selectedData]);
  return (
    <>
      <div className={`${styles.filterwrappers}  `}>
        <div className={styles.openevrything}>
          {props.options?.options?.length > 0 && (
            <div className={styles.inputdiv}>
              {/* <img src={searchIcon} alt="" /> */}
              <PravidIcons activeIcon={"searchIcon"} />
              <input
                onKeyDown={validateKeys}
                onChange={getSearchData}
                className={styles.searchinput}
                type="text"
                placeholder="Search"
              />
            </div>
          )}

          {
            <>
              {
                <div
                  className={
                    styles.colorgrey +
                    " " +
                    styles.margibttm +
                    " " +
                    (isSelectAll
                      ? styles.colorItem + " " + styles.bgbluesp
                      : "")
                  }
                >
                  <input
                    type="checkbox"
                    checked={isSelectAll ? true : false}
                    onChange={getIsAllChecked}
                    className={styles.checkboxcusror}
                  />
                  Select All
                </div>
              }
            </>
          }
          <div className={styles.wrapperforoptiosn}>
            {filterOptions?.map((each, i: number) => {
              if (each.kpiDetails.originalName === "Sr. No") return null;
              return (
                <div
                  className={`${styles.bodydata}   ${
                    selectedData?.find(
                      (ex) => ex?.customName === each?.customName
                    )
                      ? styles.bgblue
                      : ""
                  }`}
                  key={i}
                >
                  <input
                    type="checkbox"
                    checked={
                      selectedData?.find(
                        (ex) => ex?.customName === each?.customName
                      )
                        ? true
                        : false
                    }
                    onChange={() => getChecked(each, i)}
                    className={styles.checkboxofoptions}
                  />
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => getChecked(each, i)}
                    className={
                      selectedData?.find(
                        (ex) => ex?.customName === each?.customName
                      )
                        ? styles.colorItem
                        : "" + " " + styles.colorgrey
                    }
                  >
                    {each?.customName}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
