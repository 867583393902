import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DndComponent from "../../../generic/dndcomponent/DndComponent";
import StartTimeEndTime from "../startTimeEndTime/StartTimeEndTime";
import Checkbox from "../../../generic/checkbox/Checkbox";
import ToggleSwitch from "../../../generic/toggleSwitch/ToggleSwitch";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as breadcrumActions from "../../../../redux/breadcrum/actions";
import * as schedulerAction from "../../../../redux/campaign/scheduler/actions";
import { searchIcon } from "../../../../theme/assets/svg";
import Inputbox from "../../../generic/inputBox/InputBox";
import "./DCBTableData.css";

function DCBTableData(props) {
  const [bodyData, setBodyData] = useState([]);
  const [checkedData, setCheckedData] = useState("Not Connected");
  const [toggle, setToggle] = useState(false);
  const [dispositionData, setDispositionData] = useState([]);
  const [sortedOrder, setSortedOrder] = useState("");
  const [searchData, setSearchData] = useState("");
  const [totalDisposition , setTotalDisposition] = useState({})
  const preferedTime = useSelector((store) => {
    return store.schedulerReducer?.dialtimeData?.preferedTime;
  });
  const handleDisableToggleSwitch = (checked) => {
    setToggle(checked);
  };
  const tableBodyData = useSelector((store) => {
    return store.breadcrumReducer?.dispositionBasedCalling;
  });

  const dispositionType = useSelector((store) => {
    return store.schedulerReducer?.dispositionType;
  });

  const selectedDispositionData = useSelector((store) => {
    return store.breadcrumReducer?.selectedDispositionData;
  });

  const selectedDispositionDataParent = useSelector((store) => {
    return store.breadcrumReducer?.selectedDispositionDataParent;
  });
  const selectedDispositionDataChild = useSelector((store) => {
    return store.breadcrumReducer?.selectedDispositionDataChild;
  });

  const separateSelectedDispositionData = useSelector((store) => {
    return store.breadcrumReducer?.separateDispositionData;
  });
  //succseding
  const shuffledDispositionData = useSelector((store) => {
    return store.breadcrumReducer?.shuffledDispositionData;
  });
  const shuffledConnectedSucceding = useSelector((store) => {
    return store.breadcrumReducer?.shuffledConnectedSucceding;
  });
  const shuffledNotConnectedSucceding = useSelector((store) => {
    return store.breadcrumReducer?.shuffledNotConnectedSucceding;
  });

  const shuffledConnectedDispositionData = useSelector((store) => {
    return store.breadcrumReducer?.shuffledConnectedDispositionData;
  });

  const retryTimeData = useSelector((store) => {
    return store.breadcrumReducer?.retryTime;
  });
  
  /**for calculating All disposition **/
  useEffect(
    () => {
      let valueNotConnected =tableBodyData?.template?.filter((val) => {
              return val.groupKey == "not_connected";
            })

     let valueConnected =   tableBodyData?.template?.filter((val) => {
              return val.groupKey == "connected";
            });
           
    const arrDispositionNotConnected = valueNotConnected?.map(item => item.respKey);
    const arrDispositonConnected = valueConnected?.map(item => item.respKey);
    setTotalDisposition(
      {
        connected : arrDispositonConnected,
        notConneted : arrDispositionNotConnected
      }
    )

    },[tableBodyData?.length]
  )
  console.log("suffled" , shuffledConnectedDispositionData, shuffledConnectedSucceding , shuffledDispositionData, shuffledNotConnectedSucceding)
  console.log("dsp" , separateSelectedDispositionData , selectedDispositionData , selectedDispositionDataParent , selectedDispositionDataChild)
 const uniqueValue = (arr) => {
    return [...new Set([...arr])]
 }

  const checkAllSelected =(type ,lengthToCompare, toggle ) => {
    console.log("xxx12" , type , lengthToCompare , toggle)
      if(toggle === true){
        const childArray = selectedDispositionDataChild ? [...selectedDispositionDataChild] : []
        const NewChild = type === "Not Connected" ?  childArray?.filter((e) => e.startsWith("nc_"))
        : 
        childArray?.filter((e) => {
          if(e.startsWith("c_")){
            return e
          }
        })
        console.log("xx t" , type, childArray , NewChild , NewChild?.length === lengthToCompare)
        return NewChild?.length === lengthToCompare
      }
      else{
        const parentArray =  selectedDispositionDataParent ? [...selectedDispositionDataParent] : []
      
        const newParent= type == "Not Connected" ? parentArray?.filter((e) => {
          if(e.startsWith("nc_")){
            return e
          }
        })
        : 
        parentArray?.filter((e) => {
          if(e.startsWith("c_")){
            return e
          }
        })
        console.log("xx" ,type, parentArray , newParent , newParent?.length === lengthToCompare)
        return newParent?.length === lengthToCompare
      } 

  }
  const handleSelectAll = (type , toggle ) => {
   console.log("all btn" , type , toggle )
  const length = type == "Not Connected" ? totalDisposition?.notConneted?.length : totalDisposition?.connected?.length
   const check =    checkAllSelected( type ,length, toggle  )
   console.log("btn check" , check)
   console.log("btn check2")
    let value =
       type == "Not Connected"
          ? tableBodyData?.template?.filter((val) => {
              return val.groupKey == "not_connected";
            })
          : tableBodyData?.template?.filter((val) => {
              return val.groupKey == "connected";
            });
          
    const arrDisposition = value.map(item => item.respKey);
    console.log("ghg" , arrDisposition)
    if(check === true){
      const arrParent = Array.isArray(selectedDispositionDataParent)
      ? [...selectedDispositionDataParent]
      : [];
     const arrChild = Array.isArray(selectedDispositionDataChild)
      ? [...selectedDispositionDataChild ]
      : [];
        let newChild = arrChild
        let newParent =arrParent
      if(type == "Connected" && toggle ===true){
          newChild = arrChild?.filter((e) => !e?.startsWith("c_"))
       }
      if(type == "Not Connected" && toggle ===true){
         newChild = arrChild?.filter((e) => !e?.startsWith("nc_"))
      }
     if(type == "Connected" && toggle ===false){
       newParent = arrParent?.filter((e) => !e?.startsWith("c_"))
       newChild = arrChild?.filter((e) => !e?.startsWith("c_"))
    }
   if(type == "Not Connected" && toggle ===false){
      newParent = arrParent?.filter((e) => !e?.startsWith("nc_"))
      newChild = arrChild?.filter((e) => !e?.startsWith("nc_"))
  }

  props.setSelectedDispositionData([...newParent, ...newChild]);
  props.setSelectedDispositionDataParent(newParent);
  props.setSelectedDispositionDataChild(newChild);
   console.log("parent and child" , newParent , newChild)
   let connectedArr =
   separateSelectedDispositionData?.connected?.length > 0
     ? [...separateSelectedDispositionData?.connected]
     : [];
 let notConnectedArr =
   separateSelectedDispositionData?.notConnected?.length > 0
     ? [...separateSelectedDispositionData?.notConnected]
     : [];
 let succeeding_connecteds =
   separateSelectedDispositionData?.succeeding_connected?.length > 0
     ? [...separateSelectedDispositionData?.succeeding_connected]
     : [];
 let succeeding_not_connecteds =
   separateSelectedDispositionData?.succeeding_not_connected?.length > 0
     ? [...separateSelectedDispositionData?.succeeding_not_connected]
     : [];

if(type === "Connected" && toggle ===false){
  connectedArr = []
  succeeding_connecteds =[]
}
if(type === "Not Connected" && toggle === false ){
  notConnectedArr =[]
   succeeding_not_connecteds = []
}
if(type === "Connected" && toggle ===true){
 succeeding_connecteds = []
}
if(type === "Not Connected" && toggle === true){
 succeeding_not_connecteds = []
}
 
    props.storeSeparateSelectedData({
      connected: connectedArr,
      notConnected: notConnectedArr,
      succeeding_connected: succeeding_connecteds,
      succeeding_not_connected: succeeding_not_connecteds,
    });
    }
    else{
      const selectedDisData =  Array.isArray(selectedDispositionData)
      ? uniqueValue([...selectedDispositionData , ...arrDisposition])
      : arrDisposition;
      let arrParent = Array.isArray(selectedDispositionDataParent)
      ? [...selectedDispositionDataParent]
      : [];
    let arrChild = Array.isArray(selectedDispositionDataChild)
      ? [...selectedDispositionDataChild]
      : [];
     
       
      if(type == "Connected" && toggle ===true){
        arrChild = Array.isArray(selectedDispositionDataChild)
        ? uniqueValue([...selectedDispositionDataChild , ...arrDisposition])
        : arrDisposition
     }
     if(type == "Not Connected" && toggle ===true){
      arrChild = Array.isArray(selectedDispositionDataChild)
      ?uniqueValue([...selectedDispositionDataChild , ...arrDisposition])
      : arrDisposition
     }
     if(type == "Connected" && toggle ===false){
      arrParent = Array.isArray(selectedDispositionDataParent)
      ? uniqueValue([...selectedDispositionDataParent ,...arrDisposition])
      : arrDisposition
      arrChild = Array.isArray(selectedDispositionDataChild)
      ? uniqueValue([...selectedDispositionDataChild , ...arrDisposition])
      : arrDisposition
      
   }
  if(type == "Not Connected" && toggle ===false){
    arrParent = Array.isArray(selectedDispositionDataParent)
    ? uniqueValue([...selectedDispositionDataParent ,...arrDisposition])
    : arrDisposition
    arrChild = Array.isArray(selectedDispositionDataChild)
    ? uniqueValue([...selectedDispositionDataChild , ...arrDisposition])
    : arrDisposition
 }

 console.log("parent and child 1" , arrChild , arrParent)
 props.setSelectedDispositionData([...arrChild , ...arrParent]);
 props.setSelectedDispositionDataParent(arrParent);
 props.setSelectedDispositionDataChild(arrChild);
 let connectedArr =
      separateSelectedDispositionData?.connected?.length > 0
        ? [...separateSelectedDispositionData?.connected]
        : [];
    let notConnectedArr =
      separateSelectedDispositionData?.notConnected?.length > 0
        ? [...separateSelectedDispositionData?.notConnected]
        : [];
    let succeeding_connecteds =
      separateSelectedDispositionData?.succeeding_connected?.length > 0
        ? [...separateSelectedDispositionData?.succeeding_connected]
        : [];
    let succeeding_not_connecteds =
      separateSelectedDispositionData?.succeeding_not_connected?.length > 0
        ? [...separateSelectedDispositionData?.succeeding_not_connected]
        : [];

   if(type === "Connected" && toggle ===false){
     connectedArr = arrDisposition
     succeeding_connecteds = arrDisposition
   }
  if(type === "Not Connected" && toggle === false ){
     notConnectedArr =arrDisposition
      succeeding_not_connecteds = arrDisposition
  }
  if(type === "Connected" && toggle ===true){
    succeeding_connecteds = arrDisposition
  }
  if(type === "Not Connected" && toggle === true){
    succeeding_not_connecteds = arrDisposition
  }
    
      props.storeSeparateSelectedData({
        connected: connectedArr,
        notConnected: notConnectedArr,
        succeeding_connected: succeeding_connecteds,
        succeeding_not_connected: succeeding_not_connecteds,
      });

    }
    

 
  }

  const handleChange = (each, name, i) => {
    let tempArr = Array.isArray(selectedDispositionData)
      ? [...selectedDispositionData]
      : [];
    if (!tempArr.includes(name)) {
      tempArr.push(name);
    } else {
      const index = tempArr.indexOf(name);
      if (index > -1) {
        tempArr.splice(index, 1); // 2nd parameter means remove one item only
      }
    }

    setDispositionData((prev) => tempArr);
    props.setSelectedDispositionData(tempArr);
    //for parent and child
    let arrParent = Array.isArray(selectedDispositionDataParent)
      ? [...selectedDispositionDataParent]
      : [];
    let arrChild = Array.isArray(selectedDispositionDataChild)
      ? [...selectedDispositionDataChild]
      : [];
    if (toggle) {
      if (!arrChild.includes(name)) {
        arrChild.push(name);
      } else {
        const index = arrChild.indexOf(name);
        if (index > -1) {
          arrChild.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
      props.setSelectedDispositionDataChild(arrChild);
    } else {
      if (!arrParent.includes(name)) {
        arrParent.push(name);
        if (!arrChild.includes(name)) {
          arrChild.push(name);
        }
      } else {
        const index = arrParent.indexOf(name);
        if (index > -1) {
          arrParent.splice(index, 1); // 2nd parameter means remove one item only
        }
        const index2 = arrChild.indexOf(name);
        if (index2 > -1) {
          arrChild.splice(index2, 1); // 2nd parameter means remove one item only
        }
      }
      // if (!arrChild.includes(name)) {
      //   arrChild.push(name);
      // } else {
      //   const index = arrChild.indexOf(name);
      //   if (index > -1) {
      //     arrChild.splice(index, 1); // 2nd parameter means remove one item only
      //   }
      // }

      props.setSelectedDispositionDataParent(arrParent);
      props.setSelectedDispositionDataChild(arrChild);
    }
    let connectedArr =
      separateSelectedDispositionData?.connected?.length > 0
        ? [...separateSelectedDispositionData?.connected]
        : [];
    let notConnectedArr =
      separateSelectedDispositionData?.notConnected?.length > 0
        ? [...separateSelectedDispositionData?.notConnected]
        : [];
    let succeeding_connecteds =
      separateSelectedDispositionData?.succeeding_connected?.length > 0
        ? [...separateSelectedDispositionData?.succeeding_connected]
        : [];
    let succeeding_not_connecteds =
      separateSelectedDispositionData?.succeeding_not_connected?.length > 0
        ? [...separateSelectedDispositionData?.succeeding_not_connected]
        : [];
    // let dataVal = tempArr.map((each) => {
    //   if (each.includes("nc") && toggle == false) {
    //     if(! notConnectedArr?.includes(each)){
    //       notConnectedArr.push(each);
    //     }
    //     if(!succeeding_not_connecteds?.includes(each)){
    //       succeeding_not_connecteds.push(each)
    //     }
    //   } else if( !each.includes("nc") && toggle == false) {
    //     if(!connectedArr?.includes(each)){
    //       connectedArr.push(each);
    //     }

    //     if(!succeeding_connecteds?.includes(each)){
    //       succeeding_connecteds.push(each)
    //     }
    //   }
    //   else if(each.includes("nc") && toggle){
    //     if(! succeeding_not_connecteds?.includes(each)){
    //       succeeding_not_connecteds.push(each)
    //     }
    //   }
    //   else if(!each.includes("nc") && toggle){
    //     if(!succeeding_connecteds?.includes(each)){
    //       succeeding_connecteds.push(each)
    //     }

    //   }
    // });
    if (toggle) {
      arrChild?.map((each) => {
        if (each?.startsWith("nc_")){
          if (!succeeding_not_connecteds?.includes(each)) {
            succeeding_not_connecteds.push(each);
          }
        } else {
          if (!succeeding_connecteds?.includes(each)) {
            succeeding_connecteds.push(each);
          }
        }
      });
    } else {
      arrParent?.map((each) => {
        if (each?.startsWith("nc_")){
          if (!notConnectedArr?.includes(each)) {
            notConnectedArr.push(each);
          }
          if (!succeeding_not_connecteds?.includes(each)) {
            succeeding_not_connecteds.push(each);
          }
        } else {
          if (!connectedArr?.includes(each)) {
            connectedArr.push(each);
          }
          if (!succeeding_connecteds?.includes(each)) {
            succeeding_connecteds.push(each);
          }
        }
      });
    }

    console.log("seprated-->" , connectedArr , notConnectedArr , succeeding_connecteds , succeeding_not_connecteds)
    props.storeSeparateSelectedData({
      connected: connectedArr,
      notConnected: notConnectedArr,
      succeeding_connected: succeeding_connecteds,
      succeeding_not_connected: succeeding_not_connecteds,
    });
  };
 console.log("salectParentChild" ,    selectedDispositionDataParent ,selectedDispositionDataChild)
  const storePriorityList = (list, data, shuffledData) => {
    if (list?.length == 0) {
      if (dispositionType == "Not Connected") {
        props.shuffledDispositionCondition([]);
      } else {
        props.shuffledConnectedDispositionCondition([]);
      }
    } else {
      if (data?.length > 0) {
        if (list?.length > 0 && selectedDispositionDataParent?.length > 0) {
          let tempArr = [];
          const data = shuffledData
            ? list
                .map((each, i) => {
                  return each.respKey;
                })
                .filter((e) => e)
            : list;
          // const data= selectedDispositionData
          let val = tableBodyData?.template.map((er) => {
            let index = data.indexOf(er.respKey);
            return (tempArr[index] = er);
          });

          if (data.length > 0) {
            let tVal =
              dispositionType == "Not Connected"
                ? tempArr
                    ?.map((each) => {
                      if (
                        separateSelectedDispositionData?.notConnected?.length >
                          0 &&
                        separateSelectedDispositionData?.notConnected.includes(
                          each.respKey
                        )
                      ) {
                        return each;
                      }
                    })
                    .filter((e) => e)
                : tempArr
                    .map((each) => {
                      if (
                        separateSelectedDispositionData?.connected.includes(
                          each.respKey
                        )
                      ) {
                        return each;
                      }
                    })
                    .filter((e) => e);

            if (dispositionType == "Not Connected") {
              props.shuffledDispositionCondition(tVal);
            } else {
              props.shuffledConnectedDispositionCondition(tVal);
            }
          } else {
            let tVal =
              dispositionType == "Not Connected"
                ? tableBodyData?.template
                    .map((each) => {
                      if (
                        separateSelectedDispositionData?.notConnected?.length >
                          0 &&
                        separateSelectedDispositionData?.notConnected.includes(
                          each.respKey
                        )
                      ) {
                        return each;
                      }
                    })
                    .filter((e) => e)
                : tableBodyData?.template
                    .map((each) => {
                      if (
                        separateSelectedDispositionData?.connected?.length >
                          0 &&
                        separateSelectedDispositionData?.connected.includes(
                          each.respKey
                        )
                      ) {
                        return each;
                      }
                    })
                    .filter((e) => e);

            if (dispositionType == "Not Connected") {
              props.shuffledDispositionCondition(tVal);
            } else {
              props.shuffledConnectedDispositionCondition(tVal);
            }
            // props.shuffledDispositionCondition(tVal)
          }
        }
      } else {
        if (list?.length > 0 && selectedDispositionDataParent?.length > 0) {
          let tempArr = [];
          const data = shuffledData
            ? list
                .map((each, i) => {
                  return each.respKey;
                })
                .filter((e) => e)
            : list;

          // const data= selectedDispositionData
          let val = tableBodyData?.template.map((er) => {
            let index = data.indexOf(er.respKey);
            return (tempArr[index] = er);
          });

          if (data.length > 0) {
            let tVal =
              dispositionType == "Not Connected"
                ? tempArr
                    .map((each) => {
                      if (
                        separateSelectedDispositionData?.notConnected?.length >
                          0 &&
                        separateSelectedDispositionData?.notConnected.includes(
                          each.respKey
                        )
                      ) {
                        return each;
                      }
                    })
                    .filter((e) => e)
                : tempArr
                    .map((each) => {
                      if (
                        separateSelectedDispositionData?.connected?.length >
                          0 &&
                        separateSelectedDispositionData?.connected.includes(
                          each.respKey
                        )
                      ) {
                        return each;
                      }
                    })
                    .filter((e) => e);

            if (dispositionType == "Not Connected") {
              props.shuffledDispositionCondition(tVal);
            } else {
              props.shuffledConnectedDispositionCondition(tVal);
            }
            // props.shuffledDispositionCondition(tVal)
          } else {
            let tVal = tableBodyData?.template
              .map((each) => {
                if (selectedDispositionDataParent?.includes(each.respKey)) {
                  return each;
                }
              })
              .filter((e) => e);

            if (dispositionType == "Not Connected") {
              props.shuffledDispositionCondition(tVal);
            } else {
              props.shuffledConnectedDispositionCondition(tVal);
            }
            // props.shuffledDispositionCondition(tVal)
          }
        }
      }
    }
  };
  // for succedding
  const storePriorityList2 = (list, data, shuffledData) => {
    if (list?.length == 0) {
      if (dispositionType == "Not Connected") {
        props.shuffledNotConnectedSucceding([]);
      } else {
        props.shuffledConnectedSucceding([]);
      }
    } else {
      if (data?.length > 0) {
        if (list?.length > 0 && selectedDispositionDataChild?.length > 0) {
          let tempArr = [];
          const data = shuffledData
            ? list
                .map((each, i) => {
                  return each.respKey;
                })
                .filter((e) => e)
            : list;
          // const data= selectedDispositionData
          let val = tableBodyData?.template.map((er) => {
            let index = data.indexOf(er.respKey);
            return (tempArr[index] = er);
          });

          if (data.length > 0) {
            let tVal =
              dispositionType == "Not Connected"
                ? tempArr
                    ?.map((each) => {
                      if (
                        separateSelectedDispositionData
                          ?.succeeding_not_connected?.length > 0 &&
                        separateSelectedDispositionData?.succeeding_not_connected.includes(
                          each.respKey
                        )
                      ) {
                        return each;
                      }
                    })
                    .filter((e) => e)
                : tempArr
                    ?.map((each) => {
                      if (
                        separateSelectedDispositionData?.succeeding_connected.includes(
                          each.respKey
                        )
                      ) {
                        return each;
                      }
                    })
                    .filter((e) => e);

            if (dispositionType == "Not Connected") {
              props.shuffledNotConnectedSucceding(tVal);
            } else {
              props.shuffledConnectedSucceding(tVal);
            }
          } else {
            let tVal =
              dispositionType == "Not Connected"
                ? tableBodyData?.template
                    .map((each) => {
                      if (
                        separateSelectedDispositionData
                          ?.succeeding_not_connected?.length > 0 &&
                        separateSelectedDispositionData?.succeeding_not_connected.includes(
                          each.respKey
                        )
                      ) {
                        return each;
                      }
                    })
                    .filter((e) => e)
                : tableBodyData?.template
                    .map((each) => {
                      if (
                        separateSelectedDispositionData?.succeeding_connected
                          ?.length > 0 &&
                        separateSelectedDispositionData?.succeeding_connected.includes(
                          each.respKey
                        )
                      ) {
                        return each;
                      }
                    })
                    .filter((e) => e);

            if (dispositionType == "Not Connected") {
              props.shuffledNotConnectedSucceding(tVal);
            } else {
              props.shuffledConnectedSucceding(tVal);
            }
            // props.shuffledDispositionCondition(tVal)
          }
        }
      } else {
        if (list?.length > 0 && selectedDispositionDataChild?.length > 0) {
          let tempArr = [];
          const data = shuffledData
            ? list
                .map((each, i) => {
                  return each.respKey;
                })
                .filter((e) => e)
            : list;

          // const data= selectedDispositionData
          let val = tableBodyData?.template.map((er) => {
            let index = data.indexOf(er.respKey);
            return (tempArr[index] = er);
          });

          if (data.length > 0) {
            let tVal =
              dispositionType == "Not Connected"
                ? tempArr
                    .map((each) => {
                      if (
                        separateSelectedDispositionData
                          ?.succeeding_not_connected?.length > 0 &&
                        separateSelectedDispositionData?.succeeding_not_connected.includes(
                          each.respKey
                        )
                      ) {
                        return each;
                      }
                    })
                    .filter((e) => e)
                : tempArr
                    .map((each) => {
                      if (
                        separateSelectedDispositionData?.succeeding_connected
                          ?.length > 0 &&
                        separateSelectedDispositionData?.succeeding_connected.includes(
                          each.respKey
                        )
                      ) {
                        return each;
                      }
                    })
                    .filter((e) => e);

            if (dispositionType == "Not Connected") {
              props.shuffledNotConnectedSucceding(tVal);
            } else {
              props.shuffledConnectedSucceding(tVal);
            }
            // props.shuffledDispositionCondition(tVal)
          } else {
            let tVal = tableBodyData?.template
              .map((each) => {
                if (selectedDispositionDataChild?.includes(each.respKey)) {
                  return each;
                }
              })
              .filter((e) => e);

            if (dispositionType == "Not Connected") {
              props.shuffledNotConnectedSucceding(tVal);
            } else {
              props.shuffledConnectedSucceding(tVal);
            }
            // props.shuffledDispositionCondition(tVal)
          }
        }
      }
    }
  };

  useEffect(() => {
    // if(toggle){
    //   if (sortedOrder?.length > 0) {
    //     return storePriorityList2(sortedOrder, "data",true);
    //   } else {
    //     let priorityData = tableBodyData?.template.map((each) => {
    //       return each.respKey;
    //     });
    //     return storePriorityList2(selectedDispositionData,"",false);
    //   }
    // }
    // else{
    if (sortedOrder?.length > 0) {
      if (!toggle) {
        return (
          storePriorityList(sortedOrder, "data", true),
          storePriorityList2(sortedOrder, "data", true)
        );
      } else {
        return storePriorityList2(sortedOrder, "data", true);
      }
    } else {
      let priorityData = tableBodyData?.template?.map((each) => {
        return each.respKey;
      });
      return (
        storePriorityList(selectedDispositionDataParent, "", false),
        storePriorityList2(selectedDispositionDataChild, "", false)
      );
    }
    // }
  }, [
    sortedOrder,
    selectedDispositionDataParent?.length,
    selectedDispositionDataChild?.length,
  ]);

  useEffect(() => {
    if (tableBodyData?.template?.length > 0) {
      let value =
        dispositionType == "Not Connected"
          ? tableBodyData?.template?.filter((val) => {
              return val.groupKey == "not_connected";
            })
          : tableBodyData?.template?.filter((val) => {
              return val.groupKey == "connected";
            });
      
      if (searchData?.length > 0) {
        let lowerSearch = searchData?.toLowerCase();
        value = value?.filter((e) => {
          if (e?.text?.toLowerCase()?.includes(searchData)) {
            return e;
          }
        });
      }
      let result = [];
      if (preferedTime || toggle) {
        //child --Abhishek Nayak
        let childShuffled =
          dispositionType == "Not Connected"
            ? shuffledNotConnectedSucceding
            : shuffledConnectedSucceding;
        let childResKey = [];
        childShuffled?.map((e) => {
          if (e?.respKey) {
            childResKey.push(e?.respKey);
          }
        });

        let tempFirst = [];
        let tempSecond = [];
        let final = [];
        if (childResKey?.length > 0) {
          value?.map((e) => {
            if (childResKey?.includes(e?.respKey)) {
              let indx = childResKey?.indexOf(e?.respKey);
              tempFirst[indx] = e;
            } else {
              tempSecond.push(e);
            }
          });
        }
        if (tempFirst?.length) {
          final = [...tempFirst];
        }
        if (tempSecond?.length) {
          final = [...final, ...tempSecond];
        }

        let arrFinalValue = final?.length > 0 ? final : value;

        //----end logic child succeding
        //
        result = arrFinalValue?.map((each, i) => {
          return {
            condition: each?.text,
            respKey: each?.respKey,
            selectComponent: (
              <Checkbox
                extraSpan={
                  selectedDispositionDataChild?.length > 0 &&
                  selectedDispositionDataChild?.includes(each?.respKey)
                    ? "multi-border"
                    : "multi-border-bs"
                }
                checked={
                  selectedDispositionDataChild?.length > 0 &&
                  selectedDispositionDataChild?.includes(each?.respKey)
                    ? true
                    : false
                }
                onChange={(e) => handleChange(each, each?.respKey, i)}
              />
            ),
          };
        });
      } else {
        //parent -Abhishek logic
        let parentShuffled =
          dispositionType == "Not Connected"
            ? shuffledDispositionData
            : shuffledConnectedDispositionData;
        let parentResKey = [];
        parentShuffled?.map((e) => {
          if (e?.respKey) {
            parentResKey?.push(e?.respKey);
          }
        });

        let temppFirst = [];
        let temppSecond = [];
        let finalp = [];
        if (parentResKey?.length > 0) {
          value?.map((e) => {
            if (parentResKey?.includes(e?.respKey)) {
              let indx = parentResKey?.indexOf(e?.respKey);
              temppFirst[indx] = e;
            } else {
              temppSecond.push(e);
            }
          });
        }
        if (temppFirst?.length) {
          finalp = [...temppFirst];
        }
        if (temppSecond?.length) {
          finalp = [...finalp, ...temppSecond];
        }

        let arrFinalpValue = finalp?.length > 0 ? finalp : value;

        // end ---Paerent logic Abhishek
        //
        result = arrFinalpValue?.map((each, i) => {
          let retryVal =
            retryTimeData?.length > 0
              ? retryTimeData
                  .map((el) => {
                    if (el?.key == each?.respKey) {
                      return el.retryTime;
                    }
                  })
                  .filter((et) => et)
              : 0;
          let hourVal = retryVal[0] / 60;
          let minVal = retryVal[0] - Math.floor(hourVal) * 60;
         

          return {
            condition: each?.text,
            respKey: each?.respKey,
            component: (
              <StartTimeEndTime
                value={each}
                handleHourChange={hourChange}
                handleMinChange={minChange}
                hourVal={Math.floor(hourVal)}
                minVal={minVal}
              />
            ),
            selectComponent: (
              <div className="checkbox-margin-bottom">
                <Checkbox
                  extraSpan={
                    selectedDispositionDataParent?.length > 0 &&
                    selectedDispositionDataParent?.includes(each.respKey)
                      ? "multi-border"
                      : "multi-border-bs"
                  }
                  checked={
                    selectedDispositionDataParent?.length > 0 &&
                    selectedDispositionDataParent?.includes(each.respKey)
                      ? true
                      : false
                  }
                  onChange={(e) => handleChange(each, each.respKey, i)}
                />
              </div>
            ),
          };
        });
      }
     console.log("nanana")
      setBodyData((prev) => result);
    }
  }, [
    tableBodyData?.length,
    dispositionType,
    preferedTime,
    selectedDispositionData?.length,
    toggle,
    retryTimeData,
    shuffledDispositionData,
    shuffledConnectedSucceding,
    shuffledNotConnectedSucceding,
    shuffledConnectedDispositionData,
    searchData,
  ]);

  useEffect(() => {
    props.dispositionType(checkedData);
  }, [checkedData]);

  
 
  const getModifiedData = (respKey, time, type) => {
    let tempVal = Object.assign(tableBodyData);
    //  let tempRetryTime  = retryTimeData && Object.assign(retryTimeData)
 
    if(retryTimeData){
    
      tempVal["template"]?.map(er => {
         retryTimeData?.map(data => {
           
            if(er.respKey == data?.key){
             return (
              er["retryHrTime"] = Math.floor(Number(data?.retryTime /60)),
              er["retryMinTime"] = Number(data?.retryTime) - (Math.floor(Number(data?.retryTime /60))*60)
             ) 
            }
        })
      
       })
    }
     
  
    tempVal["template"].map((er) => {
      if (er.respKey == respKey) {
        er[type] = time;
        return er;
      } else {
        return er;
      }
    });
    let tempData = tempVal.template.map((en) => {
      return {
        key: en.respKey,
        retryTime: Number(en.retryHrTime * 60) + Number(en.retryMinTime),
      };
    });
 
  
    props.selectedDataRetryTime(tempData);
    props.setDCBData(tempVal);
  };

  const hourChange = (hourValue, propsValue) => {
    getModifiedData(propsValue.respKey, hourValue, "retryHrTime");
  };

  const minChange = (minValue, propsValue) => {
    getModifiedData(propsValue.respKey, minValue, "retryMinTime");
  };

  const handleSearchChange = (e) => {
    setSearchData((prev) => e.target.value.trim());
  };
  console.log("length" , totalDisposition ,  totalDisposition?.notConneted , separateSelectedDispositionData?.succeeding_connected )
  return (
    <div
      className={`dcb-wrapper ${
        !toggle && !preferedTime ? "" : "retry-time-hide"
      }`}
    >
      <div className="connected-section">
        <div className="initial-campaign-section">
          <div>
            <input
              type="radio"
              value="true"
              checked={checkedData == "Not Connected" ? true : false}
              className="scheduler-radio"
              name="Not Connected"
              onClick={() => setCheckedData((prev) => "Not Connected")}
            />
            Not Connected
            <input
              type="radio"
              value="true"
              checked={checkedData == "Connected" ? true : false}
              className="scheduler-radio"
              name="Connected"
              onClick={() => {
                setCheckedData((prev) => "Connected");
              }}
            />{" "}
            Connected
          </div>
          <div className="dbcSerachInput">
            <Inputbox
              className="userListSearchInput"
              type="search"
              value={searchData}
              placeholder="Disposition Name"
              imgSrcLeft={searchIcon}
              onChangeValue={(e) => handleSearchChange(e)}
              imageClickLeft={() => {}}
            />
          </div>
          <div>
            {!preferedTime && (
              <>
                <span className={`pre ${toggle ? "" : "radio-active"} `}>
                  Current Campaign Setting
                </span>
                <ToggleSwitch
                  id="toggle"
                  checked={toggle}
                  optionLabels={[
                    "Current Campaign Setting",
                    "Succeeding Campaign Setting",
                  ]}
                  small={true}
                  onChange={(checked) => {
                    handleDisableToggleSwitch(checked);
                  }}
                />{" "}
              </>
            )}
            <span
              className={`post ${toggle || preferedTime ? "radio-active" : ""}`}
            >
              Succeeding Campaign Setting
            </span>
          </div>
        </div>
        <div className={`callingcondition-wrapper dcb-condition-wrapper`}>
          <table
          // className={`${!toggle && !preferedTime ? "" : "retry-time-setion"}`}
          >
            <thead>
              <th>Priority</th>
              <th>Disposition</th>
              {!toggle && !preferedTime && <th>Retry Time</th>}
              <th>
                <div className="selectAllContainer">
                <span>All  </span><span className="checkbox-selectAll">
                { dispositionType == "Not Connected" ?
                 toggle ?
                 <Checkbox
                  extraSpan={
                    checkAllSelected(dispositionType ,totalDisposition?.notConneted?.length, toggle  )
                  // ( totalDisposition?.notConneted?.length === separateSelectedDispositionData?.succeeding_not_connected?.length)
                      ? "multi-border"
                      : "multi-border-bs"
                  }
                  checked={
                    checkAllSelected(dispositionType ,totalDisposition?.notConneted?.length, toggle  )
                    // totalDisposition?.notConneted?.length === separateSelectedDispositionData?.succeeding_not_connected?.length ? true : false
                  }
                  onChange={(e) => handleSelectAll(dispositionType , toggle )}
                /> 
                :
                <Checkbox
                extraSpan={
                  checkAllSelected(dispositionType ,totalDisposition?.notConneted?.length, toggle  )
                  // (separateSelectedDispositionData?.notConnected?.length >0 && totalDisposition?.notConneted?.length === separateSelectedDispositionData?.notConnected?.length)
                    ? "multi-border"
                    : "multi-border-bs"
                }
                checked={
                  checkAllSelected(dispositionType ,totalDisposition?.notConneted?.length, toggle  )
                  // totalDisposition?.notConneted?.length === separateSelectedDispositionData?.notConnected?.length ? true :false
                }
                onChange={(e) => handleSelectAll(dispositionType, toggle )}
              /> :
              (toggle && dispositionType == "Connected") ? 
              <Checkbox
              extraSpan={
                checkAllSelected(dispositionType ,totalDisposition?.connected?.length , toggle  )
                // (separateSelectedDispositionData?.succeeding_connected?.length >0 &&  totalDisposition?.connected?.length ===  separateSelectedDispositionData?.succeeding_connected?.length )
                  ? "multi-border"
                  : "multi-border-bs"
              }
              checked={
                checkAllSelected(dispositionType ,totalDisposition?.connected?.length , toggle  )
              }
              onChange={(e) => handleSelectAll(dispositionType, toggle  )}
            />
            :   <Checkbox
            extraSpan={
              checkAllSelected(dispositionType ,totalDisposition?.connected?.length , toggle  )
              // (separateSelectedDispositionData?.connected?.length >0 && totalDisposition?.connected?.length ===  separateSelectedDispositionData?.connected?.length)
                ? "multi-border"
                : "multi-border-bs"
            }
            checked={
              checkAllSelected(dispositionType ,totalDisposition?.connected?.length , toggle  )
              // totalDisposition?.connected?.length ===  separateSelectedDispositionData?.connected?.length ? true :false
            }
            onChange={(e) => handleSelectAll(dispositionType, toggle )}
          />
                }
              </span>
              </div></th>
              <th></th>
            </thead>
            <DndComponent
              startingList={bodyData}
              sendOrder={(items) => {
                setSortedOrder((prev) => items);
              }}
            />
          </table>
        </div>
      </div>
      <div></div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    Object.assign({}, breadcrumActions, schedulerAction),
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DCBTableData);
