import React, { useEffect, useState } from "react";
import styles from "./NudgeWithChannel.module.scss";
import {
  greenWhatsapp,
  blueCall,
  smsblue,
  mailyellow,
} from "../../../theme/assets/svg";
import {
  Channel,
  CommunicationWays,
  Conditions,
  PaymentLink,
  Timing,
  Title,
  Variation,
  AgentDesktop,
  Callback,
  AlternateNumber,
  ChannelsMapping,
  PromiseToPayThreshold,
  AttemptCount,
  FollowUps,
} from "../../generic/channel/Channel";
import { questionIcon } from "../../../theme/assets/svg/campaign_v3";
import {
  hasAllEssentials,
  handleScriptValidation,
} from "../../../utils/validators/validators";
import {
  setAllNudgeData,
  getConditionEditOutcomes,
  getFollowUpsDispositions,
  setIsScriptSelectVisited,
  getOutComesDispositions,
} from "../../../redux/strategy/nudgeCreation/actions";
import {
  setPropForEachNudge,
  setSelectedNudgeData,
  setClearSelectedNudgeData,
} from "../../../redux/strategy/eachNudgeBox/actions/actionCreators";
import { ScriptConfiguration } from "../campaign_V2.0/scriptConfiguration/ScriptConfiguration";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux";
import _ from "lodash";
import { USECASE } from "../../../constants";

export default function NudgeWithChannel(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedOne, setSelectedOne] = useState<string>("");
  const [valid, setValid] = useState(false);
  const [setting, setSetting] = useState<any>({});
  const selectedNudgeData = useSelector(
    (store: RootState) =>
      store.strategyModuleReducer?.individualNudgeReducer?.selectedNudgeData
  );

  const allNudgeDetail = useSelector((store: RootState) => {
    return store?.strategyModuleReducer?.nudgeReducer?.allNudgeDetail;
  });
  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const disposition = useSelector((store: RootState) => {
    return store?.strategyModuleReducer?.nudgeReducer?.outComesDispositions
      ?.data;
  });
  const dispositionAlternate = useSelector((store: RootState) => {
    return store?.strategyModuleReducer?.nudgeReducer?.dispositionAlternate
      ?.data;
  });

  const followUpsData = useSelector((store: RootState) => {
    return store?.strategyModuleReducer?.nudgeReducer?.followUpsDispositions
      ?.data;
  });

  const currentUseCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseId
  );

  const accountId = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );
  const campaignData = useSelector(
    (store: any) =>
      store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
  );

  const uploadedSheetInStartegyFlow = useSelector(
    (store: RootState) =>
      store?.campaignModuleReducer?.dataUploadData?.uploadedCSVData?.data?.[0]
  );

  const showPaymentLinkStrategy = uploadedSheetInStartegyFlow?.details?.some((data: any) => data.isPaymentLink === true)

  const moduleSelectedScreen = useSelector(
    (store: RootState) => store?.baseScreen?.leftMenu?.highlightedModule
  );
  const { show, showRight } = useSelector(
    (state: RootState) => state.strategyModuleReducer?.individualNudgeReducer
  );

  function handleApply() {
    if (!valid) return;
    const objectOfNudge = setting;
    const index = props.index;
    const toUpdate = allNudgeDetail;
    const newUpdated = toUpdate?.map((e: any) => {
      if (objectOfNudge?.isDaywise) {
        if (objectOfNudge.flow === e?.flow) {
          const rangesArr = e?.ranges;
          rangesArr?.map((d: any) => {
            if (d?.days?.[0] === objectOfNudge?.days[0]) {
              const nudgeBoxArray = d?.nugeBoxes;
              nudgeBoxArray[index] = objectOfNudge;
              d["nugeBoxes"] = nudgeBoxArray;
              return d;
            }
          });
          return e;
        } else {
          return e;
        }
      } else {
        if (objectOfNudge.flow === e?.flow) {
          const nudgeBoxArray = e?.nugeBoxes;
          nudgeBoxArray[index] = objectOfNudge;
          e["nugeBoxes"] = nudgeBoxArray;
          return e;
        } else {
          return e;
        }
      }
    });

    dispatch(setAllNudgeData(newUpdated));
    dispatch(setClearSelectedNudgeData());
    props.setSelectedOne("");
  }

  function changeSetting(data: any) {
    console.log("abe", data, setting);
    setSetting({ ...setting, ...data });
  }

  function handleClickOnChannel(each: any) {
    props.selectedOne === props.data.uniqueId
      ? props.setSelectedOne("")
      : props.setSelectedOne(props.data.uniqueId);

    if (
      props.selectedOne === props.data.uniqueId ||
      selectedNudgeData?.uniqueId === props.data.uniqueId
    ) {
      dispatch(setClearSelectedNudgeData());
    } else {
      dispatch(setClearSelectedNudgeData());
      dispatch(
        setSelectedNudgeData({
          ...props.data,
        })
      );
    }
    console.log("settinggggg", props.data);
  }

  useEffect(() => {
    console.log(selectedNudgeData, props.data, "dataaaaa");
    if (props.data?.uniqueId === selectedNudgeData?.uniqueId) {
      setSetting({ ...selectedNudgeData });
    } else {
      setSetting({ ...props?.data });
    }
  }, [props.data]);

  console.log(selectedNudgeData, setting, "dataaaaa");

  useEffect(() => {
    const checkOutComes = disposition && Object.keys(disposition)?.length > 0;
    const checkFollowUps =
      followUpsData && Object.keys(followUpsData)?.length > 0;
    const checkUSeCaseValidation: any = currentUseCaseId !== USECASE?.debtCollection?.id
    if (hasAllEssentials(setting, checkOutComes, checkFollowUps, checkUSeCaseValidation))
      setValid(true);
    else {
      setValid(false);
    }
  }, [setting]);

  // for enhancement of startegy
  function getChannelForFollowUp(channel: string) {
    const arrOfChannel = ["call", "whatsapp", "sms", "mail"];
    const arrOfChannelForFollowUps = ["Call", "WhatsApp", "SMS", "Mail"];

    const index = arrOfChannel.indexOf(channel.toLowerCase());
    if (index !== -1) {
      return arrOfChannelForFollowUps[index];
    } else {
      return "Not Found";
    }
  }

  useEffect(() => {
    if (
      setting.channel &&
      disposition &&
      Object.keys(disposition).length === 0 &&
      followUpsData &&
      Object.keys(followUpsData).length === 0
    ) {
      dispatch(
        getOutComesDispositions({
          token: token,
          useCaseId: currentUseCaseId,
          accountId: accountId,
          selectedChannel: getChannelForFollowUp(setting.channel),
        })
      );
      dispatch(
        getFollowUpsDispositions({
          token: token,
          useCaseId: currentUseCaseId,
          accountId: accountId,
          selectedChannel: getChannelForFollowUp(setting.channel),
        })
      );
    }
  }, [setting.channel]);

  // const onChangeRemoveCondition = (data: any) => {
  //   if (
  //     setting.channel !== data.channel &&
  //     data.channel !== "" &&
  //     setting?.channel?.length
  //   ) {
  //     // toRemoveCondition()
  //     const dataToUpdate = setting;
  //     delete dataToUpdate["acceptedOutcome"];
  //     delete dataToUpdate["unacceptedOutcome"];
  //     delete dataToUpdate["communicationWays"];
  //     setSetting({ ...setting, ...data });
  //   }
  //   setSetting({ ...setting, ...data });
  // };

  // useEffect(() => {
  //   if (selectedScript?.uniqueId === props?.data.uniqueId) {
  //     if (selectedScript && Object.keys(selectedScript)?.length > 0) {
  //       if (
  //         props?.data.channel !== selectedScript.channel &&
  //         selectedScript.channel !== "" &&
  //         props?.data?.channel?.length
  //       ) {
  //         const dataToUpdate = props?.data;
  //         delete dataToUpdate["acceptedOutcome"];
  //         delete dataToUpdate["unacceptedOutcome"];
  //         delete dataToUpdate["followUps"];
  //         dataToUpdate["channel"] = selectedScript.channel;
  //         dataToUpdate["communicationWays"] = selectedScript.communicationWays;
  //         dataToUpdate["scriptConfiguration"] =
  //           selectedScript.scriptConfiguration;
  //         setSetting({ ...setting, ...dataToUpdate });
  //       } else {
  //         setSetting({
  //           ...props?.data,
  //           ...setting,
  //           ...selectedScript,
  //         });
  //       }
  //     }
  //   }
  // }, [selectedScript]);

  // console.log(
  //   props.selectedOne === props.data.uniqueId,
  //   selectedNudgeData?.uniqueId === props.data.uniqueId,
  //   "nudge"
  // );

  return (
    <div className={styles.wrapper_}>
      <div
        className={styles.wrapper_top_channel}
        onClick={() => handleClickOnChannel(props.data)}
      >
        {setting.channel ? (
          <img
            src={
              setting.channel === "call"
                ? blueCall
                : setting.channel === "mail"
                  ? mailyellow
                  : setting.channel === "whatsApp"
                    ? greenWhatsapp
                    : smsblue
            }
            alt=""
            id="auto_channel_img"
          />
        ) : (
          <div className={styles.dummy_div}></div>
        )}
        <p>
          {`${setting.flow}
          ${setting.days?.length == 1 && setting.days[0] != 0
              ? setting.days[0]
              : ""
            }
          `}
        </p>
      </div>
      {(props.selectedOne === props.data.uniqueId ||
        selectedNudgeData?.uniqueId === props.data.uniqueId) && (
          <>
            <Title
              onClick={setSelectedOne}
              onClickExtraFunc={() => {
                // if (props.data && Object.keys(props.data).length > 0) {
                //  dispatch(setClearSelectedNudgeData());
                //   dispatch(setSelectedNudgeData(props.data));
                // }
                dispatch(setIsScriptSelectVisited(true));
                navigate("/strategy/scriptselection");
              }}
              open={selectedOne == "Script Configuration"}
              warningIcon={
                !(
                  "scriptConfiguration" in setting &&
                  handleScriptValidation(
                    setting?.scriptConfiguration,
                    setting?.flow
                  )
                )
              }
              title={
                !(
                  "scriptConfiguration" in setting &&
                  handleScriptValidation(
                    setting?.scriptConfiguration,
                    setting?.flow
                  )
                )
                  ? "Script Selection"
                  : "Script Selected"
              }
              actualKey={"Script Configuration"}
              selectedKey={selectedOne}
              hideArrowIcon={true}
            >
              <></>
            </Title>
            {/* <Title
            onClick={setSelectedOne}
            open={selectedOne == "Channel"}
            warningIcon={!("channel" in setting && setting.channel.length)}
            title={"Channel"}
            actualKey={"Channel"}
            selectedKey={selectedOne}
          >
            <Channel
              disp={(data: any) => onChangeRemoveCondition(data)}
              defaultValue={setting.channel ? setting.channel : ""}
            />
          </Title>
          {setting.channel == "whatsApp" && (
            <Title
              onClick={setSelectedOne}
              open={selectedOne == "Communication Ways"}
              warningIcon={
                !(
                  "communicationWays" in setting &&
                  setting.communicationWays?.length
                )
              }
              title={"Communication Ways"}
              actualKey={"Communication Ways"}
              selectedKey={selectedOne}
            >
              <CommunicationWays
                disp={(data: any) => dispatch(setSelectedNudgeData(data))}
                defaultValue={setting.communicationWays}
                // defaultValue={
                //   setting.cummunicationWays ? setting.cummunicationWays : ""
                // }
              />
            </Title>
          )} */}
            {disposition && Object.keys(disposition).length > 0 ? (
              <Title
                onClick={setSelectedOne}
                open={selectedOne == "Conditions"}
                warningIcon={
                  !(
                    "acceptedOutcome" in setting &&
                    Object.keys(setting["acceptedOutcome"]).length &&
                    "unacceptedOutcome" in setting &&
                    Object.keys(setting["unacceptedOutcome"]).length
                  )
                }
                title={"Conditions"}
                actualKey={"Conditions"}
                selectedKey={selectedOne}
              >
                <Conditions
                  disp={(data: any) => {
                    changeSetting(data);
                    dispatch(setSelectedNudgeData(data));
                  }}
                  defaultValue={setting}
                  acceptedOutcome={disposition?.["Accepted Outcome"]}
                  unacceptedOutcome={disposition?.["Unaccepted Outcome"]}
                />
              </Title>
            ) : null}
            {followUpsData &&
              Object.keys(followUpsData).length > 0 &&
              "acceptedOutcome" in setting &&
              Object.keys(setting["acceptedOutcome"]).length &&
              "unacceptedOutcome" in setting &&
              Object.keys(setting["unacceptedOutcome"]).length ? (
              <Title
                onClick={setSelectedOne}
                open={selectedOne == "followUps"}
                warningIcon={
                  !(
                    "followUps" in setting &&
                    Object.values(setting.followUps).length > 0 &&
                    !_.some(setting.followUps, _.isUndefined)
                  )
                }
                title={"Retry Time"}
                actualKey={"followUps"}
                selectedKey={selectedOne}
              >
                <FollowUps
                  defaultValue={setting.followUps}
                  onChangeValue={(data: any) => {
                    changeSetting(data);
                    dispatch(setSelectedNudgeData(data));
                  }}
                  options={followUpsData}
                  optionsAccepted={
                    "acceptedOutcome" in setting &&
                      Object.keys(setting["acceptedOutcome"]).length
                      ? Object.keys(setting["acceptedOutcome"])
                      : []
                  }
                  optionsUnAccepted={
                    "unacceptedOutcome" in setting &&
                      Object.keys(setting["unacceptedOutcome"]).length
                      ? Object.keys(setting["unacceptedOutcome"])
                      : []
                  }
                />
              </Title>
            ) : null}
            {/* <Title
            onClick={setSelectedOne}
            open={selectedOne == "Variation"}
            warningIcon={
              !("variation" in setting && setting?.variation?.length)
            }
            title={"Variation"}
            actualKey={"Variation"}
            selectedKey={selectedOne}
          >
            <Variation
              defaultValue={setting.variation}
              disp={(data: any) => dispatch(setSelectedNudgeData(data))}
            />
          </Title> */}
            {/* <Title
            onClick={setSelectedOne}
            open={selectedOne == "Agent Desktop"}
            warningIcon={
              !(
                "agentDesktop" in setting &&
                (setting?.agentDesktop === false ||
                  setting?.agentDesktop === true)
              )
            }
            title={"Agent Desktop"}
            actualKey={"Agent Desktop"}
            selectedKey={selectedOne}
          >
            <AgentDesktop
              defaultValue={setting.agentDesktop}
              disp={(data: any) => dispatch(setSelectedNudgeData(data))}
            />
          </Title> */}
            <Title
              onClick={setSelectedOne}
              open={selectedOne == "Timing"}
              warningIcon={
                !(
                  "timing" in setting &&
                  setting.timing.length &&
                  setting["timing"][0]?.split(":").join() <
                  setting["timing"][1]?.split(":").join()
                )
              }
              title={"Nudge Duration"}
              actualKey={"Timing"}
              selectedKey={selectedOne}
            >
              <Timing
                defaultValue={setting.timing}
                disp={(data: any) => {
                  changeSetting(data);
                  dispatch(setSelectedNudgeData(data));
                }}
              />
            </Title>
            <Title
              onClick={setSelectedOne}
              open={selectedOne == "reattempt"}
              warningIcon={!("reattempt" in setting && setting?.reattempt > 0)}
              title={"Attempt Count"}
              actualKey={"reattempt"}
              selectedKey={selectedOne}
            >
              <AttemptCount
                defaultValue={setting.reattempt}
                disp={(data: any) => {
                  changeSetting(data);
                  dispatch(setSelectedNudgeData(data));
                }}
              />
            </Title>

            {
              (moduleSelectedScreen === "Strategy" ? showPaymentLinkStrategy : campaignData?.isPaymentLink)
              && USECASE?.debtCollection?.id === currentUseCaseId &&
              <Title
                onClick={setSelectedOne}
                open={selectedOne == "Payment Link"}
                warningIcon={false}
                title={"Payment Link"}
                actualKey={"Payment Link"}
                selectedKey={selectedOne}
              >
                <PaymentLink
                  disp={(data: any) => dispatch(setPropForEachNudge(data))}
                  defaultValue={setting.paymentLinkDisposition}
                  paymentLink={campaignData?.isPaymentLink}
                  acceptedOutcome={disposition?.["Accepted Outcome"]}
                  unacceptedOutcome={disposition?.["Unaccepted Outcome"]}
                />
              </Title>
            }

            {setting.channel?.toLowerCase() !== "whatsapp" && USECASE?.debtCollection?.id === currentUseCaseId ? (
              <Title
                onClick={setSelectedOne}
                open={selectedOne == "callBackThreshold"}
                warningIcon={
                  !(
                    "callBackThreshold" in setting &&
                    Object.keys(setting.callBackThreshold)?.length &&
                    setting.callBackThreshold.days > 0
                  )
                }
                title={"Call Back Threshold"}
                actualKey={"callBackThreshold"}
                selectedKey={selectedOne}
              >
                <Callback
                  defaultValue={setting.callBackThreshold}
                  disp={(data: any) => {
                    changeSetting(data);
                    dispatch(setSelectedNudgeData(data));
                  }}
                />
              </Title>
            ) : null}
            {
              USECASE?.debtCollection?.id === currentUseCaseId &&
              <Title
                onClick={setSelectedOne}
                open={selectedOne == "promiseToPayThreshold"}
                warningIcon={
                  !(
                    "promiseToPayThreshold" in setting &&
                    Object.keys(setting.promiseToPayThreshold)?.length &&
                    setting.promiseToPayThreshold.days > 0
                  )
                }
                title={"Promise To Pay Threshold"}
                actualKey={"promiseToPayThreshold"}
                selectedKey={selectedOne}
              >
                <PromiseToPayThreshold
                  defaultValue={setting.promiseToPayThreshold}
                  disp={(data: any) => {
                    changeSetting(data);
                    dispatch(setSelectedNudgeData(data));
                  }}
                />
              </Title>
            }


            <Title
              onClick={setSelectedOne}
              open={selectedOne == "alternateNumber"}
              warningIcon={false}
              title={"Alternate Number"}
              actualKey={"alternateNumber"}
              selectedKey={selectedOne}
            >
              <AlternateNumber
                defaultValue={setting}
                onChangeValue={(data: any) => {
                  changeSetting(data);
                  dispatch(setSelectedNudgeData(data));
                }}
                options={dispositionAlternate}
              />
            </Title>

            {/* <Title
            onClick={setSelectedOne}
            open={selectedOne == "channelLanguageMapping"}
            warningIcon={
              !(
                "channelLanguageMapping" in setting &&
                setting.communicationWays?.length
              )
            }
            title={"Channel's Language Mapping"}
            actualKey={"channelLanguageMapping"}
            selectedKey={selectedOne}
            infoIcon={questionIcon}
          >
            <ChannelsMapping />
          </Title> */}
            <button
              className={`${!valid ? styles.disbaledsbutton : styles.aactivent}`}
              onClick={handleApply}
            >
              Apply
            </button>
          </>
        )}
    </div>
  );
}
