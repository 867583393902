import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./CrmCardComponent.module.scss";
import { getCardName } from "../../../../redux/analytics/actions";

export default function CrmCardComponent(props: any) {
  const dispatch = useDispatch();

  function getCardClicked(name: string) {
    dispatch(getCardName(name));
  }

  const isCardName = useSelector(
    (store: any) => store?.analyticsReducer?.cardName
  );
  const [card, setCard] = useState<string>(isCardName);
  useEffect(() => {
    if (props.data) {
        if(!props.data.isCard){
            setCard("");
        } else{
            setCard(props.data.name);
        }
      
    }
  }, [props.data]);

  return (
    <>
      <div
        className={styles.crmCardClassDiv}
        onClick={() => {
          return getCardClicked(props.data.name);
        }}
        style={{
          background: `${isCardName == card ? "#F9F9F9" : ""}`,
          border: `${isCardName == card ? "none" : ""}`,
          cursor: `${!props.data.isCard ? "default" : "pointer"}`
        }}
      >
        <div className={styles.cardDivName}>{props.data.name}</div>
        <div className={styles.cardDivValue}>{props.data.value}</div>
      </div>
    </>
  );
}
