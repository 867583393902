import React, { useEffect, useRef, useState } from "react";
import CustomerPreviewCardsColumn from "../../../generic/customerPreviewCards/CustomerPreviewCardsColumn";
import { searchIcon } from "../../../../../theme/assets/svg/rightSideIcon";
import "./ChatLoggerLeft.scss";
import FilterButtons from "../filterButtons/FilterButtons";
// import SearchInputBox from "../../../generic/fields/singleSelect/searchInputBox/SearchInputBox";
import SearchInputBox from "../../../../generic/searchInputBox/SearchInputBox";
// import NewPagination from "../../../../generic/pagination/NewPagination";
import NewPaginationAD from "../../../generic/newPaginationAD/NewPaginationAD";
import NoDatamodel from "../../../../agentDesktop/generic/noDatamodel/NoDatamodel";
import {
  getCallSummaryAgentDesktopChat,
  getCallTranscriptAgentDesktopChat,
  getSessionIdAgentDesktopChat,
  setSelectedSessionIdChat,
  getStatusForChat,
  setSummaryUpdateSuccess,
  setMessageStatusGroup,
} from "../../../../../redux/agentDesktop/chatLogger/action";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import useDebounce from "../../../../../hooks/useDebounce/UseDebounce";
import Loader from "../../../generic/loader/Loader";
import {
  setOngoingCallChatConversationId,
  setOngoingCallChatSessionId,
} from "../../../../../redux/agentDesktop/BaseScreenAD/action";
import { recentChat } from "../../../../../redux/agentDesktop/chatLogger/interface";
import { noPhoneDataIcon } from "../../../../../theme/assets/svg";
import actionTypes from "../../../../../redux/agentDesktop/chatLogger/actionTypes";

interface props {
  isSessionIdsLoading: boolean;
}
export interface sessionData {
  id: string;
  date: Date;
  name: string;
  sessionId: string;
  previewMessage: string;
  status: {
    status: "open" | "inprogress" | "closed";
    value: number | undefined;
    isWatching: boolean;
  };
  tooltip: string;
  onClick?: (id: string) => void;
  selected?: boolean;
}
export default function ChatLogger(props: props) {
  /* redux hooks ------------ */
  const totalPage = useSelector(
    (state: RootState) =>
      state.agentDesktopReducer.chatLogger.sessionIds.totalPages
  );
  const accountName: any = useSelector<RootState>(
    (state) =>
      state.loginReducer?.userLoginInfo?.userDetail?.accountDetails[0]?.name
  );
  const sessionLoading: any = useSelector(
    (store: RootState) => store.agentDesktopReducer?.chatLogger.sessionLoading
  );
  const rootPortalType = useSelector(
    (store: RootState) => store.baseScreen.baseScreenState.rootPortalScreen
  );
  const agentId: any = useSelector<RootState>(
    (state) => state.loginReducer.userLoginInfo.userDetail._id
  );
  const communicationType = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.communicationType
  );
  const sessionIdRedux: any = useSelector<RootState>(
    (state) => state.agentDesktopReducer.chatLogger?.sessionIds?.results
  );
  const onGoingCallChatSessionId = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.onGoingCallChatSessionId
  );
  const messageStatusGroup = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.chatLogger.messageStatusGroup
  );
  const onGoingCallChatConversationId = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.onGoingCallChatConversationId
  );
  const [statusOpen, statusClose, statusInprogress]: any =
    useSelector<RootState>((state) => {
      return [
        state.agentDesktopReducer.chatLogger.status?.data
          ?.agentChatStatusDetails?.open,
        state.agentDesktopReducer.chatLogger.status?.data
          ?.agentChatStatusDetails?.closed,
        state.agentDesktopReducer.chatLogger.status?.data
          ?.agentChatStatusDetails?.["in-progress"],
      ];
    });
  const recentChat: recentChat[] = useSelector(
    (state: any) => state.agentDesktopReducer.chatLogger.recentChat
  );
  const dispatch = useDispatch();
  const _isMounted = useRef(false);

  /* useStates ---------- */
  const [currentSelected, setCurrentSelected] = useState("");
  const [pagination, setPagination] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sessionDdata, setSessionData] = useState<sessionData[]>([]);
  const [status, setStatus] = useState<"Closed" | "In-progress" | "Open" | "">(
    messageStatusGroup
  );

  /* other hooks ------------ */
  const debouncedSearch = useDebounce((t: any) => {
    // console.log("debouncing", t);
    setSearchText(t);
  }, 500);

  /* useEffects ------------- */
  useEffect(() => {
    if (_isMounted.current) {
      dispatch(
        getStatusForChat({
          accountName,
          agentIds: [agentId],
          channel: ["Whatsapp"],
        })
      );
    }
  }, [accountName, agentId, status]);

  // useEffect(() => {
  //   const s = setInterval(() => {
  //     getSessionIds();
  //   }, 3000);
  //   return () => clearInterval(s);
  // }, []);

  useEffect(() => {
    if (agentId) {
      dispatch({ type: actionTypes.SET_SESSION_ID_LOADING_AGENT_DESKTOP_CHAT });
      setTimeout(() => {
        getSessionIds();
        dispatch(
          getStatusForChat({
            accountName,
            agentIds: [agentId],
            channel: ["Whatsapp"],
          })
        );
      }, 2000);
    }
  }, []);

  useEffect(() => {
    if (agentId && _isMounted.current) {
      getSessionIds();
    } else {
      _isMounted.current = true;
    }
    // setSessionData(dummyData);
  }, [agentId, status, pagination, searchText]);
  useEffect(() => {
    setSearchText("");
  }, [status]);
  useEffect(() => {
    // if (onGoingCallChatConversationId) {
    dispatch(getCallSummaryAgentDesktopChat(onGoingCallChatConversationId));
    dispatch(getCallTranscriptAgentDesktopChat(onGoingCallChatConversationId));
    dispatch(setSummaryUpdateSuccess(false));
    // }
  }, [onGoingCallChatConversationId]);
  useEffect(() => {
    if (sessionIdRedux) {
      setSessionData(() => {
        const prev = sessionIdRedux.flatMap((eachSessionId: any) => {
          const session = eachSessionId.recentSessionId;
          return {
            name: session.customerName,
            date: new Date(session?.recentMessage?.updatedAt),
            sessionId: session?.sessionId,
            id: session?.id,
            previewMessage: session?.recentMessage?.text,
            tooltip: "todo",
            status: {
              status: "none",
              value: 0,
              isWatching: false,
            },
          };
        });
        const addedMessage = prev.map((e: sessionData) => {
          const msgFromSocket = recentChat.filter(
            (eachRecentChat) => eachRecentChat.id === e.id
          );
          if (msgFromSocket.length > 0) {
            e.previewMessage = msgFromSocket[0].latestMessage;
          }
          return e;
        });

        return addedMessage;
      });
      //set latest message

      if (sessionIdRedux?.length > 0) {
        setCurrentSelected(sessionIdRedux[0]?.recentSessionId?.id);
        dispatch(
          setSelectedSessionIdChat(
            sessionIdRedux[0]?.recentSessionId?.sessionId
          )
        );

        dispatch(
          setOngoingCallChatSessionId(
            sessionIdRedux[0]?.recentSessionId?.sessionId
          )
        );
        dispatch(
          setOngoingCallChatConversationId(
            sessionIdRedux[0]?.recentSessionId?.id
          )
        );
        // dispatch(
        //   setOngoingCallChatSessionId(
        //     sessionIdRedux[0]?.recentSessionId?.sessionId
        //   )
        // );
      } else {
        setCurrentSelected("");
        dispatch(setSelectedSessionIdChat(""));
        dispatch(setOngoingCallChatSessionId(""));
        dispatch(setOngoingCallChatConversationId(""));
      }
    } else {
      setSessionData([]);
      setCurrentSelected("");
      dispatch(setSelectedSessionIdChat(""));
      dispatch(setOngoingCallChatSessionId(""));
      dispatch(setOngoingCallChatConversationId(""));
    }
  }, [sessionIdRedux, recentChat]);
  useEffect(() => {
    let interval: any;
    if (communicationType === "Call" && rootPortalType === "") {
      interval = setInterval(() => {
        dispatch(
          getCallTranscriptAgentDesktopChat(onGoingCallChatConversationId)
        );
      }, 2000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [communicationType]);
  // useEffect(() => {
  //   if (onGoingCallChatConversationId) {
  //     dispatch(getCallSummaryAgentDesktopChat(onGoingCallChatConversationId));
  //     dispatch(
  //       getCallTranscriptAgentDesktopChat(onGoingCallChatConversationId)
  //     );
  //   }
  // }, []);

  //*---------------------SET MESSAGES START-------------------------------
  // function setLatestMessages(recentChat: recentChat[]) {
  //   setSessionData((prev) => {
  //     const addedMessage = prev.map((e) => {
  //       const msgFromSocket = recentChat.filter(
  //         (eachRecentChat) => eachRecentChat.id === e.id
  //       );
  //       if (msgFromSocket.length > 0) {
  //         e.previewMessage = msgFromSocket[0].latestMessage;
  //       }
  //       return e;
  //     });

  //     return addedMessage;
  //   });
  // }
  // useEffect(() => {
  //   setLatestMessages(recentChat);
  // }, [recentChat]);
  //*----------------------SET MESSAGES END------------------------------------

  /* handler --------------------- */
  const handleCardClick = (id: string, sessionId: string) => {
    setCurrentSelected(id);
    dispatch(setSelectedSessionIdChat(sessionId));
    dispatch(setOngoingCallChatSessionId(sessionId));
    dispatch(setOngoingCallChatConversationId(id));
  };
  const handleStatusClick = (e: "Open" | "In-progress" | "Closed" | "") => {
    if (e !== "") {
      setStatus(e);
      dispatch(setMessageStatusGroup(e));
    }
  };

  /* helper functions --------------- */
  function getSessionIds() {
    dispatch(
      getSessionIdAgentDesktopChat({
        accountName,
        channel: ["Whatsapp"],
        // dategte: "sd",
        // datelte: "",
        limit: 8,
        page: status === "Closed" ? pagination : 1,
        agentIds: [agentId],
        chatStatus:
          status === "Closed"
            ? ["closed"]
            : status === "In-progress"
            ? ["in-progress"]
            : status === "Open"
            ? ["open"]
            : [],
        phoneNo: searchText,
      })
    );
  }

  return (
    <div className="chat-logger__left__wrapper">
      <div className="filter-button">
        <FilterButtons
          data={[
            { name: "Open", value: statusOpen },
            { name: "In-progress", value: statusInprogress },
            { name: "Closed", value: statusClose },
          ]}
          onChange={handleStatusClick}
          currentSelected={status}
        />
      </div>
      {status === "Closed" && (
        <div className={"search"}>
          <SearchInputBox
            extraClassWrapper="wrapper__extraClass"
            searchIcon={searchIcon}
            onChange={(e: any) => {
              debouncedSearch(e.target.value);
              // setSearchText(e.target.value);
              // console.log("debouncing");
              // console.log("searchText", e.target.value);
            }}
            maxLength={200}
            placeholder="Search"
          />
        </div>
      )}
      <div className="customer-preview-card-column">
        {sessionLoading ? (
          <div className="loader">
            <Loader />
          </div>
        ) : sessionDdata.length > 0 ? (
          <CustomerPreviewCardsColumn
            data={sessionDdata}
            onClick={handleCardClick}
            selectedId={currentSelected}
          />
        ) : (
          <> {<NoDatamodel srcImg={noPhoneDataIcon}></NoDatamodel>}</>
        )}
      </div>
      {status === "Closed" && (
        <div className={"pagination"}>
          {totalPage > 0 && (
            <NewPaginationAD
              totalNoOfPage={totalPage}
              getPage={pagination - 1}
              handlePageChange={(e: any) => {
                setPagination(e);
                console.log(e, "pagination");
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}
// function dispatch(arg0: {
//   type: string;
//   payload: {
//     accountName: string;
//     channel: string[];
//     limit: number;
//     page: number;
//     phoneNo?: "" | undefined;
//     agentIds: string[];
//   };
// }) {
//   throw new Error("Function not implemented.");
// }

// const data: {
//   id: string;
//   date: Date;
//   name: string;
//   previewMessage: string;
//   status: {
//     status: "open" | "inprogress" | "closed";
//     value: number | undefined;
//     isWatching: boolean;
//   };
//   tooltip: string;
//   onClick?: (id: string) => void;
//   selected?: boolean;
// }[] = [
//   {
//     id: "89743897979",
//     date: new Date(),
//     name: "Nithin N",
//     previewMessage: "this is dummy message",
//     status: { status: "open", value: 1, isWatching: false },
//     tooltip: "open",
//   },
//   {
//     id: "8978974975",
//     date: new Date(),
//     name: "Mallika arjun Mg",
//     previewMessage:
//       "all work no play make jonny a dull boy, fjskdjflsjf lsajdlfjslfjlsjflsjdlfjas li",
//     status: { status: "closed", value: 5, isWatching: false },
//     tooltip: "closed",
//   },
//   {
//     id: "8972897971",
//     date: new Date("11/11/11"),
//     name: "Nithin N",
//     previewMessage: "this is dummy message",
//     status: { status: "inprogress", value: 4, isWatching: false },
//     tooltip: "inprogress",
//   },
//   {
//     id: "8972897971",
//     date: new Date("11/11/11"),
//     name: "Nithin N",
//     previewMessage: "this is dummy message",
//     status: { status: "inprogress", value: 4, isWatching: false },
//     tooltip: "inprogress",
//   },
//   {
//     id: "8978597972",
//     date: new Date(),
//     name: "Nithin N",
//     previewMessage: "this is dummy message",
//     status: { status: "inprogress", value: 6, isWatching: false },
//     tooltip: "inprogress",
//   },
//   {
//     id: "8978917971",
//     date: new Date(),
//     name: "Nithin N",
//     previewMessage: "this is dummy message",
//     status: { status: "inprogress", value: 7, isWatching: false },
//     tooltip: "inprogress",
//   },
//   {
//     id: "8978597975",
//     date: new Date(),
//     name: "Mallika arjun Mg",
//     previewMessage:
//       "all work no play make jonny a dull boy, fjskdjflsjf lsajdlfjslfjlsjflsjdlfjas li",
//     status: { status: "closed", value: 3, isWatching: false },
//     tooltip: "closed",
//   },
//   {
//     id: "89783497971",
//     date: new Date(),
//     name: "Nithin N",
//     previewMessage: "this is dummy message",
//     status: { status: "inprogress", value: 2, isWatching: false },
//     tooltip: "inprogress",
//   },
//   {
//     id: "89785297975",
//     date: new Date(),
//     name: "Mallika arjun Mg",
//     previewMessage:
//       "all work no play make jonny a dull boy, fjskdjflsjf lsajdlfjslfjlsjflsjdlfjas li",
//     status: { status: "closed", value: 5, isWatching: false },
//     tooltip: "closed",
//   },
// ];
