import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import RenderInput from "./renderInput/RenderInput";
import "./SingleDateCalender.scss";
import TextAreaInput from "../textAreaInput/TextAreaInput";

interface props {
  value: Date;
  // eslint-disable-next-line no-unused-vars
  handleChange: (newValue: Date | null, key?: string) => void;
  objectKey?: string;
  disableField?: boolean;
  extraClass?: string;
}

export default function SingleDateCalender(props: props) {
  return (
    <div
      className={`single-date-calender ${
        props.disableField ? "disablePointerEventWithoutOpacity" : ""
      }`}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          // className={"calender-extra-class"}
          // DialogProps={{ classes: { root: "calender-extra-class" } }}
          views={["year", "month", "day"]}
          label="Basic example"
          value={props.value}
          onChange={(newValue: Date | null) => {
            props.handleChange(newValue, props.objectKey);
          }}
          dayOfWeekFormatter={(day) => day.toUpperCase()}
          renderInput={(params) => (
            <RenderInput {...params} value={props.value} />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
