const agentActions = {
  GET_ALL_AGENTS_API_REQUEST: "GET_ALL_AGENTS_REQUEST",
  GET_ALL_AGENTS_API_SUCCESS: "GET_ALL_AGENTS_SUCCESS",
  GET_ALL_AGENTS_API_FAILURE: "GET_ALL_AGENTS_API_FAILURE",

  SET_AGENT_TYPES_TO_SHOW: "SET_AGENT_TYPES_TO_SHOW",

  CREATE_AGENT_API_REQUEST: "CREATE_AGENT_API_REQUEST",
  CREATE_AGENT_API_SUCCESS: "CREATE_AGENT_API_SUCCESS",
  CREATE_AGENT_API_FAILURE: "CREATE_AGENT_API_FAILURE",

  DELETE_AGENT_API_REQUEST: "DELETE_AGENT_API_REQUEST",
  DELETE_AGENT_API_SUCCESS: "DELETE_AGENT_API_SUCCESS",
  DELETE_AGENT_API_FAILURE: "DELETE_AGENT_API_FAILURE",

  EDIT_AGENT_API_REQUEST: "EDIT_AGENT_API_REQUEST",
  EDIT_AGENT_API_SUCCESS: "EDIT_AGENT_API_SUCCESS",
  EDIT_AGENT_API_FAILURE: "EDIT_AGENT_API_FAILURE",

  SET_AGENT_DATA: "SET_AGENT_DATA",
  SET_SELECTED_AGENT_API_REQUEST: "SET_SELECTED_AGENT_API_REQUEST",
  SET_SELECTED_AGENT_API_SUCCESS: "SET_SELECTED_AGENT_API_SUCCESS",
  SET_SELECTED_AGENT_API_FAILURE: "SET_SELECTED_AGENT_API_FAILURE",

  GET_ALL_CAMPAIGN_SUMMARY_DATA_REQUEST:
    "GET_ALL_CAMPAIGN_SUMMARY_DATA_REQUEST",
  GET_ALL_CAMPAIGN_SUMMARY_DATA_SUCCESS:
    "GET_ALL_CAMPAIGN_SUMMARY_DATA_SUCCESS",
  GET_ALL_CAMPAIGN_SUMMARY_DATA_FAILURE:
    "GET_ALL_CAMPAIGN_SUMMARY_DATA_FAILURE",

  CLEAR_AGENT_DATA: "CLEAR_AGENT_DATA",
  SET_AGENT_TO_EDIT: "SET_AGENT_TO_EDIT",
  CLEAR_DOWNLOAD_ZIP: "CLEAR_DOWNLOAD_ZIP",

  GET_DOWNLOAD_CSV_ZIP_API_REQUEST: "GET_DOWNLOAD_CSV_ZIP_API_REQUEST",
  GET_DOWNLOAD_CSV_ZIP_API_SUCCESS: "GET_DOWNLOAD_CSV_ZIP_API_SUCCESS",
  GET_DOWNLOAD_CSV_ZIP_API_FAILURE: "GET_DOWNLOAD_CSV_ZIP_API_FAILURE",
};

export default agentActions;
