import React from "react";
import { useSelector } from "react-redux";
import DialTimeModal from "../../dialTimeModal/DialTimeModal";
import Accord from "./Accord/Accord";

function DialTimeAccord() {
  const checkBoxChecked = (data) => {
    console.log(data);
  };

  const schedularDetails = useSelector((store) => {
    return store.campaignModuleReducer?.schedulerData?.getSchedularDetails
      ?.dialTimeData?.data;
  });

  return (
    <Accord
      title={"Dial Time"}
      content={<DialTimeModal setCheckBox={checkBoxChecked} />}
      isChecked={
        schedularDetails &&
        (schedularDetails?.s_time || schedularDetails?.s_time === 0) &&
        (schedularDetails?.e_time || schedularDetails?.e_time === 0)
          ? true
          : false
      }
      isToggle={false}
      isHideContent={false}
      id="auto_dial_time_accord"
    />
  );
}

export default DialTimeAccord;
