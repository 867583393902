// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RTDv7k04NozrBFkGacux{width:35%;position:fixed;bottom:1%}.RTDv7k04NozrBFkGacux .QhCHimRNtCcVZTvvkL2z{display:flex;padding:0% 3%;padding-top:1%;align-items:center;justify-content:center;background:#fff !important;border:.45px solid #d8d8d8;box-shadow:0px 71.3652px 57.0921px rgba(36,69,152,.05),0px 36.1286px 24.8886px rgba(36,69,152,.03375),0px 14.273px 9.27747px rgba(36,69,152,.025),0px 3.12223px 3.30064px rgba(36,69,152,.01625);border-radius:6.85196px}.RTDv7k04NozrBFkGacux img{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/strategyComponents/nudgeCreation/toolsContainer/ToolContainer.module.scss"],"names":[],"mappings":"AAAA,sBACI,SAAA,CACA,cAAA,CACA,SAAA,CACC,4CACI,YAAA,CACA,aAAA,CACA,cAAA,CACA,kBAAA,CACA,sBAAA,CACD,0BAAA,CACA,0BAAA,CACA,gMAAA,CACA,uBAAA,CAEH,0BACI,cAAA","sourcesContent":[".toolContainerWrapper{\n    width: 35%;\n    position: fixed;\n    bottom: 1%;\n     .toolContainer{\n         display: flex;\n         padding: 0% 3%;\n         padding-top: 1%;\n         align-items: center;\n         justify-content: center;\n        background: #FFFFFF !important;\n        border: 0.45px solid #D8D8D8;\n        box-shadow: 0px 71.3652px 57.0921px rgba(36, 69, 152, 0.05), 0px 36.1286px 24.8886px rgba(36, 69, 152, 0.03375), 0px 14.273px 9.27747px rgba(36, 69, 152, 0.025), 0px 3.12223px 3.30064px rgba(36, 69, 152, 0.01625);\n        border-radius: 6.85196px;\n     }\n     img{\n         cursor: pointer;\n     }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolContainerWrapper": `RTDv7k04NozrBFkGacux`,
	"toolContainer": `QhCHimRNtCcVZTvvkL2z`
};
export default ___CSS_LOADER_EXPORT___;
