import React from "react";
import styles from "./PricingPlanCard.module.scss";
import Button from "../../../generic/button/Button";
import Icon from "../../../generic/icon/Icon";
import { tickPurchaseIcon } from "../../../../theme/assets/profileSectionSvg/paymentSvg/index";

interface PricingPlanCardProps {
  cardIndex: number;
  cardData: any;
  onClickSeeMore?: CallableFunction;
  onClickAdd?: CallableFunction;
  cardBtnText?: string;
  isUpdateFlow?: boolean;
}
export const PricingPlanCard = ({
  cardIndex,
  cardData,
  onClickSeeMore,
  onClickAdd,
  cardBtnText,
  isUpdateFlow,
}: PricingPlanCardProps) => {
  // console.log(isUpdateFlow, "llllll");

  const convertToRupees = (number: string) => {
    const rupees = parseInt(number).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    const formattedRupees = rupees.replace(/\.\d+/, ""); // Remove decimal part if present
    return formattedRupees.replace("₹", "");
  };

  return (
    <div
      className={`${styles.purchasePlanCardDiv}
      ${cardData?.tag?.length > 0 && styles.purchasePlanCardActiveDiv} `}
      key={cardIndex}
      id={`auto_plan_${cardIndex}`}
    >
      <div className={styles.topCardDiv}>
        <div className={styles.cardNameDiv}>
          <div
            className={styles.cardNameText}
            id={`auto_plan_name_${cardIndex}`}
          >
            {cardData?.planName?.replace("Plan", "")}
          </div>

          {cardData?.tag?.length > 0 && (
            <div className={styles.cardStatusDiv}>
              <div
                className={`${styles.cardStatusText}
            ${cardData?.tag?.length > 0 && styles.cardStatusActiveText} `}
                id={`auto_plan_status_${cardIndex}`}
              >
                {cardData?.tag}
              </div>
            </div>
          )}
        </div>
        {cardData?.price !== "Free" ? (
          <div className={styles.cardPricingDiv}>
            <span
              className={styles.cardPricingText}
              id={`auto_plan_pricing_${cardIndex}`}
            >
              {cardData?.price !== "Free"
                ? convertToRupees(cardData?.price)
                : cardData?.price}
              &nbsp;
              {cardData?.currency}
              &nbsp;&nbsp;
            </span>
            <span
              className={styles.cardDurationText}
              id={`auto_plan_duration_${cardIndex}`}
            >
              /30 days
            </span>
          </div>
        ) : (
          <div className={styles.cardPricingDiv}>
            <span
              className={styles.cardPricingText}
              id={`auto_plan_pricing_${cardIndex}`}
            >
              {cardData?.price}
            </span>
          </div>
        )}
        <div className={styles.addToCartDiv}>
          <Button
            text={cardBtnText}
            extraClass={`${styles.addButtonStyling}`}
            onClick={() => {
              onClickAdd && onClickAdd(cardData);
            }}
          />
        </div>
        <div className={styles.cardFeaturesDiv}>
          <div className={styles.cardFeatureHeading}>
            Top Feature Comparision
          </div>
          <div className={styles.cardFeaturesDivWrapper}>
            {cardData?.comparisonFeature?.map(
              (cardData: any, cardIndex: number) => {
                return (
                  <div className={styles.featureDiv} key={cardIndex}>
                    <div className={styles.featureIconDiv}>
                      <Icon
                        img_src={tickPurchaseIcon}
                        extraClass={styles.featureIconStyling}
                      />
                    </div>
                    <div
                      className={styles.featureTextDiv}
                      id={`auto_plan_feature_desc_${cardIndex}`}
                    >
                      <div
                        key={cardIndex}
                        dangerouslySetInnerHTML={{ __html: cardData }}
                      />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
      <div
        className={styles.seeMoreDiv}
        id={`auto_plan_seemore_${cardIndex}`}
        onClick={() => {
          // if (isUpdateFlow === false) {
          onClickSeeMore && onClickSeeMore(cardData);
          // }
          // console.log("msghjdfshj");
        }}
      >
        See More...
      </div>
    </div>
  );
};
