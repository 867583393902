import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import {
  ORGANIZATION_API,
  DEMO_SIGNUP_URL,
  config,
} from "../../../../../services/ApiRoutes";
import actionTypes from "../actionTypes";
import _ from "lodash";
import axios from "axios";
import errorToaster from "../../../../../utils/errorToaster";
import { store } from "../../../../rootStore";

export function* getOrganizationDataAPIWorker(action: any): any {
  // toast.loading("Please wait ...");
  try {
    if (action) {
      const result = yield call(
        config.GET,
        `${ORGANIZATION_API.GET_ORGANIZATION_DATA}${action.payload}`
      );
      yield put({
        type: actionTypes.ORGANIZATION_DATA_API_SUCCESS,
        payload: result.data.data,
      });
    }
    // toast.dismiss();
  } catch (error) {
    yield put({
      type: actionTypes.ORGANIZATION_DATA_API_FAILURE,
      payload: error,
    });
    console.error(error);
    // toast.dismiss();
  }
}

export function* updateOrganizationDataAPIWorker(action: any): any {
  toast.loading("Please wait ...");
  const res = action.payload;
  const { isUpdated, ...updatedPayload } = res;

  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_BODY,
        `${ORGANIZATION_API.UPDATE_ORGANIZATION_DATA}`,
        updatedPayload
      );
      const getResult = yield call(
        config.GET,
        `${ORGANIZATION_API.GET_ORGANIZATION_DATA}${action.payload.id}`
      );
      yield put({
        type: actionTypes.UPDATE_ORGANIZATION_DATA_API_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: actionTypes.ORGANIZATION_DATA_API_SUCCESS,
        payload: getResult.data.data,
      });
      // yield put({
      //   type: actionTypes.FIRST_TIME_ORG_SAVED,
      //   payload: action.payload.firstTimeSaved,
      // });
      yield put({
        type: actionTypes.UPDATE_ORG_DATA_STATUS,
        payload: true,
      });
    }
    toast.dismiss();
    toast.success("Organization Details Updated Successfully");
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_ORGANIZATION_DATA_API_FAILURE,
      payload: error,
    });
    toast.dismiss();
    console.error(error);
  }
}

export function* sendOTPDataAPIWorker(action: any): any {
  toast.dismiss();
  toast.loading("Please wait ...");
  try {
    const res = yield call(
      config.POST_WITH_BODY,
      DEMO_SIGNUP_URL.DEMO_SEND_OTP,
      action.payload
    );
    yield put({
      type: actionTypes.SEND_OTP_ORG_SUCCESS,
      payload: res.data.data,
    });
    yield put({
      type: actionTypes.CLEAR_VERIFY_OTP_ORG,
    });
    toast.dismiss();
    toast.success(res.data.data.message);
  } catch (error) {
    yield put({ type: actionTypes.SEND_OTP_ORG_FAILURE, payload: error });
    toast.dismiss();
    console.error(error);
  }
}

export function* verifyOTPDataAPIWorker(action: any): any {
  toast.dismiss();
  toast.loading("Please wait ...");
  try {
    const res = yield call(
      config.POST_WITH_BODY,
      DEMO_SIGNUP_URL.DEMO_VERIFY_OTP,
      { userId: action.payload.userId, otp: action.payload.otp }
    );
    const result = yield call(
      config.POST_WITH_BODY,
      `${ORGANIZATION_API.UPDATE_ORGANIZATION_DATA}`,
      {
        phoneNumber: action.payload?.phoneNumber,
        id: action.payload?.id,
        name: action.payload?.name,
      }
    );

    yield put({
      type: actionTypes.VERIFY_OTP_ORG_SUCCESS,
      payload: {
        data: res.data.data,
        phoneNumber: action.payload?.phoneNumber,
      },
    });
    yield put({
      type: actionTypes.UPDATE_ORGANIZATION_DATA_API_SUCCESS,
      payload: result.data.data,
    });

    toast.dismiss();
    toast.success("OTP verified successfully");
  } catch (error) {
    yield put({ type: actionTypes.VERIFY_OTP_ORG_FAILURE, payload: error });
    toast.dismiss();
    console.error(error);
  }
}

export function* verifyGstWorker(action: any): any {
  toast.dismiss();
  toast.loading("Verifying GST");
  try {
    const res = yield call(config.POST_WITH_BODY, ORGANIZATION_API.VERIFY_GST, {
      gst: action.payload.gst,
      orgName: action.payload.orgName,
    });

    const apiResponse =
      store.getState()?.profile?.yourAccountReducer?.organization.saveTempData;

    yield put({
      type: actionTypes.VERIFY_GST_ORG_SUCCESS,
      payload: res.data.message,
    });
    yield put({
      type: actionTypes.STORE_GST_PAN_DATA,
      payload: { ...apiResponse, gst: action.payload.gst },
    });

    toast.dismiss();
    toast.success(res.data.message);
  } catch (err: any) {
    yield put({ type: actionTypes.VERIFY_GST_ORG_FAILURE, payload: err });
    toast.dismiss();
    toast.error("GST Validation failed");
    console.error(err);
    // errorToaster(err, err?.response?.data?.error);
  }
}
export function* verifyPanWorker(action: any): any {
  toast.dismiss();
  toast.loading("Verifying PAN");
  try {
    const res = yield call(config.POST_WITH_BODY, ORGANIZATION_API.VERIFY_PAN, {
      pan: action.payload.pan,
      orgName: action.payload.orgName,
    });

    const apiResponse =
      store.getState()?.profile?.yourAccountReducer?.organization.saveTempData;

    yield put({
      type: actionTypes.VERIFY_PAN_ORG_SUCCESS,
      payload: res?.data?.data?.message,
    });
    yield put({
      type: actionTypes.STORE_GST_PAN_DATA,
      payload: { ...apiResponse, pan: action.payload.pan },
    });

    toast.dismiss();
    toast.success(res.data.data.message);
  } catch (err: any) {
    yield put({ type: actionTypes.VERIFY_PAN_ORG_FAILURE, payload: err });
    toast.dismiss();
    toast.error("PAN Validation failed");
    // toast.error(err.response?.data?.details?.[0]?.message);
    console.error(err);
  }
}
export function* sendSignEmailAPIWorker(action: any): any {
  const {payload , func} = action.payload
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_BODY,
        `${ORGANIZATION_API.SEND_MAIL_SIGN}`,
          payload
      );
      yield put({
        type: actionTypes.SEND_SIGN_EMAIL_SUCCESS,
        payload: result.data?.message,
      });

      const getResult = yield call(
        config.GET,
        `${ORGANIZATION_API.GET_ORGANIZATION_DATA}${payload?.accountId}`
      );
      func()
      yield put({
        type: actionTypes.ORGANIZATION_DATA_API_SUCCESS,
        payload: getResult.data.data,
      });
      toast.success(result.data?.message);
    }

   
  } catch (error:any) {
    yield put({
      type: actionTypes.SEND_SIGN_EMAIL_FAILURE,
      payload: error,
    });
    
    toast.error(error);
  }
}
