import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../generic/loader/Loader";
import VerticalBarChart from "../charts/VerticalBarChart";

import styles from "./PerformanceChartModel.module.scss";

// interface props {
//   default: string;
// }
const data= [
  {
    name: "Abhi",
    count: 12,
  },
  {
    name: "Priya",
    count: 11,
  },
  {
    name: "Swati",
    count: 10,
  },
  {
    name: "Gouri",
    count: 9,
  },
  {
    name: "Jai",
    count: 8,
  },
]
const verticalChartarr = [
  {
    chartTitle: "Calls Vs Agent",
    data: [
      {
        name: "Abhi",
        count: 12,
      },
      {
        name: "Priya",
        count: 11,
      },
      {
        name: "Swati",
        count: 10,
      },
      {
        name: "Gouri",
        count: 9,
      },
      {
        name: "Jai",
        count: 8,
      },
    ],
  },
  {
    chartTitle: "Chat Vs Agent",
    data: [
      {
        name: "Abhi",
        count: 12,
      },
      {
        name: "Priya",
        count: 11,
      },
      {
        name: "Swati",
        count: 10,
      },
      {
        name: "Gouri",
        count: 9,
      },
      {
        name: "Jai",
        count: 8,
      },
    ],
  },
  {
    chartTitle: "SLA Vs Agent",
    data: [
      {
        name: "Abhi",
        count: 12,
      },
      {
        name: "Priya",
        count: 11,
      },
      {
        name: "Swati",
        count: 10,
      },
      {
        name: "Gouri",
        count: 9,
      },
      {
        name: "Jai",
        count: 8,
      },
    ],
  },
];

export default function PerformanceChartModel(props: any) {
  const chartData = useSelector(
    (state: any) => state?.agentDesktopReducer?.dashboardReducer?.dashboardChartDetails?.performance
  );

  return (
    <>
      {props.chartDetails &&
        props.chartDetails.map((each: any, i: number) => {
          return (
            <div className={styles.performanceChartWrapper} key={i}>
              <div className={styles.performanceChartHeader}>
                {each.cardName}
              </div>
              <div className={styles.performanceChartBody}>
                {!props.loading ?
                  <VerticalBarChart chartData={chartData} chartDetails={each} />
                  :
                  <Loader />
                  }
              </div>
            </div>
          );
        })}
    </>
  );
}
