export default {
  START_LOGGER_TOUR: "START_LOGGER_TOUR",
  EXPERIENCE_PRAVID: "EXPERIENCE_PRAVID",
  PURCHASE_AND_CLAIM_REWARD: "PURCHASE_AND_CLAIM_REWARD",

  UPDATE_TOUR_SHOW_REQUEST: "UPDATE_TOUR_SHOW_REQUEST",
  UPDATE_TOUR_SHOW_SUCCESS: "UPDATE_TOUR_SHOW_SUCCESS",
  UPDATE_TOUR_SHOW_ERROR: "UPDATE_TOUR_SHOW_ERROR",

  GET_CALL_STATUS_REQUEST: "GET_CALL_STATUS_REQUEST",
  GET_CALL_STATUS_SUCCESS: "GET_CALL_STATUS_SUCCESS",
  GET_CALL_STATUS_ERROR: "GET_CALL_STATUS_ERROR",
  GET_CALL_STATUS_RESET: "GET_CALL_STATUS_RESET",

  HIDE_FILTER_TRANSITION: "HIDE_FILTER_TRANSITION",
  PUSH_ON_TRANSCRIPT: "PUSH_ON_TRANSCRIPT",

  GET_FLOW_EXPERIENCE_REQUEST: "GET_FLOW_EXPERIENCE_REQUEST",
  GET_FLOW_EXPERIENCE_SUCCESS: "GET_FLOW_EXPERIENCE_SUCCESS",
  GET_FLOW_EXPERIENCE_ERROR: "GET_FLOW_EXPERIENCE_ERROR",

  GET_LANGUAGE_EXPERIENCE_REQUEST: "GET_LANGUAGE_EXPERIENCE_REQUEST",
  GET_LANGUAGE_EXPERIENCE_SUCCESS: "GET_LANGUAGE_EXPERIENCE_SUCCESS",
  GET_LANGUAGE_EXPERIENCE_ERROR: "GET_LANGUAGE_EXPERIENCE_ERROR",

  START_TOUR: "START_TOUR",
  SET_COME_FROM_EXPERIENCE_NOW: "SET_COME_FROM_EXPERIENCE_NOW",
};
