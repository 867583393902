import React from "react";
import { Routes, Route } from "react-router-dom";
import RightSideBar from "../../components/generic/rightSidebar/RightSidebar";
import NewRightSidebar from "../../components/agentDesktop/moduleComponents/newRightsideBarFilter/NewRightSidebar";

export default function RightSideBarRoute() {
  return (
    <div>
      <Routes>
        <Route path="calllogger" element={<RightSideBar />} />
        <Route path="dashboard" element={<RightSideBar />} />
        <Route path="agent-dashboard" element={<NewRightSidebar />} />
        <Route path="admin-performance" element={<NewRightSidebar />} />
        <Route path="admin-dashboard" element={<NewRightSidebar />} />
      </Routes>
    </div>
  );
}
