import React, { useState, useEffect } from "react";
import styles from "./UpdateStrategyModal.module.scss";
import Modal from "../../generic/modal/Modal";
import Button from "../../generic/button/Button";
import { useNavigate } from "react-router-dom";
import { createStrategyCloseIcon } from "../../../theme/assets/svg/strategySvg";
import InputField from "../../generic/inputField/InputField";
import TextAreaInput from "../../generic/textareainput/TextAreaInput";
import UploadOptionModal from "../../moduleComponents/campaign_V2.0/uploadOptionPopUp/UploadOptionModal";
import {
  createStrategyAPIData,
  updateStrategyAPIData,
  getWorkflowsAPIData,
} from "../../../redux/strategy/strategyCreation/actions";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux";

interface CreateStrategyModalProps {
  show?: boolean;
  close?: CallableFunction;
  data?: any;
  onclick?: CallableFunction;
  editStrategyPopUp?: boolean;
  dontShow?: string;
  confirm?: CallableFunction;
}

type Strategy = {
  id?: string;
  strategyName: string;
  strategyDescription: string;
};

export const UpdateStrategyModal = ({
  show,
  close,
  data,
  onclick,
  editStrategyPopUp,
  dontShow,
  confirm,
}: CreateStrategyModalProps) => {
  const [strategyInput, setStrategyInput] = useState<Strategy>({
    strategyName: "",
    strategyDescription: "",
  });

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [dataUploadModal, setDataUploadModal] = useState(false);
  const [validCreate, setValidCreate] = useState<boolean>(false);
  const [createApiCall, zsetCreateApiCall] = useState(false);
  const [editApiCall, setEditApiCall] = useState(false);
  const [workFlowModal, setWorkFlowModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const moduleSelectedScreen = useSelector(
    (store: any) => store?.baseScreen?.leftMenu?.highlightedModule
  );
  const clientNameByDropdown = useSelector(
    (store: RootState) => store.campaignReducer?.campaignClientName
  );

  const campaignData = useSelector(
    (store: any) =>
      store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
  );
  const teamUseCaseId = useSelector(
    (store: RootState) => store?.loginReducer?.currentTeam?.useCaseId
  );
  const currentTeamId = useSelector(
    (state: RootState) => state.loginReducer.currentTeam.id
  );

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStrategyInput((prev: any) => {
      return { ...prev, strategyName: event?.target?.value };
    });
  };

  const handleDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStrategyInput((prev: any) => {
      return { ...prev, strategyDescription: event?.target?.value };
    });
  };

  const handleCancel = () => {
    setStrategyInput({
      strategyName: "",
      strategyDescription: "",
    });
  };

  const handleEditCancel = () => {
    setStrategyInput({
      strategyName: data?.strategyName,
      strategyDescription: data?.strategyDescription,
    });
  };

  const clientName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );

  const strategyInfo = useSelector(
    (store: RootState) => store?.strategyModuleReducer?.strategyReducer
  );

  const createdStrategyResult = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.createdStrategy
  );

  const handleCreateRuleDetails = () => {
    const data_to_send: any = {
      // clientName: clientNameByDropdown ? clientNameByDropdown : clientName,
      clientName:
        clientNameByDropdown?.length > 0
          ? clientNameByDropdown
          : campaignData?.accountName?.length > 0
            ? campaignData?.accountName
            : clientName,
      strategyName: strategyInput.strategyName,
      strategyDescription: strategyInput.strategyDescription,
      useCaseId: teamUseCaseId,
      teamId: currentTeamId
    };

    dispatch(
      createStrategyAPIData({
        ...data_to_send,
      })
    );
  };

  // console.log(strategyInput?.strategyDescription.length, "len");

  const handleUpdateRuleDetails = () => {
    const data_to_send: any = {
      // clientName: clientNameByDropdown ? clientNameByDropdown : clientName,
      clientName:
        clientNameByDropdown?.length > 0
          ? clientNameByDropdown
          : campaignData?.accountName?.length > 0
            ? campaignData?.accountName
            : clientName,
      strategyName: strategyInput.strategyName,
      strategyDescription: strategyInput.strategyDescription,
      id: data?.id,
      useCaseId: teamUseCaseId,
      teamId: currentTeamId
    };

    dispatch(
      updateStrategyAPIData({
        ...data_to_send,
      })
    );
  };

  const handleApiCall = () => {
    return editStrategyPopUp
      ? handleUpdateRuleDetails()
      : handleCreateRuleDetails();
  };

  const handleModalCall = () => {
    if (editStrategyPopUp) {
      if (
        strategyInfo?.isLoadingUpdateStrategy === true ||
        strategyInfo.updateStrategyError !== ""
      ) {
        return false;
      }
    } else {
      if (
        strategyInfo?.isLoadingCreateStrategy === true ||
        strategyInfo.createStrategyError !== ""
      ) {
        return false;
      }
    }
    return true;
  };

  const handleModalCallCampaign = () => {
    // close && close();
    if (editStrategyPopUp) {
      if (
        strategyInfo?.isLoadingUpdateStrategy === true ||
        strategyInfo.updateStrategyError !== ""
      ) {
        return false;
      }
    } else {
      if (
        strategyInfo?.isLoadingCreateStrategy === true ||
        strategyInfo.createStrategyError !== ""
      ) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (createApiCall == true || editApiCall == true) {
      moduleSelectedScreen == "Strategy"
        ? setDataUploadModal(handleModalCall())
        : setWorkFlowModal(handleModalCallCampaign());
    }
  }, [
    strategyInfo?.isLoadingCreateStrategy,
    strategyInfo?.isLoadingUpdateStrategy,
    strategyInfo.updateStrategyError,
    strategyInfo?.createStrategyError,
    createApiCall,
    editApiCall,
  ]);

  const handleValidation = () => {
    if (
      strategyInput.strategyDescription !== "" &&
      strategyInput.strategyName === ""
    ) {
      return true;
    } else if (
      strategyInput.strategyDescription === "" &&
      strategyInput.strategyName !== ""
    ) {
      return true;
    } else if (
      strategyInput.strategyDescription === "" &&
      strategyInput.strategyName === ""
    ) {
      return true;
    } else if (
      strategyInput.strategyName.trim().length === 0 ||
      strategyInput.strategyDescription.trim().length === 0
    ) {
      return true;
    } else if (strategyInput.strategyName.length > 18) {
      return true;
    } else if (strategyInput.strategyDescription.length > 250) {
      return true;
    } else if (strategyInfo.isLoadingCreateStrategy == true) {
      return true;
    } else if (strategyInfo.isLoadingUpdateStrategy == true) {
      return true;
    } else {
      return false;
    }
  };

  //console.log(validCreate, "val");
  // console.log(
  //   strategyInput.strategyName.trim().length === 0 ||
  //     strategyInput.strategyDescription.trim().length === 0,
  //   "ggg"
  // );

  useEffect(() => {
    setValidCreate(handleValidation());
  }, [
    strategyInput.strategyName,
    strategyInput.strategyDescription,
    strategyInfo.isLoadingUpdateStrategy,
    strategyInfo.isLoadingCreateStrategy,
  ]);

  useEffect(() => {
    setStrategyInput((prev) => {
      return {
        ...prev,
        strategyName: data?.strategyName ? data?.strategyName : "",
        strategyDescription: data?.strategyDescription
          ? data?.strategyDescription
          : "",
        id: data?.id ? data?.id : "",
      };
    });
  }, [data]);

  return (
    <div>
      <Modal
        shown={show}
        close={() => {
          handleCancel();

          close && close();
        }}
        extraClassStyleModalContent={styles.createStrategyModalStyling}
      >
        <div className={styles.modalInfoDiv}>
          <div className={styles.modalHeading}>
            {editStrategyPopUp ? "Update Strategy" : "Create New Strategy"}
          </div>
          <div className={styles.closeIcon}>
            <img
              src={createStrategyCloseIcon}
              onClick={() => {
                editStrategyPopUp ? handleEditCancel() : handleCancel();

                close && close();
              }}
              className={styles.iconStyling}
            />
          </div>
        </div>

        <div className={styles.strategyFormDiv}>
          <div className={styles.strategyFormNameField}>
            <div className={styles.strategyLabels}>Enter Name:</div>
            <div className={styles.strategyInputDiv}>
              <InputField
                extraClass={styles.inputDivStyling}
                className={styles.inputFieldStyling}
                type="text"
                placeholder="Name"
                onChange={handleNameChange}
                onFocus={() => {
                  setIsReadOnly(false);
                }}
                onBlur={() => {
                  setIsReadOnly(true);
                }}
                value={strategyInput?.strategyName}
                maxLength={19}
              />
              {strategyInput?.strategyName?.length > 18 ? (
                <div className={styles.errorMsgStyling}>
                  Maximum 18 characters are allowed
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={styles.strategyFormDescField}>
            <div className={styles.strategyLabels}>Enter Description:</div>
            <div className={styles.strategyInputDiv}>
              <TextAreaInput
                extraTextClass={styles.inputDescDivStyling}
                extraClass={styles.inputDescStyling}
                handleChange={handleDescChange}
                onFocus={() => {
                  setIsReadOnly(false);
                }}
                onBlur={() => {
                  setIsReadOnly(true);
                }}
                value={strategyInput?.strategyDescription}
                placeholder="Description"
                maxLength={251}
              />
              {strategyInput?.strategyDescription?.length > 250 ? (
                <div className={styles.errorMsgStyling}>
                  Maximum 250 characters are allowed
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className={styles.strategyButtonDiv}>
          <Button
            text="Cancel"
            extraClass={`${styles.buttonStyling} ${styles.cancelButtonStyle}`}
            onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation();
              close && close();
            }}
          // disabled={
          //   strategyInput?.strategyName === "" &&
          //   strategyInput?.strategyDescription === ""
          //     ? isDisabled
          //     : !isDisabled
          // }
          />
          <Button
            text={"Confirm"}
            extraClass={`${styles.buttonStyling} ${validCreate
              ? styles.createButtonInActiveStyle
              : styles.createButtonActiveStyle
              }`}
            onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleApiCall();
              handleUpdateRuleDetails();
              confirm && confirm();
              e.stopPropagation();
              close && close();
            }}
            disabled={validCreate}
          />
        </div>
      </Modal>
    </div>
  );
};
