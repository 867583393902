import React, { useState, useEffect } from "react";
import { validateInputMeta } from "../../../utils/validators/validators";
import "./TextAreaInput.css";

const TextAreaInput = (props) => {
  const [inputMessage, setInputMessage] = useState();
  const handleChangeInputMessage = (e) => {
    if (!validateInputMeta(e.target.value)) return;
    const temp = e.target.value;
    setInputMessage((prev) => temp);
    props.handleMessage(temp);
  };

  useEffect(() => {
    if (props?.defaultMessage) {
      setInputMessage((prev) => props?.defaultMessage);
    }
  }, [props?.defaultMessage]);

  return (
    <>
      <div className="textAreaWrapper">
        <div className="textAreaInputTopDiv">
          <div
            className={`fdFormTextareajpp ${
              props?.contSmall && props?.contSmall == true ? "smallify" : ""
            } ${props.extraTextClass ? props.extraTextClass : ""}`}
          >
            <textarea
              className={props.extraClass ? props.extraClass : ""}
              //value={inputMessage}
              value={props.value}
              rows="4"
              placeholder={props.placeholder ? props.placeholder : ""}
              //   onChange={handleChangeInputMessage}
              onChange={props.handleChange}
              style={{
                width: "97%",
                border: "none",
                outline: "none",
                // padding: "1vmax",
                resize: "none",
                borderRadius: "7px",
              }}
              onFocus={props.inputOnFocus}
              onBlur={props.inputOnBlur}
              maxLength={props.maxLength ? props.maxLength : ""}
              id={props?.id ? props?.id : ""}
              name={props?.name ? props?.name : ""}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TextAreaInput;
