import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";

export default function PravidIcons(props: any) {
  // const iconUrl = useSelector(
  //   (state: RootState) => state?.loginReducer?.userLoginInfo?.iconsBaseUrl
  // );
  const iconUrl = useSelector(
    (state: RootState) =>`${process.env.REACT_APP_PRAVID_ICON}`
    
  );

  // return props.donutIcon ? (
  //   <img
  //     src={`${iconUrl}${
  //       props.isInActive ? props.inActiveIcon : props.activeIcon
  //     }${`.svg#svgView(viewBox(0,0,30,25))`}`}
  //     style={props.extraStyle ? props.extraStyle : {}}
  //     onClick={(e) => {
  //       if (props.onClick && !props.disabled) {
  //         props.onClick(e);
  //       }
  //     }}
  //     className={
  //       // "icon " +
  //       (props.disabled ? "disabled " : "") +
  //       " " +
  //       (props.extraClass ? props.extraClass : "")
  //     }
  //     alt={
  //       props.altText
  //         ? props.altText
  //         : props.isActive
  //         ? props.activeIcon
  //         : props.inActiveIcon
  //     }
  //     data-type={props.datatype}
  //   />
  // ) :
  //console.log("icon" , iconUrl+`?${process.env.REACT_APP_ACCESS_TOKEN_ICON}`)
  return (
    <img
      // src={`https://saarthistorage.blob.core.windows.net/pravidicons/${
      //   props.isActive ? props.activeIcon : props.inActiveIconicon
      // }`}
      // src={`https://saarthistorage.blob.core.windows.net/pravidicons/applyIcon.svg`}
      data-testid="imgSrc"
      src={
        props.defaultIcon
          ? props.defaultImg
          : `${iconUrl}/${
              props.isInActive ? props.inActiveIcon : props.activeIcon
            }${`.svg`}${process.env.REACT_APP_CALL_RECORDING_AZURE_TOKEN}`
      }
      style={props.extraStyle ? props.extraStyle : {}}
      onClick={(e) => {
        if (props.onClick && !props.disabled) {
          props.onClick(e);
        }
      }}
      className={
        (props.disabled ? "disabled " : "") +
        " " +
        (props.extraClass ? props.extraClass : "")
      }
      alt={
        props.altText
          ? props.altText
          : props.isInActive
          ? props.inActiveIcon
          : props.activeIcon
      }
      data-type={props.datatype}
      id={props.id ? props.id : ""}
    />
  );
}
//To Get Active Icon
// <PravidIcons
// activeIcon={"applyIcon"}
// />
//To Get InActive Icon
// <PravidIcons
// activeIcon={"applyIcon"}
// inActiveIcon={"applyIcons"}
// isInActive
// Onclick
// />
