import React, { useEffect, useState } from "react";
import styles from "./TeamMemberRow.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../redux";
import ToggleSwitch from "../../../../../../generic/toggleSwitch/ToggleSwitch";
import DropDown from "../../../../../../generic/dropdownsaarthi2/DropdownSaarthi";
import {
  dropDownArrowIcon,
  toastTickIcon,
} from "../../../../../../../theme/assets/svg";
import {
  updateTeamData,
  updateInviteMemberOnPlan,
} from "../../../../../../../redux/signUpFlow/teamsPage/actions/teamsPage.actions";
import _ from "lodash";
import { toast } from "react-toastify";
import Icon from "../../../../../uiKit/iconComponent/Icon";
import Button from "../../../../../../generic/button/Button";
import { ROLES } from "../../../../../../../constants";

interface teamMemberProps {
  teamMemberData?: any;
  teamMemberIndex?: number;
}
export const TeamMemberRow = ({
  teamMemberData,
  teamMemberIndex,
}: teamMemberProps) => {
  const [isDisableAdminStatus, setIsDisableAdminStatus] = useState(false);
  const [isDisableTeamMember, setIsDisableTeamMember] = useState(false);
  const [isStatusToggle, setIsStatusToggle] = useState(
    teamMemberData?.isActive ? teamMemberData?.isActive : false
  );
  const [campaignRole, setCampaignRole] = useState(
    teamMemberData?.role ? teamMemberData?.role : ""
  );

  const dispatch = useDispatch();

  const selectedTeamDetails = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.teamsPageReducer?.selectedTeamDetails
  );

  const teamUseCaseId = useSelector(
    (state: RootState) =>
      state.signUpModuleReducer?.teamsPageReducer?.selectedTeamDetails.useCaseId
  );
  const loginUserId = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.userDetail?._id
  );
  const accessToken = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const accountDetails = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]
  );
  const myPlanData: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );
  const userName = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo?.userDetail?.name
  );
  const userEmail = useSelector(
    (store: any) => store?.loginReducer?.userLoginInfo?.userDetail?.email
  );

  // const orgEmail = useSelector(
  //   (store: RootState) =>
  //     store?.profile?.yourAccountReducer?.organization.organizationDetails
  //       ?.organizationEmail
  // );
  // const userEmail = useSelector(
  //   (store: RootState) => store?.loginReducer?.userLoginInfo?.userDetail?.email
  // );

  // const isLoading = useSelector(
  //   (store: RootState) =>
  //     store?.signUpModuleReducer?.teamsPageReducer?.isLoadingUpdateTeamDetails
  // );

  // const isInvitedTrue = useSelector(
  //   (store: RootState) =>
  //     store?.loginReducer?.userLoginInfo?.userDetail?.isInvited
  // );

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": accessToken,
  };

  const updateInviteOnPlanFunction = (checked: boolean) => {
    if (checked) {
      dispatch(
        updateInviteMemberOnPlan({
          header: headers,
          body: {
            userIncr: 1,
          },
          id: accountDetails?.id,
        })
      );
    } else {
      dispatch(
        updateInviteMemberOnPlan({
          header: headers,
          body: {
            userDecr: 1,
          },
          id: accountDetails?.id,
        })
      );
    }
  };

  const handleStatusToggleSwitch = (checked: boolean) => {
    setIsStatusToggle((prev: any) => checked);

    updateInviteOnPlanFunction(checked);

    dispatch(
      updateTeamData({
        userId: loginUserId,
        id: selectedTeamDetails?.id,
        teamMembers: [{ ...teamMemberData, isActive: checked }],
        // teamMembers: updatedData,
        teamMemberName: teamMemberData?.name,
        isStatusUpdate: true,
      })
    );
    toast.dismiss();
    toast.success(
      <>
        <div className={styles.toastDiv}>
          <div className={styles.toastTopDiv}>
            <Icon
              img_src={toastTickIcon}
              extraClass={styles.toastIconStyling}
            />
            <div className={styles.toastText}>
              {teamMemberData?.name.trim()}&apos;s&nbsp;Account&nbsp;
              {checked === true ? "Enabled" : "Disabled"} Successfully
            </div>
          </div>
          <Button
            text="Undo"
            extraClass={styles.undoBtnStyling}
            id="auto_undo_status_btn"
            onClick={() => {
              // const editedUser = {
              //   ...teamMemberData,
              //   isActive: !checked,
              // };
              // const updatedData = selectedTeamDetails.teamMembers?.map(
              //   (data: any) =>
              //     data?.userId === teamMemberData.userId ? editedUser : data
              // );

              dispatch(
                updateTeamData({
                  userId: loginUserId,
                  id: selectedTeamDetails?.id,
                  teamMembers: [{ ...teamMemberData, isActive: !checked }],
                  // teamMembers: updatedData,
                  teamMemberName: teamMemberData?.name.trim(),
                  isStatusUpdate: true,
                })
              );
              updateInviteOnPlanFunction(!checked);
            }}
          />
        </div>
      </>,
      {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        icon: false,
      }
    );
    // }
  };

  const roleOptions = {
    optionList: _.map(myPlanData?.availableRole, getNewRoleFunc),
    imgSrcRight: dropDownArrowIcon,
    imgSrcLeftHide: true,
    placeHolderText: campaignRole,
  };
  function getNewRoleFunc(obj: any) {
    return Object.values(ROLES).find(
      (item) =>
        item.display &&
        item.name === obj &&
        (!item.useCaseId || item.useCaseId === teamUseCaseId)
    )?.displayName;
  }

  const handleChangeRole = (e: string) => {
    setCampaignRole(e);
    if (e !== teamMemberData?.role) {
      // const editedUser = {
      //   ...teamMemberData,
      //   role: e,
      // };
      // const updatedData = selectedTeamDetails.teamMembers?.map((data: any) =>
      //   data?.userId === teamMemberData.userId ? editedUser : data
      // );
      e = Object.values(ROLES).find(
        (item) =>
          item.display &&
          item.displayName === e &&
          (!item.useCaseId || item.useCaseId === teamUseCaseId)
      )?.name as string;

      dispatch(
        updateTeamData({
          userId: loginUserId,
          id: selectedTeamDetails?.id,
          // teamMembers: updatedData,
          teamMembers: [
            {
              ...teamMemberData,
              role: e,
            },
          ],
          isRoleUpdate: true,
          teamMemberName: teamMemberData?.name.trim(),
        })
      );
    }
  };

  useEffect(() => {
    setCampaignRole(teamMemberData?.role ? teamMemberData?.role : "");
    // console.log("kkkkk", teamMemberData?.role, campaignRole);
  }, [teamMemberData]);

  useEffect(() => {
    setIsStatusToggle(
      teamMemberData?.isActive ? teamMemberData?.isActive : false
    );
  }, [teamMemberData]);

  // useEffect(() => {
  //   if (isInvitedTrue === true) {
  //     if (orgEmail === teamMemberData?.email) {
  //       setIsDisableAdminStatus(true);
  //     } else {
  //       setIsDisableAdminStatus(false);
  //     }
  //   } else {
  //     if (userEmail === teamMemberData?.email) {
  //       setIsDisableAdminStatus(true);
  //     } else {
  //       setIsDisableAdminStatus(false);
  //     }
  //   }
  // }, [teamMemberData, orgEmail, userEmail, isInvitedTrue]);

  useEffect(() => {
    if (teamMemberData?.email === userEmail || teamMemberData?.role === "Admin") {
      setIsDisableAdminStatus(true);
    } else {
      setIsDisableAdminStatus(false);
    }
  }, [teamMemberData]);



  useEffect(() => {
    if (teamMemberData?.name === undefined) {
      setIsDisableTeamMember(true);
    } else {
      setIsDisableTeamMember(false);
    }
  }, [teamMemberData]);

  // console.log(teamMemberData?.name, "llll");

  return (
    <div className={styles.teamMemberRowWrapper}>
      <div className={styles.teamMembersHeaderColL}>
        <div
          className={`${styles.teamMemberRowInfo} ${styles.teamMemNameStyling}`}
          id={`auto_team_member_${teamMemberIndex}_name`}
        >
          {teamMemberData?.name}
        </div>
      </div>
      <div className={styles.teamMembersHeaderColL}>
        <div
          className={styles.teamMemberRowInfo}
          id={`auto_team_member_${teamMemberIndex}_email`}
        >
          {teamMemberData?.email}
        </div>
      </div>
      <div className={styles.teamMembersHeaderColL}>
        <div
          className={`${styles.teamMemberRowInfo}  ${
            isDisableTeamMember || isDisableAdminStatus
              ? styles.disabledStatusStyling
              : ""
          }`}
          id={`auto_team_member_${teamMemberIndex}_role`}
        >
          <DropDown
            droplist={{
              ...roleOptions,
              imgSrcRight: isDisableAdminStatus ? "" : dropDownArrowIcon,
            }}
            selectedItem={(item: any) => handleChangeRole(item)}
            extraClassSelectedArea={styles.dropDownSelectedStyling}
            extraClassToBeSelectedArea={styles.dropDownToBeSelectedStyling}
            extraClassPlaceHolderStyling={styles.dropDownPlaceHolderStyling}
            extraClassPlaceHolder={styles.defaultPlaceHolderStyling}
            extraClassDropDownUl={styles.dropDownUlStyling}
            extraClassDropDownLi={styles.dropDownLiStyling}
            outerSelected={
              Object.values(ROLES).find(
                (item) =>
                  item.display &&
                  item.name === campaignRole &&
                  (!item.useCaseId || item.useCaseId === teamUseCaseId)
              )?.displayName
            }
            extraHighlight={styles.styleHighlightLi}
            extraStyleLeftImg={styles.iconRightStyling}
            editedItem={
              Object.values(ROLES).find(
                (item) =>
                  item.display &&
                  item.name === campaignRole &&
                  (!item.useCaseId || item.useCaseId === teamUseCaseId)
              )?.displayName
            }
            ignoreLeftAlign={true}
            id="auto_role_dropdown"
          />
        </div>
      </div>
      <div className={styles.teamMembersHeaderColS}>
        <div
          className={`${styles.teamMemberRowInfo} ${styles.statusStyling} ${
            isDisableAdminStatus || isDisableTeamMember
              ? styles.disabledStatusStyling
              : ""
          }`}
        >
          {teamMemberData?.name === undefined ||
           teamMemberData?.email === userEmail ||
          teamMemberData?.role === "Admin" ? (
            ""
          ) : (
            <ToggleSwitch
              id={`auto_team_member_${teamMemberIndex}_status`}
              checked={isStatusToggle}
              small={true}
              onChange={handleStatusToggleSwitch}
            />
          )}
          {teamMemberData?.name === undefined
            ? "Invited"
            : isStatusToggle
            ? "Active"
            : "Inactive"}
        </div>
      </div>
    </div>
  );
};
