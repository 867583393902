import React, { PureComponent, useState, useEffect } from "react";
import NoDatamodel from "../../generic/noDatamodel/NoDatamodel";
import { noPhoneDataIcon } from "../../../theme/assets/svg";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

function SingleLineChart(props) {
  const [data, setData] = useState([]);

  const expandedChart = useSelector(
    (store) => store?.analyticsReducer?.expandedChart
  );

  const isSort = useSelector((store) => store?.analyticsReducer?.isSort);

  const sortedChart = useSelector(
    (store) => store?.analyticsReducer?.sortedChart
  );

  let name = props.chartDetails?.keys[0].referenceKeyName;
  let key = props.chartDetails?.legendData[1];
  let value = props.chartDetails?.keys[1].referenceKeyName;
  let refenceKeyForData = props.chartDetails?.refenceKeyForData;
  let fill = props.chartDetails?.keys[1].fillColor;
  let barWidth = parseInt(props.chartDetails?.chartStyle?.width) - 100;
  let barHeight = parseInt(props.chartDetails?.chartStyle?.height) - 50;
  let expandbarHeight =
    parseInt(props.chartDetails?.expand?.expandStyle?.height) - 80;
  let legend = props.chartDetails?.legendData[0];


  useEffect(() => {
    if (props.chartDetails && props.data) {
      let temp = props.data[refenceKeyForData];
      let finalData = temp?.map((each, i) => {
        return {
          name: moment(each[name], "DD/MM/YYYY").format("DD MMM YY"),
          [key]: each[value],
          amt: 0,
        };
      });
      setData(finalData);
    }
  }, [props.chartDetails, props.data[refenceKeyForData]]);

  const setSortedData = (data) => {
    let finalData = data?.map((each, i) => {
      return {
        name: moment(each[name], "DD/MM/YYYY").format("DD MMM YYYY"),
        [key]: each[value],
        amt: 0,
      };
    });
    setData(finalData);
  };

  useEffect(() => {
    if (props.chartTitle === sortedChart) {
      if (isSort) {
        let tempdata = props?.data[refenceKeyForData];
        tempdata.reverse();
        setSortedData(tempdata);
      } else {
        let tempdata = props?.data[refenceKeyForData];
        tempdata.reverse();
        setSortedData(tempdata);
      }
    }
  }, [isSort, props?.data[refenceKeyForData]]);
  
  return data?.length > 0 ? (
    <div
      className="sd-chart"
      style={{
        minWidth: `${data.length * 140}px`,
        height: `${
          expandedChart && expandedChart.includes(props.chartTitle)
            ? `${expandbarHeight}px`
            : `${barHeight}px`
        }`,
        width: `${
          expandedChart && expandedChart.includes(props.chartTitle)
            ? "100%"
            : `${barWidth}px`
        }`,
        position: "relative",
      }}
    >
      <LineChart
        width={expandedChart && expandedChart.includes(props.chartTitle) ? 1150 : 1020}
        height={
          expandedChart && expandedChart.includes(props.chartTitle) ? 500 : 380
        }
        data={data}
      >
        <XAxis dataKey="name" padding={{ left: 50 }}>
          <Label
            // angle={200}
            position="insideBottomMiddle"
            style={{
              textAnchor: "middle",
              fill: "#000000",
            }}
            dy={15}
          >
            {props.chartDetails?.keys[0].name}
          </Label>
        </XAxis>
        <YAxis>
          <Label
            angle={270}
            offset={-20}
            position="left"
            style={{ textAnchor: "middle", fill: "#000000" }}
          >
            {props.chartDetails?.keys[1].name}
          </Label>
        </YAxis>
        <Tooltip />
        <Legend
          layout="horizontal"
          verticalAlign="top"
          align="right"
          wrapperStyle={{
            top: "0",
            // left: "800px",
            width: "auto",
          }}
          payload={[
            {
              id: "10",
              type: "square",
              value: legend,
              color: fill,
            },
          ]}
        />
        <Line
          type="monotone"
          dataKey={key}
          stroke={fill}
          activeDot={{ r: 8 }}
          strokeWidth={4}
          dot={{ r: 5, stroke: fill, fill: fill }}
          isAnimationActive={false}
        />
      </LineChart>
    </div>
  ) : (
    <div
      className="no-data"
      style={{
        height: `${
          expandedChart && expandedChart.includes(props.chartTitle)
            ? "510px"
            : "310px"
        }`,
      }}
    >
      <NoDatamodel srcImg={noPhoneDataIcon}></NoDatamodel>
    </div>
  );
}

export default SingleLineChart;