import React from "react";
import { propsForIndividualData } from "./types";
import ToolTip from "../../../generic/toolTip/ToolTip";
import styles from "./CustomerPreviewCards.module.scss";
import { single_green_tick_icon } from "../../../../theme/assets/agentDesktopSvg";
import { eye_icon } from "../../../../theme/assets/agentDesktopSvg";

import { newDateAndTime } from "../../../../utils/timeAndDate/time.util";
import NewToolTip from "../newToolTip/NewToolTip";
export default function CustomerPreviewCard(props: propsForIndividualData) {
  return (
    <div
      className={styles.eachCard}
      data-id={props.id}
      onClick={() => {
        props.onClick && props.onClick(props.id, props.sessionId);
      }}
      data-selected={props.selected}
    >
      <div className={styles.text}>
        <h2 title={props.name} className={styles.name}>
          {props.name}
        </h2>
        <p className={styles.msg}>
          {props?.previewMessage?.length > 0 ||
          typeof props?.previewMessage === "string"
            ? props.previewMessage
            : "..."}
        </p>
        {/* create a function to formate time and date status : todo*/}
        <p className={styles.time}>{newDateAndTime(props.date)}</p>
      </div>

      {props.status.status !== "none" && (
        <div className={styles.status}>
          {props.status.isWatching ? ( // status
            // <ToolTip
            //   extraClass={styles.tooltip}
            //   extraElement={
            <div className={styles.isWatching} title={props.tooltip}>
              <NewToolTip
                content={props.tooltip}
                direction={"right"}
                extraTooltipClass={styles.extraToolTip}
                // makeWrapperAbsolute={true}
                makeTooltipFixed={{ top: 18, left: 20 }}
              >
                <img src={eye_icon} alt={"eye_image"}></img>
              </NewToolTip>
            </div>
          ) : //   }
          //   about={props.tooltip}
          // ></ToolTip>
          //todo
          props.status.status === "closed" ? (
            <div className={styles.closed}>
              <img src={single_green_tick_icon} alt="colsed_image"></img>
            </div>
          ) : (
            //tooltip todo
            // change the color in css
            <NewToolTip
              content={props.tooltip}
              direction={"right"}
              extraWrapper={styles.tooltipNew}
              // makeWrapperAbsolute={true}
              extraTooltipClass={styles.extraToolTip}
              makeTooltipFixed={{ top: 18, left: 20 }}
            >
              <div
                className={styles.number}
                data-isopen={props.status.status === "open"}
                // title={props.tooltip}
              >
                {props.status.value}
              </div>
            </NewToolTip>

            // <ToolTip
            //   extraTextStylingClass={styles.toolTipTextStyling}
            //   extraAboutStylingClass={styles.toolTipAboutStyling}
            //   extraTextDivStylingClass={styles.toolTipTextDivStylingClass}
            //   extraElement={
            //     <div
            //       className={styles.number}
            //       data-isopen={props.status.status === "open"}
            //       // title={props.tooltip}
            //     >
            //       {props.status.value}
            //     </div>
            //   }
            //   about={props.tooltip}
            // ></ToolTip>
          )}
        </div>
      )}
    </div>
  );
}
