// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rZ2huYXuT03SSN6J7RDR{display:flex;justify-content:space-evenly;align-items:center;width:100%}.fAlAaHRUqR2ug4FKa2QA{font-family:"Roboto";font-weight:400;font-size:16px;padding-left:10px}.GwVDm8Jd5aVOUfS9zja6[type=radio]{background:#0061ff;border:1px solid #979797;border-radius:18px}`, "",{"version":3,"sources":["webpack://./src/components/generic/radioButton/RadioButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,4BAAA,CACA,kBAAA,CACA,UAAA,CAGF,sBACE,oBAAA,CACA,eAAA,CACA,cAAA,CACA,iBAAA,CAGF,kCACE,kBAAA,CACA,wBAAA,CACA,kBAAA","sourcesContent":[".RadioButton {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  width: 100%;\n}\n\n.LabelDiv {\n  font-family: \"Roboto\";\n  font-weight: 400;\n  font-size: 16px;\n  padding-left: 10px;\n}\n\n.input[type=\"radio\"] {\n  background: #0061ff;\n  border: 1px solid #979797;\n  border-radius: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RadioButton": `rZ2huYXuT03SSN6J7RDR`,
	"LabelDiv": `fAlAaHRUqR2ug4FKa2QA`,
	"input": `GwVDm8Jd5aVOUfS9zja6`
};
export default ___CSS_LOADER_EXPORT___;
