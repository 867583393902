import actionTypes from "../actionTypes/completedCampaign.actions";
export interface getApiPayload {
  clientName: string;
}
/**
 * ## this gets the campaign completed currently it gives api result for past three years including this year
 * this is required to show the green dot on the calendar
 * @param data
 * @param {string} data.clientName
 * @returns
 */
export function GetCompletedCampaignApi(data: getApiPayload) {
  return {
    type: actionTypes.GET_COMPLETED_CAMPAIGNS_API_CALL,
    payload: data,
  };
}
