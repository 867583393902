const prefix = "COMPLETED_CAMPAIGNS_";
const actionTypes = {
  GET_COMPLETED_CAMPAIGNS_API_CALL: prefix + "GET_COMPLETED_CAMPAIGN_API_CALL",
  GET_COMPLETED_CAMPAIGNS_API_SUCCESS:
    prefix + "GET_COMPLETED_CAMPAIGN_API_SUCCESS",
  APPEND_DATES: prefix + "APPEND_DATES",
  GET_COMPLETED_CAMPAIGNS_API_FAILURE:
    prefix + "GET_COMPLETED_CAMPAIGN_API_FAILURE",
};

export default actionTypes;
