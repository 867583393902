import actionTypes from "../productPage.actionTypes";

export const saveSelectedProductData = (payload: any) => {
  return {
    type: actionTypes.SAVE_SELECTED_PRODUCT_DATA,
    payload: payload,
  };
};

export const currentProductData = (payload: any) => {
  return {
    type: actionTypes.CURRENT_PRODUCT_DETAILS,
    payload: payload,
  };
};

export function addProductToPortfolio(payload: any) {
  return {
    type: actionTypes.ADD_CURRENT_PRODUCT_TO_PORTFOLIO,
    payload: payload,
  };
}

export const getAllDomainData = (payload: any) => {
  return {
    type: actionTypes.GET_ALL_DOMAIN_DATA_API_REQUEST,
    payload: payload,
  };
};

export const getAllProductData = (payload: any) => {
  return {
    type: actionTypes.GET_ALL_PRODUCT_DATA_API_REQUEST,
    payload: payload,
  };
};

export const getAllProductTypesData = (payload: any) => {
  return {
    type: actionTypes.GET_ALL_PRODUCT_TYPE_DATA_API_REQUEST,
    payload: payload,
  };
};

export const getAllUseCasesData = () => {
  return {
    type: actionTypes.GET_ALL_USE_CASE_DATA_API_REQUEST,
  };
};

export const clearAllData = () => {
  return {
    type: actionTypes.CLEAR_ALL_PRODUCT_DATA,
  };
};

export const updateProductCategory = (payload: any) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_CATEGORY_REQUEST,
    payload: payload,
  };
};

export const createProductTeams = (payload: any) => {
  return {
    type: actionTypes.CREATE_PRODUCT_PORTFOLIO_TEAM_REQUEST,
    payload: payload,
  };
};

export const clearCreatedProductTeams = () => {
  return {
    type: actionTypes.CLEAR_CREATED_PRODUCT_TEAMS,
  };
};

export const showProductPagePopUp = (payload: any) => {
  return {
    type: actionTypes.PRODUCT_MAIA_POP_UP_CLOSE,
    payload: payload,
  };
};
