const actionTypes = {
  REPORT_RULE_GET_DETAILS_API_CALL: "REPORT_RULE_GET_DETAILS_API_CALL",
  REPORT_RULE_GET_DETAILS_API_SUCCESS: "REPORT_RULE_GET_DETAILS_API_SUCCESS",
  REPORT_RULE_GET_DETAILS_API_FAILURE: "REPORT_RULE_GET_DETAILS_API_FAILURE",
  GET_RULE_DETAIL_API_CALL: "GET_RULE_DETAIL_API_CALL",
  GET_RULE_DETAIL_API_SUCCESS: "GET_RULE_DETAIL_API_SUCCESS",
  GET_RULE_DETAIL_API_FAILURE: "GET_RULE_DETAIL_API_FAILURE",
  REPORT_RULE_DELETE_DETAIL_API_CALL: "REPORT_RULE_DELETE_DETAIL_API_CALL",
  REPORT_RULE_DELETE_DETAIL_API_SUCCESS:
    "REPORT_RULE_DELETE_DETAIL_API_SUCCESS",
  REPORT_RULE_DELETE_DETAIL_API_FAILURE:
    "REPORT_RULE_DELETE_DETAIL_API_FAILURE",
  UPDATE_RULE_DETAIL_API_CALL: "UPDATE_RULE_DETAIL_API_CALL",
  UPDATE_RULE_DETAIL_API_SUCCESS: "UPDATE_RULE_DETAIL_API_SUCCESS",
  UPDATE_RULE_DETAIL_API_FAILURE: "UPDATE_RULE_DETAIL_API_FAILURE",
  CREATE_RULE_DETAIL_API_CALL: "CREATE_RULE_DETAIL_API_CALL",
  CREATE_RULE_DETAIL_API_SUCCESS: "CREATE_RULE_DETAIL_API_SUCCESS",
  CREATE_RULE_DETAIL_API_FAILURE: "CREATE_RULE_DETAIL_API_FAILURE",
  REPORT_AUTOMATION_RESET: "REPORT_AUTOMATION_RESET",
  USER_LOGOUT: "USER_LOGOUT",
};
export default actionTypes;
