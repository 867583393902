import actionTypes from "../security.actionTypes";

export function updateSecurityDetails(
  id: string,
  oldPassword: string,
  newPassword: string
) {
  return {
    type: actionTypes.SECURITY_UPDATE_DETAILS_API_CALL,
    payload: { id, oldPassword, newPassword },
  };
}
