import React from "react";
import {
  ResponsiveContainer,
  Sector,
  Cell,
  PieChart,
  Pie,
  Tooltip,
  Label
} from "recharts";
import {arrowPath} from "../../../../theme/assets/svg/index"

const GaugeChart = (props) => {
  const RADIAN = Math.PI / 180;

  const width = parseInt(props.chartDetails?.chartStyle?.width);
  const height =  parseInt(props.chartDetails?.chartStyle?.height) - 260;
const chartValue = 100;
//   const slices = [
//     {
//       value: 50,
//       color: "#25D366"
//     },
//     {
//       value: 50,
//       color: "#FBBC05"
//     },
//     {
//       value: 50,
//       color: "#FF4034"
//     }
//   ];

  const sumValues = props.data.map((cur) => cur.value).reduce((a, b) => a + b);

  const arrowData = [
    { value: chartValue },
    { value: 0 },
     { value: sumValues - chartValue }
  ];

  const pieProps = {
    startAngle: 180,
    endAngle: 0,
    cx: width / 2,
    cy: width / 2,
    isAnimationActive: false
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <></>
    //   <text
    //     x={x}
    //     y={y}
    //     fill="black"
    //     textAnchor={x > cx ? "start" : "end"}
    //     dominantBaseline="auto"
    //   >
    //     {`${(percent * 100).toFixed(0)}%`}
    //   </text>
    );
  };

  const Arrow = ({ cx, cy, midAngle, outerRadius }) => {
    //eslint-disable-line react/no-multi-comp
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (outerRadius - width * 0.3) * cos;
    const my = cy + (outerRadius - width * 0.05) * sin;
    return (
      <g>
        {/* <path
          d={`M${cx},${cy}L${mx},${my}`}
          strokeWidth="6"
          stroke="grey"
          fill="none"
          strokeLinecap="round"
        /> */}
        
        <image x={cx - 90} y={cy - 50}  xlinkHref={arrowPath} alt="" />
        {/* <circle cx={cx} cy={cy} r={width * 0.1} fill="white" stroke="none" /> */}
      </g>
    );
  };

  return (
    <div style={{ width, height:400 }}>
      <ResponsiveContainer>
        <PieChart width={width} height={height}>
          <Pie
            stroke="none"
            data={props.data}
            innerRadius={(width / 2) * 0.5}
            outerRadius={(width / 2) * 0.8}
            {...pieProps}
            // label
            // label={renderCustomizedLabel}
          >
            {props.data.map((each, i) => (
              <Cell key={`cell-${i}`} fill={props.data[i].color} stroke="none" />
            ))}
          </Pie>
          <Tooltip />
          <Pie
            stroke="none"
            fill="none"
            activeIndex={1}
            activeShape={Arrow}
            data={arrowData}
            innerRadius={(width / 2) * 0.5}
            outerRadius={(width / 2) * 0.6}
            {...pieProps}
          >
            <Label
              value={"Low Risk"}
              position="centerBottom" 
              offset={50}
              className="gauge-label"
              fontSize="15px"
              fontWeight="400"
              color="#333333"
              dy={30}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GaugeChart;
