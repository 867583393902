import React, { useEffect, useState } from "react";

import { Routes, Route, useNavigate } from "react-router-dom";
import { PaymentScreen } from "./paymentScreen/PaymentScreen";
import ProfileSettingScreen from "./settings/SettingsScreen";
import YourAccountScreen from "./yourAccount/YourAccountScreen";
import NoDatamodel from "../../components/generic/noDatamodel/NoDatamodel";
import styles from "./ProfileScreen.module.scss";
import { error404 } from "../../theme/assets/svg";
import { useSelector } from "react-redux";
import profileConfig from "./Profile.config";
import { RootState } from "../../redux";
import Loader from "../../components/generic/loader/Loader";
import Modal from "../../components/generic/modal/Modal";

/**
 * it contains the routes of profile screen
 * @returns jsx
 */
export default function ProfileScreen() {
  const navigate = useNavigate();

  // need to validate the routes
  const tabs = useSelector(
    (state: RootState) =>
      state.profile.navigationReducer.leftSideNavigation.data
  );

  const navigationBar = useSelector((state: RootState) => {
    return state.profile.navigationReducer;
  });

  const organizationDetails = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization?.organizationDetails
        ?.organizationName
  );
  const orgData = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization.organizationDetails
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRequiredPage, setIsRequiredPage] = useState<boolean>(false);
  const [routes, setRoutes] = useState<{
    yourAccount: boolean;
    billing: boolean;
    setting: boolean;
  }>({ yourAccount: false, billing: false, setting: false });
  const [isKycCompleted , setIsKycCompleted] = useState(false)

  function backToHome() {
    navigate("/");
  }

  useEffect(() => {
    if (
      navigationBar.leftSideNavigation.isLoading ||
      navigationBar.topDropdownHome.isLoading ||
      navigationBar.topDropdownSetting.isLoading
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    navigationBar.leftSideNavigation.isLoading,
    navigationBar.topDropdownHome.isLoading,
    navigationBar.topDropdownSetting.isLoading,
  ]);

  useEffect(() => {
    if (tabs) {
      const yourAccount = tabs?.filter((e: any) => {
        return e.name === profileConfig.yourAccount.name;
      });
      const setting = tabs?.filter((e: any) => {
        return e.name === profileConfig.settings.name;
      });
      const billing = tabs?.filter((e: any) => {
        return e.name === profileConfig.billing.name;
      });
      setRoutes({
        yourAccount: yourAccount?.[0]?.isVisible ? true : false,
        billing: billing?.[0]?.isVisible ? true : false,
        setting: setting?.[0]?.isVisible ? true : false,
      });
    }
  }, [tabs]);

  const gstNumber = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization?.organizationDetails
        ?.gstNumber
  );

  const selectedTab = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization?.selectedProfileTab
  );

//

useEffect(
  () => {
    if(orgData?.kycDocPath?.length){
    const value =orgData?.kycDocPath?.every((doc:any) => doc?.isRequested === true)
    setIsKycCompleted(value)
    }
  },[orgData?.kycDocPath]
)

  useEffect(() => {
    // if (currentTab === "Organization") {
    if (gstNumber?.length > 0 && isKycCompleted) {
      setIsRequiredPage(false);
    } else {
      setIsRequiredPage(true);
    }
    // } else {
    //   setIsRequiredPage(false);
    // }
  }, [
    // currentTab,
    gstNumber,
    isKycCompleted
  ]);

  // useEffect(() => {
  //   if (selectedTab === "My Plan") {
  //     setIsPricingTrue(true);
  //   } else {
  //     setIsPricingTrue(false);
  //   }
  // }, [selectedTab]);

  // console.log("hdh", isPricingTrue, selectedTab);

  return (
    <div className={styles.profileModule}>
      {isRequiredPage ? (
        <div className={styles.bannerStyling}>
          Complete your profile and KYC to claim 999 free credits for creating
          live campaigns.
        </div>
      ) : (
        <div className={styles.noBannerStyling}></div>
      )}
      <Routes>
        <Route
          key="billing"
          path="billing"
          element={
            isLoading ? (
              <LoaderAtCenter />
            ) : routes.billing ? (
              <div className={styles.billingWrap}>
                <PaymentScreen />
              </div>
            ) : (
              <div className={styles.error}>
                <NoDatamodel
                  srcImg={error404}
                  message="Ooops!! We can’t seem to find a page you are looking for."
                  extraCss={{ message: styles.message, img: styles.img }}
                  button={{ message: "Back to Home", onClick: backToHome }}
                ></NoDatamodel>
              </div>
            )
          }
        />

        <Route
          key="settings"
          path="settings"
          element={
            isLoading ? (
              <LoaderAtCenter />
            ) : routes.setting ? (
              <div className={styles.component}>
                <ProfileSettingScreen />
              </div>
            ) : (
              <div className={styles.error}>
                <NoDatamodel
                  srcImg={error404}
                  message="Ooops!! We can’t seem to find a page you are looking for."
                  extraCss={{ message: styles.message, img: styles.img }}
                  button={{ message: "Back to Home", onClick: backToHome }}
                ></NoDatamodel>
              </div>
            )
          }
        />

        <Route
          index
          key={"youraccount"}
          path="your-account"
          element={
            isLoading ? (
              <LoaderAtCenter />
            ) : routes.yourAccount ? (
              <div className={styles.component}>
                <YourAccountScreen />
              </div>
            ) : (
              <div className={styles.error}>
                <NoDatamodel
                  srcImg={error404}
                  message="Ooops!! We can’t seem to find a page you are looking for."
                  extraCss={{ message: styles.message, img: styles.img }}
                  button={{ message: "Back to Home", onClick: backToHome }}
                ></NoDatamodel>
              </div>
            )
          }
        />
        <Route
          path="*"
          element={
            isLoading ? (
              <LoaderAtCenter />
            ) : (
              <div className={styles.error}>
                <NoDatamodel
                  srcImg={error404}
                  message="Ooops!! We can’t seem to find a page you are looking for."
                  extraCss={{ message: styles.message, img: styles.img }}
                  button={{ message: "Back to Home", onClick: backToHome }}
                ></NoDatamodel>
              </div>
            )
          }
        />
      </Routes>
    </div>
  );
}
const LoaderAtCenter = () => {
  return (
    <Modal shown={true} extraClassStyleModalContent={styles.background}>
      <Loader />
    </Modal>
  );
};
