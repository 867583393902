export default {
  ANALYTICS_API_DATA_SUCCESS: "ANALYTICS_API_DATA_SUCCESS",
  ANALYTICS_API_DATA_FAILURE: "ANALYTICS_API_DATA_FAILURE",
  ANALYTICS_API_DATA_REQUEST: "ANALYTICS_API_DATA_REQUEST",
  SET_EXPAND_CHART:"SET_EXPAND_CHART",
  SET_SORT_CHART:"SET_SORT_CHART",
  ANALYTICS_CHARTS_DOWNLOAD_CSV_API_REQUEST:"ANALYTICS_CHARTS_DOWNLOAD_CSV_API_REQUEST",
  ANALYTICS_CHARTS_DOWNLOAD_CSV_SUCCESS:"ANALYTICS_CHARTS_DOWNLOAD_CSV_SUCCESS",
  ANALYTICS_CHARTS_DOWNLOAD_CSV_FAILURE:"ANALYTICS_CHARTS_DOWNLOAD_CSV_FAILURE",
  SET_VIEW_MORE:"SET_VIEW_MORE",
  ANALYTICS_API_CARD_DATA_REQUEST : "ANALYTICS_API_CARD_DATA_REQUEST",
  ANALYTICS_API_CARD_DATA_SUCCESS : "ANALYTICS_API_CARD_DATA_SUCCESS",
  ANALYTICS_API_CARD_DATA_FAILURE : "ANALYTICS_API_CARD_DATA_FAILURE",



  SET_CARD_NAME:"SET_CARD_NAME"

};
