export default {
  SET_ALL_PHONE_NUMBER: "SET_ALL_PHONE_NUMBER",
  SET_CONVERSATION_ID: "SET_CONVERSATION_ID",
  SET_PHONE_NUMBER: "SET_PHONE_NUMBER",
  SET_IS_LOGGER_OR_REPORT: "SET_IS_LOGGER_OR_REPORT",
  SET_CURRENT_SESSION: "SET_CURRENT_SESSION",
  SET_PHONE_LOADING: "SET_PHONE_LOADING",
  CALL_API: "CALL_API",
  GET_MESSAGES_API: "GET_MESSAGES_API",
  SET_TOTAL_PAGE: "SET_TOTAL_PAGE",
  SET_ALL_SESSION_PHONE_NUMBER: "SET_ALL_SESSION_PHONE_NUMBER",
};
