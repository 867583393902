import React, { useEffect, useRef, useState } from "react";

import styles from "./RightSidebarpop.module.scss";
// import {
//   arrow,
//   blueFilter,
//   blueCalender,
// } from "../../../theme/assets/svg/rightSideIcon";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../redux";

import DatePicker from "./../../generic/rightSidebar/dataPicker/DatePicker";
import DropdownFilter from "../rightSidebar/dropdownFilter/DropdownFilter";
import DownloadSection from "../rightSidebar/downloadSection/DownloadSection";
import PravidIcons from "../pravidIcons/PravidIcons";
import { ROLES } from "../../../constants";
interface props {
  selectedIcon: string|null;
  // show: boolean;
  // setShow: Dispatch<SetStateAction<boolean>>;
}

import {
  getAllFiltersOptionsForAccount,
  setSelectedFilterOptions,
  setSelectedFilterType,
  setShowRightSideBar,
} from "../../../redux/filters/actions";
import KPIWrapper from "../kpiWrapper/KPIWapper";
import { useCurrentPageTab } from "../../../hooks";
import { getSchemaOfClientRequest } from "../../../redux/onboarding/login/actions";
import { useNavigate } from "react-router-dom";
import {
  everyCampaingIsInOptions,
  getOptionsRemaining,
} from "../../../utils/validators/validators";
import moment from "moment";

const RightSidebarpop=React.forwardRef( ( props: any, ref: any ) => {
  const dispatch=useDispatch();
  const history=useNavigate();
  const refP=useRef<any>( null );
  const refId=useRef<any>();
  const accountName=useSelector(
    ( store: any ) => store.loginReducer?.userLoginInfo?.accountDetails?.[ 0 ]
  );
  const userDetail=useSelector(
    ( store: any ) => store.loginReducer?.userLoginInfo?.userDetail
  );
  const accountDetails=useSelector(
    ( store: any ) => store.loginReducer?.userLoginInfo?.accountDetails?.[ 0 ]
  );
  const filterOptions=useSelector(
    ( state: RootState ) => state.filterReducers.filterOptions
  );
  const show=useSelector(
    ( state: RootState ) => state.filterReducers.showRightSidePop
  );
  const userRole: any=useSelector( ( state: any ) => {
    return state.loginReducer.userLoginInfo.userDetail.role;
  } );

  const token=useSelector(
    ( store: RootState ) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const calender=useSelector(
    ( store: RootState ) => store.filterReducers.calenderData
  );
  const clientName=useSelector(
    ( store: RootState ) => store.filterReducers.selectedFilterOptions.clientName
  );
  const selectedFilterOptions=useSelector(
    ( store: RootState ) => store.filterReducers.selectedFilterOptions
  );
  const cleintFromCampaign=useSelector(
    ( store: RootState ) => store.campaignReducer.campaignClientName
  );
  const dataClient=useSelector(
    ( state: RootState ) => state.filterReducers?.clientName
  );
  const hideFilterTransition=useSelector(
    ( state: RootState ) =>
      state?.signUpModuleReducer?.productTourReducer?.hideFilterTransition
  );
  const currentChannel=useSelector(
    ( store: RootState ) => store.dashboardReducer.channelTab
  );
  const currentSelectedTeamId = useSelector(
    (store: RootState) => store.loginReducer?.currentTeam?.id
  );

  const currentTeamuseCaseId = useSelector(
    (store: RootState) => store.loginReducer?.currentTeam?.useCaseId
  );

  function process () {
    // history("/dashboard");
  }
  //user based filter
  // useEffect(() => {
  //   if (currentChannel?.name) {
  //     dispatch(
  //       getAllFiltersOptionsForAccount(
  //         {
  //           username: accountName?.name,
  //           type: accountDetails?.type,
  //           role: userDetail?.role,
  //           start_date: calender.startDate,
  //           end_date: calender.endDate,
  //           channel: [currentChannel?.name],
  //         },
  //         token
  //       )
  //     );
  //   }
  //   // if(accountDetails?.type==="Internal"&&userDetail?.role== ROLES.campaignAnalyst.name &&clientName&&clientName?.length>0){
  //   //   dispatch(
  //   //     setSelectedFilterOptions({
  //   //       type: 'clientName',
  //   //       options: [{id:"62623fda3b3b877936e3bd16",
  //   //       name:"Cred Testing",
  //   //       type:"Internal"}],
  //   //     })
  //   //   )
  //   // }
  //   // accountDetails?.type === "Internal" &&
  //   //   userRole.toString().toLowerCase() === ROLES.campaignAnalyst.name.toLowerCase() &&
  //   //   dispatch(
  //   //     setSelectedFilterOptions({
  //   //       type: "clientName",
  //   //       options: [
  //   //         {
  //   //           id: "62623fda3b3b877936e3bd16",
  //   //           name: "Cred Testing",
  //   //           type: "Internal",
  //   //         },
  //   //       ],
  //   //     })
  //   //   );
  // }, [currentChannel?.name]);
  useEffect( () => {
    if (
      accountDetails?.type==="Internal"&&
      ( userDetail?.role==ROLES.campaignAnalyst.name||
        userDetail?.role==ROLES.campaignManager.name )&&
      clientName&&
      clientName?.length>0
    ) {
      dispatch(
        getAllFiltersOptionsForAccount(
          {
            username: clientName[ 0 ]?.name,
            type: clientName[ 0 ]?.type,
            role: clientName[ 0 ]?.role,
            start_date: moment( calender.startDate ).format( "YYYY-MM-DD" ),
            end_date: moment( calender.endDate ).format( "YYYY-MM-DD" ),
            channel: [ currentChannel?.name ],
            teamId : currentSelectedTeamId,
            useCaseId:currentTeamuseCaseId

          },
          token
        )
      );
    } else {
      dispatch(
        getAllFiltersOptionsForAccount(
          {
            username: accountName?.name,
            type: accountDetails?.type,
            role: userDetail?.role,
            start_date: moment( calender.startDate ).format( "YYYY-MM-DD" ),
            end_date: moment( calender.endDate ).format( "YYYY-MM-DD" ),
            channel: [ currentChannel?.name ],
            teamId : currentSelectedTeamId,
            useCaseId:currentTeamuseCaseId
          },
          token
        )
      );
      // dispatch({type:"CLEAR_CAMPAING_FILTER"})
    }
  }, [ calender, currentChannel?.name ] );

  //client based
  useEffect( () => {
    if (
      accountDetails?.type==="Internal"&&
      ( userDetail?.role==ROLES.campaignAnalyst.name||
        userDetail?.role==ROLES.campaignManager.name )&&
      clientName&&
      clientName?.length>0
    ) {
      if ( clientName[ 0 ]?.id!==refId.current?.id ) {
        dispatch(
          getAllFiltersOptionsForAccount(
            {
              username: clientName[ 0 ]?.name,
              type: clientName[ 0 ]?.type,
              role: clientName[ 0 ]?.role,
              start_date: calender.startDate,
              end_date: calender.endDate,
              channel: [ currentChannel?.name ],
              teamId : currentSelectedTeamId,
              useCaseId:currentTeamuseCaseId
            },
            token
          )
        );
        // dispatch(
        //   getSchemaOfClientRequest({
        //     id: clientName[0]?.id,
        //     originalId: userDetail?._id,
        //     fun: process,
        //   })
        // );
        refId.current={ id: clientName[ 0 ]?.id };
      }
    }
  }, [ clientName ] );

  useEffect( () => {
    let sidebarToggleListener: ( e: MouseEvent ) => void;
    if ( show ) {
      sidebarToggleListener=( event: MouseEvent ) => {
        if (
          ref.current&&
          refP.current&&
          !refP.current.contains( event.target )&&
          !ref.current.contains( event.target )
        ) {
          dispatch( setShowRightSideBar( false ) );
          props.setSelectedIcon( "" );
        }
      };
      document.addEventListener( "click", sidebarToggleListener );
    }
    return () => document.removeEventListener( "click", sidebarToggleListener );
  }, [ show ] );

  useEffect( () => {
    if (
      selectedFilterOptions?.campaignName&&
      filterOptions?.data?.filters?.campaignName&&
      !everyCampaingIsInOptions(
        filterOptions?.data?.filters?.campaignName,
        selectedFilterOptions?.campaignName
      )
    ) {
      dispatch(
        setSelectedFilterOptions( {
          type: "campaignName",
          options: getOptionsRemaining(
            filterOptions?.data?.filters?.campaignName,
            selectedFilterOptions?.campaignName
          ),
        } )
      );
    }
  }, [ filterOptions ] );
  // useEffect(() => {
  //   if (
  //     cleintFromCampaign &&
  //     cleintFromCampaign.length > 0 &&
  //     dataClient &&
  //     userRole.toString().toLowerCase() === "campaign manager"
  //   ) {
  //     const selectedData = dataClient.find((e: any) => {
  //       return e.name === cleintFromCampaign;
  //     });

  //     dispatch(
  //       getSchemaOfClientRequest(
  //         {
  //           id: selectedData.id,
  //           originalId: userDetail?._id,
  //         },
  //         {
  //           type: "clientName",
  //           options: [selectedData],
  //         }
  //       )
  //     );
  //   }
  // }, [cleintFromCampaign, dataClient]);
  //console.log(props.selectedIcon,"SELCTED ICOn")
  return (
    <>
      { props?.selectedIcon?.length>0&&(
        <div
          ref={ refP }
          className={ `${ styles.rightsidepopwrapper } ${ show? styles.show:""
            } ${ hideFilterTransition? styles.tourTransitionStop:"" }` }
        >
          <div className={ styles.extraDiv }>
            { props?.selectedIcon==="Filter"&&(
              <div id="rightSideBarPopUp">
                <DropdownFilter selectedIcon={ props.selectedIcon } />
              </div>
            ) }
            { props?.selectedIcon==="Calendar"&&(
              <>
                <DatePicker
                  setSelectedIcon={ props.setSelectedIcon }
                  selectedIcon={ props.selectedIcon }
                />
              </>
            ) }
            { props?.selectedIcon==="Download"&&(
              <>
                <DownloadSection
                  selectedIcon={ props?.selectedIcon }
                  setSelectedIcon={ props.setSelectedIcon }
                />
              </>
            ) }
            { props?.selectedIcon?.toLowerCase()===
              "KPI customisation".toLowerCase()&&(
                <>
                  <div style={ { textAlign: "center" } }>
                    <KPIWrapper selectedIcon={ props?.selectedIcon } />
                  </div>
                </>
              ) }
            { props?.selectedIcon==="Shuffle Column"&&(
              <>
                <div style={ { textAlign: "center" } }>{ props?.selectedIcon }</div>
              </>
            ) }
          </div>
          <div>
            <div
              className={ styles.closeicon }
              onClick={ () => {
                if ( !hideFilterTransition ) {
                  dispatch( setShowRightSideBar( false ) );
                  dispatch( setSelectedFilterType( "" ) );
                  props.setSelectedIcon( "" );
                }
              } }
            >
              {/* <img src={arrow} alt="" /> */ }
              <PravidIcons activeIcon={ "arrow" } />
            </div>
          </div>

        </div>
      ) }
    </>
  );
} );

RightSidebarpop.displayName="RightSidebarpop";
export default React.memo( RightSidebarpop );
