import React ,{useState} from 'react';
import Modal from '../../components/generic/modal/Modal';
import PopUpModal from '../../components/moduleComponents/campaign/popUpMoadal/PopUpModal';

import './Operation.css';
import OperationTable from './operationtable/OperationTable';
const Operation = () => {
  
    return(
        <>
        <div>
            <OperationTable/>
        </div>
        </>
    )
}

export default Operation;