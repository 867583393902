import { takeLatest } from "redux-saga/effects";
import {
  setLeftModulesToVisibleAllWorker,
  updateModuleDetailsWorker,
} from "./leftMenu.sage";
import { actions as actionName } from "../leftMenu.interface";
import actionTypes from "../actionTypes";

export default function* leftMenuWatcher() {
  yield takeLatest(
    actionName.SET_LEFT_MODULES_TO_VISIBLE_ALL,
    setLeftModulesToVisibleAllWorker
  );
  yield takeLatest(actionTypes.UNLOCK_ICONS_REQUEST, updateModuleDetailsWorker);
}
