import routeConfig from "./routes-config";

type roleBaseRouteValidationReturnType = {
  shouldRedirect: boolean;
  redirectRoute: string | null;
};

export const roleBaseRouteValidation = (
  role: string
): roleBaseRouteValidationReturnType => {
  let redirect: null | string = null;
  const pathArr = window.location.pathname.split("/").slice(1);
  let copyRouteConfig = routeConfig;
  try {
    pathArr.forEach((path) => {
      if (
        !copyRouteConfig[path] ||
        copyRouteConfig[path].allowedRoles[0] === "*"
      ) {
        throw new Error("General route");
      }
      if (!copyRouteConfig[path].allowedRoles.includes(role)) {
        redirect = copyRouteConfig[path].redirect as string;
        throw new Error("Redirect");
      }

      if (copyRouteConfig[path].children) {
        copyRouteConfig = copyRouteConfig[path].children!;
      }
    });
    return { shouldRedirect: false, redirectRoute: "" };
  } catch (err) {
    if (err instanceof Error && err.message === "Redirect") {
      return { shouldRedirect: true, redirectRoute: redirect };
    } else if (err instanceof Error && err.message === "General route") {
      return { shouldRedirect: false, redirectRoute: "" };
    } else {
      console.error(err);
      throw err;
    }
  }
};
