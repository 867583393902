import React from "react";
import styles from "./HeaderTabComponent.module.scss";
import styless from "../subModuleHeaderTab/SubModuleHeader.module.scss";
import PravidIcons from "../../generic/pravidIcons/PravidIcons";

interface props {
  tabDetails: Array<Record<string, any>>;
  onClick: (tab: Record<string, any>) => void;
  selectedTab: Record<string, any>;
}

export default function HeaderTabComponent(props: props) {
  const tabItem = (item: any, selectedTab: Record<string, any>) => {
    const highlight = selectedTab?.name === item?.name ? true : false;
    console.log("item gg", highlight, item?.name, item.activeIcon)
    return (
      <>
        <div className={styles.headerTabDiv}>
          <div
            className={`${item.isDisabled
              ? styles.disablePointerDiv
              : styles.toggleTabComponent
              }`}
          >
            <div className={styles.titleTab}>
              <div
                className={styles.subHeaderTopDiv}
                id={"auto_subHeader_" + item.name.trim().replace(/\s/g, "")}
              >
                {item.inActiveIcon && item.activeIcon ? (
                  highlight && !item.isDisabled ? (
                    <PravidIcons
                      extraClass={styles.activeIcon}
                      activeIcon={item.activeIcon}
                    />
                  ) : (
                    <PravidIcons
                      extraClass={styles.icon}
                      activeIcon={item.inActiveIcon}
                    />
                  )
                ) : (
                  // <img
                  //   className={styles.icon}
                  //   src={`${
                  //     highlight && !item.isDisabled
                  //       ? item.activeIcon
                  //       : item.icon
                  //   }`}
                  //   alt="Icon"
                  // />

                  ""
                )}
                <div
                  className={`${styless.extraClasss
                    ? styless.extraClass
                    : `${highlight && !item.isDisabled
                      ? styles.activeTab
                      : styles.tabDiv
                    }`
                    }`}
                >
                  {item.displayName}
                </div>
              </div>

              {/* highlighter */}
              {highlight && !item.isDisabled && (
                <div
                  className={`${styless.extraHozitonLineClasss
                    ? styless.extraHozitonLineClasss
                    : styles.horizonLine
                    }`}
                ></div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {props.tabDetails?.map((item: Record<string, any>, i: number) => {
        return (
          <div key={i} className={styles.wrapperHeaderClass}>
            <div
              onClick={(e) => !item.isDisabled && props.onClick(item)}
              id={"auto_header_" + item.name.trim().replace(/\s/g, "")}
            >
              {tabItem(item, props.selectedTab)}
            </div>
          </div>
        );
      })}
    </>
  );
}
