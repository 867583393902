import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSubHeaderFromSourceDetails } from "../../../utils";
import HeaderTabComponent from "../headerTabComponent/HeaderTabComponent";
import styles from "./SubModuleHeader.module.scss";
import { useCurrentPageTab } from "../../../hooks";
import {
  setSubHeaderTabs,
  setSelectedChannelTab,
  setSelectedSubChannelTab,
  setSelectedSubmoduleTab,
} from "../../../redux/dashboard/actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { channel } from "diagnostics_channel";

interface props {
  showChannels: Array<Record<string, any>>;
  showTabs: Array<Record<string, any>>;
}

export default function SubModuleHeader(props: props) {
  /* redux hooks */
  const selectedChannel = useSelector(
    (store: RootState) => store.dashboardReducer.channelTab
  );
  const selectedSubChannel = useSelector(
    (store: RootState) => store.dashboardReducer.subChannelTab
  );
  const selectedTab = useSelector(
    (store: RootState) => store.dashboardReducer.subModuleTab
  );
  const dispatch = useDispatch();

  /* handlers */
  const handleChannelClick = (tab: Record<string, any>) => {
    if (tab?.id !== selectedChannel?.id) {
      dispatch(setSelectedChannelTab(tab));
    }
  };
  const handleSubChannelClick = (tab: Record<string, any>) => {
    if (tab?.id !== selectedSubChannel?.id) {
      dispatch(setSelectedSubChannelTab(tab));
    }
  };
  const handleSubModuleClick = (tab: Record<string, any>) => {
    if (tab?.id !== selectedTab.id) dispatch(setSelectedSubmoduleTab(tab));
  };

  return (
    <div className={styles.subModuleTopDiv}>
      <div className={styles.channelTab}>
        <HeaderTabComponent
          tabDetails={props.showChannels}
          onClick={handleChannelClick}
          selectedTab={selectedChannel}
        />
      </div>
      {selectedChannel?.childChannel && (
        <div className={styles.whatsAppChannelTab}>
          <HeaderTabComponent
            tabDetails={selectedChannel.childChannel}
            onClick={handleSubChannelClick}
            selectedTab={selectedSubChannel}
          />
        </div>
      )}
      <div className={styles.campaignTab}>
        <HeaderTabComponent
          tabDetails={props.showTabs}
          onClick={handleSubModuleClick}
          selectedTab={selectedTab}
        />
      </div>
    </div>
  );
}
