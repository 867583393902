import React, { useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  play,
  pause,
  edit,
  deleteIcon,
  archieve,
  unarchieve,
  callingList,
  dashboard,
  whatsApp,
  call,
  msg,
  email,
  iTableIcon,
  iTableBlueIcon,
  iTableRedIcon,
  userPersonaViewIcon,
  scheduleIconNew,
} from "../../../../theme/assets/svg/index";

import {
  infoIconRed,
  tickIconGreen,
  infoIconBlue,
  playActionIcon,
  archiveActionIcon,
  pauseActionIcon,
  editActionIcon,
  callingListActionIcon,
  dashboardActionIcon,
  unArchiveActionIcon,
} from "../../../../theme/assets/svg/campaign_v3";

import schedulerIcon from "../../../../theme/assets/svg/campaign/calenderIcon.svg";
import childNumberIcon from "../../../../theme/assets/svg/campaign/childNumber.svg";
import LshapeIcon from "../../../../theme/assets/svg/campaign/lshapewithdot.svg";
import StatusButton from "../CampaignTableComponent/statusButton/StatusButton";
import Icon from "../../../generic/icon/Icon";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

// This is the table constant/settings which needed to render table elements
export const tableConstants = (
  getChildCampaign,
  accountType,
  pageNo,
  downloadCampaignData,
  moveToEdit,
  deleteCampaignData,
  showManualScreen,
  moveToAnnalyticPage,
  moveToCallingListPage,
  handleArchieve,
  handlePlayCampaign,
  handlePauseCampaign,
  toShowScheduleModal,
  toshowProgressModal,
  closeArrowList,
  showChildId,
  getParentLabelId,
  toshowUploadDataModal,
  toShowPersonaModal,
  accountDetailsRedux,
  handleTerminate
) => {

  const accountDetails = useSelector(
    (store) => store?.schedulerReducer?.accountInfoDetails
  );

  const stepsTotal = accountDetails?.campaignProgressBar;

  console.log("step", stepsTotal)
  const getdata = (id) => {
    getChildCampaign(id);
  };
  const moveToAnnalytics = (data) => {
    moveToAnnalyticPage(data);
  };
  const moveToCallingList = (data) => {
    moveToCallingListPage(data);
  };
  const handleArchieveTrue = (id, data) => {
    handleArchieve(id, data);
  };
  const handlePlayCampaignTable = (id) => {
    handlePlayCampaign(id);
  };
  const handlePauseCampaignTable = (id) => {
    handlePauseCampaign(id);
  };
  const getParentdata = (id) => {
    console.log("get id ", id);
    getParentLabelId(id);
  };
  const handleSchedule = (data) => {
    if ((!stepsTotal?.includes("Strategy Selection")) || data?.agentId?.length > 0 && data?.strategyId?.length > 0) {
      toShowScheduleModal(data);
    } else {
      const msg = `Please Select ${data?.agentId?.length < 0 && data?.strategyId?.length < 0
        ? "Agent and Strategy"
        : data?.agentId?.length < 0
          ? "Agent"
          : data?.strategyId?.length < 0
            ? "Strategy"
            : "Agent and  Strategy"
        }`;
      toast.error(msg);
    }
  };
  /**for terminate**/
  const handleTerminateCampaign = (data) => {
    handleTerminate(data?.id);
  };


  return [
    // {
    //   title: "Sr.No",
    //   render: (rowdata, indx, pageNo) => {
    //     return (
    //       <span
    //         className={`${
    //           rowdata?.isChildCampaign ? "childCamapaignChildcolor" : null
    //         }`}
    //       >
    //         {!rowdata?.index && !rowdata?.isChildCampaign
    //           ? rowdata?.sl_num
    //           : ""}
    //       </span>
    //     );
    //   },
    // },
    {
      title: "Campaign Name",
      render: (rowData) => {
        return (
          <div>
            {/* {rowData?.isMaster ? <div className="masterDiv">Master</div> : null} */}
            <div
              style={{
                display: "flex",
                justifyContent: rowData?.index ? "flex-start" : "space-between",
                padding: rowData?.isParent ? "0 2%" : "0 6%",
                cursor: rowData?.isParent ? "pointer" : "",
                alignItems: "center",
              }}
              onClick={() =>
                rowData?.isParent ? getParentdata(rowData?.id) : null
              }
            >
              {rowData?.index ? (
                <img
                  src={
                    rowData?.channels?.includes("Call") ||
                      rowData?.channels?.includes("Voice")
                      ? call
                      : rowData?.channels?.includes("Sms")
                        ? msg
                        : rowData?.channels?.includes("Mail")
                          ? email
                          : whatsApp
                  }
                ></img>
              ) : (
                ""
              )}
              <span
                className={
                  rowData?.isParent
                    ? "parentBold"
                    : rowData?.index
                      ? "childCamapaign"
                      : "childCamapaignNamechild"
                }
              >
                {rowData?.isChildCampaign ? (
                  <img className="lshapeIconomni" src={LshapeIcon}></img>
                ) : (
                  ""
                )}
                {!rowData?.index && !rowData?.isChildCampaign
                  ? rowData?.sl_num + "."
                  : ""}{" "}
                {rowData.campaignName
                  ? rowData.campaignName
                  : rowData.campaignId
                    ? rowData.campaignId
                    : "-"}
              </span>{" "}
              <span>
                {!rowData?.isParent ? (
                  rowData?.childCampaigns?.length ||
                    rowData?.children?.length ? (
                    <div
                      className="childNumberDivomni"
                      onClick={() => getdata(rowData?.id)}
                    >
                      <img
                        style={{ cursor: "pointer" }}
                        src={childNumberIcon}
                        className="imgchilddr"
                      ></img>
                      {rowData?.children?.length}
                    </div>
                  ) : null
                ) : null}
              </span>
            </div>
          </div>
        );
      },
    },

    {
      title: "Created On",
      render: (rowData) => {
        let cDate = new Date(rowData.createdAt);
        let time = moment.utc(rowData.createdAt);

        return (
          <span
            className={
              rowData?.isChildCampaign ? "childCamapaignName" : "createdOn"
            }
          >
            <span>{`${cDate ? moment(cDate).format("DD MMM YY") : "-"
              } | `}</span>
            <span>{time.local().format("h:mm A")} </span>
          </span>
        );
      },
    },
    {
      title: "Created By",
      render: (rowData) => {
        return (
          <span>
            <div
              className={
                rowData?.isChildCampaign ? "childCamapaignName" : "createdOn"
              }
            >
              {rowData?.createdBy ? rowData?.createdBy : "---"}{" "}
            </div>
          </span>
        );
      },
    },
    {
      title: "Upload Status",
      render: (rowData) => {
        let uDate = new Date(rowData?.uploadedDate);
        return rowData.customerUploadStatus === "Completed" &&
          !rowData?.isParent ? (
          // <div className= {`paid-btn ${rowData?.index ? "" : "childCampaignBtnstatus"}` }>
          //   {rowData.customerUploadStatus === "Completed" &&
          //   !(rowData?.campaignStatus === "Completed") ? (
          //     <div className="greenCircle"></div>
          //   ) : null}
          //   <div className="spanBtn">
          //     <span>
          //       {rowData.customerUploadStatus === "New"
          //         ? "Pending"
          //         : rowData.customerUploadStatus === "Pending"
          //         ? "Pending"
          //         : "Uploaded"}
          //     </span>
          //     <span>
          //     <>
          //       <img
          //         className={`imgI iIcon`}
          //         src={
          //           iInprogressIcon
          //         }
          //         onClick={() => toshowUploadDataModal(rowData)}
          //         data-tip
          //         data-for="info"
          //       ></img>
          //       <ReactTooltip
          //         id="info"
          //         place="bottom"
          //         effect="solid"
          //         backgroundColor="#545454"
          //         textColor="#0174FF"
          //     padding="10px"
          //
          //       >
          //         Info
          //       </ReactTooltip>
          //     </>
          //   </span>
          //   </div>
          //   <p className="dateP">
          //     {rowData.customerUploadStatus === "New" ||
          //     rowData.customerUploadStatus === "Pending"
          //       ? ""
          //       : uDate
          //       ? moment(uDate).format("DD-MM-YYYY")
          //       : "-"}
          //   </p>
          // </div>
          <>
            <div
              // className={`${
              //   rowData?.index ? "uploadstatus" : "childUploadStatus"
              // }`}
              className={"uploadStatus"}
            >
              {" "}
              <StatusButton
                name={
                  rowData.customerUploadStatus === "Pending"
                    ? "Pending"
                    : "Uploaded"
                }
                img={
                  rowData.customerUploadStatus === "Failed"
                    ? iTableRedIcon
                    : iTableIcon
                }
                imgClick={() => toshowUploadDataModal(rowData)}
                // extraStyleOuter={
                //   rowData.customerUploadStatus !== "Completed"
                //     ? "statusBtnRed"
                //     : ""
                // }
                extraStyleName={`${rowData.customerUploadStatus !== "Completed"
                  ? "redStatus"
                  : "greenStatus"
                  } ${"nameStyling"}`}
                showToolTip={false}
                isHideIcon={true}
                noOutline={true}
                toolTipText={"djgfk"}
              />{" "}
            </div>
          </>
        ) : (
          <div className="dashLine">----------</div>
        );
        // <span>{rowData.customerUploadStatus}</span>;
      },
    },
    {
      title: "User Personas",
      render: (rowData) => {
        console.log("persona", rowData);
        return !rowData?.isParent && rowData?.personas.length != 0 ? (
          <>
            <div className="userPersonaDiv">
              {/* <Icon
                img_src={userPersonaViewIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  toShowPersonaModal(rowData);
                }}
              /> */}
              <div
                className="userPersonaText"
                onClick={(e) => {
                  e.stopPropagation();
                  toShowPersonaModal(rowData);
                }}
              >
                {rowData?.personas?.length}
              </div>
            </div>
          </>
        ) : (
          <div className="dashLine">----------</div>
        );
      },
    },
    {
      title: "Campaign Status",
      render: (rowData) => {
        return rowData?.campaignStatus &&
          !rowData?.isParent &&
          rowData?.campaignStatus !== "Pending" ? (
          // <div
          //   className={`${rowData?.index ? "" : "childCampaignBtnstatus"} ${
          //     rowData?.campaignStatus === "Completed"
          //       ? "campaignStatusBtn"
          //       : rowData?.campaignStatus === "Paused"
          //       ? "campaignStatusBtnPa"
          //       : "campaignStatusBtnIN"
          //   }`}
          // >
          //   <span>{rowData?.campaignStatus}</span>{" "}
          //   <span>
          //     <>
          //       <img
          //         className={"imgI"}
          //         src={
          //           rowData?.campaignStatus === "Completed"
          //             ? iCompleteIcon
          //             : rowData?.campaignStatus === "Paused"
          //             ? iPendingIcon
          //             : iInprogressIcon
          //         }
          //         onClick={() => toshowProgressModal(rowData)}
          //         data-tip
          //         data-for="info"
          //       ></img>
          //       <ReactTooltip
          //         id="info"
          //         place="bottom"
          //         effect="solid"
          //         backgroundColor="#545454"
          //         textColor="#0174FF"
          //
          //     padding="10px"
          //       >
          //         Info
          //       </ReactTooltip>
          //     </>
          //   </span>
          // </div>
          <>
            <div
              // className={`${
              //   rowData?.index ? "campaignstatus" : "childCampaignStatus"
              // }`}
              className={"uploadStatus"}
            >
              <StatusButton
                img={
                  rowData?.campaignStatus === "Completed"
                    ? tickIconGreen
                    : (rowData?.campaignStatus === "Paused" || rowData?.campaignStatus === "Terminated")
                      ? infoIconRed
                      : rowData?.campaignStatus === "In Progress"
                        ? infoIconBlue
                        : ""
                }
                name={rowData?.campaignStatus}
                imgClick={() => toshowProgressModal(rowData)}
                extraStyleName={`${rowData?.campaignStatus === "Completed"
                  ? "greenStatus"
                  : (rowData?.campaignStatus === "Paused" || rowData?.campaignStatus === "Terminated")
                    ? "redStatus"
                    : rowData?.campaignStatus === "In Progress"
                      ? "blueStatus"
                      : ""
                  } ${"nameStyling"}`}
                showToolTip={false}
                noOutline={true}
                isHideIcon={true}
                iconShow={true}
              />
            </div>
          </>
        ) : (
          <div className="dashLine">----------</div>
        );
      },
    },
    {
      title: "Actions",
      render: (rowData) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {/* <img className={`img  ${rowData.customerUploadStatus=="Uploaded"?"":"hide-it"}`} src={campaignDownload} onClick={()=>{downloadCampaignData(rowData)}} style={{height:"20px",marginLeft:"5px",cursor:"pointer"}} /> */}
            {!rowData?.isArchived &&
              (accountType === "Internal" ||
                accountDetailsRedux?.isShowSchedulerSetting) &&
              !(rowData?.campaignStatus === "Completed") &&
              (rowData?.campaignStatus !== "Terminated") &&
              rowData?.campaignStatus &&
              rowData.customerUploadStatus === "Completed" &&
              rowData?.mappingStatus === "Completed" &&
              !rowData?.isParent
              ? (
                rowData?.isPaused ? (
                  <>
                    <img
                      className="imgNew"
                      src={playActionIcon}
                      onClick={() => {
                        handlePlayCampaignTable(rowData?.id);
                      }}
                      data-tip
                      data-for="play"
                    ></img>
                    <ReactTooltip
                      id="play"
                      place="top"
                      effect="solid"
                      backgroundColor="#545454"
                      textColor="#fff"
                      padding="10px"
                    >
                      Play
                    </ReactTooltip>
                  </>
                ) : (
                  <>
                    <img
                      className="imgNew"
                      src={pauseActionIcon}
                      onClick={() => {
                        handlePauseCampaignTable(rowData?.id);
                      }}
                      data-tip
                      data-for="pause"
                    ></img>
                    <ReactTooltip
                      id="pause"
                      place="top"
                      effect="solid"
                      backgroundColor="#545454"
                      textColor="#fff"
                      padding="10px"
                    >
                      Pause
                    </ReactTooltip>
                  </>
                )
              ) : (
                ""
              )}

            {!rowData?.isArchived &&
              (accountType === "Internal" ||
                accountDetailsRedux?.isShowSchedulerSetting) &&
              rowData.customerUploadStatus === "Completed" &&
              rowData?.mappingStatus !== "Failed" &&
              rowData?.mappingStatus &&
              !rowData?.isParent && (
                // rowData?.campaignStatus &&
                <>
                  <img
                    className="imgNew"
                    // style={{ width: "20px", height: "40px" }}
                    src={callingListActionIcon}
                    onClick={() => moveToCallingList(rowData)}
                    data-tip
                    data-for="registerTip"
                    id="auto_campaign_table_calling_list"
                  ></img>

                  <ReactTooltip
                    id="registerTip"
                    place="top"
                    effect="solid"
                    backgroundColor="#545454"
                    textColor="#fff"
                    padding="10px"
                  >
                    Calling List
                  </ReactTooltip>
                </>
              )}

            {!rowData?.isArchived &&
              (accountType === "Internal" ||
                accountDetailsRedux?.isShowSchedulerSetting) &&
              rowData?.index &&
              rowData.customerUploadStatus === "Completed" &&
              rowData?.mappingStatus === "Completed" &&
              !rowData?.isScheduled &&
              !rowData?.isCompleted &&
              !rowData?.isParent && (
                <>
                  <img
                    className={`imgNew  ${!stepsTotal?.includes("Strategy Selection") || (rowData?.agentId?.length > 0 &&
                      rowData?.strategyId?.length > 0)
                      ? ""
                      : "imgOpacity"
                      }`}
                    src={scheduleIconNew}
                    onClick={() => handleSchedule(rowData)}
                    data-tip
                    data-for="scheduler"
                  ></img>
                  {!stepsTotal?.includes("Strategy Selection") || (rowData?.agentId?.length > 0 &&
                    rowData?.strategyId?.length > 0) ? (
                    <ReactTooltip
                      id="scheduler"
                      place="top"
                      effect="solid"
                      backgroundColor="#545454"
                      textColor="#fff"
                      padding="10px"
                    >
                      scheduler
                    </ReactTooltip>
                  ) : (
                    <ReactTooltip
                      id="scheduler"
                      place="top"
                      effect="solid"
                      backgroundColor="#545454"
                      textColor="#fff"
                      padding="10px"
                    >
                      Please Select Agent and Strategy for schedule
                    </ReactTooltip>
                  )}
                </>
              )}

            {!rowData?.isArchived &&
              rowData.customerUploadStatus === "Completed" &&
              rowData?.mappingStatus === "Completed" &&
              rowData?.campaignStatus && (
                <>
                  <img
                    className="imgNew"
                    src={dashboardActionIcon}
                    onClick={() => moveToAnnalytics(rowData)}
                    data-tip
                    data-for="analytics"
                  ></img>
                  <ReactTooltip
                    id="analytics"
                    place="top"
                    effect="solid"
                    backgroundColor="#545454"
                    textColor="#fff"
                    padding="10px"
                  >
                    Analytics
                  </ReactTooltip>
                </>
              )}
            {!rowData?.isArchived &&
              rowData?.campaignStatus !== "Completed" &&
              (rowData?.campaignStatus !== "Terminated") &&
              rowData?.campaignStatus !== "In Progress" &&
              !rowData?.isParent && (
                <>
                  <img
                    className={`imgNew`}
                    src={editActionIcon}
                    onClick={() => {
                      moveToEdit(rowData);
                    }}
                    data-tip
                    data-for="edit"
                  />
                  <ReactTooltip
                    id="edit"
                    place="top"
                    effect="solid"
                    backgroundColor="#545454"
                    textColor="#fff"
                    padding="10px"
                  >
                    Edit
                  </ReactTooltip>
                </>
              )}
            {!rowData?.isArchived &&
              (accountType === "Internal" ||
                accountDetailsRedux?.isShowSchedulerSetting) &&
              !(rowData?.campaignStatus === "Completed") &&
              (rowData?.campaignStatus !== "Terminated") &&
              rowData?.campaignStatus &&
              rowData.customerUploadStatus === "Completed" &&
              rowData?.mappingStatus === "Completed" &&
              !rowData?.isParent && (
                // rowData?.campaignStatus &&
                <>
                  <img
                    className="imgNew"
                    // style={{ width: "20px", height: "40px" }}
                    src={scheduleIconNew}
                    onClick={() => handleTerminateCampaign(rowData)}
                    data-tip
                    data-for="terminate"
                    id="auto_campaign_table_calling_list"
                  ></img>

                  <ReactTooltip
                    id="terminate"
                    place="top"
                    effect="solid"
                    backgroundColor="#545454"
                    textColor="#fff"
                    padding="10px"
                  >
                    Terminate
                  </ReactTooltip>
                </>
              )}

            {rowData.customerUploadStatus !== "Completed" &&
              !rowData?.isParent &&
              !rowData?.isArchived ? (
              <>
                <img
                  className="imgPicNew imgNew"
                  src={deleteIcon}
                  onClick={() => {
                    deleteCampaignData(rowData);
                  }}
                  data-tip
                  data-for="delete"
                />
                <ReactTooltip
                  id="delete"
                  place="bottom"
                  effect="solid"
                  backgroundColor="#545454"
                  textColor="#fff"
                  padding="10px"
                >
                  Delete
                </ReactTooltip>
              </>
            ) : null}
            {rowData?.index &&
              rowData.customerUploadStatus === "Completed" &&
              rowData?.campaignStatus !== "In Progress" &&
              !rowData?.isParent && (
                <>
                  <img
                    className="imgPicNew imgNew"
                    // src={archieve}
                    src={
                      rowData?.isArchived
                        ? unArchiveActionIcon
                        : archiveActionIcon
                    }
                    onClick={() =>
                      handleArchieveTrue(rowData?.id, rowData?.isArchived)
                    }
                    data-tip
                    data-for="archieved"
                  ></img>
                  <ReactTooltip
                    id="archieved"
                    place="top"
                    effect="solid"
                    backgroundColor="#545454"
                    textColor="#fff"
                    padding="10px"
                  >
                    {rowData?.isArchived ? "Unarchive" : "Archive"}
                  </ReactTooltip>
                </>
              )}
            {/* { accountType != "External" && 
      <img className='img' src={connectIcon} onClick={()=>{showManualScreen(rowData)}}/> } */}
          </div>
        );
      },
    },
  ];
};
