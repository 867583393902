import { takeLatest } from "redux-saga/effects";
import actionTypes from "../reportAutomation.actionTypes";
import {
  getReportRulesDataAPIWorker,
  deleteReportRuleDataAPIWorker,
  getSpecificReportRuleDataAPIWorker,
  updateReportRuleDataAPIWorker,
  createReportRuleDataAPIWorker,
} from "./reportAutomation.saga";

export default function* reportRuleDetailsWatcher(): any {
  yield takeLatest(
    actionTypes.REPORT_RULE_GET_DETAILS_API_CALL,
    getReportRulesDataAPIWorker
  );
  yield takeLatest(
    actionTypes.REPORT_RULE_DELETE_DETAIL_API_CALL,
    deleteReportRuleDataAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_RULE_DETAIL_API_CALL,
    getSpecificReportRuleDataAPIWorker
  );
  yield takeLatest(
    actionTypes.UPDATE_RULE_DETAIL_API_CALL,
    updateReportRuleDataAPIWorker
  );
  yield takeLatest(
    actionTypes.CREATE_RULE_DETAIL_API_CALL,
    createReportRuleDataAPIWorker
  );
}
