import React, { useState, useEffect } from "react";
import NoDatamodel from "../../../generic/noDatamodel/NoDatamodel";
import { noPhoneDataIcon } from "../../../../theme/assets/svg";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "../../../generic/checkbox/Checkbox";
import "./Charts.css";

function LineChartToggleLegend(props) {
  const [data, setData] = useState([]);
  const [line1, setLine1] = useState(true);
  const [line2, setLine2] = useState(true);
  const [line3, setLine3] = useState(true);

  let key1 = props.chartDetails?.legendData[0];
  let key2 = props.chartDetails?.legendData[1];
  let key3 = props.chartDetails?.legendData[2];
  let value = props.chartDetails?.keys[0].referenceKeyName;
  let value1 = props.chartDetails?.keys[1].referenceKeyName;
  let value2 = props.chartDetails?.keys[2].referenceKeyName;
  let value3 = props.chartDetails?.keys[3].referenceKeyName;
  let fill1 = props.chartDetails?.keys[1].fillColor;
  let fill2 = props.chartDetails?.keys[2].fillColor;
  let fill3 = props.chartDetails?.keys[3].fillColor;
  //   let refenceKeyForData = props.chartDetails?.refenceKeyForData;
  let barWidth = parseInt(props.chartDetails?.chartStyle?.width) - 20;
  let barHeight = parseInt(props.chartDetails?.chartStyle?.height) - 90;
  let isCardData = props.chartDetails?.isCardData;

  const isCardName = useSelector(
    (store) => store?.analyticsReducer?.cardName
  );


  useEffect(() => {
    if (props.data) {
      let finalData = props.data.map((each, i) => {
        return {
          name: each[value],
          [key1]: each[value1],
          [key2]: each[value2],
          [key3]: each[value3],
          amt: each[value],
        };
      });
      setData(finalData);
      // setMonth(temp)
    }
  }, [props.data]);

  function getFirstLineIsChecked() {
    setLine1(!line1);
  }
  function getSecondLineIsChecked() {
    setLine2(!line2);
  }
  function getThirdLineIsChecked() {
    setLine3(!line3);
  }

  useEffect(()=>{
      if(isCardData){
        if(isCardName == key1){
            setLine1(true);
        } else{
            setLine1(false);
        }
        if(isCardName == key2){
            setLine2(true)
        } else{
            setLine2(false);
        }
        if(isCardName == key3){
            setLine3(true)
        } else{
            setLine3(false)
        }
      }
  },[isCardName,isCardData])
console.log(isCardName, key2,"555")
  function renderCusomizedLegend() {
    return (
      <div className="checkboxTopClassDiv">
        <div className="checkBoxExtraClass">
          <Checkbox
            checked={line1}
            onChange={getFirstLineIsChecked}
            extraSpan={line1 ? "line1ExtraSpan" : "line1ExtraSpanBefore"}
            background={fill1}
          />
          <span style={{ margin: "0 5px" }}>{key1}</span>
        </div>
        <div className="checkBoxExtraClass">
          <Checkbox
            checked={line2}
            onChange={getSecondLineIsChecked}
            extraSpan={line2 ? "line1ExtraSpan" : "line1ExtraSpanBefore"}
            background={fill2}
          />
          <span style={{ margin: "0 5px" }}>{key2}</span>
        </div>
        <div className="checkBoxExtraClass">
          <Checkbox
            checked={line3}
            onChange={getThirdLineIsChecked}
            extraSpan={line3 ? "line1ExtraSpan" : "line1ExtraSpanBefore"}
            background={fill3}
          />
          <span style={{ margin: "0 5px" }}>{key3}</span>
        </div>
      </div>
    );
  }

  return data?.length > 0 ? (
    <div
      className="sd-chart"
      style={{
        minWidth: `${data.length * 140}px`,
        height: `${barHeight}px`,
        width: `${barWidth}px`,
        position: "relative",
      }}
    >
      <LineChart
        width={barWidth}
        height={barHeight}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 30,
          bottom: 5,
        }}
        isAnimationActive={true}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name">
          <Label
            position="insideBottomMiddle"
            style={{
              textAnchor: "middle",
              fill: "#000000",
            }}
            dy={14}
          >
            {props.chartDetails?.keys[0].name}
          </Label>
        </XAxis>
        <YAxis>
          <Label
            angle={270}
            offset={18}
            position="left"
            style={{ textAnchor: "middle", fill: "#000000" }}
          >
            {props.chartDetails?.keys[1].name}
          </Label>
        </YAxis>
        <Tooltip />
        {!isCardData && <Legend
          verticalAlign="bottom"
          height={36}
          align="center"
          //   payload={_.toPairs(this.state.chartColors).map(pair => ({
          //     dataKey: pair[0],
          //     color: pair[1]
          //   }))}
          content={renderCusomizedLegend}
        />}
        {(line1 || key1==isCardName) && (
          <Line
            type="monotone"
            dataKey={key1}
            stroke={fill1}
            activeDot={{ r: 3 }}
            // strokeWidth={2}
            dot={{ r: 4, stroke: fill1, fill: fill1 }}
            // isAnimationActive={true}
          />
        )}
        {line2 && <Line
          type="monotone"
          dataKey={key2}
          stroke={fill2}
          activeDot={{ r: 3 }}
          // strokeWidth={2}
          dot={{ r: 4, stroke: fill2, fill: fill2 }}
        //   isAnimationActive={true}
        />}
        {line3 && <Line
          type="monotone"
          dataKey={key3}
          stroke={fill3}
          activeDot={{ r: 3 }}
          // strokeWidth={2}
          dot={{ r: 4, stroke: fill3, fill: fill3 }}
        //   isAnimationActive={true}
        />}
      </LineChart>
    </div>
  ) : (
    <div
      className="no-data"
      style={{
        height: `${"310px"}`,
      }}
    >
      <NoDatamodel srcImg={noPhoneDataIcon}></NoDatamodel>
    </div>
  );
}

export default LineChartToggleLegend;
