import React, { ReactElement } from "react";
import Skeleton from "@mui/material/Skeleton";
import { Typography } from "@material-ui/core";

type func = () => any;
interface props {
  children: ReactElement | string;
  isLoading: boolean;
  animation?: "pulse" | "wave" | false;
  classes?: object;
  height?: number | string;
  width?: number | string;
  sx?: Array<func | object | boolean> | func | object;
  variation?: "circular" | "rectangular" | "rounded" | "text" | string;
  className?: string;
  style?: React.CSSProperties;
  textVariation?:
    | "body1"
    | "body2"
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "inherit"
    | "overline"
    | "subtitle1"
    | "subtitle2";
}

const CustomBodySkeleton = ({
  children,
  isLoading,
  textVariation,
  ...rest
}: props) => {
  /*****************************  JSX  *****************************/
  return (
    <>
      {isLoading ? (
        <Skeleton {...rest}>
          {rest.variation === "text" ? (
            <Typography variant={textVariation}>children</Typography>
          ) : (
            children
          )}
        </Skeleton>
      ) : (
        children
      )}
    </>
  );
};

export default CustomBodySkeleton;
