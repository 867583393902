import rightSidePopupActionTypes from "../actionTypes/rightSidePopup.actionTypes";
export interface state {
  selectedIcon: string;
  showRightSideBar: boolean;
}
const initalState: state = {
  selectedIcon: "",
  showRightSideBar: false,
};

export default function rightSidePopupReducer(
  state: state = initalState,
  action: any
): state {
  switch (action.type) {
    case rightSidePopupActionTypes.SET_SELECTED_ICON: {
      return { ...state, selectedIcon: action.payload };
    }
    case rightSidePopupActionTypes.SET_SHOW_RIGHT_SIDE_BAR: {
      return { ...state, showRightSideBar: action.payload };
    }
    default:
      return state;
  }
}
