import { takeLatest } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import {
  getInvoicesDataAPIWorker,
  getDownloadAPIWorker,
  getSortedInvoicesDataAPIWorker,
} from "./invoiceDetails.saga";

export default function* invoicesDetailsWatcher(): any {
  yield takeLatest(
    actionTypes.INVOICES_DATA_API_REQUEST,
    getInvoicesDataAPIWorker
  );
  yield takeLatest(
    actionTypes.DOWNLOAD_INVOICES_API_REQUEST,
    getDownloadAPIWorker
  );

  yield takeLatest(
    actionTypes.SORT_INVOICES_DATA,
    getSortedInvoicesDataAPIWorker
  );
}
