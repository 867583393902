import React, { useEffect, useState } from "react";
import OnGoingCallIndicator from "../../../generic/callLogger/onGoingCall/OnGoingCallIndicator";
import SessionId from "../../../generic/callLogger/sessionId/SessionId";
import "./CallLoggerLeft.scss";
import {
  getCallSummaryAgentDesktop,
  getCallTranscriptAgentDesktop,
  getSessionIdAgentDesktop,
  setSelectedSessionId,
  setSelectedConversationId,
  setSummaryUpdateSuccess,
} from "../../../../../redux/agentDesktop/callLogger/action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux";

interface props {
  setShowAudioPlayer: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CallLogger(props: props) {
  const dispatch = useDispatch();
  //        redux
  const accountName: any = useSelector<RootState>(
    (state) =>
      state.loginReducer?.userLoginInfo?.userDetail?.accountDetails[0]?.name
  );
  const sessionLoading = useSelector(
    (store: RootState) => store.agentDesktopReducer?.callLogger.sessionLoading
  );
  const agentId: any = useSelector<RootState>(
    (state) => state.loginReducer.userLoginInfo.userDetail?._id
  );
  const callStatus = useSelector((store: RootState) => {
    return store.agentDesktopReducer.baseScreenAD.callNotification.callStatus;
  });
  const sessionIdRedux: any = useSelector<RootState>(
    (state) =>
      state.agentDesktopReducer.callLogger?.sessionIds?.data?.results[0]
        ?.sessionIds
  );
  const callerNumber = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.callNotification.callerNumber
  );
  const callerName = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.callNotification.callerName
  );
  const sessionIdCall = useSelector(
    (store: RootState) => store.agentDesktopReducer.callLogger.sessionIds
  );
  const onGoingCallChatSessionId = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.onGoingCallChatSessionId
  );
  const selectedSessionId = useSelector(
    (store: RootState) => store.agentDesktopReducer.callLogger.selectedSessionId
  );
  // const dummyData = [
  //   { dateTime: new Date(), sessionId: "3847923-3438d9-34" },
  //   { dateTime: new Date(), sessionId: "3847923-34389-34" },
  //   { dateTime: new Date(), sessionId: "33447923-34389-34" },
  // ];

  /* useStates -------- */
  const [sessionData, setSessionData] = useState<
    { dateTime: Date; sessionId: string; id: string }[]
  >([]);
  const [currentSelected, setCurrentSelected] = useState("");

  function getSessionIds() {
    dispatch(
      getSessionIdAgentDesktop({
        accountName,
        channel: ["Call"],
        // dategte: "sd",
        // datelte: "",
        phoneNo: callerNumber || "xxxxxxxxxx",
        limit: 4,
        page: 1,
        // agentIds: [agentId],
      })
    );
  }
  /* useEffects ------- */
  useEffect(() => {
    if (accountName && agentId && !sessionLoading) {
      setTimeout(() => getSessionIds());
      // console.log("calling api");
    }
    // setSessionData(dummyData);
  }, [accountName, agentId]);

  useEffect(() => {
    dispatch(setSummaryUpdateSuccess(false));
  }, [onGoingCallChatSessionId]);

  useEffect(() => {
    if (currentSelected !== "") {
      dispatch(getCallSummaryAgentDesktop(currentSelected));
      dispatch(getCallTranscriptAgentDesktop(currentSelected));
    }
  }, [currentSelected]);

  useEffect(() => {
    if (sessionIdRedux) {
      setSessionData((prev) => {
        return sessionIdRedux.map((e: any) => {
          return {
            dateTime: new Date(e.createdAt),
            sessionId: e.sessionId,
            id: e.id,
          };
        });
      });
      if (sessionIdRedux?.length > 0) {
        setCurrentSelected(sessionIdRedux[0].id);
        dispatch(setSelectedSessionId(sessionIdRedux[0].sessionId));
        dispatch(setSelectedConversationId(sessionIdRedux[0].id));
      } else {
        setCurrentSelected("");
        dispatch(setSelectedSessionId(""));
        dispatch(setSelectedConversationId(""));
      }
    } else {
      setSessionData([]);
      setCurrentSelected("");
      dispatch(setSelectedSessionId(""));
      dispatch(setSelectedConversationId(""));
    }
  }, [sessionIdRedux]);

  // useEffect(() => {
  //   const intervel = setTimeout(
  //     () =>
  //       dispatch(
  //         getSessionIdAgentDesktop({
  //           accountName,
  //           channel: ["Call"],
  //           // dategte: "sd",
  //           // datelte: "",
  //           phoneNo: callerNumber || "xxxxxxxxxx",
  //           limit: 20,
  //           page: 1,
  //           agentIds: [agentId],
  //         })
  //       ),
  //     3000
  //   );
  //   return () => clearInterval(intervel);
  // }, []);
  /* handlers -------- */
  const handleSessionIdClick = (id: string, sessionId: string) => {
    if (sessionId !== selectedSessionId) {
      setCurrentSelected(id);
      dispatch(setSelectedSessionId(sessionId));
      dispatch(setSelectedConversationId(id));
      dispatch(setSummaryUpdateSuccess(false));
      if (
        sessionId === sessionData?.[0]?.sessionId &&
        id === sessionData?.[0]?.id
      ) {
        props.setShowAudioPlayer(false);
      } else props.setShowAudioPlayer(true);
    }
  };

  return (
    <div className="agent__call-logger-left__wrapper">
      {!sessionLoading && (
        <>
          {(sessionIdCall?.data?.results?.length > 0 || true) && (
            <div className="on-going-call">
              <OnGoingCallIndicator
                callerName={callerName}
                isSessionsHovered={callStatus !== ""} //
                sessionId={sessionData?.[0]?.sessionId}
                handleSessionIdClick={handleSessionIdClick}
                currentSelected={currentSelected}
                id={sessionData?.[0]?.id}
              />
            </div>
          )}
          <div className="session-ids">
            {sessionData.map((item, index) => {
              if (index === 0 || index > 3) return <></>;
              return (
                <div className="session-id" key={index}>
                  <SessionId
                    sessionId={item.sessionId}
                    dateTime={item.dateTime}
                    handleSessionIdClick={handleSessionIdClick}
                    currentSelected={currentSelected}
                    id={item.id}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
