import React, { useState, useEffect, useRef } from "react";
import styles from "./AgentCardComponent.module.scss";

import InfoIcon from "../../../../../../theme/assets/agentDesktopSvg/dashboardSvg/cardSvg/infoIcon.svg";
import CardImg from "../../../../../../theme/assets/agentDesktopSvg/dashboardSvg/cardSvg/dummyImg.svg";
import ReactTooltip from "react-tooltip";
import convert from "convert-seconds";
import CounterComponent from "../../../counter/CounterComponent";
import PravidIcons from "../../../../generic/pravidIcons/PravidIcons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux";
// interface props {
//   default: string;
// }
// const convert = require('convert-seconds');
export default function CardComponent(props: any) {
  const rangeEndDate = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.rootFilterReducer.calenderFilterReducer.endDate
  );
  const rangeStartDate = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.rootFilterReducer.calenderFilterReducer
        .startDate
  );
  const agentStatus = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.status.currentState
  );

  return (
    <>
      {props.cardDetails &&
        props.cardDetails.map((each: any, i: number) => {
          const refernceKey = each?.cardData?.referenceKey?.split(".");
          const time =
            each?.type == "time" &&
            props?.data[refernceKey[0]] &&
            convert(
              props?.data[refernceKey[0]][refernceKey[1]]
                ? props?.data[refernceKey[0]][refernceKey[1]]
                : 0
            );
          return (
            <div
              className={styles.cardTopdivWrapper}
              key={i}
              style={{
                width: `${props.width}`,
                height: `${props.height}`,
              }}
            >
              <div
                className={`${
                  each?.cardData?.isShowPopup
                    ? styles.infoIconDiv
                    : styles.infoInactiveDiv
                }`}
                data-testid="infoIconDivTest"
              >
                {each?.cardData?.isShowPopup && (
                  <img
                    className={styles.tooltipIconDiv}
                    src={InfoIcon}
                    alt="Info Img"
                    data-tip
                    data-for={each._id}
                  />
                )}
                <ReactTooltip
                  id={each._id}
                  // place="top"
                  effect="solid"
                  backgroundColor="#B6B6B6"
                  textColor="#FFFFFF"
                  className={styles.tooltipComponentClass}
                >
                  {each?.cardData?.popUp}
                </ReactTooltip>
              </div>
              <div className={styles.cardDataDiv}>
                {each?.cardData?.icon && (
                  //  <img src={CardImg} alt="Card Img" />
                  <PravidIcons activeIcon={each?.cardData?.icon} />
                )}
                {/* {each?.cardData?.icon} */}
                <div className={styles.infoDiv}>
                  {(each?.cardData?.name === "Login Session Time" ||
                    each?.cardData?.name === "Available Duration") &&
                  each?.type === "time" ? (
                    <CounterComponent
                      time={
                        props?.data &&
                        refernceKey?.length > 0 &&
                        refernceKey[0] &&
                        props?.data[refernceKey[0]] &&
                        props?.data[refernceKey[0]][refernceKey[1]]
                          ? props?.data[refernceKey[0]][refernceKey[1]]
                          : 0
                      }
                      endDate={rangeEndDate}
                      startDate={rangeStartDate}
                      pauseTimer={
                        each?.cardData?.name === "Available Duration" &&
                        agentStatus !== "Available"
                      }
                    />
                  ) : (
                    <p className={styles.cardValueDiv}>
                      {props?.data &&
                      refernceKey?.length > 0 &&
                      refernceKey[0] &&
                      props?.data[refernceKey[0]]
                        ? each?.type == "time"
                          ? `${time.hours}h ${time.minutes}m ${time.seconds}s`
                          : props?.data[refernceKey[0]][refernceKey[1]]
                        : 0}
                    </p>
                  )}
                </div>
                {/* {each?.cardData?.referenceKey} */}
              </div>
              <div className={styles.cardTitleDiv}>
                <p
                  className={styles.cardTextDiv}
                  style={{ fontSize: `${props.fontSize}` }}
                >
                  {each?.currentName}
                </p>
              </div>
            </div>
          );
        })}
    </>
  );
}