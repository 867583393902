import React, { useEffect, useState } from "react";
import Header from "../header/header";
import DataTable from "../dataTable/dataTable";
import styles from "./exclusionsListContainer.module.scss";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setExclusionsListType } from "../../../../redux/exclusionsList/actions";

const ExclusionsListContainer = () => {
  // USESTATE SECTION
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  // MISC
  const location = useLocation();

  const dispatch = useDispatch();

  // USEEFFECT SECTION
  useEffect(() => {
    if (location?.pathname === "/exclusions-list-client")
      dispatch(setExclusionsListType("Client"));
    else dispatch(setExclusionsListType("Global"));
  }, [location?.pathname]);

  return (
    <div className={styles.exclusionListContainer}>
      <Header
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
      <DataTable
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </div>
  );
};

export default ExclusionsListContainer;
