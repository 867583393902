import { call, put } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import { config, AGENT_DESKTOP_API } from "../../../../services/ApiRoutes";

export function* getAgentDesktopSchemaWorker(action: any): any {
  const role = sessionStorage.getItem("role");
  const url =
    role === "Admin Desktop"
      ? AGENT_DESKTOP_API.GET_ADMIN_SCHEMA
      : AGENT_DESKTOP_API.GET_AGENT_SCHEMA;
  try {
    const result = yield call(config.GET, url);
    yield put({
      type: actionTypes.GET_AGENT_DESKTOP_SCHEMA_SUCCESS,
      payload: result.data?.data?.[0],
    });

    if (role === "Agent Desktop") {
      const dispositions = yield call(
        config.GET,
        AGENT_DESKTOP_API.GET_DISPOSITIONS
      );
      const reasons = yield call(config.GET, AGENT_DESKTOP_API.GET_REASONS);
      const summarySchema = makeSummarySchema(
        result.data?.data?.[0]?.summaryDetails,
        dispositions?.data?.data[0]?.dispositions,
        reasons?.data?.data[0]?.reasons
      );
      yield put({
        type: actionTypes.SET_SUMMARY_SCHEMA_AGENT_DESKTOP,
        payload: summarySchema,
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_AGENT_DESKTOP_SCHEMA_FAILURE,
      payload: error,
    });
  }
}

/* helper function ------ */
const makeSummarySchema = (
  summaryDetails: any,
  dispositions: any,
  reasons: any
) => {
  const result: any[] = [];
  summaryDetails.forEach((category: any) => {
    let obj = {
      type: "fieldHeader",
      data: { headerName: category.mainKey },
    };
    if (category.mainKey === "Conversation") {
      obj = {
        type: "fieldHeader",
        data: { headerName: "Summary" },
      };
    }
    result.push(obj);
    category.subKey.forEach((item: any) => {
      const obj: Record<string, any> = {
        type: item.type,
        data: {
          fieldName: item.keyName,
          referenceKeyName: item.referenceKeyName,
          referenceKeyName2: item.referenceKeyName2,
          backEndKey: item.backEndKey,
          hasOtherOption: item.hasOtherOption,
        },
      };
      // dropdown options
      if (item.keyName === "Disposition") {
        obj["data"]["options"] = dispositions;
      }
      if (item.keyName === "Reason") {
        obj["data"]["options"] = reasons;
      }
      result.push(obj);
    });

    const subKey = category.subKey;
    subKey;
  });
  return result;
};
export function* getAgentStatusWorker(): any {
  try {
    const result: any = yield call(
      config.GET,
      AGENT_DESKTOP_API.GET_STATUS_LIST
    );
    yield put({
      type: actionTypes.GET_AGENT_DESKTOP_STATUS_API_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: actionTypes.GET_AGENT_DESKTOP_STATUS_API_FAILURE,
      payload: err,
    });
  }
}
