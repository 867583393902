import React, { useEffect, useState } from "react";
import styles from "./OrganizationForm.module.scss";
import InputContainer from "../../../generic/inputContainer/InputContainer";
import Button from "../../../generic/button/Button";
import _ from "lodash";
import {
  orgPhoneNumberValidation,
  domainValidation,
  gstNumberValidation,
  panNumberValidation,
  pinCodeValidation,
  webSiteValidation,
  maxLengthValidations,
  orgNameValidation,
  orgDisplayNameValidation,
  orgAddressValidation,
  stateValidation,
  cinNoValidation
} from "../../../../utils/Validation";
import { OrgPopUp } from "../orgPopUp/OrgPopUp";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { VerifyPopUp } from "../../signUpFlow/verifyPopUp/VerfiyPopUp";
import { verifyProductIcon } from "../../../../theme/assets/svg";
import {
  saveOrganizationAPIData,
  updateOrgStatus,
  sendOrgDataOTP,
  verifyOrgDataOTP,
  clearVerifyOTP,
  firstTimeOrgSaved,
  getGstVerification,
  getPanVerification,
  resetGstVerification,
  resetPanVerification,
} from "../../../../redux/profile/yourAccount/organizationDetails/actions/organizationDetails.actions";
import { OTPComponent } from "../../signUpFlow/otpComponent/OTPComponent";
import { generateOrderId } from "../../../../redux/profile/billing/orderSummary/action/orderSummary.action"
import { toast } from "react-toastify";
import { getPricingPlansAPIData } from "../../../../redux/profile/billing/pricingPlan/actions/pricingPlan.actions";
import { getMyPlanAPIData } from "../../../../redux/profile/billing/myPlan/actions";
import { createTelephonyData } from "../../../../redux/integration_V2.0/telephony/actions";
import {
  getTeamsData,
  getTeamTabs,
  getTeamChannels,

} from "../../../../redux/onboarding/login/actions";

interface props {
  scroll?: CallableFunction
}
export const OrganizationForm = (props: props) => {
  const initialState = {
    organizationName: "",
    organizationDisplayName: "",
    gstNumber: "",
    website: "",
    pincode: "",
    organizationEmail: "",
    phoneNumber: "",
    pan: "",
    organizationAddress: "",
    cinNo: "",
    state: "",
  };

  const requiredData = [
    "organizationName",
    "organizationDisplayName",
    "gstNumber",
    "website",
    "pincode",
    "organizationEmail",
    "phoneNumber",
    "pan",
    "organizationAddress",
    "state",
    "cinNo"
  ];

  const [organizationInfo, setOrganizationInfo] = useState(initialState);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);
  const [orgDetailsInValid, setOrgDetailsInValid] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [isUpdateValid, setIsUpdateValid] = useState(false);
  const [isShowVerify, setIsShowVerify] = useState(false);
  const [isShowPanVerify, setIsShowPanVerify] = useState(false);
  const [isShowGstVerify, setIsShowGstVerify] = useState(false);
  const [otp, setOtp] = useState("");
  const [isFocused, setIsFocused] = useState("");
  const [isKycCompleted, setIsKycCompleted] = useState(false)

  const dispatch = useDispatch();

  const orgData = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization.organizationDetails
  );

  const saveGSTPanData = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization?.saveTempData
  );

  const isSaveSuccess = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization?.isUpdateSuccess
  );
  const [panVerify, gstVerify] = useSelector((state: RootState) => {
    return [
      state.profile.yourAccountReducer.organization.panRequest,
      state.profile.yourAccountReducer.organization.gstRequest,
    ];
  });

  const isFirstTimeOrgSaved = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization?.firstTimeSaved
  );

  const personalPhoneNo = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.personal?.personalDetails?.data?.data
        ?.phoneNo
  );

  const personalEmail = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo?.userDetail?.email
  );

  const accountDetails = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]
  );

  const isVerifyOtp = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization?.verifyOTPData
  );

  const isLoadingVerifyOtp = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization?.isLoadingVerifyOTP
  );

  const verifyError = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization?.verifyOTPDataError
  );

  const loginUserId = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.userDetail?._id
  );

  const isInvitedTrue = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.userDetail?.isInvited
  );
  const currentSelectedTeamId = useSelector(
    (store: RootState) => store.loginReducer?.currentTeam?.id
  );
  const loginDetails = useSelector((state: any) => {
    return state.loginReducer?.userLoginInfo;
  });
  const accessToken = useSelector(
    (store: any) =>
      store?.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );


  const userId = useSelector(
    (state: any) => state?.loginReducer?.userLoginInfo?.userDetail?._id
  );

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
  );
  const paymentDetails = useSelector(
    (state: RootState) =>
      state.profile?.billingReducer?.pricingPlan?.pricingPlanDetails?.data
        ?.planData
  );
  const myPlan: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  /** for Kyc check */

  useEffect(
    () => {
      if (orgData?.kycDocPath?.length) {
        const value = orgData?.kycDocPath?.every((doc: any) => doc?.isRequested === true)
        setIsKycCompleted(value)
      }
    }, [orgData?.kycDocPath]
  )
  /**getting Plan Wise Channel */
  const handleGetAllChannelTab = () => {
    dispatch(getTeamsData({
      accountId: accountId
    }))
    dispatch(getTeamTabs(currentSelectedTeamId));
    dispatch(getTeamChannels(currentSelectedTeamId));
  }
  /** for setting Telephony First Time */
  const handleAddTelephony = () => {
    const cluster = {
      "clusters": [
        {
          "cluster_name": "teleindia",
          "capacity": "3",
          "weightage": 1,
          "gateways": [
            {
              "name": "ti_airtel2",
              "did_range": " 8048678500-8048678500"
            }
          ]
        }
      ]
    }


    const data = {
      body: {
        name: accountDetails?.name,
        accountId: accountDetails?.id,
        telephony: "Epicode",
        "transactional": cluster
      },
      headers: headers,
      // mode: mode,
    };

    dispatch(createTelephonyData(data));
  }

  /**for showing Pop Up first Time **/
  console.log("hh", isKycCompleted, myPlan, !myPlan?.planName?.length)
  useEffect(
    () => {
      if (isKycCompleted && !myPlan?.planName?.length) {
        handleGenerateOrderId()
        handleAddTelephony()
        dispatch(firstTimeOrgSaved(false));
        setShowPopUp(true);
      }
    }, [isKycCompleted]
  )

  console.log("ghg", paymentDetails, isFirstTimeOrgSaved, isKycCompleted)
  /** for getting all Plan details**/
  const getAllPlansDetails = () => {
    dispatch(
      getPricingPlansAPIData({
        currency: "INR",
        accountId: accountId,
        userId: userId,
      })
    );
  }

  /** for Free Plan Subscribtion**/
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": accessToken,
  };
  const handleActivePlan = () => {
    toast.success("Free Plan Activated")
    setShowPopUp(true);
    dispatch(getMyPlanAPIData({ accountId: accountId }));
    // handleGetAllChannelTab()

    // move to dashboard 

  }

  const handleGenerateOrderId = () => {
    const freePlanId = paymentDetails?.find((e: any) => e?.price === "Free")
    const body = {
      userId: loginDetails?.userDetail["_id"],
      accountId: loginDetails?.accountDetails?.[0]?.id,
      planId: freePlanId?.id,
      selectedDuration: "1 MONTH"
    }

    const data = {
      body: body,
      func: () => handleActivePlan(),
      headers: headers
    }
    console.log(data)
    dispatch(generateOrderId(data, true))
  }

  const handleChangeOTP = (enteredOtp: any) => {
    setOtp(enteredOtp);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "pincode" || e.target.name === "phoneNumber") {
      const value = e.target.value;
      setOrganizationInfo({
        ...organizationInfo,
        [e.target.name]: value.replace(/[^0-9]/g, ""),
      });
    } else if (
      e.target.name === "organizationName" ||
      e.target.name === "organizationDisplayName"
    ) {
      const value = e.target.value;
      setOrganizationInfo({
        ...organizationInfo,
        [e.target.name]: value.replace(/[^a-zA-Z0-9.\- ]/g, ""),
      });
    } else if (e.target.name === "gstNumber" || e.target.name === "pan") {
      const value = e.target.value;
      setOrganizationInfo({
        ...organizationInfo,
        [e.target.name]: value.replace(/[^a-zA-Z0-9]/g, ""),
      });
      if (e.target.name === "gstNumber") {
        dispatch(resetGstVerification());
      } else {
        dispatch(resetPanVerification());
      }
    } else if (e.target.name === "state") {
      const value = e.target.value;
      setOrganizationInfo({
        ...organizationInfo,
        [e.target.name]: value.replace(/[^a-zA-Z ]/g, ""),
      });
    } else if (e.target.name === "organizationEmail") {
      const value = e.target.value;
      setOrganizationInfo({
        ...organizationInfo,
        [e.target.name]: value.replace(/[^a-zA-Z0-9@._\-]/g, ""),
      });
    } else if (e.target.name === "website") {
      const value = e.target.value;
      setOrganizationInfo({
        ...organizationInfo,
        [e.target.name]: value.replace(/[^a-zA-Z0-9./:]/g, ""),
      });
    } else if (e.target.name === "organizationAddress") {
      const value = e.target.value;
      setOrganizationInfo({
        ...organizationInfo,
        [e.target.name]: value.replace(/[^a-zA-Z0-9#.\- ]/g, ""),
      });
    }
    else if (e.target.name === "cinNo") {
      const value = e.target.value;
      //value.replace(/[^A-Z0-9]+$/g, ""),
      setOrganizationInfo({
        ...organizationInfo,
        [e.target.name]: value,
      });
    }

    else {
      const value = e.target.value;
      setOrganizationInfo({
        ...organizationInfo,
        [e.target.name]: value,
      });
    }
    // setIsFocused(e.target.name);
  };

  const handleCancel = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setOrganizationInfo({
      organizationName: "",
      organizationDisplayName: "",
      gstNumber: "",
      website: "",
      pincode: "",
      organizationEmail: "",
      phoneNumber: "",
      pan: "",
      "cinNo": "",
      organizationAddress: "",
      state: "",
    });
  };

  const apiDetails = Object.values(_.pick(orgData, requiredData));
  const userInput = Object.values(_.pick(organizationInfo, requiredData));

  const handleUpdateApiCallValidation = () => {
    if (_.isEqual(userInput, apiDetails)) {
      return false;
    } else {
      return true;
    }
  };

  const handleSave = () => {
    const orgData = {
      ...organizationInfo,

      organizationName: organizationInfo?.organizationName?.slice(0, 50),
      organizationDisplayName: organizationInfo?.organizationDisplayName?.slice(
        0,
        150
      ),
      organizationEmail: organizationInfo?.organizationEmail.slice(0, 100),
      pan: organizationInfo?.pan.toUpperCase(),
      gstNumber: organizationInfo?.gstNumber.toUpperCase(),
      website: organizationInfo?.website.slice(0, 50),
      organizationAddress: organizationInfo?.organizationAddress.slice(0, 80),
      state: organizationInfo?.state.slice(0, 50),
      cinNo: organizationInfo?.cinNo
    };
    setOrganizationInfo(orgData);
    if (isUpdateValid) {
      dispatch(
        saveOrganizationAPIData({
          ...orgData,
          id: accountDetails?.id,
          name: accountDetails?.name,
          // firstTimeSaved: false,
        })
      );
    }
    if (props.scroll) {
      props.scroll()
    }

  };

  const handleOrgFormValidation = () => {
    if (Object.values(organizationInfo).includes("")) {
      return true;
    }
    if (!orgNameValidation(organizationInfo?.organizationName).isValid) {
      return true;
    }
    if (
      !orgDisplayNameValidation(organizationInfo?.organizationDisplayName)
        .isValid
    ) {
      return true;
    }

    if (!pinCodeValidation(organizationInfo?.pincode).isValid) {
      return true;
    }
    if (!orgPhoneNumberValidation(organizationInfo?.phoneNumber).isValid) {
      return true;
    }
    if (!webSiteValidation(organizationInfo?.website).isValid) {
      return true;
    }
    if (!orgAddressValidation(organizationInfo?.organizationAddress).isValid) {
      return true;
    }
    if (!cinNoValidation(organizationInfo?.cinNo).isValid) {
      return true;
    }
    if (!stateValidation(organizationInfo?.state).isValid) {
      return true;
    }
    if (
      !domainValidation(organizationInfo?.organizationEmail, personalEmail)
        .isValid
    ) {
      return true;
    }
    if (isShowVerify) {
      return true;
    }
    if (isShowGstVerify) {
      return true;
    }
    if (isShowPanVerify) {
      return true;
    }
    if (_.isEqual(userInput, apiDetails)) {
      return true;
    } else {
      return false;
    }
  };

  const handleShowVerify = () => {
    if (orgData?.phoneNumber === undefined) {
      if (personalPhoneNo === organizationInfo.phoneNumber) {
        return false;
      } else {
        return true;
      }
    } else if (orgData?.phoneNumber !== undefined) {
      if (personalPhoneNo === organizationInfo.phoneNumber) {
        return false;
      } else {
        if (
          personalPhoneNo !== organizationInfo.phoneNumber ||
          personalPhoneNo !== orgData?.phoneNumber
        ) {
          if (orgData.phoneNumber !== organizationInfo.phoneNumber) {
            return true;
          }
        }
      }
    }
    return false;
  };

  /** calling Api for free plan**/
  useEffect(
    () => {
      if (isFirstTimeOrgSaved) {
        getAllPlansDetails()
      }
    }, [isFirstTimeOrgSaved]
  )

  useEffect(() => {
    setIsShowVerify(handleShowVerify());
  }, [personalPhoneNo, organizationInfo?.phoneNumber, orgData?.phoneNumber]);

  //gst verify
  function handleShowGstVerify() {
    if (
      orgData?.gstNumber?.length > 0 &&
      orgData?.gstNumber === organizationInfo.gstNumber
    ) {
      return false;
    } else if (
      saveGSTPanData?.gst?.length > 0 &&
      saveGSTPanData?.gst === organizationInfo.gstNumber
    ) {
      return false;
    } else if (gstVerify.data) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    setIsShowGstVerify(handleShowGstVerify());
  }, [
    organizationInfo?.gstNumber,
    orgData?.gstNumber,
    gstVerify.data,
    saveGSTPanData,
  ]);

  //pan verify
  function handleShowPanVerify() {
    if (orgData?.pan?.length > 0 && orgData?.pan === organizationInfo.pan) {
      return false;
    } else if (
      saveGSTPanData?.pan?.length > 0 &&
      saveGSTPanData?.pan === organizationInfo.pan
    ) {
      return false;
    } else if (panVerify.data) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    setIsShowPanVerify(handleShowPanVerify());
  }, [organizationInfo?.pan, orgData?.pan, panVerify.data, saveGSTPanData]);

  useEffect(() => {
    if (_.isEqual(initialState, organizationInfo)) {
      setIsCancelDisabled(true);
    } else {
      setIsCancelDisabled(false);
    }
    setOrgDetailsInValid(handleOrgFormValidation());
  }, [
    organizationInfo,
    orgData,
    isShowVerify,
    isShowGstVerify,
    isShowPanVerify,
  ]);

  useEffect(() => {
    dispatch(resetPanVerification());
    dispatch(resetGstVerification());
  }, []);

  useEffect(() => {
    setOrganizationInfo((prev) => {
      return {
        ...prev,
        organizationName: orgData?.organizationName
          ? orgData?.organizationName
          : "",
        organizationDisplayName: orgData?.organizationDisplayName
          ? orgData?.organizationDisplayName
          : "",
        gstNumber: orgData?.gstNumber ? orgData?.gstNumber : "",
        website: orgData?.website ? orgData?.website : "",
        pincode: orgData?.pincode ? orgData?.pincode : "",
        organizationEmail: orgData?.organizationEmail
          ? orgData?.organizationEmail
          : "",
        phoneNumber: orgData?.phoneNumber ? orgData?.phoneNumber : "",
        pan: orgData?.pan ? orgData?.pan : "",
        organizationAddress: orgData?.organizationAddress
          ? orgData?.organizationAddress
          : "",
        state: orgData?.state ? orgData?.state : "",
        cinNo: orgData?.cinNo ? orgData?.cinNo : ""
      };
    });
  }, [orgData]);
  //change added orgData in useEffect
  useEffect(() => {
    setIsUpdateValid(handleUpdateApiCallValidation());
  }, [orgData, organizationInfo]);

  useEffect(() => {
    if (isSaveSuccess) {
      // if (isFirstTimeOrgSaved) {
      //   setShowPopUp(true);
      //   handleGenerateOrderId()
      //   dispatch(firstTimeOrgSaved(false));
      // }
      dispatch(updateOrgStatus(false));
    }
  }, [isSaveSuccess]);

  useEffect(() => {
    if (isVerifyOtp && Object.keys(isVerifyOtp)?.length > 0) {
      setOtp("");
      setShowVerifyModal(false);
    }
  }, [isVerifyOtp]);

  // console.log("kk", showVerifyModal);

  useEffect(() => {
    if (showVerifyModal === false) {
      dispatch(clearVerifyOTP());
    }
  }, [showVerifyModal]);

  return (
    <>
      <div className={styles.organizationFormDiv}>
        <div className={styles.organizationInfoDiv}>
          <div className={styles.leftHalf}>
            <InputContainer
              label="Organization Name"
              inputType="text"
              inputPlaceholder="Organization Name"
              inputValue={organizationInfo?.organizationName?.slice(0, 50)}
              inputName="organizationName"
              inputOnChange={handleInputChange}
              extraInputClass={`${isInvitedTrue ? styles.disabledInputDivStyling : ""
                } ${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${isInvitedTrue ? styles.disabledMainDivStyling : ""
                } ${styles.inputMainDivStyling}`}
              errorMsg={
                organizationInfo?.organizationName.length > 0
                  ? !orgNameValidation(organizationInfo.organizationName)
                    .isValid
                    ? orgNameValidation(organizationInfo.organizationName)
                      .errors.message
                    : isFocused === "organizationName"
                      ? !maxLengthValidations(
                        organizationInfo.organizationName,
                        "Organization Name",
                        50
                      ).isValid
                        ? maxLengthValidations(
                          organizationInfo.organizationName,
                          "Organization Name",
                          50
                        ).errors.message
                        : ""
                      : ""
                  : ""
              }
              inputOnFocus={() => setIsFocused("organizationName")}
              inputOnBlur={() => setIsFocused("")}
              showLabel={true}
            />
            <InputContainer
              label="Organization Display Name"
              inputType="text"
              inputPlaceholder="Organization Display Name"
              inputValue={organizationInfo?.organizationDisplayName?.slice(
                0,
                150
              )}
              inputName="organizationDisplayName"
              inputOnChange={handleInputChange}
              extraInputClass={`${isInvitedTrue ? styles.disabledInputDivStyling : ""
                } ${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${isInvitedTrue ? styles.disabledMainDivStyling : ""
                } ${styles.inputMainDivStyling}`}
              showLabel={true}
              errorMsg={
                organizationInfo?.organizationDisplayName.length > 0
                  ? !orgDisplayNameValidation(
                    organizationInfo.organizationDisplayName
                  ).isValid
                    ? orgDisplayNameValidation(
                      organizationInfo.organizationDisplayName
                    ).errors.message
                    : isFocused === "organizationDisplayName"
                      ? !maxLengthValidations(
                        organizationInfo.organizationDisplayName,
                        "Organization Display Name",
                        150
                      ).isValid
                        ? maxLengthValidations(
                          organizationInfo.organizationDisplayName,
                          "Organization Display Name",
                          150
                        ).errors.message
                        : ""
                      : ""
                  : ""
              }
              inputOnFocus={() => setIsFocused("organizationDisplayName")}
              inputOnBlur={() => setIsFocused("")}
            />
            <InputContainer
              label="GST Number"
              inputType="text"
              inputPlaceholder="GST Number"
              inputName="gstNumber"
              inputValue={organizationInfo?.gstNumber}
              inputOnChange={handleInputChange}
              extraInputClass={`${isInvitedTrue ? styles.disabledInputDivStyling : ""
                } ${styles.inputDivStyling} ${styles.capitalizedStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${isInvitedTrue ? styles.disabledMainDivStyling : ""
                } ${styles.inputMainDivStyling}`}
              showLabel={true}
              maxLength={15}
              extraVerifyClass={
                organizationInfo?.organizationName.length > 0 &&
                  organizationInfo?.gstNumber.length > 0
                  ? ""
                  : styles.disableVerify
              }
              isVerify={isShowGstVerify}
              onClickVerify={() => {
                dispatch(
                  getGstVerification({
                    gst: organizationInfo.gstNumber?.toUpperCase() || "",
                    orgName: organizationInfo.organizationName,
                  })
                );
              }}
            />
            <InputContainer
              label="CIN No"
              inputType="text"
              inputPlaceholder="CIN No"
              inputValue={organizationInfo?.cinNo}
              inputName="cinNo"
              inputOnChange={handleInputChange}
              extraInputClass={`${isInvitedTrue ? styles.disabledInputDivStyling : ""
                } ${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${isInvitedTrue ? styles.disabledMainDivStyling : ""
                } ${styles.inputMainDivStyling}`}
              showLabel={true}
              maxLength={21}
              errorMsg={
                organizationInfo?.cinNo.length > 0
                  ? cinNoValidation(organizationInfo?.cinNo).isValid
                    ? ""
                    : cinNoValidation(organizationInfo?.cinNo).errors.message
                  : ""
              }
            />

            <InputContainer
              label="Website"
              inputType="text"
              inputPlaceholder="Website"
              inputValue={organizationInfo?.website?.slice(0, 50)}
              inputName="website"
              inputOnChange={handleInputChange}
              extraInputClass={`${isInvitedTrue ? styles.disabledInputDivStyling : ""
                } ${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${isInvitedTrue ? styles.disabledMainDivStyling : ""
                } ${styles.inputMainDivStyling}`}
              showLabel={true}
              errorMsg={
                organizationInfo?.website.length > 0
                  ? !webSiteValidation(organizationInfo.website).isValid
                    ? webSiteValidation(organizationInfo.website).errors.message
                    : isFocused === "website"
                      ? !maxLengthValidations(
                        organizationInfo.website,
                        "Website",
                        50
                      ).isValid
                        ? maxLengthValidations(
                          organizationInfo.website,
                          "Website",
                          50
                        ).errors.message
                        : ""
                      : ""
                  : ""
              }
              inputOnFocus={() => setIsFocused("website")}
              inputOnBlur={() => setIsFocused("")}
            />
            <InputContainer
              label="Pin Code"
              inputType="text"
              inputPlaceholder="Pin Code"
              inputValue={organizationInfo?.pincode}
              inputName="pincode"
              inputOnChange={handleInputChange}
              extraInputClass={`${isInvitedTrue ? styles.disabledInputDivStyling : ""
                } ${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${isInvitedTrue ? styles.disabledMainDivStyling : ""
                } ${styles.inputMainDivStyling}`}
              showLabel={true}
              maxLength={6}
              errorMsg={
                organizationInfo?.pincode.length > 0
                  ? pinCodeValidation(organizationInfo.pincode).isValid
                    ? ""
                    : pinCodeValidation(organizationInfo.pincode).errors.message
                  : ""
              }
            />

          </div>
          <div className={styles.rightHalf}>
            <InputContainer
              label="Organization Email"
              inputType="text"
              inputPlaceholder="Organization Email"
              inputValue={organizationInfo?.organizationEmail?.slice(0, 100)}
              inputName="organizationEmail"
              inputOnChange={handleInputChange}
              extraInputClass={`${isInvitedTrue ? styles.disabledInputDivStyling : ""
                } ${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${isInvitedTrue ? styles.disabledMainDivStyling : ""
                } ${styles.inputMainDivStyling}`}
              errorMsg={
                organizationInfo?.organizationEmail.length > 0
                  ? !domainValidation(
                    organizationInfo.organizationEmail,
                    personalEmail
                  ).isValid
                    ? domainValidation(
                      organizationInfo.organizationEmail,
                      personalEmail
                    ).errors.message
                    : isFocused === "organizationEmail"
                      ? !maxLengthValidations(
                        organizationInfo.organizationEmail,
                        "Organization Email",
                        100
                      ).isValid
                        ? maxLengthValidations(
                          organizationInfo.organizationEmail,
                          "Organization Email",
                          100
                        ).errors.message
                        : ""
                      : ""
                  : ""
              }
              showLabel={true}
              inputOnFocus={() => setIsFocused("organizationEmail")}
              inputOnBlur={() => setIsFocused("")}
            />
            <InputContainer
              showLabel={true}
              label="Contact Number"
              inputType="text"
              maxLength={10}
              inputPlaceholder="Contact Number"
              inputValue={organizationInfo?.phoneNumber}
              inputName="phoneNumber"
              inputOnChange={handleInputChange}
              extraInputClass={`${isInvitedTrue ? styles.disabledInputDivStyling : ""
                } ${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${isInvitedTrue ? styles.disabledMainDivStyling : ""
                } ${styles.inputMainDivStyling}`}
              errorMsg={
                organizationInfo?.phoneNumber.length > 0
                  ? orgPhoneNumberValidation(organizationInfo.phoneNumber)
                    .isValid
                    ? ""
                    : orgPhoneNumberValidation(organizationInfo.phoneNumber)
                      .errors.message
                  : ""
              }
              extraVerifyClass={
                !orgPhoneNumberValidation(organizationInfo.phoneNumber).isValid
                  ? styles.disableVerify
                  : ""
              }
              isVerify={isShowVerify}
              onClickVerify={() => {
                // console.log("phoneee", organizationInfo?.phoneNumber);
                if (
                  orgPhoneNumberValidation(organizationInfo.phoneNumber).isValid
                ) {
                  dispatch(
                    sendOrgDataOTP({
                      userId: loginUserId,
                      phoneNumber: organizationInfo?.phoneNumber,
                    })
                  );
                  setShowVerifyModal(true);
                }
              }}
            />
            <InputContainer
              showLabel={true}
              label="PAN Number"
              inputType="text"
              inputPlaceholder="PAN Number"
              inputValue={organizationInfo?.pan}
              inputName="pan"
              inputOnChange={handleInputChange}
              extraInputClass={`${isInvitedTrue ? styles.disabledInputDivStyling : ""
                } ${styles.inputDivStyling} ${styles.capitalizedStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${isInvitedTrue ? styles.disabledMainDivStyling : ""
                } ${styles.inputMainDivStyling}`}
              maxLength={10}
              extraVerifyClass={
                organizationInfo?.organizationName.length > 0 &&
                  organizationInfo?.pan.length > 0
                  ? ""
                  : styles.disableVerify
              }
              isVerify={isShowPanVerify}
              onClickVerify={() => {
                dispatch(
                  getPanVerification({
                    pan: organizationInfo?.pan?.toUpperCase() || "",
                    orgName: organizationInfo.organizationName,
                  })
                );
              }}
            />
            <InputContainer
              showLabel={true}
              label="Organization Address"
              inputType="text"
              inputPlaceholder="Organization Address"
              inputValue={organizationInfo?.organizationAddress?.slice(0, 80)}
              inputName="organizationAddress"
              inputOnChange={handleInputChange}
              extraInputClass={`${isInvitedTrue ? styles.disabledInputDivStyling : ""
                } ${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${isInvitedTrue ? styles.disabledMainDivStyling : ""
                } ${styles.inputMainDivStyling}`}
              errorMsg={
                organizationInfo?.organizationAddress.length > 0
                  ? !orgAddressValidation(organizationInfo.organizationAddress)
                    .isValid
                    ? orgAddressValidation(organizationInfo.organizationAddress)
                      .errors.message
                    : isFocused === "organizationAddress"
                      ? !maxLengthValidations(
                        organizationInfo.organizationAddress,
                        "Organization Address",
                        80
                      ).isValid
                        ? maxLengthValidations(
                          organizationInfo.organizationAddress,
                          "Organization Address",
                          80
                        ).errors.message
                        : ""
                      : ""
                  : ""
              }
              inputOnFocus={() => setIsFocused("organizationAddress")}
              inputOnBlur={() => setIsFocused("")}
            />
            <InputContainer
              showLabel={true}
              label="State"
              inputType="text"
              inputPlaceholder="State"
              inputValue={organizationInfo?.state?.slice(0, 50)}
              inputName="state"
              inputOnChange={handleInputChange}
              extraInputClass={`${isInvitedTrue ? styles.disabledInputDivStyling : ""
                } ${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraUserInputClass={`${isInvitedTrue ? styles.disabledMainDivStyling : ""
                } ${styles.inputMainDivStyling}`}
              extraLabelDivClass={styles.labelDivStyling}
              errorMsg={
                organizationInfo?.state.length > 0
                  ? !stateValidation(organizationInfo.state).isValid
                    ? stateValidation(organizationInfo.state).errors.message
                    : isFocused === "state"
                      ? !maxLengthValidations(organizationInfo.state, "State", 50)
                        .isValid
                        ? maxLengthValidations(
                          organizationInfo.state,
                          "State",
                          50
                        ).errors.message
                        : ""
                      : ""
                  : ""
              }
              inputOnFocus={() => setIsFocused("state")}
              inputOnBlur={() => setIsFocused("")}
            />
          </div>
        </div>
        <div className={styles.organizationBtnDiv}>
          <Button
            text="Cancel"
            extraClass={`${styles.buttonStyling} ${isCancelDisabled || isInvitedTrue
              ? styles.cancelInActiveButtonStyle
              : styles.cancelActiveButtonStyle
              }`}
            onClick={handleCancel}
            disabled={isCancelDisabled}
          />
          <Button
            text="Save"
            extraClass={`${styles.buttonStyling} ${orgDetailsInValid || isInvitedTrue
              ? styles.saveInActiveButtonStyle
              : styles.saveActiveButtonStyle
              }`}
            onClick={handleSave}
            disabled={orgDetailsInValid}
          />
        </div>
      </div>

      <OrgPopUp
        show={showPopUp}
        close={() => {
          setShowPopUp(false);
          handleGetAllChannelTab()
        }}
        onclick={handleGetAllChannelTab}
      />

      <VerifyPopUp
        show={showVerifyModal}
        img={verifyProductIcon}
        extraStyleImg={styles.orgIconStyling}
        close={() => {
          setOtp("");
          setShowVerifyModal(false);
          dispatch(clearVerifyOTP());
        }}
        contentToDisplay={
          <OTPComponent
            otpValue={otp}
            otpText={`I just want to make sure the company phone number you entered is correct. You would have received an OTP on XXXXXXX${organizationInfo?.phoneNumber?.slice(
              -3
            )}. Please enter the OTP to confirm.`}
            otpOnChange={handleChangeOTP}
            otpOnSubmit={() => {
              dispatch(
                verifyOrgDataOTP({
                  userId: loginUserId,
                  phoneNumber: organizationInfo?.phoneNumber,
                  id: accountDetails?.id,
                  name: accountDetails?.name,
                  otp: otp,
                })
              );
            }}
            otpOnResend={() => {
              dispatch(
                sendOrgDataOTP({
                  userId: loginUserId,
                  phoneNumber: organizationInfo?.phoneNumber,
                })
              );
            }}
            isOTPInValid={
              verifyError && Object.keys(verifyError)?.length != 0
                ? true
                : false
            }
            disableConfirmBtn={isLoadingVerifyOtp}
          //phoneNo={organizationInfo?.phoneNumber}
          />
        }
      />
    </>
  );
};
