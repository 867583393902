import actionTypes from "../whatsApp.actionTypes";

export function setWhatsAppScreen(screenName: string) {
  return {
    type: actionTypes.SET_CURRENT_SCREEN,
    payload: screenName,
  };
}

export function setWhatsAppInputData(inputData: object) {
  return {
    type: actionTypes.SET_WHATSAPP_DATA,
    payload: inputData,
  };
}

export function updateWhatsAppProfilePic(payload: object) {
  return {
    type: actionTypes.UPDATE_WHATSAPP_PROFILE_PIC_REQUEST,
    payload: payload,
  };
}

export function deleteWhatsAppProfilePic(payload: object) {
  return {
    type: actionTypes.DELETE_WHATSAPP_PROFILE_PIC_REQUEST,
    payload: payload,
  };
}

// export function setBackIconClicked(payload: boolean) {
//   return {
//     type: actionTypes.SET_BACK_ICON_CLICKED,
//     payload: payload,
//   };
// }

export function setIsDataChanged(payload: boolean) {
  return {
    type: actionTypes.SET_IS_DATA_CHANGED,
    payload: payload,
  };
}

export function getAllIndustry() {
  return {
    type: actionTypes.GET_ALL_INDUSTRY_DATA_REQUEST,
  };
}

export function clearWhatsAppData() {
  return {
    type: actionTypes.CLEAR_WHATSAPP_DATA,
  };
}
