import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { closeTagIcon } from "../../../theme/assets/profileSectionSvg";
import styles from "./TagsInput.module.scss";
import { validateEmailDomain } from "../../../utils/emailValidation";
import { InfoToolTip } from "../infoToolTip/InfoToolTip";
export const TagsInput = forwardRef(
  (
    props: {
      tags: string[];
      selectedTags: CallableFunction;
      placeholder?: string | undefined;
      validEmailDomain: string;
      extraClass?: string | undefined;
      extraClassInput?: any;
      disabled?: boolean;
      showNewToolTipMsg?: any;
      showNewToolTip?: boolean;
      showNewToolTipPosition?: string;
      extraTagsStyling?: string;
      growListDirection?: "horizontal" | "vertical";
    },
    ref
  ) => {
    const [tags, setTags] = useState<string[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      setTags(props.tags);
    }, [props.tags]);
    useImperativeHandle(
      ref,
      () => {
        return {
          clearInput() {
            if (inputRef?.current?.value) {
              inputRef.current.value = "";
            }
          },
        };
      },
      [inputRef]
    );
    const removeTags = (indexToRemove: any) => {
      // props.selectedTags([
      //   ...tags.filter((_: any, index: any) => index !== indexToRemove),
      // ]);
      const newTags = [...tags];
      newTags.splice(indexToRemove, 1);
      // Call the defined function setTags which will replace tags with the new value.
      setTags(() => {
        return newTags;
      });
      props.selectedTags(newTags);
    };

    // const addTags = (event: any) => {
    //   if (event.target.value !== "") {
    //     //setTags([...tags, event.target.value]);
    //     props.selectedTags([...tags, event.target.value.trim()]);
    //     event.target.value = "";
    //   }
    // };

    const addTags = (e: any) => {
      // Only allow specific characters in input
      const allowedCharacters = /[^a-zA-Z0-9@._,;\-]/g;
      if (allowedCharacters.test(e.key)) {
        e.preventDefault();
        return;
      }

      [",", ";"].includes(e.key) && e.preventDefault();

      const val = e.target.value;
      if ((e.key === "Enter" || e.key === "," || e.key === ";") && val) {
        // const val = e.target.value;
        // if (e.key === "Enter" && val) {
        // if (tags.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        //   return;
        // }
        if (e.target.value !== "") {
          setTags([...tags, e.target.value]);
          props.selectedTags([...tags, e.target.value.trim()]);
          e.target.value = "";
        }
      } else if (e.key === "Backspace" && !val) {
        removeTags(tags.length - 1);
      }
    };

    return (
      <div
        className={`${styles.tagsInput} ${
          props.extraClass ? props.extraClass : null
        } ${props?.showNewToolTip ? styles.toolTipStyling : ""}`}
      >
        <ul
          id="tags"
          className={styles.tags}
          data-testid="tags"
          style={
            props.growListDirection === "horizontal"
              ? {
                  flexWrap: "nowrap",
                }
              : {}
          }
        >
          {tags.map((tag: any, index: any) => (
            <li
              key={index}
              className={`${styles.tag}  ${
                props?.extraTagsStyling ? props?.extraTagsStyling : ""
              }`}
              data-isvalid={
                validateEmailDomain(tag, props.validEmailDomain) &&
                tags.filter((each) => each === tag).length === 1
              }
            >
              <span className={`${styles.tagTitle}`}>{tag}</span>
              <span
                className={styles.tagCloseIcon}
                onClick={() => removeTags(index)}
                data-testid="close-icon"
              >
                <img src={closeTagIcon} />
              </span>
            </li>
          ))}
          {tags.length === 10 ? null : (
            <li className={styles.inputTagListStyling}>
              <input
                ref={inputRef}
                type="text"
                onKeyDown={addTags}
                placeholder={
                  props.placeholder
                    ? props.placeholder
                    : tags.length === 0
                    ? "Enter emails"
                    : ""
                }
                className={`${
                  props.extraClassInput ? props.extraClassInput : null
                } ${styles.inputTagStyling}`}
                data-testid="input-field"
              />
            </li>
          )}
        </ul>
        {props?.showNewToolTip && tags?.length === 0 && (
          <InfoToolTip
            message={props?.showNewToolTipMsg}
            isError={false}
            position={props?.showNewToolTipPosition}
            isIconSmall={false}
          />
        )}
      </div>
    );
  }
);
