// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CLQFHtVnRI5BeDczoI9L{display:flex;align-items:center;border-bottom:.5px solid #ccd2e3;box-sizing:border-box;justify-content:center;background-color:#fff}.CLQFHtVnRI5BeDczoI9L .ouWUI4NuSibCDW3pzsoL{margin-left:7px;height:20px;width:1.5px;background-color:#d8d8d8}.CLQFHtVnRI5BeDczoI9L img{margin-left:10px;width:20px;background-color:#fff}.CLQFHtVnRI5BeDczoI9L input{width:100%;padding:7px;border:none;outline:none}`, "",{"version":3,"sources":["webpack://./src/components/generic/ddWIthInputOption/searchInputBox/SearchInputBox.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAEA,gCAAA,CACA,qBAAA,CACA,sBAAA,CAEA,qBAAA,CAEA,4CACE,eAAA,CACA,WAAA,CACA,WAAA,CACA,wBAAA,CAIJ,0BACE,gBAAA,CACA,UAAA,CACA,qBAAA,CAGF,4BACE,UAAA,CACA,WAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".searchAreaDivLLP {\n  display: flex;\n  align-items: center;\n  /* border: 1px solid #000; */\n  border-bottom: 0.5px solid #ccd2e3;\n  box-sizing: border-box;\n  justify-content: center;\n  /* border-radius: 7px; */\n  background-color: #ffffff;\n\n  .separator {\n    margin-left: 7px;\n    height: 20px;\n    width: 1.5px;\n    background-color: #d8d8d8;\n  }\n}\n\n.searchAreaDivLLP img {\n  margin-left: 10px;\n  width: 20px;\n  background-color: #ffffff;\n}\n\n.searchAreaDivLLP input {\n  width: 100%;\n  padding: 7px;\n  border: none;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchAreaDivLLP": `CLQFHtVnRI5BeDczoI9L`,
	"separator": `ouWUI4NuSibCDW3pzsoL`
};
export default ___CSS_LOADER_EXPORT___;
