import React from "react";
import { useState } from "react";
import {
  phoneBlue,
  dropdownUpArrow,
  dropdownDownArrow,
} from "../../../../../../theme/assets/svg";
import FieldComponent from "./FieldComponent";
import "./SummaryContent.scss";
import { format } from "date-fns";
import { Mixpanel } from "../../../../../../utils/mixpanelSetup";
import { useSelector } from "react-redux";

interface props {
  // customerProfile: { [key: string]: string };
  // conversation: { [key: string]: string };
  summaryBody: Record<string, Record<string, string>[]> | undefined;
}
// width 302px or 20.9vw
export default function SummaryContent({ summaryBody }: props) {
  // useSate
  const [showSummary, setShowSummary] = useState<boolean>(true);

  // handlers

  const defaultuser = useSelector((state: any) => {
    return state.loginReducer.userLoginInfo.accountDetails[0].name;
  });
  const handleHeadClick = () => {
    setShowSummary((prev) => !prev);
    Mixpanel.track(" Logger | Transcript Summary | Click", {
      "user Name": defaultuser,
      Date: format(new Date(), "dd-MM-yyyy"),
      Click: "expand summary",
    });
  };

  return (
    <div className="wrapper-summary">
      <div className="summary__content">
        <div className="summary__head" onClick={handleHeadClick}>
          <img className="phone icon" src={phoneBlue} alt="phone icon" />
          <span className="summary__heading">Summary</span>
          {showSummary ? (
            <img
              src={dropdownUpArrow}
              alt="up arrow icon"
              className="up-arrow icon"
            />
          ) : (
            <img
              src={dropdownDownArrow}
              alt="down arrow icon"
              className="down-arrow icon"
            />
          )}
        </div>
        {showSummary && summaryBody && (
          <>
            {Object.keys(summaryBody).map((category, key) => {
              return (
                <div className="summary__body" key={key}>
                  <div className="body__category">
                    <div className="heading">{category}</div>
                    {summaryBody[category]?.map(
                      (item: Record<string, string>, index: number) => {
                        return (
                          <FieldComponent
                            key={index}
                            extraClassField={"customer-profile__fields"}
                            object={item}
                            fieldKey={Object?.keys(item)?.[0]}
                          />
                        );
                      }
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
