import React, { useEffect, useRef, useState } from "react";
import styles from "./AllAgentPage.module.scss";
import AgentCard from "../agentCard/AgentCard";
import StepProgress from "../../../generic/stepProgress/StepProgress";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { backCurveArrow, userWhite } from "../../../../theme/assets/genericSvg";
import Modal from "../../../generic/modal/Modal";
import AgentDetailedView from "../../strategyComponents/agentDetailedView/AgentDetailedView";
import {
  getAllAgents,
  deleteAgentRequest,
  setAgentTypesToShow,
  setAgentToEdit,
  setAgentData,
  createAgentRequest,
  getCampaignSummaryData,
} from "../../../../redux/campaign_V2.0/agentConfiguration/actions/agentConfiguration.action";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setSelectedAgent } from "../../../../redux/campaign_V2.0/agentConfiguration/actions/agentConfiguration.action";
import ModalSimple from "../../../generic/modalSimple/ModalSimple";
import {
  maxCompletedStep,
  storeCurrentStep,
} from "../../../../redux/campaign_V2.0/progressBar/actions";
import {
  channelSearch,
  seperateByComma,
} from "../../../../utils/validators/validators";
import { getRouteByIndex } from "../../../../utils/ProgressBarNavFunc";
import { AgentCreatePopUp } from "../agentCreatePopUp/AgentCreatePopUp";
import CustomBodySkeleton from "../../../generic/customSkeleton/CustomBodySkeleton";
import { getSchedularAPIData } from "../../../../redux/campaign_V2.0/schedularSetting/actions/schedularSetting.actions";
import { toast } from "react-toastify";

export default function AllAgentPage() {
  const idx = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [clickedCard, setClickedCard] = useState<string>("");
  const [showAgentDetail, setShowAgentDetail] = useState<object | null>(null);
  // const [show, setShow] = useState<boolean>(false);
  const [showAgentCreatePopUp, setShowAgentCreatePopUp] =
    useState<boolean>(false);

  const {
    allAgents: { data: allAgentsData },
    agentData: { data: agentInfo },
    selectedAgent: { data: selectedAgentInfo, isNavigate: isAgentSelected },
    campaignSummaryData: { data: campaignSummaryInfo },
  } = useSelector(
    (state: RootState) => state.campaignModuleReducer?.agentConfigData
  );

  const token = useSelector(
    (store: any) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const campaignData = useSelector(
    (store: any) =>
      store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
  );

  const currentStep: number = useSelector(
    (state: RootState) => state.campaignModuleReducer?.progressBar?.currentStep
  );

  const maxStateCompleted = useSelector(
    (state: RootState) =>
      state.campaignModuleReducer?.progressBar?.maxCompletedStep
  );

  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );

  const stepsTotal: string[] = useSelector(
    (store: RootState) =>
      store?.schedulerReducer?.accountInfoDetails?.campaignProgressBar
  );

  const clientNameByDropdown = useSelector(
    (store: RootState) => store.campaignReducer?.campaignClientName
  );

  const schedulerDetails = useSelector((store: RootState) => {
    return store.campaignModuleReducer?.schedulerData?.getSchedularDetails;
  });
  const myPlanData: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );
  const teamUseCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseId
  );
  const currentTeamId = useSelector(
    (state: RootState) => state.loginReducer.currentTeam.id
  );
  console.log("hhh", myPlanData);
  useEffect(() => {
    console.log("hhhhhh", myPlanData);
    if (myPlanData?.isStrategyToShow) {
      let body: any = {
        // clientName: clientNameByDropdown ? clientNameByDropdown : accountName,
        clientName:
          clientNameByDropdown?.length > 0
            ? clientNameByDropdown
            : campaignData?.accountName?.length > 0
              ? campaignData?.accountName
              : accountName,
        useCaseId: teamUseCaseId,
        teamId: currentTeamId
      };
      if (campaignData?.flowType?.length > 0) {
        body["flows"] = campaignData?.flowType;
      }
      if (campaignData?.channels?.length > 0) {
        const channel = [...campaignData?.channels];
        let ind = channel.indexOf("Whatsapp");
        if (ind !== -1) {
          channel[ind] = "WhatsApp";
        }
        body["channels"] = channel;
      }
      if (campaignData?.language?.length > 0) {
        body["languages"] = campaignData?.language;
      }

      dispatch(getAllAgents(body));
    } else {
      let body: any = {
        clientName: `default_saarthi_${myPlanData?.planName}`,
      };
      dispatch(getAllAgents(body));
    }
  }, [agentInfo, campaignData, myPlanData]);

  useEffect(() => {
    if (campaignData?.agentId?.length) {
      if (maxStateCompleted < stepsTotal.indexOf("Agent Configuration") + 1)
        dispatch(
          maxCompletedStep(stepsTotal.indexOf("Agent Configuration") + 1)
        );
    }
  }, [campaignData]);

  useEffect(() => {
    if (location.pathname.includes("allagents")) {
      dispatch(
        storeCurrentStep(stepsTotal?.indexOf("Agent Configuration") + 1)
      );
    }
  }, [location.pathname, stepsTotal]);

  useEffect(() => {
    if (isAgentSelected) {
      // const nextStep = getRouteByIndex(currentStep, stepsTotal);
      // if (nextStep) {
      //   dispatch(setAgentData({ isAgentSelected: false }));
      //   navigate("/campaignmanager");
      // }
      setShowAgentDetail(selectedAgentInfo?.agentId);
    } else {
      setShowAgentDetail(null);
    }
  }, [isAgentSelected]);

  useEffect(() => {
    if (schedulerDetails && Object.keys(schedulerDetails)?.length > 0) {
      navigate("/campaignmanager");
    }
  }, [schedulerDetails]);

  /*****************************  handlers  *****************************/

  function handleCreateAgent(agent: string) {
    navigate("/campaignmanager/createagent");
    dispatch(setAgentTypesToShow(agent));
    dispatch(setAgentData({ agentToEdit: null }));
  }

  function handleClickOnEdit(each: any) {
    navigate("/campaignmanager/createagent");
    dispatch(setAgentToEdit(each));
  }

  function handleClickOnDelete(each: any) {
    let body: any = {
      // clientName: clientNameByDropdown ? clientNameByDropdown : accountName,
      clientName:
        clientNameByDropdown?.length > 0
          ? clientNameByDropdown
          : campaignData?.accountName?.length > 0
            ? campaignData?.accountName
            : accountName,
    };
    if (campaignData?.flowType?.maiaFlow?.length > 0) {
      body["flows"] =
        campaignData?.flowType?.maiaFlow || campaignData?.flowType;
    }
    if (campaignData?.channels?.length > 0) {
      body["channels"] = campaignData?.channels;
    }
    if (campaignData?.language?.length > 0) {
      body["languages"] = campaignData?.language;
    }
    dispatch(
      deleteAgentRequest({
        agentId: each.agentId,
        ...body,
      })
    );
  }
  function handleDuplicate(details: any) {
    const {
      agentId,
      id,
      flowsBasedOnChannel,
      updatedAt,
      createdAt,
      accountId,
      ...dataTosend
    } = details;
    dispatch(createAgentRequest(dataTosend));
  }

  const handleAgentDetailConfirmClick = (agentDetail: any) => {
    dispatch(
      getSchedularAPIData({
        token: token,
        campaignId: campaignData?.id,
        strategyId: campaignData?.strategyId,
      })
    );
  };

  const handleCreateAgentScreen = (e: any) => {
    if (myPlanData?.isStrategyToShow) {
      e.stopPropagation();
      setShowAgentCreatePopUp(true);
    } else {
      toast.error("Please Upgrade Your Plan For Creating an Agent");
    }
  };

  return (
    <div className={styles.agentScreenWrapper}>
      <div className={styles.progresdd}>
        {/* <ModalSimple
          close={() => {
            setShow(false);
          }}
          onClickButton={() => {
            setShow(false);
            navigate("/campaignmanager/schedulersetting");
          }}
          show={show}
          setShow={setShow}
          text="OK"
        /> */}
        <StepProgress
          color={"#07B464"}
          arr={[
            {
              name: "Campaign Details",
              fun: () => {
                navigate("/campaignmanager/campaigncreation");
              },
            },
            {
              name: "Data Upload",
              fun: () => {
                navigate("/campaignmanager/dataupload");
              },
            },
            {
              name: "Strategy Selection",
              fun: () => {
                navigate("/campaignmanager/strategyselection");
              },
            },
            {
              name: "Agent Configuration",
              fun: () => {
                navigate("/campaignmanager/allagents");
              },
            },
          ].filter((each) => stepsTotal.includes(each.name))}
          currentStepComplete={campaignData?.agentId ? true : false}
          maxStateCompleted={maxStateCompleted}
          inProgressStepFromOut={stepsTotal.indexOf("Agent Configuration") + 1}
        />
      </div>
      <hr className={styles.hrStyling} />
      <div className={styles.allAgentScreen_wrapper}>
        <div className={styles.all_agent_top}>
          <div className={styles.allagent_wrapper_leftside}>
            <img
              className={styles.backIcon}
              src={backCurveArrow}
              alt="back"
              onClick={() => navigate("/campaignmanager/strategyselection")}
            />
            <p className={styles.youragent}>Agent Configuration</p>
          </div>
          <div>
            {!myPlanData?.isStrategyToShow ? (
              <></>
            ) : (
              <button
                className={styles.createNewButton}
                onClick={(e: any) => {
                  handleCreateAgentScreen(e);
                }}
                style={{ cursor: "pointer" }}
              >
                <img src={userWhite} alt="folder" id="auto_agent_img" />
                <p>Create Agent</p>
              </button>
            )}
          </div>
        </div>

        {/* <p className={styles.recomentt_para}>Recommended</p> */}
        <div className={styles.recent_para_wrapper}>
          {allAgentsData?.map((i: any, index: number) => {
            return (
              <div
                className={`${styles.card_wrap} ${campaignData?.agentId == i.agentId ? styles.bluehighlight : ""
                  }`}
                key={i.agentId}
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(
                    setSelectedAgent({
                      agentId: i?.agentId,
                      campaignId: [campaignData?.id],
                      campaignData: campaignData,
                      selectedAgentId: campaignData?.agentId,
                      personas: campaignData?.personas,
                      token: token,
                    })
                  );

                  // selectedAgentInfo?.agentId != i.agentId && handleClickAgent(i);
                  // selectedAgentInfo?.agentId == i.agentId && setShow(true);
                }}
                id={`agent_${i}`}
              >
                <CustomBodySkeleton isLoading={false}>
                  <AgentCard
                    index={index + 1}
                    agentInfo={{
                      name: i.agentId,
                      assignedCampaign: i?.assignedCampaign
                        ? i.assignedCampaign
                        : null,
                      channels: channelSearch(i) || [],
                      femaleAgentName: i.femaleAgentName,
                      maleAgentName: i.maleAgentName,
                      languages: i?.languages || [],
                      description: i?.description || "",
                    }}
                    editButtonFun={() => {
                      handleClickOnEdit(i);
                    }}
                    duplicateBtnFunc={() => {
                      handleDuplicate(i);
                    }}
                    deleteButtonFun={() => {
                      handleClickOnDelete(i);
                    }}
                    clickedCard={clickedCard}
                    setClickedCard={setClickedCard}
                    isStrategyToShow={myPlanData?.isStrategyToShow}
                  />
                </CustomBodySkeleton>
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        shown={showAgentDetail != null}
        close={() => {
          setShowAgentDetail(null);
          dispatch(setAgentData({ isAgentSelected: false }));
        }}
        extraClassStyleModalContent={styles.extraModalContent}
      >
        <AgentDetailedView
          agent={showAgentDetail as object}
          summaryData={campaignSummaryInfo}
          onCancel={() => {
            setShowAgentDetail(null);
            dispatch(setAgentData({ isAgentSelected: false }));
          }}
          onConfirm={handleAgentDetailConfirmClick}
        />
      </Modal>

      {showAgentCreatePopUp && (
        <Modal
          shown={showAgentCreatePopUp}
          close={() => setShowAgentCreatePopUp(false)}
          extraClassStyleModalContent={styles.extraAgentModalContent}
        >
          <AgentCreatePopUp
            onClose={() => {
              setShowAgentCreatePopUp(false);
            }}
            onSendGender={(agent: string) => handleCreateAgent(agent)}
          />
        </Modal>
      )}
    </div>
  );
}
