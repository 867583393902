interface channelInterface {
  domains: Array<string>;
  isDefault: boolean;
  parentId: Array<string>;
  isActive: boolean;
  screen: Array<string>;
  isDisabled: boolean;
  name: string;
  type: string;
  subType: string;
  icon: string;
  position: number;
  displayName: string;
  createdAt: string;
  updatedAt: string;
  inActiveIcon: string;
  activeIcon: string;
  id: string;
  childChannel?: Array<channelInterface>;
}

export const getChannelsToShow = (
  currentDomain: string,
  teamChannels: Array<channelInterface>,
  outSideSelectedChannel: channelInterface | undefined,
  outSideSelectedSubChannel: channelInterface | undefined
) => {
  // console.log("domain", currentDomain);
  // checks
  if (!currentDomain)
    throw new Error(
      `analyticsLoggerHeader.fn.ts > getChannelsToShow: undefined domain`
    );

  // add child to parent
  teamChannels.forEach((channel) => {
    if (channel.parentId?.length > 0 && channel.isActive) {
      addChildToParent(channel);
    }
  });

  // removes all child channels
  teamChannels = teamChannels.flatMap((item) => {
    if (item.parentId.length <= 0) {
      return [item];
    }
    return [];
  });

  const channelsToShow: Array<Record<string, any>> = [];
  teamChannels.forEach((item: Record<string, any>) => {
    if (item.isActive && item.domains.includes(currentDomain)) {
      channelsToShow.push(item);
      // sort childChannels
      if (item.childChannel?.length) {
        item.childChannel = item.childChannel.sort(
          (a: Record<string, any>, b: Record<string, any>) =>
            a.position - b.position
        );
      }
    }
  });

  // output function
  let defaultSelectedChannel = null;
  let defaultSelectedSubChannel: any = null;
  // setting selected tab if outsideSelected channel exist.
  if (outSideSelectedChannel?.name) {
    if (
      channelsToShow.find(
        (item) => item.id === outSideSelectedChannel.id && !item.isDisabled
      )
    ) {
      defaultSelectedChannel = outSideSelectedChannel;
      if (outSideSelectedSubChannel?.name) {
        if (
          defaultSelectedChannel?.childChannel &&
          defaultSelectedChannel.childChannel.find(
            (item) =>
              item.id === outSideSelectedSubChannel.id && !item.isDisabled
          )
        )
          defaultSelectedSubChannel = outSideSelectedSubChannel;
      }
    }
  }

  if (!defaultSelectedChannel) {
    defaultSelectedChannel =
      channelsToShow.find((item) => item.isDefault && !item.isDisabled) ||
      channelsToShow.find((item) => !item.isDisabled);
  }
  if (
    defaultSelectedChannel?.childChannel &&
    !(
      defaultSelectedSubChannel &&
      (defaultSelectedChannel as channelInterface)?.childChannel?.find(
        (item) => item.id === defaultSelectedSubChannel.id
      )
    )
  ) {
    defaultSelectedSubChannel =
      defaultSelectedChannel.childChannel.find(
        (item: channelInterface) => item.isDefault && !item.isDisabled
      ) ||
      defaultSelectedChannel.childChannel.find(
        (item: channelInterface) => !item.isDisabled
      );
  }

  return {
    channelsToShow,
    defaultSelectedChannel,
    defaultSelectedSubChannel,
  };

  /* helper function ---------------- */
  // function to add children in parent
  function addChildToParent(child: channelInterface) {
    child.parentId?.forEach((id: string) => {
      teamChannels.forEach((item) => {
        if (item.id === id) {
          if (item.childChannel?.length) {
            item.childChannel = [...(item?.childChannel || []), child];
          } else {
            item.childChannel = [child];
          }
          return;
        }
      });
    });
  }
};

export const getTabsToShow = (
  currentDomainId: string,
  teamTabs: Array<Record<string, any>>,
  currentChannelId: string,
  isActivePage: string,
  outSideSelectedSubModuleTab: Record<string, any>
) => {
  let defaultSelectedTab: Record<string, any> = {};
  // setting default selected tab
  if (outSideSelectedSubModuleTab && Object.keys(outSideSelectedSubModuleTab)) {
    defaultSelectedTab = outSideSelectedSubModuleTab;
  }

  const tabsToShow: Array<Record<string, any>> = [];
  teamTabs.forEach((item: Record<string, any>) => {
    if (item.isActive) {
      if (
        item.domains.includes(currentDomainId) &&
        item.channelId.includes(currentChannelId) &&
        item.screen.includes(isActivePage)
      ) {
        tabsToShow.push(item);
        if (
          item.isDefault &&
          !defaultSelectedTab.keys(defaultSelectedTab)?.length
        ) {
          defaultSelectedTab = item;
        }
      }
    }
  });

  // if outside selected tab is not in tabs to show > defaultSelected = {}
  if (Object.keys(defaultSelectedTab).length) {
    let present = false;
    tabsToShow.forEach((item) => {
      if (item.name === defaultSelectedTab.name) present = true;
    });
    if (!present) defaultSelectedTab = {};
  }

  tabsToShow.sort((a, b) => a.position - b.position);
  if (!Object.keys(defaultSelectedTab).length) {
    defaultSelectedTab = tabsToShow[0];
  }
  return { tabsToShow, defaultSelectedTab };
};
