import agentActions from "../actionTypes";

export function getAllAgents(data: object) {
  return {
    type: agentActions.GET_ALL_AGENTS_API_REQUEST,
    payload: data,
  };
}

export function setAgentTypesToShow(data: string) {
  return {
    type: agentActions.SET_AGENT_TYPES_TO_SHOW,
    payload: data,
  };
}

export function createAgentRequest(data: object) {
  return {
    type: agentActions.CREATE_AGENT_API_REQUEST,
    payload: data,
  };
}

export function setAgentData(payload: object) {
  return {
    type: agentActions.SET_AGENT_DATA,
    payload: payload,
  };
}

export function setSelectedAgent(data: object) {
  return {
    type: agentActions.SET_SELECTED_AGENT_API_REQUEST,
    payload: data,
  };
}

export function deleteAgentRequest(data: object) {
  return {
    type: agentActions.DELETE_AGENT_API_REQUEST,
    payload: data,
  };
}

export function editAgentRequest(data: object) {
  return {
    type: agentActions.EDIT_AGENT_API_REQUEST,
    payload: data,
  };
}

export function setAgentToEdit(data: object) {
  return {
    type: agentActions.SET_AGENT_TO_EDIT,
    payload: data,
  };
}

export function getCampaignSummaryData(data: object) {
  return {
    type: agentActions.GET_ALL_CAMPAIGN_SUMMARY_DATA_REQUEST,
    payload: data,
  };
}

export function downloadUploadedCSVZip(payload: object) {
  return {
    type: agentActions.GET_DOWNLOAD_CSV_ZIP_API_REQUEST,
    payload: payload,
  };
}
