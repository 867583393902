import actionTypes from "../personalDetails.actionTypes";
export function getPersonalDetails(payload: any) {
  return {
    type: actionTypes.PROFILE_PERSONAL_GET_DETAILS_API_CALL,
    payload: payload,
  };
}
export function updateProfilePic(payload: any) {
  return {
    type: actionTypes.UPDATE_PROFILE_PIC,
    payload: payload,
  };
}
export function deleteProfilePic(payload: any) {
  return {
    type: actionTypes.DELETE_PROFILE_PIC,
    payload: payload,
  };
}

export function updateUserDetails(payload: any) {
  return {
    type: actionTypes.PROFILE_UPDATE_USERNAME_PHONE,
    payload: payload,
  };
}
//
