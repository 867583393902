import moment from "moment";
import React, { useState } from "react";
import {
  copyIconGrey,
  copyBlueFilled,
  tickBlue,
} from "../../../../../theme/assets/agentDesktopSvg";

import "./SessionId.scss";
import NewToolTip from "../../newToolTip/NewToolTip";
import { newDateAndTime } from "../../../../../utils/timeAndDate/time.util";
interface props {
  sessionId: string;
  dateTime: Date;
  handleSessionIdClick: (id: string, sessionId: string) => void;
  currentSelected: string;
  id: string;
}

export default function SessionID(props: props) {
  /* useStates ------ */
  const [icon, setIcon] = useState(copyIconGrey);
  const [isCopied, setIsCopied] = useState(false);
  const [toolTipText, setToolTipText] = useState("Copy Session Id");

  /* handlers ------- */
  const handleClick = () =>
    props.handleSessionIdClick(props.id, props.sessionId);
  const handleIconMouseOver = () => !isCopied && setIcon(copyBlueFilled);
  const handleIconMouseOut = () => !isCopied && setIcon(copyIconGrey);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(props.sessionId);
    setIcon(tickBlue);
    setIsCopied(true);
    setToolTipText("Session ID Copied");
    setTimeout(() => {
      setIcon(copyIconGrey);
      setIsCopied(false);
      setToolTipText("Copy Session Id");
    }, 1500);
  };

  return (
    <div
      className={`agent__session-id__component ${
        props.currentSelected === props.id &&
        "agent__session-id__component__selected"
      }`}
      onClick={handleClick}
    >
      <span className="date">
        {/* {moment(props.dateTime).format("DD MMM YY | HH:MM A")}
         */}
        {newDateAndTime(props.dateTime)}
      </span>
      <NewToolTip content={toolTipText} direction="right">
        <img
          src={icon}
          alt=""
          className="copy-icon"
          onClick={handleCopyClick}
          onMouseOver={handleIconMouseOver}
          onMouseOut={handleIconMouseOut}
        />
      </NewToolTip>
    </div>
  );
}
