import React, { useEffect, useState } from "react";
import styles from "./IntegrationsComponent.module.scss";
import InputContainer from "../../../generic/inputContainer/InputContainer";
import { searchCategoryIcon } from "../../../../theme/assets/svg/integration_V2.0/index";
import { IntegrationCard } from "../integrationCard/IntegrationCard";
import { SelectedIntegrationDisplay } from "../selectedIntegrationDisplay/SelectedIntegrationDisplay";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux";
import { SKELTON_DATA } from "../../../../constants/skeletonDummyData";
import { setSelectedIntegrationInfo } from "../../../../redux/integration_V2.0/allIntegrations/actions";
import CustomBodySkeleton from "../../../generic/customSkeleton/CustomBodySkeleton";


interface propsInterfaceProps {
  isLoading: boolean;
}

const StatusValueDisplay = ({ value }: any) => {
  let customStyles;
  switch (value) {
    case "View Api Key":
      customStyles = {
        color: "#00d95f",
        backgroundColor: "#ebfae2",
        padding: "5px",
        minWidth: "100px",
        width: "auto",
      };
      break;
    case "Connected":
      customStyles = {
        color: "#00d95f",
        backgroundColor: "#ebfae2",
        padding: "5px",
        minWidth: "100px",
        width: "auto",
      };
      break;
    case "Under Verification":
      customStyles = {
        color: "#E99222",
        backgroundColor: "#E992221A",
        padding: "5px",
        minWidth: "140px",
        width: "auto",
      };
      break;
    case "In Progress":
      customStyles = {
        color: "#9A1AFE",
        backgroundColor: "#9747FF1A",
        padding: "5px",
        minWidth: "100px",
        width: "auto",
      };
      break;
    default:
      customStyles = {
        color: "#00d95f",
        backgroundColor: "#ebfae2",
        padding: "5px",
        minWidth: "100px",
        width: "auto",
      };
  }
  return <div style={customStyles}>{value}</div>;
};

export const IntegrationsComponent = (props: propsInterfaceProps) => {
  const dispatch = useDispatch();
  // const filteredData = [
  //   {
  //     icon: customIntegrationAPI,
  //     name: "Custom Integration API",
  //     desc: "APIs for you to push allocation and payments data.",
  //     recommended: true,
  //     error: "APIs for you to push allocation and payments data.",
  //   },
  // ];

  const [searchIntegration, setSearchIntegration] = useState("");
  const [selectedIntegrationName, setSelectedIntegrationName] = useState("");
  const [selectedIntegration, setSelectedIntegration] = useState({});
  const [showIntegrationPopUp, setShowIntegrationPopUp] = useState(false);
  const [integrationsData, setIntegrationsData] = useState([]);

  const allIntegrationsInfo = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.allIntegrationsData
  );

  const filteredCategoryData: any = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.filteredData
  );

  const filterIntegrations = (searchInput: string) => {
    const dataString = searchInput.trim();
    return filteredCategoryData?.filter((integration: any) => {
      return (
        integration.integrationName
          .toLowerCase()
          .indexOf(dataString.toLowerCase()) !== -1
      );
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchIntegration(e.target.value);
  };

  const handleSelectedIntegration = (name: string, data: object) => {
    setSelectedIntegrationName(name);
    setSelectedIntegration(data);
    dispatch(setSelectedIntegrationInfo(data));
    setShowIntegrationPopUp(true);
  };

  useEffect(() => {
    if (filteredCategoryData) {
      setIntegrationsData(filteredCategoryData);
    }
  }, [filteredCategoryData, allIntegrationsInfo]);

  useEffect(() => {
    const filteredObjects = filterIntegrations(searchIntegration);
    setIntegrationsData(filteredObjects);
  }, [searchIntegration, filteredCategoryData]);

  return (
    <div className={styles.integrationCardsWrapper}>
      <div className={styles.integrationHeader}>
        <CustomBodySkeleton isLoading={props.isLoading}>
          <div className={styles.integrationHeading}>Integration</div>
        </CustomBodySkeleton>
        <div className={styles.integrationDesc}>
          <CustomBodySkeleton isLoading={props.isLoading}>
            <span>
              Connect with external apps and tools to turbo-charge your
              campaigns. We have a wide variety of integration capabilities for
              providing you best results. You can check out the{" "}
              <a
                href={"https://help.pravid.io/docs"}
                target="_blank"
                rel="noreferrer"
                className={styles.linkStyling}
              >
                Integrations help section
              </a>{" "}
              to better understand how these apps and integrations work.
            </span>
          </CustomBodySkeleton>
        </div>
      </div>
      <hr className={styles.divSection} />
      <div className={styles.integrationCardsTopDiv}>
        <CustomBodySkeleton
          isLoading={props.isLoading}
          variation={"text"}
          textVariation="h4"
          width={"22ch"}
        >
          <div className={styles.integrationCardsTitle}>
            Available Integrations
          </div>
        </CustomBodySkeleton>
        <CustomBodySkeleton isLoading={props.isLoading}>
          <div>
            <InputContainer
              inputType="text"
              inputPlaceholder="Search"
              inputValue={searchIntegration}
              inputOnChange={handleInputChange}
              showLabel={false}
              inputIconLeft={searchCategoryIcon}
              extraInputClass={`${styles.inputDivStyling}`}
              extraUserInputClass={` ${styles.inputMainDivStyling}`}
              noErrorMsg={true}
            />
          </div>
        </CustomBodySkeleton>
      </div>

      <div className={styles.integrationCards}>
        {(props.isLoading
          ? SKELTON_DATA.integrationCards
          : integrationsData
        )?.map((data: any, index: number) => {
          return (
            <div className={styles.integrationCard} key={index}>
              <IntegrationCard
                isLoading={props.isLoading}
                integrationInfo={data}
                onClickFunc={() =>
                  handleSelectedIntegration(data?.integrationName, data)
                }
                statusInfo={
                  <StatusValueDisplay value={data?.integrationStatus} />
                }
              />
            </div>
          );
        })}
      </div>

      <SelectedIntegrationDisplay
        integrationName={selectedIntegrationName}
        integrationData={selectedIntegration}
        shown={showIntegrationPopUp}
        close={() => {
          setShowIntegrationPopUp(false);
        }}
      />
    </div>
  );
};
