import React from "react";
import styles from "./CardComponent.module.scss";
import { viewIcon } from "../../../../../theme/assets/svg/campaign_v3";


interface CardData {
  title: string;
  heading: string;
  description: string;
  icon?: string;
  bgColor: string;
  color: string
  variation?: string,
  version?: string
}

interface cardProps {
  key: number;
  cardData: CardData;
  onClickView: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isSelected: boolean;
  sendSelectedData: (data: CardData) => void;
}
export const CardComponent = ({
  cardData,
  key,
  onClickView,
  isSelected,
  sendSelectedData,
}: cardProps) => {
  return (
    <div
      className={`${styles.scriptCardWrapper} ${isSelected ? styles.selectedScriptCard : ""
        }`}
      key={key}
      onClick={() => sendSelectedData(cardData)}
    >
      <div className={styles.headerDiv}>
        <div
          className={styles.header}
          style={cardData?.title ? {
            backgroundColor: cardData?.bgColor,
            color: cardData?.color,
          } : {}}
        >
          {cardData?.title}
        </div>
        <div>
          <img
            src={viewIcon}
            alt="view"
            className={styles.viewBtn}
            onClick={onClickView}
          />
        </div>
      </div>
      <div className={styles.headerText}>{cardData?.heading}</div>
      <div className={styles.descText}>{cardData?.description}</div>
      {
        cardData?.variation && <div className={styles.variation}>
          <span>{cardData?.version}</span> <span>{cardData?.variation}</span>
        </div>
      }
    </div>
  );
};
