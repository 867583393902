import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { RootState } from "../../../../../redux";
import styles from "./AddOns.module.scss";
import Button from "../../../../generic/button/Button";
import { useNavigate } from "react-router-dom";
// import { AddOnsTable } from "./addOnsTable/AddOnsTable";
// import { getInvoicesAPIData } from "../../../../../redux/profile/billing/invoiceDetails/actions/invoiceDetails.actions";
// import ReactPaginate from "react-paginate";
import { AddOnsProgress } from "./addOnsProgress/AddOnsProgress";
interface addOnDataProps {
  planData: any;
  orderSummary: any;
}

export const AddOns = ({ planData, orderSummary }: addOnDataProps) => {
  const navigate = useNavigate();

  // const [limit, setLimit] = useState(4);
  // const [currentPage, setCurrentPage] = useState<number>(1);

  // const accountId = useSelector(
  //   (store: RootState) =>
  //     store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
  // );

  // const isLoading = useSelector(
  //   (store: RootState) =>
  //     store?.profile?.billingReducer?.invoices?.isLoadingInvoiceDetails
  // );

  // const invoicesInfo = useSelector(
  //   (store: RootState) =>
  //     store?.profile?.billingReducer?.invoices?.invoiceDetails?.invoices
  // );

  // const totalPages = useSelector(
  //   (store: RootState) =>
  //     store?.profile?.billingReducer?.invoices?.invoiceDetails?.totalPage
  // );

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (accountId) {
  //     dispatch(getInvoicesAPIData(accountId, limit, currentPage));
  //   }
  // }, [accountId, limit, currentPage]);

  // const handlePageChange = (page: any) => {
  //   setCurrentPage(Number(page.selected) + 1);
  // };

  return (
    <div className={styles.addOnTableWrapper}>
      <div className={styles.addOnTableHeaderWrapper}>
        <div className={styles.addOnTableHeader}>ADD-Ons</div>
        <Button
          text="Add more"
          extraClass={`${styles.addButtonStyling}`}
          onClick={() => navigate("/profile/billing/addonsinvoices")}
        />
      </div>
      <AddOnsProgress planData={planData} />
      {/* <AddOnsTable addOnsInfo={invoicesInfo} />
      {totalPages > 1 && (
        <div className={styles.addOnsPaginationDiv}>
          <div className={styles.pageTextStyling}>
            Showing <strong>{currentPage * limit - (limit - 1)}</strong> to{" "}
            <strong>{currentPage * limit}</strong> of <strong>47</strong>{" "}
            results
          </div>
          <div className={styles.pagination}>
            <div className={styles.ok}></div>
            <div>
              <ReactPaginate
                nextLabel={">"}
                onPageChange={handlePageChange}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={totalPages}
                forcePage={currentPage - 1}
                previousLabel="<"
                pageClassName={styles["page-item"]}
                pageLinkClassName={styles["page-link"]}
                previousClassName={styles["prev"]}
                previousLinkClassName={styles["page-link"]}
                nextClassName={styles["next"]}
                nextLinkClassName={styles["page-link"]}
                breakLabel="..."
                breakClassName={styles["page-item"]}
                breakLinkClassName={styles["page-link"]}
                containerClassName={"pagination"}
                activeClassName={styles["active"]}
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      )}  */}
    </div>
  );
};
