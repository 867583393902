// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UKznAQi_nwqetUNq_3rW{height:80%}.UKznAQi_nwqetUNq_3rW .jTSWxfxmL1klLqUpQrIA{display:flex;height:100%}.UKznAQi_nwqetUNq_3rW .BvCMa3Qp3O3mZIWqzZyJ{width:100%;margin-left:1%;margin-right:1%}.UKznAQi_nwqetUNq_3rW .ovPn8_Xdy4CQd42I5e4n{width:30%;margin-right:1%;height:100%}.UKznAQi_nwqetUNq_3rW .BxKIGEHeSwEZIe7tcpFw{display:flex;justify-content:flex-start;align-items:center;width:100%;padding:0px 20px 0px 25px;margin-bottom:20px !important}`, "",{"version":3,"sources":["webpack://./src/screens/campaign/smsSchedulerSettingPage/SMSsettingPage.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,4CACI,YAAA,CACA,WAAA,CAGJ,4CAEI,UAAA,CACA,cAAA,CACA,eAAA,CAEJ,4CACI,SAAA,CAEA,eAAA,CACA,WAAA,CAEJ,4CACI,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,UAAA,CACA,yBAAA,CACA,6BAAA","sourcesContent":[".smssettingPageWrapper{\n    height: 80%;\n    .schedulerSettingPageWp{\n        display: flex;\n        height: 100%;\n    \n    }\n    .scheduleSettingwp{\n        // width: 70%;\n        width: 100%;\n        margin-left: 1%;\n        margin-right: 1%;\n    }\n    .previewWp{\n        width: 30%;\n        // height: 65vh;\n        margin-right: 1%;\n        height: 100%;\n    }\n    .breadcrum-wrapper {\n        display: flex;\n        justify-content: flex-start;\n        align-items: center;\n        width: 100%;\n        padding: 0px 20px 0px 25px;\n        margin-bottom: 20px !important;\n    }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smssettingPageWrapper": `UKznAQi_nwqetUNq_3rW`,
	"schedulerSettingPageWp": `jTSWxfxmL1klLqUpQrIA`,
	"scheduleSettingwp": `BvCMa3Qp3O3mZIWqzZyJ`,
	"previewWp": `ovPn8_Xdy4CQd42I5e4n`,
	"breadcrum-wrapper": `BxKIGEHeSwEZIe7tcpFw`
};
export default ___CSS_LOADER_EXPORT___;
