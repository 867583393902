import { combineReducers } from "redux";
import billingReducer from "./billing/billing.rootReducer";
import yourAccountReducer from "./yourAccount/yourAccount.rootReducer";
import settingsReducer from "./settings/settings.rootReducer";
import navigationReducer from "./navigation/reducer/navigation.reducer";
const profileReducer = combineReducers({
  yourAccountReducer,
  billingReducer,
  settingsReducer,
  navigationReducer,
});
export default profileReducer;
