import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { CAMPAIGN_NEW_API, config } from "../../../../services/ApiRoutes";
import actionTypes from "../createCampaign.actionTypes";
import _ from "lodash";

export function* getCampaignASRDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        `${CAMPAIGN_NEW_API.GET_ASR_OPTIONS}`,
        action.payload
      );
      yield put({
        type: actionTypes.GET_CAMPAIGN_ASR_API_SUCCESS,
        payload: result.data.data,
      });
      toast.dismiss();
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.GET_CAMPAIGN_ASR_API_FAILURE,
      payload: error.response?.data?.error?.message,
    });
    toast.dismiss();
    console.error(error);
  }
}

export function* getCampaignTTSDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        `${CAMPAIGN_NEW_API.GET_TTS_OTPIONS}`,
        action.payload
      );
      yield put({
        type: actionTypes.GET_CAMPAIGN_TTS_API_SUCCESS,
        payload: result.data.data,
      });
      toast.dismiss();
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.GET_CAMPAIGN_TTS_API_FAILURE,
      payload: error.response?.data?.error?.message,
    });
    toast.dismiss();
    console.error(error);
  }
}

export function* getCampaignOptionsDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET,
        `${CAMPAIGN_NEW_API.GET_CAMPAIGN_OPTIONS}`
      );
      yield put({
        type: actionTypes.GET_CAMPAIGN_OPTIONS_API_SUCCESS,
        payload: result.data.data,
      });
      toast.dismiss();
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.GET_CAMPAIGN_OPTIONS_API_FAILURE,
      payload: error.response?.data?.error?.message,
    });
    toast.dismiss();
    console.error(error);
  }
}

export function* createCampaignDataAPIWorker(action: any): any {
  try {
    toast.dismiss();
    const id = toast.loading("Please Wait ... ");
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${CAMPAIGN_NEW_API.CREATE_CAMPAIGN}`,
        _.omit(action.payload, "token"),
        {
          "x-access-token": action.payload.token,
          "Content-Type": "application/json",
        }
      );

      yield put({
        type: actionTypes.CREATE_CAMPAIGN_DATA_API_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: actionTypes.SET_CAMPAIGN_DATA,
        payload: { data: result.data.data },
      });
      if (result.data.data) {
        toast.dismiss(id);
        toast.success("Campaign Created Successfully");
      }
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.CREATE_CAMPAIGN_DATA_API_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    toast.error(error.response?.data?.error);
  }
}

export function* updateCampaignDataAPIWorker(action: any): any {
  try {
    toast.dismiss();
    const id = toast.loading("Please Wait ... ");
    if (action) {
      const res = action.payload;
      const { personas, token, ...updatedPayload } = res;
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${CAMPAIGN_NEW_API.UPDATE_CAMPAIGN}`,
        updatedPayload,
        {
          "x-access-token": token,
          "Content-Type": "application/json",
        }
      );
      yield put({
        type: actionTypes.UPDATE_CAMPAIGN_DATA_API_SUCCESS,
        payload: { ...result.data.data[0], personas: personas },
      });
      yield put({
        type: actionTypes.SET_CAMPAIGN_DATA,
        payload: { data: { ...result.data.data[0], personas: personas } },
      });
      toast.dismiss(id);
      toast.success("Campaign Updated Successfully");
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.UPDATE_CAMPAIGN_DATA_API_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    toast.error(error.response?.data?.error);
    console.error(error);
  }
}
