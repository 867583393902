import actionTypes from "../actionTypes";

export const mixpannelModuleCount = (bodyData: any) => {
  return {
    type: actionTypes.SET_COUNT_MODULE_CLICK,
    payload:  bodyData ,
  };
};

export const mixpannelTranscriptSessionIdDetails = (data: any) => {
  return {
    type: actionTypes.SET_TRANSCRIPT_SESSIONID,
    payload:  data,
  };
};
export const mixpannelForgotPasswordCount = (data: any) => {
  return {
    type: actionTypes.SET_FORGOT_PASSWORD_COUNT,
    payload:  data,
  };
};
export const mixpannelCampaignDetails = (data: any) => {
  return {
    type: actionTypes.SET_CAMPAIGN_DETAILS,
    payload:  data,
  };
};

export const mixpannelModuleDetails = (data: any) => {
  return {
    type: actionTypes.SET_MIXPANNEL_MODULE_DETAILS,
    payload:  data,
  };
};
export const mixpannelIntegrationDetails = (data: any) => {
  return {
    type: actionTypes.SET_MIXPANNEL_INTEGRATION_DETAILS,
    payload:  data,
  };
};
export const mixpannelLogin = (data: any) => {
  return {
    type: actionTypes.SET_MIXPANNEL_LOGIN_DETAILS,
    payload:  data,
  };
};

