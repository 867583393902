export default {
  SCHEDULAR_DATA_API_REQUEST: "SCHEDULAR_DATA_API_REQUEST",
  SCHEDULAR_DATA_API_SUCCESS: "SCHEDULAR_DATA_API_SUCCESS",
  SCHEDULAR_DATA_API_FAILURE: "SCHEDULAR_DATA_API_FAILURE",

  GET_SCHEDULAR_DATA_API_REQUEST: "GET_SCHEDULAR_DATA_API_REQUEST",
  GET_SCHEDULAR_DATA_API_SUCCESS: "GET_SCHEDULAR_DATA_API_SUCCESS",
  GET_SCHEDULAR_DATA_API_FAILURE: "GET_SCHEDULAR_DATA_API_FAILURE",

  CLEAR_SCHEDULAR_DATA: "CLEAR_SCHEDULAR_DATA",
};
