import React from "react";
import styles from "./EmailTable.module.scss";
import { InfoToolTip } from "../../../../generic/infoToolTip/InfoToolTip";
import InputContainer from "../../../../generic/inputContainer/InputContainer";

interface EmailTableProps {
  isError?: boolean;
  extraHeaderStyling?: string;
  emailTableData?: any;
  emailerrorTable?: any;
}

export const EmailTable = ({
  isError,
  extraHeaderStyling,
  emailTableData,
  emailerrorTable,
}: EmailTableProps) => {
  // const emailData = [
  //   {
  //     type: "MX",
  //     host: "sdgjhsgdksdgskdfsuidvfsid",
  //     data: "smdhgjhfgjhfvjdhfvhj",
  //   },
  //   {
  //     type: "MX",
  //     host: "sdgjhsgdksdgskdfsuidvfsid",
  //     data: "smdhgjhfgjhfvjdhfvhj",
  //   },
  //   {
  //     type: "MX",
  //     host: "sdgjhsgdksdgskdfsuidvfsid",
  //     data: "smdhgjhfgjhfvjdhfvhj",
  //   },
  // ];

  return (
    <div
      className={`${styles.emailTableWrapper} ${
        isError ? styles.isRecordErrorStyling : ""
      }`}
    >
      <div className={styles.emailTableHeader}>
        <div
          className={`${styles.emailTableHeaderLabel} ${
            styles.emailTableHeaderLabelType
          } ${extraHeaderStyling ? extraHeaderStyling : ""} `}
        >
          <div className={styles.emailLabelTextStyling}>Type</div>
          <div className={styles.emailLabelInfoStyling}>
            <InfoToolTip
              message={
                "We use MX and TXT records to ensure that emails are delivered to your customers inbox and not spam folder."
              }
              isError={false}
              position="top-end"
            />
          </div>
        </div>
        <div
          className={`${styles.emailTableHeaderLabel} ${
            styles.emailTableHeaderLabelHost
          }  ${extraHeaderStyling ? extraHeaderStyling : ""}`}
        >
          <div className={styles.emailLabelTextStyling}>Host</div>
          <div className={styles.emailLabelInfoStyling}>
            <InfoToolTip
              message={
                "These are the pointers created in your DNS system towards Pravid."
              }
              isError={false}
              position="top-end"
            />
          </div>
        </div>
        <div
          className={`${styles.emailTableHeaderLabel} ${
            styles.emailTableHeaderLabelData
          }  ${extraHeaderStyling ? extraHeaderStyling : ""}`}
        >
          <div className={styles.emailLabelTextStyling}>Data</div>
          <div className={styles.emailLabelInfoStyling}>
            <InfoToolTip
              message={
                "This is the address of our system where your domain is pointing to for email delivery."
              }
              isError={false}
              position="top-end"
            />
          </div>
        </div>
      </div>

      {emailTableData?.map((data: any, index: number) => {
        return (
          <div className={`${styles.emailTableInfoDiv}`} key={index}>
            <div
              className={`${styles.emailTableHeader} ${styles.emailTableInfo}`}
            >
              <div
                className={`${styles.emailTableHeaderLabel} ${styles.emailTableHeaderInfoType}`}
              >
                <InputContainer
                  inputType="text"
                  inputValue={data?.type}
                  extraInputClass={`${styles.inputDivStyling}`}
                  extraLabelClass={styles.inputLabelStyling}
                  extraLabelDivClass={styles.labelDivStyling}
                  extraUserInputClass={`${styles.inputMainDivStyling}`}
                  showCopyIcon={false}
                  extraWrapperClass={styles.wrapperStyling}
                  noErrorMsg
                  readOnly={true}
                  showOverFlowToolTip={true}
                />
              </div>
              <div
                className={`${styles.emailTableHeaderLabel} ${styles.emailTableHeaderInfoHost}`}
              >
                <InputContainer
                  inputType="text"
                  inputValue={data?.host}
                  extraInputClass={`${styles.inputDivStyling}`}
                  extraLabelClass={styles.inputLabelStyling}
                  extraLabelDivClass={styles.labelDivStyling}
                  extraUserInputClass={`${styles.inputMainDivStyling}`}
                  showCopyIcon={true}
                  extraWrapperClass={styles.wrapperStyling}
                  noErrorMsg
                  readOnly={true}
                  showOverFlowToolTip={true}
                />
              </div>
              <div
                className={`${styles.emailTableHeaderLabel} ${styles.emailTableHeaderInfoData}`}
              >
                <InputContainer
                  inputType="text"
                  inputValue={data?.data}
                  extraInputClass={`${styles.inputDivStyling}`}
                  extraLabelClass={styles.inputLabelStyling}
                  extraLabelDivClass={styles.labelDivStyling}
                  extraUserInputClass={`${styles.inputMainDivStyling}`}
                  extraWrapperClass={styles.wrapperStyling}
                  showCopyIcon={true}
                  noErrorMsg
                  readOnly={true}
                  showOverFlowToolTip={true}
                />
              </div>
            </div>
            {isError && data?.valid === false ? (
              <div className={styles.errorDiv}>
                {`Record not found. We expected a ${data?.type} record for ${data?.host} to match ${data?.data} .`}
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
