import moment from "moment";
import calendarActionTypes from "../actionTypes/calendarFilter.actionTypes";

export interface state {
  startDate: Date;
  endDate: Date;
  formatedStartDate: string;
  formatedEndDate: string;
}
const intialState: state = {
  startDate: new Date(),
  endDate: new Date(),
  formatedStartDate: moment(new Date()).format("YYYY-MM-DD"),
  formatedEndDate: moment(new Date()).format("YYYY-MM-DD"),
};

export default function calenderFilterReducer(
  state: state = intialState,
  action: any
): state {
  switch (action.type) {
    case calendarActionTypes.SET_START_DATE: {
      return {
        ...state,
        startDate: action.payload,
        formatedStartDate: moment(action.payload).format("YYYY-MM-DD"),
      };
    }
    case calendarActionTypes.SET_END_DATE: {
      return {
        ...state,
        endDate: action.payload,
        formatedEndDate: moment(action.payload).format("YYYY-MM-DD"),
      };
    }
    case calendarActionTypes.SET_RANGE: {
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        formatedStartDate: moment(action.payload.startDate).format("YYYY-MM-DD"),
        formatedEndDate: moment(action.payload.endDate).format("YYYY-MM-DD"),
      };
    }
    default:
      return state;
  }
}
