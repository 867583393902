import actionTypes from "../reportAutomation.actionTypes";

export const getReportRulesAPIData = (data: string) => {
  return {
    type: actionTypes.REPORT_RULE_GET_DETAILS_API_CALL,
    payload: data,
  };
};

export const deleteReportRuleAPIData = (data: {
  ruleId: string;
  accountId: string;
}) => {
  return {
    type: actionTypes.REPORT_RULE_DELETE_DETAIL_API_CALL,
    payload: data,
  };
};

export function getSpecificReportRuleByIdAPIData(payload: any) {
  return {
    type: actionTypes.GET_RULE_DETAIL_API_CALL,
    payload: payload,
  };
}

export function updateReportRuleAPIData(payload: any) {
  return {
    type: actionTypes.UPDATE_RULE_DETAIL_API_CALL,
    payload: payload,
  };
}

export function createReportRuleAPIData(payload: any) {
  return {
    type: actionTypes.CREATE_RULE_DETAIL_API_CALL,
    payload: payload,
  };
}
export function resetCurrentReportAutomationData() {
  return {
    type: actionTypes.REPORT_AUTOMATION_RESET,
  };
}
