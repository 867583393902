import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./TabComponent.module.scss";
import {
  setSelectedTab,
  setBackendRefernceKey
} from "../../../../redux/agentDesktop/tabReducer/action";

// interface props {
//   default: any;
// }

export default function TabComponent(props: any) {
  const [showTabName, setShowTabName] = useState<string>();

  const channelSchema = useSelector(
    (state: any) => state.agentDesktopReducer?.baseScreenAD?.schema?.channel
  );

  const dispatch = useDispatch();

  const handleClick = (name: string, refenceKey:string) => {
    setShowTabName(name);
    dispatch(setSelectedTab(name));
    dispatch(setBackendRefernceKey(refenceKey));
  };

  const channelDetails =
  channelSchema && Object.values(channelSchema)?.length > 0
      ? channelSchema.sort((a: any, b: any) => {
          return a?.position - b?.position;
        })
      : [];

  useEffect(()=>{
    if(channelSchema && channelDetails){
      setShowTabName(channelDetails[0]?.displayName);
        dispatch(setSelectedTab(channelDetails[0]?.displayName));
        dispatch(setBackendRefernceKey(channelDetails[0]?.backendRefenceKey));
    }
  },[channelSchema,channelDetails])

  return (
    <>
        <div className={styles.headerTabdiv}>
          {channelDetails &&
            channelDetails.map((each: any, i: number) => {
              return (
                <>
                  <div
                    className={`${
                      each?.displayName == showTabName
                        ? styles.tabActiveName
                        : styles.tabInactiveName
                    }`}
                    onClick={() => handleClick(each?.displayName, each?.backendRefenceKey)}
                    key={i}
                  >
                    {each?.displayName}
                    <div
                    className={`${each?.displayName == showTabName ? styles.highligtLine : ""}`}
                  ></div>
                  </div>
                </>
              );
            })}
        </div>
        <div className={styles.horizontalLineDiv}></div>
    </>
  );
}
