import {
  config,
  CONVERSATION_URL,
  SERVER_URL2,
} from "../../../../services/ApiRoutes";
import { put, call, delay } from "redux-saga/effects";
import TRANSCRIPT_ACTION from "../actionTypes";
import axios from "axios";
import moment from "moment";

export function* setAllPhoneList(data: any, loading: boolean) {
  const preFilterData = data;
  yield put({
    type: TRANSCRIPT_ACTION.SET_ALL_PHONE_NUMBER,
    payload: { preFilterData, loading },
  });
}

let cancelTokenRD: any;
export function* apiCall(action: any): any {
  if (typeof cancelTokenRD != typeof undefined) {
    cancelTokenRD.cancel("Operation canceled due to new request.");
  }
  cancelTokenRD = axios.CancelToken.source();
  // console.log("saga running", data);
  // yield put({ type: TRANSCRIPT_ACTION.SET_PHONE_LOADING, payload: true });
  try {
    // let channel;
    // // console.log(data, "api");
    // if (data.data.channel.toString().toLowerCase() === "call") {
    //   channel = "Call";
    // } else if (data.data.channel.toString().toLowerCase() === "whatsapp") {
    //   channel = "Whatsapp";
    // }
    // let source;
    // if (data?.data?.source) {
    //   if (data.data.source.includes("Debt")) {
    //     source = "Debt";
    //   } else if (data.data.source.includes("Lead")) {
    //     source = "Lead";
    //   }
    // }

    // console.log("transcript saga before api");
    let dummyBodyData: any = {
      accountName: action.payload.data.user,
      //  accountName: "Saarthi Demo Testing",
      channel: [action.payload.data.channel],
      dategte: action.payload.data.startDate,
      datelte: action.payload.data.endDate,
      limit: action.payload.data.limit,
      page: action.payload.data.page,
      useCaseId: action.payload.data.useCaseId,
      // language: data.data?.language || [],
      // disposition: data.data?.disposition || [],
      // flowType: data.data?.flowType || [],
      phoneNo: action.payload.data.phoneNo,
      accountType: action.payload.data.accountType,
      role: action.payload.data.role,
      teamId : action.payload.data.teamId
    };

    if (dummyBodyData?.startDueDate) {
      dummyBodyData["startDueDate"] = `${moment(
        dummyBodyData.startDueDate
      ).format("YYYY-MM-DD")}T00:00:00`;
      dummyBodyData["endDueDate"] = `${moment(dummyBodyData?.endDueDate).format(
        "YYYY-MM-DD"
      )}T23:59:59`;
    }

    const selecetdFil = action.payload.data?.selectedFilters
      ? JSON.parse(JSON.stringify(action.payload.data?.selectedFilters))
      : {};
    delete selecetdFil["clientName"];
    dummyBodyData = { ...dummyBodyData, ...selecetdFil };
    if (action.payload.data?.phoneNo) {
      dummyBodyData.phoneNo = action.payload.data.phoneNo;
    }
    if (
      action.payload.data?.channel?.toString()?.toLowerCase() === "whatsapp" ||
      action.payload.data?.channel?.toString()?.toLowerCase() === "chat"
    ) {
      dummyBodyData.communicationType = [action.payload.data.communicationType];
    }
    const phoneSessionIdUrl = `${SERVER_URL2}${CONVERSATION_URL.FETCH_SESSION_ID}`;
    //  console.log("the url", phoneSessionIdUrl);
    const response = yield call(
      config.POST_WITH_CANCEL_TOKEN_HEADER,
      phoneSessionIdUrl,
      dummyBodyData,
      cancelTokenRD,
      { "x-access-token": action.payload.token }
    );
    // console.log(dummyBodyData, "body Data", { response }, "the Response");
    /**** Note ----- Here we have used nextPage as total page (temporary) to avoid time require for total page calculation */
    yield put({
      type: TRANSCRIPT_ACTION.SET_TOTAL_PAGE,
      payload: response.data.data?.next?.page || dummyBodyData?.page,
    });
    yield put({
      type: TRANSCRIPT_ACTION.SET_ALL_SESSION_PHONE_NUMBER,
      payload: response.data.data,
    });
    const phoneList = response.data.data.results;
    yield call(setAllPhoneList, phoneList, false);
    // console.log(response, "the response");
  } catch (error) {
    console.error(error);
    yield call(setAllPhoneList, [], false);
  }
  // yield put({ type: TRANSCRIPT_ACTION.SET_PHONE_LOADING, payload: false });
}
