import { takeLatest } from "redux-saga/effects";
import actionTypes from "../dataUpload.actionTypes";
import {
  getUploadedCSVDataAPIWorker,
  uploadCampaignCSVSagaWorker,
  APICSVCampaignDataSagaWorker,
  selectedCSVDataAPIWorker,
  deleteCSVDataAPIWorker,
  getCSVTobeEditedDataAPIWorker,
  updateCSVDataAPIWorker,
  salesforceCSVCampaignDataSagaWorker,
  uploadPaymentCSVSagaWorker,
  uploaddunningCSVSagaWorker,
  selectedAPIDataAPIWorker
} from "./dataUpload.saga";

export default function* campaignV2DetailsWatcher(): any {
  yield takeLatest(
    actionTypes.GET_SALESFORCE_CSV_DATA_REQUEST,
    salesforceCSVCampaignDataSagaWorker
  );

  yield takeLatest(
    actionTypes.GET_UPLOADED_INFO_API_REQUEST,
    getUploadedCSVDataAPIWorker
  );

  yield takeLatest(
    actionTypes.UPLOAD_CSV_FILE_API_REQUEST,
    uploadCampaignCSVSagaWorker
  );

  yield takeLatest(
    actionTypes.GET_API_CSV_DATA_REQUEST,
    APICSVCampaignDataSagaWorker
  );

  yield takeLatest(
    actionTypes.POST_SELECTED_CSV_DATA_REQUEST,
    selectedCSVDataAPIWorker
  );

  yield takeLatest(
    actionTypes.POST_SELECTED_API_DATA_REQUEST,
    selectedAPIDataAPIWorker
  );

  yield takeLatest(actionTypes.DELETE_CSV_API_REQUEST, deleteCSVDataAPIWorker);

  yield takeLatest(
    actionTypes.GET_EDIT_SCREEN_CSV_REQUEST,
    getCSVTobeEditedDataAPIWorker
  );

  yield takeLatest(
    actionTypes.POST_UPDATED_CSV_API_REQUEST,
    updateCSVDataAPIWorker
  );

  yield takeLatest(
    actionTypes.UPLOAD_PAYMENT_CSV_FILE_API_REQUEST,
    uploadPaymentCSVSagaWorker
  );
  yield takeLatest(
    actionTypes.UPLOAD_DUNNIG_CSV_FILE_API_REQUEST,
    uploaddunningCSVSagaWorker
  );
}
