import React, { ReactNode, useEffect, useState } from "react";

import styles from "./PageToggler.module.scss";
interface props {
  current: string;
  innerComponents: { name: string; element: ReactNode }[];
  switchTab: CallableFunction;
  extraHeaderStyling?: string;
  blueStyles?: boolean;
  tabStyling?: string;
  extraWrapperClass?: string;
  extraTextStyling?: string;
}
export default function PageToggler(props: props) {
  const [currentTab, setCurrentTab] = useState<ReactNode>(<></>);
  useEffect(() => {
    setCurrentTab(() => {
      const tab = props.innerComponents.filter((e) => {
        return e.name === props.current;
      });
      // console.log({ tab }, "nithin");
      return tab.length > 0 ? tab[0].element : <></>;
    });
    //  console.log(props.current, "nithin");
  }, [props.current, props.innerComponents]);
  //   useEffect(()=>{

  //   },[])
  return (
    <div
      className={`${props.extraWrapperClass ? props.extraWrapperClass : ""} ${
        styles.wrapper
      }`}
    >
      <div
        className={`${
          props.extraHeaderStyling ? props.extraHeaderStyling : ""
        } ${styles.header}
       `}
      >
        {props.innerComponents.map((each, index) => {
          return (
            <span
              key={index}
              data-selected={props.current === each.name}
              onClick={() => {
                props.switchTab(each.name);
              }}
              className={`${props.blueStyles ? styles.bluestyling : ""} ${
                styles.blackstyling
              } ${props?.extraTextStyling ? props?.extraTextStyling : ""}`}
              id="auto_current_tab_name"
            >
              {each.name}
            </span>
          );
        })}
      </div>
      <div
        className={`${props.tabStyling ? props.tabStyling : ""} ${styles.tab}`}
      >
        {currentTab}
      </div>
    </div>
  );
}
