import React, { useState, useRef, useEffect } from "react";
import styles from "./DemoDropdownComp.module.scss";
import useOnClickOutside from "../../../utils/useOnClickOutside";
import { ApiList } from "./apiList";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

export function DemoDropdownComp(props: any) {
  const [showoptionList, setShowoptionList] = useState<boolean>(false);
  const [statusCode, setStatusCode] = useState<any>();
  const [isStatusOk, setIsStatusOk] = useState<boolean>(false);

  const dropdownRef = useRef<any>();
  useOnClickOutside(dropdownRef, () => setShowoptionList(false));

  const token = useSelector(
    (store: any) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const handleDropdownClick = () => {
    setShowoptionList(!showoptionList);
  };

  // useEffect(() => {
  //   const statusMsg: { status: any; url: any; name: any }[] = [];
  //   ApiList &&
  //     ApiList.map((each: any) => {
  //       axios
  //         .get(each.api)
  //         .then((res) => {
  //           statusMsg.push({
  //             status: res.status,
  //             url: each.api,
  //             name: each.name,
  //           });
  //         })
  //         .catch((e) => {
  //           statusMsg.push({
  //             status: e?.response.status,
  //             url: each.api,
  //             name: each.name,
  //           });
  //         });
  //       setStatusCode(statusMsg);
  //     });
  // }, []);

  useEffect(() => {
    if (props?.data) {
      props?.data?.length > 0
        ? props?.data.every((item: any) => item?.message)
          ? setIsStatusOk(true)
          : setIsStatusOk(false)
        : null;
    }
  }, [props?.data]);

  return (
    <div
      className={styles.demoDropdownWrapper}
      onClick={handleDropdownClick}
      ref={dropdownRef}
    >
      <button
        className={props.extraStyle ? props.extraStyle : styles.dropdownBtn}
      >
        <p className={styles.dropdownHeadingDiv}>Tech Health</p>
        <div
          className={styles.dropdownStatusDiv}
          style={{
            backgroundColor: `${isStatusOk ? "#4DE108" : "#F5222D"}`,
          }}
        ></div>
      </button>
      {showoptionList && (
        <div
          // className={styles.dropdownOptionTopClass}
          className={`${props?.extraTopClass ? props?.extraTopClass : ""} ${
            styles.dropdownOptionTopClass
          }
          }`}
        >
          {props?.data &&
            props?.data?.length > 0 &&
            props?.data?.map((each: any, i: number) => {
              return (
                <>
                  <div className={styles.dropdownOptionListClass} key={i}>
                    <p className={styles.dropdownTextClass}>{each.name}</p>

                    <div
                      className={styles.dropdownStatusDiv}
                      style={{
                        backgroundColor: `${
                          each?.message ? "#4DE108" : "#F5222D"
                        }`,
                      }}
                    ></div>
                  </div>
                  <hr />
                </>
              );
            })}
        </div>
      )}
    </div>
  );
}
