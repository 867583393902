/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, useState } from "react";
import NewRadio from "../../../../components/generic/newRadio/NewRadio";
import styles from "./ReportAutomation.module.scss";
import { WeekSelector } from "../../../../components/generic/weekSelector/WeekSelector";
import { dropDownArrowIcon } from "../../../../theme/assets/profileSectionSvg";
import Dropdown from "react-multilevel-dropdown";
import ToolTip from "../../../../components/generic/toolTip/ToolTip";
import PravidIcons from "../../../../components/generic/pravidIcons/PravidIcons";
import TimePicker from "../../../../components/generic/timepicker/TimePicker";
import { TagsInput } from "../../../../components/generic/tagsInput/TagsInput";
import Button from "../../../../components/generic/button/Button";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux";
import {
  getReportRulesAPIData,
  deleteReportRuleAPIData,
  getSpecificReportRuleByIdAPIData,
  updateReportRuleAPIData,
  createReportRuleAPIData,
  resetCurrentReportAutomationData,
} from "../../../../redux/profile/settings/reportAutomation/actions";
import { validateEmailDomain } from "../../../../utils/emailValidation";
import { ReportRuleCards } from "../../../../components/moduleComponents/reportRuleCards/ReportRuleCards";
import { ReportRuleModal } from "../../../../components/moduleComponents/reportRuleModal/ReportRuleModal";
import _ from "lodash";
import { toast } from "react-toastify";
// import moment from "moment";

export type WeeklyType =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY";
export type DailyType = "DAILY";
export type MonthlyType = "MONTHLY";
export type ReportType = "Analytics" | "Customer Logs";
export enum frequency {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
}

export default function ReportAutomationComponent() {
  const [createModal, setCreateModal] = useState(false);
  const [updateRuleId, setUpdateRuleId] = useState("");
  // const [isRuleNameFocused, setIsRuleNameFocused] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [updateMode, setUpdateMode] = useState<boolean>(false);
  const tagsRef = useRef<any>(null);
  const [timeFromcard, settimeFromCard] = useState<any>({
    hasdata: true,
    am: true,
    hour: "09",
    minute: "00",
  });
  const [data, setData] = useState<{
    name: string;
    frequency: { type: frequency; value: WeeklyType | DailyType | MonthlyType };
    time: string | null;
    tags: string[];
    reportType: ReportType | undefined;
  }>({
    name: "",
    frequency: { type: frequency.Daily, value: "DAILY" },
    time: "09:00",
    tags: [],
    reportType: undefined,
  });
  const [errorTags, setErrorTags] = useState<{
    repeated: boolean;
    invalid: boolean;
  }>({ repeated: false, invalid: false });
  const [isRuleNameValid, setIsRuleNameValid] = useState<boolean>(true);
  const [isReportScheduleValid, setIsReportScheduleValid] =
    useState<boolean>(true);
  const [currentDeleteId, setCurrentDeleteId] = useState<string>("");
  const [validCreate, setValidCreate] = useState<boolean>(true);
  const [validCancel, setValidCancel] = useState<boolean>(true);
  const [timeObj, setTimeObj] = useState<any>();
  const [currentSelectedId, setCurrentSelectId] = useState<string>("");

  // console.log("data", data?.time);
  const dropdownRef: any = useRef();

  const email = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization.organizationDetails
        ?.organizationEmail
  );

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
  );

  const reportRulesInfo = useSelector(
    (store: RootState) =>
      store?.profile?.settingsReducer?.reportAutomation?.reportRuleDetails
  );

  const specificReportRuleInfo = useSelector(
    (store: RootState) =>
      store?.profile?.settingsReducer?.reportAutomation
        ?.specificReportRuleDetail
  );

  const isLoading = useSelector(
    (store: RootState) => store?.profile?.settingsReducer?.reportAutomation
  );

  const defaultState: {
    name: string;
    frequency: { type: frequency; value: WeeklyType | DailyType | MonthlyType };
    time: string | null;
    tags: string[];
    reportType: ReportType | undefined;
  } = {
    name: "",
    frequency: { type: frequency.Daily, value: "DAILY" },
    time: "09:00",
    tags: [],
    reportType: undefined,
  };

  const defaultGetRuleState = {
    name: specificReportRuleInfo?.ruleName,
    frequency: {
      type: specificReportRuleInfo?.frequency,
      value:
        specificReportRuleInfo?.frequency === "Daily"
          ? "DAILY"
          : specificReportRuleInfo?.frequency === "Monthly"
          ? "MONTHLY"
          : specificReportRuleInfo?.dayOfWeek,
    },
    time: specificReportRuleInfo?.time,
    tags: specificReportRuleInfo?.shareTo,
    reportType: specificReportRuleInfo?.reportType,
  };

  const dispatch = useDispatch();

  //change the frequency
  function changeFrequency(
    type: frequency,
    week?: WeeklyType | DailyType | MonthlyType
  ) {
    setData((prev) => {
      const frequency: {
        type: frequency;
        value: WeeklyType | DailyType | MonthlyType;
      } = { type, value: week || prev.frequency.value };
      return { ...prev, frequency };
    });
  }

  const setTimeData = (value: any) => {
    setData((prev) => {
      return { ...prev, time: value };
    });
  };

  const handleDeleteReport = (ruleId: string, accountId: string) => {
    dispatch(deleteReportRuleAPIData({ ruleId: ruleId, accountId: accountId }));
    setCurrentSelectId("");
  };

  const handleGetRuleDetails = (ruleId: string) => {
    dispatch(getSpecificReportRuleByIdAPIData(ruleId));
    setCurrentSelectId(ruleId);
  };

  const handleUpdateRuleDetails = (ruleId: string) => {
    const data_to_send: any = {
      ruleId: ruleId,
      accountId: accountId,
      ruleName: data.name,
      frequency: data.frequency.type,
      // dayOfWeek: data.frequency.value,
      time: data.time,
      reportType: data.reportType,
      shareTo: data.tags,
    };
    if (data.frequency.type === frequency.Weekly) {
      data_to_send.dayOfWeek = data.frequency.value;
    }

    dispatch(
      updateReportRuleAPIData({
        ...data_to_send,
      })
    );
    setCurrentSelectId("");
  };

  const handleOverwritingRule = (ruleId: string) => {
    const exisitingRule = reportRulesInfo?.find(
      (obj: any) => obj.id === updateRuleId
    );
    if (_.union(exisitingRule.shareTo, data.tags).length > 10) {
      toast.error("You can share the report with a maximum of 10 email IDs");
    } else {
      if (!updateMode) {
        const data_to_send: any = {
          ruleId: ruleId,
          accountId: accountId,
          ruleName: data.name,
          frequency: data.frequency.type,
          time: data.time,
          reportType: data.reportType,
          shareTo: _.union(exisitingRule.shareTo, data.tags),
        };
        if (data.frequency.type === frequency.Weekly) {
          data_to_send.dayOfWeek = data.frequency.value;
        }
        dispatch(
          updateReportRuleAPIData({
            ...data_to_send,
          })
        );
        setCurrentSelectId("");
      } else {
        const data_to_send: any = {
          updateMode: true,
          currentId: currentSelectedId,
          ruleId: ruleId,
          accountId: accountId,
          ruleName: data.name,
          frequency: data.frequency.type,
          time: data.time,
          reportType: data.reportType,
          shareTo: _.union(exisitingRule.shareTo, data.tags),
        };
        if (data.frequency.type === frequency.Weekly) {
          data_to_send.dayOfWeek = data.frequency.value;
        }
        dispatch(
          updateReportRuleAPIData({
            ...data_to_send,
          })
        );
        setCurrentSelectId("");
      }
    }
  };

  const handleCreateRuleDetails = () => {
    const data_to_send: any = {
      ruleName: data.name,
      frequency: data.frequency.type,
      //dayOfWeek: data.frequency.value,
      time: data.time,
      reportType: data.reportType,
      shareTo: data.tags,
      accountId: accountId,
    };
    if (data.frequency.type === frequency.Weekly) {
      data_to_send.dayOfWeek = data.frequency.value;
    }
    dispatch(
      createReportRuleAPIData({
        ...data_to_send,
      })
    );
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => {
      return { ...prev, name: event?.target?.value };
    });
  };

  // rule name validation
  const ruleNameValidate = (name: string) => {
    if (!updateMode) {
      for (const each of reportRulesInfo) {
        if (each?.ruleName.toLowerCase() === name.toLowerCase()) {
          setIsRuleNameValid(false);
          return;
        }
      }
      setIsRuleNameValid(true);
    } else {
      const newArray = [...reportRulesInfo];
      removeObjectWithId(newArray, currentSelectedId);
      for (const each of newArray) {
        if (each?.ruleName.toLowerCase() === name.toLowerCase()) {
          setIsRuleNameValid(false);
          return;
        }
      }
      setIsRuleNameValid(true);
    }
  };

  function removeObjectWithId(arr: any, id: string) {
    const objWithIdIndex = arr.findIndex((obj: any) => obj.id === id);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  }

  const reportScheduleValidation = (
    frequency: { type: string; value: string },
    time: string | null,
    reportType: string | undefined
  ) => {
    if (updateMode) {
      const newArray = [...reportRulesInfo];
      removeObjectWithId(newArray, currentSelectedId);
      for (const each of newArray) {
        if (
          each?.frequency === frequency.type &&
          (each?.frequency === "Weekly"
            ? each?.dayOfWeek === frequency.value
            : true) &&
          each?.time === time &&
          each?.reportType === reportType
        ) {
          setIsReportScheduleValid(false);
          setUpdateRuleId(each.id);
          return;
        }
      }
      setIsReportScheduleValid(true);
    } else {
      for (const each of reportRulesInfo) {
        if (
          each?.frequency === frequency.type &&
          (each?.frequency === "Weekly"
            ? each?.dayOfWeek === frequency.value
            : true) &&
          each?.time === time &&
          each?.reportType === reportType
        ) {
          setIsReportScheduleValid(false);
          setUpdateRuleId(each.id);
          return;
        }
      }
      setIsReportScheduleValid(true);
    }
  };

  // on handle cancel
  // clear the data -> set to default state
  // clear all the errors to default(no error) state
  // clear the update/create state -> create state
  const handleCancel = () => {
    setCurrentSelectId("");
    setUpdateMode(false);
    setData({
      ...defaultState,
    });
    tagsRef?.current?.clearInput();
    settimeFromCard({ hasdata: true, am: true, hour: "09", minute: "00" });
  };

  function validateCreate(): any {
    if (!isRuleNameValid) {
      return false;
    }
    if (data.name === "") {
      return false;
    }
    if (!data.reportType) {
      return false;
    }
    if (errorTags.invalid || errorTags.repeated) {
      return false;
    }
    if (data.tags.length === 0) {
      return false;
    }
    if (_.isEqual(data, defaultState) && !updateMode) {
      return false;
    }
    if (_.isEqual(data, defaultGetRuleState) && updateMode) {
      return false;
    }
    if (timeObj?.hour === "00") {
      return false;
    }
    if (isLoading.isLoadingUpdateReportRuleDetail) {
      return false;
    }
    if (isLoading.isLoadingCreateReportRuleDetails) {
      return false;
    }
    // if (isLoading.isLoadingReportRuleDetails) {
    //   return false;
    // }
    // if (isLoading.isLoadingSpecificReportRuleDetail) {
    //   return false;
    // }
    return true;
  }

  function validateCancel(): any {
    // if (data.tags.length === 0) {
    //   return true;
    // }
    if (_.isEqual(data, defaultState)) {
      return true;
    }
    if (isLoading.isLoadingUpdateReportRuleDetail) {
      return true;
    }
    if (isLoading.isLoadingCreateReportRuleDetails) {
      return true;
    }
    return false;
  }

  const handlePopUp = () => {
    if (!isReportScheduleValid) {
      handleOverwritingRule(updateRuleId);
    } else if (isReportScheduleValid && updateMode) {
      handleUpdateRuleDetails(specificReportRuleInfo?.id);
    } else if (isReportScheduleValid && !updateMode) {
      handleCreateRuleDetails();
    }
    setCreateModal(false);
  };

  useEffect(() => {
    let invalid = false;
    let repeated = false;
    // check fro valid email
    for (const i of data.tags) {
      if (!validateEmailDomain(i, email)) {
        invalid = true;
        break;
      }
    }
    // check for repeated email
    for (const i of data.tags) {
      if (data.tags.filter((e) => e === i).length > 1) {
        repeated = true;
        break;
      }
    }
    setErrorTags({ invalid, repeated });
  }, [data.tags]);
  //*******************************************/

  useEffect(() => {
    //when the component unmounts clear the current data
    return () => {
      dispatch(resetCurrentReportAutomationData());
    };
  }, []);

  // useEffect(() => {
  //   console.log({ data }, "Nithin date");
  // }, [data]);
  // setDisabled

  useEffect(() => {
    if (errorTags.invalid || errorTags.repeated) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [errorTags]);

  useEffect(() => {
    if (accountId && reportRulesInfo?.length === 0) {
      dispatch(getReportRulesAPIData(accountId));
    }
  }, [accountId]);

  useEffect(() => {
    handleCancel();
  }, [reportRulesInfo]);

  useEffect(() => {
    if (
      specificReportRuleInfo &&
      Object.keys(specificReportRuleInfo).length !== 0
    ) {
      setData({
        name: specificReportRuleInfo?.ruleName,
        frequency: {
          type: specificReportRuleInfo?.frequency,
          value:
            specificReportRuleInfo?.frequency === "Daily"
              ? "DAILY"
              : specificReportRuleInfo?.frequency === "Monthly"
              ? "MONTHLY"
              : specificReportRuleInfo?.dayOfWeek,
        },
        reportType: specificReportRuleInfo?.reportType,
        tags: specificReportRuleInfo?.shareTo,
        time: specificReportRuleInfo?.time,
      });
      setUpdateMode(true);
    } else {
      setUpdateMode(false);
    }
  }, [specificReportRuleInfo]);

  useEffect(() => {
    ruleNameValidate(data.name);
  }, [data.name]);

  useEffect(() => {
    reportScheduleValidation(data.frequency, data.time, data.reportType);
  }, [data.frequency, data.reportType, data.time]);

  // console.log("rel", isReportScheduleValid);

  useEffect(() => {
    setValidCreate(validateCreate());
  }, [
    isRuleNameValid,
    errorTags,
    data,
    defaultGetRuleState,
    defaultState,
    updateMode,
    timeObj,
    isLoading,
  ]);

  useEffect(() => {
    setValidCancel(validateCancel());
  }, [errorTags, data, defaultState, isLoading]);

  // console.log(validCancel, "gg", data.tags.length);

  useEffect(() => {
    if (specificReportRuleInfo?.time) {
      const time = specificReportRuleInfo?.time?.split(":");
      const obj = {
        hasdata: true,
        am: time?.[0] < 12 ? true : false,
        hour:
          time?.[0] > 12
            ? Number(time?.[0]) - 12
            : time?.[0] === "00"
            ? 12
            : time?.[0],
        minute: time?.[1],
      };
      settimeFromCard(obj);
    }
  }, [specificReportRuleInfo?.time]);

  return (
    <div className={styles.reportWrapper}>
      <div className={styles.reportAutomationWrapper}>
        <h4 className={styles.reportAutomationHeading}>Automation Rule</h4>
        <div className={styles.reportAutomationContent}>
          <div className={styles.label}>
            <p>Rule Name:</p>
          </div>
          <div className={`${styles.input}`}>
            <input
              data-testid="rule_name"
              className={`${styles.inputField} ${
                !isRuleNameValid ? styles.notValid : null
              }`}
              placeholder="Enter Rule Name"
              onChange={handleNameChange}
              value={data.name}
              maxLength={40}
              readOnly={isReadOnly}
              onFocus={() => {
                // setIsRuleNameFocused(true);
                // ruleNameValidate(event);
                setIsReadOnly(false);
              }}
              onBlur={() => {
                // setIsRuleNameFocused(false);
                setIsReadOnly(true);
              }}
            />
            {data.name && data.name.length > 0 && !isRuleNameValid ? (
              <p className={styles.errorMessage}>
                A rule with the name you entered already exists
              </p>
            ) : null}
          </div>
        </div>
        <div
          className={styles.reportAutomationFrequencyContent}
          data-testid="frequency-div"
        >
          <div className={` ${styles.frequencyLabel}`}>
            <span className={styles.frequencyLabelInfo}>
              <span className={styles.frequencyHeading}>Frequency:</span>
              <div
                id="auto_frequency_tooltip"
                className={styles.frequencyToolTip}
              >
                <ToolTip about="Select if you want the report to be auto shared Daily, Once a week, or Once a month" />
              </div>
            </span>
          </div>
          <div className={styles.frequencyfield}>
            <span className={styles.frequencyWrapper}>
              <NewRadio
                testId="daily_button"
                isSelected={data.frequency.type === frequency.Daily}
                name={"Daily"}
                onClick={() => {
                  changeFrequency(frequency.Daily, "DAILY");
                }}
              />
            </span>
            <span className={styles.frequencyWrapper}>
              <NewRadio
                testId="weekly_button"
                isSelected={data.frequency.type === frequency.Weekly}
                name={"Weekly"}
                onClick={() => {
                  changeFrequency(frequency.Weekly, "MONDAY");
                }}
              />
            </span>
            <span className={styles.frequencyWrapper}>
              <NewRadio
                testId="monthly_button"
                isSelected={data.frequency.type === frequency.Monthly}
                name={"Monthly"}
                onClick={() => {
                  changeFrequency(frequency.Monthly, "MONTHLY");
                }}
              />
            </span>
            {data.frequency.type === frequency.Weekly && (
              <div className={styles.weekWrapper}>
                <WeekSelector
                  currentlySelected={data.frequency.value}
                  onChange={(e: WeeklyType) => {
                    setData((prev) => {
                      return {
                        ...prev,
                        frequency: { type: frequency.Weekly, value: e },
                      };
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.reportAutomationContent}>
          <div className={`${styles.label} ${styles.timeLabel}`}>
            <span className={styles.timeHeading}>Time:</span>
            <div id="auto_time_tooltip">
              <ToolTip
                about={
                  data.frequency.type === frequency.Daily
                    ? "For daily reports, if you enter the time as < 8 PM then the previous day's report will be shared at that time. The data collected for the previous day will be between 12 AM till midnight."
                    : data.frequency.type === frequency.Weekly
                    ? "For weekly reports, If Tuesday at 6 PM is selected then you will receive the report every Tuesday at 6 PM but the data will be from Last Tuesday till This Monday."
                    : "A monthly report will include the data from 1st day of last month at 12 AM to the last day of last month and the report will be shared with you on 1 st of every new month at the time you enter."
                }
              />
            </div>
          </div>
          <div className={styles.timeLabel} data-testid="time-label">
            <TimePicker
              twentyFourHour={false}
              timePickerComponent={styles.timePickerComponent}
              timePickerInnerComponent={styles.timePickerInnerComponent}
              inputStyling={styles.inputTimeStyling}
              spanStyling={styles.spanStyling}
              timePickerIcon={styles.timePickerIcon}
              reportData={timeFromcard}
              sendReportTime={(value: any) => {
                setTimeData(value);
              }}
              sendObjTime={(value: any) => {
                setTimeObj(value);
              }}
            />
            <div className={styles.timeIcon}>
              <PravidIcons activeIcon={"timezone"} />
            </div>
            <div className={styles.timeInfo}>UTC +5:30 IST</div>
          </div>
        </div>
        <div className={styles.reportAutomationContent}>
          <div className={styles.label}>
            <p>Report Type:</p>
          </div>
          <div className={`${styles.input}`} data-testid="dropdown">
            <Dropdown
              ref={dropdownRef}
              title={
                <div
                  className={styles.dropdownTitle}
                  data-testid={"report_type"}
                >
                  <span
                    className={`${styles.dropdownButtonInfo} ${
                      data.reportType
                        ? styles.dropdownButtonSelected
                        : styles.dropdownButtonDefault
                    }`}
                  >
                    {data.reportType ? data.reportType : "Select type"}
                  </span>
                  <img src={dropDownArrowIcon}></img>
                </div>
              }
              buttonClassName={styles.dropdownButton}
              menuClassName={styles.menuClass}
            >
              {/* <Dropdown.Item
                className={`${styles.itemx} ${styles.disabled}`}
                data-testid="analytics"
                onClick={() => {
                  setData((prev) => {
                    return { ...prev, reportType: "Analytics" };
                  });
                  if (dropdownRef.current) {
                    dropdownRef.current?.toggle();
                  }
                }}
                // isDisabled={true}
              >
                Analytics
              </Dropdown.Item>*/}
              <Dropdown.Item
                className={styles.itemx}
                data-testid="customer-logs"
                onClick={() => {
                  setData((prev) => {
                    return { ...prev, reportType: "Customer Logs" };
                  });
                  if (dropdownRef.current) {
                    dropdownRef.current?.toggle();
                  }
                }}
              >
                Customer Logs
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
        <div className={styles.reportAutomationFrequencyContent}>
          <div className={` ${styles.shareToLabel}`}>
            <span className={styles.frequencyLabelInfo}>
              <span className={styles.frequencyHeading}>Share to:</span>
            </span>
          </div>
          <div className={styles.frequencyfield} data-testid="email-tags">
            <TagsInput
              ref={tagsRef}
              validEmailDomain={email}
              selectedTags={(e: string[]) => {
                setData((prev) => {
                  return { ...prev, tags: e };
                });
              }}
              tags={data.tags}
              // placeholder={"Enter emails"}
            />
            {errorTags.repeated ? (
              <p className={styles.errorMessage}>Email entered is repeated</p>
            ) : null}
            {errorTags.invalid ? (
              <p className={styles.errorMessage}>
                Report can be shared only to Email IDs from your company
              </p>
            ) : null}
          </div>
        </div>
        <div className={styles.reportComponentButtons}>
          <Button
            dataTest={"cancel_button"}
            text="Cancel"
            extraClass={`${styles.buttonStyling} ${styles.cancelButtonStyle}`}
            onClick={_.isEqual(data, defaultState) ? null : handleCancel}
            disabled={validCancel}
          />

          <Button
            dataTest="update_button"
            text={updateMode ? "Update" : "Create"}
            extraClass={`${styles.buttonStyling} ${
              !validCreate
                ? styles.createButtonInActiveStyle
                : styles.createButtonActiveStyle
            }`}
            onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation();
              setCreateModal(true);
            }}
            disabled={!validCreate}
            // disabled={!validCreate && isLoadingData ? true : false}
          />
          <ReportRuleModal
            show={createModal}
            close={() => {
              setCreateModal(false);
            }}
            email={email}
            selectedTags={(e: string[]) => {
              setData((prev) => {
                return { ...prev, tags: e };
              });
            }}
            errorTags={errorTags}
            tags={data.tags}
            disabled={!validCreate}
            onclick={() => {
              handlePopUp();
            }}
            emailPopUp={!updateMode && isReportScheduleValid ? true : false}
            updatePopUp={isReportScheduleValid && updateMode ? true : false}
          />
        </div>
      </div>
      <ReportRuleCards
        openModel={(id: string) => {
          setCurrentDeleteId(id);
        }}
        currentSelectedId={currentSelectedId}
        onSelect={handleGetRuleDetails}
        onDelete={handleDeleteReport}
        reportRulesData={reportRulesInfo}
        accountId={accountId}
      />
    </div>
  );
}
