import { takeLatest } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import {
    updateTourModuleWorker,
    getCallStatusWorker,
    getFlowApiWorker,
    getLanguageApiWorker
 
} from "./productTour.saga";

export default function* productPageDataWatcher(): any {
  yield takeLatest(
    actionTypes.UPDATE_TOUR_SHOW_REQUEST,
    updateTourModuleWorker
  );
  yield takeLatest(
    actionTypes.GET_CALL_STATUS_REQUEST,
    getCallStatusWorker
  );
  yield takeLatest(
    actionTypes.GET_FLOW_EXPERIENCE_REQUEST,
    getFlowApiWorker
  );
  yield takeLatest(
    actionTypes.GET_LANGUAGE_EXPERIENCE_REQUEST,
    getLanguageApiWorker
  );
}
