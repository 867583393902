import actionTypes from "../actionTypes/campaignTestingInfo.actionTypes";

const campaignTestinInfoReducer = (
  state: any = {
    customerDataArr: [],
    disableBtn: true,
    initiallySettingUp: true,
    setupError: "",
    schemaDataFiltered: {},
    selectedAgent: "",
  },
  action: { payload: any; type: string }
) => {
  switch (action.type) {
    case actionTypes.ADD_CUSTOMER: {
      const newDataArr = [
        ...state.customerDataArr,
        action.payload.customerData,
      ];
      return { ...state, customerDataArr: [...newDataArr] };
    }
    case actionTypes.DELETE_CUSTOMER: {
      const newCustomerDataArr = [...state.customerDataArr];
      newCustomerDataArr.splice(action.payload.index, 1);
      return { ...state, customerDataArr: newCustomerDataArr };
    }
    case actionTypes.ADD_FETCHED_CUSTOMERS: {
      return { ...state, customerDataArr: action.payload.data };
    }
    case actionTypes.UPDATE_CUSTOMER_DATA: {
      const { value, key, index } = action.payload;
      // const newData = [...state.customerDataArr];
      const newData = JSON.parse(JSON.stringify(state.customerDataArr));
      newData[index][key] = value;
      return { ...state, customerDataArr: [...newData] };
    }
    case actionTypes.CAMPAIGN_TESTING_UPDATE_CUSTOMER_DATA_OBJECT: {
      const newCustomerDataArr = [...state.customerDataArr];
      newCustomerDataArr[action.payload.index] = action.payload.dataObject;
      return { ...state, customerDataArr: newCustomerDataArr };
    }
    case actionTypes.SET_DISABLE_BUTTON: {
      return { ...state, disableBtn: action.payload.data };
    }
    case actionTypes.CAMPAIGN_TESTING_GET_SCHEMA_API_REQUEST: {
      return { ...state, initiallySettingUp: true };
    }
    case actionTypes.CAMPAIGN_TESTING_GET_SCHEMA_API_SUCCESS: {
      return {
        ...state,
        schemaDataFiltered: { ...action.payload },
        initiallySettingUp: false,
        setupError: "",
      };
    }
    case actionTypes.CAMPAIGN_TESTING_GET_SCHEMA_API_FAILURE: {
      return {
        ...state,
        initiallySettingUp: false,
        setupError: action.payload,
        schemaDataFiltered: {},
      };
    }

    case actionTypes.SET_SELECTED_AGENT_CAMPAIGN_TESTING: {
      return { ...state, selectedAgent: action.payload.selectedAgent };
    }

    case actionTypes.UPDATE_DATA_BY_AGENT_CAMPAIGN_TESTING: {
      const newSchemaFilteredData = {
        ...state.schemaDataFiltered,
        schema: action.payload.schema,
        defaultInputFieldData: action.payload.defaultInputFieldData,
        dropDownCallMapping: {
          ...state.schemaDataFiltered.dropDownCallMapping,
          ...action.payload.extraDropDownCallMapping,
        },
        dropDownWhatsAppMapping: {
          ...state.schemaDataFiltered.dropDownWhatsAppMapping,
          ...action.payload.extraDropDownWhatsAppMapping,
        },
        agentName: action.payload.agentName,
        showFieldsByFlow:
          action.payload.showFieldsByFlow ||
          state.schemaDataFiltered.showFieldsByFlow,
        showCategoriesByFlow:
          action.payload.showCategoriesByFlow ||
          state.schemaDataFiltered.showCategoriesByFlow,
        showFieldsByDisposition: action.payload.showFieldsByDisposition,
        showCategoriesByDisposition: action.payload.showCategoriesByDisposition,
        showLanguageByFlow: action.payload.showLanguageByFlow,
      };

      return {
        ...state,
        schemaDataFiltered: newSchemaFilteredData,
        customerDataArr: action.payload.customerData,
      };
    }
    default: {
      return state;
    }
  }
};

export default campaignTestinInfoReducer;
