// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x_jfHV14ODGhgJ8yXmHe{width:100%;background:#fff;border-bottom:.75px solid #e8e8e8;display:flex;justify-content:space-around;padding:.4% 0;overflow-y:scroll}.x_jfHV14ODGhgJ8yXmHe .NqaYBEthwKnLq5ge2JFW{font-weight:400;font-size:14px;color:#333;cursor:pointer;min-width:10%}.x_jfHV14ODGhgJ8yXmHe .NqaYBEthwKnLq5ge2JFW p{text-align:center}.x_jfHV14ODGhgJ8yXmHe .SvZ8VDdvr7qfOV7fkCX8{font-weight:400;font-size:14px;color:#0174ff;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/strategyComponents/nudgeCreation/headerFlows/HeaderFlows.module.scss"],"names":[],"mappings":"AAAA,sBACA,UAAA,CACA,eAAA,CACA,iCAAA,CACA,YAAA,CACA,4BAAA,CACA,aAAA,CACA,iBAAA,CAEA,4CACI,eAAA,CACA,cAAA,CACA,UAAA,CACA,cAAA,CACA,aAAA,CAGA,8CACI,iBAAA,CAGR,4CACA,eAAA,CACA,cAAA,CACA,aAAA,CACA,cAAA","sourcesContent":[".headerFlowWrapper{\nwidth: 100%;\nbackground: #FFFFFF;\nborder-bottom: 0.75px solid #E8E8E8;\ndisplay: flex;\njustify-content: space-around;\npadding: 0.4% 0;\noverflow-y: scroll;\n\n.flowsName{\n    font-weight: 400;\n    font-size: 14px;\n    color: #333333;\n    cursor: pointer;\n    min-width: 10%;\n    // min-width: 105px;\n    // max-width: 165px;\n    p{\n        text-align: center;\n    }\n}\n.selectedflowsName{\nfont-weight: 400;\nfont-size: 14px;\ncolor: #0174FF;\ncursor: pointer;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerFlowWrapper": `x_jfHV14ODGhgJ8yXmHe`,
	"flowsName": `NqaYBEthwKnLq5ge2JFW`,
	"selectedflowsName": `SvZ8VDdvr7qfOV7fkCX8`
};
export default ___CSS_LOADER_EXPORT___;
