// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zmbtW0ZZHPtMvaIt1hR7{width:500px;height:420px;background:#fff;border:1.24155px solid #e6e8ec;border-radius:12.4155px;margin:3% 5% 3% 0}.zmbtW0ZZHPtMvaIt1hR7 .t3xpNjsywPotSlM1vyS0{padding:20px;font-family:"Roboto";font-style:normal;font-weight:400;font-size:19.023px;line-height:22px;color:#676767}`, "",{"version":3,"sources":["webpack://./src/components/agentDesktop/moduleComponents/chartView/ChartView.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,YAAA,CACA,eAAA,CACA,8BAAA,CACA,uBAAA,CACA,iBAAA,CAEA,4CACI,YAAA,CACA,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,gBAAA,CACA,aAAA","sourcesContent":[".chartViewComponentWrapper {\n    width: 500px;\n    height: 420px;\n    background: #FFFFFF;\n    border: 1.24155px solid #E6E8EC;\n    border-radius: 12.4155px;\n    margin: 3% 5% 3% 0;\n\n    .chartViewHeader {\n        padding: 20px;\n        font-family: 'Roboto';\n        font-style: normal;\n        font-weight: 400;\n        font-size: 19.023px;\n        line-height: 22px;\n        color: #676767;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartViewComponentWrapper": `zmbtW0ZZHPtMvaIt1hR7`,
	"chartViewHeader": `t3xpNjsywPotSlM1vyS0`
};
export default ___CSS_LOADER_EXPORT___;
