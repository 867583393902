// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Vs3Pr3noQC_IBV_AFQqm{border:.5px solid;border-radius:2px;width:170.05px;height:35px;display:flex;justify-content:space-around;align-items:center;padding-left:3px}.Vs3Pr3noQC_IBV_AFQqm .R24P9vGVoZVYD2W_hlrZ{width:11.93px;height:11.93px;border-radius:50%}.Vs3Pr3noQC_IBV_AFQqm .aUK5T4yAbxykuLSaaxwS{font-family:Roboto;font-size:14px;font-weight:400;line-height:16px;letter-spacing:0em;min-width:97px;text-align:center}.qZc6jAW_CkNjeIwnP6xt{border:none !important;width:170 !important}.fHPGilYXboxpTmFpX8qo{border:none !important;border-bottom:.5px #24bee5 solid !important}`, "",{"version":3,"sources":["webpack://./src/components/agentDesktop/moduleComponents/statusDropdown/StatusDropdown.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,iBAAA,CACA,cAAA,CACA,WAAA,CACA,YAAA,CACA,4BAAA,CACA,kBAAA,CACA,gBAAA,CACA,4CACE,aAAA,CACA,cAAA,CACA,iBAAA,CAEF,4CACE,kBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA,CAGJ,sBACE,sBAAA,CACA,oBAAA,CAEF,sBACE,sBAAA,CACA,2CAAA","sourcesContent":[".dropdown {\n  border: 0.5px solid;\n  border-radius: 2px;\n  width: 170.05px;\n  height: 35px;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  padding-left: 3px;\n  .dot {\n    width: 11.93px;\n    height: 11.93px;\n    border-radius: 50%;\n  }\n  .title {\n    font-family: Roboto;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 16px;\n    letter-spacing: 0em;\n    min-width: 97px;\n    text-align: center;\n  }\n}\n.extraWrapper {\n  border: none !important;\n  width: 170 !important;\n}\n.extraDropdown {\n  border: none !important;\n  border-bottom: 0.5px #24bee5 solid !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `Vs3Pr3noQC_IBV_AFQqm`,
	"dot": `R24P9vGVoZVYD2W_hlrZ`,
	"title": `aUK5T4yAbxykuLSaaxwS`,
	"extraWrapper": `qZc6jAW_CkNjeIwnP6xt`,
	"extraDropdown": `fHPGilYXboxpTmFpX8qo`
};
export default ___CSS_LOADER_EXPORT___;
