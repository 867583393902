import React, { useState, useEffect } from "react";
import styles from "./NewSMSScreen.module.scss";
import InputContainer from "../../../../generic/inputContainer/InputContainer";
import Button from "../../../../generic/button/Button";
import Icon from "../../../../generic/icon/Icon";
import { uploadSMSDataIcon } from "../../../../../theme/assets/svg/integration_V2.0/index";
import { downloadIcon } from "../../../../../theme/assets/svg/integration_V2.0/index";
import { UploadFile } from "../../../../generic/uploadFile/UploadFile";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux";

import {
  setNewAccount,
  updateNewAccount,
} from "../../../../../redux/integration_V2.0/sms/actions";
import { useDispatch } from "react-redux";
import { verify } from "../../../../../theme/gif/index";
import { saveAs } from "file-saver";
import _ from "lodash";
interface NewSMSScreenProps {
  onClickClose?: any;
}

export const downloadSMSPdfFile = (filePath: string, fileName: string) => {
  saveAs(filePath, fileName);
};

export const NewSMSScreen = ({ onClickClose }: NewSMSScreenProps) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [newSMSData, setNewSMSData] = useState({
    senderId: "",
  });
  const [smsIntegrationFileDetail, setSmsIntegrationFileDetail] = useState<any>(
    {}
  );
  const [isInputUpdated, setIsInputUpdated] = useState(false);

  const dispatch = useDispatch();
  /** ***** Redux Data ******  */
  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );
  const selectedIntegrationSMS = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.selectedIntegrationData
  );
  const setNewAccountApiData = useSelector(
    (store: RootState) => store?.integrationReducer?.smsReducer?.newAccountInfo
  );

  const isUpdateLoading = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.smsReducer?.updateAccountInfo?.isLoading
  );

  const updatedData = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.smsReducer?.updateAccountInfo?.data
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleUploadFileDetails(e.target.value, "senderId");
    if (e.target.name === "senderId") {
      const value = e.target.value;
      setNewSMSData({
        ...newSMSData,
        [e.target.name]: value.replace(/[^A-Za-z0-9]/g, ""),
      });
      setIsInputUpdated(true);
    }
  };

  useEffect(() => {
    if (
      selectedIntegrationSMS?.integrationDetails &&
      Object.keys(selectedIntegrationSMS?.integrationDetails).length > 2
    ) {
      if (isInputUpdated) {
        if (
          newSMSData?.senderId?.length === 0 ||
          (newSMSData?.senderId?.length > 0 &&
            newSMSData?.senderId?.length < 6) ||
          Object.keys(smsIntegrationFileDetail)?.length < 7
        ) {
          setIsInvalid(true);
        } else {
          setIsInvalid(false);
        }
      } else {
        setIsInvalid(true);
      }
    } else {
      if (
        newSMSData?.senderId?.length === 0 ||
        (newSMSData?.senderId?.length > 0 &&
          newSMSData?.senderId?.length < 6) ||
        Object.keys(smsIntegrationFileDetail)?.length < 7
      ) {
        setIsInvalid(true);
      } else {
        setIsInvalid(false);
      }
    }
  }, [
    newSMSData,
    smsIntegrationFileDetail,
    selectedIntegrationSMS,
    isInputUpdated,
  ]);

  useEffect(() => {
    const data: any = {};

    for (const key in selectedIntegrationSMS?.integrationDetails) {
      if (key !== "senderId") {
        data[key] = selectedIntegrationSMS?.integrationDetails[key]?.link;
      } else if (key === "senderId") {
        data[key] = selectedIntegrationSMS?.integrationDetails[key];
        setNewSMSData({
          senderId: selectedIntegrationSMS?.integrationDetails[key],
        });
      }
    }
    if (data.principleEntityId) {
      delete data.principleEntityId;
    }
    setSmsIntegrationFileDetail(data);
  }, [selectedIntegrationSMS]);

  const formData = new FormData();
  const handleUploadFileDetails = (data: any, name: string) => {
    const updatedata: any = smsIntegrationFileDetail;
    if (name === "senderId") {
      updatedata[name] = data;
    } else if (data === "") {
      delete updatedata[name];
    } else {
      formData.append(name, data[0]);

      updatedata[name] = data[0];
    }
    setSmsIntegrationFileDetail({ ...updatedata });
    setIsInputUpdated(true);
  };

  const handleSetUpNewAccount = () => {
    setIsInputUpdated(false);
    const bodyToSend = {
      ...smsIntegrationFileDetail,
      ...newSMSData,
    };

    if (selectedIntegrationSMS?.integrationStatus) {
      dispatch(
        updateNewAccount({
          integrationName: selectedIntegrationSMS?.integrationName,
          accountId: accountId,
          ...bodyToSend,
        })
      );
    } else {
      dispatch(
        setNewAccount({
          integrationName: selectedIntegrationSMS?.integrationName,
          accountId: accountId,
          type: "account",
          ...bodyToSend,
        })
      );
    }
  };

  return (
    <div className={styles.newSMSScreenWrapper}>
      <div className={styles.contentDiv}>
        <div className={`${styles.subDiv} ${styles.senderIdDiv}`}>
          <InputContainer
            label="Sender ID"
            inputType="text"
            inputPlaceholder="Sender ID"
            maxLength={6}
            inputValue={newSMSData?.senderId}
            inputName="senderId"
            inputOnChange={handleInputChange}
            showLabel={true}
            showNewToolTip
            showNewToolTipPosition={"top-end"}
            showNewToolTipMsg={
              "Your preferred 6 character Sender ID. This will be displayed as your message header."
            }
            toolTipSize={false}
            extraInputClass={`${styles.inputDivStyling}`}
            extraLabelClass={styles.inputLabelStyling}
            extraLabelDivClass={styles.labelDivStyling}
            extraUserInputClass={`${styles.inputMainDivStyling}`}
            errorMsg={
              newSMSData?.senderId?.length > 0 &&
              newSMSData?.senderId?.length < 6
                ? "Please Enter a Valid Sender ID "
                : ""
            }
          />
        </div>
        <div className={`${styles.subDiv}`}></div>
        <div className={`${styles.subDiv}  ${styles.uploadBtnDiv}`}>
          <UploadFile
            uploadIcon={uploadSMSDataIcon}
            fileLabel={"PAN"}
            handleUploadFile={(data: any) => {
              handleUploadFileDetails(data, "panCard");
            }}
            uploadedfileName={
              selectedIntegrationSMS?.integrationDetails?.panCard?.name
            }
            toshowGreenLine={true}
          />
        </div>
        <div className={`${styles.subDiv}  ${styles.uploadBtnDiv}`}>
          <UploadFile
            uploadIcon={uploadSMSDataIcon}
            fileLabel={"Aadhar"}
            handleUploadFile={(data: any) => {
              handleUploadFileDetails(data, "aadharCard");
            }}
            uploadedfileName={
              selectedIntegrationSMS?.integrationDetails?.aadharCard?.name
            }
            toshowGreenLine={true}
          />
        </div>
        <div className={`${styles.subDiv}  ${styles.uploadBtnDiv}`}>
          <UploadFile
            uploadIcon={uploadSMSDataIcon}
            fileLabel={"CIN"}
            handleUploadFile={(data: any) => {
              handleUploadFileDetails(data, "CINCard");
            }}
            uploadedfileName={
              selectedIntegrationSMS?.integrationDetails?.CINCard?.name
            }
            toshowGreenLine={true}
          />
        </div>
        <div className={`${styles.subDiv}  ${styles.uploadBtnDiv}`}>
          <UploadFile
            uploadIcon={uploadSMSDataIcon}
            fileLabel={"GSTIN"}
            handleUploadFile={(data: any) => {
              handleUploadFileDetails(data, "GSTINCard");
            }}
            uploadedfileName={
              selectedIntegrationSMS?.integrationDetails?.GSTINCard?.name
            }
            toshowGreenLine={true}
          />
        </div>
        <div className={`${styles.subDiv}  ${styles.uploadBtnDiv}`}>
          <UploadFile
            uploadIcon={uploadSMSDataIcon}
            fileLabel={"Authority Signature"}
            isShowToolTip={true}
            showNewToolTipMsg={
              "A proof of authorisation for the signatory of Letter of Authority."
            }
            showNewToolTipPosition={"top-end"}
            extraToopTipDiv={styles.tooltipBtnDiv}
            handleUploadFile={(data: any) => {
              handleUploadFileDetails(data, "authoritySignature");
            }}
            uploadedfileName={
              selectedIntegrationSMS?.integrationDetails?.authoritySignature
                ?.name
            }
            toshowGreenLine={true}
          />
        </div>
        <div className={`${styles.subDiv} ${styles.uploadBtnDiv}`}>
          <UploadFile
            uploadIcon={uploadSMSDataIcon}
            fileLabel={"Letter of Authority"}
            isShowToolTip={true}
            showNewToolTipMsg={
              "This letter will allow us to create and manage a new DLT account on your behalf. Download the sample and create a letter on your company letter head."
            }
            showNewToolTipPosition={"top-end"}
            extraToopTipDiv={styles.tooltipBtnDiv}
            handleUploadFile={(data: any) => {
              handleUploadFileDetails(data, "letterOfAuthority");
            }}
            uploadedfileName={
              selectedIntegrationSMS?.integrationDetails?.letterOfAuthority
                ?.name
            }
            toshowGreenLine={true}
          />

          <div
            className={styles.downloadSampleDiv}
            onClick={() =>
              downloadSMSPdfFile(
                selectedIntegrationSMS?.authLetterLink,
                "LOA_Template.pdf"
              )
            }
          >
            <Icon img_src={downloadIcon} />
            <div className={styles.downloadStyling}>Download Sample Form</div>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {}
        {!isInputUpdated &&
        ((updatedData && Object.keys(updatedData)?.length > 0) ||
          (setNewAccountApiData &&
            Object.keys(setNewAccountApiData.data)?.length > 0)) ? (
          setNewAccountApiData.isLoading || isUpdateLoading ? (
            <Button
              text="Setup New SMS Account"
              extraClass={`${styles.buttonStyling} ${
                isInvalid || setNewAccountApiData.isLoading || isUpdateLoading
                  ? styles.inActiveBtnStyling
                  : styles.activeBtnStyling
              }`}
              onClick={handleSetUpNewAccount}
            />
          ) : (
            <Button
              text="Success"
              extraClass={`${styles.buttonStyling} ${styles.activeBtnStyling}`}
              image_src_left={verify}
              imgClass={`${styles.sentIconStyling}`}
              onClick={() => {
                onClickClose();
                setIsInputUpdated(false);
              }}
            />
          )
        ) : (
          <Button
            text="Setup New SMS Account"
            extraClass={`${styles.buttonStyling} ${
              isInvalid || setNewAccountApiData.isLoading || isUpdateLoading
                ? styles.inActiveBtnStyling
                : styles.activeBtnStyling
            }`}
            onClick={handleSetUpNewAccount}
          />
        )}
      </div>
    </div>
  );
};
