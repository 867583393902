// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wS8rOWZEdeP_tn8YFmZz{padding:20px}.wS8rOWZEdeP_tn8YFmZz header{display:flex;align-items:center;width:100%;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/screens/maiaAnalytics/MaiaAnalyticsPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,sBAAA","sourcesContent":[".wrapper {\n  padding: 20px;\n  header {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    justify-content: center;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wS8rOWZEdeP_tn8YFmZz`
};
export default ___CSS_LOADER_EXPORT___;
