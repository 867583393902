import React from "react";
import styles from "./PrivacyPolicy.module.scss";

export const PrivacyPolicy = () => {
  return (
    <div className={styles.privacyPolicyWrapper}>
      <div>
        When you use Saarthi’s services, you trust us with your information.
        This Privacy Policy is meant to help you understand what we collect, why
        we collect it, and what we do with it.
      </div>
      <div className={styles.contentStyling}>
        <div>
          <div className={styles.headerStyling}>POLICY</div>
          <div>
            The terms “Saarthi,” “we,” and “us” include Saarthi, our parent
            company (Gamut Analytics Pvt. Ltd.), affiliates and subsidiaries.
            The Privacy Policy explains how Saarthi may collect, use, and
            disclose information we obtain through the “Service.”
          </div>
        </div>
        <div>
          <div className={styles.headerStyling}>ABOUT SAARTHI</div>
          <div>
            Saarthi provides clients and users with services and tools that
            allow them to improve their online interactions with
            individuals/services on our app, existing social media and messaging
            services and platforms (“the Service”). There are many different
            ways you can use Saarthi–search for services, share information,
            communicate your needs to the Service and interact with users and
            other people. We act as a service provider to our clients and we
            collect data on behalf of our clients. Therefore, our use and
            disclosure of Personal Information is limited by our agreements with
            them. This Privacy Policy does not reflect the privacy practices of
            our clients, and we are not responsible for our clients’ privacy
            policies or practices. We do not review, comment upon, or monitor
            our clients’ privacy policies or their compliance with their
            respective privacy policies, nor do we review our client’s
            instructions with respect to our processing of information to
            determine whether such instructions are in compliance or conflict
            with the terms of the client’s published privacy policy.
          </div>
        </div>
        <div>
          <div className={styles.headerStyling}>
            THE SERVICE COLLECTS YOUR INFORMATION
          </div>
          <div>
            “Personal Information” means information that alone or when in
            combination with other information may be used to readily identify,
            contact, or locate you, such as: name, address, email address, or
            phone number, daily schedules, service preferences, health profiles,
            etc. We do not consider Personal Information to include information
            that has been anonymized so that it does not allow a third party to
            easily identify a specific individual. Contact Information. When you
            communicate with our clients through the Service, we may collect
            online contact information about you, such as your name, address,
            email address, and telephone number. When the Service completes a
            communication session with you and the processing necessary to
            respond to your request, we may pass this information to our clients
            or their agents before deleting it from our end. However, we retain
            social media and messaging service user names so we can remember who
            you are and personalize the Service for you. For example, if you
            communicate with the Service through a client’s Twitter account, we
            may collect your Twitter username and retain it along with other
            information you want to give us access to for improving your
            experience. Also, the primary user’s contact information is retained
            for verification. Public Information on Social Media and Messaging
            Services. When you communicate with our clients using the Service
            through our app, social media and messaging services, we may collect
            publicly available information about you from your profile on the
            social media or message services you use. For example, when you
            communicate through Facebook with a client using the Service, we may
            collect the Personal Information you have made publicly available in
            Facebook, such as your name, user ID, profile picture, cover photo,
            username, gender, networks, age range, language, country, and any
            other information you have made public or you would allow us access
            to. Communications with Clients. We collect the information in
            messages between you and our clients when they use the Service. For
            example, when you communicate with our clients by social media,
            email, text message, or other methods by initiating orders and
            requesting and receiving customer service from our clients through
            the Service, we will collect the information you submit during the
            discussion. We take steps to delete the Personal Information
            collected by the Service during such interactions after the
            communication session is done and we have finished processing the
            information as necessary to respond to your request. Please note
            that when you use social media and messaging services, they will
            also collect the information you communicate with our clients using
            the Service, and such information will be subject to their privacy
            policies. From Your Client Account. When a client communicates with
            you through the Service and you log into the account you have with
            the client, the client may send us a unique ID and other information
            associated with you so that the client can match you with your
            interactions using the Service and we can personalize the Service
            for you. We will either have restricted or no access to your client
            account credentials. Transactions. The Service may facilitate
            transactions between you and our client, but the Service does not
            process payments. When you make credit card payments to our clients,
            our clients or their third-party service providers collect the
            payment information. We do not collect or handle credit card
            information. However, you may save your cards and other information
            that might help you in transacting faster. In any case, we neither
            collect it nor provide it to anyone. Communicate with Us. We may
            collect Personal Information through your communications with our
            customer-support team or through other communications with us.
            Geo-Location Information from Your Mobile Device. When you
            communicate with our clients using the Service, you may be able to
            send us your current geo-location information. Collection of this
            information is used to personalize the services provided to you,
            such as to show you the client’s nearest stores or venues. Automatic
            Data Collection: Cookies and Related Technologies. The Service
            collects and stores information that is generated automatically as
            you use it, including anonymous usage statistics. When we associate
            such information with Personal Information, we will treat the
            combination as Personal Information. When you use the Service, we,
            our clients, and our third-party partners, such as analytics
            providers, use “cookies” and other similar technologies to collect
            information about how the Service is accessed and used. Our clients
            and partners also may collect information about your online
            activities over time, on other devices, and on other websites or
            apps. When they provide such services, they are governed by their
            own privacy policies. You may be able to change browser settings to
            block and delete cookies when you access the Service through a web
            browser. However, if you do that, the Service may not work properly.
            We may use Google Analytics to collect and process certain Services
            usage data. By using the Service, you are authorizing us to gather,
            parse, and retain data related to the provision of the Service. We
            may combine all of the information we collect about you and that we
            derive through data analysis into a user profile.
          </div>
        </div>
        <div>
          <div className={styles.headerStyling}>
            HOW SAARTHI USES YOUR INFORMATION
          </div>
          <div>
            Internal and Service-Related Usage. We use the information we
            collect for internal and service-related purposes and may provide it
            to third parties to allow us to facilitate the Service. For example,
            we may use your address and the connection between your social media
            account and client account to allow you to more easily place an
            order with a client in the future. We will also use the information
            we collect through interactions to personalize the Service for you.
            We may use and retain any data we collect to provide and improve any
            of our services. Communicating with You. Our clients use the Service
            to communicate with you. For example, they may use a social media or
            messaging service or application, text messaging, or other method to
            interact with you. The client uses the Service to respond to you
            using the same communication method. For example, if you communicate
            through Twitter with our client using the Service, then the Service
            will respond via Twitter. If you communicate by text message with
            our client using the Service, then the Service will respond via text
            message. By communicating with the Service using a particular
            method, you consent to receiving communications from the Service by
            that same method. The client may also use the Service to initiate
            push notifications to your device through a mobile application you
            are using to communicate with the client. This will also be governed
            by the preferences you have shared with the Service. Marketing. Our
            clients may use the Service to provide you with offers and other
            marketing information. This will also be governed by the preferences
            you have shared with the Service. Aggregate Data. We may anonymize
            or aggregate data collected through the Service and use and disclose
            it for any purpose.
          </div>
          <div className={styles.breakStyling}> </div>
          <div>
            We may also need your information to contact you via email,
            facsimile, phone or text messages, to deliver certain services or
            information you have requested.
          </div>
        </div>
        <div>
          <div className={styles.headerStyling}>
            SAARTHI MAY DISCLOSE YOUR INFORMATION
          </div>
          <div>
            Vendors and Service Providers. We may share any information we
            receive with vendors and service providers we use to help us provide
            the Service. For example, our web hosting and e-commerce service
            providers may handle information for us. Clients. The information
            provided during your interactions with a client facilitated by the
            Service will be made available to that client. Clients will use the
            information to complete requests and transactions and for their
            business purposes. When a client asks us, we may also share such
            information with others. We are not responsible for how clients will
            use and disclose the information they receive. You may visit our
            clients’ privacy policy to learn how they collect, use, and disclose
            information. Social Networking and Other Websites. You may be able
            to direct the Service to share information, including Personal
            Information, with social networking and messaging services, such as
            Facebook. We do not share your Personal Information with them unless
            you direct the Service to share it. Their use of the information
            will be governed by their privacy policies, and you may be able to
            modify your privacy settings on their websites Marketing. The
            information collected through the Service is collected on behalf of
            our clients. Therefore, we will provide such information to our
            clients or agents, and our clients may use it for their business
            purposes, including for marketing and advertising purposes.
            Otherwise, we do not rent, sell, or share Personal Information about
            you with other people or nonaffiliated companies for their direct
            marketing purposes, unless we have your permission. We may allow
            access to other data collected by the Service for general marketing
            or advertising purposes Legal and Similar Disclosures. We may
            access, preserve, and disclose collected information, if we believe
            doing so is required or appropriate to: comply with law enforcement
            requests and legal process, such as a court order or subpoena;
            respond to your requests; comply with the law; or protect your, our,
            or others’ rights, property, or safety. Merger, Sale, or Other Asset
            Transfers. If we are involved in a merger, acquisition, financing
            due diligence, reorganization, bankruptcy, receivership, sale of
            company assets, or transition of service to another provider, your
            information may be sold or transferred as part of such a transaction
            as permitted by law and/or contract. We cannot control how such
            entities may use or disclose such information. With Your Permission.
            We may also disclose your Personal Information with your permission.
          </div>
        </div>
        <div>
          <div className={styles.headerStyling}>INFORMATION SECURITY</div>
          <div>
            We take steps to ensure that your information is treated securely
            and in accordance with this Privacy Policy. Unfortunately, the
            Internet cannot be guaranteed to be 100% secure, and we cannot
            ensure or warrant the security of any information you provide to us.
            We do not accept liability for unintentional disclosure. By using
            the Service or providing Personal Information to us, you agree that
            we may communicate with you electronically regarding security,
            privacy, and administrative issues relating to your use of the
            Service. If we learn of a security system’s breach, we may attempt
            to notify you electronically by posting a notice on the Service or
            sending an email to you. You may have a legal right to receive this
            notice by mail.
          </div>
        </div>
        <div>
          <div className={styles.headerStyling}>CHILDREN’S PRIVACY</div>
          <div>
            We do not knowingly collect information from children under 13. We
            will take steps to delete it if we learn we have collected it We do
            not knowingly collect, maintain, or use personal information from
            children under 13 years of age, and no part of the Service is
            directed to children under the age of 13. If you learn that your
            child has provided us with personal information without your
            consent, you may alert us at privacy@saarthi.ai. If we learn that we
            have collected any personal information from children under 13, we
            will promptly take steps to delete such information and terminate
            the child’s account.
          </div>
        </div>
        <div>
          <div className={styles.headerStyling}>INTERNATIONAL USERS</div>
          <div>
            By using the Service, you will transfer data to India. If you are
            visiting from the European Union or other regions with laws
            governing data collection and use, you agree to the transfer of your
            information to India and processing globally. By providing your
            information you consent to any transfer and processing in accordance
            with this Privacy Policy.
          </div>
        </div>
        <div>
          <div className={styles.headerStyling}>
            UPDATE YOUR INFORMATION OR POSE A QUESTION OR SUGGESTION
          </div>
          <div>
            If you would like to update or correct any information that you have
            provided to us through your use of the Service or otherwise, or if
            you have suggestions for improving this Privacy Policy, please send
            an email to hello@saarthi.ai
          </div>
        </div>
        <div>
          <div className={styles.headerStyling}>
            CHANGES TO OUR PRIVACY POLICY AND PRACTICES
          </div>
          <div>
            We may revise this Privacy Policy, so review it periodically.
            Posting of Revised Privacy Policy. We will post any adjustments to
            the Privacy Policy on this web page, and the revised version will be
            effective when it is posted. New Uses of Personal Information. From
            time to time, we may desire to use Personal Information for uses not
            previously disclosed in our Privacy Policy. If our practices change
            regarding previously collected Personal Information in a way that
            would be materially less restrictive than stated in the version of
            this Privacy Policy in effect at the time we collected the
            information, we will make reasonable efforts to provide notice and
            obtain consent to any such uses as may be required by law.
          </div>
        </div>
      </div>
      <div>
        <div>
          If there are any questions or concerns regarding this privacy policy,
          you may contact our Grievance Officer using the information below:
        </div>
        <div className={styles.btmDivStyling}>Sangram Sabat</div>
        <div>
          Gamut Analytics Private Limited: Indiqube octagon, No.643, 80 Feet Rd,
          opp. Swathi Maharaja Hotel, 4th Block, Koramangala, Bengaluru,
          Karnataka 560034
        </div>
        <div className={styles.btmDivStyling}>privacy@saarthi.ai</div>
        <div>
          We also have a privacy officer who may be contacted in relation to any
          privacy or data protection issues through the above email address
          (privacy@saarthi.ai).
        </div>
      </div>
    </div>
  );
};
