import navigationActionTypes from "../navigation.actionTypes";
export interface state {
  leftSideNavigation: { data: any[]; Error: any; isLoading: boolean };
  topDropdownHome: { data: any[]; Error: any; isLoading: boolean };
  topDropdownSetting: { data: any[]; Error: any; isLoading: boolean };
  currentTeam: Record<string, any>;
}
const initialState: state = {
  leftSideNavigation: { data: [], Error: "", isLoading: false },
  topDropdownHome: { data: [], Error: "", isLoading: false },
  topDropdownSetting: { data: [], Error: "", isLoading: false },
  currentTeam: {},
};
export default function navigationReducer(
  state: state = initialState,
  action: any
): state {
  switch (action.type) {
    case navigationActionTypes.PROFILE_MODULE_GET_API_CALL:
      return {
        ...state,
        leftSideNavigation: {
          ...state.leftSideNavigation,
          Error: "",
          isLoading: true,
        },
      };
    case navigationActionTypes.PROFILE_MODULE_GET_API_SUCCESS:
      return {
        ...state,
        leftSideNavigation: {
          data: action.payload,
          Error: "",
          isLoading: false,
        },
      };
    case navigationActionTypes.PROFILE_MODULE_GET_API_FAILURE:
      return {
        ...state,
        leftSideNavigation: {
          data: [],
          Error: action.payload,
          isLoading: false,
        },
      };
    case navigationActionTypes.DROPDOWN_HOME_GET_API_CALL:
      return {
        ...state,
        topDropdownHome: {
          ...state.topDropdownHome,
          Error: "",
          isLoading: true,
        },
      };
    case navigationActionTypes.DROPDOWN_HOME_GET_API_SUCCESS:
      return {
        ...state,
        topDropdownHome: {
          data: action.payload,
          Error: "",
          isLoading: false,
        },
      };
    case navigationActionTypes.DROPDOWN_HOME_GET_API_FAILURE:
      return {
        ...state,
        topDropdownHome: {
          data: [],
          Error: action.payload,
          isLoading: false,
        },
      };
    case navigationActionTypes.DROPDOWN_SETTING_GET_API_CALL:
      return {
        ...state,
        topDropdownSetting: {
          ...state.topDropdownSetting,
          Error: "",
          isLoading: true,
        },
      };
    case navigationActionTypes.DROPDOWN_SETTING_GET_API_SUCCESS:
      return {
        ...state,
        topDropdownSetting: {
          data: action.payload,
          Error: "",
          isLoading: false,
        },
      };
    case navigationActionTypes.DROPDOWN_SETTING_GET_API_FAILURE:
      return {
        ...state,
        topDropdownSetting: {
          data: [],
          Error: action.payload,
          isLoading: false,
        },
      };
    case navigationActionTypes.SET_CURRENT_TEAM_PROFILE: {
      return {
        ...state,
        currentTeam: action.payload,
      };
    }
    case navigationActionTypes.USER_LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
//
