import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { INTEGRATION_API, config } from "../../../../services/ApiRoutes";
import actionTypes from "../allIntegrations.actionTypes";
import { integrationCardInterface } from "../interface";
import _ from "lodash";

export function* getAllIntegrationsDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_ALL_INTEGRATIONS}`,
        {
          accountId: action.payload.accountId,
        }
      );
      const integrationsData: integrationCardInterface[] = result?.data.data;
      const groupedIntegrationsData = integrationsData?.reduce(
        (result: any, integration) => {
          const category = integration.category[0];
          if (!result[category]) {
            result[category] = [];
          }
          result[category].push(integration);
          return result;
        },
        {}
      );

      const categoriesData = Object.entries(groupedIntegrationsData).map(
        ([category, data]) => ({
          categoryName: category,
          categoryData: data,
        })
      );

      categoriesData.unshift({
        categoryName: "All",
        categoryData: integrationsData,
      });

      yield put({
        type: actionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS,
        payload: result?.data.data,
      });
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.GET_ALL_INTEGRATIONS_DATA_FAILURE,
      payload: error?.response?.data?.error,
    });
    console.error(error);
  }
}

export function* createAllIntegrationsDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_BODY,
        `${INTEGRATION_API.CREATE_INTEGRATION}`,
        action.payload
      );
      const getResult = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_ALL_INTEGRATIONS}`,
        {
          accountId: action.payload.accountId,
        }
      );
      yield put({
        type: actionTypes.CREATE_INTEGRATION_DATA_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: actionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS,
        payload: getResult?.data.data,
      });
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.CREATE_INTEGRATION_DATA_FAILURE,
      payload: error.response?.data?.error,
    });
    console.error(error);
  }
}

export function* updateAllIntegrationsDataAPIWorker(action: any): any {
  try {
    if (action) {
      console.log("body data ", action.payload);
      const result =
        action.payload.integrationName === "SMS"
          ? yield call(
              config.PUT_WITH_HEADER_BODY,
              `${INTEGRATION_API.UPDATE_INTEGRATION}`,
              action.payload,
              {
                headers: {
                  "content-type": "multipart/form-data",
                },
              }
            )
          : yield call(
              config.PUT_WITH_BODY,
              `${INTEGRATION_API.UPDATE_INTEGRATION}`,
              action.payload
            );
      const getResult = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_ALL_INTEGRATIONS}`,
        {
          accountId: action.payload.accountId,
        }
      );
      yield put({
        type: actionTypes.UPDATE_INTEGRATION_DATA_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: actionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS,
        payload: getResult?.data.data,
      });
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.UPDATE_INTEGRATION_DATA_FAILURE,
      payload: error.response?.data?.error,
    });
    console.error(error);
  }
}
