import actionTypes from "../actionTypes";

import { getChatStatus, recentChat } from "../interface";
export const getCallSummaryAgentDesktopChat = (sessionId: string) => {
  return {
    type: actionTypes.GET_SUMMARY_REQUEST_AGENT_DESKTOP_CHAT,
    payload: sessionId,
  };
};

export const getCallTranscriptAgentDesktopChat = (sessionId: string) => {
  return {
    type: actionTypes.GET_TRANSCRIPT_REQUEST_AGENT_DESKTOP_CHAT,
    payload: sessionId,
  };
};
/**
 * 
 * {
    "accountName": "Capri Global Testing",
    "channel": [
        "Call"
    ],
    "dategte": "2021-11-01T00:00:00",
    "datelte": "2024-03-31T23:59:59",
    "limit": 10,
    "page": 1,
    "phoneNo": "",
    "agentIds": [
        "63b57e181ae92c6fe710320d"
    ]
}
 * @param data 
 * @returns 
 */
export const getSessionIdAgentDesktopChat = (data: {
  accountName: string;
  channel: string[];
  // dategte: string;
  // datelte: string;
  limit: number;
  page: number;
  phoneNo?: string;
  /**
   * send filter in array
   * in-pregress
   * open
   * close
   */
  chatStatus: ["in-progress" | "open" | "closed"] | [];
  agentIds: string[];
}) => {
  return {
    type: actionTypes.GET_SESSION_ID_REQUEST_AGENT_DESKTOP_CHAT,
    payload: data,
  };
};

export const getSessionIdWithoutAgentDesktopChat = (data: {
  accountName: string;
  channel: string[];
  // dategte: string;
  // datelte: string;
  limit: number;
  page: number;
  phoneNo?: string;
  /**
   * send filter in array
   * in-pregress
   * open
   * close
   */
  chatStatus: ["in-progress" | "open" | "closed"] | [];
  agentIds: string[];
}) => {
  return {
    type: actionTypes.GET_SESSION_ID_REQUEST_WITHOUT_REFRESH_AGENT_DESKTOP_CHAT,
    payload: data,
  };
};

// export const setChatStatusChat = (status: string) => {
//   return {
//     type: actionTypes.SET_CHAT_STATUS_AGENT_DESKTOP_CHAT,
//     payload: status,
//   };
// };
export const setSelectedSessionIdChat = (sessionId: string) => {
  return {
    type: actionTypes.SET_SELECTED_SESSION_ID_AGENT_DESKTOP_CHAT,
    payload: sessionId,
  };
};
/**
 * 
 * @param data '{
    "accountName": "Saarthi",
    "channel": [
        "whatsapp"
    ],
    "agentIds": ["63b57e181ae92c6fe710320d"]
}'
 * @returns 
 */
export const getStatusForChat = (data: {
  accountName: string;
  channel: ["Whatsapp" | "Call"];
  agentIds: [string];
}) => {
  console.log("nithin payload", data);
  return {
    type: actionTypes.GET_STATUS_OF_CHAT_AGENT_DESKTOP_API_CALL,
    payload: data,
  };
};

export const updateSummary = (
  data: Record<string, any>,
  summaryId: string,
  chatObjective?: string
) => {
  return {
    type: actionTypes.UPDATE_SUMMARY_CHAT_REQUEST_AGENT_DESKTOP,
    payload: { data, summaryId, chatObjective },
  };
};

export const setSummaryUpdateSuccess = (value: boolean) => {
  return {
    type: actionTypes.SET_SUMMARY_CHAT_UPDATE_SUCCESS_AGENT_DESKTOP,
    payload: value,
  };
};
export function setRecentMessageFromSockets(recentChat: recentChat[]) {
  return {
    type: actionTypes.APPEND_RECENT_MESSAGE_FROM_SOCKETS,
    payload: recentChat,
  };
}

export const appendChatTranscript = (
  message: string,
  timeStamp: string,
  speaker: string,
  interactionType?: string,
  fileName?: string
) => {
  return {
    type: actionTypes.APPEND_CHAT_TRANSCRIPT_AGENT_DESKTOP,
    payload: { message, timeStamp, speaker, interactionType, fileName },
  };
};

export const setMessageStatusGroup = (status: string) => {
  return {
    type: actionTypes.SET_MESSAGE_STATUS_GROUP_AGENT_DESKTOP,
    payload: status,
  };
};
/**
* @example
 dispatch(getStatusForWhatsAppChat({
    "conversationId":"63c904cc541f82784ef0f74b",
    "messageId": "63c904d9ed48476e1e9b4d40"
}))
 * @param data 
 * @returns 
 * 
 */
export function getStatusForWhatsAppChat(data: getChatStatus[]) {
  return {
    type: actionTypes.GET_STATUS_OF_CHAT_AGENT_DESKTOP_API_CALL,
    payload: data,
  };
}
export function getCannedMessages(messageKey: string) {
  return {
    type: actionTypes.GET_CANNED_MESSAGES_API_CALL,
    payload: messageKey,
  };
}

export const handleChatPageLeave = () => {
  return {
    type: actionTypes.HANDLE_CHAT_PAGE_LEAVE_AD,
  };
};
