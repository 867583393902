import actionTypes from "../email.actionTypes";

export function getEmailDNSData(payload: object) {
  return {
    type: actionTypes.GET_EMAIL_DNS_DETAILS_API_REQUEST,
    payload: payload,
  };
}

export function getDevEmailDNSData(payload: object) {
  return {
    type: actionTypes.GET_DEV_EMAIL_DETAILS_API_REQUEST,
    payload: payload,
  };
}

export function updateEmailDNSData(payload: object) {
  return {
    type: actionTypes.UPDATE_EMAIL_DNS_DETAILS_API_REQUEST,
    payload: payload,
  };
}

export function verifyEmailDNSData(payload: object) {
  return {
    type: actionTypes.VERIFY_EMAIL_DNS_DETAILS_API_REQUEST,
    payload: payload,
  };
}

export function sendDNSToDevEmailDNSData(payload: object) {
  return {
    type: actionTypes.SEND_DNS_TO_DEV_EMAIL_API_REQUEST,
    payload: payload,
  };
}

export function setEmailScreen(screenName: string) {
  return {
    type: actionTypes.SET_CURRENT_SCREEN_EMAIL,
    payload: screenName,
  };
}

export function clearEmailData() {
  return {
    type: actionTypes.CLEAR_EMAIL_DATA,
  };
}

export function clearEmailVerifyData() {
  return {
    type: actionTypes.CLEAR_VERIFY_DATA,
  };
}
