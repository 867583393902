// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gwlXt3OtYtCQg9YqyUGn{width:100% !important;height:100% !important;display:flex;flex-direction:row}.gwlXt3OtYtCQg9YqyUGn .IgPXGne_k6VOCNHufWt1{display:flex;align-items:center;justify-content:center;width:100%;height:100%;color:#0174ff;animation:3s qysGeuGqKJ3eU34ibQbL}@keyframes qysGeuGqKJ3eU34ibQbL{from{margin-left:-100%}to{margin-left:0%}}`, "",{"version":3,"sources":["webpack://./src/screens/integration_V2.0/IntegrationScreen.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,sBAAA,CACA,YAAA,CACA,kBAAA,CAEA,4CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,aAAA,CACA,iCAAA,CAKJ,gCACE,KACE,iBAAA,CAEF,GACE,cAAA,CAAA","sourcesContent":[".integrationScreenWrapper {\n  width: 100% !important;\n  height: 100% !important;\n  display: flex;\n  flex-direction: row;\n\n  .comingSoonDiv {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    height: 100%;\n    color: #0174ff;\n    animation: 3s slide-right;\n    //animation: reveal 3s backwards;\n  }\n}\n\n@keyframes slide-right {\n  from {\n    margin-left: -100%;\n  }\n  to {\n    margin-left: 0%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"integrationScreenWrapper": `gwlXt3OtYtCQg9YqyUGn`,
	"comingSoonDiv": `IgPXGne_k6VOCNHufWt1`,
	"slide-right": `qysGeuGqKJ3eU34ibQbL`
};
export default ___CSS_LOADER_EXPORT___;
