import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../generic/loader/Loader";
import BiaxialBarChart from "../charts/BiaxialBarChart";
import HorizontallProgress from "../../generic/horizontalProgress/HorizontalProgress";
import FunnelChartComponent from "../charts/FunnelChart";
import LineChartToggleLegend from "../charts/LineChartToggleLegend";
import SimpleLineChart from "../charts/SimpleLineChart";
import CrmCardComponent from "../crmCardComp/CrmCardComponent";
import CRMDonutChartComponent from "../crmdonutChartComponent/CRMDonutChartComp";
import MapComponent from "../mapComponent/MapComponent";
import PieChartComponent from "../pieChartComponent/PieChartComponent";

import styles from "./ChartModel.module.scss";
import Slide from "../../../generic/slide/Slide";

export default function CRMChartModel(props: any) {
  const [channel, setChannel] = useState<any>("Call");

  const isCardName = useSelector(
    (store: any) => store?.analyticsReducer?.cardName
  );

  return (
    <div className={styles.chartModelParentComp}>
      {props.chartDetails &&
        props.chartDetails.map((each: any, i: number) => {
          return (
            <>
              {each.type == "card" ? (
                <div className={styles.cardDetailsDiv}>
                  <CrmCardComponent data={each} />
                </div>
              ) : (
                <div
                  className={styles.crmChartModelComponentWrapper}
                  key={i}
                  style={{
                    width: `${each.chartStyle.width}`,
                    height: `${each.chartStyle.height}`,
                  }}
                >
                  <div className={styles.chartModelHeader}>
                    {each.isCardData ? (
                      <p>{isCardName}</p>
                    ) : (
                      <p>{each.chartTitle}</p>
                    )}
                    <div>
                      {each.isSlider && (
                        <Slide
                          text={["Call", "WhatsApp"]}
                          defaultIndex={0}
                          onChange={(a: string | number) => {
                            setChannel(a);
                          }}
                          disabled={false}
                        />
                      )}
                    </div>
                  </div>
                  <hr className={styles.horizontalLine} />
                  <div className={styles.chartModelBody}>
                    {each.chartType == "pieChart" ? (
                      <PieChartComponent data={each.data} />
                    ) : each.chartType == "donutChart" ? (
                      <CRMDonutChartComponent
                        data={each.data}
                        chartDetails={each}
                      />
                    ) : each.chartType == "funnelChart" ? (
                      <FunnelChartComponent
                        data={
                          each.isSlider
                            ? channel == "Call"
                              ? each.dataCall
                              : each.dataWhatsApp
                            : each.data
                        }
                        chartDetails={each}
                      />
                    ) : each.chartType == "lineChart" ? (
                      <SimpleLineChart
                        chartDetails={each}
                        data={
                          each.isSlider
                            ? channel == "Call"
                              ? each.dataCall
                              : each.dataWhatsApp
                            : each.data
                        }
                      />
                    ) : each.chartType == "multiLineChart" ? (
                      <LineChartToggleLegend
                        data={each.data}
                        chartDetails={each}
                      />
                    ) : each.chartType == "biaxialBarChart" ? (
                      <BiaxialBarChart data={each.data} chartDetails={each} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </>
          );
        })}
      {/* <div>
        <MapComponent />
        <HorizontallProgress
        title="After PTP Received"
        left={{ title: "paid on time", value: 90 }}
        right={{ title: "not paid on time", value: 90 }}
         />
      </div> */}
    </div>
  );
}
