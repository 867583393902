import React, { useEffect, useState, useRef } from "react";
import styles from "./UploadComponent.module.scss";
import { dataImportIcon } from "../../../../../theme/assets/svg/campaign_v3/index";
import Icon from "../../../../generic/icon/Icon";
import Button from "../../../../generic/button/Button";

interface UploadComponentProps {
  onFileSelect: any;
  onClickSample: any;
  isLoading: boolean;
  selectedTab: string;
  isStrategy: boolean;
}

export const UploadComponent = ({
  onFileSelect,
  onClickSample,
  isLoading,
  selectedTab,
  isStrategy,
}: UploadComponentProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const acceptedFileTypes = ".csv";

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files);
      onFileSelect(fileList);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div
      className={styles.uploadComponentDiv}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={styles.uploadDataInitialDiv}>
        <div className={styles.uploadInfoDiv}>
          <Icon
            img_src={dataImportIcon}
            extraClass={styles.iconStyling}
            id="auto_upload_file_icon"
          />
          {/* <div>{method}</div> */}
          <div className={styles.initialHeading}>Import Customers Data</div>
          <div className={styles.initialSubHeading}>
            Supports only CSV files.{" "}
            <span className={styles.downloadSubHeading} onClick={onClickSample}>
              Download Sample File
            </span>
          </div>
        </div>
        <div className={styles.uploadBtnDiv}>
          <input
            type="file"
            accept={acceptedFileTypes}
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileInputChange}
            multiple={
              !isStrategy && selectedTab?.toLocaleLowerCase() === "allocation"
            }
          />
          <Button
            text={"Browse"}
            onClick={() => fileInputRef.current?.click()}
            extraClass={`${styles.buttonStyling} ${
              isLoading
                ? styles.browseButtonInActiveStyle
                : styles.browseButtonActiveStyle
            }`}
          />
        </div>
      </div>
    </div>
  );
};
