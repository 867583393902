import { takeLatest, takeEvery } from "redux-saga/effects";
import actionTypes from "../actionTypes/login.actionTypes";
import {
  schemaUpdateWorker,
  requestFeatureWorker,
  requestSchemaOfClientWorker,
  getTeamDataWorker,
  getAccountDetailsByClientWorker,
  updateUserDataWorker,
  getUserDataWorker,
  getTeamSchemaWorker,
  getTeamTabsWorker,
  getTeamChannelsWorker,
  getSidebarModulesWorker,
  logOutDataApiWorker,
  refreshTokenAPIworker,
  verifyInviteTokenWorker,
} from "./login.saga";

export default function* loginSagas() {
  //  yield takeLatest(actionTypes.LOGIN_REQUEST, loginWorker);
  yield takeLatest(actionTypes.SET_SCHEMA_UPDATE_REQUEST, schemaUpdateWorker);
  yield takeLatest(
    actionTypes.SET_REQUEST_FEATURE_API_REQUEST,
    requestFeatureWorker
  );
  yield takeLatest(
    actionTypes.GET_SCHEMA_OF_CLIENT_REQUEST,
    requestSchemaOfClientWorker
  );
  yield takeLatest(actionTypes.GET_TEAM_DATA_REQUEST, getTeamDataWorker);
  yield takeLatest(
    actionTypes.GET_ALL_CLIENT_DATA_REQUEST,
    getAccountDetailsByClientWorker
  );
  yield takeLatest(actionTypes.UPDATE_USER_DATA_REQUEST, updateUserDataWorker);
  yield takeLatest(actionTypes.GET_USER_DATA_REQUEST, getUserDataWorker);
  yield takeLatest(actionTypes.GET_TEAM_SCHEMA_REQUEST, getTeamSchemaWorker);
  yield takeLatest(actionTypes.GET_TEAM_TAB_REQUEST, getTeamTabsWorker);
  yield takeLatest(actionTypes.GET_TEAM_CHANNEL_REQUEST, getTeamChannelsWorker);
  yield takeLatest(
    actionTypes.GET_SIDEBAR_MODULE_REQUEST,
    getSidebarModulesWorker
  );
  yield takeLatest(actionTypes.USER_LOGOUT, logOutDataApiWorker);
  yield takeLatest(
    actionTypes.REFRESH_TOKEN_API_REQUEST,
    refreshTokenAPIworker
  );
  yield takeLatest(actionTypes.VERIFY_INVITE_REQUEST, verifyInviteTokenWorker);
}
