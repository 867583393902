// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../theme/assets/svg/integration_V2.0/copyIconLight.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../theme/assets/svg/integration_V2.0/copyIconDark.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../theme/assets/svg/integration_V2.0/tickIcon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r6J2OiSHRJScpzf82gUj .YZmGkPv34LTkOVsDV0HQ{display:inline-block;width:2em;height:2em}.r6J2OiSHRJScpzf82gUj ._15AokWvTE4kgSm2sbAu{background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;cursor:pointer}.r6J2OiSHRJScpzf82gUj ._15AokWvTE4kgSm2sbAu:hover{background:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat center center;cursor:pointer}.r6J2OiSHRJScpzf82gUj .CAV4glXp3qAS8Fc0JCOD{background:url(${___CSS_LOADER_URL_REPLACEMENT_2___}) no-repeat center center;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/generic/copyToolTip/CopyToolTip.module.scss"],"names":[],"mappings":"AACE,4CACE,oBAAA,CACA,SAAA,CACA,UAAA,CAGF,4CACE,0EAAA,CAEA,cAAA,CAEF,kDACE,0EAAA,CAEA,cAAA,CAGF,4CACE,0EAAA,CAEA,cAAA","sourcesContent":[".copyTooltipStylingWrapper {\n  .iconStyling {\n    display: inline-block;\n    width: 2em;\n    height: 2em;\n  }\n\n  .copyIcon {\n    background: url(\"../../../theme/assets/svg/integration_V2.0/copyIconLight.svg\")\n      no-repeat center center;\n    cursor: pointer;\n  }\n  .copyIcon:hover {\n    background: url(\"../../../theme/assets/svg/integration_V2.0/copyIconDark.svg\")\n      no-repeat center center;\n    cursor: pointer;\n  }\n\n  .tickIcon {\n    background: url(\"../../../theme/assets/svg/integration_V2.0/tickIcon.svg\")\n      no-repeat center center;\n    cursor: pointer;\n  }\n}\n\n// .copyTooltipStylingWrapper:not(:hover) .copyIcon {\n//   background: url(\"../../../theme/assets/svg/integration_V2.0/copyIconLight.svg\")\n//     no-repeat center center;\n//   cursor: pointer;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyTooltipStylingWrapper": `r6J2OiSHRJScpzf82gUj`,
	"iconStyling": `YZmGkPv34LTkOVsDV0HQ`,
	"copyIcon": `_15AokWvTE4kgSm2sbAu`,
	"tickIcon": `CAV4glXp3qAS8Fc0JCOD`
};
export default ___CSS_LOADER_EXPORT___;
