// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hVTuTI9Tg2i7G4Lx9mmu{display:flex;justify-content:flex-start;color:var(--primary-color);padding-left:1em}.fD1NjToiioFxBcQ6OJY7{color:inherit;margin:0;margin-left:.8em;margin-top:7px;margin-bottom:7px;font-size:14px}.bMNPKPYhEhLR5DfMqAT9{border:.5px solid var(--secondary-grey)}`, "",{"version":3,"sources":["webpack://./src/components/generic/rightSidebar/downloadSection/DownloadSection.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,0BAAA,CACA,0BAAA,CACA,gBAAA,CAEA,sBACE,aAAA,CACA,QAAA,CACA,gBAAA,CACA,cAAA,CACA,iBAAA,CACA,cAAA,CAGF,sBACE,uCAAA","sourcesContent":[".titleofilter{\n    display: flex;\n    justify-content: flex-start;\n    color:var(--primary-color);\n    padding-left: 1em;\n    }\n    .titletext{\n      color:inherit;\n      margin: 0;\n      margin-left: .8em;\n      margin-top:7px;\n      margin-bottom: 7px;\n      font-size: 14px;\n     \n    }\n    .divide{\n      border: .5px solid var(--secondary-grey);\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleofilter": `hVTuTI9Tg2i7G4Lx9mmu`,
	"titletext": `fD1NjToiioFxBcQ6OJY7`,
	"divide": `bMNPKPYhEhLR5DfMqAT9`
};
export default ___CSS_LOADER_EXPORT___;
