import React, { useEffect, useImperativeHandle, useState } from "react";

import styles from "./Pagination.module.scss";

import { props } from "./types";
import ReactPaginate from "react-paginate";

import {
  moveLeftIcon,
  moveRightIcon,
  leftSingleArrow,
  rightSingleArrow,
} from "../../../theme/assets/svg";

const NewPagination = React.forwardRef((props: props, ref: any) => {
  //const [ pageCount, setPageCount] = useState();

  const [current, setCurrentPage] = useState<number>(0);
  const [forcePage, setForcePage] = useState<number>(props.getPage || 0);
  useImperativeHandle(ref, () => ({
    resetCurrentPage: () => {
      setCurrentPage(0);
      // console.log("nithin");
    },
  }));

  useEffect(() => {
    setForcePage(props.getPage || 0);
    setCurrentPage(props.getPage || 0);
  }, [props.getPage]);

  function jump(i: any) {
    // console.log(current,"Oidsidosidoiasidosi");
    let newPage = current + i;
    // if (newPage >= props.totalNoOfPage) {
    //   newPage = props.totalNoOfPage - 1;
    // }else if (newPage < 0) {
    //   newPage = 0;
    // }
    if(newPage >= props.totalNoOfPage){
      newPage = props.totalNoOfPage - 1;
    }
    if (newPage < 0) {
      newPage = 0;
    }

    setCurrentPage(newPage);
    setForcePage(newPage);
    props.handlePageChange(newPage + 1);
  }
  const handlePageClick = (e: any) => {
    const tempPage = e.selected;
    setCurrentPage(tempPage);
    props.handlePageChange(tempPage + 1);
  };

  return (
    <div className={styles.wrapper}>
      <img
        onClick={() => {
          jump(-1);
        }}
        src={props.noExtraArrowIcon ? "" : moveLeftIcon}
      ></img>
      <ReactPaginate
        previousLabel={
          props?.hideArrow ? (
            ""
          ) : (
            <img
              src={props.leftArrow ? props.leftArrow : leftSingleArrow}
              alt="<"
            />
          )
        }
        nextLabel={
          props?.hideArrow ? (
            ""
          ) : (
            <img
              src={props.rightArrow ? props.rightArrow : rightSingleArrow}
              alt=">"
            />
          )
        }
        breakLabel={".."}
        breakClassName={"break-me"}
        pageCount={props.totalNoOfPage}
        marginPagesDisplayed={props.marginPagesDisplayed || 1}
        pageRangeDisplayed={props.pageRangeDisplayed || 3}
        onPageChange={handlePageClick}
        containerClassName={styles.pagination}
        //subContainerClassName={`${styles.pages} ${styles.pagination}`}
        activeClassName={styles.active}
        forcePage={forcePage}
      />

      <img
        onClick={() => {
          jump(+1);
        }}
        src={props.noExtraArrowIcon ? "" : moveRightIcon}
      ></img>
    </div>
  );
});
NewPagination.displayName = "NewPagination";
export default NewPagination;
