import React, { useEffect, useState, useRef } from "react";
import styles from "./CollectionAgentConfigScreen.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux";
import { useNavigate } from "react-router-dom";
import InputField from "../../../generic/inputField/InputField";
import VoiceTypeDD from "./voiceType/VoiceType";
import { leftArrowLine } from "../../../../theme/assets/genericSvg";
import { dropdownDownArrow } from "../../../../theme/assets/svg";
import {
  createAgentRequest,
  editAgentRequest,
  setAgentData,
} from "../../../../redux/campaign_V2.0/agentConfiguration/actions/agentConfiguration.action";
import _ from "lodash";
import { SelectChangeEvent } from "@mui/material/Select";
import { ChipDropDown } from "./chipDropdown/ChipDropdown";
import { toast } from "react-toastify";

const allLanguage = ["English", "Hindi", "Bangla", "Tamil", "Telugu"];
const allChannels = ["Call", "WhatsApp", "SMS", "Mail"];
const allMaleVoiceType = [
  { name: "Voice 1", link: "/voice" },
  { name: "Voice 2", link: "/voice" },
  { name: "Voice 3", link: "/voice" },
];
const allFemaleVoiceType = [
  { name: "Voice 1", link: "/voice" },
  { name: "Voice 2", link: "/voice" },
  { name: "Voice 3", link: "/voice" },
];

export default function CollectionAgentConfigScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    agentToEdit,
    agentsToShow,
    agentData: { status, isNavigate: isAgentDataSaved },
  } = useSelector(
    (state: RootState) => state.campaignModuleReducer?.agentConfigData
  );

  console.log(agentsToShow, "jjjjjjj");
  const clientNameByDropdown = useSelector(
    (store: RootState) => store.campaignReducer?.campaignClientName
  );
  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );

  const { organizationDisplayName, organizationName } = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization?.organizationDetails
  );
  const teamUseCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseId
  );
  const teamData = useSelector(
    (store: RootState) => store.loginReducer.currentTeam
  );
  const campaignData = useSelector(
    (store: RootState) =>
      store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
  );
  const myPlanData: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );
  console.log(campaignData, "hello");
  const channels = campaignData?.channels ? [...campaignData?.channels] : [];
  let ind = channels.indexOf("Whatsapp");
  if (ind !== -1) {
    channels[ind] = "WhatsApp";
  }
  const defaultState = {
    maleAgentName: "",
    femaleAgentName: "",
    // clientName: clientNameByDropdown ? clientNameByDropdown : accountName || "", //clientName
    clientName:
      clientNameByDropdown?.length > 0
        ? clientNameByDropdown
        : campaignData?.accountName?.length > 0
          ? campaignData?.accountName
          : accountName,
    bankName: organizationDisplayName || "", //bankAccountName
    languages: campaignData?.language
      ? campaignData?.language
      : ["English", "Hindi"],
    channels: channels ? channels : ["Call"],
    flows: campaignData?.flowType?.maiaFlow || campaignData?.flowType || [],
    description: "",
    useCaseId: teamUseCaseId,
    product: teamData?.productName,
    productCategory: teamData?.productTypeName,
    teamId: teamData?.id
  };

  const [agentInfo, setAgentInfo] = useState<any>(defaultState);
  const [valid, setIsValid] = useState<boolean>(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name.includes("maleAgentName") || name.includes("femaleAgentName")) {
      setAgentInfo((prevAgentInfo: any) => ({
        ...prevAgentInfo,
        [name]: value.replace(/[^a-zA-Z ]/g, ""),
      }));
    } else if (name.includes("clientName") || name.includes("bankName")) {
      setAgentInfo((prevAgentInfo: any) => ({
        ...prevAgentInfo,
        [name]: value.replace(/[^a-zA-Z0-9.\- ]/g, ""),
      }));
    } else {
      setAgentInfo((prevAgentInfo: any) => ({
        ...prevAgentInfo,
        [name]: value,
      }));
    }
  };

  const handleDropDownChange = (value: string | string[], name: string) => {
    setAgentInfo((prevAgentInfo: any) => ({
      ...prevAgentInfo,
      [name]: value,
    }));
  };

  const handleInputValidation = () => {
    const {
      languages,
      channels,
      description,
      maleAgentName,
      femaleAgentName,
      ...agentInputs
    } = agentInfo;

    const hasEmptyValues = Object.values(agentInputs).some(
      (value) => typeof value === "string" && value.trim() === ""
    );

    if (
      _.isEqual(defaultState, agentInfo) ||
      hasEmptyValues ||
      !languages.length ||
      !channels.length
    ) {
      return false;
    }
    if (
      agentsToShow?.toLowerCase() === "female" &&
      femaleAgentName.trim() === ""
    ) {
      return false;
    } else if (
      agentsToShow?.toLowerCase() === "male" &&
      maleAgentName.trim() === ""
    ) {
      return false;
    } else if (agentsToShow?.toLowerCase() === "both") {
      if (maleAgentName.trim() === "" || femaleAgentName.trim() === "")
        return false;
    }
    if (status === "loading") {
      return false;
    }
    return true;
  };

  const handleSaveAgent = () => {
    if (myPlanData?.isStrategyToShow === true) {
      let data: any = {};
      data = { ...data, ...agentInfo };
      data = { ...data, type: agentsToShow?.toLowerCase(), ...agentInfo };
      if (agentToEdit !== null) {
        data["agentId"] = agentToEdit["agentId"];
        const { clientName, ...dataToSend } = data;
        dispatch(editAgentRequest(dataToSend));
      } else {
        console.log("hey", data, agentInfo);
        dispatch(createAgentRequest(data));
      }
    } else {
      toast.error("You don't have Valid Plan ");
    }
  };

  // useEffect(() => {
  //   setAgentInfo({
  //     ...agentInfo,
  //     bankName: organizationDisplayName,
  //   });
  // }, [organizationDisplayName, organizationName]);

  useEffect(() => {
    setIsValid(handleInputValidation());
  }, [agentInfo, status, agentsToShow]);
  useEffect(() => {
    if (isAgentDataSaved) {
      if (agentToEdit) {
        return;
      }
      navigate("/campaignmanager/allagents");
      dispatch(setAgentData({ isNavigate: false }));
    }
  }, [isAgentDataSaved]);

  useEffect(() => {
    if (agentToEdit) {
      console.log("edit", agentToEdit?.clientName, agentToEdit);
      const updateBody = {
        type: agentsToShow?.toLowerCase() || "",
        maleAgentName: agentToEdit?.maleAgentName || "",
        femaleAgentName: agentToEdit?.femaleAgentName || "",
        clientName:
          agentToEdit?.clientName ||
          (clientNameByDropdown?.length > 0
            ? clientNameByDropdown
            : campaignData?.accountName?.length > 0
              ? campaignData?.accountName
              : accountName) ||
          "", //clientName
        bankName: agentToEdit?.bankName || organizationDisplayName || "", //bankAccountName
        languages: agentToEdit?.languages || campaignData?.language,
        channels: agentToEdit?.channels || channels,
        flows:
          agentToEdit?.flows ||
          campaignData?.flowType?.maiaFlow ||
          campaignData?.flowType ||
          [],
        description: agentToEdit?.description || "",
        useCaseId: agentToEdit?.useCaseId || teamUseCaseId,
        product: agentToEdit?.product || teamData?.productName,
        productCategory:
          agentToEdit?.productCategory || teamData?.productTypeName,
        teamId: agentToEdit?.teamId || teamData?.id
      };
      setAgentInfo((prev: object) => ({ ...prev, ...updateBody }));
    }
  }, [agentToEdit]);
  console.log("hhhh", agentToEdit, agentInfo);

  /*****************************  jsx  *****************************/
  return (
    <div className={styles.agentInfoWrapper}>
      <div className={`${styles.collection_wrapper}`}>
        <div className={styles.iconsonandtitle}>
          <img
            onClick={() => navigate("/campaignmanager/allagents")}
            src={leftArrowLine}
            alt="<"
            id="auto_agent_back_icon"
          />
          <p className={styles.agent_info_para}>Basic Information</p>
        </div>

        <div className={styles.agent_info_wrapper}>
          <div className={styles.agent_info_parts_one}>
            <div
              className={styles.agent_info_parts}
              style={{
                display: agentsToShow?.toLowerCase() === "female" ? "none" : "",
              }}
            >
              {agentsToShow?.toLowerCase() !== "female" ? (
                <div className={styles.agent_name_input}>
                  <InputField
                    extraClass={styles.agentInputDiv}
                    className={styles.agentInputFieldStyling}
                    type="text"
                    extraLabelClass={styles.small_text_label}
                    label={
                      agentsToShow?.toLowerCase() !== "both"
                        ? "Agent Name (Male)"
                        : "Male Agent Name"
                    }
                    placeholder="Enter Male Agent Name"
                    onChange={handleInputChange}
                    name={"maleAgentName"}
                    value={agentInfo.maleAgentName}
                    id="auto_male_agent_name_input"
                  />
                </div>
              ) : null}
            </div>
            <div className={styles.agent_info_parts}>
              {agentsToShow?.toLowerCase() !== "male" ? (
                <div className={styles.agent_name_input}>
                  <InputField
                    extraClass={styles.agentInputDiv}
                    className={styles.agentInputFieldStyling}
                    type="text"
                    extraLabelClass={styles.small_text_label}
                    label={
                      agentsToShow?.toLowerCase() !== "both"
                        ? "Agent Name (Female)"
                        : "Female Agent Name"
                    }
                    placeholder="Enter Female Agent Name"
                    onChange={handleInputChange}
                    name={"femaleAgentName"}
                    value={agentInfo.femaleAgentName}
                    id="auto_female_agent_name_input"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.agent_info_parts}>
            <InputField
              extraClass={styles.agentInputDiv}
              className={styles.agentInputFieldStyling}
              label={"Client Name"}
              extraLabelClass={styles.small_text_label}
              type="text"
              placeholder="Client Name"
              onChange={handleInputChange}
              name={"clientName"}
              value={agentInfo.clientName}
              disabled={true}
              id="auto_client_name_input"
            />
          </div>
          <div className={styles.agent_info_parts}>
            <InputField
              extraClass={styles.agentInputDiv}
              className={styles.agentInputFieldStyling}
              label={"Client Display Name"}
              extraLabelClass={styles.small_text_label}
              type="text"
              placeholder="Client Display Name"
              onChange={handleInputChange}
              name={"bankName"}
              value={agentInfo.bankName}
              id="auto_client_display_name_input"
            />
          </div>
          <div className={styles.agent_info_parts}>
            <ChipDropDown
              optionsDefaultSelected={agentInfo?.languages}
              onChange={(
                newSelectedLanguages: string[],
                dropdownName: string
              ) => handleDropDownChange(newSelectedLanguages, dropdownName)}
              label={"Agent Language(s) Supported"}
              options={allLanguage}
              disabled={true}
              extraClass={styles.MultiDropDownExtraClass}
              id="auto_flow_type_input"
              rightIcon={dropdownDownArrow}
              dropdownName="languages"
              defaultNotEditable={true}
              defaultValues={campaignData?.language}
            />
          </div>
          <div className={styles.agent_info_parts}>
            <ChipDropDown
              optionsDefaultSelected={agentInfo?.channels}
              onChange={(newSelectedChannels: string[], dropdownName: string) =>
                handleDropDownChange(newSelectedChannels, dropdownName)
              }
              label={"Channel(s)"}
              options={allChannels}
              disabled={true}
              extraClass={styles.MultiDropDownExtraClass}
              id="auto_channel_input"
              rightIcon={dropdownDownArrow}
              dropdownName="channels"
              defaultNotEditable={true}
              defaultValues={channels}
            />
          </div>
          {/* <div className={styles.agent_info_parts}>
            <VoiceTypeDD
              dropdownName="Male Agent Voice Type"
              label="Male Agent Voice Type"
              onChange={(event: SelectChangeEvent<string>) =>
                handleDropDownChange(event.target.value, "maleVoiceType")
              }
              options={allMaleVoiceType}
              optionsDefaultSelected={agentInfo?.maleVoiceType}
              disabled={true}
              extraClass={styles.MultiDropDownExtraClass}
              id="auto_mailVoiceType_input"
              rightIcon={dropdownDownArrow}
            />
          </div>
          <div className={styles.agent_info_parts}>
            <VoiceTypeDD
              dropdownName="Feale Agent Voice Type"
              label="Female Agent Voice Type"
              onChange={(event: SelectChangeEvent<string>) =>
                handleDropDownChange(event.target.value, "femaleVoiceType")
              }
              options={allFemaleVoiceType}
              optionsDefaultSelected={agentInfo?.femaleVoiceType}
              disabled={true}
              extraClass={styles.MultiDropDownExtraClass}
              id="auto_mailVoiceType_input"
              rightIcon={dropdownDownArrow}
            />
          </div> */}
        </div>
      </div>

      {/* description --------------------------- */}
      <div className={`${styles.collection_wrapper}`}>
        <InputField
          extraClass={styles.agentInputDiv}
          className={styles.agentInputFieldStyling}
          type="text"
          extraLabelClass={styles.small_text_label}
          label={"Description (optional)"}
          placeholder="This agent  has good skill"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleDropDownChange(event.target.value, "description")
          }
          value={agentInfo?.description}
          id="auto_male_agent_name_input"
        />
      </div>

      {/* button grounp */}
      <div className={styles.button__group}>
        <button
          onClick={handleSaveAgent}
          className={`${valid ? styles.confirm_button : styles.save__button}`}
          id="auto_agent_edit_confirm_btn"
        >
          Save
        </button>

        {/* <button
          onClick={() => navigate("/campaignmanager/allagents")}
          className={`${styles.confirm_button}`}
        >
          Continue
        </button> */}
      </div>
    </div>
  );
}
