import React, { useEffect, useState } from "react";
import styles from "./TeamSetUp.module.scss";
import InputContainer from "../../../../generic/inputContainer/InputContainer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../../redux";
import Button from "../../../../generic/button/Button";
import Icon from "../../../../generic/icon/Icon";
import Modal from "../../../../generic/modal/Modal";
import { HelpModal } from "../../helpModal/HelpModal";
import InviteUser from "./intiveUser/InviteUser";
import { TeamMembersTable } from "./teamMembersTable/TeamMembersTable";
import {
  teamInviteGray,
  teamInviteBlue,
  lockLight,
  teamNameEditIcon,
  teamNameCrossIcon,
  teamNameTickIcon,
  campaignPageIcon,
} from "../../../../../theme/assets/svg";
import { updateTeamData } from "../../../../../redux/signUpFlow/teamsPage/actions/teamsPage.actions";
import { getCurrentTab } from "../../../../../redux/profile/yourAccount/organizationDetails/actions/organizationDetails.actions";
import { experiencePravid } from "../../../../../redux/signUpFlow/productTour/actions";
import {
  maxLengthValidations,
  teamNameValidation,
} from "../../../../../utils/Validation";
import { setIsMaiaPopUp } from "../../../../../redux/signUpFlow/signUp/action/signUp.action";
import { getMyPlanAPIData } from "../../../../../redux/profile/billing/myPlan/actions";

export const TeamSetUp = () => {
  const [teamName, setTeamName] = useState("");
  const [isInviteValid, setIsInviteValid] = useState(false);
  const [isShowInviteModal, setIsShowInviteModal] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [showMaiaPopUp, setShowMaiaPopUp] = useState(false);
  const [isExpNowPopUp, setIsExpNowPopUp] = useState(false);
  const [isBtnClicked, setIsBtnClicked] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedTeamDetails = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.teamsPageReducer?.selectedTeamDetails
  );

  const organizationInfo = useSelector(
    (store: RootState) => store?.profile?.yourAccountReducer?.organization
  );

  const loginUserId = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.userDetail?._id
  );

  const accountId = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo?.userDetail?.accountDetails?.[0]?.id
  );

  const loginUserDetail = useSelector(
    (store: RootState) => store.loginReducer?.userData
  );

  const isSignUpFlow = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo.accountDetails?.[0].isSignUpFlow
  );

  const handleTeamNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTeamName(event?.target?.value.replace(/[^a-zA-Z0-9 _]/g, ""));
    setIsFocused(true);
    setIsBtnClicked(false);
  };

  useEffect(() => {
    if (selectedTeamDetails?.teamName?.length > 0) {
      setTeamName(
        selectedTeamDetails?.teamName ? selectedTeamDetails?.teamName : ""
      );
    }
  }, [selectedTeamDetails]);

  useEffect(() => {
    setIsInviteValid(handleInviteValid());
  }, [organizationInfo]);

  useEffect(() => {
    dispatch(getMyPlanAPIData({ accountId: accountId }));
  }, [accountId]);

  const handleInviteValid = () => {
    if (!isSignUpFlow) {
      return true;
    }
    if (
      Object.keys(organizationInfo?.organizationDetails)?.includes(
        "gstNumber"
      )
    ) {
      // if (organizationInfo?.organizationDetails?.kycDocPath?.length > 0) {
      return true;
      // }
      //return false;
    } else {
      return false;
    }
  };

  const handleIconClick = () => {
    if (isReadOnly) {
      setIsReadOnly(false);
      scrollToBottom("auto_teamName");
      setIsFocused(false);
    } else if (teamNameValidation(teamName).isValid) {
      //updateApi
      if (selectedTeamDetails?.teamName !== teamName) {
        dispatch(
          updateTeamData({
            id: selectedTeamDetails?.id,
            teamName: teamName.slice(0, 100),
            userId: loginUserId,
            accountId: accountId,
            isNameUpdate: true,
          })
        );
      }
      setIsReadOnly(true);
      setIsFocused(false);
      setIsBtnClicked(false);
    } else {
      setIsReadOnly(true);
      setIsFocused(false);
      setIsBtnClicked(false);
      setTeamName(selectedTeamDetails?.teamName);
    }
  };

  const handleShowExperienceModal = (data: boolean) => {
    dispatch(experiencePravid(data));
  };

  const scrollToBottom = (id: any) => {
    const foo: any = document.getElementById(id);
    foo.value = teamName.slice(0, 100);
    foo.focus();
    foo.setSelectionRange(foo.value.length, foo.value.length);
    setIsBtnClicked(true);
  };

  return (
    <div className={styles.teamSetUpWrapper}>
      <div className={styles.teamSetUpTopDiv}>
        <div className={styles.teamHeading}>Team Setup</div>
        <div className={styles.teamInfoDiv}>
          <div className={styles.teamInfoSubDiv}>
            <InputContainer
              label="Team Name"
              inputType="text"
              inputPlaceholder="Team Name"
              inputValue={teamName.slice(0, 100)}
              inputName="teamName"
              inputOnChange={handleTeamNameChange}
              // extraInputClass={styles.inputDivStyling}
              extraLabelClass={styles.inputLabelStyling}
              extraInputClass={`${styles.inputDivStyling} ${isReadOnly && styles.readOnlyStyling
                }`}
              extraUserInputClass={`${styles.inputMainDivStyling} ${isReadOnly && styles.readOnlyStyling
                } ${isBtnClicked ? styles.caretStyling : styles.caretStylingBlack
                }`}
              showLabel={true}
              readOnly={isReadOnly}
              inputIconRight={
                isReadOnly
                  ? teamNameEditIcon
                  : !teamNameValidation(teamName).isValid || !isFocused
                    ? teamNameCrossIcon
                    : teamNameTickIcon
              }
              onClickRightIcon={handleIconClick}
              // isFocusGif={isBtnClicked}
              extraRightIconClass={styles.extraRightIconClass}
              errorMsg={
                isFocused
                  ? !teamNameValidation(teamName).isValid
                    ? teamNameValidation(teamName).errors.message
                    : !maxLengthValidations(teamName, "Team Name", 100).isValid
                      ? maxLengthValidations(teamName, "Team Name", 100).errors
                        .message
                      : ""
                  : ""
              }
              inputOnFocus={() => {
                setIsFocused(true);
              }}
              inputOnBlur={() => setIsFocused(false)}
            />

            <div className={styles.productDiv}>
              <div className={styles.productLabelStyling}>
                Product and Usecase
              </div>
              <div className={styles.productStyling} id="auto_product_usecase">
                {selectedTeamDetails?.productTypeName}&nbsp;|&nbsp;&nbsp;
                {selectedTeamDetails?.useCaseName}
              </div>
            </div>
          </div>
          {process.env.REACT_APP_ENV === "production" && !isInviteValid ? (
            <div
              className={styles.disabledBtnDiv}
              onClick={() => {
                setShowMaiaPopUp(true);
                dispatch(setIsMaiaPopUp(true));
              }}
            >
              <Button
                dataTest="auto_invite_btn_disabled"
                text="Invite Members"
                image_src_left={teamInviteGray}
                extraClass={styles.disabledInviteBtnStyling}
                id="auto_invite_btn_disabled"
              />
              <Icon
                img_src={lockLight}
                extraClass={styles.iconDisabledStyling}
              />
            </div>
          ) : (
            <div className={styles.btnDiv}>
              <Button
                dataTest="auto_invite_btn_enabled"
                text="Invite Members"
                image_src_left={teamInviteBlue}
                extraClass={styles.enabledInviteBtnStyling}
                id="auto_invite_btn_enabled"
                onClick={() => setIsShowInviteModal(true)}
              />
            </div>
          )}
        </div>
      </div>
      <TeamMembersTable />
      <Modal
        shown={isShowInviteModal}
        close={() => setIsShowInviteModal(false)}
        extraClassStyleModalContent={styles.modalContentStyling}
      >
        <InviteUser
          closePopup={() => {
            setIsShowInviteModal(false);
          }}
        />
      </Modal>
      {showMaiaPopUp && !loginUserDetail?.isInvited && (
        <HelpModal
          show={showMaiaPopUp}
          modalIcon={campaignPageIcon}
          isClose={true}
          close={() => {
            setShowMaiaPopUp(false);
            dispatch(setIsMaiaPopUp(false));
          }}
          extraContentClass={styles.extraContentInviteStyling}
          extraClassModalBackDrop={styles.extraClassInviteModalBackDrop}
          extraModalStyling={styles.extraModalStylingInvite}
          extraContentCloseClass={styles.extraContentCloseClassInvite}
          extraClassModalStyling={styles.extraClassModalStylingInvite}
          modalContent={
            <>
              <div className={styles.inviteContentTextDiv}>
                Oops! This content will be unlocked once you complete your
                profile setup. In the meantime, you can explore other features
                or finish setting up your organisation details.
              </div>
              <div className={styles.inviteContentBtnDiv}>
                <Button
                  text="Explore Pravid"
                  extraClass={`${styles.inviteExploreBtnStyling} ${styles.inviteBtnStyling}`}
                  onClick={() => {
                    setShowMaiaPopUp(false);
                    dispatch(setIsMaiaPopUp(false));
                    setIsExpNowPopUp(true);
                    handleShowExperienceModal(true);
                  }}
                  id="auto_team_explore_btn"
                />
                <Button
                  text="Complete Account Setup"
                  extraClass={`${styles.inviteSetUpBtnStyling} ${styles.inviteBtnStyling}`}
                  onClick={() => {
                    setShowMaiaPopUp(false);
                    dispatch(setIsMaiaPopUp(false));
                    dispatch(getCurrentTab("Organization"));
                    navigate("/profile/your-account");
                  }}
                  id="auto_team_setup_btn"
                />
              </div>
            </>
          }
        />
      )}
    </div>
  );
};
