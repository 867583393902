import React from "react";
import styles from "./ToolTip.module.scss";
import { infoIcon } from "../../../theme/assets/profileSectionSvg";
import { infoLightIcon } from "../../../theme/assets/svg";

interface ToolTipProps {
  name?: string;
  about?: string;
  extraElement?: JSX.Element;
  extraClass?: any;
  extraTextStylingClass?: any;
  extraAboutStylingClass?: any;
  extraTextDivStylingClass?: any;
  customIcon?: boolean;
  hideIcon?: string;
  id?: string;
}

const ToolTip = ({
  name,
  about,
  extraClass,
  extraTextStylingClass,
  extraAboutStylingClass,
  extraTextDivStylingClass,
  customIcon,
  hideIcon,
  id,
}: ToolTipProps) => {
  return (
    <div className={extraClass ? extraClass : styles.toolTip}>
      {customIcon ? (
        <img
          src={infoLightIcon}
          alt="questIcon"
          className={styles.IconStyling}
        />
      ) : (
        <img
          src={infoIcon}
          alt="questIcon"
          className={`${styles.IconStyling} ${hideIcon ? hideIcon : ""}`}
        />
      )}
      <span
        className={`${
          extraTextDivStylingClass ? extraTextDivStylingClass : null
        } ${styles.toolTipText}`}
      >
        <div
          className={`${extraTextStylingClass ? extraTextStylingClass : null} ${
            styles.toolTipTextDiv
          }`}
        >
          <p className={styles.toolTipTextHead}>{name} </p>
          <p
            className={
              extraAboutStylingClass
                ? extraAboutStylingClass
                : styles.toolTipTextAbout
            }
            data-testid="tool-tip"
            id={id ? id : ""}
          >
            {about}
          </p>
        </div>
      </span>
    </div>
  );
};

export default ToolTip;
