import { takeLatest } from "redux-saga/effects";
import actionTypes from "../productPage.actionTypes";
import {
  getAllDomainsDataAPIWorker,
  getAllProductsDataApiWorker,
  getAllProductTypesDataApiWorker,
  getAllUseCasesDataAPIWorker,
  updateProductCategoryAPIWorker,
  createProductTeamAPIWorker,
} from "./productPage.saga";

export default function* productPageDataWatcher(): any {
  yield takeLatest(
    actionTypes.GET_ALL_DOMAIN_DATA_API_REQUEST,
    getAllDomainsDataAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_ALL_PRODUCT_DATA_API_REQUEST,
    getAllProductsDataApiWorker
  );
  yield takeLatest(
    actionTypes.GET_ALL_PRODUCT_TYPE_DATA_API_REQUEST,
    getAllProductTypesDataApiWorker
  );
  yield takeLatest(
    actionTypes.GET_ALL_USE_CASE_DATA_API_REQUEST,
    getAllUseCasesDataAPIWorker
  );
  yield takeLatest(
    actionTypes.UPDATE_PRODUCT_CATEGORY_REQUEST,
    updateProductCategoryAPIWorker
  );
  yield takeLatest(
    actionTypes.CREATE_PRODUCT_PORTFOLIO_TEAM_REQUEST,
    createProductTeamAPIWorker
  );
}
