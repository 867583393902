import moment from "moment";
import React from "react";

import "./RenderInput.scss";

interface props {
  default: string;
}

export default function RenderInput(props: any) {
  console.log("pppppp", props.value);
  /* handlers -------- */
  const handleFiledClick = () => {
    const div = document.querySelector(
      ".calender-inputRender > .MuiInputAdornment-root > button"
    ) as HTMLElement;
    // console.log(props.value);
    div?.click();
  };
  return (
    <>
      {" "}
      <div className="calender-inputRender" onClick={handleFiledClick}>
        {props.InputProps?.endAdornment}
        <div className="display-area" ref={props.inputRef}>
          <span className="display-area__title">PTP Date</span>
          <span className="display-area__separator">{": "}</span>
          <span className="display-area__value">
            {props.value === "-"
              ? ""
              : moment(props.value).format("Do MMM YYYY")}
          </span>
        </div>
      </div>
    </>
  );
}
