import React, { useEffect, useRef, useState } from "react";
import styles from "./ProductCard.module.scss";
import PravidIcons from "../../../../../generic/pravidIcons/PravidIcons";
import Icon from "../../../../../generic/icon/Icon";
import Button from "../../../../../generic/button/Button";
import { ToggleComponent } from "../../../../../generic/toggleComponent/ToggleComponent";

interface ProductCardProps {
  productData: any;
  productIndex?: number;
  onClickProduct?: any;
  isSelected?: string;
  isUseCase?: boolean;
  onClickCategory?: any;
  isSelectedCategory?: string;
}

export const ProductCard = ({
  productData,
  productIndex,
  onClickProduct,
  isSelected,
  isUseCase,
  onClickCategory,
  isSelectedCategory,
}: ProductCardProps) => {
  // const [isShown, setIsShown] = useState(false);

  return (
    <div
      className={`${isUseCase ? styles.useCaseCardWrapper : ""} ${
        styles.productCardWrapper
      }
      } ${
        isSelected === productData?.type ||
        isSelected === productData?.useCasename
          ? styles.productCardSelectedDiv
          : ""
      }`}
      id={`auto_product_${productIndex}`}
      onClick={onClickProduct}
      key={productIndex}
    >
      <div className={styles.productCardTopDiv}>
        <div className={styles.productIconDiv}>
          {" "}
          <PravidIcons
            altText={"icon"}
            activeIcon={productData?.icon}
            extraClass={styles.productIconStyling}
            id={`auto_${
              isUseCase ? productData?.type : productData?.useCasename
            }_icon`}
          />
        </div>

        {/* <Icon img_src={productData.icon} /> */}
        <div
          className={styles.productCardName}
          id={`auto_${
            isUseCase ? productData?.useCasename : productData?.type
          }`}
        >
          {isUseCase ? productData?.useCasename : productData?.type}
        </div>
      </div>
      <div
        className={
          isUseCase ? styles.useCaseDescDiv : styles.productCardDescDiv
        }
        id={`auto_desc_${
          isUseCase ? productData?.useCasename : productData?.type
        }`}
      >
        {isUseCase ? productData?.usecaseDiscription : productData?.description}
        {/* {productData.desc.length < 51 ? (
          productData.desc
        ) : (
          <span>
            <span>{productData.desc?.slice(0, 51)}</span>
            <span
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
            >
              {isShown ? proproductData.desc?.slice(0, 51) : "...see more"}
            </span>
          </span>
        )} */}
      </div>
      {/* {console.log("data", productData?.category)} */}
      {productData?.category ? (
        <div id={`auto_product_keys`} className={styles.productKeysDiv}>
          <ToggleComponent
            options={["secured", "unsecured"]}
            outSideSelected={productData?.category}
            getSelectedData={onClickCategory}
          />
        </div>
      ) : (
        ""
      )}{" "}
    </div>
  );
};
