import React, { useEffect, useState } from "react";
import Modal from "../../../generic/modal/Modal";
import style from "./CustomIntegration.module.scss";
import {
  closeIntegrationIcon,
  // customIntegrationAPI,
} from "../../../../theme/assets/svg/integration_V2.0/index";
import Icon from "../../../generic/icon/Icon";
import Button from "../../../generic/button/Button";
import { CustomIntegrationInputScreen } from "./customIntegrationInputScreen/CustomIntegrationInputScreen";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { clearIntegration } from "../../../../redux/integration_V2.0/allIntegrations/actions/allIntegrations.actions";
import {
  getSecretAPIDetails,
  clearCustomIntegrationData,
} from "../../../../redux/integration_V2.0/customIntegration/actions/customIntegration.actions";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";

interface Props {
  shown?: boolean;
  close?: any;
  integrationIcon?: string;
}
function CustomIntegration({ shown, close, integrationIcon }: Props) {
  const dispatch = useDispatch();

  const selectedIntegrationCustomAPI = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.selectedIntegrationData
  );

  const isLoadingDetails = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.customIntegrationReducer
        ?.isLoadingSecretApiData
  );

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const [generateApiCredential, setGenerateApiCredential] = useState(false);

  const onClickGenerateApiCredential = () => {
    dispatch(
      getSecretAPIDetails({
        accountId: accountId,
        integrationListId: selectedIntegrationCustomAPI?.id,
        regenerate: false,
      })
    );
  };

  useEffect(() => {
    if (
      selectedIntegrationCustomAPI?.integrationDetails &&
      Object.keys(selectedIntegrationCustomAPI?.integrationDetails)?.length > 0
    ) {
      setGenerateApiCredential(true);
    }
  }, [selectedIntegrationCustomAPI]);

  const handleCloseScreen = () => {
    setGenerateApiCredential(false);
    dispatch(clearIntegration());
    dispatch(clearCustomIntegrationData());
    close();
  };

  return (
    <div>
      <Modal
        shown={shown}
        close={handleCloseScreen}
        extraClassStyleModalBackDrop={style.integrationModalStyling}
        extraClassStyleModalContent={style.integrationModalContentStyling}
      >
        <div className={style.customIntegrationWrapper}>
          <div className={style.customHeading}>
            <div className={style.customLeftHeaderDiv}>
              {/* <Icon
                img_src={customIntegrationAPI}
                extraClass={style.headerIconStyling}
              /> */}
              <PravidIcons
                activeIcon={integrationIcon}
                extraClass={style.headerIconStyling}
              />
              <div className={style.headerTextStyling}>
                Custom Integration API
              </div>
            </div>
            <div className={style.customRightHeaderDiv}>
              <div className={style.closeIconStyling}>
                <Icon
                  img_src={closeIntegrationIcon}
                  extraClass={style.iconCloseStyling}
                  onClick={handleCloseScreen}
                />
              </div>
            </div>
          </div>
          <div className={style.paracontain}>
            <span>
              We have created awesome APIs for you to push &nbsp;
              <a
                href={"https://help.pravid.io/docs"}
                target="_blank"
                rel="noreferrer"
                className={style.linkStyling}
              >
                allocation and payments data
              </a>
              . Generate credentials to start using these APIs. Check our{" "}
              <a
                href={"https://help.pravid.io/docs"}
                target="_blank"
                rel="noreferrer"
                className={style.linkStyling}
              >
                API Integrations documentation
              </a>
              &nbsp;to know more.
            </span>
          </div>
          {!generateApiCredential && (
            <div className={style.buttonContainer}>
              <Button
                text={
                  isLoadingDetails
                    ? "Generating API Credentials"
                    : "Generate API Credentials"
                }
                extraClass={`${style.activeBtnStyle} ${style.buttonStyling} ${
                  isLoadingDetails ? style.inActiveBtnStyle : ""
                }`}
                onClick={onClickGenerateApiCredential}
              />
            </div>
          )}
          {generateApiCredential && (
            <CustomIntegrationInputScreen onClickClose={handleCloseScreen} />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default CustomIntegration;
