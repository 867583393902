import React, { useEffect, useState } from "react";
import style from "./ChannelContainer.module.scss";
import {
  call,
  whatsApp,
  sms,
  mail,
  highlightcall,
  highlightmail,
  highlightsms,
  highlightwhatsApp,
  humanHandOff,
  humanHandOffHighlight
} from "../../../../../theme/assets/svg/strategySvg";
import { useDrag } from "react-dnd";
import {selectChannel} from "../../../../../redux/strategy/nudgeCreation/actions"
import { useDispatch } from "react-redux";

function ChannelContainer() {
  const [selectedChannel, setSelectedChannel] = useState("");
  const dispatch = useDispatch()
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "channel",
      item: { channel: selectedChannel },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [selectedChannel]
  );
  const channel = [
    { name: "whatsApp", img: whatsApp, activeImg: highlightwhatsApp },
    { name: "call", img: call, activeImg: highlightcall },
    {
      name: "human",
      img: humanHandOff, activeImg: humanHandOffHighlight
    },
    { name: "sms", img: sms, activeImg: highlightsms },
    {
      name: "mail",
      img: mail,
      activeImg: highlightmail,
    },

  ];
 
  // for updating in redux channnel 
  useEffect(
    () => {
      selectedChannel?.length && dispatch(selectChannel(selectedChannel))
    },[selectedChannel]
  )

  const handleOnclick = (data: string) => {
    if (data === selectedChannel) {
      setSelectedChannel("");
    } else {
      setSelectedChannel(data);
    }
  };
  return (
    <div>
      <div className={style.channelContainerout} id="auto_channelContainerout">
        {channel?.map((e: any, i: number) => {
          return (
            <img
              ref={selectedChannel === e.name ? drag : null}
              onClick={() => handleOnclick(e?.name)}
              key={i}
              style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: "move",
              }}
              src={selectedChannel === e.name ? e?.activeImg : e?.img}
              id={`auto_selectedChannel_${i}`}
            ></img>
          );
        })}
      </div>
    </div>
  );
}

export default ChannelContainer;
