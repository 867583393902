import React, { useEffect, useState } from "react";
import Icon from "../../../generic/icon/Icon";
import styles from "./ScriptConfigScreen.module.scss";
import { arrowBlueIcon } from "../../../../theme/assets/svg/campaign_v3/index";
import { useNavigate } from "react-router-dom";
import {
  setVariation,
  setMindMapData,
} from "../../../../redux/strategy/nudgeCreation/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { maiaFlow } from "../../../../theme/pdfs/index";
//import { maiaFlow } from "../../../../theme/campaign_v3";

interface Variation {
  name: string;
  data: {
    header: string;
    slot: string;
    responses: {
      subHeader: string;
      message: string;
    }[];
  }[];
}

export const ScriptConfigScreen = () => {
  const variations: Variation[] = [
    {
      name: "Credit Score",
      data: [
        {
          header: "Payment Due Reminder",
          slot: "Slot 1",
          responses: [
            {
              subHeader: "Main Response",
              message:
                " I’m {agent_name} from{housing_finance}. Your EMI of {emi_amount}Rs is still not paid inspite of multiple reminders for {dpd_days}days. Will you be able to make the payment within next 7 days to avoid Loan Recall Notice (LRN)?",
            },
            {
              subHeader: "Alternate Response",
              message:
                " This call is from {housing_finance}, about  your EMI of {emi_amount} Rs which is still not paid inspite multiple remainder for {dpd_days} days. Will you be able to make the payment within next 7 days to avoid Loan Recall Notice (LRN)? ",
            },
            {
              subHeader: "Short Response",
              message:
                " May I know if you can make the payment within next 7 days to avoid Loan Recall Notice (LRN)?",
            },
          ],
        },
        {
          header: "Counselling",
          slot: "Slot 2",
          responses: [
            {
              subHeader: "Main Response",
              message:
                " I’m {agent_name} from{housing_finance}. Your EMI of {emi_amount}Rs is still not paid inspite of multiple reminders for {dpd_days}days. Will you be able to make the payment within next 7 days to avoid Loan Recall Notice (LRN)?",
            },

            {
              subHeader: "Alternate Response",
              message:
                " This call is from {housing_finance}, about  your EMI of {emi_amount} Rs which is still not paid inspite multiple remainder for {dpd_days} days. Will you be able to make the payment within next 7 days to avoid Loan Recall Notice (LRN)? ",
            },
            {
              subHeader: "Short Response",
              message:
                " May I know if you can make the payment within next 7 days to avoid Loan Recall Notice (LRN)?",
            },
          ],
        },
      ],
    },
    {
      name: "Late Fees",
      data: [
        {
          header: "Payment Due Reminder",
          slot: "Slot 1",
          responses: [
            {
              subHeader: "Main Response",
              message:
                " I’m {agent_name} from{housing_finance}. Your EMI of {emi_amount}Rs is still not paid inspite of multiple reminders for {dpd_days}days. Will you be able to make the payment within next 7 days to avoid Loan Recall Notice (LRN)?",
            },
          ],
        },
        {
          header: "Counselling",
          slot: "Slot 2",
          responses: [
            {
              subHeader: "Main Response",
              message:
                " I’m {agent_name} from{housing_finance}. Your EMI of {emi_amount}Rs is still not paid inspite of multiple reminders for {dpd_days}days. Will you be able to make the payment within next 7 days to avoid Loan Recall Notice (LRN)?",
            },
          ],
        },
      ],
    },
    { name: "Bounce Charges", data: [] },
    { name: "Credit Bureau Reporting", data: [] },
    { name: "Service Suspension Warning", data: [] },
  ];
  const colors = ["#FEE69D", "#FFD5D5", "#DDFFE1"];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { variation, mindMap } = useSelector(
    (state: RootState) => state.strategyModuleReducer?.nudgeReducer
  );
  console.log("scriptData", mindMap)
  const [selectedVariation, setSelectedVariation] = useState<Variation>();

  const handleSelectVariation = (data: any) => {
    dispatch(setVariation(data));
  };

  useEffect(() => {
    if (!mindMap?.show) {
      if (variation && Object.keys(variation).length === 0) {
        dispatch(setVariation(variations?.[0]));
      }
    }
  }, []);


  useEffect(() => {
    setSelectedVariation(variation);
  }, [variation]);

  return (
    <div className={styles.scriptConfigScreenWrapper}>
      <div className={styles.scriptConfigHeader}>
        <div
          className={styles.textStyling}
          onClick={() => {
            if (mindMap?.show) {
              navigate("/strategy/scriptselection");
              dispatch(setMindMapData({ show: false, scriptData: {} }));
            } else {
              navigate("/strategy/nudgecreation");
            }
          }}
        >
          Back
        </div>
        {!mindMap?.show && (
          <div className={styles.variationsDiv}>
            {variations?.map((variation: any, index: number) => {
              return (
                <div
                  className={`${styles.textStyling} ${selectedVariation &&
                    Object.keys(selectedVariation) &&
                    selectedVariation?.name === variation.name
                    ? styles.greenBgStyling
                    : styles.grayBgStyling
                    }`}
                  key={index}
                  onClick={() => handleSelectVariation(variation)}
                >
                  {variation?.name}
                </div>
              );
            })}
          </div>
        )}
      </div>

      {mindMap?.show ? (
        // <img src={maiaFlow} width="100%" height="100%" />
        <iframe src={mindMap?.scriptData?.flowDiagramURL === "dummy" ? maiaFlow : mindMap?.scriptData?.flowDiagramURL} width="100%" height="100%"></iframe>
      ) : selectedVariation && Object.keys(selectedVariation).length > 0 ? (
        <div className={styles.slotsFlowDiv}>
          {selectedVariation?.data?.map((info: any, index: number) => {
            return (
              <>
                <div key={index} className={styles.responseBox}>
                  <div className={styles.headerStyling}>{info.header}</div>
                  <div className={styles.infoDivStyling}>
                    <div className={styles.slotStyling}>{info.slot}</div>
                    {info?.responses?.map((data: any, index: number) => {
                      return (
                        <div
                          className={styles.responseDiv}
                          key={index}
                          style={{ backgroundColor: colors[index] }}
                        >
                          <div className={styles.resHeaderStyling}>
                            {data.subHeader}
                          </div>
                          <div className={styles.resTextStyling}>
                            {data.message}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {index % 2 === 0 && (
                  <Icon
                    img_src={arrowBlueIcon}
                    extraClass={styles.arrowIconStyling}
                  />
                )}
              </>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
