export default {
  DASHBOARD_API_DATA_SUCCESS: "DASHBOARD_API_DATA_SUCCESS",
  DASHBOARD_API_DATA_FAILURE: "DASHBOARD_API_DATA_FAILURE",
  DASHBOARD_API_DATA_REQUEST: "DASHBOARD_API_DATA_REQUEST",
  DASHBOARD_API_CARD_DATA_REQUEST : "DASHBOARD_API_CARD_DATA_REQUEST",
  DASHBOARD_API_CARD_DATA_SUCCESS : "DASHBOARD_API_CARD_DATA_SUCCESS",
  DASHBOARD_API_CARD_DATA_FAILURE : "DASHBOARD_API_CARD_DATA_FAILURE",
  SET_WEBSOCKET_ADMIN_DATA:  "SET_WEBSOCKET_ADMIN_DATA",
  // UPDATE_DASHBOARD_API_CARD_DATA_SUCCESS: "UPDATE_DASHBOARD_API_CARD_DATA_SUCCESS"
  PERFORMANCE_TABLE_DATA_REQUEST:"PERFORMANCE_TABLE_DATA_REQUEST",
  PERFORMANCE_TABLE_DATA_SUCCESS:"PERFORMANCE_TABLE_DATA_SUCCESS",
  PERFORMANCE_TABLE_DATA_FAILURE:"PERFORMANCE_TABLE_DATA_FAILURE"
};
