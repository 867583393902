import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../generic/loader/Loader";
import DonutPieChartComponent from "../donutPieChartComponent/DonutPieChartComponent";

import styles from "./ChartView.module.scss";

// interface props {
//   default: string;
// }

export default function ChartView(props: any) {
  const chartData = useSelector(
    (state: any) => state?.agentDesktopReducer?.dashboardReducer?.dashboardChartDetails
  );
  
  return (
    <>
      {props.chartDetails &&
        props.chartDetails.map((each: any, i: number) => {
          return (
            <div
              className={styles.chartViewComponentWrapper}
            //   style={{
            //     width: `${each?.chartData?.chartStyle?.width}`,
            //     height: `${each?.chartData?.chartStyle?.height}`,
            //   }}
              key={i}
            >
              <div className={styles.chartViewHeader}>
                {each?.displayName}
              </div>
              <div className={styles.chartViewBody}>
                {!props.isLoading ?
                  <DonutPieChartComponent
                  chartDetails={each?.chartData}
                  donutChartDetails={each?.chartData?.keys}
                  data={chartData}
                />
                : <Loader />
                }
              </div>
            </div>
          );
        })}
    </>
  );
}
