import React from "react";
import styles from "./OrgPopUp.module.scss";
import Modal from "../../../generic/modal/Modal";
import Button from "../../../generic/button/Button";
import { useNavigate } from "react-router-dom";
import { createStrategyCloseIcon } from "../../../../theme/assets/svg/strategySvg";
import { rectangle1, rectangle2 } from "../../../../theme/gif/index";
import Icon from "../../../generic/icon/Icon";
interface orgModalProps {
  show?: boolean;
  close?: CallableFunction;
  onclick?: CallableFunction
}

export const OrgPopUp = ({ show, close, onclick }: orgModalProps) => {
  const navigate = useNavigate();

  return (
    <div>
      <Modal
        shown={show}
        close={() => {
          close && close();
        }}
        extraClassStyleModalContent={styles.orgPopUpStyling}
        extraClassStyleModalBackDrop={styles.orgPopUpBgStyling}
      >
        <div className={styles.gifDivStyling}>
          <Icon img_src={rectangle1} extraClass={styles.rect1Styling} />
          <Icon img_src={rectangle2} extraClass={styles.rect2Styling} />
        </div>

        <div
          className={styles.modalInfoDiv}
          id="auto_org_modal_div"
          data-testid="org-modal"
        >
          <div className={styles.modalCloseIcon}>
            <img
              src={createStrategyCloseIcon}
              onClick={() => {
                close && close();
              }}
              className={styles.iconStyling}
              id="auto_org_modal_close_icon"
              data-testid="close-icon"
            />
          </div>
          <div
            className={styles.modalInfoHeading}
            data-testid="org-modal"
            id="auto_org_modal_div"
          >
            Congratulations!! You got 999 free credits. You can use these
            credits to initiate Calls.
          </div>

          <div className={styles.orgButtonDiv}>
            <Button
              text="Purchase Plan"
              extraClass={`${styles.buttonStyling} ${styles.planButtonStyle}`}
              onClick={() => {
                navigate("/profile/billing");
                onclick && onclick()
              }}
              id="auto_strategy_purchase_button"
            />
            <Button
              text="Create Campaign"
              extraClass={`${styles.buttonStyling} ${styles.createButtonStyle}`}
              onClick={() => {
                navigate("/campaignmanager");
                onclick && onclick()
              }}
              id="auto_strategy_campaign_button"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
