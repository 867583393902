import actionTypes from "../progressBar.actionTypes";

export function maxCompletedStep(step: number) {
  console.log("dispatchingg...", step);
  return {
    type: actionTypes.MAX_STEP_COMPLETE,
    payload: step,
  };
}

export function storeCurrentStep(currentStep: number) {
  return {
    type: actionTypes.STORE_CURRENT_STEP,
    payload: currentStep,
  };
}
