import React, { useEffect, useState } from "react";
import closeIcon from "../../../../theme/assets/svg/campaign_V2.0/closeIcon.svg";
import inProgressIcon from "../../../../theme/assets/svg/campaign_V2.0/inProgressIcon.svg";
import "./InProgressPopup.css";
import ProgressBarComponent from "../../responseRate/progressBarComponent/ProgressBarComponent";
import {
  SERVER_URL_CONNECTOR,
  SCHEDULER_URL,
} from "../../../../services/ApiRoutes";
import axios from "axios";

function InProgressPopup(props) {
  const [responceData, setResponceData] = useState();

  const cancelPopUp = () => {
    props.hide(false);
  };
  console.log(props.id);

  useEffect(() => {
    let url = `${SERVER_URL_CONNECTOR}${SCHEDULER_URL.CALLING_INFO}`;
    let obj = {
      campaignId: props.id,
      channel: props.data?.channels?.[0],
    };
    let jwtHeader = { headers: props.jwt };
    console.log("nn", jwtHeader);
    axios
      .post(url, obj, jwtHeader)
      .then((res) => {
        setResponceData(res?.data?.data);
      })
      .catch((err) => console.log(err));
  }, [props.id]);

  return (
    <div className="in-progress-popup-wrapper">
      <div className="in-progress-popup-container">
        <div className="head-container">
          <div className="head-container-heading">
            <img src={inProgressIcon} alt="progressicon" />
            <p className="heading-popup">{props.data.campaignStatus}</p>
          </div>

          <img
            className="cross-pos"
            onClick={() => cancelPopUp()}
            src={closeIcon}
            alt="cut"
          />
        </div>

        <div className="progress-area">
          <div className="progress-text">
            <p className="c-text">Connection Rate</p>
            <p className="c-text">{responceData?.connectionRate}%</p>
          </div>
          <ProgressBarComponent
            isLoading={false}
            percent={responceData?.connectionRate}
            // size={"small"}
            showInfo={false}
            extraProgress="extraProgressStyling"
            // extraClass="green-bar"
          />
        </div>
        <div className="details">
          {responceData?.rows
            ?.sort((a, b) => a?.id - b?.id)
            ?.map((e, i) => {
              return (
                <p className="para">
                  {e?.title}:
                  <span
                    className={
                      e?.title?.includes(
                        "Not Connected" || "Failed" || "Not Delivered "
                      )
                        ? "span4"
                        : e?.title?.includes("Connected" || "Delivered")
                        ? "span3"
                        : e?.title?.includes("Collected")
                        ? "span2"
                        : "span1"
                    }
                  >
                    {e?.value}
                  </span>
                </p>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default InProgressPopup;
