import { configureStore, Middleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import storage from "redux-persist/lib/storage/session";
import { persistStore, persistReducer } from "redux-persist";
import rootReducers from "./rootReducer";
import rootSaga from "./rootSaga";
import { encryptTransform } from "redux-persist-transform-encrypt";

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  key: "root",
  storage,
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_REDUX_PERSIST_ENCRYPTION_KEY as string,
    }),
  ],
};
// middleware arrays  ----------------------------------*
const allMiddleware: Middleware[] = [];
// saga middleware
allMiddleware.push(sagaMiddleware);
// redux logger middleware cosnditonally and disable consoles

if (process.env.REACT_APP_ENV != "development") {
  console.log("%c script disabled ", "color: red");

  window.console.log = () => {
    //;
  };
  window.alert = () => {
    //
  };
  allMiddleware.push(createLogger());
}
// end middleware    -----------------------------------*
const persistedReducer = persistReducer(persistConfig, rootReducers);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(...allMiddleware),

  devTools:
    process.env.REACT_APP_ENV === "development" ||
    process.env.REACT_APP_ENV === "preprod",
});

// console.info(`REDUX STORE INFO
//               immutableCheck: ${process.env.REACT_APP_ENV !== "production"}
//               serializableCheck: ${process.env.REACT_APP_ENV !== "production"}
// `);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducers>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
