import React from "react";
import styles from "./InfoToolTip.module.scss";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStylesDark = makeStyles((theme) => ({
  customTooltipSmall: {
    backgroundColor: "#B6B6B6",
    borderRadius: "1.20455px",
    color: "#fff",
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: 400,
    maxWidth: "210px",
    // width: "180px",
    // height: "45px",
    overflowWrap: "break-word",
    // marginRight: "1em",
  },
  customTooltipLarge: {
    backgroundColor: "#B6B6B6",
    borderRadius: "1.20455px",
    color: "#fff",
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: 400,
    maxWidth: "210px",
    // minWidth: "180px",
    // minHeight: "45px",
    overflowWrap: "break-word",
    // marginRight: "1em",
  },
  customArrow: {
    color: "#B6B6B6",
    fontSize: 9,
  },
}));

const useStylesLight = makeStyles((theme) => ({
  customTooltipSmall: {
    backgroundColor: "#F6F6F6",
    borderRadius: "1.20455px",
    color: "#676767",
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: 400,
    maxWidth: "210px",
    // width: "180px",
    // height: "45px",
    overflowWrap: "break-word",
    // marginRight: "1em",
  },
  customTooltipLarge: {
    backgroundColor: "#F6F6F6",
    borderRadius: "1.20455px",
    color: "#676767",
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: 400,
    maxWidth: "210px",
    // width: "180px",
    // height: "45px",
    overflowWrap: "break-word",
    // marginRight: "1em",
  },
  customArrow: {
    color: "#F6F6F6",
    fontSize: 9,
  },
}));

interface InfoToolTipProps {
  message: string;
  isError: boolean;
  position?: any;
  toolTipLarge?: boolean;
  isIconSmall?: boolean;
  isLight?: boolean;
}

export const InfoToolTip = ({
  message,
  isError,
  position,
  toolTipLarge,
  isIconSmall,
  isLight,
}: InfoToolTipProps) => {
  const classes = isLight ? useStylesLight() : useStylesDark();
  return (
    <Tooltip
      title={message}
      placement={position}
      classes={{
        arrow: classes.customArrow,
        tooltip: toolTipLarge
          ? classes.customTooltipLarge
          : classes.customTooltipSmall,
      }}
      arrow={isError ? false : true}
    >
      <div className={`${styles.infoTooltipStylingWrapper}`}>
        <div
          className={`${
            isIconSmall
              ? styles.smallInfoIcon
              : isError
              ? styles.errorIcon
              : styles.infoIcon
          } ${isIconSmall ? styles.iconStylingSmall : styles.iconStylingLarge}`}
        ></div>
      </div>
    </Tooltip>
  );
};
