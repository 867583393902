import actionTypes from "../actionTypes/login.actionTypes";

export const setLoggedInUserInfo = (value: any) => {
  // console.log("logg", value);
  return {
    type: actionTypes.SET_LOGGEDIN_USER_INFO,
    payload: value,
  };
};

export const setIsPageType = (value: any) => {
  return {
    type: actionTypes.SET_PAGE_TYPE,
    payload: value,
  };
};

export const setIsActivePageType = (value: any) => {
  return {
    type: actionTypes.SET_ACTIVE_PAGE_TYPE,
    payload: value,
  };
};

export const doLogin = (value: any) => {
  return {
    type: actionTypes.LOGIN_REQUEST,
    payload: value,
  };
};

export const getSchemaOfClientRequest = (
  { id, originalId }: Record<string, any>,
  filterObj: any
) => {
  return {
    type: actionTypes.GET_SCHEMA_OF_CLIENT_REQUEST,
    payload: { id, originalId, filterObj },
  };
};
export const updateSchemaRequest = (data: any) => {
  return {
    type: actionTypes.SET_SCHEMA_UPDATE_REQUEST,
    payload: data,
  };
};
export const directlyUpdateSchema = (data: any) => {
  return {
    type: actionTypes.SET_SCHEMA_UPDATE_SUCCESS,
    payload: data,
  };
};

export const userLogoutAction = (payload: {
  userId: string;
  refreshToken: string;
}) => {
  return {
    type: actionTypes.USER_LOGOUT,
    payload: payload,
  };
};
export const getrequestFeature = (data: any) => {
  return {
    type: actionTypes.SET_REQUEST_FEATURE_API_REQUEST,
    payload: data,
  };
};
export const setRequestFeatureState = (value: any) => {
  return {
    type: actionTypes.SET_REQUEST_FEATURE_STATE,
    payload: value,
  };
};

export const getTeamsData = (data: Record<any, string>) => {
  // console.log(data, "teams");
  return { type: actionTypes.GET_TEAM_DATA_REQUEST, payload: data };
};

export const getAllClientDetailByname = (name: string) => {
  return { type: actionTypes.GET_ALL_CLIENT_DATA_REQUEST, payload: name };
};
export const updateUserData = (data: any) => {
  return { type: actionTypes.UPDATE_USER_DATA_REQUEST, payload: data };
};
export const getUserData = (data: any) => {
  return { type: actionTypes.GET_USER_DATA_REQUEST, payload: data };
};

export const getTeamSchema = (
  type: string,
  uniqueId: string,
  teamId: string,
  isInternal: boolean
) => {
  return {
    type: actionTypes.GET_TEAM_SCHEMA_REQUEST,
    payload: { type, uniqueId, teamId, isInternal },
  };
};

export const getTeamTabs = (teamId: string) => {
  return { type: actionTypes.GET_TEAM_TAB_REQUEST, payload: { teamId } };
};

export const getTeamChannels = (teamId: string) => {
  return { type: actionTypes.GET_TEAM_CHANNEL_REQUEST, payload: { teamId } };
};

export const getSidebarModules = () => {
  return {
    type: actionTypes.GET_SIDEBAR_MODULE_REQUEST,
  };
};
export const setUserData = (data: any) => {
  return { type: actionTypes.GET_USER_DATA_SUCCESS, payload: data };
};

export const refreshTokenApiRequest = (data: {
  userId: string;
  refreshToken: string;
}) => {
  return { type: actionTypes.REFRESH_TOKEN_API_REQUEST, payload: data };
};

export const verifyInviteToken = (token: string) => {
  return { type: actionTypes.VERIFY_INVITE_REQUEST, payload: token };
};
