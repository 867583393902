import moment from "moment";
import { dates } from "../../components/moduleComponents/popupCalenderfilter/PopupCalenderfilter.interface";
import Moment from "moment";
import { format } from "path";

export function DateToLocalString(
  date: Date | undefined | string,
  format?: string
): string {
  if (date === undefined) {
    return " ";
  }
  const newDate = new Date(date);
  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${day}/${month}/${year}`;
}

export function DateStartFormat(date: Date | undefined) {
  if (date === undefined) {
    return " ";
  }
  const day = date.getDate();
  const month = date.getDate();
  const year = date.getFullYear();
  return `${year}-${month}-${day}T00:00:00`;
}
export function DateEndFormat(date: Date | undefined) {
  if (date === undefined) {
    return " ";
  }
  const day = date.getDate();
  const month = date.getDate();
  const year = date.getFullYear();
  return `${year}-${month}-${day}T23:59:59`;
}

export function lastUpdatedText(date: Date | undefined) {
  if (date === undefined) return;
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const min = date.getMinutes();
  const AMORPM = hour >= 12 ? "PM" : "AM";

  return `Last updated on ${Moment.utc(date).format("DD-MM-YYYY, hh:mma")} 
  `;
}
export function agentDatePTP(dates:Date){
  const date=new Date(dates)
  if (date === undefined) return;
  
    return date.getDate()+ " " +date.toLocaleString('default', { month: 'long' }) +" "+ date.getFullYear()
}

/**
 *
 * @param date
 * @returns {string}
 */
export function newDateAndTime(date: Date) {
  try {
    const someDt = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );
    const dtNow = new Date();
    let day = moment(date).format("DD MMM YY");
    if (
      dtNow.getFullYear() === someDt.getFullYear() &&
      dtNow.getMonth() === someDt.getMonth()
    ) {
      // console.log(dtNow.getDate(),someDt.getDate(),dtNow.getDate() ===someDt.getDate() )
      if (dtNow.getDate() === someDt.getDate())
        day = "Today"; // Today, 15:32
      else if (dtNow.getDate() - 1 === someDt.getDate()) day = "Yesterday"; //Yesterday, 13:26
    }
    const dateStr: string = day + " | " + moment(date).format("hh:mm a");
    return dateStr;
  } catch (err) {
    console.error(err, "check date");
    return "undefined";
  }
}