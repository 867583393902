import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import { UploadPicture } from "../../../../generic/uploadPicture/UploadPicture";
import InputContainer from "../../../../generic/inputContainer/InputContainer";
import { InfoToolTip } from "../../../../generic/infoToolTip/InfoToolTip";
import TextAreaInput from "../../../../generic/textareainput/TextAreaInput";
import Button from "../../../../generic/button/Button";
import styles from "./InitialWhatsAppScreen.module.scss";
import {
  maxLengthValidations,
  orgPhoneNumberValidation,
} from "../../../../../utils/Validation";
import {
  updateWhatsAppProfilePic,
  deleteWhatsAppProfilePic,
} from "../../../../../redux/integration_V2.0/whatsApp/actions";
import {
  // setBackIconClicked,
  setWhatsAppInputData,
  setWhatsAppScreen,
  setIsDataChanged,
} from "../../../../../redux/integration_V2.0/whatsApp/actions/whatsApp.actions";

import _ from "lodash";

export const InitialWhatsAppScreen = () => {
  const selectedIntegrationWA = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.selectedIntegrationData
  );

  const organizationInfo = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization.organizationDetails
  );

  const screenName = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.whatsAppReducer?.currentScreen
  );

  const userInputData = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.whatsAppReducer?.whatsAppInputData
  );

  const whatsAppProfilePicData = useSelector(
    (store: RootState) => store?.integrationReducer?.whatsAppReducer
  );

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const initialState = {
    registeredName: organizationInfo?.organizationName,
    displayName: organizationInfo?.organizationDisplayName,
    testingPhoneNo: "",
    phoneNo: organizationInfo?.phoneNumber,
    desc: "",
  };
  const dispatch = useDispatch();

  const [isImageUrl, setImageUrl] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [isFocused, setIsFocused] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [initialWhatsAppInfo, setInitialWhatsAppInfo] = useState(initialState);
  const [refreshBtn, setRefreshBtn] = useState(false);

  const hiddenFileInput = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleClickUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChangeDelete = () => {
    setRefreshBtn(true);
    setUploadError("");
    // setImageUrl("");
    if (
      selectedIntegrationWA?.integrationDetails &&
      Object.keys(selectedIntegrationWA?.integrationDetails).length > 0
    ) {
      setImageUrl("");
      setUploadError("Please Upload the Profile Picture");
    } else {
      dispatch(
        deleteWhatsAppProfilePic({
          id: accountId,
          integrationName: selectedIntegrationWA?.integrationName,
        })
      );
    }
  };

  const handleFileChangeUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRefreshBtn(true);
    if (!e.target.files || e.target.files.length === 0) {
      setUploadError("Please Select a Valid File");
      return;
    } else {
      const selectedFile: any = e.target.files[0];
      const reader = new FileReader();
      if (!selectedFile) {
        return;
      }
      // Check file type
      if (selectedFile.type !== "image/png") {
        setUploadError("Please upload a PNG image file.");
        setImageUrl("");
        return;
      }
      // setImageUrl(URL.createObjectURL(e.target.files[0]));
      reader.readAsDataURL(selectedFile);
      reader.onload = (event: any) => {
        const img: any = new Image();
        img.src = event.target.result;
        img.onload = () => {
          if (
            selectedFile.size > 5000000 ||
            img.width !== img.height ||
            img.height > 640 ||
            img.width > 640
          ) {
            setUploadError(
              "PNG Image should be of max file size 5 MB and 640x640 pixels"
            );
            setImageUrl("");
          } else {
            setUploadError("");
            // setImageUrl(event.target.result);
            dispatch(
              updateWhatsAppProfilePic({
                file: (e.target.files as FileList)[0],
                id: accountId,
                integrationName: selectedIntegrationWA?.integrationName,
              })
            );
          }
          e.target.value = "";
        };
      };
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRefreshBtn(true);
    if (e.target.name === "testingPhoneNo" || e.target.name === "phoneNo") {
      const value = e.target.value;
      setInitialWhatsAppInfo({
        ...initialWhatsAppInfo,
        [e.target.name]: value.replace(/[^0-9]/g, ""),
      });
    } else if (
      e.target.name === "registeredName" ||
      e.target.name === "displayName"
    ) {
      const value = e.target.value;
      setInitialWhatsAppInfo({
        ...initialWhatsAppInfo,
        [e.target.name]: value.replace(/[^a-zA-Z0-9.\- ]/g, ""),
      });
    }
  };

  const handleDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInitialWhatsAppInfo((prev) => {
      return { ...prev, desc: event?.target?.value };
    });
  };

  const handleCreateAccount = () => {
    setRefreshBtn(true);

    dispatch(setIsDataChanged(true));

    const data = {
      ...userInputData,
      profilePicPath: userInputData?.profilePicPath,
      registeredCompanyName: initialWhatsAppInfo?.registeredName?.slice(0, 50),
      displayName: initialWhatsAppInfo?.displayName?.slice(0, 150),
      businessPhoneNumber: initialWhatsAppInfo?.phoneNo,
      testPhoneNumber: initialWhatsAppInfo?.testingPhoneNo,
      briefAboutBusiness: initialWhatsAppInfo?.desc?.slice(0, 139),
    };
    dispatch(setWhatsAppScreen("step2"));
    dispatch(setWhatsAppInputData(data));
  };

  const handleValidation = () => {
    if (_.isEqual(initialState, initialWhatsAppInfo)) {
      return false;
    }
    if (!orgPhoneNumberValidation(initialWhatsAppInfo?.phoneNo).isValid) {
      return false;
    }
    if (
      initialWhatsAppInfo?.testingPhoneNo?.length > 0 &&
      !orgPhoneNumberValidation(initialWhatsAppInfo?.testingPhoneNo).isValid
    ) {
      return false;
    }
    if (isImageUrl === "") {
      return false;
    }
    if (whatsAppProfilePicData?.isLoadingUploadProfilePic === true) {
      return false;
    }
    if (whatsAppProfilePicData?.isLoadingDeleteProfilePic === true) {
      return false;
    }
    if (
      initialWhatsAppInfo?.registeredName?.length === 0 ||
      initialWhatsAppInfo?.displayName?.length === 0 ||
      initialWhatsAppInfo?.phoneNo?.length === 0 ||
      // initialWhatsAppInfo?.testingPhoneNo?.length === 0 ||
      initialWhatsAppInfo?.desc?.length === 0
    ) {
      return false;
    }
    return true;
  };

  // useEffect(() => {
  //   dispatch(setBackIconClicked(false));
  // }, []);

  useEffect(() => {
    setIsValid(handleValidation());
  }, [initialWhatsAppInfo, isImageUrl, whatsAppProfilePicData]);

  useEffect(() => {
    if (screenName === "") {
      setInitialWhatsAppInfo(initialState);
      setImageUrl("");
      setUploadError("");
      setIsFocused("");
      setRefreshBtn(false);
    }
  }, [screenName]);

  // To render data from GET API
  useEffect(() => {
    if (Object.keys(userInputData)?.length > 0) {
      setInitialWhatsAppInfo((prev) => {
        return {
          ...prev,
          registeredName: userInputData?.registeredCompanyName
            ? userInputData?.registeredCompanyName
            : initialWhatsAppInfo?.registeredName,
          displayName: userInputData?.displayName
            ? userInputData?.displayName
            : initialWhatsAppInfo?.displayName,
          phoneNo: userInputData?.businessPhoneNumber
            ? userInputData?.businessPhoneNumber
            : initialWhatsAppInfo?.phoneNo,
          testingPhoneNo: userInputData?.testPhoneNumber
            ? userInputData?.testPhoneNumber
            : initialWhatsAppInfo?.testingPhoneNo,
          desc: userInputData?.briefAboutBusiness
            ? userInputData?.briefAboutBusiness
            : initialWhatsAppInfo?.desc,
        };
      });
    }
  }, [userInputData]);

  useEffect(() => {
    if (userInputData?.profilePicPath) {
      if (userInputData?.profilePicPath?.length > 0) {
        setImageUrl(
          `${userInputData?.profilePicPath}`
        );
      } else {
        setImageUrl(userInputData?.profilePicPath);
        setUploadError("Please Upload the Profile Picture");
      }
    } else {
      setImageUrl("");
    }
  }, [userInputData]);

  return (
    <div>
      <div className={styles.whatsAppScreen}>
        <div>
          <UploadPicture
            channel={"whatsApp"}
            handleDeleteFunc={handleFileChangeDelete}
            handleUploadFunc={handleClickUpload}
            handleFileUploadFunc={handleFileChangeUpload}
            hiddenFileInputRef={hiddenFileInput}
            picture={isImageUrl}
            errorMsg={uploadError}
            infoMsg={
              "This is the display picture that will be visible to your customers in whatsapp.The display pic shall be constrained to size of 640x640px and 5 MB."
            }
            tooltipSize={true}
            isPicLoading={whatsAppProfilePicData?.isLoadingUploadProfilePic}
            isDeleteValid={
              // (selectedIntegrationWA?.integrationDetails &&
              //   Object.keys(selectedIntegrationWA?.integrationDetails)?.length >
              //     1) ||
              isImageUrl?.length === 0 ||
              whatsAppProfilePicData?.isLoadingUploadProfilePic ||
              whatsAppProfilePicData?.isLoadingDeleteProfilePic
            }
            isUploadValid={whatsAppProfilePicData?.isLoadingUploadProfilePic}
          />
        </div>
        <div className={styles.contentBtmDiv}>
          <div className={styles.subDiv}>
            <InputContainer
              label="Company Registered Name"
              inputType="text"
              inputPlaceholder="Company Registered Name"
              inputValue={initialWhatsAppInfo?.registeredName?.slice(0, 50)}
              inputName="registeredName"
              inputOnChange={handleInputChange}
              showLabel={true}
              showNewToolTip
              showNewToolTipPosition={"top-end"}
              showNewToolTipMsg={
                "This is the officially registered name of your company."
              }
              toolTipSize={false}
              extraInputClass={`${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${styles.inputMainDivStyling}`}
              errorMsg={
                initialWhatsAppInfo?.registeredName?.length > 0 &&
                isFocused === "registeredName"
                  ? !maxLengthValidations(
                      initialWhatsAppInfo?.registeredName,
                      "Company Registered Name",
                      50
                    ).isValid
                    ? maxLengthValidations(
                        initialWhatsAppInfo?.registeredName,
                        "Company Registered Name",
                        50
                      ).errors.message
                    : ""
                  : ""
              }
              inputOnFocus={() => setIsFocused("registeredName")}
              inputOnBlur={() => setIsFocused("")}
            />
          </div>
          <div className={styles.subDiv}>
            <InputContainer
              label="Display Name"
              inputType="text"
              inputPlaceholder="Display Name"
              inputValue={initialWhatsAppInfo?.displayName?.slice(0, 150)}
              inputName="displayName"
              inputOnChange={handleInputChange}
              showLabel={true}
              showNewToolTip
              showNewToolTipPosition={"top-end"}
              showNewToolTipMsg={
                "This is a short name for your brand that will be displayed in whatsapp profile."
              }
              toolTipSize={false}
              extraInputClass={`${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${styles.inputMainDivStyling}`}
              errorMsg={
                initialWhatsAppInfo?.displayName?.length > 0 &&
                isFocused === "displayName"
                  ? !maxLengthValidations(
                      initialWhatsAppInfo?.displayName,
                      "Display Name",
                      150
                    ).isValid
                    ? maxLengthValidations(
                        initialWhatsAppInfo?.displayName,
                        "Display Name",
                        150
                      ).errors.message
                    : ""
                  : ""
              }
              inputOnFocus={() => setIsFocused("displayName")}
              inputOnBlur={() => setIsFocused("")}
            />
          </div>

          <div className={styles.subDiv}>
            <InputContainer
              label="Business Phone Number"
              inputType="text"
              inputPlaceholder="Business Phone Number"
              inputValue={initialWhatsAppInfo?.phoneNo}
              inputName="phoneNo"
              inputOnChange={handleInputChange}
              maxLength={10}
              showLabel={true}
              showNewToolTip
              showNewToolTipPosition={"top-end"}
              showNewToolTipMsg={
                "This is the actual whatsapp number that will be used to send messages to your customers and will be displayed in whatsapp profile."
              }
              toolTipSize={true}
              extraInputClass={`${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${styles.inputMainDivStyling}`}
              errorMsg={
                initialWhatsAppInfo?.phoneNo?.length > 0
                  ? orgPhoneNumberValidation(initialWhatsAppInfo?.phoneNo)
                      .isValid
                    ? ""
                    : orgPhoneNumberValidation(initialWhatsAppInfo?.phoneNo)
                        .errors.message
                  : ""
              }
            />
          </div>
          <div className={styles.subDiv}>
            <InputContainer
              label="Test Phone Number  (Optional)"
              inputType="text"
              inputPlaceholder="Test Phone Number  (Optional)"
              inputValue={initialWhatsAppInfo?.testingPhoneNo}
              inputName="testingPhoneNo"
              inputOnChange={handleInputChange}
              maxLength={10}
              showLabel={true}
              showNewToolTip
              showNewToolTipPosition={"top-end"}
              showNewToolTipMsg={
                "This number will be used for testing purpose."
              }
              toolTipSize={false}
              extraInputClass={`${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${styles.inputMainDivStyling}`}
              errorMsg={
                initialWhatsAppInfo?.testingPhoneNo?.length > 0
                  ? orgPhoneNumberValidation(
                      initialWhatsAppInfo?.testingPhoneNo
                    ).isValid
                    ? ""
                    : orgPhoneNumberValidation(
                        initialWhatsAppInfo?.testingPhoneNo
                      ).errors.message
                  : ""
              }
            />
          </div>
        </div>
        <div className={styles.textAreaDiv}>
          <div
            className={`${styles.inputLabelStyling} ${styles.textAreaLabel}`}
          >
            {initialWhatsAppInfo?.desc &&
            initialWhatsAppInfo?.desc?.length > 0 ? (
              <>
                <div className={styles.labelDivStyling}>
                  Brief About Business
                </div>
                <InfoToolTip
                  message={
                    "This is a short text about your business displayed below your business name on whatsapp profile."
                  }
                  isError={false}
                  position="top-end"
                  toolTipLarge={true}
                />
              </>
            ) : null}
          </div>
          <div
            className={`${styles.textAreaInputDivStyling} ${
              maxLengthValidations(
                initialWhatsAppInfo?.desc,
                "Brief About Business",
                139
              ).errors.message?.length > 0 && isFocused === "desc"
                ? styles.errorBorderStyling
                : ""
            }`}
          >
            <TextAreaInput
              extraTextClass={`${styles.textInputDescDivStyling}`}
              extraClass={styles.inputDescStyling}
              handleChange={handleDescChange}
              value={initialWhatsAppInfo?.desc?.slice(0, 139)}
              placeholder="Brief About Business"
              inputOnFocus={() => setIsFocused("desc")}
              inputOnBlur={() => setIsFocused("")}
              name="desc"
            />
            {initialWhatsAppInfo?.desc?.length === 0 && (
              <InfoToolTip
                message={
                  "This is a short text about your business displayed below your business name on whatsapp profile."
                }
                isError={false}
                position={"top-end"}
                toolTipLarge={true}
                isIconSmall={false}
              />
            )}
          </div>
          <div className={styles.errorMsgStyling}>
            {initialWhatsAppInfo?.desc?.length > 0 && isFocused === "desc"
              ? !maxLengthValidations(
                  initialWhatsAppInfo?.desc,
                  "Brief About Business",
                  139
                ).isValid
                ? maxLengthValidations(
                    initialWhatsAppInfo?.desc,
                    "Brief About Business",
                    139
                  ).errors.message
                : ""
              : ""}
          </div>
        </div>
        <div className={styles.whatsAppbtnDiv}>
          <Button
            text="Next"
            extraClass={`${styles.buttonStyling} ${
              isValid ? styles.activeBtnStyling : styles.inActiveBtnStyling
            }`}
            onClick={handleCreateAccount}
          />
        </div>
      </div>
      {/* {selectedIntegrationWA?.integrationDetails &&
      Object.keys(selectedIntegrationWA?.integrationDetails).length > 1 &&
      isImageUrl === "" ? (
        <div className={styles.wpErrorMsgStyling}>
          Please Upload the Profile Picture
        </div>
      ) : (
        ""
      )} */}
      {/* )} */}
    </div>
  );
};
