import React, { useState, useEffect } from "react";
import styles from "./TeamsPage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux";
import Button from "../../../components/generic/button/Button";
import { TeamCard } from "../../../components/moduleComponents/signUpFlow/teamsPage/teamCard/TeamCard";
import { TeamSetUp } from "../../../components/moduleComponents/signUpFlow/teamsPage/teamSetUp/TeamSetUp";
import { HelpModal } from "../../../components/moduleComponents/signUpFlow/helpModal/HelpModal";
import {
  clearCreatedProductTeams,
  currentProductData,
  getAllDomainData,
} from "../../../redux/signUpFlow/productPage/actions/productPage.actions";

import {
  getAllTeamsData,
  getAllRolesData,
  selectedTeamDetails,
  setIsTeamPageVisited,
  clearTeamsData,
} from "../../../redux/signUpFlow/teamsPage/actions/teamsPage.actions";
import { setIsMaiaPopUp } from "../../../redux/signUpFlow/signUp/action/signUp.action";
import {
  experiencePravid,
  updateTourModuleWise,
} from "../../../redux/signUpFlow/productTour/actions";
import { getCurrentTab } from "../../../redux/profile/yourAccount/organizationDetails/actions/organizationDetails.actions";
import { teamsPageIcon } from "../../../theme/assets/svg";
import Loader from "../../../components/generic/loader/Loader";
import { getAllProductData } from "../../../redux/signUpFlow/productPage/actions/productPage.actions";
import { ROLES } from "../../../constants";

export const TeamsPage = () => {
  const isLoadingTeams = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.teamsPageReducer?.isLoadingTeamsDetails
  );

  const teamsCreated = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.teamsPageReducer?.teamsDetails
  );

  const selectedTeamData = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.teamsPageReducer?.selectedTeamDetails
  );

  const loginUserDetail = useSelector(
    (store: RootState) => store.loginReducer?.userData
  );

  const role = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.userDetail?.role
  );

  const userId = useSelector(
    (state: RootState) => state?.loginReducer?.userLoginInfo?.userDetail?._id
  );

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const teamData = useSelector(
    (store: RootState) => store.loginReducer.teamData
  );
  const teamSchema = useSelector(
    (store: RootState) => store.loginReducer.teamSchemaData
  );
  const teamTab = useSelector(
    (store: RootState) => store.loginReducer.teamTabs
  );
  const teamChannel = useSelector(
    (store: RootState) => store.loginReducer.teamChannels
  );
  const sideBarModuleData = useSelector(
    (store: RootState) => store.loginReducer.sidebarModules
  );

  const isUserListTrue = useSelector(
    (store: RootState) => store.baseScreen?.leftMenu?.highlightedModule
  );

  const domainId = useSelector(
    (state: RootState) =>
      state.signUpModuleReducer?.teamsPageReducer?.selectedTeamDetails?.domainId
  );

  const domainData = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productPageReducer?.domainDetails
  );

  const [gigaLoader, setGigaLoader] = useState(true);
  const [isExpNowPopUp, setIsExpNowPopUp] = useState<boolean>(false);
  const [isInitialPopUp, setIsInitialPopUp] = useState<boolean>(false);
  const [teamDataSelected, setTeamDataSelected] = useState({
    id:
      selectedTeamData && Object.keys(selectedTeamData).length > 0
        ? selectedTeamData?.id
        : teamsCreated?.[0]?.id,
    teamName:
      selectedTeamData && Object.keys(selectedTeamData).length > 0
        ? selectedTeamData?.teamName
        : teamsCreated?.[0]?.teamName,
    teamMembers:
      selectedTeamData && Object.keys(selectedTeamData).length > 0
        ? selectedTeamData?.teamMembers
        : teamsCreated?.[0]?.teamMembers,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !teamData?.isLoading &&
      !teamSchema?.isLoading &&
      !teamTab?.isLoading &&
      !teamChannel?.isLoading &&
      !sideBarModuleData?.isLoading
    ) {
      setGigaLoader(false);
    } else {
      setGigaLoader(true);
    }
  }, [
    teamData?.isLoading,
    teamSchema?.isLoading,
    teamTab?.isLoading,
    teamChannel?.isLoading,
    sideBarModuleData?.isLoading,
  ]);

  useEffect(() => {
    dispatch(clearCreatedProductTeams());
    setTimeout(() => dispatch(setIsTeamPageVisited(true)), 4000);
  }, []);

  useEffect(() => {
    setIsInitialPopUp(true);
    dispatch(setIsMaiaPopUp(false));
  }, []);

  useEffect(() => {
    if (
      isUserListTrue === "Admin" &&
      (role === ROLES.admin.name ||
        role === ROLES.chiefRiskOfficer.name ||
        role === ROLES.financialSpecialist.name)
    ) {
      dispatch(clearTeamsData());
      dispatch(getAllTeamsData({ userId: userId }));
    }
  }, [userId, isUserListTrue]);

  useEffect(() => {
    setTeamDataSelected((prev) => {
      return {
        ...prev,
        id:
          selectedTeamData && Object.keys(selectedTeamData).length > 0
            ? selectedTeamData?.id
            : teamsCreated?.[0]?.id,
        teamName:
          selectedTeamData && Object.keys(selectedTeamData).length > 0
            ? selectedTeamData?.teamName
            : teamsCreated?.[0]?.teamName,
        teamMembers:
          selectedTeamData && Object.keys(selectedTeamData).length > 0
            ? selectedTeamData?.teamMembers
            : teamsCreated?.[0]?.teamMembers,
      };
    });
    dispatch(
      selectedTeamDetails(
        selectedTeamData && Object.keys(selectedTeamData).length > 0
          ? selectedTeamData
          : teamsCreated?.[0]
      )
    );
  }, [teamsCreated, selectedTeamData]);

  useEffect(() => {
    if (
      isUserListTrue === "Admin" &&
      selectedTeamData &&
      Object.keys(selectedTeamData)?.length > 0
    ) {
      dispatch(getAllRolesData({ usecaseId: selectedTeamData?.useCaseId }));
    }
  }, [selectedTeamData]);

  /** api recalling issue **/
  useEffect(() => {
    dispatch(getAllDomainData({ userId: userId, token: token }));
  }, [userId, token]);

  useEffect(() => {
    if (domainId !== undefined && domainData?.length > 0) {
      // if (
      //   currentProduct?.domain &&
      //   Object.keys(currentProduct?.domain).length !== 0
      // ) {
      //   dispatch(currentProductData({ domain: currentProduct?.domain?.[0] }));
      // } else {
      const domainSelected: any = domainData?.filter((data: any) => {
        return data.id === domainId;
      });
      // console.log("kkk", domainSelected?.[0]);
      dispatch(currentProductData({ domain: domainSelected?.[0] }));
      dispatch(
        getAllProductData({
          userId: userId,
          token: token,
          domainId: domainSelected?.[0]?.id,
        })
      );
    }
    // }
  }, [domainData, domainId]);

  const handleTeamSelection = (data: any) => {
    setTeamDataSelected(data);
    dispatch(selectedTeamDetails(data));
    // setTeamsLoader(false);
    dispatch(getAllTeamsData({ userId: userId }));
  };

  const handleShowExperienceModal = (data: boolean) => {
    dispatch(experiencePravid(data));
  };

  // for updating popup
  const updateSeenPage = () => {
    const body = {
      userId: userId,
      moduleName: "Team Page",
    };
    dispatch(updateTourModuleWise(body));
  };

  if (!teamData?.data?.length) return <></>;
  return (
    <div className={styles.teamsPageWrapper}>
      {isLoadingTeams || gigaLoader ? (
        <div className={styles.teamsLoader}>
          <Loader />
        </div>
      ) : (
        <div className={styles.teamsPageDiv}>
          <div className={styles.teamPageLeftSideWrapper}>
            <div className={styles.teamsTopDiv}>
              <div className={styles.teamsTopHeadingDiv}>Teams</div>
              <Button
                text="+ Create New"
                extraClass={styles.createBtnStyling}
                dataTest="auto_teams_create_btn"
                onClick={() => {
                  navigate("/productPage");
                }}
                id="auto_teams_create_btn"
              />
            </div>
            <div className={styles.teamsBottomDiv}>
              {teamsCreated?.map((data: any, index: number) => {
                return (
                  <div key={index}>
                    <TeamCard
                      teamData={data}
                      teamIndex={index}
                      onClickTeam={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.stopPropagation();
                        handleTeamSelection(data);
                      }}
                      isSelected={teamDataSelected.id}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.teamSetUpDiv}>
            <TeamSetUp />
          </div>
        </div>
      )}
      {!loginUserDetail?.productShowcase?.includes("Team Page") &&
        isInitialPopUp &&
        !loginUserDetail?.isInvited && (
          <HelpModal
            show={true}
            modalIcon={teamsPageIcon}
            extraContentClass={styles.extraTeamsContentClassStyling}
            isClose={true}
            close={() => {
              setIsInitialPopUp(false);
              updateSeenPage();
            }}
            extraClassModalStyling={styles.extraTeamsClassModalStyling}
            extraClassModalBackDrop={styles.extraClassModalTeamsBackDrop}
            extraClassContentModalStyling={styles.extraClassContentModalStyling}
            modalContent={
              <>
                <div className={styles.teamContentTextDiv}>
                  Great teams achieve great things! I&apos;ve set up a team
                  space for you to invite your teammates.
                </div>
                <div className={styles.teamContentBtnDiv}>
                  <Button
                    text="Explore Pravid"
                    extraClass={`${styles.teamExploreBtnStyling} ${styles.teamBtnStyling}`}
                    onClick={() => {
                      setIsInitialPopUp(false);
                      setIsExpNowPopUp(true);
                      updateSeenPage();
                      handleShowExperienceModal(true);
                    }}
                    id="auto_team_explore_btn"
                  />
                  <Button
                    text="Complete Account Setup"
                    extraClass={`${styles.teamSetUpBtnStyling} ${styles.teamBtnStyling}`}
                    onClick={() => {
                      updateSeenPage();
                      dispatch(getCurrentTab("Organization"));
                      navigate("/profile/your-account");
                      setIsInitialPopUp(false);
                    }}
                    id="auto_team_setup_btn"
                  />
                </div>
              </>
            }
          />
        )}
    </div>
  );
};
