import React, { useState, useEffect } from "react";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Chip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select";
import CustomChip from "../../../../generic/chip/Chip";
import styles from "./ChipDropdown.module.scss";
import { dropdownDownArrow } from "../../../../../theme/assets/svg";
import { Checkbox } from "antd";
import {
  voice,
  whatsapp,
  sms,
  mail,
  voiceWhite,
  whatsappWhite,
  smsWhite,
  mailWhite,
} from "../../.../../../../../theme/assets/svg/campaign_v3";
import { deleteCircle } from "../../../../../theme/assets/genericSvg";
import { toast } from "react-toastify";

interface Props {
  onChange: (selectedItems: string[], dropdownName: string) => void;
  options: string[];
  optionsDefaultSelected: string[];
  label?: string;
  disabled?: boolean;
  extraClass?: string;
  rightIcon?: any;
  id?: string;
  dropdownName: string;
  defaultNotEditable ?: boolean;
  defaultValues ?:any
}

const channelIcons: any = {
  Voice: voice,
  Whatsapp: whatsapp,
  Sms: sms,
  Email: mail,
};

const channelWhiteIcons: any = {
  Voice: voiceWhite,
  Whatsapp: whatsappWhite,
  Sms: smsWhite,
  Email: mailWhite,
};

export const ChipDropDown = (props: Props) => {
  const chipLimit = props.dropdownName === "language" ? 5 : 4;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  const sx = {
    width: "100%",
    height: "35px",
    backgroundColor: "#f5f5f5",
    border: "none", // Remove border
    borderRadius: "5px",
    borderColor: "transparent",

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderRadius: "5px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderRadius: "5px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: "0" },
  };

  const chipStyle = {
    backgroundColor: "rgb(1, 116, 255)",
    color: "white",
    height: "25px",
    fontSize: "12px",
    margin: "2px",
  };

  const [selectedNames, setSelectedNames] = useState<string[]>(
    props.optionsDefaultSelected || []
  );

  
  useEffect(
    () => {
     setSelectedNames(props.optionsDefaultSelected)
    },[props.optionsDefaultSelected]
  )
  const handleDeleteChip = (value: string) => {
    if(props.defaultNotEditable && props.defaultValues?.includes(value)){
       toast.error("This data is required and cannot be edited.")
    }
    else{
      setSelectedNames((prevSelected) =>
      prevSelected.filter((item) => item !== value)
    );
    }
  };
 const onChangeSelect =(event :any) => {
      const checkAlreadyData = props.defaultValues?.every((val:any) =>event?.target?.value?.includes(val))
      if(props.defaultNotEditable ){
        if(checkAlreadyData ){
          setSelectedNames(event?.target?.value)
        }else{
          toast.error("This data is required and cannot be edited.")
        }
      }
      else{
        setSelectedNames(event?.target?.value)
      }
 }

  useEffect(() => {
    props.onChange(selectedNames, props.dropdownName);
  }, [selectedNames]);

  return (
    <>
      {props?.label ? <div className={styles.label}>{props?.label}</div> : null}
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-multiple-chip"
        multiple
        value={selectedNames}
        onChange={(event: any) => onChangeSelect(event)}
        input={<OutlinedInput id="select-multiple-chip" />}
        sx={sx}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.slice(0, chipLimit)?.map((value) => (
              <CustomChip
                value={value}
                onDelete={() => handleDeleteChip(value)}
                key={value}
                chipIcon={
                  props.dropdownName === "channels"
                    ? channelWhiteIcons[value]
                    : null
                }
              />
            ))}
            {selected.length > chipLimit ? (
              <CustomChip
                key={"extra"}
                value={`+${selected.length - chipLimit}`}
                disableDeleteIcon
              />
            ) : (
              ""
            )}
          </Box>
        )}
        MenuProps={MenuProps}
        IconComponent={() => (
          <img className={styles.img_dropdown_icon} src={dropdownDownArrow} />
        )}
      >
        {props.options?.map((name: any) => (
          <MenuItem
            key={name}
            value={name}
            sx={{
              color: "#676767", // Change color of dropdown options
              "&:hover": {
                color: "#676767 !important", // Change color of dropdown options on hover
              },
              "&.Mui-selected": {
                backgroundColor: "transparent !important", // Remove background color of selected options
              },
            }}
          >
            <Checkbox
              checked={selectedNames.indexOf(name) > -1}
              style={{ marginRight: "20px" }}
            />
            {props.dropdownName === "channels" && (
              <img
                className={`${styles.option_img} ${
                  ["Voice", "Whatsapp"].includes(name)
                    ? styles.extraPadding
                    : ""
                }`}
                src={channelIcons[name]}
              />
            )}
            {name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
