// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mr5DcoJCzSU7FIlsc18W{width:240px;height:98px;border:.383974px solid #9e9e9e;border-radius:4.60769px;display:flex;justify-content:center;align-items:center;flex-direction:column;font-family:"Roboto";font-style:normal;text-transform:capitalize;margin:3% 5% 3% 3%;cursor:pointer}.mr5DcoJCzSU7FIlsc18W .XSAeUz691LoLg8_rCQ8g{font-weight:400;font-size:14px;line-height:15px;letter-spacing:.01em;color:#000;margin:5%}.mr5DcoJCzSU7FIlsc18W .n52jbR6kzQhv8xCEHcmg{font-weight:600;font-size:27px;line-height:16px;color:#1a81fe;margin:5%}`, "",{"version":3,"sources":["webpack://./src/components/crm/moduleComponent/crmCardComp/CrmCardComponent.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,WAAA,CACA,8BAAA,CACA,uBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,oBAAA,CACA,iBAAA,CACA,yBAAA,CACA,kBAAA,CACA,cAAA,CAEA,4CACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CACA,UAAA,CACA,SAAA,CAIJ,4CACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,SAAA","sourcesContent":[".crmCardClassDiv {\n    width: 240px;\n    height: 98px;\n    border: 0.383974px solid #9E9E9E;\n    border-radius: 4.60769px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    font-family: 'Roboto';\n    font-style: normal;\n    text-transform: capitalize;\n    margin: 3% 5% 3% 3%;\n    cursor: pointer;\n\n    .cardDivName {\n        font-weight: 400;\n        font-size: 14px;\n        line-height: 15px;\n        letter-spacing: 0.01em;\n        color: #000000;\n        margin: 5%;\n\n    }\n\n    .cardDivValue {\n        font-weight: 600;\n        font-size: 27px;\n        line-height: 16px;\n        color: #1A81FE;\n        margin: 5%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crmCardClassDiv": `mr5DcoJCzSU7FIlsc18W`,
	"cardDivName": `XSAeUz691LoLg8_rCQ8g`,
	"cardDivValue": `n52jbR6kzQhv8xCEHcmg`
};
export default ___CSS_LOADER_EXPORT___;
