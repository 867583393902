import React, { useEffect, useState } from "react";
import styles from "./StrategyCardComponent.module.scss";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";
import Icon from "../../../generic/icon/Icon";
import {
  strategyDeleteIcon,
  strategyEditIcon,
} from "../../../../theme/assets/svg/strategySvg";
import { deleteIcon } from "../../../../theme/assets/svg";
import { editPencilGrey } from "../../../../theme/assets/genericSvg";
import { threeDotVertical } from "../../../../theme/assets/genericSvg";
import {
  strategyCallIcon,
  strategyWhatsAppIcon,
  strategyMailIcon,
  strategySMSIcon,
} from "../../../../theme/assets/svg/campaign_v3/index";
import Popover from "@mui/material/Popover";

import { CreateStrategyModal } from "../createStartegyModal/CreateStrategyModal";
import { StrategyModalPopUp } from "../strategyModalPopUp/StrategyModalPopUp";
import { createdStrategy } from "../../../../redux/strategy/strategyCreation/actions";
import { useDispatch, useSelector } from "react-redux";
import { StrategyDescModal } from "../strategyDescModal/StrategyDescModal";
import { RootState } from "../../../../redux";

interface effectivenessData {
  effectiveness: string;
  connection_rate: string;
  collection_rate: string;
  call_objective: string;
}

interface strategyData {
  id: string;
  strategyName: string;
  effectiveness?: effectivenessData[];
  strategyDescription: string;
  mode?: object;
}
interface StrategyCardProps {
  strategyData: strategyData;
  clientName: string;
  onDelete: CallableFunction;
  cardIndex: number;
}

export const StrategyCardComponent = ({
  strategyData,
  onDelete,
  clientName,
  cardIndex,
}: StrategyCardProps) => {
  const [editStrategyModal, setEditStrategyModal] = useState<boolean>(false);
  const [strategyModal, setStrategyModal] = useState<boolean>(false);
  const [descriptionModal, setDescriptionModal] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<string>("");
  const [editWarnPopUp, setEditWarnPopUp] = useState<boolean>(false);

  const dispatch = useDispatch();

  const isLoadingDelStrategy = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer.isLoadingDeleteStrategy
  );

  const moduleSelectedScreen = useSelector(
    (store: RootState) => store?.baseScreen?.leftMenu?.highlightedModule
  );

  const myPlanData: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  useEffect(() => {
    if (editStrategyModal === true) {
      dispatch(createdStrategy({ ...strategyData, addedFlows: true }));
    }
  }, [strategyData, editStrategyModal]);

  useEffect(() => {
    if (isLoadingDelStrategy === false) {
      setIdDelete("");
    }
  }, [isLoadingDelStrategy]);

  useEffect(() => {
    if (strategyModal === false && isLoadingDelStrategy === false) {
      setIdDelete("");
    }
  }, [strategyModal]);

  // console.log("campaign", strategyData?.mode);

  const handleModes = (data: object) => {
    const arr = [];
    const modesArr = data ? Object.keys(data) : undefined;
    //const modesArr = data && data[0] ? Object.keys(data[0]) : undefined;
    // console.log("mode", Object.keys(data));
    if (modesArr && modesArr.includes("call")) {
      // arr.push("strategyCallIcon");
      arr.push(strategyCallIcon);
    }
    if (
      modesArr &&
      (modesArr.includes("whatsApp") || modesArr.includes("whatsapp"))
    ) {
      arr.push(strategyWhatsAppIcon);
      // arr.push("strategyWhatsAppIcon");
    }
    if (modesArr && modesArr.includes("sms")) {
      arr.push(strategySMSIcon);
      // arr.push("strategyMsgIcon");
    }
    if (modesArr && modesArr.includes("mail")) {
      arr.push(strategyMailIcon);
      // arr.push("strategyMailIcon");
    }
    return arr;
  };

  /*****************************  popover  *****************************/
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div
      className={`${styles.strategyCardDiv} ${
        moduleSelectedScreen !== "Strategy" ? styles.extraCampaignClass : ""
      }`}
    >
      <div className={styles.strategyInfoTopDiv}>
        <div className={styles.strategyNameDiv} id="auto_strategyName">
          <span>{strategyData?.strategyName}</span>

          {!myPlanData?.isStrategyToShow ? (
            <></>
          ) : (
            <>
              {" "}
              <img
                className={styles["three-dot"]}
                src={threeDotVertical}
                onClick={(e) =>
                  handleClick(e as never as React.MouseEvent<HTMLButtonElement>)
                }
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    borderRadius: "8px", // Set the border radius for the Paper component
                  },
                }}
              >
                <div className={styles.popup__div}>
                  <div
                    className={styles.edit}
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => {
                      e.stopPropagation();
                      setEditWarnPopUp(true);
                      setAnchorEl(null);
                    }}
                  >
                    <Icon
                      id="auto_strategyEditIcon"
                      img_src={editPencilGrey}
                      extraClass={styles.strategyCardIcon}
                      disabled={idDelete === strategyData?.id ? true : false}
                    />
                    <span>Edit</span>
                  </div>
                  <div
                    className={styles.delete}
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => {
                      e.stopPropagation();
                      setStrategyModal(true);
                      setIdDelete(strategyData?.id);
                      setAnchorEl(null);
                    }}
                  >
                    <Icon
                      id="auto_strategyDeleteIcon"
                      img_src={deleteIcon}
                      extraClass={styles.strategyCardIcon}
                      disabled={idDelete === strategyData?.id ? true : false}
                    />
                    <span>Delete</span>
                  </div>
                </div>
              </Popover>
            </>
          )}
        </div>
      </div>
      <div className={styles.channels}>
        <span>Channels</span>
        <div className={styles.strategyModeDiv} id="auto_strategyModeDiv">
          {strategyData?.mode !== undefined
            ? handleModes(strategyData?.mode).map(
                (data: string, index: number) => {
                  return (
                    // <PravidIcons
                    //   activeIcon={data}
                    //   key={index}
                    //   extraClass={styles.strategyCardIcon}
                    // />
                    <Icon
                      img_src={data}
                      key={index}
                      extraClass={styles.strategyCardIcon}
                    />
                  );
                }
              )
            : ""}
        </div>
      </div>
      <div className={styles.strategyDescDiv} id="auto_strategyDescDiv">
        {strategyData?.strategyDescription?.length > 50 ? (
          <span>
            <span className={styles.strategyDescription}>
              {strategyData?.strategyDescription?.slice(0, 50)}...
            </span>
            <a
              className={styles.strategyDescExtraStyling}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                idDelete === strategyData?.id
                  ? setDescriptionModal(false)
                  : setDescriptionModal(true);
              }}
            >
              &nbsp;&nbsp;see more
            </a>
          </span>
        ) : (
          <span>{strategyData?.strategyDescription}</span>
        )}
      </div>
      <div className={styles["effectiveness"]}>
        {strategyData?.effectiveness &&
          strategyData?.effectiveness?.map(
            (data: effectivenessData, index: number) => {
              return (
                <div
                  className={styles.strategyEffectivenessDiv}
                  key={index}
                  id={`auto_strategyEffectiveness_${index}`}
                >
                  <div
                    className={styles.strategyEffectivenessDivText}
                    id="auto_strategyEffectiveness"
                  >
                    Effectiveness:&nbsp;{data?.effectiveness}%
                  </div>
                  <div className={`${styles.strategyEffectivenessInfoDiv}`}>
                    <div>
                      <div
                        className={styles.strategyEffectivenessDivText}
                        id="auto_strategyEffe"
                      >
                        Effectiveness:&nbsp;{data?.effectiveness}%
                      </div>
                    </div>
                    <div className={styles.effeBtmDiv}>
                      <div
                        className={styles.effeBtmDivText}
                        id="auto_connection_rate"
                      >
                        Connection Rate:&nbsp;{data?.connection_rate}%
                      </div>
                      <div
                        className={styles.effeBtmDivText}
                        id="auto_collection_rate"
                      >
                        Collection Rate:&nbsp;{data?.collection_rate}%
                      </div>
                      <div
                        className={styles.effeBtmDivText}
                        id="auto_call_objective"
                      >
                        Call Objective:&nbsp;{data?.call_objective}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
      </div>
      <StrategyDescModal
        show={descriptionModal}
        close={() => {
          setDescriptionModal(false);
        }}
        name={strategyData?.strategyName}
        description={strategyData?.strategyDescription}
      />
      <div className={styles.strategyBottomDiv}>
        <div
          className={styles.strategyBtnDiv}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <StrategyModalPopUp
            show={strategyModal}
            close={() => {
              setStrategyModal(false);
            }}
            onclick={() => {
              if (strategyModal)
                onDelete(
                  clientName,
                  strategyData?.strategyName,
                  strategyData?.id
                );
            }}
            name={strategyData?.strategyName}
            deleteModal={true}
            isLoading={isLoadingDelStrategy}
          />
          <StrategyModalPopUp
            show={editWarnPopUp}
            close={() => {
              setEditWarnPopUp(false);
            }}
            onclick={() => {
              setEditStrategyModal(true);
            }}
            name={strategyData?.strategyName}
          />
          <CreateStrategyModal
            show={editStrategyModal}
            close={() => {
              setEditStrategyModal(false);
            }}
            data={strategyData}
            editStrategyPopUp={true}
          />
          <StrategyDescModal
            show={descriptionModal}
            close={() => {
              setDescriptionModal(false);
            }}
            name={strategyData?.strategyName}
            description={strategyData?.strategyDescription}
          />
        </div>
      </div>
    </div>
  );
};
