import React from "react";
import { getValueByReferenceKey } from "../../../../utils";
import style from "./Table.module.scss";
export type columnType =
  | "Sr. No"
  | "Agent"
  | "Online (hrs)"
  | "Break (hrs)"
  | "Offline(hrs)"
  | "Office Work (hrs)"
  | "Avg. Response Time"
  | "CSAT"
  | "SLA%"
  | "Avg. Handle Time"
  | string;
export interface columnInterface {
  //   createdAt: string;
  //   id: string;
  //   isPermanentDisable: boolean;
  //   isSortBtn: boolean;
  //   name: string;
  originalName: columnType;
  //   positionName: string;
  //   position: number;
  //   updatedAt: string;
  referancKeyName?: string;
  currentName: string;
  //   isActive: string | boolean;
}

export default function TableConstantPerformance(
  columnSchema: columnInterface[],
  limit: number
) {
  const columnsToShow: Array<any> = [];
  const totalColumnData = columnSchema ? [...columnSchema] : [];

  totalColumnData.forEach((e) => {
    switch (e.originalName) {
      case "Sr. No": {
        const obj = {
          title: e.originalName,
          key: e.referancKeyName,
          render: (
            rowData: any,
            key: string,
            index: number,
            pageNo: number
          ) => (
            <div className={style.eachRowElement}>
              {(pageNo - 1) * limit + index + 1}
            </div>
          ),
        };
        columnsToShow.push(obj);
        break;
      }
      default: {
        const obj = {
          title: e.originalName,
          key: e.referancKeyName,
          render: (rowData: any, key: any) => {
            return (
              <div className={style.eachRowElement}>
                {getValueByReferenceKey(rowData, key)}
              </div>
            );
          },
        };
        columnsToShow.push(obj);
        break;
      }
    }
  });
  return columnsToShow;
}
