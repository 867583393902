import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { CAMPAIGN_NEW_API, config } from "../../../../services/ApiRoutes";
import actionTypes from "../schedularSetting.actionTypes";
import campaignActionTypes from "../../createCampaign/createCampaign.actionTypes";
import progressBarActionTypes from "../../progressBar/progressBar.actionTypes";
import _ from "lodash";

export function* getSchedularDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${CAMPAIGN_NEW_API.GET_SCHEDULER_DATA}`,
        {
          params: _.omit(action.payload, "token"),
          headers: {
            "x-access-token": action.payload.token,
            "Content-Type": "application/json",
          },
        }
      );
      yield put({
        type: actionTypes.GET_SCHEDULAR_DATA_API_SUCCESS,
        payload: result.data.data,
      });

      toast.dismiss();
      toast.success(result.data.data.message);
    }
  } catch (error: any) {
    toast.dismiss();
    toast.error(error.response?.data?.error?.message);
    console.error(error);
    yield put({
      type: actionTypes.GET_SCHEDULAR_DATA_API_FAILURE,
      payload: error,
    });
  }
}

export function* saveSchedularDataAPIWorker(action: any): any {
  try {
    if (action) {
      const res = action.payload;
      const { token, parentLabel, ...updatedPayload } = res;
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${CAMPAIGN_NEW_API.SAVE_SCHEDULER_DATA}`,
        updatedPayload,
        {
          "x-access-token": action.payload.token,
          "Content-Type": "application/json",
        }
      );
      yield put({
        type: actionTypes.SCHEDULAR_DATA_API_SUCCESS,
        payload: { ...result.data.data, isScheduledSuccess: true },
      });
      yield put({
        type: campaignActionTypes.SET_CAMPAIGN_DATA,
        payload: { data: null },
      });
      yield put({
        type: progressBarActionTypes.MAX_STEP_COMPLETE,
        payload: 1,
      });

      toast.dismiss();
      toast.success(
        `${action.payload.parentLabel} Campaign has been Scheduled`
      );
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.SCHEDULAR_DATA_API_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    toast.error(error.response?.data?.error);
    console.error(error);
  }
}
