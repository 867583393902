import { all, fork } from "redux-saga/effects";
import * as createCampaignWatcherSaga from "./createCampaign/saga";
import * as dataUploadWatcherSaga from "./dataUpload/saga";
import * as strategyWatcherSaga from "./strategySelection/saga";
import * as agentConfigWatcherSaga from "./agentConfiguration/saga";
import * as schedularSettingWatcherSaga from "./schedularSetting/saga";
import * as watchClearCampaignData from "./campaignTable/saga";


export default function* campaignRootSaga() {
  yield all([...Object.values(createCampaignWatcherSaga)].map(fork));
  yield all([...Object.values(dataUploadWatcherSaga)].map(fork));
  yield all([...Object.values(strategyWatcherSaga)].map(fork));
  yield all([...Object.values(agentConfigWatcherSaga)].map(fork));
  yield all([...Object.values(schedularSettingWatcherSaga)].map(fork));
  yield all([...Object.values(watchClearCampaignData)].map(fork));
}
