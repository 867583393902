const actionTypes = {
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SUMMARY_GET_API_REQUEST: "SUMMARY_GET_API_REQUEST",
  SUMMARY_GET_API_SUCCESS: "SUMMARY_GET_API_SUCCESS",
  SUMMARY_GET_API_FAILURE: "SUMMARY_GET_API_FAILURE",
  TRANSCRIPT_GET_API_REQUEST: "TRANSCRIPT_GET_API_REQUEST",
  TRANSCRIPT_GET_API_SUCCESS: "TRANSCRIPT_GET_API_SUCCESS",
  TRANSCRIPT_GET_API_FAILURE: "TRANSCRIPT_GET_API_FAILURE",
  FEEDBACK_POST_API_REQUEST: "FEEDBACK_POST_API_REQUEST",
  FEEDBACK_POST_API_SUCCESS: "FEEDBACK_POST_API_SUCCESS",
  FEEDBACK_POST_API_FAILURE: "FEEDBACK_POST_API_FAILURE",
  FEEDBACK_HISTORY_API_REQUEST: "FEEDBACK_HISTORY_API_REQUEST",
  FEEDBACK_HISTORY_API_SUCCESS: "FEEDBACK_HISTORY_API_SUCCESS",
  FEEDBACK_HISTORY_API_FAILURE: "FEEDBACK_HISTORY_API_FAILURE",
  REPORT_ISSUE_API_REQUEST: "REPORT_ISSUE_API_REQUEST",
  SET_FEEDBACK_HISTORY_TABLE_DATA: "SET_FEEDBACK_HISTORY_TABLE_DATA",
  SET_FEEDBACK_CALENDER_DATA: "SET_FEEDBACK_CALENDER_DATA",
  PORTAL_TRANSCRIPT_DOWNLOAD_CSV: "PORTAL_TRANSCRIPT_DOWNLOAD_CSV",
  FEEDBACK_HISTORY_DOWNLOAD_CSV: "FEEDBACK_HISTORY_DOWNLOAD_CSV",
  FEEDBACK_HISTORY_DOWNLOAD_CSV_SUCCESS:
    "FEEDBACK_HISTORY_DOWNLOAD_CSV_SUCCESS",
  FEEDBACK_HISTORY_DOWNLOAD_CSV_FAILURE:
    "FEEDBACK_HISTORY_DOWNLOAD_CSV_FAILURE",
  REPORT_ISSUE_DOWNLOAD_CSV: "REPORT_ISSUE_DOWNLOAD_CSV",
  REPORT_ISSUE_DOWNLOAD_CSV_SUCCESS: "REPORT_ISSUE_DOWNLOAD_CSV_SUCCESS",
  REPORT_ISSUE_DOWNLOAD_CSV_FAILURE: "REPORT_ISSUE_DOWNLOAD_CSV_FAILURE",
  CLEAR_TRANSCRIPT_SUMMARY_DATA: "CLEAR_TRANSCRIPT_SUMMARY_DATA",

  GET_FEEDBACK_HISTORY_SCHEMA_REQUEST: "GET_FEEDBACK_HISTORY_SCHEMA_REQUEST",
  GET_FEEDBACK_HISTORY_SCHEMA_SUCCESS: "GET_FEEDBACK_HISTORY_SCHEMA_SUCCESS",
  GET_FEEDBACK_HISTORY_SCHEMA_FAILURE: "GET_FEEDBACK_HISTORY_SCHEMA_FAILURE",

  SUMMARY_DATA_CLEAN : "SUMMARY_DATA_CLEAN",
  GET_AUDIO_URL_TO_PLAY_REQUEST:"GET_AUDIO_URL_TO_PLAY_REUEST",
  GET_AUDIO_URL_TO_PLAY_SUCCESS:"GET_AUDIO_URL_TO_PLAY_SUCCESS",
  GET_AUDIO_URL_TO_PLAY_FAILURE:"GET_AUDIO_URL_TO_PLAY_FAILURE",
  CLEAR_AUDIO_URL:"CLEAR_AUDIO_URL"
};

export default actionTypes;
