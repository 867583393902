import React from "react";
import { check_ring_light_svg } from "../../../../../theme/assets/svg";
import PravidIcons from "../../../../generic/pravidIcons/PravidIcons";
import Icon from "../../../../generic/icon/Icon";
import styles from "./BillingFeatures.module.scss";
import {
  maiaFeatureIcon,
  pravidFeatureIcon,
  supportIcon,
} from "../../../../../theme/assets/profileSectionSvg/paymentSvg/index";
export interface IFeatures {
  name: string;
  features: string[];
  icon: string;
}
interface props {
  features: IFeatures[];
}
export default function BillingFeatures(props: props) {
  const featuresArray = props.features;
  const iconsArray = [pravidFeatureIcon, maiaFeatureIcon, supportIcon];

  const result = featuresArray?.map((element, index) => {
    return {
      value: element,
      icon: iconsArray[index],
    };
  });

  return (
    <div className={styles.containerDiv}>
      <div>
        {result?.map((feature, index) => {
          return (
            index % 2 === 0 && (
              <div key={index} className={styles.eachFeature}>
                <div className={styles.title}>
                  {feature?.icon && (
                    <Icon
                      img_src={feature?.icon}
                      extraClass={styles.featureIconStyling}
                    />
                  )}
                  {/* <PravidIcons activeIcon={feature.icon} /> */}
                  <div className={styles.featureName}>
                    {feature?.value?.name}
                  </div>
                </div>

                {feature?.value?.features.map((each, index) => {
                  return (
                    <div key={index} className={styles.featureList}>
                      <img src={check_ring_light_svg}></img>
                      <div className={styles.info}>{each}</div>
                    </div>
                  );
                })}
              </div>
            )
          );
        })}
      </div>
      <div>
        {result?.map((feature, index) => {
          return (
            index % 2 !== 0 && (
              <div key={index} className={styles.eachFeature}>
                <div className={styles.title}>
                  {feature?.icon && (
                    <Icon
                      img_src={feature?.icon}
                      extraClass={styles.featureIconStyling}
                    />
                  )}
                  {/* <PravidIcons activeIcon={feature.icon} /> */}
                  <div className={styles.featureName}>
                    {feature?.value?.name}
                  </div>
                </div>

                {feature?.value?.features.map((each, index) => {
                  return (
                    <div key={index} className={styles.featureList}>
                      <img src={check_ring_light_svg}></img>
                      <div className={styles.info}>{each}</div>
                    </div>
                  );
                })}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}
