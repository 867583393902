import React, { useEffect, useState } from "react";
import KPIComponent from "../kpiFilter/KPIComponent";
import { firstLetterCapital } from "./../../../utils/stringmodifycase";
// import { blueKpi } from "./../../../theme/assets/svg/rightSideIcon";
import styles from "./KPIWapper.module.scss";
import { RootState } from "./../../../redux";
import { useSelector } from "react-redux";
import { useCurrentPageTab } from "../../../hooks";
import { getDataFromSchema } from "../../../utils";
import { functions } from "lodash";
import PravidIcons from "../pravidIcons/PravidIcons";
import {
  chartInterface,
  cardInterface,
} from "../../../screens/analytics/analyticsScreen.types";
import { parentChannels } from "../../../constants";

function KPIWrapper(props: any) {
  // const whereweare=useSelector((state:RootState)=>state.dashboardReducer);
  // const sourceTab=whereweare.sourceTab;
  // const channelTab=whereweare.channelTab;
  // const whatsappChannelTab=whereweare.whatsappChannelTab;
  // const subModuleTab=whereweare.subModuleTab;
  // const loggerstate=useSelector((state:RootState)=>state.loggerReducer.loggerState.currentPage)
  // const pageType=useSelector((state:RootState)=>state.loginReducer.isActivePageType)
  // const schema =useSelector((state:RootState)=>state.loginReducer?.userLoginInfo?.schema);
  //   const allFilterOptions=useSelector((state:RootState)=>state.filterReducers?.filterOptions?.data?.filters);
  //getting data dfor downlaod
  //  const downloadsoption=(schema["Logger"]['report']['Debt Collection']["Campaign"]["Call"]["kpiDetails"]);

  const {
    schema,
    isActivePageType,
    subChannelTab,
    subModuleTab,
    channelTab,
    currentLoggerPage,
  } = useCurrentPageTab();

  const [kpiDetails, setKpiDetails] = useState<any>({ charts: [], cards: [] });
  const [charts, setCharts] = useState<chartInterface[]>();
  const [cards, setCards] = useState<cardInterface[]>();
  const [chartMapOptions, setChartMapOptions] = useState<chartInterface[]>([]);
  const [cardsMap, setCardsMap] = useState<cardInterface[]>([]);

  useEffect(() => {
    // console.log("INSIDE EFFECTS");
    if (
      channelTab.name &&
      currentLoggerPage &&
      isActivePageType &&
      schema &&
      Object.keys(schema).length &&
      (subChannelTab?.name || !parentChannels.includes(channelTab?.name)) &&
      subModuleTab.name
    ) {
      setCharts(
        getDataFromSchema({
          schemaData: schema,
          channel: channelTab,
          dataType: "charts",
          subChannel: subChannelTab,
          tab: subModuleTab,
          screen: isActivePageType,
          subScreen: currentLoggerPage,
        }) as chartInterface[]
      );
      setCards(
        getDataFromSchema({
          schemaData: schema,
          channel: channelTab,
          dataType: "cards",
          subChannel: subChannelTab,
          tab: subModuleTab,
          screen: isActivePageType,
          subScreen: currentLoggerPage,
        }) as cardInterface[]
      );
    }
  }, [
    schema,
    subChannelTab?.name,
    subModuleTab?.name,
    channelTab?.name,
    currentLoggerPage,
    isActivePageType,
  ]);

  useEffect(() => {
    if (!charts) return;
    let chartMapOptions = structuredClone(charts);
    chartMapOptions = chartMapOptions
      .sort((a, b) => a.position - b.position)
      .map((each) => {
        return each;
      });
    setChartMapOptions(chartMapOptions);
  }, [charts]);

  useEffect(() => {
    if (!cards) return;
    let cardsMap = structuredClone(cards);
    cardsMap = cardsMap
      .sort((a, b) => a.position - b.position)
      .map((each) => {
        return each;
      });
    setCardsMap(cardsMap);
  }, [cards]);

  return (
    <>
      <div className={styles.titleofilter}>
        <PravidIcons activeIcon={"blueKpi"} />
        <p className={styles.titletext}>{"KPI Customisation"}</p>
      </div>
      <div className={styles.divide}></div>

      <KPIComponent
        options={{
          options: chartMapOptions as never as cardInterface[],
          name: "Charts",
          fieldName: "charts",
        }}
        disabled={false}
      />
      <KPIComponent
        options={{ options: cardsMap, name: "Cards", fieldName: "cards" }}
        disabled={false}
      />

      <div className={styles.divide}></div>
    </>
  );
}

export default React.memo(KPIWrapper);
