import React,{useState} from "react";
import styles from "./AgentCreatePopUp.module.scss";
import Button from "../../../generic/button/Button"
import  {createStrategyCloseIcon} from "../../../../theme/assets/svg/strategySvg/index"

export const AgentCreatePopUp = ({onClose, onSendGender}:any)=>{


  const genderOptions = ["Male", "Female", "Both"];

  const [selectedGender, setSelectedGender] = useState("");

  const handleGenderChange = (event:any) => {
    setSelectedGender(event.target.value);
  };


return <div className={styles.agentModalContent}>        
    <div className={styles.topAgentDiv}>
    <div className={styles.header}>Create Agent</div>
    <div className={styles.modalCloseIcon}>
        <img
        src={createStrategyCloseIcon}
        onClick={onClose}
        className={styles.iconStyling}
        id="auto_agent_modal_close_icon"
        />
    </div>
    </div>
    <div className={styles.desc}>Select gender of the agent and continue</div>
    <div className={styles.optionsDiv}>
      {genderOptions.map((option, index) => (
      <label key={index}>
      <input
          type="radio"
          name="gender"
          value={option}
          checked={selectedGender === option}
          onChange={handleGenderChange}
          className={styles.radioInput}
      />
      {option}
      </label>
      ))}
    </div>
    <div className={styles.btnDiv}> 
      <Button
            text="Continue"
            extraClass={`${styles.buttonStyling} ${!selectedGender.length ? styles.disabledStyling : ""}`}
            onClick={() => {
              onSendGender(selectedGender)
              onClose();
            }}
            id="auto_continue_icon"
          />
    </div>
  </div>
}