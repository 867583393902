import React, { PureComponent, useState, useEffect } from "react";
import NoDatamodel from "../../generic/noDatamodel/NoDatamodel";
import { noPhoneDataIcon } from "../../../theme/assets/svg";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

function SimpleLineChart(props) {
  const [data, setData] = useState([]);
  const [timeSlotData, setTimeSlotData] = useState([]);

  const expandedChart = useSelector(
    (store) => store?.analyticsReducer?.expandedChart
  );

  const isSort = useSelector((store) => store?.analyticsReducer?.isSort);

  const sortedChart = useSelector(
    (store) => store?.analyticsReducer?.sortedChart
  );

  let name = props.chartDetails?.keys[0].referenceKeyName;
  let key1 = props.chartDetails?.legendData[0];
  let key2 = props.chartDetails?.legendData[1];
  let value1 = props.chartDetails?.keys[1].referenceKeyName;
  let value2 = props.chartDetails?.keys[2].referenceKeyName;
  let refenceKeyForData = props.chartDetails?.refenceKeyForData;
  let fill1 = props.chartDetails?.keys[1].fillColor;
  let fill2 = props.chartDetails?.keys[2].fillColor;
  let barWidth = parseInt(props.chartDetails?.chartStyle?.width) - 90;
  let barHeight = parseInt(props.chartDetails?.chartStyle?.height) - 80;
  let expandbarHeight =
    parseInt(props.chartDetails?.expand?.expandStyle?.height) - 80;
  let legend1 = props.chartDetails?.legendData[0];
  let legend2 = props.chartDetails?.legendData[1];

  useEffect(() => {
    if (props.chartDetails && props.data) {
      var sortedTimeList = [];
      var tempTimesList = [];
      let tempData = props.data[refenceKeyForData];
      tempData?.map((each, index) => {
        var tempSingleTimeArr = each[name].split("-");
        tempTimesList.push({
          time: moment(tempSingleTimeArr[0].trim(), "hh:mm a").unix(),
          index: index,
          count1: each[value1],
          count2: each[value2],
        });
      });
      tempTimesList.sort(function (a, b) {
        return a.time - b.time;
      });

      tempTimesList.forEach(function (t) {
        sortedTimeList.push(tempData[t.index]);
      });
      setTimeSlotData(sortedTimeList);
    }
  }, [props.chartDetails, props.data]);

  useEffect(() => {
    if (props.chartDetails && props.data) {
      let temp = props.data[refenceKeyForData];
      let finalData = temp?.map((each, i) => {
        return {
          name: moment(each[name], "DD/MM/YYYY").format("DD MMM YY"),
          [key1]: each[value1],
          [key2]: each[value2],
          amt: 0,
        };
      });
      setData(finalData);
    }
  }, [props.chartDetails, props.data[refenceKeyForData]]);

  const setSortedData = (data) => {
    let finalData = data?.map((each, i) => {
      return {
        name: moment(each[name], "DD/MM/YYYY").format("DD MMM YYYY"),
        [key1]: each[value1],
        [key2]: each[value2],
        amt: 0,
      };
    });
    setData(finalData);
  };

  useEffect(() => {
    if (props.chartTitle === sortedChart) {
      if (isSort) {
        let tempdata = props?.data[refenceKeyForData];
        tempdata.reverse();
        setSortedData(tempdata);
      } else {
        let tempdata = props?.data[refenceKeyForData];
        tempdata.reverse();
        setSortedData(tempdata);
      }
    }
  }, [isSort, props?.data[refenceKeyForData]]);
  
  return data?.length > 0 ? (
    <div
      className="sd-chart"
      style={{
        minWidth: `${data.length * 140}px`,
        height: `${
          expandedChart && expandedChart.includes(props.chartTitle)
            ? `${expandbarHeight}px`
            : `${barHeight}px`
        }`,
        width: `${
          expandedChart && expandedChart.includes(props.chartTitle)
            ? "100%"
            : `${barWidth}px`
        }`,
        position: "relative",
      }}
    >
      <LineChart
        width={1000}
        height={
          expandedChart && expandedChart.includes(props.chartTitle) ? 500 : 380
        }
        data={data}
      >
        <XAxis dataKey="name" padding={{ left: 60 }}>
          <Label
            // angle={200}
            position="insideBottomMiddle"
            style={{
              textAnchor: "middle",
              fill: "#000000",
              padding: "20px",
              margin: "20px",
            }}
            dy={15}
          >
            {props.chartDetails?.keys[0].name}
          </Label>
        </XAxis>
        <YAxis>
          <Label
            angle={270}
            offset={-20}
            position="left"
            style={{ textAnchor: "middle", fill: "#000000" }}
          >
            {props.chartDetails?.keys[1].name}
          </Label>
        </YAxis>
        <Tooltip />
        <Legend
          layout="horizontal"
          verticalAlign="top"
          align="left"
          wrapperStyle={{
            top: "0",
            left: "800px",
            width: "auto",
          }}
          payload={[
            {
              id: "10",
              type: "square",
              value: legend1,
              color: fill1,
            },
            {
              id: "10",
              type: "square",
              value: legend2,
              color: fill2,
            },
          ]}
        />
        <Line
          type="monotone"
          dataKey={key1}
          stroke={fill1}
          activeDot={{ r: 8 }}
          strokeWidth={4}
          dot={{ r: 5, stroke: "#318EFF", fill: "#318EFF" }}
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          dataKey={key2}
          stroke={fill2}
          activeDot={{ r: 8 }}
          strokeWidth={4}
          dot={{ r: 5, stroke: "#C4D8F7", fill: "#C4D8F7" }}
          isAnimationActive={false}
        />
      </LineChart>
    </div>
  ) : (
    <div
      className="no-data"
      style={{
        height: `${
          expandedChart && expandedChart.includes(props.chartTitle)
            ? "510px"
            : "310px"
        }`,
      }}
    >
      <NoDatamodel srcImg={noPhoneDataIcon}></NoDatamodel>
    </div>
  );
}

export default SimpleLineChart;
