// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EDAJTQP4Au9c1KFLjnBJ{height:100%;width:100%;display:flex;flex-direction:column}.EDAJTQP4Au9c1KFLjnBJ .GG4H1j5gv9zs2blqgsL4 p{font-family:Roboto;font-size:14px;font-weight:500;line-height:16px;text-align:left;margin-top:.7em;margin-bottom:.7em;margin-left:1em}.EDAJTQP4Au9c1KFLjnBJ .GG4H1j5gv9zs2blqgsL4 div{border:.5px solid #f1f1f1}.EDAJTQP4Au9c1KFLjnBJ .jztMXcv9ZJVZhsM25sd6{flex:1;overflow:auto;margin-bottom:1em}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/editAllNudge/EditAllNudge.module.scss"],"names":[],"mappings":"AAAA,sBACG,WAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CAGC,8CACE,kBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CAEA,eAAA,CACA,eAAA,CACA,kBAAA,CACA,eAAA,CAEF,gDACE,yBAAA,CAIJ,4CACE,MAAA,CACA,aAAA,CACA,iBAAA","sourcesContent":[".wrapper {\n   height:100%;\n   width:100%;\n   display: flex;\n   flex-direction: column;\n\n  .title_top {\n    p {\n      font-family: Roboto;\n      font-size: 14px;\n      font-weight: 500;\n      line-height: 16px;\n\n      text-align: left;\n      margin-top: 0.7em;\n      margin-bottom: 0.7em;\n      margin-left: 1em;\n    }\n    div {\n      border: 0.5px solid #f1f1f1;\n    }\n  }\n\n  .title_div{\n    flex:1;\n    overflow: auto;\n    margin-bottom: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `EDAJTQP4Au9c1KFLjnBJ`,
	"title_top": `GG4H1j5gv9zs2blqgsL4`,
	"title_div": `jztMXcv9ZJVZhsM25sd6`
};
export default ___CSS_LOADER_EXPORT___;
