import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./EachNudgeRightSidePop.module.scss";
import { editIcons, deleteic, gohide } from "../../../theme/assets/svg";
import {
  blackEditIcon,
  deleteIcon,
  questionIcon,
} from "../../../theme/assets/svg/campaign_v3";
import {
  Channel,
  CommunicationWays,
  Conditions,
  PaymentLink,
  Timing,
  Title,
  Variation,
  AgentDesktop,
  Callback,
  AlternateNumber,
  ChannelsMapping,
  PromiseToPayThreshold,
  FollowUps,
  AttemptCount,
} from "../../generic/channel/Channel";
// import Tooltip from "@material-ui/core/Tooltip";
// import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { useDispatch } from "react-redux";
import {
  clearNudgeSetings,
  setIsBackIconClicked,
  setPropForEachNudge,
  setShowNudgeSetting,
  setShowRightsideAll,
} from "../../../redux/strategy/eachNudgeBox/actions/actionCreators";
import {
  setAllNudgeData,
  removeConnectedIdsDelete,
  setWorkingNudgeData,
  getConditionEditOutcomes,
  getOutComesDispositions,
  getdispositionForAlternate,
  setIsScriptSelectVisited,
  getFollowUpsDispositions,
} from "../../../redux/strategy/nudgeCreation/actions";
import {
  hasAllEssentials,
  handleScriptValidation,
} from "../../../utils/validators/validators";
// import Modal from "../../generic/modal/Modal";
// import { ScriptConfiguration } from "../campaign_V2.0/scriptConfiguration/ScriptConfiguration";
// import { style } from "@mui/system";
// import { InfoToolTip } from "../../generic/infoToolTip/InfoToolTip";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { USECASE } from "../../../constants";


// const title = "Predue-10";

export default function EachNudgeRightSidePop() {
  const [selectedOne, setSelectedOne] = useState("");
  const [valid, setValid] = useState(false);
  const dispatch = useDispatch();
  const refD = useRef<any>();
  const navigate = useNavigate();
  const setting = useSelector(
    (state: RootState) =>
      state.strategyModuleReducer?.individualNudgeReducer.setting
  );

  const id = useSelector(
    (state: RootState) => state.strategyModuleReducer?.individualNudgeReducer.id
  );
  const show = useSelector(
    (state: RootState) =>
      state.strategyModuleReducer?.individualNudgeReducer.show
  );

  const dataIndividual = useSelector(
    (state: RootState) => state.strategyModuleReducer?.individualNudgeReducer
  );
  const allNudgeDetail = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.allNudgeDetail;
  });
  const disposition = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.outComesDispositions
      ?.data;
  });
  const dispositionAlternate = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.dispositionAlternate
      ?.data;
  });

  const followUpsData = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.followUpsDispositions
      ?.data;
  });


  const token = useSelector(
    (store: any) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const isBackFromScriptSelect = useSelector((store: any) => {
    return store.strategyModuleReducer?.individualNudgeReducer.isBackBtnClicked;
  });

  const currentUseCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseId
  );

  const accountId = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );
  const campaignData = useSelector(
    (store: any) =>
      store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
  );
  const uploadedSheetInStartegyFlow = useSelector(
    (store: RootState) =>
      store?.campaignModuleReducer?.dataUploadData?.uploadedCSVData?.data?.[0]
  );

  const showPaymentLinkStrategy = uploadedSheetInStartegyFlow?.details?.some((data: any) => data.isPaymentLink === true)

  const moduleSelectedScreen = useSelector(
    (store: RootState) => store?.baseScreen?.leftMenu?.highlightedModule
  );



  const handleClickAnyWhere = useCallback(
    (e: MouseEvent) => {
      if (refD.current && !refD.current.contains(e.target))
        if (!isBackFromScriptSelect) {
          dispatch(setShowNudgeSetting(false));
        } else {
          dispatch(setIsBackIconClicked(false));
        }
    },
    [show, refD, isBackFromScriptSelect]
  );

  function handleClickGoRight(e: React.MouseEvent) {
    e?.stopPropagation();
    dispatch(setShowNudgeSetting(false));
  }

  function handleApply() {
    if (!valid) return;
    const objectOfNudge = dataIndividual?.setting;
    const index = dataIndividual.id;
    const toUpdate = allNudgeDetail;
    const newUpdated = toUpdate?.map((e: any) => {
      if (objectOfNudge?.isDaywise) {
        if (objectOfNudge.flow === e?.flow) {
          const rangesArr = e?.ranges;
          rangesArr?.map((d: any) => {
            if (d?.days?.[0] === objectOfNudge?.days[0]) {
              const nudgeBoxArray = d?.nugeBoxes;
              nudgeBoxArray[index] = objectOfNudge;
              d["nugeBoxes"] = nudgeBoxArray;
              return d;
            }
          });
          return e;
        } else {
          return e;
        }
      } else {
        if (objectOfNudge.flow === e?.flow) {
          const nudgeBoxArray = e?.nugeBoxes;
          nudgeBoxArray[index] = objectOfNudge;
          e["nugeBoxes"] = nudgeBoxArray;
          return e;
        } else {
          return e;
        }
      }
    });

    dispatch(setAllNudgeData(newUpdated));
    dispatch(setShowNudgeSetting(false));
    setSelectedOne("");
  }
  console.log("Helo Abhi", allNudgeDetail, dataIndividual)
  //for delete
  function handleDelete() {
    const objectOfNudge = dataIndividual?.setting;
    const index = dataIndividual.id;
    objectOfNudge?.connectedTo?.map((e: any) =>
      dispatch(removeConnectedIdsDelete(e))
    );
    const toUpdate = allNudgeDetail;
    const newUpdated = toUpdate?.map((e: any) => {
      if (objectOfNudge?.isDaywise) {
        if (objectOfNudge.flow === e?.flow) {
          const rangesArr = e?.ranges;
          rangesArr?.map((d: any) => {
            if (d?.days?.[0] === objectOfNudge?.days[0]) {
              const nudgeBoxArray = d?.nugeBoxes;
              nudgeBoxArray.splice(index, 1);
              if (nudgeBoxArray?.length > 0) {
                d["nugeBoxes"] = nudgeBoxArray;
              } else {
                delete d["nugeBoxes"];
              }
              return d;
            }
          });
          return e;
        } else {
          return e;
        }
      } else {
        if (objectOfNudge.flow === e?.flow) {
          const nudgeBoxArray = e?.nugeBoxes;
          nudgeBoxArray.splice(index, 1);
          console.log("dg", nudgeBoxArray?.length);
          if (nudgeBoxArray?.length > 0) {
            e["nugeBoxes"] = nudgeBoxArray;
          } else {
            delete e["nugeBoxes"];
          }
          return e;
        } else {
          return e;
        }
      }
    });

    console.log("delete", newUpdated);
    dispatch(setAllNudgeData(newUpdated));
    dispatch(setShowNudgeSetting(false));
    dispatch(setWorkingNudgeData(""));
  }

  // for onchange channel clear condition

  // const toRemoveCondition = () => {
  //   const objectOfNudge = dataIndividual?.setting;
  //   const toUpdate = allNudgeDetail;
  //   const index = dataIndividual.id;
  //   const newUpdated = toUpdate?.map((e: any) => {
  //     if (objectOfNudge.isDaywise) {
  //       if (objectOfNudge.flow === e?.flow) {
  //         const rangesArr = e?.ranges;
  //         rangesArr?.map((d: any) => {
  //           if (d?.days[0] === objectOfNudge?.days[0]) {
  //             const nudgeBoxArray = d?.nugeBoxes;
  //             if (nudgeBoxArray[index]["channel"] !== objectOfNudge.channel) {
  //               delete nudgeBoxArray[index]["acceptedOutcome"];
  //               delete nudgeBoxArray[index]["unacceptedOutcome"];
  //               delete nudgeBoxArray[index]["communicationWays"];
  //             }
  //             nudgeBoxArray[index]["channel"] = objectOfNudge.channel;
  //             d["nugeBoxes"] = nudgeBoxArray;
  //             return d;
  //           }
  //         });
  //         return e;
  //       } else {
  //         return e;
  //       }
  //     } else {
  //       if (objectOfNudge.flow === e?.flow) {
  //         const nudgeBoxArray = e?.nugeBoxes;
  //         if (nudgeBoxArray[index]["channel"] !== objectOfNudge.channel) {
  //           delete nudgeBoxArray[index]["acceptedOutcome"];
  //           delete nudgeBoxArray[index]["unacceptedOutcome"];
  //           delete nudgeBoxArray[index]["communicationWays"];
  //         }
  //         nudgeBoxArray[index]["channel"] = objectOfNudge.channel;
  //         e["nugeBoxes"] = nudgeBoxArray;
  //         return e;
  //       } else {
  //         return e;
  //       }
  //     }
  //   });
  //   dispatch(setAllNudgeData(newUpdated));
  // };

  // const onChangeRemoveCondition = (data: any) => {
  //   if (
  //     setting.channel !== data.channel &&
  //     data.channel !== "" &&
  //     setting?.channel?.length
  //   ) {
  //     // toRemoveCondition()
  //     const dataToUpdate = setting;
  //     delete dataToUpdate["acceptedOutcome"];
  //     delete dataToUpdate["unacceptedOutcome"];
  //     delete dataToUpdate["communicationWays"];
  //     dispatch(setPropForEachNudge(dataToUpdate));
  //   }
  //   dispatch(setPropForEachNudge(data));
  // };

  function getChannelForFollowUp(channel: string) {
    const arrOfChannel = ["call", "whatsapp", "sms", "mail"];
    const arrOfChannelForFollowUps = ["Call", "WhatsApp", "SMS", "Mail"];

    const index = arrOfChannel.indexOf(channel.toLowerCase());
    if (index !== -1) {
      return arrOfChannelForFollowUps[index];
    } else {
      return "Not Found";
    }
  }

  useEffect(() => {
    const checkOutComes = disposition && Object.keys(disposition)?.length > 0;
    const checkFollowUps =
      followUpsData && Object.keys(followUpsData)?.length > 0;
    const checkUSeCaseValidation: any = currentUseCaseId !== USECASE?.debtCollection?.id
    if (hasAllEssentials(setting, checkOutComes, checkFollowUps, checkUSeCaseValidation))
      setValid(true);
    else {
      setValid(false);
    }
  }, [setting]);

  useEffect(() => {
    if (show) {
      window.addEventListener("click", handleClickAnyWhere);
    } else {
      window.removeEventListener("click", handleClickAnyWhere);
    }
    return () => window.removeEventListener("click", handleClickAnyWhere);
  }, [show, handleClickAnyWhere]);

  useEffect(() => {
    // if (!show) dispatch(clearNudgeSetings());
    if (show) setSelectedOne("");
  }, [show, id, setting.flow]);

  useEffect(() => {
    if (
      setting.channel &&
      disposition &&
      Object.keys(disposition).length === 0 &&
      followUpsData &&
      Object.keys(followUpsData).length === 0
    ) {
      dispatch(
        getOutComesDispositions({
          token: token,
          useCaseId: currentUseCaseId,
          accountId: accountId,
          selectedChannel: getChannelForFollowUp(setting.channel),
        })
      );

      dispatch(
        getFollowUpsDispositions({
          token: token,
          useCaseId: currentUseCaseId,
          accountId: accountId,
          selectedChannel: getChannelForFollowUp(setting.channel),
        })
      );
    }
  }, [setting.channel]);

  // for getting altenate number disposition
  useEffect(() => {
    if (dispositionAlternate?.length === 0) {
      dispatch(getdispositionForAlternate({ token: token }));
    }
  }, []);

  return (
    <>
      {
        <div
          className={`${styles.wrapperclass_popup}  ${!show ? styles.goright : ""
            }`}
          ref={refD}
        >
          <div className={styles.divgoright}>
            <div onClick={handleClickGoRight} className={styles.gorighticon}>
              <img src={gohide} alt=">" id="auto_gohide" />
            </div>
          </div>
          <div className={styles.titlewrapper}>
            <div className={styles.titlewrapper1}>
              <img src={blackEditIcon} alt="e" id="auto_editIcons" />
              <p>{setting.flow}</p>
            </div>
            <div onClick={() => handleDelete()} className={styles.deletediv}>
              <img src={deleteIcon} alt="del" id="auto_deleteic" />
            </div>
          </div>
          <div className={styles.optionsMenu}>
            {/* <div className={styles.divmenu}></div> */}
            <Title
              onClick={setSelectedOne}
              onClickExtraFunc={() => {
                dispatch(setIsScriptSelectVisited(true));
                navigate("/strategy/scriptselection");
              }}
              open={selectedOne == "Script Configuration"}
              warningIcon={
                !(
                  "scriptConfiguration" in setting &&
                  handleScriptValidation(
                    setting?.scriptConfiguration,
                    setting?.flow
                  )
                )
              }
              title={
                !(
                  "scriptConfiguration" in setting &&
                  handleScriptValidation(
                    setting?.scriptConfiguration,
                    setting?.flow
                  )
                )
                  ? "Script Selection"
                  : "Script Selected"
              }
              actualKey={"Script Configuration"}
              selectedKey={selectedOne}
              hideArrowIcon={true}
            >
              <></>
            </Title>
            {/* <Title
              onClick={setSelectedOne}
              open={selectedOne == "Channel"}
              warningIcon={!("channel" in setting && setting.channel.length)}
              title={"Channel"}
              actualKey={"Channel"}
              selectedKey={selectedOne}
            >
              <Channel
                disp={(data: any) => {
                  onChangeRemoveCondition(data);
                }}
                defaultValue={setting.channel ? setting.channel : ""}
              />
            </Title>
            {setting.channel == "whatsApp" && (
              <Title
                onClick={setSelectedOne}
                open={selectedOne == "Communication Ways"}
                warningIcon={
                  !(
                    "communicationWays" in setting &&
                    setting.communicationWays?.length
                  )
                }
                title={"Communication Ways"}
                actualKey={"Communication Ways"}
                selectedKey={selectedOne}
              >
                <CommunicationWays
                  disp={(data: any) => dispatch(setPropForEachNudge(data))}
                  defaultValue={setting.communicationWays}
                />
              </Title>
            )} */}
            {disposition && Object.keys(disposition).length > 0 ? (
              <Title
                onClick={setSelectedOne}
                open={selectedOne == "Conditions"}
                warningIcon={
                  !(
                    "acceptedOutcome" in setting &&
                    Object.keys(setting["acceptedOutcome"]).length &&
                    "unacceptedOutcome" in setting &&
                    Object.keys(setting["unacceptedOutcome"]).length
                  )
                }
                title={"Conditions"}
                actualKey={"Conditions"}
                selectedKey={selectedOne}
              >
                <Conditions
                  disp={(data: any) => dispatch(setPropForEachNudge(data))}
                  defaultValue={setting}
                  acceptedOutcome={disposition?.["Accepted Outcome"]}
                  unacceptedOutcome={disposition?.["Unaccepted Outcome"]}
                />
              </Title>
            ) : null}
            {followUpsData &&
              Object.keys(followUpsData).length > 0 &&
              "acceptedOutcome" in setting &&
              Object.keys(setting["acceptedOutcome"]).length &&
              "unacceptedOutcome" in setting &&
              Object.keys(setting["unacceptedOutcome"]).length ? (
              <Title
                onClick={setSelectedOne}
                open={selectedOne == "followUps"}
                warningIcon={
                  !(
                    "followUps" in setting &&
                    Object.values(setting.followUps).length > 0 &&
                    !_.some(setting.followUps, _.isUndefined)
                  )
                }
                title={"Retry Time"}
                actualKey={"followUps"}
                selectedKey={selectedOne}
              >
                <FollowUps
                  defaultValue={setting.followUps}
                  onChangeValue={(data: any) =>
                    dispatch(setPropForEachNudge(data))
                  }
                  options={followUpsData}
                  optionsAccepted={
                    "acceptedOutcome" in setting &&
                      Object.keys(setting["acceptedOutcome"]).length
                      ? Object.keys(setting["acceptedOutcome"])
                      : []
                  }
                  optionsUnAccepted={
                    "unacceptedOutcome" in setting &&
                      Object.keys(setting["unacceptedOutcome"]).length
                      ? Object.keys(setting["unacceptedOutcome"])
                      : []
                  }
                />
              </Title>
            ) : null}
            {/* <Title
              onClick={setSelectedOne}
              open={selectedOne == "Variation"}
              warningIcon={
                !("variation" in setting && setting?.variation?.length)
              }
              title={"Variation"}
              actualKey={"Variation"}
              selectedKey={selectedOne}
            >
              <Variation
                defaultValue={setting.variation}
                disp={(data: any) => dispatch(setPropForEachNudge(data))}
              />
            </Title> */}
            {/* <Title
              onClick={setSelectedOne}
              open={selectedOne == "Agent Desktop"}
              warningIcon={
                !(
                  "agentDesktop" in setting &&
                  (setting?.agentDesktop === false ||
                    setting?.agentDesktop === true)
                )
              }
              title={"Agent Desktop"}
              actualKey={"Agent Desktop"}
              selectedKey={selectedOne}
            >
              <AgentDesktop
                defaultValue={setting.agentDesktop}
                disp={(data: any) => dispatch(setPropForEachNudge(data))}
              />
            </Title> */}
            <Title
              onClick={setSelectedOne}
              open={selectedOne == "Timing"}
              warningIcon={
                !(
                  "timing" in setting &&
                  setting.timing.length &&
                  setting["timing"][0]?.split(":").join() <
                  setting["timing"][1]?.split(":").join()
                )
              }
              title={"Nudge Duration"}
              actualKey={"Timing"}
              selectedKey={selectedOne}
            >
              <Timing
                defaultValue={setting.timing}
                disp={(data: any) => dispatch(setPropForEachNudge(data))}
              />
            </Title>
            <Title
              onClick={setSelectedOne}
              open={selectedOne == "reattempt"}
              warningIcon={!("reattempt" in setting && setting?.reattempt > 0)}
              title={"Attempt Count"}
              actualKey={"reattempt"}
              selectedKey={selectedOne}
            >
              <AttemptCount
                defaultValue={setting.reattempt}
                disp={(data: any) => dispatch(setPropForEachNudge(data))}
              />
            </Title>
            {
              (moduleSelectedScreen === "Strategy" ? showPaymentLinkStrategy : campaignData?.isPaymentLink)
              && USECASE?.debtCollection?.id === currentUseCaseId && <Title
                onClick={setSelectedOne}
                open={selectedOne == "Payment Link"}
                warningIcon={false}
                title={"Payment Link"}
                actualKey={"Payment Link"}
                selectedKey={selectedOne}
              >

                <PaymentLink
                  disp={(data: any) => dispatch(setPropForEachNudge(data))}
                  defaultValue={setting.paymentLinkDisposition}
                  paymentLink={campaignData?.isPaymentLink}
                  acceptedOutcome={disposition?.["Accepted Outcome"]}
                  unacceptedOutcome={disposition?.["Unaccepted Outcome"]}
                />
              </Title>
            }
            {setting.channel?.toLowerCase() !== "whatsapp" && USECASE?.debtCollection?.id === currentUseCaseId ? (
              <Title
                onClick={setSelectedOne}
                open={selectedOne == "callBackThreshold"}
                warningIcon={
                  !(
                    "callBackThreshold" in setting &&
                    Object.keys(setting.callBackThreshold)?.length > 1 &&
                    setting.callBackThreshold.days >= 0
                  )
                }
                title={"Call Back Threshold"}
                actualKey={"callBackThreshold"}
                selectedKey={selectedOne}
              >
                <Callback
                  defaultValue={setting.callBackThreshold}
                  disp={(data: any) => dispatch(setPropForEachNudge(data))}
                />
              </Title>
            ) : null}
            {
              USECASE?.debtCollection?.id === currentUseCaseId && (
                <Title
                  onClick={setSelectedOne}
                  open={selectedOne == "promiseToPayThreshold"}
                  warningIcon={
                    !(
                      "promiseToPayThreshold" in setting &&
                      Object.keys(setting.promiseToPayThreshold)?.length > 1 &&
                      setting.promiseToPayThreshold.days >= 0
                    )
                  }
                  title={"Promise To Pay Threshold"}
                  actualKey={"promiseToPayThreshold"}
                  selectedKey={selectedOne}
                >
                  <PromiseToPayThreshold
                    defaultValue={setting.promiseToPayThreshold}
                    disp={(data: any) => dispatch(setPropForEachNudge(data))}
                  />
                </Title>
              )
            }


            <Title
              onClick={setSelectedOne}
              open={selectedOne == "alternateNumber"}
              warningIcon={false}
              title={"Alternate Number"}
              actualKey={"alternateNumber"}
              selectedKey={selectedOne}
            >
              <AlternateNumber
                defaultValue={setting}
                onChangeValue={(data: any) =>
                  dispatch(setPropForEachNudge(data))
                }
                options={dispositionAlternate}
              />
            </Title>

            {/* <Title
              onClick={setSelectedOne}
              open={selectedOne == "channelLanguageMapping"}
              warningIcon={
                !(
                  "channelLanguageMapping" in setting &&
                  setting.communicationWays?.length
                )
              }
              title={"Channel's Language Mapping"}
              actualKey={"channelLanguageMapping"}
              selectedKey={selectedOne}
              infoIcon={questionIcon}
            >
              <ChannelsMapping />
            </Title> */}
          </div>

          <div className={styles.btnDiv}>
            <button
              className={`${!valid ? styles.disbaledsbutton : styles.aactivent
                }`}
              onClick={handleApply}
            >
              Apply
            </button>
          </div>
        </div>
      }
    </>
  );
}
