import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
// import { routesToBeRendered } from "./routes.functions";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
//import Salesforce from "../components/moduleComponents/integration/salesforces/salesforce";
import { error404 } from "../theme/assets/svg";
import HomePage from "../screens/onBoarding/Home";
import BaseScreen from "../screens/baseScreeen/BaseScreen";
import Logger from "../screens/logger/Logger";
import Analytics from "../screens/analytics/AnalyticsScreen";
import ConnectorPage from "../components/moduleComponents/demo/connectorPage/ConnectorPage";
import DemoLogIn from "../screens/demoScreen/demoLoginInPage/DemoLogIn";
import Admin from "../screens/admin/Admin";
import Billing from "../screens/billing/billing";
import Operation from "../screens/operation/Operation";
import { StrategyScreen } from "../screens/strategy/StrategyScreen";
import Campaign from "../screens/campaign/campaignTable/Campaign";
import CampaignTestingInfo from "../screens/campaign/dynamicCampaign/CampaignTesting";
import Bot from "../screens/botManager/bot";
import UserAdmin from "../screens/admin/Admin";
// import SalesAndOnboarding from "../screens/campaign/salesAndOnboarding/SalesAndOnboarding";
import {
  ACCESS_TOKEN_EXPIRY_TIME,
  ACCOUNT_TYPE,
  screenType,
} from "../constants";
import { RootState } from "../redux/rootStore";
import styles from "./Routes.module.scss";
// import { RootState } from "../redux";
// import {LOGIN} from "./Path.js"
import ProfileScreen from "../screens/profile/ProfileScreen";
import NoDatamodel from "../components/generic/noDatamodel/NoDatamodel";
import LogOutComponent from "../components/moduleComponents/logOut/LogoutComponent";
/* Development ---------- */
import DevScreen from "../screens/devScreen/DevComponent";

/* Agent Desktop ---------- */
import CallLogger from "../screens/agentDesktop/callLogger/CallLogger";
import AgentDashboard from "../screens/agentDesktop/Dashboard";
import DummyScreen from "../screens/devScreen/DevComponent";
import AdminDashboardScreen from "../screens/agentDesktop/adminDashboardScreen/AdminDashboardScreen";
import AdminPerformanceScreen from "../screens/agentDesktop/adminPerformanceScreen/AdminPerformanceScreen";
import BaseScreenAD from "../screens/agentDesktop/BaseScreenAD";
import AgentDashboardScreen from "../screens/agentDesktop/agentDashboardScreen/AgentDashboardScreen";
import ChatLogger from "../screens/agentDesktop/chatLogger/ChatLogger";
import { UserPersonaScreen } from "../components/moduleComponents/strategyComponents/userPersonaScreen/UserPersonaScreen";
import CollectionAgentConfigScreen from "../components/moduleComponents/campaign_V2.0/collectionAgentConfig/CollectionAgentConfigScreen";
import AllAgentPage from "../components/moduleComponents/campaign_V2.0/allAgentsPage/AllAgentPage";
import CampaignNew from "../screens/campaign_V2.0/campaignTable/Campaign";
import { CampaignDetailsScreen } from "../components/moduleComponents/campaign_V2.0/campaignDetailsScreen/CampaignDetailsScreen";
import { DataUploadScreen } from "../components/moduleComponents/campaign_V2.0/dataUploadScreen/DataUploadScreen";
import { EditCSVScreen } from "../components/moduleComponents/campaign_V2.0/dataUploadScreen/editCSVScreen/EditCSVScreen";
import NudgeCanvas from "../screens/strategy/nudgeFinal/NudgeCanvas";
import { ScriptConfiguration } from "../components/moduleComponents/campaign_V2.0/scriptConfiguration/ScriptConfiguration";
import { StrategySelectScreen } from "../components/moduleComponents/campaign_V2.0/strategySelectScreen/StrategySelectScreen";
import DrawLine from "../components/moduleComponents/strategyComponents/nudgeCreation/drawLine/DrawLine";

import SchedulerSetting from "../components/moduleComponents/campaign_V2.0/schedulerSetting/SchedulerSetting";
import Line from "../components/moduleComponents/strategyComponents/nudgeCreation/line/Line";
import DiallerSection from "../components/moduleComponents/campaign/diallerSection/DiallerSection";

import CrmHeaderFull from "../components/crm/moduleComponent/crmHeaderFull/CrmHeaderFull";
import MaiaAnalyticsPage from "../screens/maiaAnalytics/MaiaAnalyticsPage";
import CustomerProfile from "../components/crm/moduleComponent/customerProfile/CustomerProfile";
import SignUp from "../screens/onBoarding/signUp/SignUp";

//SignUpImports
import { ProductPage } from "../screens/signUpFlow/productPage/ProductPage";
import { TeamsPage } from "../screens/signUpFlow/teamsPage/TeamsPage";
import LoginSignUpEntry from "../screens/onBoarding/LoginSignUpEntry/LoginSignUpEntry";
import LoginFields from "../components/moduleComponents/onBoarding/login/loginField/LoginFields";
import CreatePassword from "../screens/onBoarding/createPassword/CreatePassword";
import ExperienceNow from "../components/moduleComponents/signUpFlow/experienceNow/ExperienceNow";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch } from "react-redux";
import { refreshTokenApiRequest } from "../redux/onboarding/login/actions";
import { ACCESS_TOKEN_REFRESH_TIME } from "../constants";
import secureLocalStorage from "react-secure-storage";
import SMSSettingPage from "../screens/campaign/smsSchedulerSettingPage/SMSsettingPage";
import MailSettingPage from "../screens/campaign/mailSchedulerSettingPage/MailsettingPage";
import ChannelSummary from "../components/moduleComponents/campaign/omniChannel/channelSummary/ChanneSummary";
import SMSschedule from "../components/moduleComponents/campaign/omniChannel/smsScheduler/SMSschedule";
import TokenUtil from "./TokenUtil";
import { roleBaseRouteValidation } from "./routers.fn";
import routes from "./routes";
import { IntegrationScreen } from "../screens/integration_V2.0/IntegrationScreen";
import ExclusionsListContainer from "../components/moduleComponents/exclusionsList/exclusionsListContainer/exclusionsListContainer";
import ProtectedRoute from "./ProtectedRoute";
import { ROLES } from "../constants";
import AxiosUtil from "./AxiosUtil";
import { GlobalPubNub } from "../utils/utils-component";
import { EmailIntegrationPopUp } from "../components/moduleComponents/integration_V2.0/emailScreen/emailIntegrationPopUp/EmailIntegrationPopUp";
import SelectPlansPage from "../components/moduleComponents/paymentModuleComponents/selectPlans/selectPlansPage/SelectPlansPage";
import { PaymentScreen } from "../screens/profile/paymentScreen/PaymentScreen";
import { ComparePricingScreen } from "../screens/profile/paymentScreen/comparePricingScreen/ComparePricingScreen";
import { BillingInvoiceScreen } from "../screens/profile/paymentScreen/billingInvoiceScreen/BillingInvoiceScreen";
import OrderSummaryScreen from "../screens/profile/paymentScreen/orderSummaryScreen/OrderSummaryScreen";
import AddonsScreen from "../screens/profile/paymentScreen/addonScreen/AddonsScreen";
import SendEmail from "../components/generic/sendEmailPopUp/SendEmail";
import MaiaSummaryScreen from "../screens/profile/paymentScreen/maiaSummary/MaiaSummaryScreen";
import { ScriptConfigScreen } from "../components/moduleComponents/campaign_V2.0/scriptConfigScreen/ScriptConfigScreen";
import { ScriptSelection } from "../components/moduleComponents/campaign_V2.0/scriptSelection/ScriptSelection";
import CallingListTable from "../components/moduleComponents/campaign_V2.0/callingListTable/CallingListTable";
import { DataUploadComp } from "../components/moduleComponents/campaign_V2.0/dataUploadScreen/dataUploadComp/DataUploadComp";
import ManyFileStatus from "../components/moduleComponents/campaign_V2.0/manyFileUploadStatus/ManyFileStatus";
import WorkSpaceSalesOnBoarding from "../components/moduleComponents/strategyComponents/workSpaceForeSalesOnBoarding/WorkSpaceSalesOnBoarding";
import { trueSign } from "../theme/assets/genericSvg";


const Routers = () => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState();
  //const [role, setRole] = useState();
  const [rev, setRev] = useState([]);
  useEffect(() => console.log(rev, "-=-=-09"), [rev]);

  const tokenRedux = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo?.userSessionDetails?.accessToken
  );
  const sphotClient = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo?.userDetail?.accountDetails[0]?.name
  );
  const userId = useSelector(
    (state: RootState) => state.loginReducer?.userLoginInfo?.userDetail?._id
  );
  const role = useSelector(
    (state: RootState) => state.loginReducer.userLoginInfo?.userDetail?.role
  );
  const dispatch = useDispatch();
  const defaultSelectedModule = useSelector(
    (store: RootState) => store?.baseScreen?.leftMenu?.defaultSelectedModule
  );
  const clientNameByDropdown = useSelector(
    (store: RootState) => store.campaignReducer?.campaignClientName
  );
  const currentSelectedTeamId = useSelector(
    (store: RootState) => store.loginReducer?.currentTeam?.id
  );
  const isProductPageShown = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo?.userDetail?.isProductPageShown
  );
  const isTeamPageVisited = useSelector(
    (store: RootState) =>
      store.signUpModuleReducer.teamsPageReducer.isTeamPageVisited
  );
  const accountType = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo.accountDetails?.[0]?.type
  );
  const userName = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.userDetail?.userName
  );
  const teamUseCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseId
  );
  const myPlanData: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );
  const currentSelectedTeam = useSelector(
    (store: RootState) => store.loginReducer?.currentTeam

  );
  console.log("team Data", currentSelectedTeam)
  /*****************************  other hooks  *****************************/
  const _Mount = useRef(false);
  const navigate = useNavigate();
  function backToHome() {
    navigate("/");
  }

  /*****************************  useEffects  *****************************/
  // routing based on default module
  useEffect(() => {
    if (
      ACCOUNT_TYPE.internal === accountType &&
      [
        ROLES.campaignAnalyst.name,
        ROLES.campaignManager.name,
        ROLES.guest.name,
      ].includes(role)
    )
      return;
    if (_Mount.current) {
      if (isProductPageShown && !isTeamPageVisited) return;
      if (defaultSelectedModule?.url) {
        navigate(`/${defaultSelectedModule.url}`, { replace: true });
      }
    } else _Mount.current = true;
  }, [defaultSelectedModule?.url, currentSelectedTeamId]);

  // auth routing
  useEffect(() => {
    const path = window.location.pathname;
    if ("/404" === path) return;
    if (!role) return;

    const redirect = roleBaseRouteValidation(role);
    if (redirect.shouldRedirect) {
      if (redirect.redirectRoute) {
        navigate(`${redirect.redirectRoute}?redirected=true`, {
          replace: true,
        });
      } else {
        navigate(`/404?redirected=true`, {
          replace: true,
        });
      }
    }
  }, [window.location.pathname]);

  // auto logout on multiple tab account opening.
  useEffect(() => {
    let storageListener: EventListener;
    if (userId) {
      storageListener = () => {
        const localUserId = window.localStorage.getItem("userId");
        if (localUserId && localUserId !== userId) {
          navigate(screenType.singOut.routeName);
          window.location.reload();
        }
      };
      window.addEventListener("storage", storageListener);
    }
    return () => window.removeEventListener("storage", storageListener);
  }, [userId]);

  /*****************************  JSX  *****************************/
  if (!tokenRedux) {
    return (
      <>
        <TokenUtil />
        <AxiosUtil />
        {false && <GlobalPubNub />}
        {/* {console.log("i am in non token ")} */}
        <Routes>
          <Route path={routes.HOME_PAGE} element={<HomePage />}></Route>

          <Route element={<LoginSignUpEntry />}>
            <Route path={routes.SIGNUP} element={<SignUp />} />
            <Route path={routes.SIGNUP_WITH_TOKEN} element={<SignUp />} />
            {/* {searchParams.get("emailId") &&
              searchParams.get("accountId") &&
              searchParams.get("teamId") && (
                <Route path="signup/:id" element={<SignUp />} />
              )} */}
            <Route
              path={routes.LOGIN}
              element={<LoginFields setToken={setToken} />}
            />
            {/* <Route
              path="createpassword/:token/:accountName/:email"
              element={<CreatePassword />}
            /> */}
            <Route
              path={routes.CREATE_PASSWORD_WITH_TOKEN}
              element={<CreatePassword setToken={setToken} />}
            />
            <Route
              path={routes.RESET_PASSWORD}
              element={<CreatePassword resetPassword={true} />}
            ></Route>
          </Route>

          <Route
            path={routes.SIGN_OUT}
            element={
              <LogOutComponent
                resetToken={() => {
                  setToken(undefined);
                }}
              />
            }
          />
          {/* <Route path="/homepage" element={<HomePage />}></Route> */}
          {window.location.hostname === "localhost" && (
            <Route path={routes.DEV_ROUTE} element={<DevScreen />} />
          )}
          <Route
            path={routes.EMAIL_DNS_VERIFY_PAGE}
            element={<EmailIntegrationPopUp />}
          />
          <Route path="*" element={<Navigate to="/" />}></Route>
        </Routes>
      </>
    );
  }
  return (
    <>
      <TokenUtil />
      <AxiosUtil />
      <GlobalPubNub />
      <Routes>
        {/* <Route path="/homepage" element={<HomePage />} /> */}
        <Route
          path={routes.EMAIL_DNS_VERIFY_PAGE}
          element={<EmailIntegrationPopUp />}
        />
        <Route element={<LoginSignUpEntry />}>
          <Route path={routes.SIGNUP} element={<SignUp />} />
          <Route path={routes.SIGNUP_WITH_TOKEN} element={<SignUp />} />
          {/* {searchParams.get("emailId") &&
            searchParams.get("accountId") &&
            searchParams.get("teamId") && (
              <Route path="signup/:id" element={<SignUp />} />
            )} */}
          <Route
            path={routes.LOGIN}
            element={<LoginFields setToken={setToken} />}
          />
        </Route>
        {window.location.hostname === "localhost" && (
          <Route path={routes.DEV_ROUTE} element={<DevScreen />} />
        )}
        {/* Agent Desktop */}
        <Route path={routes.AGENT_DESKTOP} element={<BaseScreenAD />}>
          <Route
            key={routes.PROFILE}
            path={routes.PROFILE_ALL_SUB_ROUTES}
            element={<ProfileScreen />}
          />
          <Route element={<PaymentScreen />}>
            <Route
              key={routes.PLAN_DETAILS}
              path={routes.PLAN_DETAILS}
              element={<SelectPlansPage />}
            />
            <Route
              key={routes.COMPARE_PLANS}
              path={routes.COMPARE_PLANS}
              element={<ComparePricingScreen />}
            />
            <Route
              key={routes.INVOICES_SCREEN}
              path={routes.INVOICES_SCREEN}
              element={<BillingInvoiceScreen />}
            />
          </Route>
          <Route
            path={routes.AGENT_DASHBOARD}
            element={<AgentDashboardScreen />}
          />
          <Route path={routes.AGENT_CALL} element={<CallLogger />} />
          <Route path={routes.AGENT_CHAT} element={<ChatLogger />} />
          <Route
            path={routes.ADMIN_DASHBOARD}
            element={<AdminDashboardScreen />}
          />
          <Route
            path={routes.ADMIN_PERFORMANCE}
            element={<AdminPerformanceScreen />}
          />
        </Route>
        {/* Agent Desktop end -----> */}
        <Route element={<BaseScreen />}>
          <Route path={routes.DASHBOARD} element={<Analytics />} />
          {/* <Route path="dev_billing" element={<SelectPlansPage />} /> */}
          <Route path={routes.CALL_LOGGER} element={<Logger />} />
          <Route
            path={routes.CONNECTOR_PAGE}
            element={<IntegrationScreen />}
          // element={<ConnectorPage hideProgressBar="hide" />}
          />
          <Route path={routes.DEMO} element={<CampaignTestingInfo />} />
          <Route path={routes.CALLING_LIST} element={<CallingListTable />} />
          {/* due to route issue  start */}
          <Route
            path={routes.CAMPAIGN_CREATION}
            element={<CampaignDetailsScreen />}
          />
          <Route
            path={routes.CAMPAIGN_DATA_UPLOAD}
            element={<DataUploadScreen />}
          />
          <Route path={routes.EDIT_CSV_SCREEN} element={<EditCSVScreen />} />

          <Route path={routes.USER_PERSONAC} element={<UserPersonaScreen />} />
          <Route
            path={routes.STRATEGY_SELECTION}
            element={<StrategySelectScreen />}
          />
          <Route path={routes.ALL_AGENT_PAGE} element={<AllAgentPage />} />
          <Route
            path={routes.SCHEDULER_SETTING}
            element={<SchedulerSetting />}
          />
          <Route path={routes.NUDGE_CREATIONC} element={<NudgeCanvas />} />
          {/* <Route
            path={routes.SCRIPT_CONFIGURATION}
            element={<ScriptConfiguration showPopUp={true} />}
          /> */}
          <Route path={routes.SCRIPT_SELECTION} element={<ScriptSelection />} />
          <Route
            path={routes.SCRIPT_CONFIGURATION_SCREEN}
            element={<ScriptConfigScreen />}
          />
          <Route path={routes.DIALER_SECTION} element={<DiallerSection />} />
          <Route
            path={routes.CREATE_AGENT}
            element={<CollectionAgentConfigScreen />}
          />
          {/* due to  route issue  end  */}
          <Route
            path={routes.CAMPAIGN_MANGER}
            element={
              currentSelectedTeam?.isStrategy === false
                ? (
                  <Campaign /> // old Flow Campaign 
                ) : (

                  <CampaignNew /> // New Flow Campaign with Strategy 
                )
            }
          >
            {/* <Route
              path={routes.CAMPAIGN_CREATION}
              element={<CampaignDetailsScreen />}
            /> */}
            {/* <Route path={routes.USER_PERSONA} element={<UserPersonaScreen />} /> */}
            {/* <Route
              path={routes.STRATEGY_SELECTION}
              element={<StrategySelectScreen />}
            /> */}
            {/* <Route path={routes.ALL_AGENT_PAGE} element={<AllAgentPage />} /> */}
            {/* <Route
              path={routes.SCHEDULER_SETTING}
              element={<SchedulerSetting />}
            /> */}
            {/* <Route path={routes.NUDGE_CREATION} element={<NudgeCanvas />} /> */}
            {/* <Route path={routes.DIALER_SECTION} element={<DiallerSection />} /> */}
            {/* <Route
              path={routes.CREATE_AGENT}
              element={<CollectionAgentConfigScreen />}
            /> */}
          </Route>
          <Route path="try" element={<WorkSpaceSalesOnBoarding />} />
          <Route path={routes.BILLING} element={<Billing />} />
          {/**due to route issue  --- */}
          <Route path={routes.USER_PERSONA} element={<UserPersonaScreen />} />
          <Route path={routes.NUDGE_CREATION} element={<NudgeCanvas />} />
          {/**due to route issue  --- end */}
          <Route path={routes.STRATEGY} element={<StrategyScreen />} />
          <Route
            path={routes.STRATEGY_DATA_UPLOAD}
            element={<DataUploadComp />}
          />
          {/* <Route path={routes.USER_PERSONA} element={<UserPersonaScreen />} /> */}
          {/* <Route path={routes.NUDGE_CREATION} element={<NudgeCanvas />} /> */}
          {/* <Route path="strategy" element={<Operation />} /> */}

          <Route path={routes.ADMIN} element={<Admin />} />
          <Route
            element={
              <ProtectedRoute
                isAuthorized={ACCOUNT_TYPE.internal === accountType}
              />
            }
          >
            <Route
              path={routes.CAMPAIGN_TESTING_INFO}
              element={<CampaignTestingInfo />}
            />
          </Route>
          <Route path={routes.BOT_MANAGER} element={<Bot />} />
          <Route path={routes.OPERATION} element={<Operation />} />
          <Route path={routes.USER_LIST} element={<TeamsPage />} />
          {/*          <Route path="/userlist" element={<UserAdmin />} /> */}
          <Route path={routes.UPLOAD} element={<ExperienceNow />} />
          <Route
            key={routes.PROFILE}
            path={routes.PROFILE_ALL_SUB_ROUTES}
            element={<ProfileScreen />}
          />
          <Route element={<PaymentScreen />}>
            <Route
              key={routes.PLAN_DETAILS}
              path={routes.PLAN_DETAILS}
              element={<SelectPlansPage />}
            />
            <Route
              key={routes.COMPARE_PLANS}
              path={routes.COMPARE_PLANS}
              element={<ComparePricingScreen />}
            />
            <Route
              key={routes.ADD_TO_CART}
              path={routes.ADD_TO_CART}
              element={<OrderSummaryScreen />}
            />
            <Route
              key={routes.MAIA_SUMMARY}
              path={routes.MAIA_SUMMARY}
              element={<MaiaSummaryScreen />}
            />
            <Route
              key={routes.INVOICES_SCREEN}
              path={routes.INVOICES_SCREEN}
              element={<BillingInvoiceScreen />}
            />
            <Route
              key={routes.ADD_ONS_INVOICES_SCREEN}
              path={routes.ADD_ONS_INVOICES_SCREEN}
              element={<AddonsScreen />}
            />
          </Route>
          <Route path={routes.PRODUCT_PAGE} element={<ProductPage />}></Route>
          <Route path={routes.TEAMS_PAGE} element={<TeamsPage />}></Route>
          <Route path={routes.CRM} element={<CrmHeaderFull />} />
          <Route path={routes.CUSTOMER} element={<CustomerProfile />} />
          <Route path={routes.MAIA_ANALYTICS} element={<MaiaAnalyticsPage />} />
          {/* exclusion list routes */}
          <Route
            element={
              <ProtectedRoute
                isAuthorized={userName === "sudhanshu.shekhar@saarthi.ai"}
              />
            }
          >
            <Route
              path={routes.EXCLUSIONS_LIST}
              element={<ExclusionsListContainer />}
            />
          </Route>
          <Route
            path={routes.EXCLUSIONS_LIST_CLIENT}
            element={<ExclusionsListContainer />}
          />
          <Route
            path={routes.SIGN_OUT}
            element={
              <LogOutComponent
                resetToken={() => {
                  setToken(undefined);
                }}
              />
            }
          />

          {/******************************  route handler  *****************************/}
          <Route
            path={routes.HOME_PAGE}
            element={
              defaultSelectedModule.url && (
                <Navigate to={defaultSelectedModule.url} replace={true} />
              )
            }
          />
          <Route
            path="*"
            element={
              <div className={styles.error}>
                <NoDatamodel
                  srcImg={error404}
                  message="Ooops!! We can’t seem to find a page you are looking for."
                  extraCss={{ message: styles.message, img: styles.img }}
                  button={{ message: "Back to Home", onClick: backToHome }}
                ></NoDatamodel>
              </div>
            }
          />
          {/* exclusion list routes
          <Route
            path="/exclusions-list"
            element={<ExclusionsListContainer />}
          />
          <Route
            path="/exclusions-list-client"
            element={<ExclusionsListContainer />}
          /> */}
        </Route>
      </Routes>
    </>
  );
};

export default Routers;
