import React from "react";
import styles from "./Loader.module.scss";
// import Spinner from "../../../theme/assets/svg/lodder/Saarthiloadinggif.gif";
import { Spinner } from "../../../../theme/assets/agentDesktopSvg";
import { Threedots }from "../../../../theme/assets/agentDesktopSvg";


// interface props {
//   default: string;
// }


export default function Loader(props: any) {
  return (
    <>
      <div className={styles.loaderSaarthi}>
                <img className={styles.loaderSaarthiGif}
                  src={props.isDot?Threedots:Spinner}
                  alt="spinner"
                />
        </div>
    </>
  );
}
