// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yiNP4WKWAmoMz66Co3fZ{width:100%;height:80px;background:#fff;box-shadow:0px 0px 16px rgba(0,0,0,.06);border-radius:2px;margin-top:2px}.uKTbpR80wH06YgKoJFwo{width:100%;color:#7a7979;font-family:"Roboto";font-style:normal;font-weight:400;font-size:14px;line-height:20px;cursor:pointer;border:none;padding:10px;background-color:#fff}.uKTbpR80wH06YgKoJFwo:hover{width:100%;color:#fff;font-family:"Roboto";font-style:normal;font-weight:400;font-size:14px;line-height:20px;cursor:pointer;border:none;padding:10px;background-color:#2f80ed}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/chartDownloadComponent/ChartDownloadComponent.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,eAAA,CACA,uCAAA,CACA,iBAAA,CACA,cAAA,CAGF,sBACE,UAAA,CACA,aAAA,CACA,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,cAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CAGF,4BACE,UAAA,CACA,UAAA,CACA,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,cAAA,CACA,WAAA,CACA,YAAA,CACA,wBAAA","sourcesContent":[".chartDownloadTopDiv {\n  width: 100%;\n  height: 80px;\n  background: #ffffff;\n  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06);\n  border-radius: 2px;\n  margin-top: 2px;\n}\n\n.downloadData {\n  width: 100%;\n  color: #7a7979;\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  cursor: pointer;\n  border: none;\n  padding: 10px;\n  background-color: white;\n}\n\n.downloadData:hover {\n  width: 100%;\n  color: white;\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  cursor: pointer;\n  border: none;\n  padding: 10px;\n  background-color: #2f80ed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartDownloadTopDiv": `yiNP4WKWAmoMz66Co3fZ`,
	"downloadData": `uKTbpR80wH06YgKoJFwo`
};
export default ___CSS_LOADER_EXPORT___;
