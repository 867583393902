import actionTypes from "../actionTypes";

export const setSelectedTab = (payload:any) => {
  return {
    type: actionTypes.SET_SELECTED_TAB,
    payload: payload,
  };
};

export const setBackendRefernceKey = (payload:any) => {
 console.log(payload,"999")
    return {
      type: actionTypes.SET_BACKEND_REFERNCE_KEY,
      payload: payload,
    };
  };