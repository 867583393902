import React, { useEffect, useState } from "react";
import { mailSentImage } from "../../../../../theme/assets/svg";
import { crossThinIcon } from "../../../../../theme/assets/genericSvg";
import "./EmailVerificationPopUp.scss";
import ErrorMessage from "../../../../generic/errorMessage/ErrorMessage";

interface props {
  handleCrossClick: () => void;
  handleResendVerificationLink: () => void;
  error: string;
  extraClassError: string;
}

const EmailVerificationPopUp = (props: props) => {
  /* useStates --------- */
  const [timer, setTimer] = useState<number>(30);
  const [resendLink, setResendLink] = useState(true);

  /* useEffects */
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1);
      if (timer < 0) clearInterval(interval);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  /* handler */
  const handleResendVerificationLink = () => {
    props.handleResendVerificationLink();
    setTimer(30);
    setResendLink(false);
  };

  return (
    <div className="email-verification-popup-wrapper">
      <div className="cross-btn">
        <img
          className="btn"
          src={crossThinIcon}
          alt=""
          onClick={props.handleCrossClick}
        />
      </div>
      <div className="header">
        <img src={mailSentImage} alt="" className="mailSentImg" />
        <p className="header__heading">Check your email</p>
      </div>
      <div className="body">
        Please click on the link that has just been sent to your email account
        to verify your email and continue the registration process.
      </div>
      {props.error && (
        <ErrorMessage
          errorMessage={props.error}
          extraClass={props.extraClassError}
        />
      )}
      <div className={`footer ${timer >= 0 ? "disable-btn" : ""}`}>
        {resendLink && (
          <div className="footer__btn" onClick={handleResendVerificationLink}>
            <span>Didn&apos;t get the mail? Send it again </span>
            {timer < 0 ? <span>now</span> : <span>{`in ${timer} sec`}</span>}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailVerificationPopUp;
