import { Reducer } from "redux";
import actionType from "../dataUpload.actionTypes";
import { DataUploadInterface } from "../interface";

const initialState: DataUploadInterface = {
  selectedInfo: {
    selectedMethod: "",
    selectedType: 0,
  },
  editScreenData: {
    isNavigate: false,
    data: null,
    status: "idle",
    error: null,
  },
  manualCSVData: {
    data: null,
    status: "idle",
    error: null,
  },
  uploadedCSVData: {
    data: null,
    status: "idle",
    error: null,
  },
  apiCSVData: {
    data: null,
    status: "idle",
    error: null,
  },
  salesforceCSVData: {
    data: null,
    status: "idle",
    error: null,
  },
  selectedCSVData: {
    data: null,
    status: "idle",
    error: null,
    isNavigate: false,
  },
  updatedCSVData: {
    data: null,
    status: "idle",
    error: null,
  },
  deleteCSVData: {
    data: null,
    status: "idle",
    error: null,
  },
};

const dataUploadReducer: Reducer<DataUploadInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionType.SET_METHOD_TAB: {
      const { selectedMethod, selectedType } = action.payload;
      return {
        ...state,
        selectedInfo: {
          selectedMethod: selectedMethod,
          selectedType: selectedType,
        },
      };
    }

    case actionType.GET_UPLOADED_INFO_API_REQUEST: {
      return {
        ...state,
        uploadedCSVData: {
          data: null,
          status: "loading",
          error: null,
        },
      };
    }

    case actionType.GET_UPLOADED_INFO_API_SUCCESS: {
      const res = action.payload;

      const areaMappings: any = {
        Allocation: { name: "Campaign Allocation", position: 1 },
        Payment: { name: "Payment Data", position: 2 },
        Dunning: { name: "Dunning Notices", position: 3 },
      };

      const updated = res?.map((obj: any) => ({
        ...obj,
        ...(areaMappings[obj.area] || {}), // Use the mapping if it exists, otherwise use an empty object
      }));

      const sorted = updated?.sort((a: any, b: any) => a.position - b.position);

      return {
        ...state,
        uploadedCSVData: {
          data: sorted,
          status: "success",
          error: null,
        },
      };
    }

    case actionType.GET_UPLOADED_INFO_API_FAILURE: {
      return {
        ...state,
        uploadedCSVData: {
          data: null,
          status: "failure",
          error: action.payload,
        },
      };
    }

    case actionType.UPLOAD_CSV_FILE_API_REQUEST:
    case actionType.UPLOAD_DUNNIG_CSV_FILE_API_REQUEST:
    case actionType.UPLOAD_PAYMENT_CSV_FILE_API_REQUEST: {
      return {
        ...state,
        manualCSVData: {
          data: null,
          status: "loading",
          error: null,
        },
      };
    }

    case actionType.UPLOAD_CSV_FILE_API_SUCCESS:
    case actionType.UPLOAD_DUNNIG_CSV_FILE_API_SUCCESS:
    case actionType.UPLOAD_PAYMENT_CSV_FILE_API_SUCCESS: {
      return {
        ...state,
        manualCSVData: {
          data: action.payload,
          status: "success",
          error: null,
        },
      };
    }

    case actionType.UPLOAD_CSV_FILE_API_FAILURE:
    case actionType.UPLOAD_DUNNIG_CSV_FILE_API_FAILURE:
    case actionType.UPLOAD_PAYMENT_CSV_FILE_API_FAILURE: {
      return {
        ...state,
        manualCSVData: {
          data: null,
          status: "failure",
          error: action.payload,
        },
      };
    }

    case actionType.GET_API_CSV_DATA_REQUEST: {
      return {
        ...state,
        apiCSVData: {
          data: null,
          status: "loading",
          error: null,
        },
      };
    }

    case actionType.GET_API_CSV_DATA_SUCCESS: {
      // const res = Object.entries(action.payload).map(([area, details]) => ({
      //   area,
      //   details,
      // }));
      const res = action.payload;
      const areaMappings: any = {
        Allocation: { name: "Campaign Allocation", position: 1 },
        Payment: { name: "Payment Data", position: 2 },
        Dunning: { name: "Dunning Notices", position: 3 },
      };

      const updated = res?.map((obj: any) => ({
        ...obj,
        ...(areaMappings[obj.area] || {}), // Use the mapping if it exists, otherwise use an empty object
      }));

      const sorted = updated.sort((a: any, b: any) => a.position - b.position);

      return {
        ...state,
        apiCSVData: {
          data: sorted,
          status: "success",
          error: null,
        },
      };
    }

    case actionType.GET_API_CSV_DATA_FAILURE: {
      return {
        ...state,
        apiCSVData: {
          data: null,
          status: "failure",
          error: action.payload,
        },
      };
    }

    case actionType.GET_SALESFORCE_CSV_DATA_REQUEST: {
      return {
        ...state,
        salesforceCSVData: {
          data: null,
          status: "loading",
          error: null,
        },
      };
    }

    case actionType.GET_SALESFORCE_CSV_DATA_SUCCESS: {
      const res = Object.entries(action.payload).map(([area, details]) => ({
        area,
        details,
      }));

      const areaMappings: any = {
        allocation: { name: "Campaign Allocation", position: 1 },
        payment: { name: "Payment Data", position: 2 },
        dunning: { name: "Dunning Notices", position: 3 },
      };

      const updated = res?.map((obj: any) => ({
        ...obj,
        ...(areaMappings[obj.area] || {}), // Use the mapping if it exists, otherwise use an empty object
      }));

      const sorted = updated.sort((a: any, b: any) => a.position - b.position);

      return {
        ...state,
        salesforceCSVData: {
          data: sorted,
          status: "success",
          error: null,
        },
      };
    }

    case actionType.GET_SALESFORCE_CSV_DATA_FAILURE: {
      return {
        ...state,
        salesforceCSVData: {
          data: null,
          status: "failure",
          error: action.payload,
        },
      };
    }

    case actionType.POST_SELECTED_CSV_DATA_REQUEST: {
      return {
        ...state,
        selectedCSVData: {
          data: null,
          status: "loading",
          error: null,
          isNavigate: false,
        },
      };
    }

    case actionType.POST_SELECTED_CSV_DATA_SUCCESS: {
      return {
        ...state,
        selectedCSVData: {
          data: action.payload,
          status: "success",
          error: null,
          isNavigate: true,
        },
      };
    }

    case actionType.POST_SELECTED_CSV_DATA_FAILURE: {
      return {
        ...state,
        selectedCSVData: {
          data: null,
          status: "failure",
          error: action.payload,
          isNavigate: false,
        },
      };
    }

    case actionType.POST_SELECTED_API_DATA_REQUEST: {
      return {
        ...state,
        selectedCSVData: {
          data: null,
          status: "loading",
          error: null,
          isNavigate: false,
        },
      };
    }

    case actionType.POST_SELECTED_API_DATA_SUCCESS: {
      return {
        ...state,
        selectedCSVData: {
          data: action.payload,
          status: "success",
          error: null,
          isNavigate: true,
        },
      };
    }

    case actionType.POST_SELECTED_API_DATA_FAILURE: {
      return {
        ...state,
        selectedCSVData: {
          data: null,
          status: "failure",
          error: action.payload,
          isNavigate: false,
        },
      };
    }

    case actionType.SET_CSV_DATA_SELECTED: {
      const { isNavigate, data } = action.payload;

      return {
        ...state,
        selectedCSVData: {
          ...state.selectedCSVData,
          ...(data !== undefined ? { data } : {}),
          ...(isNavigate !== undefined ? { isNavigate } : {}),
        },
      };
    }

    case actionType.GET_EDIT_SCREEN_CSV_REQUEST: {
      return {
        ...state,
        editScreenData: {
          data: null,
          status: "loading",
          error: null,
          isNavigate: false,
        },
      };
    }
    case actionType.GET_EDIT_SCREEN_CSV_SUCCESS: {
      return {
        ...state,
        editScreenData: {
          data: action.payload,
          status: "success",
          error: null,
          isNavigate: true,
        },
      };
    }
    case actionType.GET_EDIT_SCREEN_CSV_FAILURE: {
      return {
        ...state,
        editScreenData: {
          data: null,
          status: "failure",
          error: action.payload,
          isNavigate: false,
        },
      };
    }

    case actionType.SET_SHOW_EDIT_SCREEN: {
      const { isNavigate } = action.payload;

      return {
        ...state,
        editScreenData: {
          ...state.editScreenData,
          data: null,
          ...(isNavigate !== undefined ? { isNavigate } : {}),
        },
      };
    }

    case actionType.POST_UPDATED_CSV_API_REQUEST: {
      return {
        ...state,
        updatedCSVData: {
          data: null,
          status: "loading",
          error: null,
        },
      };
    }

    case actionType.POST_UPDATED_CSV_API_SUCCESS: {
      return {
        ...state,
        updatedCSVData: {
          data: action.payload,
          status: "success",
          error: null,
        },
      };
    }

    case actionType.POST_UPDATED_CSV_API_FAILURE: {
      return {
        ...state,
        updatedCSVData: {
          data: null,
          status: "failure",
          error: action.payload,
        },
      };
    }

    case actionType.DELETE_CSV_API_REQUEST: {
      return {
        ...state,
        deleteCSVData: {
          data: null,
          status: "loading",
          error: null,
        },
      };
    }

    case actionType.DELETE_CSV_API_SUCCESS: {
      return {
        ...state,
        deleteCSVData: {
          data: action.payload,
          status: "success",
          error: null,
        },
      };
    }

    case actionType.DELETE_CSV_API_FAILURE: {
      return {
        ...state,
        deleteCSVData: {
          data: null,
          status: "failure",
          error: action.payload,
        },
      };
    }

    case actionType.CLEAR_UPLOADED_DATA:
      return initialState;

    default: {
      return state;
    }
  }
};

export default dataUploadReducer;
