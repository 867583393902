import React, { ReactNode, useEffect, useState } from "react";
import styles from "./YourAccountScreen.module.scss";
import PageToggler from "../../../components/generic/pageToggler/PageToggler";
import PersonalComponent from "./personalComponent/PersonalComponent";
import OrganizationComponent from "./organizationComponent/OrganizationComponent";
import profileConfig from "../Profile.config";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux";
import { getCurrentTab } from "../../../redux/profile/yourAccount/organizationDetails/actions/organizationDetails.actions";

export default function YourAccountScreen() {
  const selectedTab = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization?.selectedProfileTab
  );

  const tabs = useSelector(
    (state: RootState) =>
      state.profile.navigationReducer.leftSideNavigation.data
  );

  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState<string>("");

  const [tab, setTab] = useState<{ name: string; element: ReactNode }[]>([
    // { name: "Personal", element: <PersonalComponent /> },
    // { name: "Organization", element: <OrganizationComponent /> },
  ]);

  useEffect(() => {
    if (selectedTab === "Organization") {
      setCurrentTab(selectedTab);
    } else if (tab.length > 0) {
      setCurrentTab(tab[0].name);
    }
  }, [tab, selectedTab]);

  useEffect(() => {
    if (selectedTab !== "Organization") {
      dispatch(getCurrentTab(""));
    }
  }, []);

  useEffect(() => {
    if (tabs) {
      const yourAccount = tabs?.filter((e: any) => {
        return e.name === profileConfig.yourAccount.name;
      });
      const modules = yourAccount[0]?.moduleTabs.flatMap((e: any) => {
        if (e.isVisible) {
          switch (e.name) {
            case profileConfig.yourAccount.subModule.personal.name:
              return [{ name: e.name, element: <PersonalComponent /> }];

            case profileConfig.yourAccount.subModule.organization.name:
              return [{ name: e.name, element: <OrganizationComponent /> }];
            default:
              return [{ name: e.name, element: <></> }];
          }
        }

        return [];
      });
      setTab(modules ? modules : []);
    }
  }, [tabs]);

  return (
    <div className={styles.wrapper}>
      <PageToggler
        current={currentTab}
        innerComponents={tab}
        switchTab={(e: string) => {
          setCurrentTab(e);
        }}
        extraHeaderStyling={styles.accountsTabHeader}
      ></PageToggler>
    </div>
  );
}
