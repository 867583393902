// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yTsxhb4ZVZz3e4i1fzaK{margin:-2em 15em 3.5em 20em}.F5fH5KepK_FC18jFTeFK{border:1px solid #f6f6f6;margin-top:2em}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/campaign_V2.0/schedulerSetting/SchedulerSetting.module.scss"],"names":[],"mappings":"AAAA,sBACE,2BAAA,CAGF,sBACE,wBAAA,CACA,cAAA","sourcesContent":[".stepProgressDiv {\n  margin: -2em 15em 3.5em 20em;\n}\n\n.hrStyling {\n  border: 1px solid #f6f6f6;\n  margin-top: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepProgressDiv": `yTsxhb4ZVZz3e4i1fzaK`,
	"hrStyling": `F5fH5KepK_FC18jFTeFK`
};
export default ___CSS_LOADER_EXPORT___;
