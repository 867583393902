import React from "react";

import styles from "./FilterButtons.module.scss";
export interface props {
  data: { name: string; value: string }[];
  onChange: CallableFunction;
  currentSelected: string;
}

export default function FilterButtons(props: props) {
  return (
    <div className={styles.wrapper}>
      {props.data.map((e, index) => {
        return (
          <div
            className={styles.each}
            onClick={() => {
              if (props.currentSelected === e.name) {
                props.onChange("");
              } else {
                props.onChange(e.name);
              }
            }}
            data-isselected={e.name === props.currentSelected}
            key={index}
          >
            <span>{e.value}</span>
            {e.name}
          </div>
        );
      })}
    </div>
  );
}
