import React, { useCallback, useEffect, useRef, useState } from "react";
// import { RootState } from "../../../redux";
import {
  DatePickermodule,
  ref as DatePickermoduleRef,
} from "../../../generic/datePicker";

import styles from "./NewCalendarFilterAgent.module.scss";
import { DateToLocalString } from "../../../../utils/timeAndDate/time.util";
import { useSelector, useDispatch } from "react-redux";
// import {
//   setCalenderData,
//   toggleShowRightSideBar,
// } from "../../../redux/filters/actions";
import {
  setSelectedIconAgent,
  setShowRightSideBarAgent,
} from "../../../../redux/agentDesktop/filterReducer/rightSidePopup/action/rightSidePopup.action";
import moment from "moment";
import { RootState } from "../../../../redux";
import { setCalendarRangeAgentDesktop } from "../../../../redux/agentDesktop/filterReducer/calendarFilter/actions/calendarFilter.actions";
function NewCalendarFilterAgent(props: { setSelectedIcon?: any }) {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [totalDays, setTotalDays] = useState<string>();
  const dispatch = useDispatch();
  const calenderData = useSelector(
    (state: RootState) =>
      state?.agentDesktopReducer.rootFilterReducer.calenderFilterReducer
  );
  // completed campaign details

  // const user = "Maia Testing";

  const ref = useRef<DatePickermoduleRef>();
  const setOnChange = useCallback(() => {
    if (ref.current) {
      setTotalDays(ref.current.getNumberOfDays());
      const updatedDates = ref.current.getUpdatedRange();
      setStartDate(() => {
        return updatedDates.startDate;
      });
      setEndDate(() => {
        return updatedDates.endDate;
      });
    }
  }, [ref.current]);

  useEffect(() => {
    setStartDate(calenderData.startDate);
    setEndDate(calenderData.endDate);
  }, []);
  function handleSubmit() {
    const formatedStartDate = moment(startDate).format("YYYY-MM-DD");

    const formatedEndDate = moment(endDate).format("YYYY-MM-DD");

    dispatch(setCalendarRangeAgentDesktop(startDate, endDate));
    dispatch(setShowRightSideBarAgent(false));
    dispatch(setSelectedIconAgent(""));
  }
  // call the api to get completed months for past three years

  const [startAndEndDate, setStartAndEndDate] = useState({
    startDate: new Date(calenderData.startDate),
    endDate: new Date(calenderData.endDate),
  });
  useEffect(() => {
    setStartAndEndDate({
      startDate: new Date(calenderData.startDate),
      endDate: new Date(calenderData.endDate),
    });
  }, [calenderData]);

  //add useSelector Here

  const selectedPlanDetails: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  const selectedPlanRange: any =
    Object.keys(selectedPlanDetails).length > 0
      ? {
          planStartDate: selectedPlanDetails?.calenderStartDate,
          planExpireDate: selectedPlanDetails?.calenderEndDate,
        }
      : { planStartDate: new Date(2015, 0), planExpireDate: new Date(2035, 0) };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.date}>
          <div className={styles.startDate}>{DateToLocalString(startDate)}</div>
          <div>-</div>
          <div className={styles.endDate}>{DateToLocalString(endDate)}</div>
        </div>
        <div className={styles.totalDays}>{totalDays} days selected</div>
        <DatePickermodule
          // onMonthYearChange={getCampaignCompletedMonth} //
          // dates to put green dots
          minDate={
            Object.keys(selectedPlanRange).length !== 0
              ? new Date(selectedPlanRange?.planStartDate)
              : undefined
          }
          maxDate={
            Object.keys(selectedPlanRange).length !== 0 &&
            new Date(selectedPlanRange?.planExpireDate) > new Date()
              ? new Date()
              : new Date(selectedPlanRange?.planExpireDate)
          }
          range={{
            startDate: startAndEndDate.startDate,
            endDate: startAndEndDate.endDate,
          }}
          ref={ref}
          onChange={setOnChange}
        ></DatePickermodule>
      </div>
      <div className={styles.button}>
        <button onClick={handleSubmit}>View Result</button>
      </div>
    </>
  );
}

export default React.memo(NewCalendarFilterAgent);
