export const downloadCsvFile = (csv, fileName) => {
  // Download it
  //var fileName =  fileName + '.csv';
  var link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
  );
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const downloadFromLink = (url) => {

    var link = document.createElement('a');
    link.style.display = 'none';
    // link.setAttribute('target', '_blank'); 
    link.setAttribute('href', url);
    document.body.appendChild(link);
    link.click();
    // link.preventDefault();
    document.body.removeChild(link);
}

