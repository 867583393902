import React from "react";
import styles from "./SaveModal.module.scss";
import Button from "../../../../../generic/button/Button";
import Modal from "../../../../../generic/modal/Modal";

interface SaveModalProps{
 show:boolean;
 onClose: ()=>void;
 onClickCancel:()=>void;
 onClickYes:()=>void;
 isLoading:boolean;
}

export const SaveModal = ({show,isLoading, onClose,onClickCancel,onClickYes}:SaveModalProps)=>{
    return   <Modal
    shown={show}
    close={onClose}
    extraClassStyleModalContent={styles.saveModalWrapper}
  >
    <div className={styles.headerText}>
      Are you sure you want to save all changes?
    </div>
    <div className={styles.btnDiv}>
      <div className={styles.cancelBtn}>
        <Button
          text="Cancel"
          extraClass={`${styles.buttonStyling} ${styles.cancelBtnStyling}`}
          onClick={onClickCancel}
        />
      </div>
      <div className={styles.yesBtn}>
        <Button
          text="Yes"
          extraClass={`${styles.buttonStyling} ${isLoading ? styles.yesInActiveBtnStyling : styles.yesButtonStyling}`}
          onClick={onClickYes}
        />
      </div>
    </div>
  </Modal>
}