import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./AdminPerformance.module.scss";
import TabComponent from "../../../components/agentDesktop/moduleComponents/tabComponent/TabComponent";
import PerformanceChartModel from "../../../components/agentDesktop/moduleComponents/performanceChartModel/PerformanceChartModel";
import AdminCardComponent from "../../../components/agentDesktop/moduleComponents/adminCardComponent/AdminCardComponent";
import CardImg from "../../../theme/assets/agentDesktopSvg/dashboardSvg/cardSvg/dummyImg.svg";
import TablePravid from "../../../components/agentDesktop/generic/tablePravid/TablePravid";
import TableConstantPerformance from "../../../components/agentDesktop/moduleComponents/tableConstantsPerformance/TableConstantsPerformance";
import { RootState } from "../../../redux";
import {
  getDashboardAPIData,
  getPerformanceTableData,
} from "../../../redux/agentDesktop/dashboard/actions";
import Loader from "../../../components/agentDesktop/generic/loader/Loader";
// interface props {
//   default: any;
// }
const adminCard = [
  {
    name: "Avg. Call Per Agent",
    icon: CardImg,
    value: "560",
    minVal: "347",
    maxVal: "606",
  },
  {
    name: "Avg. Chat Per Agent",
    icon: CardImg,
    value: "560",
    minVal: "347",
    maxVal: "606",
  },
  {
    name: "Avg. SLA Per Agent",
    icon: CardImg,
    value: "560",
    minVal: "347",
    maxVal: "606",
  },
];
/**
 *  "Sr. No",
                "Agent",
                "Online (hrs)",
                "Break (hrs)",
                "Offline(hrs)",
                "Office Work (hrs)",
                "Avg. Response Time",
                "CSAT",
                "SLA%",
                "Avg. Handle Time",
 */
const tableRows = [
  {
    originalName: "Sr. No",
    referenceKey: "sl",
    currentName: "Sr. No",
  },
  {
    originalName: "Agent",
    referenceKey: "agent",
    currentName: "Agent",
  },
  {
    originalName: "Online (hrs)",
    referenceKey: "online",
    currentName: "Online (hrs)",
  },
  {
    originalName: "Break (hrs)",
    referenceKey: "break",
    currentName: "Break (hrs)",
  },
  {
    originalName: "Offline(hrs)",
    referenceKey: "offline",
    currentName: "Offline(hrs)",
  },
  {
    originalName: "Office Work (hrs)",
    referenceKey: "office",
    currentName: "Office Work (hrs)",
  },
  {
    originalName: "Avg. Response Time",
    referenceKey: "response_time",
    currentName: "Avg. Response Time",
  },
  {
    originalName: "CSAT",
    referenceKey: "csat",
    currentName: "CSAT",
  },
  {
    originalName: "SLA%",
    referenceKey: "sla",
    currentName: "SLA%",
  },
  {
    originalName: "Avg. Handle Time",
    referenceKey: "handle_time",
    currentName: "Avg. Handle Time",
  },
];
const dummyTableData = [
  {
    information: {
      agentName: "Nitin",
      online: "5",
      break: "8",
      offline: "7",
      officeWork: "4",
      avgResponseTime: "5",
      csat: "8",
      sla: "34",
      avgHandleTime: 2,
    },
  },
  {
    information: {
      agentName: "Nitin",
      online: "5",
      break: "8",
      offline: "7",
      officeWork: "4",
      avgResponseTime: "5",
      csat: "8",
      sla: "34",
      avgHandleTime: 2,
    },
  },
  {
    information: {
      agentName: "Nitin",
      online: "45",
      break: "8",
      offline: "37",
      officeWork: "4",
      avgResponseTime: "5",
      csat: "8",
      sla: "34",
      avgHandleTime: 2,
    },
  },
  {
    information: {
      agentName: "Nitin",
      online: "5",
      break: "8",
      offline: "7",
      officeWork: "4",
      avgResponseTime: "5",
      csat: "8",
      sla: "34",
      avgHandleTime: 2,
    },
  },
  {
    information: {
      agentName: "Nitin",
      online: "5",
      break: "8",
      offline: "7",
      officeWork: "34",
      avgResponseTime: "15",
      csat: "8",
      sla: "34",
      avgHandleTime: 12,
    },
  },
  // {
  //   agent: "nithin",
  //   online: 7,
  //   break: 2,
  //   offline: 2,
  //   office: 1,
  //   response_time: "2s",
  //   csat: 2,
  //   sla: "67%",
  //   handle_time: "fsd",
  // },
  // {
  //   agent: "nithin",
  //   online: 7,
  //   break: 2,
  //   offline: 2,
  //   office: 1,
  //   response_time: "2s",
  //   csat: 2,
  //   sla: "67%",
  //   handle_time: "fsd",
  // },
];

export default function AdminPerformanceScreen(props: any) {
  /* redux hooks */
  const dispatch = useDispatch();
  const performanceTableColumn = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.schema?.performance?.tableColumns
  );

  const performanceChartSchema = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.schema?.performance?.rangeCards
  );

  const performanceCardSchema = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.schema?.performance?.cards
  );

  const agentId = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo?.userDetail?._id
  );

  const channelTab = useSelector(
    (store: any) => store?.agentDesktopReducer?.tabReducer?.backendRefenceKey
  );

  const startDate = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer?.rootFilterReducer?.calenderFilterReducer
        ?.formatedStartDate
  );

  const endDate = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer?.rootFilterReducer?.calenderFilterReducer
        ?.formatedEndDate
  );
  const loading = useSelector(
    (store: RootState) =>
      store?.agentDesktopReducer?.dashboardReducer?.isLoadingDashboardChart
  );

  const performanceLoading = useSelector(
    (store: RootState) =>
      store?.agentDesktopReducer?.dashboardReducer
        ?.isLoadingPerformanceTableChart
  );

  useEffect(() => {
    if (channelTab) {
      dispatch(
        getDashboardAPIData({
          start_date: startDate,
          end_date: endDate,
          channel: channelTab,
          adminId: agentId,
        })
      );
    }
  }, [startDate, endDate, channelTab, agentId]);

  useEffect(() => {
    if (channelTab) {
      dispatch(
        getPerformanceTableData({
          start_date: startDate,
          end_date: endDate,
          channel: channelTab,
          adminId: agentId,
        })
      );
    }
  }, [startDate, endDate, channelTab, agentId]);
  const tableData = useSelector(
    (state: any) =>
      state?.agentDesktopReducer?.dashboardReducer?.performanceTableChart
  );

  return (
    <>
      <div className={styles.adminPerformanceScreenDiv}>
        <div className={styles.performanceScreenTopDiv}>
          {/* <div className={styles.verticalBarClassDiv}> */}
          <PerformanceChartModel
            chartDetails={performanceChartSchema}
            loading={loading}
          />
          {/* </div> */}
          <div className={styles.adminCardsDiv}>
            <AdminCardComponent cardDetails={performanceCardSchema} />
          </div>
        </div>
        <div className={styles.PerformanceScreenBottomDiv}>
          <p className={styles.performanceBottomTitleClass}>Channel Metrics</p>
          <TabComponent />
        </div>
        <div className={styles.table}>
          {!performanceLoading ? (
            <TablePravid
              extraClassTableBody={styles.body}
              data={Object.assign([], tableData)}
              pageNo={1}
              columns={TableConstantPerformance(performanceTableColumn, 20)}
            />
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
}
