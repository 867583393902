import { takeEvery, takeLatest } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import {
  getDashboardDataAPIWorker,
  // getDashboardCardDataAPIWorker,
  getPerformanceTableDataAPIWorker
} from "./dashboard.saga";

export default function* dashboardWatcher(): any {
  yield takeLatest(
    actionTypes.DASHBOARD_API_DATA_REQUEST,
    getDashboardDataAPIWorker
  );
  // yield takeEvery(
  //   actionTypes.DASHBOARD_API_CARD_DATA_REQUEST,
  //   getDashboardCardDataAPIWorker
  // );
  yield takeLatest(
    actionTypes.PERFORMANCE_TABLE_DATA_REQUEST,
    getPerformanceTableDataAPIWorker
  );
  
}
