import React, { useEffect, useState } from "react";
import style from "./HeaderFlows.module.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
  setSelectedFlowRange
} from "../../../../../redux/strategy/nudgeCreation/actions";

interface props {
  selectedFlow?: any;
  flowList: any;
}

function HeaderFlows(props: props) {
  const arr = ["Pre-Due", "Post-Due", "DPD", "TT"];
  const [selectedFlow, setSelectedFlow] = useState<string>();
  const [dispalyName, setDisplayName] = useState<any>({});
  const dispatch = useDispatch();
  //redux data
  const createdBucketData = useSelector((store: any) => {
    return store?.strategyModuleReducer?.strategyReducer?.createdStrategy;
  });

  const selectedFlowRangeName = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.selectedFlowRange;
  });
  console.log("nayak flow", selectedFlowRangeName)

  useEffect(() => {
    const obj: any = {};
    createdBucketData?.flows?.map((e: any) => {
      const dispalyNameobj = e?.displayName;
      const originalName = e?.originalName;
      return (obj[originalName] = dispalyNameobj);
    });
    setDisplayName({ ...obj });
  }, [createdBucketData]);

  // for  selected sending flow from redux
  useEffect(
    () => {
      if (selectedFlowRangeName?.length) {
        props.selectedFlow(selectedFlowRangeName);
        setSelectedFlow(selectedFlowRangeName)
      }

    }, [selectedFlowRangeName]
  )

  const handleSelect = (data: string, mainData: any) => {
    console.log("qw", mainData);
    if (data === selectedFlow) {
      setSelectedFlow("");
      props.selectedFlow("");
      dispatch(setSelectedFlowRange(""))
    } else if (mainData?.arrayOfDays?.[0] === Number(0) && mainData?.arrayOfDays?.length == 1) {
      toast.error("Due Date is itself daywise ");
    } else if (!mainData?.nugeBoxes) {
      setSelectedFlow(data);
      props.selectedFlow(data);
      dispatch(setSelectedFlowRange(data))
    } else {
      toast.error(
        "You can not made day wise nudge if you already created for flow wise"
      );
    }
  };
  return (
    <div className={style.headerFlowWrapper} id="auto_headerFlowWrapper">
      {props.flowList?.map((e: any, i: number) => {
        return (
          <div
            key={i}
            className={
              selectedFlow === e?.flow
                ? style.selectedflowsName
                : style.flowsName
            }
            id={`auto_selectedFlow_div_${i}`}
          >
            <p
              data-tip
              data-for={`${e?.flow}`}
              onClick={() => handleSelect(e?.flow, e)}
              id={`auto_selectedFlow_${i}`}
            >
              {dispalyName[`${e?.flow}`]}
            </p>
            <ReactTooltip
              id={`${e?.flow}`}
              place="bottom"
              effect="solid"
              backgroundColor="#b6b6b6"
              textColor="#fff"
              borderColor="#b6b6b6"
              border={true}
            >
              {`${e?.flow}`}
            </ReactTooltip>
          </div>
        );
      })}
    </div>
  );
}

export default HeaderFlows;
