import actionTypes from "../sms.actionTypes";

export interface stateInterface {
  connectExistingAccount: { data: any; isLoading: boolean; error: any };
  newAccountInfo: { data: any; isLoading: boolean; error: any };
  updateAccountInfo: { data: any; isLoading: boolean; error: any };
  currentScreen: string;
}

const initialState: stateInterface = {
  connectExistingAccount: { data: {}, isLoading: false, error: {} },
  newAccountInfo: { data: {}, isLoading: false, error: {} },
  updateAccountInfo: { data: {}, isLoading: false, error: {} },
  currentScreen: "",
};

export default function emailReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.SET_CONNECT_EXISTING_ACCOUNT_REQUEST: {
      return {
        ...state,
        connectExistingAccount: { data: {}, isLoading: true, error: {} },
      };
    }
    case actionTypes.SET_CONNECT_EXISTING_ACCOUNT_SUCCESS: {
      return {
        ...state,
        connectExistingAccount: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.SET_CONNECT_EXISTING_ACCOUNT_FAILURE: {
      return {
        ...state,
        connectExistingAccount: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.SET_NEW_ACCOUNT_REQUEST: {
      return {
        ...state,
        newAccountInfo: { data: {}, isLoading: true, error: {} },
      };
    }
    case actionTypes.SET_NEW_ACCOUNT_SUCCESS: {
      return {
        ...state,
        newAccountInfo: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.SET_NEW_ACCOUNT_FAILURE: {
      return {
        ...state,
        newAccountInfo: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.UPDATE_NEW_ACCOUNT_REQUEST: {
      return {
        ...state,
        updateAccountInfo: { data: {}, isLoading: true, error: {} },
      };
    }
    case actionTypes.UPDATE_NEW_ACCOUNT_SUCCESS: {
      return {
        ...state,
        updateAccountInfo: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.UPDATE_NEW_ACCOUNT_FAILURE: {
      return {
        ...state,
        updateAccountInfo: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.SET_CLEAR_SMS_DATA: {
      return {
        ...initialState,
      };
    }
    case actionTypes.SET_NEW_ACCOUNT_CLEAN: {
      return {
        ...state,
        newAccountInfo: {
          data: {},
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.SET_CURRENT_SCREEN_SMS: {
      return {
        ...state,
        currentScreen: action.payload,
      };
    }
    default:
      return state;
  }
}
