import React, { useState, useEffect } from "react";
import styles from "./UserPersonaScreen.module.scss";
// import {
//   divideIcon,
//   editUserPersonaIcon,
//   leftArrowIcon,
//   rightArrowIcon,
//   strategyCallIcon,
// } from "../../../../theme/assets/svg/strategySvg";
import {
  backIcon,
  editBlueIcon,
} from "../../../../theme/assets/svg/campaign_v3/index";
import { filter, caratDown } from "../../../../theme/assets/genericSvg";
import InputField from "../../../generic/inputField/InputField";
import Icon from "../../uiKit/iconComponent/Icon";
// import NewPagination from "../../../generic/pagination/NewPagination";
import Button from "../../../generic/button/Button";
import { DateSelectionModal } from "../dateSelectionModal/DateSelectionModal";
import {
  addUserPersonaAPIData,
  getUserPersonaAPIData,
  getUserPersonaOptionsAPIData,
  getWorkflowsAPIData,
  selectUserPersona,
} from "../../../../redux/strategy/strategyCreation/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import Loader from "../../../../components/generic/loader/Loader";
import MultiLevelCheck from "../../../generic/multilevelCheck/MultiLevelCheck";
import { useNavigate, useLocation } from "react-router-dom";
import { StrategyModalPopUp } from "../strategyModalPopUp/StrategyModalPopUp";
import { getRouteByIndex } from "../../../../utils/ProgressBarNavFunc";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import { USECASE } from "../../../../constants";
import WorkSpaceSalesOnBoarding from "../workSpaceForeSalesOnBoarding/WorkSpaceSalesOnBoarding";

interface personaData {
  name: string;
  age: string;
  gender: string;
  occupation: string;
  location: string;
  typology: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  userPersonaRecommended?: string;
}

export const UserPersonaScreen = () => {
  const strategyDetails = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.createdStrategy
  );
  const [dateSelectionModal, setDateSelectionModal] = useState(false);
  const [userPersonaModal, setUserPersonaModal] = useState(strategyDetails?.useCaseId === USECASE?.debtCollection?.id ? true : false);
  const [limit, setLimit] = useState(11);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [checked, setChecked] = useState<string[]>([]);
  const [checkedPersonas, setCheckedPersons] = useState<personaData[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [userPersonaFilters, setUserPersonaFilters] = useState<object>({});
  const [inCampaign, setInCampaign] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userPersonaData = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.userPersonaDetails.persona
  );

  const userPersonaOptions = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer.userPersonaOptions
  );

  const isLoading = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.isLoadingUserPersonaDetails
  );

  const { totalPage, totalCount } = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.userPersonaDetails
  );

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const checkedUserPersona = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.selectedUserPersona
  );

  const userPersonaFromCSVData = inCampaign
    ? useSelector(
      (store: RootState) =>
        store?.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
          ?.personas
    )
    : useSelector(
      (store: RootState) =>
        store?.strategyModuleReducer?.strategyReducer?.createdStrategy
          ?.personas
    );

  const strategyId = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.createdStrategy?.id
  );

  const campaignDetails = useSelector(
    (store: RootState) =>
      store?.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
  );

  const maxStateCompleted: number = useSelector(
    (state: RootState) =>
      state.campaignModuleReducer?.progressBar?.maxCompletedStep
  );

  const currentStep: number = useSelector(
    (state: RootState) => state.campaignModuleReducer?.progressBar?.currentStep
  );

  const stepsTotal: string[] = useSelector(
    (store: RootState) =>
      store?.schedulerReducer?.accountInfoDetails?.campaignProgressBar
  );
  const teamUseCaseId = useSelector(
    (store: RootState) => store?.loginReducer?.currentTeam?.useCaseId
  );
  const currentTeamId = useSelector(
    (state: RootState) => state.loginReducer.currentTeam.id
  );
  const currentAccountId = useSelector(
    (state: RootState) => state.loginReducer.currentTeam?.accountId
  );

  // const strategyDetails = useSelector(
  //   (store: RootState) =>
  //     store?.strategyModuleReducer?.strategyReducer?.createdStrategy
  // );



  // const stepsTotal: string[] = [
  //   "Campaign Details",
  //   "Data Upload",
  //   "Strategy Selection",
  //   "Agent Configuration",
  //   "Scheduler Setting",
  // ];

  // const handlePageChange = (page: number) => {
  //   if (currentPage !== page) {
  //     setCurrentPage(page);
  //   }
  // };

  const handlePageChange = (page: any) => {
    if (currentPage !== page) {
      setCurrentPage(Number(page.selected) + 1);
    }
  };

  const handleAddUserPersona = () => {
    dispatch(
      addUserPersonaAPIData({
        token: token,
        strategyId: strategyId,
        personas: checkedUserPersona?.data,
      })
    );
  };

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    data: personaData
  ) => {
    let updatedList = checkedUserPersona.id;
    let updatedObjList = checkedUserPersona.data;
    if (event.target.checked) {
      updatedList = [...checked, id];
      updatedObjList = [...checkedPersonas, data];
      setChecked(updatedList);
      setCheckedPersons(updatedObjList);
    } else {
      const index = checked.indexOf(id);
      const idx = checked.indexOf(data?.id);

      if (index > -1) {
        updatedList.splice(index, 1);
        updatedObjList.splice(idx, 1);
      }
      setChecked((prev) => [...updatedList]);
      setCheckedPersons((prev) => [...updatedObjList]);
    }
  };

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedList = checkedUserPersona?.id;
    let updatedObjList = checkedUserPersona?.data;
    const checkItems = _.map(userPersonaData, "id");
    const checkPersonaItems = userPersonaData;

    if (event.target.checked) {
      updatedList = _.union([...checked], checkItems);
      setChecked(updatedList);
      updatedObjList = _.union([...checkedPersonas], checkPersonaItems);
      setCheckedPersons(updatedObjList);
      setCheckAll(true);
    } else {
      const res = updatedObjList.filter(
        (o1: personaData) =>
          !userPersonaData?.some((o2: personaData) => o1.id === o2.id)
      );
      const ress = updatedList.filter(
        (o1: string) =>
          !userPersonaData?.some((o2: personaData) => o1 === o2.id)
      );
      setCheckedPersons(res);
      setChecked(ress);
      setCheckAll(false);
    }
  };

  useEffect(() => {
    userPersonaFromCSVData?.map((data: personaData, i: number) => {
      checked.push(data?.id);
      checkedPersonas.push(data);
    });
  }, [userPersonaFromCSVData, limit, currentPage]);

  useEffect(() => {
    dispatch(selectUserPersona(checked, checkedPersonas));
  }, [checked, limit, currentPage]);

  const checkItems = _.map(userPersonaData, "id");
  const updatedList = _.union(checked, checkItems)?.length;

  useEffect(() => {
    if (updatedList != checked?.length) {
      setCheckAll(false);
    }
    if (updatedList == checked?.length) {
      setCheckAll(true);
    }
  }, [updatedList, checkItems]);

  useEffect(() => {
    // if (isLoading === false) {
    const body: any = {};
    location?.pathname === "/campaignmanager/userpersona"
      ? (body["campaignId"] = campaignDetails?.id)
      : (body["strategyId"] = strategyId);
    console.log(body, inCampaign, "iddddd");
    dispatch(
      getUserPersonaAPIData({
        limit: limit,
        page: currentPage,
        token: token,
        useCaseId: teamUseCaseId,
        ...userPersonaFilters,
        ...body,
      })
    );
    // }
  }, [
    limit,
    currentPage,
    token,
    userPersonaFilters,
    inCampaign,
    campaignDetails,
    strategyId,
    location?.pathname,
  ]);

  useEffect(() => {
    dispatch(
      getWorkflowsAPIData({
        token: token,
        useCaseId: teamUseCaseId,
        teamId: currentTeamId,
        accountId: currentAccountId
      })
    );
  }, [token]);

  useEffect(() => {
    dispatch(getUserPersonaOptionsAPIData({ token: token }));
  }, [token]);

  // useEffect(() => {
  //   if (moduleSelectedScreen !== "Strategy") {
  //     dispatch(selectUserPersona([], []));
  //   }
  // }, []);

  // const selectedUserPersonaInfo = userPersonaData?.filter((data: any) =>
  //   checkedUserPersona?.id.includes(data?.id)
  // );

  useEffect(() => {
    if (location?.pathname === "/campaignmanager/userpersona") {
      setInCampaign(true);
    } else {
      setInCampaign(false);
    }
    console.log(
      location?.pathname === "/campaignmanager/userpersona",
      location?.pathname,
      "/campaignmanager/userpersona"
    );
  }, [inCampaign, location?.pathname]);
  console.log("work space", strategyDetails?.useCaseId, USECASE?.debtCollection?.id)
  return (
    <div className={styles.userPersonaDiv} id="auto_userPersonaDiv">
      <div className={styles.userPersonaTopDiv}>
        <div
          className={styles.userPersonaHeadDiv}
          id="auto_userPersonaHeaderDiv"
        >
          <Icon
            img_src={backIcon}
            onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation();
              inCampaign
                ? navigate("/campaignmanager/dataupload")
                : navigate("/strategy/dataupload");
            }}
            id="auto_userPersona_backIcon"
          />
          <StrategyModalPopUp
            show={userPersonaModal}
            close={() => {
              setUserPersonaModal(false);
            }}
            noOfUserPersonasSelected={userPersonaFromCSVData?.length}
            userPersonaModal={true}
          />
          <div
            className={styles.userPersonaHeading}
            id="auto_userPersonaHeading"
          >
            User Persona Selection
          </div>
        </div>
        <div
          className={styles.userPersonaDropDown}
          id="auto_userPersonaDropDown"
          data-testid="dropdown"
        >
          <MultiLevelCheck
            leftIcon={filter}
            // rightIcon={caratDown}
            title={"Filter"}
            allOptions={userPersonaOptions}
            defaultOptions={{}}
            getDataOut={setUserPersonaFilters}
            singleIcon={false}
            singleSelectField={[]}
            isOpenToLeft={true}
          />
        </div>
      </div>
      <div className={styles.userPersonaBtmDiv}>
        {isLoading ? (
          <div className={styles.loader} id="auto_loader">
            <Loader />
          </div>
        ) : (
          <div className={styles.userPersonTableDiv}>
            <div className={styles.userPersonTableSyling}>
              <div
                className={`${inCampaign
                  ?
                  // styles.userPersonaTableC
                  styles.userPersonaTable
                  : styles.userPersonaTable
                  } ${styles.columnHeaderStyling}`}
              >
                {(
                  <div className={styles.userPersonaCheckBox}>
                    <InputField
                      type="checkbox"
                      className={styles.inputCheckStyling}
                      extraClass={styles.inputCheckBoxStyling}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleCheckAll(e);
                      }}
                      checked={checkAll ? true : false}
                      id="auto_persona_checkbox"
                    />
                  </div>
                )}
                <div className={styles.userPersonaSrNo}>Sr. No</div>
                <div className={styles.userPersonas}>User Personas</div>
                <div className={styles.userPersonaRecommended}>
                  Saarthi&apos;s Recommended Strategies
                </div>
              </div>
              <div
                className={styles.userPersonaTableInfoDiv}
                id="auto_userPersonaTableInfoDiv"
              >
                {userPersonaData?.map((data: personaData, index: number) => {
                  return (
                    <>
                      <div
                        className={`${inCampaign
                          ?
                          //  styles.userPersonaTableC
                          styles.userPersonaTable
                          : styles.userPersonaTable
                          } ${styles.columnDataStyling}`}
                        key={index}
                        id={`auto_userPersonTableInfo_${index}`}
                      >
                        {(
                          <div className={styles.userPersonaCheckBox}>
                            <InputField
                              type="checkbox"
                              className={styles.inputCheckStyling}
                              extraClass={styles.inputCheckBoxStyling}
                              onChange={(e) => {

                                handleCheck(e, data?.id, data)

                              }}
                              checked={
                                checkedUserPersona?.id?.includes(data?.id)
                                  ? true
                                  : false
                              }
                              id={`auto_userPersonaCheckBox_${index}`}
                            />
                          </div>
                        )}
                        <div
                          className={styles.userPersonaSrNo}
                          id={`auto_userPersonaSrNo_${index}`}
                        >
                          {(currentPage - 1) * 11 + index + 1}
                        </div>
                        <div
                          className={styles.userPersonas}
                          id={`auto_userPersonaName_${index}`}
                        >
                          {data.name}
                        </div>
                        <div className={styles.recommendedDataDiv}>
                          <Icon
                            img_src={editBlueIcon}
                            id={`auto_editUserPersonaIcon_${index}`}
                            extraClass={styles.editImageStyling}
                          />
                          <div
                            className={styles.recommendedData}
                            id={`auto_recommendedData_${index}`}
                          >
                            {data.userPersonaRecommended
                              ? data.userPersonaRecommended
                              : "Saarthi Strategy 1"}
                          </div>
                          {/* <Icon
                            img_src={divideIcon}
                            extraClass={styles.divideImageStyling}
                            id={`auto_divideIcon_${index}`}
                          /> */}
                        </div>
                      </div>
                      {/* <hr className={styles.hrStyling} /> */}
                    </>
                  );
                })}
              </div>
            </div>
            {/* {totalPages > 1 && (
              <div
                className={styles.userPersonaPaginationDiv}
                id="auto_userPersonaPaginationDiv"
              >
                <div className={styles.userPersonaPagination}>
                  <NewPagination
                    handlePageChange={handlePageChange}
                    totalNoOfPage={totalPages}
                    getPage={currentPage - 1}
                    noExtraArrowIcon={true}
                    leftArrow={leftArrowIcon}
                    rightArrow={rightArrowIcon}
                  />
                </div>
              </div>
            )} */}
            {totalPage > 1 && (
              <div
                className={styles.userPersonaPaginationDiv}
                id="auto_userPersonaPaginationDiv"
              >
                <div className={styles.pageTextStyling}>
                  Showing <strong>{(currentPage - 1) * limit + 1}</strong> to{" "}
                  <strong>{Math.min(currentPage * limit, totalCount)}</strong>{" "}
                  of <strong>{totalCount}</strong> results
                </div>
                <div className={styles.userPersonaPagination}>
                  <ReactPaginate
                    nextLabel={">"}
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={totalPage}
                    forcePage={currentPage - 1}
                    previousLabel="<"
                    pageClassName={styles["page-item"]}
                    pageLinkClassName={styles["page-link"]}
                    previousClassName={styles["prev"]}
                    previousLinkClassName={styles["page-link"]}
                    nextClassName={styles["next"]}
                    nextLinkClassName={styles["page-link"]}
                    breakLabel="..."
                    breakClassName={styles["page-item"]}
                    breakLinkClassName={styles["page-link"]}
                    containerClassName={"pagination"}
                    activeClassName={styles["active"]}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            )}
            <div className={styles.userPersonaBtnDiv}>
              {inCampaign ? (
                <Button
                  text={`${getRouteByIndex(currentStep, stepsTotal)?.nextStepName ===
                    "Strategy Selection"
                    ? "Select Strategy"
                    : "Next"

                    //"Select Strategy"
                    }`}
                  extraClass={`${styles.selectButtonStyling}`}
                  onClick={() => {
                    const nextStep = getRouteByIndex(currentStep, stepsTotal);
                    if (nextStep) {
                      navigate(nextStep?.route);
                    }
                    //navigate("/campaignmanager/strategyselection");
                  }}
                  id="auto_select_btn"
                />
              ) : (
                <Button
                  text="Create Strategy"
                  extraClass={`${styles.selectButtonStyling}`}
                  onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.stopPropagation();
                    handleAddUserPersona();
                    setDateSelectionModal(true);
                  }}
                  id="auto_create_btn"
                />
              )}
              {
                dateSelectionModal && (
                  strategyDetails?.useCaseId === USECASE?.debtCollection?.id
                    ?
                    <DateSelectionModal
                      show={dateSelectionModal}
                      close={() => {
                        setDateSelectionModal(false);
                      }}
                    />
                    :
                    <WorkSpaceSalesOnBoarding
                      show={dateSelectionModal}
                      close={() => {
                        setDateSelectionModal(false);
                      }}
                    />

                )


              }

            </div>
          </div>
        )}
      </div>
    </div>
  );
};
