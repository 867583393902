import { Reducer } from "redux";
import actionType from "../actionTypes";
// import { stateInterface } from "../interface";
export interface stateInterface {
  organizationDetails: any;
  isLoadingOrganizationDetails: boolean;
  organizationDetailsError: string;
  saveOrganizationDetails: any;
  isLoadingSaveOrganizationDetails: boolean;
  saveOrganizationDetailsError: any;
  isUpdateSuccess: boolean;
  selectedProfileTab: string;
  sendOTPData: object;
  isLoadingSendOTP: boolean;
  sendOTPDataError: any;
  verifyOTPData: object;
  isLoadingVerifyOTP: boolean;
  verifyOTPDataError: any;
  firstTimeSaved: boolean;
  panRequest: { data: any; isLoading: boolean; error: any };
  gstRequest: { data: any; isLoading: boolean; error: any };
  saveTempData: { gst: string; pan: string };
  sendSignEmail: { data: any; isLoading: boolean; error: any };
}
const initialState: stateInterface = {
  organizationDetails: {},
  isLoadingOrganizationDetails: false,
  organizationDetailsError: "",
  saveOrganizationDetails: {},
  isLoadingSaveOrganizationDetails: false,
  saveOrganizationDetailsError: "",
  isUpdateSuccess: false,
  selectedProfileTab: "",
  sendOTPData: {},
  isLoadingSendOTP: false,
  sendOTPDataError: "",
  verifyOTPData: {},
  isLoadingVerifyOTP: false,
  verifyOTPDataError: "",
  firstTimeSaved: false,
  panRequest: { data: undefined, isLoading: false, error: undefined },
  gstRequest: { data: undefined, isLoading: false, error: undefined },
  saveTempData: { gst: "", pan: "" },
  sendSignEmail: { data: [], isLoading: false, error: {} }
};

const OrganizationReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionType.ORGANIZATION_DATA_API_REQUEST: {
      return {
        ...state,
        isLoadingOrganizationDetails: true,
        organizationDetails: {},
        organizationDetailsError: "",
      };
    }
    case actionType.ORGANIZATION_DATA_API_SUCCESS: {
      return {
        ...state,
        isLoadingOrganizationDetails: false,
        organizationDetails: action.payload,
        organizationDetailsError: "",
      };
    }
    case actionType.ORGANIZATION_DATA_API_FAILURE: {
      return {
        ...state,
        organizationDetails: {},
        isLoadingOrganizationDetails: false,
        organizationDetailsError: action.payload,
      };
    }
    case actionType.UPDATE_ORGANIZATION_DATA_API_REQUEST: {
      return {
        ...state,
        saveOrganizationDetails: {},
        isLoadingSaveOrganizationDetails: true,
        saveOrganizationDetailsError: "",
      };
    }
    case actionType.UPDATE_ORGANIZATION_DATA_API_SUCCESS: {
      return {
        ...state,
        saveOrganizationDetails: action.payload,
        isLoadingSaveOrganizationDetails: false,
        saveOrganizationDetailsError: "",
      };
    }
    case actionType.UPDATE_ORGANIZATION_DATA_API_FAILURE: {
      return {
        ...state,
        saveOrganizationDetails: {},
        isLoadingSaveOrganizationDetails: false,
        saveOrganizationDetailsError: action.payload,
      };
    }
    case actionType.UPDATE_ORG_DATA_STATUS: {
      return {
        ...state,
        isUpdateSuccess: action.payload,
      };
    }
    case actionType.SEND_OTP_ORG_REQUEST: {
      return {
        ...state,
        sendOTPData: {},
        isLoadingSendOTP: true,
        sendOTPDataError: "",
      };
    }
    case actionType.SEND_OTP_ORG_FAILURE: {
      return {
        ...state,
        sendOTPData: {},
        isLoadingSendOTP: false,
        sendOTPDataError: action.payload,
      };
    }
    case actionType.SEND_OTP_ORG_SUCCESS: {
      return {
        ...state,
        sendOTPData: action.payload,
        isLoadingSendOTP: false,
        sendOTPDataError: "",
      };
    }
    case actionType.VERIFY_OTP_ORG_REQUEST: {
      return {
        ...state,
        verifyOTPData: {},
        isLoadingVerifyOTP: true,
        verifyOTPDataError: "",
      };
    }
    case actionType.VERIFY_OTP_ORG_FAILURE: {
      return {
        ...state,
        verifyOTPData: {},
        isLoadingVerifyOTP: false,
        verifyOTPDataError: action.payload,
      };
    }
    case actionType.VERIFY_OTP_ORG_SUCCESS: {
      return {
        ...state,
        verifyOTPData: action.payload.data,
        isLoadingVerifyOTP: false,
        verifyOTPDataError: "",
        organizationDetails: {
          ...state.organizationDetails,
          phoneNumber: action.payload.phoneNumber,
        },
      };
    }
    case actionType.SELECTED_TAB: {
      return {
        ...state,
        selectedProfileTab: action.payload,
      };
    }
    case actionType.FIRST_TIME_ORG_SAVED: {
      return {
        ...state,
        firstTimeSaved: action.payload,
      };
    }
    case actionType.CLEAR_SENT_OTP_ORG: {
      return {
        ...state,
        sendOTPData: {},
        isLoadingSendOTP: false,
        sendOTPDataError: "",
      };
    }
    case actionType.CLEAR_VERIFY_OTP_ORG:
      return {
        ...state,
        sendOTPData: {},
        isLoadingSendOTP: false,
        sendOTPDataError: "",
        verifyOTPData: {},
        isLoadingVerifyOTP: false,
        verifyOTPDataError: "",
      };
    case actionType.VERIFY_GST_ORG_REQUEST:
      return {
        ...state,
        gstRequest: { data: undefined, isLoading: true, error: undefined },
      };
    case actionType.VERIFY_GST_ORG_SUCCESS:
      return {
        ...state,
        gstRequest: {
          data: action.payload,
          isLoading: false,
          error: undefined,
        },
      };
    case actionType.VERIFY_GST_ORG_REQUEST:
      return {
        ...state,
        gstRequest: { data: undefined, isLoading: true, error: action.payload },
      };
    case actionType.VERIFY_PAN_ORG_REQUEST:
      return {
        ...state,
        panRequest: { data: undefined, isLoading: true, error: undefined },
      };
    case actionType.VERIFY_PAN_ORG_SUCCESS:
      return {
        ...state,
        panRequest: {
          data: action.payload,
          isLoading: false,
          error: undefined,
        },
      };
    case actionType.VERIFY_PAN_ORG_REQUEST:
      return {
        ...state,
        panRequest: { data: undefined, isLoading: true, error: action.payload },
      };
    case actionType.VERIFY_GST_RESET:
      return {
        ...state,
        gstRequest: { data: undefined, isLoading: false, error: undefined },
      };
    case actionType.VERIFY_PAN_RESET:
      return {
        ...state,
        panRequest: { data: undefined, isLoading: false, error: undefined },
      };
    case actionType.STORE_GST_PAN_DATA:
      return {
        ...state,
        saveTempData: { gst: action.payload.gst, pan: action.payload.pan },
      };

    case actionType.USER_LOGOUT: {
      return {
        ...initialState,
      };
    }
  
  case actionType.SEND_SIGN_EMAIL_REQUEST: {
    return {
      ...state,
      sendSignEmail :{ data : [] , isLoading : true , error : {}}
    };
  }
  case actionType.SEND_SIGN_EMAIL_SUCCESS: {
    return {
      ...state,
      sendSignEmail :{ data : action.payload , isLoading : false , error : {}}
    };
  }
  case actionType.SEND_SIGN_EMAIL_FAILURE: {
    return {
      ...state,
      sendSignEmail :{ data : [] , isLoading : true , error : action.payload}
    };
  }

    default: {
      return state;
    }
  }
};

export default OrganizationReducer;
