 export function exceptTheKey(Obj,key){
     const newObj=JSON.parse(JSON.stringify(Obj));
       delete newObj[key]
    return newObj;
 };

 export function addClientNameAsKey(Obj,key){
    const newObj=JSON.parse(JSON.stringify(Obj));

   
        if(newObj['clientName'] && key!="clientName"){
             if(newObj['clientName'].length>0){
        
                newObj[key] =newObj['clientName'][0].name;
                  delete newObj['clientName']
             }
        } else{
            if(newObj['clientName']&& key=="clientName"&&newObj['clientName'].length>0){
        
                newObj[key] =newObj['clientName'][0].name;
                //   delete newObj['clientName']
             }
        }
    
     
     return newObj;
 }