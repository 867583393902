import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/rootStore";
// import { userLogoutAction } from "../../../redux/onboarding/login/actions";
import { setIsOnlyIcons as setIsOnlyIconsLeftMenu } from "../../../redux/baseScreen/leftMenu";
import {
  getDropDownHomeDetails,
  getDropDownSettingDetails,
  setCurrentTeam,
} from "../../../redux/profile/navigation/action/navigation.action";
import styles from "./NavigationBar.module.scss";
import Button from "../../generic/button/Button";
// import ReportRequest from "../reportRequest/ReportRequest";
import { toast, ToastContainer } from "react-toastify";
import { Mixpanel } from "../../../utils/mixpanelSetup";
// import clearCacheData from "../../../utils/clearCacheData";
import { signOutCloseIcon } from "../../../theme/assets/profileSectionSvg";
import { format } from "date-fns";
import {
  pravidLogo,
  homeIcon,
  downArrow,
  sidebarIconOpen,
  sidebarIconClosed,
  requestFeatureIcon,
  reportBugs,
  reportBugActiveIcon,
  requestFeatureActiveIcon,
} from "../../../theme/assets/headerSvg";
import * as profileIcons from "../../../theme/assets/svg/profile_new_Icons";
import {
  firstTimeOrgSaved,
  getOrganizationAPIData,
} from "../../../redux/profile/yourAccount/organizationDetails/actions/organizationDetails.actions";
import { getPricingPlansAPIData } from "../../../redux/profile/billing/pricingPlan/actions";
// import {
//   setShowRightSideBar,
//   setSelectedFilterType,
// } from "../../../redux/filters/actions";
import Icon from "../../generic/icon/Icon";
import { tickTopIcon, topArrowIcon } from "../../../theme/assets/svg";
import {
  currentProductData,
  getAllDomainData,
  getAllProductData,
} from "../../../redux/signUpFlow/productPage/actions/productPage.actions";
import Avatar from "react-avatar";
import Dropdown from "react-multilevel-dropdown";
import { getPersonalDetails } from "../../../redux/profile/yourAccount/personalDetails/action/personalDetails.action";
import { homedir } from "os";
import Modal from "../../generic/modal/Modal";
import StatusDropdown from "../../agentDesktop/moduleComponents/statusDropdown/StatusDropdown";
import { ROLES } from "../../../constants";
import QueueInformation from "../../agentDesktop/generic/queueInformation/QueueInformation";
import id from "date-fns/esm/locale/id/index.js";
import CountdownChip from "../../generic/countdownChip/CountdownChip";
import { updateUserData } from "../../../redux/onboarding/login/actions";
import ReactTooltip, { TooltipProps } from "react-tooltip";
import {
  setIsBackIconClicked,
  tempStoreScriptDataForBack,
} from "../../../redux/strategy/eachNudgeBox/actions/actionCreators";
import { backIcon } from "../../../theme/assets/svg/campaign_v3";
interface FeatureOptionsDetails {
  1: string;
  2: string;
  3: string;
  4: string;
}

interface CustomTooltipProps extends TooltipProps {
  zIndex: number;
}

export default function NavigationBar(props: any) {
  /* useStates --------- */
  const [nameRedux, setNameRedux] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [isImageUrl, setImageUrl] = useState("");
  const [statusAgent, setStatusAgent] = useState<any>("Available");
  const [selectedPurchasePlan, setSelectedPurchasePlan] = useState<any>({});
  // const [propsOption, setPropsOption] = useState<FeatureOptionsDetails | null>(
  //   null
  // );
  // const [isError, setIsError] = useState();
  const [isCheckActiveRR, setIsCheckActiveRR] = useState({
    reportBugActiveIcon: false,
    requestFeatureActiveIcon: false,
  });
  // profile dropdown
  const [dropdownTab, setDropdownTab] = useState<{
    yourAccount: boolean;
    settings: boolean;
    billing: boolean;
    home: boolean;
    signout: boolean;
  }>({
    yourAccount: true,
    settings: true,
    billing: true,
    home: false,
    signout: false,
  });
  const [pathValidity, setPathValidity] = useState<{ profile: boolean }>({
    profile: false,
  });
  const [isProductPage, setIsProductPage] = useState<boolean>(false);
  const [teamList, setTeamList] = useState<Array<Record<string, any>>>([]);
  const [showDropDown, setShowDropDown] = useState(true);
  const [isShowTimer, setIsShowTimer] = useState(true);
  const [isClickable, setIsClickable] = useState(false);
  const [isShowComponent, setIsShowComponent] = useState(true);

  /* react other hooks --------- */
  const dispatch = useDispatch();

  const ref = useRef<any>();
  const drodownRef = useRef<any>();

  const navigate = useNavigate();
  const location = useLocation();

  /* redux hooks --------------- */

  const showOnlyIconsLeftMenu = useSelector(
    (store: RootState) => store.baseScreen.leftMenu.isOnlyIcons
  );
  const activePage = useSelector(
    (store: RootState) => store.loginReducer.isActivePageType
  );
  const userAccountDetails = useSelector(
    (store: any) => store?.loginReducer?.userLoginInfo?.userDetail
  );
  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
  );

  const organizationInfo = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization.organizationDetails
  );
  console.log("jai ho", organizationInfo);
  const moduleSelectedScreen = useSelector(
    (store: any) => store?.baseScreen?.leftMenu?.highlightedModule
  );

  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );
  const timeForFreeCredit = useSelector(
    (store: any) => store.signUpModuleReducer?.signUp?.timeForFreeCredit
  );

  const freeCredit = useSelector(
    (store: any) => store?.loginReducer?.userLoginInfo?.creditPoints
  );

  const name = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.personal.personalDetails?.data?.data
        ?.name
  );

  const userId = useSelector(
    (state: any) => state?.loginReducer?.userLoginInfo?.userDetail?._id
  );

  const pic = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.personal.personalDetails?.data?.data
        ?.profilePicPath
  );

  const profileHomeTabs: any = useSelector((state: any) => {
    return state.profile.navigationReducer.topDropdownHome.data;
  });

  const profileAccountTabs: any = useSelector((state: any) => {
    return state.profile.navigationReducer.topDropdownSetting.data;
  });
  const agentCommunicationType = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer?.baseScreenAD?.communicationType
  );
  const inQueueCalls = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer?.baseScreenAD?.callNotification?.inQueue
  );

  const mixpannellogInDetails = useSelector(
    (store: any) => store?.mixpanelReducer?.loginDetails
  );
  const teamData = useSelector(
    (store: RootState) => store?.loginReducer?.teamData?.data
  );

  const selectedPlan: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  console.log(selectedPlan?.planName, "lll");

  const domainId = useSelector(
    (state: RootState) =>
      state.signUpModuleReducer?.teamsPageReducer?.selectedTeamDetails?.domainId
  );

  const domainData = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productPageReducer?.domainDetails
  );

  const teamsDetails = useSelector(
    (state: RootState) =>
      state.signUpModuleReducer?.teamsPageReducer?.teamsDetails
  );

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const isProductPageShown = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userDetail?.isProductPageShown
  );
  const currentTeamId = useSelector(
    (state: RootState) => state.loginReducer?.currentTeam?.id
  );

  const currentTeam = useSelector(
    (state: RootState) => state.loginReducer?.currentTeam
  );
  const role = useSelector(
    (state: RootState) => state.loginReducer.userLoginInfo?.userDetail?.role
  );

  const { show, showRight } = useSelector(
    (state: RootState) => state.strategyModuleReducer?.individualNudgeReducer
  );

  const nudgeWithChannelData = useSelector(
    (store: RootState) =>
      store.strategyModuleReducer?.individualNudgeReducer?.selectedNudgeData
  );

  const eachNudgeData = useSelector(
    (state: RootState) =>
      state.strategyModuleReducer?.individualNudgeReducer.setting
  );

  const selectedFlow = showRight
    ? nudgeWithChannelData?.flow
    : eachNudgeData?.flow;

  const timeDifference =
    (new Date(timeForFreeCredit).getTime() - new Date().getTime()) / 1000;

  /* useEffect ----------------- */
  useEffect(() => {
    if (
      accountId &&
      (organizationInfo?.organizationName?.length === 0 ||
        organizationInfo?.gstNumber ||
        Object.keys(organizationInfo).length === 0)
    ) {
      dispatch(getOrganizationAPIData(accountId));
    }
  }, [accountId]);

  useEffect(() => {
    setSelectedPurchasePlan(selectedPlan);
  }, [selectedPlan]);

  useEffect(() => {
    if (
      organizationInfo?.organizationName?.length > 0 &&
      organizationInfo?.gstNumber?.length > 0
    ) {
      setIsShowTimer(false);
    }
    if (selectedPurchasePlan && Object.keys(selectedPurchasePlan)?.length > 0) {
      setIsShowTimer(false);
    }
    if (freeCredit > 0) {
      setIsShowTimer(false);
    }
  }, [organizationInfo, selectedPurchasePlan, freeCredit]);

  useEffect(() => {
    if (timeDifference > 0) {
      setIsShowComponent(true);
    }
    if (timeDifference < 0) {
      if (freeCredit > 0) {
        setIsShowComponent(true);
      } else if (
        organizationInfo?.organizationName?.length > 0 &&
        organizationInfo?.gstNumber?.length > 0
      ) {
        setIsShowComponent(true);
      } else if (
        selectedPurchasePlan &&
        Object.keys(selectedPurchasePlan)?.length > 0
      ) {
        setIsShowComponent(true);
      } else {
        setIsShowComponent(false);
      }
    }
  }, [timeDifference, freeCredit, organizationInfo, selectedPurchasePlan]);

  // console.log(isShowComponent, isShowTimer, timeDifference, "jjj");

  // useEffect(() => {
  //   dispatch(getAllDomainData({ userId: userId, token: token }));
  // }, [userId, token]);

  // useEffect(() => {
  //   if (domainData?.length > 0) {
  //     // if (
  //     //   currentProduct?.domain &&
  //     //   Object.keys(currentProduct?.domain).length !== 0
  //     // ) {
  //     //   dispatch(currentProductData({ domain: currentProduct?.domain?.[0] }));
  //     // } else {
  //     const domainSelected: any = domainData?.filter((data: any) => {
  //       return data.id === domainId;
  //     });
  //     // console.log("kkk", domainSelected?.[0]);
  //     dispatch(currentProductData({ domain: domainSelected?.[0] }));
  //     dispatch(
  //       getAllProductData({
  //         userId: userId,
  //         token: token,
  //         domainId: domainSelected?.[0]?.id,
  //       })
  //     );
  //   }
  //   // }
  // }, [domainData, domainId]);

  useEffect(() => {
    if (userId) {
      dispatch(getPersonalDetails({ userId }));
      if (profileHomeTabs?.length === 0) {
        dispatch(getDropDownHomeDetails({ id: userId, teamId: currentTeamId }));
      }
      if (profileAccountTabs?.length === 0) {
        dispatch(
          getDropDownSettingDetails({ id: userId, teamId: currentTeamId })
        );
      }
    }
  }, [userId]);

  /**For check First Time**/
  useEffect(() => {
    if (organizationInfo?.kycDocPath?.length) {
      const value = organizationInfo?.kycDocPath?.every(
        (doc: any) => doc?.isRequested === true
      );
      dispatch(firstTimeOrgSaved(!value));
    } else {
      dispatch(firstTimeOrgSaved(true));
    }
  }, [organizationInfo]);

  useEffect(() => {
    setNameRedux(name ? name : "");
  }, [name]);

  useEffect(() => {
    setImageUrl(
      // pic ? `${pic}?${process.env.REACT_APP_ACCESS_TOKEN_USERUPLOADS}` : ""
       pic ? `${pic}` : ""
    );
  }, [pic]);

  // const requestFeatureOption = {
  //   1: "Feature Request",
  //   2: "Feature Requested",
  //   3: "Thank you for requesting the feature.",
  //   4: "Request another feature",
  // };
  // const reportBugOption = {
  //   1: "Report a Bug",
  //   2: "Bug Reported",
  //   3: "Thank you for reporting the bug.",
  //   4: "Report another bug",
  // };

  useEffect(() => {
    toGetUserCredentials();
  }, []);

  // To close the request feature and report bug pop up on outside click
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        isCheckActiveRR.requestFeatureActiveIcon &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setIsCheckActiveRR((prev) => {
          return {
            ...prev,
            reportBugActiveIcon: false,
            requestFeatureActiveIcon: false,
          };
        });
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isCheckActiveRR.requestFeatureActiveIcon]);

  // To close the request feature and report bug pop up on outside click
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        isCheckActiveRR.reportBugActiveIcon &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setIsCheckActiveRR((prev) => {
          return {
            ...prev,
            reportBugActiveIcon: false,
            requestFeatureActiveIcon: false,
          };
        });
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isCheckActiveRR.reportBugActiveIcon]);

  useEffect(() => {
    const teamList: Record<string, any>[] = teamData?.flatMap(
      (item: Record<string, any>) => {
        if (!item.isActive) {
          return [];
        }
        return [item];
      }
    );

    teamList?.sort((a: any, b: any) => {
      return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
    });
    setTeamList(teamList);
  }, [teamData]);
  const moduleNames: any = {
    Connector: "समायोजनम्",
    Strategy: "नीति",
    Logger: "स्मृति",
    CRM: "ग्राहकचरितम्",
    Analytics: "प्रज्ञा",
    Admin: "अधिकारः",
    Billing: "विक्रयणम्",
    "Maia Analytics": "विश्लेषणम्",
    Campaign: "अभियानम्",
  };

  const handleModuleName = () => {
    if (moduleSelectedScreen in moduleNames) {
      return moduleNames[moduleSelectedScreen];
    }
  };

  //console.log("valuess", handleModuleName());

  // const handleModuleName = () => {
  //   if (moduleSelectedScreen == "Connector") {
  //     return "समायोजनम्";
  //   }
  //   if (moduleSelectedScreen == "Strategy") {
  //     return "नीति";
  //   }
  //   if (moduleSelectedScreen == "Logger") {
  //     return "स्मृति";
  //   }
  //   if (moduleSelectedScreen == "CRM") {
  //     return "ग्राहकचरितम्";
  //   }
  //   if (moduleSelectedScreen == "Analytics") {
  //     return "प्रज्ञा";
  //   }
  //   if (moduleSelectedScreen == "Admin") {
  //     return "अधिकारः";
  //   }
  //   if (moduleSelectedScreen == "Billing") {
  //     return "विक्रयणम्";
  //   }
  //   if (moduleSelectedScreen == "Maia Analytics") {
  //     return "विश्लेषणम्";
  //   }
  //   if (moduleSelectedScreen == "Campaign") {
  //     return "अभियानम्";
  //   }
  // };

  // console.log("hh", moduleSelectedScreen);
  // selection between  request feature and report issue
  // const handleClickRequestReport = (type: any) => {
  //   dispatch(setShowRightSideBar(false));
  //   dispatch(setSelectedFilterType(""));
  //   if (type === "requestFeature") {
  //     setPropsOption((previouState) => {
  //       return {
  //         ...requestFeatureOption,
  //       };
  //     });
  //     setIsCheckActiveRR((prev) => {
  //       return {
  //         ...prev,
  //         reportBugActiveIcon: false,
  //         requestFeatureActiveIcon: !prev.requestFeatureActiveIcon,
  //       };
  //     });
  //   } else {
  //     setPropsOption((previouState) => {
  //       return {
  //         ...reportBugOption,
  //       };
  //     });
  //     setIsCheckActiveRR((prev) => {
  //       return {
  //         ...prev,
  //         reportBugActiveIcon: !prev.reportBugActiveIcon,
  //         requestFeatureActiveIcon: false,
  //       };
  //     });
  //   }
  // };

  // const toCloseRRModel = () => {
  //   setIsCheckActiveRR((prev) => {
  //     return {
  //       ...prev,
  //       reportBugActiveIcon: false,
  //       requestFeatureActiveIcon: false,
  //     };
  //   });
  // };

  useEffect(() => {
    const path = location.pathname.split("/").filter((e) => e !== "");
    //console.log(path, "left");
    // //********************************* */
    // const yourAccountInHome = profileHomeTabs?.filter((e: any) => {
    //   return e.name === "Your Account";
    // })[0]?.isVisible
    //   ? true
    //   : false;
    // const settingsInHome = profileHomeTabs?.filter((e: any) => {
    //   return e.name === "Settings";
    // })[0]?.isVisible
    //   ? true
    //   : false;
    // const billingInHome = profileHomeTabs?.filter((e: any) => {
    //   return e.name === "Billing";
    // })[0]?.isVisible
    //   ? true
    //   : false;
    // const homeInHome = profileHomeTabs?.filter((e: any) => {
    //   return e.name === "Home";
    // })[0]?.isVisible
    //   ? true
    //   : false;
    // const signOutInHome = profileHomeTabs?.filter((e: any) => {
    //   return e.name === "Sign Out";
    // })[0]?.isVisible
    //   ? true
    //   : false;
    // //**************************** */
    // const yourAccountInProfile = profileAccountTabs?.filter((e: any) => {
    //   return e.name === "Your Account";
    // })[0]?.isVisible
    //   ? true
    //   : false;
    // const settingsInProfile = profileAccountTabs?.filter((e: any) => {
    //   return e.name === "Settings";
    // })[0]?.isVisible
    //   ? true
    //   : false;
    // const billingInProfile = profileAccountTabs?.filter((e: any) => {
    //   return e.name === "Billing";
    // })[0]?.isVisible
    //   ? true
    //   : false;
    // const homeInProfile = profileAccountTabs?.filter((e: any) => {
    //   return e.name === "Home";
    // })[0]?.isVisible
    //   ? true
    //   : false;
    // const signOutInProfile = profileAccountTabs?.filter((e: any) => {
    //   return e.name === "Sign Out";
    // })[0]?.isVisible
    //   ? true
    //   : false;

    switch (path[0]) {
      case "profile":
        setPathValidity((prev) =>
          prev?.profile === true ? prev : { profile: true }
        );
        // setDropdownTab({
        //   yourAccount: yourAccountInProfile,
        //   settings: settingsInProfile,
        //   billing: billingInProfile,
        //   home: homeInProfile,
        //   signout: signOutInProfile,
        // });
        setIsOnlyIconsLeftMenu(dispatch, false);
        break;
      default:
        // setDropdownTab({
        //   yourAccount: yourAccountInHome,
        //   settings: settingsInHome,
        //   billing: billingInHome,
        //   signout: signOutInHome,
        //   home: homeInHome,
        // });
        setPathValidity((prev) =>
          prev?.profile === false ? prev : { profile: false }
        );
    }
  }, [location, profileHomeTabs, profileAccountTabs]);

  const toGetUserCredentials = async () => {
    const temp = await sessionStorage.getItem("name");
  };

  const logout = () => {
    // localStorage.clear();
    // sessionStorage.clear();
    // clearCacheData();
    // dispatch(userLogoutAction());
    // setTimeout(() => {
    //   navigate("/");
    // }, 1000);
    const loginSessionTime =
      new Date().getTime() / 1000 - Number(mixpannellogInDetails?.startTime);
    Mixpanel.track(`Platform | Overall Session Time`, {
      module: "LogIn",
      "user Name": mixpannellogInDetails?.accountName,
      Date: `${format(new Date(), "dd-MM-yyyy")}`,
      "Total Time Spent":
        Math.floor(Number(loginSessionTime) / 60) >= 1
          ? `${Math.floor(Number(loginSessionTime) / 60)} minutes`
          : `${Number(loginSessionTime)} second`,
    });
    navigate("/signout");
  };

  const handleToggleButtonClick = () => {
    setIsOnlyIconsLeftMenu(dispatch, !showOnlyIconsLeftMenu);
  };
  // path for profile disable close button

  const handleClickReportBug = () => {
    window.open(
      "https://forms.clickup.com/3667185/f/3fx7h-38984/6HO5TLVGIH4MOBD0AU"
    );
  };

  function goToHome() {
    navigate("/dashboard");
  }
  function goToTeamList(){
     navigate("/userlist")
  }

  function goToAndClose(path: string, name: string, e: any) {
    if (name === "Sign Out") {
      // logout();
      e.stopPropagation();
      setCreateModal(true);
      return;
    }
    navigate(path);
    if (drodownRef.current) {
      drodownRef.current.toggle();
    }
  }

  // useEffect(() => {
  //   if (location.pathname == "/productPage") {
  //     setIsProductPage(true);
  //   } else {
  //     setIsProductPage(false);
  //   }
  // }, [isProductPage, location]);

  useEffect(() => {
    if (isProductPageShown) {
      if (teamsDetails?.length === 0) {
        setIsProductPage(true);
      } else {
        setIsProductPage(false);
      }
    } else {
      setIsProductPage(false);
    }
  }, [isProductPage, teamsDetails]);

  useEffect(() => {
    if (!isProductPage) {
      if (!pathValidity.profile) {
        if (location.pathname == "/productPage" && teamsDetails?.length > 0 ) {
          setIsClickable(true);
        } else {
          setIsClickable(false);
        }
      } else {
        setIsClickable(false);
      }
    } else {
      setIsClickable(false);
    }
  }, [isProductPage, location, pathValidity]);

  // console.log("sfdjhsfkd", location.pathname, isProductPage);

  /* handlers ----------- */
  const handleTeamChangeClick = (e: Event, item: Record<string, any>) => {
    if (item.id !== currentTeamId) {
      dispatch(setCurrentTeam(item));

      toast.success(`Switching to ${item.teamName} team`);
      setShowDropDown(false);
      setTimeout(() => {
        setShowDropDown(true);
      }, 500);
    }
  };

  useEffect(() => {
    if (currentTeam && Object.keys(currentTeam).length > 0) {
      dispatch(
        updateUserData({
          id: accountId,
          name: accountName,
          domain: currentTeam?.domainName,
        })
      );
    }
  }, [currentTeam, accountId, accountName]);
  console.log("nayak", selectedPurchasePlan);
  return (
    <div className="navbar-wrapper">
      <div className={styles.navigationbarTopDiv} ref={ref}>
        <div className={styles.navgationLeft}>
          <div className={styles.navgationLeftChild}>
            <span className={styles.navgationLeftLogoArea}>
              {!isProductPage ? (
                !pathValidity.profile ? (
                  location.pathname == "/productPage" ? null : (
                    // <img
                    //   src={homeIcon}
                    //   onClick={() => {
                    //     goToHome();
                    //   }}
                    //   alt="home"
                    //   className={styles.homeIconStyling}
                    // />
                    <img
                      className={styles.sidebarIcon}
                      src={
                        showOnlyIconsLeftMenu
                          ? sidebarIconClosed
                          : sidebarIconOpen
                      }
                      onClick={handleToggleButtonClick}
                    />
                  )
                ) : (
                  <img
                    src={homeIcon}
                    onClick={() => {
                      goToHome();
                    }}
                    alt="home"
                    className={styles.homeIconStyling}
                  />
                )
              ) : null}
              {isClickable ? (
                <img
                  className={styles.backMainnav}
                  data-testid="logo"
                  src={backIcon}
                  onClick={() => {
                    goToTeamList();
                  }}
                />
              ) : null}
              <img
                className={`${styles.logomainnav}`}
                data-testid="logo"
                src={pravidLogo}
                onClick={() => {
                  goToHome();
                }}
              />

              {accountName?.includes("Sphot") && (
                <p className={styles.moduleName}>{handleModuleName()}</p>
              )}
              {location.pathname.includes("/strategy/scriptselection") ? (
                <div className={styles.scriptSelectDiv}>
                  <Button
                    text="Back"
                    id="back-btn"
                    extraClass={styles.scriptBackBtn}
                    onClick={() => {
                      dispatch(tempStoreScriptDataForBack({}));
                      dispatch(setIsBackIconClicked(true));
                      navigate("/strategy/nudgecreation");
                    }}
                  />
                  <div className={styles.pageHeaderStyling}>
                    Script Selection
                  </div>
                  <div className={styles.flowStyling}>{selectedFlow}</div>
                </div>
              ) : null}
            </span>{" "}
          </div>
        </div>
        <div className={styles.navigationRightChild}>
          {/* <div className={styles.navigationRightClientPic}>
            {isImageUrl && isImageUrl !== "" ? (
              <img
                style={{ width: 35, height: 35, borderRadius: "50%" }}
                src={isImageUrl}
                className={styles.profilePictureStyling}
              />
            ) : (
              <Avatar
                name={nameRedux}
                size="35"
                round="17px"
                color={"#DDECFF"}
                fgColor={"#0174FF"}
              />
            )}
          </div>
          <div className={styles.navigationRightClientInfo}>
            <div className={styles.navigationRightClientName}>{nameRedux}</div>
            <div className={styles.navigationRightClientRole}>
              {userAccountDetails.role}
            </div>
          </div> */}
          {(timeForFreeCredit?.length || !isShowTimer) &&
          role !== ROLES.agentDesktop_admin.name &&
          role !== ROLES.agentDesktop_agent.name ? (
            <CountdownChip
              isCountdown={isShowTimer ? true : false}
              credits={
                selectedPurchasePlan?.conversationDetails
                  ? Number(
                      selectedPurchasePlan?.conversationDetails?.totalLimit
                    ) +
                    Number(
                      selectedPurchasePlan?.conversationDetails
                        ?.addonCreditLimit
                    ) -
                    (Number(
                      selectedPurchasePlan?.conversationDetails?.totalUsage
                    ) +
                      Number(
                        selectedPurchasePlan?.conversationDetails
                          ?.addonCreditUsage
                      ))
                  : 0
              }
              baseTimer={timeForFreeCredit}
              price={selectedPurchasePlan?.price}
              showComponent={isShowComponent}
            />
          ) : (
            ""
          )}

          {userAccountDetails.role === ROLES.agentDesktop_agent.name ? (
            agentCommunicationType === "call" ? (
              <div className={styles["queue-info"]}>
                <QueueInformation
                  queueQuantity={inQueueCalls}
                  quantityColor={"#F5222D"}
                  queueContent={"Calls on Queue"}
                  indicatorColor={"#0174FF"}
                  indicatorMessage={"On Call"}
                />
              </div>
            ) : (
              <span className={styles.agentStatus}>
                <StatusDropdown selected={statusAgent} />
              </span>
            )
          ) : (
            <></>
          )}
          <div
            className={`${styles.navigationRightRoutes} ${
              agentCommunicationType === "call"
                ? "disablePointerEventUniversaljp"
                : ""
            }`}
          >
            <Dropdown
              data-testid="routeDropdown"
              ref={drodownRef}
              title={
                <div className={styles.navRightNew}>
                  <div className={styles.navigationRightClientPic}>
                    {isImageUrl && isImageUrl !== "" ? (
                      <img
                        style={{ width: 35, height: 35, borderRadius: "50%" }}
                        src={isImageUrl}
                        className={styles.profilePictureStyling}
                        id="profile_pic"
                        data-testid="profile_pic"
                      />
                    ) : (
                      <Avatar
                        name={
                          nameRedux !== undefined
                            ? nameRedux?.split(" ").slice(0, 2).join(" ")
                            : userAccountDetails.name
                                .split(" ")
                                .slice(0, 2)
                                .join(" ")
                        }
                        size="35"
                        round="17px"
                        color={"#DDECFF"}
                        fgColor={"#0174FF"}
                      />
                    )}
                  </div>
                  <div className={styles.navigationRightClientInfo}>
                    <div
                      id="user_name_top"
                      className={styles.navigationRightClientName}
                    >
                      {nameRedux ? nameRedux : userAccountDetails.name}
                    </div>
                    <div
                      id="user_role_top"
                      className={styles.navigationRightClientRole}
                    >
                      {
                        Object.values(ROLES).find(
                          (item) =>
                            item.display &&
                            item.name === userAccountDetails.role &&
                            (!item.useCaseId ||
                              item.useCaseId === currentTeam.useCaseId)
                        )?.displayName
                      }
                    </div>
                  </div>
                  <img
                    src={downArrow}
                    className={styles.downArrow}
                    alt="downArrow"
                    id="auto_dropdown_arrow"
                    data-testid="auto_dropdown_arrow"
                  />
                </div>
              }
              wrapperClassName={styles.dropdownStyle}
              buttonClassName={styles.dropdownButton}
              menuClassName={styles.noShadowMain}
            >
              {/* {pathValidity.profile && (
                <Dropdown.Item
                  className={styles.itemx}
                  onClick={() => {
                    goToAndClose("/");
                  }}
                >
                  Home
                </Dropdown.Item>
              )}
              {dropdownTab.yourAccount && (
                <Dropdown.Item
                  className={styles.itemx}
                  onClick={() => {
                    goToAndClose("profile/your-account");
                  }}
                >
                  Your Account
                </Dropdown.Item>
              )}
              {dropdownTab.settings && (
                <Dropdown.Item
                  className={styles.itemx}
                  onClick={() => {
                    goToAndClose("profile/settings");
                  }}
                >
                  Settings
                </Dropdown.Item>
              )}
              {dropdownTab.billing && (
                <Dropdown.Item
                  className={styles.itemx}
                  onClick={() => {
                    goToAndClose("profile/billing");
                  }}
                >
                  Billing
                </Dropdown.Item>
              )} */}

              {!isProductPage ? (
                pathValidity.profile ? (
                  <>
                    {profileAccountTabs?.map((each: any, index: number) => {
                      if (each.name === "Sign Out") {
                        return [];
                      }
                      return [
                        <Dropdown.Item
                          className={`${styles.itemx}} item`}
                          onClick={(e: any) => {
                            goToAndClose(each?.routeName, each.name, e);
                          }}
                          key={index}
                        >
                          <span id={"auto_" + each.name}> {each.name}</span>
                        </Dropdown.Item>,
                      ];
                    })}
                  </>
                ) : (
                  <>
                    {profileHomeTabs?.flatMap((each: any, index: number) => {
                      if (each.name === "Sign Out") {
                        return [];
                      }
                      return [
                        each.isVisible && (
                          <Dropdown.Item
                            className={`${styles.itemx}} item`}
                            key={index}
                            onClick={(e: any) => {
                              goToAndClose(each?.routeName, each.name, e);
                            }}
                          >
                            {" "}
                            <span id={"auto_" + each.name}> {each.name}</span>
                          </Dropdown.Item>
                        ),
                      ];
                    })}
                  </>
                )
              ) : (
                <></>
              )}

              {/* <Dropdown.Item
                className={styles.itemx}
                onClick={() => {
                  logout();
                }}
              >
                Sign out
              </Dropdown.Item> */}
              {isProductPage ? (
                ""
              ) : (
                <>
                  <div className={styles.divider}></div>
                  {role !== ROLES.agentDesktop_admin.name &&
                    role !== ROLES.agentDesktop_agent.name && (
                      <Dropdown.Item className={`${styles.itemx}} item`}>
                        <span
                          className={styles.switchTeamDiv}
                          id="auto_Switch Team"
                          data-testid="switchTeam"
                        >
                          <span className={styles.switchTeamText}>
                            Switch Team
                          </span>
                          <Icon
                            img_src={topArrowIcon}
                            extraClass={styles.switchTeamIconStyling}
                          />
                        </span>
                        {showDropDown ? (
                          <Dropdown.Submenu
                            className={styles.noShadowSub}
                            position="left"
                          >
                            {teamList?.map((item, index) => (
                              <Dropdown.Item
                                className={`${styles.itemx} subItem ${styles.itemToolTip}  `}
                                key={index}
                                onClick={(e) => handleTeamChangeClick(e, item)}
                                isActive={item.id === currentTeamId}
                              >
                                <span
                                  // data-tip
                                  data-tip={item.teamName}
                                  data-place="left"
                                  data-offset="{'left': 25}"
                                  // data-for={`${item.teamName}`}
                                  id={`${item.teamName}`}
                                  // id={`Team-${item.id}`}
                                  data-team-id={item.id}
                                  className={styles.selectedSwitchTeamDiv}
                                  // onClick={() => handleTeamChangeClick(item)}
                                >
                                  <span
                                    className={styles.selectedSwitchTeamText}
                                  >
                                    {item.teamName}
                                  </span>
                                </span>
                                {item.id === currentTeamId && (
                                  <Icon
                                    img_src={tickTopIcon}
                                    extraClass={
                                      styles.selectedSwitchTeamIconStyling
                                    }
                                  />
                                )}
                                {/* <span>
                              <span className={styles.itemToolTipText1}>
                                {item.teamName}
                              </span>
                            </span> */}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Submenu>
                        ) : (
                          <></>
                        )}
                      </Dropdown.Item>
                    )}
                  <Dropdown.Item
                    className={`${styles.itemx}} item`}
                    onClick={(e: any) => {
                      goToAndClose("Sign Out", "Sign Out", e);
                    }}
                  >
                    {" "}
                    <span id={"auto_" + "Sign Out"}> {"Sign Out"}</span>
                  </Dropdown.Item>
                </>
              )}
              <ReactTooltip effect="solid" backgroundColor="#7a7979" />
            </Dropdown>
          </div>
          {/* <span
          // onClick={logout}
          >
            <Button
              text="Log Out"
              extraClass={styles.logoutButtonStyle}
              onClick={() => logout()}
            /> */}
          {/* Logout */}
          {/* </a> */}
          {/* <span style={{ position: "relative" }}>
            <span
              className={styles.requestFeatureSpan}
              onClick={() => handleClickRequestReport("requestFeature")}
            >
              <span className={styles.requestFeaturePopupIcon}>
                <img
                  src={
                    isCheckActiveRR.requestFeatureActiveIcon
                      ? requestFeatureActiveIcon
                      : requestFeatureIcon
                  }
                  alt="request feature"
                />
                <span className={styles.requestFeaturePopupDescription}>
                  {" "}
                  Request Feature{" "}
                </span>
              </span>
            </span>
          </span>
          {isCheckActiveRR.requestFeatureActiveIcon && (
            <ReportRequest
              propsOption={propsOption}
              toCloseRRModel={() => toCloseRRModel()}
            />
          )} */}
          {/* <span style={{ position: "relative" }}>
            <span
              className={styles.reportBugSpan}
              // onClick={() => handleClickRequestReport("reportbug")}
              onClick={() => handleClickReportBug()}
            >
              <span className={styles.requestFeaturePopupIcon}>
                <img
                  src={
                    isCheckActiveRR.reportBugActiveIcon
                      ? reportBugActiveIcon
                      : reportBugs
                  }
                  alt="report bug"
                />
                <span className={styles.requestFeaturePopupDescription}>
                  {" "}
                  Report Bug{" "}
                </span>
              </span>
            </span>
          </span> */}
          {/* {isCheckActiveRR.reportBugActiveIcon && (
            <ReportRequest
              propsOption={propsOption}
              toCloseRRModel={() => toCloseRRModel()}
            />
          )} */}
        </div>
      </div>
      {/* <ToastContainer
        position="top-center"
        // type="success"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={true}
      /> */}
      <Modal
        shown={createModal}
        close={() => {
          setCreateModal(false);
        }}
        extraClassStyleModalContent={styles.ModalContentDiv}
      >
        <div className={styles.closeIcon}>
          <img
            src={signOutCloseIcon}
            onClick={() => {
              setCreateModal(false);
            }}
            className={styles.IconStyling}
          />
        </div>
        <div className={styles.ModalContent}>
          <div className={styles.ModalContentText}>
            Are you sure you want to sign out?
          </div>
          <div className={styles.ModalContentButtons}>
            <Button
              text="Cancel"
              extraClass={`${styles.buttonStyling} ${styles.cancelButtonStyling}`}
              onClick={() => {
                setCreateModal(false);
              }}
            />
            <Button
              text="Yes"
              extraClass={`${styles.buttonStyling} ${styles.yesButtonStyling}`}
              onClick={() => {
                logout();
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
