// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fClm_VmSA1AvlrAFwubH{padding:1em;width:100%;height:100%}.fClm_VmSA1AvlrAFwubH .hgXN78khMaSoCJVWFil4{border-bottom:1px solid #e6e6e6}.fClm_VmSA1AvlrAFwubH .hgXN78khMaSoCJVWFil4 .PYN0pmrAZwr4tbb6bCOM{font-family:"Roboto";font-style:normal;font-weight:500;font-size:20px;line-height:120%;color:#333;padding:.5em 0em 1em 0em}.fClm_VmSA1AvlrAFwubH .egspggDnQzFIvxFCVgCO{display:flex;justify-content:space-between;align-items:flex-start;padding-top:1.75em}.fClm_VmSA1AvlrAFwubH .egspggDnQzFIvxFCVgCO .REK_lrXsx9Pxba1dIL1N{width:54%}.fClm_VmSA1AvlrAFwubH .egspggDnQzFIvxFCVgCO .IXU3d1r6k5eUwY_w8irB{width:42%}`, "",{"version":3,"sources":["webpack://./src/screens/profile/paymentScreen/maiaSummary/MaiaSummaryScreen.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,UAAA,CACA,WAAA,CAEA,4CACE,+BAAA,CAEA,kEACE,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA,CACA,wBAAA,CAIJ,4CACE,YAAA,CACA,6BAAA,CACA,sBAAA,CACA,kBAAA,CAEA,kEACE,SAAA,CAEF,kEACE,SAAA","sourcesContent":[".orderSummaryScreenWrapper {\n  padding: 1em;\n  width: 100%;\n  height: 100%;\n\n  .orderScreenHeader {\n    border-bottom: 1px solid #e6e6e6;\n\n    .orderScreenHeaderText {\n      font-family: \"Roboto\";\n      font-style: normal;\n      font-weight: 500;\n      font-size: 20px;\n      line-height: 120%;\n      color: #333333;\n      padding: 0.5em 0em 1em 0em;\n    }\n  }\n\n  .orderSummaryScreen {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n    padding-top: 1.75em;\n\n    .plansummaryClass {\n      width: 54%;\n    }\n    .ordersummaryClass {\n      width: 42%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orderSummaryScreenWrapper": `fClm_VmSA1AvlrAFwubH`,
	"orderScreenHeader": `hgXN78khMaSoCJVWFil4`,
	"orderScreenHeaderText": `PYN0pmrAZwr4tbb6bCOM`,
	"orderSummaryScreen": `egspggDnQzFIvxFCVgCO`,
	"plansummaryClass": `REK_lrXsx9Pxba1dIL1N`,
	"ordersummaryClass": `IXU3d1r6k5eUwY_w8irB`
};
export default ___CSS_LOADER_EXPORT___;
