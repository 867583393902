import routes from "./routes";
import { ROLES } from "../constants";

interface routeConfig {
  allowedRoles: Array<string>;
  redirect?: string;
  children?: Record<any, routeConfig>;
}

/**
 * if path allowed for all roles -> allowedRoles = ["*",...] or don't add in config
 */

const routeConfig: Record<any, routeConfig> = {
  /*****************************  onboarding  *****************************/
  [routes.LOGIN]: {
    allowedRoles: ["*"],
  },

  /*****************************  main app  *****************************/
  [routes.DASHBOARD]: {
    allowedRoles: [
      ROLES.admin.name,
      ROLES.campaignAnalyst.name,
      ROLES.campaignManager.name,
      // ROLES.salesManager.name,
      // ROLES.collectionManager.name,
      ROLES.chiefRiskOfficer.name,
      ROLES.guest.name,
      ROLES.financialSpecialist.name,
    ],
  },
  [routes.CALL_LOGGER]: {
    allowedRoles: [
      ROLES.admin.name,
      ROLES.campaignAnalyst.name,
      ROLES.campaignManager.name,
      // ROLES.salesManager.name,
      // ROLES.collectionManager.name,
      ROLES.chiefRiskOfficer.name,
      ROLES.guest.name,
      ROLES.financialSpecialist.name,
    ],
  },
  // [routes.CONNECTOR_PAGE]: {
  //   allowedRoles: ["*"],
  // },
  [routes.USER_LIST]: {
    // admin module
    allowedRoles: [
      ROLES.admin.name,
      ROLES.chiefRiskOfficer.name,
      ROLES.guest.name,
      ROLES.financialSpecialist.name,
    ],
  },
  [routes.CAMPAIGN_MANGER]: {
    allowedRoles: [
      ROLES.admin.name,
      ROLES.campaignManager.name,
      // ROLES.salesManager.name,
      // ROLES.collectionManager.name,
      ROLES.chiefRiskOfficer.name,
      ROLES.guest.name,
    ],
  },
  [routes.STRATEGY]: {
    allowedRoles: [
      ROLES.admin.name,
      // ROLES.salesManager.name,
      // ROLES.collectionManager.name,
      ROLES.chiefRiskOfficer.name,
      ROLES.campaignManager.name,
      ROLES.guest.name,
    ],
  },
  [routes.PROFILE]: {
    allowedRoles: [
      ROLES.admin.name,
      ROLES.campaignAnalyst.name,
      ROLES.campaignManager.name,
      // ROLES.salesManager.name,
      // ROLES.collectionManager.name,
      ROLES.chiefRiskOfficer.name,
      ROLES.guest.name,
      ROLES.financialSpecialist.name,
    ],
    children: {
      [routes.BILLING]: {
        allowedRoles: [
          ROLES.admin.name,
          // ROLES.salesManager.name,
          // ROLES.collectionManager.name,
          ROLES.chiefRiskOfficer.name,
          ROLES.guest.name,
          ROLES.financialSpecialist.name,
        ],
      },
    },
  },
  [routes.PRODUCT_PAGE]: {
    allowedRoles: [
      ROLES.admin.name,
      ROLES.chiefRiskOfficer.name,
      ROLES.guest.name,
    ],
  },
  [routes.CONNECTOR_PAGE]: {
    allowedRoles: [
      ROLES.admin.name,
      ROLES.campaignManager.name,
      // ROLES.salesManager.name,
      // ROLES.collectionManager.name,
      ROLES.chiefRiskOfficer.name,
      ROLES.guest.name,
    ],
  },

  /*****************************  agent desktop  **************************** */
  [routes.AGENT_DESKTOP]: {
    allowedRoles: [
      ROLES.agentDesktop_admin.name,
      ROLES.agentDesktop_agent.name,
    ],
    children: {
      [routes.AGENT_DASHBOARD]: {
        allowedRoles: [ROLES.agentDesktop_agent.name],
      },
      [routes.AGENT_CALL]: {
        allowedRoles: [ROLES.agentDesktop_agent.name],
      },
      [routes.AGENT_CHAT]: {
        allowedRoles: [ROLES.agentDesktop_agent.name],
      },
      [routes.ADMIN_DASHBOARD]: {
        allowedRoles: [ROLES.agentDesktop_admin.name],
      },
      [routes.ADMIN_PERFORMANCE]: {
        allowedRoles: [ROLES.agentDesktop_admin.name],
      },
    },
  },
};

export default routeConfig;
