import actionTypes from "../actionTypes";

export const getCallSummaryAgentDesktop = (sessionId: string) => {
  return {
    type: actionTypes.GET_SUMMARY_REQUEST_AGENT_DESKTOP,
    payload: sessionId,
  };
};

export const getCallTranscriptAgentDesktop = (sessionId: string) => {
  return {
    type: actionTypes.GET_TRANSCRIPT_REQUEST_AGENT_DESKTOP,
    payload: sessionId,
  };
};

export const setSummaryDataEditable = (data: Record<string, any>) => {
  return {
    type: actionTypes.SET_SUMMARY_DATA_EDITABLE_AGENT_DESKTOP,
    payload: data,
  };
};
/**
 * 
 * {
    "accountName": "Capri Global Testing",
    "channel": [
        "Call"
    ],
    "dategte": "2021-11-01T00:00:00",
    "datelte": "2024-03-31T23:59:59",
    "limit": 10,
    "page": 1,
    "phoneNo": "",
    "agentIds": [
        "63b57e181ae92c6fe710320d"
    ]
}
 * @param data 
 * @returns 
 */
export const getSessionIdAgentDesktop = (data: {
  accountName: string;
  channel: string[];
  // dategte: string;
  // datelte: string;
  limit: number;
  page: number;
  phoneNo?: string;
  agentIds?: string[];
}) => {
  return {
    type: actionTypes.GET_SESSION_ID_REQUEST_AGENT_DESKTOP,
    payload: data,
  };
};

export const setChatStatus = (status: string) => {
  return { type: actionTypes.SET_CHAT_STATUS_AGENT_DESKTOP, payload: status };
};
export const setSelectedSessionId = (sessionId: string) => {
  return {
    type: actionTypes.SET_SELECTED_SESSION_ID_AGENT_DESKTOP,
    payload: sessionId,
  };
};
export const setSelectedConversationId = (id: string) => {
  return {
    type: actionTypes.SET_SELECTED_CONVERSATION_ID_AGENT_DESKTOP,
    payload: id,
  };
};

export const updateSummary = (
  data: Record<string, any>,
  conversationId: string
) => {
  return {
    type: actionTypes.UPDATE_SUMMARY_REQUEST_AGENT_DESKTOP,
    payload: { data, conversationId },
  };
};

export const setSummaryUpdateSuccess = (value: boolean) => {
  return {
    type: actionTypes.SET_SUMMARY_UPDATE_SUCCESS_AGENT_DESKTOP,
    payload: value,
  };
};

export const setTimestampCallAccept = (time: Date | null) => {
  return {
    type: actionTypes.SET_TIMESTAMP_CALL_ACCEPT_AGENT_DESKTOP,
    payload: time,
  };
};

// export const setSessionIdLoader = () => {
//   return { type: actionTypes.LOADER_SESSION_ID_AD };
// };

export const handleCallModuleLeave = () => {
  return { type: actionTypes.HANDLE_CALL_MODULE_LEAVE_AD };
};

export const setShowCallModule = (value: boolean) => {
  return { type: actionTypes.SET_SHOW_CALL_MODULE_AD, payload: value };
};
export const setCallSessionIdLoader = (value: boolean) => {
  return { type: actionTypes.SET_CALL_SESSION_ID_LOADER, payload: value };
};
