import * as SKELTON_DATA_INTEGRATION from "./integration";
import * as SKELTON_DATA_LOGGER from "./logger";
import * as SKELTON_DATA_STRATEGY from "./strategy";

const SKELTON_DATA = {
  ...SKELTON_DATA_INTEGRATION,
  ...SKELTON_DATA_LOGGER,
  ...SKELTON_DATA_STRATEGY,
};

export { SKELTON_DATA };
