import actionTypes from "../campaignTable.actionTypes";

export const getAllCampaignsData = (payload: any) => {
  return {
    type: actionTypes.GET_ALL_CAMPAIGNS_DATA,
    payload: payload,
  };
};

export const setSelectedCampaignData = (payload: any) => {
  return {
    type: actionTypes.SET_SELECTED_CAMPAIGN_DATA,
    payload: payload,
  };
};

export const clearAllCampaignData = () => {
  return {
    type: actionTypes.CLEAR_ALL_CAMPAIGN_DATA,
  };
};
export const terminateCampaign = (payload: any) => {
  return {
    type: actionTypes.POST_CAMPAIGN_TERMINATE_REQUEST,
    payload: payload,
  };
};