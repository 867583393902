import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import actionTypes from "../actionTypes";
import {
  ONBOARDING_URL,
  config,
  DEMO_SIGNUP_URL,
  SERVER_URL,
} from "../../../../services/ApiRoutes";
export function* signUpAPIRequestWorker(action: any): any {
  try {
    const res = yield call(
      config.POST_WITH_BODY,
      ONBOARDING_URL.SIGN_UP_API,
      action.payload
    );
    yield put({
      type: actionTypes.SIGN_UP_API_SUCCESS,
      payload: res.data.data,
    });
  } catch (err: any) {
    yield put({
      type: actionTypes.SIGN_UP_API_FAILURE,
      payload: err.response?.data?.error,
    });
  }
}

export function* createPasswordAPIRequestWorker(action: any): any {
  try {
    const res = yield call(
      config.POST_WITH_BODY,
      ONBOARDING_URL.CREATE_PASSWORD,
      action.payload
    );
    yield put({
      type: actionTypes.CREATE_PASSWORD_SUCCESS_SIGNUP,
      payload: res?.data?.data,
    });
  } catch (err: any) {
    yield put({
      type: actionTypes.CREATE_PASSWORD_FAILURE_SIGNUP,
      payload: err.response.data.error,
    });
  }
}

export function* sendOTPExperienceNowWorker(action: any): any {
  try {
    const res = yield call(
      config.POST_WITH_BODY,
      DEMO_SIGNUP_URL.DEMO_SEND_OTP,
      action.payload
    );
    yield put({
      type: actionTypes.SEND_OTP_SING_UP_SUCCESS,
      payload: res.data.data,
    });
    toast.success(res?.data?.data?.message);
  } catch (err: any) {
    yield put({ type: actionTypes.SEND_OTP_SING_UP_FAILURE, payload: err });
    toast.error(err);
  }
}
export function* verifyOTPExperienceNowWorker(action: any): any {
  try {
    const res = yield call(
      config.POST_WITH_BODY,
      DEMO_SIGNUP_URL.DEMO_VERIFY_OTP,
      action.payload
    );
    yield put({
      type: actionTypes.VERIFY_OTP_SING_UP_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    yield put({ type: actionTypes.VERIFY_OTP_SING_UP_FAILURE, payload: err });
  }
}
export function* initiatCallExperienceNow(action: any): any {
  try {
    const res = yield call(
      config.POST_WITH_BODY,
      DEMO_SIGNUP_URL.EXPERIENCE_NOW,
      action.payload
    );
    yield put({
      type: actionTypes.CALL_EXPERIENCE_NOW_SUCCESS,
      payload: res.data.data,
    });
    yield put({
      type: actionTypes.SET_TIMER_FOR_FREE_CREADIT,
      payload: res.data.data?.experienceNowTimer,
    });
    yield put({
      type: actionTypes.SET_EXP_NOW_COUNT,
      payload: 1,
    });
  } catch (err) {
    yield put({ type: actionTypes.CALL_EXPERIENCE_NOW_FAILURE, payload: err });
  }
}
export function* resetPasswordRequestWorker(action: any): any {
  try {
    const res = yield call(
      config.POST_WITH_HEADERS_BODY,
      `${SERVER_URL}${ONBOARDING_URL.UPDATE_RESET_PASSWORD}`,
      action.payload?.body,
      action.payload?.header
    );
    yield put({
      type: actionTypes.RESET_PASSWORD_SUCCESS,
      payload: res?.data?.data,
    });
    if (res) {
      toast.success(res?.data?.data?.message);
      action.payload.fun();
    }
  } catch (err: any) {
    yield put({
      type: actionTypes.RESET_PASSWORD_FAILURE,
      payload: err.response.data.error,
    });
  }
}
