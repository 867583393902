export default {
  GET_SECRET_API_REQUEST: "GET_SECRET_API_REQUEST",
  GET_SECRET_API_SUCCESS: "GET_SECRET_API_SUCCESS",
  GET_SECRET_API_FAILURE: "GET_SECRET_API_FAILURE",
  SEND_INFO_TO_DEV_API_REQUEST: "SEND_INFO_TO_DEV_API_REQUEST",
  SEND_INFO_TO_DEV_API_SUCCESS: "SEND_INFO_TO_DEV_API_SUCCESS",
  SEND_INFO_TO_DEV_API_FAILURE: "SEND_INFO_TO_DEV_API_FAILURE",
  CLEAR_CUSTOM_INTEGRATION_DATA: "CLEAR_CUSTOM_INTEGRATION_DATA",
  CLEAR_CUSTOM_INTEGRATION_API_DATA: "CLEAR_CUSTOM_INTEGRATION_API_DATA",
};
