import React from "react";
import Icon from "../../../../generic/icon/Icon";
import styles from "./Method.module.scss";

interface methodProps {
  methodData: {
    name: string;
    icon: string;
  };
  method: string;
  sendSelectedMethod: (methodName: string)=>void;
}
export const MethodDiv = ({
  methodData,
  method,
  sendSelectedMethod,
}: methodProps) => {
  const handleMethod = (methodName: string) => {
    sendSelectedMethod(methodName);
  };

  return (
    <div
      className={`${
        methodData?.name === method ? styles.methodSelectedWrapper : ""
      } ${styles.methodWrapper}`}
      onClick={() => handleMethod(methodData?.name)}
    >
      <Icon
        img_src={methodData?.icon}
        alt={methodData?.name}
        extraClass={styles.methodIcon}
      />
      <div className={styles.methodHeader}>{methodData?.name}</div>
    </div>
  );
};
