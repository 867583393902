const allIssues = {
  ASRIssue: [
    "No Transcription",
    "False Interpretation",
    "Background Noise",
    "Incomplete Sentence",
    "Language Not Recognized",
    "Other",
  ],
  BotIssue: [
    "Wrong Flow",
    "Server Issue ",
    "Stuck in Loop",
    "End Call Abruptly",
    "No response",
    "Audio Issue",
    "Incorrect Disposition",
    "Other",
  ],
  CustomerIssue: [
    "No Response",
    "Wrong Response",
    "Interrupt",
    "Background Noise",
  ],
  NLUIssue: [
    "Missed Entity",
    "Wrong Entity",
    "No Response",
    "Wrong Intent (Low Confidence)",
    "Wrong Intent (High Confidence)",
    "Correct Intent (Low Confidence)",
    "Other",
  ],
  OtherIssue: ["Data Mismatch"],
};
export default allIssues;
const demo = {
  flow: "post_due_0_7",
  userId: "624a84edace6480cb6f1e9f6",
  language: "Telugu",
  phoneNo: "9010657100",
  sessionId: "9010657100-ameyo-1658755012748",
  conversationId: "62de97ea5f14391ad2cbd9a2",
  type: "Internal",
  accountName: "Sriram",
  issue: {
    BOT: [
      "Wrong Flow",
      "Server Issue ",
      "Stuck in Loop",
      "End Call Abruptly",
      "No response",
      "Audio Issue",
      "Incorrect Disposition",
      "Other",
    ],
    ASR: [
      "No Transcription",
      "False Interpretation",
      "Background Noise",
      "Incomplete Sentence",
      "Language Not Recognized",
      "Other",
    ],
    NLU: [
      "Missed Entity",
      "Wrong Entity",
      "No Response",
      "Wrong Intent (Low Confidence)",
      "Wrong Intent (High Confidence)",
      "Correct Intent (Low Confidence)",
      "Other",
    ],
    Customer: [
      "No Response",
      "Wrong Response",
      "Interrupt",
      "Background Noise",
    ],
    Other: ["Data Mismatch"],
    Feedback: ["test"],
  },
  status: "to do",
};
