import React, { useEffect, useState } from "react";
import CallLoggerLeft from "../../../components/agentDesktop/moduleComponents/callLogger/callLoggerLeft/CallLoggerLeft";
import SummaryAD from "../../../components/agentDesktop/moduleComponents/summaryAD/SummaryAD";
import CallLoggerCenter from "../../../components/agentDesktop/moduleComponents/callLogger/callLoggerCenter/CallLoggerCenter";
import Loader from "../../../components/agentDesktop/generic/loader/Loader";
import "./CallLogger.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import {
  setSummaryUpdateSuccess,
  updateSummary,
  handleCallModuleLeave,
} from "../../../redux/agentDesktop/callLogger/action";
import { noTranscript } from "../../../theme/assets/agentDesktopSvg";
import { useDispatch } from "react-redux";
import NoDatamodel from "../../../components/generic/noDatamodel/NoDatamodel";
import {
  setCommunicationType,
  setOngoingCallChatConversationId,
  setOngoingCallChatSessionId,
} from "../../../redux/agentDesktop/BaseScreenAD/action";

export default function ComponentName() {
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [error, setError] = useState("");
  /* redux hooks */
  const sessionLoading = useSelector(
    (store: RootState) => store.agentDesktopReducer.callLogger.sessionLoading
  );
  const sessionIdCall = useSelector(
    (store: RootState) => store.agentDesktopReducer.callLogger.sessionIds
  );
  const isSaving = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.callLogger.summaryUpdatingLoading
  );
  const updateSuccess = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.callLogger.summaryUpdateSuccess
  );
  const summaryDataEditable = useSelector(
    (store: any) => store.agentDesktopReducer.callLogger.summaryDataEditable
  );
  const selectedConversationId = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.callLogger.selectedConversationId
  );
  const selectedSessionId = useSelector(
    (store: RootState) => store.agentDesktopReducer.callLogger.selectedSessionId
  );
  const sessionIdRedux: any = useSelector<RootState>(
    (state) =>
      state.agentDesktopReducer.callLogger?.sessionIds?.data?.results[0]
        ?.sessionIds
  );
  const summaryId = useSelector(
    (store: RootState) => store.agentDesktopReducer.callLogger.summary?.[0]?.id
  );
  const showCallModule = useSelector(
    (store: RootState) => store.agentDesktopReducer.callLogger.showCallModule
  );
  const communicationType = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.communicationType
  );
  const dispatch = useDispatch();
  // const isActivePage = useSelector(
  //   (store: RootState) => store.loginReducer.isActivePageType
  // );

  /* useEffects ------ */
  useEffect(() => {
    const cleanup = () => {
      dispatch(setOngoingCallChatConversationId(""));
      dispatch(setOngoingCallChatSessionId(""));
      dispatch(handleCallModuleLeave());
      setCommunicationType("");
    };
    return cleanup;
  }, []);

  /* handlers */
  const handleSaveClick = () => {
    if (summaryDataEditable.agentFeedback?.length > 1024) {
      setError(
        "Maximum of 1024 characters is permitted in Notes. Kindly reduce it and try again."
      );
      return;
    }
    dispatch(updateSummary(summaryDataEditable, summaryId));
  };
  const handleEditClick = () => {
    dispatch(setSummaryUpdateSuccess(false));
  };

  return (
    <div className="call-logger__wrapper">
      {showCallModule || communicationType === "call" ? (
        <>
          <div
            className={`call-logger__left ${
              (sessionLoading ||
                (sessionIdCall?.data?.results?.length <= 0 && false)) &&
              "hide-border"
            }`}
          >
            <CallLoggerLeft setShowAudioPlayer={setShowAudioPlayer} />
          </div>
          {sessionLoading ? (
            <div className="loader">
              <Loader />
            </div>
          ) : // eslint-disable-next-line no-constant-condition
          sessionIdCall?.data?.results?.length > 0 || true ? (
            <>
              <div className="call-logger__center">
                {sessionLoading ? (
                  <div className="loader">
                    <Loader />
                  </div>
                ) : (
                  <CallLoggerCenter showAudioPlayer={showAudioPlayer} />
                )}
              </div>
              <div
                className={`call-logger__right ${
                  sessionLoading && "hide-border"
                }`}
              >
                {!sessionLoading && (
                  <SummaryAD
                    isSaving={isSaving}
                    updateSuccess={updateSuccess}
                    isNotEditable={
                      selectedSessionId !== sessionIdRedux?.[0]?.sessionId
                    }
                    error={error}
                    handleEditClick={handleEditClick}
                    handleSaveClick={handleSaveClick}
                    setError={setError}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="no-data-model">
              <NoDatamodel
                srcImg={noTranscript}
                message={"No ongoing call"}
                extraCss={{
                  img: "extraImgClass",
                  message: "extraMessageClass",
                }}
              />
            </div>
          )}
        </>
      ) : (
        <div className="call-logger-no-data">
          <NoDatamodel
            srcImg={noTranscript}
            message={"No ongoing call"}
            extraCss={{
              img: "extraImgClass",
              message: "extraMessageClass",
            }}
          />
        </div>
      )}
    </div>
  );
}
