import React from "react";
import "./QueueInformation.scss";

interface props {
  queueQuantity: number;
  quantityColor: string;
  queueContent: string;
  indicatorMessage: string;
  indicatorColor: string;
}

export default function QueueInformation(props: props) {
  return (
    <div className="queue-information">
      {props.queueQuantity > 0 && (
        <div className="queue-message">
          <span
            className="queue-message__quantity"
            style={{ color: props.quantityColor }}
          >
            {props.queueQuantity}
          </span>
          <span className="queue-message__content">{props.queueContent}</span>
        </div>
      )}
      <div
        className="indicatorMessage"
        style={{ borderColor: props.indicatorColor }}
      >
        <div
          className="indicatorMessage__circle"
          style={{ backgroundColor: props.indicatorColor }}
        ></div>
        <span
          className="indicatorMessage__content"
          style={{ color: props.indicatorColor }}
        >
          {props.indicatorMessage}
        </span>
      </div>
    </div>
  );
}
