import actionTypes from "../exclusionsList.actionTypes";

export const getPhoneNumber = (data: {
  isTesting?: boolean;
  isGlobal?: boolean;
  pageNumber: string;
  pageLimit: string;
  searchParam?: string;
  accountId?: string;
}) => {
  return { type: actionTypes.GET_PHONE_NUMBER_REQUEST, payload: data };
};

export const AddPhoneNumber = (data: {
  phoneNumber: string;
  isGlobal?: boolean;
  accountId?: string;
}) => {
  return { type: actionTypes.ADD_PHONE_NUMBER_REQUEST, payload: data };
};

export const addPhoneNumberCsv = (data: any) => {
  return { type: actionTypes.ADD_PHONE_NUMBER_CSV_REQUEST, payload: data };
};

export const removePhoneNumber = (data: {
  data: string[];
  isGlobal?: boolean;
  accountId?: string;
}) => {
  return { type: actionTypes.REMOVE_PHONE_NUMBER_REQUEST, payload: data };
};

export const setSelectedRowsFromCheckbox = (data: any) => {
  return { type: actionTypes.SET_SELECTED_ROWS_FROM_CHECKBOX, payload: data };
};

export const setExclusionsListType = (type: string) => {
  return { type: actionTypes.SET_EXCLUSIONS_LIST_TYPE, payload: type };
};

export const setClearAddPhoneNumberError = () => {
  return { type: actionTypes.SET_CLEAR_ADD_PHONE_NUMBER_ERROR };
};
