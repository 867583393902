// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bVKQuP7zPJcMLcZhU0Ds{width:600px;border:1px solid #e1e2e9;border-radius:5px}.o1VCSyutASeLYigVw9Fy{width:100%;table-layout:fixed;border-collapse:collapse;margin-left:0 !important;margin-right:0 !important}.o1VCSyutASeLYigVw9Fy tbody{display:block;width:100%;overflow:auto;height:392px}.o1VCSyutASeLYigVw9Fy thead tr{display:table-row;border-bottom:1px solid #e1e2e9}.o1VCSyutASeLYigVw9Fy thead tr img{transform:translateY(4px)}.o1VCSyutASeLYigVw9Fy th,.o1VCSyutASeLYigVw9Fy td{padding:5px;text-align:left;width:200px}.o1VCSyutASeLYigVw9Fy th:nth-child(1),.o1VCSyutASeLYigVw9Fy td:nth-child(1){width:150px}.o1VCSyutASeLYigVw9Fy th:nth-child(2),.o1VCSyutASeLYigVw9Fy td:nth-child(2){width:250px}`, "",{"version":3,"sources":["webpack://./src/components/crm/moduleComponent/maiaCallTable/MaiaCallTable.module.scss"],"names":[],"mappings":"AAGA,sBACE,WAHW,CAIX,wBAAA,CACA,iBAAA,CAEF,sBACE,UAAA,CACA,kBAAA,CACA,wBAAA,CACA,wBAAA,CACA,yBAAA,CACA,4BACE,aAAA,CACA,UAAA,CACA,aAAA,CACA,YAlBU,CAoBZ,+BACE,iBAAA,CACA,+BAAA,CACA,mCACE,yBAAA,CAIJ,kDAEE,WAAA,CACA,eAAA,CACA,WAAA,CACA,4EACE,WAAA,CAEF,4EACE,WAAA","sourcesContent":["$tableHeight: 392px;\n$tableWidth: 600px;\n\n.wrapper {\n  width: $tableWidth;\n  border: 1px solid #e1e2e9;\n  border-radius: 5px;\n}\n.fixed_header {\n  width: 100%;\n  table-layout: fixed;\n  border-collapse: collapse;\n  margin-left: 0 !important;\n  margin-right: 0 !important;\n  tbody {\n    display: block;\n    width: 100%;\n    overflow: auto;\n    height: $tableHeight;\n  }\n  thead tr {\n    display: table-row;\n    border-bottom: 1px solid #e1e2e9;\n    img {\n      transform: translateY(4px);\n    }\n  }\n\n  th,\n  td {\n    padding: 5px;\n    text-align: left;\n    width: 200px;\n    &:nth-child(1) {\n      width: 150px;\n    }\n    &:nth-child(2) {\n      width: 250px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `bVKQuP7zPJcMLcZhU0Ds`,
	"fixed_header": `o1VCSyutASeLYigVw9Fy`
};
export default ___CSS_LOADER_EXPORT___;
