import React, { useEffect, useRef } from "react";
import { useState } from "react";
import TablePravid from "../../../components/generic/tablePravid/TablePravid";
import { tableConstant } from "./tableConstant";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { columnInterface } from "./type";
import NoDatamodel from "../../../components/generic/noDatamodel/NoDatamodel";
import { noTranscriptDataIcon } from "../../../theme/assets/svg";
import {
  getLoggerReportDataAPI,
  sortReportTableData,
  getLoggerReportNextDataAPI,
} from "../../../redux/logger/report/actions";
import {
  setRootPortalScreen,
  setConversationIdSummaryPortal,
} from "../../../redux/baseScreen/baseScreenState/actions";
import portalTypes from "../../rootPortal/portalTypes";
import AudioPlayer from "../../../components/generic/audioPlayer/AudioPlayer";
import NewPagination from "../../../components/generic/pagination/NewPagination";
import SearchInputBox from "../../../components/generic/searchInputBox/SearchInputBox";
import { getDataFromSchema } from "../../../utils";
import { searchIcon } from "../../../theme/assets/svg";
import "./Report.scss";
import { useDebounce } from "../../../hooks";
import { ToastContainer, toast } from "react-toastify";
import { useCurrentPageTab } from "../../../hooks";
import {
  handleAudioClickFunction,
  handleSortClickFunction,
  getLimitFunc,
} from "./report.functions";
import SummaryPortal from "../../../components/moduleComponents/report/summaryPortal/SummaryPortal";
import SelectClient from "../../../components/moduleComponents/transcript/selectClient/SelectClient";
import moment from "moment";
import AudioPlayerWrapper from "../../../components/generic/audioPlayer/AudioPlayerWrapper";
import { previousDay } from "date-fns";
import Loader from "../../../components/generic/loader/Loader";
import { Mixpanel } from "../../../utils/mixpanelSetup";
import { format } from "date-fns";
import { addClientNameAsKey } from "../../../utils/objectModify";
import { ROLES, parentChannels } from "../../../constants";
import { SKELTON_DATA } from "../../../constants";
import CustomBodySkeleton from "../../../components/generic/customSkeleton/CustomBodySkeleton";

export default function Report() {
  /* redux hooks */
  // const accountName = useSelector(
  //   (store: any) =>
  //     store.loginReducer?.userLoginInfo?.userDetail.accountDetails[0].name
  // );
  const {
    schema,
    isActivePageType,
    subChannelTab,
    subModuleTab,
    channelTab,
    currentLoggerPage,
  } = useCurrentPageTab();
  const {
    results: tableData,
    totalPages,
    next,
    previous,
  } = useSelector(
    (store: RootState) => store.loggerReducer?.report?.reportTableDetails
  );
  console.log("bhbh", next?.page);
  const isTableLoading = useSelector(
    (store: RootState) => store.loggerReducer?.report?.isLoadingReportTable
  );
  const calender = useSelector((state: RootState) => {
    return state.filterReducers?.calenderData;
  });
  const selectedFilterOptions = useSelector((state: RootState) => {
    return state.filterReducers?.selectedFilterOptions;
  });
  const tableSortingColumn = useSelector(
    (store: RootState) => store.loggerReducer?.report?.tableSortingColumn
  );
  const currentUseCaseName = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseName
  );
  const defaultuser = useSelector((state: any) => {
    return state.loginReducer?.userLoginInfo?.accountDetails[0]?.name;
  });
  // const user = "Maia Testing";
  const currentUser = useSelector((state: RootState) => {
    return state.filterReducers.selectedFilterOptions.clientName;
  });
  const userRole: any = useSelector((state: any) => {
    return state.loginReducer.userLoginInfo.userDetail.role;
  });
  const refreshPage = useSelector((state: RootState) => {
    return state.filterReducers?.refreshPage;
  });
  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const currentUseCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseId
  );
  // for all records
  const allRecordsNextPrevious = useSelector(
    (store: RootState) =>
      store.loggerReducer?.report?.tableAllNextPreviousRecord
  );
  const isHitNextApi = useSelector(
    (store: RootState) => store.loggerReducer?.report?.isHitNextPageApi
  );
  const accountType = useSelector((state: any) => {
    return state.loginReducer?.userLoginInfo?.accountDetails[0]?.type;
  });
  const currentSelectedTeamId = useSelector(
    (store: RootState) => store.loginReducer?.currentTeam?.id
  );

  const [accountName, setAccountName] = useState(defaultuser);
  Mixpanel.track("Logger | Transcript + Report | Time spent", {
    "user Name": defaultuser,
    Date: format(new Date(), "dd-MM-yyyy"),
    Page: "Report",
  });
  useEffect(() => {
    // console.log("account name changed", currentUser);
    if (
      currentUser &&
      (userRole.toString().toLowerCase() ===
        ROLES.campaignAnalyst.name.toLowerCase() ||
        userRole.toString().toLowerCase() ===
          ROLES.campaignManager.name.toLowerCase())
    ) {
      if (currentUser?.length > 0) {
        setAccountName(currentUser[0].name);
      }
    }
  }, [currentUser, userRole]);
  const dispatch = useDispatch();

  /* useState */
  const [currentAudioState, setCurrentAudioState] = useState<{
    currentTab: string | undefined;
    isPlaying: boolean;
  }>({ currentTab: undefined, isPlaying: false });
  const [currentPageNo, setCurrentPageNo] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string | number>("");
  const [audioUrl, setAudioUrl] = useState<string>("");
  const [reportColumn, setReportColumn] = useState<Array<Record<string, any>>>(
    []
  );
  const [limit, setLimit] = useState(18);
  const [phoneNo, setPhoneNo] = useState("");
  const [tablePageData, setTablePageData] = useState<any>([]);
  const [previousPage, setPreviousPage] = useState<number>();
  const ref = useRef<any>();

  const limitPage = getLimitFunc();

  useEffect(() => {
    setCurrentAudioState({ currentTab: "", isPlaying: false });
    setAudioUrl("");
  }, [calender]);

  useEffect(() => {
    setCurrentAudioState({ currentTab: "", isPlaying: false });
    //   console.log("lala" , tablePageData?.[0]?.information?.recording_file_url)
    // setAudioUrl(tablePageData?.[0]?.information?.recording_file_url)
  }, [tablePageData]);
  /* useEffect */
  useEffect(() => {
    if (
      channelTab?.name &&
      schema &&
      Object.keys(schema)?.length &&
      isActivePageType &&
      (subChannelTab?.name || !parentChannels.includes(channelTab?.name)) &&
      subModuleTab?.name
    ) {
      setReportColumn(
        getDataFromSchema({
          channel: channelTab,
          dataType: "reportColumns",
          schemaData: schema,
          subChannel: subChannelTab,
          tab: subModuleTab,
          screen: isActivePageType,
          subScreen: currentLoggerPage,
        })
      );
    }
  }, [
    schema,
    subChannelTab?.name,
    subModuleTab?.name,
    channelTab?.name,
    accountName,
    isActivePageType,
    currentLoggerPage,
  ]);

  useEffect(() => {
    setCurrentPageNo(1);
  }, [
    calender,
    selectedFilterOptions,
    channelTab?.name,
    accountName,
    schema,
    subChannelTab?.name,
  ]);
  useEffect(() => {
    const currentLimit = getLimitFunc();
    setLimit(currentLimit);
    if (channelTab?.name) {
      const bodyData = {
        dategte: `${moment(calender?.startDate).format("YYYY-MM-DD")}T00:00:00`,
        datelte: `${moment(calender?.endDate).format("YYYY-MM-DD")}T23:59:59`,
        channel: [channelTab.name],
        useCaseId: [currentUseCaseId],
        source: [currentUseCaseName.split(" ")[0]],
        page: currentPageNo,
        limit: Math.max(currentLimit, 10) * 5,
        ...selectedFilterOptions,
        clientName: accountName,
        accountType: accountType,
        role: userRole,
        teamId : currentSelectedTeamId
      };
      if (phoneNo) {
        bodyData["phoneNo"] = phoneNo;
      }
      if (
        channelTab?.name?.toString().toLowerCase() === "whatsapp" ||
        channelTab?.name?.toString().toLowerCase() === "chat"
      ) {
        if (subChannelTab?.name) {
          bodyData["communicationType"] = [subChannelTab.name];
          dispatch(getLoggerReportDataAPI(bodyData, token));
        }
      } else dispatch(getLoggerReportDataAPI(bodyData, token));
    }
  }, [
    // currentPageNo,
    calender,
    selectedFilterOptions,
    channelTab?.name,
    accountName,
    schema,
    subChannelTab?.name,
    refreshPage,
  ]);

  useEffect(() => {
    if (tableData?.length > 0) {
      dispatch(sortReportTableData(tableData || [], tableSortingColumn));
    }
  }, [tableSortingColumn, isTableLoading]);

  useEffect(() => {
    setPreviousPage(previous?.page);
  }, [previous]);

  console.log("allnextPreviousRecord", allRecordsNextPrevious);

  /* handlers */
  const handlePageChange = async (pageNo: number) => {
    /***logic for back page click */
    const currentpageSlot = Math.ceil(currentPageNo / 5);
    const backPageSlot = Math.ceil(pageNo / 5);

    const previousData = allRecordsNextPrevious[backPageSlot - 2];
    console.log(
      "abhishekNayak",
      previousData,
      backPageSlot,
      allRecordsNextPrevious
    );
    if (backPageSlot < currentpageSlot) {
      handlePageChangeApiCall(
        previousData?.next?.page || 1,
        true,
        previousData?.next
      );
    }
    setCurrentPageNo(pageNo);
    const availablePage = Math.ceil(tableData?.length / limitPage);
    if (
      pageNo === availablePage - 1 ||
      pageNo === 1 ||
      availablePage === pageNo
    ) {
      const page = pageNo === 1 ? pageNo : next?.page;
      page
        ? handlePageChangeApiCall(page, false, next)
        : handlePageChangeApiCall(
            previousData?.next?.page || 1,
            true,
            previousData?.next
          );
    }
  };

  const handleSearchPhoneNo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNo(e.target.value);
    setCurrentPageNo(1);
    const bodyData = {
      clientName: accountName,
      dategte: `${moment(calender?.startDate).format("YYYY-MM-DD")}T00:00:00`,
      datelte: `${moment(calender?.endDate).format("YYYY-MM-DD")}T23:59:59`,
      channel: [channelTab.name],
      useCaseId: [currentUseCaseId],
      source: [currentUseCaseName.split(" ")[0]],
      page: currentPageNo,
      accountType: accountType,
      limit: limit,
      ...addClientNameAsKey(selectedFilterOptions, "clientName"),
      phoneNo: e.target.value,
      role: userRole,
    };
    if (
      channelTab?.name?.toString().toLowerCase() === "whatsapp" ||
      channelTab?.name?.toString().toLowerCase() === "chat"
    ) {
      if (subChannelTab?.name) {
        bodyData["communicationType"] = [subChannelTab.name];
        dispatch(getLoggerReportDataAPI(bodyData, token));
      }
    } else dispatch(getLoggerReportDataAPI(bodyData, token));
  };
  const debouncedHandleSearchPhoneNo = useDebounce(handleSearchPhoneNo, 500);

  const handleAudioClick = (
    e: React.MouseEvent<HTMLElement>,
    url: string,
    tab: string
  ) => {
    handleAudioClickFunction(e, url, setAudioUrl, ref);
    setCurrentAudioState((prev) => {
      return { ...prev, currentTab: tab };
    });
    setTimeout(() => {
      let isPlaying = false;
      if (ref.current?.isPlaying) {
        isPlaying = true;
      }
      setCurrentAudioState((prev) => {
        return { ...prev, isPlaying };
      });
    }, 500);
    // setCurrentAudioState((prev) => {
    //   return { ...prev, currentTab: tab };
    // });

    // console.log(tab, "nithin audio");
  };
  // useEffect(() => {
  //   console.log(currentAudioState, "nithin audio");
  // }, [currentAudioState]);

  const handleSummaryClick = (conversationId: string) => {
    dispatch(
      setConversationIdSummaryPortal(
        conversationId ? conversationId : "undefined"
      )
    );
    dispatch(setRootPortalScreen(portalTypes.SUMMARY_PORTAL));
  };
  //making infinite loop
  // useEffect(() => {
  //   // pause the audio if up thing is paused
  //   if (ref.current) {
  //     setCurrentAudioState((prev) => {
  //       return { ...prev, isPlaying: ref.current.isPlaying };
  //     });
  //   }
  // }, [ref.current]);

  function pauseAud() {
    if (ref.current && currentAudioState.isPlaying !== ref.current.isPlaying) {
      setCurrentAudioState((prev) => {
        return { ...prev, isPlaying: ref.current.isPlaying };
      });
    }
  }
  useEffect(() => {
    const interval = setInterval(pauseAud, 300);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const handleSortClick = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    handleSortClickFunction(e, tableSortingColumn, dispatch);
  };
  function onStop() {
    setCurrentAudioState((prev) => {
      return { ...prev, isPlaying: false };
    });
  }
  function onLoad() {
    let isPlaying = false;
    if (ref.current?.isPlaying) {
      isPlaying = true;
    }
    setCurrentAudioState((prev) => {
      return { ...prev, isPlaying: true };
    });
  }
  // delayed loading for glitchy
  const [delayedLoading, setDelayedLoading] = useState<boolean>(false);
  useEffect(() => {
    if (isTableLoading == true) {
      setDelayedLoading(true);
    } else {
      setTimeout(() => {
        setDelayedLoading(false), 500;
      });
    }
  }, [isTableLoading]);

  /** handle page chnage **/
  const handlePageChangeApiCall = (
    pageNo: number,
    slot?: boolean,
    extraData?: any
  ) => {
    const currentLimit = getLimitFunc();
    setLimit(currentLimit);
    if (channelTab?.name) {
      const bodyData = {
        dategte: `${moment(calender?.startDate).format("YYYY-MM-DD")}T00:00:00`,
        datelte: `${moment(calender?.endDate).format("YYYY-MM-DD")}T23:59:59`,
        channel: [channelTab.name],
        useCaseId: [currentUseCaseId],
        page: pageNo,
        limit: Math.max(currentLimit, 10) * 5,
        ...selectedFilterOptions,
        clientName: accountName,
        accountType: accountType,
        role: userRole,
        teamId : currentSelectedTeamId
        // nextDate: extraData?.nextDate,
        // nextLimit: extraData?.nextLimit,
      };
      if (pageNo > 1) {
        bodyData["nextDate"] = extraData?.nextDate;
        bodyData["nextLimit"] = extraData?.nextLimit;
      }
      if (phoneNo) {
        bodyData["phoneNo"] = phoneNo;
      }
      if (
        channelTab?.name?.toString().toLowerCase() === "whatsapp" ||
        channelTab?.name?.toString().toLowerCase() === "chat"
      ) {
        if (subChannelTab?.name) {
          bodyData["communicationType"] = [subChannelTab.name];
          dispatch(getLoggerReportNextDataAPI(bodyData, token, slot || false));
        }
      } else
        dispatch(getLoggerReportNextDataAPI(bodyData, token, slot || false));
    }
  };

  /** logger paggination started**/
  useEffect(() => {
    // const page = (currentPageNo - 1) * 15;
    const page = (currentPageNo - 1) * limitPage;
    const data = tableData?.slice(page, currentPageNo * limitPage);
    setTablePageData(data);
    console.log("nayak bro", limitPage, page, currentPageNo * limitPage);
  }, [tableData, currentPageNo]);

  useEffect(() => {
    if (isHitNextApi) {
      const currentpageSlot = Math.ceil(currentPageNo / 5);
      const nextData = allRecordsNextPrevious[currentpageSlot - 1];
      console.log(
        "nextData b",
        currentpageSlot,
        nextData,
        allRecordsNextPrevious
      );
      nextData?.next &&
        handlePageChangeApiCall(nextData?.next?.page, false, nextData?.next);
    }
  }, [isHitNextApi]);

  console.log("length", tableData?.length);
  return (
    <div className="wrapper__report">
      {/* <div
        className="report_no_data"
        data-show={(!tableData || tableData?.length <= 0) && !delayedLoading}
      >
        <NoDatamodel
          message="No Data"
          srcImg={noTranscriptDataIcon}
          extraCss={{ message: "message" }}
        ></NoDatamodel>
      </div> */}
      <div
        className="search-audio-player"
        // data-show={tableData?.length > 0 || delayedLoading}
      >
        <div className="search-box">
          <SearchInputBox
            searchIcon={searchIcon}
            onChange={debouncedHandleSearchPhoneNo}
            placeholder={"Search"}
            extraClassWrapper={"extraClassSearchWrapper"}
            value={searchValue}
            inputType={"text"}
          />
        </div>
        {/* {userRole.toString().toLowerCase() === ROLES.campaignAnalyst.name.toLowerCase()  &&
        accountName !== "Demo" ? (
          <div className="select-client">
            <SelectClient></SelectClient>
          </div>
        ) : (
          ""
        )} */}
        {channelTab?.name?.toString()?.toLowerCase() === "call" ? (
          <div className="audio-player">
            {audioUrl ? (
              <CustomBodySkeleton isLoading={isTableLoading}>
                <AudioPlayerWrapper
                  url={audioUrl}
                  ref={ref}
                  autoPlay={true}
                  onStop={onStop}
                  onLoad={onLoad}
                />
              </CustomBodySkeleton>
            ) : (
              <CustomBodySkeleton isLoading={isTableLoading}>
                <AudioPlayerWrapper url="" ref={ref} />
              </CustomBodySkeleton>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className="report__table"
        // data-show={tableData?.length > 0 || delayedLoading}
      >
        <TablePravid
          data={isTableLoading ? SKELTON_DATA.tableRowData : tablePageData}
          pageNo={currentPageNo}
          columns={tableConstant({
            limit,
            columnSchema: reportColumn as columnInterface[],
            handleAudioClick,
            handleSummaryClick,
            handleSortClick,
            tableSortingColumn,
            currentAudioState,
          })}
          // fsd
          isLoading={isTableLoading}
          extraClassTableBody={"extraClassTableBody"}
          extraClassTableWrapper={"extraClassTableWrapper"}
          tableEndDisplay={false}
        />
      </div>
      <div
        className={`report__pagination ${
          // !tableData || tableData?.length <= 0 || delayedLoading
          // next?.page || previous?.page ? "" : "hide-report-table"
          Math.ceil(tableData?.length / limitPage) >= 1
            ? ""
            : "hide-report-table"
        }`}
      >
        {/* //next?.page ? next?.page : previous?.page + 1 */}
        <NewPagination
          totalNoOfPage={
            Math.ceil(tableData?.length / limitPage) > 5
              ? Math.ceil(tableData?.length / limitPage)
              : Math.ceil(tableData?.length / limitPage)
          }
          handlePageChange={handlePageChange}
          getPage={currentPageNo - 1}
          hideArrow
        />
      </div>
      {/* <ToastContainer
        position="top-center"
        // type="success"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={true}
      /> */}
    </div>
  );
}
