const random = "_CSP";
const actionTypes = {
  SET_SHOW_INDIVIDUAL_NUDGE_SETTING:
    "SET_SHOW_INDIVIDUAL_NUDGE_SETTING" + random,
  TOGGLE_SHOW_INDIVIDUAL_NUDGE_SETTING:
    "TOGGLE_SHOW_INDIVIDUAL_NUDGE_SETTING" + random,
  SET_EACH_PROP_OF_NUDGE: "SET_EACH_PROP_OF_NUDGE" + random,
  CLEAR_SETTING_OF_NUDGE: "CLEAR_SETTING_OF_NUDGE" + random,
  SET_NUDGE_ID: "SET_NUDGE_ID" + random,
  SET_EACH_PROP_OF_NUDGE_ALL: "SET_EACH_PROP_OF_NUDGE_ALL" + random,
  CLEAR_SETTING_DATA: "CLEAR_SETTING_DATA" + random,
  SET_SHOW_EDIT_ALL_POP: "SET_SHOW_EDIT_ALL_POP" + random,
  SET_SELECTED_ITEM_ON_RIGHT: "SET_SELECTED_ITEM_ON_RIGHT" + random,
  SET_IS_BACK_FROM_SCRIPT_SELECT: "SET_IS_BACK_FROM_SCRIPT_SELECT",
  SET_SELECTED_NUDGE_DATA: "SET_SELECTED_NUDGE_DATA",
  SET_TEMP_SCRIPT_DATA: "SET_TEMP_SCRIPT_DATA",
  CLEAR_SELECTED_NUDGE_DATA: "CLEAR_SELECTED_NUDGE_DATA",
};

export default actionTypes;
