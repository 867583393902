import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatLoggerLeft from "../../../components/agentDesktop/moduleComponents/chatLogger/chatLoggerLeft/ChatLoggerLeft";
import CustomerPreviewCardsColumn from "../../../components/agentDesktop/generic/customerPreviewCards/CustomerPreviewCardsColumn";
import ChatLoggerCenter from "../../../components/agentDesktop/moduleComponents/chatLogger/chatLoggerCenter/ChatLoggerCenter";
import SummaryAD from "../../../components/agentDesktop/moduleComponents/summaryAD/SummaryAD";
import { setChatStatus } from "../../../redux/agentDesktop/callLogger/action";
import {
  setSendChatSignal,
  handleChatFinishAction,
  setOngoingCallChatConversationId,
  setOngoingCallChatSessionId,
  setCommunicationType,
} from "../../../redux/agentDesktop/BaseScreenAD/action";
import ToggleButton from "../../../components/agentDesktop/generic/toggelButton/ToggleButton";
import Loader from "../../../components/agentDesktop/generic/loader/Loader";
import "./ChatLogger.scss";
import { RootState } from "../../../redux";
import {
  setSummaryUpdateSuccess,
  updateSummary,
  handleChatPageLeave,
} from "../../../redux/agentDesktop/chatLogger/action";

export default function ChatLogger() {
  /* redux hooks */
  const chatObjective = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.callLogger.summaryDataEditable?.chatObjective
  );
  const isSavingChat = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.chatLogger.summaryUpdatingLoading
  );
  const updateSuccessChat = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.chatLogger.summaryUpdateSuccess
  );
  const summaryDataEditable = useSelector(
    (store: any) => store.agentDesktopReducer.callLogger.summaryDataEditable
  );
  const selectedConversationId = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.chatLogger.selectedConversationId
  );
  const sessionLoading = useSelector(
    (store: RootState) => store.agentDesktopReducer.chatLogger.sessionLoading
  );
  const communicationType = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.communicationType
  );
  const summaryId = useSelector(
    (store: RootState) => store.agentDesktopReducer.chatLogger.summary?.[0]?.id
  );
  const summaryChat = useSelector(
    (store: RootState) => store.agentDesktopReducer.chatLogger.summary
  );
  const chatStatus = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.chatLogger.summary?.[0]?.information?.chatStatus
  );
  const transcript: any = useSelector<RootState>(
    (state) => state.agentDesktopReducer.chatLogger.transcript
  );
  const agentStatus = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD?.status?.currentState
  );
  const dispatch = useDispatch();

  /* useStates */
  const [error, setError] = useState("");
  const [hideSummary, setHideSummary] = useState(false);
  /* useEffect */
  // useEffect(() => {
  //   dispatch(getCallSummaryAgentDesktop("62be782c934a85778e2e7c57"));
  // }, []);

  /* useEffects --------- */
  useEffect(() => {
    dispatch(setCommunicationType("chat"));
    const cleanup = () => {
      dispatch(setOngoingCallChatConversationId(""));
      dispatch(setOngoingCallChatSessionId(""));
      dispatch(handleChatPageLeave());
      setCommunicationType("");
    };
    return cleanup;
  }, []);

  useEffect(() => {
    setHideSummary(false);
  }, [summaryChat]);
  // useEffect(() => {
  //   if (summaryId && chatObjective) {

  //   }
  // }, [chatObjective]);

  /* handlers */
  const handleStatusToggle = (status: string) => {
    status =
      status === "Achieved" ? "Yes" : status === "Not Achieved" ? "No" : status;
    dispatch(updateSummary(summaryDataEditable, summaryId, status));
    dispatch(setChatStatus(status));
    dispatch(setSendChatSignal("close"));
    setHideSummary(true);
    // eslint-disable-next-line no-constant-condition
  };

  const handleSaveClick = () => {
    if (summaryDataEditable.agentFeedback?.length > 1024) {
      setError(
        "Maximum of 1024 characters is permitted in Notes. Kindly reduce it and try again."
      );
      return;
    }
    dispatch(updateSummary(summaryDataEditable, summaryId));
  };

  const handleEditClick = () => {
    dispatch(setSummaryUpdateSuccess(false));
  };

  return (
    <div className="chat-logger__wrapper__agent">
      <div className={`chat-logger_left `}>
        <ChatLoggerLeft isSessionIdsLoading={sessionLoading} />
      </div>
      <div className="chat-logger__center">
        <ChatLoggerCenter hideChatBox={hideSummary} />
      </div>
      <div
        className={`chat-logger__right ${
          chatStatus === "closed" || agentStatus !== "Available"
            ? "disablePointerEventUniversalLowOpacity"
            : ""
        } ${
          transcript.length <= 0 || hideSummary
            ? "disablePointerEventUniversalLowOpacity"
            : ""
        }`}
      >
        {
          <div
            className={
              Object.keys(summaryChat).length <= 0
                ? "disablePointerEventWithoutOpacity"
                : ""
            }
          >
            <SummaryAD
              isSaving={isSavingChat}
              updateSuccess={updateSuccessChat}
              error={error}
              handleEditClick={handleEditClick}
              handleSaveClick={handleSaveClick}
              setError={setError}
            />
            {/* <div
              className={`${
                communicationType === "call" || communicationType === "chat"
                  ? "disablePointerEventUniversaljp"
                  : ""
              }`}
            > */}
            <div className="chat-objective__title">Chat Objective</div>
            <ToggleButton
              options={["Not Achieved", "Achieved"]}
              getSelectedData={handleStatusToggle}
              outSideSelected={
                chatObjective === "Yes"
                  ? "Achieved"
                  : chatObjective === "No"
                  ? "Not Achieved"
                  : chatObjective
              }
              extraClass={"extraClassToggle"}
              updateSuccess={updateSuccessChat}
              alwaysTakeOutSideValue={true}
            />
          </div>
        }
      </div>
    </div>
  );
}
