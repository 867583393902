import { getApiPayload } from "../actions/completedCampaign.action";
import actionTypes from "../actionTypes/completedCampaign.actions";
import { config, CAMPAIGN_URL } from "../../../services/ApiRoutes";
import { put } from "redux-saga/effects";
/**
 * get the data for minimum of 3 years
 * @param action
 */
export function* getAllCampaignCompletedApiWorker(action: {
  type: string;
  payload: getApiPayload;
}): any {
  try {
    let current_year = new Date().getFullYear();
    const data: any = action.payload;
    let result: Date[] = [];
    // for (let i = 0; i < 3; i++) {
    //   try {
    //     data.year = String(current_year);
    //     const result_temp = yield config.POST(
    //       CAMPAIGN_URL.GET_ALL_COMPLETED_CAMPAIGN,
    //       data
    //     );
    //     result = result.concat(
    //       result_temp.data.data.map((e: any) => new Date(e.date))
    //     );
    //   } catch (err) {
    //     //
    //   }
    //   current_year = current_year - 1;
    // }
    const promiseList: Promise<any>[] = [];
    for (let i = 0; i < 3; i++) {
      data.year = String(current_year);
      const response = config.POST(
        CAMPAIGN_URL.GET_ALL_COMPLETED_CAMPAIGN,
        data
      );
      promiseList.push(response);

      current_year = current_year - 1;
    }
    const resultArr: any = yield Promise.allSettled(promiseList);
    result = resultArr.flatMap((e: PromiseSettledResult<any>) => {
      if (e.status === "fulfilled" && typeof e.value?.data?.data !== "string") {
        return e.value.data.data.map((e: any) => new Date(e.date));
      } else {
        return [];
      }
    });
    // console.log({ result }, "jerru");

    // console.log({ result }, "calender result");
    yield put({
      type: actionTypes.GET_COMPLETED_CAMPAIGNS_API_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_COMPLETED_CAMPAIGNS_API_FAILURE,
      payload: error,
    });
    // console.log(error, "calender");
    console?.error(error);
  }
}
