// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gFeYgVxwf6zG4mpTcAus .moPTVj01gBx6uNTBYrk5{max-height:58vh;min-height:58vh;width:100%;overflow-y:overlay}.gFeYgVxwf6zG4mpTcAus .i15CwRL3erin_4pjgkGe{width:65% !important;height:90% !important;background-color:#fff;border-radius:7px}.gFeYgVxwf6zG4mpTcAus .coOd3pPKfHXKN1Y6tJmp{width:100%;height:90%;background-color:#fff;padding:20px;border:none}.gFeYgVxwf6zG4mpTcAus .Kio_laDLtMyQ3knRsNfZ{display:flex;flex-direction:row;height:25px !important;margin-right:20px;margin-top:1.5em;cursor:pointer;display:flex;align-items:center;justify-content:flex-end}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/paymentModuleComponents/invoiceTab/invoiceTable/InvoiceTable.module.scss"],"names":[],"mappings":"AACE,4CACE,eAAA,CACA,eAAA,CACA,UAAA,CACA,kBAAA,CAGF,4CACE,oBAAA,CACA,qBAAA,CACA,qBAAA,CACA,iBAAA,CAGF,4CACE,UAAA,CACA,UAAA,CACA,qBAAA,CACA,YAAA,CACA,WAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,gBAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA","sourcesContent":[".invoiceTableWrapper {\n  .invoiceRowWrapper {\n    max-height: 58vh;\n    min-height: 58vh;\n    width: 100%;\n    overflow-y: overlay;\n  }\n\n  .ModalPDFContent {\n    width: 65% !important;\n    height: 90% !important;\n    background-color: white;\n    border-radius: 7px;\n  }\n\n  .PdfViewStyling {\n    width: 100%;\n    height: 90%;\n    background-color: white;\n    padding: 20px;\n    border: none;\n  }\n\n  .closeIcon {\n    display: flex;\n    flex-direction: row;\n    height: 25px !important;\n    margin-right: 20px;\n    margin-top: 1.5em;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invoiceTableWrapper": `gFeYgVxwf6zG4mpTcAus`,
	"invoiceRowWrapper": `moPTVj01gBx6uNTBYrk5`,
	"ModalPDFContent": `i15CwRL3erin_4pjgkGe`,
	"PdfViewStyling": `coOd3pPKfHXKN1Y6tJmp`,
	"closeIcon": `Kio_laDLtMyQ3knRsNfZ`
};
export default ___CSS_LOADER_EXPORT___;
