const REPORT_ISSUES_ACTION={
    SET_ALL_REPORT_ISSUES:"SET_ALL_REPORT_ISSUES",
    SET_BOT_REPORT_ISSUES:"SET_BOT_REPORT_ISSUES",
    SET_ASR_REPORT_ISSUES:"SET_ASR_REPORT_ISSUES",
    SET_NLU_REPORT_ISSUES:"SET_NLU_REPORT_ISSUES",
    SET_CUSTOMER_REPORT_ISSUES:"SET_CUSTOMER_REPORT_ISSUES",
    SET_OTHERS_REPORT_ISSUES:"SET_OTHERS_REPORT_ISSUES",
    SET_CREATE_REPORT_ISSUES:"SET_CREATE_REPORT_ISSUES",
    SET_FETCH_REPORT_ISSUES:"SET_FETCH_REPORT_ISSUES",
    SET_UPDATE_REPORT_ISSUES:"SET_UPDATE_REPORT_ISSUES",
    SET_MESSAGE_REPORT_ISSUES:"SET_MESSAGE_REPORT_ISSUES",
    SET_REPORT_ISSUE_UPDATE_ID: "SET_REPORT_ISSUE_UPDATE_ID"
}
export default REPORT_ISSUES_ACTION;