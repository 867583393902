// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yv52Wqh4thD8DG3aIixX{height:80%}.yv52Wqh4thD8DG3aIixX .eiGvGRHdI1yZXJ7k_1qz{display:flex;height:100%}.yv52Wqh4thD8DG3aIixX .uhbyE5FAnQzxySyUZE_2{width:100%;margin-left:1%;margin-right:1%}.yv52Wqh4thD8DG3aIixX .DMYlsfvKRm2R6PbUlgww{width:30%;margin-right:1%;height:100%}.yv52Wqh4thD8DG3aIixX .OsHjp8g8ajQIg2VkOkcg{display:flex;justify-content:flex-start;align-items:center;width:100%;padding:0px 20px 0px 25px;margin-bottom:20px !important}`, "",{"version":3,"sources":["webpack://./src/screens/campaign/mailSchedulerSettingPage/MailsettingPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,4CACE,YAAA,CACA,WAAA,CAEF,4CACE,UAAA,CACA,cAAA,CACA,eAAA,CAEF,4CACE,SAAA,CAEA,eAAA,CACA,WAAA,CAEF,4CACE,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,UAAA,CACA,yBAAA,CACA,6BAAA","sourcesContent":[".mailsettingPageWrapper {\n  height: 80%;\n  .schedulerSettingPageWp {\n    display: flex;\n    height: 100%;\n  }\n  .scheduleSettingwp {\n    width: 100%;\n    margin-left: 1%;\n    margin-right: 1%;\n  }\n  .previewWp {\n    width: 30%;\n    // height: 65vh;\n    margin-right: 1%;\n    height: 100%;\n  }\n  .breadcrum-wrapper {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    width: 100%;\n    padding: 0px 20px 0px 25px;\n    margin-bottom: 20px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mailsettingPageWrapper": `yv52Wqh4thD8DG3aIixX`,
	"schedulerSettingPageWp": `eiGvGRHdI1yZXJ7k_1qz`,
	"scheduleSettingwp": `uhbyE5FAnQzxySyUZE_2`,
	"previewWp": `DMYlsfvKRm2R6PbUlgww`,
	"breadcrum-wrapper": `OsHjp8g8ajQIg2VkOkcg`
};
export default ___CSS_LOADER_EXPORT___;
