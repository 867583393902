import React, { useState } from "react";
import Button from "../../../generic/button/Button";
import Modal from "../../../generic/modal/Modal";
import styles from "./AgreeToTermsPopUp.module.scss";

interface termsModalProps {
  show?: boolean;
  close?: CallableFunction;
  onClick?: CallableFunction;
  headingContent?: any;
  termstoAgreeContent?: any;
}

export const AgreeToTerms = ({
  show,
  close,
  onClick,
  headingContent,
  termstoAgreeContent,
}: termsModalProps) => {
  const [bottom, setBottom] = useState(false);

  const handleScroll = (e: any) => {
    const bottom =
      (e.target.scrollHeight - e.target.scrollTop) / 2 <= e.target.clientHeight;

    // console.log(
    //   "hhh",
    //   e.target.scrollHeight - e.target.scrollTop,
    //   e.target.clientHeight
    // );
    setBottom(bottom);
  };

  return (
    <Modal shown={show} extraClassStyleModalContent={styles.termsModalStyling}>
      <div className={styles.contentDivStyling} data-testid="terms-modal">
        <div className={styles.headingStyling}>{headingContent}</div>
        <div className={styles.textStyling} onScroll={handleScroll}>
          {termstoAgreeContent}
        </div>
        <div className={styles.btnDivStyling}>
          <Button
            text="Accept &amp; Continue"
            extraClass={`${styles.buttonStyling} ${
              bottom && styles.termsButtonStyling
            }`}
            // extraClass={`${styles.buttonStyling} ${styles.termsButtonStyling}`}
            onClick={() => onClick && onClick()}
            disabled={bottom ? false : true}
            id="auto_agree_to_terms_button"
            dataTest="auto_agree_to_terms_button"
          />
        </div>
      </div>
    </Modal>
  );
};
