import { call, put } from "redux-saga/effects";
import {
  config,
  INVITE_USER_API,
  TEAMS_PAGE_API,
} from "../../../../services/ApiRoutes";
import actionTypes from "../inviteUser.actionTypes";
import teamActionTypes from "../../teamsPage/teamsPage.actionTypes";
import { toast } from "react-toastify";

export function* sendInviteUserAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${INVITE_USER_API.SEND_INVITE_USER}`,
        action.payload
      );
      const res = yield call(
        config.GET_WITH_PARAMS,
        `${TEAMS_PAGE_API.GET_ALL_TEAMS}`,
        { userId: action.payload?.[0].userId }
      );
      // console.log("gggg", action.payload?.[0].userId);
      const ress = yield call(
        config.GET_WITH_PARAMS,
        `${TEAMS_PAGE_API.GET_TEAM_MEMBER_DATA}`,
        { teamId: action.payload?.[0].teamId }
      );
      // console.log("gggg", action.payload?.[0].userId);

      yield put({
        type: actionTypes.SEND_INVITE_USER_SUCCESS,
        payload: { msg: result.data.data, data: action.payload },
      });
      yield put({
        type: teamActionTypes.GET_ALL_TEAMS_DATA_SUCCESS,
        payload: res.data.data,
      });

      const filtered = res.data.data.filter(
        (x: any) => x.id === action.payload?.[0].teamId
      );

      // console.log(filtered[0], "ppp");

      yield put({
        type: teamActionTypes.SELECTED_TEAM_DETAILS,
        payload: filtered[0],
      });
      yield put({
        type: teamActionTypes.GET_TEAM_MEMBER_DATA_SUCCESS,
        payload: ress.data.data,
      });
      if (result?.data?.data?.errors?.length > 0) {
        toast.error(result?.data?.data?.errors?.[0]);
      } else {
        toast.success(result?.data?.data?.msg);
      }
    }
  } catch (error) {
    yield put({
      type: actionTypes.SEND_INVITE_USER_FAILURE,
      payload: error,
    });
    toast.error("Fail to invite users");
    console.error(error);
  }
}
