import React from "react";
import styles from "./TeamCard.module.scss";

interface TeamCardProps {
  teamData: any;
  teamIndex?: number;
  onClickTeam?: any;
  isSelected?: string;
}
export const TeamCard = ({
  teamData,
  teamIndex,
  onClickTeam,
  isSelected,
}: TeamCardProps) => {
  return (
    <div
      className={`${styles.teamCardWrapper} ${
        isSelected === teamData?.id ? styles.teamCardSelectedDiv : ""
      }`}
      id={`auto_team_${teamIndex}`}
      onClick={onClickTeam}
      data-testid="team-card"
    >
      <div
        className={`${styles.teamInfoNameStyling} ${
          isSelected === teamData?.id ? styles.teamInfoNameSelectedStyling : ""
        }`}
        id={`auto_${teamIndex}_name`}
        data-testid="team-name"
      >
        {teamData?.teamName}
      </div>
      <div
        className={styles.teamInfoMemberStyling}
        id={`auto_${teamIndex}_members`}
        data-testid="member-count"
      >
        {`${teamData?.teamMembers?.length} ${
          teamData?.teamMembers?.length === 1 ? "member" : "members"
        }`}
      </div>
    </div>
  );
};
