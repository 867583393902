import React, { useEffect, useState } from "react";
import {
  PasswordConditions,
  ConfirmPasswordConditions,
  NewPasswordCondition,
} from "./PasswordConditions";
import Button from "../button/Button";
import {
  closeTagIcon,
  hideIcon,
  showIcon,
} from "../../../theme/assets/profileSectionSvg";
import styles from "./PasswordConditions.module.scss";
import _ from "lodash";
let timer: any;
export const PasswordValidation = (props: any) => {
  const [passwordCheck, setPasswordCheck] = useState({
    currentPwHidden: true,
    pwHidden: true,
    confirmPwHidden: true,
    newPassword: "",
    currentPassword: "",
    confirmPassword: "",
    nameMatch: false,
    charMinLength: false,
    uppercase: false,
    numChar: false,
    lowercase:false,
    // charMaxLength: false,
    passwordMatch: false,
    confirmPasswordMatch: false,
  });

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  // const [isNewPasswordFocused, setIsNewPasswordFocused] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  // const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] =
  //   useState(false);

  const handleChangeCurrentPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordCheck((prev) => ({
      ...prev,
      currentPassword: event.target.value,
    }));
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordCheck((prev) => {
      return { ...prev, newPassword: event.target.value };
    });
  };

  const handleChangeConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordCheck((prev) => {
      return { ...prev, confirmPassword: event.target.value };
    });
  };

  const toggleShowForCurrentPassword = () => {
    setPasswordCheck((prev) => {
      return { ...prev, currentPwHidden: !passwordCheck.currentPwHidden };
    });
  };

  const toggleShowForPassword = () => {
    setPasswordCheck((prev) => {
      return { ...prev, pwHidden: !passwordCheck.pwHidden };
    });
  };

  const toggleShowForConfirmPassword = () => {
    setPasswordCheck((prev) => {
      return { ...prev, confirmPwHidden: !passwordCheck.confirmPwHidden };
    });
  };

  const handleCancelPasswords = () => {
    setPasswordCheck({
      currentPwHidden: true,
      pwHidden: true,
      confirmPwHidden: true,
      newPassword: "",
      currentPassword: "",
      confirmPassword: "",
      nameMatch: false,
      charMinLength: false,
      uppercase: false,
      lowercase:false,
      numChar: false,
      // charMaxLength: false,
      passwordMatch: false,
      confirmPasswordMatch: false,
    });
  };

  const passwordValidate = () => {
    const currentPassword = passwordCheck.currentPassword;
    const newPassword = passwordCheck.newPassword;
    const userName = props?.userName?.toLowerCase();
    const userNameWOSpace = props?.userName?.toLowerCase().replace(/\s/g, "");
    const regexUppercase = /[A-Z]/;
    const regexLowercase = /[a-z]/;
    // const regexNumChar = /[\d`~!@#$%&*()+=|;:'",.<>?/^\\]/;
    const regexNumChar = /^(?=.*\d)(?=.*[\W_]).*$/

    if (newPassword !== "") {
      newPassword.toLowerCase()?.includes(userName) ||
      newPassword.toLowerCase()?.includes(userNameWOSpace)
        ? setPasswordCheck((prev) => {
            return { ...prev, nameMatch: false };
          })
        : setPasswordCheck((prev) => {
            return { ...prev, nameMatch: true };
          });
    } else if (newPassword == "") {
      setPasswordCheck((prev) => {
        return { ...prev, nameMatch: false };
      });
    }
    newPassword.length >= 8
      ? setPasswordCheck((prev) => {
          return { ...prev, charMinLength: true };
        })
      : setPasswordCheck((prev) => {
          return { ...prev, charMinLength: false };
        });

    regexUppercase.test(newPassword)
      ? setPasswordCheck((prev) => {
          return { ...prev, uppercase: true };
        })
      : setPasswordCheck((prev) => {
          return { ...prev, uppercase: false };
        });

    regexLowercase.test(newPassword)
        ? setPasswordCheck((prev) => {
            return { ...prev, lowercase: true };
          })
        : setPasswordCheck((prev) => {
            return { ...prev, lowercase: false };
          });

    regexNumChar.test(newPassword)
      ? setPasswordCheck((prev) => {
          return { ...prev, numChar: true };
        })
      : setPasswordCheck((prev) => {
          return { ...prev, numChar: false };
        });

    // newPassword.length <= 25
    //   ? setPasswordCheck((prev) => {
    //       return { ...prev, charMaxLength: true };
    //     })
    //   : setPasswordCheck((prev) => {
    //       return { ...prev, charMaxLength: false };
    //     });

    newPassword === currentPassword
      ? setPasswordCheck((prev) => {
          return { ...prev, passwordMatch: true };
        })
      : setPasswordCheck((prev) => {
          return { ...prev, passwordMatch: false };
        });
  };

  const confirmPasswordValidate = () => {
    const confirmPassword = passwordCheck.confirmPassword;
    const newPassword = passwordCheck.newPassword;

    confirmPassword === newPassword
      ? setPasswordCheck((prev) => {
          return { ...prev, confirmPasswordMatch: true };
        })
      : setPasswordCheck((prev) => {
          return { ...prev, confirmPasswordMatch: false };
        });
  };

  const newPasswordValidate = () => {
    const currentPassword = passwordCheck.currentPassword;
    const newPassword = passwordCheck.newPassword;
    newPassword === currentPassword
      ? setPasswordCheck((prev) => {
          return { ...prev, passwordMatch: true };
        })
      : setPasswordCheck((prev) => {
          return { ...prev, passwordMatch: false };
        });
  };

  useEffect(() => {
    if (
      passwordCheck?.currentPassword === "" &&
      passwordCheck?.newPassword === "" &&
      passwordCheck?.confirmPassword === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    passwordCheck.newPassword,
    passwordCheck.confirmPassword,
    passwordCheck.currentPassword,
  ]);

  useEffect(() => {
    if (
      passwordCheck?.currentPassword === "" &&
      passwordCheck?.newPassword === "" &&
      passwordCheck?.confirmPassword === ""
    ) {
      setIsPasswordValid(false);
    } else if (
      passwordCheck.nameMatch &&
      passwordCheck.charMinLength &&
      passwordCheck.uppercase &&
      passwordCheck.lowercase &&
      passwordCheck.numChar &&
      passwordCheck.confirmPasswordMatch &&
      !passwordCheck.passwordMatch &&
      passwordCheck?.currentPassword !== "" &&
      passwordCheck?.newPassword !== "" &&
      passwordCheck?.confirmPassword !== ""
    ) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  }, [passwordCheck]);

  useEffect(() => {
    if (passwordCheck) {
      passwordValidate();
      confirmPasswordValidate();
      newPasswordValidate();
    }
  }, [
    passwordCheck.newPassword,
    passwordCheck.confirmPassword,
    passwordCheck.currentPassword,
  ]);

  // console.log(
  //   "pass",
  //   passwordCheck.newPassword,
  //   passwordCheck.confirmPassword,
  //   passwordCheck.currentPassword,
  //   passwordCheck.passwordMatch,
  //   isPasswordValid
  // );

  // console.log(
  //   "passs",
  //   passwordCheck.confirmPassword !== "" && !passwordCheck.confirmPasswordMatch
  // );
  return (
    <div className={styles.passwordWrapper}>
      <div>
        <div className={styles.passwordInfoWrapper}>
          <label className={styles.passwordLabelStyling}>
            Current Password
          </label>
          <div className={styles.inputDivStyling}>
            <input
              className={styles.inputFieldStyling}
              onChange={handleChangeCurrentPassword}
              //placeholder="Enter Your Current Password"
              id="currentPassword"
              data-testid="current-password"
              value={passwordCheck.currentPassword}
              type={passwordCheck.currentPwHidden ? "password" : "text"}
              maxLength={50}
              readOnly={isReadOnly}
              onFocus={() => {
                setIsReadOnly(false);
              }}
              onBlur={() => {
                setIsReadOnly(true);
              }}
            />
            {passwordCheck.currentPassword == "" ? null : (
              <img
                src={passwordCheck.currentPwHidden ? hideIcon : showIcon}
                className={styles.toggleShowButton}
                onClick={toggleShowForCurrentPassword}
              />
            )}
          </div>
        </div>
        <div className={styles.passwordInfoWrapper}>
          <label className={styles.passwordLabelStyling}>New Password</label>
          <div
            className={`${styles.inputDivStyling}
            ${
              passwordCheck.passwordMatch && passwordCheck.newPassword !== ""
                ? styles.notValid
                : null
            }
            `}
          >
            <input
              className={`${styles.inputFieldStyling} input`}
              onChange={handleChangePassword}
              id="newPassword"
              data-testid="new-password"
              value={passwordCheck.newPassword}
              type={passwordCheck.pwHidden ? "password" : "text"}
              maxLength={50}
              readOnly={isReadOnly}
              onFocus={() => {
                // setIsNewPasswordFocused(true);
                setIsReadOnly(false);
              }}
              onBlur={() => {
                // setIsNewPasswordFocused(false);
                setIsReadOnly(true);
              }}
              //onKeyUp={_.debounce(handleKeyUp, 1000)}
              // onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
              //   clearTimeout(timer);
              //   timer = setTimeout(() => {
              //     // event.preventDefault();
              //     const time = event.target as HTMLElement;
              //     time.blur();
              //   }, 1000);
              // }}
            />
            {passwordCheck.newPassword == "" ? null : (
              <img
                src={passwordCheck.pwHidden ? hideIcon : showIcon}
                className={styles.toggleShowButton}
                onClick={toggleShowForPassword}
                data-testid="img-show-hide"
              />
            )}
          </div>
          {passwordCheck.newPassword !== "" && !passwordCheck.passwordMatch ? (
            // <PasswordConditions
            //   nameMatch={passwordCheck.nameMatch}
            //   charMinLength={passwordCheck.charMinLength}
            //   uppercase={passwordCheck.uppercase}
            //   numChar={passwordCheck.numChar}
            //   charMaxLength={passwordCheck.charMaxLength}
            //   passwordMatch={passwordCheck.passwordMatch}
            // />
            <PasswordConditions
            nameMatch={passwordCheck.nameMatch}
            charMinLength={passwordCheck.charMinLength}
            uppercase={passwordCheck.uppercase}
            numChar={passwordCheck.numChar}
            lowercase={passwordCheck.lowercase}
            passwordMatch={passwordCheck.passwordMatch}
            />
          ) : null}
          {passwordCheck.newPassword !== "" &&
          passwordCheck.currentPassword !== "" &&
          passwordCheck.passwordMatch ? (
            //&&
            // passwordCheck.confirmPassword === "" &&
            // !isNewPasswordFocused
            <NewPasswordCondition passwordMatch={passwordCheck.passwordMatch} />
          ) : null}
        </div>
        <div className={styles.passwordInfoWrapper}>
          <label className={styles.passwordLabelStyling}>
            Re-enter new Password
          </label>
          <div
            className={`${styles.inputDivStyling}   ${
              passwordCheck.confirmPassword !== "" &&
              !passwordCheck.confirmPasswordMatch
                ? styles.notValid
                : null
            }`}
          >
            <input
              className={`${styles.inputFieldStyling} input`}
              onChange={handleChangeConfirmPassword}
              id="confirmPassword"
              data-testid="confirm-password"
              value={passwordCheck.confirmPassword}
              type={passwordCheck.confirmPwHidden ? "password" : "text"}
              maxLength={50}
              readOnly={isReadOnly}
              onFocus={() => {
                // setIsConfirmPasswordFocused(true);
                // confirmPasswordValidate();
                setIsReadOnly(false);
              }}
              // onKeyUp={_.debounce(handleKeyUp, 1000)}
              // onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
              //   clearTimeout(timer);
              //   timer = setTimeout(() => {
              //     // event.preventDefault();
              //     const time = event.target as HTMLElement;
              //     time.blur();
              //   }, 1000);
              // }}
              onBlur={() => {
                // setIsConfirmPasswordFocused(false);
                setIsReadOnly(true);
              }}
            />
            {passwordCheck.confirmPassword == "" ? null : (
              <img
                src={passwordCheck.confirmPwHidden ? hideIcon : showIcon}
                className={styles.toggleShowButton}
                onClick={toggleShowForConfirmPassword}
                data-testid="img-icon"
              />
            )}
          </div>
          {/* {isConfirmPasswordFocused ? ( */}
          {passwordCheck.confirmPassword !== "" ? (
            <ConfirmPasswordConditions
              confirmPasswordMatch={passwordCheck.confirmPasswordMatch}
            />
          ) : null}
        </div>
      </div>

      <div className={styles.passwordComponentButtons}>
        <Button
          text="Cancel"
          extraClass={`${styles.buttonStyling} ${styles.cancelButtonStyle}`}
          onClick={handleCancelPasswords}
          dataTest="cancel_button"
          disabled={isDisabled}
        />

        {isPasswordValid ? (
          <Button
            text="Confirm"
            extraClass={`${styles.buttonStyling} ${styles.saveButtonActiveStyle}`}
            onClick={() => {
              props.onSave(passwordCheck);
              handleCancelPasswords();
            }}
            disabled={props.isLoading ? true : false}
          />
        ) : (
          <Button
            text="Confirm"
            extraClass={`${styles.buttonStyling} ${styles.saveButtonInActiveStyle}`}
            disabled={true}
          />
        )}
      </div>
    </div>
  );
};
