import { takeLatest } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import { getMyPlanDataAPIWorker } from "./myPlan.saga";

export default function* myPlanDetailsWatcher(): any {
  yield takeLatest(
    actionTypes.MY_PLAN_DATA_API_REQUEST,
    getMyPlanDataAPIWorker
  );
}
