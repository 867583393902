import React from "react";
import Button from "../../../generic/button/Button";
import OtpInput from "react18-input-otp";
import styles from "./OTPComponent.module.scss";

interface OTPProps {
  otpValue?: string;
  otpOnChange?: CallableFunction;
  otpOnSubmit?: CallableFunction;
  otpOnResend?: CallableFunction;
  isOTPInValid?: boolean;
  phoneNo?: any;
  otpText?: string;
  otpTextHeading?: string;
  disableConfirmBtn?: boolean;
}
export const OTPComponent = ({
  otpValue,
  otpOnChange,
  otpOnSubmit,
  isOTPInValid,
  otpOnResend,
  otpText,
  phoneNo,
  disableConfirmBtn,
  otpTextHeading,
}: OTPProps) => {
  // console.log("kk" , disableConfirmBtn)
  return (
    <div className={styles.OTPContentStyling}>
      {otpTextHeading && (
        <div className={styles.contentTextStyling}>{otpTextHeading}</div>
      )}
      <div className={styles.contentTextStyling}>{otpText}</div>

      <OtpInput
        value={otpValue}
        onChange={otpOnChange}
        numInputs={4}
        onSubmit={otpOnSubmit}
        containerStyle={styles.conatinerStyling}
        inputStyle={`${styles.inputStyling} ${
          isOTPInValid ? styles.otpErrorStyling : ""
        }`}
        isInputNum={true}
      />
      <div className={styles.otpErrorTextStyling}>
        {isOTPInValid ? "Please provide the correct OTP" : ""}
      </div>

      <div className={styles.contentBtnDivStyling} data-testid="otp-modal">
        <Button
          text="Resend OTP"
          // extraClass={`${styles.contentButtonStyling} ${styles.otpButtonStyle}`}
          extraClass={`${styles.contentButtonStyling} ${
            // otpValue?.length == 0
            //   ? styles.otpInActiveButtonStyle:
            styles.otpButtonStyle
          }`}
          id={"auto_resend_otp_btn"}
          onClick={otpOnResend}
          disabled={disableConfirmBtn ? true : false}
        />
        <Button
          text="Confirm"
          extraClass={`${styles.contentButtonStyling} ${
            otpValue && otpValue?.length === 4 && !disableConfirmBtn
              ? styles.confirmActiveButtonStyle
              : styles.confirmInActiveButtonStyle
          }`}
          id={"auto_confirm_otp_btn"}
          onClick={otpOnSubmit}
          disabled={
            otpValue && otpValue?.length === 4 && !disableConfirmBtn
              ? false
              : true
          }
        />
      </div>
    </div>
  );
};
