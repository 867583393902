import { takeLatest } from "redux-saga/effects";
import actionTypes from "../strategy.actionTypes";
import {
  getStrategiesDataAPIWorker,
  getUserPersonaOptionsAPIWorker,
  getStrategyOptionsAPIWorker,
  getUserPersonasDataAPIWorker,
  deleteStrategyDataAPIWorker,
  updateStrategyDataAPIWorker,
  createStrategyDataAPIWorker,
  getWorkspaceFlowsDataAPIWorker,
  addUserPersonaDataAPIWorker,
  addWorkspaceFlowDataAPIWorker,
  setWorkspaceFlowDataAPIWorker,
} from "./strategy.saga";

export default function* campaignV2DetailsWatcher(): any {
  yield takeLatest(
    actionTypes.GET_STRATEGIES_DATA_API_REQUEST,
    getStrategiesDataAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_USER_PERSONA_OPTIONS_API_REQUEST,
    getUserPersonaOptionsAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_STRATEGY_OPTIONS_API_REQUEST,
    getStrategyOptionsAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_USER_PERSONA_DATA_API_REQUEST,
    getUserPersonasDataAPIWorker
  );
  yield takeLatest(
    actionTypes.CREATE_STRATEGY_DATA_API_REQUEST,
    createStrategyDataAPIWorker
  );
  yield takeLatest(
    actionTypes.UPDATE_STRATEGY_DATA_API_REQUEST,
    updateStrategyDataAPIWorker
  );
  yield takeLatest(
    actionTypes.DELETE_STRATEGY_DATA_API_REQUEST,
    deleteStrategyDataAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_WORK_FLOWS_DATA_API_REQUEST,
    getWorkspaceFlowsDataAPIWorker
  );
  yield takeLatest(
    actionTypes.ADD_USER_PERSONA_DATA_API_REQUEST,
    addUserPersonaDataAPIWorker
  );
  yield takeLatest(
    actionTypes.ADD_WORK_FLOW_DATA_API_REQUEST,
    addWorkspaceFlowDataAPIWorker
  );
  yield takeLatest(
    actionTypes.SET_WORK_FLOW_DATA_API_REQUEST,
    setWorkspaceFlowDataAPIWorker
  );
}
