import { takeEvery, takeLatest } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import {
  getAnalyticsDataAPIWorker,
  getAnalyticsChartDownloadCsvAPIWorker,
  getAnalyticsCardDataAPIWorker
} from "./analytics.saga";

export default function* analyticsWatcher(): any {
  yield takeEvery(
    actionTypes.ANALYTICS_API_DATA_REQUEST,
    getAnalyticsDataAPIWorker
  );
  yield takeEvery(
    actionTypes.ANALYTICS_CHARTS_DOWNLOAD_CSV_API_REQUEST,
    getAnalyticsChartDownloadCsvAPIWorker
  );
  yield takeEvery(
    actionTypes.ANALYTICS_API_CARD_DATA_REQUEST,
    getAnalyticsCardDataAPIWorker
  );
  
}
