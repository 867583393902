// import { toast } from "react-toastify";
import { call, put, delay } from "redux-saga/effects";
// import moment from "moment";
import {
  SERVER_URL2,
  CALL_REPORT_URL,
  config,
} from "../../../../services/ApiRoutes";
import { getValueByReferenceKey } from "../../../../utils";
import actionTypes from "../actionTypes";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { downloadFromLink } from "../../../../utils/downloadCsvFile";

let cancelTokenRD: any;
export function* getReportDataAPIWorker(action: any): any {
  yield delay(300);
  if (typeof cancelTokenRD != typeof undefined) {
    cancelTokenRD.cancel("Operation canceled due to new request.");
  }
  cancelTokenRD = axios.CancelToken.source();
  const bodyData = action.payload.bodyData;
  if (bodyData?.startDueDate) {
    bodyData["startDueDate"] = `${moment(bodyData.startDueDate).format(
      "YYYY-MM-DD"
    )}T00:00:00`;
    bodyData["endDueDate"] = `${moment(bodyData?.endDueDate).format(
      "YYYY-MM-DD"
    )}T23:59:59`;
  }

  try {
    const res = yield call(
      config.POST_WITH_CANCEL_TOKEN_HEADER,
      `${SERVER_URL2}${CALL_REPORT_URL.FILTER_URL}`,
      // `http://localhost:6050/api/conversations/summary/v1/filter`,
      bodyData,
      cancelTokenRD,
      { "x-access-token": action.payload.token }
    );
    // console.log(res);
    yield put({
      type: actionTypes.REPORT_DATA_API_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    console.error(error);
    yield put({ type: actionTypes.REPORT_DATA_API_FAILURE, payload: error });
  }
}

export function* sortReportTableDataWatcher({ payload }: any): any {
  const key = Object.keys(payload.tableSortingColumn)[0];
  const newData = [...payload.tableData];
  if (key === "time") {
    if (payload.tableSortingColumn[key] === "asc") {
      newData.sort(
        (a, b) =>
          new Date("1970/01/01 " + getValueByReferenceKey(a, key)).getTime() -
          new Date("1970/01/01 " + getValueByReferenceKey(b, key)).getTime()
      );
    } else {
      newData.sort(
        (a, b) =>
          new Date("1970/01/01 " + getValueByReferenceKey(b, key)).getTime() -
          new Date("1970/01/01 " + getValueByReferenceKey(a, key)).getTime()
      );
    }
  }

  // general case
  else if (key) {
    if (payload.tableSortingColumn[key] === "asc") {
      newData.sort((a, b) =>
        getValueByReferenceKey(a, key)?.localeCompare(
          getValueByReferenceKey(b, key)
        )
      );
    } else {
      newData.sort((a, b) =>
        getValueByReferenceKey(b, key)?.localeCompare(
          getValueByReferenceKey(a, key)
        )
      );
    }
  }
  yield put({ type: actionTypes.SET_REPORT_TABLE_DATA, payload: newData });
}

export function* getReportNextDataAPIWorker(action: any): any {
  if (typeof cancelTokenRD != typeof undefined) {
    cancelTokenRD.cancel("Operation canceled due to new request.");
  }
  cancelTokenRD = axios.CancelToken.source();
  const bodyData = action.payload.bodyData;
  if (bodyData?.startDueDate) {
    bodyData["startDueDate"] = `${moment(bodyData.startDueDate).format(
      "YYYY-MM-DD"
    )}T00:00:00`;
    bodyData["endDueDate"] = `${moment(bodyData?.endDueDate).format(
      "YYYY-MM-DD"
    )}T23:59:59`;
  }

  try {
    const res = yield call(
      config.POST_WITH_CANCEL_TOKEN_HEADER,
      `${SERVER_URL2}${CALL_REPORT_URL.FILTER_URL}`,
      // `http://localhost:6050/api/conversations/summary/v1/filter`,
      bodyData,
      cancelTokenRD,
      { "x-access-token": action.payload.token }
    );
    console.log("bro", action.payload);
    if (bodyData.page !== 1) {
      if (action.payload?.slot) {
        yield put({
          type: actionTypes.REPORT_DATA_PREVIOUS_SLOT_API_SUCCESS,
          payload: res.data.data,
        });
      } else {
        yield put({
          type: actionTypes.REPORT_DATA_NEXT_API_SUCCESS,
          payload: res.data.data,
        });
      }
    } else {
      if (action.payload?.slot) {
        yield put({
          type: actionTypes.REPORT_DATA_PREVIOUS_SLOT_API_SUCCESS,
          payload: res.data.data,
        });
      } else {
        yield put({
          type: actionTypes.REPORT_DATA_PREVIOUS_API_SUCCESS,
          payload: res.data.data,
        });
        yield put({
          type: actionTypes.REPORT_DATA_API_SUCCESS,
          payload: res.data.data,
        });
      }
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.REPORT_DATA_NEXT_API_FAILURE,
      payload: error,
    });
  }
}

export function* downloadDurationReportCsv(data: any): any {
  const toastId = toast.loading("Downloading transcript...");
  const transcriptDownloadUrl = `${SERVER_URL2}${CALL_REPORT_URL.DOWNLOAD_DURATION_REPORT}`;
  try {
    const res = yield call(
      config.POST_WITH_HEADERS_BODY,
      transcriptDownloadUrl,
      data.payload.data,
      { "x-access-token": data.payload.token }
    );
    if (res?.data?.url) {
      downloadFromLink(
        res?.data?.url
      );
      toast.update(toastId, {
        render: "Report downloaded successfully",
        type: "success",
        autoClose: 4000,
        closeButton: true,
        isLoading: false,
      });
    }
    if (res?.data?.message) {
      toast.update(toastId, {
        render: res?.data?.message,
        type: "error",
        autoClose: 4000,
        closeButton: true,
        isLoading: false,
      });
    }
  } catch (err) {
    console.error(err);
    toast.update(toastId, {
      render: "Report download failed",
      type: "error",
      autoClose: 4000,
      closeButton: true,
      isLoading: false,
    });
  }
}
export function* downloadCustomiseReportCsv(data: any): any {
  const toastId = toast.loading("Downloading transcript...");
  const transcriptDownloadUrl = `${SERVER_URL2}${CALL_REPORT_URL.REPORT_MAIL_CUSTOMISE_DATA}`;
  try {
    const res = yield call(
      config.POST_WITH_HEADERS_BODY,
      transcriptDownloadUrl,
      data.payload.data,
      { "x-access-token": data.payload.token }
    );
    console.log("hjk", res?.data);
    if (res?.data?.message) {
      toast.update(toastId, {
        render: res?.data?.message,
        type: "success",
        autoClose: 4000,
        closeButton: true,
        isLoading: false,
      });
    }
  } catch (err) {
    console.error(err);
    toast.update(toastId, {
      render: "Report download failed",
      type: "error",
      autoClose: 4000,
      closeButton: true,
      isLoading: false,
    });
  }
}
