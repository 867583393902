import React, { useEffect, useState } from "react";
import styles from "./CampaignDetailsScreen.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { useNavigate } from "react-router-dom";

import InputField from "../../../generic/inputField/InputField";
import TextAreaInput from "../../../generic/textareainput/TextAreaInput";
import Button from "../../../generic/button/Button";
import DropDown from "../../../generic/dropdownsaarthi2/DropdownSaarthi";
import Icon from "../../../generic/icon/Icon";
import StepProgress from "../../../generic/stepProgress/StepProgress";
import { InfoToolTip } from "../../../generic/infoToolTip/InfoToolTip";

import { getRouteByIndex } from "../../../../utils/ProgressBarNavFunc";

import {
  backIcon,
  dropdownIcon,
} from "../../../../theme/assets/svg/campaign_v3/index";

import { format } from "date-fns";
import _ from "lodash";

import {
  maxCompletedStep,
  storeCurrentStep,
} from "../../../../redux/campaign_V2.0/progressBar/actions";
import {
  getCampaignOptionsAPIData,
  getCampaignASROptionsAPIData,
  getCampaignTTSOptionsAPIData,
  createCampaignAPIData,
  updateCampaignAPIData,
  setCampaignAPIData,
} from "../../../../redux/campaign_V2.0/createCampaign/actions";
import { clearAllCampaignData } from "../../../../redux/campaign_V2.0/campaignTable/actions";

interface Campaign {
  id?: string;
  name: string;
  desc: string;
  callType: string;
  leadType: string;
  campaignType: string;
  asr: string;
  telephony: string;
  tts: string;
}

function findKeyByValue(valueToFind: string, options: any) {
  for (const key in options) {
    if (options[key] === valueToFind) {
      return key;
    }
  }
  return null;
}

export const CampaignDetailsScreen = () => {
  const accountDetails = useSelector(
    (store: RootState) => store?.schedulerReducer?.accountInfoDetails
  );

  const stepsTotal: string[] = accountDetails?.campaignProgressBar;

  const { maxCompletedStep: maxStateCompleted, currentStep } = useSelector(
    (state: RootState) => state?.campaignModuleReducer?.progressBar
  );

  const currentSelectedTeamId = useSelector(
    (store: RootState) => store.loginReducer?.currentTeam?.id
  );

  const teamUseCaseId = useSelector(
    (store: any) => store.loginReducer.currentTeam.useCaseId
  );
  const token = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  // const campaignOptions = useSelector(
  //   (store: RootState) =>
  //     store?.campaignModuleReducer?.createCampaignData?.campaignOptions?.data
  // );

  // const asrOptions = useSelector(
  //   (store: RootState) =>
  //     store?.campaignModuleReducer?.createCampaignData?.campaignASROptions?.data
  // );

  // const ttsOptions = useSelector(
  //   (store: RootState) =>
  //     store?.campaignModuleReducer?.createCampaignData?.campaignTTSOptions?.data
  // );

  const {
    // data: campaignData,
    status: isLoadingCampaignData,
    error: campaignDataError,
    isNavigate,
  } = useSelector(
    (store: RootState) =>
      store?.campaignModuleReducer?.createCampaignData?.campaignData
  );

  const campaignData = useSelector(
    (store: RootState) =>
      store?.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
  );

  // const ASROptions = campaignOptions?.ASR;
  // const teleOptions = campaignOptions?.telephony;
  // const ttsOptions = campaignOptions?.TTS;
  // const callTypeAllOptions = campaignOptions?.callType;

  const telephonyOptions = ["Epicode", "Internal", "Twilio"];
  const telephonyDisplayOptions: any = {
    "Saarthi Telephony 1": "Epicode",
    "Saarthi Telephony 2": "Internal",
    "Saarthi Telephony 3": "Twilio",
  };

  const callTypeAllOptions = ["Client UAT", "Internal UAT", "Live"];
  const leadTypeAllOptions = ["Transactional", "Promotional", "Default"];
  const campaignTypeAllOptions = ["AI", "AI & Human", "Human"];

  const callTypeOptions =
    process.env.REACT_APP_ENV === "production" && accountDetails?.type === "External"
      ? _.pull(callTypeAllOptions, "Internal UAT") : callTypeAllOptions;


  const defaultState: Campaign = {
    name: "",
    desc: "",
    callType: "",
    leadType:
      teamUseCaseId !== "62c81c4a4a0affe91342aaaa"
        ? "Promotional"
        : "Transactional",
    campaignType: "",
    asr: "",
    telephony: accountDetails?.type !== "Internal" ? "Epicode" : "",
    tts: "",
  };

  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
  const [campaignInput, setCampaignInput] = useState<Campaign>(defaultState);
  const [isDetailsInValid, setIsDetailsInValid] = useState<boolean>(true);
  const [isUpdateValid, setIsUpdateValid] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignInput((prev: Campaign) => {
      return { ...prev, name: event?.target?.value };
    });
  };

  const handleDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignInput((prev: Campaign) => {
      return { ...prev, desc: event?.target?.value };
    });
  };

  const onChangeCallType = (e: string) => {
    setCampaignInput({ ...campaignInput, callType: e });
  };

  const onChangeLeadType = (e: string) => {
    setCampaignInput({ ...campaignInput, leadType: e });
  };

  const onChangeCampaignType = (e: string) => {
    setCampaignInput({ ...campaignInput, campaignType: e });
  };

  // const onChangeASR = (e: string) => {
  //   setCampaignInput({ ...campaignInput, asr: e });
  // };

  const onChangeTelephony = (e: string) => {
    setCampaignInput({ ...campaignInput, telephony: e });
  };

  // const onChangeTTS = (e: string) => {
  //   setCampaignInput({ ...campaignInput, tts: e });
  // };

  const handleInputValidation = () => {
    if (_.isEqual(campaignInput, defaultState)) {
      return true;
    }

    const { name, desc, callType, leadType, campaignType, telephony } =
      campaignInput;

    if (
      name.trim().length === 0 ||
      desc.trim().length === 0 ||
      desc.length > 350 ||
      callType === "" ||
      leadType === "" ||
      campaignType === "" ||
      telephony === "" ||
      isLoadingCampaignData === "loading"
    ) {
      return true;
    }

    return false;
  };

  const handleUpdateApiCallValidation = () => {
    const res = Object.values(
      _.pick(campaignData, [
        "parentLabel",
        "description",
        "ASR",
        "TTS",
        "telephonyName",
        "callType",
        "leadType",
      ])
    );
    const input = Object.values(
      _.pick(campaignInput, [
        "name",
        "desc",
        "asr",
        "tts",
        "telephony",
        "callType",
        "leadType",
      ])
    );

    if (
      _.isEqual(input, res) &&
      _.isEqual(campaignData?.campaignType?.[0], campaignInput?.campaignType)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleCreateCampaign = () => {
    const data_to_send: object = {
      accountName: accountDetails?.name,
      accountId: accountDetails?.id,
      token: token,
      campaignName: campaignInput?.name,
      description: campaignInput?.desc,
      ASR: "Azure",
      TTS: "Saarthi",
      telephonyName:
        accountDetails?.type !== "Internal"
          ? "Epicode"
          : telephonyDisplayOptions[campaignInput?.telephony],
      callType: campaignInput?.callType,
      leadType: campaignInput?.leadType,
      campaignType: [campaignInput?.campaignType],
      useCaseId: teamUseCaseId,
      startDate: format(new Date(), "yyyy-MM-dd"),
      endDate: format(new Date(), "yyyy-MM-dd"),
      teamId: currentSelectedTeamId,
    };
    dispatch(
      createCampaignAPIData({
        ...data_to_send,
      })
    );
  };

  const handleUpdateCampaign = () => {
    if (isUpdateValid) {
      const data_to_send: object = {
        id: [campaignData?.id],
        token: token,
        campaignName: campaignInput?.name,
        description: campaignInput?.desc,
        ASR: "Azure",
        TTS: "Saarthi",
        telephonyName:
          accountDetails?.type !== "Internal"
            ? "Epicode"
            : telephonyDisplayOptions[campaignInput?.telephony],
        callType: campaignInput?.callType,
        leadType: campaignInput?.leadType,
        campaignType: [campaignInput?.campaignType],
        personas: campaignData?.personas,
        mode: "update",
        startDate: campaignData?.startDate,
        endDate: campaignData?.endDate,
      };
      dispatch(
        updateCampaignAPIData({
          ...data_to_send,
        })
      );
      setIsUpdateValid(false);
    } else {
      const nextStep = getRouteByIndex(currentStep, stepsTotal);
      if (nextStep) {
        navigate(nextStep?.route);
        dispatch(setCampaignAPIData({ isNavigate: false }));
      }
    }
  };

  const handleOnClickNextForUpload = () => {
    campaignData && campaignData?.id.length > 0
      ? handleUpdateCampaign()
      : handleCreateCampaign();
  };

  useEffect(() => {
    setIsDetailsInValid(handleInputValidation());
  }, [campaignInput, isLoadingCampaignData, campaignDataError, campaignData]);

  useEffect(() => {
    if (campaignData && campaignData?.id.length > 0) {
      setIsUpdateValid(handleUpdateApiCallValidation());
    }
  }, [campaignInput, campaignData]);

  useEffect(() => {
    if (isNavigate) {
      if (campaignData?.id.length > 0) {
        const nextStep = getRouteByIndex(currentStep, stepsTotal);
        if (nextStep) {
          navigate(nextStep?.route);
          dispatch(setCampaignAPIData({ ...campaignData, isNavigate: false }));
        }
      }
    }
  }, [isNavigate]);

  // useEffect(() => {
  //   if (campaignOptions === null) {
  //     dispatch(getCampaignOptionsAPIData());
  //   }
  // }, [campaignOptions]);

  // useEffect(() => {
  //   if (asrOptions === null) {
  //     dispatch(getCampaignASROptionsAPIData({ accountId: accountDetails?.id }));
  //   }
  //   if (ttsOptions === null) {
  //     dispatch(getCampaignTTSOptionsAPIData({ accountId: accountDetails?.id }));
  //   }
  // }, [asrOptions, ttsOptions, accountDetails]);

  useEffect(() => {
    if (campaignData && campaignData?.id?.length > 0) {
      if (maxStateCompleted <= 1) dispatch(maxCompletedStep(1));
    }
  }, [campaignData, maxStateCompleted]);

  useEffect(() => {
    if (stepsTotal) {
      if (location.pathname.includes("campaigncreation")) {
        dispatch(storeCurrentStep(stepsTotal?.indexOf("Campaign Details") + 1));
      }
    }
  }, [location.pathname, stepsTotal]);

  useEffect(() => {
    if (campaignData) {
      setCampaignInput({
        name: campaignData.parentLabel || "",
        desc: campaignData.description || "",
        callType: campaignData.callType || "",
        leadType:
          campaignData.leadType ||
          (teamUseCaseId !== "62c81c4a4a0affe91342aaaa"
            ? "Promotional"
            : "Transactional"),
        campaignType: campaignData.campaignType?.[0] || "",
        asr: campaignData.ASR || "",
        telephony:
          accountDetails?.type !== "Internal"
            ? "Epicode"
            : findKeyByValue(
              campaignData?.telephonyName,
              telephonyDisplayOptions
            ) || "",
        tts: campaignData.TTS || "",
      });
    }
    console.log(campaignData);
  }, [campaignData, accountDetails]);

  const CampaignNameLabel = (
    <div className={styles.labelDivStyling}>
      <div className={styles.labelStyling}>Campaign Name</div>
      {/* <InfoToolTip
        message={
          "Lorem ipsum it is sometimes known, is dummy text used in laying out print..."
        }
        isError={false}
        position="top-end"
        isIconSmall
        isLight
      />*/}
    </div>
  );

  console.log(campaignInput.telephony, defaultState, "tele");

  return (
    <div className={styles.createCampaignScreenDiv}>
      <div className={styles.stepProgressDiv}>
        <StepProgress
          color={"#07B464"}
          arr={[
            {
              name: "Campaign Details",
              fun: () => {
                navigate("/campaignmanager/campaigncreation");
              },
            },
            {
              name: "Data Upload",
              fun: () => {
                navigate("/campaignmanager/dataupload");
              },
            },
            {
              name: "Strategy Selection",
              fun: () => {
                navigate("/campaignmanager/strategyselection");
              },
            },
            {
              name: "Agent Configuration",
              fun: () => {
                navigate("/campaignmanager/allagents");
              },
            },
          ].filter((each) => stepsTotal?.includes(each.name))}
          maxStateCompleted={maxStateCompleted}
          currentStepComplete={
            campaignData && campaignData?.id.length > 0 ? true : false
          }
          inProgressStepFromOut={stepsTotal?.indexOf("Campaign Details") + 1}
        />
      </div>
      <div className={styles.createCampaignScreen}>
        <div className={styles.createCampaignScreenTopDiv}>
          <Icon
            img_src={backIcon}
            extraClass={styles.backIconStyling}
            onClick={() => {
              dispatch(clearAllCampaignData());
              navigate("/campaignmanager");
            }}
            id="auto_campaign_create_back_icon"
          />
          <div className={styles.headerInfoStyling}>Create a campaign</div>
        </div>
        <div className={styles.createCampaignScreenInputsDiv}>
          <div className={`${styles.campaignNameDiv}`}>
            <InputField
              // label={
              //   campaignInput?.name && campaignInput?.name?.length > 0
              //     ? CampaignNameLabel
              //     : ""
              // }
              label={CampaignNameLabel}
              extraLabelClass={styles.labelDivStyling}
              extraClass={styles.campaignInputDivStyling}
              className={styles.campaignInputFieldStyling}
              type="text"
              placeholder="Campaign Name"
              onChange={handleNameChange}
              onFocus={() => {
                setIsReadOnly(false);
              }}
              onBlur={() => {
                setIsReadOnly(true);
              }}
              value={campaignInput?.name}
              id="auto_campaign_input_name"
              maxLength={21}
            />
            <div className={styles.errorMsgDiv}>
              {campaignInput?.name && campaignInput?.name?.length > 20 ? (
                <div
                  className={styles.errorMsgStyling}
                  id="auto_campaign_error_name"
                >
                  Maximum 20 characters are allowed
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={styles.createCampaignInfoDiv}>
            {accountDetails?.type === "Internal" ? (
              <div className={styles.createCampaignInfoSubDiv}>
                <div className={styles.labelDivStyling}>
                  <div className={styles.labelStyling}>Telephony</div>
                  <InfoToolTip
                    message={
                      "Lorem ipsum it is sometimes known, is dummy text used in laying out print..."
                    }
                    isError={false}
                    position="top-start"
                    isIconSmall
                    isLight
                  />
                </div>
                {/* <div
                className={styles.dropDownSelectedStyleStatic}
                id="auto_campaign_input_telephony"
              >
                Saarthi-Telephony_1
              </div> */}
                <DropDown
                  droplist={{
                    optionList: Object.keys(telephonyDisplayOptions),
                    placeHolderText: campaignInput?.telephony ? (
                      campaignInput?.telephony
                    ) : (
                      <span className={styles.defaultStyle}>
                        Select Telephony
                      </span>
                    ),
                    imgSrcRight: dropdownIcon,
                  }}
                  selectedItem={(item: any) => onChangeTelephony(item)}
                  extraClassSelectedArea={styles.dropDownSelectedStyling}
                  extraClassToBeSelectedArea={
                    styles.dropDownToBeSelectedStyling
                  }
                  extraClassPlaceHolderStyling={
                    styles.dropDownPlaceHolderStyling
                  }
                  extraClassPlaceHolder={styles.defaultPlaceHolderStyling}
                  extraClassDropDownUl={styles.dropDownUlStyling}
                  extraClassDropDownLi={styles.dropDownLiStyling}
                  outerSelected={campaignInput?.telephony}
                  extraHighlight={styles.highlightStyling}
                />
              </div>
            ) : null}
            <div className={styles.createCampaignInfoSubDiv}>
              <div className={styles.labelDivStyling}>
                {/* {campaignInput?.campaignType &&
                  campaignInput?.campaignType.length > 0 ? (
                    <> */}
                <div className={styles.labelStyling}>Agent Type</div>
                <InfoToolTip
                  message={
                    "Lorem ipsum it is sometimes known, is dummy text used in laying out print..."
                  }
                  isError={false}
                  position="top-start"
                  isIconSmall
                  isLight
                />
                {/* </>
                  ) : null} */}
              </div>

              <DropDown
                droplist={{
                  optionList: campaignTypeAllOptions,
                  placeHolderText: campaignInput?.campaignType ? (
                    campaignInput?.campaignType
                  ) : (
                    <span className={styles.defaultStyle}>
                      Select Agent Type
                    </span>
                  ),
                  imgSrcRight: dropdownIcon,
                }}
                selectedItem={(item: string) => onChangeCampaignType(item)}
                extraClassSelectedArea={styles.dropDownSelectedStyling}
                extraClassToBeSelectedArea={styles.dropDownToBeSelectedStyling}
                extraClassPlaceHolderStyling={styles.dropDownPlaceHolderStyling}
                extraClassPlaceHolder={styles.defaultPlaceHolderStyling}
                extraClassDropDownUl={styles.dropDownUlStyling}
                extraClassDropDownLi={styles.dropDownLiStyling}
                outerSelected={campaignInput?.campaignType}
                extraHighlight={styles.highlightStyling}
                id="auto_campaign_type_dropdown"
              />
            </div>
            {/* <div className={styles.createCampaignInfoSubDiv}>
              <div className={styles.labelDivStyling}>
                {/* {campaignInput?.asr && campaignInput?.asr.length > 0 ? (
                <> */}
            {/* <div className={styles.labelStyling}>ASR</div> */}
            {/* 
                  <InfoToolTip
                    message={
                      "Lorem ipsum it is sometimes known, is dummy text used in laying out print..."
                    }
                    isError={false}
                    position="top-end"
                    isIconSmall
                    isLight
                  />
                  */}
            {/* </> */}
            {/* ) : null} */}
            {/* </div>
              <div
                className={styles.dropDownSelectedStyleStatic}
                id="auto_campaign_input_asr"
              >
                Saarthi-ASR_1
              </div> */}
            {/* <DropDown
              droplist={{
                optionList: ASROptions,
                placeHolderText: campaignInput?.asr ? (
                  campaignInput?.asr
                ) : (
                  <span className={styles.defaultStyle}>Select ASR</span>
                ),
                imgSrcRight: dropDownArrowIcon,
              }}
              selectedItem={(item: any) => onChangeASR(item)}
              extraClassSelectedArea={styles.dropDownSelectedStyling}
              extraClassToBeSelectedArea={styles.dropDownToBeSelectedStyling}
              extraClassPlaceHolderStyling={styles.dropDownPlaceHolderStyling}
              extraClassPlaceHolder={styles.defaultPlaceHolderStyling}
              extraClassDropDownUl={styles.dropDownUlStyling}
              extraClassDropDownLi={styles.dropDownLiStyling}
                                extraHighlight={styles.highlightStyling}
              outerSelected={campaignInput?.asr}
            /> */}
            {/* </div> */}
            <div className={styles.createCampaignInfoSubDiv}>
              <div className={styles.labelDivStyling}>
                {/* {campaignInput?.callType &&
                  campaignInput?.callType.length > 0 ? (
                    <> */}
                <div className={styles.labelStyling}>Call Type</div>
                <InfoToolTip
                  message={
                    "Lorem ipsum it is sometimes known, is dummy text used in laying out print..."
                  }
                  isError={false}
                  position="top-start"
                  isIconSmall
                  isLight
                />
                {/*  </>
                  ) : null} */}
              </div>

              <DropDown
                droplist={{
                  optionList: callTypeOptions,
                  placeHolderText: campaignInput?.callType ? (
                    campaignInput?.callType
                  ) : (
                    <span className={styles.defaultStyle}>
                      Select Call Type
                    </span>
                  ),
                  imgSrcRight: dropdownIcon,
                }}
                selectedItem={(item: string) => onChangeCallType(item)}
                extraClassSelectedArea={styles.dropDownSelectedStyling}
                extraClassToBeSelectedArea={styles.dropDownToBeSelectedStyling}
                extraClassPlaceHolderStyling={styles.dropDownPlaceHolderStyling}
                extraClassPlaceHolder={styles.defaultPlaceHolderStyling}
                extraClassDropDownUl={styles.dropDownUlStyling}
                extraClassDropDownLi={styles.dropDownLiStyling}
                outerSelected={campaignInput?.callType}
                extraHighlight={styles.highlightStyling}
                id="auto_call_type_dropdown"
              />
            </div>

            <div className={styles.createCampaignInfoSubDiv}>
              <div className={styles.labelDivStyling}>
                {/* {campaignInput?.leadType &&
                  campaignInput?.leadType.length > 0 ? (
                    <> */}
                <div className={styles.labelStyling}>Lead Type</div>
                <InfoToolTip
                  message={
                    "Lorem ipsum it is sometimes known, is dummy text used in laying out print..."
                  }
                  isError={false}
                  position="top-start"
                  isIconSmall
                  isLight
                />
                {/* </>
                  ) : null} */}
              </div>

              {/* {!campaignData?.isCreated ? (
                <DropDown
                  droplist={{
                    optionList: leadTypeAllOptions,
                    placeHolderText: campaignInput?.leadType
                      ? campaignInput?.leadType
                      :  teamUseCaseId !== "62c81c4a4a0affe91342aaaa"
                      ? setCampaignInput((prev) => {
                          return {
                            ...prev,
                            leadType: "Promotional",
                          };
                        })
                      : setCampaignInput((prev) => {
                          return {
                            ...prev,
                            leadType: "Transactional",
                          };
                        }),
                    imgSrcRight: dropdownIcon,
                  }}
                  selectedItem={(item: any) => onChangeLeadType(item)}
                  extraClassSelectedArea={styles.dropDownSelectedStyling}
                  extraClassToBeSelectedArea={
                    styles.dropDownToBeSelectedStyling
                  }
                  extraClassPlaceHolderStyling={
                    styles.dropDownPlaceHolderStyling
                  }
                  extraClassPlaceHolder={styles.defaultPlaceHolderStyling}
                  extraClassDropDownUl={styles.dropDownUlStyling}
                  extraClassDropDownLi={styles.dropDownLiStyling}
                  outerSelected={campaignInput?.leadType}
                  extraHighlight={styles.highlightStyling}
                  id="auto_lead_type_dropdown"
                />

            
              ) : ( */}
              <div
                className={styles.dropDownSelectedStyleStatic}
                id="auto_lead_type_dropdown"
              >
                {campaignInput?.leadType}
              </div>
              {/* )} */}
            </div>

            {/* <div className={styles.createCampaignInfoSubDiv}>
              <div className={styles.labelDivStyling}>
                {/* {campaignInput?.tts && campaignInput?.tts.length > 0 ? ( */}
            {/* <> */}
            {/* <div className={styles.labelStyling}>TTS</div> */}
            {/*  <InfoToolTip
                    message={
                      "Lorem ipsum it is sometimes known, is dummy text used in laying out print..."
                    }
                    isError={false}
                    position="top-end"
                    isIconSmall
                    isLight
                  />*/}
            {/* </> */}
            {/* ) : null} */}
            {/* </div>
              <div
                className={styles.dropDownSelectedStyleStatic}
                id="auto_campaign_input_tts"
              >
                Saarthi-TTS_1
              </div> */}
            {/* <DropDown
              droplist={{
                optionList: ttsOptions,
                placeHolderText: campaignInput?.tts ? (
                  campaignInput?.tts
                ) : (
                  <span className={styles.defaultStyle}>Select TTS</span>
                ),
                imgSrcRight: dropDownArrowIcon,
              }}
              selectedItem={(item: any) => onChangeTTS(item)}
              extraClassSelectedArea={styles.dropDownSelectedStyling}
              extraClassToBeSelectedArea={styles.dropDownToBeSelectedStyling}
              extraClassPlaceHolder={styles.defaultPlaceHolderStyling}
              extraClassPlaceHolderStyling={styles.dropDownPlaceHolderStyling}
              extraClassDropDownUl={styles.dropDownUlStyling}
              extraClassDropDownLi={styles.dropDownLiStyling}
              outerSelected={campaignInput?.tts}
                                extraHighlight={styles.highlightStyling}
            /> */}
            {/* </div> */}
          </div>
          <div className={styles.createCampaignFieldDiv}>
            <div className={styles.labelDivStyling}>
              {/* {campaignInput?.desc && campaignInput?.desc.length > 0 ? (
                <> */}
              <div className={styles.labelStyling}>Campaign Description</div>
              {/*  <InfoToolTip
                    message={
                      "Lorem ipsum it is sometimes known, is dummy text used in laying out print..."
                    }
                    isError={false}
                    position="top-end"
                    isIconSmall
                    isLight
                  /> */}
              {/* </>
              ) : null} */}
            </div>
            <TextAreaInput
              extraTextClass={styles.campaignInputDescDivStyling}
              extraClass={styles.campaignInputDescStyling}
              onFocus={() => {
                setIsReadOnly(false);
              }}
              onBlur={() => {
                setIsReadOnly(true);
              }}
              handleChange={handleDescChange}
              value={campaignInput?.desc}
              placeholder="Campaign Description"
              maxLength={351}
              id="auto_campaign_input_desc"
            />
            {campaignInput && campaignInput.desc.length > 350 ? (
              <div
                className={styles.errorMsgStyling}
                id="auto_campaign_error_desc"
              >
                Maximum 350 characters are allowed
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={styles.createCampaignButtonDiv}>
            <Button
              text={"Next"}
              extraClass={`${styles.buttonStyling} ${isDetailsInValid
                ? // ||
                // (campaignData?.customerUploadStatus !== undefined &&
                //   campaignData?.customerUploadStatus !== "Completed")
                styles.nextButtonInActiveStyle
                : styles.nextButtonActiveStyle
                }`}
              disabled={isDetailsInValid}
              onClick={() => {
                handleOnClickNextForUpload();
              }}
              id="auto_campaign_next_btn"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
