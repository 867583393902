import React, { useRef, useState, useEffect } from "react";
import styles from "./UploadFile.module.scss";
import InputField from "../inputField/InputField";
import Button from "../button/Button";
import Icon from "../icon/Icon";
import { uploadCloseIcon } from "../../../theme/assets/svg/integration_V2.0/index";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

interface UploadFileProps {
  uploadIcon?: string;
  fileLabel?: string;
  isShowToolTip?: boolean;
  showNewToolTipMsg?: any;
  showNewToolTipPosition?: any;
  extraToopTipDiv?: string;
  handleUploadFile?: any;
  uploadedfileName?: string;
  toshowGreenLine?: boolean;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "6px",
  borderBottomLeftRadius: 5,
  borderBottomRightRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#fff",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#11AF22;",
  },
}));

export const UploadFile = ({
  uploadIcon,
  fileLabel,
  isShowToolTip,
  showNewToolTipMsg,
  showNewToolTipPosition,
  extraToopTipDiv,
  handleUploadFile,
  uploadedfileName,
  toshowGreenLine,
}: UploadFileProps) => {
  const hiddenFileInput = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState("");
  const [progress, setProgress] = React.useState(20);
  const [startProgress, setStartProgress] = useState(false);

  useEffect(() => {
    if (startProgress === true) {
      const timer = setInterval(() => {
        if (toshowGreenLine) {
          setProgress((prevProgress) =>
            prevProgress >= 100 ? 100 : prevProgress + 20
          );
        } else {
          setProgress((prevProgress) =>
            prevProgress >= 100 ? 20 : prevProgress + 20
          );
        }
      }, 700);
      return () => {
        clearInterval(timer);
      };
    } else {
      setProgress(20);
    }
  }, [startProgress]);

  useEffect(() => {
    if (fileName && !toshowGreenLine) {
      setTimeout(() => {
        setStartProgress(false);
      }, 3500);
    }
  }, [fileName]);

  /** for setting already uploaded file name **/
  useEffect(() => {
    uploadedfileName?.length && setFileName(uploadedfileName);
  }, [uploadedfileName]);

  const handleFileUpload = (event: any) => {
    const files = hiddenFileInput.current.files;

    if (files && files.length > 0) {
      const selectedFile = files[0];
      // Check if the selected file is a PDF and its size is less than 4MB
      if (
        selectedFile.type === "application/pdf" &&
        selectedFile.size <= 4 * 1024 * 1024
      ) {
        handleUploadFile(event.target.files);
        setFileName(event.target.files[0].name);
        setError("");
        // Perform upload logic here
      } else {
        setFileName("");
        // Display an error message
        setError("Please select a PDF file that is less than 4MB in size.");
      }
    }
  };

  const handleDeleteFile = () => {
    setFileName("");
    setError("");
    handleUploadFile("");
    setStartProgress(false);
    setProgress(20);
  };
  const habndleFileuploadClick = (e: any) => {
    setStartProgress(true);
    handleFileUpload(e);
  };

  return (
    <div className={styles.uploadFileCompWrapper}>
      <div className={styles.uploadFileWrapper}>
        {fileName ? (
          <div className={styles.uploadedDivStyling}>
            <div className={styles.uploadedDivTopStyling}>
              <div className={styles.fileNameStyling}>{fileName} </div>
              <Icon
                img_src={uploadCloseIcon}
                onClick={handleDeleteFile}
                extraClass={styles.closeIconStyling}
              />
            </div>
            <div className={styles.uploadedDivBtmStyling}>
              {startProgress && (
                <BorderLinearProgress variant="determinate" value={progress} />
              )}
            </div>
          </div>
        ) : (
          <div className={styles.uploadDivStyling}>
            <InputField
              ref={hiddenFileInput}
              onChange={habndleFileuploadClick}
              extraClass={styles.inputUploadStyling}
              type="file"
              accept=".pdf"
            />
            <Button
              text={fileLabel}
              extraClass={`${styles.uploadButtonStyling}`}
              image_src_left={uploadIcon}
              imgClass={`${styles.extraUploadIconStyling}`}
              onClick={() => {
                hiddenFileInput.current.click();
              }}
              isShowToolTip={isShowToolTip}
              showNewToolTipMsg={showNewToolTipMsg}
              showNewToolTipPosition={showNewToolTipPosition}
              extraToopTipDiv={extraToopTipDiv}
            />
          </div>
        )}
      </div>
      <div className={styles.errorDivStyling}>{error && error}</div>
    </div>
  );
};
