import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import {
  SERVER_URL2,
  AGENT_DESKTOP_API,
  ANALYTICS_URL,
  config,
} from "../../../../services/ApiRoutes";
import actionTypes from "../actionTypes";
import axios from "axios";

const cancelTokenRD: Record<string, any> = {};
let cancelTokSp: any;
export function* getDashboardDataAPIWorker(action: any): any {
  // if (typeof cancelTokSp!= typeof undefined) {
  //   cancelTokSp.cancel("Operation canceled due to new request.");
  // }
  // cancelTokSp=axios.CancelToken.source();
  // if (
  //   typeof cancelTokenRD?.[action.payload.bodyData.uniqueId] != typeof undefined
  // ) {
  //   cancelTokenRD?.[action.payload.bodyData.uniqueId].cancel(
  //     "Operation canceled due to new request."
  //   );
  // }
  // cancelTokenRD[action.payload.bodyData?.uniqueId] = axios.CancelToken.source();

  try {
    const res = yield call(
      config.POST_WITH_HEADERS_BODY_CANCEL_TOKEN,
      `${
        AGENT_DESKTOP_API.DASHBOARD_DATA_URL
      }`,
      action.payload.bodyData,
      // { "x-access-token": action.payload.token },
      //  cancelTokenRD[action.payload.bodyData?.uniqueId].token
    );

    yield put({
      type: actionTypes.DASHBOARD_API_DATA_SUCCESS,
      payload: res.data.data,
      // uniqueId: action.payload.bodyData.uniqueId,
    });
  
    // if (res.data.data?.card_details) {
    //   yield put({
    //     type: actionTypes.DASHBOARD_API_CARD_DATA_SUCCESS,
    //     payload: res.data.data?.card_details,
    //   });
    // }
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.DASHBOARD_API_DATA_FAILURE,
      payload: error,
      // uniqueId: action.payload.bodyData.uniqueId,
    });
  }
}

// for getting all cards data
// export function* getDashboardCardDataAPIWorker(action: any): any {
//   const accountName = sessionStorage.getItem("accountName");
//   const subModule = sessionStorage.getItem("subModule");
//   // if (typeof cancelTokSp!= typeof undefined) {
//   //   cancelTokSp.cancel("Operation canceled due to new request.");
//   // }
//   // cancelTokSp=axios.CancelToken.source();
//   if (
//     typeof cancelTokenRD?.[action.payload.bodyData?.uniqueId] !=
//     typeof undefined
//   ) {
//     cancelTokenRD[action.payload.bodyData?.uniqueId].cancel(
//       "Operation canceled due to new request."
//     );
//   }
//   cancelTokenRD[action.payload.bodyData?.uniqueId] = axios.CancelToken.source();

//   try {
//     const res = yield call(
//       config.POST_WITH_HEADERS_BODY_CANCEL_TOKEN,
//       `${
//         ANALYTICS_URL.DASHBOARD_URL_CARDS
//       }`,
//       action.payload.bodyData,
//       { "x-access-token": action.payload.token },
//       cancelTokenRD[action.payload.bodyData?.uniqueId].token
//     );
    
//     yield put({
//       type: actionTypes.DASHBOARD_API_CARD_DATA_SUCCESS,
//       payload: res.data.data?.card_details,
//     });
//     yield put({
//       type: actionTypes.DASHBOARD_API_DATA_SUCCESS,
//       payload: {},
//     });
//   } catch (error) {
//     console.error(error);
//     yield put({
//       type: actionTypes.DASHBOARD_API_CARD_DATA_FAILURE,
//       payload: error,
//     });
//   }
// }
export function* getPerformanceTableDataAPIWorker(action: any): any {

  try {
    const res = yield call(
      config.POST_WITH_HEADERS_BODY_CANCEL_TOKEN,
      `${
        AGENT_DESKTOP_API.PERFORMANCE_TABLE_URL
      }`,
      action.payload.bodyData,
     
    );

    yield put({
      type: actionTypes.PERFORMANCE_TABLE_DATA_SUCCESS,
      payload: res.data.data,
     
    });
  
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.PERFORMANCE_TABLE_DATA_FAILURE,
      payload: error,
    });
  }
}