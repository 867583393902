import React, { useEffect } from "react";
import styles from "./SecurityComponent.module.scss";
// import { certificationIcon } from "../../../../theme/assets/profileSectionSvg";
import { PasswordValidation } from "../../../../components/generic/passwordValidation/PasswordValidation";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux";
import { getOrganizationAPIData } from "../../../../redux/profile/yourAccount/organizationDetails/actions/organizationDetails.actions";
import { updateSecurityDetails } from "../../../../redux/profile/settings/security/action/security.action";
import { DateToLocalString } from "../../../../utils/timeAndDate/time.util";

export default function SecurityComponent() {
  const dispatch = useDispatch();

  const name = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.personal.personalDetails?.data?.data
        ?.name
  );

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
  );

  const userId = useSelector(
    (state: RootState) => state.loginReducer?.userLoginInfo?.userDetail?._id
  );

  const updatedDate = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.personal.personalDetails?.data?.data
        ?.passwordChangedAt
  );

  const organizationInfo = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization.organizationDetails
  );

  const securityCertifications = organizationInfo?.securityCertificationsPath;

  const isLoadingSecurityDetails = useSelector(
    (store: RootState) =>
      store?.profile?.settingsReducer?.security?.isLoadingSecurityDetails
  );

  useEffect(() => {
    if (
      accountId &&
      (organizationInfo?.organizationName?.length === 0 ||
        organizationInfo?.gstNumber?.length === 0 || 
        Object.keys(organizationInfo).length === 0)
    ) {
      dispatch(getOrganizationAPIData(accountId));
    }
  }, [accountId]);

  function updatePassword(passwordInfo: {
    currentPassword: string;
    newPassword: string;
  }) {
    if (userId) {
      dispatch(
        updateSecurityDetails(
          userId,
          passwordInfo.currentPassword,
          passwordInfo.newPassword
        )
      );
      // dispatch(getPersonalDetails({ userId }));
    }
  }

  // console.log("updatedDate", updatedDate);

  return (
    <div className={styles.securityComponentWrapper}>
      <div className={styles.passwordComponentDiv}>
        <div className={styles.passwordComponentHeaderDiv}>
          <div className={styles.securityComponentHeader}> Password</div>
          <div className={styles.securityComponentInfo}>
            {updatedDate
              ? "(Last updated on " + DateToLocalString(updatedDate) + ")"
              : ""}
          </div>
        </div>
        <PasswordValidation
          userName={name}
          onSave={(updateP: any) => updatePassword(updateP)}
          isLoading={isLoadingSecurityDetails}
        />
      </div>
      <div className={styles.autoLogOutComponentDiv}>
        <div className={styles.securityComponentHeader}> Auto Logout</div>
        <div className={styles.securityComponentInfo}>
          Your account will logout automatically after 30 minutes of inactivity
        </div>
      </div>
      <div className={styles.certificationsComponentDiv}>
        <div className={styles.securityComponentHeader}>
          Security Certifications
        </div>

        <div className={styles.certificationsImagesDiv}>
          {securityCertifications?.map((data: any, index: number) => {
            return (
              <div className={styles.certificationDiv} key={index}>
                <img
                  className={styles.certificationImageDiv}
                  // src={`${data?.certificatePath}?${process.env.REACT_APP_ACCESS_TOKEN_USERUPLOADS}`}
                  src={`${data?.certificatePath}`}
                  alt="certificationIcon"
                />
                <p className={styles.certificationInfoDiv}>{data?.name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
