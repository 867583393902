// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t3Y1N3aSMdwHZjCx0rhG{display:flex;flex-direction:row;justify-content:space-evenly;align-items:center;padding:5px 6px;width:340px;height:34px;border:.5px solid #b6b6b6;border-radius:4px}.t3Y1N3aSMdwHZjCx0rhG .YEDJ0rorrCHaj3MqWegD{font-family:"Roboto";font-style:normal;font-weight:400;font-size:14px;line-height:18px;text-align:center;padding:2px;color:#676767;cursor:pointer}.t3Y1N3aSMdwHZjCx0rhG .YEDJ0rorrCHaj3MqWegD[data-selected=true]{background:#f6f6f6;border-radius:4px;color:#0174ff}`, "",{"version":3,"sources":["webpack://./src/components/generic/weekSelector/WeekSelector.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,4BAAA,CACA,kBAAA,CACA,eAAA,CAEA,WAAA,CACA,WAAA,CAEA,yBAAA,CACA,iBAAA,CACA,4CACE,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAGA,iBAAA,CACA,WAAA,CACA,aAAA,CAOA,cAAA,CANA,gEACE,kBAAA,CACA,iBAAA,CAEA,aAAA","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-evenly;\n  align-items: center;\n  padding: 5px 6px;\n\n  width: 340px;\n  height: 34px;\n\n  border: 0.5px solid #b6b6b6;\n  border-radius: 4px;\n  .eachWeek {\n    font-family: \"Roboto\";\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 18px;\n\n    /* identical to box height, or 129% */\n    text-align: center;\n    padding: 2px;\n    color: #676767;\n    &[data-selected=\"true\"] {\n      background: #f6f6f6;\n      border-radius: 4px;\n\n      color: #0174ff;\n    }\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `t3Y1N3aSMdwHZjCx0rhG`,
	"eachWeek": `YEDJ0rorrCHaj3MqWegD`
};
export default ___CSS_LOADER_EXPORT___;
