import { takeLatest } from "redux-saga/effects";
import actionTypes from "../pricingPlan.actionTypes";
import {
  getPricingPlanDataAPIWorker,
  updatePricingPlanDataAPIWorker,
  customPlanRequestAPIWorker,
  getCoupanPlanAPIWorker
} from "./pricingPlan.saga";

export default function* invoicesDetailsWatcher(): any {
  yield takeLatest(
    actionTypes.GET_PRICING_DATA_API_REQUEST,
    getPricingPlanDataAPIWorker
  );
  yield takeLatest(
    actionTypes.UPDATE_PRICING_DATA_API_REQUEST,
    updatePricingPlanDataAPIWorker
  );
  yield takeLatest(
    actionTypes.CUSTOM_PLAN_API_REQUEST,
    customPlanRequestAPIWorker
  );
  yield takeLatest(
    actionTypes.COUPAN_PLAN_API_REQUEST,
    getCoupanPlanAPIWorker
  );
 
}
