import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../redux";
import styles from "./TeamMembersTable.module.scss";
// import Icon from "../../../../../generic/icon/Icon";
import { TeamMemberRow } from "./teamMemberRow/TeamMemberRow";
// import {
//   teamsDropDownIcon,
//   teamsSortingIcon,
// } from "../../../../../../theme/assets/svg";
import { getTeamMemberData } from "../../../../../../redux/signUpFlow/teamsPage/actions";
// import { ToastContainer } from "react-toastify";
// import { template } from "lodash";

export const TeamMembersTable = () => {
  const [selectedTeam, setSelectedTeam] = useState<any[]>([]);

  const selectedTeamDetails = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.teamsPageReducer?.selectedTeamDetails
        ?.teamMembers
  );

  const teamId = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.teamsPageReducer?.selectedTeamDetails?.id
  );

  const teamMemberDetails = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.teamsPageReducer?.teamMemberDetails
  );

  const membersInvited = useSelector(
    (state: RootState) => state.signUpModuleReducer?.inviteUserReducer.userDetails.data
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedTeam(selectedTeamDetails);
    if (teamId) {
      dispatch(getTeamMemberData({ teamId }));
    }
  }, [selectedTeamDetails, membersInvited]);

  useEffect(() => {
    let tempArray;
    if (
      typeof teamMemberDetails === "object" &&
      !Array.isArray(teamMemberDetails) &&
      teamMemberDetails !== null
    )
      tempArray = [teamMemberDetails];
    else tempArray = teamMemberDetails;
    if (selectedTeamDetails) {
      const memberDetails: any[] = [...selectedTeamDetails, ...tempArray];
      setSelectedTeam(memberDetails);
    }
  }, [teamMemberDetails]);

  return (
    <>
      {/* {isLoadingMembersDetails ? (
        <div className={styles.teamsTableLoader}>
          <Loader />
        </div>
      ) : ( */}
      <div className={styles.teamMembersWrapper}>
        <div className={styles.teamMembersHeader}>
          <div className={styles.teamMembersHeaderColL}>
            <div className={styles.columnHeaderStyling}>Name</div>
            {/* <Icon img_src={teamsSortingIcon} extraClass={styles.iconStyling} /> */}
          </div>

          <div className={styles.teamMembersHeaderColL}>
            <div className={styles.columnHeaderStyling}>Email</div>
            {/* <Icon img_src={teamsSortingIcon} extraClass={styles.iconStyling} /> */}
          </div>

          <div className={styles.teamMembersHeaderColL}>
            <div className={styles.columnHeaderStyling}>Team Role</div>
            {/* <Icon img_src={teamsDropDownIcon} extraClass={styles.iconStyling} /> */}
          </div>

          <div className={styles.teamMembersHeaderColS}>
            <div className={styles.columnHeaderStyling}>Status</div>
            {/* <Icon img_src={teamsDropDownIcon} extraClass={styles.iconStyling} /> */}
          </div>
        </div>
        <hr className={styles.hrStyling} />
        <div className={styles.teamMembersStyling}>
          {selectedTeam?.length > 0 &&
            selectedTeam?.map((data: any, index: number) => {
              return (
                <div key={index}>
                  <TeamMemberRow
                    teamMemberData={data}
                    teamMemberIndex={index}
                  />
                </div>
              );
            })}
        </div>
      </div>
      {/* )} */}
    </>
  );
};
