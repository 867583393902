import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import styles from "./EmailScreen.module.scss";
import {
  // emailIntegration,
  closeIntegrationIcon,
} from "../../../../theme/assets/svg/integration_V2.0/index";
import { EmailTemplate } from "./emailTemplate/EmailTemplate";
import Icon from "../../../generic/icon/Icon";
import Modal from "../../../generic/modal/Modal";
import Button from "../../../generic/button/Button";
import InputContainer from "../../../generic/inputContainer/InputContainer";
import { EmailDetailsScreen } from "./emailDetailsScreen/EmailDetailsScreen";
import {
  maxLengthValidations,
  emailValidation,
} from "../../../../utils/Validation";
import {
  clearEmailData,
  getEmailDNSData,
  setEmailScreen,
} from "../../../../redux/integration_V2.0/email/actions";
import { clearIntegration } from "../../../../redux/integration_V2.0/allIntegrations/actions/allIntegrations.actions";
import { backIcon } from "../../../../theme/assets/svg/strategySvg";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";

interface EmailProps {
  shown?: boolean;
  close?: any;
  integrationName?: string;
  integrationData?: any;
}

export const EmailScreen = ({
  shown,
  close,
  integrationData,
  integrationName,
}: EmailProps) => {
  const dispatch = useDispatch();

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const emailDNSDataobj: any = useSelector(
    (store: RootState) => store?.integrationReducer?.emailReducer
  );

  const screenName = useSelector(
    (store: RootState) => store?.integrationReducer?.emailReducer?.currentScreen
  );

  const selectedIntegrationEmail = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.selectedIntegrationData
  );

  const orgEmail = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization.organizationDetails
        ?.organizationEmail
  );

  const orgIndex = orgEmail.indexOf("@");
  const orgDomain = orgEmail.substr(orgIndex + 1);

  const [isFocused, setIsFocused] = useState("");
  const [email, setEmail] = useState("");

  const handleCreateAccount = () => {
    dispatch(
      getEmailDNSData({
        accountId: accountId,
        integrationListId: selectedIntegrationEmail?.id,
        email: email,
      })
    );
  };

  // useEffect(() => {
  //   if (
  //     Object.keys(emailDNSDataobj?.emailsDNSData).length > 0 &&
  //     Object.keys(emailDNSDataobj?.emailsDNSData?.integrationDetails).length > 0
  //   ) {
  //     dispatch(setEmailScreen("emailTable"));
  //   }
  // }, [emailDNSDataobj?.emailsDNSData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value.replace(/[^a-zA-Z0-9@._-]/g, ""));
  };

  const handleCloseScreen = () => {
    dispatch(setEmailScreen(""));
    dispatch(clearEmailData());
    dispatch(clearIntegration());
    close();
  };

  useEffect(() => {
    setEmail(
      selectedIntegrationEmail?.integrationDetails?.fromEmail
        ? selectedIntegrationEmail?.integrationDetails?.fromEmail
        : ""
    );
    if (selectedIntegrationEmail?.integrationDetails?.fromEmail?.length > 0) {
      dispatch(setEmailScreen("emailTable"));
    }
  }, [selectedIntegrationEmail]);

  return (
    <Modal
      shown={shown}
      close={handleCloseScreen}
      extraClassStyleModalBackDrop={styles.integrationModalStyling}
      extraClassStyleModalContent={styles.integrationModalContentStyling}
    >
      <div className={styles.emailAppWrapper}>
        <div className={styles.emailAppHeaderDiv}>
          <div className={styles.emailAppLeftHeaderDiv}>
            {/* <Icon
              img_src={emailIntegration}
              extraClass={styles.headerIconStyling}
            /> */}
            <PravidIcons
              activeIcon={integrationData?.icon}
              extraClass={styles.headerIconStyling}
            />
            <div className={styles.headerTextStyling}>Email</div>
          </div>
          <div className={styles.emailRightHeaderDiv}>
            {screenName === "showTemplate" ? (
              <div className={styles.backIconStyling}>
                <Icon
                  img_src={backIcon}
                  extraClass={styles.iconBackStyling}
                  onClick={() => {
                    dispatch(setEmailScreen("emailTable"));
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <div className={styles.closeIconStyling}>
              <Icon
                img_src={closeIntegrationIcon}
                extraClass={styles.iconCloseStyling}
                onClick={handleCloseScreen}
              />
            </div>
          </div>
        </div>

        {screenName === "" ? (
          <>
            <div className={styles.subHeaderDiv}>
              {`You can use a custom email address to send email based nudges to your customers such as no-reply@${orgDomain}.`}
            </div>
            <div className={styles.contentDiv}>
              <div className={styles.subDiv}>
                {" "}
                <InputContainer
                  label="Email"
                  inputType="text"
                  inputPlaceholder="Email"
                  inputValue={email?.slice(0, 128)}
                  inputName="email"
                  inputOnChange={handleInputChange}
                  showLabel={true}
                  showNewToolTip
                  showNewToolTipPosition={"top-end"}
                  showNewToolTipMsg={
                    "This is the email address that will be used to send emails to your customers."
                  }
                  toolTipSize={false}
                  extraInputClass={`${styles.inputDivStyling}`}
                  extraLabelClass={styles.inputLabelStyling}
                  extraLabelDivClass={styles.labelDivStyling}
                  extraUserInputClass={`${styles.inputMainDivStyling}`}
                  errorMsg={
                    email.length > 0
                      ? !emailValidation(email).isValid
                        ? emailValidation(email).errors.message
                        : isFocused === "email"
                        ? !maxLengthValidations(email, "Email", 128).isValid
                          ? maxLengthValidations(email, "Email", 128).errors
                              .message
                          : ""
                        : ""
                      : ""
                  }
                  inputOnFocus={() => setIsFocused("email")}
                  inputOnBlur={() => setIsFocused("")}
                />
              </div>
              <div className={styles.subDiv}>
                <Button
                  text="Next"
                  extraClass={`${
                    emailValidation(email).isValid &&
                    emailDNSDataobj?.isLoadingemailsDNSData === false
                      ? styles.activeBtnStyling
                      : styles.inActiveBtnStyling
                  } ${styles.buttonStyling}`}
                  onClick={handleCreateAccount}
                />
              </div>
            </div>
          </>
        ) : screenName === "emailTable" ? (
          <EmailDetailsScreen
            onClickClose={handleCloseScreen}
            // emailOfSender={email}
            // emailData={selectedIntegrationEmail}
          />
        ) : screenName === "showTemplate" ? (
          <EmailTemplate />
        ) : (
          ""
        )}
      </div>
    </Modal>
  );
};
