import React, { useState, SetStateAction, useEffect, useRef } from "react";
import styles from "./KPIComponent.module.scss";
// import {
//   searchIcon,
//   openarrow,
//   closearrow,
//   crossblue,
//   tickblue,
// } from "../../../theme/assets/svg/rightSideIcon";
import { useDispatch, useSelector } from "react-redux";
//import { setSelectedFilterOptions } from "../../../redux/filters/actions";
import { setSelectedFilterType } from "../../../redux/filters/actions";
import { RootState } from "../../../redux";
import { setKpiEditedOption } from "../../../redux/filters/actions";
import { template } from "lodash";
import { useCurrentPageTab } from "../../../hooks";
import { getDataFromSchema } from "../../../utils/getDataFromSchema/getDataFromSchema";
import {
  directlyUpdateSchema,
  updateSchemaRequest,
} from "../../../redux/onboarding/login/actions";
import { toast, ToastContainer } from "react-toastify";
import PravidIcons from "../pravidIcons/PravidIcons";
import {
  validateInputMeta,
  validateKeys,
} from "../../../utils/validators/validators";
import { channelData, parentChannels, screenType } from "../../../constants";
import {
  cardInterface,
  chartInterface,
} from "../../../screens/analytics/analyticsScreen.types";

interface propsInterface {
  options: {
    options: cardInterface[];
    name: string;
    fieldName: "cards" | "charts";
  };
  disabled: boolean;
}

export default function KPIComponent(props: propsInterface) {
  const [closeDropDown, setCloseDropDown] = useState<boolean>(true);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<cardInterface[]>([]);
  const [selectedData, setSelectedData] = useState<cardInterface[]>([]);
  const selectedFilterType = useSelector(
    (state: RootState) => state.filterReducers?.selectedFilterType
  );
  const activeLoggerPage = useSelector(
    (store: RootState) => store.loggerReducer.loggerState.currentPage
  );
  // const [kpiDetails, setKpiDetails] = useState<any>({ charts: [], cards: [] });
  const [cards, setCards] = useState<Array<cardInterface>>([]);
  const [charts, setCharts] = useState<Array<chartInterface>>([]);
  const dispatch = useDispatch();
  const userId = useSelector(
    (state: RootState) => state.loginReducer.userLoginInfo?.userDetail?._id
  );

  function handleCloseOnDropdown() {
    selectedFilterType === props.options.name &&
      setCloseDropDown(!closeDropDown);
    dispatch(setSelectedFilterType(props?.options?.name));
  }
  const {
    schema,
    isActivePageType,
    subChannelTab,
    subModuleTab,
    channelTab,
    currentLoggerPage,
  } = useCurrentPageTab();
  useEffect(() => {
    if (
      subModuleTab.name &&
      channelTab.name &&
      currentLoggerPage &&
      isActivePageType &&
      schema &&
      Object.keys(schema).length &&
      (subChannelTab?.name || !parentChannels.includes(channelTab?.name))
    ) {
      setCards(
        getDataFromSchema({
          channel: channelTab,
          dataType: "cards",
          schemaData: schema,
          subChannel: subChannelTab,
          tab: subModuleTab,
          screen: isActivePageType,
          subScreen: activeLoggerPage,
        }) as cardInterface[]
      );
      setCharts(
        getDataFromSchema({
          channel: channelTab,
          dataType: "charts",
          schemaData: schema,
          subChannel: subChannelTab,
          tab: subModuleTab,
          screen: isActivePageType,
          subScreen: activeLoggerPage,
        }) as chartInterface[]
      );
    }
  }, [
    schema,
    subChannelTab?.name,
    subModuleTab?.name,
    channelTab?.name,
    currentLoggerPage,
    isActivePageType,
    activeLoggerPage,
  ]);
  //manange slelcte filter
  useEffect(() => {
    setFilterOptions(props?.options?.options);
  }, [props?.options?.options]);
  useEffect(() => {
    if (selectedFilterType === props.options.name) {
      if (closeDropDown) {
        setCloseDropDown(false);
      } else {
        setCloseDropDown(true);
      }
    } else {
      setCloseDropDown(true);
    }
  }, [selectedFilterType]);
  // const optionsDefaultSelected = useSelector(
  //   (state: RootState) =>
  //     state.filterReducers.selectedFilterOptions[props.options.name]
  // );
  const getChecked = (data: cardInterface, index: number) => {
    if (selectedData?.find((ex) => ex.kpiDetails._id === data.kpiDetails._id)) {
      const tempArr = [...selectedData];
      const indexr = tempArr.findIndex(
        (ex) => ex.kpiDetails._id === data.kpiDetails._id
      );
      if (indexr > -1) {
        tempArr.splice(indexr, 1);
      }
      setSelectedData([...tempArr]);
      // if (selectedData.length !== props.options.options.length) {
      //   setIsSelectAll(false);
      // } else {
      //   setIsSelectAll(true);
      // }
    } else {
      // if (selectedData.length !== props.options.options.length) {
      //   setIsSelectAll(false);
      // } else {
      //   setIsSelectAll(true);
      // }
      setSelectedData([...selectedData, data]);
    }
  };
  useEffect(() => {
    let currentSelected = 0;
    selectedData.forEach((e) => {
      if (filterOptions.includes(e)) {
        currentSelected++;
      }
    });
    setIsSelectAll(currentSelected === filterOptions.length);
  }, [selectedData, filterOptions]);
  const getSearchData = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!validateInputMeta(e.target.value)) return;
    // if(e.target.value.length<4 &&!(e.target.value.length===0)) return ;
    const searchTerm = e.target.value.toLowerCase();
    const searchedData: cardInterface[] = [];
    props.options.options.forEach((each) => {
      if (each.customName.toLowerCase().includes(searchTerm)) {
        searchedData.push(each);
      }
    });
    setFilterOptions(searchedData);
  };
  const getIsAllChecked = () => {
    if (isSelectAll) {
      // setIsSelectAll(false);
      const tempSelectedData = selectedData.filter((e) => {
        return !filterOptions.includes(e);
      });
      setSelectedData(tempSelectedData);
    } else {
      // setIsSelectAll(true);
      const temp = [...selectedData];
      filterOptions.forEach((e) => {
        if (!temp.includes(e)) {
          temp.push(e);
        }
      });
      setSelectedData(temp);
    }
  };
  function handleCloseButton() {
    setSelectedData([]);
    // setIsSelectAll(false);
    setCloseDropDown(true);
    dispatch(setKpiEditedOption({ type: props.options.name, options: [] }));
    toast.success("Please wait ...");
  }
  function handleTickButton() {
    setCloseDropDown(true);
    const op = props.options.options.map((e) => {
      if (selectedData.find((ex) => ex.kpiDetails._id === e.kpiDetails._id)) {
        const temp = selectedData.find(
          (ex) => ex.kpiDetails._id === e.kpiDetails._id
        )!;
        temp.isActive = true;
        return temp;
      } else {
        const temp = e;
        temp.isActive = false;
        return temp;
      }
    });
    // console.log(op,"OPTIONS",props.options.fieldName)
    const tempSchema = structuredClone(schema);
    //console.log(isActivePageType,currentLoggerPage,sourceTab,channelTab,subModuleTab,"TABS")
    /* uncomment this ------------ */
    if (props.options.fieldName === "cards") {
      (tempSchema.cards as cardInterface[]).forEach((item) => {
        if (
          item.screen === screenType.analytics.name.toLowerCase() &&
          (item.channelId === channelTab.id ||
            (channelTab.childChannel?.length &&
              item.channelId === subChannelTab.id)) &&
          item.tabId === subModuleTab.id
        ) {
          const element = op.find(
            (elm) => elm.kpiDetails._id === item.kpiDetails._id
          )!;
          item.isActive = element.isActive;
        }
      });
      dispatch(
        updateSchemaRequest({
          id: tempSchema._id,
          teamId: tempSchema.teamId,
          type: "user",
          cards: tempSchema.cards,
        })
      );
    } else if (props.options.fieldName === "charts") {
      (tempSchema.charts as chartInterface[]).forEach((item) => {
        if (
          item.screen === screenType.analytics.name.toLowerCase() &&
          (item.channelId === channelTab.id ||
            (channelTab.childChannel?.length &&
              item.channelId === subChannelTab.id)) &&
          item.tabId === subModuleTab.id
        ) {
          const element = (op as never as chartInterface[]).find(
            (elm) => elm.kpiDetails._id === item.kpiDetails._id
          )!;
          console.log(item);
          item.isActive = element.isActive;
        }
      });
      dispatch(
        updateSchemaRequest({
          id: tempSchema._id,
          teamId: tempSchema.teamId,
          type: "user",
          charts: tempSchema.charts,
        })
      );
    } else throw new Error("Not a chart or cart");

    // if (channelTab === "Call") {
    //   tempSchema["analytics"][sourceTab][channelTab][
    //     !subModuleTab ? "Campaign" : subModuleTab
    //   ]["kpiDetails"][props.options.fieldName] = op;
    // } else {
    //   tempSchema["analytics"][sourceTab][channelTab][whatsappChannelTab][
    //     !subModuleTab ? "Campaign" : subModuleTab
    //   ]["kpiDetails"][props.options.fieldName] = op;
    // }
    // console.log(tempSchema,"MAKING--- TEMPSCHEMA--KPI")
    dispatch(directlyUpdateSchema(tempSchema));
    /* uncomment this  */

    // dispatch(
    //   setKpiEditedOption({
    //     type: props.options.name,
    //     options: op,
    //   })
    // );
    toast.success("Please wait ...");
  }
  function handleClickOutsideButtons(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    e.stopPropagation();
    setCloseDropDown(false);
  }
  const inputRef = useRef<any>();
  useEffect(() => {
    setFilterOptions(props.options.options || []);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  }, [closeDropDown]);
  useEffect(() => {
    const defSelected = props.options.options?.filter((e) => e.isActive);
    setSelectedData(defSelected);
    // if (optionsDefaultSelected !== undefined){//
    // }
    //setSelectedData(optionsDefaultSelected);
  }, [props.options.options]);

  return (
    <>
      {props.options?.options.length > 0 && (
        <div
          className={`${styles.filterwrappers}  ${
            props.disabled ? styles.disablethisfilter : " "
          }`}
        >
          <div className={styles.topdivof} onClick={handleCloseOnDropdown}>
            {/* <img src={!closeDropDown ? openarrow : closearrow}></img> */}
            {!closeDropDown ? (
              <PravidIcons activeIcon={"openarrow"} />
            ) : (
              <PravidIcons activeIcon={"closearrow"} />
            )}
            <p
              className={
                closeDropDown
                  ? styles.closedropdowntext
                  : styles.opendropdowntext
              }
            >
              {props.options.name}
            </p>
          </div>

          <div
            className={
              closeDropDown ? styles.closeevrything : styles.openevrything
            }
            id={"auto_filter_" + props.options.name.replace(/\s/g, "")}
          >
            <div className={styles.horozontalrules} />
            {props.options?.options?.length > 7 && (
              <div className={styles.inputdiv}>
                {/* <img src={searchIcon} alt="" /> */}
                <PravidIcons activeIcon={"searchIcon"} />
                <input
                  onChange={getSearchData}
                  className={styles.searchinput}
                  type="text"
                  placeholder="Search"
                  ref={inputRef}
                  onKeyDown={validateKeys}
                />
              </div>
            )}

            {
              <>
                {props?.options?.options.length > 0 && (
                  <div
                    className={
                      styles.colorgrey +
                      " " +
                      styles.margibttm +
                      " " +
                      (isSelectAll ? styles.colorItem : "")
                    }
                  >
                    <input
                      type="checkbox"
                      checked={isSelectAll ? true : false}
                      onChange={getIsAllChecked}
                      className={styles.checkboxcusror}
                    />
                    Select All
                  </div>
                )}
                <div className={styles.horozontalrules} />
              </>
            }
            <div className={styles.wrapperforoptiosn}>
              {filterOptions?.map((each, i: number) => {
                return (
                  <div className={styles.bodydata} key={i}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => getChecked(each, i)}
                      className={
                        selectedData?.includes(each)
                          ? styles.colorItem
                          : "" + " " + styles.colorgrey
                      }
                    >
                      <input
                        type="checkbox"
                        checked={selectedData?.includes(each) ? true : false}
                        onChange={() => getChecked(each, i)}
                        className={styles.checkboxofoptions}
                      />
                      {props.options.fieldName === "cards"
                        ? each.customName
                        : (each as never as chartInterface).customName}
                    </div>
                  </div>
                );
              })}
            </div>
            {props?.options?.options?.length > 0 && (
              <>
                <div className={styles.divcontainingbtn}>
                  {" "}
                  <button
                    className={styles.btnsforcloseopen}
                    onClick={() => {
                      handleCloseButton();
                    }}
                    id={props.options.name + "cancel"}
                  >
                    <PravidIcons activeIcon={"crossblue"} />
                  </button>{" "}
                  <button
                    onClick={() => {
                      handleTickButton();
                    }}
                    className={styles.btnsforcloseopen}
                    id={props.options.name + "ok"}
                  >
                    <PravidIcons activeIcon={"tickblue"} />
                  </button>{" "}
                </div>
              </>
            )}
          </div>

          {closeDropDown && selectedData.length > 0 && (
            <>
              <div className={styles.optionsoutside}>
                {selectedData.map((e, i: number) => {
                  if (i < 5) {
                    return (
                      <div
                        onClick={(e) => {
                          handleClickOutsideButtons(e);
                        }}
                        className={`${
                          selectedData[i].kpiDetails.kpiCustomizationName
                            ?.length > 8
                            ? styles.eachoptionoutsideCollapseDiv
                            : styles.eachoptionoutside
                        }`}
                        key={i}
                      >
                        {props.options.fieldName === "cards"
                          ? selectedData[i].customName
                          : (selectedData[i] as never as chartInterface)
                              .customName}
                      </div>
                    );
                  } else if (i === 5) {
                    return (
                      <div
                        onClick={(e) => {
                          handleClickOutsideButtons(e);
                        }}
                        className={styles.eachoptionoutside}
                        key={i}
                      >
                        {"+" + (selectedData.length - 5)}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </>
          )}
          {/* <ToastContainer position="top-center" /> */}
        </div>
      )}
      {/* <div className={styles.divide}></div> */}
    </>
  );
}
