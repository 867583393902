import React, { useEffect, useState } from "react";
import RightSideBarComp from "./RightSidebarComp";
// import {
//   //   arrow,
//   calenderIcon,
//   blueCalender,
//   downloadIcon,
//   blueRefresh,
//   blueDownload,
//   blueColumnCustomize,
//   filterIcon,
//   refreshIcon,
//   columnCustomizeIcon,
//   kpiIcon,
//   blueFilter,
//   blueKpi,

// } from "../../../theme/assets/svg/rightSideIcon";
import { ToastContainer } from "react-toastify";
import { useCurrentPageTab, useFetchFromSchema } from "../../../hooks";
import { getDataFromSchema } from "../../../utils";
import { getSideBarModules } from "../../../utils/genericFunctions";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { useDispatch } from "react-redux";
import { setSelectedIcon } from "../../../redux/filters/actions/actionCreators";

export default function RightSideBar() {
  const iconList = [
    { name: "Calendar", firstIcon: "calenderIcon", secondIcon: "blueCalender" },
    {
      name: "Last Updated",
      firstIcon: "refreshIcon",
      secondIcon: "blueRefresh",
    },
    { name: "Filter", firstIcon: "filterIcon", secondIcon: "blueFilter" },
    {
      name: "Download",
      firstIcon: "downloadIconGray",
      secondIcon: "blueDownload",
    },
    { name: "KPI customisation", firstIcon: "kpiIcon", secondIcon: "blueKpi" },
    {
      name: "Shuffle Column",
      firstIcon: "columnCustomizeIcon",
      secondIcon: "blueColumnCustomize",
    },
  ];
  // const whereWeAre = {
  //   module: "Logger",
  //   source: "Debt Collection",
  //   channel: "WhatsApp",
  //   subchannel: "oneWay",
  //   submodule: "Campaign",
  //   reportOrTranscript: "transcript",
  // };
  const sidebarModuleData = useSelector(
    (store: RootState) => store.loginReducer.sidebarModules.data
  );
  const activeLoggerPage = useSelector(
    (store: RootState) => store.loggerReducer.loggerState.currentPage
  );
  const dispatch = useDispatch();
  const {
    schema,
    isActivePageType,
    subChannelTab,
    subModuleTab,
    channelTab,
    currentLoggerPage,
  } = useCurrentPageTab();

  const [allData, setAllData] = useState<Record<string, any>[]>([]);
  const [allData2, setAllData2] = useState<any>();
  // const [allData2,setAllData2]=useState<any>([]);
  useEffect(() => {
    if (sidebarModuleData?.length && activeLoggerPage && isActivePageType) {
      setAllData(
        getSideBarModules(sidebarModuleData, isActivePageType, activeLoggerPage)
      );
    }
  }, [sidebarModuleData, isActivePageType, activeLoggerPage]);

  useEffect(() => {
    dispatch(setSelectedIcon(""));
  }, []);

  //  let  allData= finderForRightSide(whereWeAre,schema).sidebarModules;
  // const allData = useFetchFromSchema().sidebarModul'es;
  // iconList=  iconList.filter((e:any)=>{
  //   let res=false;
  //      for(let i=0;i<allData.length;i++){
  //       if(e.name===allData[i].name) res=true;
  //      }
  //      return res;
  // //   })
  useEffect(() => {
    let allData2: Array<any> = structuredClone(allData);
    allData2 = allData2?.map((e: any) => {
      e["firstIcon"] = iconList.find((eh) => eh.name === e.name)?.firstIcon;
      e["secondIcon"] = iconList.find((eh) => eh.name === e.name)?.secondIcon;
      return e;
      //
    });
    setAllData2(allData2);
  }, [allData]);

  // useEffect(()=>{
  //   let allData3: Array<any> = JSON.parse(JSON.stringify(allData));
  // allData3 = allData3.map((e: any) => {
  //   e["firstIcon"] = iconList.find((eh) => eh.name === e.name)?.firstIcon;
  //   return e;
  //   //
  // });
  // setAllData2(allData3);
  // },[allData])

  return (
    <>
      {allData2?.length ? <RightSideBarComp icons={allData2} /> : null}
      {/* <ToastContainer
        position="top-center"
        // type="success"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={true}
      /> */}
    </>
  );
}
