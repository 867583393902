import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { INTEGRATION_API, config } from "../../../../services/ApiRoutes";
import actionTypes from "../trueCaller.actionTypes";
import integrationActionTypes from "../../allIntegrations/allIntegrations.actionTypes";
import { store } from "../../../rootStore";

export function* getAllCategoriesDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_ALL_CATEGORIES}`
      );

      yield put({
        type: actionTypes.GET_ALL_CATEGORIES_SUCCESS,
        payload: result?.data.data,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ALL_CATEGORIES_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}

export function* createTrueCallerAPIWorker(action: any): any {
  const picture = action.payload.file;
  const ress = action.payload;
  const { file, id, ...createPayload } = ress;
  try {
    if (action) {
      const formData = new FormData();
      formData.append("file", picture);
      formData.append("destination", "images");
      const picResult = yield call(
        config.PUT_WITH_PARAMS,
        INTEGRATION_API.UPLOAD_PIC_TRUECALLER +
          action.payload.id +
          "/" +
          action.payload.integrationName,
        formData
      );
      const result = yield call(
        config.POST_WITH_BODY,
        `${INTEGRATION_API.CREATE_INTEGRATION}`,
        createPayload
      );
      const getResult = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_ALL_INTEGRATIONS}`,
        {
          accountId: action.payload.id,
        }
      );
      yield put({
        type: actionTypes.UPDATE_TRUECALLER_PROFILE_PIC_SUCCESS,
        payload: picResult?.data.data?.integrationDetails?.profilePicPath,
      });
      yield put({
        type: integrationActionTypes.CREATE_INTEGRATION_DATA_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: integrationActionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS,
        payload: getResult?.data.data,
      });
      const apiResponse =
        store.getState().integrationReducer?.allIntegrationsReducer
          ?.selectedIntegrationData;
      const filteredData: any = getResult?.data.data?.filter(
        (item: any) => item.integrationName === apiResponse?.integrationName
      );
      yield put({
        type: integrationActionTypes.SET_SELECTED_INTEGRATION_DATA,
        payload: {
          ...filteredData[0],
        },
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_TRUECALLER_PROFILE_PIC_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}

export function* updateTrueCallerAPIWorker(action: any): any {
  const picture = action.payload.file;
  const ress = action.payload;
  const { file, id, ...createPayload } = ress;
  try {
    if (action) {
      const formData = new FormData();
      formData.append("file", picture);
      formData.append("destination", "images");
      const picResult = yield call(
        config.PUT_WITH_PARAMS,
        INTEGRATION_API.UPLOAD_PIC_TRUECALLER +
          action.payload.id +
          "/" +
          action.payload.integrationName,
        formData
      );
      const result = yield call(
        config.POST_WITH_BODY,
        `${INTEGRATION_API.UPDATE_INTEGRATION}`,
        createPayload
      );
      const getResult = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_ALL_INTEGRATIONS}`,
        {
          accountId: action.payload.id,
        }
      );
      yield put({
        type: actionTypes.UPDATE_TRUECALLER_PROFILE_PIC_SUCCESS,
        payload: picResult?.data.data?.integrationDetails?.profilePicPath,
      });
      yield put({
        type: integrationActionTypes.UPDATE_INTEGRATION_DATA_REQUEST,
        payload: result.data.data,
      });
      yield put({
        type: integrationActionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS,
        payload: getResult?.data.data,
      });
      const apiResponse =
        store.getState().integrationReducer?.allIntegrationsReducer
          ?.selectedIntegrationData;
      const filteredData: any = getResult?.data.data?.filter(
        (item: any) => item.integrationName === apiResponse?.integrationName
      );
      yield put({
        type: integrationActionTypes.SET_SELECTED_INTEGRATION_DATA,
        payload: {
          ...filteredData[0],
        },
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_TRUECALLER_PROFILE_PIC_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}

export function* uploadTrueCallerProfilePicAPIWorker(action: any): any {
  const picture = action.payload.file;
  try {
    if (action) {
      const formData = new FormData();
      formData.append("file", picture);
      formData.append("destination", "images");
      const result = yield call(
        config.PUT_WITH_PARAMS,
        INTEGRATION_API.UPLOAD_PIC_TRUECALLER +
          action.payload.id +
          "/" +
          action.payload.integrationName,
        formData
      );
      const getResult = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_ALL_INTEGRATIONS}`,
        {
          accountId: action.payload.id,
        }
      );
      yield put({
        type: actionTypes.UPDATE_TRUECALLER_PROFILE_PIC_SUCCESS,
        payload: result?.data.data?.integrationDetails?.profilePicPath,
      });
      yield put({
        type: integrationActionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS,
        payload: getResult?.data.data,
      });
      yield put({
        type: integrationActionTypes.SET_SELECTED_INTEGRATION_DATA,
        payload: result?.data.data,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_TRUECALLER_PROFILE_PIC_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}

export function* deleteTrueCallerProfilePicAPIWorker(action: any): any {
  try {
    const response = yield call(
      config.DELETE,
      INTEGRATION_API.DELETE_PIC_TRUECALLER +
        action.payload.id +
        "/" +
        action.payload.integrationName
    );
    const getResult = yield call(
      config.GET_WITH_PARAMS,
      `${INTEGRATION_API.GET_ALL_INTEGRATIONS}`,
      {
        accountId: action.payload.id,
      }
    );
    yield put({
      type: actionTypes.DELETE_TRUECALLER_PROFILE_PIC_SUCCESS,
      payload: response?.data.data?.integrationDetails?.profilePicPath,
    });
    yield put({
      type: integrationActionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS,
      payload: getResult?.data.data,
    });
    const picResponse =
      store.getState().integrationReducer?.allIntegrationsReducer
        ?.selectedIntegrationData;
    yield put({
      type: integrationActionTypes.SET_SELECTED_INTEGRATION_DATA,
      payload: {
        ...picResponse,
        integrationDetails: {
          ...picResponse.integrationDetails,
          profilePicPath: "",
        },
      },
    });
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_TRUECALLER_PROFILE_PIC_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}
