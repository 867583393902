export const integrationCardData = {
  category: ["Custom"],
  integrationName: "Custom Integrations API",
  isRecommended: true,
  integrationDescription:
    "Pravid APIs for you to send payments & allocation data.",
  position: 1,
  icon: "customIntegrationAPI",
  id: "644669d23f6f4768e74127e1",
};
export const integrationCategoryData = {};
