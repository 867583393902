import {
  config,
  CAMPAIGN_TESTING_URL,
  CAMPAIGN_URL,
  STRATEGY_API
} from "../../../services/ApiRoutes";
import { put, call } from "redux-saga/effects";
import actionTypes from "../../campaignTestingInfo/actionTypes/campaignTestingInfo.actionTypes";
import { RootState, store } from "../../rootStore";
import { USECASE } from "../../../constants";

export function* getCampaignTestingSchema(action: any): any {
  const useCaseId = (store.getState() as RootState).loginReducer.currentTeam
    .useCaseId;
  const showLanguageByFlow: Record<string, any[]> = {};
  console.log("useCase id", useCaseId);
  try {
    const apiResponse = yield call(
      config.GET_WITH_PARAMS,
      `${CAMPAIGN_TESTING_URL.GET_SCHEMA}`,
      { clientName: action.payload.clientName, useCaseId: useCaseId }
    );

    // maia Testing
    let agentDetailResponse;
    if (
      (action.payload.clientName?.includes("Maia") ||
        action.payload.clientName === "Demo Testing" ||
        action.payload.clientName?.includes("SmartCoin") ||
        action.payload.clientName?.includes("Sphot") ||
        action.payload.clientName?.includes("Saarthi Demo") ||
        action.payload.agentIdDropdown === true) &&
      useCaseId === USECASE.debtCollection.id
    ) {
      agentDetailResponse = yield call(
        config.GET_WITH_PARAMS,
        `${CAMPAIGN_URL.GET_AGENT_CONFIGURATION}`,
        { clientName: action.payload.clientName }
      );
      console.log("hhh" , agentDetailResponse?.data?.data)
    } else if (
      action.payload.clientName?.includes("TVS Credit") ||
      action.payload.clientName?.includes("Bajaj Finance Nach") ||
      action.payload.clientName?.includes("Fullerton") ||
      action.payload.clientName?.includes(
        "Smartcoin Lead Generation Testing"
      ) ||
      useCaseId === USECASE.salesAndOnboarding.id ||
      useCaseId === USECASE.vehicleService.id ||
      useCaseId === USECASE.political.id ||
      useCaseId === USECASE.loanDisbursal.id ||
      action.payload.agentIdDropdown === true
    ) {
      agentDetailResponse = yield call(
        config.GET_WITH_PARAMS,
        `${CAMPAIGN_URL.SALES_ONBOARDING_AGENT_CONFIG}`,
        { clientName: action.payload.clientName }
      );
    }
  
    /* showFields & showCategories */
    const {
      defaultInputFieldData,
      backEndKey,
      saveKey,
      dropDownCallMapping,
      dropDownWhatsAppMapping,
      nameToBackEndKeyMapping,
      showFieldsByFlow,
      showCategoriesByFlow,
      showFieldsByDisposition,
      showCategoriesByDisposition,
      isRequiredObject,
      uploadDataKey,
      makeValueObject,
    } = getDataFromCampaignTestingSchema(
      apiResponse?.data?.data?.[0]?.schema,
      apiResponse?.data?.data?.[0]?.showFieldsByFlow,
      apiResponse?.data?.data?.[0]?.showCategoriesByFlow
    );
    const telephonyData = replaceUnderScoreWithDot(
      apiResponse.data?.data?.[0]?.telephonyData
    );
    /* showLanguageByFlow */
    if (apiResponse.data?.data?.[0]?.showLanguagesByFlow) {
      Object.keys(
        apiResponse.data?.data?.[0]?.showLanguagesByFlow.call
      )?.forEach((item: any) => {
        showLanguageByFlow[item] =
          apiResponse.data?.data?.[0]?.showLanguagesByFlow.call[item];
      });

      Object.keys(
        apiResponse.data?.data?.[0]?.showLanguagesByFlow.whatsApp
      )?.forEach((item: any) => {
        if (showLanguageByFlow[item]) {
          showLanguageByFlow[item] = Array.from(
            new Set([
              ...showLanguageByFlow[item],
              ...(apiResponse.data?.data?.[0]?.showLanguagesByFlow.whatsApp[
                item
              ] as Array<any>),
            ])
          );
        } else {
          showLanguageByFlow[item] =
            apiResponse.data?.data?.[0]?.showLanguagesByFlow.whatsApp[item];
        }
      });
    }
    // setting telephonyData
    yield put({
      type: actionTypes.CAMPAIGN_TESTING_GET_SCHEMA_API_SUCCESS,
      payload: {
        defaultInputFieldData,
        backEndKey,
        saveKey,
        isRequiredObject,
        dropDownCallMapping,
        dropDownWhatsAppMapping,
        nameToBackEndKeyMapping,
        showFieldsByFlow,
        showCategoriesByFlow,
        showFieldsByDisposition,
        showCategoriesByDisposition,
        telephonyData,
        uploadDataKey,
        createNestedObjectCalling:
          apiResponse.data.data[0]?.createNestedObjectCalling,
        showLanguageByFlow: showLanguageByFlow,
        schema: apiResponse.data?.data?.[0]?.schema,
        makeValueObject,
        whatsAppToBackendKeyMapping:
          apiResponse.data?.data?.[0]?.whatsAppToBackendKeyMapping,
        showFieldsByFlowFromSchema: apiResponse?.data?.data?.[0]
          ?.showFieldsByFlow
          ? true
          : false,
        agentsConfigData:
          typeof agentDetailResponse?.data?.data === "string"
            ? []
            : agentDetailResponse?.data?.data || [],
      },
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.CAMPAIGN_TESTING_GET_SCHEMA_API_FAILURE,
      payload: error,
    });
  }
}

export function* updateSchemaByAgent(action: any): any {
  const showFieldsByFlowFromSchema = (store.getState() as RootState)
    .campaignTestingInfoReducer.schemaDataFiltered.showFieldsByFlowFromSchema;
  const showLanguageByFlow: Record<string, string[]> = {};
  let accountName =
    store.getState().loginReducer.userLoginInfo.userDetail.accountDetails[0]
      .name;
  const selectedAccountName =
    store.getState().filterReducers?.selectedFilterOptions?.clientName?.[0]
      ?.name;
  if (selectedAccountName) {
    accountName = selectedAccountName;
  }
  const useCaseId = store.getState().loginReducer.currentTeam.useCaseId;
  // const showFieldsByFlow = store.getState().campaignTestingInfoReducer.
  const schema = action.payload.schema;
  const agentData = action.payload.agentsConfig.find(
    (item: any) => item.agentId === action.payload.selectedAgent
  );
  console.log("data" , schema , agentData)
  // const { flowType, language } = agentData;
  let flowType: any;
  let language: any;
  // for debt with agent
  if (agentData?.Call?.flowTypes || agentData?.Whatsapp?.flowTypes) {
    flowType = [
      ...(agentData?.Call?.flowTypes || []),
      ...(agentData?.Whatsapp?.flowTypes || []),
    ];
    /* dynamic languages ---------------- */
    if (agentData?.Call?.flowTypes) {
      agentData.Call.flowTypes?.forEach((flow: any) => {
        showLanguageByFlow[flow.value] = flow.languages;
      });
    }

    if (agentData?.Whatsapp?.flowTypes) {
      agentData.Whatsapp.flowTypes?.forEach((flow: any) => {
        if (showLanguageByFlow[flow.value]) {
          showLanguageByFlow[flow.value] = [
            ...new Set([...showLanguageByFlow[flow.value], ...flow.languages]),
          ];
        } else {
          showLanguageByFlow[flow.value] = flow.languages;
        }
      });
    }
    /* dynamic languages --------------- */
  }
  // sales and onboarding with agent
  else if (agentData?.parentFlow) {
    const allFields = getAllSchemaFields(schema);
    flowType = [];
    agentData.parentFlow?.forEach((item: any) => {
      if (item?.isParent) {
        flowType.push({
          displayValue: item?.flow,
          value: item?.flow,
          showFields: allFields,
        });
      }
    });
    agentData.parentFlow?.forEach((item: any) => {
      if (item?.isParent) showLanguageByFlow[item.flow] = item.language;
    });
  }
  // else if(agentData?.flows?.length >0){
  //  /** New agent for strategy - abhishek */
  //  const allFields = getAllSchemaFields(schema);
  //  flowType = [];
  //  agentData?.flows?.forEach((flow: any) => {
  //      flowType.push({
  //        displayValue: flow,
  //        value: flow,
  //        showFields: allFields,
  //      });
     
  //  });
  //  /** dynamic languages ***/
  //  agentData?.flows?.forEach((flow: any) => {
  //     showLanguageByFlow[flow] = agentData?.languages;
  //  });
  //  console.log("hello" , flowType , showLanguageByFlow)

  // }
  else if (agentData) {
    flowType = agentData.flowType;
    // language = agentData.language;
  } else {
    return;
  }

  const agentName = {
    maleAgentName: agentData?.maleAgentName,
    femaleAgentName: agentData?.femaleAgentName,
  };
  let bankNameKey = "";
  schema?.forEach((category: any) => {
    category.data?.forEach((field: any) => {
      if (field.name === "Flow") {
        field.options = flowType;
      } else if (field.name === "Language" && language?.length > 0) {
        field.options = language;
      } else if (field.name === "Bank Name") {
        bankNameKey = field.BackendKey;
      }
    });
  });

  const defaultInputFieldData: Record<string, any> = {};
  const extraDropDownCallMapping: Record<string, any> = {};
  const extraDropDownWhatsAppMapping: Record<string, any> = {};
  const showFieldsByFlow: Record<string, any> = {};
  const showCategoriesByFlow: Record<string, any> = {};
  const showFieldsByDisposition: Record<string, string[]> = {};
  const showCategoriesByDisposition: Record<string, string[]> = {};
  const allFieldsArr: string[] = [];
  const allFieldsArrDisposition: string[] = [];

  schema.forEach((dataRow: any) => {
    dataRow.data.forEach((inputField: Record<string, any>) => {
      defaultInputFieldData[inputField.BackendKey] = inputField.value;
      allFieldsArr.push(inputField.name);
      // show fields by flow
      if (inputField.name === "Flow") {
        inputField.options.forEach((option: any) => {
          showFieldsByFlow[option.displayValue] = option.showFields;
          showCategoriesByFlow[option.displayValue] = option.showCategories;
        });
      }
      if (inputField.name === "Disposition") {
        inputField.options.forEach((option: any) => {
          showFieldsByDisposition[option.displayValue] = option.showFields;
          showCategoriesByDisposition[option.displayValue] =
            option.showCategories;
        });
      }
      // display vs backend value mapping
      if (inputField.name === "Flow" || inputField.name === "Language") {
        const mappingCall: Record<string, string> = {};
        const mappingWhatsapp: Record<string, string> = {};
        inputField.options.forEach((option: any) => {
          mappingCall[option.displayValue] = option.value;
          mappingWhatsapp[option.displayValue] = option.whatsAppValue;
        });
        extraDropDownCallMapping[inputField.BackendKey] = mappingCall;
        extraDropDownWhatsAppMapping[inputField.BackendKey] = mappingWhatsapp;
      }
    });
  });
  defaultInputFieldData[bankNameKey] = agentData.bankName;
  showFieldsByFlow[""] = allFieldsArr;
  showFieldsByDisposition[""] = allFieldsArrDisposition;
  const customerData =
    action.payload.customerData?.length > 0
      ? action.payload.customerData
      : [{ ...defaultInputFieldData, isActive: true }];
  const newCustomerData = updateCustomerDataByAgent(
    customerData,
    action.payload.nameToBackEndKeyMapping,
    agentData,
    accountName,
    useCaseId
  );
  // add client name here if show fields need to be taken from schema.
  if (
    accountName.includes("Fullerton") ||
    accountName.includes("SmartCoin") ||
    accountName.includes("DBS Mintek Testing") ||
    accountName === "Bajaj Finserv Counselling Flow Testing" ||
    accountName === "Axio Testing" ||
    accountName.includes("TVS Credit Collections") ||
    accountName.includes("Shriram Housing") ||
    accountName.includes("Piramal Finance") ||
    accountName.includes("PayTM") ||
    accountName.includes("Stellantis") ||
    showFieldsByFlowFromSchema
  ) {
    yield put({
      type: actionTypes.UPDATE_DATA_BY_AGENT_CAMPAIGN_TESTING,
      payload: {
        schema,
        agentName,
        defaultInputFieldData,
        extraDropDownCallMapping,
        extraDropDownWhatsAppMapping,
        showFieldsByDisposition,
        showCategoriesByDisposition,
        showLanguageByFlow,
        customerData: newCustomerData,
      },
    });
  } else {
    yield put({
      type: actionTypes.UPDATE_DATA_BY_AGENT_CAMPAIGN_TESTING,
      payload: {
        schema,
        agentName,
        defaultInputFieldData,
        extraDropDownCallMapping,
        extraDropDownWhatsAppMapping,
        showFieldsByFlow,
        showCategoriesByFlow,
        showFieldsByDisposition,
        showCategoriesByDisposition,
        showLanguageByFlow,
        customerData: newCustomerData,
      },
    });
  }
}

export function* modifyCustomerDataDemo(action: any): any {
  const useCaseId = store.getState().loginReducer.currentTeam.useCaseId;
  let accountName =
    store.getState().loginReducer.userLoginInfo.userDetail?.accountDetails?.[0]
      ?.name;
  const selectedAccountName =
    store.getState().filterReducers?.selectedFilterOptions?.clientName?.[0]
      ?.name;
  if (selectedAccountName) {
    accountName = selectedAccountName;
  }
  const agentData = action.payload.agentsConfigData.find(
    (item: any) => item.agentId === action.payload.selectedAgent
  );
  const newCustomerData = updateCustomerDataByAgent(
    action.payload.customersData,
    action.payload.nameToBackEndKeyMapping,
    agentData,
    accountName,
    useCaseId
  );
  yield put({
    type: actionTypes.ADD_FETCHED_CUSTOMERS,
    payload: { data: newCustomerData },
  });
}

/* helper functions */
const getDataFromCampaignTestingSchema = (
  schema: Record<string, any>,
  showFieldsByFlowIn: Record<string, any>,
  showCategoriesByFlowIn: Record<string, any>
) => {
  const defaultInputFieldData: Record<string, any> = {};
  const backEndKey: string[] = [];
  const saveKey: string[] = [];
  const uploadDataKey: string[] = [];
  const dropDownCallMapping: Record<string, any> = {};
  const dropDownWhatsAppMapping: Record<string, any> = {};
  const nameToBackEndKeyMapping: Record<string, string> = {};
  const showFieldsByFlow: Record<string, any> = {};
  const showFieldsByDisposition: Record<string, string[]> = {};
  const showCategoriesByFlow: Record<string, any> = {};
  const showCategoriesByDisposition: Record<string, string[]> = {};
  const isRequiredObject: Record<string, any> = {};
  const allFieldsArr: string[] = [];
  const allFieldsArrDisposition: string[] = [];
  const makeValueObject: Record<string, boolean> = {};

  schema?.forEach((dataRow: any) => {
    if (dataRow.isVisible === false) return;
    dataRow.data.forEach((inputField: Record<string, any>) => {
      defaultInputFieldData[inputField.BackendKey] = inputField.value;
      backEndKey.push(inputField.BackendKey);
      saveKey.push(inputField.savekey);
      uploadDataKey.push(inputField.uploadDataKey);
      allFieldsArr.push(inputField.name);
      nameToBackEndKeyMapping[inputField.name] = inputField.BackendKey;
      if (inputField.isVisible)
        isRequiredObject[inputField.BackendKey] = inputField.isRequire;
      makeValueObject[inputField.BackendKey] = inputField.makeValueObject;
      // show fields by flow
      if (inputField.name === "Flow") {
        if (!showCategoriesByFlowIn) {
          inputField.options.forEach((option: any) => {
            showCategoriesByFlow[option.displayValue] = option.showCategories;
          });
        } else {
          Object.keys(showCategoriesByFlowIn.call).forEach((item: any) => {
            showCategoriesByFlow[item] = showCategoriesByFlowIn.call[item];
          });

          Object.keys(showCategoriesByFlowIn.whatsApp).forEach((item: any) => {
            if (showCategoriesByFlow[item]) {
              showCategoriesByFlow[item] = Array.from(
                new Set([
                  ...showCategoriesByFlow[item],
                  ...(showCategoriesByFlowIn.whatsApp[item] as Array<any>),
                ])
              );
            } else {
              showCategoriesByFlow[item] =
                showCategoriesByFlowIn.whatsApp[item];
            }
          });
        }

        if (!showFieldsByFlowIn) {
          inputField.options.forEach((option: any) => {
            showFieldsByFlow[option.displayValue] = option.showFields;
          });
        } else {
          Object.keys(showFieldsByFlowIn.call).forEach((item: any) => {
            showFieldsByFlow[item] = showFieldsByFlowIn.call[item];
          });

          Object.keys(showFieldsByFlowIn.whatsApp).forEach((item: any) => {
            if (showFieldsByFlow[item]) {
              showFieldsByFlow[item] = Array.from(
                new Set([
                  ...showFieldsByFlow[item],
                  ...(showFieldsByFlowIn.whatsApp[item] as Array<any>),
                ])
              );
            } else {
              showFieldsByFlow[item] = showFieldsByFlowIn.whatsApp[item];
            }
          });
        }
      }
      if (inputField.name === "Disposition") {
        inputField.options.forEach((option: any) => {
          showFieldsByDisposition[option.displayValue] = option.showFields;
          showCategoriesByDisposition[option.displayValue] =
            option.showCategories;
        });
      }
      // display vs backend value mapping
      if (
        inputField.type === "dropdown" ||
        inputField.type === "toggleButton"
      ) {
        const mappingCall: Record<string, string> = {};
        const mappingWhatsapp: Record<string, string> = {};
        inputField.options.forEach((option: any) => {
          mappingCall[option.displayValue] = option.value;
          mappingWhatsapp[option.displayValue] = option.whatsAppValue;
        });
        dropDownCallMapping[inputField.BackendKey] = mappingCall;
        dropDownWhatsAppMapping[inputField.BackendKey] = mappingWhatsapp;
      }
    });
  });
  showFieldsByFlow[""] = allFieldsArr;
  showFieldsByDisposition[""] = allFieldsArrDisposition;

  return {
    defaultInputFieldData,
    backEndKey,
    saveKey,
    uploadDataKey,
    dropDownCallMapping,
    dropDownWhatsAppMapping,
    nameToBackEndKeyMapping,
    showFieldsByFlow,
    showFieldsByDisposition,
    showCategoriesByFlow,
    showCategoriesByDisposition,
    isRequiredObject,
    makeValueObject,
  };
};

const replaceUnderScoreWithDot = (data: Record<string, any>) => {
  const result: Record<string, any> = {};
  data && Object.keys(data)?.forEach((item: string) => {
    if (item.includes("&_")) {
      result[item.replaceAll("&_", "_")] = data[item];
      return;
    }
    result[item.replaceAll("_", ".")] = data[item];
  });
  return result;
};

function updateCustomerDataByAgent(
  customerData: any,
  nameToBackEndKeyMapping: any,
  agentData: any,
  accountName: string,
  useCaseId: string
) {
  customerData.forEach((item: Record<string, any>) => {
    if (item[nameToBackEndKeyMapping["Flow"]]) {
      item[nameToBackEndKeyMapping["Flow"]] = "";
    }
    if (item[nameToBackEndKeyMapping["Language"]]) {
      item[nameToBackEndKeyMapping["Language"]] = "";
    }
    item[nameToBackEndKeyMapping["Bank Name"]] =
      agentData?.["bankName"] ||
      item[nameToBackEndKeyMapping["Bank Name"]] ||
      "";
    if (item[nameToBackEndKeyMapping["gender"]]) {
      item[nameToBackEndKeyMapping["Agent Name"]] =
        item[nameToBackEndKeyMapping["gender"]] === "Male"
          ? agentData?.femaleAgentName ||
            item[nameToBackEndKeyMapping["gender"]] ||
            ""
          : agentData?.maleAgentName ||
            item[nameToBackEndKeyMapping["gender"]] ||
            "";
    }
    if (
      ((accountName.includes("Saarthi Demo") ||
        accountName.includes("International Demo")) &&
        useCaseId === USECASE.salesAndOnboarding.id) ||
      accountName.includes("SBA Lead Generation")
    ) {
      item[nameToBackEndKeyMapping["Agent Name"]] =
        agentData?.agentName ||
        item[nameToBackEndKeyMapping["Agent Name"]] ||
        "";
      item[nameToBackEndKeyMapping["Bank Name"]] =
        agentData?.parentFlow?.[0]?.clientName ||
        item[nameToBackEndKeyMapping["Bank Name"]] ||
        "";
      item[nameToBackEndKeyMapping["Product Type"]] =
        agentData?.productType ||
        item[nameToBackEndKeyMapping["Product Type"]] ||
        "";
    }
  });
  return customerData;
}

function getAllSchemaFields(schema: any) {
  const result: string[] = [];
  schema.forEach((category: any) => {
    category.data.forEach((field: any) => {
      if (field?.isVisible) result.push(field.name);
    });
  });
  return result;
}
