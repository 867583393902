import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux";
import styles from "./ProductPage.module.scss";
import Icon from "../../../components/generic/icon/Icon";
import Button from "../../../components/generic/button/Button";
import {
  noProjectDomain,
  productPageIcon,
  teamsPageIcon,
  campaignPageIcon,
} from "../../../theme/assets/svg";
import { giftBox } from "../../../theme/gif";
import { DomainCard } from "../../../components/moduleComponents/signUpFlow/productPage/domainCard/DomainCard";
import { ProductPortfolio } from "../../../components/moduleComponents/signUpFlow/productPage/productPortfolio/ProductPortfolio";
import { Products } from "../../../components/moduleComponents/signUpFlow/productPage/productsComponent/Products";
import { HelpModal } from "../../../components/moduleComponents/signUpFlow/helpModal/HelpModal";

import {
  currentProductData,
  getAllDomainData,
  getAllProductData,
  clearAllData,
  showProductPagePopUp,
} from "../../../redux/signUpFlow/productPage/actions/productPage.actions";
import Loader from "../../../components/generic/loader/Loader";
import { toast } from "react-toastify";

export const ProductPage = () => {
  const [domainDataSelected, setDomainDataSelected] = useState({
    name: "",
    description: "",
    icon: "",
    id: "",
  });
  const [showDomainPopUp, setShowDomainPopUp] = useState(false);
  const [domainTobeSelected, setDomainTobeSelected] = useState({
    name: "",
    description: "",
    icon: "",
    id: "",
  });
  const [isInitialPopUp, setIsInitialPopUp] = useState<boolean>(false);

  const dispatch = useDispatch();

  const productPortflios = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productPageReducer?.productPortfolioDetails
  );

  const currentProduct = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productPageReducer?.currentProductDetails
  );

  const domainData = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productPageReducer?.domainDetails
  );

  const isLoadingDomain = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productPageReducer?.isLoadingDomainDetails
  );

  const isPortfolioLoading = useSelector(
    (state: RootState) =>
      state.signUpModuleReducer?.productPageReducer
        ?.isLoadingProductPortfolioTeams
  );

  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.name
  );

  const userName = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo?.userDetail?.name
  );

  const userId = useSelector(
    (state: RootState) => state.loginReducer.userLoginInfo?.userDetail?._id
  );

  const isTeamPageVisited = useSelector(
    (store: RootState) =>
      store.signUpModuleReducer.teamsPageReducer?.isTeamPageVisited
  );

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const isProductPageShown = useSelector(
    (store: RootState) => store.loginReducer?.userData?.isProductPageShown
  );

  const handleUpdateDomainSelection = () => {
    dispatch(clearAllData());
    dispatch(currentProductData({ domain: domainTobeSelected }));
    dispatch(
      getAllProductData({
        userId: userId,
        token: token,
        domainId: domainTobeSelected?.id,
      })
    );
  };

  const handleDomainSelection = (data: any) => {
    if (
      currentProduct?.domain &&
      Object.keys(currentProduct?.domain).length !== 0 &&
      currentProduct?.domain?.name !== data?.name &&
      productPortflios?.length > 0
    ) {
      setShowDomainPopUp(true);
      setDomainTobeSelected(data);
    } else {
      setDomainDataSelected(data);
      dispatch(currentProductData({ domain: data }));
      dispatch(
        getAllProductData({
          userId: userId,
          token: token,
          domainId: data?.id,
        })
      );
    }
  };

  // for updating accept terms and condition
  const onClickStartSetUp = () => {
    setIsInitialPopUp(false);
    dispatch(showProductPagePopUp(false));
  };
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    const avoidURLChangeNewUser = (event: Event) => {
      window.history.pushState(null, document.title, window.location.href);
    };
    const removeBackNavigation = function (event: Event) {
      window.history.pushState(null, document.title, window.location.href);
    };
    if (isProductPageShown && !isTeamPageVisited)
      window.addEventListener("hashchange", avoidURLChangeNewUser);
    window.addEventListener("popstate", removeBackNavigation);

    return () => {
      window.removeEventListener("popstate", removeBackNavigation);
      window.removeEventListener("hashchange", avoidURLChangeNewUser);
    };
  }, []);

  useEffect(() => {
    if (isProductPageShown) {
      setIsInitialPopUp(true);
    } else {
      setIsInitialPopUp(false);
    }
  }, [isProductPageShown]);

  useEffect(() => {
    dispatch(getAllDomainData({ userId: userId, token: token }));
  }, [userId, token]);

  useEffect(() => {
    setDomainDataSelected((prev) => {
      return {
        ...prev,
        name: currentProduct?.domain?.name ? currentProduct?.domain?.name : "",
        description: currentProduct?.domain?.description
          ? currentProduct?.domain?.description
          : "",
        icon: currentProduct?.domain?.icon ? currentProduct?.domain?.icon : "",
        id: currentProduct?.domain?.id ? currentProduct?.domain?.id : "",
      };
    });
  }, [currentProduct]);

  return (
    <>
      <div
        className={`${styles.productPageWrapper} ${
          isPortfolioLoading ? styles.disableProductScreen : ""
        } `}
      >
        {isLoadingDomain ? (
          <div className={styles.productsLoader}>
            <Loader />
          </div>
        ) : (
          <>
            <div className={styles.productPageLeftSideWrapper}>
              <div
                className={styles.productDomainHeading}
                id="auto_select_domain"
              >
                Select the domain:
              </div>
              <div className={styles.productDomainOptionsDiv}>
                {domainData?.map((data: any, index: number) => {
                  return (
                    <div key={index}>
                      <DomainCard
                        domainData={data}
                        domainIndex={index}
                        onClickDomain={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          e.stopPropagation();
                          handleDomainSelection(data);
                        }}
                        isSelected={domainDataSelected.name}
                        isShowDomainPopUp={showDomainPopUp}
                        isCloseDomainPopUp={() => setShowDomainPopUp(false)}
                        onClickYes={() => {
                          handleUpdateDomainSelection();
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {domainDataSelected?.name?.length !== 0 ? (
              <div
                className={styles.productPageDataWrapper}
                data-testid="project-page"
              >
                <Products />
                <ProductPortfolio />
              </div>
            ) : (
              <div className={styles.productPageNoDataWrapper}>
                <div className={styles.noDomainDiv}>
                  <Icon
                    img_src={noProjectDomain}
                    extraClass={styles.noProjectDomainIconStyling}
                    id={"auto_noProjectDomain_icon"}
                  />
                  <div className={styles.selectDomainText}>
                    Select the domain first
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {isInitialPopUp && (
          <HelpModal
            show={isInitialPopUp}
            modalIcon={productPageIcon}
            extraClassModalBackDrop={styles.extraClassModalProductBackDrop}
            extraModalStyling={styles.extraModalStylingProduct}
            extraClassModalStyling={styles.extraClassModalStylingProduct}
            modalContent={
              <>
                <div className={styles.projectContentTextDiv}>
                  Hey {userName?.length > 0 ? userName : accountName}, I&apos;m
                  MAIA - your AI SuperAgent. Besides your customers, I&apos;m
                  here to take care of you throughout the Pravid account setup
                  and beyond.
                </div>
                <div className={styles.projectContentBtnDiv}>
                  <Button
                    text="Start account setup"
                    extraClass={styles.projectBtnStyling}
                    onClick={() => onClickStartSetUp()}
                    id="auto_product_setup_btn"
                  />
                </div>
              </>
            }
          />
        )}
      </div>
    </>
  );
};
