import React, { useEffect, useState } from "react";
import styles from "./ScriptSelection.module.scss";
import Button from "../../../generic/button/Button";
import { InputComponent } from "./inputComponent/InputComponent";
import { useNavigate } from "react-router-dom";
import { CardComponent } from "./cardComponent/CardComponent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import {
  setMindMapData,
  getConditionEditOutcomes,
  getOutComesDispositions,
  getFollowUpsDispositions,
  getScriptPaymentModebyFlow,
} from "../../../../redux/strategy/nudgeCreation/actions";
import {
  setPropForEachNudge,
  setShowNudgeSetting,
  setIsBackIconClicked,
  setShowRightsideAll,
  setSelectedNudgeData,
  tempStoreScriptDataForBack,
} from "../../../../redux/strategy/eachNudgeBox/actions/actionCreators";
import {
  whatsapp,
  whatsappblue,
  callsx,
  callsblue,
  message,
  messageblue,
  emails,
  emailxblue,
  dropdownDownArrow,
} from "../../../../theme/assets/svg";
import MultiSelectDropDown from "../../../generic/multiSelectWithBtn/MultiSelectWithBtn";
import { handleScriptValidation } from "../../../../utils/validators/validators";
import { USECASE } from "../../../../constants";

interface ScriptData {
  channel: string;
  communicationWays: string;
  humanHandOff: boolean;
  paymentMethod: string[];
  payment: { paymentMode: string; paymentType: string };
  partialPayment: any;
  minimumNegotiationPercentage: any;
  levelOfNegotiation: any;
  script: any
}

interface CardData {
  title: string;
  heading: string;
  description: string;
  icon: string;
}

export const ScriptSelection = () => {
  const paymentMethods = [
    "Online",
    "Agent",
    "Card",
    "UPI",
    "Net banking",
    "Wallet",
    "Branch",
    "App",
  ];

  const cardsData: CardData[] = [
    {
      title: "Autodebit",
      heading: "Damage to Credit Score",
      icon: "sdsdsd",
      description:
        "This flow type typically communication and actions related to reminding the debtor about an upcoming due date or payment that is due soon, usually before the due date",
    },
    {
      title: "Manual",
      heading: "Late Payment Charges",
      icon: "sdsdsd",
      description:
        "This flow type typically covers communication and actions related to reminding the debtor about an upcoming due date or payment that is due soon, usually before the due date",
    },
    {
      title: "Autodebit",
      heading: "Impact on Future Loan Eligibility",
      icon: "sdsdsd",
      description:
        "This flow type typically covers communication and actions related to reminding the debtor about an upcoming due date or payment that is due soon, usually before the due date",
    },
  ];

  const partialpaymentCard: any = [
    {
      title: "Level Of Negotiation -L1",
      heading: "Minimum Negotiation Percentagen 20%",
      icon: "sdsdsd",
      description:
        "This flow type typically communication and actions related to reminding the debtor about an upcoming due date or payment that is due soon, usually before the due date",
    },
    {
      title: "Level Of Negotiation -L2",
      heading: "Minimum Negotiation Percentage 50%",
      icon: "sdsdsd",
      description:
        "This flow type typically covers communication and actions related to reminding the debtor about an upcoming due date or payment that is due soon, usually before the due date",
    },
    {
      title: "Level Of Negotiation -L3",
      heading: "Minimum Negotiation Percentage 60%",
      icon: "sdsdsd",
      description:
        "This flow type typically covers communication and actions related to reminding the debtor about an upcoming due date or payment that is due soon, usually before the due date",
    },
  ];

  const channelList = [
    {
      label: "Call",
      inActiveIcon: callsx,
      activeIcon: callsblue,
      value: "call",
    },
    {
      label: "Whatsapp",
      inActiveIcon: whatsapp,
      activeIcon: whatsappblue,
      value: "whatsApp",
    },
    {
      label: "Message",
      inActiveIcon: message,
      activeIcon: messageblue,
      value: "sms",
    },
    {
      label: "Email",
      inActiveIcon: emails,
      activeIcon: emailxblue,
      value: "mail",
    },
    // {
    //   label: "Human",
    //   inActiveIcon: humanHandOffRight,
    //   activeIcon: humanHandOffHighlightRight,
    //   value: "human",
    // },
  ];

  const communicationWayOptions = [
    { label: "One Way", value: "One Way" },
    { label: "Two Way", value: "Two Way" },
  ];

  const arrOfChannelForFollowUps = ["Call", "WhatsApp", "SMS", "Mail"];

  const followUps = arrOfChannelForFollowUps.map((channelName) => {
    const normalizedChannelName = channelName.toLowerCase();
    const channel = channelList.find(
      (data) => data.value.toLowerCase() === normalizedChannelName
    );
    if (channel) {
      return {
        label: channel.value,
        value: channelName,
      };
    }
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const currentUseCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseId
  );

  const accountId = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );
  const paymentModes = useSelector(
    (state: RootState) =>
      state.strategyModuleReducer?.nudgeReducer?.paymentModeBasedFlow?.data ||
      []
  );

  const myPlanData: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  const { show, showRight } = useSelector(
    (state: RootState) => state.strategyModuleReducer?.individualNudgeReducer
  );

  const nudgeWithChannelData = useSelector(
    (store: RootState) =>
      store.strategyModuleReducer?.individualNudgeReducer?.selectedNudgeData
  );

  const eachNudgeData = useSelector(
    (state: RootState) =>
      state.strategyModuleReducer?.individualNudgeReducer.setting
  );

  const tempScriptData = useSelector(
    (state: RootState) =>
      state.strategyModuleReducer?.individualNudgeReducer.tempScriptData
  );

  const isShowMindMap = useSelector(
    (state: RootState) =>
      state.strategyModuleReducer?.nudgeReducer?.mindMap?.show
  );

  const strategyData = useSelector((store: RootState) => {
    return store?.strategyModuleReducer?.strategyReducer?.createdStrategy;
  });

  console.log("scriptv2", strategyData, tempScriptData)

  const [selectedNudgeData, setNudgeData] = useState<any>({});
  const [isEnabled, setIsEnabled] = useState(false);
  const [selectedScript, setSelectedScript] = useState<ScriptData>({
    channel: selectedNudgeData?.channel || "Call",
    communicationWays: "Two Way",
    humanHandOff: false,
    paymentMethod: [],
    payment: { paymentMode: "", paymentType: "" },
    partialPayment: {
      [selectedNudgeData?.flow]: false,
    },
    levelOfNegotiation: {},
    minimumNegotiationPercentage: {},
    script: {}
  });

  const handlePaymentMethod = (values: string[]) => {
    setSelectedScript({
      ...selectedScript,
      paymentMethod: values,
    });
  };

  console.log(selectedScript, selectedNudgeData, "script", paymentModes);

  const handlePartialPaymentDetails = (data: any) => {
    setSelectedScript({
      ...selectedScript,
      minimumNegotiationPercentage: {
        [selectedNudgeData?.flow]:
          (data?.heading && data?.heading.match(/\d+/)[0]) || 0,
      },
      levelOfNegotiation: {
        [selectedNudgeData?.flow]: data.title?.split("-")?.[1]?.[1] || "",
      },
    });
  };

  const handleTogglePartialPayment = (data: boolean) => {
    setSelectedScript({
      ...selectedScript,
      partialPayment: { [selectedNudgeData?.flow]: data },
    });
    if (data === false) {
      setSelectedScript({
        ...selectedScript,
        minimumNegotiationPercentage: {},
        levelOfNegotiation: {},
        partialPayment: { [selectedNudgeData?.flow]: data },
      });
    }
  };

  const handleSaveScript = () => {
    if (
      selectedNudgeData?.channel !== selectedScript?.channel &&
      selectedScript?.channel !== "" &&
      selectedNudgeData?.channel?.length
    ) {
      const dataToUpdate = selectedNudgeData;
      delete dataToUpdate["acceptedOutcome"];
      delete dataToUpdate["unacceptedOutcome"];
      delete dataToUpdate["followUps"];
      dataToUpdate["channel"] = selectedScript?.channel;
      dataToUpdate["communicationWays"] = selectedScript?.communicationWays;
      dataToUpdate["agentDesktop"] = selectedScript?.humanHandOff;
      dataToUpdate["scriptConfiguration"] = selectedScript;
      if (show) {
        dispatch(setPropForEachNudge(dataToUpdate));
      }
      if (showRight) {
        dispatch(setSelectedNudgeData(dataToUpdate));
      }
    } else {
      if (show) {
        dispatch(
          setPropForEachNudge({
            channel: selectedScript?.channel,
            communicationWays: selectedScript?.communicationWays,
            scriptConfiguration: selectedScript,
          })
        );
      }
      if (showRight) {
        dispatch(
          setSelectedNudgeData({
            channel: selectedScript?.channel,
            communicationWays: selectedScript?.communicationWays,
            scriptConfiguration: selectedScript,
          })
        );
      }
    }
    if (show) {
      dispatch(setShowNudgeSetting(true));
    }
    dispatch(setIsBackIconClicked(true));
    dispatch(tempStoreScriptDataForBack({}));
    navigate("/strategy/nudgecreation");

  };

  /*****get payment Method by flow */
  useEffect(() => {
    const body = {
      params: {
        flow: selectedNudgeData?.flow,
      },
      token: token,
    };
    selectedNudgeData?.flow && dispatch(getScriptPaymentModebyFlow(body));
  }, [selectedNudgeData?.flow]);

  useEffect(() => {
    setNudgeData(showRight ? nudgeWithChannelData : eachNudgeData);
  }, [showRight, show]);

  useEffect(() => {
    if (tempScriptData && Object.keys(tempScriptData).length > 0) {
      setSelectedScript({
        ...selectedScript,
        channel: tempScriptData?.channel || "call",
        communicationWays: tempScriptData?.communicationWays || "Two Way",
        humanHandOff: tempScriptData?.humanHandOff || false,
        paymentMethod: tempScriptData?.paymentMethod || [],
        payment: {
          paymentType: tempScriptData?.payment?.paymentType || "",
          paymentMode: tempScriptData?.payment?.paymentMode || "",
        },
        partialPayment: tempScriptData?.partialPayment || {
          [selectedNudgeData?.flow]: false,
        },
        levelOfNegotiation: tempScriptData?.levelOfNegotiation || {},
        minimumNegotiationPercentage:
          tempScriptData?.minimumNegotiationPercentage || {},
      });
    } else {
      if (selectedNudgeData) {
        setSelectedScript({
          ...selectedScript,
          channel:
            selectedNudgeData?.scriptConfiguration?.channel ||
            selectedNudgeData.channel ||
            "call",
          communicationWays:
            selectedNudgeData?.scriptConfiguration?.communicationWays ||
            selectedNudgeData.communicationWays ||
            "Two Way",
          humanHandOff:
            selectedNudgeData?.scriptConfiguration?.humanHandOff || false,
          paymentMethod:
            selectedNudgeData?.scriptConfiguration?.paymentMethod || [],
          payment: {
            paymentType:
              selectedNudgeData?.scriptConfiguration?.payment?.paymentType ||
              "",
            paymentMode:
              selectedNudgeData?.scriptConfiguration?.payment?.paymentMode ||
              "",
          },
          partialPayment: selectedNudgeData?.scriptConfiguration
            ?.partialPayment || {
            [selectedNudgeData?.flow]: false,
          },
          levelOfNegotiation:
            selectedNudgeData?.scriptConfiguration?.levelOfNegotiation || {},
          minimumNegotiationPercentage:
            selectedNudgeData?.scriptConfiguration
              ?.minimumNegotiationPercentage || {},
          script: selectedNudgeData?.scriptConfiguration?.script || {}
        });
      }
    }
  }, [location.pathname, tempScriptData, selectedNudgeData]);

  useEffect(() => {
    setIsEnabled(
      handleScriptValidation(selectedScript, selectedNudgeData?.flow)
    );
  }, [selectedScript]);

  useEffect(() => {
    const selectedChannel = followUps.find(
      (data: any) => data.label === selectedScript?.channel
    );

    if (selectedScript?.channel) {
      dispatch(
        getOutComesDispositions({
          token: token,
          useCaseId: currentUseCaseId,
          accountId: accountId,
          selectedChannel: selectedChannel?.value,
        })
      );
      dispatch(
        getFollowUpsDispositions({
          token: token,
          useCaseId: currentUseCaseId,
          accountId: accountId,
          selectedChannel: selectedChannel?.value,
        })
      );
    }
  }, [selectedScript?.channel]);

  return (
    <div className={`${styles.scriptSelectionWrapper} ${myPlanData?.isStrategyToShow === true ? "" : "disablePointerEventWithoutOpacity"}`}>
      {/* <div className={styles.headerBackDiv}>
        <Button
          text="Back"
          id="back-btn"
          extraClass={styles.backBtn}
          onClick={() => {
            navigate("/strategy/nudgecreation");
            dispatch(setIsBackIconClicked(true));
          }}
        />
      </div> */}
      <div className={styles.headerDiv}>
        <InputComponent
          header={"Channel"}
          isDropDown
          onInputValueChange={(data) =>
            setSelectedScript({ ...selectedScript, channel: data })
          }
          inputValue={selectedScript?.channel}
          options={channelList}
          placeHolderText={"Select Channel"}
        />
        <InputComponent
          header={"Communication Way"}
          isDropDown
          noIcons
          onInputValueChange={(data) =>
            setSelectedScript({ ...selectedScript, communicationWays: data })
          }
          inputValue={selectedScript?.communicationWays}
          options={communicationWayOptions}
          placeHolderText={"Select Way"}
        />
        {
          USECASE?.debtCollection?.id === strategyData?.useCaseId &&
          <div className={styles.paymentMethodDiv}>
            <div className={styles.headerText}>Payment Type</div>
            <MultiSelectDropDown
              droplist={{
                optionList: paymentMethods,
                imgSrcRight: dropdownDownArrow,
                placeHolderText: "Payment Type",
                imgSrcLeftHide: true,
                selectedOptions: selectedScript?.paymentMethod
                  ? selectedScript?.paymentMethod
                  : [],
              }}
              onSubmit={(selectedItems: string[]) => {
                handlePaymentMethod(selectedItems);
              }}
            />
          </div>
        }
        {
          (USECASE?.debtCollection?.id === strategyData?.useCaseId || USECASE?.insuranceRenewal?.id === strategyData?.useCaseId) &&
          <InputComponent
            header={"Human Hand Off"}
            isDropDown={false}
            onValueChange={(data) =>
              setSelectedScript({ ...selectedScript, humanHandOff: data })
            }
            value={selectedScript?.humanHandOff}
          />
        }

        {
          USECASE?.debtCollection?.id === strategyData?.useCaseId &&
          <InputComponent
            header={"Partial Payment"}
            isDropDown={false}
            onValueChange={(data) => handleTogglePartialPayment(data)}
            value={selectedScript?.partialPayment?.[selectedNudgeData?.flow]}
          />
        }


      </div>
      <div className={styles.menuDiv}>
        <div className={styles.cardsDiv}>
          {paymentModes?.scripts?.map((data: any, index: number) => {
            console.log("scriptAbhi", data, selectedScript)
            return (
              <CardComponent
                key={index}
                cardData={{
                  variation: data?.variation,
                  version: data?.version,
                  title: data?.paymentType,
                  heading: data?.header,
                  description: data?.description,
                  bgColor:
                    data?.paymentType === "Autodebit" ? "#E6F1FF" : "#E6F8F0",
                  color:
                    data?.paymentType === "Autodebit" ? "#0051B3" : "#047E46",
                }}
                onClickView={(
                  e: React.MouseEvent<HTMLDivElement, MouseEvent>
                ) => {
                  e.stopPropagation();
                  dispatch(tempStoreScriptDataForBack(selectedScript));
                  navigate("/strategy/scriptconfigscreen");
                  dispatch(setMindMapData({ show: true, scriptData: data }));
                }}
                sendSelectedData={(datas) =>
                  setSelectedScript({
                    ...selectedScript,
                    payment: {
                      paymentType: datas?.heading,
                      paymentMode: datas?.title?.includes("Manual") ? `${datas?.title} payment` : datas?.title,
                    },
                    script: data
                  })
                }
                isSelected={
                  USECASE?.debtCollection?.id === strategyData?.useCaseId ?
                    (data.id === selectedScript?.script?.id)
                    :
                    (data.id === selectedScript?.script?.id
                    )
                }
              />
            );
          })}
        </div>
        {selectedScript?.partialPayment?.[selectedNudgeData?.flow] && (
          <div className={styles.paymetsDiv}>
            {partialpaymentCard?.map((data: CardData, index: number) => {
              return (
                <CardComponent
                  key={index}
                  cardData={{
                    title: data?.title,
                    heading: data?.heading,
                    description: data?.description,
                    bgColor: data.title === "Autodebit" ? "#E6F1FF" : "#E6F8F0",
                    color: data.title === "Autodebit" ? "#0051B3" : "#047E46",
                  }}
                  onClickView={(
                    e: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => {
                    e.stopPropagation();
                    dispatch(tempStoreScriptDataForBack(selectedScript));
                    navigate("/strategy/scriptconfigscreen");
                    dispatch(setMindMapData({ show: true, scriptData: data }));
                  }}
                  sendSelectedData={(data: any) =>
                    handlePartialPaymentDetails(data)
                  }
                  isSelected={
                    data.title?.split("-")?.[1]?.[1] ===
                    selectedScript?.levelOfNegotiation?.[
                    selectedNudgeData?.flow
                    ]
                  }
                />
              );
            })}
          </div>
        )}
      </div>
      <div className={styles.btmDiv}>
        <Button
          text="Save Script"
          extraClass={`${styles.btnStyling} ${isEnabled ? styles.selectBtn : styles.selectBtnInActive
            }`}
          onClick={handleSaveScript}
        />
      </div>
    </div>
  );
};
