import { Reducer } from "redux";
import actionType from "../reportAutomation.actionTypes";
// import { stateInterface } from "../interface";
export interface stateInterface {
  reportRuleDetails: any;
  isLoadingReportRuleDetails: boolean;
  reportRuleDetailsError: string;

  isLoadingDeleteReportRuleDetails: boolean;
  deleteReportRuleDetailsError: string;

  specificReportRuleDetail: any;
  isLoadingSpecificReportRuleDetail: boolean;
  specificReportRuleDetailError: string;

  isLoadingUpdateReportRuleDetail: boolean;
  updateReportRuleDetailError: string;

  isLoadingCreateReportRuleDetails: boolean;
  createReportRuleDetailsError: string;
}
export const initialState: stateInterface = {
  reportRuleDetails: [],
  isLoadingReportRuleDetails: false,
  reportRuleDetailsError: "",

  isLoadingDeleteReportRuleDetails: false,
  deleteReportRuleDetailsError: "",

  specificReportRuleDetail: {},
  isLoadingSpecificReportRuleDetail: false,
  specificReportRuleDetailError: "",

  isLoadingUpdateReportRuleDetail: false,
  updateReportRuleDetailError: "",

  isLoadingCreateReportRuleDetails: false,
  createReportRuleDetailsError: "",
};

const reportAutomationReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionType.REPORT_RULE_GET_DETAILS_API_CALL: {
      return {
        ...state,
        isLoadingReportRuleDetails: true,
        reportRuleDetails: [],
        reportRuleDetailsError: "",
      };
    }
    case actionType.REPORT_RULE_GET_DETAILS_API_SUCCESS: {
      return {
        ...state,
        isLoadingReportRuleDetails: false,
        reportRuleDetails: action.payload,
      };
    }
    case actionType.REPORT_RULE_GET_DETAILS_API_FAILURE: {
      return {
        ...state,
        isLoadingReportRuleDetails: false,
        reportRuleDetailsError: action.payload,
      };
    }

    case actionType.REPORT_RULE_DELETE_DETAIL_API_CALL: {
      return {
        ...state,
        isLoadingDeleteReportRuleDetails: true,
        deleteReportRuleDetailsError: "",
      };
    }

    case actionType.REPORT_RULE_DELETE_DETAIL_API_SUCCESS: {
      return {
        ...state,
        isLoadingDeleteReportRuleDetails: false,
      };
    }

    case actionType.REPORT_RULE_DELETE_DETAIL_API_FAILURE: {
      return {
        ...state,
        isLoadingDeleteReportRuleDetails: false,
        deleteReportRuleDetailsError: action.payload,
      };
    }

    case actionType.GET_RULE_DETAIL_API_CALL: {
      return {
        ...state,
        isLoadingSpecificReportRuleDetail: true,
        specificReportRuleDetail: {},
        specificReportRuleDetailError: "",
      };
    }

    case actionType.GET_RULE_DETAIL_API_SUCCESS: {
      return {
        ...state,
        isLoadingSpecificReportRuleDetail: false,
        specificReportRuleDetail: action.payload,
      };
    }

    case actionType.GET_RULE_DETAIL_API_FAILURE: {
      return {
        ...state,
        isLoadingSpecificReportRuleDetail: false,
        specificReportRuleDetailError: action.payload,
      };
    }

    case actionType.UPDATE_RULE_DETAIL_API_CALL: {
      return {
        ...state,
        isLoadingUpdateReportRuleDetail: true,
        updateReportRuleDetailError: "",
      };
    }

    case actionType.UPDATE_RULE_DETAIL_API_SUCCESS: {
      return {
        ...state,
        isLoadingUpdateReportRuleDetail: false,
      };
    }

    case actionType.UPDATE_RULE_DETAIL_API_FAILURE: {
      return {
        ...state,
        isLoadingUpdateReportRuleDetail: false,
        updateReportRuleDetailError: action.payload,
      };
    }

    case actionType.CREATE_RULE_DETAIL_API_CALL: {
      return {
        ...state,
        isLoadingCreateReportRuleDetails: true,
        createReportRuleDetailsError: "",
      };
    }

    case actionType.CREATE_RULE_DETAIL_API_SUCCESS: {
      return {
        ...state,
        isLoadingCreateReportRuleDetails: false,
      };
    }

    case actionType.CREATE_RULE_DETAIL_API_FAILURE: {
      return {
        ...state,
        isLoadingCreateReportRuleDetails: false,
        createReportRuleDetailsError: action.payload,
      };
    }
    case actionType.REPORT_AUTOMATION_RESET: {
      return {
        ...state,
        specificReportRuleDetail: [],
        isLoadingSpecificReportRuleDetail: false,
        specificReportRuleDetailError: "",
      };
    }
    case actionType.USER_LOGOUT: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default reportAutomationReducer;
