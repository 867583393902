// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BBJjs4acfiRcr7ZtvDb3{background:#fff;border:1px solid #0074ff;border-radius:8px;padding:.4rem;text-align:center;font-family:"Roboto";font-style:normal;cursor:pointer}.BBJjs4acfiRcr7ZtvDb3 .kubxerOsDRLrArCaYvma{font-weight:400;font-size:.875rem;color:#0074ff}.BBJjs4acfiRcr7ZtvDb3 .rN2i_NWtziuIa_fs78Y6{display:flex;justify-content:space-evenly;align-items:center}.BBJjs4acfiRcr7ZtvDb3 .lR4LKVGgFnBYpF5Icsbw{width:3rem;border:none;font-weight:400;font-size:.875rem;color:#0074ff;text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/generic/addButton/AddButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,wBAAA,CACA,iBAAA,CACA,aAAA,CACA,iBAAA,CACA,oBAAA,CACA,iBAAA,CACA,cAAA,CAEA,4CACE,eAAA,CACA,iBAAA,CACA,aAAA,CAGF,4CACE,YAAA,CACA,4BAAA,CACA,kBAAA,CAEF,4CACE,UAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA,CACA,aAAA,CACA,iBAAA","sourcesContent":[".addButtonWrapper {\n  background: #ffffff;\n  border: 1px solid #0074ff;\n  border-radius: 8px;\n  padding: 0.4rem;\n  text-align: center;\n  font-family: \"Roboto\";\n  font-style: normal;\n  cursor: pointer;\n\n  .textClass {\n    font-weight: 400;\n    font-size: 0.875rem;\n    color: #0074ff;\n  }\n\n  .plusMinusClass {\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n  }\n  .inputbox {\n    width: 3rem;\n    border: none;\n    font-weight: 400;\n    font-size: 0.875rem;\n    color: #0074ff;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButtonWrapper": `BBJjs4acfiRcr7ZtvDb3`,
	"textClass": `kubxerOsDRLrArCaYvma`,
	"plusMinusClass": `rN2i_NWtziuIa_fs78Y6`,
	"inputbox": `lR4LKVGgFnBYpF5Icsbw`
};
export default ___CSS_LOADER_EXPORT___;
