import { Reducer } from "redux";
import actionTypes from "../actionTypes";
import { stateInterface, actionInterface } from "../interface";

const initialState: stateInterface = {
  userData: { name: "", phone: "", email: "", reference: "" },
  termsAndPrivacy: false,
  signUpAPI: { data: {}, isLoading: false, error: {} },
  passwordData: { newPassword: "", confirmPassword: "" },
  linkValidityAPI: { data: {}, isLoading: false, error: {} },
  createPasswordAPI: { data: {}, isLoading: false, error: {} },
  agreeToTermsState: true,
  sendOTPExperienceNow: {
    data: {},
    isLoading: false,
    error: {},
    isShowNextStep: false,
  },
  verifyOTPExperienceNow: {
    data: {},
    isLoading: false,
    error: {},
    isNextStep: false,
  },
  callExperienceNow: {
    data: {},
    isLoading: false,
    error: {},
    isNextStep: false,
  },
  isMaiaPopUpOpen: false,
  initialExpNowCount: 0,
  expNowCount: 0,
  resetPasswordDetail: { data: {}, isLoading: false, error: {} },
};

const signUpReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.SET_FIELD_DATA_SIGNUP: {
      return {
        ...state,
        userData: {
          ...state.userData,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    /* signup API -------- */
    case actionTypes.SIGN_UP_API_REQUEST: {
      return { ...state, signUpAPI: { data: {}, isLoading: true, error: {} } };
    }
    case actionTypes.SIGN_UP_API_SUCCESS: {
      return {
        ...state,
        signUpAPI: {
          ...state.signUpAPI,
          isLoading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case actionTypes.SIGN_UP_API_FAILURE: {
      return {
        ...state,
        signUpAPI: {
          ...state.signUpAPI,
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.TOGGLE_TERMS_AND_PRIVACY_SIGNUP: {
      return { ...state, termsAndPrivacy: action.payload };
    }

    /* create password ------------ */
    case actionTypes.SET_PASSWORD_DATA_SIGNUP: {
      return {
        ...state,
        passwordData: {
          ...state.passwordData,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case actionTypes.CREATE_PASSWORD_REQUEST_SIGNUP: {
      return { ...state, signUpAPI: { data: {}, isLoading: true, error: {} } };
    }
    case actionTypes.CREATE_PASSWORD_SUCCESS_SIGNUP: {
      return {
        ...state,
        createPasswordAPI: {
          ...state.createPasswordAPI,
          isLoading: false,
          data: action.payload,
        },
      };
    }
    case actionTypes.CREATE_PASSWORD_FAILURE_SIGNUP: {
      return {
        ...state,
        createPasswordAPI: {
          ...state.createPasswordAPI,
          isLoading: false,
          error: action.payload,
        },
      };
    }

    case actionTypes.AGREE_TO_TERMS_CLOSE: {
      return { ...state, agreeToTermsState: action.payload };
    }
    case actionTypes.SEND_OTP_SING_UP_REQUEST: {
      return {
        ...state,
        sendOTPExperienceNow: {
          ...state.sendOTPExperienceNow,
          data: {},
          error: {},
          isLoading: true,
          isShowNextStep: false,
        },
      };
    }
    case actionTypes.SEND_OTP_SING_UP_SUCCESS: {
      return {
        ...state,
        sendOTPExperienceNow: {
          ...state.sendOTPExperienceNow,
          data: action.payload,
          isLoading: false,
          isShowNextStep: true,
        },
      };
    }
    case actionTypes.SEND_OTP_SING_UP_FAILURE: {
      return {
        ...state,
        sendOTPExperienceNow: {
          ...state.sendOTPExperienceNow,
          error: {},
          isLoading: false,
          isShowNextStep: false,
        },
      };
    }
    case actionTypes.VERIFY_OTP_SING_UP_REQUEST: {
      return {
        ...state,
        verifyOTPExperienceNow: {
          ...state.sendOTPExperienceNow,
          data: {},
          error: {},
          isLoading: true,
          isNextStep: false,
        },
      };
    }
    case actionTypes.VERIFY_OTP_SING_UP_SUCCESS: {
      return {
        ...state,
        verifyOTPExperienceNow: {
          ...state.verifyOTPExperienceNow,
          data: action.payload,
          isLoading: false,
          isNextStep: true,
        },
      };
    }
    case actionTypes.VERIFY_OTP_SING_UP_FAILURE: {
      return {
        ...state,
        verifyOTPExperienceNow: {
          data: {},
          error: action.payload,
          isLoading: false,
          isNextStep: false,
        },
      };
    }
    case actionTypes.CALL_EXPERIENCE_NOW_REQUEST: {
      return {
        ...state,
        callExperienceNow: {
          data: {},
          error: action.payload,
          isLoading: true,
          isNextStep: false,
        },
      };
    }
    case actionTypes.CALL_EXPERIENCE_NOW_SUCCESS: {
      return {
        ...state,
        callExperienceNow: {
          data: {},
          error: action.payload,
          isLoading: false,
          isNextStep: true,
        },
      };
    }
    case actionTypes.SET_TIMER_FOR_FREE_CREADIT: {
      return {
        ...state,
        timeForFreeCredit: action.payload,
      };
    }

    case actionTypes.SET_INTITIAL_EXP_NOW_COUNT: {
      return {
        ...state,
        initialExpNowCount: action.payload,
      };
    }

    case actionTypes.SET_EXP_NOW_COUNT: {
      return {
        ...state,
        expNowCount: state.expNowCount + action.payload,
      };
    }
    case actionTypes.CALL_EXPERIENCE_NOW_FAILURE: {
      return {
        ...state,
        callExperienceNow: {
          data: {},
          error: action.payload,
          isLoading: false,
          isNextStep: false,
        },
      };
    }
    case actionTypes.RESET_EXPERIENCE_NOW: {
      return {
        ...state,
        sendOTPExperienceNow: {
          data: {},
          isLoading: false,
          error: {},
          isShowNextStep: false,
        },
        verifyOTPExperienceNow: {
          data: {},
          isLoading: false,
          error: {},
          isNextStep: false,
        },
        callExperienceNow: {
          data: {},
          isLoading: false,
          error: {},
          isNextStep: false,
        },
      };
    }
    case actionTypes.IS_MAIA_POPUP_OPEN: {
      return {
        ...state,
        isMaiaPopUpOpen: action.payload,
      };
    }
    case actionTypes.RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        resetPasswordDetail: { data: {}, isLoading: true, error: {} },
      };
    }
    case actionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        resetPasswordDetail: {
          isLoading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case actionTypes.RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        resetPasswordDetail: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default signUpReducer;
