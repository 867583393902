import React, { useEffect, useRef, useState } from "react";
import styles from "./AddonOrderCart.module.scss";
import Button from "../../../generic/button/Button";
import { caratDown } from "../../../../theme/assets/genericSvg/index";
import useOnClickOutside from "../../../../utils/useOnClickOutside";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import PaymentSuccessfull from "../paymentSuccessfull/PaymentSuccessfull";
import { Navigate, useNavigate } from "react-router-dom";
import {
  generateOrderId,
  verifyOrderPayment,
} from "../../../../redux/profile/billing/orderSummary/action/orderSummary.action";
import DisplayRazorPay from "../razorPayIntegration/DisplayRazorPay";
import { getMyPlanAPIData } from "../../../../redux/profile/billing/myPlan/actions";
import { getInvoicesAPIData } from "../../../../redux/profile/billing/invoiceDetails/actions";
import { getLeftsideModuleProfileDetails } from "../../../../redux/profile/navigation/action/navigation.action";
import { toast } from "react-toastify";
import {
  getTeamsData,
  getTeamTabs,
  getTeamChannels,

} from "../../../../redux/onboarding/login/actions";

import {
  setIsShowTopBanner,
} from "../../../../redux/baseScreen/baseScreenState/actions";

const AddonOrderCart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isAddonClick, setIsAddonClick] = useState(false);
  const [subTotalAmount, setSubTotalAmount] = useState<number>(0);
  const [cgst, setCgst] = useState<number>(0);
  const [sgst, setSgst] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalAddons, setTotalAddons] = useState<number>();
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);

  const selectedAddons = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.orderSummary?.selectedAddons
  );

  const durationString = useSelector(
    (store: RootState) => store?.profile?.billingReducer?.orderSummary?.duration
  );
  const loginDetails = useSelector((state: RootState) => {
    return state.loginReducer?.userLoginInfo;
  });
  const myPlanDetails: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  const currentTeamId = useSelector(
    (state: RootState) => state.loginReducer?.currentTeam?.id
  );

  const duration = parseInt(durationString?.split(" ")[0]);

  const taxData = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.pricingPlan?.pricingPlanDetails?.data
        ?.taxData
  );
  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
  );

  const userId = useSelector(
    (state: RootState) => state?.loginReducer?.userLoginInfo?.userDetail?._id
  );
  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const currentSelectedTeamId = useSelector(
    (store: RootState) => store.loginReducer?.currentTeam?.id
  );

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const handleClickPayment = () => {
    setShowSuccessPopUp(true);
    dispatch(setIsShowTopBanner(false))
  };
  const handlePaymentVerifyApi = (body: object) => {
    const data = {
      body: body,
      headers: headers,
      func: handleClickPayment,
    };

    dispatch(verifyOrderPayment(data));
  };
  const handleGenerateOrderId = (totalAmount: number) => {
    if (totalAmount < 0) {
      toast.error("Envalid Amount ");
      return;
    }
    const valuAddOnsDetails = selectedAddons?.map((data: any) => {
      return {
        valueAddonID: data?.valueAddonID,
        quantity: data?.quantity,
      };
    });
    const durationPeriod = `${durationString?.split(" ")[0]} ${durationString
      ?.split(" ")[1]
      ?.toUpperCase()}`;
    const body: Record<string, any> = {
      userId: loginDetails?.userDetail["_id"],
      accountId: loginDetails?.accountDetails?.[0]?.id,
      planId: myPlanDetails?.planId,
      selectedDuration: durationPeriod,
      valueAddons: valuAddOnsDetails,
    };

    const data = {
      body: body,
      func: (data: any) => DisplayRazorPay(data, handlePaymentVerifyApi),
      headers: headers,
    };
    dispatch(generateOrderId(data));
  };

  const handleOnClick = () => {
    if (accountId && userId && currentTeamId) {
      dispatch(
        getLeftsideModuleProfileDetails({ id: userId, teamId: currentTeamId })
      );
      dispatch(getMyPlanAPIData({ accountId: accountId }));
      dispatch(
        getInvoicesAPIData({
          userId: userId,
          accountId: accountId,
          limit: 10,
          page: 1,
          token: token,
        })
      );
      dispatch(getTeamsData({
        accountId: accountId
      }))
      dispatch(getTeamTabs(currentSelectedTeamId));
      dispatch(getTeamChannels(currentSelectedTeamId));
    }
    navigate("/profile/billing/invoices");

  };

  function getTotalOrderSummary(duration: number, totalAddon: number) {
    const subTotal = totalAddon;

    const cgstTaxValue: number = taxData?.taxValue?.CGST
      ? parseFloat(taxData.taxValue.CGST) / 100
      : 0.09;
    const sgstTaxValue: number = taxData?.taxValue?.SGST
      ? parseFloat(taxData.taxValue.SGST) / 100
      : 0.09;
    const cgst = subTotal * cgstTaxValue;
    const sgst = subTotal * sgstTaxValue;
    const total = subTotal + cgst + sgst;

    return {
      subTotal: parseFloat(subTotal.toFixed(2)),
      cgst: parseFloat(cgst.toFixed(2)),
      sgst: parseFloat(sgst.toFixed(2)),
      total: parseFloat(total.toFixed(2)),
    };
  }

  function getTotalAddonsAmount(array: any, duration: number) {
    let total = 0;
    if (array?.length > 0) {
      for (let i = 0; i < array.length; i++) {
        const item = array[i];
        const itemTotal = item.price * item.quantity;
        total += itemTotal;
      }
    }
    return total;
  }

  useEffect(() => {
    if (selectedAddons) {
      const totalValue = getTotalAddonsAmount(selectedAddons, duration);
      setTotalAddons(totalValue);
    }
  }, [duration, selectedAddons, totalAddons]);

  useEffect(() => {
    if (totalAddons !== undefined) {
      const cartItem = getTotalOrderSummary(duration, totalAddons);
      setCgst(cartItem?.cgst);
      setSgst(cartItem?.sgst);
      setSubTotalAmount(cartItem?.subTotal);
      setTotalAmount(cartItem?.total);
    }
  }, [duration, totalAddons, selectedAddons]);

  const addonref = useRef<any>();
  useOnClickOutside(addonref, () => setIsAddonClick(false));

  return (
    <div className={styles.addonOrderCartWrapper}>
      <div className={styles.orderTitle}>Order Summary</div>
      {selectedAddons?.length > 0 && (
        <div className={styles.itemsNumber}>{1} Item</div>
      )}
      <hr className={styles.horizontalLine} />
      <div className={styles.orderDetailsTopClass}>
        {selectedAddons?.length > 0 && (
          <div className={styles.addonTopClass}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setIsAddonClick(!isAddonClick)}
            >
              <span>Add ons</span> <span>({selectedAddons?.length})</span>
              <img src={caratDown} style={{ marginLeft: "5px" }} />
            </div>
            <div className={styles.totalAddonAmount}>
              {totalAddons?.toLocaleString("en-IN")}
            </div>
          </div>
        )}
        {isAddonClick && selectedAddons && selectedAddons?.length > 0 && (
          <div>
            {selectedAddons?.map((each: any, i: number) => {
              return (
                <div className={styles.orderDetail} key={i}>
                  <div>{each.valueName}</div>
                  <div className={styles.numberClass}>
                    <span>{each?.price}</span> X <span>{each.quantity}</span>
                    {/* X{" "}
                    <span>{duration}</span> */}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className={styles.orderDetail}>
          <div>Subtotal</div>
          <div className={styles.numberClass}>
            {subTotalAmount?.toLocaleString("en-IN")}
          </div>
        </div>
        <div className={styles.orderDetail}>
          <div>CGST({taxData?.taxValue["CGST"]}%)</div>
          <div className={styles.numberClass}>
            {cgst?.toLocaleString("en-IN")}
          </div>
        </div>
        <div className={styles.orderDetail}>
          <div>SGST({taxData?.taxValue["SGST"]}%)</div>
          <div className={styles.numberClass}>
            {sgst?.toLocaleString("en-IN")}
          </div>
        </div>
        <hr className={styles.dashedLine} />
        <div className={styles.totalAmountClass}>
          <div>Total</div>
          <div style={{ color: "#067D78" }}>
            INR {totalAmount?.toLocaleString("en-IN")}
          </div>
        </div>
      </div>
      <Button
        text="I’m Ready to Pay"
        extraClass={styles.payAmountbutton}
        onClick={() => {
          handleGenerateOrderId(totalAmount);
        }}
      />
      <PaymentSuccessfull
        open={showSuccessPopUp}
        setOpen={setShowSuccessPopUp}
        onClickOk={handleOnClick}
      />
    </div>
  );
};

export default AddonOrderCart;
