import React, { useState, useEffect } from "react";
import styles from "./PlanDetails.module.scss";
import Button from "../../../../generic/button/Button";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import VisibilitySensor from "react-visibility-sensor";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";
import DoughNutChart from "./doughNut";
import { infoIconBlue } from "../../../../../theme/assets/svg";
import ChargeRate from "./tooltipPopUp/ChargeRate";
import ToolTipwithChild from "../../../../generic/toolTipWithChildComponent/ToolTipwithChild";

interface PlanDataProps {
  planData: any;
  orderSummary: any;
}

const convertToNumber = (str: string) => {
  const parsedInt = parseInt(str, 10);
  if (!isNaN(parsedInt) && parsedInt.toString() === str) {
    return parsedInt;
  } else {
    const parsedFloat = parseFloat(str);
    return isNaN(parsedFloat) ? NaN : parsedFloat;
  }
};

export const PlanDetails = ({ planData, orderSummary }: PlanDataProps) => {
  const [conversationData, setConversationData] = useState<any>([]);
  const navigate = useNavigate();
  const [chartsData, setChartsData] = useState<any>({});

  const convertToRupees = (number: string, currency: string) => {
    const rupees = convertToNumber(number)?.toLocaleString("en-IN", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: convertToNumber(number) % 1 === 0 ? 0 : 2,
      maximumFractionDigits: convertToNumber(number) % 1 === 0 ? 0 : 2,
    });
    // const formattedRupees = rupees?.replace(/\.\d+/, ""); // Remove decimal part if present
    return rupees?.replace("₹", currency + " ");
  };

  const convertDate = (dateString: string) => {
    const date = moment(dateString);
    const formattedDate = date.format("DD MMM YYYY");
    return formattedDate;
  };

  const calculateProgress = (num: number, den: number) => {
    const totalprogress = (num / den) * 100;
    return totalprogress;
  };

  function getColorByLabel(label: string) {
    const colorsMap: any = {
      Call: "#06B464",
      Whatsapp: "#01C6FF",
      Email: "#7800FF",
      SMS: "#DB303F",
      Remaining: "#e6e6e6",
    };

    const labelLowerCase = label.toLowerCase();

    for (const key in colorsMap) {
      if (labelLowerCase.includes(key.toLowerCase())) {
        return { color: colorsMap[key], channel: key };
      }
    }

    return { color: "#e6e6e6", channel: "Remaining" };
  }

  useEffect(() => {
    const updatedData =
      planData?.conversationDetails?.usageDescriptionData?.map((data: any) => {
        const progress = calculateProgress(
          data.usage,
          planData?.conversationDetails?.totalLimit
        );
        const { color, channel } = getColorByLabel(data.valueName);

        return { ...data, progress, color, channel };
      });

    const progress = calculateProgress(
      planData?.conversationDetails?.totalLimit -
        parseFloat(planData?.conversationDetails?.totalUsage),
      planData?.conversationDetails?.totalLimit
    );
    const { color, channel } = getColorByLabel("remaining");
    const remData = { progress: progress, color: color, channel: channel };
    setConversationData([...updatedData, remData]);

    // const dataaa = [
    //   {
    //     valueName: "credit used for Calling Mins",
    //     usage: "0.00",
    //     label: "Call",
    //     progress: 15,
    //     color: "#06B464",
    //   },

    //   {
    //     valueName: "credit used for Whatsapp",
    //     usage: "0.00",
    //     label: "WhatsApp",
    //     progress: 20,
    //     color: "#01C6FF",
    //   },

    //   {
    //     valueName: "credit used for Email",
    //     usage: "0.00",
    //     label: "Email",
    //     progress: 30,
    //     color: "#7800FF",
    //   },

    //   {
    //     valueName: "credit used for SMS",
    //     usage: "0.00",
    //     label: "SMS",
    //     progress: 10,

    //     color: "#DB303F",
    //   },

    //   { progress: 25, color: "#e6e6e6", channel: "Remaining" },
    // ];

    // setConversationData(dataaa);
  }, [planData?.conversationDetails]);

  useEffect(() => {
    if (conversationData) {
      setChartsData({
        data: conversationData
          ? conversationData.map((data: any) => data.progress)
          : [],
        colors: conversationData
          ? conversationData.map((data: any) => data.color)
          : [],
        channels: conversationData
          ? conversationData.map((data: any) => data.channel)
          : [],
        donutChartLabel: [
          {
            text:
              String(planData?.conversationDetails?.totalUsage) +
              "/" +
              String(planData?.conversationDetails?.totalLimit),
            color: "#000",
            font: {
              size: "14",
              family: "Roboto",
              weight: "500",
            },
          },
        ],
      });
    }
  }, [conversationData]);

  console.log(
    planData?.storageAccess,
    planData?.storageAccess?.length,
    "llllllllll"
  );

  return (
    <div className={styles.planDetailsWrapper}>
      <div className={styles.planDetailsTopWrapper}>
        <div className={`${styles.planDetailsDiv} ${styles.planNameStyling}`}>
          <div className={`${styles.planDetailsDivHeader}`}>Plan</div>
          <div className={`${styles.planDetailsDivText}`}>
            {planData?.planName}
          </div>
        </div>
        <div className={`${styles.planDetailsDiv}  ${styles.planPriceStyling}`}>
          <div className={`${styles.planDetailsDivHeader}`}>Payment</div>
          <div className={`${styles.planDetailsDivText}`}>
            {planData?.price !== "Free"
              ? convertToRupees(planData?.price, planData?.currency)
              : "Free"}
            <span className={styles.planDetailsSubText}>&nbsp;/30 days</span>
          </div>
        </div>
        <div className={`${styles.planDetailsDiv} ${styles.planPeriodStyling}`}>
          <div className={`${styles.planDetailsDivHeader}`}>Billing period</div>
          <div className={`${styles.planDetailsDivText}`}>
            {planData?.duration === "1 MONTH"
              ? planData?.duration?.replace("MONTH", "Month")
              : planData?.duration?.replace("MONTHS", "Months")}
            &nbsp;
            <span className={`${styles.planDetailsSubText}`}>
              (renews on&nbsp;{convertDate(planData?.planExpireDate)} )
            </span>
          </div>
        </div>
        <div className={`${styles.planDetailsDiv} ${styles.btnStyling}`}>
          <Button
            text="Upgrade Plan"
            extraClass={`${styles.addButtonStyling}`}
            onClick={() => navigate("/profile/billing/compareplans")}
          />
        </div>
      </div>
      <div className={styles.planDetailsBtmWrapper}>
        <div className={styles.planDetailsHeaderDiv}>
          Usage
          <ToolTipwithChild
            extraStyleTooltip={styles.toolTipBox}
            tootTipComponent={
              <ChargeRate
                heading="Charge Rates"
                data={planData?.conversationDetailsInfo}
              />
            }
          >
            <img className={styles.infoImg} src={infoIconBlue} />
          </ToolTipwithChild>
        </div>
        <div className={styles.planDetailsContentWrapper}>
          <div className={styles.planUsageDetailsWrapper}>
            <div className={styles.planUsageDetailsHeader}>
              {`Total ${planData?.conversationDetails?.totalLimit} Conversation Credits`}
            </div>
            <div className={styles.planUsageDetailsChartWrapper}>
              <div className={styles.channelContainerDiv}>
                {chartsData && (
                  <DoughNutChart
                    colorsArray={chartsData?.colors}
                    dataArray={chartsData?.data}
                    labelsArray={chartsData?.channels}
                    donutLabels={chartsData?.donutChartLabel}
                  />
                )}
              </div>
              <div className={styles.channelContainerTextDiv}>
                {conversationData &&
                  conversationData?.map((data: any, index: number) => (
                    <div className={styles.planDetailsNormal} key={index}>
                      {data?.usage && (
                        <>
                          <div
                            className={`${styles.dot}`}
                            style={{ backgroundColor: data.color }}
                          ></div>
                          <div>
                            <span className={styles.planDetailsHighlighted}>
                              {data?.usage}&nbsp;
                            </span>
                            of{data?.limit}&nbsp;{data?.valueName}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className={styles.planOtherDetailsWrapper}>
            <div className={styles.planUsageDetailsHeader}>Others</div>
            <div className={styles.planUsageDetailsContent}>
              {planData &&
                Object.values(planData?.others)
                  .map((obj: any) => obj?.usageDescriptionData)
                  ?.map((data: any, index: number) => {
                    return (
                      <div className={styles.planDetailsBtmDiv} key={index}>
                        {/* <div className={styles.planDetailsHeaderDiv}>
                      {data?.name}
                    </div> */}
                        <div className={styles.containerDiv}>
                          <VisibilitySensor>
                            {({ isVisible }: any) => {
                              const percentage = isVisible
                                ? calculateProgress(data?.usage, data?.limit)
                                : 0;
                              return (
                                <CircularProgressbar
                                  value={percentage}
                                  text={`${data?.label}`}
                                  styles={buildStyles({
                                    textColor: "#000",
                                    trailColor: "#E6F1FF",
                                    pathColor: "#06B464",
                                    textSize: "25px",
                                    pathTransitionDuration: 1,
                                  })}
                                  counterClockwise
                                  strokeWidth={15}
                                />
                              );
                            }}
                          </VisibilitySensor>
                        </div>
                        <div className={styles.planDetailsNormal}>
                          <span className={styles.planDetailsHighlighted}>
                            {data?.usage}&nbsp;
                          </span>
                          of&nbsp;{data?.limit}&nbsp;{data?.valueName}
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
          {planData?.storageAccess && (
            <div className={styles.planOtherDetailsWrapper}>
              <div className={styles.planUsageDetailsHeader}>
                Storage Access
              </div>
              <div className={styles.planUsageDetailsContentStorage}>
                {planData?.storageAccess?.map((data: string, i: number) => {
                  return (
                    <div
                      key={i}
                      className={`${styles.storageStyleContainer} ${
                        planData?.storageAccess?.length === 1
                          ? styles.minwidth
                          : ""
                      }`}
                    >
                      <span className={styles.dot}></span>
                      <span>{data}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
