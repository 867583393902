/* eslint-disable no-console */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";
import portalTypes from "../../rootPortal/portalTypes";
import { setRootPortalScreen } from "../../../redux/baseScreen/baseScreenState/actions";
import { setCallerData } from "../../../redux/agentDesktop/BaseScreenAD/action";
import { RootState } from "../../../redux";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import {
  setCommunicationType,
  handleCallFinishActions,
  setInQueueCalls,
  setAcceptData,
} from "../../../redux/agentDesktop/BaseScreenAD/action";
import { emptyBytes } from "./empytBytes";
import { AGENT_DESKTOP_API } from "../../../services/ApiRoutes";
import axios from "axios";
import moment from "moment";

let mute = false;
let hold = false;
const audioPlayer = new Audio();
// const audioArr: any[] = [];
// let isAudioPlaying = false;
// let audioSource: any;
// const audioContext = new (window.AudioContext ||
//   (window as any).webkitAudioContext)();
let recorder: RecordRTC;
export default function CallWebSocket() {
  /* redux hooks -------- */
  const agentId = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.userDetail?._id
  );
  const callStatus = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.callNotification.callStatus
  );
  const onGoingCallChatSessionId = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.onGoingCallChatSessionId
  );
  const accountId = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo.userDetail.accountDetails[0]["id"]
  );
  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );
  const dispatch = useDispatch();
  /* useStates */
  const [sendMedia, setSendMedia] = useState(false);
  const [callStartTime, setCallStartTime] = useState(moment(new Date()));
  // const recorder = useRef<undefined | RecordRTC>(undefined);
  const inQueueCall = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.callNotification.inQueue
  );
  const summaryDataEditable = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.callLogger.summaryDataEditable
  );
  const ringTime = useSelector(
    (store: RootState) => store.agentDesktopReducer.baseScreenAD.ringTime
  );
  const mobileNumber = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.callNotification.callerNumber
  );
  // const didUnmount = useRef(false);

  /* other hooks */
  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(process.env.REACT_APP_SOCKET_URL as string, {
    onOpen: () => console.info("call socket open"),
    onClose: () => console.info("call socket closed"),
    shouldReconnect: () => {
      // return didUnmount.current === false;
      return true;
    },
    reconnectAttempts: 5000,
    reconnectInterval: 1000,
  });

  /*****************************  helper function  *****************************/
  // const base64ToArrayBuffer = useCallback((base64: string) => {
  //   const binaryString = atob(base64);
  //   const length = binaryString.length;
  //   const buffer = new ArrayBuffer(length);
  //   const array = new Uint8Array(buffer);

  //   for (let i = 0; i < length; i++) {
  //     array[i] = binaryString.charCodeAt(i);
  //   }
  //   return buffer;
  // }, []);

  // const playNextAudioDataPacket = useCallback(() => {
  //   if (audioArr.length === 0) {
  //     isAudioPlaying = false;
  //     return;
  //   }
  //   const audioBuffer = audioArr.shift();
  //   console.log(audioArr.length);
  //   audioSource = audioContext.createBufferSource();
  //   audioSource.buffer = audioBuffer;
  //   audioSource.connect(audioContext.destination);
  //   audioSource.onended = playNextAudioDataPacket;
  //   audioSource.playbackRate.value = 1 + 0.1 * audioArr.length;
  //   audioSource.start();
  //   isAudioPlaying = true;
  // }, []);

  // const playNextAudioDataPacketSrc = useCallback(() => {
  //   const playInterval = setInterval(() => {
  //     if (audioArr.length === 0) {
  //       isAudioPlaying = false;
  //       clearInterval(playInterval);
  //       return;
  //     }
  //     console.log(audioArr.length);
  //     const audioSrc = audioArr.shift();
  //     audioPlayer.src = audioSrc;
  //     audioPlayer.play();

  //     isAudioPlaying = true;
  //   }, 400);
  // }, []);

  /*****************************  handlers  *****************************/

  const handleSocketEvent = useCallback(
    (data: Record<string, any>) => {
      try {
        if (data.event === "media") {
          if (!hold) {
            audioPlayer.src = data.bytes;
            audioPlayer.play();
            data.inQueue !== inQueueCall &&
              dispatch(setInQueueCalls(data.inQueue));
          }
        } else if (data.event === "start") {
          dispatch(
            setCallerData(
              data.customerName,
              data.mobileNo,
              data.sessionId,
              data.customerStartTime
            )
          );
          dispatch(setCommunicationType(data.type)); // ***** callType should come in event
          dispatch(
            setRootPortalScreen(portalTypes.CALL_NOTIFICATION_AGENT_DESKTOP)
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
    [inQueueCall]
  );
  // const handleSocketEventSrcQueue = useCallback(
  //   async (data: Record<string, any>) => {
  //     try {
  //       if (data.event === "media") {
  //         if (!hold) {
  //           audioArr.push(data.bytes);
  //           if (!isAudioPlaying) {
  //             setTimeout(() => {
  //               playNextAudioDataPacketSrc();
  //             }, 10);
  //           }
  //           data.inQueue !== inQueueCall &&
  //             dispatch(setInQueueCalls(data.inQueue));
  //         }
  //       } else if (data.event === "start") {
  //         dispatch(
  //           setCallerData(
  //             data.customerName,
  //             data.mobileNo,
  //             data.sessionId,
  //             data.customerStartTime
  //           )
  //         );
  //         dispatch(setCommunicationType(data.type)); // ***** callType should come in event
  //         dispatch(
  //           setRootPortalScreen(portalTypes.CALL_NOTIFICATION_AGENT_DESKTOP)
  //         );
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   },
  //   [inQueueCall]
  // );

  // const handleSocketEventAudioContextQueue = useCallback(
  //   async (data: Record<string, any>) => {
  //     try {
  //       if (data.event === "media") {
  //         if (!hold) {
  //           const audioData = base64ToArrayBuffer(data.bytes);
  //           const audioBuffer = await audioContext.decodeAudioData(audioData);
  //           audioArr.push(audioBuffer);
  //           if (!isAudioPlaying) {
  //             setTimeout(() => {
  //               playNextAudioDataPacket();
  //             }, 10);
  //           }
  //           data.inQueue !== inQueueCall &&
  //             dispatch(setInQueueCalls(data.inQueue));
  //         }
  //       } else if (data.event === "start") {
  //         dispatch(
  //           setCallerData(
  //             data.customerName,
  //             data.mobileNo,
  //             data.sessionId,
  //             data.customerStartTime
  //           )
  //         );
  //         dispatch(setCommunicationType(data.type)); // ***** callType should come in event
  //         dispatch(
  //           setRootPortalScreen(portalTypes.CALL_NOTIFICATION_AGENT_DESKTOP)
  //         );
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   },
  //   [inQueueCall]
  // );

  // const handleSocketEventAudioContext = useCallback(
  //   async (data: Record<string, any>) => {
  //     try {
  //       if (data.event === "media") {
  //         if (!hold) {
  //           const audioData = base64ToArrayBuffer(data.bytes.split(",")[1]);
  //           const audioBuffer = await audioContext.decodeAudioData(audioData);
  //           audioSource = audioContext.createBufferSource(); //
  //           audioSource.buffer = audioBuffer;
  //           audioSource.connect(audioContext.destination);
  //           audioSource.start();

  //           data.inQueue !== inQueueCall &&
  //             dispatch(setInQueueCalls(data.inQueue));
  //         }
  //       } else if (data.event === "start") {
  //         dispatch(
  //           setCallerData(
  //             data.customerName,
  //             data.mobileNo,
  //             data.sessionId,
  //             data.customerStartTime
  //           )
  //         );
  //         dispatch(setCommunicationType(data.type)); // ***** callType should come in event
  //         dispatch(
  //           setRootPortalScreen(portalTypes.CALL_NOTIFICATION_AGENT_DESKTOP)
  //         );
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   },
  //   []
  // );

  const handleSocketEventEnd = useCallback(() => {
    try {
      const statusData = {
        agentId: agentId,
        sessionId: onGoingCallChatSessionId,
        event: "Call",
        startTime: moment(callStartTime),
        mobileNumber: mobileNumber,
        endTime: moment(new Date()),
        accountId: accountId,
        flow: summaryDataEditable["data[0].information.[flow_type]"],
        disposition: summaryDataEditable["disposition"],
      };
      dispatch(setAcceptData(statusData));
      sendStatus(statusData);
      // setIsFirst(true);
      // audioArr = [];
      setSendMedia(false);
      dispatch(handleCallFinishActions());
    } catch (e) {
      console.error(e);
    }
  }, [
    onGoingCallChatSessionId,
    callStartTime,
    mobileNumber,
    summaryDataEditable,
  ]);

  const connectionStatus = useMemo(() => {
    console.log("connectionStatus");
    return {
      [ReadyState.CONNECTING]: "Connecting",
      [ReadyState.OPEN]: "Open",
      [ReadyState.CLOSING]: "Closing",
      [ReadyState.CLOSED]: "Closed",
      [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    }[readyState];
  }, [readyState]);

  /* useEffects ------ */
  // listens for every message
  // listens for every message
  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      if ("start" === data.event || "media" === data.event) {
        handleSocketEvent(data);
        // handleSocketEventSrcQueue(data);
        // handleSocketEventAudioContextQueue(data);
        // handleSocketEventAudioContext(data);
      } else if ("end" === data.event) {
        handleSocketEventEnd();
      } else {
        throw new Error("webSocket: unhandled call event");
      }
    }
  }, [lastMessage]);
  // sends first message after connection.
  useEffect(() => {
    console.log("connection useEffect");
    if (connectionStatus === "Open") {
      sendMessage(
        JSON.stringify({
          agentId: agentId,
          source: "agent",
          event: "connect",
          accountId: accountId,
          accountName: accountName,
        })
      );
    }
  }, [connectionStatus]);

  const sendStatus = useCallback((data: object) => {
    const url = AGENT_DESKTOP_API.SET_CALL_STATUS;
    const getUrl = `${AGENT_DESKTOP_API.GET_RING_DATA}/${agentId}`;
    axios.post(url, data).then((res) => {
      // console.log(res);
      axios.get(getUrl, data).then((res) => {
        // console.log(res);
      });
    });
  }, []);

  // handles call status.
  useEffect(() => {
    // ***** callType condition
    if (callStatus === "accept") {
      setSendMedia(true);
      const statusData = {
        agentId: agentId,
        sessionId: onGoingCallChatSessionId,
        event: "Ring",
        startTime: moment(ringTime),
        mobileNumber: mobileNumber,
        endTime: moment(new Date()),
        status: "Answered",
        accountId: accountId,
        flow: summaryDataEditable["data[0].information.[flow_type]"],
      };
      setCallStartTime(moment(new Date()));
      dispatch(setAcceptData(statusData));
      sendStatus(statusData);
      sendJsonMessage({
        status: "accept",
        agentId: agentId,
        sessionId: onGoingCallChatSessionId,
        event: "start", //start/end/hold
        source: "agent",
        accountId: accountId,
        accountName: accountName,
      });
    } else if (callStatus === "decline") {
      const statusData = {
        agentId: agentId,
        sessionId: onGoingCallChatSessionId,
        event: "Ring",
        startTime: moment(ringTime),
        mobileNumber: mobileNumber,
        endTime: moment(new Date()),
        status: "Declined",
        accountId: accountId,
        flow: summaryDataEditable["data[0].information.[flow_type]"],
      };
      setCallStartTime(moment(new Date()));
      dispatch(setAcceptData(statusData));
      sendStatus(statusData);
      setSendMedia(false);
      sendJsonMessage({
        status: "decline",
        agentId: agentId,
        sessionId: onGoingCallChatSessionId,
        event: "end", //start/end/hold
        source: "agent",
        accountId: accountId,
        accountName: accountName,
      });
      dispatch(handleCallFinishActions());
    } else if (callStatus === "hold") {
      setSendMedia(false);
      sendJsonMessage({
        status: "accept",
        agentId: agentId,
        sessionId: onGoingCallChatSessionId,
        event: "hold", //start/end/hold
        source: "agent",
        accountId: accountId,
        accountName: accountName,
      });
    } else if (callStatus === "unHold") {
      setSendMedia(false);
      sendJsonMessage({
        status: "accept",
        agentId: agentId,
        sessionId: onGoingCallChatSessionId,
        event: "unHold".toLowerCase(), //start/end/hold
        source: "agent",
        accountId: accountId,
        accountName: accountName,
      });
    } else if (callStatus === "disconnect") {
      // setIsFirst(true);
      // audioArr = [];
      setSendMedia(false);
      const statusData = {
        agentId: agentId,
        sessionId: onGoingCallChatSessionId,
        event: "Call",
        startTime: moment(callStartTime),
        mobileNumber: mobileNumber,
        endTime: moment(new Date()),
        accountId: accountId,
        flow: summaryDataEditable["data[0].information.[flow_type]"],
        disposition: summaryDataEditable["disposition"],
      };
      dispatch(setAcceptData(statusData));
      sendStatus(statusData);
      sendJsonMessage({
        status: "disconnect",
        agentId: agentId,
        sessionId: onGoingCallChatSessionId,
        event: "end", //start/end/hold
        source: "agent",
      });
      dispatch(handleCallFinishActions());
    } else if (callStatus === "missed") {
      setSendMedia(false);
      const statusData = {
        agentId: agentId,
        sessionId: onGoingCallChatSessionId,
        event: "Ring",
        startTime: moment(callStartTime),
        mobileNumber: mobileNumber,
        endTime: moment(new Date()),
        accountId: accountId,
        flow: summaryDataEditable["data[0].information.[flow_type]"],
        disposition: summaryDataEditable["disposition"],
        status: "Missed",
      };
      dispatch(setAcceptData(statusData));
      sendStatus(statusData);
      sendJsonMessage({
        status: "missed",
        agentId: agentId,
        sessionId: onGoingCallChatSessionId,
        event: "missed", //start/end/hold
        source: "agent",
        accountId: accountId,
        accountName: accountName,
      });
      dispatch(handleCallFinishActions());
    }
  }, [callStatus]);

  const streamAudioBytes = useCallback(
    (blob: Blob) => {
      const reader = new FileReader(); // option: make this as global object
      reader.onload = () => {
        const base64data = reader.result;
        sendJsonMessage({
          // ws send audio
          agentId: agentId,
          sessionId: onGoingCallChatSessionId,
          event: "media",
          source: "agent",
          status: "",
          bytes:
            mute || hold
              ? emptyBytes
              : (base64data as string).split("base64,")[1].substring(56),
          accountId: accountId,
          accountName: accountName,
        });
      };
      reader.readAsDataURL(blob);
    },
    [onGoingCallChatSessionId]
  );
  // useEffect(() => {
  //   console.log("ADX", { onGoingCallChatSessionId });
  // }, [onGoingCallChatSessionId]);

  const initAudioRecorder = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (mediaStream) {
        const newRecorder = new RecordRTC(mediaStream, {
          type: "audio",
          mimeType: "audio/webm;codecs=pcm",
          recorderType: StereoAudioRecorder,
          timeSlice: 600, // set 250 ms intervals of data that sends to AAI
          //sampleRate: 16000,
          desiredSampRate: 8000,
          numberOfAudioChannels: 1,
          bufferSize: 512,
          // audioBitsPerSecond: 128000,
          ondataavailable: streamAudioBytes,
        });
        // setRecorder(recorder);
        recorder = newRecorder;
        // recorder.current.startRecording();
      });
  }, [streamAudioBytes]);

  useEffect(() => {
    initAudioRecorder();
    // cleanup function
    return () => {
      recorder?.destroy();
    };
  }, [onGoingCallChatSessionId]);

  useEffect(() => {
    // console.log("nitxxx", callStatus);
    if (callStatus === "hold") {
      hold = true;
    } else if (callStatus === "mute") {
      mute = true;
    } else if (callStatus == "unHold") {
      hold = false;
    } else if (callStatus === "unMute") {
      mute = false;
    } else if (sendMedia === true) {
      recorder?.stopRecording();
      recorder?.startRecording();
      console.log("ADX recording started");
      mute = false;
      hold = false;
    } else {
      recorder?.stopRecording();
      console.log("ADX recording stoped");
      mute = false;
      hold = false;
    }
  }, [sendMedia, recorder, callStatus]);

  useEffect(() => {
    return () => {
      getWebSocket()?.close();
      // didUnmount.current === false;
    };
  }, []);
  return <></>;
}
