import { all, fork } from "redux-saga/effects";
import * as invoiceDetailsWatcherSaga from "./invoiceDetails/saga";
import * as pricingPlanDetailsWatcherSaga from "./pricingPlan/saga";
import * as orderSummaryWatcher from "./orderSummary/saga";
import * as myPlanWatcher from "./myPlan/saga";

export default function* billingRootSaga() {
  yield all([...Object.values(invoiceDetailsWatcherSaga)].map(fork));
  yield all([...Object.values(pricingPlanDetailsWatcherSaga)].map(fork));
  yield all([...Object.values(orderSummaryWatcher)].map(fork));
  yield all([...Object.values(myPlanWatcher)].map(fork));
}
