import React from "react";
import styles from "./ConfirmPopUp.module.scss";
import Modal from "../../../generic/modal/Modal";
import Button from "../../../generic/button/Button";
import Icon from "../../../generic/icon/Icon";
import { createStrategyCloseIcon } from "../../../../theme/assets/svg/strategySvg";

interface confirmModalProps {
  show?: boolean;
  close?: CallableFunction;
  name?: any;
  onClick?: CallableFunction;
  isLoading?: boolean;
}

export const ConfirmPopUp = ({
  show,
  close,
  name,
  onClick,
  isLoading,
}: confirmModalProps) => {
  return (
    <div>
      <Modal
        shown={show}
        close={() => {
          close && close();
        }}
        extraClassStyleModalContent={styles.signUpConfirmModalStyling}
        extraClassStyleModalBackDrop={styles.signUpConfirmBackDropStyling}
      >
        <div
          className={styles.modalInfoDiv}
          id="auto_signUp_modal_div"
          data-testid="signup-modal"
        >
          <div className={styles.modalConfirmCloseIcon}>
            <img
              src={createStrategyCloseIcon}
              onClick={() => {
                close && close();
              }}
              className={styles.iconStyling}
              id="auto_signup_modal_close_icon"
              data-testid="close-icon"
            />
          </div>
          <div className={styles.modalTopInfoDiv}>
            <div
              className={styles.modalInfoHeading}
              data-testid="domain-modal"
              id="auto_signup_domain_modal_div"
            >
              <div id="auto_signup_domain_modal_div_text">
                Changing domain will remove products from your portfolio.
              </div>
              <div
                className={styles.modalInfoSubHeading}
                id="auto_signup_domain_modal_div_sub_text"
              >
                Are you sure you want to change?
              </div>
            </div>

            <div className={styles.signUpButtonDiv}>
              <Button
                text="Yes"
                extraClass={`${styles.buttonStyling} ${styles.yesButtonStyle}`}
                onClick={() => {
                  onClick && onClick();
                  close && close();
                }}
                id="auto_signup_yes_button"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
