import React, { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addYears } from "date-fns";
import styles from "./CalendarPopUp.module.scss";
import { blueCalendarIcon } from "../../../../../theme/assets/svg/campaign_v3/index";
import Icon from "../../../../generic/icon/Icon";
import Button from "../../../../generic/button/Button";
import moment from "moment";
import { format } from "date-fns";
import { useSelector } from "react-redux";

interface DateRangeItem {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface CalendarPopUpProps {
  calenderOpen: boolean;
  extraStyle?: string;
  handleClickCloseCalendar: (isOpen: boolean) => void;
  dateFormater: (item: DateRangeItem) => void;
  dateRange: DateRangeItem[];
  disableRange?: boolean;
  typeText?: string;
  handleSumitDateRange: (isOpen: boolean) => void;
  totalDays?: string;
}

// requre parameter   1) range 2) close event 3) submit date range 4) onChange

const CalendarPopUp = (props: CalendarPopUpProps) => {
  const [dateRangeData, setDateRangeData] = useState<DateRangeItem[]>(
    props?.dateRange
  );

  const isDateValid = (dateString: any) => {
    const date = moment(dateString, "DD/MM/YYYY", true); // Parse date with the specified format (YYYY-MM-DD)

    return date.isValid();
  };

  useEffect(() => {
    // Update dateRangeData when props.dateRange changes
    setDateRangeData(props?.dateRange);
  }, [props.dateRange]);

  console.log(dateRangeData, "rangeee");
  const ref = useRef<HTMLDivElement>(null);

  const selectedPlanDetails: any = useSelector(
    (store: any) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );
  const selectedPlanRange: any =
    Object.keys(selectedPlanDetails).length > 0
      ? {
        planStartDate: selectedPlanDetails?.calenderStartDate,
        planExpireDate: selectedPlanDetails?.calenderEndDate,
      }
      : { planStartDate: addYears(new Date(), -7), planExpireDate: addYears(new Date(), +7) };

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        props?.calenderOpen &&
        ref.current &&
        !ref.current.contains(e.target as Node)
      ) {
        props?.handleClickCloseCalendar(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [props.calenderOpen]);


  return (
    <>
      <div
        className={`${styles.dateFilterDiv} ${props.extraStyle ? props.extraStyle : ""
          }`}
        ref={ref}
        id="exceptClick"
      >
        <div className={styles.dateFilterTopDiv}>
          <div className={styles.dateFilterHeader}>
            <Icon img_src={blueCalendarIcon} />
            <div className={styles.header}>Calendar</div>
          </div>
          {isDateValid(dateRangeData?.[0]?.startDate) &&
            isDateValid(dateRangeData?.[0]?.endDate) ? (
            <div className={styles.dateDiv}>
              <div className={styles.date}>
                {moment(dateRangeData?.[0]?.startDate).format("DD/MM/YYYY")}
              </div>
              <div className={styles.dash}>-</div>
              <div className={styles.date}>
                {moment(dateRangeData?.[0]?.endDate).format("DD/MM/YYYY")}
              </div>
            </div>
          ) : null}
          {props?.totalDays ? (
            <div className={styles.totalDaysDiv}>
              {props?.totalDays} selected
            </div>
          ) : null}
        </div>
        <div className={styles.dateRangeWrapper}>
          <DateRange
            moveRangeOnFirstSelection={false}
            onChange={(item: DateRangeItem) => {
              props?.dateFormater(item);
            }}
            showDateDisplay={false}
            showSelectionPreview={false}
            shownDate={new Date()}
            ranges={dateRangeData}
            showMonthArrow={true}
            color="#0174FF"
            rangeColors={["#0174FF"]}

            maxDate={
              Object.keys(selectedPlanRange).length !== 0 &&
                new Date(selectedPlanRange?.planExpireDate) > new Date()
                ? new Date()
                : new Date(selectedPlanRange?.planExpireDate)
            }
            minDate={
              props?.disableRange
                ? addYears(new Date(), 0)
                : Object.keys(selectedPlanRange).length !== 0
                  ? new Date(selectedPlanRange?.planStartDate)
                  : undefined
            }
          />
        </div>
        <div className={styles.btnDiv}>
          <Button
            text={"Select"}
            onClick={() => props.handleSumitDateRange(false)}
            extraClass={styles.btnStyling}
          />
        </div>
      </div>
    </>
  );
};

export default CalendarPopUp;
