import React, { useEffect, useRef, useState } from "react";
import styles from "./InputContainer.module.scss";
import ToolTip from "../toolTip/ToolTip";
import { cursor } from "../../../theme/assets/svg";
import Icon from "../icon/Icon";
import { InfoToolTip } from "../../generic/infoToolTip/InfoToolTip";
import { CopyToolTip } from "../../generic/copyToolTip/CopyToolTip";
import { Tooltip as MUITooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#B6B6B6",
    borderRadius: "1.20455px",
    color: "#fff",
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: 400,
    minWidth: "180px",
    overflowWrap: "break-word",
  },
  customArrow: {
    color: "#B6B6B6",
    transform: "translateX(3em)",
    fontSize: 9,
  },
  customArrowHide: {
    visibility: "hidden",
  },
  customTTHide: { visibility: "hidden" },
}));

interface InputContainerProps {
  label?: string;
  inputIconLeft?: string;
  inputIconRight?: string;
  inputType?: string;
  inputPlaceholder?: string;
  inputOnChange?: any;
  inputOnFocus?: any;
  inputOnKeyDown?: any;
  inputRequired?: boolean;
  errorMsg?: string | null;
  isShowTooltip?: boolean;
  toolTipAbout?: string;
  inputValue?: string;
  inputAutoComplete?: string;
  maxLength?: number;
  onClickRightIcon?: any;
  keyName?: string;
  inputName?: string;
  extraInputClass?: string;
  extraLabelClass?: string;
  extraUserInputClass?: string;
  isVerify?: boolean;
  onClickVerify?: any;
  index?: number;
  showLabel?: boolean;
  extraRightIconClass?: string;
  readOnly?: boolean;
  inputOnBlur?: any;
  passwordMaskClass?: any;
  extraVerifyClass?: string;
  extraLabelDivClass?: string;
  isFocusGif?: boolean;
  noErrorMsg?: boolean;
  showNewToolTip?: boolean;
  showNewToolTipMsg?: any;
  showNewToolTipPosition?: string;
  toolTipSize?: any;
  showCopyIcon?: boolean;
  isAutoFocus?: boolean | undefined;
  extraWrapperClass?: string;
  showOverFlowToolTip?: boolean;
  showToolTipWithLabel?: boolean;
  hideInputToolTip?: boolean;
}
export default function InputContainer({
  hideInputToolTip,
  showToolTipWithLabel,
  showOverFlowToolTip,
  showNewToolTip,
  showNewToolTipMsg,
  showNewToolTipPosition,
  label,
  inputIconLeft,
  inputType,
  inputPlaceholder,
  inputOnChange,
  inputOnFocus,
  inputOnKeyDown,
  inputRequired,
  errorMsg,
  isShowTooltip,
  isFocusGif,
  toolTipAbout,
  inputValue,
  inputAutoComplete,
  maxLength,
  inputName,
  inputIconRight,
  onClickRightIcon,
  keyName,
  extraInputClass,
  extraLabelClass,
  extraUserInputClass,
  isVerify,
  onClickVerify,
  extraRightIconClass,
  readOnly,
  inputOnBlur,
  index,
  extraVerifyClass,
  extraLabelDivClass,
  noErrorMsg,
  toolTipSize,
  showCopyIcon,
  isAutoFocus,
  extraWrapperClass,
  ...props
}: InputContainerProps) {
  /* handlers */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputOnChange(e, keyName, index);
  };
  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement>(null);
  const [checkOverflowClass, setCheckOverflowClass] = useState("");
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseOver = () => {
    checkOverflowClass.length > 0 && setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    checkOverflowClass.length > 0 && setIsTooltipVisible(false);
  };

  useEffect(() => {
    checkOverflow(inputRef);
  }, [inputValue]);

  function checkOverflow(inputRef: React.RefObject<HTMLInputElement>) {
    if (inputRef.current) {
      const input = inputRef.current;
      if (input.scrollWidth > input.clientWidth) {
        setCheckOverflowClass("text-overflow-tooltip");
      } else {
        setCheckOverflowClass("");
      }
    }
  }

  return (
    <div
      className={`${styles.inputContainerWrapper} ${
        extraWrapperClass ? extraWrapperClass : ""
      }`}
    >
      {props.showLabel && (
        <div
          className={`${
            extraLabelDivClass ? extraLabelDivClass : styles.labelDivStyling
          }
            
          `}
        >
          {showToolTipWithLabel ||
          (inputValue && inputValue?.trim().length > 0) ? (
            <>
              <div
                className={`${styles.labelStyling} ${
                  extraLabelClass ? extraLabelClass : ""
                }`}
              >
                {label}
              </div>
              {isShowTooltip && <ToolTip about={toolTipAbout} />}
              {showNewToolTip && (
                <InfoToolTip
                  message={showNewToolTipMsg}
                  isError={false}
                  position={showNewToolTipPosition}
                  toolTipLarge
                  isIconSmall={true}
                />
              )}
            </>
          ) : (
            ""
          )}
        </div>
      )}
      <div
        className={`${styles.userInputDiv} ${
          extraUserInputClass ? extraUserInputClass : ""
        } ${errorMsg ? styles.errorBorderStyling : ""} ${
          inputValue &&
          typeof inputValue === "string" &&
          inputValue?.trim().length > 0
            ? props.passwordMaskClass
            : ""
        }`}
      >
        {inputIconLeft && (
          <img
            src={inputIconLeft}
            alt={`auto_${label}_icon`}
            id={`auto_${label}_icon`}
          />
        )}
        <MUITooltip
          title={inputValue ? inputValue : ""}
          placement="top-end"
          classes={{
            arrow:
              showOverFlowToolTip &&
              checkOverflowClass?.length > 0 &&
              isTooltipVisible
                ? classes.customArrow
                : classes.customArrowHide,
            tooltip:
              showOverFlowToolTip &&
              checkOverflowClass?.length > 0 &&
              isTooltipVisible
                ? classes.customTooltip
                : classes.customTTHide,
          }}
          arrow
        >
          <input
            autoComplete={inputAutoComplete}
            className={`${styles.inputDivStyling} ${
              extraInputClass ? extraInputClass : ""
            } ${checkOverflowClass}`}
            type={inputType}
            placeholder={inputPlaceholder}
            required={inputRequired}
            onChange={handleInputChange}
            onFocus={inputOnFocus}
            onBlur={inputOnBlur}
            onKeyDown={inputOnKeyDown}
            id={inputName ? `auto_${inputName}` : `auto_${inputPlaceholder}`}
            value={inputValue}
            maxLength={maxLength}
            data-key={keyName}
            name={inputName}
            readOnly={readOnly}
            autoFocus={isAutoFocus}
            ref={inputRef}
            onMouseOver={showOverFlowToolTip ? handleMouseOver : () => {}}
            onMouseLeave={showOverFlowToolTip ? handleMouseLeave : () => {}}
          />
        </MUITooltip>
        <div className={styles.blinkStylingDiv}>
          {isFocusGif && (
            <Icon img_src={cursor} extraClass={styles.blinkStyling} />
          )}
        </div>
        {isVerify && (
          <div
            className={`${extraVerifyClass ? extraVerifyClass : ""} ${
              styles.verifyStyling
            }`}
            id={`auto_${label}_verify_icon`}
            onClick={onClickVerify}
          >
            Verify
          </div>
        )}
        {hideInputToolTip
          ? ""
          : !showCopyIcon &&
            !inputIconRight &&
            showNewToolTip &&
            inputValue?.length === 0 && (
              <InfoToolTip
                message={showNewToolTipMsg}
                isError={false}
                position={showNewToolTipPosition}
                toolTipLarge={toolTipSize}
                isIconSmall={false}
              />
            )}
        {showCopyIcon && <CopyToolTip data={inputValue} />}

        {inputIconRight && (
          <img
            src={inputIconRight}
            alt={`auto_${label}_icon`}
            id={`auto_${label}_icon`}
            data-key={keyName}
            className={extraRightIconClass}
            onClick={onClickRightIcon}
          />
        )}
      </div>
      {noErrorMsg ? (
        ""
      ) : (
        <div className={styles.errorMsgStyling} id={`auto_${label}_errMsg`}>
          {errorMsg}
        </div>
      )}
    </div>
  );
}
