/* eslint-disable no-constant-condition */
import React, { useState, useEffect } from "react";
import styles from "./CallingListTable.module.scss";
import { Table, ConfigProvider, TimePicker, Input } from "antd";
import ReactPaginate from "react-paginate";
import type { ColumnsType } from "antd/es/table";
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux";
import axios from "axios";
import {
  SERVER_URL_CONNECTOR,
  SCHEDULER_URL,
} from "../../../../services/ApiRoutes";
import {
  setDateFilterData,
  getCallingListFilters,
  setCallingListFilter,
} from "../../../../redux/filter/actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Button from "../../../generic/button/Button";
import downArrow from "../../../../theme/assets/svg/campaign/dropdownIconDown.svg";
import backIcon from "../../../../theme/assets/svg/campaign/backicon.svg";
import callIcon from "../../../../theme/assets/svg/campaign/callIcon.svg";
import callDisconnectIcon from "../../../../theme/assets/svg/campaign/callDisableIcon.svg";
import SearchIcon from "../../../../theme/assets/svg/generic/searchIcon.svg";
import Icon from "../../../generic/icon/Icon";
import DropdownComponent from "./DropdownComponent/DropdownComponent";
import { CalendarComponent } from "../calendarComponent/CalendarComponent";
import { useNavigate } from "react-router-dom";
import { setCallingListScreen } from "../../../../redux/campaign/actions";

interface DataType {
  key: React.Key;
  srNo: string;
  callingId: string;
  loanId: string;
  customerName: string;
  alternateNumber1?: string;
  alternateNumber2?: string;
  customerEmail?: string;
  createdAt: string;
  emiAmount: string;
  emiDueDate: string;
  flowType: string;
  isEnabled: boolean;
  language: string;
  mobile: string;
  paymentStatus: string;
  connectionStatus?: string;
  disposition?: string;
  dialedTime?: string;
  customerId?: string
}

function CallingListTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fromDate, toDate } = useSelector(
    (store: RootState) => store.filterReducer?.filteredDateRangeData
  );

  const token = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const userRole = useSelector(
    (store: any) => store.loginReducer?.userLoginInfo?.userDetail?.role
  );

  const callingListDataAll = useSelector(
    (store: RootState) => store.campaignReducer?.callingListData
  );

  const headers = {
    headers: { "Content-Type": "application/json", "x-access-token": token },
  };

  const filterByCallingListData = useSelector(
    (store: any) => store.filterReducer?.filterByCallingListData
  );

  const [callingData, setCallingData] = useState<any[]>([]);
  const [paginationData, setPaginationData] = useState<any[]>([]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchInput, setSearchInput] = useState<any>(null);
  const [load, setLoad] = useState("Loaded");
  const [totalCount, setTotalCount] = useState(0);
  const [value, onChange] = useState(["10:00 Am", "11:00 Pm"]);
  const [selectedRowData, setSelectedRowData] = useState<React.Key[]>([]);
  const [selectAllBtn, setSelectAllBtn] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [selectedData, setSelectedData] = useState(false);
  const [maxAttemptData, setMaxAttemptData] = useState();
  const [flowData, setFlowData] = useState([]);
  const [selectedLanguageData, setSelectedLanguageData] = useState([]);
  const [selectedConnectionData, setSelectedConnectionData] = useState([]);
  const [selectedDispositionData, setSelectedDispositionData] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  // const [dateFil, setDateFil] = useState<any>();
  const [listData, setListData] = useState([
    "No. of Attempts",
    "Flow",
    "Language",
    "Connection Status",
    "Disposition",
    "Disabled Accounts",
  ]);
  const [disableList, setDisableList] = useState(false);
  const [dataLength, setDataLength] = useState<number>(0);
  const [allSelected, setAllSelectedData] = useState<any>([]);
  const [startObjTime, setStartObjTime] = useState<any>(null);
  const [endObjTime, setEndObjTime] = useState<any>(null);

  const columns: ColumnsType<DataType> = [
    {
      title: "Sr.No",
      dataIndex: "srNo",
      width: 100,
      onHeaderCell: (column) => {
        return {
          style: {
            fontWeight: 500,
            fontSize: "16px",
            color: "#2C2D33",
            fontFamily: "Roboto",
          },
        };
      },
      render: (_, rowdata) => (
        <span
          className={
            rowdata?.isEnabled === false && !disableList
              ? styles.disableRowColor
              : ""
          }
        >
          {(pageNo - 1) * 11 + Number(rowdata?.srNo)}.
        </span>
      ),
    },
    {
      title:
        callingListDataAll?.useCaseId === "639053fb52d33c75af54947a"
          ? "Customer Id"
          : "Loan ID",
      dataIndex: "loanId",
      width: 150,
      onHeaderCell: (column) => {
        return {
          style: {
            fontWeight: 500,
            fontSize: "16px",
            color: "#2C2D33",
            fontFamily: "Roboto",
          },
        };
      },
      render: (_, rowData) => (
        <span
          className={`${rowData?.connectionStatus?.toLowerCase() === "connected"
            ? ""
            : styles.rowdata
            } ${rowData?.isEnabled === false && !disableList
              ? styles.disableRowColor
              : ""
            } `}
        >
          {callingListDataAll?.useCaseId === "639053fb52d33c75af54947a" ? (rowData?.customerId ? rowData?.customerId : "-") : (rowData?.loanId ? rowData?.loanId : "-")}
        </span>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      width: 200,
      onHeaderCell: (column) => {
        return {
          style: {
            fontWeight: 500,
            fontSize: "16px",
            color: "#2C2D33",
            fontFamily: "Roboto",
          },
        };
      },
      render: (_, rowData) => (
        <span
          className={`${rowData?.connectionStatus?.toLowerCase() === "connected"
            ? ""
            : styles.rowdata
            } ${rowData?.isEnabled === false && !disableList
              ? styles.disableRowColor
              : ""
            } `}
        >
          {rowData?.customerName}
        </span>
      ),
    },
    {
      title: "Phone No.",
      dataIndex: "mobile",
      width: 150,
      onHeaderCell: (column) => {
        return {
          style: {
            fontWeight: 500,
            fontSize: "16px",
            color: "#2C2D33",
            fontFamily: "Roboto",
          },
        };
      },
      render: (_, rowData) => (
        <span
          className={`${rowData?.connectionStatus?.toLowerCase() === "connected"
            ? ""
            : styles.rowdata
            } ${rowData?.isEnabled === false && !disableList
              ? styles.disableRowColor
              : ""
            } `}
        >
          {rowData?.mobile}
        </span>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "emiDueDate",
      width: 150,
      onHeaderCell: (column) => {
        return {
          style: {
            fontWeight: 500,
            fontSize: "16px",
            color: "#2C2D33",
            fontFamily: "Roboto",
          },
        };
      },
      render: (_, rowData) => (
        <span
          className={`${rowData?.connectionStatus?.toLowerCase() === "connected"
            ? ""
            : styles.rowdata
            } ${rowData?.isEnabled === false && !disableList
              ? styles.disableRowColor
              : ""
            } `}
        >
          {new Date(rowData?.emiDueDate)
            ? moment(new Date(rowData?.emiDueDate)).format("DD-MM-YYYY")
            : "-"}
        </span>
      ),
    },
    {
      title: "Language",
      dataIndex: "language",
      width: 150,
      onHeaderCell: (column) => {
        return {
          style: {
            fontWeight: 500,
            fontSize: "16px",
            color: "#2C2D33",
            fontFamily: "Roboto",
          },
        };
      },
      render: (_, rowData) => (
        <span
          className={`${rowData?.connectionStatus?.toLowerCase() === "connected"
            ? ""
            : styles.rowdata
            } ${rowData?.isEnabled === false && !disableList
              ? styles.disableRowColor
              : ""
            }  `}
        >
          {rowData?.language}
        </span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "emiAmount",
      width: 150,
      onHeaderCell: (column) => {
        return {
          style: {
            fontWeight: 500,
            fontSize: "16px",
            color: "#2C2D33",
            fontFamily: "Roboto",
          },
        };
      },
      render: (_, rowData) => (
        <span
          className={`${rowData?.connectionStatus?.toLowerCase() === "connected"
            ? ""
            : styles.rowdata
            } ${rowData?.isEnabled === false && !disableList
              ? styles.disableRowColor
              : ""
            } `}
        >
          {rowData?.emiAmount}
        </span>
      ),
    },
    {
      title: "Flow",
      dataIndex: "flowType",
      width: 150,
      onHeaderCell: (column) => {
        return {
          style: {
            fontWeight: 500,
            fontSize: "16px",
            color: "#2C2D33",
            fontFamily: "Roboto",
          },
        };
      },
      render: (_, rowData) => (
        <span
          className={`${rowData?.connectionStatus?.toLowerCase() === "connected"
            ? ""
            : styles.rowdata
            } ${rowData?.isEnabled === false && !disableList
              ? styles.disableRowColor
              : ""
            }  `}
        >
          {["noc", "femi", "iot", "npa"]?.includes(rowData?.flowType)
            ? rowData?.flowType?.toUpperCase()
            : rowData?.flowType}
        </span>
      ),
    },
    {
      title: "Connection Status",
      dataIndex: "connectionStatus",
      width: 200,
      onHeaderCell: (column) => {
        return {
          style: {
            fontWeight: 500,
            fontSize: "16px",
            color: "#2C2D33",
            fontFamily: "Roboto",
          },
        };
      },
      render: (_, rowData) => (
        <>
          {rowData?.paymentStatus?.toLocaleLowerCase() === "paid" ? (
            <></>
          ) : (
            <div
              className={`${rowData?.paymentStatus?.toLocaleLowerCase() === "paid"
                ? styles.paidBtn
                : rowData?.connectionStatus?.toLowerCase() === "connected"
                  ? styles.listConnectedbtn
                  : styles.listNotConnectedbtn
                } ${rowData?.isEnabled === false && !disableList
                  ? styles.disableTableButton
                  : ""
                }  `}
            >
              <span>
                {rowData?.connectionStatus
                  ? rowData?.connectionStatus
                  : "Yet to start"}
              </span>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Disposition",
      dataIndex: "disposition",
      width: 150,
      onHeaderCell: (column) => {
        return {
          style: {
            fontWeight: 500,
            fontSize: "16px",
            color: "#2C2D33",
            fontFamily: "Roboto",
          },
        };
      },
      render: (_, rowData) => (
        <div
          className={`${rowData?.disposition
            ? rowData?.paymentStatus?.toLocaleLowerCase() === "paid"
              ? styles.paidBtn
              : rowData?.connectionStatus?.toLowerCase() === "connected"
                ? styles.listConnectedbtn
                : styles.listNotConnectedbtn
            : ""
            } ${rowData?.isEnabled === false && !disableList
              ? styles.disableTableButton
              : ""
            } `}
        >
          <span>{rowData?.disposition}</span>
        </div>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      width: 150,
      onHeaderCell: (column) => {
        return {
          style: {
            fontWeight: 500,
            fontSize: "16px",
            color: "#2C2D33",
            fontFamily: "Roboto",
          },
        };
      },
      render: (_, rowData) => (
        <div
          className={`${rowData?.paymentStatus?.toLocaleLowerCase() === "paid"
            ? styles.paidBtn
            : rowData?.connectionStatus?.toLowerCase() === "connected"
              ? styles.listConnectedbtn
              : styles.listNotConnectedbtn
            } ${rowData?.isEnabled === false && !disableList
              ? styles.disableTableButton
              : ""
            } `}
        >
          <span>{rowData?.paymentStatus}</span>
        </div>
      ),
    },
    {
      title: "Dialed Time",
      dataIndex: "dialedTime",
      width: 150,
      onHeaderCell: (column) => {
        return {
          style: {
            fontWeight: 500,
            fontSize: "16px",
            color: "#2C2D33",
            fontFamily: "Roboto",
          },
        };
      },
      render: (_, rowData) => (
        <div
          className={`${rowData?.connectionStatus?.toLowerCase() === "connected"
            ? ""
            : styles.rowdata
            } ${rowData?.isEnabled === false && !disableList
              ? styles.disableRowColor
              : ""
            } `}
        >
          <div>
            {rowData?.dialedTime && new Date(rowData?.dialedTime)
              ? moment(new Date(rowData?.dialedTime)).format("DD-MM-YYYY")
              : ""}
          </div>
          <div style={{ fontSize: "0.75vmax" }}>
            {rowData?.dialedTime
              ? moment.utc(rowData?.dialedTime).format("h:mm A")
              : ""}
          </div>
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedRowData,
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      const callingIds = selectedRows.map((row) => row.callingId);
      console.log(
        `selectedRowKeys: ${callingIds}`,
        "selectedRows: ",
        selectedRows,
        selectedRowKeys,
        callingIds
      );
      setSelectedRowData(selectedRowKeys);
    },

    getCheckboxProps: (record: DataType) => ({
      name: record.customerName,
      disabled: record?.isEnabled === false && !disableList,
    }),
  };

  //For set date range  of campaign
  // useEffect(() => {
  //   const start = callingListDataAll?.startDate;
  //   const end = callingListDataAll?.endDate;
  //   if (start && end) {
  //     setDateFilterData({ fromDate: start, toDate: end })(dispatch);
  //   }
  // }, [callingListDataAll]);

  useEffect(() => {
    const temp: any[] = [];
    if (selectedLanguageData?.length) {
      temp.push(...selectedLanguageData);
    }
    if (flowData?.length) {
      temp.push(...flowData);
    }
    if (selectedConnectionData?.length) {
      temp.push(...selectedConnectionData);
    }
    if (selectedDispositionData?.length) {
      temp.push(...selectedDispositionData);
    }
    if (disableList) {
      temp.push("Disabled Accounts");
    }

    setAllSelectedData(temp);
  }, [
    selectedLanguageData,
    selectedConnectionData,
    selectedDispositionData,
    flowData,
    disableList,
  ]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  //function to get all table data
  const getAllCallingListData = () => {
    const startDate =
      fromDate !== null ? format(new Date(fromDate), "yyyy-MM-dd") : "";
    const endDate =
      toDate !== null ? format(new Date(toDate), "yyyy-MM-dd") : "";
    setLoad("");
    const url = SERVER_URL_CONNECTOR + "" + SCHEDULER_URL.GET_CALLINGLIST_API;
    const obj: any = {
      campaignId: callingListDataAll?.id,
      limit: 11,
      offset: pageNo,
      role: userRole,
    };
    if (fromDate?.length > 0 && toDate?.length > 0) {
      obj["startDate"] = startDate;
      obj["endDate"] = endDate;
    }
    if (searchInput?.length > 0) {
      obj["search"] = searchInput;
    }
    axios.post(url, obj, headers).then((res: any) => {
      setCallingData(res?.data?.data);
      const arrSize = Math.ceil(res.data?.totalCount / 11);
      setTotalCount(res.data?.totalCount);
      setTotalPage(arrSize);
      setLoad("Loaded");
    });
  };

  useEffect(() => {
    const ans = callingData?.sort((a: any, b: any) => {
      if (a?.isEnabled === false) {
        return 1;
      } else {
        return -1;
      }
    });
    setCallingData(ans);
  }, [callingData]);

  useEffect(() => {
    getAllCallingListData();
  }, [callingListDataAll]);

  const getPagination = () => {
    const tempData = callingData?.map((data: any, index: number) => {
      return { ...data, srNo: (index + 1).toString(), key: data.callingId };
    });

    setPaginationData(tempData);
  };

  useEffect(() => {
    getPagination();
  }, [pageNo, callingData]);

  //get Length
  useEffect(() => {
    const arr: any = callingData?.map((e: any) => {
      if (e?.isEnabled !== false) {
        return e?.callingId;
      }
    });
    setDataLength(arr?.length);
  }, [callingData]);

  //for getting all id by select all
  const getAllselect = () => {
    // const allRowKeys = callingData?.map((e) => {
    //   if (e?.isEnabled !== false) {
    //     return e?.key;
    //   } else if (disableList) {
    //     if (e?.isEnabled === false) {
    //       return e?.key;
    //     }
    //   }
    // });
    const allRowKeys = paginationData?.map((e) => {
      if (e?.isEnabled !== false) {
        return e?.key;
      } else if (disableList) {
        if (e?.isEnabled === false) {
          return e?.key;
        }
      }
    });
    setSelectedRowData(allRowKeys);
    setDataLength(allRowKeys.length);
    setSelectAllBtn(true);
  };
  const getUnSelect = () => {
    setSelectedRowData([]);
    setSelectAllBtn(false);
  };

  useEffect(() => {
    if (dataLength) {
      if (dataLength !== selectedRowData?.length) {
        setSelectAllBtn(false);
      } else if (dataLength === selectedRowData?.length) {
        setSelectAllBtn(true);
      }
    }
  }, [selectedRowData]);

  //for making disable perticuler ids
  const toHandleDisable = () => {
    if (selectedRowData?.length) {
      const url = `${SERVER_URL_CONNECTOR}${SCHEDULER_URL.CALLINGLIST_DISABLE_API}`;
      const obj = {
        callingIdList: selectedRowData,
        status: false,
      };
      axios
        .post(url, obj, headers)
        .then((res) => {
          return (
            setSelectedRowData((prev) => []),
            toast.success(
              "The selected number have been disabled from the calling list"
            ),
            // getAllCallingListData()
            setFilters()
          );
        })
        .catch((err) => console.log(err));
    }
  };

  //for  making enable perticuler ids
  const toHandleEnable = () => {
    if (selectedRowData?.length) {
      const url = `${SERVER_URL_CONNECTOR}${SCHEDULER_URL.CALLINGLIST_DISABLE_API}`;
      const obj = {
        callingIdList: selectedRowData,
        status: true,
      };
      axios
        .post(url, obj, headers)
        .then((res) => {
          return (
            setSelectedRowData((prev) => []),
            toast.success("The selected number have been successfully enabled"),
            // getAllCallingListData()
            setFilters()
          );
        })
        .catch((err) => console.log(err));
    }
  };

  const format1 = "h:mm a";

  const now = moment().hour(0).minute(0);
  const end = moment().hour(23).minute(59);

  const onDisableTime: any = (value: any, type: any) => {
    if (type === "start" && endTime) {
      const endTimeFormatted = moment(endTime).format("HH:mm");
      return value.isAfter(endTimeFormatted) ? true : false;
    }
    return false;
  };

  const options = {
    imgSrcRight: downArrow,
    imgSrcleft: "",
    placeHolderText: allSelected?.length ? allSelected : "Filter By",
  };

  const getFilteredData = (value: any) => {
    setCallingListFilter(value)(dispatch);
    setSelectedData((prev) => value);
  };

  useEffect(() => {
    if (callingListDataAll?.id) {
      getCallingListFilters(
        { campaignId: callingListDataAll.id },
        token
      )(dispatch);
    }

    if (callingListDataAll.channels?.[0]) {
      const channel = callingListDataAll.channels?.[0];
      if (channel === "Call" || channel === "WhatsApp") {
        columns.push(
          {
            title: "Alt No. -1",
            dataIndex: "alternateNumber1",
            width: 150,
            onHeaderCell: (column) => {
              return {
                style: {
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#2C2D33",
                  fontFamily: "Roboto",
                },
              };
            },
            render: (_, rowData) => (
              <span
                className={`${rowData?.connectionStatus?.toLowerCase() === "connected"
                  ? ""
                  : styles.rowdata
                  } ${rowData?.isEnabled === false && !disableList
                    ? styles.disableRowColor
                    : ""
                  } `}
              >
                {rowData?.alternateNumber1}
              </span>
            ),
          },
          {
            title: "Alt No. -2",
            dataIndex: "alternateNumber2",
            width: 150,
            onHeaderCell: (column) => {
              return {
                style: {
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#2C2D33",
                  fontFamily: "Roboto",
                },
              };
            },
            render: (_, rowData) => (
              <span
                className={`${rowData?.connectionStatus?.toLowerCase() === "connected"
                  ? ""
                  : styles.rowdata
                  } ${rowData?.isEnabled === false && !disableList
                    ? styles.disableRowColor
                    : ""
                  } `}
              >
                {rowData?.alternateNumber2}
              </span>
            ),
          }
        );
      }
      if (channel === "Mail") {
        columns.push({
          title: "Email ID",
          dataIndex: "customerEmail",
          width: 200,
          onHeaderCell: (column) => {
            return {
              style: {
                fontWeight: "500",
                fontSize: "16px",
                color: "#2C2D33",
                fontFamily: "Roboto",
              },
            };
          },
          render: (_, rowData) => (
            <span
              className={`${rowData?.connectionStatus?.toLowerCase() === "connected"
                ? ""
                : styles.rowdata
                } ${rowData?.isEnabled === false && !disableList
                  ? styles.disableRowColor
                  : ""
                } `}
            >
              {rowData?.customerEmail}
            </span>
          ),
        });
      }
    }
  }, [callingListDataAll]);

  const setFilters = () => {
    const startDate =
      fromDate !== null ? format(new Date(fromDate), "yyyy-MM-dd") : "";
    const endDate =
      toDate !== null ? format(new Date(toDate), "yyyy-MM-dd") : "";
    const temp = [];
    temp.push(`0-${maxAttemptData}`);
    setLoad("");
    const url = SERVER_URL_CONNECTOR + "" + SCHEDULER_URL.GET_CALLINGLIST_API;
    const obj: any = {
      campaignId: callingListDataAll?.id,
      limit: 11,
      offset: pageNo,
      role: userRole,
    };
    if (fromDate?.length > 0 && toDate?.length > 0) {
      obj["startDate"] = startDate;
      obj["endDate"] = endDate;
    }
    if (selectedLanguageData?.length > 0) {
      obj["language"] = selectedLanguageData;
    }
    if (selectedConnectionData?.length > 0) {
      obj["connectionStatus"] = selectedConnectionData;
    }
    if (selectedDispositionData?.length > 0) {
      obj["disposition"] = selectedDispositionData;
    }
    if (flowData?.length > 0) {
      obj["flow"] = flowData;
    }
    if (maxAttemptData) {
      obj["attempt"] = temp;
    }
    if (disableList) {
      obj["isDisabled"] = disableList;
    }
    if (searchInput?.length > 0) {
      obj["search"] = searchInput;
    }
    if (startTime?.length > 0 && endTime?.length > 0) {
      obj["startTime"] = startTime;
      obj["endTime"] = endTime;
    }
    const requestToken = axios.CancelToken.source();
    axios
      .post(url, obj, {
        headers: headers.headers,
        cancelToken: requestToken.token,
      })
      .then((res) => {
        setCallingData(res?.data?.data);
        const arrSize = Math.ceil(res.data?.totalCount / 11);
        setTotalCount(res.data?.totalCount);
        setTotalPage((prev) => arrSize);
        setLoad("Loaded");
      })
      .catch((error: any) => {
        if (axios.isCancel(error)) return;
      });
    return () => requestToken.cancel();
  };

  useEffect(() => {
    setFilters();
    console.log("kkkkkkk", startTime, endTime);
  }, [
    maxAttemptData,
    flowData,
    selectedLanguageData,
    selectedConnectionData,
    selectedDispositionData,
    disableList,
    startTime,
    endTime,
    pageNo,
    fromDate,
    toDate,
    searchInput,
  ]);

  // for disableList
  useEffect(() => {
    if (filterByCallingListData?.includes("Disabled Accounts")) {
      setDisableList(true);
    } else {
      setDisableList(false);
    }
  }, [filterByCallingListData?.length]);

  const handleClickPageNo = (page: any) => {
    if (pageNo !== page) {
      setPageNo(Number(page.selected) + 1);
    }
  };

  const formatTime = (time: any) => {
    const formattedTime = time.format("h:mm a");
    const total = formattedTime.split(":");
    const timeHr = total[0];
    const hour = timeHr === "12" ? "00" : timeHr;
    const minutes = total[1].split(" ")[0];
    const amPm = total[1].split(" ")[1];
    // console.log(formattedTime, total, hour,minutes,amPm ,"timeee");
    return `${amPm.includes("pm") ? Number(hour) + 12 : hour}:${minutes}`;
  };

  const handleTimeRange = (values: any) => {
    if (values !== null) {
      if (values[0] !== null) {
        const startTime = formatTime(values[0]);
        setStartObjTime(values[0]);
        setStartTime(startTime);
      }
      if (values[1] !== null) {
        const endTime = formatTime(values[1]);
        setEndObjTime(values[1]);
        setEndTime(endTime);
      }
    }
  };

  return (
    <div className={styles.callingListTableWrapper}>
      <div className={styles.topDiv}>
        <div className={styles.filterDiv}>
          <div className={styles.components}>
            <div className={styles.searchBoxDiv}>
              {/* <div className={styles.label}></div> */}
              <div className={styles.component}>
                <Input
                  placeholder={`${callingListDataAll?.useCaseId === "639053fb52d33c75af54947a"
                    ? "Customer Id"
                    : "Loan ID"
                    } or Phone Number`}
                  allowClear
                  prefix={<Icon img_src={SearchIcon} />}
                  onChange={handleSearchChange}
                  size="large"
                />
              </div>
            </div>
            <div className={styles.componentDiv}>
              {/* <div className={styles.label}>Date Range:</div> */}
              <div className={styles.component}>
                {/* <DateFilter id="dateRangescheduler" dateHeader={"show"} /> */}
                <CalendarComponent
                  id="dateRangeschedulers"
                  showDefaultTime={false}
                // extraPopUpStyling={styles.dataPopUp}
                // onChangeValue="true"
                // getonChangeValue={setDateFil}
                />
              </div>
            </div>
            <div className={styles.componentDiv}>
              {/* <div className={styles.label}>Time Range:</div> */}

              <div className={`${styles.component}`}>
                <TimePicker.RangePicker
                  format="h:mm a"
                  value={[startObjTime, endObjTime]}
                  onChange={(values) => {
                    handleTimeRange(values);
                  }}
                  use12Hours={true}
                  disabledTime={onDisableTime}
                  size={"large"}
                  inputReadOnly={true}
                />
              </div>
            </div>
            <div className={styles.componentDiv}>
              {/* <div className={styles.label}></div> */}
              <div className={styles.component}>
                <DropdownComponent
                  isHideFooter={true}
                  isHideSearchBar={true}
                  isHideAllCheckbox={true}
                  toBeFilterData={listData}
                  options={options}
                  extraSelectedClass="extraSelectedClass"
                  getFilteredData={(value: any) => getFilteredData(value)}
                  key="languageMultiSelectOne"
                  selectedDataOutside={selectedData}
                  isDisable={false}
                  maxAttempt={(data: any) => {
                    setMaxAttemptData(data);
                  }}
                  flowData={(data: any) => {
                    setFlowData(data);
                  }}
                  selectedLanguageData={(data: any) => {
                    setSelectedLanguageData(data);
                  }}
                  connectionStatus={(data: any) => {
                    setSelectedConnectionData(data);
                  }}
                  selectedDispositionData={(data: any) => {
                    setSelectedDispositionData(data);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.backIconDiv}>
            <img
              src={backIcon}
              className={styles.backIcon}
              onClick={() => {
                setCallingListScreen(false)(dispatch);
                navigate("/campaignmanager");
              }}
            ></img>
          </div>
        </div>

        <div className={styles.campaignNameDiv}>
          <div className={styles.campaignName}>
            {" "}
            Campaign Name -{" "}
            {callingListDataAll?.campaignName
              ? callingListDataAll?.campaignName
              : callingListDataAll?.campaignId}
          </div>
          <div className={styles.buttonContainer}>
            <div>
              <Button
                text={selectAllBtn ? "Unselect All" : "Select All"}
                extraClass={
                  selectAllBtn
                    ? styles.unselectallButtonStyle
                    : styles.selectallButtonStyle
                }
                onClick={() => (selectAllBtn ? getUnSelect() : getAllselect())}
              />
            </div>
            <div>
              <Button
                text={
                  disableList
                    ? "Enable from Calling List"
                    : "Disable from Calling List"
                }
                extraClass={
                  selectedRowData?.length
                    ? styles.disableCallButton
                    : styles.disableButtonStyle
                }
                onClick={() =>
                  disableList ? toHandleEnable() : toHandleDisable()
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.tableListDiv}>
        {/* <ConfigProvider
          theme={{
            components: {
              Table: {
                cellPaddingBlock: 5,
                cellPaddingBlockMD: 5,
                cellPaddingBlockSM: 5,
                cellPaddingInline: 5,
                cellPaddingInlineMD: 5,
                cellPaddingInlineSM: 5,
              },
            },
          }}
        > */}
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={[
            ...columns,
            {
              title: "Action",
              dataIndex: "Action",
              width: 100,
              onHeaderCell: (column) => {
                return {
                  style: {
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#fafafa",
                    fontFamily: "Roboto",
                  },
                };
              },
              render: (_, rowData) => {
                return (
                  <div className={styles.callIconDiv}>
                    <img
                      src={
                        rowData?.isEnabled === false && !disableList
                          ? callDisconnectIcon
                          : callIcon
                      }
                      className={styles.callIconStyling}
                    />
                  </div>
                );
              },
            },
          ]}
          dataSource={paginationData}
          pagination={false}
          // pagination={{
          //   defaultPageSize: 11,
          //   defaultCurrent: 1,
          //   position: ["bottomCenter"],
          //   showSizeChanger: false,
          //   total: totalCount,
          // }}
          scroll={{ y: "100%", x: "100%" }}
          bordered={false}
          loading={false}
        />
        {/* </ConfigProvider> */}
      </div>
      {totalPage > 1 ? (
        <div className={styles.paginationDiv}>
          <ReactPaginate
            nextLabel={">"}
            onPageChange={handleClickPageNo}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={totalPage}
            forcePage={pageNo - 1}
            previousLabel="<"
            pageClassName={styles["page-item"]}
            pageLinkClassName={styles["page-link"]}
            previousClassName={styles["prev"]}
            previousLinkClassName={styles["page-link"]}
            nextClassName={styles["next"]}
            nextLinkClassName={styles["page-link"]}
            breakLabel="..."
            breakClassName={styles["page-item"]}
            breakLinkClassName={styles["page-link"]}
            containerClassName={"pagination"}
            activeClassName={styles["active"]}
            renderOnZeroPageCount={null}
          />
        </div>
      ) : null}
    </div>
  );
}
export default CallingListTable;
