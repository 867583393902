import React, { useEffect, useState } from "react";
import moment from "moment";
import AudioPlayerWrapper from "../../../generic/audioPlayer/AudioPlayerWrapper";
import TranscriptHeader from "../transcript/transcriptHeader/TranscriptHeader";
import DialerWrapper from "../../callLogger/dialerWrapper/DialerWrapper";
import NewConversationCards from "../../../generic/newConversationCards/NewConversationCards";
import { getCallTranscriptAgentDesktop } from "../../../../../redux/agentDesktop/callLogger/action";
import { props as conversationProps } from "../../../generic/newConversationCards/types";
import "./CallLoggerCenter.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import { useDispatch } from "react-redux";

interface props {
  showAudioPlayer?: boolean;
}
// updatedText(pin):
// conversationId(pin): "6364fbba7c0ba9279f1c7b9c"
// speaker(pin): "Bot"
// createdAt(pin): "2022-11-04T11:47:07.804Z"
// updatedAt(pin): "2022-11-04T11:47:07.804Z"
export default function CallLoggerCenter(props: props) {
  /* Redux hooks */
  const updatedAt = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.callLogger.summary?.[0]?.agentInformation
        ?.agentAssignedTime
  );
  const transcript: any = useSelector<RootState>(
    (state) => state.agentDesktopReducer.callLogger.transcript
  );
  const audioUrl = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.callLogger.summary?.[0]?.information
        ?.recording_file_url
  );
  const selectedSessionId = useSelector(
    (store: RootState) => store.agentDesktopReducer.callLogger.selectedSessionId
  );
  const selectedConversationId = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.callLogger.selectedConversationId
  );
  const communicationType = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.communicationType
  );
  const updatedAtChat = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.chatLogger.summary?.[0]?.information
        ?.agentAssignedTime
  );
  const dispatch = useDispatch();

  const [conversation, setConversation] = useState<conversationProps[]>([]);
  useEffect(() => {
    //
    if (transcript) {
      const conversationFromTranscript: conversationProps[] = transcript.map(
        (e: any) => {
          return {
            type:
              e.speaker === "Bot" || e.speaker === "Agent"
                ? "sent"
                : "received",
            message: e?.text.join(" "),
            time: new Date(e.createdAt),
          };
        }
      );

      // adding handover time
      let pushed = false;
      let i = 0;
      const dataForSorting = new Date(updatedAtChat).setMilliseconds(
        new Date(updatedAtChat).getMilliseconds() + 1000
      );

      const newArray: conversationProps[] = [];
      while (i < conversationFromTranscript.length) {
        if (
          new Date(dataForSorting) <
            (conversationFromTranscript[i]?.time as Date) &&
          !pushed
        ) {
          newArray.push({ toShow: "chatHandover" });
          pushed = true;
        } else {
          newArray.push(conversationFromTranscript[i]);
          i++;
        }
      }
      if (!pushed) newArray.push({ toShow: "chatHandover" });
      // end
      setConversation(newArray);
    } else {
      setConversation([]);
    }
  }, [transcript, updatedAtChat]);

  useEffect(() => {
    // let interval;
    // if (selectedConversationId !== "" && communicationType === "call") {
    //   interval = setInterval(() => {
    //     dispatch(getCallTranscriptAgentDesktop(selectedConversationId));
    //   }, 2000);
    // } else {
    //   clearTimeout(interval);
    // }
    // return clearInterval(interval);
    let interval: any;
    if (communicationType === "call") {
      interval = setInterval(() => {
        handleRefreshClick();
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [communicationType, selectedConversationId]);

  useEffect(() => {
    const element = document.querySelector(".scrollable");
    element?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [conversation]);

  /* handlers ------- */
  const handleRefreshClick = () => {
    dispatch(getCallTranscriptAgentDesktop(selectedConversationId));
  };

  return (
    <div
      className="agent__call-logger-center"
      style={props.showAudioPlayer ? {} : { paddingTop: "15px" }}
    >
      {props.showAudioPlayer && (
        <div
          className={`audio-player ${
            audioUrl || audioUrl == "" ? "" : "disablePointerEventUniversaljp"
          }`}
        >
          <AudioPlayerWrapper url={audioUrl} />
        </div>
      )}
      <div className="transcript-header">
        <TranscriptHeader
          sessionId={selectedSessionId}
          dateTime={updatedAt}
          botTransferred={true}
          agentTransferred={false}
          showRefreshIcon={
            props.showAudioPlayer === undefined ? false : !props.showAudioPlayer
          }
          handleRefreshClick={handleRefreshClick}
          tooltipContent={"Call handled by AI"}
        />
      </div>
      <div
        className="transcript-chats"
        style={communicationType !== "call" ? { height: "100%" } : {}}
      >
        <div className="scrollable">
          {conversation.map((item, index) => {
            if (item?.toShow === "chatHandover") {
              if (updatedAtChat) {
                return (
                  <div className="handover-details" key={index}>
                    <span className="handover__by">
                      {"Call Transferred from AI to Agent | "}
                    </span>
                    <span className="handover__at">
                      {moment(updatedAtChat).format("DD MMM YY | h:mm A")}
                    </span>
                  </div>
                );
              }
            } else {
              return (
                <div
                  className="conversation-card"
                  key={index}
                  data-type={item.type}
                >
                  {item.message && (
                    <NewConversationCards
                      type={item.type}
                      time={item.time}
                      message={item.message}
                    />
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className="call-logger-dialer">
        <DialerWrapper />
      </div>
    </div>
  );
}
