import React, { ReactNode, createContext, useContext, useMemo } from "react";
import PubNub from "pubnub";

const PubNubContext = createContext<any | undefined>(undefined);
let pubNub;

const usePubNub = () => {
  const pubNub = useContext(PubNubContext);
  return pubNub;
};

const PubNubProvider = (props: { children: ReactNode }) => {
  pubNub = useMemo(
    () =>
      new PubNub({
        publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
        subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
        uuid: process.env.REACT_APP_PUBNUB_UUID,
        keepAlive: false,
      }),
    []
  );

  return (
    <PubNubContext.Provider value={pubNub}>
      {props.children}
    </PubNubContext.Provider>
  );
};

export { PubNubProvider, usePubNub, pubNub };
