// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oTVRfRqDXcAMnbEy6C9a .j83G4y9L6d7bH2NwDa9O{display:flex;margin-bottom:1.2em}.oTVRfRqDXcAMnbEy6C9a .j83G4y9L6d7bH2NwDa9O .COLTEypPfRpuccokgKUg{font-family:Roboto;font-size:16px;font-weight:400;text-align:left;color:#676767;margin-left:1em}.oTVRfRqDXcAMnbEy6C9a .j83G4y9L6d7bH2NwDa9O .X8xm_RH6Ow0EIXWr2xPh{font-family:Roboto;font-size:16px;font-weight:400;text-align:left;color:#0174ff;margin-left:.5em}.wYJh6XIsyAKDeJVcRJLn{background-color:rgba(135,135,135,.1450980392);text-align:center;width:100%;cursor:pointer;bottom:0;position:absolute;border-bottom-left-radius:4px;border-bottom-right-radius:4px}.K1D22MlFX8Rmr97qjR7u{transform:rotate(-180deg)}`, "",{"version":3,"sources":["webpack://./src/components/crm/moduleComponent/productDetail/ProductDetail.module.scss"],"names":[],"mappings":"AACE,4CACE,YAAA,CAEA,mBAAA,CACA,kEACE,kBAAA,CACA,cAAA,CACA,eAAA,CAEA,eAAA,CACA,aAAA,CACA,eAAA,CAEF,kEACE,kBAAA,CACA,cAAA,CACA,eAAA,CAEA,eAAA,CACA,aAAA,CACA,gBAAA,CAIN,sBACE,8CAAA,CACA,iBAAA,CACA,UAAA,CACA,cAAA,CACA,QAAA,CACA,iBAAA,CACA,6BAAA,CACA,8BAAA,CAEF,sBACE,yBAAA","sourcesContent":[".wrapper {\n  .eachAttribute {\n    display: flex;\n\n    margin-bottom: 1.2em;\n    .eachkey {\n      font-family: Roboto;\n      font-size: 16px;\n      font-weight: 400;\n\n      text-align: left;\n      color: #676767;\n      margin-left: 1em;\n    }\n    .eachvalue {\n      font-family: Roboto;\n      font-size: 16px;\n      font-weight: 400;\n\n      text-align: left;\n      color: #0174ff;\n      margin-left: 0.5em;\n    }\n  }\n}\n.tooglesections {\n  background-color: #87878725;\n  text-align: center;\n  width: 100%;\n  cursor: pointer;\n  bottom: 0;\n  position: absolute;\n  border-bottom-left-radius: 4px;\n  border-bottom-right-radius: 4px;\n}\n.rotate {\n  transform: rotate(-180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `oTVRfRqDXcAMnbEy6C9a`,
	"eachAttribute": `j83G4y9L6d7bH2NwDa9O`,
	"eachkey": `COLTEypPfRpuccokgKUg`,
	"eachvalue": `X8xm_RH6Ow0EIXWr2xPh`,
	"tooglesections": `wYJh6XIsyAKDeJVcRJLn`,
	"rotate": `K1D22MlFX8Rmr97qjR7u`
};
export default ___CSS_LOADER_EXPORT___;
