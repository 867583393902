import React, { useEffect, useState } from "react";
import DropdownFilterAccord from "../../dropdownFilter/DropdownFilterAccord";
import { firstLetterCapital } from "../../../../utils/stringmodifycase";
// import { blueFilter } from "../../../../theme/assets/svg/rightSideIcon";
import styles from "./DropdownFilter.module.scss";
import DropdownSingleSelectCheck from "../../dropdownSingleSelectCheck/DropdownSingleSelectCheck";
import { RootState } from "../../../../redux";
import { useSelector } from "react-redux";
import { useCurrentPageTab } from "../../../../hooks";
import { getDataFromSchema } from "../../../../utils/getDataFromSchema/getDataFromSchema";
import PravidIcons from "../../pravidIcons/PravidIcons";
import DropdownFilterRadio from "../../dropdownFIlterRadio/DropDownFilterRadio";
import LoaderNeon from "../../mkLoader/LoaderNeon";
import { useDispatch } from "react-redux";
import { setSelectedFilterOptions } from "../../../../redux/filters/actions";
import Loader from "../../loader/Loader";
import { filterInterface } from "./dropdonwFilter.types";
import { parentChannels } from "../../../../constants";
import { ROLES } from "../../../../constants";
export default function DropdownFilter(props: any) {
  // const whereweare=useSelector((state:RootState)=>state.dashboardReducer);
  // const sourceTab=whereweare.sourceTab;
  // const channelTab=whereweare.channelTab;
  // const whatsappChannelTab=whereweare.whatsappChannelTab;
  // const subModuleTab=whereweare.subModuleTab;
  // const loggerstate=useSelector((state:RootState)=>state.loggerReducer.loggerState.currentPage)
  // const pageType=useSelector((state:RootState)=>state.loginReducer.isActivePageType)
  // const schema =useSelector((state:RootState)=>state.loginReducer?.userLoginInfo?.schema);
  const allFilterOptions = useSelector(
    (state: RootState) => state.filterReducers?.filterOptions?.data?.filters
  );
  // ) ||
  // useSelector(
  //   (state: RootState) => state.filterReducers?.filterOptions?.data
  // );
  const dispatch = useDispatch();
  const dataClient = useSelector(
    (state: RootState) => state.filterReducers?.clientName
  );
  const accountDetails = useSelector(
    (store: any) => store.loginReducer?.userLoginInfo?.accountDetails[0]
  );
  const userRole: any = useSelector((state: any) => {
    return state.loginReducer.userLoginInfo.userDetail.role;
  });
  const defaultuser = useSelector((state: any) => {
    return state.loginReducer.userLoginInfo.accountDetails[0].name;
  });
  //getting data dfor downlaod
  // const optionsDefaultSelected = useSelector(
  //   (state: RootState) => state.filterReducers?.selectedFilterOptions
  // );

  const isLoading = useSelector(
    (state: RootState) => state.filterReducers.isLoading
  );
  const {
    schema,
    isActivePageType,
    subChannelTab,
    subModuleTab,
    channelTab,
    currentLoggerPage,
  } = useCurrentPageTab();

  const [downloadOptions, setDownloadOptions] = useState<
    Array<filterInterface>
  >([]);
  const [filterOptionstoMap, setFilterOptionstoMap] = useState<Array<any>>([]);
  useEffect(() => {
    if (
      channelTab?.name &&
      schema &&
      Object.keys(schema)?.length &&
      (subChannelTab?.name || !parentChannels.includes(channelTab?.name)) &&
      subModuleTab?.name
    ) {
      setDownloadOptions(
        getDataFromSchema({
          channel: channelTab,
          dataType: "filters",
          schemaData: schema,
          subChannel: subChannelTab,
          tab: subModuleTab,
          screen: isActivePageType,
          subScreen: currentLoggerPage,
        }) as filterInterface[]
      );
    }
  }, [
    channelTab?.name,
    schema,
    subChannelTab?.name,
    subModuleTab?.name,
    isActivePageType,
    currentLoggerPage,
  ]);

  // console.log(filterOptionstoMap, "lllll");

  useEffect(() => {
    let filterOptionstoMap = structuredClone(downloadOptions);
    filterOptionstoMap =
      allFilterOptions &&
      filterOptionstoMap.map((each) => {
        const x: any = {};
        x["name"] = each.customName;
        let options = allFilterOptions[each?.kpiDetails?.backendReference];
        x["backendRefrence"] = each?.kpiDetails?.backendReference;
        options?.sort((a: any, b: any) => b?.priority - a?.priority);
        options = options?.map(
          (e: any) => e[each?.kpiDetails?.backendReference]
        );

        x["options"] = options;
        return x;
      });
    setFilterOptionstoMap(filterOptionstoMap);
  }, [downloadOptions, allFilterOptions]);
  // useEffect(()=>{

  // },[optionsDefaultSelected])

  return (
    <>
      <div className={styles.titleofilter}>
        {/* <img src={blueFilter} alt="" /> */}
        <PravidIcons activeIcon={"blueFilter"} />
        <p className={styles.titletext}>
          {firstLetterCapital(props.selectedIcon)}
        </p>
      </div>
      {isLoading && !allFilterOptions ? (
        <Loader />
      ) : (
        <>
          <div className={styles.divide}></div>
          {(userRole.toString().toLowerCase() ===
            ROLES.campaignAnalyst.name.toLowerCase() ||
            userRole.toString().toLowerCase() ===
            ROLES.campaignManager.name.toLowerCase()) &&
            accountDetails.type === "Internal" &&
            defaultuser !== "Demo" &&
            dataClient ? (
            <DropdownFilterRadio
              options={{
                name: "Client Name",
                options: [...dataClient],
                backendRefrence: "clientName",
              }}
            />
          ) : null}

          {/* {console.log(filterOptionstoMap, "llll")} */}

          {filterOptionstoMap
            ? filterOptionstoMap
              ?.filter((obj: any) => obj?.options?.length > 0)
              .map((e: any, i: number) => {
                return (
                  <DropdownFilterAccord
                    options={e}
                    key={i}
                    disabled={false}
                  />
                );
              })
            : null}

          {/* <DropdownFilterAccord
            options={{
              isCalendar: true,
              name: "Due Date",
              position: 18,
              backendReference: "startDueDate",
              backendReference2: "endDueDate",
              createdAt: "2023-03-21T12:02:58.898Z",
              updatedAt: "2023-03-21T12:02:58.898Z",
              id: "64199cf2c30c7f32037439a5",
            }}
            disabled={false}
          /> */}
        </>
      )}
      <div className={styles.divide}></div>
    </>
  );
}
