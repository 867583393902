import React, { useRef } from "react";
import ColumnDnD from "./columnDnd/ColumnDnD";
import ColumnMultiselect from "./columnMultiselect/ColumnMultiselect";
import styles from "./ColumnCustomizeWrapper.module.scss";
import { blueTags, cross } from "../../../theme/assets/svg/rightSideIcon";
import { screenType, loggerTab, channelData, parentChannels } from "../../../constants";
// import { tags } from "../../../theme/assets/svg/rightSideIcon";

import { createPortal } from "react-dom";
import {
  setShowColumnCustomizePop,
  setShowEditColumnName,
  toggleShowColumnCustomizePop,
} from "../../../redux/filters/actions";
import { useDispatch } from "react-redux";

import { useEffect, useState } from "react";
import { useCurrentPageTab } from "../../../hooks";
import { getDataFromSchema } from "../../../utils";
import { find } from "lodash";
import {
  directlyUpdateSchema,
  updateSchemaRequest,
} from "../../../redux/onboarding/login/actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { toast } from "react-toastify";
import PravidIcons from "../../generic/pravidIcons/PravidIcons";
import { columnInterface } from "../../../screens/logger/report/type";

export default function ColumnCustomizeWrapper() {
  //const refs = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(toggleShowColumnCustomizePop());
  };
  const [showBlue, setShowBlue] = useState<boolean>(false);
  const [getOutOrder, setGetOutOrder] = useState<columnInterface[]>();
  const {
    schema,
    isActivePageType,
    subChannelTab,
    subModuleTab,
    channelTab,
    currentLoggerPage,
  } = useCurrentPageTab();

  const [allData, setAllData] = useState<{
    options: columnInterface[];
    name: string;
  }>({
    options: [],
    name: "Columns",
  });
  useEffect(() => {
    if (
      channelTab?.name &&
      schema &&
      Object.keys(schema)?.length &&
      (subChannelTab?.name || !parentChannels.includes(channelTab?.name)) &&
      subModuleTab?.name
    ) {
      const columns: columnInterface[] = getDataFromSchema({
        channel: channelTab,
        dataType: "reportColumns",
        schemaData: schema,
        subChannel: subChannelTab,
        tab: subModuleTab,
        screen: isActivePageType,
        subScreen: currentLoggerPage,
      }) as columnInterface[];
      // columns.forEach(
      //   (item: Record<string, any>) =>
      //     (item.currentName = item.currentName || item.originalName)
      // );
      setAllData({
        options: columns,
        name: "Columns",
      });
    }
  }, [
    channelTab?.name,
    schema,
    subChannelTab?.name,
    subModuleTab?.name,
    isActivePageType,
    currentLoggerPage,
  ]);

  // useEffect(() => {
  //   // console.log(allData,"ALLDATA")
  // }, [allData]);
  // const reportingcolumn = useSelector(
  //   (state: RootState) => state.filterReducers.defaultOptionsColumn
  // );
  // const userId = useSelector(
  //   (state: RootState) => state.loginReducer.userLoginInfo?.userDetail?._id
  // );
  const showCustomizeName = () => {
    dispatch(setShowEditColumnName(true));
    dispatch(setShowColumnCustomizePop(false));
    //
  };
  //const reportingcolumn = useFetchFromSchema().reportColumns;
  //dispatch(setReportColumnInDnd(reportingcolumn));
  const handleApplyChange = () => {
    toast.success("Please wait ...");
    let temp = structuredClone(allData.options);
    getOutOrder?.forEach((each, i: number) => {
      // review
      temp = temp.map((e) => {
        if (e.kpiDetails._id === each.kpiDetails._id) {
          return { ...e, position: i + 2, isActive: true };
        } else {
          return e;
        }
      });
    });
    temp = temp.map((e) => {
      if (getOutOrder?.find((ex) => ex.kpiDetails._id === e.kpiDetails._id)) {
        return { ...e, isActive: true };
      } else {
        if (
          e.kpiDetails.originalName === "Sr. No" ||
          e.kpiDetails.originalName === "Contact Number"
        ) {
          return { ...e, isActive: true };
        }
        return { ...e, isActive: false };
      }
    });

    /* uncomment ------------------ */
    // send temp to API temp
    const tempSchema = structuredClone(schema);

    // iteration
    (tempSchema.reportColumns as columnInterface[]).forEach((item) => {
      if (
        item.screen === screenType.logger.name.toLowerCase() &&
        item?.subScreen === loggerTab.report.toLowerCase() &&
        (item.channelId === channelTab.id ||
          (channelTab.childChannel?.length &&
            item.channelId === subChannelTab.id)) &&
        item.tabId === subModuleTab.id
      ) {
        const element = temp.find(
          (elm) => elm.kpiDetails._id === item.kpiDetails._id
        );
        item.isActive = element?.isActive!;
        item.position = element?.position!;
      }
    });

    //console.log(isActivePageType,currentLoggerPage,sourceTab,channelTab,subModuleTab,"TABS")

    // if (channelTab === "Call") {
    //   tempSchema[isActivePageType.toLowerCase()]["report"][sourceTab][
    //     channelTab
    //   ][!subModuleTab ? "Campaign" : subModuleTab]["reportColumns"] = temp;
    // } else {
    //   tempSchema[isActivePageType.toLowerCase()]["report"][sourceTab][
    //     channelTab
    //   ][whatsappChannelTab][!subModuleTab ? "Campaign" : subModuleTab][
    //     "reportColumns"
    //   ] = temp;
    // }
    //console.log(tempSchema,"MAKING--- TEMPSCHEMA")
    dispatch(
      updateSchemaRequest({
        id: tempSchema._id,
        teamId: tempSchema.teamId,
        type: "user",
        reportColumns: tempSchema.reportColumns,
      })
    );
    dispatch(directlyUpdateSchema(tempSchema));
    /* uncomment */

    dispatch(setShowColumnCustomizePop(false));
  };
  return createPortal(
    <div className={styles.fixed}>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <p>Edit table columns</p>{" "}
          <img onClick={handleClose} src={cross} alt="X" />
          {/* <PravidIcons
            activeIcon={"cross"}
            onClick={handleClose}
            className={styles.crosimg}
            altText="X"
          /> */}
        </div>
        <div className={styles.separators}>
          <ColumnMultiselect options={allData} />
          <ColumnDnD setOut={setGetOutOrder} />
        </div>

        <div className={styles.bottomssetioon}>
          <div
            className={styles.tagsarea}
            onMouseOver={() => {
              setShowBlue(true);
            }}
            onMouseLeave={() => {
              setShowBlue(false);
            }}
          >
            {showBlue ? (
              <PravidIcons
                activeIcon={"blueTags"}
                onClick={showCustomizeName}
                className={styles.crosimg}
                altText="Names"
              />
            ) : (
              <PravidIcons
                activeIcon={"tags"}
                onClick={showCustomizeName}
                className={styles.crosimg}
                altText="Names"
              />
            )}

            <p
              onClick={showCustomizeName}
              style={
                showBlue
                  ? { color: "#0174ff", cursor: "pointer" }
                  : { cursor: "pointer" }
              }
            >
              Custom Name
            </p>
          </div>
          <div>
            <button
              className={styles.applychanges}
              onClick={() => handleApplyChange()}
            >
              {" "}
              Apply Changes{" "}
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("root-portal")!
  );
}
