import React, { useEffect, useRef, useState } from "react";

import styles from "./SingleSelect.module.scss";

import { props } from "./types";
import { dropdownDownArrow } from "../../../../../theme/assets/svg";
import { searchIcon } from "../../../../../theme/assets/svg";
import SearchInputBox from "./searchInputBox/SearchInputBox";
import { validateInputMeta } from "../../../../../utils/validators/validators";
/**
 *  use this for single dropdown element
 *
 *
 */
export default function SingleDropdown(props: props) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selected, setSelected] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>([]);
  const [title, setTitle] = useState("");
  const ref = useRef<any>();
  useEffect(() => {
    setFilteredData(props.data);
  }, [props.data]);
  useEffect(() => {
    if (selected !== "") {
      setTitle(selected);
      props.handleChange(selected, props.objectKey);
    } else {
      setTitle(props.title);
      props.handleChange(selected, props.objectKey);
    }
  }, [selected]);

  // clear the filter
  useEffect(() => {
    if (!showDropdown) {
      setFilteredData(props.data);
    }
    // if (showDropdown) {
    //   // const dropdownElm = document.querySelector(`.${styles.dropdown}`);
    //   const listenerFunction = (e: React.MouseEvent) => {
    //     if (!ref.current?.contains(e.target)) {
    //       setShowDropdown(false);
    //       // document.removeEventListener("click", listenerFunction);
    //     }
    //   };
    //   document.addEventListener("click", listenerFunction);
    // }
  }, [showDropdown]);
  useEffect(() => {
    const listenerFunction = (e: any) => {
      if (!ref?.current?.contains(e.target)) {
        setShowDropdown(false);
        // document.removeEventListener("click", listenerFunction);
      }
    };
    if (ref) {
      // const dropdownElm = document.querySelector(`.${styles.dropdown}`);

      document.addEventListener("click", listenerFunction);
    }
    return () => {
      document.removeEventListener("click", listenerFunction);
    };
  }, [ref]);
  function filterSearch(searchText: any) {
    const val = searchText.target.value;
    if (!validateInputMeta(val)) return;
    const filter_data_temp = props.data.filter((e) => {
      return e.toLowerCase().includes(searchText.target.value.toLowerCase());
    });
    setFilteredData(filter_data_temp);
  }

  /* handlers -------- */
  const handleOtherOptionChange = (e: React.ChangeEvent) => {
    const elm = e.target as HTMLInputElement;
    setSelected(elm.value);
  };

  const formateSelectedValue = (text: string) => {
    if (props.limitSelectedTextLength) {
      if (text.length > props.limitSelectedTextLength) {
        return text.substring(0, props.limitSelectedTextLength) + "...";
      }
    }
    return text;
  };

  return (
    <>
      <span
        ref={ref}
        className={`${
          props.disableField ? "disablePointerEventWithoutOpacity" : ""
        }`}
      >
        {!props.extraDisplayElement && props.selectedItem && (
          <span className={styles["single-select-dropdown__label"]}>
            {props.title}
          </span>
        )}

        <div className={`${styles.wrapper} ${props.extraWrapperClass}`}>
          {props.extraDisplayElement ? (
            <span
              onClick={() => {
                setShowDropdown((prev) => !prev);
              }}
            >
              {props.extraDisplayElement}
            </span>
          ) : (
            <div
              className={styles.dropBox}
              onClick={() => {
                setShowDropdown((prev) => !prev);
              }}
            >
              <span
                title={
                  props.limitSelectedTextLength
                    ? props.selectedItem?.length > props.limitSelectedTextLength
                      ? props.selectedItem
                      : ""
                    : ""
                }
                className={
                  props.selectedItem ? styles["item-selected__class"] : ""
                }
              >
                {formateSelectedValue(props.selectedItem || props.title)}
              </span>
              <img src={props.icon ? props.icon : dropdownDownArrow} alt="" />
            </div>
          )}

          {showDropdown ? (
            <div className={`${styles.dropdown} ${props.extraDropdownClass}`}>
              {!props.hideSearchBox && (
                <SearchInputBox
                  searchIcon={searchIcon}
                  placeholder="Search"
                  onChange={filterSearch}
                ></SearchInputBox>
              )}
              <div className={styles.dropdownData}>
                {filteredData.map((each: any, index: any) => {
                  return (
                    <div
                      key={index}
                      data-selected={selected === each}
                      className={`${styles.eachDropdown} ${props.extraEachDropdownClass}`}
                      onClick={() => {
                        setSelected((prev) => {
                          return each;
                        });
                        setShowDropdown(false);
                      }}
                    >
                      {each}
                    </div>
                  );
                })}
                {props.hasOtherOption && (
                  <input
                    className={`${styles.eachDropdown} ${styles.otherOptionInput}`}
                    onChange={handleOtherOptionChange}
                    placeholder="Other"
                  />
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </span>
    </>
  );
}
