import React, { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./MultiLevelCheck.module.scss";
import { arrow } from "../../../theme/assets/genericSvg";

{
  /**   @example 
   <MultiLevelCheck
leftIcon={filter}
rightIcon={caratDown}
title={"Filter Options"}
allOptions={{
  Age: ["202", 24],
  Gender: ["202", 24],
  Typology: ["202", 24],
}}
defaultOptions={{ Age: ["202", 24] }}
getDataOut={setDataOut}
/>*/
}
interface PropsOfMultiCheck {
  leftIcon: string | undefined;
  rightIcon?: string | undefined;
  isOpenToLeft?: boolean;
  title: ReactNode;
  allOptions: Record<string, Array<string | number>>;
  defaultOptions?: Record<string, Array<string | number>>;
  getDataOut: React.Dispatch<React.SetStateAction<any>>;
  isSingleSelect?: boolean;
  singleIcon?: boolean;
  extraClass?: boolean;
  singleSelectField: Array<string>;
  extraClassName?: string;
}
export default function MultiLevelCheck(props: PropsOfMultiCheck) {
  const [closeDropDown, setCloseDropdown] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<
    Record<string, Array<string | number>>
  >({});
  const refD = useRef<any>();
  useEffect(() => {
    const temp = JSON.parse(JSON.stringify(props.defaultOptions));
    setSelectedData(temp);
  }, []);

  useEffect(() => {
    const handleWindowMouseDown = (event: MouseEvent) => {
      if (refD.current && !refD.current.contains(event.target as Node)) {
        setCloseDropdown(false);
      }
    };

    if (closeDropDown) {
      window.addEventListener("mousedown", handleWindowMouseDown);
    }

    return () => {
      window.removeEventListener("mousedown", handleWindowMouseDown);
    };
  }, [closeDropDown]);

  useEffect(() => {
    props.getDataOut(selectedData);
  }, [selectedData]);

  function getChecked(each: string, e: string | number): void {
    const temp = JSON.parse(JSON.stringify(selectedData));

    if (!props.singleSelectField?.includes(each)) {
      if (temp[each]?.length > 0) {
        if (temp[each].includes(e)) {
          const index = temp[each].indexOf(e);
          temp[each].splice(index, 1);
          if (temp[each].length === 0) delete temp[each];
          setSelectedData(temp);
        } else {
          temp[each].push(e);
          setSelectedData(temp);
        }
      } else {
        temp[each] = [];
        temp[each].push(e);

        setSelectedData(temp);
      }
    } else {
      if (temp[each]?.length > 0) {
        if (temp[each][0] == e) {
          delete temp[each];
        } else {
          temp[each] = [e];
        }
      } else {
        temp[each] = [e];
      }

      setSelectedData(temp);
    }
  }
  function getCheckedAll(each: string) {
    const temp = JSON.parse(JSON.stringify(selectedData));
    if (temp[each]?.length > 0) {
      delete temp[each];

      setSelectedData(temp);
    } else {
      if (!props.singleSelectField.includes(each))
        temp[each] = props.allOptions[each];
      else {
        temp[each] = [props.allOptions[each][0]];
      }
      setSelectedData(temp);
    }
  }

  return (
    <div
      ref={refD}
      className={`${props.extraClass ? styles.bgColorStyling : ""} ${
        styles.multichech_wrapper
      } ${props.extraClassName}`}
    >
      <div
        className={`${styles.top_button_multicheck}  ${
          props.singleIcon ? styles.noborder : ""
        } `}
        onClick={() => setCloseDropdown(!closeDropDown)}
      >
        {!props.singleIcon ? (
          <>
            <img
              className={styles.imgStyling}
              src={props.leftIcon}
              alt="icon"
            ></img>
            <p className={styles.title}>{props.title}</p>
            {props.rightIcon ? (
              <img src={props.rightIcon} alt="icon"></img>
            ) : (
              ""
            )}
          </>
        ) : (
          <img
            className={styles.imgStyling}
            src={props.leftIcon}
            alt="icon"
          ></img>
        )}
      </div>
      {closeDropDown &&
        (Object.keys(props.allOptions) as Array<string>).length > 0 && (
          <div
            className={
              props?.isOpenToLeft
                ? styles.main_dropdown_left
                : !props?.singleIcon
                ? styles.main_dropdown_menu
                : styles.main_dropdown
            }
          >
            {(Object.keys(props?.allOptions) as Array<string>).map(
              (each: string, i: number) => {
                return (
                  <div
                    className={styles.each_options}
                    onClick={() => getCheckedAll(each)}
                    key={i}
                  >
                    <div className={styles.input_div_text}>
                      <input
                        type="checkbox"
                        checked={
                          // selectedData[each]?.length ===
                          // props.allOptions[each]?.length
                          selectedData[each]?.length > 0 ? true : false
                        }
                        onChange={() => getCheckedAll(each)}
                        readOnly
                      />

                      <span onClick={() => getCheckedAll(each)}>{each}</span>
                    </div>
                    <div className={styles.tooltip_area}>
                      <img src={arrow} alt=">" />
                      {
                        <div
                          className={
                            props?.isOpenToLeft
                              ? styles.next_level_left
                              : styles.next_level
                          }
                        >
                          <div className={styles.mrlongs}></div>
                          {props?.allOptions[each]?.map(
                            (e: string | number) => {
                              return (
                                <div
                                  className={`${styles.next_level_each_div}        

                                  `}
                                  key={e}
                                  onClick={(ex) => {
                                    ex.stopPropagation();
                                    getChecked(each, e);
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      selectedData[each]?.includes(e)
                                        ? true
                                        : false
                                    }
                                    onChange={() => getChecked(each, e)}
                                    readOnly
                                  />

                                  {
                                    <span onClick={() => getChecked(each, e)}>
                                      {e}
                                    </span>
                                  }
                                </div>
                              );
                            }
                          )}
                        </div>
                      }
                    </div>
                  </div>
                );
              }
            )}
          </div>
        )}
    </div>
  );
}
