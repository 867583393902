import { combineReducers } from "redux";

import rootFilterReducer from "./filterReducer/filterReducer.rootReducer";
import baseScreenADReducer from "./BaseScreenAD/reducer/baseScreenAD.reducer";
import tabReducer from "./tabReducer/reducer/tab.reducer";
import dashboardReducer from "../agentDesktop/dashboard/reducer";
import callLoggerReducer from "./callLogger/reducer/callLogger.reducer";
import chatLoggerReducer from "./chatLogger/reducer/chatLogger.reducer";
const agentDesktopReducer = combineReducers({
  rootFilterReducer,
  baseScreenAD: baseScreenADReducer,
  dashboardReducer,
  callLogger: callLoggerReducer,
  chatLogger: chatLoggerReducer,
  tabReducer,
});

export default agentDesktopReducer;
