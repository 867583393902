import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogoutAction } from "../../../redux/onboarding/login/actions";
import clearCacheData from "../../../utils/clearCacheData";
import axios from "axios";
import { AGENT_DESKTOP_API } from "../../../services/ApiRoutes";
import { RootState } from "../../../redux";
import secureLocalStorage from "react-secure-storage";

export default function LogOutComponent(props: {
  resetToken: CallableFunction;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const agentId = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo.userDetail?.["_id"]
  );
  const accountId = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo.userDetail?.accountDetails?.[0]?.["id"]
  );
  const currentState = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.status.currentState
  );
  useEffect(() => {
    props.resetToken();
    navigate("/");

    return () => {
      dispatch(
        userLogoutAction({
          userId: agentId,
          refreshToken: secureLocalStorage.getItem("refresh_token") as string,
        })
      );
      localStorage.clear();
      sessionStorage.clear();
      secureLocalStorage.clear();
      // clearCacheData();
    };
  }, []);

  useEffect(() => {
    const url = AGENT_DESKTOP_API.UPDATE_STATUS;
    const data = {
      previousState: currentState,
      currentState: "Logout",
      agentId: agentId,
      accountId: accountId,
    };
    const res = axios.post(url, data);
  }, []);

  return <></>;
}
