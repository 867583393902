import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import Transcript from "./transcript/Transcript";
import Report from "./report/Report";
import { setIsActivePageType } from "../../redux/onboarding/login/actions";
import TourLogger from "../../components/moduleComponents/signUpFlow/tour/TourLogger";
import { setCurrentPage } from "../../redux/logger/loggerState/action";
import { useDispatch } from "react-redux";
export default function Logger() {
  const dispatch = useDispatch();
  //redux data
  const startLoggerTour = useSelector((state: any) => {
    return state.signUpModuleReducer?.productTourReducer?.startLoggerTour;
  });
  const currentPage = useSelector(
    (store: RootState) => store.loggerReducer.loggerState.currentPage
  );
  const showExpNowModal = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productTourReducer?.experiencePravid
  );
  const pushOnTranscript = useSelector((state: any) => {
    return state.signUpModuleReducer?.productTourReducer?.pushOnTranscriptPage;
  });
  const loginUserDetail = useSelector(
    (store: any) => store.loginReducer?.userData
  );
  //for sign up flow
  useEffect(() => {
    if (!loginUserDetail?.productShowcase?.includes("Logger")) {
      dispatch(setCurrentPage("Transcript"));
    }
  }, [loginUserDetail]);
  useEffect(() => {
    if (pushOnTranscript === true) {
      dispatch(setCurrentPage("Transcript"));
    }
  }, [pushOnTranscript]);
  console.log("nayak", startLoggerTour, showExpNowModal);
  return (
    <>
      {currentPage === "Report" ? <Report /> : <Transcript />}
      {startLoggerTour && !showExpNowModal && <TourLogger />}
    </>
  );
}
