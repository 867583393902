export default {
  SET_LOGGEDIN_USER_INFO: "SET_LOGGEDIN_USER_INFO",
  SET_PAGE_TYPE: "SET_PAGE_TYPE",
  SET_ACTIVE_PAGE_TYPE: "SET_ACTIVE_PAGE_TYPE",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  SET_SCHEMA_UPDATE_REQUEST: "SET_SCHEMA_UPDATE_REQUEST",
  SET_SCHEMA_UPDATE_SUCCESS: "SET_SCHEMA_UPDATE_SUCCESS",
  SET_SCHEMA_UPDATE_FAILURE: "SET_SCHEMA_UPDATE_FAILURE",

  SET_REQUEST_FEATURE_API_REQUEST: "SET_REQUEST_FEATURE_API_REQUEST",
  SET_REQUEST_FEATURE_SUCCESS: "SET_REQUEST_FEATURE_SUCCESS",
  SET_REQUEST_FEATURE_FAILURE: "SET_REQUEST_FEATURE_FAILURE",
  SET_REQUEST_FEATURE_STATE: "SET_REQUEST_FEATURE_STATE",
  GET_SCHEMA_OF_CLIENT_REQUEST: "GET_SCHEMA_OF_CLIENT_REQUEST",
  GET_SCHEMA_OF_CLIENT_REQUEST_SUCCESS: "GET_SCHEMA_OF_CLIENT_REQUEST_SUCCESS",
  GET_SCHEMA_OF_CLIENT_REQUEST_FAILURE: "GET_SCHEMA_OF_CLIENT_REQUEST_FAILURE",
  GET_TEAM_DATA_REQUEST: "GET_TEAM_DATA_REQUEST",
  GET_TEAM_DATA_SUCCESS: "GET_TEAM_DATA_SUCCESS",
  GET_TEAM_DATA_FAILURE: "GET_TEAM_DATA_FAILURE",

  GET_ALL_CLIENT_DATA_REQUEST: "GET_ALL_CLIENT_DATA_REQUEST",
  GET_ALL_CLIENT_DATA_SUCCESS: "GET_ALL_CLIENT_DATA_SUCCESS",
  GET_ALL_CLIENT_DATA_ERROR: "GET_ALL_CLIENT_DATA_ERROR",

  UPDATE_USER_DATA_REQUEST: "UPDATE_USER_DATA_REQUEST",
  UPDATE_USER_DATA_SUCCESS: "UPDATE_USER_DATA_SUCCESS",
  UPDATE_USER_DATA_ERROR: "UPDATE_USER_DATA_ERROR",

  GET_USER_DATA_REQUEST: "GET_USER_DATA_REQUEST",
  GET_USER_DATA_SUCCESS: "GET_USER_DATA_SUCCESS",
  GET_USER_DATA_ERROR: "GET_USER_DATA_ERROR",

  GET_TEAM_SCHEMA_REQUEST: "GET_TEAM_SCHEMA_REQUEST",
  GET_TEAM_SCHEMA_SUCCESS: "GET_TEAM_SCHEMA_SUCCESS",
  GET_TEAM_SCHEMA_FAILURE: "GET_TEAM_SCHEMA_FAILURE",

  GET_TEAM_TAB_REQUEST: "GET_TEAM_TAB_REQUEST",
  GET_TEAM_TAB_SUCCESS: "GET_TEAM_TAB_SUCCESS",
  GET_TEAM_TAB_FAILURE: "GET_TEAM_TAB_FAILURE",

  GET_TEAM_CHANNEL_REQUEST: "GET_TEAM_CHANNEL_REQUEST",
  GET_TEAM_CHANNEL_SUCCESS: "GET_TEAM_CHANNEL_SUCCESS",
  GET_TEAM_CHANNEL_FAILURE: "GET_TEAM_CHANNEL_FAILURE",

  GET_SIDEBAR_MODULE_REQUEST: "GET_SIDEBAR_MODULE_REQUEST",
  GET_SIDEBAR_MODULE_SUCCESS: "GET_SIDEBAR_MODULE_SUCCESS",
  GET_SIDEBAR_MODULE_FAILURE: "GET_SIDEBAR_MODULE_FAILURE",

  USER_LOGOUT: "USER_LOGOUT",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  USER_LOGOUT_FAILURE: "USER_LOGOUT_FAILURE",

  REFRESH_TOKEN_API_REQUEST: "REFRESH_TOKEN_API_REQUEST",
  REFRESH_TOKEN_API_SUCCESS: "REFRESH_TOKEN_API_SUCCESS",
  REFRESH_TOKEN_API_FAILURE: "REFRESH_TOKEN_API_FAILURE",

  VERIFY_INVITE_REQUEST: "VERIFY_INVITE_REQUEST",
  VERIFY_INVITE_SUCCESS: "VERIFY_INVITE_SUCCESS",
  VERIFY_INVITE_FAILURE: "VERIFY_INVITE_FAILURE",
};
