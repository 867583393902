// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qFKCoRfMkRyYHLKMOffg{width:100%;height:100%;padding-top:1em}`, "",{"version":3,"sources":["webpack://./src/screens/profile/paymentScreen/PaymentScreen.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".paymentScreenWrapper {\n  width: 100%;\n  height: 100%;\n  padding-top: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentScreenWrapper": `qFKCoRfMkRyYHLKMOffg`
};
export default ___CSS_LOADER_EXPORT___;
