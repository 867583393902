import React, { useRef } from "react";
import styles from "./UploadPicture.module.scss";

import { uploadGif } from "../../../theme/gif";
import { deleteIcon } from "../../../theme/assets/profileSectionSvg";

import InputField from "../inputField/InputField";
import Button from "../button/Button";
import { InfoToolTip } from "../infoToolTip/InfoToolTip";

interface UploadPictureProps {
  isPicLoading?: boolean;
  isUploadValid?: boolean;
  isDeleteValid?: boolean;
  picture?: any;
  channel?: string;
  handleUploadFunc: CallableFunction;
  handleDeleteFunc: CallableFunction;
  handleFileUploadFunc: any;
  hiddenFileInputRef: any;
  errorMsg?: string;
  infoMsg?: any;
  tooltipSize?: any;
}

export const UploadPicture = ({
  isPicLoading,
  isDeleteValid,
  isUploadValid,
  channel,
  picture,
  handleUploadFunc,
  handleDeleteFunc,
  handleFileUploadFunc,
  hiddenFileInputRef,
  errorMsg,
  infoMsg,
  tooltipSize,
}: UploadPictureProps) => {
  const hiddenFileInput = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleClickUpload = () => {
    hiddenFileInput.current.click();
  };

  // console.log(picture, errorMsg, "lll");
  return (
    <div className={styles.uploadPictureContent}>
      <div className={styles.uploadPictureWrapper}>
        <div className={styles.uploadPictureDiv}>
          {isPicLoading ? (
            <img
              src={uploadGif}
              id={`auto_${channel}_picture_loading`}
              className={styles.uploadGifStyling}
            />
          ) : picture && picture !== "" ? (
            <img
              src={picture}
              // src={`${picture}?${process.env.REACT_APP_ACCESS_TOKEN_USERUPLOADS}`}
              id={`auto_${channel}_picture`}
              className={styles.uploadPictureStyling}
              onClick={handleClickUpload}
            />
          ) : (
            <div
              className={styles.uploadPictureTextStyling}
              onClick={handleClickUpload}
            >
              Upload Display Photo
            </div>
          )}
        </div>

        <div>
          <div className={styles.uploadPictureInfoDiv}>
            <p className={styles.uploadImageText}>
              Add PNG, max 5 MB, &nbsp;
              {channel === "trueCaller" ? "200px" : "640px"}
            </p>
            <div
              id={`auto_${channel}_image_tooltip`}
              className={styles.uploadImageIcon}
            >
              <InfoToolTip
                message={infoMsg}
                isError={false}
                position={"top-end"}
                isIconSmall
                toolTipLarge={tooltipSize}
              />
            </div>
          </div>
          <div className={styles.uploadPictureButton}>
            <Button
              text="Upload Picture"
              extraClass={`${styles.buttonStyling} ${styles.uploadButtonStyle}`}
              onClick={handleClickUpload}
              disabled={isUploadValid}
            />

            <InputField
              ref={hiddenFileInput}
              onChange={handleFileUploadFunc}
              extraClass={styles.inputUploadStyling}
              type="file"
              accept=" .png "
            />

            <Button
              text="Delete"
              extraClass={`${styles.buttonStyling} ${styles.deleteButtonStyle}`}
              image_src_left={deleteIcon}
              onClick={handleDeleteFunc}
              disabled={isDeleteValid}
              imgClass={styles.ImageClassStyling}
            />
          </div>
        </div>
      </div>
      <div className={styles.uploadPictureContentError}>
        {errorMsg ? errorMsg : ""}
      </div>
    </div>
  );
};
