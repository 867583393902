import { combineReducers } from "@reduxjs/toolkit";
import inviteUserReducer from "./inviteUser/reducer/inviteUser.reducer";
import productPageReducer from "./productPage/reducer";
import teamsPageReducer from "./teamsPage/reducer";
import signUpReducer from "./signUp/reducer";
import productTourReducer from "./productTour/reducer";
const rootSignUpFlowReducer = combineReducers({
  inviteUserReducer: inviteUserReducer,
  productPageReducer: productPageReducer,
  productTourReducer: productTourReducer,
  signUp: signUpReducer,
  teamsPageReducer: teamsPageReducer,
});
export default rootSignUpFlowReducer;
