import { takeLatest } from "redux-saga/effects";
import actionTypes from "../exclusionsList.actionTypes";
import {
  getPhoneNumberAPIWorker,
  addPhoneNumberAPIWorker,
  addPhoneNumberCsvAPIWorker,
  removePhoneNumberAPIWorker,
} from "./exclusionsList.saga";

export default function* exclusionsListWatcher(): any {
  yield takeLatest(
    actionTypes.GET_PHONE_NUMBER_REQUEST,
    getPhoneNumberAPIWorker
  );

  yield takeLatest(
    actionTypes.ADD_PHONE_NUMBER_REQUEST,
    addPhoneNumberAPIWorker
  );

  yield takeLatest(
    actionTypes.ADD_PHONE_NUMBER_CSV_REQUEST,
    addPhoneNumberCsvAPIWorker
  );

  yield takeLatest(
    actionTypes.REMOVE_PHONE_NUMBER_REQUEST,
    removePhoneNumberAPIWorker
  );
}
