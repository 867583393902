import actionTypes from "../actionTypes";

export const getAgentDesktopSchema = (role: string) => {
  return { type: actionTypes.GET_AGENT_DESKTOP_SCHEMA_REQUEST, payload: role };
};

export const setCallStatus = (
  status:
    | "accept"
    | "decline"
    | ""
    | "hold"
    | "disconnect"
    | "missed"
    | "mute"
    | "unMute"
    | "unHold"
) => {
  return { type: actionTypes.SET_CALL_STATUS_AGENT_DESKTOP, payload: status };
};

export const setRingTime=(date:string)=>{
  return {
    type: actionTypes.SET_RING_TIME,
    payload: date
  }
}

export const setCallerData = (
  callerName: string,
  callerNumber: string,
  sessionId: string,
  startTime: string
) => {
  return {
    type: actionTypes.SET_CALLER_DATA_AGENT_DESKTOP,
    payload: { callerName, callerNumber, sessionId ,startTime},
  };
};

export const setCommunicationType = (type: string) => {
  return {
    type: actionTypes.SET_COMMUNICATION_TYPE_AGENT_DESKTOP,
    payload: type,
  };
};

export const setInQueueCalls = (inQueueCalls: number) => {
  return {
    type: actionTypes.SET_IN_QUEUE_CALL_AGENT_DESKTOP,
    payload: inQueueCalls,
  };
};
export const setAcceptData=(callStatusData: object)=>{
  return {
    type: actionTypes.SET_CALL_STATUS_DATA,
    payload: callStatusData,
  };
}
export const setInQueueChat = (inQueueChat: number) => {
  return {
    type: actionTypes.SET_IN_QUEUE_CHAT_AGENT_DESKTOP,
    payload: inQueueChat,
  };
};

export const setMessengerData = (
  name: string,
  message: string,
  sessionId: string
) => {
  return {
    type: actionTypes.SET_MESSENGER_DATA_AGENT_DESKTOP,
    payload: { name, message, sessionId },
  };
};

export const setSendChatSignal = (
  value: "close" | "send" | "sendAgain" | ""
) => {
  return {
    type: actionTypes.SET_SEND_CHAT_SIGNAL_AGENT_DESKTOP,
    payload: value,
  };
};

export const setOngoingCallChatSessionId = (sessionId: string) => {
  return {
    type: actionTypes.SET_ONGOING_CALL_CHAT_SESSION_ID,
    payload: sessionId,
  };
};

export const handleCallFinishActions = () => {
  return { type: actionTypes.HANDLE_CALL_FINISH_AGENT_DESKTOP };
};

export const handleChatFinishAction = () => {
  return { type: actionTypes.HANDLE_CHAT_FINISH_AGENT_DESKTOP };
};
export const setSelectedStatus = (bodyData: object) => {
  return { type: actionTypes.SET_UPDATED_STATUS, payload: bodyData };
};

export const setOngoingCallChatConversationId = (conversationId: string) => {
  return {
    type: actionTypes.SET_ONGOING_CALL_CHAT_CONVERSATION_ID,
    payload: conversationId,
  };
};
export const getAgentStatus = () => {
  return { type: actionTypes.GET_AGENT_DESKTOP_STATUS_API_CALL };
};
