import React, { useEffect, useRef, useState } from "react";
import InputContainer from "../../../components/generic/inputContainer/InputContainer";
import Button from "../../../components/generic/button/Button";
import { loadingFourDot, tick } from "../../../theme/gif";
import {
  setPasswordData,
  createPasswordRequest,
  setFreeCreditTimer,
  setResetPasswordApi,
} from "../../../redux/signUpFlow/signUp/action";
import { hidden, visibility, passwordKeyIcon } from "../../../theme/assets/svg";
import { PasswordConditions } from "../../../components/generic/passwordValidation/PasswordConditions";

import signUpStyles from "../signUp/SignUp.module.scss";
import styles from "./CreatePassword.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ErrorMessage from "../../../components/generic/errorMessage/ErrorMessage";
import axios from "axios";
import { ONBOARDING_URL } from "../../../services/ApiRoutes";
import {
  setIsPageType,
  setLoggedInUserInfo,
  setUserData,
  verifyInviteToken,
} from "../../../redux/onboarding/login/actions";

import { setLoginTime } from "../../../redux/baseScreen/leftMenu";
import { Mixpanel } from "../../../utils/mixpanelSetup";
import { format } from "date-fns";
import { mixpannelLogin } from "../../../redux/mixpanel/actions";
import secureLocalStorage from "react-secure-storage";

const CreatePassword = (props: any) => {
  /*****************************  redux hooks  *****************************/
  const history = useNavigate();
  const passwordData = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (store: any) => store.signUpModuleReducer.signUp.passwordData
  );
  const createPasswordAPI = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (store: any) => store.signUpModuleReducer.signUp.createPasswordAPI
  );
  const resetPasswordData = useSelector(
    (store: any) => store.signUpModuleReducer.signUp.resetPasswordDetail
  );
  const dispatch = useDispatch();

  /*****************************  other hooks  *****************************/
  const prams = useParams();
  const navigate = useNavigate();
  const _isMount = useRef(false);

  // userId and email
  const location = useLocation();
  const queryPrams = new URLSearchParams(location.search);
  const userName = queryPrams.get("userName");
  const emailId = queryPrams.get("emailId");
  const userId = queryPrams.get("id");
  /*****************************  useStates  *****************************/
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [showCreatePassword] = useState(true);
  const [passwordCreated, setPasswordCreated] = useState(false);
  const [btnStatus, setBtnStatus] = useState<
    "noAction" | "loading" | "success" | "error"
  >("noAction");
  const [showPassword, setShowPassword] = useState<Record<string, boolean>>({
    newPassword: false,
    confirmNewPassword: false,
  });
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [pageErrorMsg, setPageErrorMsg] = useState("");
  const [passwordCheck, setPasswordCheck] = useState({
    nameMatch: false,
    charMinLength: false,
    uppercase: false,
    lowercase : false,
    numChar: false,
  });

  /*****************************  useEffects  *****************************/
  useEffect(() => {
    if (prams.token) dispatch(verifyInviteToken(prams.token));
  }, []);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (_isMount.current) {
      if (
        !createPasswordAPI.isLoading &&
        Object.keys(createPasswordAPI.data).length !== 0
      ) {
        setBtnStatus("success");
        timeout = setTimeout(() => {
          handleSubmit();
          setPasswordCreated(true);
          setBtnStatus("noAction");
        }, 2000);
      } else if (
        !createPasswordAPI.isLoading &&
        createPasswordAPI.error &&
        Object.keys(createPasswordAPI.error).length !== 0
      ) {
        setBtnStatus("noAction");
        if (createPasswordAPI.error === "Please provide valid token") {
          setPageErrorMsg("Invalid token, please use valid link.");
        } else {
          setPageErrorMsg(createPasswordAPI.error);
        }
      }
    } else {
      _isMount.current = true;
    }
    return () => clearTimeout(timeout);
  }, [createPasswordAPI]);

  useEffect(() => {
    const newPassword: string = passwordData.newPassword;
    // name match
    if (!newPassword.includes(userName as string) && newPassword.length > 0) {
      setPasswordCheck((prev) => {
        return { ...prev, nameMatch: true };
      });
    } else {
      setPasswordCheck((prev) => {
        return { ...prev, nameMatch: false };
      });
    }

    // charMinLength
    if (newPassword.length >= 8) {
      setPasswordCheck((prev) => {
        return { ...prev, charMinLength: true };
      });
    } else {
      setPasswordCheck((prev) => {
        return { ...prev, charMinLength: false };
      });
    }

    // uppercase
    if (/[A-Z]/.test(newPassword)) {
      setPasswordCheck((prev) => {
        return { ...prev, uppercase: true };
      });
    } else {
      setPasswordCheck((prev) => {
        return { ...prev, uppercase: false };
      });
    }

  // lowercase
  if (/[a-z]/.test(newPassword)) {
    setPasswordCheck((prev) => {
      return { ...prev, lowercase: true };
    });
  } else {
    setPasswordCheck((prev) => {
      return { ...prev, lowercase: false };
    });
  }


    //numSpecialCharacter
    //  /[\d`~!@#$%&*()+=|;:'",.<>?/^\\]/
    if ( /^(?=.*\d)(?=.*[\W_]).*$/.test(newPassword)) {
      setPasswordCheck((prev) => {
        return { ...prev, numChar: true };
      });
    } else {
      setPasswordCheck((prev) => {
        return { ...prev, numChar: false };
      });
    }
  }, [passwordData.newPassword]);

  /*****************************  handlers  *****************************/
  const handleSubmit = async () => {
    const start = new Date().getTime();
    // e.preventDefault();
    // const signInUrl = SERVER_URL + ONBOARDING_URL.SIGN_IN;
    // const checkingEmailLP = emailValidation(userCreditials.email);
    // const checkingPasswordLP = passwordValidation(userCreditials.password);
    // if (!checkingEmailLP.isValid || !checkingPasswordLP.isValid) {
    //   setErrorHandle((previousState) => {
    //     return {
    //       ...previousState,
    //       emailInvalid: "Please enter correct email",
    //       passwordInvalid: checkingPasswordLP.errors.message,
    //     };
    //   });
    //   return;
    // }
    const data = {
      userName: emailId,
      password: passwordData.newPassword,
    };
    const headers = { "Content-Type": "application/json" };
    //console.log(signInUrl)
    setIsLoginLoading(true);
    const res = await axios
      .post(ONBOARDING_URL.LOGIN2, data, { headers })
      .then((res) => {
        if (!res.data.data.userDetail.isActive) {
          setPageErrorMsg("Inactive user");
          return;
        }

        // res.data.data["moduleDetails"] =
        //   res.data.data.userDetail?.moduleDetails;

        //  //let tempresjj = { ...res.data.data, schema: schema };
        // dispatch(
        //   getAllFiltersOptionsForAccount(tempresjj?.accountDetails[0]?.name)
        // );
        // // tempresjj.schema = schema;
        // // //tempresjj["sourceDetails"] = sourceDetails;
        // //console.log("loginUserInfo", { ...res.data.data, schema: schema });
        const token = res.data.data.userSessionDetails.accessToken;
        const refreshToken = res.data.data.userSessionDetails.refreshToken;
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        dispatch(setLoggedInUserInfo(res.data.data));
        secureLocalStorage.setItem("refreshToken", refreshToken);
        secureLocalStorage.setItem("jwt_token", token);
        secureLocalStorage.setItem(
          "jwt_token_time",
          new Date() as never as string
        );
        window.localStorage.setItem("userId", res.data.data.userDetail._id);

        dispatch(setUserData(res.data.data.userDetail));
        if (res.data.data.accountDetails?.[0]?.experienceNowTimer)
          dispatch(
            setFreeCreditTimer(
              res.data.data.accountDetails[0].experienceNowTimer
            )
          );
        // const { dataModules, defaultSelected } = getLeftSideModuleDetails(
        //   _.cloneDeep(res.data.data.moduleDetails)
        // );

        // dispatch(setLeftSideModuleDetails(dataModules));
        // dispatch(setDefaultSelectedModule(defaultSelected));
        // dispatch(setIsActivePageType(defaultSelected.name));
        // dispatch(setHighlightedModule(defaultSelected.name));
        const curretTime = new Date();
        console.log(curretTime, "cccop");
        dispatch(setLoginTime(curretTime));

        // const subModuleMapping = mapSubModulesToModulesFunc(
        //   res.data.data.moduleDetails
        // );
        // dispatch(setSubModuleMappingToModule(subModuleMapping));
        // const urlToModulesMapping = mapUrlToModulesFunc(
        //   res.data.data.moduleDetails
        // );
        // console.log(urlToModulesMapping);
        // dispatch(setUrlToModuleMapping(urlToModulesMapping));
        const mixPanelUserobj = {
          $name: res.data.data.userDetail?.name,
          $id: res.data.data.userDetail?._id,
          $email: res.data.data.userDetail?.email,
        };

        Mixpanel.identify(res.data.data.userDetail?.accountDetails?.[0]?.id);
        Mixpanel.people.set({
          $name: res.data.data.userDetail?.name,
          $id: res.data.data.userDetail?._id,
          $email: res.data.data.userDetail?.email,
        });
        Mixpanel.track("Homepage | Login | Logged In", {
          "user Name": res.data.data.userDetail?.name,
          count: 1,
          Date: format(new Date(), "dd-MM-yyyy"),
        });
        const mixpannelLoginDetails = {
          startTime: new Date().getTime() / 1000,
          accountName: res.data.data.userDetail?.name,
        };
        dispatch(mixpannelLogin(mixpannelLoginDetails));

        const rrolr = res.data.data.userDetail.role;
        if (res.data.data.userDetail?.isProductPageShown === true) {
          history("/productPage");
        } else if (rrolr === "Camapign Manager") {
          // dispatch(setIsActivePageType("conversation logger"));
          dispatch(setIsPageType("conversation logger"));
          window.sessionStorage.setItem("isActive", "conversation logger");
          sessionStorage.setItem("pageType", "conversation logger");
          history("/calllogger");
        } else {
          if (
            rrolr.toString().toLowerCase() === "guest" ||
            rrolr.toString().toLowerCase() === "owner"
          ) {
            // dispatch(setIsActivePageType("demo"));
            dispatch(setIsPageType("demo"));
            window.sessionStorage.setItem("isActive", "demo");
            sessionStorage.setItem("pageType", "demo");
            history("/demo");
          } else {
            window.sessionStorage.setItem("isActive", "dashboard");
            sessionStorage.setItem("pageType", "Analytics Dashboard");
            // dispatch(setIsActivePageType("dashboard"));

            //console.log("abhishek 12222", tempresjj);
            // history(`/${defaultSelected.url}`);
            // dispatch(setIsPageType("dashboard"));
            //console.log("abhishek 12", tempresjj);
            history("/dashboard");
          }
        }

        props.setToken(res.data.data.userSessionDetails.accessToken);
        // console.log("ggg", res.data.data.userSessionDetails.accessToken);
        sessionStorage.setItem("Id", res.data.data.userSessionDetails.userId);
        sessionStorage.setItem("email", res.data.data.userDetail.email);
        sessionStorage.setItem("name", res.data.data.userDetail.name);
        sessionStorage.setItem("role", res.data.data.userDetail.role);
        // sessionStorage.setItem("isActive", res.data.data.userDetail.isActive);
        const accountNametemp = res.data.data.userDetail.accountDetails[0].name;
        sessionStorage.setItem("accountName", accountNametemp);

        // // // To get all types filters from store
        // props.getFilterDetails(accountNametemp);

        // // to get all client info
        // props.getClientDetails();

        sessionStorage.setItem(
          "accountId",
          res.data.data.userDetail.accountDetails[0].id
        );
        sessionStorage.setItem("language", res.data.data.userDetail.language);
        sessionStorage.setItem(
          "userType",
          res.data.data.accountDetails[0].type
        );
        // axios
        //   .get(
        //     `https://${process.env.REACT_APP_SERVER_URL}/api/accounts/account/v1/all`
        //   )
        //   .then((response) => {
        //     const fdata = response?.data?.data;
        //     const type = fdata.filter((each: any) => {
        //       if (
        //         each?.name?.toLowerCase() ===
        //         res?.data?.data?.userDetail?.accountDetails[0]?.name?.toLowerCase()
        //       ) {
        //         return each;
        //       }
        //     });
        //     sessionStorage.setItem(
        //       "userType",
        //       res.data.data.accountDetails[0].type
        //     );
        //     // sessionStorage.setItem("userType",type[0].type)
        //     const typeOfUser = type[0].type;
        //     // props?.setUserType("SET_USER_TYPE", typeOfUser);
        //   });
      })
      .catch((e) => {
        setPageErrorMsg(
          e?.response?.data?.error || "Unable to process request."
        );
      });
    const end = new Date().getTime();
    setIsLoginLoading(false);
  };

  const handleResetPassword = () => {
    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      setConfirmPasswordError("Both password should match.");
    } else {
      const dataTosend = {
        body: {
          id: userId,
          password: passwordData.newPassword,
        },
        header: { "Content-type": "application/json" },
        fun: () => navigate("/login"),
      };
      dispatch(setResetPasswordApi(dataTosend));
    }
    console.log("call");
  };

  const handleCreateClick = () => {
    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      setConfirmPasswordError("Both password should match.");
    } else {
      dispatch(
        createPasswordRequest(passwordData.newPassword, prams.token as string)
      );
      setBtnStatus("loading");
      // navigate(
      //   `/login?password=${passwordData.newPassword}&email=${prams.email}`
      // );
    }
  };
  const handleInputFormChange = (
    e: React.MouseEvent<HTMLInputElement>,
    key: string
  ) => {
    const value = e.currentTarget.value.trim();
    setConfirmPasswordError("");
    setPageErrorMsg("");
    dispatch(setPasswordData(key, value));
  };
  const handleRightIconClick = (e: React.MouseEvent<HTMLInputElement>) => {
    const key = e.currentTarget.dataset.key;
    setShowPassword((prev) => {
      return {
        ...prev,
        [key as string]: !prev[key as string],
      };
    });
  };

  return (
    <>
      {showCreatePassword ? (
        <div className={styles["create-password__wrapper"]}>
          <div className={`header ${signUpStyles["sign-up__header"]}`}>
            <h3>Create New Password</h3>
          </div>
          <div className={styles["body"]}>
            <InputContainer
              showLabel={true}
              label="Enter New Password"
              inputAutoComplete="false"
              inputIconLeft={passwordKeyIcon}
              inputOnChange={handleInputFormChange}
              inputPlaceholder={"Enter New Password"}
              keyName={"newPassword"}
              extraUserInputClass={signUpStyles["extraUserInputClass"]}
              inputValue={passwordData.newPassword}
              inputIconRight={showPassword.newPassword ? hidden : visibility}
              onClickRightIcon={handleRightIconClick}
              inputType={showPassword.newPassword ? "text" : "password"}
              passwordMaskClass={styles.passwordMaskClass}
              maxLength={50}
            />
            <div
              className={`${styles["password-validation"]} ${
                !passwordData.newPassword && styles["hide__password-validation"]
              }`}
            >
              <PasswordConditions
                nameMatch={passwordCheck.nameMatch}
                charMinLength={passwordCheck.charMinLength}
                uppercase={passwordCheck.uppercase}
                lowercase={passwordCheck.lowercase}
                numChar={passwordCheck.numChar}
              />
            </div>
            <InputContainer
              showLabel={true}
              label="Confirm New Password"
              errorMsg={confirmPasswordError}
              inputAutoComplete={"false"}
              inputIconLeft={passwordKeyIcon}
              inputOnChange={handleInputFormChange}
              inputPlaceholder={"Confirm New Password"}
              keyName={"confirmNewPassword"}
              extraUserInputClass={signUpStyles["extraUserInputClass"]}
              inputValue={passwordData.confirmNewPassword}
              inputIconRight={
                showPassword.confirmNewPassword ? hidden : visibility
              }
              onClickRightIcon={handleRightIconClick}
              inputType={showPassword.confirmNewPassword ? "text" : "password"}
              passwordMaskClass={styles.passwordMaskClass}
              maxLength={50}
            />
          </div>
          <div className={styles["footer"]}>
            <div
              className={`${signUpStyles.errorContainer} ${
                pageErrorMsg && signUpStyles.showErrorMessage
              }`}
            >
              <ErrorMessage
                errorMessage={pageErrorMsg}
                extraClass={signUpStyles.extraErrorMessageClass}
              />
            </div>
            <Button
              btnContents={{
                loading: {
                  icon: loadingFourDot,
                  class: signUpStyles["extraBtnConditionalClass"],
                },
                noAction: { text: passwordCreated ? "Logging In.." : "Create" },
                success: {
                  icon: tick,
                  class: signUpStyles["extraBtnConditionalClass"],
                },
              }}
              btnStatus={btnStatus}
              extraClass={signUpStyles["extraBtnClass"]}
              disabled={
                !passwordCheck.charMinLength ||
                !passwordCheck.nameMatch ||
                !passwordCheck.numChar ||
                !passwordCheck.uppercase ||
                !passwordCheck.lowercase ||
                !passwordData.confirmNewPassword ||
                pageErrorMsg !== "" ||
                passwordCreated ||
                resetPasswordData?.isLoading === true
              }
              onClick={
                props.resetPassword ? handleResetPassword : handleCreateClick
              }
              extraBtnImageClass={signUpStyles["extraBtnImageClass"]}
            />
          </div>
        </div>
      ) : (
        <div className={`header ${signUpStyles["sign-up__header"]}`}>
          <h3>Invalid link redirecting to login...</h3>
        </div>
      )}
    </>
  );
};

export default CreatePassword;
