import React from "react";
import styles from "./DomainCard.module.scss";
import PravidIcons from "../../../../generic/pravidIcons/PravidIcons";
import Icon from "../../../../generic/icon/Icon";
import { ConfirmPopUp } from "../../confirmPopUp/ConfirmPopUp";
import { lockIcon } from "../../../../../theme/assets/svg";
import ToolTip from "../../../../generic/toolTip/ToolTip";
interface DomainCardProps {
  domainData: any;
  domainIndex?: number;
  onClickDomain?: any;
  isSelected?: string;
  isShowDomainPopUp?: boolean;
  isCloseDomainPopUp?: any;
  onClickYes?: any;
}
export const DomainCard = ({
  domainData,
  domainIndex,
  onClickDomain,
  isSelected,
  isShowDomainPopUp,
  isCloseDomainPopUp,
  onClickYes,
}: DomainCardProps) => {
  return (
    <div
      className={`${
        domainData?.isDisabled === "true" ? styles.domainToolTip : ""
      }  `}
    >
      <div
        className={`${
          domainData?.isDisabled === "true" ? styles.disabledDomainDiv : ""
        }  `}
      >
        <div
          className={`${styles.domainCardWrapper} ${
            isSelected === domainData?.name ? styles.domainCardSelectedDiv : ""
          } `}
          id={`auto_domain_${domainIndex}`}
          onClick={onClickDomain}
          data-testid="domain-card"
        >
          <div className={styles.domainCardTopDiv}>
            <PravidIcons
              activeIcon={
                domainData?.isDisabled === "true"
                  ? domainData.lockedIcon
                  : domainData.icon
              }
              extraClass={styles.domainIconStyling}
              id={`auto_${domainData.icon}_icon`}
            />
            <div
              className={`${styles.domainCardName} ${
                domainData?.isDisabled === "true"
                  ? styles.disabledCardStyling
                  : ""
              }`}
              id={`auto_name_${domainIndex}`}
            >
              {domainData?.name}
            </div>
          </div>
          <div
            className={`${styles.domainCardDescDiv} ${
              domainData?.isDisabled === "true"
                ? styles.disabledCardStyling
                : ""
            }`}
            id={`auto_desc_${domainIndex}`}
          >
            {domainData?.description}
          </div>
          <ConfirmPopUp
            show={isShowDomainPopUp}
            close={isCloseDomainPopUp}
            onClick={onClickYes}
          />
        </div>
        {domainData?.isDisabled === "true" ? (
          <Icon img_src={lockIcon} extraClass={styles.iconDisabledStyling} />
        ) : null}
      </div>
      {domainData?.isDisabled === "true" ? (
        <span className={styles.domainToolTipText}>Coming Soon...</span>
      ) : null}
    </div>
  );
};
