import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";

import { Colors } from "./colors";
import moment from "moment";
import NoDatamodel from "../../generic/noDatamodel/NoDatamodel";
import { noPhoneDataIcon } from "../../../theme/assets/svg";

function ClubbedBarChart(props) {
  const [data, setData] = useState([]);

  const expandedChart = useSelector(
    (store) => store?.analyticsReducer?.expandedChart
  );

  const isSort = useSelector((store) => store?.analyticsReducer?.isSort);

  const sortedChart = useSelector(
    (store) => store?.analyticsReducer?.sortedChart
  );

  const keyLength = props?.chartDetails?.keys?.length;
  let barWidth =
    parseInt(props.chartDetails?.chartStyle?.width) < 600
      ? parseInt(props.chartDetails?.chartStyle?.width) - 30
      : parseInt(props.chartDetails?.chartStyle?.width) - 100;
  let barHeight = parseInt(props.chartDetails?.chartStyle?.height) - 80;
  let expandbarHeight =
    parseInt(props.chartDetails?.expand?.expandStyle?.height) - 60;
  let name1 = props.chartDetails?.keys[0].name;
  let name2 = props.chartDetails?.keys[1].name;
  let key1 = props.chartDetails?.keys[keyLength - 4].name;
  let key2 = props.chartDetails?.keys[keyLength - 3].name;
  let key3 = props.chartDetails?.keys[keyLength - 2].name;
  let key4 = props.chartDetails?.keys[keyLength - 1]?.name;
  let fill1 = props.chartDetails?.keys[keyLength - 4].fillColor;
  let fill2 = props.chartDetails?.keys[keyLength - 3].fillColor;
  let fill3 = props.chartDetails?.keys[keyLength - 2].fillColor;
  let fill4 = props.chartDetails?.keys[keyLength - 1]?.fillColor;
  let value1 = props.chartDetails?.keys[keyLength - 4].referenceKeyName;
  let value2 = props.chartDetails?.keys[keyLength - 3].referenceKeyName;
  let value3 = props.chartDetails?.keys[keyLength - 2].referenceKeyName;
  let value4 = props.chartDetails?.keys[keyLength - 1]?.referenceKeyName;
  let refenceKeyForData = props.chartDetails?.refenceKeyForData;

  useEffect(() => {
    if (
      props.data[refenceKeyForData] &&
      props.chartDetails &&
      Object.keys(props.data[refenceKeyForData])?.length > 0
    ) {
      let temp = props?.chartDetails?.keys?.slice(0, keyLength - 5);
      let finalData = temp?.map((each, index) => {
        return {
          name: each.name,
          [key1]:
            props.data[refenceKeyForData] &&
            props?.data[refenceKeyForData][each?.referenceKeyName][value1],
          [key2]:
            props.data[refenceKeyForData] &&
            props?.data[refenceKeyForData][each?.referenceKeyName][value2],
          [key3]:
            props.data[refenceKeyForData] &&
            props?.data[refenceKeyForData][each?.referenceKeyName][value3],
          [key4]:
            props.data[refenceKeyForData] &&
            props?.data[refenceKeyForData][each?.referenceKeyName][value4],
        };
      });
      setData(finalData);
    }
  }, [props.chartDetails, props.data[refenceKeyForData]]);


  return props.data[refenceKeyForData] &&
     Object.keys(props.data[refenceKeyForData])?.length > 0 ? (
    <div
      style={{
        minWidth: `${data.length * 80}px`,
        height: `${
          expandedChart && expandedChart.includes(props.chartTitle)
            ? `${expandbarHeight}px`
            : `${barHeight}px`
        }`,
        width: `${
          expandedChart && expandedChart.includes(props.chartTitle)
            ? "100%"
            : `${barWidth}px`
        }`,
      }}
    >
      <ResponsiveContainer height="100%">
        <BarChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: -10,
            right: 10,
            bottom: 20,
            left: 30,
          }}
          barGap={0}
          barSize={
            expandedChart && expandedChart.includes(props.chartTitle) ? 55 : 35
          }
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis
            dataKey="name"
            style={{
              fontSize: `${
                parseInt(props.chartDetails?.chartStyle?.width) < 600
                  ? "12px"
                  : "15px"
              }`,
            }}
          ></XAxis>
          <YAxis interval={1}>
            <Label
              angle={270}
              offset={40}
              dx={30}
              position="left"
              style={{ textAnchor: "middle", fill: "#000000" }}
            >
              {props.chartDetails?.keys[keyLength - 5]?.name}
            </Label>
            {/* } */}
          </YAxis>
          <Tooltip />
          <Legend
            layout="horizontal"
            verticalAlign="top"
            align="left"
            dy={-10}
            wrapperStyle={{
              fontSize: `${
                expandedChart && expandedChart.includes(props.chartTitle)
                  ? "18px"
                  : "14px"
              }`,
              paddingLeft: `${
                expandedChart && expandedChart.includes(props.chartTitle)
                  ? "850px"
                  : "355px"
              }`,
            }}
          />

          <Bar dataKey={key1} fill={fill1} />
          <Bar dataKey={key2} fill={fill2} />
          <Bar dataKey={key3} fill={fill3} />
          <Bar dataKey={key4} fill={fill4} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <div
      className="no-data"
      style={{
        height: `${
          expandedChart && expandedChart.includes(props.chartTitle)
            ? "455px"
            : "310px"
        }`,
      }}
    >
      {props.chartDetails?.keys[0]?.name === "Date" ? (
        <NoDatamodel
          srcImg={noPhoneDataIcon}
          // message="No PTP given for selected date range"
        ></NoDatamodel>
      ) : (
        <NoDatamodel srcImg={noPhoneDataIcon}></NoDatamodel>
      )}
    </div>
  );
}

export default ClubbedBarChart;