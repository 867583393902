import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { INTEGRATION_API, config } from "../../../../services/ApiRoutes";
import actionTypes from "../telephony.actionTypes";
import bannerActionTypes from "../../../baseScreen/baseScreenState/actionTypes";
import integrationActionTypes from "../../allIntegrations/allIntegrations.actionTypes";
import { store } from "../../../rootStore";
import _ from "lodash";

export function* getAllTelephonySystemAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_TELEPHONY_DETAILS}`,
        {
          name: action.payload.name,
        }
      );
      yield put({
        type: actionTypes.GET_ALL_TELEPHONY_SYSTEM_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ALL_TELEPHONY_SYSTEM_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}

export function* getDefaultTelephonySystemAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        INTEGRATION_API.GET_TELEPHONY_DETAILS,
        _.omit(action.payload, "name")
      );

      const res = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_TELEPHONY_DETAILS}`,
        {
          name: action.payload.name,
        }
      );

      yield put({
        type: actionTypes.GET_DEFAULT_TELEPHONY_SYSTEM_SUCCESS,
        payload: result.data.data,
      });

      const filteredArray = result.data.data.filter(
        (obj: any) => obj.isDefault === true
      );

      // console.log(result.data.data, res.data.data, filteredArray, "lllll");

      if (
        result?.data?.data?.length === 0 ||
        (filteredArray.length === 0 && result?.data?.data?.length !== 0)
      ) {
        const filteredData = res.data.data?.map((obj: any) => {
          if (obj.telephony === res.data.data?.[0]?.telephony) {
            return { ...obj, isDefault: true };
          }
          return obj;
        });
        // console.log(filteredData, "lllll");
        yield put({
          type: actionTypes.GET_ALL_TELEPHONY_SYSTEM_SUCCESS,
          payload: filteredData,
        });
      } else {
        const telephonyMap = result?.data?.data.reduce((map: any, obj: any) => {
          map[obj.telephony] = obj.isDefault;
          return map;
        }, {});

        // Update isdefault values in array2 based on telephonyMap
        const updatedArray2 = res?.data?.data.map((obj: any) => {
          if (
            Object.prototype.hasOwnProperty.call(telephonyMap, obj.telephony)
          ) {
            obj.isDefault = telephonyMap[obj.telephony];
          }
          return obj;
        });

        // console.log(updatedArray2, "lllllllll");

        yield put({
          type: actionTypes.GET_ALL_TELEPHONY_SYSTEM_SUCCESS,
          payload: updatedArray2,
        });
      }
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_DEFAULT_TELEPHONY_SYSTEM_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}
export function* getAllSIPGatewayAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(config.GET_WITH_PARAMS, `urlget`, {
        accountId: action.payload.accountId,
      });

      yield put({
        type: actionTypes.GET_SIP_GATEWAY_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SIP_GATEWAY_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}
export function* getDefaultTelephonyDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        INTEGRATION_API.GET_TELEPHONY_DETAILS,
        action.payload
      );
      yield put({
        type: actionTypes.GET_DEFAULT_TELEPHONY_DATA_SUCCESS,
        payload: result.data.data,
      });
      // console.log(result.data.data, "kkkkk");
      // if (typeof result.data.data === "object" && result.data.data !== null) {
      //   const apiResponse =
      //     store.getState().integrationReducer?.telephonyReducer
      //       ?.allTelephonySystem?.data;
      //   const filteredData = apiResponse?.map((obj: any) => {
      //     if (obj.telephony === result.data.data?.telephony) {
      //       return { ...obj, isDefault: true };
      //     }
      //     return obj;
      //   });
      //   yield put({
      //     type: actionTypes.GET_ALL_TELEPHONY_SYSTEM_SUCCESS,
      //     payload: filteredData,
      //   });
      //   // console.log(filteredData, "llll");
      // } else {
      //   const apiResponse =
      //     store.getState().integrationReducer?.telephonyReducer
      //       ?.allTelephonySystem?.data;
      //   const filteredData = apiResponse?.map((obj: any) => {
      //     if (obj.telephony === apiResponse?.[0]?.telephony) {
      //       return { ...obj, isDefault: true };
      //     }
      //     return obj;
      //   });
      //   yield put({
      //     type: actionTypes.GET_ALL_TELEPHONY_SYSTEM_SUCCESS,
      //     payload: filteredData,
      //   });
      // }
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_DEFAULT_TELEPHONY_DATA_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}
export function* getClientTelephonyDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        INTEGRATION_API.GET_TELEPHONY_DETAILS,
        action.payload
      );
      yield put({
        type: actionTypes.GET_CLIENT_TELEPHONY_DATA_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_CLIENT_TELEPHONY_DATA_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}
export function* setTelephonyDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        INTEGRATION_API.CRETE_TELEPHONY_DETAILS,
        action.payload.body,
        action.payload.headers
      );

      yield put({
        type: actionTypes.SET_TELEPHONY_DATA_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: bannerActionTypes.SET_IS_SHOW_BANNER_MSG,
        payload: "",
      });
      yield put({
        type: bannerActionTypes.SET_IS_SHOW_TOP_BANNER,
        payload: false,
      });
    }
    console.log("mode", action.payload.mode);
    toast.success(
      action.payload.mode === "create"
        ? "Telephony data created Successfully"
        : "Telephony data updated Successfully"
    );
  } catch (error: any) {
    const errorMessage = error?.response?.data?.error;
    // toast.error(errorMessage);
    yield put({
      type: actionTypes.SET_TELEPHONY_DATA_FAILURE,
      payload: errorMessage,
    });
    yield put({
      type: bannerActionTypes.SET_IS_SHOW_BANNER_MSG,
      payload: errorMessage,
    });
    yield put({
      type: bannerActionTypes.SET_IS_SHOW_TOP_BANNER,
      payload: true,
    });
    console.error(error);
  }
}

export function* setDefaultTelephonyDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_BODY,
        INTEGRATION_API.CRETE_TELEPHONY_DETAILS,
        action.payload
      );
      yield put({
        type: actionTypes.SET_DEFAULT_TELEPHONY_DATA_SUCCESS,
        payload: result.data.data,
      });
    }
    toast.success("Default Telephony added Successfully");
  } catch (error: any) {
    const errorMessage = error?.response?.data?.error;
    toast.error(errorMessage);
    yield put({
      type: actionTypes.SET_DEFAULT_TELEPHONY_DATA_FAILURE,
      payload: errorMessage,
    });
    console.error(error);
  }
}
