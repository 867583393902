import React from "react";
import { sort } from "../../../../theme/assets/svg";
export const datas = [
  {
    paymentDate: "13 June 2022",
    dueDate: "25 June 2022",
    paymentAmount: "6350",
    paymentMethod: "Credit",
    transactionId: "3847387454",
    emiAmount: "6350",
  },
  {
    paymentDate: "13 July 2022",
    dueDate: "25 July 2022",
    paymentAmount: "6350",
    paymentMethod: "Credit",
    transactionId: "38473248743",
    emiAmount: "6350",
  },
  {
    paymentDate: "13 Aug 2022",
    dueDate: "12 Aug 2022",
    paymentAmount: "6350",
    paymentMethod: "Credit",
    transactionId: "38473248743",
    emiAmount: "6350",
  },
  {
    paymentDate: "9 Sept 2022",
    dueDate: "12 Sept 2022",
    paymentAmount: "6350",
    paymentMethod: "Credit",
    transactionId: "38479238743",
    emiAmount: "6350",
  },
  {
    paymentDate: "12 Oct 2022",
    dueDate: "11 Oct 2022",
    paymentAmount: "6350",
    paymentMethod: "Credit",
    transactionId: "45338473874",
    emiAmount: "6350",
  },

  {
    paymentDate: "12 Aug 2022",
    dueDate: "12 Aug 2022",
    paymentAmount: "6350",
    paymentMethod: "Credit",
    transactionId: "89884738743",
    emiAmount: "6350",
  },
  {
    paymentDate: "13 Dec 2022",
    dueDate: "12 Dec 2022",
    paymentAmount: "6350",
    paymentMethod: "Credit",
    transactionId: "33284738743",
    emiAmount: "6350",
  },
  {
    paymentDate: "1 Jan 2022",
    dueDate: "12 Jan 2022",
    paymentAmount: "6350",
    paymentMethod: "Credit",
    transactionId: "36953847384",
    emiAmount: "6350",
  },
];

export const headTranformers = [
  {
    key: "paymentDate",
    displayName: "Payment Date",
    transform: (value: any) => value,
  },
  { key: "dueDate", displayName: "Due Date" },
  { key: "paymentAmount", displayName: "Payment Amount" },
  {
    key: "paymentMethod",
    displayName: "Payment Method",
  },
  { key: "transactionId", displayName: "Transaction Id" },
  {
    key: "emiAmount",
    displayName: "EMI Amount",
    transform: (value: any) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {value}
        <img style={{ marginLeft: "7px" }} src={sort} alt=">" />
      </div>
    ),
  },
];

export const bodyTranformer = [
  { key: "paymentDate", transform: (value: any) => value },
  { key: "dueDate", transform: (value: any) => value },
  { key: "paymentAmount", transform: (value: any) => <>&#8377;{value}</> },
  { key: "paymentMethod", transform: (value: any) => value },
  { key: "transactionId", transform: (value: any) => value },
  { key: "emiAmount", transform: (value: any) => <>&#8377;{value}</> },
];
