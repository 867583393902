import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import icon from "../../../../theme/assets/svg/signUpFlow/maiaIconRightBottom.svg";
import iconCard from "../../../../theme/assets/svg/signUpFlow/maiaIconDashboard.svg";
import TourModal from "../tourPopUp/TourModal";
import {
  claimAndPurchasePlan,
  updateTourModuleWise,
  setStartTourMain,
} from "../../../../redux/signUpFlow/productTour/actions";
import { getUserData } from "../../../../redux/onboarding/login/actions";

const TOUR_STEPS = [
  // {
  //     target:  "#auto_left_Logger",
  //     content: "This is where you can search the dashboard.",
  //     disableBeacon: true, // This makes the tour to start automatically without clicking
  //     name : "abhishek",

  //   },
  // {
  //   target:  "#raju",
  //   content: "This is where you can search the dashboard.",

  // },
  // {
  //   target:  "#auto_left_Analytics",
  //   content: "This is where you can search the dashboard.",
  //   placement : "right-end",
  //   disableBeacon: true // This makes the tour to start automatically without clicking
  // },
  {
    target: "#abhi",
    content:
      "This section shows you the most critical Key Performance Indicators. It's the Spotlight of your outreach.",
    placement: "top",
    disableBeacon: true,
    img: iconCard,
    styleMiaa: {
      position: "absolute",
      right: "-167px",
      bottom: "-42px",
    },
  },
  {
    target: "#chartModel0",
    content:
      "This section contains some vital visualizations. I suggest you go through the resources section to get to know the metrics displayed.",
    placement: "right-start",
    disableBeacon: true,
    styleMiaa: {
      position: "absolute",
      right: "-154px",
      bottom: "-174px",
    },
    img: icon,
    spotlightPadding: 10,
  },
];

const INITIAL_STATE = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};
const TourToolTip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}: any) => {
  return (
    <TourModal
      tooltipProps={tooltipProps}
      step={step}
      primaryProps={primaryProps}
    />
  );
};
// Tour component
const Tour = (props: any) => {
  // Tour state is the state which control the JoyRide component
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const navigation = useNavigate();
  const dispatchRedux = useDispatch();
  const loginUserId = useSelector(
    (store: any) => store.loginReducer.userLoginInfo?.userDetail?._id
  );
  useEffect(() => {
    // Auto start the tour if the tour is not viewed before

    if (!localStorage.getItem("tour")) {
      // setTimeout(() => {
      dispatch({ type: "START" });
      // }, 4000);
    }

    document.body.style.overflow = "hidden";
    dispatchRedux(setStartTourMain(true));
  }, []);

  // Set once tour is viewed, skipped or closed
  const setTourViewed = () => {
    // localStorage.setItem("tour", "1");
  };

  const callback = (data: any) => {
    const { action, index, type, status } = data;

    // if(index ==1){
    // navigation("/dashboard")
    window.scrollTo(0, 0);
    // }
    if (index === 1) {
      document.body.style.overflow = "hidden";
    }

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: "STOP" });
      const data = {
        headers: { userId: loginUserId },
      };
      dispatchRedux(getUserData(data));
      dispatchRedux(setStartTourMain(false));
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.

      window.scrollTo(0, 0);
      window.scrollBy(0, 0);
      document.body.style.overflow = "";
      // setTimeout(() => {
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
      //  }, 400);

      if (index === 1) {
        dispatchRedux(claimAndPurchasePlan(true));
        dispatchRedux(setStartTourMain(false));
      }
      window.scrollTo(0, 0);
      window.scrollBy(0, 0);
    }
  };

  const startTour = () => {
    // Start the tour manually
    dispatch({ type: "RESTART" });
  };

  useEffect(() => {
    if (tourState.stepIndex === 1) {
      window.scrollTo(0, 0);
      window.scrollBy(0, 0);
    }
  }, [tourState]);

  //haibgghsgd
  useEffect(() => {
    const body = {
      userId: loginUserId,
      moduleName: "Analytics",
    };
    dispatchRedux(updateTourModuleWise(body, "Analytics"));
  }, []);

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton={false}
        hideCloseButton
        hideBackButton
        spotlightPadding={0.5}
        tooltipComponent={TourToolTip}
        scrollDuration={100}
        disableOverlay={false}
        styles={{
          tooltipContainer: {
            textAlign: "left",
          },
          overlay: { height: "100%" },
          spotlight: {
            position: "fixed",
            color: "red",
          },
        }}
        locale={{
          last: "End",
        }}
      />
    </>
  );
};

export default Tour;
