import { Reducer } from "redux";
import actionTypes from "../nudge.actionTypes";
import { toast } from "react-toastify";

export interface stateInterface {
  allNudgeDetail: any;
  connectedNudgeData: any;
  isLoadingCreateNudge: boolean;
  showDot: boolean;
  conditionOutcomes: { isLoading: boolean; data: any; error: any };
  conditionEditOutcomes: { isLoading: boolean; data: any; error: any };
  variation: any;
  mindMap: { show: boolean; scriptData: any };
  dispositionAlternate: { data: any; isLoading: boolean; error: any };
  followUpsDispositions: { data: any; isLoading: boolean; error: any };
  outComesDispositions: { data: any; isLoading: boolean; error: any };
  isScriptSelectVisted: boolean;
  paymentModeBasedFlow: { data: any; isLoading: boolean; error: any };
}

export const initialState: stateInterface = {
  allNudgeDetail: [],
  connectedNudgeData: [],
  isLoadingCreateNudge: true,
  showDot: false,
  conditionOutcomes: { isLoading: false, data: {}, error: {} },
  conditionEditOutcomes: { isLoading: false, data: {}, error: {} },
  variation: {},
  mindMap: { show: false, scriptData: {} },
  dispositionAlternate: { data: [], isLoading: false, error: {} },
  followUpsDispositions: { data: [], isLoading: false, error: {} },
  outComesDispositions: { data: [], isLoading: false, error: {} },
  isScriptSelectVisted: false,
  paymentModeBasedFlow: { data: [], isLoading: false, error: {} },
};

const nudgeReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.SET_ALL_NUDGE_DATA: {
      return {
        ...state,
        allNudgeDetail: action.payload,
      };
    }
    case actionTypes.UPDATE_EXPECTED_DATA: {
      const { data, index, updateData, key } = action.payload;
      console.log("nayak", action.payload);
      const toUpdate = state?.allNudgeDetail;
      const newUpdated = toUpdate?.map((e: any) => {
        if (data?.isDaywise) {
          if (data.flow === e?.flow) {
            const rangesArr = e?.ranges;
            rangesArr?.map((d: any) => {
              if (d?.days?.[0] === data?.days[0]) {
                const nudgeBoxArray = d?.nugeBoxes;
                nudgeBoxArray[index][key] = updateData;
                d["nugeBoxes"] = nudgeBoxArray;
                return d;
              }
            });
            return e;
          } else {
            return e;
          }
        } else {
          if (data.flow === e?.flow) {
            const nudgeBoxArray = e?.nugeBoxes;
            nudgeBoxArray[index][key] = updateData;
            e["nugeBoxes"] = nudgeBoxArray;
            return e;
          } else {
            return e;
          }
        }
      });
      return {
        ...state,
        allNudgeDetail: [...newUpdated],
      };
    }
    case actionTypes.CREATE_NUDGE_API_SUCCESS: {
      return {
        ...state,
        isLoadingCreateNudge: false,
        createApiNudge: action.payload,
      };
    }
    case actionTypes.CREATE_NUDGE_API_FAILURE: {
      return {
        ...state,
        createApiNudgeFailure: action.payload,
      };
    }
    case actionTypes.CREATE_NUDGE_LODDING: {
      return {
        ...state,
        isLoadingCreateNudge: action.payload,
      };
    }
    case actionTypes.SET_CONNECTED_NUDGE: {
      const data = state?.connectedNudgeData ? state?.connectedNudgeData : [];
      console.log("b89", data);
      return {
        ...state,
        connectedNudgeData: [...data, action.payload],
      };
    }
    case actionTypes.SET_CONNECTED_NUDGE_ID: {
      const { start, end } = action.payload;
      const [id, flow, index, day, isDaywise, key] = start.split("&");
      const [updatedId, extra] = end.split("&");
      const days = day?.split(",");
      console.log(id, flow, index, days, days.length, key, updatedId, "vb");
      const toUpdate = state?.allNudgeDetail;
      const newUpdated = toUpdate?.map((e: any) => {
        if (isDaywise) {
          if (flow === e?.flow) {
            const rangesArr = e?.ranges;
            rangesArr?.map((d: any) => {
              if (d?.days?.[0] === Number(days?.[0])) {
                console.log("vbv1", d?.days?.[0], days?.[0]);
                const nudgeBoxArray = d?.nugeBoxes;
                const toaddId = nudgeBoxArray[index][key];
                Object.keys(toaddId)?.map((x: any) => {
                  toaddId[x] = updatedId;
                });
                nudgeBoxArray[index][key] = toaddId;

                d["nugeBoxes"] = nudgeBoxArray;
                return d;
              }
            });
            return e;
          } else {
            console.log("vbv1", "else");
            return e;
          }
        } else {
          if (flow === e?.flow) {
            const nudgeBoxArray = e?.nugeBoxes;
            const toaddId = nudgeBoxArray[index][key];
            Object.keys(toaddId)?.map((x: any) => {
              toaddId[x] = updatedId;
            });
            nudgeBoxArray[index][key] = toaddId;
            e["nugeBoxes"] = nudgeBoxArray;
            return e;
          } else {
            return e;
          }
        }
      });
      console.log("vbv", newUpdated);
      return {
        ...state,
        allNudgeDetail: [...newUpdated],
      };
    }
    case actionTypes.SET_CONNECTED_NUDGE_START: {
      const { start, end } = action.payload;
      const [id, flow, index, day, isDaywise, key] = start.split("&");
      const [updatedId, endFlow, endIndex, endDay, endIsDaywise, endKey] =
        end.split("&");
      const days = day?.split(",");
      const endDays = endDay?.split(",");
      console.log("mere", action.payload, days, endDays);
      if (
        start &&
        end?.length &&
        Number(days[days?.length - 1]) > Number(endDays[endDays?.length - 1])
      ) {
        toast.error("Can't  connect next Day nudge with previous day nudge ");
      }
      if (
        start &&
        end?.length &&
        start !== end &&
        Number(days[days.length - 1]) <= Number(endDays[endDays.length - 1])
      ) {
        const toUpdate = state?.allNudgeDetail;
        let newUpdated = toUpdate?.map((e: any) => {
          if (isDaywise == "true") {
            if (flow === e?.flow) {
              const rangesArr = e?.ranges;
              rangesArr?.map((d: any) => {
                if (d?.days?.[0] === Number(days?.[0])) {
                  console.log("vbv1", d?.days?.[0], days?.[0]);
                  const nudgeBoxArray = d?.nugeBoxes;
                  const toaddId = nudgeBoxArray[index][key];
                  Object.keys(toaddId)?.map((x: any) => {
                    toaddId[x] = updatedId;
                  });
                  nudgeBoxArray[index][key] = toaddId;

                  d["nugeBoxes"] = nudgeBoxArray;
                  return d;
                }
              });
              return e;
            } else {
              console.log("vbv1", "else");
              return e;
            }
          } else {
            if (flow === e?.flow) {
              const nudgeBoxArray = e?.nugeBoxes;
              const toaddId = nudgeBoxArray[index][key];
              Object.keys(toaddId)?.map((x: any) => {
                toaddId[x] = updatedId;
              });
              nudgeBoxArray[index][key] = toaddId;
              e["nugeBoxes"] = nudgeBoxArray;
              return e;
            } else {
              return e;
            }
          }
        });
        newUpdated = newUpdated?.map((e: any) => {
          if (isDaywise == "true") {
            if (endFlow === e?.flow) {
              const rangesArr = e?.ranges;
              rangesArr?.map((d: any) => {
                if (d?.days?.[0] === Number(endDay?.[0])) {
                  console.log("vbv1", d?.days?.[0], endDay?.[0]);
                  const nudgeBoxArray = d?.nugeBoxes;
                  const toaddId = nudgeBoxArray[endIndex];
                  const arrayOfConnected = toaddId["connectedTo"]
                    ? toaddId["connectedTo"]
                    : [];
                  nudgeBoxArray[endIndex]["connectedTo"] = [
                    ...arrayOfConnected,
                    start,
                  ];

                  d["nugeBoxes"] = nudgeBoxArray;
                  console.log("bro", d);
                  return d;
                }
              });
              return e;
            } else {
              console.log("vbv1", "else");
              return e;
            }
          } else {
            if (endFlow === e?.flow) {
              const nudgeBoxArray = e?.nugeBoxes;
              const toaddId = nudgeBoxArray[endIndex];
              const arrayOfConnected = toaddId["connectedTo"]
                ? toaddId["connectedTo"]
                : [];
              nudgeBoxArray[endIndex]["connectedTo"] = [
                ...arrayOfConnected,
                start,
              ];
              e["nugeBoxes"] = nudgeBoxArray;
              console.log("bro", e);
              return e;
            } else {
              return e;
            }
          }
        });
        console.log("vbv", newUpdated);
        return {
          ...state,
          allNudgeDetail: [...newUpdated],
          connectedNudgeStartEnd: { start: "", end: "" },
        };
      } else {
        return {
          ...state,
          connectedNudgeStartEnd: { start: start, end: end },
        };
      }
    }
    case actionTypes.GET_NUDGE_API_SUCCESS: {
      return {
        ...state,
        getApiNudge: action.payload,
      };
    }
    case actionTypes.GET_NUDGE_API_FAILURE: {
      return {
        ...state,
        getApiNudgeFailure: action.payload,
      };
    }
    case actionTypes.SHOW_DOT: {
      return {
        ...state,
        showDot: action.payload,
      };
    }
    case actionTypes.SET_TYPE_OF_STRATEGY_MODE: {
      return {
        ...state,
        typeOfstrategyMode: action.payload,
      };
    }
    case actionTypes.UPDATE_NUDGE_API_SUCCESS: {
      return {
        ...state,
        isLoadingCreateNudge: false,
        updateApiNudge: action.payload,
      };
    }
    case actionTypes.UPDATE_NUDGE_API_FAILURE: {
      return {
        ...state,
        updateApiNudgeFailure: action.payload,
      };
    }

    case actionTypes.REMOVE_CONNECTION_LINE: {
      const [id, flow, index, day, isDaywise, key] = action.payload.split("&");

      const days = day?.split(",");
      console.log(id, flow, index, days, days.length, key, "vb");
      const toUpdate = state?.allNudgeDetail;
      const newUpdated = toUpdate?.map((e: any) => {
        if (isDaywise == "true") {
          if (flow === e?.flow) {
            const rangesArr = e?.ranges;
            rangesArr?.map((d: any) => {
              if (d?.days?.[0] === Number(days?.[0])) {
                console.log("vbv1", d?.days?.[0], days?.[0]);
                const nudgeBoxArray = d?.nugeBoxes;
                const toaddId = nudgeBoxArray[index][key];
                Object.keys(toaddId)?.map((x: any) => {
                  toaddId[x] = "";
                });
                nudgeBoxArray[index][key] = toaddId;

                d["nugeBoxes"] = nudgeBoxArray;
                return d;
              }
            });
            return e;
          } else {
            console.log("vbv1", "else");
            return e;
          }
        } else {
          if (flow === e?.flow) {
            const nudgeBoxArray = e?.nugeBoxes;
            const toaddId = nudgeBoxArray[index][key];
            Object.keys(toaddId)?.map((x: any) => {
              toaddId[x] = "";
            });
            nudgeBoxArray[index][key] = toaddId;
            e["nugeBoxes"] = nudgeBoxArray;
            return e;
          } else {
            return e;
          }
        }
      });
      console.log("vbv", newUpdated);
      return {
        ...state,
        allNudgeDetail: [...newUpdated],
      };
    }
    case actionTypes.REMOVE_ID_DELETE: {
      const [id, flow, index, day, isDaywise, key] = action.payload.split("&");

      const days = day?.split(",");
      console.log(id, flow, index, days, days.length, key, "vb");
      const toUpdate = state?.allNudgeDetail;
      const newUpdated = toUpdate?.map((e: any) => {
        if (isDaywise == "true") {
          if (flow === e?.flow) {
            const rangesArr = e?.ranges;
            rangesArr?.map((d: any) => {
              if (d?.days?.[0] === Number(days?.[0])) {
                console.log("vbv1", d?.days?.[0], days?.[0]);
                const nudgeBoxArray = d?.nugeBoxes;
                let indx = "";
                nudgeBoxArray?.map((a: any, i: any) => {
                  if (a?.uniqueId == id) {
                    indx = i;
                    const toaddId = nudgeBoxArray[indx][key];
                    Object.keys(toaddId)?.map((x: any) => {
                      toaddId[x] = "";
                    });
                    nudgeBoxArray[indx][key] = toaddId;
                  }
                });
                nudgeBoxArray?.length > 0 && (d["nugeBoxes"] = nudgeBoxArray);
                return d;
              }
            });
            return e;
          } else {
            console.log("vbv1", "else");
            return e;
          }
        } else {
          if (flow === e?.flow) {
            const nudgeBoxArray = e?.nugeBoxes;
            let indx = "";
            nudgeBoxArray?.map((a: any, i: any) => {
              console.log("tg", a);
              if (a?.uniqueId == id) {
                indx = i;
                const toaddId = nudgeBoxArray[indx][key];
                Object.keys(toaddId)?.map((x: any) => {
                  toaddId[x] = "";
                });
                nudgeBoxArray[indx][key] = toaddId;
              }
            });
            console.log("indxx", indx);
            nudgeBoxArray?.length > 0 && (e["nugeBoxes"] = nudgeBoxArray);
            return e;
          } else {
            return e;
          }
        }
      });
      console.log("vbv", newUpdated);
      return {
        ...state,
        allNudgeDetail: [...newUpdated],
      };
    }
    case actionTypes.SET_CHANNEL_SELECTED: {
      return {
        ...state,
        selectedChannel: action.payload,
      };
    }
    case actionTypes.SET_WORKING_NUDGE_DATA: {
      return {
        ...state,
        workingNudgeData: action.payload,
      };
    }
    case actionTypes.GET_CONDITION_API_REQUEST: {
      return {
        ...state,
        conditionOutcomes: { isLoading: true, data: {}, error: {} },
      };
    }
    case actionTypes.GET_CONDITION_API_SUCCESS: {
      return {
        ...state,
        conditionOutcomes: {
          isLoading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case actionTypes.GET_CONDITION_API_FAILURE: {
      return {
        ...state,
        conditionOutcomes: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case actionTypes.GET_CONDITION_EDIT_API_REQUEST: {
      return {
        ...state,
        conditionEditOutcomes: { isLoading: true, data: {}, error: {} },
      };
    }
    case actionTypes.GET_CONDITION_EDIT_API_SUCCESS: {
      return {
        ...state,
        conditionEditOutcomes: {
          isLoading: false,
          data: action.payload,
          error: {},
        },
      };
    }
    case actionTypes.GET_CONDITION_EDIT_API_FAILURE: {
      return {
        ...state,
        conditionEditOutcomes: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case actionTypes.SET_VARIATION_SELECTED: {
      return {
        ...state,
        variation: action.payload,
      };
    }

    case actionTypes.SET_SHOW_MINDMAP: {
      return {
        ...state,
        mindMap: action.payload,
      };
    }
    case actionTypes.GET_DISPOSITION_ALTERNATE_API_REQUEST: {
      return {
        ...state,
        dispositionAlternate: { data: [], isLoading: true, error: {} },
      };
    }
    case actionTypes.GET_DISPOSITION_ALTERNATE_API_SUCCESS: {
      return {
        ...state,
        dispositionAlternate: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.GET_DISPOSITION_ALTERNATE_API_FAILURE: {
      return {
        ...state,
        dispositionAlternate: {
          data: [],
          isLoading: false,
          error: action.payload,
        },
      };
    }

    case actionTypes.SET_IS_SCRIPT_SELECT_VISITED: {
      return {
        ...state,
        isScriptSelectVisted: action.payload,
      };
    }

    case actionTypes.GET_FOLLOWUPS_DISPOSITION_API_REQUEST: {
      return {
        ...state,
        followUpsDispositions: { data: [], isLoading: true, error: {} },
      };
    }
    case actionTypes.GET_FOLLOWUPS_DISPOSITION_API_SUCCESS: {
      return {
        ...state,
        followUpsDispositions: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.GET_FOLLOWUPS_DISPOSITION_API_FAILURE: {
      return {
        ...state,
        followUpsDispositions: {
          data: [],
          isLoading: false,
          error: action.payload,
        },
      };
    }

    case actionTypes.GET_OUTCOMES_DISPOSITIONS_API_REQUEST: {
      return {
        ...state,
        outComesDispositions: { data: [], isLoading: true, error: {} },
      };
    }
    case actionTypes.GET_OUTCOMES_DISPOSITIONS_API_SUCCESS: {
      return {
        ...state,
        outComesDispositions: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.GET_OUTCOMES_DISPOSITIONS_API_FAILURE: {
      return {
        ...state,
        outComesDispositions: {
          data: [],
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.GET_PAYMENT_MODE_API_REQUEST: {
      return {
        ...state,
        paymentModeBasedFlow: { data: [], isLoading: true, error: {} },
      };
    }
    case actionTypes.GET_PAYMENT_MODE_API_SUCCESS: {
      return {
        ...state,
        paymentModeBasedFlow: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.GET_PAYMENT_MODE_API_FAILURE: {
      return {
        ...state,
        paymentModeBasedFlow: {
          data: [],
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.SET_SELECTED_FLOW_RANGE: {
      return {
        ...state,
        selectedFlowRange: action.payload
      };
    }
   
    default: {
      return state;
    }
  }
};

export default nudgeReducer;
