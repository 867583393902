import state from "../interface/completedCampaign.interface";
import actionTypes from "../actionTypes/completedCampaign.actions";
import { Reducer } from "react";

const initialState: state = {
  data: [],
  error: "",
  isLoading: false,
};
export default function completedCampaignReducer(
  state: state = initialState,
  action: { type: string; payload: any }
): state {
  switch (action.type) {
    case actionTypes.GET_COMPLETED_CAMPAIGNS_API_CALL: {
      return { ...state, data: [], error: "", isLoading: true };
    }
    case actionTypes.GET_COMPLETED_CAMPAIGNS_API_FAILURE: {
      return { ...state, data: [], error: action.payload, isLoading: false };
    }
    case actionTypes.GET_COMPLETED_CAMPAIGNS_API_SUCCESS: {
      return { ...state, data: action.payload, error: "", isLoading: false };
    }
    case actionTypes.APPEND_DATES: {
      return { ...state, data: state.data.concat(action.payload) };
    }
    default: {
      return state;
    }
  }
}
