import React, { useEffect } from "react";
import { optionType } from "../leftSideMenu.interface";
import ImageComponent from "../../../generic/imageComponent/ImageComponent";
import styles from "./option.module.scss";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";
import { Mixpanel } from "../../../../utils/mixpanelSetup";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import {
  mixpannelModuleCount,
  mixpannelModuleDetails,
} from "../../../../redux/mixpanel/actions";
import Icon from "../../../generic/icon/Icon";
import {
  connectorLocked,
  strategyLocked,
} from "../../../../theme/assets/svg/signUpFlow/lockedIcons/index";
interface props {
  optionData: any;
  setActiveOption: (optionData: any) => void;
  isOnlyIcons?: boolean;
  highlightedModule?: string;
  hideIcon?: boolean;
}

export default function Option({
  optionData,
  setActiveOption,
  highlightedModule,
  hideIcon,
}: props) {
  /* handlers */
  const dispatch = useDispatch();
  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );
  const modulecount = useSelector(
    (store: RootState) => store?.mixpanelReducer?.moduleClickCount
  );
  const moduleDetail = useSelector(
    (store: any) => store?.mixpanelReducer?.moduleDetails
  );
  const accountRole = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo?.userDetail?.role
  );
  // console.log("optionsss", optionData);
  // console.log("klkl1" , moduleDetail)
  useEffect(() => {
    if (!moduleDetail) {
      const newModuleDetails = {
        name: highlightedModule,
        startTime: new Date().getTime() / 1000,
        previousModule: moduleDetail?.name
          ? moduleDetail?.name
          : highlightedModule,
        previousSpentTime:
          new Date().getTime() / 1000 -
          Number(moduleDetail?.startTime || new Date().getTime() / 1000),
      };
      dispatch(mixpannelModuleDetails(newModuleDetails));
      // console.log("12" ,newModuleDetails)
    }
  }, []);
  const handleOptionClick = () => {
    // for mixpannel-only

    const moduleClick = modulecount[optionData?.displayName]
      ? modulecount[optionData?.displayName]
      : 0;
    const increaseClick = Number(moduleClick) + 1;
    const countBody = { ...modulecount };
    countBody[optionData?.displayName] = increaseClick;

    dispatch(mixpannelModuleCount(countBody));

    Mixpanel.track(
      `Global | Left Menu_${optionData?.displayName} Module Button | Clicks`,
      {
        module: optionData?.displayName,
        "user Name": accountName,
        Date: `${format(new Date(), "dd-MM-yyyy")}`,
        count: increaseClick,
      }
    );

    const newModuleDetails = {
      name: optionData?.displayName,
      startTime: new Date().getTime() / 1000,
      previousModule: moduleDetail?.name
        ? moduleDetail?.name
        : optionData?.displayName,
      previousSpentTime:
        new Date().getTime() / 1000 -
        Number(moduleDetail?.startTime || new Date().getTime() / 1000),
    };
    if (moduleDetail?.previousModule) {
      Mixpanel.track(
        `${newModuleDetails?.previousModule} | Landing Page  | Total Time spent`,
        {
          module: newModuleDetails?.previousModule,
          "user Name": accountName,
          Date: `${format(new Date(), "dd-MM-yyyy")}`,
          "Total Time Spent":
            Math.floor(Number(newModuleDetails?.previousSpentTime) / 60) >= 1
              ? `${Math.floor(
                  Number(newModuleDetails?.previousSpentTime) / 60
                )} minutes`
              : `${Number(newModuleDetails?.previousSpentTime)} second`,
        }
      );
      const jj = {
        module: newModuleDetails?.previousModule,
        "user Name": accountName,
        Date: `${format(new Date(), "dd-MM-yyyy")}`,
        "Total Time Spent": Math.floor(
          Number(newModuleDetails?.previousSpentTime) / 60
        ),
      };
      // console.log("1627", jj);
    }
    dispatch(mixpannelModuleDetails(newModuleDetails));

    //---end
    setActiveOption(optionData);
  };

  // console.log("options", optionData?.isLocked, optionData?.name);

  return (
    <div
      className={`${styles.optionContainer} optionContainer 
      ${optionData?.isLocked && styles.isLockedStyling}
      `}
      style={
        highlightedModule === optionData.name
          ? {
              backgroundColor: optionData.activeColor,
              color: accountRole.includes("Desktop")
                ? "black"
                : optionData?.color
                ? optionData.color
                : "white",
            }
          : { backgroundColor: optionData.inActiveColor, color: "black" }
      }
      onClick={!optionData.isLocked ? handleOptionClick : () => {}}
      id={"auto_leftMenu_option_" + optionData.name}
    >
      {!hideIcon && (
        <PravidIcons
          activeIcon={
            optionData?.isLocked
              ? optionData.inActiveLockedIcon
              : optionData.activeIcon
          }
          inActiveIcon={
            optionData?.isLocked
              ? optionData.lockedIcon
              : optionData.inActiveIcon
          }
          isInActive={highlightedModule !== optionData.name}
          extraClass={
            optionData?.isLocked
              ? styles.leftMenu_locked
              : styles.leftMenu__icon
          }
        />
      )}
      <span
        className={`${styles.optionName} ${
          optionData?.isLocked ? styles.optionNameLocked : ""
        }`}
        id="auto_leftMenu_option_4"
      >
        {optionData.displayName}
      </span>
    </div>
  );
}
