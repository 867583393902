import MaiaCallTable from "../MaiaCallTable";
import { sortIcon } from "../../../../../theme/assets/genericSvg";
import React from "react";
const data = [
  {
    slNo: "1",
    disposition: "Promise To Pay",
    count: "2126(20%)",
  },
  {
    slNo: "2",
    disposition: "Payment Info Conveyed",
    count: "1913(18%)",
  },
  {
    slNo: "3",
    disposition: "No Response",
    count: "1594(15%)",
  },
  {
    slNo: "4",
    disposition: "Partial Payment Accepted",
    count: "1063(10%)",
  },
  {
    slNo: "5",
    disposition: "Agree To Pay",
    count: "903(8%)",
  },
  // {
  //   slNo: "5",
  //   disposition: "Customer Hangup",
  //   count: "850(8%)",
  // },
  // {
  //   slNo: "6",
  //   disposition: "Refuse To pay",
  //   count: "1552(14.6%)",
  // },
  // {
  //   slNo: "7",
  //   disposition: "Language Change",
  //   count: "1403(13.2%)",
  // },
  {
    slNo: "6",
    disposition: "Paid",
    count: "744(7%)",
  },
  
  {
    slNo: "7",
    disposition: "Call Back",
    count: "637(6%)",
  },
  {
    slNo: "8",
    disposition: "Human Handoff Requested",
    count: "531(5%)",
  },
  {
    slNo: "10",
    disposition: "Customer Hangup",
    count: "425(4%)",
  },
  {
    slNo: "11",
    disposition: "Refuse To pay",
    count: "318(3%)",
  },
  {
    slNo: "12",
    disposition: "Language Change",
    count: "297(2.8%)",
  },
  {
    slNo: "13",
    disposition: "Acceptable Promise to Pay",
    count: "127(1.2%)",
  },
];
const bodyTranformer = [
  { key: "slNo", transform: (value: any) => value },
  { key: "disposition", transform: (value: any) => value },
  { key: "count", transform: (value: any) => value },
];
const headTranformer = [
  {
    key: "slNo",
    displayName: (
      <>
        S.No.{" "}
        <span>
          {/* <img src={sortIcon}></img> */}
        </span>
      </>
    ),
  },
  {
    key: "disposition",
    displayName: (
      <>
        Disposition
        <span>
          {/* <img src={sortIcon}></img> */}
        </span>
      </>
    ),
  },
  {
    key: "count",
    displayName: (
      <>
        Count
        <span>
          <img src={sortIcon}></img>
        </span>
      </>
    ),
  },
];
export default function CallDispositionTable() {
  return (
    <>
      <MaiaCallTable
        data={data}
        bodyTranformer={bodyTranformer}
        headingTransformer={headTranformer}
      />
    </>
  );
}
