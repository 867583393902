// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hCDwfmHiggHL81wBj_zB{height:300px;width:312px;background:#f9f9f9;border-radius:6px;margin-right:25px}.hCDwfmHiggHL81wBj_zB .p6tFg70XyNr8yMZ94Osz{padding:20px;padding-bottom:0;font-family:"Roboto";font-style:normal;font-weight:400;font-size:18px;line-height:20px;color:#000;display:flex;justify-content:center}.hCDwfmHiggHL81wBj_zB .RNGVw6xZiJ4rfvkKB34C{padding:0 0 20px 10px}`, "",{"version":3,"sources":["webpack://./src/components/agentDesktop/moduleComponents/performanceChartModel/PerformanceChartModel.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,kBAAA,CACA,iBAAA,CACA,iBAAA,CACA,4CACE,YAAA,CACA,gBAAA,CACA,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA,CACA,YAAA,CACA,sBAAA,CAGF,4CACE,qBAAA","sourcesContent":[".performanceChartWrapper {\n  height: 300px;\n  width: 312px;\n  background: #f9f9f9;\n  border-radius: 6px;\n  margin-right: 25px;\n  .performanceChartHeader {\n    padding: 20px;\n    padding-bottom: 0;\n    font-family: \"Roboto\";\n    font-style: normal;\n    font-weight: 400;\n    font-size: 18px;\n    line-height: 20px;\n    color: #000000;\n    display: flex;\n    justify-content: center;\n  }\n\n  .performanceChartBody{\n    padding: 0 0 20px 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"performanceChartWrapper": `hCDwfmHiggHL81wBj_zB`,
	"performanceChartHeader": `p6tFg70XyNr8yMZ94Osz`,
	"performanceChartBody": `RNGVw6xZiJ4rfvkKB34C`
};
export default ___CSS_LOADER_EXPORT___;
