import React, { useEffect, useState } from "react";
import { StrategyCardComponent } from "../../components/moduleComponents/strategyComponents/strategyCardComponent/StrategyCardComponent";
import { CreateStrategyModal } from "../../components/moduleComponents/strategyComponents/createStartegyModal/CreateStrategyModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import styles from "./StrategyScreen.module.scss";
import Button from "../../components/generic/button/Button";
import {
  strategyCreateIcon,
  noStrategiesIcon,
} from "../../theme/assets/svg/strategySvg";
import {
  deleteStrategyAPIData,
  getStrategiesAPIData,
  getStrategyOptionsAPIData,
} from "../../redux/strategy/strategyCreation/actions";
import Icon from "../../components/generic/icon/Icon";
import {
  filter,
  caratDown,
  backCurveArrow,
  bulbWhite,
} from "../../theme/assets/genericSvg";
import MultiLevelCheck from "../../components/generic/multilevelCheck/MultiLevelCheck";
import { setCampaignAPIData } from "../../redux/campaign_V2.0/createCampaign/actions";
import { addStrategyToCampaignAPIData } from "../../redux/campaign_V2.0/strategySelection/actions";
import { SKELTON_DATA } from "../../constants/skeletonDummyData";
import { useNavigate } from "react-router-dom";
import { getMyPlanAPIData } from "../../redux/profile/billing/myPlan/actions";
import { toast } from "react-toastify";
import { getRouteByIndex } from "../../utils/ProgressBarNavFunc";
import CustomBodySkeleton from "../../components/generic/customSkeleton/CustomBodySkeleton";

interface Strategy {
  id: string;
  strategyName: string;
  strategyDescription: string;
}

export const StrategyScreen = () => {
  const { isNavigate: isNavigate } = useSelector(
    (store: RootState) =>
      store.campaignModuleReducer?.createCampaignData?.campaignData
  );

  const campaignData = useSelector(
    (store: RootState) =>
      store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
  );

  const [createScreenStrategyModal, setCreateScreenStrategyModal] =
    useState<boolean>(false);
  const [cardScreenCreateStrategyModal, setCardScreenCreateStrategyModal] =
    useState<boolean>(false);
  const [strategyFilters, setStrategyFilters] = useState<any>({});
  const [selectedStrategy, setSelectedStrategy] = useState<string>(
    campaignData?.strategyId ? campaignData?.strategyId : ""
  );
  const [selectedStrategyName, setSelectedStrategyName] = useState<string>("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
  );
  const strategyOptions = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.strategyOptionsDetails
  );

  const strategyInfo = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.strategyDetails
  );

  const isLoading = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.isLoadingStrategyDetails
  );

  const clientName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );

  const moduleSelectedScreen = useSelector(
    (store: RootState) => store?.baseScreen?.leftMenu?.highlightedModule
  );

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const stepsTotal: string[] = useSelector(
    (store: RootState) =>
      store?.schedulerReducer?.accountInfoDetails?.campaignProgressBar
  );

  const clientNameByDropdown = useSelector(
    (store: RootState) => store.campaignReducer?.campaignClientName
  );

  const myPlanData: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  const currentStep: number = useSelector(
    (state: RootState) => state.campaignModuleReducer?.progressBar?.currentStep
  );
  const currentUser = useSelector((state: RootState) => {
    return state.filterReducers.selectedFilterOptions?.clientName;
  });
  const teamUseCaseId = useSelector(
    (store: RootState) => store?.loginReducer?.currentTeam?.useCaseId
  );
  const currentTeamId = useSelector(
    (state: RootState) => state.loginReducer.currentTeam.id
  );


  const sphotValidClients = [
    "Sphot",
    // "Axio",
    // "Abhishek",
    // "Gayathri",
    // "Saarthi Demo",
  ];

  const sphotClient = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo?.userDetail?.accountDetails[0]?.name
  );

  //for geting plan detail
  // useEffect(() => {
  //   dispatch(getMyPlanAPIData({ accountId: accountId }));
  // }, [accountId]);
  /** getting Plan details by campaign account Id  */
  useEffect(() => {
    if (campaignData?.accountId) {
      dispatch(getMyPlanAPIData({ accountId: campaignData?.accountId }));
    }
  }, [campaignData]);

  /****/

  useEffect(() => {
    console.log("hey", myPlanData?.isStrategyToShow);
    if (myPlanData?.isStrategyToShow === true) {
      if (Object.keys(strategyFilters).length !== 0) {
        const effToSend =
          strategyFilters?.Effectiveness &&
          strategyFilters?.Effectiveness?.map((string: string) =>
            string.slice(0, -1)
          );
        const channelsTosend = decodeURI(
          JSON.stringify(
            strategyFilters?.Channel?.map((each: string) => {
              if (each === "Call") return "call";
              if (each === "Whatsapp") return "whatsApp";
              if (each === "Email") return "mail";
              if (each === "SMS") return "sms";
            })
          )
        );
        const strategyFiltersTosend = {
          effectiveness: effToSend,
          channels: channelsTosend,
        };

        const filteredObj = Object.fromEntries(
          Object.entries(strategyFiltersTosend).filter(
            ([_, value]) => value !== "undefined"
          )
        );

        // console.log(filteredObj, "gggg");

        const data_to_send_client: any = {
          // clientName: clientNameByDropdown ? clientNameByDropdown : clientName,
          clientName:
            clientNameByDropdown?.length > 0
              ? clientNameByDropdown
              : campaignData?.accountName?.length > 0
                ? campaignData?.accountName
                : clientName,
          useCaseId: teamUseCaseId,
          teamId: currentTeamId,
          ...filteredObj,

        };
        dispatch(
          getStrategiesAPIData({
            ...data_to_send_client,
          })
        );
      }

      if (Object.keys(strategyFilters).length === 0) {
        const data_to_send: any = {
          // clientName: clientNameByDropdown ? clientNameByDropdown : clientName,
          clientName:
            clientNameByDropdown?.length > 0
              ? clientNameByDropdown
              : campaignData?.accountName?.length > 0
                ? campaignData?.accountName
                : clientName,
          useCaseId: teamUseCaseId,
          teamId: currentTeamId
        };
        dispatch(
          getStrategiesAPIData({
            ...data_to_send,
          })
        );
      }
    } else {
      let clientNamePlan =
        clientNameByDropdown?.length > 0
          ? clientNameByDropdown
          : campaignData?.accountName?.length > 0
            ? campaignData?.accountName
            : clientName;
      const data_to_send: any = {
        clientName: clientNamePlan,
        planName: myPlanData?.planName,
        useCaseId: teamUseCaseId,
        teamId: currentTeamId
      };
      dispatch(
        getStrategiesAPIData({
          ...data_to_send,
        })
      );
    }
  }, [strategyFilters, clientName, clientNameByDropdown, myPlanData]);

  useEffect(() => {
    if (
      moduleSelectedScreen !== "Strategy" &&
      Object.keys(strategyInfo).length > 0
    ) {
      const arr: Strategy[] = [];
      strategyInfo?.strategies?.map((data: Strategy) => {
        arr.push(data);
      });
      strategyInfo?.recommendedStrategy?.map((data: Strategy) => {
        arr.push(data);
      });
      const res = arr.filter((data: Strategy) => data.id === selectedStrategy);
      setSelectedStrategyName(res[0]?.strategyName);
      // console.log(arr, res, "arrr");
      // console.log(res[0].strategyName, "arrr");
    }
  }, [
    moduleSelectedScreen,
    strategyInfo,
    selectedStrategyName,
    selectedStrategy,
  ]);

  useEffect(() => {
    dispatch(getStrategyOptionsAPIData());
  }, []);

  useEffect(() => {
    if (createScreenStrategyModal) {
      setCardScreenCreateStrategyModal(false);
    }
    if (cardScreenCreateStrategyModal) {
      setCreateScreenStrategyModal(false);
    }
  }, [createScreenStrategyModal, cardScreenCreateStrategyModal]);

  useEffect(() => {
    if (isNavigate) {
      const nextStep = getRouteByIndex(currentStep, stepsTotal);
      if (nextStep) {
        navigate(nextStep?.route);
      }
      dispatch(
        setCampaignAPIData({
          isNavigate: false,
        })
      );
    }
  }, [isNavigate]);

  const handleDeleteStrategy = (
    clientName: string,
    strategyName: string,
    id: string
  ) => {
    dispatch(
      deleteStrategyAPIData({
        // clientName: clientNameByDropdown ? clientNameByDropdown : clientName,
        clientName:
          clientNameByDropdown?.length > 0
            ? clientNameByDropdown
            : campaignData?.accountName?.length > 0
              ? campaignData?.accountName
              : clientName,
        strategyName: strategyName,
        id: id,
        useCaseId: teamUseCaseId,
        teamId: currentTeamId
      })
    );
  };

  const handleClickStrategy = (data: Strategy) => {
    if (moduleSelectedScreen !== "Strategy") {
      toast.dismiss();
      if (campaignData?.personas?.length > 0) {
        toast.dismiss();
        dispatch(
          addStrategyToCampaignAPIData({
            strategyId: data?.id,
            campaignId: campaignData?.id,
            campaignData: campaignData,
            token: token,
          })
        );
      } else {
        toast.dismiss();
        toast.error("Please Pick a CSV with Personas.");
      }
    }
  };

  const handleBackButtonClick = () => {
    navigate("/campaignmanager/dataupload");
  };

  return (
    <div
      className={styles.strategyScreenParentDiv}
      id="auto_strategyScreenParentDiv"
    >
      {true || sphotValidClients?.includes("Gayathri") ? (
        // sphotClient?.includes( "TVS Credit Collections") ||
        // clientNameByDropdown?.includes("TVS Credit Collections")
        <div
          id="auto_strategyScreenDiv"
          className={`${styles.strategyScreenDiv} ${moduleSelectedScreen !== "Strategy"
            ? styles.campaignScreenDiv
            : styles.strategyExrtaScreenDiv
            }`}
        >
          {/* {moduleSelectedScreen !== "Strategy" ? (
            selectedStrategyName ? (
              <div className={styles.selectDivStyling}>
                <span className={styles.selectTextStyling}>
                  Selected Strategy:
                </span>
                <span className={styles.selectedTextStyling}>
                  {selectedStrategyName}
                </span>
              </div>
            ) : (
              <div className={styles.selectDivStyling}>
                <div className={styles.selectTextStyling}>
                  Select the Strategy
                </div>
              </div>
            )
          ) : (
            ""
          )} */}
          <div className={styles.strategyTopDiv}>
            <div className={styles.strategyLeftDiv}>
              <div
                className={styles.strategyMainHeading}
                id="auto_strategyMainHeading"
              >
                {moduleSelectedScreen !== "Strategy" && (
                  <img
                    src={backCurveArrow}
                    alt=""
                    onClick={handleBackButtonClick}
                    style={{ cursor: "pointer" }}
                  />
                )}
                <span>Adaptive Communication Strategies</span>
              </div>
            </div>

            {strategyInfo["strategies"] !== undefined ||
              cardScreenCreateStrategyModal === true ||
              isLoading ? (
              <div className={styles.buttons_div}>
                {!myPlanData?.isStrategyToShow ? (
                  <></>
                ) : (
                  <MultiLevelCheck
                    leftIcon={filter}
                    // rightIcon={caratDown}
                    title={"Filter"}
                    allOptions={{
                      Channel: strategyOptions?.communication_channel,
                      Effectiveness: strategyOptions?.effectiveness,
                    }}
                    defaultOptions={{}}
                    getDataOut={setStrategyFilters}
                    singleIcon={false}
                    extraClass={
                      moduleSelectedScreen === "Strategy" ? true : false
                    }
                    isOpenToLeft={true}
                    singleSelectField={["Effectiveness"]}
                  />
                )}

                <CustomBodySkeleton isLoading={isLoading}>
                  {!myPlanData?.isStrategyToShow ? (
                    <></>
                  ) : (
                    <Button
                      text="Create Strategy"
                      image_src_left={bulbWhite}
                      extraClass={`${styles.createButtonStyling}`}
                      onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.stopPropagation();
                        setCardScreenCreateStrategyModal(true);
                      }}
                      extraStyle={{ width: "150px" }}
                      id="auto_createButton"
                    />
                  )}
                </CustomBodySkeleton>
              </div>
            ) : (
              ""
            )}
          </div>
          {!isLoading &&
            (strategyInfo["strategies"] === undefined ||
              createScreenStrategyModal === true) ? (
            <div
              className={styles.strategyCreateMainInfo}
              id="auto_strategyCreateMainInfo"
            >
              <Icon
                img_src={noStrategiesIcon}
                extraClass={styles.createImageStyling}
                id="auto_noStrategiesIcon"
              />
              <div className={styles.strategyCreateDesc}>
                Let’s Get Started. Create your first strategy.
              </div>
              <div className={styles.strategyCreateBtn}>
                <Button
                  text="Create New"
                  image_src_left={strategyCreateIcon}
                  extraClass={`${styles.strategyCreateButtonStyling}`}
                  onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.stopPropagation();
                    setCreateScreenStrategyModal(true);
                  }}
                  id="auto_strategyCreateButton"
                />
              </div>
            </div>
          ) : strategyInfo["strategies"] !== undefined ||
            cardScreenCreateStrategyModal === true ||
            true ? (
            <div
              className={styles.strategyCardsTopDiv}
              id="auto_strategyCardsTopDiv"
            >
              <div
                className={styles.strategyMiddleDiv}
                id="auto_strategyMiddleDiv"
              >
                <div className={styles.strategiesDiv}>
                  <div
                    className={styles.strategyCardsDiv}
                    id="auto_strategyCardsDiv"
                  >
                    {(
                      strategyInfo?.strategies || SKELTON_DATA.strategyCardsData
                    )?.map((data: Strategy, index: number) => {
                      return (
                        <>
                          <div
                            id={`auto_strategies_${index}`}
                            key={index}
                            className={`${moduleSelectedScreen !== "Strategy" &&
                              selectedStrategy === data?.id
                              ? styles.blueHighlight
                              : ""
                              }`}
                            onClick={() => {
                              setSelectedStrategy(data?.id);
                              setSelectedStrategyName(data?.strategyName);
                              handleClickStrategy(data);
                            }}
                          >
                            <CustomBodySkeleton
                              isLoading={isLoading}
                              style={{ margin: "0px 5px" }}
                            >
                              <StrategyCardComponent
                                strategyData={data}
                                // strategyData={{
                                //   ...data,
                                //   effectiveness: [
                                //     {
                                //       effectiveness: "60",
                                //       connection_rate: "50",
                                //       collection_rate: "70",
                                //       call_objective: "80",
                                //     },
                                //   ],
                                // }}
                                clientName={clientName}
                                onDelete={handleDeleteStrategy}
                                cardIndex={index}
                              />
                            </CustomBodySkeleton>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <div className={styles.strategyBottomDiv}>
                <div className={styles.strategySubHeading}>Recommended</div>
                <div className={styles.strategiesDiv}>
                  <div className={styles.strategyCardsDiv}>
                    {strategyInfo?.recommendedStrategy?.map(
                      (data: Strategy, index: number) => {
                        return (
                          <div
                            id={`auto_recommendedStrategy_${index}`}
                            key={index}
                            className={`${
                              moduleSelectedScreen !== "Strategy" &&
                              campaignData?.strategyId === data?.id
                                ? styles.blueHighlight
                                : ""
                            }`}
                            onClick={() => {
                              handleClickStrategy(data);
                            }}
                          >
                            <StrategyCardComponent
                              strategyData={data}
                              clientName={clientName}
                              onDelete={handleDeleteStrategy}
                              cardIndex={index}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div> */}
            </div>
          ) : (
            ""
          )}
          {cardScreenCreateStrategyModal && (
            <CreateStrategyModal
              show={cardScreenCreateStrategyModal}
              close={() => {
                setCardScreenCreateStrategyModal(false);
              }}
            />
          )}
          {createScreenStrategyModal && (
            <CreateStrategyModal
              show={createScreenStrategyModal}
              close={() => {
                setCreateScreenStrategyModal(false);
              }}
            />
          )}
        </div>
      ) : (
        <div className={styles.comingSoonDiv} id="auto_comingSoonDiv">
          <h1>This Feature is not available for your current plan.</h1>
        </div>
      )}
    </div>
  );
};
