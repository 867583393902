// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EfTZcS4jhRI4GYUK8utH .aUT31PejGbwV_Qajpg8c{padding-bottom:2.5em}.EfTZcS4jhRI4GYUK8utH .z1vYqthmIIhbZGiGzPT_{font-weight:bold;padding:.75em 0}.EfTZcS4jhRI4GYUK8utH .PAJPKrUb6obDIjU1dZqh{padding:.5em 0}.EfTZcS4jhRI4GYUK8utH .FUiEf0g4TYZqMiqWgB6A{padding-top:.75em}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/signUpFlow/agreeToTermsPopUp/privacyPolicy/PrivacyPolicy.module.scss"],"names":[],"mappings":"AACE,4CACE,oBAAA,CAGF,4CACE,gBAAA,CACA,eAAA,CAGF,4CACE,cAAA,CAGF,4CACE,iBAAA","sourcesContent":[".privacyPolicyWrapper {\n  .contentStyling {\n    padding-bottom: 2.5em;\n  }\n\n  .headerStyling {\n    font-weight: bold;\n    padding: 0.75em 0;\n  }\n\n  .breakStyling {\n    padding: 0.5em 0;\n  }\n\n  .btmDivStyling {\n    padding-top: 0.75em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacyPolicyWrapper": `EfTZcS4jhRI4GYUK8utH`,
	"contentStyling": `aUT31PejGbwV_Qajpg8c`,
	"headerStyling": `z1vYqthmIIhbZGiGzPT_`,
	"breakStyling": `PAJPKrUb6obDIjU1dZqh`,
	"btmDivStyling": `FUiEf0g4TYZqMiqWgB6A`
};
export default ___CSS_LOADER_EXPORT___;
