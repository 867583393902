import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { PRODUCT_PAGE_API, config } from "../../../../services/ApiRoutes";
import actionTypes from "../productPage.actionTypes";

export function* getAllDomainsDataAPIWorker(action: any): any {
  // toast.loading("Please wait ...");
  try {
    if (action) {
      const headers = {
        userId: action.payload.userId,
        "x-access-token": action.payload.token,
      };
      const result = yield call(
        config.GET_WITH_HEADER,
        `${PRODUCT_PAGE_API.GET_ALL_DOMAINS}`,
        { headers: headers }
      );
      yield put({
        type: actionTypes.GET_ALL_DOMAIN_DATA_API_SUCCESS,
        payload: result.data.data,
      });
    }
    // toast.dismiss();
  } catch (error) {
    yield put({
      type: actionTypes.GET_ALL_DOMAIN_DATA_API_FAILURE,
      payload: error,
    });
    // toast.dismiss();
    console.error(error);
  }
}

export function* getAllProductsDataApiWorker(action: any): any {
  try {
    if (action) {
      const headers = {
        userId: action.payload.userId,
        // Authorization: `Bearer ${action.payload.token}`,
      };
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${PRODUCT_PAGE_API.GET_ALL_PRODUCTS}`,
        {
          params: { domainId: action.payload.domainId },
          headers: headers,
        }
      );
      const sortedResult = result?.data?.data?.sort((a:any,b:any) => a?.position- b?.position)
      console.log("hhh" , sortedResult)
      const res = yield call(
        config.GET_WITH_PARAMS,
        `${PRODUCT_PAGE_API.GET_ALL_PRODUCT_TYPES}`,
        {
          productId: sortedResult?.[0]?.id,
        }
      );
      yield put({
        type: actionTypes.GET_ALL_PRODUCT_DATA_API_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: actionTypes.GET_ALL_PRODUCT_TYPE_DATA_API_SUCCESS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_ALL_PRODUCT_DATA_API_FAILURE,
      payload: error,
    });
  }
}

export function* getAllProductTypesDataApiWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        `${PRODUCT_PAGE_API.GET_ALL_PRODUCT_TYPES}`,
        {
          productId: action.payload.productId,
        }
      );
      yield put({
        type: actionTypes.GET_ALL_PRODUCT_TYPE_DATA_API_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_ALL_PRODUCT_TYPE_DATA_API_FAILURE,
      payload: error,
    });
  }
}

export function* getAllUseCasesDataAPIWorker(action: any): any {
  try {
    if (action) {
      //   const headers = {
      //     userId: action.payload.userId,
      //     "x-access-token": action.payload.token,
      //   };
      const result = yield call(
        config.GET_WITH_HEADER,
        `${PRODUCT_PAGE_API.GET_ALL_USE_CASE}`
        // { headers: headers }
      );
      yield put({
        type: actionTypes.GET_ALL_USE_CASE_DATA_API_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_ALL_USE_CASE_DATA_API_FAILURE,
      payload: error,
    });
  }
}

export function* updateProductCategoryAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.PUT_WITH_BODY,
        `${PRODUCT_PAGE_API.UPDATE_PRODUCT_TYPE}`,
        { category: action.payload.category, id: action.payload.id }
      );
      const res = yield call(
        config.GET_WITH_PARAMS,
        `${PRODUCT_PAGE_API.GET_ALL_PRODUCT_TYPES}`,
        {
          productId: action.payload.productId,
        }
      );
      const useCaseResult = yield call(
        config.GET_WITH_HEADER,
        `${PRODUCT_PAGE_API.GET_ALL_USE_CASE}`
        // { headers: headers }
      );
      yield put({
        type: actionTypes.UPDATE_PRODUCT_CATEGORY_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: actionTypes.GET_ALL_PRODUCT_TYPE_DATA_API_SUCCESS,
        payload: res.data.data,
      });
      yield put({
        type: actionTypes.GET_ALL_USE_CASE_DATA_API_SUCCESS,
        payload: useCaseResult.data.data,
      });
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.UPDATE_PRODUCT_CATEGORY_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}

export function* createProductTeamAPIWorker(action: any): any {
  toast.dismiss();
  toast.loading("Please wait ...");
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_BODY,
        `${PRODUCT_PAGE_API.CREATE_PRODUCT_TEAM}`,
        action.payload
      );
      yield put({
        type: actionTypes.CREATE_PRODUCT_PORTFOLIO_TEAM_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: actionTypes.ADD_CURRENT_PRODUCT_TO_PORTFOLIO,
        payload: [],
      });
      toast.dismiss();
      toast.success(result?.data?.data?.message);
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.CREATE_PRODUCT_PORTFOLIO_TEAM_FAILURE,
      payload: error,
    });
    toast.dismiss();
    toast.error("Product Creation Unsuccessful");
    console.error(error);
  }
}
