import { all, fork } from "redux-saga/effects";
import * as baseScreenADSaga from "./BaseScreenAD/saga";
import * as dashboardWatcher from "./dashboard/saga";
import * as callLoggerAgentDesktopWatcher from "./callLogger/saga";
import * as chatLoggerAgentDesktopWatcher from "./chatLogger/saga";
export default function* rootAgentDesktopSaga() {
  yield all([...Object.values(baseScreenADSaga).map(fork)]);
  yield all([...Object.values(dashboardWatcher).map(fork)]);
  yield all([...Object.values(callLoggerAgentDesktopWatcher).map(fork)]);
  yield all([...Object.values(chatLoggerAgentDesktopWatcher).map(fork)]);
}
