import { Reducer } from "redux";
import actionType from "../campaignTable.actionTypes";
import { CampaignInterface } from "../interface";

const initialState: CampaignInterface = {
  campaignsData: {
    data: null,
    status: "idle",
    error: null,
  },
  selectedCampaginData: {},
  terminateCampaignData : {data : [] , isLoading : false , error : {}}
};

const campaignTableReducer: Reducer<CampaignInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionType.GET_ALL_CAMPAIGNS_DATA: {
      return {
        ...state,
        campaignsData: {
          ...state.campaignsData,
          data: action.payload,
        },
      };
    }
    case actionType.SET_SELECTED_CAMPAIGN_DATA: {
      return {
        ...state,
        selectedCampaginData: action.payload,
      };
    }
    case actionType.POST_CAMPAIGN_TERMINATE_REQUEST: {
      return {
        ...state,
        terminateCampaignData: {data : {}, isLoading : true , error : {}},
      };
    }
    case actionType.POST_CAMPAIGN_TERMINATE_SUCCESS: {
      return {
        ...state,
        terminateCampaignData: {data : action.payload, isLoading : false , error : {}},
      };
    }
    case actionType.POST_CAMPAIGN_TERMINATE_FAILURE: {
      return {
        ...state,
        terminateCampaignData: {data : {}, isLoading : false , error : action.payload},
      };
    }
    default: {
      return state;
    }
  }
};

export default campaignTableReducer;
