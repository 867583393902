export default {
  SET_CONNECT_EXISTING_ACCOUNT_REQUEST: "SET_CONNECT_EXISTING_ACCOUNT",
  SET_CONNECT_EXISTING_ACCOUNT_SUCCESS: "SET_CONNECT_EXISTING_ACCOUNT_SUCCESS",
  SET_CONNECT_EXISTING_ACCOUNT_FAILURE: "SET_CONNECT_EXISTING_ACCOUNT_FAILURE",

  SET_NEW_ACCOUNT_REQUEST: "SET_NEW_ACCOUNT",
  SET_NEW_ACCOUNT_SUCCESS: "SET_NEW_ACCOUNT_SUCCESS",
  SET_NEW_ACCOUNT_FAILURE: "SET_NEW_ACCOUNT_FAILURE",

  UPDATE_NEW_ACCOUNT_REQUEST: "UPDATE_NEW_ACCOUNT",
  UPDATE_NEW_ACCOUNT_SUCCESS: "UPDATE_NEW_ACCOUNT_SUCCESS",
  UPDATE_NEW_ACCOUNT_FAILURE: "UPDATE_NEW_ACCOUNT_FAILURE",

  SET_CLEAR_SMS_DATA: "SET_CLEAR_SMS_DATA",
  SET_NEW_ACCOUNT_CLEAN: "SET_NEW_ACCOUNT_CLEAN",
  SET_CURRENT_SCREEN_SMS: "SET_CURRENT_SCREEN_SMS",
};
