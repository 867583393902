import React from "react";
import Moment from "moment";
import {
  sortingDescIcon,
  sortingAscIcon,
  noSortingIcon,
} from "../../../../theme/assets/genericSvg";
import { feedbackColumnInterface } from "./interface";
import { getValueByReferenceKey } from "../../../../utils";
import { ROLES } from "../../../../constants";

interface argInterface {
  limit: number;
  columnSchema: Array<feedbackColumnInterface>;
  handleSortClick: any;
  title: string;
  tableSortingColumn: { referenceKey: string; direction: string };
  accountType: string;
}

export const tableConstant = ({
  limit,
  columnSchema,
  handleSortClick,
  title,
  tableSortingColumn,
  accountType,
}: argInterface) => {
  const sortingColumn = Object.keys(tableSortingColumn)?.[0];
  const columnsToShow: Array<any> = [];
  let totalColumnData = [...columnSchema];

  totalColumnData = totalColumnData.filter((item) => item.isFeebackColumn);
  totalColumnData = totalColumnData.sort((a, b) => a.position - b.position);
  totalColumnData.map((item, currentRow) => {
    if (item.originalName === "Sr. No") {
      const obj = {
        title: item.originalName,
        render: (rowData: any, key: string, index: number, pageNo: number) => (
          <span>{(pageNo - 1) * limit + index + 1}</span>
        ),
      };
      columnsToShow.push(obj);
    } else if (
      item.originalName === "Feedback" &&
      sessionStorage.getItem("userType") === "Internal" &&
      sessionStorage.getItem("role") === ROLES.campaignAnalyst.name
    ) {
      return;
    } else if (item.originalName === "Issue") {
      const obj = {
        title: item?.originalName,
        key: item?.isFeedbackReferenceKey,
        render: (rowData: any, key: string) => {
          return (
            <span>
              {rowData &&
                rowData?.issue &&
                Object.keys(rowData?.issue)?.length > 0 &&
                Object.keys(rowData?.issue)?.map((item, i) => {
                  return (
                    <>
                      <div key={i}>
                        {rowData?.issue[item] &&
                          rowData?.issue[item]?.map((each: any, j: number) => {
                            return (
                              <>
                                <p
                                  key={i * j + 1}
                                  style={{
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    color: "#0174FF",
                                    background: "#ddebff",
                                    borderRadius: "3px",
                                    padding: "3px 12px",
                                    marginBottom: "5px",
                                    // marginLeft: "auto",
                                    // marginRight: "auto",
                                    width: "fit-content",
                                    textAlign: "left",
                                    ...(item === "Feedback"
                                      ? { whiteSpace: "pre-wrap" }
                                      : {}),
                                  }}
                                >
                                  {item + " - " + each}
                                </p>
                              </>
                            );
                          })}
                      </div>
                    </>
                  );
                })}
            </span>
          );
        },
      };
      columnsToShow.push(obj);
    } else {
      const obj = {
        title: (
          <span>
            {item.isSortBtn && (
              <img
                src={
                  sortingColumn === item.isFeedbackReferenceKey
                    ? tableSortingColumn.direction === "asc"
                      ? sortingAscIcon
                      : sortingDescIcon
                    : noSortingIcon
                }
                style={{ marginRight: "8px", cursor: "pointer" }}
                onClick={handleSortClick}
                data-reference-key={item.isFeedbackReferenceKey}
                alt="sorting Decreasing Icon"
              />
            )}
            <span>{item.originalName}</span>
          </span>
        ),
        key: item.isFeedbackReferenceKey,
        render: (rowData: any, key: string) => (
          <span>{getValueByReferenceKey(rowData, key)}</span>
        ),
      };
      columnsToShow.push(obj);
    }
  });

  return columnsToShow;
};

//   let column = [
//     {
//       title: "Sr. No.",
//       render: (rowData: any, key: string, index: number, pageNo: number) => {
//         return <span>{(pageNo - 1) * limit + index + 1}</span>;
//       },
//     },
//     {
//       title: (
//         <div>
//           <p>Date</p>
//         </div>
//       ),
//       render: (rowData: any) => {
//         return (
//           <div>
//             <p>{Moment.utc(rowData?.date).format("DD-MM-YY") || "-"}</p>
//           </div>
//         );
//       },
//     },
//     {
//       title: (
//         <span>
//           {
//             <img
//               src={
//                 tableSortingColumn.referenceKey === "phoneNo"
//                   ? tableSortingColumn.direction === "asc"
//                     ? sortingAscIcon
//                     : sortingDescIcon
//                   : noSortingIcon
//               }
//               style={{ marginRight: "8px", cursor: "pointer" }}
//               onClick={handleSortClick}
//               data-reference-key="phoneNo"
//               alt="sorting Decreasing Icon"
//             />
//           }
//           <span>{"Contact No."}</span>
//         </span>
//       ),
//       render: (rowData: any) => {
//         return <span>{rowData?.phoneNo || "-"}</span>;
//       },
//     },
//     {
//       title: accountType === "Internal" ? "Type" : "Client Name",
//       render: (rowData: any) => {
//         return (
//           <span>
//             {accountType === "Internal"
//               ? rowData?.type
//                 ? rowData?.type
//                 : "-"
//               : rowData?.accountName}
//           </span>
//         );
//       },
//     },
//     {
//       title: "Language",
//       render: (rowData: any) => {
//         return <span>{rowData?.language || "-"}</span>;
//       },
//     },

//     {
//       title: "Flow Type",
//       render: (rowData: any) => {
//         return <span>{rowData?.flow || "-"}</span>;
//       },
//     },
//     {
//       title: "Session Id",
//       render: (rowData: any) => {
//         return <span>{rowData?.sessionId || "-"}</span>;
//       },
//     },
//     {
//       title:
//         title.toString().toLowerCase() === ROLES.campaignAnalyst.name.toLowerCase()
//           ? "Issue"
//           : "Feedback",
//       render: (rowData: any) => {
//         if (title.toString().toLowerCase() === ROLES.campaignAnalyst.name.toLowerCase() ) {
//           return (
//             <div>
//               {Object.keys(rowData?.issue).length > 0 &&
//                 Object.keys(rowData?.issue)?.map((item, i) => {
//                   return (
//                     <>
//                       <div key={i}>
//                         {rowData?.issue[item] &&
//                           rowData?.issue[item]?.map((each: any) => {
//                             return (
//                               <>
//                                 <p
//                                   key={i + i + 1}
//                                   style={{
//                                     fontFamily: "Roboto",
//                                     fontStyle: "normal",
//                                     fontWeight: "500",
//                                     fontSize: "12px",
//                                     color: "#0174FF",
//                                     background: "#ddebff",
//                                     borderRadius: "3px",
//                                     padding: "3px 12px",
//                                     marginBottom: "5px",
//                                     marginLeft: "auto",
//                                     marginRight: "auto",
//                                     width: "fit-content",
//                                     textAlign: "center",
//                                   }}
//                                 >
//                                   {item + " - " + each}
//                                 </p>
//                               </>
//                             );
//                           })}
//                       </div>
//                     </>
//                   );
//                 })}
//             </div>
//           );
//         } else {
//           return <span>{rowData?.feedback || "-"}</span>;
//         }
//       },
//     },
//   ];
//   // for report issue
//   if (title.toString().toLowerCase() === ROLES.campaignAnalyst.name.toLowerCase()) {
//     const status = {
//       title: "Status",
//       render: (rowData: any) => {
//         return <span>{rowData?.status}</span>;
//       },
//     };

//     const remark = {
//       title: "Remark",
//       render: (rowData: any) => {
//         return <span>{rowData?.remark}</span>;
//       },
//     };
//     column = [...column.slice(0, 3), ...column.slice(3), status, remark];
//   }
//   return column;
// };

// export const tableConstantForIssue = {};
