export default {
  GET_ALL_INTEGRATIONS_DATA_REQUEST: "GET_ALL_INTEGRATIONS_DATA_REQUEST",
  GET_ALL_INTEGRATIONS_DATA_SUCCESS: "GET_ALL_INTEGRATIONS_DATA_SUCCESS",
  GET_ALL_INTEGRATIONS_DATA_FAILURE: "GET_ALL_INTEGRATIONS_DATA_FAILURE",
  FILTER_BY_CATEGORY_DATA: "FILTER_BY_CATEGORY_DATA",
  CREATE_INTEGRATION_DATA_REQUEST: "CREATE_INTEGRATION_DATA_REQUEST",
  CREATE_INTEGRATION_DATA_SUCCESS: "CREATE_INTEGRATION_DATA_SUCCESS",
  CREATE_INTEGRATION_DATA_FAILURE: "CREATE_INTEGRATION_DATA_FAILURE",
  CLEAR_INTEGRATION_DATA: "CLEAR_INTEGRATION_DATA",
  UPDATE_INTEGRATION_DATA_REQUEST: "UPDATE_INTEGRATION_DATA_REQUEST",
  UPDATE_INTEGRATION_DATA_SUCCESS: "UPDATE_INTEGRATION_DATA_SUCCESS",
  UPDATE_INTEGRATION_DATA_FAILURE: "UPDATE_INTEGRATION_DATA_FAILURE",
  SET_SELECTED_INTEGRATION_DATA: "SET_SELECTED_INTEGRATION_DATA",
  CLEAR_CREATED_INTEGRATION_DATA: "CLEAR_CREATED_INTEGRATION_DATA",
};
