import React, { useEffect, useState } from "react";
import styles from "./DropDownThin.module.scss";
import { caratFdown } from "../../../../theme/assets/svg";
interface prop {
  options: Array<number | string>;
  setDataOut: (a: any) => void;
  defaultSelected: number | string;
  openUp: boolean;
}
export default function DropDownThin(props: prop) {
  const [show, setShow] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>();
  useEffect(() => {
    setSelected(props.defaultSelected);
  }, []);
  useEffect(() => {
    setSelected(props.defaultSelected);
  }, []);
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.dd_tip}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShow(!show);
        }}
      >
        <p>{selected}</p>
        <img src={caratFdown} alt="" />
      </div>
      {show && (
        <div
          className={`${styles.wrapper_list} ${
            props.openUp ? styles.openup : ""
          }`}
        >
          {props.options.map((each) => {
            return (
              <div key={each} onClick={() => setSelected(each)}>
                <p>{each}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
