import React from 'react'
import style from "./MessageModal.module.scss"


interface props{
    text ?: string ,
    buttonName ?: string,
    styleImgMaia ?: any,
    btnClick ?: any,
    img ?:string
}
function MessageModal(props : props) {
    const handleNextclick =() => {
        props.btnClick()
    }
  return (
    <div className={style.messageMaiaModalWrapper}>
    <div className={style.messageMaiaModalOuterDiv}>
      <p className={style.contentPara}> {props.text} </p>
      <div className={style.buttonContainer}>
        <button  className={style.btnNext} 
        onClick ={() => handleNextclick()}
        >
          Next
        </button>
      </div>
    </div>
    <img className={style.maiaLeftImg} style={props.styleImgMaia} src={props.img}></img>
  </div>
  )
}

export default MessageModal