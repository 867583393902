export default {
  SAVE_SELECTED_PRODUCT_DATA: "SAVE_SELECTED_PRODUCT_DATA",
  CURRENT_PRODUCT_DETAILS: "CURRENT_PRODUCT_DETAILS",
  ADD_CURRENT_PRODUCT_TO_PORTFOLIO: "ADD_CURRENT_PRODUCT_TO_PORTFOLIO",
  GET_ALL_DOMAIN_DATA_API_REQUEST: "GET_ALL_DOMAIN_DATA_API_REQUEST",
  GET_ALL_DOMAIN_DATA_API_FAILURE: "GET_ALL_DOMAIN_DATA_API_FAILURE",
  GET_ALL_DOMAIN_DATA_API_SUCCESS: "GET_ALL_DOMAIN_DATA_API_SUCCESS",
  GET_ALL_PRODUCT_DATA_API_REQUEST: "GET_ALL_PRODUCT_DATA_API_REQUEST",
  GET_ALL_PRODUCT_DATA_API_FAILURE: "GET_ALL_PRODUCT_DATA_API_FAILURE",
  GET_ALL_PRODUCT_DATA_API_SUCCESS: "GET_ALL_PRODUCT_DATA_API_SUCCESS",
  GET_ALL_PRODUCT_TYPE_DATA_API_REQUEST:
    "GET_ALL_PRODUCT_TYPE_DATA_API_REQUEST",
  GET_ALL_PRODUCT_TYPE_DATA_API_FAILURE:
    "GET_ALL_PRODUCT_TYPE_DATA_API_FAILURE",
  GET_ALL_PRODUCT_TYPE_DATA_API_SUCCESS:
    "GET_ALL_PRODUCT_TYPE_DATA_API_SUCCESS",
  GET_ALL_USE_CASE_DATA_API_REQUEST: "GET_ALL_USE_CASE_DATA_API_REQUEST",
  GET_ALL_USE_CASE_DATA_API_FAILURE: "GET_ALL_USE_CASE_DATA_API_FAILURE",
  GET_ALL_USE_CASE_DATA_API_SUCCESS: "GET_ALL_USE_CASE_DATA_API_SUCCESS",
  CLEAR_ALL_PRODUCT_DATA: "CLEAR_ALL_PRODUCT_DATA",
  UPDATE_PRODUCT_CATEGORY_REQUEST: "UPDATE_PRODUCT_CATEGORY_REQUEST",
  UPDATE_PRODUCT_CATEGORY_SUCCESS: "UPDATE_PRODUCT_CATEGORY_SUCCESS",
  UPDATE_PRODUCT_CATEGORY_FAILURE: "UPDATE_PRODUCT_CATEGORY_FAILURE",
  CREATE_PRODUCT_PORTFOLIO_TEAM_REQUEST:
    "CREATE_PRODUCT_PORTFOLIO_TEAM_REQUEST",
  CREATE_PRODUCT_PORTFOLIO_TEAM_SUCCESS:
    "CREATE_PRODUCT_PORTFOLIO_TEAM_SUCCESS",
  CREATE_PRODUCT_PORTFOLIO_TEAM_FAILURE:
    "CREATE_PRODUCT_PORTFOLIO_TEAM_FAILURE",
  CLEAR_CREATED_PRODUCT_TEAMS: "CLEAR_CREATED_PRODUCT_TEAMS",
  PRODUCT_MAIA_POP_UP_CLOSE: "PRODUCT_MAIA_POP_UP_CLOSE",
};
