// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.__nt9xbgoXCWwvSLy8NO{width:250px;display:flex;justify-content:center;align-items:center;flex-direction:column;border:.5px solid #b8b8b8;border-radius:7px;margin:auto;padding-bottom:10px}.__nt9xbgoXCWwvSLy8NO .GVwI_NLHP12_jbcf695B{margin-top:5px;margin-bottom:18px;font-weight:500;font-size:18px;line-height:28px;display:flex;justify-content:center;align-items:center}.__nt9xbgoXCWwvSLy8NO .GVwI_NLHP12_jbcf695B img{cursor:pointer;margin:5px}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/transcript/newReportIssue/NewReportIssue.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,yBAAA,CACA,iBAAA,CACA,WAAA,CACA,mBAAA,CACA,4CACE,cAAA,CACA,kBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,gDACE,cAAA,CACA,UAAA","sourcesContent":[".wrapper {\n  width: 250px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  border: 0.5px solid #b8b8b8;\n  border-radius: 7px;\n  margin: auto;\n  padding-bottom: 10px;\n  .title {\n    margin-top: 5px;\n    margin-bottom: 18px;\n    font-weight: 500;\n    font-size: 18px;\n    line-height: 28px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    img {\n      cursor: pointer;\n      margin: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `__nt9xbgoXCWwvSLy8NO`,
	"title": `GVwI_NLHP12_jbcf695B`
};
export default ___CSS_LOADER_EXPORT___;
