import React from "react";
import CustomerCard from "./CustomerCard";
import LoaderSaarthi from "../../../../components/generic/loader/Loader";
//import styles from "./ComponentName.module.scss";

interface props {
  isLoading: boolean;
  customersData: Record<string, any>;
  schema: Record<string, any>;
  handleInputChange: (value: any, key: any, index: any) => void;
  handleRemoveClick: (index: any) => void;
  showExtraFieldsIndex: number | null;
  handleShowMoreToggle: (index: any, e: any) => void;
  handleDropdownClick: (e: any) => void;
  handleCalenderClick: (e: any) => void;
  handleCalenderBlur: (e: any) => void;
  dropdownCallMapping: Record<string, any>;
  showFieldsByFlow: Record<string, string[]>;
  showCategoriesByFlow: Record<string, string[]>;
  showFieldsByDisposition: Record<string, string[]>;
  showCategoriesByDisposition: Record<string, string[]>;
  showLanguageByFlow: Record<string, any>;
}

export default function CampaignTestingBody(props: props) {
  return (
    <div className="container__body">
      {props.isLoading ? (
        <LoaderSaarthi />
      ) : !props?.schema?.length || props.schema.length <= 0 ? (
        <div className="errorDiv">No schema or no agent Data</div>
      ) : (
        props.customersData?.map((customerData: any, index: any) => {
          return (
            <CustomerCard
              key={index}
              customerData={customerData}
              schema={props.schema}
              customerIndex={index}
              handleInputChange={props.handleInputChange}
              handleRemoveClick={props.handleRemoveClick}
              showExtraFieldsIndex={props.showExtraFieldsIndex}
              handleShowMoreToggle={props.handleShowMoreToggle}
              handleDropdownClick={props.handleDropdownClick}
              handleCalenderClick={props.handleCalenderClick}
              handleCalenderBlur={props.handleCalenderBlur}
              dropdownCallMapping={props.dropdownCallMapping}
              showFieldsByFlow={props.showFieldsByFlow}
              showCategoriesByFlow={props.showCategoriesByFlow}
              showFieldsByDisposition={props.showFieldsByDisposition}
              showCategoriesByDisposition={props.showCategoriesByDisposition}
              showLanguageByFlow={props.showLanguageByFlow}
            />
          );
        })
      )}
    </div>
  );
}
