export default {
  GET_SUMMARY_REQUEST_AGENT_DESKTOP: "GET_SUMMARY_REQUEST_AGENT_DESKTOP",
  GET_SUMMARY_SUCCESS_AGENT_DESKTOP: "GET_SUMMARY_SUCCESS_AGENT_DESKTOP",
  GET_SUMMARY_FAILURE_AGENT_DESKTOP: "GET_SUMMARY_FAILURE_AGENT_DESKTOP",
  GET_TRANSCRIPT_REQUEST_AGENT_DESKTOP: "GET_TRANSCRIPT_REQUEST_AGENT_DESKTOP",
  GET_TRANSCRIPT_SUCCESS_AGENT_DESKTOP: "GET_TRANSCRIPT_SUCCESS_AGENT_DESKTOP",
  GET_TRANSCRIPT_FAILURE_AGENT_DESKTOP: "GET_TRANSCRIPT_FAILURE_AGENT_DESkTOP",
  GET_SESSION_ID_REQUEST_AGENT_DESKTOP: "GET_SESSION_ID_REQUEST_AGENT_DESKTOP",
  GET_SESSION_ID_SUCCESS_AGENT_DESKTOP: "GET_SESSION_ID_SUCCESS_AGENT_DESKTOP",
  GET_SESSION_ID_FAILURE_AGENT_DESKTOP: "GET_SESSION_ID_FAILURE_AGENT_DESKTOP",
  SET_SUMMARY_DATA_EDITABLE_AGENT_DESKTOP:
    "SET_SUMMARY_DATA_EDITABLE_AGENT_DESKTOP",
  SET_CHAT_STATUS_AGENT_DESKTOP: "SET_CHAT_STATUS_AGENT_DESKTOP",
  SET_SELECTED_SESSION_ID_AGENT_DESKTOP:
    "SET_SELECTED_SESSION_ID_AGENT_DESKTOP",
  SET_SELECTED_CONVERSATION_ID_AGENT_DESKTOP:
    "SET_SELECTED_CONVERSATION_ID_AGENT_DESKTOP",
  UPDATE_SUMMARY_REQUEST_AGENT_DESKTOP: "UPDATE_SUMMARY_REQUEST_AGENT_DESKTOP",
  UPDATE_SUMMARY_SUCCESS_AGENT_DESKTOP: "UPDATE_SUMMARY_SUCCESS_AGENT_DESKTOP",
  UPDATE_SUMMARY_FAILURE_AGENT_DESKTOP: "UPDATE_SUMMARY_FAILURE_AGENT_DESKTOP",
  SET_SUMMARY_UPDATE_SUCCESS_AGENT_DESKTOP:
    "SET_SUMMARY_UPDATE_SUCCESS_AGENT_DESKTOP",
  SET_TIMESTAMP_CALL_ACCEPT_AGENT_DESKTOP:
    "SET_TIMESTAMP_CALL_ACCEPT_AGENT_DESKTOP",
  // LOADER_SESSION_ID_AD: "LOADER_SESSION_ID_AD",
  HANDLE_CALL_MODULE_LEAVE_AD: "HANDLE_CALL_MODULE_LEAVE_AD",
  SET_SHOW_CALL_MODULE_AD: "SET_SHOW_CALL_MODULE_AD",
  SET_CALL_SESSION_ID_LOADER: "SET_CALL_SESSION_ID_LOADER",
};
