import React from "react";
import styles from "./MaiaCallTable.module.scss";
import SimpleTable from "../simpleTable/SimpleTable";
interface dataforTable {
  slNo: string;
  disposition: string;
  count: string;
}
/**
 * @example
 * const data = [
  {
    slNo: "1",
    disposition: "No Response",
    count: "1400(40%)",
  },
  {
    slNo: "1",
    disposition: "No Response",
    count: "1400(40%)",
  },
  {
    slNo: "1",
    disposition: "No Response",
    count: "1400(40%)",
  },
  {
    slNo: "1",
    disposition: "No Response",
    count: "1400(40%)",
  },
];
const bodyTranformer = [
  { key: "slNo", transform: (value: any) => value },
  { key: "disposition", transform: (value: any) => value },
  { key: "count", transform: (value: any) => value },
];
const headTranformer = [
  { key: "slNo", displayName: "S.No." },
  { key: "disposition", displayName: "Disposition" },
  { key: "count", displayName: "Count" },
];
**/
function MaiaCallTable(props: {
  data: any;
  bodyTranformer: { key: string; transform: any }[];
  headingTransformer: { key: string; displayName: any }[];
}) {
  return (
    <>
      <div className={styles.wrapper}>
        <SimpleTable
          dataToShowOnTable={props.data}
          bodyTranformer={props.bodyTranformer}
          headingTranformer={props.headingTransformer}
          extraClassWrapper={styles.fixed_header}
          donotDisplayBreak={true}
        />
      </div>
    </>
  );
}
export default MaiaCallTable;
