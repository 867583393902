import { combineReducers } from "@reduxjs/toolkit";
import campaignTableReducer from "./campaignTable/reducer";
import progressBarReducer from "./progressBar/reducer";
import createCampaignReducer from "./createCampaign/reducer";
import dataUploadReducer from "./dataUpload/reducer";
import strategySelectionReducer from "./strategySelection/reducer";
import agentConfigReducer from "./agentConfiguration/reducer";
import schedulerSettingReducer from "./schedularSetting/reducer";


const rootCampaignReducer = combineReducers({
  campaignTable: campaignTableReducer,
  progressBar: progressBarReducer,
  createCampaignData: createCampaignReducer,
  dataUploadData: dataUploadReducer,
  strategySelectionData: strategySelectionReducer,
  agentConfigData: agentConfigReducer,
  schedulerData: schedulerSettingReducer,
});

export default rootCampaignReducer;
