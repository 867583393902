import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import "./dataTable.module.scss";
import { Stack, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RemovePhoneNumberModal from "../removePhoneNumberModal/removePhoneNumberModal";
import Button from "../../../generic/button/Button";
import styles from "./dataTable.module.scss";
import { useDispatch } from "react-redux";
import {
  getPhoneNumber,
  setSelectedRowsFromCheckbox,
} from "../../../../redux/exclusionsList/actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import moment from "moment";
import { noDataIcon } from "../../../../theme/assets/svg";

interface DataTableProps {
  paginationModel: any;
  setPaginationModel: (prevState: any) => any;
  isRemoveNumberModalVisible?: boolean;
  handleRemovePhoneNumberModal?: () => void;
}

const DataTable = ({ paginationModel, setPaginationModel }: DataTableProps) => {
  // USESTATE SECTION
  const [isRemoveNumberModalVisible, setIsRemoveNumberModalVisible] =
    useState(false);

  const [phoneNumberList, setPhoneNumberList] = useState([]);

  const [selectedRow, setSelectedRow]: any = useState();

  // USESELECTOR SECTION
  const phoneNumberData: any = useSelector(
    (store: RootState) => store?.exclusionsListReducer?.phoneNumberData
  );

  const isPhoneNumberRemovedSuccessfully: any = useSelector(
    (store: RootState) =>
      store?.exclusionsListReducer?.isPhoneNumberRemovedSuccessfully
  );

  const isPhoneNumberAdditionSuccessful = useSelector(
    (store: RootState) =>
      store?.exclusionsListReducer?.isPhoneNumberAdditionSuccessful
  );

  const exclusionsListType = useSelector((store: RootState) => {
    return store?.exclusionsListReducer?.exclusionsListType;
  });

  const accountId = useSelector(
    (store: any) => store.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const accountDetails = useSelector(
    (store: RootState) => store?.schedulerReducer?.accountInfoDetails?.id
  );

  const isLoading = useSelector((store: RootState) => {
    return store?.exclusionsListReducer?.isLoading;
  });

  // USEEFFECT SECTION
  useEffect(() => {
    if (!exclusionsListType) return;
    getPhoneNumberApiCall();
  }, [exclusionsListType]);

  useEffect(() => {
    const tempList = phoneNumberData?.exclusionList?.map((obj: any) => ({
      ...obj,
      dateAdded: moment(new Date(obj.addedDate.slice(0, -1))).format(
        "DD-MM-YYYY"
      ),
      lastCommunicationDate:
        obj.lastCommunicationDate === "-"
          ? obj.lastCommunicationDate
          : moment(new Date(obj.lastCommunicationDate.slice(0, -1))).format(
              "DD-MM-YYYY"
            ),
    }));
    setPhoneNumberList(tempList);
  }, [phoneNumberData]);

  useEffect(() => {
    if (isPhoneNumberRemovedSuccessfully || isPhoneNumberAdditionSuccessful) {
      setTimeout(
        () =>
          setPaginationModel((prevState: any) => ({
            pageSize: prevState?.pageSize,
            page: 0,
          })),
        1000
      );
    }
  }, [isPhoneNumberRemovedSuccessfully, isPhoneNumberAdditionSuccessful]);

  useEffect(() => {
    getPhoneNumberApiCall();
  }, [paginationModel]);

  // HANDLERS
  const handlePageClick = (e: React.ChangeEvent<unknown>, page: number) => {
    setPaginationModel((prevState: any) => ({
      pageSize: prevState?.pageSize,
      page: page - 1,
    }));
  };

  const handleRemovePhoneNumberModal = () => {
    setIsRemoveNumberModalVisible((prevState) => !prevState);
  };

  // MISC
  const useStyles = makeStyles({
    dataGridCheckbox: {
      "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
        {
          background: "#f5f5f5",
          outline: "none !important",
        },
      "& .MuiDataGrid-cell:focus": {
        outline: "none !important",
      },
      "& .MuiDataGrid-columnHeader:focus": {
        outline: "none !important",
      },
      "& .MuiDataGrid-columnHeaderCheckbox": {
        outline: "none !important",
      },
      "& .MuiDataGrid-cellCheckbox": {
        outline: "none !important",
      },
    },
  });

  const dispatch = useDispatch();

  const classes = useStyles();

  const getPhoneNumberApiCall = () => {
    const data: any = {
      pageNumber: String(paginationModel?.page + 1),
      pageLimit: String(paginationModel?.pageSize),
    };
    if (exclusionsListType === "Global") data["isGlobal"] = true;
    else data["accountId"] = accountDetails;
    dispatch(getPhoneNumber(data));
  };

  const columns: GridColDef[] = [
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: styles.header,
      renderCell: (params) => (
        <span className={styles.phoneNumberCellText}>{params.value}</span>
      ),
    },
    {
      field: "dateAdded",
      headerName: "Date Added",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: styles.header,
    },
    {
      field: "lastCommunicationDate",
      headerName: "Last Communication Date",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: styles.header,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: styles.header,
      renderCell: (params) => (
        <Button
          text="Remove"
          extraClass={styles.removeBtn}
          id="remove_btn"
          onClick={handleRemovePhoneNumberModal}
        />
      ),
    },
  ];

  const displayNoDataComponent = () => (
    <div className={styles.noDataDiv}>
      <img src={noDataIcon} alt="no-data" className={styles.noDataImg} />
      <p className={styles.noDataText}>
        Click on the &quot;Add Phone Number&quot; to create {exclusionsListType}{" "}
        exclusion list
      </p>
    </div>
  );

  return (
    <>
      <DataGrid
        rows={phoneNumberList ? phoneNumberList : []}
        columns={columns}
        checkboxSelection
        // paginationModel={paginationModel}
        className={classes.dataGridCheckbox}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        hideFooterSelectedRowCount
        disableRowSelectionOnClick
        hideFooter
        rowHeight={50}
        onRowClick={(row) => {
          setSelectedRow(row);
        }}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = phoneNumberList?.filter((row: any) =>
            selectedIDs.has(row.id.toString())
          );
          dispatch(setSelectedRowsFromCheckbox(selectedRows));
        }}
        slots={{
          ...(!isLoading && { noRowsOverlay: displayNoDataComponent }),
        }}
        sx={{ height: 570, width: "100%" }}
      />
      <Stack alignItems="center">
        {phoneNumberData?.totalPages ? (
          <Pagination
            count={Math.ceil(phoneNumberData?.totalPages)}
            showFirstButton
            showLastButton
            onChange={handlePageClick}
            color="primary"
            page={paginationModel?.page + 1}
          />
        ) : null}
      </Stack>
      <RemovePhoneNumberModal
        isRemoveNumberModalVisible={isRemoveNumberModalVisible}
        handleRemovePhoneNumberModal={handleRemovePhoneNumberModal}
        phoneNumber={selectedRow?.row?.phoneNumber}
        type={"single"}
      />
    </>
  );
};

export default DataTable;
