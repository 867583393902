const NEW_REPORT_ISSUES_ACTION = {
  // SET_ALL_REPORT_ISSUES: "SET_ALL_REPORT_ISSUES",
  // SET_BOT_REPORT_ISSUES: "SET_BOT_REPORT_ISSUES",
  // SET_ASR_REPORT_ISSUES: "SET_ASR_REPORT_ISSUES",
  // SET_NLU_REPORT_ISSUES: "SET_NLU_REPORT_ISSUES",
  // SET_CUSTOMER_REPORT_ISSUES: "SET_CUSTOMER_REPORT_ISSUES",
  // SET_OTHERS_REPORT_ISSUES: "SET_OTHERS_REPORT_ISSUES",
  CREATE_REPORT_ISSUES_API: "SET_CREATE_REPORT_ISSUES_API",
  FETCH_REPORT_ISSUES: "SET_FETCH_REPORT_ISSUES",
  SET_REPORT_ISSUES: "SET_REPORT_ISSUES",
  SET_UPDATE_REPORT_ISSUES: "SET_UPDATE_REPORT_ISSUES",
  SET_MESSAGE_REPORT_ISSUES: "SET_MESSAGE_REPORT_ISSUES",
  SET_REPORT_ISSUE_UPDATE_ID: "SET_REPORT_ISSUE_UPDATE_ID",
  SET_EMPTY_ISSUE: "SET_EMPTY_ISSUE",
  SET_NO_ISSUE: "SET_NO_ISSUE",
  UPDATE_REPORT_ISSUE: "UPDATE_REPORT_ISSUE",
  UPDATE_NO_ISSUE: "UPDATE_NO_ISSUE",
  REPORT_ISSUE_API_SUCCESS: "REPORT_ISSUE_SUCCESS",
  REPORT_ISSUE_API_FAILURE: "REPORT_ISSUE_FAILURE",
  REPORT_ISSUE_API_CALL: "REPORT_ISSUE_API_CALL",
};

export default NEW_REPORT_ISSUES_ACTION;
