import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import InputContainer from "../../../components/generic/inputContainer/InputContainer";
import DDWithInputOption from "../../../components/generic/ddWIthInputOption/DDWithInputOption";
import {
  setInputField,
  toggleTermsAndPrivacy,
  signUpAPIRequest,
} from "../../../redux/signUpFlow/signUp/action";
import {
  phoneGrey,
  userGrey,
  emailGrey,
  questionMarkGrey,
  linkedIn,
  google,
  usersBlue,
  twitter,
  fourBoxBlue,
} from "../../../theme/assets/genericSvg";
import { loadingFourDot, tick } from "../../../theme/gif";
import Button from "../../../components/generic/button/Button";
import {
  orgNameValidation,
  nameValidation,
  phoneNumberValidation,
  emailValidation,
} from "../../../utils/Validation";
import RootPortal2 from "../../../utils/rootPortal2/RootPortal2";
import EmailVerificationPopUp from "../../../components/moduleComponents/onBoarding/signUp/EmailVerificationPopUp/EmailVerificationPopUp";
import styles from "./SignUp.module.scss";
import ErrorMessage from "../../../components/generic/errorMessage/ErrorMessage";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PUBLIC_DOMAINS } from "../../../constants";
import routes from "../../../router/routes";

export default function SignUp() {
  const userData = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (store: any) => store.signUpModuleReducer.signUp.userData
  );

  const termsPrivacy = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (store: any) => store.signUpModuleReducer.signUp?.termsAndPrivacy
  );
  const signUpAPI = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (store: any) => store.signUpModuleReducer.signUp?.signUpAPI
  );
  const dispatch = useDispatch();

  /* other hooks --------- */
  const { token } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  /* useStates ------------ */
  const [error, setError] = useState<Record<string, string>>({
    organizationName: "",
    name: "",
    phone: "",
    email: "",
    reference: "",
    pageError: "",
  });
  const [btnStatus, setBtnStatus] = useState<
    "loading" | "noAction" | "success"
  >("noAction");
  const [showPopup, setShowPopup] = useState(false);
  const _isMounted = useRef(false);

  const orgName = searchParams.get("organizationName");
  const emailId = searchParams.get("emailId");
  const accountId = searchParams.get("accountId");
  const teamId = searchParams.get("teamId");
  const role = searchParams.get("role");

  const navigate = useNavigate();

  /* useEffects ------ */
  useEffect(() => {
    // reset terms
    dispatch(toggleTermsAndPrivacy(false));
    const initialUserData: any = {
      organizationName: "",
      name: "",
      phone: "",
      email: "",
      reference: "",
    };
    Object.keys(initialUserData).map((key) =>
      dispatch(setInputField(key, initialUserData[key]))
    );
    if (!token) return;
    if (orgName && emailId && accountId && teamId && role) {
      setTimeout(() => {
        dispatch(setInputField("organizationName", orgName));
        dispatch(setInputField("email", emailId));
      }, 200);
    } else {
      setError({ ...error, pageError: "Invalid Link" });
    }
  }, []);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (_isMounted.current) {
      if (!signUpAPI.isLoading && Object.keys(signUpAPI.data).length !== 0) {
        !showPopup && setBtnStatus("success");
        timeout = setTimeout(() => {
          if (signUpAPI.data?.userDetails) {
            navigate(
              `/${routes.CREATE_PASSWORD}/${signUpAPI.data?.userDetails.token}?userName=${signUpAPI.data?.userDetails.name}&emailId=${signUpAPI.data?.userDetails.email} `
            );
          } else setShowPopup(true);
          setBtnStatus("noAction");
        }, 2000);
      } else if (
        !signUpAPI?.isLoading &&
        signUpAPI.error &&
        Object.keys(signUpAPI?.error)?.length !== 0
      ) {
        setBtnStatus("noAction");
        if (signUpAPI?.error?.type === "organizationName") {
          setError((prev) => {
            return { ...prev, organizationName: signUpAPI.error.message };
          });
        } else if (signUpAPI?.error?.type === "email") {
          setError((prev) => {
            return { ...prev, email: signUpAPI.error.message };
          });
        } else if (signUpAPI.error.type === "userName") {
          setError((prev) => {
            return { ...prev, name: signUpAPI.error.message };
          });
        } else if (signUpAPI.error.type === "phone") {
          setError((prev) => {
            return { ...prev, phone: signUpAPI.error.message };
          });
        } else {
          if (signUpAPI.error.message) {
            setError((prev) => {
              return { ...prev, pageError: signUpAPI.error.message };
            });
          } else if (
            (Array.isArray(signUpAPI.error) && !signUpAPI.error?.length) ||
            !Object.keys(signUpAPI.error)?.length ||
            signUpAPI.error === null ||
            signUpAPI.error === undefined
          ) {
            setError((prev) => {
              return { ...prev, pageError: "" };
            });
          } else {
            setError((prev) => {
              return { ...prev, pageError: signUpAPI.error };
            });
          }
        }
      }
    } else {
      _isMounted.current = true;
    }
    return () => clearTimeout(timeout);
  }, [signUpAPI]);

  /* handlers */
  const handleInputFormChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    keyName: string
  ) => {
    let value = e.currentTarget.value;
    if (keyName === "organizationName" && value.length > 50) return;
    if (keyName === "name" && value.length > 35) return;
    if (keyName === "email" && String(value).length > 50) {
      return;
    }
    if (keyName === "email") {
      value = value.toLowerCase();
    }
    if (keyName === "phone" && value.length > 10) {
      return;
    }
    setError((prev) => {
      return { ...prev, pageError: "" };
    });
    if (error[keyName]) {
      setError((prev) => {
        return { ...prev, [keyName]: "" };
      });
    }
    dispatch(setInputField(keyName, value));
  };

  const handleDropDownChange = (value: string, key?: string) => {
    dispatch(setInputField(key as string, value));
    if (error[key as string]) {
      setError((prev) => {
        return { ...prev, [key as string]: "" };
      });
    }
  };

  const handleTermsAndPrivacyToggle = () => {
    dispatch(toggleTermsAndPrivacy(!termsPrivacy));
  };

  const handleSignUpClick = () => {
    setError({ ...error, pageError: "" });
    let isError = false;
    const validateOrgName = orgNameValidation(userData.organizationName);
    const validateName = nameValidation(userData.name);
    const validatePhone = phoneNumberValidation(userData.phone);
    const validateEmail = emailValidation(userData.email);

    if (!validateOrgName.isValid) {
      isError = true;
      setError((prev) => {
        return { ...prev, organizationName: "Please enter organization name." };
      });
    }

    if (!validateName.isValid) {
      isError = true;
      setError((prev) => {
        return { ...prev, name: "Please enter account name." };
      });
    }
    if (!validateEmail.isValid) {
      isError = true;
      setError((prev) => {
        return { ...prev, email: validateEmail.errors.message };
      });
    }
    const domain = userData.email.split("@")[1];
    if (PUBLIC_DOMAINS.includes(domain)) {
      isError = true;
      setError((prev) => {
        return { ...prev, email: "Public domains not allowed." };
      });
    }
    if (!validatePhone.isValid) {
      isError = true;
      setError((prev) => {
        return { ...prev, phone: validatePhone.errors.message as string };
      });
    }
    if (!userData.reference) {
      isError = true;
      setError((prev) => {
        return { ...prev, reference: "Field required." };
      });
    }
    if (!isError) {
      let data: Record<string, any> = {
        organizationName: userData.organizationName,
        name: userData.name,
        email: userData.email,
        phoneNumber: userData.phone,
        hearAboutUs: userData.reference,
        isResendLink: false,
        isInvited: false,
      };
      if (token) {
        data = {
          ...data,
          token: token,
          accountId,
          teamId,
          role,
          isInvited: true,
        };
      }
      dispatch(signUpAPIRequest(data));
      setBtnStatus("loading");
    }
  };

  const handlePopCrossBtnClick = () => {
    setShowPopup(false);
  };

  const handleResendVerificationLink = () => {
    let data: Record<string, any> = {
      organizationName: userData.organizationName,
      name: userData.name,
      email: userData.email,
      phoneNumber: userData.phone,
      hearAboutUs: userData.reference,
      isResendLink: true,
      isInvited: false,
    };
    if (token) {
      data = {
        ...data,
        token: token,
        accountId,
        teamId,
        role,
        isInvited: true,
      };
    }

    dispatch(signUpAPIRequest(data));
  };

  return (
    <div className={styles["sign-up__wrapper"]}>
      <div className={styles["sign-up__form__container"]}>
        <div className={styles["sign-up__form"]}>
          <div className={styles["sign-up__header"]}>
            <h3>Welcome to Pravid!</h3>
            <p>There is so much to explore, Let&lsquo;s get started</p>
          </div>
          <div
            className={`${styles["sign-up__form__div"]} ${
              signUpAPI.isLoading ? "disablePointerEventWithoutOpacity" : ""
            }`}
          >
            <InputContainer
              showLabel={true}
              label={"Organization Name"}
              errorMsg={error.organizationName}
              inputAutoComplete="false"
              inputIconLeft={userGrey}
              inputOnChange={handleInputFormChange}
              inputPlaceholder={"Organization Name"}
              keyName={"organizationName"}
              extraUserInputClass={styles["extraUserInputClass"]}
              inputValue={userData.organizationName}
              readOnly={Boolean(orgName)}
              inputOnKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) =>
                !/^[a-zA-Z\s]*$/.test(evt.key) && evt.preventDefault()
              }
            />
            <InputContainer
              showLabel={true}
              label={"Name"}
              errorMsg={error.name}
              inputAutoComplete="false"
              inputIconLeft={userGrey}
              inputOnChange={handleInputFormChange}
              inputPlaceholder={"Name"}
              keyName={"name"}
              extraUserInputClass={styles["extraUserInputClass"]}
              inputValue={userData.name}
              inputOnKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) =>
                !/^[a-zA-Z\s]*$/.test(evt.key) && evt.preventDefault()
              }
            />
            <InputContainer
              showLabel={true}
              label="E-mail"
              errorMsg={error.email}
              inputAutoComplete="false"
              inputIconLeft={emailGrey}
              inputOnChange={handleInputFormChange}
              inputPlaceholder={"E-mail"}
              keyName={"email"}
              inputValue={userData.email}
              readOnly={Boolean(emailId)}
              extraUserInputClass={styles["extraUserInputClass"]}
              inputOnKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) =>
                !/^[a-zA-Z0-9+_.-@]*$/.test(evt.key) && evt.preventDefault()
              }
            />
            <InputContainer
              showLabel={true}
              label="Phone"
              errorMsg={error.phone}
              inputAutoComplete="false"
              inputIconLeft={phoneGrey}
              inputOnChange={handleInputFormChange}
              inputPlaceholder={"Phone"}
              keyName={"phone"}
              inputValue={userData.phone}
              inputType={"number"}
              extraUserInputClass={styles["extraUserInputClass"]}
              inputOnKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) =>
                ["e", "E", "+", "-", "."].includes(evt.key) &&
                evt.preventDefault()
              }
            />
            <DDWithInputOption
              data={[
                {
                  option: "Linkedin",
                  icon: linkedIn,
                  isDoubleExtraMargin: true,
                },
                { option: "Google", icon: google, isDoubleExtraMargin: true },
                { option: "Friends", icon: usersBlue },
                { option: "Twitter", icon: twitter, isExtraMargin: true },
              ]}
              showLabel={true}
              objectKey={"reference"}
              handleChange={handleDropDownChange}
              selectedItem={userData.reference}
              title={"Where did you hear about us?"}
              titleIcon={questionMarkGrey}
              hasInputOption={{
                isActive: true,
                icon: fourBoxBlue,
                value: "Others",
              }}
              extraWrapperClass={styles["extraWrapperClass"]}
              error={error.reference}
              maxLength={50}
            />
            <div className={styles["checkBoxDiv"]}>
              <div className={styles["checkBoxMsgDiv"]}>
                <input
                  type="checkbox"
                  checked={termsPrivacy}
                  onChange={handleTermsAndPrivacyToggle}
                />
                <p className={styles["checkParaLogIn"]}>
                  I agree with
                  <a
                    href="https://saarthi.ai/terms-of-service"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    &nbsp;Terms of Service&nbsp;
                  </a>
                  and
                  <a
                    rel="noreferrer noopener"
                    href="https://saarthi.ai/privacy-policy"
                    target="_blank"
                  >
                    &nbsp;Privacy Policy
                  </a>
                </p>
              </div>
            </div>
            <div
              className={`${styles.errorContainer} ${
                error.pageError && styles.showErrorMessage
              }`}
            >
              <ErrorMessage
                errorMessage={error.pageError}
                extraClass={styles.extraErrorMessageClass}
              />
            </div>
            <Button
              btnContents={{
                loading: {
                  icon: loadingFourDot,
                  class: styles["extraBtnConditionalClass"],
                },
                noAction: { text: "Sign Up" },
                success: {
                  icon: tick,
                  class: styles["extraBtnConditionalClass"],
                },
              }}
              btnStatus={btnStatus}
              extraClass={styles["extraBtnClass"]}
              disabled={
                !termsPrivacy || Object.keys(error).some((key) => error[key])
              }
              onClick={handleSignUpClick}
              extraBtnImageClass={styles["extraBtnImageClass"]}
            />
            <div className={styles["login-redirect"]}>
              <span>Already have an account? </span>
              <Link to="/login">Log In</Link>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <RootPortal2
          extraModelStyle={{ width: "50vw", borderRadius: "4px" }}
          outSideClickFunction={handlePopCrossBtnClick}
        >
          <EmailVerificationPopUp
            handleCrossClick={handlePopCrossBtnClick}
            handleResendVerificationLink={handleResendVerificationLink}
            error={error.pageError}
            extraClassError={styles.extraErrorMessageClass}
          />
        </RootPortal2>
      )}
    </div>
  );
}
