import React, { useEffect } from "react";
import "./ColumnEditName.scss";
import { createPortal } from "react-dom";
// import { searchIcon } from "../../../../theme/assets/svg/rightSideIcon";
// import { tags,blueTags } from "../../../../theme/assets/svg/rightSideIcon";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux";
import { useState } from "react";
import {
  updateSchemaRequest,
  directlyUpdateSchema,
} from "../../../../redux/onboarding/login/actions";

import {
  setShowEditColumnName,
  setShowColumnCustomizePop,
} from "../../../../redux/filters/actions";
import { useCurrentPageTab } from "../../../../hooks";
import { getDataFromSchema } from "../../../../utils";
import { toast } from "react-toastify";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";
import {
  validateInputMeta,
  validateInputMetaColumnName,
  validateKeys,
} from "../../../../utils/validators/validators";
import { channelData, loggerTab, parentChannels, screenType } from "../../../../constants";
import { columnInterface } from "../../../../screens/logger/report/type";

interface schemaInterface {
  reportColumns: columnInterface[];
  _id: string;
  teamId: string;
}

export default function ColumnEditName() {
  const {
    schema,
    isActivePageType,
    subChannelTab,
    subModuleTab,
    channelTab,
    currentLoggerPage,
  } = useCurrentPageTab();

  const [allData, setAllData] = useState<{
    options: columnInterface[];
    name: string;
  }>({ options: [], name: "Columns" });
  useEffect(() => {
    if (
      subModuleTab.name &&
      channelTab.name &&
      currentLoggerPage &&
      (subChannelTab?.name || !parentChannels.includes(channelTab?.name)) &&
      isActivePageType
    ) {
      setAllData({
        options: getDataFromSchema({
          channel: channelTab,
          dataType: "reportColumns",
          schemaData: schema,
          subChannel: subChannelTab,
          tab: subModuleTab,
          screen: isActivePageType,
          subScreen: currentLoggerPage,
        }) as columnInterface[],
        name: "Columns",
      });
    }
  }, [
    schema,
    subChannelTab?.name,
    subModuleTab?.name,
    channelTab?.name,
    isActivePageType,
    currentLoggerPage,
  ]);

  const dispatch = useDispatch();
  const [editCopy, setEditCopy] = useState<columnInterface[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>({
    _id: -1,
  });
  const [editedText, setEditedText] = useState<string>("");
  const [options, setOptions] = useState<columnInterface[]>([]);
  const [showBlue, setShowBlue] = useState<boolean>(false);
  // const [showConfirm, setShowConfirm] = useState<boolean>(false);
  useEffect(() => {
    const cp = structuredClone(allData.options);
    setEditCopy(cp);
    setOptions(cp);
  }, [allData]);
  useEffect(() => {
    if (selectedItem._id !== -1) {
      setEditedText(selectedItem.customName);
    }
  }, [selectedItem]);
  const userId = useSelector(
    (state: RootState) => state.loginReducer.userLoginInfo?.userDetail?._id
  );
  useEffect(() => {
    setOptions(editCopy);
  }, [editCopy]);
  const handleChangeInInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    each: columnInterface
  ) => {
    if (!validateInputMetaColumnName(e.target.value)) return;
    setEditedText(e.target.value);
    //
  };
  const handleClick = (each: columnInterface) => {
    if (!selectedItem) {
      setSelectedItem({ ...each.kpiDetails, customName: each.customName });
    } else if (selectedItem && each.kpiDetails._id !== selectedItem._id) {
      setSelectedItem({ ...each.kpiDetails, customName: each.customName });
    }
    // setShowConfirm(true);
    //
  };
  const getSearchData = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("Triggered");
    if (!validateInputMeta(e.target.value)) return;
    if (e.target.value.length < 3 && !(e.target.value.length === 0)) return;
    const searchTerm = e.target.value.toLowerCase();
    const searchedData: columnInterface[] = [];
    editCopy.forEach((each) => {
      if (each.customName.toLowerCase().includes(searchTerm)) {
        searchedData.push(each);
      }
    });
    setOptions(searchedData);
  };
  const handleMouseLeave = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    each: columnInterface
  ) => {
    let editCopyTemp = editCopy;
    editCopyTemp = editCopyTemp.map((eachx) => {
      if (eachx.kpiDetails._id === each.kpiDetails._id)
        return { ...each, customName: editedText };
      else return eachx;
    });
    setEditCopy(editCopyTemp);
    setSelectedItem({ _id: -1 });
  };
  const handleTags = () => {
    let cp = structuredClone(allData.options);
    cp = cp.map((each) => {
      return {
        ...each,
        customName: each.kpiDetails.originalName,
      };
    });

    setEditCopy(cp);
    /* uncomment --------------- */
    const tempSchema: schemaInterface = structuredClone(schema);
    tempSchema.reportColumns.forEach((item) => {
      if (
        item.screen === screenType.logger.name.toLowerCase() &&
        item?.subScreen === loggerTab.report.toLowerCase() &&
        (item.channelId === channelTab.id ||
          (channelTab.childChannel?.length &&
            item.channelId === subChannelTab.id)) &&
        item.tabId === subModuleTab.id
      ) {
        const element = cp.find(
          (elm) => elm.kpiDetails._id === item.kpiDetails._id
        );
        console.log(item);
        item.customName = element?.customName!;
      }
    });

    //console.log(isActivePageType,currentLoggerPage,sourceTab,channelTab,subModuleTab,"TABS")
    // if (channelTab === "Call") {
    //   tempSchema[isActivePageType.toLocaleLowerCase()]["report"][sourceTab][
    //     channelTab
    //   ][!subModuleTab ? "Campaign" : subModuleTab]["reportColumns"] = cp;
    // } else {
    //   tempSchema[isActivePageType.toLocaleLowerCase()]["report"][sourceTab][
    //     channelTab
    //   ][whatsappChannelTab][!subModuleTab ? "Campaign" : subModuleTab][
    //     "reportColumns"
    //   ] = cp;
    // }
    //console.log(tempSchema,"MAKING--- TEMPSCHEMA")
    dispatch(setShowEditColumnName(false));
    dispatch(setShowColumnCustomizePop(false));
    dispatch(
      updateSchemaRequest({
        id: tempSchema._id, // review
        teamId: tempSchema.teamId,
        type: "user",
        reportColumns: tempSchema.reportColumns,
      })
    );
    //update th schema on ui before backend
    dispatch(directlyUpdateSchema(tempSchema));
    /* uncomment */
  };
  const handleCancel = () => {
    dispatch(setShowEditColumnName(false));
    dispatch(setShowColumnCustomizePop(true));
  };
  const handleConfirm = () => {
    /* uncomment ------------------- */
    const tempSchema: schemaInterface = structuredClone(schema);
    tempSchema.reportColumns.forEach((item) => {
      if (
        item.screen === screenType.logger.name.toLowerCase() &&
        item?.subScreen === loggerTab.report.toLowerCase() &&
        (item.channelId === channelTab.id ||
          (channelTab.childChannel?.length &&
            item.channelId === subChannelTab.id)) &&
        item.tabId === subModuleTab.id
      ) {
        const element = editCopy.find(
          (elm) => elm.kpiDetails._id === item.kpiDetails._id
        );
        console.log(item);
        item.customName = element?.customName!;
      }
    });
    //console.log(tempSchema,"MAKING--- TEMPSCHEMA")
    dispatch(setShowEditColumnName(false));
    dispatch(setShowColumnCustomizePop(false));
    dispatch(
      updateSchemaRequest({
        id: tempSchema._id,
        teamId: tempSchema.teamId,
        type: "user",
        reportColumns: tempSchema.reportColumns,
      })
    );
    dispatch(directlyUpdateSchema(tempSchema));
    /* uncomment */
    toast.success("Please Wait ...");
  };
  const handleKey = (
    e: React.KeyboardEvent<HTMLInputElement>,
    each: columnInterface
  ) => {
    if (e.key === "Enter") {
      let editCopyTemp = editCopy;
      editCopyTemp = editCopyTemp.map((eachx) => {
        if (eachx.kpiDetails._id === each.kpiDetails._id)
          return { ...each, currentName: editedText };
        else return eachx;
      });
      setEditCopy(editCopyTemp);
      setSelectedItem({ _id: -1 });
    }
  };
  return createPortal(
    <div className="fized-wrapper_fixed">
      <div className="popup-box-wrapper">
        <div className="popup-inner-section">
          <p className="p">Set Custom Name</p>

          <div className="searchsection">
            {/* <img src={searchIcon} alt="" /> */}
            <PravidIcons activeIcon={"searchIcon"} />
            <input
              onChange={getSearchData}
              className="searcharea"
              type="text"
              placeholder="Search"
              onKeyDown={validateKeys}
            />
          </div>
          <div className="search-and-items">
            {options.length > 0 &&
              options.map((each, i: number) => {
                if (each.kpiDetails.originalName === "Sr. No") return null;
                return each.kpiDetails._id !== selectedItem._id ? (
                  <div
                    className="each-option"
                    onClick={() =>
                      !(each.kpiDetails.originalName === "Sr. No") &&
                      handleClick(each)
                    }
                    key={i}
                  >
                    <p
                      className={
                        each.customName !== each.kpiDetails.originalName
                          ? "colorblue"
                          : ""
                      }
                    >
                      {each.customName}
                    </p>
                  </div>
                ) : (
                  <div
                    className="each-option"
                    // onChange={() => handleClick(each)}
                    key={i}
                  >
                    <input
                      className="inputtextsarea"
                      onChange={(e) => handleChangeInInput(e, each)}
                      onMouseLeave={(e) => {
                        handleMouseLeave(e, each);
                      }}
                      onKeyDown={(e) => {
                        validateKeys(e);
                        handleKey(e, each);
                      }}
                      value={editedText}
                    ></input>
                  </div>
                );
              })}
          </div>
          <div className="dividehr"></div>
          <div
            className="tagssection"
            onMouseOver={(e) => {
              setShowBlue(true);
            }}
            onMouseLeave={() => {
              setShowBlue(false);
            }}
          >
            {showBlue ? (
              <PravidIcons activeIcon={"blueTags"} onClick={handleTags} />
            ) : (
              <PravidIcons activeIcon={"tags"} onClick={handleTags} />
            )}
            <p
              className={"tagssectiontext " + (showBlue ? "  colorblue" : " ")}
            >
              Set to default name
            </p>
          </div>

          <div className="buttonsection">
            <button className="cancelbutton" onClick={handleCancel}>
              Cancel
            </button>

            <button className="Confirmbutton" onClick={handleConfirm}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("root-portal")!
  );
}
