import React, { useCallback, useEffect, useRef, useState } from "react";
import { RootState } from "../../../redux";
import {
  DatePickermodule,
  ref as DatePickermoduleRef,
} from "../../generic/datePicker";
import styles from "./PopupCalenderfilter.module.scss";
import { DateToLocalString } from "../../../utils/timeAndDate/time.util";
import { useSelector, useDispatch } from "react-redux";
import {
  setCalenderData,
  toggleShowRightSideBar,
} from "../../../redux/filters/actions";
import { transcriptPhoneApi } from "../../../redux/logger/transcript/actions/actions";
import {
  setShowRightSideBar,
  setSelectedFilterType,
} from "../../../redux/filters/actions";
import moment from "moment";
import { GetCompletedCampaignApi } from "../../../redux/completedCampaign/actions/completedCampaign.action";
import Loader from "../../generic/loader/Loader";
function PopupCalenderfilter(props: {
  setSelectedIcon?: any;
  /**
   * dates to be highlighted in green color which represents campaign completed status
   */
  highlightedDates?: Date[];
  isDueDateFilter?: boolean;
  handleApply?: (startDueDate?: any, endDueDate?: any) => void;
  customizeDateFilter?: boolean;
  handleCustomizeDate?: any;
}) {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [totalDays, setTotalDays] = useState<string>();

  const dispatch = useDispatch();
  const calenderData = useSelector(
    (state: RootState) => state.filterReducers.calenderData
  );

  const dueDateData = {
    startDueDate: useSelector(
      (state: RootState) =>
        state.filterReducers.selectedFilterOptions.startDueDate
    ),
    endDueDate: useSelector(
      (state: RootState) =>
        state.filterReducers.selectedFilterOptions.endDueDate
    ),
  };

  // completed campaign details
  const completedCampaign = useSelector(
    (state: RootState) => state.completedCampaignReducer
  );
  const defaultuser = useSelector((state: any) => {
    return state.loginReducer.userLoginInfo.accountDetails[0].name;
  });
  // const user = "Maia Testing";
  const currentUser = useSelector((state: RootState) => {
    return state.filterReducers.selectedFilterOptions?.clientName;
  });

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const ref = useRef<DatePickermoduleRef>();
  const setOnChange = useCallback(() => {
    if (ref.current) {
      setTotalDays(ref.current.getNumberOfDays());
      const updatedDates = ref.current.getUpdatedRange();
      setStartDate(() => {
        return updatedDates.startDate;
      });
      setEndDate(() => {
        return updatedDates.endDate;
      });
    }
  }, [ref.current]);

  useEffect(() => {
    if (
      props.isDueDateFilter &&
      dueDateData.startDueDate &&
      dueDateData.endDueDate
    ) {
      setStartDate(dueDateData.startDueDate);
      setEndDate(dueDateData.endDueDate);
    }
    setStartDate(calenderData.startDate);
    setEndDate(calenderData.endDate);
  }, []);

  function handleSubmit() {
    if (props.isDueDateFilter) props.handleApply?.(startDate, endDate);
    else {
      dispatch(setCalenderData({ startDate, endDate }));

      props.handleApply?.([], []);

      transcriptPhoneApi(
        { dategte: startDate, datetle: endDate, token },
        token
      );
      const formatedStartDate = moment(startDate).format("YYYY-MM-DD");

      const formatedEndDate = moment(endDate).format("YYYY-MM-DD");

      dispatch(
        setCalenderData({
          startDate,
          endDate,
          formatedStartDate,
          formatedEndDate,
        })
      );
    }

    dispatch(setShowRightSideBar(false));
    dispatch(setSelectedFilterType(""));
    props.setSelectedIcon("");
  }
  // call the api to get completed months for past three years
  // useEffect(() => {
  //   // console.log("calendar");
  //   dispatch(
  //     GetCompletedCampaignApi({
  //       clientName: currentUser ? currentUser[0].name : defaultuser,
  //     })
  //   );
  // }, [defaultuser, currentUser]);
  const [startAndEndDate, setStartAndEndDate] = useState({
    startDate: new Date(calenderData.startDate),
    endDate: new Date(calenderData.endDate),
  });
  useEffect(() => {
    setStartAndEndDate({
      startDate: new Date(calenderData.startDate),
      endDate: new Date(calenderData.endDate),
    });
  }, [calenderData]);

  const [startAndEndDueDate, setStartAndEndDueDate] = useState({
    startDate: dueDateData.startDueDate,
    endDate: dueDateData.endDueDate,
  });

  useEffect(() => {
    setStartAndEndDueDate({
      startDate: new Date(
        dueDateData.startDueDate
          ? dueDateData.startDueDate
          : startAndEndDate.startDate
      ),
      endDate: new Date(
        dueDateData.endDueDate
          ? dueDateData.endDueDate
          : startAndEndDate.endDate
      ),
    });
  }, [dueDateData.startDueDate, dueDateData.endDueDate]);

  /** for custom date range  logger Report start**/

  useEffect(() => {
    if (props.customizeDateFilter) {
      props.handleCustomizeDate(startDate, endDate);
    }
  }, [startDate, endDate]);
  /** for custom date range  logger Report end **/

  //add useSelector Here

  const selectedPlanDetails: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  const selectedPlanRange: any =
    Object.keys(selectedPlanDetails).length > 0
      ? {
          planStartDate: selectedPlanDetails?.calenderStartDate,
          planExpireDate: selectedPlanDetails?.calenderEndDate,
        }
      : { planStartDate: new Date(2015, 0), planExpireDate: new Date(2035, 0) };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.date}>
          <div className={styles.startDate}>{DateToLocalString(startDate)}</div>
          <div>-</div>
          <div className={styles.endDate}>{DateToLocalString(endDate)}</div>
        </div>
        <div className={styles.totalDays}>{totalDays} days selected</div>
        {/* {completedCampaign.isLoading ? (
          <Loader></Loader>
        ) : ( */}
        <DatePickermodule
          // onMonthYearChange={getCampaignCompletedMonth} //
          highlightedDates={completedCampaign.data} // dates to put green dots
          range={{
            startDate:
              props.isDueDateFilter &&
              startAndEndDueDate.startDate !== undefined
                ? new Date(startAndEndDueDate.startDate)
                : startAndEndDate.startDate,
            endDate:
              props.isDueDateFilter && startAndEndDueDate.endDate !== undefined
                ? new Date(startAndEndDueDate.endDate)
                : startAndEndDate.endDate,
          }}
          minDate={
            Object.keys(selectedPlanRange).length !== 0
              ? new Date(selectedPlanRange?.planStartDate)
              : undefined
          }
          maxDate={
            Object.keys(selectedPlanRange).length !== 0 &&
            new Date(selectedPlanRange?.planExpireDate) > new Date()
              ? new Date()
              : new Date(selectedPlanRange?.planExpireDate)
          }
          // range={{
          //   startDate: startAndEndDate.startDate,
          //   endDate: startAndEndDate.endDate,
          // }}
          ref={ref}
          onChange={setOnChange}
          isDueDateFilter={props.isDueDateFilter}
        ></DatePickermodule>
        {/* )} */}
      </div>
      {props.customizeDateFilter === true ? (
        <div style={{ height: "1px", backgroundColor: "#DDECFF" }}></div>
      ) : (
        <div className={styles.button}>
          <button onClick={handleSubmit}>
            {props.isDueDateFilter ? "Apply" : "View Result"}
          </button>
        </div>
      )}
    </>
  );
}

export default React.memo(PopupCalenderfilter);
