import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/rootStore";
import ChartModel from "../../components/moduleComponents/chartModel/ChartModel";
import { useCurrentPageTab } from "../../hooks";
import { getDataFromSchema } from "../../utils/getDataFromSchema/getDataFromSchema";
import {
  getAnalyticsAPIData,
  getAnalyticsApiCards,
} from "../../redux/analytics/actions";
import { setCalenderData } from "../../redux/filters/actions";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";
import styles from "./AnalyticsScreen.module.scss";
import { Mixpanel } from "../../utils/mixpanelSetup";
import { addClientNameAsKey } from "../../utils/objectModify";
import { setSelectedFilterOptions } from "../../redux/filters/actions";
import { setSubHeaderTabs } from "../../redux/dashboard/actions";
import { setLoggedInUserInfo } from "../../redux/onboarding/login/actions";
import { claimAndPurchasePlan } from "../../redux/signUpFlow/productTour/actions";
import { getCurrentTab } from "../../redux/profile/yourAccount/organizationDetails/actions";
import { sourceDetails } from "../../components/moduleComponents/onBoarding/login/loginField/sourceDetails";
import { HelpModal } from "../../components/moduleComponents/signUpFlow/helpModal/HelpModal";
import Icon from "../../components/generic/icon/Icon";
import Button from "../../components/generic/button/Button";
import Tour from "../../components/moduleComponents/signUpFlow/tour/Tour";
import { giftBox } from "../../theme/gif";
import { campaignPageIcon } from "../../theme/assets/svg";
import { useNavigate } from "react-router-dom";
import { ROLES, parentChannels } from "../../constants";
import { getUserData } from "../../redux/onboarding/login/actions";
import { AnalyticsMaiaPopText } from "./AnalyticsMaiaPopText";
import { setIsMaiaPopUp } from "../../redux/signUpFlow/signUp/action";
import { cardInterface, chartInterface } from "./analyticsScreen.types";
interface props {
  default: string;
}

export default function Analytics() {
  /*****************************  redux hooks  *****************************/
  const timeForFreeCredit = useSelector(
    (store: any) => store.signUpModuleReducer?.signUp?.timeForFreeCredit
  );
  const timeDifference =
    (new Date(timeForFreeCredit).getTime() - new Date().getTime()) / 1000;

  const userLoginInfo = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo
  );
  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );
  const role = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo?.userDetail?.role
  );
  const chartData = useSelector(
    (store: RootState) => store?.analyticsReducer?.analyticsChartDetails
  );
  const loading = useSelector(
    (store: RootState) => store?.analyticsReducer?.isLoadingAnalyticsChart
  );

  const loadingCard = useSelector(
    (store: RootState) => store?.analyticsReducer?.isLoadingAnalyticsCard
  );

  const filterSelectedDate = useSelector(
    (store: RootState) => store?.filterReducers?.calenderData
  );
  const selectedFilters = useSelector(
    (state: RootState) => state?.filterReducers?.selectedFilterOptions
  );
  const isDashboardToShow = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.demoSessionModules
  );
  const downloadAnalyticsState = useSelector(
    (state: RootState) => state.filterReducers.downloadViewAll
  );
  const refreshPage = useSelector((state: RootState) => {
    return state.filterReducers?.refreshPage;
  });
  const campaignNameId = useSelector(
    (state: RootState) => state.schedulerReducer?.analyticsIdName?.allInfo?.id
  );
  const campaignstartDate = useSelector(
    (state: RootState) => state.schedulerReducer?.analyticsIdName?.startDate
  );
  const campaignEndDate = useSelector(
    (state: RootState) => state.schedulerReducer?.analyticsIdName?.endDate
  );
  const parentCampaignName = useSelector(
    (state: RootState) =>
      state.schedulerReducer?.analyticsIdName?.allInfo?.parentCampaignName
  );
  const campaignName = useSelector(
    (state: RootState) =>
      state.schedulerReducer?.analyticsIdName?.allInfo?.campaignName
  );
  const isParent = useSelector(
    (state: RootState) =>
      state.schedulerReducer?.analyticsIdName?.allInfo?.isMaster
  );
  const campaignChannelName = useSelector(
    (state: RootState) =>
      state.schedulerReducer?.analyticsIdName?.allInfo?.channels
  );
  const campaignClientName = useSelector(
    (state: RootState) =>
      state.schedulerReducer?.analyticsIdName?.allInfo?.accountName
  );
  const showExpNowModal = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productTourReducer?.experiencePravid
  );
  const showClaimAndPurchaseModal = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productTourReducer?.claimAndPurchase
  );
  const currentUseCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseId
  );
  const currentUseCaseName = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseName
  );
  const loginUserId = useSelector(
    (store: any) => store.loginReducer.userLoginInfo?.userDetail?._id
  );
  const loginUserDetail = useSelector(
    (store: any) => store.loginReducer?.userData
  );
  const organizationInfo = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization.organizationDetails
  );
  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const accountType = useSelector((state: any) => {
    return state.loginReducer?.userLoginInfo?.accountDetails[0]?.type;
  });
  const currentSelectedTeamId = useSelector(
    (store: RootState) => store.loginReducer?.currentTeam?.id
  );

  const {
    schema,
    isActivePageType,
    subChannelTab,
    subModuleTab,
    channelTab,
    currentLoggerPage,
  } = useCurrentPageTab();
  const dispatch = useDispatch();

  const apiChangeArray = [
    "aht(sec)_card",
    "totaluniqueaccountsconnected_card",
    "totaluniqueaccountsnotconnected_card",
    "totalconnectedcalls_card",
    "totalnotconnectedcalls_card",
    "talktime(min)_card",
    "messagesent_card",
    "totaluniqueaccountsconnectedvsnotconnected_chart",
    "calls<10secvscalls>=10sec_chart",
    "calldisposition_chart",
  ];

  /*****************************  useStates  *****************************/
  const [cards, setCards] = useState<Array<cardInterface>>();
  const [charts, setCharts] = useState<Array<chartInterface>>();
  const [showTour, setTour] = useState(true);

  /*****************************  other hooks  *****************************/
  const analyticsRef = useRef<any>(null);
  const navigate = useNavigate();

  /*****************************  useEffects  *****************************/
  useEffect(() => {
    if (showClaimAndPurchaseModal) {
      dispatch(setIsMaiaPopUp(true));
    }
  }, [showClaimAndPurchaseModal]);

  // useEffect(() => {
  //   if (downloadAnalyticsState === "InActive") {
  //     //
  //   } else {
  //     takeScreenshot()
  //       .then(downloadAnalytics)
  //       .then(() => dispatch(setDownloadViewAll("InActive")));
  //   }
  // }, [downloadAnalyticsState]);

  // const sourceTab = useSelector(
  //   (store: RootState) => store.dashboardReducer.sourceTab
  // );
  // const subModuleTab = useSelector(
  //   (store: RootState) => store.dashboardReducer.subModuleTab
  // );
  // const channelTab = useSelector(
  //   (store: RootState) => store.dashboardReducer.channelTab
  // );
  // const whatsappChannelTab = useSelector(
  //   (store: RootState) => store.dashboardReducer.whatsappChannelTab
  // );

  // useEffect(() => {
  //   setIsActivePageType("Analytics");
  // }, []);
  // useEffect(
  //   () => {
  //     const data = {
  //       headers : { userId: loginUserId}
  //     }
  //     dispatch(getUserData(data))
  //   },[]
  // )

  useEffect(() => {
    if (campaignNameId && campaignstartDate && campaignEndDate) {
      dispatch(
        setCalenderData({
          startDate: new Date(campaignstartDate),
          endDate: new Date(campaignEndDate),
        })
      );
      // dispatch(
      //   setSelectedFilterOptions({
      //     type: "campaignName",
      //     options: [isParent ? campaignName : parentCampaignName],
      //   })
      // );
    }
  }, [campaignNameId]);

  useEffect(() => {
    if (campaignNameId && role == ROLES.campaignManager.name) {
      // const newUserDetailsObject = Object.assign({}, userDetails,

      userLoginInfo["accountDetails"][0]["name"] = campaignClientName;
      dispatch(setLoggedInUserInfo(userLoginInfo));
    }
  }, [campaignNameId, role]);

  useEffect(() => {
    // if (campaignChannelName && campaignChannelName?.length > 0) {
    //   campaignChannelName?.map((each: any) => {
    //     if (each.toLowerCase() === "whatsapp") {
    //       dispatch(setSubHeaderTabs("Whatsapp", "One Way", "Campaign"));
    //     } else {
    //       dispatch(setSubHeaderTabs(each, "", "Campaign"));
    //     }
    //   });
    // }
  }, [campaignChannelName]);

  useEffect(() => {
    analyticsRef && analyticsRef.current.scrollIntoView({ behavior: "smooth" });
  }, [refreshPage]);

  useEffect(() => {
    Mixpanel.track("Analytics | Landing Page| Total Time spent", {
      module: "Analytics",
    });
    if (
      channelTab?.name &&
      schema &&
      Object.keys(schema)?.length &&
      (subChannelTab?.name || !parentChannels.includes(channelTab?.name)) &&
      subModuleTab?.name &&
      isActivePageType
    ) {
      setCards(
        getDataFromSchema({
          channel: channelTab,
          dataType: "cards",
          schemaData: schema,
          subChannel: subChannelTab,
          tab: subModuleTab,
          screen: isActivePageType,
          subScreen: currentLoggerPage,
        }) as cardInterface[]
      );
      setCharts(
        getDataFromSchema({
          channel: channelTab,
          dataType: "charts",
          schemaData: schema,
          subChannel: subChannelTab,
          tab: subModuleTab,
          screen: isActivePageType,
          subScreen: currentLoggerPage,
        }) as chartInterface[]
      );
    }
  }, [
    subModuleTab?.name,
    channelTab?.name,
    subChannelTab?.name,
    schema,
    isActivePageType,
    currentLoggerPage,
  ]);

  //for getting cards individually
  useEffect(() => {
    if (channelTab?.name && (cards?.length || charts?.length)) {
      // if(accountName.includes("Saarthi Demo") && (subModuleTab==="Customer Behaviour" || subModuleTab==="Payment")){
      //   dispatch(
      //     getAnalyticsApiCards(
      //       {
      //         username: accountName,
      //         start_date: !filterSelectedDate?.startDate
      //           ? moment(new Date()).format("YYYY-MM-DD")
      //           : moment(filterSelectedDate?.startDate).format("YYYY-MM-DD"),

      //         end_date: !filterSelectedDate?.endDate
      //           ? moment(new Date()).format("YYYY-MM-DD")
      //           : moment(filterSelectedDate?.endDate).format("YYYY-MM-DD"),
      //         source: [sourceTab],
      //         channel: [channelTab],
      //         ...(whatsappChannelTab && {
      //           communicationType: [whatsappChannelTab],
      //         }),
      //         // ...(campaignNameId && (moment(campaignstartDate).format("YYYY-MM-DD") ===
      //         //   moment(filterSelectedDate?.startDate).format("YYYY-MM-DD")) &&
      //         //   (moment(campaignEndDate).format("YYYY-MM-DD") ===
      //         //   moment(filterSelectedDate?.endDate).format("YYYY-MM-DD")) &&  {
      //         //     campaignId: [campaignNameId],
      //         //   }),
      //         ...addClientNameAsKey(selectedFilters, "username"),
      //       },
      //       token
      //     )
      //   );
      // } else{
      cards?.map((e) => {
        const data = {
          username: !loginUserDetail?.productShowcase?.includes("Analytics")
            ? "Dummy Data"
            : accountName,
          start_date: !loginUserDetail?.productShowcase?.includes("Analytics")
            ? "2023-03-22"
            : !filterSelectedDate?.startDate
              ? moment(new Date()).format("YYYY-MM-DD")
              : moment(filterSelectedDate?.startDate).format("YYYY-MM-DD"),

          end_date: !loginUserDetail?.productShowcase?.includes("Analytics")
            ? "2023-03-22"
            : !filterSelectedDate?.endDate
              ? moment(new Date()).format("YYYY-MM-DD")
              : moment(filterSelectedDate?.endDate).format("YYYY-MM-DD"),
          useCaseId: [currentUseCaseId],
          source: [currentUseCaseName.split(" ")[0]],
          channel: [channelTab?.name],
          ...addClientNameAsKey(selectedFilters, "username"),
          uniqueId: e?.kpiDetails?.uniqueId,
          accountType: accountType,
          role: role,
          teamId: currentSelectedTeamId
        };
        if (subChannelTab?.name && parentChannels.includes(channelTab?.name)) {
          data["communicationType"] = [subChannelTab?.name];
        }
        const newApiCheck = apiChangeArray?.includes(
          e?.kpiDetails?.uniqueId?.toLocaleLowerCase()
        );
        dispatch(getAnalyticsApiCards(data, token, newApiCheck));
      });
      // }
    }
  }, [
    //subModuleTab,
    filterSelectedDate,
    selectedFilters,
    refreshPage,
    campaignNameId,
    charts?.length,
    cards?.length,
    // accountName
  ]);
  useEffect(() => {
    if (channelTab?.name) {
      // if(accountName.includes("Saarthi Demo") && (subModuleTab==="Customer Behaviour" || subModuleTab==="Payment")){
      //   dispatch(
      //     getAnalyticsAPIData(
      //       {
      //         username: accountName,
      //         start_date: !filterSelectedDate?.startDate
      //           ? moment(new Date()).format("YYYY-MM-DD")
      //           : moment(filterSelectedDate?.startDate).format("YYYY-MM-DD"),

      //         end_date: !filterSelectedDate?.endDate
      //           ? moment(new Date()).format("YYYY-MM-DD")
      //           : moment(filterSelectedDate?.endDate).format("YYYY-MM-DD"),
      //         source: [sourceTab],
      //         channel: [channelTab],
      //         ...(whatsappChannelTab && {
      //           communicationType: [whatsappChannelTab],
      //         }),
      //         // ...(campaignNameId && (moment(campaignstartDate).format("YYYY-MM-DD") ===
      //         //   moment(filterSelectedDate?.startDate).format("YYYY-MM-DD")) &&
      //         //   (moment(campaignEndDate).format("YYYY-MM-DD") ===
      //         //   moment(filterSelectedDate?.endDate).format("YYYY-MM-DD")) &&  {
      //         //     campaignId: [campaignNameId],
      //         //   }),
      //         ...addClientNameAsKey(selectedFilters, "username"),
      //       },
      //       token
      //     )
      //   );
      // } else {
      charts?.map((e) => {
        const data = {
          username: !loginUserDetail?.productShowcase?.includes("Analytics")
            ? "Dummy Data"
            : accountName,
          start_date: !loginUserDetail?.productShowcase?.includes("Analytics")
            ? "2023-03-22"
            : !filterSelectedDate?.startDate
              ? moment(new Date()).format("YYYY-MM-DD")
              : moment(filterSelectedDate?.startDate).format("YYYY-MM-DD"),

          end_date: !loginUserDetail?.productShowcase?.includes("Analytics")
            ? "2023-03-22"
            : !filterSelectedDate?.endDate
              ? moment(new Date()).format("YYYY-MM-DD")
              : moment(filterSelectedDate?.endDate).format("YYYY-MM-DD"),
          useCaseId: [currentUseCaseId],
          source: [currentUseCaseName.split(" ")[0]],
          channel: [channelTab?.name],
          ...addClientNameAsKey(selectedFilters, "username"),
          uniqueId: e?.kpiDetails?.uniqueId,
          accountType: accountType,
          role: role,
          teamId: currentSelectedTeamId
        };
        if (subChannelTab?.name && parentChannels.includes(channelTab?.name)) {
          data["communicationType"] = [subChannelTab?.name];
        }
        const newApiCheck = apiChangeArray?.includes(
          e?.kpiDetails?.uniqueId?.toLocaleLowerCase()
        );

        dispatch(getAnalyticsAPIData(data, token, newApiCheck));
      });
      // }
    }
  }, [
    filterSelectedDate,
    selectedFilters,
    refreshPage,
    campaignNameId,
    charts?.length,
    cards?.length,
  ]);

  /*****************************  handlers  *****************************/
  const handleCloseClaimPurchaseModal = (data: boolean) => {
    dispatch(claimAndPurchasePlan(data));
  };
  console.log("ghgh", cards, charts,)
  console.log("chartData", chartData)
  /*****************************  JSX  *****************************/
  return (
    <>
      <div className={styles.topDiv}>
        <div className={styles.childdiv}>
          <div className={styles.chartModel}>
            <div id="bro" className={styles.row} ref={analyticsRef}>
              {(charts?.length || cards?.length) && (
                <ChartModel
                  cards={cards!}
                  charts={charts!}
                  chartData={chartData}
                  isLoading={loading}
                  isLoadingCard={loadingCard}
                  channel={channelTab?.name}
                  useCaseId={currentUseCaseId}
                  whatsappChannel={
                    channelTab.childChannel ? subChannelTab?.name : ""
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showTour &&
        !showExpNowModal &&
        !loginUserDetail?.productShowcase?.includes("Analytics") ? (
        <Tour />
      ) : (
        ""
      )}
      {organizationInfo?.organizationName === undefined || organizationInfo?.gstNumber === undefined || timeDifference > 0
        ? showClaimAndPurchaseModal &&
        !loginUserDetail?.isInvited && (
          <HelpModal
            show={true}
            modalIcon={campaignPageIcon}
            isClose={true}
            close={() => {
              handleCloseClaimPurchaseModal(false);
              dispatch(setIsMaiaPopUp(false));
            }}
            extraContentClass={styles.extraContentAnalyticsStyling}
            extraClassModalBackDrop={styles.extraAnalyticsClassModalBackDrop}
            extraModalStyling={styles.extraAnalyticsModalStyling}
            extraContentCloseClass={styles.extraContentCloseClassAnalytics}
            modalContent={
              <>
                {<AnalyticsMaiaPopText baseTimer={timeForFreeCredit} />}
                <div className={styles.analyticsIconStyling}>
                  <Icon
                    img_src={giftBox}
                    extraClass={styles.giftGifStyling}
                  />
                </div>
                <div className={styles.analyticsContentBtnDiv}>
                  <Button
                    text="Purchase Plan"
                    extraClass={`${styles.teamExploreBtnStyling} ${styles.teamBtnStyling}`}
                    onClick={() => {
                      // dispatch(getCurrentTab("My Plan"));
                      navigate("/profile/billing");
                      //navigate("/purchasePage");
                      handleCloseClaimPurchaseModal(false);
                      dispatch(setIsMaiaPopUp(false));
                    }}
                    id="auto_team_explore_btn"
                  />
                  <Button
                    text="Claim Free Credits"
                    extraClass={`${styles.teamSetUpBtnStyling} ${styles.teamBtnStyling}`}
                    onClick={() => {
                      dispatch(getCurrentTab("Organization"));
                      navigate("/profile/your-account");
                      handleCloseClaimPurchaseModal(false);
                      dispatch(setIsMaiaPopUp(false));
                    }}
                    id="auto_team_setup_btn"
                  />
                </div>
              </>
            }
          />
        )
        : ""}
    </>
  );
}
