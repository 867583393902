// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G4eJsjPnMYPDdNMfkg_S{background-color:#fff;width:100%;height:100%;padding:4%;border-radius:8px;box-shadow:0 2px 4px rgba(0,0,0,.2)}.G4eJsjPnMYPDdNMfkg_S .q4fhMD_KMZYf0fpyp57u{color:#0074ff;font-size:14px;font-weight:400}.G4eJsjPnMYPDdNMfkg_S .PPuvyWH7TOb6SklxvS09{width:100% !important;margin-top:2%;color:#6e7079;font-size:12px;font-weight:400}.G4eJsjPnMYPDdNMfkg_S .PPuvyWH7TOb6SklxvS09 .kxHJruvSLn5QDuhgsBXG{width:100%;display:flex}.G4eJsjPnMYPDdNMfkg_S .PPuvyWH7TOb6SklxvS09 .kxHJruvSLn5QDuhgsBXG .rfRRvtEHZKGHi7WpiWnO{width:70%}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/paymentModuleComponents/myPlan/planDetails/tooltipPopUp/ChargeRate.module.scss"],"names":[],"mappings":"AAAA,sBACI,qBAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,mCAAA,CAEA,4CACG,aAAA,CACA,cAAA,CACA,eAAA,CAGH,4CACE,qBAAA,CACA,aAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CAEF,kEACI,UAAA,CACA,YAAA,CACA,wFACI,SAAA","sourcesContent":[".chargeRateWrapper{\n    background-color: #ffffff;\n    width: 100%;\n    height : 100%;\n    padding: 4%;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n\n    .heading{\n       color: #0074FF;\n       font-size: 14px;\n       font-weight: 400;\n    }\n\n    .container{\n      width: 100% !important;\n      margin-top: 2%;\n      color:#6E7079;\n      font-size: 12px;\n      font-weight: 400;\n      \n    .containerRow{\n        width: 100%;\n        display: flex;\n        .spanone {\n            width: 70%;\n        }\n        // justify-content: space-between;\n    }\n      \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chargeRateWrapper": `G4eJsjPnMYPDdNMfkg_S`,
	"heading": `q4fhMD_KMZYf0fpyp57u`,
	"container": `PPuvyWH7TOb6SklxvS09`,
	"containerRow": `kxHJruvSLn5QDuhgsBXG`,
	"spanone": `rfRRvtEHZKGHi7WpiWnO`
};
export default ___CSS_LOADER_EXPORT___;
