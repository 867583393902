import React from "react";
import styles from "./DownloadSection.module.scss";
import DownloadComponent from "../../../moduleComponents/downloadComponet/DownloadComponent";
// import { blueCalender } from "../../../../theme/assets/svg/rightSideIcon";
// import { firstLetterCapital } from "../../../../utils/stringmodifycase";
// import { useSelector } from "react-redux";
// import { RootState } from "../../../../redux";
import { getDataFromSchema } from "../../../../utils";
import { useEffect, useState } from "react";
import useCurrentPageTab from "../../../../hooks/useCurrentPageTab/useCurrentPageTab";
import { getSideBarModules } from "../../../../utils/genericFunctions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
export default function DownloadSection(props: any) {
  const sidebarModuleData = useSelector(
    (store: RootState) => store.loginReducer.sidebarModules.data
  );
  const activeLoggerPage = useSelector(
    (store: RootState) => store.loggerReducer.loggerState.currentPage
  );
  // const whereweare = useSelector((state: RootState) => state.dashboardReducer);
  // const sourceTab = whereweare.sourceTab;
  // const channelTab = whereweare.channelTab;
  // const whatsappChannelTab = whereweare.whatsappChannelTab;
  // const subModuleTab = whereweare.subModuleTab;
  // const loggerstate = useSelector(
  //   (state: RootState) => state.loggerReducer.loggerState.currentPage
  // );
  // const pageType = useSelector(
  //   (state: RootState) => state.loginReducer.isActivePageType
  // );
  // const datafordownload = useSelector(
  //   (state: RootState) => state.loginReducer?.userLoginInfo?.schema
  // );

  const {
    schema,
    isActivePageType,
    subChannelTab,
    subModuleTab,
    channelTab,
    currentLoggerPage,
  } = useCurrentPageTab();
  //getting data dfor downlaod
  // const downloadsoption=(datafordownload["Logger"]['report']['Debt Collection']["Campaign"]["Call"]["sidebarModules"]).find((e:any)=>e.name==="Download").keys;
  // const downloadsoption = getDataFromSchema({
  //   schema: datafordownload,
  //   sourceTab,
  //   subModuleTab,
  //   isActivePageType: pageType,
  //   channelTab,
  //   whatappChannelTab: whatsappChannelTab,
  //   currentLoggerPage: loggerstate,
  // });
  // const downloadsoption2 =(datafordownload["Logger"]['report']['Debt Collection']["Payment"]["Call"])
  // console.log(JSON.stringify(downloadsoption) === JSON.stringify(downloadsoption2), "HAHHAHAHAHHAH");
  const [dataforoptions, setDataforoptions] = useState<any>([]);
  useEffect(() => {
    if (sidebarModuleData?.length && isActivePageType && activeLoggerPage) {
      setDataforoptions(
        getSideBarModules(
          sidebarModuleData,
          isActivePageType,
          activeLoggerPage
        )?.find((e: any) => e.name === "Download")?.keys
      );
    }
  }, [sidebarModuleData, isActivePageType, activeLoggerPage]);

  console.log("chnage", dataforoptions);

  return (
    <>
      <div className={styles.titleofilter}>
        <p className={styles.titletext}>
          {dataforoptions?.length ? dataforoptions[0]?.mainKey : "Download"}
        </p>
      </div>
      <div className={styles.divide}></div>
      {dataforoptions?.length > 0 && (
        <DownloadComponent
          setSelectedIcon={props?.setSelectedIcon}
          radioOptions={
            //   [
            //   {
            //     name: dataforoptions[0]?.subKey[0]?.keyName,
            //     about: dataforoptions[0]?.subKey[0]?.info,
            //     format: dataforoptions[0]?.subKey[0]?.format,
            //   },
            //   {
            //     name: dataforoptions[0]?.subKey[1]?.keyName,
            //     about: dataforoptions[0]?.subKey[1]?.info,
            //     format: dataforoptions[0]?.subKey[1]?.format,
            //   },
            // ]
            dataforoptions[0]?.subKey
          }
          //  downloadFormats={[dataforoptions[0]?.subKey[0]?.format,dataforoptions[0]?.subKey[1]?.format]}
        />
      )}
    </>
  );
}
