export default {
  GET_STRATEGIES_DATA_API_REQUEST: "GET_STRATEGIES_DATA_API_REQUEST",
  GET_STRATEGIES_DATA_API_SUCCESS: "GET_STRATEGIES_DATA_API_SUCCESS",
  GET_STRATEGIES_DATA_API_FAILURE: "GET_STRATEGIES_DATA_API_FAILURE",

  CREATE_STRATEGY_DATA_API_REQUEST: "CREATE_STRATEGY_DATA_API_REQUEST",
  CREATE_STRATEGY_DATA_API_SUCCESS: "CREATE_STRATEGY_DATA_API_SUCCESS",
  CREATE_STRATEGY_DATA_API_FAILURE: "CREATE_STRATEGY_DATA_API_FAILURE",

  UPDATE_STRATEGY_DATA_API_REQUEST: "UPDATE_STRATEGY_DATA_API_REQUEST",
  UPDATE_STRATEGY_DATA_API_SUCCESS: "UPDATE_STRATEGY_DATA_API_SUCCESS",
  UPDATE_STRATEGY_DATA_API_FAILURE: "UPDATE_STRATEGY_DATA_API_FAILURE",

  DELETE_STRATEGY_DATA_API_REQUEST: "DELETE_STRATEGY_DATA_API_REQUEST",
  DELETE_STRATEGY_DATA_API_SUCCESS: "DELETE_STRATEGY_DATA_API_SUCCESS",
  DELETE_STRATEGY_DATA_API_FAILURE: "DELETE_STRATEGY_DATA_API_FAILURE",

  GET_USER_PERSONA_DATA_API_REQUEST: "GET_USER_PERSONA_DATA_API_REQUEST",
  GET_USER_PERSONA_DATA_API_SUCCESS: "GET_USER_PERSONA_DATA_API_SUCCESS",
  GET_USER_PERSONA_DATA_API_FAILURE: "GET_USER_PERSONA_DATA_API_FAILURE",

  GET_WORK_FLOWS_DATA_API_REQUEST: "GET_WORK_FLOWS_DATA_API_REQUEST",
  GET_WORK_FLOWS_DATA_API_SUCCESS: "GET_WORK_FLOWS_DATA_API_SUCCESS",
  GET_WORK_FLOWS_DATA_API_FAILURE: "GET_WORK_FLOWS_DATA_API_FAILURE",

  ADD_WORK_FLOW_DATA_API_REQUEST: "ADD_WORK_FLOW_DATA_API_REQUEST",
  ADD_WORK_FLOW_DATA_API_SUCCESS: "ADD_WORK_FLOW_DATA_API_SUCCESS",
  ADD_WORK_FLOW_DATA_API_FAILURE: "ADD_WORK_FLOW_DATA_API_FAILURE",

  SET_WORK_FLOW_DATA_API_REQUEST: "SET_WORK_FLOW_DATA_API_REQUEST",
  SET_WORK_FLOW_DATA_API_SUCCESS: "SET_WORK_FLOW_DATA_API_SUCCESS",
  SET_WORK_FLOW_DATA_API_FAILURE: "SET_WORK_FLOW_DATA_API_FAILURE",

  ADD_USER_PERSONA_DATA_API_REQUEST: "ADD_USER_PERSONA_DATA_API_REQUEST",
  ADD_USER_PERSONA_DATA_API_SUCCESS: "ADD_USER_PERSONA_DATA_API_SUCCESS",
  ADD_USER_PERSONA_DATA_API_FAILURE: "ADD_USER_PERSONA_DATA_API_FAILURE",

  SELECT_USER_PERSONA: "SELECT_USER_PERSONA",
  STORE_CREATED_STRATEGY: "STORE_CREATED_STRATEGY",
  // STORE_CREATED_WORKFLOWS: "STORE_CREATED_WORKFLOWS",
  // SELECTED_USER_PERSONA_FROM_CSV: "SELECTED_USER_PERSONA_FROM_CSV",
  // GET_UPLOADED_STRATEGY: "GET_UPLOADED_STRATEGY",
  // GET_UPLOADED_STRATEGYSUCCESS: "GET_UPLOADED_STRATEGYSUCCESS",

  // UPLOADFILE_API_REQUEST: "UPLOADFILE_API_REQUEST",
  // UPLOADFILE_API_SUCCESS: "UPLOADFILE_API_SUCCESS",
  // UPLOADFILE_API_FAILURE: "UPLOADFILE_API_FAILURE",

  GET_STRATEGY_OPTIONS_API_REQUEST: "GET_STRATEGY_OPTIONS_API_REQUEST",
  GET_STRATEGY_OPTIONS_API_SUCCESS: "GET_STRATEGY_OPTIONS_API_SUCCESS",
  GET_STRATEGY_OPTIONS_API_FAILURE: "GET_STRATEGY_OPTIONS_API_FAILURE",

  GET_USER_PERSONA_OPTIONS_API_REQUEST: "GET_USER_PERSONA_OPTIONS_API_REQUEST",
  GET_USER_PERSONA_OPTIONS_API_SUCCESS: "GET_USER_PERSONA_OPTIONS_API_SUCCESS",
  GET_USER_PERSONA_OPTIONS_API_FAILURE: "GET_USER_PERSONA_OPTIONS_API_FAILURE",

  

  CLEAR_STRATEGY_DATA: "CLEAR_STRATEGY_DATA",
};
