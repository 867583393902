/**
 * modifyNestedObject is used when you want to modify in nested object.
 * you require one key string with "." separated.
 * @example
 * const modifiedItem = {
 *   customerInfo: {
 *    name: "ram",
 *    age: 23,
 *    gender: "M",
 *    loan: {},
 *   },
 * };
 * const key = "customerInfo.loan.bankName.name";
 * const value = "SBI";
 * modifyNestedObject(modifiedItem, key, value);
 * @param obj : reference of object to be changed
 * @param key : dot separated keys
 * @param value
 */

export const modifyNestedObject = (
  obj: Record<string, any>,
  key: string,
  value: string
) => {
  const keyArr: string[] = key.split(".");
  const lastKey: string = keyArr.pop()!;

  keyArr.forEach((key) => {
    if (!obj[key]) {
      obj[key] = {};
    }
    obj = obj[key];
  });

  obj[lastKey] = value;
};
