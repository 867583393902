import React, { useEffect, useState } from "react";
import DiallerSection from "../../campaign_V2.0/diallerSection/DiallerSection";
import StepProgress from "../../../generic/stepProgress/StepProgress";
import { useNavigate } from "react-router-dom";
import styles from "./SchedulerSetting.module.scss";
import NavigationChannel from "../../../moduleComponents/campaign_V2.0/navigation/NavigationChannel";
import { maxCompletedStep } from "../../../../redux/campaign_V2.0/progressBar/actions";
import {
  getCallingCondition,
  getDBCCondition,
} from "../../../../redux/breadcrum/actions/breadcrum.actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
//import DialTimeModal from "../../campaign/schedulerAllComponent/dialTimeModal/DialTimeModal";
//import DialTimeModal from "../../campaign_V2.0/dialTimeModal/DialTimeModal";
//import DiallerSectionn from "../diallerSection/DiallerSection";
import { RootState } from "../../../../redux";
// import { getSchedularAPIData } from "../../../../redux/campaign_V2.0/actions/campaignV2.actions";

export default function SchedulerSetting() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const maxStateCompleted = useSelector(
    (state: any) => state.campaignModuleReducer?.progressBar?.maxCompletedStep
  );

  const stepsTotal: string[] = useSelector(
    (store: RootState) =>
      store?.schedulerReducer?.accountInfoDetails?.campaignProgressBar
  );

  // const stepsTotal = [
  //   "Campaign Details",
  //   "Data Upload",
  //   "Strategy Selection",
  //   "Agent Configuration",
  //   "Scheduler Setting",
  // ];

  const token = useSelector(
    (store: any) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const accountId = useSelector(
    (store: any) =>
      store.loginReducer.userLoginInfo?.userDetail?.accountDetails?.[0]?.id
  );

  const useCaseId = useSelector(
    (store: any) =>
      store.loginReducer.userLoginInfo?.accountDetails?.[0]?.useCaseId
  );

  useEffect(() => {
    if (maxStateCompleted < stepsTotal.length)
      dispatch(maxCompletedStep(stepsTotal.length));
  }, [maxStateCompleted]);

  // useEffect(() => {
  //   if (schedularDetails.isScheduledSuccess == true) {
  //     navigate("/campaignmanager");
  //   }
  // }, [schedularDetails.isScheduledSuccess]);

  // useEffect(() => {
  //   dispatch(
  //     getSchedularAPIData({
  //       token: token,
  //       campaignId: campaignData?.id,
  //       strategyId: campaignData?.strategyId,
  //     })
  //   );
  // }, [campaignData?.id, campaignData?.strategyId, token]);

  useEffect(() => {
    getCallingCondition(token)(dispatch);
    getDBCCondition(accountId, useCaseId, token)(dispatch);
  }, [token, accountId, useCaseId]);

  return (
    <div>
      <div className={styles.stepProgressDiv}>
        <StepProgress
          color={"#07B464"}
          arr={[
            {
              name: "Campaign Details",
              fun: () => {
                navigate("/campaignmanager/campaigncreation");
              },
            },
            {
              name: "Data Upload",
              fun: () => {
                navigate("/campaignmanager/dataupload");
              },
            },
            {
              name: "Strategy Selection",
              fun: () => {
                navigate("/campaignmanager/strategyselection");
              },
            },
            {
              name: "Agent Configuration",
              fun: () => {
                navigate("/campaignmanager/allagents");
              },
            },
          ].filter((each) => stepsTotal.includes(each.name))}
          currentStepComplete={true}
          inProgressStepFromOut={stepsTotal.length}
          maxStateCompleted={stepsTotal.length}
        />
      </div>
      <hr className={styles.hrStyling} />
      {/* <DiallerSectionn /> */}
      <DiallerSection />
    </div>
  );
}
