import React, { useEffect, useRef, useState } from "react";
import styles from "./TrueCallerScreen.module.scss";
import {
  // trueCaller,
  closeIntegrationIcon,
  arrowDDIcon,
  arrowSendIcon,
} from "../../../../theme/assets/svg/integration_V2.0/index";
import Icon from "../../../generic/icon/Icon";
// import Dropdown from "react-multilevel-dropdown";
import { Dropdown, Space } from "antd";
import Modal from "../../../generic/modal/Modal";
import { UploadPicture } from "../../../generic/uploadPicture/UploadPicture";
import ToggleSwitch from "../../../generic/toggleSwitch/ToggleSwitch";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import InputContainer from "../../../generic/inputContainer/InputContainer";
import { InfoToolTip } from "../../../generic/infoToolTip/InfoToolTip";
import Button from "../../../generic/button/Button";
import { maxLengthValidations } from "../../../../utils/Validation";
import _ from "lodash";
import {
  createIntegration,
  clearIntegration,
  updateIntegration,
} from "../../../../redux/integration_V2.0/allIntegrations/actions/allIntegrations.actions";
import {
  updateTrueCallerProfilePic,
  deleteTrueCallerProfilePic,
  clearTrueCallerData,
  setTrueCallerData,
} from "../../../../redux/integration_V2.0/trueCaller/actions/trueCaller.actions";
import { Menu } from "antd";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";

interface TrueCallerProps {
  shown?: boolean;
  close?: any;
  integrationName?: string;
  integrationData?: any;
}

export const TrueCallerScreen = ({
  shown,
  close,
  integrationName,
  integrationData,
}: TrueCallerProps) => {
  const organizationDisplayName = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization.organizationDetails
        ?.organizationDisplayName
  );

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const selectedIntegration = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.selectedIntegrationData
  );

  const isCreateLoading = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.isCreateLoading
  );

  const isUpdateLoading = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.isUpdateLoading
  );

  const trueCallerData = useSelector(
    (store: RootState) => store?.integrationReducer?.trueCallerReducer
  );

  const createdData = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.createIntegrationData
  );

  const updatedData = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.updateIntegrationData
  );

  const userInputDataTC = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.trueCallerReducer?.trueCallerData
  );

  const categories = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.trueCallerReducer?.allCategoryData?.[0]
        ?.categories
  );

  const initialState = {
    name: organizationDisplayName,
    priority: false,
    category: "",
    subCategory: "",
  };

  const dispatch = useDispatch();

  const [trueCallerInfo, setTrueCallerInfo] = useState(initialState);
  const [isImageUrl, setImageUrl] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [isFocused, setIsFocused] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<any>(null);

  const [refreshBtn, setRefreshBtn] = useState(false);

  // const categories = [
  //   {
  //     name: "Category 1",
  //     subCategories: [{ name: "Sub Category 1" }, { name: "Sub Category 2" }],
  //   },
  //   {
  //     name: "Category 2",
  //     subCategories: [
  //       { name: "Sub Category 1" },
  //       { name: "Sub Category 2" },
  //       { name: "Sub Category 3" },
  //     ],
  //   },
  // ];

  const handleSubmenuItem = (category: string, subcategory: string) => {
    handleCategorySelect(category);
    handleSubCategorySelect(subcategory);

    // Update the category based on the clicked subcategory
  };

  const getSubMenu = (category: string, subcategories: string[]) => (
    <Menu>
      {subcategories?.map((subcategory, index) => (
        <Menu.Item
          key={index}
          onClick={() => handleSubmenuItem(category, subcategory)}
        >
          {subcategory}
        </Menu.Item>
      ))}
    </Menu>
  );

  const onClickCategory = (e: any) => {
    const title = e.domEvent.target.innerText; // Get the title of the clicked submenu
    handleCategorySelect(title);
    handleSubCategorySelect("");
  };

  const hiddenFileInput = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleClickUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChangeDelete = () => {
    setUploadError("");
    setRefreshBtn(true);
    // setImageUrl("");

    if (
      selectedIntegration?.integrationDetails &&
      Object.keys(selectedIntegration?.integrationDetails)?.length > 0
    ) {
      setImageUrl("");
      setUploadError("Please Upload the Profile Picture");
    } else {
      dispatch(
        deleteTrueCallerProfilePic({
          id: accountId,
          integrationName: selectedIntegration?.integrationName,
        })
      );
    }

    dispatch(
      setTrueCallerData({
        displayName: trueCallerInfo?.name,
        priorityListing: trueCallerInfo?.priority,
        category: trueCallerInfo?.category,
        subCategory: trueCallerInfo?.subCategory,
      })
    );
  };

  const handleFileChangeUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRefreshBtn(true);
    if (!e.target.files || e.target.files?.length === 0) {
      setUploadError("Please Select a Valid File");
      return;
    } else {
      const selectedFile: any = e.target.files[0];
      const reader = new FileReader();
      if (!selectedFile) {
        return;
      }
      // Check file type
      if (selectedFile.type !== "image/png") {
        setUploadError("Please upload a PNG image file.");
        setImageUrl("");
        return;
      }
      // setImageUrl(URL.createObjectURL(e.target.files[0]));
      reader.readAsDataURL(selectedFile);
      reader.onload = (event: any) => {
        const img: any = new Image();
        img.src = event.target.result;
        img.onload = () => {
          if (
            selectedFile.size > 5000000 ||
            img.width !== img.height ||
            img.height > 200 ||
            img.width > 200
          ) {
            setUploadError(
              "PNG Image should be of max file size 5 MB and 200x200 pixels"
            );
            setImageUrl("");
          } else {
            setUploadError("");
            // setImageUrl(event.target.result);
            dispatch(
              updateTrueCallerProfilePic({
                file: (e.target.files as FileList)[0],
                id: accountId,
                integrationName: selectedIntegration?.integrationName,
              })
            );
            dispatch(
              setTrueCallerData({
                displayName: trueCallerInfo?.name,
                priorityListing: trueCallerInfo?.priority,
                category: trueCallerInfo?.category,
                subCategory: trueCallerInfo?.subCategory,
              })
            );
          }
          e.target.value = "";
        };
      };
    }
  };

  const handlePrioritySwitch = (checked: boolean) => {
    setTrueCallerInfo((prevTrueCallerInfo) => ({
      ...prevTrueCallerInfo,
      priority: checked,
    }));
    setRefreshBtn(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "displayName") {
      const value = e.target.value;

      setTrueCallerInfo((prevTrueCallerInfo) => ({
        ...prevTrueCallerInfo,
        name: value.replace(/[^a-zA-Z0-9.\- ]/g, ""),
      }));
    }
    setRefreshBtn(true);
  };

  const handleCategorySelect = (data: string) => {
    setTrueCallerInfo((prevTrueCallerInfo) => ({
      ...prevTrueCallerInfo,
      category: data,
    }));
    setRefreshBtn(true);
  };

  const handleSubCategorySelect = (data: string) => {
    setTrueCallerInfo((prevTrueCallerInfo) => ({
      ...prevTrueCallerInfo,
      subCategory: data,
    }));
    setIsOpen(false);
    dropdownRef.current?.toggle(false);
    setRefreshBtn(true);
  };

  const handleValidation = () => {
    if (_.isEqual(initialState, trueCallerInfo)) {
      return false;
    }
    if (isImageUrl === "") {
      return false;
    }
    if (trueCallerData?.isLoadingUploadProfilePic === true) {
      return false;
    }
    if (trueCallerData?.isLoadingDeleteProfilePic === true) {
      return false;
    }
    if (
      trueCallerInfo?.category?.length === 0 ||
      trueCallerInfo?.subCategory?.length === 0
    ) {
      return false;
    }
    if (trueCallerInfo?.name?.length === 0) {
      return false;
    }
    if (Object.keys(selectedIntegration?.integrationDetails)?.length > 1) {
      const input = {
        profilePicPath: selectedIntegration?.integrationDetails?.profilePicPath,
        category: trueCallerInfo?.category,
        subCategory: trueCallerInfo?.subCategory,
        priorityListing: trueCallerInfo?.priority,
        displayName: trueCallerInfo?.name,
      };
      const res = {
        profilePicPath: selectedIntegration?.integrationDetails?.profilePicPath,
        category: selectedIntegration?.integrationDetails?.category,
        subCategory: selectedIntegration?.integrationDetails?.subCategory,
        priorityListing:
          selectedIntegration?.integrationDetails?.priorityListing,
        displayName: selectedIntegration?.integrationDetails?.displayName,
      };

      if (_.isEqual(input, res)) {
        return false;
      }

      return true;
    }
    return true;
  };

  const handleDataChange = () => {
    setRefreshBtn(false);
    const bodyToSend = {
      integrationDetails: {
        priorityListing: trueCallerInfo?.priority,
        displayName: trueCallerInfo?.name?.slice(0, 150),
        category: trueCallerInfo?.category,
        subCategory: trueCallerInfo?.subCategory,
      },
    };
    if (Object.keys(selectedIntegration?.integrationDetails)?.length > 1) {
      dispatch(
        updateIntegration({
          integrationName: selectedIntegration?.integrationName,
          accountId: accountId,
          ...bodyToSend,
        })
      );
    } else {
      dispatch(
        createIntegration({
          integrationName: selectedIntegration?.integrationName,
          type: "account",
          accountId: accountId,
          ...bodyToSend,
        })
      );
    }
  };

  const handleCloseScreen = () => {
    setTrueCallerInfo(initialState);
    setImageUrl("");
    setUploadError("");
    setIsFocused("");
    setIsOpen(false);
    setRefreshBtn(false);
    dispatch(clearTrueCallerData());
    dispatch(clearIntegration());
    close();
  };

  useEffect(() => {
    setIsValid(handleValidation());
  }, [trueCallerInfo, isImageUrl, trueCallerData]);

  useEffect(() => {
    if (
      selectedIntegration?.integrationDetails &&
      Object.keys(selectedIntegration?.integrationDetails)?.length > 0
    ) {
      if (selectedIntegration?.integrationDetails?.profilePicPath?.length > 0) {
        setImageUrl(
          `${selectedIntegration?.integrationDetails?.profilePicPath}`
        );
      } else if (
        selectedIntegration?.integrationDetails?.profilePicPath?.length === 0
      ) {
        setImageUrl(selectedIntegration?.integrationDetails?.profilePicPath);
        setUploadError("Please Upload the Profile Picture");
      }
    } else {
      setImageUrl("");
    }
    // dispatch(getAllCategories());
  }, [selectedIntegration]);

  useEffect(() => {
    if (userInputDataTC && Object.keys(userInputDataTC)?.length > 0) {
      setTrueCallerInfo((prev) => {
        return {
          ...prev,
          name: userInputDataTC?.displayName
            ? userInputDataTC?.displayName
            : trueCallerInfo?.name,
          priority: userInputDataTC?.priorityListing
            ? userInputDataTC?.priorityListing
            : trueCallerInfo?.priority,
          category: userInputDataTC?.category
            ? userInputDataTC?.category
            : trueCallerInfo?.category,
          subCategory: userInputDataTC?.subCategory
            ? userInputDataTC?.subCategory
            : trueCallerInfo?.subCategory,
        };
      });
    } else {
      setTrueCallerInfo((prev) => {
        return {
          ...prev,
          name: selectedIntegration?.integrationDetails?.displayName
            ? selectedIntegration?.integrationDetails?.displayName
            : trueCallerInfo?.name,
          priority: selectedIntegration?.integrationDetails?.priorityListing
            ? selectedIntegration?.integrationDetails?.priorityListing
            : trueCallerInfo?.priority,
          category: selectedIntegration?.integrationDetails?.category
            ? selectedIntegration?.integrationDetails?.category
            : trueCallerInfo?.category,
          subCategory: selectedIntegration?.integrationDetails?.subCategory
            ? selectedIntegration?.integrationDetails?.subCategory
            : trueCallerInfo?.subCategory,
        };
      });
    }
  }, [selectedIntegration, userInputDataTC]);

  return (
    <Modal
      shown={shown}
      //shown={true}
      close={handleCloseScreen}
      extraClassStyleModalBackDrop={styles.integrationModalStyling}
      extraClassStyleModalContent={styles.integrationModalContentStyling}
    >
      <div className={styles.trueCallerWrapper}>
        <div className={styles.trueCallerHeaderDiv}>
          <div className={styles.trueCallerLeftHeaderDiv}>
            {/* <Icon img_src={trueCaller} extraClass={styles.headerIconStyling} /> */}
            <PravidIcons
              activeIcon={integrationData?.icon}
              extraClass={styles.headerIconStyling}
            />
            <div className={styles.headerTextStyling}>Truecaller</div>
          </div>
          <div className={styles.trueCallerRightHeaderDiv}>
            <div className={styles.closeIconStyling}>
              <Icon
                img_src={closeIntegrationIcon}
                extraClass={styles.iconCloseStyling}
                onClick={handleCloseScreen}
              />
            </div>
          </div>
        </div>
        <div className={styles.subHeaderDiv}>
          Truecaller recommends verifying your caller identity to increase your
          connection rates.
        </div>
        <div className={styles.contentTopDiv}>
          <div>
            <UploadPicture
              channel={"trueCaller"}
              handleDeleteFunc={handleFileChangeDelete}
              handleUploadFunc={handleClickUpload}
              handleFileUploadFunc={handleFileChangeUpload}
              hiddenFileInputRef={hiddenFileInput}
              picture={isImageUrl}
              errorMsg={uploadError}
              infoMsg={
                "A PNG image of 200 X 200 px (Square Logo) is acceptable."
              }
              isPicLoading={trueCallerData?.isLoadingUploadProfilePic}
              isDeleteValid={
                // (selectedIntegration?.integrationDetails &&
                //   Object.keys(selectedIntegration?.integrationDetails)?.length >
                //     1) ||
                isImageUrl?.length === 0 ||
                trueCallerData?.isLoadingUploadProfilePic ||
                trueCallerData?.isLoadingDeleteProfilePic
              }
              isUploadValid={trueCallerData?.isLoadingUploadProfilePic}
            />
          </div>
          <div className={styles.priorityStyling}>
            <div> Priority listing</div>
            <div className={styles.textStyling}>
              <InfoToolTip
                message={
                  "By default you will get a verified business listing with green banner. Select this option if you want a priority listing with purple banner."
                }
                isError={false}
                position="top-end"
                toolTipLarge
                isIconSmall={true}
              />
            </div>
            <ToggleSwitch
              id={`auto_priority_value`}
              checked={trueCallerInfo?.priority}
              small={true}
              onChange={handlePrioritySwitch}
            />
          </div>
        </div>
        <div className={styles.contentBtmDiv}>
          <div className={styles.subDiv}>
            <InputContainer
              label="Display Name"
              inputType="text"
              inputPlaceholder="Display Name"
              inputValue={trueCallerInfo?.name?.slice(0, 150)}
              inputName="displayName"
              inputOnChange={handleInputChange}
              showLabel={true}
              showNewToolTip
              showNewToolTipPosition={"top-end"}
              showNewToolTipMsg={
                "Your brand name which will be displayed on truecaller banner when your customer receives a call."
              }
              toolTipSize={true}
              extraInputClass={`${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${styles.inputMainDivStyling}`}
              errorMsg={
                trueCallerInfo?.name?.length > 0 && isFocused === "displayName"
                  ? !maxLengthValidations(
                    trueCallerInfo?.name,
                    "Display Name",
                    150
                  ).isValid
                    ? maxLengthValidations(
                      trueCallerInfo?.name,
                      "Display Name",
                      150
                    ).errors.message
                    : ""
                  : ""
              }
              inputOnFocus={() => setIsFocused("displayName")}
              inputOnBlur={() => setIsFocused("")}
            />
          </div>
          <div className={styles.subDiv}>
            <div className={styles.ddLabelStyling}>Category</div>
            <div className={styles.trueCallerDDBtnStyling}>
              <Dropdown
                overlayClassName={styles.categoriesMenuStylingDiv}
                overlay={
                  <Menu>
                    {categories?.map((item: any, index: number) => (
                      <Menu.SubMenu
                        key={index}
                        title={item.categoryName}
                        popupClassName={styles.categoriesSubMenuStylingDiv}
                        onTitleClick={onClickCategory}
                      >
                        {item?.subcategories?.map(
                          (child: string, childIndex: number) => (
                            <Menu.Item
                              key={`${index}-${childIndex}`}
                              onClick={() =>
                                handleSubmenuItem(item.categoryName, child)
                              }
                            >
                              {child}
                            </Menu.Item>
                          )
                        )}
                      </Menu.SubMenu>
                    ))}
                  </Menu>
                }
                // trigger={["click"]}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space className={styles.categoryButtonDiv}>
                    <div className={styles.dropDownTitle}>
                      <div className={styles.dropDownTitleText}>
                        {trueCallerInfo?.category?.length === 0 ? (
                          <span className={styles.categoryPlaceholder}>
                            Category
                          </span>
                        ) : (
                          <InputContainer
                            inputType="text"
                            inputValue={
                              trueCallerInfo?.category +
                              ", " +
                              trueCallerInfo?.subCategory
                            }
                            extraInputClass={`${styles.inputDivStyling}`}
                            extraLabelClass={styles.inputLabelStyling}
                            extraLabelDivClass={styles.labelDivStyling}
                            extraUserInputClass={`${styles.inputMainDivStyling}`}
                            extraWrapperClass={styles.wrapperStyling}
                            noErrorMsg
                            readOnly={true}
                            showOverFlowToolTip={true}
                          />
                        )}
                      </div>
                      <Icon
                        img_src={arrowDDIcon}
                        extraClass={styles.buttonIconStyling}
                      />
                    </div>
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className={styles.contentButtonDiv}>
          {refreshBtn ||
          (updatedData &&
            Object.keys(updatedData)?.length === 0 &&
            createdData &&
            Object.keys(createdData)?.length === 0) ? (
            <Button
              text={
                isCreateLoading || isUpdateLoading
                  ? "Applying For Verification"
                  : "Apply For Verification"
              }
              extraClass={`${styles.buttonStyling} ${
                !isValid || isCreateLoading || isUpdateLoading
                  ? styles.inActiveBtnStyling
                  : styles.activeBtnStyling
              }`}
              onClick={handleDataChange}
            />
          ) : (
            <Button
              text="Request Sent"
              image_src_left={arrowSendIcon}
              extraClass={`${styles.buttonStyling} ${styles.sendBtnStyling}`}
              imgClass={`${styles.sendIconStyling}`}
              buttonTextClass={`${styles.movingText}`}
              onClick={handleCloseScreen}
            />
          )}
        </div>

        {/* {selectedIntegration?.integrationDetails &&
        Object.keys(selectedIntegration?.integrationDetails).length >= 1 &&
        isImageUrl === "" ? (
          <div className={styles.tcErrorMsgStyling}>
            Please Upload the Profile Picture
          </div>
        ) : (
          ""
        )} */}
      </div>
    </Modal>
  );
};
