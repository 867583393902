// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nu96zInQYTJ20y9tIj5c{height:80vh}.nu96zInQYTJ20y9tIj5c .pTAzQ89NgHCuToVK9_e0{font-family:"Roboto";font-style:normal;font-weight:400;font-size:20px;line-height:18px;color:#9b9b9b}`, "",{"version":3,"sources":["webpack://./src/router/Routes.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,4CACE,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA","sourcesContent":[".error {\n  height: 80vh;\n  .message {\n    font-family: \"Roboto\";\n    font-style: normal;\n    font-weight: 400;\n    font-size: 20px;\n    line-height: 18px;\n    color: #9b9b9b;\n  }\n  .img {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `nu96zInQYTJ20y9tIj5c`,
	"message": `pTAzQ89NgHCuToVK9_e0`
};
export default ___CSS_LOADER_EXPORT___;
