import React, { useEffect, useState } from "react";
import DonutPieChart from "../charts/DonutPieChart";
import styles from "./DonutPieChartComponent.module.scss";
import { noPhoneDataIcon } from "../../../../theme/assets/svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { Colors } from "../charts/colors";
import _ from "lodash";

// interface props {
//   default: string;
// }

export default function DonutPieChartComponent(props: any) {
  const [noData, setNoData] = useState<boolean>(true);
  const [customSchema, setCustomSchema] = useState<boolean>(false);
  const agentCallDistributionData = useSelector(
    (store: any) =>
      store.agentDesktopReducer.dashboardReducer?.dashboardChartDetails
    // ?.agentCallDistributionPerFlow_details
  );
  // console.log(agentCallDistributionData, "chartvalues");
  const donutDetails = props?.donutChartDetails?.slice(1);
  const [donutData, setDonutData] = useState([]);
  const flowtypeDetails = Object.assign([], agentCallDistributionData);
  // const donutDetails =
  const referenceKey = props.chartDetails?.refenceKeyForData;
  // console.log(dotDetails,"8888")
  useEffect(() => {
    if (props.data && props.data[referenceKey]) {
      props.data && props.data[referenceKey]
        ? Object.values(props.data[referenceKey]).every((item) => item === 0)
          ? setNoData(true)
          : setNoData(false)
        : null;
    }

    // console.log(referenceKey, "referenceKey");
    if (
      referenceKey === "agentChatDistributionPerFlow_details" ||
      referenceKey === "agentCallDistributionPerFlow_details"
    ) {
      setCustomSchema(true);
    } else {
      setCustomSchema(false);
    }
    // const objectData = props.data[referenceKey];
    // const keys = Object.keys(objectData);
    // const eleminatedKey = props?.donutChartDetails[0].referenceKeyName;
    // const filteredData = keys?.filter((data) => data !== eleminatedKey);
    // const newObj = filteredData.map((val) => {
    //   return { name: val.toUpperCase(), referenceKey: val };
    // });
    // console.log("=====", newObj);
  }, [props.data, props.donutChartDetails]);

  useEffect(() => {
    if (customSchema) {
      const objectData = props.data[referenceKey];
      const keys = objectData && Object.keys(objectData);
      const eleminatedKey = props?.donutChartDetails[0].referenceKeyName;
      const filteredData = keys?.filter((data: any) => data !== eleminatedKey);
      const newObj = filteredData?.map((val: any, index: any) => {
        return {
          name: val.toUpperCase(),
          referenceKeyName: val,
          fillColor: Colors[index],
        };
      });
      setDonutData((prev) => (_.isEqual(prev, newObj) ? prev : newObj));
    } else {
      const donutDetails = props?.donutChartDetails?.slice(1);
      setDonutData((prev) =>
        _.isEqual(prev, donutDetails) ? prev : donutDetails
      );
    }
  }, [customSchema, donutDetails]);

  return (
    <>
      {!noData ? (
        <div className={styles.donutComponentWrapper}>
          <DonutPieChart
            chartDetails={props.chartDetails}
            donutChartDetails={donutData}
            data={props.data[referenceKey]}
            totalCallCount={
              props.data[referenceKey] &&
              props.data[referenceKey][
                props.donutChartDetails[0]?.referenceKeyName
              ]
            }
          />
          <div className={styles.donutChildComponent}>
            <div className={styles.leftsideDiv}>
              <p>{props?.donutChartDetails[0]?.name}:</p>
              <span
                className={styles.totalValueDiv}
                style={{ color: `${props.donutChartDetails[0]?.fillColor}` }}
              >
                {props.data && props.data[referenceKey]
                  ? props.data[referenceKey][
                      props.donutChartDetails[0]?.referenceKeyName
                    ]
                  : 0}
                {/* {100} */}
              </span>
            </div>
            <div className={styles.rightsideDiv}>
              {donutData &&
                donutData?.map((each: any, i: any) => {
                  return props.data &&
                    props.data[referenceKey] &&
                    props.data[referenceKey][each?.referenceKeyName] &&
                    props.data[referenceKey][each?.referenceKeyName] > 0 ? (
                    <div className={styles.dataColumnDiv} key={i}>
                      <span
                        className={styles.dot}
                        style={{
                          backgroundColor: `${Colors[i % Colors.length]}`,
                        }}
                      ></span>
                      <p className={styles.dataName}>{each.name}</p>
                      <span
                        style={{
                          color: `${Colors[i % Colors.length]}`,
                          fontWeight: "800",
                        }}
                      >
                        {props.data &&
                        props.data[referenceKey] &&
                        props.data[referenceKey][each?.referenceKeyName]
                          ? props.data[referenceKey][each?.referenceKeyName]
                          : ""}
                      </span>
                    </div>
                  ) : (
                    ""
                  );
                })}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.noDataDiv}>
          <img className={styles.noDataImgClass} src={noPhoneDataIcon} />
        </div>
      )}
    </>
  );
}
