import React from "react";

import styles from "./WeekSelector.module.scss";
const WEEK_DAYS = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
const WEEK_FULL_MAP = new Map([
  ["MON", "MONDAY"],
  ["TUE", "TUESDAY"],
  ["WED", "WEDNESDAY"],
  ["THU", "THURSDAY"],
  ["FRI", "FRIDAY"],
  ["SAT", "SATURDAY"],
  ["SUN", "SUNDAY"],
]);
export function WeekSelector(props: {
  currentlySelected: string;
  onChange: CallableFunction;
}) {
  return (
    <div className={styles.wrapper}>
      {WEEK_DAYS.map((e, index) => {
        return (
          <span
            key={index}
            data-selected={props.currentlySelected === WEEK_FULL_MAP.get(e)}
            onClick={() => {
              props.onChange(WEEK_FULL_MAP.get(e));
            }}
            className={styles.eachWeek}
            data-testid={e}
          >
            {e}
          </span>
        );
      })}
    </div>
  );
}
