import { Reducer } from "redux";
import agentActions from "../actionTypes";
import { AgentConfigInterface } from "../interface";

const initialState: AgentConfigInterface = {
  allAgents: {
    data: null,
    status: "idle",
    error: null,
  },
  agentsToShow: "",
  agentData: {
    data: null,
    status: "idle",
    error: null,
    isNavigate: false,
  },
  selectedAgent: {
    data: null,
    status: "idle",
    error: null,
    isNavigate: false,
  },
  agentToEdit: null,
  deletedAgent: {
    data: null,
    status: "idle",
    error: null,
  },
  campaignSummaryData: {
    data: null,
    status: "idle",
    error: null,
  },
  downloadCSVZipData: { data: null, status: "idle", error: null },
};

const agentConfigReducer: Reducer<AgentConfigInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case agentActions.GET_ALL_AGENTS_API_REQUEST: {
      return {
        ...state,
        allAgents: {
          data: null,
          status: "loading",
          error: null,
        },
      };
    }
    case agentActions.GET_ALL_AGENTS_API_SUCCESS: {
      return {
        ...state,
        allAgents: {
          data: action.payload,
          status: "success",
          error: null,
        },
      };
    }
    case agentActions.GET_ALL_AGENTS_API_FAILURE: {
      return {
        ...state,
        allAgents: {
          data: null,
          status: "failure",
          error: action.payload,
        },
      };
    }

    case agentActions.SET_AGENT_TYPES_TO_SHOW:
      return {
        ...state,
        agentsToShow: action.payload,
      };

    case agentActions.CREATE_AGENT_API_REQUEST:
    case agentActions.EDIT_AGENT_API_REQUEST: {
      return {
        ...state,
        agentData: {
          data: null,
          status: "loading",
          error: null,
          isNavigate: false,
        },
      };
    }
    case agentActions.CREATE_AGENT_API_SUCCESS:
    case agentActions.EDIT_AGENT_API_SUCCESS: {
      return {
        ...state,
        agentData: {
          data: action.payload,
          status: "success",
          error: null,
          isNavigate: true,
        },
      };
    }
    case agentActions.CREATE_AGENT_API_FAILURE:
    case agentActions.EDIT_AGENT_API_FAILURE: {
      return {
        ...state,
        agentData: {
          data: null,
          status: "failure",
          error: action.payload,
          isNavigate: true,
        },
      };
    }

    case agentActions.SET_AGENT_TO_EDIT: {
      return {
        ...state,
        agentToEdit: action.payload,
      };
    }

    case agentActions.SET_AGENT_DATA: {
      const { data, isNavigate, isAgentSelected, agentToEdit } = action.payload;
      return {
        ...state,
        agentData: {
          ...state.agentData,
          ...(data !== undefined ? { data } : {}),
          ...(isNavigate !== undefined ? { isNavigate } : {}),
        },
        agentToEdit:
          agentToEdit !== undefined ? agentToEdit : state.agentToEdit,
        selectedAgent: {
          ...state.selectedAgent,
          ...(isAgentSelected !== undefined
            ? { isNavigate: isAgentSelected }
            : {}),
        },
      };
    }

    case agentActions.DELETE_AGENT_API_REQUEST: {
      return {
        ...state,
        deletedAgent: {
          data: null,
          status: "loading",
          error: null,
        },
      };
    }
    case agentActions.DELETE_AGENT_API_SUCCESS: {
      return {
        ...state,
        deletedAgent: {
          data: action.payload,
          status: "success",
          error: null,
        },
      };
    }
    case agentActions.DELETE_AGENT_API_FAILURE: {
      return {
        ...state,
        deletedAgent: {
          data: null,
          status: "failure",
          error: action.payload,
        },
      };
    }

    case agentActions.SET_SELECTED_AGENT_API_REQUEST: {
      return {
        ...state,
        selectedAgent: {
          data: null,
          status: "loading",
          error: null,
          isNavigate: false,
        },
      };
    }
    case agentActions.SET_SELECTED_AGENT_API_SUCCESS: {
      return {
        ...state,
        selectedAgent: {
          data: action.payload,
          status: "success",
          error: null,
          isNavigate: true,
        },
      };
    }
    case agentActions.SET_SELECTED_AGENT_API_FAILURE: {
      return {
        ...state,
        selectedAgent: {
          data: null,
          status: "failure",
          error: action.payload,
          isNavigate: false,
        },
      };
    }
    case agentActions.GET_ALL_CAMPAIGN_SUMMARY_DATA_REQUEST: {
      return {
        ...state,
        campaignSummaryData: {
          data: null,
          status: "loading",
          error: null,
        },
      };
    }
    case agentActions.GET_ALL_CAMPAIGN_SUMMARY_DATA_SUCCESS: {
      return {
        ...state,
        campaignSummaryData: {
          data: action.payload,
          status: "success",
          error: null,
        },
      };
    }
    case agentActions.GET_ALL_CAMPAIGN_SUMMARY_DATA_FAILURE: {
      return {
        ...state,
        campaignSummaryData: {
          data: null,
          status: "failure",
          error: action.payload,
        },
      };
    }
    case agentActions.GET_DOWNLOAD_CSV_ZIP_API_REQUEST: {
      return {
        ...state,
        downloadCSVZipData: {
          data: null,
          status: "loading",
          error: null,
        },
      };
    }
    case agentActions.GET_DOWNLOAD_CSV_ZIP_API_SUCCESS: {
      return {
        ...state,
        downloadCSVZipData: {
          data: action.payload,
          status: "success",
          error: null,
        },
      };
    }
    case agentActions.GET_DOWNLOAD_CSV_ZIP_API_FAILURE: {
      return {
        ...state,
        downloadCSVZipData: {
          data: null,
          status: "failure",
          error: action.payload,
        },
      };
    }

    case agentActions.CLEAR_DOWNLOAD_ZIP: {
      return {
        ...state,
        downloadCSVZipData: { data: null, status: "idle", error: null },
      };
    }
    case agentActions.CLEAR_AGENT_DATA:
      return initialState;

    default:
      return state;
  }
};

export default agentConfigReducer;
