import React from "react";
import styles from "./PasswordConditions.module.scss";
import {
  checkInActiveIcon,
  checkActiveIcon,
} from "../../../theme/assets/profileSectionSvg";

interface passwordConditionProps {
  nameMatch?: boolean;
  charMinLength?: boolean;
  uppercase?: boolean;
  lowercase ?:boolean;
  numChar?: boolean;
  charMaxLength?: boolean;
  passwordMatch?: boolean;
}

interface confirmPasswordConditionProps {
  confirmPasswordMatch?: boolean;
}

interface newPasswordConditionProps {
  passwordMatch?: boolean;
}

export const PasswordConditions = ({
  nameMatch,
  charMinLength,
  uppercase,
  numChar,
  lowercase,
  charMaxLength,
}: passwordConditionProps) => {
  return (
    <div className={styles.passwordConditionsWrapper}>
      <div
        className={`${styles.conditionsTextStyling} ${
          nameMatch ? styles.valid : styles.inValid
        }`}
      >
        <img
          src={nameMatch ? checkActiveIcon : checkInActiveIcon}
          className={styles.conditionsIconStyling}
          data-testid="condition"
        />
        <p>Must not contain your name</p>
      </div>
      <div
        className={` ${styles.conditionsTextStyling}  ${
          charMinLength ? styles.valid : styles.inValid
        }`}
      >
        <img
          src={charMinLength ? checkActiveIcon : checkInActiveIcon}
          className={styles.conditionsIconStyling}
        />
        <p>Must be a minimum of 8 characters</p>
      </div>
      <div
        className={` ${styles.conditionsTextStyling}  ${
          uppercase ? styles.valid : styles.inValid
        }`}
      >
        <img
          src={uppercase ? checkActiveIcon : checkInActiveIcon}
          className={styles.conditionsIconStyling}
        />
        <p>Must contain at least one uppercase character</p>
      </div>
      <div
        className={` ${styles.conditionsTextStyling}  ${
        lowercase ? styles.valid : styles.inValid
        }`}
      >
        <img
          src={lowercase ? checkActiveIcon : checkInActiveIcon}
          className={styles.conditionsIconStyling}
        />
        <p>Must contain at least one lowercase character</p>
      </div>
      <div
        className={` ${styles.conditionsTextStyling}  ${
          numChar ? styles.valid : styles.inValid
        }`}
      >
        <img
          src={numChar ? checkActiveIcon : checkInActiveIcon}
          className={styles.conditionsIconStyling}
        />
        <p>Must contain at least one numeric and special character</p>
      </div>

      {/* <div
        className={`${styles.conditionsTextStyling}  ${
          charMaxLength ? styles.valid : styles.inValid
        }`}
      >
        <img
          src={charMaxLength ? checkActiveIcon : checkInActiveIcon}
          className={styles.conditionsIconStyling}
        />
        <p>Must not contain more than 25 characters</p>
      </div> */}

      {/* <div
        className={`${styles.conditionsTextStyling}  ${
          passwordMatch ? styles.valid : styles.inValid
        }`}
      >
        <img
          src={passwordMatch ? checkActiveIcon : checkInActiveIcon}
          className={styles.conditionsIconStyling}
        />
        <p>Must not match the current password</p>
      </div> */}
    </div>
  );
};

export const ConfirmPasswordConditions = ({
  confirmPasswordMatch,
}: confirmPasswordConditionProps) => {
  return !confirmPasswordMatch ? (
    <div className={`${styles.conditionsTextStyling} ${styles.errorMessage}`}>
      <p>Password is not matching </p>
    </div>
  ) : null;
};

export const NewPasswordCondition = ({
  passwordMatch,
}: newPasswordConditionProps) => {
  return passwordMatch ? (
    <div className={`${styles.conditionsTextStyling} ${styles.errorMessage}`}>
      <p>Current Password and New Password must be different</p>
    </div>
  ) : null;
};
