import React from "react";
import styles from "./FileViewDownload.module.scss"
import {downloadUnderline} from "../../../theme/assets/svg/campaign_v3"

interface props{
    fileName: string;
    date: string;
    onDownload: () => void;
}

const FileViewDownload = (props: props) => {
    return ( <div className={styles.fileViewDownload}>
        <div className={styles.fileInfo}>
            <div className={styles.fileName}>{props.fileName}</div>
            <div className={styles.date}>{props.date}</div>
        </div>
        <img src={downloadUnderline} alt="" className={styles.downloadBtn} onClick={props.onDownload}/>
    </div> );
}
 
export default FileViewDownload;