import React from "react";
import {
  TextArea,
  SingleDateCalender,
  SingleSelectDD,
  FieldsHeader,
  ShowText,
} from "../../../generic/fields";
import styles from "./FieldsController.module.scss";

interface props {
  type: string;
  data: Record<string, any>;
  handleOnChange: (value: string | Date | null, key?: string) => void;
  summaryData: Record<string, any>;
  icon?: any;
  handleEditClick?: () => void;
  disableInputFields?: boolean;
  summaryLoading?: boolean;
  maxLength?: number;
}

export default function FieldsController(props: props) {
  const objectKey = props.data.backEndKey || props.data.referenceKeyName || "";
  const value = props.summaryData?.[objectKey];

  switch (props.type) {
    case "text": {
      return (
        <ShowText
          fieldName={props.data.fieldName}
          value={value}
          separator={": "}
          noDataMessage={props.data.fieldName === "Name" ? "No Record" : ""}
          isLoading={props.summaryLoading}
        />
      );
    }
    case "textArea": {
      return (
        <TextArea
          maxLength={props?.maxLength}
          handleMessage={props.handleOnChange}
          defaultMessage={value}
          placeholder={props.data.fieldName}
          objectKey={objectKey}
          disableField={props.disableInputFields}
        />
      );
    }
    case "calendar": {
      return (
        <SingleDateCalender
          handleChange={props.handleOnChange}
          value={value}
          objectKey={objectKey}
          disableField={props.disableInputFields}
        />
      );
    }
    case "dropdown": {
      return (
        <SingleSelectDD
          data={props.data.options}
          handleChange={props.handleOnChange}
          selectedItem={value}
          title={props.data.fieldName}
          hasOtherOption={props.data.hasOtherOption}
          objectKey={objectKey}
          limitSelectedTextLength={25}
          disableField={props.disableInputFields}
        />
      );
    }
    case "fieldHeader": {
      return (
        <FieldsHeader
          headerName={props.data.headerName}
          extraClass={styles["fields-header__summary__extraClass"]}
          icon={props.icon}
          handleEditClick={props.handleEditClick}
        />
      );
    }
    default: {
      return <></>;
    }
  }
}
