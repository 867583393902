import React from "react";
import styles from "./StatusButton.module.scss";
import { iTableBlueIcon, iTableIcon } from "../../../../../theme/assets/svg";
import ReactTooltip from "react-tooltip";

interface propsButton {
  img?: string;
  name?: string;
  imgClick?: any;
  extraStyleOuter?: string;
  extraStyleName?: string;
  extraStyleImg?: string;
  showToolTip?: boolean;
  isHideIcon?: boolean;
  noOutline?: boolean;
  iconShow?: boolean;
  toolTipText?: string;
}
function StatusButton(props: propsButton) {
  return (
    <div className={styles.statusButtonWrapper}>
      <div
        className={`${props?.noOutline ? "" : styles.styleOuter}
               ${props.extraStyleOuter ? props.extraStyleOuter : ""}`}
        data-tip
        data-for={props.showToolTip ? props.toolTipText : ""}
      >
        {!props?.isHideIcon ? (
          <img
            className={`${styles.styleImg}
             ${props.extraStyleImg ? props.extraStyleImg : ""}`}
            onClick={() => (props.imgClick ? props.imgClick() : null)}
            src={props.img}
          ></img>
        ) : (
          ""
        )}

        <p
          className={`${styles.styleName}
            ${props.extraStyleName ? props.extraStyleName : ""}`}
          onClick={() => (props.imgClick ? props.imgClick() : null)}
        >
          {props?.iconShow ? (
            <img
              className={`${styles.styleInfoImg}
             ${props.extraStyleImg ? props.extraStyleImg : ""}`}
              src={props.img}
              data-tip
            ></img>
          ) : (
            ""
          )}
          {props.name ? props.name : "uploaded"}
        </p>
      </div>
      <ReactTooltip
        id={props.toolTipText}
        place="top"
        effect="solid"
        backgroundColor="#545454"
        textColor="#fff"
        padding="10px"
      >
        {props.toolTipText}
      </ReactTooltip>
    </div>
  );
}

export default StatusButton;
