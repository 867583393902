import { call, put, takeLatest } from "redux-saga/effects";
import { config, SETTINGS_API } from "../../../../../services/ApiRoutes.js";
import actionTypes from "../security.actionTypes";
import { toast } from "react-toastify";
import { getPersonalDetails } from "../../../yourAccount/personalDetails/action/personalDetails.action";
export default function* securityDetailsWatcher(): any {
  yield takeLatest(
    actionTypes.SECURITY_UPDATE_DETAILS_API_CALL,
    updateSecurityDetailsSaga
  );
}

function* updateSecurityDetailsSaga(action: any): any {
  try {
    if (action) {
      // const { id, oldPassword, newPassword } = action.payload;
      const response = yield call(
        config.PUT_WITH_BODY,
        SETTINGS_API.UPDATE_SECURITY,
        {
          id:action.payload.id,
          oldPassword: action.payload.oldPassword,
          newPassword: action.payload.newPassword,
        }
      );
      yield put({
        type: actionTypes.SECURITY_UPDATE_DETAILS_API_SUCCESS,
        payload: response.data,
      });
      yield put(getPersonalDetails({ userId: action.payload.id }));
      toast.success(response?.data?.data?.message);
    }
  } catch (error: any) {
    console.error(error);
    toast.error(error.response?.data?.error);
  }
}
