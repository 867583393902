export default {
  ORGANIZATION_DATA_API_SUCCESS: "ORGANIZATION_DATA_API_SUCCESS",
  ORGANIZATION_DATA_API_FAILURE: "ORGANIZATION_DATA_API_FAILURE",
  ORGANIZATION_DATA_API_REQUEST: "ORGANIZATION_DATA_API_REQUEST",
  ORGANIZATION_DATA_API_REFRESS_REQUEST: "ORGANIZATION_DATA_API_REFRESS_REQUEST",
  UPDATE_ORGANIZATION_DATA_API_SUCCESS: "UPDATE_ORGANIZATION_DATA_API_SUCCESS",
  UPDATE_ORGANIZATION_DATA_API_FAILURE: "UPDATE_ORGANIZATION_DATA_API_FAILURE",
  UPDATE_ORGANIZATION_DATA_API_REQUEST: "UPDATE_ORGANIZATION_DATA_API_REQUEST",
  UPDATE_ORG_DATA_STATUS: "UPDATE_ORG_DATA_STATUS",
  SAVE_ORGANIZATION_DATA: "SAVE_ORGANIZATION_DATA",
  SEND_OTP_ORG_REQUEST: "SEND_OTP_ORG_REQUEST",
  SEND_OTP_ORG_SUCCESS: "SEND_OTP_ORG_SUCCESS",
  SEND_OTP_ORG_FAILURE: "SEND_OTP_ORG_FAILURE",
  VERIFY_OTP_ORG_REQUEST: "VERIFY_OTP_ORG_REQUEST",
  VERIFY_OTP_ORG_SUCCESS: "VERIFY_OTP_ORG_SUCCESS",
  VERIFY_OTP_ORG_FAILURE: "VERIFY_OTP_ORG_FAILURE",
  CLEAR_SENT_OTP_ORG: "CLEAR_SENT_OTP_ORG",
  CLEAR_VERIFY_OTP_ORG: "CLEAR_VERIFY_OTP_ORG",
  SELECTED_TAB: "SELECTED_TAB",
  FIRST_TIME_ORG_SAVED: "FIRST_TIME_ORG_SAVED",
  USER_LOGOUT: "USER_LOGOUT",
  VERIFY_GST_ORG_REQUEST: "VERIFY_GST_ORG_REQUEST",
  VERIFY_GST_ORG_SUCCESS: "VERIFY_GST_ORG_SUCCESS",
  VERIFY_GST_ORG_FAILURE: "VERIFY_GST_ORG_FAILURE",
  VERIFY_GST_RESET: "VERIFY_GST_RESET",
  VERIFY_PAN_ORG_REQUEST: "VERIFY_GST_PAN_REQUEST",
  VERIFY_PAN_ORG_SUCCESS: "VERIFY_GST_PAN_SUCCESS",
  VERIFY_PAN_ORG_FAILURE: "VERIFY_GST_PAN_FAILURE",
  VERIFY_PAN_RESET: "VERIFY_PAN_RESET",
  STORE_GST_PAN_DATA: "STORE_GST_PAN_DATA",

  SEND_SIGN_EMAIL_REQUEST: "SEND_SIGN_EMAIL_REQUEST",
  SEND_SIGN_EMAIL_SUCCESS: "SEND_SIGN_EMAIL_SUCCESS",
  SEND_SIGN_EMAIL_FAILURE: "SEND_SIGN_EMAIL_FAILURE",


};
