import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/rootStore";
import Card from "../cardComponent/card/Card";
import ChartView from "../chartView/ChartView";

import styles from "./AgentDashboardModel.module.scss";

// import CardImg from "../../../../theme/assets/agentDesktopSvg/dashboardSvg/cardSvg/dummyImg.svg";
import AdminCardComponent from "../adminCardComponent/AdminCardComponent";
import TabComponent from "../tabComponent/TabComponent";
import {
  getDashboardAPIData,
} from "../../../../redux/agentDesktop/dashboard/actions";

// interface props {
//   default: any;
// }

export default function AgentDashboardModel(props: any) {
  const dispatch = useDispatch();
  const callCardSchema = useSelector(
    (state: any) =>
      state.agentDesktopReducer?.baseScreenAD?.schema?.calls?.cards
  );

  const chatCardSchema = useSelector(
    (state: any) =>
      state.agentDesktopReducer?.baseScreenAD?.schema?.chats?.cards
  );

  const selectedTab = useSelector(
    (state: any) => state.agentDesktopReducer?.tabReducer?.selectedTab
  );

  const callChartrSchema = useSelector(
    (state: any) =>
      state.agentDesktopReducer?.baseScreenAD?.schema?.calls?.charts
  );

  const chatChartrSchema = useSelector(
    (state: any) =>
      state.agentDesktopReducer?.baseScreenAD?.schema?.chats?.charts
  );

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );

  const agentId = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo?.userDetail?._id
  );

  const channelTab = useSelector(
    (store: any) => store?.agentDesktopReducer?.tabReducer?.backendRefenceKey
  );

  const startDate = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer?.rootFilterReducer?.calenderFilterReducer
        ?.formatedStartDate
  );

  const endDate = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer?.rootFilterReducer?.calenderFilterReducer
        ?.formatedEndDate
  );

  const cardSchema = selectedTab == "Call" ? callCardSchema : chatCardSchema;
  const chartSchema =
    selectedTab == "Call" ? callChartrSchema : chatChartrSchema;

  const cardDetails =
    cardSchema && Object.values(cardSchema)?.length > 0
      ? cardSchema.sort((a: any, b: any) => {
          return a?.position - b?.position;
        })
      : [];

  const chartDetails =
    chartSchema && Object.values(chartSchema)?.length > 0
      ? chartSchema.sort((a: any, b: any) => {
          return a?.position - b?.position;
        })
      : [];

  // const cardArr = [
  //   {
  //     name: "Avg. Talk Time Per Call",
  //     icon: CardImg,
  //     value: "1h 20m 30s",
  //     tooltip:true
  //   },
  //   {
  //     name: "Available Duration",
  //     icon: CardImg,
  //     value: "2h 20m 30s",
  //     tooltip:true
  //   },
  //   {
  //     name: "Overall CSAT",
  //     icon: CardImg,
  //     value: "7.5/10",
  //     tooltip:true
  //   },
  //   {
  //     name: "Campaign Assigns",
  //     icon: CardImg,
  //     value: "5",
  //     tooltip:false
  //   },
  // ];
  // useEffect(() => {
  //   if (channelTab) {
  //     cardSchema?.map((e: any) => {
  //       dispatch(
  //         getDashoardApiCards(
  //           {
  //             username: accountName,
  //             start_date: startDate,
  //             end_date: endDate,
  //             channel: [channelTab],
  //             uniqueId: e?.cardData?.uniqueId,
  //             agentIds: [agentId],
  //           },
  //           token
  //         )
  //       );
  //     });
  //   }
  // }, [startDate, endDate, channelTab, cardSchema, agentId]);

  useEffect(() => {
    if (channelTab) {
      // chartSchema?.map((e: any) => {
        dispatch(
          getDashboardAPIData(
            {
              // username: accountName,
              start_date: startDate,
              end_date: endDate,
              channel: channelTab,
              // uniqueId: e?.chartData?.uniqueId,
              agentId: [agentId],
            },

          )
        );
      // });
    }
  }, [startDate, endDate, channelTab, chartSchema, agentId,cardSchema]);

  return (
    <>
      <div className={styles.dashboardModelDiv}>
        <TabComponent />
        <div className={styles.cardBodyDiv}>
          <Card
            cardDetails={cardDetails}
            width={"205px"}
            height={"120px"}
            fontSize={"16px"}
          />
        </div>
        <div className={styles.chartBodyDiv}>
          <ChartView chartDetails={chartDetails} isLoading={props.isLoading} />
        </div>
      </div>
    </>
  );
}
