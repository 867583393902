import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { REPORT_RULE_API, config } from "../../../../../services/ApiRoutes";
import actionTypes from "../reportAutomation.actionTypes";
// import { toast } from "react-toastify";
export function* getReportRulesDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET,
        `${REPORT_RULE_API.GET_REPORT_RULES_BY_ACCOUNT_ID}${action.payload}`
      );
      yield put({
        type: actionTypes.REPORT_RULE_GET_DETAILS_API_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error: any) {
    toast.error(error.response?.data?.error?.message);
    console.error(error);
    yield put({
      type: actionTypes.REPORT_RULE_GET_DETAILS_API_FAILURE,
      payload: error,
    });
  }
}

export function* deleteReportRuleDataAPIWorker(action: any): any {
  // const { ruleId, accountId } = action.payload;
  try {
    if (action) {
      const result = yield call(
        config.DELETE,
        `${REPORT_RULE_API.DELETE_REPORT_RULE_BY_ID}${action.payload.ruleId}`
      );
      yield put({
        type: actionTypes.REPORT_RULE_DELETE_DETAIL_API_SUCCESS,
        payload: result.data.data,
      });
      const res = yield call(
        config.GET,
        `${REPORT_RULE_API.GET_REPORT_RULES_BY_ACCOUNT_ID}${action.payload.accountId}`
      );
      yield put({
        type: actionTypes.REPORT_RULE_GET_DETAILS_API_SUCCESS,
        payload: res.data.data,
      });
    }
    toast.success("Automation Rule Deleted");
  } catch (error: any) {
    console.error(error);
    yield put({
      type: actionTypes.REPORT_RULE_DELETE_DETAIL_API_FAILURE,
      payload: error,
    });
    toast.error(error.response?.data?.error?.message);
  }
}

export function* getSpecificReportRuleDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET,
        `${REPORT_RULE_API.GET_SPECIFIC_REPORT_RULE_BY_ID}${action.payload}`
      );
      yield put({
        type: actionTypes.GET_RULE_DETAIL_API_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error: any) {
    console.error(error);
    yield put({
      type: actionTypes.GET_RULE_DETAIL_API_FAILURE,
      payload: error,
    });
    toast.error(error.response?.data?.error?.message);
  }
}

export function* updateReportRuleDataAPIWorker(action: any): any {
  try {
    const {
      ruleName,
      frequency,
      time,
      dayOfWeek,
      shareTo,
      accountId,
      reportType,
    } = action.payload;
    if (action.payload.updateMode) {
      const response = yield call(
        config.DELETE,
        `${REPORT_RULE_API.DELETE_REPORT_RULE_BY_ID}${action.payload.currentId}`
      );
      yield put({
        type: actionTypes.REPORT_RULE_DELETE_DETAIL_API_SUCCESS,
        payload: response.data.data,
      });
      const result = yield call(
        config.PUT_WITH_PARAMS,
        `${REPORT_RULE_API.UPDATE_REPORT_RULE_BY_ID}${action.payload.ruleId}`,
        { ruleName, frequency, time, dayOfWeek, shareTo, accountId, reportType }
      );
      const res = yield call(
        config.GET,
        `${REPORT_RULE_API.GET_REPORT_RULES_BY_ACCOUNT_ID}${action.payload.accountId}`
      );
      yield put({
        type: actionTypes.UPDATE_RULE_DETAIL_API_SUCCESS,
        payload: result.data.data,
      });

      yield put({
        type: actionTypes.REPORT_RULE_GET_DETAILS_API_SUCCESS,
        payload: res.data.data,
      });
      toast.success("Automation Rule Updated");
    } else {
      const result = yield call(
        config.PUT_WITH_PARAMS,
        `${REPORT_RULE_API.UPDATE_REPORT_RULE_BY_ID}${action.payload.ruleId}`,
        { ruleName, frequency, time, dayOfWeek, shareTo, accountId, reportType }
      );
      const res = yield call(
        config.GET,
        `${REPORT_RULE_API.GET_REPORT_RULES_BY_ACCOUNT_ID}${action.payload.accountId}`
      );
      yield put({
        type: actionTypes.REPORT_RULE_GET_DETAILS_API_SUCCESS,
        payload: res.data.data,
      });
      yield put({
        type: actionTypes.UPDATE_RULE_DETAIL_API_SUCCESS,
        payload: result.data.data,
      });

      toast.success("Automation Rule Updated");
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.UPDATE_RULE_DETAIL_API_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.error(error.response?.data?.error);
    console.error(error);
  }
}

export function* createReportRuleDataAPIWorker(action: any): any {
  try {
    const result = yield call(
      config.POST_WITH_PARAMS,
      `${REPORT_RULE_API.CREATE_REPORT_RULE_BY_ACCOUNT_ID}`,
      action.payload
    );
    const res = yield call(
      config.GET,
      `${REPORT_RULE_API.GET_REPORT_RULES_BY_ACCOUNT_ID}${action.payload.accountId}`
    );

    yield put({
      type: actionTypes.CREATE_RULE_DETAIL_API_SUCCESS,
      payload: result.data.data,
    });

    yield put({
      type: actionTypes.REPORT_RULE_GET_DETAILS_API_SUCCESS,
      payload: res.data.data,
    });
    toast.success("Automation Rule Created");
  } catch (error: any) {
    console.error(error);

    yield put({
      type: actionTypes.CREATE_RULE_DETAIL_API_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.error(error.response?.data?.error?.message);
  }
}
