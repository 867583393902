import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { CAMPAIGN_NEW_API, config } from "../../../../services/ApiRoutes";
import actionTypes from "../campaignTable.actionTypes";


export function* clearCampaignDataSagaWorker(action: any): any {
  yield put({ type: "CLEAR_AGENT_DATA" });
  yield put({ type: "CLEAR_CAMPAIGN_DATA" });
  yield put({ type: "CLEAR_UPLOADED_DATA" });
  yield put({ type: "CLEAR_PROGRESS_BAR" });
  yield put({ type: "CLEAR_SCHEDULAR_DATA" });
  yield put({ type: "CLEAR_STRATEGY_DATA" });
}

export function* terminateCampaignDataAPIWorker(action: any): any {
  try {

    if (action) {

      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${CAMPAIGN_NEW_API.TERMINATE_CAMPAIGN}`,
        action.payload?.body,
        action.payload.headers
      );
      yield put({
        type: actionTypes.POST_CAMPAIGN_TERMINATE_SUCCESS,
        payload: result.data.data,
      });
      if(result){
        action.payload.func()
      }
   
      toast.success(result?.data?.data?.message);
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.POST_CAMPAIGN_TERMINATE_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.error(error.response?.data?.error);
    console.error(error);
  }
}