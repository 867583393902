const profileConfig = {
  yourAccount: {
    name: "Your Account",
    subModule: {
      personal: { name: "Personal" },
      organization: { name: "Organization" },
    },
  },
  billing: {
    name: "Billing",
    subModule: {
      invoices: { name: "Invoices" },
      pricingPlan: { name: "My Plan" },
    },
  },
  settings: {
    name: "Settings",
    subModule: {
      security: { name: "Security" },
      reportAutomation: { name: "Report Automation" },
    },
  },
};
export default profileConfig;
