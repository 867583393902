export const convertToCSV = (data: any) => {
  try {
    // Create arrays to hold non-empty rows and columns
    const nonEmptyRows: any = [];
    const nonEmptyColumns: any = Array.from(
      { length: data[0]?.length },
      () => []
    );

    // Populate non-empty rows and columns
    data.forEach((row: any) => {
      const nonEmptyRow = row.map((cell: any) => (cell ? cell.value : ""));
      nonEmptyRows.push(nonEmptyRow);

      row.forEach((cell: any, colIndex: any) => {
        if (cell && cell.value !== "") {
          nonEmptyColumns[colIndex].push(cell.value);
        }
      });
    });

    // Filter out undefined columns and rows
    const filteredNonEmptyRows = nonEmptyRows.filter((row: any) =>
      row.some((value: any) => value !== "")
    );

    // Create CSV string using join
    const csvData = filteredNonEmptyRows
      .map((row: any) => row.join(","))
      .join("\n");

    return csvData;
  } catch (error) {
    console.error("Error converting to CSV:", error);
    return "";
  }
};
