import { put, call } from "redux-saga/effects";
import { actions as actionName } from "../leftMenu.interface";
import actionTypes from "../actionTypes";
import { ONBOARDING_URL, config } from "../../../../services/ApiRoutes";
import _ from "lodash";

export function* setLeftModulesToVisibleAllWorker(action: any) {
  const modules = JSON.parse(JSON.stringify(action.payload));
  modules.forEach((item: any) => {
    item.shouldFrontEndShow = true;
  });
  yield put({ type: actionName.SET_LEFT_SIDE_MODULES, payload: modules });
}

export function* updateModuleDetailsWorker(action: any): any {
  try {
    if (action) {
      const res = yield call(
        config.POST_WITH_BODY,
        `${ONBOARDING_URL.UNLOCK_ICONS}`,
        action.payload
      );
      yield put({
        type: actionTypes.UNLOCK_ICONS_SUCCESS,
        payload: res.data.data,
      });
      yield put({
        type: actionName.SET_LEFT_SIDE_MODULES,
        payload: action.payload.moduleDetails,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.UNLOCK_ICONS_FAILURE,
      payload: error,
    });
    console.error(error);
  }
}
