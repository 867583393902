import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../generic/loader/Loader";
import GaugeChart from "../charts/GaugeChart";

import styles from "./CustomerData.module.scss";

// interface props {
//   default: string;
// }

export default function CustomerData(props: any) {
  return (
    <>
      <div className={styles.customerData}>
        {props.data &&
          props.data?.map((each: any, i: number) => {
            return (
              <div className={styles.textTitleClass} key={i}>
                <p className={styles.text}>{each.email}</p>
                <p className={styles.text}>{each.phone}</p>
                <p className={styles.text}>
                  <span>KYC Status: </span>
                  <span style={{color:"#25D366"}}>{each["KYC Status"]}</span>
                </p>
                <p className={styles.text}>
                  <span>Gender: </span>
                  <span style={{color:"#333333"}}>{each["Gender"]}</span>
                </p>
                <p className={styles.text}>
                  <span>Age: </span>
                  <span style={{color:"#333333"}}>{each["Age"]}</span>
                </p>
                <p className={styles.text}>
                  <span>Location: </span>
                  <span style={{color:"#333333"}}>{each["Location"]}</span>
                </p>
              </div>
            );
          })}
      </div>
    </>
  );
}
