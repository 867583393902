// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qwtJboxaSKcihPULjjhA{height:100vh;width:100vw;flex-direction:column;justify-content:center;display:flex;align-items:center}.qwtJboxaSKcihPULjjhA h1{color:var(--primary-grey);font-weight:800;margin:30px}.qwtJboxaSKcihPULjjhA h2{color:#d9d9d9;margin:10px}.qwtJboxaSKcihPULjjhA button{all:unset;background-color:var(--secondary-grey);color:var(--primary-grey);cursor:pointer;height:30px;padding:5px;border-radius:7px}`, "",{"version":3,"sources":["webpack://./src/components/generic/errorBoundary/ErrorBoundary.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,qBAAA,CACA,sBAAA,CACA,YAAA,CACA,kBAAA,CACA,yBACE,yBAAA,CACA,eAAA,CACA,WAAA,CAEF,yBACE,aAAA,CACA,WAAA,CAEF,6BACE,SAAA,CACA,sCAAA,CACA,yBAAA,CACA,cAAA,CACA,WAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".fullscreen {\n  height: 100vh;\n  width: 100vw;\n  flex-direction: column;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n  h1 {\n    color: var(--primary-grey);\n    font-weight: 800;\n    margin: 30px;\n  }\n  h2 {\n    color: rgb(217, 217, 217);\n    margin: 10px;\n  }\n  button {\n    all: unset;\n    background-color: var(--secondary-grey);\n    color: var(--primary-grey);\n    cursor: pointer;\n    height: 30px;\n    padding: 5px;\n    border-radius: 7px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullscreen": `qwtJboxaSKcihPULjjhA`
};
export default ___CSS_LOADER_EXPORT___;
