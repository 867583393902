import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./CategoryComponent.module.scss";
import { filterByCategory } from "../../../../redux/integration_V2.0/allIntegrations/actions";
import CustomBodySkeleton from "../../../generic/customSkeleton/CustomBodySkeleton";


interface categoryProps {
  categories: any;
  isLoading: boolean;
}

export const CategoryComponent = ({ categories, ...props }: categoryProps) => {
  const dispatch = useDispatch();

  const [selectedCategory, setSelectedCategory] = useState("");

  const handleSelectCategory = (data: string) => {
    setSelectedCategory(data);
    dispatch(filterByCategory(data));
  };

  useEffect(() => {
    setSelectedCategory(
      selectedCategory ? selectedCategory : categories?.[0]?.categoryName
    );
    dispatch(filterByCategory(categories?.[0]?.categoryName));
  }, [categories]);

  return (
    <div className={styles.categoryComponentWrapper}>
      <CustomBodySkeleton isLoading={props.isLoading}>
        <div className={styles.categoryHeading}>Categories</div>
      </CustomBodySkeleton>
      <div className={styles.categoriesDiv}>
        {categories?.map((data: any, index: number) => {
          return (
            <CustomBodySkeleton
              isLoading={props.isLoading}
              key={index}
              width={"100%"}
            >
              <div
                id={`auto_category_${index}`}
                key={index}
                className={`${
                  selectedCategory === data?.categoryName
                    ? styles.categorySelectedTextDiv
                    : ""
                } ${styles.categoryDiv}`}
                onClick={() => handleSelectCategory(data?.categoryName)}
              >
                <div
                  className={`${styles.categoryText} ${
                    selectedCategory === data?.categoryName
                      ? styles.categorySelectedText
                      : ""
                  }`}
                  id={`auto_category_name_${index}`}
                >
                  {data.categoryName}
                </div>
                <div
                  className={`${styles.categoryText} ${styles.categoryCount} ${
                    selectedCategory === data?.categoryName
                      ? styles.categorySelectedText
                      : ""
                  }`}
                  id={`auto_category_count_${index}`}
                >
                  ({String(data.categoryData?.length).padStart(2, "0")})
                </div>
              </div>
            </CustomBodySkeleton>
          );
        })}
      </div>
    </div>
  );
};
