import React, { useEffect, useState } from "react";
import styles from "./ComparePricingScreen.module.scss";
import {
  getPricingPlansAPIData,
  setSelectedPlanData,
  setAddedToCartRoute,
  sendCustomPlanRequest,
} from "../../../../redux/profile/billing/pricingPlan/actions/pricingPlan.actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { PricingPlanCard } from "../../../../components/moduleComponents/paymentModuleComponents/pricingCard/PricingPlanCard";
import { useNavigate } from "react-router-dom";
import { profileRoutes } from "../../../../router/routes";
import Button from "../../../../components/generic/button/Button";
import { toast } from "react-toastify";

export const ComparePricingScreen = () => {
  const [isUpdateFlow, setIsUpdateFlow] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const purchasePlans = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.pricingPlan?.pricingPlanDetails?.data
        ?.planData
  );

  const userId = useSelector(
    (state: RootState) => state?.loginReducer?.userLoginInfo?.userDetail?._id
  );

  const accountDetails = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo?.accountDetails[0]
  );

  const accountId = accountDetails?.id;

  const userDetails = useSelector(
    (state: RootState) => state?.loginReducer?.userLoginInfo?.userDetail
  );

  const accessToken = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const customPlanRequest = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.pricingPlan?.customPlanRequest
  );
  const orgData = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization.organizationDetails
  );
  console.log("orgData", orgData);
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": accessToken,
  };
  // for get latest data
  const getAllPlansDetails = () => {
    dispatch(
      getPricingPlansAPIData({
        currency: "INR",
        accountId: accountId,
        userId: userId,
      })
    );
  };
  useEffect(() => {
    getAllPlansDetails();
  }, []);

  useEffect(() => {
    const isPlanActive: any = purchasePlans?.filter(
      (data) => data.tag === "My Plan"
    );
    if (isPlanActive?.length > 0) {
      setIsUpdateFlow(isPlanActive?.[0]?.planName);
    }
  }, [purchasePlans]);

  const handleClickContactUs = () => {
    if (!orgData?.name && !orgData?.organizationEmail) {
      toast.error("Please fill Organisation Details");
      return;
    }
    const obj = {
      body: {
        userName: userDetails?.name,
        accountId: accountId,
        organisationName: accountDetails?.organizationName,
        userEmail: accountDetails?.organizationEmail,
        phoneNumber: accountDetails?.phoneNumber,
        subject: `Custom Enterprise Plan request from ${accountDetails?.organizationName}`,
      },
      headers: headers,
    };
    dispatch(sendCustomPlanRequest(obj));
  };

  return (
    <div className={styles.comparePricingScreenWrapper}>
      <div className={styles.comparePricingHeader}>
        <div className={styles.comparePricingHeaderText}>Select Plan</div>
        {customPlanRequest?.data?.accountDetails?.isContactUsEmailSent ===
          true || accountDetails?.isContactUsEmailSent === true ? (
          <div className={styles.customPlan}> Custom Plan request sent </div>
        ) : (
          <div className={styles.customPlan}>
            Looking for custom Plan ?
            <Button
              text="Contact Us"
              extraClass={`${styles.buttonCustomPlan}`}
              onClick={handleClickContactUs}
              disabled={customPlanRequest?.isLoading}
            />
          </div>
        )}
      </div>
      <div className={styles.purchasePlanCards}>
        {purchasePlans
          ?.sort((a: any, b: any) => a?.position - b?.position)
          ?.map((data: any, index: number) => {
            return (
              <div key={index} className={styles.purchasePlanCard}>
                <PricingPlanCard
                  cardIndex={index}
                  cardData={data}
                  onClickSeeMore={() => {
                    dispatch(setSelectedPlanData(data));
                    navigate("/" + profileRoutes.PLAN_DETAILS);
                  }}
                  onClickAdd={(data: any) => {
                    // if (
                    //   isUpdateFlow.length > 0 &&
                    //   isUpdateFlow === data?.planName
                    // ) {
                    //   dispatch(getCurrentTab("My Plan"));
                    //   navigate("/profile/billing/invoices");
                    // } else {
                    dispatch(setSelectedPlanData(data));
                    dispatch(setAddedToCartRoute("comparePage"));
                    navigate("/profile/billing/addtocart");
                    // }
                  }}
                  cardBtnText={
                    isUpdateFlow.length > 0
                      ? isUpdateFlow === data?.planName
                        ? "Upgrade"
                        : "Upgrade"
                      : "Add to cart"
                  }
                  isUpdateFlow={isUpdateFlow.length > 0 ? true : false}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
