import {
  config,
  CONVERSATION_URL,
  SERVER_URL2,
  AGENT_DESKTOP_API,
} from "../../../../services/ApiRoutes";
import { put, call } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import { toast } from "react-toastify";
import screenType from "../../../../constants/screenType";

export function* getCallLoggerSummaryWorker(action: any): any {
  try {
    const result = yield call(
      config.GET_WITH_PARAMS,
      `${AGENT_DESKTOP_API.GET_SUMMARY}`,
      {
        conversationId: action.payload,
      }
    );
    yield put({
      type: actionTypes.GET_SUMMARY_SUCCESS_AGENT_DESKTOP,
      payload: result.data.data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_SUMMARY_FAILURE_AGENT_DESKTOP,
      payload: error,
    });
  }
}

export function* getCallLoggerTranscriptWorker(action: any): any {
  try {
    const id = action.payload; // this is the id for  call summary inside here to fetch
    const url = `${AGENT_DESKTOP_API.GET_TRANSCRIPT}`;
    const result = yield call(config.GET_WITH_PARAMS, url, {
      conversationId: id,
    });
    yield put({
      type: actionTypes.GET_TRANSCRIPT_SUCCESS_AGENT_DESKTOP,
      payload: result.data.data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_TRANSCRIPT_FAILURE_AGENT_DESKTOP,
      payload: error,
    });
  }
}
export function* getSessionID(action: any): any {
  try {
    const url = `${SERVER_URL2}${CONVERSATION_URL.FETCH_SESSION_ID}`;
    const result = yield call(config.POST, url, action.payload);
    yield put({
      type: actionTypes.GET_SESSION_ID_SUCCESS_AGENT_DESKTOP,
      payload: result.data,
    });
    console.log(result);
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_SESSION_ID_FAILURE_AGENT_DESKTOP,
      payload: error,
    });
  }
}

export function* updateSummaryWorker(action: any): any {
  const body = JSON.parse(JSON.stringify(action.payload.data));
  delete body["chatStatus"];
  const apiBody = { id: action.payload.conversationId, information: body };
  try {
    yield call(
      config.POST_WITH_BODY,
      `${AGENT_DESKTOP_API.UPDATE_SUMMARY}`,
      apiBody
    );
    yield put({ type: actionTypes.UPDATE_SUMMARY_SUCCESS_AGENT_DESKTOP });
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.UPDATE_SUMMARY_FAILURE_AGENT_DESKTOP,
      payload: error,
    });
    toast.error(new String(error));
  }
}
