import React from "react";
import styles from "./VerifyPopUp.module.scss";
import Modal from "../../../generic/modal/Modal";
import Icon from "../../../generic/icon/Icon";
import { createStrategyCloseIcon } from "../../../../theme/assets/svg/strategySvg";

interface verifyModalProps {
  show?: boolean;
  close?: CallableFunction;
  onClick?: CallableFunction;
  contentToDisplay?: any;
  img?: string;
  extraStyleImg?: string;
  isGif?: boolean;
  imgGif?: string;
  imgIcon?: string;
}

export const VerifyPopUp = ({
  show,
  close,
  onClick,
  contentToDisplay,
  img,
  isGif,
  extraStyleImg,
  imgIcon,
  imgGif,
}: verifyModalProps) => {
  return (
    <div>
      <Modal
        shown={show}
        close={() => {
          close && close();
        }}
        extraClassStyleModalContent={styles.verifyModalStyling}
        extraClassStyleModalBackDrop={styles.verifyBackDropStyling}
      >
        <div data-testid="verify-modal" className={styles.modalVerifyInfoDiv}>
          {isGif ? (
            <div>
              <Icon
                img_src={imgGif}
                extraClass={
                  extraStyleImg ? extraStyleImg : styles.iconGifStyling
                }
              />
              <Icon img_src={imgIcon} extraClass={styles.imgIconStyling} />
            </div>
          ) : (
            <Icon
              img_src={img}
              extraClass={extraStyleImg ? extraStyleImg : styles.iconStyling}
            />
          )}
          <div className={styles.modalVerifyCloseIcon}>
            <img
              src={createStrategyCloseIcon}
              onClick={() => {
                close && close();
              }}
              id="auto_verify_modal_close_icon"
            />
          </div>
          <div className={styles.contentDiv}>{contentToDisplay}</div>
        </div>
      </Modal>
    </div>
  );
};
