import { takeLatest } from "redux-saga/effects";
import actionTypes from "../createCampaign.actionTypes";
import {
  getCampaignASRDataAPIWorker,
  getCampaignTTSDataAPIWorker,
  getCampaignOptionsDataAPIWorker,
  updateCampaignDataAPIWorker,
  createCampaignDataAPIWorker,
} from "./createCampaign.saga";

export default function* createCampaignV2DetailsWatcher(): any {
  yield takeLatest(
    actionTypes.GET_CAMPAIGN_ASR_API_REQUEST,
    getCampaignASRDataAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_CAMPAIGN_TTS_API_REQUEST,
    getCampaignTTSDataAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_CAMPAIGN_OPTIONS_API_REQUEST,
    getCampaignOptionsDataAPIWorker
  );
  yield takeLatest(
    actionTypes.CREATE_CAMPAIGN_DATA_API_REQUEST,
    createCampaignDataAPIWorker
  );
  yield takeLatest(
    actionTypes.UPDATE_CAMPAIGN_DATA_API_REQUEST,
    updateCampaignDataAPIWorker
  );
}
