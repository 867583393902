import React from "react";
import styles from "./AdminCardComponent.module.scss";
import CardImg from "../../../../theme/assets/agentDesktopSvg/dashboardSvg/cardSvg/dummyImg.svg";
import { useDispatch, useSelector } from "react-redux";
import PravidIcons from "../../generic/pravidIcons/PravidIcons";

// interface props {
//   default: string;
// }

export default function AdminCardComponent(props: any) {
  const chartData = useSelector(
    (state: any) => state?.agentDesktopReducer?.dashboardReducer?.dashboardChartDetails?.performance
  );
  return (
    <>
      {props.cardDetails &&
        props.cardDetails.map((each: any, i: number) => {
          return (
            <div className={styles.adminCardWrapper} key={i}>
              <div className={styles.headerIcondiv}>
                {/* <img style={{ height: "21px" }} src={CardImg} alt="Img" /> */}
                
                <PravidIcons
                activeIcon={each?.cardIcon}
                 />
              </div>
              <div className={styles.bodyDataDiv}>
                <p className={styles.cardNameDiv}>{each.cardName}</p>
                <span className={styles.cardvalueDiv}>{Math.ceil(chartData[each.referenceKey]["avg"] ? chartData[each.referenceKey]["avg"] : 0)}</span>
              </div>
              <div className={styles.footerDataDiv}>
                <span className={styles.footerName}></span>
                <span className={styles.middlevalueDiv}><span>Min:</span>{chartData[each.referenceKey]["min"] ? chartData[each.referenceKey]["min"] : 0}</span>
                <span className={styles.lastvaluediv}><span>Max:</span>{chartData[each.referenceKey]["max"] ? chartData[each.referenceKey]["max"] : 0}</span>
              </div>
            </div>
          );
        })}
    </>
  );
}
