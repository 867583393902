export const FilterActionTypes = {
  GET_ALL_FILTER_REQUEST: "GET_ALL_FILTER_REQUEST",
  GET_ALL_FILTER_SUCCESS: "GET_ALL_FILTER_SUCCESS",
  GET_ALL_FILTER_FAILURE: "GET_ALL_FILTER_FAILURE",
  SET_DATES_FROM_CALENDER: "SET_DATE_FROM_CALENDER",
  SET_FILTER_OPTIONS_SELECTED_IN_REDUX: "SET_FILTER_OPTIONS_SELECTED_IN_REDUX",
  PUT_FILTER_OPTIONS_SELECTED_IN_REDUX: "PUT_FILTER_OPTIONS_SELECTED_IN_REDUX",
  SET_DOWNLOAD_OPTIONS_AND_DOWNLOAD: "SET_DOWNLOAD_OPTIONS_AND_DOWNLOAD",
  SET_LAST_UPDATED_TIME: "SET_LAST_UPDATED_TIME",
  SET_SELECTED_FILTER_TYPE: "SET_SELECTED_FILTER_TYPE",
  SET_SHOW_COLUMN_CUSTOMIZE_POP: " SET_SHOW_COLUMN_CUSTOMIZE_POP",
  TOGGLE_SHOW_COLUMN_CUSTOMIZE_POP: "TOGGLE_SHOW_COLUMN_CUSTOMIZE_POP",
  TOGGLE_SHOW_COLUMN_NAMECUSTOMIZE_POP: "TOGGLE_SHOW_COLUMN_NAMECUSTOMIZE_POP",
  SET_SHOW_COLUMN_NAMECUSTOMIZE_POP: "SET_SHOW_COLUMN_NAMECUSTOMIZE_POP",
  SET_ALL_REPORT_COLUMN_IN_DND: "SET_ALL_REPORT_COLUMN_IN_DND",
  ADDREPORT_COLUMN_IN_REPORT_COLUMN: "ADD_REPORT_COLUMN_IN_REPORT_COLUMN",
  DELETE_REPORT_COLUMN_IN_REPORT_COLUMN:
    "DELETE_REPORT_COLUMN_IN_REPORT_COLUMN",
  SET_DEFAULT_OPTIONS_REPORT_COLUMN_IN_DND:
    "DEFAULT_OPTIONS_REPORT_COLUMN_IN_DND",
  SET_SHOW_EDIT_COLUMN_NAME: "SET_SHOW_EDIT_COLUMNNAME",
  TOGGLE_SHOW_EDIT_COLUMN_NAME: "TOGGLE_SHOW_EDIT_COLUMN_NAME",
  SET_HAS_CHANGE_FILTER: "SET_HAS_CHANGE_FILTER",
  SET_DOWNLOAD_VIEW_ALL: "SET_DOWNLOAD_VIEW_ALL",
  SET_KPI_EDITED_OPTIONS: "SET_KPI_EDITED_OPTIONS",
  SET_SHOW_RIGHTSIDE_BAR: "SET_SHOW_RIGHTSIDE_BAR",
  TOGGLE_SHOW_RIGHTSIDE_BAR: "TOGGLE_SHOW_RIGHTSIDE_BAR",
  DIRECT_DOWNLOAD_REPORT_REQUEST: "DIRECT_DOWNLAOD_REPORT_REQUEST",
  DIRECT_DOWNLOAD_REPORT_SUCCESS: "DIRECT_DOWNLAOD_REPORT_SUCCESS",
  DIRECT_DOWNLOAD_REPORT_FAILURE: "DIRECT_DOWNLOAD_REPORT_FAILURE",
  REFRESH_FILTER_OPTIONS: "REFRESH_FILTER_OPTIONS",
  TOGGLE_REFRESH: "TOGGLE_REFRESH",
  REFRESH_PAGE_TIME_API_REQUEST: "REFRESH_PAGE_TIME_API_REQUEST",
  REFRESH_PAGE_TIME_API_SUCCESS: "REFRESH_PAGE_TIME_API_SUCCESS",
  ALL_REFRESH_FILTERS: "ALL_REFRESH_FILTERS",
  SET_SELECTED_ICON_RIGHTSIDE: "SET_SELECTED_ICON_RIGHTSIDE",
  USER_LOGOUT: "USER_LOGOUT",
};
