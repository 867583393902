import React, { useRef } from "react";
import { useOnClickOutside } from "../useOnClickOutside/UseOnClickOutside";
import styles from "./Modal.module.scss";

interface ModalProps {
  children?: any;
  shown?: any;
  close?: any;
  extraClassStyleModalBackDrop?: any;
  extraClassStyleModalContent?: any;
}
export default function Modal({
  children,
  shown,
  close,
  extraClassStyleModalBackDrop,
  extraClassStyleModalContent,
}: ModalProps) {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  // useOnClickOutside(ref, () => close());
  return shown ? (
    <div
      className={` ${
        extraClassStyleModalBackDrop ? extraClassStyleModalBackDrop : ""
      } ${styles.modalBackdrop}`}
      onClick={(e) => {
        // close modal when outside of modal is clicked
        e.stopPropagation();
        close();
      }}
      // ref={ref}
    >
      <div
        className={` ${
          extraClassStyleModalContent
            ? extraClassStyleModalContent
            : styles.modalContent
        }`}
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
        data-testid="modal-window"
      >
        {children}
      </div>
    </div>
  ) : null;
}
