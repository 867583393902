import React, { useState } from "react";
import Button from "../button/Button";
import InputContainer from "../inputContainer/InputContainer";
import Modal from "../modal/Modal";
import style from "./SendEmail.module.scss";
import { crossIcon } from "../../../theme//assets/profileSectionSvg";
import { callbacks } from "../../../screens/agentDesktop/callWebSocket/callWebSocketTs/callWebSocketTs";
import {
  maxLengthValidations,
  emailValidation,
  domainValidation,
} from "../../../utils/Validation";

interface props {
  onClickSend?: any;
  close?: any;
  personalEmail?: string;
}

function SendEmail(props: props) {
  const [email, setEmail] = useState<string>();
  const [isFocused, setIsFocused] = useState("");
  // handle change
  const handleChnageEmail = (e: any) => {
    setEmail(e?.target?.value);
  };

  const handleClose = () => {
    props.close();
  };

  const handleSend = () => {
    props.onClickSend(email);
  };

  return (
    <div
      className={style.modalClassPopUp}
      onClick={(e) => {
        // close modal when outside of modal is clicked
        e.stopPropagation();
        props.close();
      }}
      // r
    >
      <div
        className={style.sendEmailPopWrapper}
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
      >
        <div className={style.closeIcon}>
          <img
            src={crossIcon}
            className={style.IconStyling}
            onClick={() => props.close()}
          />
        </div>
        <div className={style.containerInput}>
          <div className={style.headingLabel}>Enter your email</div>
          <div>
            <InputContainer
              // showLabel={true}
              label="E-mail"
              inputAutoComplete="false"
              inputOnChange={handleChnageEmail}
              inputPlaceholder={"E-mail"}
              keyName={"email"}
              inputValue={email}
              extraUserInputClass={style.extraUserInputClass}
              inputOnKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) =>
                !/^[a-zA-Z0-9+_.-@]*$/.test(evt.key) && evt.preventDefault()
              }
              showNewToolTip
              showNewToolTipPosition={"top-end"}
              showNewToolTipMsg={
                "This is the email address that will be used to send emails to your customers."
              }
              inputOnFocus={() => setIsFocused("email")}
              toolTipSize={false}
              errorMsg={
                email && email.length > 0
                  ? !domainValidation(email, props?.personalEmail).isValid
                    ? domainValidation(email, props?.personalEmail).errors
                        .message
                    : isFocused === "email"
                    ? !maxLengthValidations(email, "Email", 128).isValid
                      ? maxLengthValidations(email, "Email", 128).errors.message
                      : ""
                    : ""
                  : ""
              }
            />
          </div>
        </div>
        <div className={style.buttonContainer}>
          <Button
            text="Cancel"
            extraClass={style.cancelButtonStyle}
            onClick={handleClose}
          />
          <Button
            text="Send"
            extraClass={
              email &&
              email.length > 0 &&
              domainValidation(email, props?.personalEmail).isValid
                ? style.sendButtonStyle
                : style.sendButtonDisableStyle
            }
            onClick={handleSend}
          />
        </div>
      </div>
    </div>
  );
}

export default SendEmail;
