import React from "react";
import styles from "./InteractionPage.module.scss";
import { callsx, whatsapp } from "../../../../theme/assets/svg";
const data = [
  {
    date: "24 Dec 2022",
    nudges: [
      {
        name: "Nudge N1",
        channel: "whatsapp",
        time: "9:39AM",
        disposition: "Promise_to_pay",
        campaign: "EMI Collection",
        attempt: 2,
        ptp: "26 Dec 2022",
        delayReason: "Medical Problem",
        flow: "Pre_Due",
      },
      {
        name: "Nudge N3",
        channel: "call",
        time: "9:10 AM",
        disposition: "Promise_to_pay",
        campaign: "EMI Collection",
        attempt: 2,
        ptp: "26 Dec 2022",

        flow: "Pre_Due",
      },
    ],
  },
  {
    date: "26 Dec 2022",
    nudges: [
      {
        name: "Nudge N4_7",
        channel: "call",
        time: "10:30AM",
        disposition: "Promise_to_pay",
        attempt: 2,
        ptp: "27 Dec 2022",
        flow: "Pre_Due",
      },
    ],
  },
  {
    date: "28 Dec 2022",
    nudges: [
      {
        name: "Nudge N6",
        channel: "call",
        time: "9:35AM",
        disposition: "Disconnected",
        attempt: 5,
        negative: true,
        flow: "Pre_Due",
      },
      {
        name: "Nudge N7",
        channel: "whatsapp",
        time: "1:40PM",
        disposition: "Promise_to_pay",
        attempt: 3,
        ptp: "28 Dec 2022",
        delayReason: "Medical Problem",
        flow: "Pre_Due",
      },
    ],
  },
  {
    date: "30 Dec 2022",
    nudges: [
      {
        name: "Nudge N8",
        channel: "call",
        time: "10:30AM",
        disposition: "Promise_to_pay",
        attempt: 3,
        ptp: "1 Jan 2023",
        delayReason: "Medical Problem",
        flow: "Pre_Due",
      },
    ],
  },
];
export default function InteractionPage() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperx}>
        {data.map((each: any) => {
          return (
            <div className={styles.box_nudgexx} key={each.date}>
              <div className={styles.nudgebutton}>{each.date}</div>
              <div className={styles.vertical_divide}></div>
              {each.nudges.map((eachNudge: any, i: number) => {
                return (
                  <div
                    key={i}
                    className={styles.box_nudge}
                    style={{
                      ...(eachNudge.negative
                        ? { border: "0.7px solid #F5222D" }
                        : {}),
                    }}
                  >
                    <div className={styles.headsxs}>
                      <img
                        src={eachNudge.channel == "call" ? callsx : whatsapp}
                        alt="chanel"
                      />
                      <p>{eachNudge.name}</p>
                    </div>
                    {}
                    {Object.keys(eachNudge).map((eachInfoNudge: any, idx) => {
                      if (eachInfoNudge == "disposition") {
                        return (
                          <div key={idx} className={styles.footersx}>
                            <p>Disposition: </p>
                            <p>{eachNudge.disposition}</p>
                          </div>
                        );
                      }
                      if (eachInfoNudge == "campaign") {
                        return (
                          <div key={idx} className={styles.footersx}>
                            <p>Campaign: </p>
                            <p>{eachNudge.campaign}</p>
                          </div>
                        );
                      }
                      if (eachInfoNudge == "attempt") {
                        return (
                          <div key={idx} className={styles.footersx}>
                            <p>Connection Attempt: </p>
                            <p>{eachNudge.attempt}</p>
                          </div>
                        );
                      }
                      if (eachInfoNudge == "ptp") {
                        return (
                          <div key={idx} className={styles.footersx}>
                            <p>PTP Date: </p>
                            <p>{eachNudge.ptp}</p>
                          </div>
                        );
                      }
                      if (eachInfoNudge == "delayReason") {
                        return (
                          <div key={idx} className={styles.footersx}>
                            <p>Delay Reason: </p>
                            <p>{eachNudge.delayReason}</p>
                          </div>
                        );
                      }
                      if (eachInfoNudge == "flow") {
                        return (
                          <div key={idx} className={styles.footersx}>
                            <p>Flow: </p>
                            <p>{eachNudge.flow}</p>
                          </div>
                        );
                      }
                    })}

                    <div className={styles.timex}>{eachNudge.time}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
