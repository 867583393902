import React, { useEffect, useState } from "react";
import styles from "./CrmHeader.module.scss";
interface prop {
  setDataOut: (a: string) => any;
  options: Array<any>;
  defaultSelected?: any;
}
export default function CrmHeader(props: prop) {
  const [selected, setSelected] = useState<string>(props.options[0]);
  function handleClick(data: string) {
    setSelected(data);
  }
  useEffect(() => {
    props?.setDataOut(selected);
  }, [selected]);
  useEffect(() => {
    if (props.defaultSelected) {
      props?.setDataOut(props.defaultSelected);
      setSelected(props.defaultSelected);
    }
  }, []);
  return (
    <div className={styles.wrapperx}>
      <div
        onClick={(e) => {
          e.stopPropagation();
          handleClick(props.options[0]);
        }}
        className={`${styles.each_div} ${
          selected == props.options[0] ? styles.speacial_div : ""
        }`}
      >
        <p
          className={`  ${selected == props.options[0] ? styles.sp_para : ""} `}
        >
          {props.options[0]}
        </p>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          handleClick(props.options[1]);
        }}
        className={`${styles.each_div} ${
          selected == props.options[1] ? styles.speacial_div : ""
        }`}
      >
        <p
          className={`  ${selected == props.options[1] ? styles.sp_para : ""} `}
        >
          {props.options[1]}
        </p>{" "}
      </div>
    </div>
  );
}
