import { combineReducers } from "@reduxjs/toolkit";
import allIntegrationsReducer from "./allIntegrations/reducer";
import trueCallerReducer from "./trueCaller/reducer";
import whatsAppReducer from "./whatsApp/reducer";
import emailReducer from "./email/reducer";
import customIntegrationReducer from "./customIntegration/reducer";
import smsReducer from "./sms/reducer";
import telephonyReducer from "./telephony/reducer";

const rootIntegrationReducer = combineReducers({
  allIntegrationsReducer: allIntegrationsReducer,
  trueCallerReducer: trueCallerReducer,
  whatsAppReducer: whatsAppReducer,
  emailReducer: emailReducer,
  smsReducer: smsReducer,
  customIntegrationReducer: customIntegrationReducer,
  telephonyReducer,
});
export default rootIntegrationReducer;
