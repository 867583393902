import React, { useState } from "react";
import styles from "./CustomerProfile.module.scss";
import { goback } from "../../../../theme/assets/svg";
import { useNavigate } from "react-router-dom";
import PaymentTable from "../paymentTable/PaymentTable";
import InteractionPage from "../interactionPage/InteractionPage";
import CustomerProfileModel from "../customerProfileModel/CustomerProfileModel";
export default function CustomerProfile() {
  const navigate = useNavigate();
  const [selectedIP, setSelectedIP] = useState<string>("interaction");
  return (
    <div className={styles.customer_profile_wrpaper}>
      {/* top section */}
      <div className={styles.customer_top_div}>
        <div className={styles.customer_top_head}>
          <img onClick={() => navigate("/crm")} src={goback} alt="<" />{" "}
          <p>Customer Profile</p>
        </div>
        <div className={styles.custome_loan}>Loan ID: 57191860763929</div>
      </div>
      {/* top 3 */}
      <CustomerProfileModel />
      <div></div>
      {/* bottom head section */}
      <div className={styles.bittoms_bttons}>
        <div
          onClick={() => setSelectedIP("interaction")}
          className={`${
            selectedIP == "interaction" ? styles.selected_tab : ""
          }`}
        >
          Interaction
        </div>
        <div
          onClick={() => setSelectedIP("payment")}
          className={`${selectedIP == "payment" ? styles.selected_tab : ""}`}
        >
          Payment
        </div>
      </div>
      {selectedIP == "interaction" ? <InteractionPage /> : <PaymentTable />}
    </div>
  );
}
