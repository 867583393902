import calendarActionTypes from "../actionTypes/calendarFilter.actionTypes";

export function setStartDateAgentDesktop(startDate: Date) {
  return { type: calendarActionTypes.SET_START_DATE, payload: startDate };
}
export function setEndDateAgentDesktop(endDate: Date) {
  return { type: calendarActionTypes.SET_START_DATE, payload: endDate };
}
export function setCalendarRangeAgentDesktop(startDate: Date, endDate: Date) {
  return {
    type: calendarActionTypes.SET_RANGE,
    payload: { startDate, endDate },
  };
}
