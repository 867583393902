import React from "react";
import styles from "./IntegrationCard.module.scss";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";
import Icon from "../../../generic/icon/Icon";
import ToolTip from "../../../generic/toolTip/ToolTip";
import { InfoToolTip } from "../../../generic/infoToolTip/InfoToolTip";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { lockIcon } from "../../../../theme/assets/svg";
import CustomBodySkeleton from "../../../generic/customSkeleton/CustomBodySkeleton";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#B6B6B6",
    borderRadius: "1.20455px",
    color: "#fff",
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: 400,
    maxWidth: "210px",
    // width: "180px",
    // height: "45px",
    overflowWrap: "break-word",
  },
  customArrow: {
    color: "#B6B6B6",
    transform: "translateX(3em)",
    fontSize: 9,
  },
}));

interface integrationProps {
  integrationInfo?: any;
  onClickFunc?: any;
  statusInfo?: any;
  isLoading: boolean;
}

export const IntegrationCard = ({
  integrationInfo,
  onClickFunc,
  statusInfo,
  isLoading,
}: integrationProps) => {
  const classes = useStyles();
  return (
    <CustomBodySkeleton isLoading={isLoading}>
      <div
        className={`${
          integrationInfo?.isDisabled === true
            ? styles.integrationCardToolTip
            : ""
        }  `}
      >
        <div
          className={`${
            integrationInfo?.isDisabled === true
              ? styles.disabledIntegrationCardDiv
              : ""
          }  `}
        >
          <div className={styles.integrationCardWrapper} onClick={onClickFunc}>
            <div className={styles.integrationTopDiv}>
              <div className={styles.integrationTopLeftDiv}>
                <PravidIcons
                  activeIcon={integrationInfo?.icon}
                  extraClass={styles.integrationIconStyling}
                />
                {/* <Icon
            img_src={integrationInfo?.icon}
            extraClass={styles.integrationIconStyling}
          /> */}
              </div>
              <div className={styles.integrationTopRightDiv}>
                {integrationInfo?.error?.length > 0 ? (
                  <div>
                    <InfoToolTip
                      message={integrationInfo?.error}
                      isError
                      position="top-end"
                    />
                  </div>
                ) : (
                  ""
                )}
                {integrationInfo?.integrationStatus ? (
                  <Tooltip
                    title={
                      integrationInfo?.tooltip
                        ? integrationInfo?.tooltip
                        : "status info"
                    }
                    placement="top-end"
                    classes={{
                      arrow: classes.customArrow,
                      tooltip: classes.customTooltip,
                    }}
                    arrow
                  >
                    <div className={`${styles.integrationStatus} `}>
                      {statusInfo}
                    </div>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={styles.integrationHeaderDiv}>
              <div className={styles.integrationHeading}>
                {integrationInfo?.integrationName}
              </div>
              <div className={styles.integrationDesc}>
                {integrationInfo?.integrationDescription}
              </div>
            </div>

            {integrationInfo?.isRecommended === true ? (
              <div className={styles.recommendedDiv}>
                <div className={styles.recommendedText}>Recommended</div>
              </div>
            ) : (
              ""
            )}
          </div>
          {integrationInfo?.isDisabled === true ? (
            <Icon img_src={lockIcon} extraClass={styles.iconDisabledStyling} />
          ) : null}
        </div>
        {integrationInfo?.isDisabled === true ? (
          <span className={styles.integrationCardToolTipText}>
            Coming Soon...
          </span>
        ) : null}
      </div>
    </CustomBodySkeleton>
  );
};
