import React, { useEffect, useState } from "react";
import styles from "./InputDays.module.scss";

interface InputDaysProps {
  extraClass?: string;
  onCountChange: (count: number) => void;
  getCount?: number | 0;
  minValue?: number;
  maxValue?: number;
  text?: string;
}

export const InputDays = (props: InputDaysProps) => {
  const [count, setCount] = useState(0);

  const handlePlusClick = () => {
    setCount((prev) => {
      const newValue = prev + 1;
      const maxValue = props.maxValue !== undefined ? props.maxValue : Infinity;
      const newCount = Math.min(maxValue, newValue); // Limit count to maxValue
      props.onCountChange(newCount);
      return newCount;
    });
  };

  function inputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = parseInt(e.target.value);
    const minValue = props.minValue !== undefined ? props.minValue : -Infinity;
    const maxValue = props.maxValue !== undefined ? props.maxValue : Infinity;
    const newCount = Math.min(maxValue, Math.max(minValue, newValue));
    setCount(newCount);
    props.onCountChange(newCount);
  }

  const handleMinusClick = () => {
    const newValue = count - 1;
    const minValue = props.minValue !== undefined ? props.minValue : -Infinity;
    const newCount = Math.max(minValue, newValue);
    setCount(newCount);
    props.onCountChange(newCount);
  };

  useEffect(() => {
    if (props.getCount !== undefined && props.getCount !== count) {
      setCount(props.getCount);
    }
  }, [props.getCount]);

  useEffect(() => {
    props.onCountChange(count ? count : 0);
  }, [count]);

  return (
    <div
      className={props.extraClass ? props.extraClass : styles.addDaysWrapper}
    >
      <div onClick={handleMinusClick} className={styles.ctrlStylingMinus}>
        -
      </div>
      <div className={styles.inputDiv}>
        <input
          className={styles.inputBox}
          value={count}
          onChange={inputChange}
          type="number"
        />
        <p>{props?.text ? props?.text : "days"}</p>
      </div>
      <div onClick={handlePlusClick} className={styles.ctrlStyling}>
        +
      </div>
    </div>
  );
};
