import React, { PureComponent, useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  Label,
  XAxis,
  YAxis,
  LabelList,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
 
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import NoDatamodel from "../../../generic/noDatamodel/NoDatamodel";
import { noPhoneDataIcon } from "../../../../theme/assets/svg";


function BiaxialBarChart(props) {
  const [data, setData] = useState([]);
  

  const expandedChart = useSelector(
    (store) => store?.analyticsReducer?.expandedChart
  );

  const isSort = useSelector((store) => store?.analyticsReducer?.isSort);

  const sortedChart = useSelector(
    (store) => store?.analyticsReducer?.sortedChart
  );

  let name = props.chartDetails?.keys[0].referenceKeyName;
  let key1 = props.chartDetails?.legendData[0];
  let key2 = props.chartDetails?.legendData[1];
  let value1 = props.chartDetails?.keys[1].referenceKeyName;
  let value2 = props.chartDetails?.keys[2].referenceKeyName;
  let fill1 = props.chartDetails?.keys[1].fillColor;
  let fill2 = props.chartDetails?.keys[2].fillColor;
  let barWidth = parseInt(props.chartDetails?.chartStyle?.width);
  let barHeight = parseInt(props.chartDetails?.chartStyle?.height) - 60;
//   let expandbarHeight = parseInt(props.chartDetails?.expand?.expandStyle?.height)-80;
let barSize = parseInt(props.chartDetails?.chartStyle?.barSize);


useEffect(() => {
    if (props.data) {
      let finalData = props.data.map((each, i) => {
        return {
          name: each[name],
          [key1]: each[value1],
          [key2]: each[value2],
          amt: each[name],
        };
      });
      setData(finalData);
      // setMonth(temp)
    }
  }, [props.data]);

  return data?.length > 0 ? (
    <div
      style={{
        // minWidth: `${data.length * 80}px`,
        height: `${barHeight}px`,
        width: `${barWidth}px`,
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={barWidth}
          height={barHeight}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 15,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" stroke="#000000" style={{textTransform:"capitalize"}}>
            <Label
              // angle={200}
              position="insideBottomMiddle"
              style={{
                textAnchor: "middle",
                fill: "#000000",
                padding: "20px",
                margin: "20px",
              }}
              dy={23}
              offset={-10}
            >
              {props.chartDetails?.keys[0].name}
              {/* {config?.barAttemptChart?.chart?.xAxis} */}
            </Label>
          </XAxis>
          <YAxis yAxisId="left" orientation="left" stroke="#000000">
            <Label
              angle={270}
              offset={20}
              dx={15}
              position="left"
              style={{ textAnchor: "middle", fill: "#000000" }}
            >
              {props.chartDetails?.keys[1].name}
            </Label>
          </YAxis>
          <YAxis yAxisId="right" orientation="right" stroke="#ffffff" />
          <Tooltip />
          <Legend
          verticalAlign="bottom"
          iconType="circle" 
          align="left"
          layout="horizontal"
          />
          <Bar yAxisId="left" dataKey={key1} fill={fill1} barSize={barSize}>
            <LabelList
              dataKey={key1}
              position="top"
              style={{ fontSize: "10px", padding: "20px" }}
            />
          </Bar>
          <Bar yAxisId="right" dataKey={key2} fill={fill2} barSize={barSize}>
            <LabelList
              dataKey={key2}
              position="top"
              style={{ fontSize: "10px", padding: "20px", fill: "#000000" }}
            />
          </Bar>
          
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <div
      className="no-data"
      style={{
        height: `${
         "350px"
        }`,
      }}
    >
      <NoDatamodel srcImg={noPhoneDataIcon}></NoDatamodel>
    </div>
  );
}

export default BiaxialBarChart;
