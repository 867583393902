import moment from "moment";
import React, { useState } from "react";
import {
  copyIconGrey,
  copyBlueFilled,
  agentIcon,
  botIcon,
  tickBlue,
  refreshIconGrey,
  refreshIconBlue,
} from "../../../../../../theme/assets/agentDesktopSvg";

import "./TranscriptHeader.scss";
import NewToolTip from "../../../../generic/newToolTip/NewToolTip";

interface props {
  sessionId: string;
  dateTime: Date;
  botTransferred?: boolean;
  agentTransferred?: boolean;
  tooltipContent: string;
  showRefreshIcon?: boolean;
  handleRefreshClick?: () => void;
}

export default function TranscriptHeader(props: props) {
  /* useStates ------- */
  const [icon, setIcon] = useState(copyIconGrey);
  const [toolTipContentCopyCopy, setToolTipContentCopy] =
    useState("Copy Session ID");
  const [isCopied, setIsCopied] = useState(false);
  const [refreshClicked, setRefreshClicked] = useState(false);

  /* handlers ------ */
  const handleMouseOverCopy = () => !isCopied && setIcon(copyBlueFilled);
  const handleMouseOutCopy = () => !isCopied && setIcon(copyIconGrey);
  const handleRefreshClick = () => {
    props.handleRefreshClick && props.handleRefreshClick();
    setRefreshClicked(true);
    setTimeout(() => setRefreshClicked(false), 2000);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(props.sessionId);
    setToolTipContentCopy("Session ID Copied");
    setIcon(tickBlue);
    setIsCopied(true);
    setToolTipContentCopy("Session ID Copied");
    setTimeout(() => {
      setToolTipContentCopy("Copy Session ID");
      setIcon(copyIconGrey);
      setIsCopied(false);
    }, 1500);
  };

  return (
    <div className="agent-transcript-header">
      <div className="left">
        <span className="left__session-id">{props.sessionId}</span>
        <span
          className="left__copy-icon"
          onMouseOver={handleMouseOverCopy}
          onMouseOut={handleMouseOutCopy}
          onClick={handleCopyClick}
        >
          <NewToolTip content={toolTipContentCopyCopy} direction="right">
            <img src={icon} alt="" />
          </NewToolTip>
        </span>
        {props.showRefreshIcon && (
          <span className="left__refresh-icon" onClick={handleRefreshClick}>
            <NewToolTip content={"Refresh"} direction="right">
              <img
                src={refreshClicked ? refreshIconBlue : refreshIconGrey}
                alt=""
                className={`refresh-icon`}
              />
            </NewToolTip>
          </span>
        )}
      </div>
      <div className="right">
        <span className="transferred-icons">
          {props.agentTransferred && (
            <NewToolTip content={props.tooltipContent}>
              <img src={agentIcon} />
            </NewToolTip>
          )}
          {props.botTransferred && (
            <NewToolTip content={props.tooltipContent}>
              <img src={botIcon} />
            </NewToolTip>
          )}
        </span>
        <span className="date-time">
          {props.dateTime &&
            moment(props.dateTime).format("DD MMM YY | h:mm A")}
        </span>
      </div>
    </div>
  );
}
