// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GEysoa0BCyKgyGzTWkNT{display:flex;justify-content:flex-start;color:var(--primary-color);padding-left:1em}.dlEUwjd5mAcT39h5IKrF{color:inherit;margin:0;margin-left:.5em;margin-top:7px;margin-bottom:7px}.ylMJsYaClVI26xceTiXI{border:.5px solid var(--secondary-grey)}`, "",{"version":3,"sources":["webpack://./src/components/generic/rightSidebar/dropdownFilter/DropdownFilter.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,0BAAA,CACA,0BAAA,CACA,gBAAA,CAEA,sBACE,aAAA,CACA,QAAA,CACA,gBAAA,CACA,cAAA,CACA,iBAAA,CAGF,sBACE,uCAAA","sourcesContent":["\n.titleofilter{\n    display: flex;\n    justify-content: flex-start;\n    color:var(--primary-color);\n    padding-left: 1em;\n    }\n    .titletext{\n      color:inherit;\n      margin: 0;\n      margin-left: .5em;\n      margin-top:7px;\n      margin-bottom: 7px;\n     \n    }\n    .divide{\n      border: .5px solid var(--secondary-grey);\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleofilter": `GEysoa0BCyKgyGzTWkNT`,
	"titletext": `dlEUwjd5mAcT39h5IKrF`,
	"divide": `ylMJsYaClVI26xceTiXI`
};
export default ___CSS_LOADER_EXPORT___;
