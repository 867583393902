export const Colors = [
    "#72B2FF",
    "#B6B6B6",
    "#FFBA93",
    "#93ABFF",
    "#FFE193",
    "#93E5FF",
    "#FF93AD",
    "#C393FF",
    "#9365F6",
    "#45A1F6",
    "#36B9D6",
    "#4EC468",
    "#A8C533",
    "#E8A43D",
    "#E8663D",
    "#F9C74F",
    "#F665AA",
    "#D065F6",
    "#4D5DEE",
    "#d298e2",
    "#6119d0",
  ];
  