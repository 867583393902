import React, { useState, useEffect, useRef } from "react";
import {
  getAllReportCsvData,
  setSelectedIcon,
  refreshAllFilters,
  refreshApi,
  refreshFilters,
  setLastUpdatedTime,
  setSelectedFilterType,
  setShowColumnCustomizePop,
  setShowRightSideBar,
  toggleRefresh,
  toggleShowColumnCustomizePop,
} from "../../../redux/filters/actions";
import RightSidebarpop from "../rightSidebarpop/RightSidebarpop";
import { useDispatch, useSelector } from "react-redux";
import styles from "./RightSidebarComp.module.scss";
import { RootState } from "../../../redux";
import { lastUpdatedText } from "../../../utils/timeAndDate/time.util";
import KPIWrapper from "../kpiWrapper/KPIWapper";
import ColumnCustomizeWrapper from "../../moduleComponents/columCustomize/ColumnCustomizeWrapper";
import ColumnEditName from "../../moduleComponents/columCustomize/columnEditName/ColumnEditName";
import { useCurrentPageTab } from "../../../hooks";
import {
  getMessages,
  getSummaryByAPI,
} from "../../../redux/logger/loggerState/action";
import { getLoggerReportDataAPI } from "../../../redux/logger/report/actions";
import { getAnalyticsAPIData } from "../../../redux/analytics/actions";
import moment from "moment";
import Loader from "../loader/Loader";
import { getDataFromSchema } from "../../../utils";
import { getDayInCorrectFormat } from "../../../utils/ConvertTime";
import { toast, ToastContainer } from "react-toastify";
import { loader } from "../../../theme/assets/svg/rightSideIcon";
import { each } from "lodash";
import screenType from "../../../constants/screenType";
import { channel } from "diagnostics_channel";
import PravidIcons from "../pravidIcons/PravidIcons";
import { Mixpanel } from "../../../utils/mixpanelSetup";
import { format } from "date-fns";
import { someFiltersExist } from "../../../utils/validators/validators";
import { getSideBarModules } from "../../../utils/genericFunctions";
import { ROLES } from "../../../constants";
interface IconObj {
  name: string;
  firstIcon: any;
  secondIcon?: any;
  isActive?: boolean;
}
interface props {
  icons: Array<IconObj>;
}

export default function RightSidebar(props: props) {
  //const [selectedIcon, setSelectedIcon] = useState<string | null>(null);
  const selectedIcon = useSelector((state: RootState) => {
    return state.filterReducers.selectedIcon;
  });
  const show = useSelector(
    (state: RootState) => state.filterReducers.showRightSidePop
  );
  const refP = useRef<any>(null);
  // const iconUrl = useSelector(
  //   (state: RootState) => state?.loginReducer?.userLoginInfo?.iconsBaseUrl
  // );
  const iconUrl = useSelector(
    (state: RootState) => `${process.env.REACT_APP_PRAVID_ICON}`
  );
  // const lastUpdated = useSelector(
  //   (state: RootState) => state.filterReducers.lastUpdated
  // );
  const showPopupForColumnCustomize = useSelector(
    (state: RootState) => state.filterReducers.showColumnCustomizePopup
  );
  const lastUpdated = useSelector(
    (state: RootState) => state.filterReducers.lastUpdatedTime
  );
  const showColumnEditName = useSelector(
    (state: RootState) => state.filterReducers.showEditColumnName
  );
  const isSchemaUpdating = useSelector(
    (state: RootState) => state.loginReducer.gettingClientSchema
  );
  const currentUseCaseName = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseName
  );
  const isReportDownloading = useSelector(
    (state: RootState) => state.filterReducers.isReportDownloading
  );
  const accountDetails = useSelector(
    (store: any) => store.loginReducer?.userLoginInfo?.accountDetails[0]
  );
  const sidebarModuleData = useSelector(
    (store: RootState) => store.loginReducer.sidebarModules.data
  );
  const activeLoggerPage = useSelector(
    (store: RootState) => store.loggerReducer.loggerState.currentPage
  );
  const currentUser = useSelector((state: RootState) => {
    return state.filterReducers.selectedFilterOptions.clientName;
  });
  const accountType = useSelector((state: any) => {
    return state.loginReducer?.userLoginInfo?.accountDetails[0]?.type;
  });
  console.log("hey", accountType);
  const dispatch = useDispatch();
  function handleClickOnIcons(e: IconObj) {
    if (
      (selectedIcon === e.name && show) ||
      e.name === "Last Updated" ||
      e.name === "Shuffle Column"
      // ||
      // (e.name == "Download" &&
      //   isActivePageType !== "Analytics" &&
      //   currentLoggerPage === "Report")
    ) {
      dispatch(setSelectedIcon(""));
      dispatch(setShowRightSideBar(false));
    } else {
      dispatch(setSelectedIcon(e.name));
      dispatch(setShowRightSideBar(true));

      // if(isActivePageType === screenType.logger.name && currentLoggerPage=="Report"&&selectedIcon==="Download"){
      //   dispatch(setShowRightSideBar(false));
      // }
    }

    dispatch(setSelectedFilterType(""));
  }
  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );

  const selectedFilterOptions = useSelector((state: RootState) => {
    return state.filterReducers?.selectedFilterOptions;
  });
  const filterSelectedDate = useSelector(
    (store: RootState) => store?.filterReducers?.calenderData
  );
  const currentSelectedDate = useSelector(
    (store: RootState) => store.filterReducers.calenderData
  );
  const userId = useSelector(
    (state: RootState) => state.loginReducer.userLoginInfo?.userDetail?._id
  );
  const userRole: any = useSelector((state: any) => {
    return state.loginReducer.userLoginInfo.userDetail.role;
  });
  const currentUseCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseId
  );
  const currentTeamId = useSelector(
    (state: RootState) => state.loginReducer.currentTeam.id
  );
  const [showBlue, setShowBlue] = useState<any>(false);
  const {
    schema,
    isActivePageType,
    subChannelTab,
    subModuleTab,
    channelTab,
    currentLoggerPage,
  } = useCurrentPageTab();

  const [directDownload, setDataforoptions] = useState<any>(true);
  useEffect(() => {
    if (sidebarModuleData?.length && isActivePageType && activeLoggerPage) {
      setDataforoptions(
        getSideBarModules(
          sidebarModuleData,
          isActivePageType,
          activeLoggerPage
        )?.find((e: any) => e.name === "Download")?.isDirectdownload
      );
    }
  }, [sidebarModuleData, isActivePageType, activeLoggerPage]);
  /* useEffect */
  useEffect(() => {
    dispatch(setShowRightSideBar(false));
  }, [isActivePageType]);

  // useEffect(() => {
  //   if (
  //     isActivePageType === screenType.logger.name &&
  //     currentLoggerPage == "Report" &&
  //     selectedIcon === "Download"
  //   ) {
  //     dispatch(setShowRightSideBar(false));
  //   }
  // }, [currentLoggerPage, selectedIcon]);

  useEffect(() => {
    dispatch(setShowRightSideBar(false));
  }, [isActivePageType]);

 // for Api call when client change 
 useEffect(
   () => {
     if(currentUser?.[0]?.name?.length){
      dispatch(refreshApi(currentUser?.[0]?.name))
     }
   },[currentUser?.[0]?.name]
 )


  // useEffect(()=>{
  //   if(!isSchemaUpdating){
  //     toast.success("Changes Applied Successfully")
  //   }
  // },[isSchemaUpdating])
  // useEffect(() => {
  //   if (isSchemaUpdating) {
  //     dispatch(setShowRightSideBar(false));
  //     dispatch(setSelectedIcon(""));
  //   }
  // }, [isSchemaUpdating]);

  //   useEffect(() => {
  //     if(calender){
  //        dispatch(refreshAllFilters());
  //    }

  //  }, [ calender]);
  const handleRefresh = (e: any) => {
    // dispatch(setLastUpdatedTime({ time: new Date() }));
    (userRole.toString().toLowerCase() ===
      ROLES.campaignAnalyst.name.toLowerCase() ||
      userRole.toString().toLowerCase() ===
        ROLES.campaignManager.name.toLowerCase()) &&
    accountDetails.type === "Internal" &&
    selectedFilterOptions &&
    selectedFilterOptions["clientName"]?.length > 0
      ? dispatch(refreshApi(selectedFilterOptions["clientName"][0]?.name))
      : dispatch(refreshApi(accountName));

    dispatch(toggleRefresh());
    setShowBlue(true);
    setTimeout(() => {
      setShowBlue(false);
    }, 1000);
    Mixpanel.track("Logger | Transcript | Refresh", {
      "user Name": accountName,
      Date: `${format(new Date(), "dd-MM-yyyy")}`,
      Page: "Transcript",
    });
    // if (isActivePageType === "Analytics") {
    //   if (sourceTab && channelTab) {

    //     dispatch(
    //       getAnalyticsAPIData({
    //         username: accountName,
    //         start_date:moment(filterSelectedDate.startDate).format("YYYY-MM-DD"),

    //         end_date:moment(filterSelectedDate.endDate).format("YYYY-MM-DD"),

    //         source: [sourceTab],
    //         channel: [channelTab],
    //         communicationType:[whatsappChannelTab],
    //       //  ...(channelTab==="WhatsApp"?{communicationType:[whatsappChannelTab]}:{}),

    //         ...selectedFilterOptions,
    //       })
    //     );
    //   }
    // } else {
    //   if (currentLoggerPage === "Report") {

    //     const bodyData = {
    //       clientName: accountName,
    //       dategte: moment(filterSelectedDate.startDate).format("YYYY-MM-DD")+"T00:00:00",
    //       datelte: moment(filterSelectedDate.startDate).format("YYYY-MM-DD")+"T23:59:59",
    //       channel: [channelTab],
    //       source: [sourceTab],
    //       ...(channelTab==="Whatsapp"?{communicationType:[whatsappChannelTab]}:{}),
    //       // page: currentPageNo,
    //       limit: 19,
    //       page:1,
    //       ...selectedFilterOptions,
    //     };
    //     dispatch(getLoggerReportDataAPI(bodyData));
    //   } else {
    //     dispatch(getMessages(conversationId));
    //     dispatch(getSummaryByAPI(conversationId));
    //   }
    // }

    //
  };
  //getting current satus

  const showPopupForColumnCustomization = () => {
    //console.log("CLICKE DON SHIFFLe")
    dispatch(toggleShowColumnCustomizePop());
    //setShowColumnCustomizePop toggleShowColumnCustomizePop
  };
  const handleDirectDownload = () => {
    //dispatch(setShowRightSideBar(false));
    toast.success("Please Wait...Download In Progress");
    dispatch(
      getAllReportCsvData(
        {
          dategte:
            moment(filterSelectedDate.startDate).format("YYYY-MM-DD") +
            "T00:00:00",
          datelte:
            moment(filterSelectedDate.endDate).format("YYYY-MM-DD") +
            "T23:59:59",
          channelId: [channelTab.id],
          source: [currentUseCaseName.split(" ")[0]],
          useCaseId: [currentUseCaseId],
          tabId: [subModuleTab.id],
          channel: [channelTab.name],
          subModule: subModuleTab.name,
          ...(channelTab?.name === "Whatsapp" || channelTab?.name === "Chat"
            ? { subChannelId: [subChannelTab.id] }
            : {}),
          ...(channelTab?.name === "Whatsapp" || channelTab?.name === "Chat"
            ? { communicationType: [subChannelTab.name] }
            : {}),
          userId: userId,
          accountType: accountType,
          teamId: currentTeamId,
          ...selectedFilterOptions,
          clientName:
            (userRole.toString().toLowerCase() ===
              ROLES.campaignAnalyst.name.toLowerCase() ||
              userRole.toString().toLowerCase() ===
                ROLES.campaignManager.name.toLowerCase()) &&
            currentUser?.[0]?.name
              ? currentUser[0].name
              : accountName,
          role: userRole,
        },
        dispatch,
        token
      )
    );
  };
  //  console.log(schema,
  //   sourceTab,
  //   isActivePageType,
  //   whatsappChannelTab,
  //   subModuleTab,
  //   channelTab,
  //   currentLoggerPage,"ACTIVE")

  //console.log(selectedIcon,currentLoggerPage,isReportDownloading,sourceTab,"STATEX")
  return (
    <div id="rightSideBar" ref={refP} className={styles.rightsidebarcontianer}>
      {props.icons.map((e, i) => {
        return (
          <div
            key={i}
            className={styles.iconswrapperrightside + " " + styles.tooltiparea}
            id={"auto_" + e.name?.trim().replace(/\s/g, "")}
          >
            <button
              onClick={() => {
                // if (
                //   e.name == "Download" &&
                //   selectedIcon == "Download" &&
                //   isActivePageType !== "Analytics" &&
                //   currentLoggerPage === "Report" &&
                //   directDownload
                // ) {
                //   dispatch(setSelectedIcon(""));
                //   return;
                // }
                handleClickOnIcons(e);
                e.name === "Last Updated" && handleRefresh(e);
                e.name === "Shuffle Column" &&
                  showPopupForColumnCustomization();
                // e.name === "Download" &&
                //   isActivePageType !== "Analytics" &&
                //   directDownload &&
                //   currentLoggerPage == "Report" &&
                //   handleDirectDownload();
              }}
              className={`${styles.rightsidebaricosn}  ${
                userRole.toString().toLowerCase() ===
                  ROLES.campaignAnalyst.name.toLowerCase() &&
                accountDetails?.type === "Internal" &&
                (e.name === "Shuffle Column" ||
                  e.name == "Download" ||
                  e.name === "KPI customisation")
                  ? "hidePointerEventUniversal"
                  : ""
              }`}
            >
              {e.name == "Filter" &&
                selectedIcon != "Filter" &&
                someFiltersExist(selectedFilterOptions) && (
                  <div className={styles.notificationode}></div>
                )}
              {e.name !== "Last Updated" ? (
                <img
                  src={
                    e.name == "Download" &&
                    // selectedIcon == "Download" &&
                    currentLoggerPage == "Report" &&
                    isActivePageType !== "Analytics"
                      ? isReportDownloading
                        ? loader
                        : `${iconUrl}/${e.firstIcon}.svg${process.env.REACT_APP_CALL_RECORDING_AZURE_TOKEN}`
                      : selectedIcon === e.name
                      ? `${iconUrl}/${e.secondIcon}.svg${process.env.REACT_APP_CALL_RECORDING_AZURE_TOKEN}`
                      : `${iconUrl}/${e.firstIcon}.svg${process.env.REACT_APP_CALL_RECORDING_AZURE_TOKEN}`
                  }
                  alt="io"
                />
              ) : (
                <PravidIcons
                  activeIcon={showBlue ? e.secondIcon : e.firstIcon}
                  alt="alt"
                />
              )}
            </button>
            {e.name === "Last Updated" ? (
              <div className={styles.tooltiptextsp}>
                {lastUpdatedText(new Date(lastUpdated))}
              </div>
            ) : (
              <div
                className={styles.tooltiptext}
                style={e.name === "Calendar" ? { width: "175px" } : {}}
              >
                {e.name === "Shuffle Column"
                  ? "Customize Table"
                  : e.name === "Calendar"
                  ? getDayInCorrectFormat(currentSelectedDate)
                  : e.name === "KPI customisation"
                  ? "KPI Selection"
                  : e.name}
              </div>
            )}
          </div>
        );
      })}

      <RightSidebarpop
        selectedIcon={selectedIcon}
        setSelectedIcon={(e: any) => {
          dispatch(setSelectedIcon(e));
        }}
        ref={refP}
      />

      {showPopupForColumnCustomize && <ColumnCustomizeWrapper />}
      {showColumnEditName && <ColumnEditName />}
      {isSchemaUpdating && (
        <div className={styles.loader}>
          <div>
            <Loader></Loader>
          </div>
        </div>
      )}
    </div>
  );
}
