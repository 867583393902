import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { TEAMS_PAGE_API, config ,PRICING_PLAN_API } from "../../../../services/ApiRoutes";
import actionTypes from "../teamsPage.actionTypes";
import loginActionTypes from "../../../onboarding/login/actionTypes/login.actionTypes";
import { setCurrentTeam } from "../../../profile/navigation/action/navigation.action";
import { store } from "../../../rootStore";
import _ from "lodash";

export function* getAllTeamsDataAPIWorker(action: any): any {
  const teamData = store.getState().loginReducer.teamData.data;
  // toast.loading("Please wait ...");
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        `${TEAMS_PAGE_API.GET_ALL_TEAMS}`,
        action.payload
      );
      yield put({
        type: actionTypes.GET_ALL_TEAMS_DATA_SUCCESS,
        payload: result.data.data,
      });

      // setting teamData of profile dropdown
      if (!_.isEqual(teamData, result.data.data)) {
        yield put({
          type: loginActionTypes.GET_TEAM_DATA_SUCCESS,
          payload: result.data.data,
        });

        let teamExist;
        const selectedTeam = store.getState().loginReducer.currentTeam;
        if (selectedTeam?.id) {
          teamExist = result.data.data.find(
            (item: Record<string, any>) => item.id === selectedTeam.id
          );
        }
        if (result.data.data.length > 0 && !teamExist) {
          const team = result.data.data[0];
          yield put(setCurrentTeam(team));
        }
      }
    }
    //toast.dismiss();
  } catch (error) {
    yield put({
      type: actionTypes.GET_ALL_TEAMS_DATA_FAILURE,
      payload: error,
    });
    yield put({ type: loginActionTypes.GET_TEAM_DATA_FAILURE, payload: error });

    console.error(error);
  }
}

export function* getAllRolesDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        `${TEAMS_PAGE_API.GET_ALL_ROLES}`,
        action.payload
      );
      yield put({
        type: actionTypes.GET_ALL_ROLES_DATA_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_ALL_ROLES_DATA_FAILURE,
      payload: error,
    });
  }
}

export function* updateTeamDataAPIWorker(action: any): any {
  const {
    userId,
    isNameUpdate,
    isRoleUpdate,
    isStatusUpdate,
    teamMemberName,
    ...updatedPayload
  } = action.payload;
  const teamData = store.getState().loginReducer.teamData.data;
  if (action?.payload?.isNameUpdate || action?.payload?.isRoleUpdate) {
    toast.dismiss();
    toast.loading("Please wait ...");
  }
  try {
    if (action) {
      const result = yield call(
        config.PUT_WITH_BODY,
        `${TEAMS_PAGE_API.UPDATE_PRODUCT_TEAM}`,
        updatedPayload
      );

      const getResult = yield call(
        config.GET_WITH_PARAMS,
        `${TEAMS_PAGE_API.GET_ALL_TEAMS}`,
        { userId: action.payload.userId }
      );

      yield put({
        type: actionTypes.UPDATE_PRODUCT_PORTFOLIO_TEAM_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: actionTypes.GET_ALL_TEAMS_DATA_SUCCESS,
        payload: getResult.data.data,
      });
      // setting teamData of profile dropdown
      if (!_.isEqual(teamData, getResult.data.data)) {
        yield put({
          type: loginActionTypes.GET_TEAM_DATA_SUCCESS,
          payload: getResult.data.data,
        });

        let teamExist;
        const selectedTeam = store.getState().loginReducer.currentTeam;
        if (selectedTeam?.id) {
          teamExist = getResult.data.data.find(
            (item: Record<string, any>) => item.id === selectedTeam.id
          );
        }
        if (getResult.data.data.length > 0 && !teamExist) {
          const team = getResult.data.data[0];
          yield put(setCurrentTeam(team));
        }
      }
      yield put({
        type: actionTypes.SELECTED_TEAM_DETAILS,
        payload: result.data.data,
      });

      if (action?.payload?.isNameUpdate) {
        toast.dismiss();
        toast.success("Team Name Updated Successfully");
      } else if (action?.payload?.isRoleUpdate) {
        toast.dismiss();
        toast.success(`${teamMemberName}'s Role Updated Successfully`);
      }
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.UPDATE_PRODUCT_PORTFOLIO_TEAM_FAILURE,
      payload: error?.response?.data?.error,
    });

    if (action?.payload?.isNameUpdate) {
      toast.dismiss();
      toast.error(error?.response?.data?.error);
    } else if (action?.payload?.isRoleUpdate) {
      toast.dismiss();
      toast.error(`${teamMemberName}'s Role Updation Unsuccessful`);
    } else {
      if (teamMemberName?.length > 0) {
        toast.dismiss();
        toast.error(`${teamMemberName} not removed successfully`);
      }
    }
    console.error(error);
  }
}

export function* getTeamMemberDataAPIWorker(action: any): any {
  // toast.loading("Please wait ...");
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        `${TEAMS_PAGE_API.GET_TEAM_MEMBER_DATA}`,
        action.payload
      );
      yield put({
        type: actionTypes.GET_TEAM_MEMBER_DATA_SUCCESS,
        payload: result.data.data,
      });
    }
    // toast.dismiss();
  } catch (error) {
    yield put({
      type: actionTypes.GET_TEAM_MEMBER_DATA_FAILURE,
      payload: error,
    });
    // toast.dismiss();
    console.error(error);
  }
}

export function* updateInviteMemberOnPlanAPIWorker(action: any): any {
  // toast.loading("Please wait ...");
  try {
    if (action) {
      console.log("jai ho" , action.payload.header,
      action.payload.body)
      const result = yield call(
        config.PUT_WITH_HEADER_BODY,
        `${PRICING_PLAN_API.UPDATE_INVITEMEMBER_STATUS}${action.payload.id}`,
        action.payload.body,
        action.payload.header
      );
      yield put({
        type: actionTypes.UPDATE_INVITE_MEMBER_ON_PLAN_SUCCESS,
        payload: result.data.data,
      });
    }
    // toast.dismiss();
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_INVITE_MEMBER_ON_PLAN_FAILURE,
      payload: error,
    });
    // toast.dismiss();
    console.error(error);
  }
}
