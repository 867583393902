import React, { useEffect, useState } from "react";
import style from "./WhatsAppSchedulerPage.module.scss";
import languageIcon from "../../../../../theme/assets/svg/demo/template.svg";
import campaignTypeicon from "../../../../../theme/assets/svg/demo/communicationIcon.svg";
import dateRangeIcon from "../../../../../theme/assets/svg/demo/clockOmni.svg";
import optionListIcon from "../../../../../theme/assets/svg/campaign/dropdownOptionIcon.svg";
import MultiSelectDropdown from "../../../campaign/omniChannel/multiDropdownOmni/MultiSelectDropdown";
import DateFilter from "../../../../generic/datefilter/DateFilter";
import downArrow from "../../../../../theme/assets/svg/campaign/dropdownIconDown.svg";
import TimePicker from "../../../../generic/timepicker/TimePicker";
import moment from "moment";
import Button from "../../../../generic/button/Button";
import { useSelector, useDispatch } from "react-redux";
import dateActive from "../../../../../theme/assets/svg/demo/dateIcon.svg";
import clockActive from "../../../../../theme/assets/svg/demo/clockActive.svg";

import { RootState } from "../../../../../redux";
import {
  getTemplatesDropdown,
  storeSedulerSettingDataMail,
  storeSedulerSettingDataSms,
} from "../../../../../redux/omniChannel/actions/omniChannel.actions";

function WhatsAppSchedulerPage(props: any) {
  const [selectTemplateId, setSelectTemplateId] = useState();
  const [templateDropdownArray, SetTemplateDropDownArray] = useState([]);

  // const storedDataScheduler = useSelector(
  //   (store: RootState) =>
  //     store?.omniChannelReducer?.storedschedulerSettingSmsAndMail
  // );

  const [selectTemplate, setSelectTemplate] = useState(
    props.storedDataScheduler?.template
      ? props.storedDataScheduler?.template
      : ""
  );
  const [flowsSelected , setFlowsSelected] = useState<any>([])

  useEffect(() => {
    setSelectTemplate(props.storedDataScheduler?.template);
  }, [props.storedDataScheduler]);

  useEffect(
    () => {
      if(props.whatsAppFlowUnique?.length){
        setFlowsSelected(props.whatsAppFlowUnique)
      }
    },[props?.whatsAppFlowUnique]
  )


  const reduxClientNameDropdown = useSelector(
    (store: RootState) => store?.campaignReducer?.campaignClientName
  );

  const accountName = useSelector(
    (store: RootState) => store?.loginReducer.userLoginInfo
  );

  const templatesDropDown = useSelector(
    (store: any) => store?.omniChannelReducer?.getDropdownTemplates
  );
  const mainClientName = accountName?.userDetail?.accountDetails[0]?.name;

  const [selectCommunication, setSelectCommunication] = useState(
   "Two way"
  );


  const dispatch = useDispatch();
  
  const langOptions = {
    imgSrcRight: downArrow,
    imgSrcleft: "",
    // placeHolderText:  selectedLanguage?.length ?  selectedLanguage?.[0]+(selectedLanguage?.length>1 ? ("+".concat(selectedLanguage?.length-1)) : ""): "Select"
    placeHolderText: selectTemplate?.length
      ? selectTemplate?.toString()?.length > 16
        ? selectTemplate[0].length > 16
          ? selectTemplate[0].substring(0, 16) + "..."
          : selectTemplate[0]
        : selectTemplate
      : "Select Template",
  };
  const flowOptions = {
    imgSrcRight: downArrow,
    imgSrcleft: "",
    // placeHolderText:  selectedLanguage?.length ?  selectedLanguage?.[0]+(selectedLanguage?.length>1 ? ("+".concat(selectedLanguage?.length-1)) : ""): "Select"
    placeHolderText: flowsSelected?.length
      ? flowsSelected?.toString()?.length > 16
        ? flowsSelected[0]?.length > 16
          ? flowsSelected[0].substring(0, 16) + "..."
          : flowsSelected[0]
        : flowsSelected
      : "Select Flows",
  };

  const onChangeselectTemplate = (item: any) => {
    setSelectTemplate(item);
    // props.onChangeTemplate(item);
  };

  const onChangeFlows = (item: any) => {
    setFlowsSelected(item)
    // props.onChangeTemplate(item);
  };
  const onChangeCommunication = (data: any) => {
    // setSelectCommunication((prev: any) => data);
  };

  //useEffects
  useEffect(() => {
    if (templatesDropDown?.length > 0) {
      const arr = templatesDropDown?.map(
        (e: any) => e?.templates?.templateName
      );
      SetTemplateDropDownArray((prev: any) => arr);
    } else {
      SetTemplateDropDownArray([]);
      // if (selectTemplate) return;
      // setSelectTemplate("");
    }
  }, [templatesDropDown]);

  useEffect(() => {
    if (selectTemplate) {
      const idArray = templatesDropDown
        ?.map((e: any) => {
          if (selectTemplate?.includes(e?.templates?.templateName)) {
            return e?.id; 
          }
        })
        ?.filter((e: any) => e);
      setSelectTemplateId((prev: any) => idArray);
      props.onClickTemplateId(idArray)
    }
  }, [selectTemplate]);

  // useEffect(() => {
  //   setSelectTemplate(props.template);
  // }, [props.template]);



  useEffect(() => {
    if(flowsSelected?.length){
      triggerApi();
    }
  }, [flowsSelected?.length]);


 console.log('hello' , props.whatsAppFlowUnique)

  const triggerApi = () => {
    const data = {
      channel: "Whatsapp",
       "msgType": "Two Way",
       language: props?.campaignData?.language,
       flow: {maiaFlow : flowsSelected},
      accountName:
        reduxClientNameDropdown && reduxClientNameDropdown !== "Select All"
          ? reduxClientNameDropdown
          : mainClientName,
    };
    // alert(0);
    if (selectCommunication ) {
      // alert(1);
      getTemplatesDropdown(data)(dispatch);
    }
  };

  return (
    <div className={style.schedulerOmniSMSWrapperModal}>
      <div className={style.OuterDiv}>
        <div className={style.ContainDiv}>
          <div className={style.logoNameDiv}>
            <img src={campaignTypeicon} width={"50px"} alt="img"></img>
            <h2 className={style.heading}>Communication</h2>
          </div>
          <div className={style.dropDownDiv}>
            <div
              className={
                selectCommunication == "One way"
                  ? style.staticDiv
                  : style.staticDivHuman
              }
              onClick={() => onChangeCommunication("One way")}
            >
              <p>One way</p>
            </div>
            <div
              className={`
                ${
                  selectCommunication == "Two way"
                    ? style.staticDiv
                    : style.staticDivHuman
                } ${style.gapBtn}
              `}
              // onClick={() => onChangeCommunication("Two way")}
            >
              <p>Two way</p>
            </div>
          </div>
        </div>
        <div className={style.ContainDiv}>
          <div className={style.logoNameDiv}>
            <img src={languageIcon} width={"50px"} alt="img"></img>
            <h2 className={style.heading}>Flows</h2>
          </div>
          <div className={style.dropDownDiv}>
            <MultiSelectDropdown
              options={flowOptions}
              toBeFilterData={props.whatsAppFlowUnique ? props.whatsAppFlowUnique : []}
              extraSelectedClass={style.languageDropdown}
              getFilteredData={(value: any) => onChangeFlows(value)}
              key="dispositionMultiSelectOne"
              selectedDataOutside={flowsSelected}
              extraPlaceHolderStyle={style.extraPlaceHolderStyle}
              filterDataTobeSelected={style.filterDataTobeSelected}
              imgList={optionListIcon}
            />
          </div>
        </div>
        <div className={style.ContainDiv}>
          <div className={style.logoNameDiv}>
            <img src={languageIcon} width={"50px"} alt="img"></img>
            <h2 className={style.heading}>Template</h2>
          </div>
          <div className={style.dropDownDiv}>
            <MultiSelectDropdown
              options={langOptions}
              toBeFilterData={templateDropdownArray}
              extraSelectedClass={style.languageDropdown}
              getFilteredData={(value: any) => onChangeselectTemplate(value)}
              key="dispositionMultiSelectOne"
              selectedDataOutside={selectTemplate}
              extraPlaceHolderStyle={style.extraPlaceHolderStyle}
              filterDataTobeSelected={style.filterDataTobeSelected}
              imgList={optionListIcon}
            />
          </div>
        </div>
       
        <div className={style.ContainDiv}>
          <div className={style.logoNameDiv}>
            <img src={dateRangeIcon} width={"50px"} alt="img"></img>
            <h2 className={style.heading}>When to Schedule</h2>
          </div>
          <div className={style.dropDownDiv}>
            <div className={`${style.midDate}  ${style.timeclr}`}>
              <img src={dateActive} />
              &nbsp;&nbsp;
              {moment(props.nudgeScheduleData?.start_date_range).format("MMM DD") +
                " - " +
                moment(props.nudgeScheduleData?.end_date_ranfge).format("MMM DD")}
            </div>
            {/* <div className={`${style.midDate} ${style.gapBtn} ${style.timeclr}`}>
              <img src={clockActive} />
              &nbsp;
              {props?.time?.hour + " " + ":" + " " + props?.time?.minute}{" "}
              {props?.time?.am ? "AM" : "PM"}
            </div> */}
          </div>
        </div>
        
      </div>
      {/* 
      {loadingTemplates ? (
        <PopUpImg>
          {" "}
          <LoaderSaarthi />{" "}
        </PopUpImg>
      ) : (
        ""
      )} */}

      {/* {true ?  */}

      <div className={style.buttonSubmit}>
        <Button
          text="Schedule"
          extraClass={style.submitOmni}
          onClick={() => {
            props.onSubmit();
          }}
        
        />
      </div>
    </div>
  );
}

export default WhatsAppSchedulerPage;
