import React, { useEffect, useState } from "react";
import styles from "./ToggleComponent.module.scss";

interface toggleProps {
  options: string[];
  getSelectedData: CallableFunction;
  outSideSelected?: string;
  extraClassOuterDiv?: string;
  extraBtnClass?: string;
}
export const ToggleComponent = ({
  options,
  getSelectedData,
  outSideSelected,
  extraClassOuterDiv,
  extraBtnClass,
}: toggleProps) => {
  const [active, setActive] = useState(options[0]);

  const setActiveButton = (data: string) => {
    setActive(data);
  };

  useEffect(() => {
    getSelectedData(active);
  }, [active]);

  useEffect(() => {
    if (outSideSelected) {
      setActiveButton(outSideSelected);
    }
  }, [outSideSelected]);

  function capitalize(s: string) {
    return s[0].toUpperCase() + s.slice(1);
  }

  return (
    <div className={styles.toggleButtonWrapper}>
      <div
        className={`${extraClassOuterDiv ? extraClassOuterDiv : ""} ${
          styles.toggleButtonOuterDiv
        }`}
      >
        <div
          className={`${
            active == options[0] ? styles.activeDiv : styles.notActiveDiv
          } ${extraBtnClass ? extraBtnClass : styles.buttonStyling}`}
          onClick={() => setActiveButton(options[0])}
          id={`auto_${options[0]}`}
        >
          <p> {capitalize(options[0])}</p>
        </div>
        <div
          className={`${
            active == options[1] ? styles.activeDiv : styles.notActiveDiv
          }  ${extraBtnClass ? extraBtnClass : styles.buttonStyling}`}
          onClick={() => setActiveButton(options[1])}
          id={`auto_${options[1]}`}
        >
          <p> {capitalize(options[1])}</p>
        </div>
      </div>
    </div>
  );
};
