import React, { useEffect, useState } from "react";
import styles from "./ProductPortflio.module.scss";
import Icon from "../../../../generic/icon/Icon";
import Button from "../../../../generic/button/Button";
import { noProductIcon } from "../../../../../theme/assets/svg";
import { ProductCreated } from "./productCreated/ProductCreated";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { RootState } from "../../../../../redux";
import { updateUserData } from "../../../../../redux/onboarding/login/actions";
import {
  addProductToPortfolio,
  currentProductData,
  createProductTeams,
  getAllProductTypesData,
} from "../../../../../redux/signUpFlow/productPage/actions/productPage.actions";
import { updateTourModuleWise } from "../../../../../redux/signUpFlow/productTour/actions";

// const productPortflios: any = [
//   {
//     productType: "Loan shgdjhsnhdgjhgfkdgfuodfgodfgoghu",
//     useCase: "sbdkjsbnsgdksgdousdyosdyj",
//   },
//]

export const ProductPortfolio = () => {
  const [portfolio, setPortfolio] = useState<object[]>([]);
  const [isDeleted, setIsDeleted] = useState(false);

  const productPortflios = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productPageReducer?.productPortfolioDetails
  );

  const currentProduct = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productPageReducer?.currentProductDetails
  );

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const userId = useSelector(
    (state: RootState) => state.loginReducer.userLoginInfo.userDetail?._id
  );

  const isPortfolioCreated = useSelector(
    (state: RootState) =>
      state.signUpModuleReducer?.productPageReducer?.productPortfolioTeams
  );

  const productsData = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productPageReducer?.productDetails
  );

  const isPortfolioLoading = useSelector(
    (state: RootState) =>
      state.signUpModuleReducer?.productPageReducer
        ?.isLoadingProductPortfolioTeams
  );

  const loginUserId = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.userDetail?._id
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteProduct = (data: any) => {
    const index = portfolio.indexOf(data);
    if (index > -1) {
      portfolio.splice(index, 1);
      setPortfolio(portfolio);
      setIsDeleted(true);
      dispatch(addProductToPortfolio(portfolio));
    }
  };

  const handleSavePortfolio = () => {
    const result = productPortflios?.map((data: any, index: number) => {
      return {
        accountId: accountId,
        userId: userId,
        domainId: data?.domain?.id,
        domainName: data?.domain?.name,
        productId: data?.product?.id,
        productName: data?.product?.name,
        productTypeId: data?.productType?.id,
        productTypeName: data?.productType?.type,
        useCaseId: data?.useCase?.id,
        useCaseName: data?.useCase?.useCasename,
        teamMembers: [userId],
        position: index + 1,
        isActive: true,
      };
    });
    dispatch(createProductTeams(result));
    // dispatch(addProductToPortfolio([]));
    dispatch(currentProductData({ domain: currentProduct?.domain }));
    const data = {
      id: accountId,
      isProductPageShown: false,
    };
    dispatch(updateUserData(data));

    const body = {
      userId: loginUserId,
      isProductPageShown: false,
    };
    dispatch(updateTourModuleWise(body));
  };

  useEffect(() => {
    if (isDeleted) {
      if (productPortflios.length == 0) {
        dispatch(
          currentProductData({
            domain: currentProduct?.domain,
            product: productsData?.[0],
          })
        );
        dispatch(getAllProductTypesData({ productId: productsData?.[0]?.id }));
      }
      setPortfolio(portfolio);
      setIsDeleted(false);
    }
  }, [isDeleted]);

  useEffect(() => {
    setPortfolio(productPortflios ? productPortflios : []);
  }, [productPortflios]);

  useEffect(() => {
    if (isPortfolioCreated?.message?.length > 0) {
      // navigate("/teamsPage");
      navigate("/userlist");
    }
  }, [isPortfolioCreated]);

  return (
    <div className={styles.productPortflioWrapper}>
      <div className={styles.productPortfolioHeading}>Product Portfolio</div>

      {portfolio.length > 0 ? (
        <div className={styles.productPortfolioWithContentDiv}>
          {portfolio?.map((data: any, index: number) => {
            return (
              <div key={index}>
                <ProductCreated
                  productCreatedData={data}
                  productCreatedIndex={index}
                  onClickDeleteProduct={() => handleDeleteProduct(data)}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles.productPortfolioNoContentDiv}>
          <Icon
            img_src={noProductIcon}
            extraClass={styles.noProductIconStyling}
            id={"auto_noProductIcon_icon"}
          />
          <div className={styles.selectProductText}>
            Select product and usecase
          </div>
        </div>
      )}

      <div className={styles.productPortfolioButton}>
        <Button
          text={"Proceed"}
          extraClass={`${styles.proceedButtonStyling} ${productPortflios?.length == 0 || isPortfolioLoading
            ? ""
            : styles.proceedActiveButtonStyling
            }`}
          id="auto_product_proceed_btn"
          onClick={handleSavePortfolio}
          disabled={
            productPortflios?.length == 0 || isPortfolioLoading ? true : false
          }
        />
      </div>
    </div>
  );
};
