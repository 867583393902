import React from "react";

import Moment from "moment";
import downloadBtn from "../../../../../theme/assets/svg/campaign/campaignDownload.svg";
import mapingIcon from "../../../../../theme/assets/svg/campaign/mappingIcon.svg";
import downloadGreen from "../uploadedinfo/downloadGreenIcon.svg";
import DownloadRed from "../uploadedinfo/downloadRed.svg";

// This is the table constant/settings which needed to render table elements
export const callDetailsTableConstants = (
  downloadcsvFunc,
  downloadIdFun,
  tomap,
  accountType,
  breadcrumName,
  accountName,
  module,
  isMaiaAccount,
  teamUseCaseId
) => {
  const toDownloadCsv = (url, name) => {
    downloadcsvFunc(url, name);
  };
  const downloadId = (url, name) => {
    downloadIdFun(url, name);
  };
  const mappin = (data, rowdata) => {
    tomap(data, rowdata);
  };

  return [
    {
      title: "Mode",
      render: (rowData, indx, pageNo) => {
        return <span id="auto_uploaded_file_mode">{rowData?.mode}</span>;
      },
    },
    {
      title: "File Name",
      render: (rowData) => {
        return (
          <>
            <span id="auto_uploaded_file_name">
              {rowData?.uploadCsvFileName[0]}
            </span>
          </>
        );
      },
    },
    {
      title: "",
      render: (rowData) => {
        return (
          <>
            {" "}
            <img
              className="download-btn"
              src={downloadBtn}
              onClick={() =>
                toDownloadCsv(rowData?.validatedFile, rowData?.fileName)
              }
              id="auto_uploaded_file_download_btn"
            />
          </>
        );
      },
    },

    {
      title: "Uploaded On",
      render: (rowData) => {
        return (
          <div>
            <p>
              <div id="auto_uploaded_file_created_at">
                {Moment.utc(rowData?.createdAt).format("DD MMM YY")}
              </div>
              <div
                style={{ fontSize: "0.75vmax" }}
                id="auto_uploaded_file_created_at_local"
              >
                {Moment.utc(rowData?.createdAt).local().format("h:mma")}
              </div>
            </p>
          </div>
        );
      },
    },
    {
      title: "",
      render: (rowData) => {
        let pathData =
          rowData?.validatedFile
            ? rowData.validatedFile
            : rowData?.downloadablePath
            ? [rowData.downloadablePath]
            : "";
        return (
          accountType != "External" &&
          (rowData?.area=== "Payment" || !isMaiaAccount ) &&
          (rowData?.area=== "Payment" ||teamUseCaseId !== "639053fb52d33c75af54947a" )&&
          (rowData?.area=== "Payment" || teamUseCaseId !== "6451009fa3029a066498a246")
           &&
          rowData?.area != "Dunning" &&
        
          !module && (
            <>
              <img
                className={`mapBtn ${
                  rowData.status === "Completed" ||
                  rowData.status == "Partial Completed" ||
                  rowData.status == "Failed"
                    ? "mapBtnDis"
                    : ""
                }`}
                src={mapingIcon}
                onClick={() => mappin(pathData, rowData)}
                id="auto_uploaded_file_maping_icon"
              />
            </>
          )
        );
      },
    },
    {
      title: "",
      render: (rowData) => {
        return (
          <>
            {" "}
            {(rowData.status === "Completed" &&
              rowData?.area === "Allocation") ||
            (rowData.status === "Completed" && rowData?.area === "Dunning") ? (
              <img
                className="download-btn2"
                src={downloadGreen}
                onClick={() =>
                  downloadId(rowData?.downloadablePath, rowData?.fileName)
                }
                id="auto_uploaded_file_download_green_icon"
              />
            ) : (rowData.status === "Partial Completed" &&
                rowData?.area === "Allocation") ||
              (rowData.status === "Failed" && rowData?.area === "Allocation") ||
              (rowData.status === "Partial Completed" &&
                rowData?.area === "Dunning") ||
              (rowData.status === "Failed" && rowData?.area === "Dunning") ? (
              <img
                className="download-btn2"
                src={DownloadRed}
                onClick={() =>
                  downloadId(rowData?.downloadablePath, rowData?.fileName)
                }
                id="auto_uploaded_file_download_red_icon"
              />
            ) : (
              ""
            )}{" "}
          </>
        );
      },
    },
  ];
};
