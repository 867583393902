import { Reducer } from "redux";
import actionTypes from "../teamsPage.actionTypes";

export interface stateInterface {
  teamsDetails: any;
  isLoadingTeamsDetails: boolean;
  teamsDetailsError: any;
  updateTeamDetails: any;
  isLoadingUpdateTeamDetails: boolean;
  updateTeamDetailsError: any;
  selectedTeamDetails: any;
  sendInviteAPI: {
    data: Record<string, any>;
    isLoading: boolean;
    error: Record<string, any>;
  };
  rolesDetails: any;
  isLoadingRolesDetails: boolean;
  rolesDetailsError: any;
  teamMemberDetails: any;
  isLoadingTeamMemberDetails: boolean;
  teamMemberDetailsError: any;
  isTeamPageVisited: boolean;
  updateInviteMemberOnPlan: {
    data: any;
    isLoading: boolean;
    error: any;
  };
}

export const initialState: stateInterface = {
  teamsDetails: [],
  isLoadingTeamsDetails: false,
  teamsDetailsError: "",
  updateTeamDetails: {},
  isLoadingUpdateTeamDetails: false,
  updateTeamDetailsError: "",
  selectedTeamDetails: {},
  sendInviteAPI: { data: {}, isLoading: false, error: {} },
  rolesDetails: [],
  isLoadingRolesDetails: false,
  rolesDetailsError: "",
  teamMemberDetails: [],
  isLoadingTeamMemberDetails: false,
  teamMemberDetailsError: "",
  isTeamPageVisited: false,
  updateInviteMemberOnPlan : { data: [], isLoading: false, error: {} },
};

const teamsPageReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.GET_ALL_TEAMS_DATA_REQUEST: {
      return {
        ...state,
        teamsDetails: [],
        isLoadingTeamsDetails: true,
        teamsDetailsError: "",
      };
    }
    case actionTypes.GET_ALL_TEAMS_DATA_SUCCESS: {
      const objects = action.payload;
      objects.sort((a: any, b: any) => {
        return (
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
        );
      });
      return {
        ...state,
        teamsDetails: objects,
        isLoadingTeamsDetails: false,
        teamsDetailsError: "",
      };
    }
    case actionTypes.GET_ALL_TEAMS_DATA_FAILURE: {
      return {
        ...state,
        teamsDetails: [],
        teamsDetailsError: action.payload,
        isLoadingTeamsDetails: false,
      };
    }

    case actionTypes.GET_TEAM_MEMBER_DATA_REQUEST: {
      return {
        ...state,
        teamMemberDetails: [],
        isLoadingTeamMemberDetails: true,
        teamMemberDetailsError: "",
      };
    }
    case actionTypes.GET_TEAM_MEMBER_DATA_SUCCESS: {
      return {
        ...state,
        teamMemberDetails: action.payload !== null ? action.payload : [],
        isLoadingTeamMemberDetails: false,
        teamMemberDetailsError: "",
      };
    }
    case actionTypes.GET_TEAM_MEMBER_DATA_FAILURE: {
      return {
        ...state,
        teamMemberDetails: [],
        isLoadingTeamMemberDetails: false,
        teamMemberDetailsError: action.payload,
      };
    }

    case actionTypes.GET_ALL_ROLES_DATA_REQUEST: {
      return {
        ...state,
        rolesDetails: [],
        isLoadingRolesDetails: true,
        rolesDetailsError: "",
      };
    }
    case actionTypes.GET_ALL_ROLES_DATA_SUCCESS: {
      return {
        ...state,
        rolesDetails: action.payload,
        isLoadingRolesDetails: false,
        rolesDetailsError: "",
      };
    }
    case actionTypes.GET_ALL_ROLES_DATA_FAILURE: {
      return {
        ...state,
        rolesDetails: [],
        rolesDetailsError: action.payload,
        isLoadingRolesDetails: false,
      };
    }

    case actionTypes.UPDATE_PRODUCT_PORTFOLIO_TEAM_REQUEST: {
      return {
        ...state,
        updateTeamDetails: {},
        isLoadingUpdateTeamDetails: true,
        updateTeamDetailsError: "",
      };
    }
    case actionTypes.UPDATE_PRODUCT_PORTFOLIO_TEAM_SUCCESS: {
      return {
        ...state,
        updateTeamDetails: action.payload,
        isLoadingUpdateTeamDetails: false,
        updateTeamDetailsError: "",
      };
    }
    case actionTypes.UPDATE_PRODUCT_PORTFOLIO_TEAM_FAILURE: {
      return {
        ...state,
        updateTeamDetails: {},
        updateTeamDetailsError: action.payload,
        isLoadingUpdateTeamDetails: false,
      };
    }

    case actionTypes.SELECTED_TEAM_DETAILS: {
      return {
        ...state,
        selectedTeamDetails: action.payload,
      };
    }
    /* send invite------- */
    case actionTypes.SEND_INVITE_TEAM_SETUP_REQUEST: {
      return {
        ...state,
        sendInviteAPI: {
          ...state.sendInviteAPI,
          isLoading: true,
          data: {},
          error: {},
        },
      };
    }
    case actionTypes.SEND_INVITE_TEAM_SETUP_SUCCESS: {
      return {
        ...state,
        sendInviteAPI: {
          ...state.sendInviteAPI,
          isLoading: false,
          data: action.payload,
        },
      };
    }
    case actionTypes.SEND_INVITE_TEAM_SETUP_FAILURE: {
      return {
        ...state,
        sendInviteAPI: {
          ...state.sendInviteAPI,
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.CLEAR_TEAMS_DATA: {
      return { ...initialState };
    }

    case actionTypes.IS_TEAM_PAGE_VISITED: {
      return { ...state, isTeamPageVisited: action.payload };
    }

    case actionTypes.UPDATE_INVITE_MEMBER_ON_PLAN_REQUEST: {
      return {
        ...state,
        updateInviteMemberOnPlan :  { data: [], isLoading: true, error: {} },
      };
    }
    case actionTypes.UPDATE_INVITE_MEMBER_ON_PLAN_SUCCESS: {
      return {
        ...state,
        updateInviteMemberOnPlan :  { data: action.payload, isLoading: false, error: {} },
      };
    }
    case actionTypes.UPDATE_INVITE_MEMBER_ON_PLAN_FAILURE: {
      return {
        ...state,
        updateInviteMemberOnPlan :  { data:[], isLoading: false, error:  action.payload },
      };
    }

    default: {
      return state;
    }
  }
};

export default teamsPageReducer;
