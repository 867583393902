import React, { useState } from "react";
import styles from "./ProductDetail.module.scss";
import { caratFdown } from "../../../../theme/assets/svg";
interface prop {
  data: Array<{ displayName: string; value: string; key: string }>;
}
export default function ProductDetail(props: prop) {
  const [show, setShow] = useState<boolean>(false);
  return (
    <div className={styles.wrapper}>
      {!show
        ? props.data.map((each, index) => {
            if (index < 7)
              return (
                <div className={styles.eachAttribute} key={index}>
                  <p className={styles.eachkey}>{each.displayName}:</p>
                  <p className={styles.eachvalue}>
                    {each.displayName.toLowerCase().includes("amount") ? (
                      <>&#8377;{each.value}</>
                    ) : (
                      each.value
                    )}
                  </p>
                </div>
              );
          })
        : props.data.map((each, index) => {
            if (index >= 7)
              return (
                <div className={styles.eachAttribute} key={index}>
                  <p className={styles.eachkey}>{each.displayName}:</p>
                  <p className={styles.eachvalue}>
                    {each.displayName.toLowerCase().includes("amount") ? (
                      <>&#8377;{each.value}</>
                    ) : (
                      each.value
                    )}
                  </p>
                </div>
              );
          })}
      <div className={styles.tooglesections} onClick={() => setShow(!show)}>
        <img
          className={`${show ? styles.rotate : ""}`}
          src={caratFdown}
          alt=""
        />
      </div>
    </div>
  );
}
