import React, { useCallback, useEffect, useRef, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import NudgeCanvasScreen from "../nudgeCanvas/NudgeCanvasScreen";
import EachNudgeRightSidePop from "../../../components/moduleComponents/rightSidebarStrategy/EachNudgeRightSidePop";
import { ArcherContainer, ArcherElement } from "react-archer";
import RightsideStrategy from "../../../components/moduleComponents/rightsideStrategy/RightsideStrategy";
import RightsideBig from "../../../components/moduleComponents/rightSideBig/RightsideBig";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { ClipEffect } from "html2canvas/dist/types/render/effects";
import { isSorted, warn } from "../../../utils/validators/validators";
import { toast } from "react-toastify";

function NudgeCanvas() {
  const ref = useRef<any>();
  const renderFunc = () => {
    ref?.current?.refreshScreen();
    setTimeout(() => {
      ref?.current?.refreshScreen(), 3000;
    });
  };
  const allData = useSelector(
    (state: RootState) => state.strategyModuleReducer?.nudgeReducer.allNudgeDetail
  );
  useEffect(() => {
    warn(allData);
  }, [allData]);
  const handleClickAnyWhere = useCallback(
    (e: any) => {
      if (e.keyCode == 9) e.preventDefault();
    },
    [allData]
  );
  useEffect(() => {
    //tab key changing the ui design/it prevents unexpected behaviour
    window.addEventListener("keydown", handleClickAnyWhere);

    return () => window.removeEventListener("keydown", handleClickAnyWhere);
  }, [allData, handleClickAnyWhere]);
  return (
    <DndProvider backend={HTML5Backend}>
      {/* <ArcherContainer strokeColor="red" ref={ref}> */}
      <div style={{ width: "96%" }}>
        <NudgeCanvasScreen render={renderFunc} />
      </div>
      {/* </ArcherContainer> */}
      {/*This component for Single Nudge*/}
      <EachNudgeRightSidePop />
      <div style={{ width: "4%" }}>
        { /*Fixed RightSide Bar*/}
        <RightsideStrategy />
      </div>
      {/*condition based Rendering for Save and All Edits */}
      <RightsideBig />
    </DndProvider>
  );
}

export default NudgeCanvas;
