import React, { useCallback, useEffect, useRef } from "react";
import styles from "./RightSideBig.module.scss";
import { gohide } from "../../../theme/assets/svg";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import {
  setSelectedItemOnRight,
  setShowRightsideAll,
  setIsBackIconClicked,
} from "../../../redux/strategy/eachNudgeBox/actions/actionCreators";
import EditAllNudge from "../editAllNudge/EditAllNudge";
import SaveSaveAs from "../saveSaveAs/SaveSaveAs";
import { setClearSelectedNudgeData } from "../../../redux/strategy/eachNudgeBox/actions/actionCreators";

export default function RightsideBig() {
  const dispatch = useDispatch();
  const refD = useRef<any>();
  const showRight = useSelector(
    (state: RootState) =>
      state.strategyModuleReducer?.individualNudgeReducer.showRight
  );

  const selectedItem = useSelector(
    (state: RootState) =>
      state.strategyModuleReducer?.individualNudgeReducer.itemSelected
  );

  const isBackFromScriptSelect = useSelector((store: any) => {
    return store.strategyModuleReducer?.individualNudgeReducer.isBackBtnClicked;
  });

  function handleClickGoRight() {
    dispatch(setShowRightsideAll(false));
    dispatch(setSelectedItemOnRight(""));
    dispatch(setClearSelectedNudgeData());
  }
  const handleClickAnyWhere = useCallback(
    (e: any) => {
      if (refD.current && !refD.current?.contains(e.target))
        if (!isBackFromScriptSelect) {
          dispatch(setShowRightsideAll(false));
          dispatch(setClearSelectedNudgeData());
        } else {
          dispatch(setIsBackIconClicked(false));
        }
    },
    [showRight, refD, isBackFromScriptSelect]
  );

  useEffect(() => {
    if (showRight) {
      window.addEventListener("click", handleClickAnyWhere);
    } else {
      window.removeEventListener("click", handleClickAnyWhere);
    }
    return () => window.removeEventListener("click", handleClickAnyWhere);
  }, [showRight, handleClickAnyWhere]);

  return (
    <div
      className={`${styles.wrapperclass_popup}  ${!showRight ? styles.goright : ""
        }`}
      ref={refD}
    >
      <div className={styles.divgoright}>
        <div onClick={handleClickGoRight} className={styles.gorighticon}>
          <img src={gohide} alt=">" id="auto_gohide" />
        </div>
      </div>

      {selectedItem == "save" ? (
        <div className={styles.title_top}>
          <SaveSaveAs />
          {/* <div className={styles.t23}></div> */}
        </div>
      ) : selectedItem == "edit" ? (
        <EditAllNudge />
      ) : selectedItem == "setting" ? (
        <div className={styles.title_top}>
          <p>Workspace setting</p>
          <div></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
