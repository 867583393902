import { gridColumnGroupsUnwrappedModelSelector } from "@mui/x-data-grid"
import { useEffect, useRef, useState } from "react"
import Button from "../../../generic/button/Button"
import style from "./ManyFileStatus.module.scss"

interface props {
  data?: Array<any>
  errorName?: string
  errorNote?: string
  failMessage?: any
  fileName?: string
  status?: string
  outsideClose?: boolean
}
function ManyFileStatus(props: props) {
  const [open, setOpen] = useState(false)
  //close outside 

  useEffect(
    () => {
      console.log("hey", props?.outsideClose)
      props?.outsideClose && setOpen(props?.outsideClose)
    }, [props?.outsideClose]
  )



  const ref: any = useRef()
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {

      if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false)
      }
    }
    document.addEventListener("click", checkIfClickedOutside)
    return () => {
      document.removeEventListener("click", checkIfClickedOutside)
    }
  }, [open, ref])
  return (
    <div className={style.manyUploadFilewrapper} ref={ref} onClick={() => { setOpen(!open) }} >
      <div className={style.nameButtonContainer}  >
        <span className={style.filename}>{props.fileName}</span>
        <span>
          <Button
            text={props.failMessage?.length > 0 ? "Error" : "Success"}
            onClick={() => { setOpen(!open) }}
            extraClass={`${style.buttonStyle} ${props.failMessage?.length > 0 ? style.error : ""}`}
          />
        </span>
      </div>
      {
        open &&
        <div className={style.infoContainer}>
          {props.failMessage && (
            <div className={style.popUpModalMessage}>
              <span className={style.failerReson}>Failure Reason : </span>
              {`${props.failMessage?.[0]?.data || ""}`}
              <span className={style.failerReson}>
                - {props.failMessage?.[0]?.message}
              </span>
            </div>
          )}
          {props?.data &&
            props?.data?.map((e: any, i: any) => {
              return (
                <div className={style.popUpModalMsgData} key={i}>
                  <p className={style.firstPara}>{e?.name}</p>
                  <p className={style.MsgDataDiffer}>:</p>
                  <p>{e?.value}</p>
                </div>
              );
            })}
          {(props?.errorName) && (
            <div className={style.popUpModalErrorName}>
              {" "}
              <p>{`${props?.errorName ? props?.errorName : " "}`} </p>
              <p className={style.popUpModalErrorNote}>
                {props?.errorNote ? props?.errorNote : ""}
              </p>
            </div>
          )}
        </div>
      }
    </div>
  )
}

export default ManyFileStatus