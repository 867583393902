/**
 * This is used to get value from nested object with dot separated keys eg. (information.call.[duration])
 * This is same as getValueByReferenceKey (function) used when last key is inside array eg (information.call.[duration])
 * @param {object} value
 * @param {strings} referenceKey
 * @param {strings} returnDefault
 * @returns {string}
 */

export const getValueByReferenceKey2 = (
  value: any,
  referenceKey: string,
  returnDefault?: string
) => {
  try {
    const referenceKeyArr: Array<string> = referenceKey.split(".");
    let lastKey: string = referenceKeyArr.pop() || "";
    lastKey = lastKey?.substring(1, lastKey.length - 1);
    referenceKeyArr.forEach((item) => {
      value = value?.[item];
    });
    return value?.[lastKey];
  } catch (err) {
    return returnDefault || "";
  }
};
