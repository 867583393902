import { getValueByReferenceKey2 } from "../../../../utils";

export const getSummaryDataEditableFunction = (
  summarySchema: Record<string, any>,
  summaryData: any
) => {
  const result: Record<string, any> = {};
  summarySchema.forEach((item: any) => {
    if (item.type !== "fieldHeader") {
      result[item.data.backEndKey || item.data.referenceKeyName] =
        getValueByReferenceKey2(
          summaryData,
          item.data.referenceKeyName?.substring(8),
          ""
        ) ||
        getValueByReferenceKey2(
          summaryData,
          item.data.referenceKeyName2?.substring(8),
          ""
        ) ||
        "";
    }
  });
  if (summaryData?.information?.chatObjective)
    result["chatObjective"] = summaryData?.information?.chatObjective;
  return result;
};
