// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UQS0Gpr1sISSp8JTCzjj{margin-top:-10px;transition:all .5s ease-out;transform-origin:top}.IqiwKQexd_XenceL7hfw{transform:scaleY(0);display:none}.yH1ucp6W4ma38mMjy9TA input[type=password]{font-size:21px;color:var(--grey2);background-color:rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/screens/onBoarding/createPassword/CreatePassword.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,2BAAA,CACA,oBAAA,CAGF,sBACE,mBAAA,CACA,YAAA,CAEF,2CACE,cAAA,CACA,kBAAA,CACA,8BAAA","sourcesContent":[".password-validation {\n  margin-top: -10px;\n  transition: all 0.5s ease-out;\n  transform-origin: top;\n}\n\n.hide__password-validation {\n  transform: scaleY(0);\n  display: none;\n}\n.passwordMaskClass input[type=\"password\"] {\n  font-size: 21px;\n  color: var(--grey2);\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"password-validation": `UQS0Gpr1sISSp8JTCzjj`,
	"hide__password-validation": `IqiwKQexd_XenceL7hfw`,
	"passwordMaskClass": `yH1ucp6W4ma38mMjy9TA`
};
export default ___CSS_LOADER_EXPORT___;
