import React, { useEffect, useState } from "react";
import style from "./SmsSchedulerPage.module.scss";
import "../configurecampaign/ConfigureCampaign.css";
import languageIcon from "../../../../theme/assets/svg/demo/template.svg";
import campaignTypeicon from "../../../../theme/assets/svg/demo/communicationIcon.svg";
import dateRangeIcon from "../../../../theme/assets/svg/demo/clockOmni.svg";
import optionListIcon from "../../../../theme/assets/svg/campaign/dropdownOptionIcon.svg";
import MultiSelectDropdown from "../../../moduleComponents/campaign/omniChannel/multiDropdownOmni/MultiSelectDropdown";
import DateFilter from "../../../generic/datefilter/DateFilter";
import downArrow from "../../../../theme/assets/svg/campaign/dropdownIconDown.svg";
import TimePicker from "../../../generic/timepicker/TimePicker";
import moment from "moment";
import Button from "../../../generic/button/Button";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../../../../redux";
import {
  getTemplatesDropdown,
  storeSedulerSettingDataMail,
  storeSedulerSettingDataSms,
} from "../../../../redux/omniChannel/actions/omniChannel.actions";

function SmsSchedulerPage(props: any) {
  const [selectTemplateId, setSelectTemplateId] = useState();
  const [isShowSummaryPage, setIsShowSummaryPage] = useState();
  const [showMindMap, setMindMap] = useState(false);
  const [wpdataCampaign, setWpdataCampaign]: any = useState();
  const [templateDropdownArray, SetTemplateDropDownArray] = useState([]);

  // const storedDataScheduler = useSelector(
  //   (store: RootState) =>
  //     store?.omniChannelReducer?.storedschedulerSettingSmsAndMail
  // );

  const [selectTemplate, setSelectTemplate] = useState(
    props.storedDataScheduler?.template
      ? props.storedDataScheduler?.template
      : ""
  );

  useEffect(() => {
    setSelectTemplate(props.storedDataScheduler?.template);
  }, [props.storedDataScheduler]);

  const campaignWhatsAppData = useSelector(
    (store: RootState) => store?.campaignReducer?.campaignAllCampaignChannelData
  );

  const reduxClientNameDropdown = useSelector(
    (store: RootState) => store?.campaignReducer?.campaignClientName
  );

  const accountName = useSelector(
    (store: RootState) => store?.loginReducer.userLoginInfo
  );

  const templatesDropDown = useSelector(
    (store: any) => store?.omniChannelReducer?.getDropdownTemplates
  );

  const dateData = useSelector(
    (store: RootState) => store?.filterReducer?.filteredDateRangeData
  );

  const storedDetails = useSelector((store: RootState) => {
    return store.campaignReducer.campaignWhatsappSchedulerData;
  });

  let minMapImg = useSelector(
    (store: any) => store?.omniChannelReducer?.getMindmap?.azureLink
  );

  const mainClientName = accountName?.userDetail?.accountDetails[0]?.name;

  const [selectCommunication, setSelectCommunication] = useState(
    props.storedDataScheduler?.communication
      ? props.storedDataScheduler?.communication
      : "One way"
  );

  const [time, setTime] = useState(
    props.storedDataScheduler?.time ? props.storedDataScheduler?.time : ""
  );

  const setTimeData = (value: any) => {
    setTime((prev: any) => value);
  };

  const dispatch = useDispatch();

  const langOptions = {
    imgSrcRight: downArrow,
    imgSrcleft: "",
    // placeHolderText:  selectedLanguage?.length ?  selectedLanguage?.[0]+(selectedLanguage?.length>1 ? ("+".concat(selectedLanguage?.length-1)) : ""): "Select"
    placeHolderText: selectTemplate?.length
      ? selectTemplate?.toString()?.length > 16
        ? selectTemplate[0].length > 16
          ? selectTemplate[0].substring(0, 16) + "..."
          : selectTemplate[0]
        : selectTemplate
      : "Select Template",
  };
  const format1 = "h:mm a";
  const now = moment().hour(0).minute(0);

  const onChangeselectTemplate = (item: any) => {
    setSelectTemplate(item);
    // props.onChangeTemplate(item);
  };

  const onChangeCommunication = (data: any) => {
    // if (selectCommunication == data) {
    //   setSelectCommunication((prev: any) => null);
    // } else {
    setSelectCommunication((prev: any) => data);
    // }
  };

  //useEffects
  useEffect(() => {
    if (templatesDropDown?.length > 0) {
      const arr = templatesDropDown?.map(
        (e: any) => e?.templates?.templateName
      );
      SetTemplateDropDownArray((prev: any) => arr);
    } else {
      SetTemplateDropDownArray([]);
      // if (selectTemplate) return;
      // setSelectTemplate("");
    }
  }, [templatesDropDown]);

  useEffect(() => {
    if (selectTemplate) {
      const idArray = templatesDropDown
        ?.map((e: any) => {
          if (selectTemplate?.includes(e?.templates?.templateName)) {
            return e?.id;
          }
        })
        ?.filter((e: any) => e);
      setSelectTemplateId((prev: any) => idArray);
    }
  }, [selectTemplate]);

  // useEffect(() => {
  //   setSelectTemplate(props.template);
  // }, [props.template]);

  useEffect(() => {
    if (campaignWhatsAppData) {
      campaignWhatsAppData?.map((e: any) => {
        if (e?.channels?.includes("SMS") || e?.channels?.includes("Mail")) {
          setWpdataCampaign((prev: any) => e);
        }
      });
    }
  }, [campaignWhatsAppData]);

  useEffect(() => {
    if (langOptions.placeHolderText === "Select Template") return;
    triggerApi();
  }, []);

  useEffect(() => {
    triggerApi();
  }, [wpdataCampaign, selectCommunication]);

  useEffect(() => {
    const obj = {
      communication: selectCommunication,
      template: selectTemplate,
      startDate:
        // storedDataScheduler?.startDate
        //   ? storedDataScheduler?.startDate
        //   :
        dateData?.fromDate,
      endDate:
        // storedDataScheduler?.endDate
        //   ? storedDataScheduler?.endDate
        //   :
        dateData?.toDate,
      templatesId: selectTemplateId,
      time: time,
      mindMapLink: minMapImg,
    };
    // alert(1);
    if (props.channel === "SMS") storeSedulerSettingDataSms(obj)(dispatch);
    else if (props.channel === "Mail")
      storeSedulerSettingDataMail(obj)(dispatch);
  }, [
    selectCommunication,
    selectTemplate,
    dateData,
    selectTemplateId,
    time,
    minMapImg,
    storedDetails,
  ]);

  useEffect(() => {
    if (storedDetails) {
      const dialTimeData = storedDetails.dialTimeData.data;
      setSelectTemplateId((prev: any) => dialTimeData.templates);
      minMapImg = dialTimeData?.mindmap_url ? dialTimeData.mindmap_url : "";
    }
  }, [storedDetails]);

  useEffect(() => {
    if (props.channel === "SMS") storeSedulerSettingDataSms({})(dispatch);
    else if (props.channel === "Mail")
      storeSedulerSettingDataMail({})(dispatch);
  }, []);

  const triggerApi = () => {
    const data = {
      channel: props.channel,
      //       "language": ["English"],
      //      "flow": {
      //     "Pre-Due": [],
      //     "Post-Due": ["DPD 1-7","DPD 8-15","DPD 30+","NPA","DPD 30-60"]
      //  },
      //  "msgType": "One Way",
      // "accountName": "Kreditbee Debt Testing"
      language: wpdataCampaign?.language,
      flow: wpdataCampaign?.flowType,
      msgType: selectCommunication,
      accountName:
        reduxClientNameDropdown && reduxClientNameDropdown !== "Select All"
          ? reduxClientNameDropdown
          : mainClientName,
    };
    // alert(0);
    if (selectCommunication && wpdataCampaign?.flowType) {
      // alert(1);
      getTemplatesDropdown(data)(dispatch);
    }
  };

  return (
    <div className={style.schedulerOmniSMSWrapperModal}>
      <div className={style.OuterDiv}>
        <div className={style.ContainDiv}>
          <div className={style.logoNameDiv}>
            <img src={campaignTypeicon} width={"50px"} alt="img"></img>
            <h2 className={style.heading}>Communication</h2>
          </div>
          <div className={style.dropDownDiv}>
            <div
              className={
                selectCommunication == "One way"
                  ? style.staticDiv
                  : style.staticDivHuman
              }
              onClick={() => onChangeCommunication("One way")}
            >
              <p>One way</p>
            </div>
            <div
              className={`
                ${
                  selectCommunication == "Two way"
                    ? style.staticDiv
                    : style.staticDivHuman
                } ${style.gapBtn}
              `}
              // onClick={() => onChangeCommunication("Two way")}
            >
              <p>Two way</p>
            </div>
          </div>
        </div>
        <div className={style.ContainDiv}>
          <div className={style.logoNameDiv}>
            <img src={languageIcon} width={"50px"} alt="img"></img>
            <h2 className={style.heading}>Template</h2>
          </div>
          <div className={style.dropDownDiv}>
            <MultiSelectDropdown
              options={langOptions}
              toBeFilterData={templateDropdownArray}
              extraSelectedClass={style.languageDropdown}
              getFilteredData={(value: any) => onChangeselectTemplate(value)}
              key="dispositionMultiSelectOne"
              selectedDataOutside={selectTemplate}
              extraPlaceHolderStyle={style.extraPlaceHolderStyle}
              filterDataTobeSelected={style.filterDataTobeSelected}
              imgList={optionListIcon}
            />
          </div>
        </div>
        <div className={style.ContainDiv}>
          <div className={style.logoNameDiv}>
            <img src={dateRangeIcon} width={"50px"} alt="img"></img>
            <h2 className={style.heading}>When to Schedule</h2>
          </div>
          <div className={style.dropDownDiv}>
            <div className={style.midDate}>
              <DateFilter
                id="dateRangeOne"
                dateHeader={"show"}
                //   hideBtnUp= {btnUploadHide}
                schedulerFilter={true}
                disableRangeMin={true}
                typeText="campaign"
              />
            </div>
            <div
              className={`${style.midDate} ${style.gapBtn}`}
              //  sendTime={(value)=>{setTimeData(value)}}
            >
              <TimePicker
                twentyFourHour={false}
                sendTime={(value: any) => {
                  setTimeData(value);
                  props.onChangeTime(value);
                }}
                isWhatsapp={false}
                dataOutside={props.storedDataScheduler?.time}
                channelData={props.storedDataScheduler}
                hideBorder={style.hideborderTimer}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 
      {loadingTemplates ? (
        <PopUpImg>
          {" "}
          <LoaderSaarthi />{" "}
        </PopUpImg>
      ) : (
        ""
      )} */}

      {/* {true ?  */}

      <div className={style.buttonSubmit}>
        <Button
          text="Submit"
          extraClass={style.submitOmni}
          onClick={() => {
            props.onSubmit();
          }}
          disabled={langOptions.placeHolderText === "Select Template"}
        />
      </div>

      {/* //    : (
    //     <div className={style.buttonSubmit2}> </div>
    //   )} */}
    </div>
  );
}

export default SmsSchedulerPage;
