import { newReportIssueState } from "../types";

import NEW_REPORT_ISSUES_ACTION from "../reportIssue.actionTypes";
export default function newReportIssueReducer(
  state: newReportIssueState = {
    data: [],
    updateReportIssueId: "",
    loading: false,
  },
  action: any
) {
  switch (action.type) {
    case NEW_REPORT_ISSUES_ACTION.CREATE_REPORT_ISSUES_API: {
      return { ...state, loading: true };
    }
    case NEW_REPORT_ISSUES_ACTION.SET_REPORT_ISSUES: {
      return { ...state, data: action.payload, loading: false };
    }
    case NEW_REPORT_ISSUES_ACTION.SET_EMPTY_ISSUE: {
      return { data: [], updateReportIssueId: "", loading: false };
    }
    case NEW_REPORT_ISSUES_ACTION.REPORT_ISSUE_API_CALL: {
      return { ...state, loading: true };
    }
    case NEW_REPORT_ISSUES_ACTION.REPORT_ISSUE_API_SUCCESS: {
      return { ...state, loading: false };
    }
    case NEW_REPORT_ISSUES_ACTION.REPORT_ISSUE_API_FAILURE: {
      return { ...state, loading: false };
    }
    default: {
      return state;
    }
  }
}
