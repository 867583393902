// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vEg2do7DJIwyYujW85hw{display:flex;flex-direction:row;align-items:center;width:90%;min-width:154px;min-height:45px;padding:.5em;align-items:center;border-radius:8px;border:.5px solid #c4c4c4;background:#fff;margin:0 auto}.vEg2do7DJIwyYujW85hw .avem_jBN8hfR5_tXuP83{width:2em}.vEg2do7DJIwyYujW85hw .K3eZjbKgngXnvVzinood{color:#333;font-family:"Roboto";font-size:14px;font-style:normal;font-weight:400;line-height:20px;padding-left:1em}.hSN5sskRghFg2Rae9jCG{border:1px solid #0051b3 !important}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/campaign_V2.0/dataUploadScreen/methodDiv/Method.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,SAAA,CACA,eAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,yBAAA,CACA,eAAA,CACA,aAAA,CAEA,4CACE,SAAA,CAGF,4CACE,UAAA,CACA,oBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,gBAAA,CAIJ,sBACE,mCAAA","sourcesContent":[".methodWrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 90%;\n  min-width: 154px;\n  min-height: 45px;\n  padding: 0.5em;\n  align-items: center;\n  border-radius: 8px;\n  border: 0.5px solid #c4c4c4;\n  background: #fff;\n  margin: 0 auto;\n\n  .methodIcon {\n    width: 2em;\n  }\n\n  .methodHeader {\n    color: #333;\n    font-family: \"Roboto\";\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 20px;\n    padding-left: 1em;\n  }\n}\n\n.methodSelectedWrapper {\n  border: 1px solid #0051b3 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"methodWrapper": `vEg2do7DJIwyYujW85hw`,
	"methodIcon": `avem_jBN8hfR5_tXuP83`,
	"methodHeader": `K3eZjbKgngXnvVzinood`,
	"methodSelectedWrapper": `hSN5sskRghFg2Rae9jCG`
};
export default ___CSS_LOADER_EXPORT___;
