import { Reducer } from "redux";
import actionType from "../schedularSetting.actionTypes";
import { StateInterface } from "../interface";

const initialState: StateInterface = {
  schedularDetails: [],
  isLoadingSchedularDetails: false,
  schedularDetailsError: "",
  getSchedularDetails: [],
  isLoadingGetSchedularDetails: false,
  getSchedularDetailsError: "",
};

const schedulerSettingReducer: Reducer<StateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionType.SCHEDULAR_DATA_API_REQUEST: {
      return {
        ...state,
        isLoadingSchedularDetails: true,
        schedularDetailsError: "",
      };
    }

    case actionType.SCHEDULAR_DATA_API_SUCCESS: {
      return {
        ...state,
        isLoadingSchedularDetails: false,
        schedularDetails: action.payload,
      };
    }

    case actionType.SCHEDULAR_DATA_API_FAILURE: {
      return {
        ...state,
        isLoadingSchedularDetails: false,
        schedularDetailsError: action.payload,
      };
    }

    case actionType.GET_SCHEDULAR_DATA_API_REQUEST: {
      return {
        ...state,
        isLoadingGetSchedularDetails: true,
        getSchedularDetailsError: "",
      };
    }
    case actionType.GET_SCHEDULAR_DATA_API_SUCCESS: {
      return {
        ...state,
        isLoadingGetSchedularDetails: false,
        getSchedularDetails: action.payload,
      };
    }

    case actionType.GET_SCHEDULAR_DATA_API_FAILURE: {
      return {
        ...state,
        isLoadingGetSchedularDetails: false,
        getSchedularDetailsError: action.payload,
      };
    }

    case actionType.CLEAR_SCHEDULAR_DATA:
      return initialState;

    default: {
      return state;
    }
  }
};

export default schedulerSettingReducer;
