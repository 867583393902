// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wecj8zW8XvuKRzAcvXNO{width:3.5em;position:relative}.wecj8zW8XvuKRzAcvXNO .lzR6lpjo6wAm3CrsR63R{background:rgba(94,99,102,.0784313725);display:flex;justify-content:space-around;align-items:center;border-radius:4px;color:#8c8e97;padding:3px;cursor:pointer}.wecj8zW8XvuKRzAcvXNO .SpTZqAYBBisAw8bEsSbt{position:absolute;border-radius:3px}.wecj8zW8XvuKRzAcvXNO .SpTZqAYBBisAw8bEsSbt div{background:rgba(113,117,119,.0784313725);width:3.5em;color:#8c8e97;text-align:left;cursor:pointer}.wecj8zW8XvuKRzAcvXNO .SpTZqAYBBisAw8bEsSbt div p{margin-left:.6em}.wecj8zW8XvuKRzAcvXNO .SpTZqAYBBisAw8bEsSbt div:hover{background:rgba(29,34,36,.0784313725)}.wecj8zW8XvuKRzAcvXNO .qwBY9eKkkUMnoswZ6LE1{bottom:0}`, "",{"version":3,"sources":["webpack://./src/components/crm/moduleComponent/dropdownThin/DropDownThin.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,iBAAA,CACA,4CACE,sCAAA,CACA,YAAA,CACA,4BAAA,CACA,kBAAA,CACA,iBAAA,CACA,aAAA,CACA,WAAA,CACA,cAAA,CAEF,4CACE,iBAAA,CAEA,iBAAA,CACA,gDACE,wCAAA,CACA,WAAA,CACA,aAAA,CACA,eAAA,CACA,cAAA,CACA,kDACE,gBAAA,CAEF,sDACE,qCAAA,CAIN,4CACE,QAAA","sourcesContent":[".wrapper {\n  width: 3.5em;\n  position: relative;\n  .dd_tip {\n    background: #5e636614;\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    border-radius: 4px;\n    color: hsl(230, 5%, 57%);\n    padding: 3px;\n    cursor: pointer;\n  }\n  .wrapper_list {\n    position: absolute;\n\n    border-radius: 3px;\n    div {\n      background: #71757714;\n      width: 3.5em;\n      color: hsl(230, 5%, 57%);\n      text-align: left;\n      cursor: pointer;\n      p {\n        margin-left: 0.6em;\n      }\n      &:hover {\n        background: #1d222414;\n      }\n    }\n  }\n  .openup {\n    bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wecj8zW8XvuKRzAcvXNO`,
	"dd_tip": `lzR6lpjo6wAm3CrsR63R`,
	"wrapper_list": `SpTZqAYBBisAw8bEsSbt`,
	"openup": `qwBY9eKkkUMnoswZ6LE1`
};
export default ___CSS_LOADER_EXPORT___;
