import React, { useEffect, useState } from "react";
import styles from "./DataUploadComp.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import { useNavigate, useLocation } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Button from "../../../../generic/button/Button";
import { MethodDiv } from "../methodDiv/MethodDiv";
import { UploadComponent } from "../uploadComponent/UploadComponent";
import { UploadedDataTable } from "../uploadedDataTable/UploadedDataTable";
import backIcon from "../../../../../theme/assets/svg/campaign/backicon.svg";
import Icon from "../../../../generic/icon/Icon";
import {
  api,
  manualCsv,
} from "../../../../../theme/assets/svg/campaign_v3/index";
import {
  postUploadedCSVFile,
  postPaymentUploadedCSVFile,
  getUploadedCSVInfoAPIData,
  getCSVAPIData,
  getCSVSalesForceData,
  storeSelectedMethod,
  postSelectedCSVData,
  postSelectedAPICSVData,
  setSelectedCSVAPIData,
  postDunningCSVFile,
} from "../../../../../redux/campaign_V2.0/dataUpload/actions";
import {
  sampleCsvDownload,
  sampleCsvPaymentDownload,
} from "../../../../../redux/campaign/actions";
import { PaymentStatusUpdate } from "../../../../../utils/file/index";
import { downloadCsvFile } from "../../../../../utils/downloadCsvFile";
import PopUpModal from "../../../campaign/popUpMoadal/PopUpModal";
import Modal from "../../../../generic/modal/Modal";
import ManyFileStatus from "../../manyFileUploadStatus/ManyFileStatus";
import { CrossIcon } from "../../../../../theme/assets/genericSvg";
import { style } from "@mui/system";

interface MethodInfo {
  name: string;
  value: string;
  icon: string;
}

interface FileData {
  uploadCsvFileName: string[];
  uploadCsvPath: string[];
  validatedFile:string;
  updatedAt: string;
  failureReason: string[];
  totalCount: number;
  id: string;
  area: string;
  campaignManagerId?: string;
  strategyId?: string;
  isSheetError: boolean;
  isFilePicked: boolean;
  personas: any;
  language: any;
  flowType: any;
  isPaymentLink: boolean;
  requestId: any;
  mode:string;
}

interface TabInfo {
  name: string;
  position: number;
  area: string;
  details: any;
}

export const DataUploadComp = () => {
  const location = useLocation();
  const methodInfo: MethodInfo[] = location.pathname?.includes("strategy/dataupload") ? [{
    name: "Manual .CSV",
    value: "Manual",
    icon: manualCsv,
  },] : [
    {
      name: "Manual .CSV",
      value: "Manual",
      icon: manualCsv,
    },
    { name: "API", value: "API", icon: api },
    // { name: "Salesforce", value: "Salesforce", icon: api },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openMultiFileNumber, setOpenMultiFileNumber] = useState(0)
  const [isStrategyDataUpload, setIsStrategyDataUpload] = useState(false);
  const [tabInfo, setTabInfo] = useState<TabInfo[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [method, setSelectedMethod] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<any | null>(null);
  const [selectedCSV, setSelectedCSV] = useState<FileData[]>([]);
  const [isCSVValidToSend, setIsCSVValidToSend] = useState(false);
  const [showPopUpAfterUploadError, setShowPopUpAfterUploadError] =
    useState<any>({
      show: false,
      data: {},
    });

  console.log(tabInfo, "tabsssss");

  const [showPopUpAfterUploadSuccess, setShowPopUpAfterUploadSuccess] =
    useState<any>({
      show: false,
      data: {},
    });
  const [showPopUpAfterCheck, setShowPopUpAfterCheck] = useState<any>({
    show: false,
    data: {},
  });
  const [showPopUpMany, setShowPopUpMany] = useState<any>({
    show: false,
    data: [],
  });

  const accountDetails = location.pathname?.includes("/strategy/dataupload")
    ? useSelector(
      (store: RootState) =>
        store?.loginReducer?.userLoginInfo?.accountDetails?.[0]
    )
    : useSelector(
      (store: RootState) => store?.schedulerReducer?.accountInfoDetails
    );

  const teamUseCaseId = useSelector(
    (store: RootState) => store.loginReducer?.currentTeam?.useCaseId
  );

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const campaignData = useSelector((store: RootState) => {
    return store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo;
  });

  const strategyId = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.createdStrategy?.id
  );
  const stepsTotal = accountDetails?.campaignProgressBar;

  const {
    manualCSVData: { status: isLoadingFile },
    uploadedCSVData: { data: uploadedCSVInfo, status: isLoadingUploadedData },
    selectedInfo: selectedData,
    apiCSVData: { data: apiDataUploadInfoObj },
    salesforceCSVData: { data: salesforceDataUploadInfoObj },
    selectedCSVData: { status: isSendingCSV, isNavigate },
  } = useSelector(
    (store: RootState) => store.campaignModuleReducer.dataUploadData
  );

  const selectedCSVData = useSelector(
    (store: RootState) =>
      store.campaignModuleReducer.dataUploadData?.selectedCSVData
  );

  /**Pop Up function**/
  const handleShowPopUp = (data: any) => {
    console.log("hello buddy", data)
    if (data?.length == 1 && data?.[0]?.errorRespose?.length) {
      setShowPopUpAfterUploadError({
        show: true,
        data: data?.[0]?.errorRespose?.[0],
      });
    } else if (data?.length == 1 && data?.[0]?.popUpDetails?.length) {
      console.log("heloo", data)
      setShowPopUpAfterUploadSuccess({
        show: true,
        data: data?.[0],
      });
    }
    else if (data?.length > 1) {
      console.log("heloo", data)
      setShowPopUpMany({
        show: true,
        data: data
      });
    }

  };

  /**show pop up on check  */
  const handleShowCheckError = (data: any) => {
    console.log("hellooooo", data);
    if (data?.popUpDetails?.length) {
      setShowPopUpAfterCheck({
        show: true,
        data: data,
      });
    }
  };

  const handleClosePopUpError = () => {
    setShowPopUpAfterUploadError({
      show: false,
      data: {},
    });
  };
  const handleClosePopSuccess = () => {
    setShowPopUpAfterUploadSuccess({
      show: false,
      data: [],
    });
  };
  const handleClosePopUpCheck = () => {
    setShowPopUpAfterCheck({
      show: false,
      data: {},
    });
  };
  const handleClosePopUpMany = () => {
    setShowPopUpMany({
      show: false,
      data: [],
    });
  };

  const handleFileSelection = (file: any) => {
    setSelectedFile(file);
    const area = tabInfo?.[selectedData?.selectedType]?.area?.toLowerCase();
  
    if (area === "payment") {
      dispatch(
        postPaymentUploadedCSVFile({
          file: file,
          area: tabInfo?.[selectedData?.selectedType]?.area,
          id: campaignData?.id,
          accountId: accountDetails?.id,
          token: token,
          strategyId: strategyId,
          isStrategy: isStrategyDataUpload,
          accountType: accountDetails?.type,
        })
      );
    } else if (area === "dunning") {
      dispatch(
        postDunningCSVFile({
          file: file,
          area: tabInfo?.[selectedData?.selectedType]?.area,
          id: campaignData?.id,
          accountId: accountDetails?.id,
          token: token,
          strategyId: strategyId,
          isStrategy: isStrategyDataUpload,
          accountType: accountDetails?.type,
          func: handleShowPopUp,
        })
      );
    } else {
      dispatch(
        postUploadedCSVFile({
          file: file,
          area: tabInfo?.[selectedData?.selectedType]?.area,
          id: campaignData?.id,
          strategyId: strategyId,
          isStrategy: isStrategyDataUpload,
          accountType: accountDetails?.type,
          token: token,
          accountId: accountDetails?.id,
          useCaseId: teamUseCaseId,
          func: handleShowPopUp,
        })
      );
    }
  };

  const handleSelectedMethod = (methodName: string) => {
    if (methodName !== selectedData?.selectedMethod) {
      setSelectedCSV([]);
    }
    setSelectedMethod(methodName);
    dispatch(
      storeSelectedMethod({
        ...selectedData,
        selectedMethod: methodName,
      })
    );
  };

  const handleDownloadSampleCsv = async () => {
    const response: any = await fetch(PaymentStatusUpdate);
    const reader = response.body.getReader();
    const result = await reader.read();
    const decoder = new TextDecoder("utf-8");
    const csv = await decoder.decode(result.value);
    // downloadCsvFile(csv, sampleFileName);

    if (tabInfo?.[selectedData?.selectedType]?.area === "Allocation") {
      dispatch(
        sampleCsvDownload({
          useCaseId: teamUseCaseId,
          channelId: "640989b312a7c0017b9c40a3", //add correct channelId here
          needCsv: true,
        })
      );
    } else {
      dispatch(sampleCsvPaymentDownload());
      // downloadCsvFile(csv, "Payment Information Sheet");
    }
  };

  const handleSelectedCSVForCampaign = (data: FileData[]) => {
    setSelectedCSV(data);
  };

  const postCSVSelectedValidation = () => {
    const selectedTabData = tabInfo?.[selectedData?.selectedType];
    const selectedMethodData = selectedData?.selectedMethod;
    // console.log(selectedTabData, selectedMethodData, "hhhhhh");

    if (selectedMethodData?.includes("Manual")) {
      if (selectedTabData?.area === "Allocation") {
        // console.log(selectedTabData?.details?.length, selectedCSV, "hhhhhh");

        if (selectedTabData?.details?.length === 0) {
          return false;
        }

        if (!isStrategyDataUpload) {
          if (
            isSendingCSV === "loading" ||
            (selectedCSV && selectedCSV.length === 0)
          ) {
            return false;
          }

          if (selectedCSV && selectedCSV.length > 0) {
            if (
              selectedCSV.every((item) => !item.isFilePicked) ||
              selectedCSV.some((item) => item.isFilePicked && item.isSheetError)
            ) {
              return false;
            }
          }
        }
      } else {
        // For other cases (not "Allocation")
        if (selectedTabData?.details?.length === 0) {
          return false;
        }
        if (
          selectedTabData?.details?.length > 0 &&
          selectedTabData?.details?.[0]?.isSheetError
        ) {
          return false;
        }
      }
    } else {
      // console.log(selectedTabData?.details?.length, selectedCSV, "hhhhhh");

      if (selectedTabData?.details?.data?.length === 0) {
        return false;
      }

      if (
        isSendingCSV === "loading" ||
        (selectedCSV && selectedCSV.length === 0)
      ) {
        return false;
      }

      if (selectedCSV && selectedCSV.length > 0) {
        if (
          selectedCSV.every((item) => !item.isFilePicked) ||
          selectedCSV.some((item) => item.isFilePicked && item.isSheetError)
        ) {
          return false;
        }
      }
    }

    return true;
  };

  console.log("selectedCSV",selectedCSV)

  const handleSendSelectedCSV = () => {
    console.log("check kar", tabInfo?.[selectedData?.selectedType]?.area);
    if (isStrategyDataUpload) {
      navigate("/strategy/userpersona");
    } else {
      if (
        tabInfo?.[selectedData?.selectedType]?.area?.toLowerCase() ===
        "allocation"
      ) {
        const personasSet = new Set<any>();
        const languageSet = new Set<any>();
        const flowTypeSet = new Set<any>();
        let paymentLink = false;
        const filteredSelectedCSV = selectedCSV?.filter(
          (item) => item?.isFilePicked && !item?.isSheetError
        );

        filteredSelectedCSV?.forEach((item) => {
          item?.personas.forEach((persona: any) => {
            if (persona && (persona.id || persona._id)) {
              personasSet.add(JSON.stringify(persona));
            }
          });

          item?.language.forEach((lang: string) => languageSet.add(lang));
          if (item?.flowType?.maiaFlow) {
            item.flowType?.maiaFlow?.forEach((flow: any) =>
              flowTypeSet.add(JSON.stringify(flow))
            );
          }
          if (item?.isPaymentLink) {
            paymentLink = true;
          }
        });

        const idsArr = selectedCSV?.map((data: any) => ({
          id: data?.id,
          isFilePicked: data?.isFilePicked,
        }));

        const personasArr = Array.from(personasSet).map((personaStr) =>
          JSON.parse(personaStr)
        );
        const languageArr = Array.from(languageSet);
        const flowTypeArr = Array.from(flowTypeSet).map((flowStr) =>
          JSON.parse(flowStr)
        );
  
        const requestIDArr = selectedCSV?.map((data: any) => data?.requestId);
        console.log("requestIDArr", requestIDArr);
  
        if (selectedData.selectedMethod === "API") {
          dispatch(
            postSelectedAPICSVData({
              ids: idsArr,
              personas: personasArr,
              language: languageArr,
              flowType: flowTypeArr,
              parentCampaignManagerId: campaignData?.id,
              strategyId: strategyId,
              isStrategy: isStrategyDataUpload,
              isPaymentLink: paymentLink,
              accountId: accountDetails?.id,
              requestIds: requestIDArr
              // func: handleShowCheckError, // Add pop up function.
            })
          );
        } else {
          dispatch(
            postSelectedCSVData({
              ids: idsArr,
              personas: personasArr,
              language: languageArr,
              flowType: flowTypeArr,
              uploadedSuccessfully: true,
              parentCampaignManagerId: campaignData?.id,
              strategyId: strategyId,
              isStrategy: isStrategyDataUpload,
              isPaymentLink: paymentLink,
              func: handleShowCheckError, // Add pop up function.
            })
          );
        }
      } else {
        navigate("/campaignmanager");
      }
    }
  };
  
  

  useEffect(() => {
    // console.log(selectedCSV, selectedData, "dataaaa");
    setIsCSVValidToSend(postCSVSelectedValidation());
  }, [selectedCSV, isSendingCSV, selectedData, isStrategyDataUpload, tabInfo]);

  useEffect(() => {
    if (!selectedData?.selectedMethod.length) {
      dispatch(
        storeSelectedMethod({
          selectedType: 0,
          selectedMethod: methodInfo?.[0]?.name,
        })
      );
      setSelectedMethod(methodInfo?.[0]?.name);
      setTabIndex(0);
    }
  }, [selectedData]);

  useEffect(() => {
    if (selectedData?.selectedMethod.length) {
      if (selectedData.selectedMethod === "API") {
        dispatch(
          getCSVAPIData({
            accountId: accountDetails?.id,
            page: 1,
            token: token,
          })
        );
      } else if (selectedData.selectedMethod === "Manual .CSV") {
        const payload = { userType: accountDetails?.type, token: token };
        const apiDataParams = location.pathname?.includes("strategy/dataupload")
          ? { strategyId: strategyId, ...payload }
          : { campaignManagerId: campaignData?.id, ...payload };

        dispatch(getUploadedCSVInfoAPIData(apiDataParams));
      } else {
        dispatch(
          getCSVSalesForceData({
            accountId: accountDetails?.id,
            page: 1,
            token: token,
          })
        );
      }
    }
  }, [
    selectedData?.selectedMethod,
    accountDetails?.id,
    campaignData?.id,
    strategyId,
    isStrategyDataUpload,
    accountDetails?.type,
    token,
  ]);

  useEffect(() => {
    if (location.pathname?.includes("strategy/dataupload")) {
      setIsStrategyDataUpload(true);
    } else {
      setIsStrategyDataUpload(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    let newTabInfo: any = [];

    if (selectedData?.selectedMethod === "API" && apiDataUploadInfoObj) {
      newTabInfo = apiDataUploadInfoObj;
    } else if (
      selectedData?.selectedMethod === "Manual .CSV" &&
      uploadedCSVInfo
    ) {
      newTabInfo = uploadedCSVInfo;
    } else {
      newTabInfo = salesforceDataUploadInfoObj;
    }

    if (newTabInfo && newTabInfo.length > 0) {
      if (selectedData?.selectedMethod?.includes(method)) {
        const selected: any = newTabInfo.find(
          (data: any) => data.area === newTabInfo[tabIndex]?.area
        );

        if (selected?.details?.length > 0) {
          const res = selected?.details?.filter(
            (data: any) => data.isFilePicked === true
          );

          if (res) {
            setSelectedCSV(res);
          }
        }
      }
    }

    if (location.pathname?.includes("/strategy/dataupload")) {
      const tabsToShow = newTabInfo?.filter(
        (data: any) => data?.name === "Campaign Allocation"
      );
      setTabInfo((prevTabInfo) =>
        JSON.stringify(prevTabInfo) === JSON.stringify(tabsToShow)
          ? prevTabInfo
          : tabsToShow
      );
    } else {
      setTabInfo((prevTabInfo) =>
        JSON.stringify(prevTabInfo) === JSON.stringify(newTabInfo)
          ? prevTabInfo
          : newTabInfo
      );
    }

    console.log(newTabInfo, "kkkkkkkkkkk");
  }, [
    uploadedCSVInfo,
    apiDataUploadInfoObj,
    selectedData,
    salesforceDataUploadInfoObj,
    tabIndex,
    method,
    location.pathname,
  ]);
  useEffect(() => {
    if (isNavigate) {
      if (isStrategyDataUpload) {
        navigate("/strategy/userpersona");
      } else {

        if (!stepsTotal?.includes("Strategy Selection")) {
          navigate("/campaignmanager");
          return
        }
        navigate("/campaignmanager/userpersona");
      }
      dispatch(
        setSelectedCSVAPIData({ ...selectedCSVData, isNavigate: false })
      );
    }
  }, [isNavigate]);

  const handleValidation = () => {
    try {
      if (selectedCSV?.length > 0 && selectedData?.selectedType !== undefined) {
        const selectedMethod = selectedData?.selectedMethod.toLowerCase();
  
        const filteredCSV = selectedCSV.filter((data) => {
          const mode = data.mode?.toLowerCase();
          return (
            (selectedMethod.includes("manual") && mode.includes("manual")) ||
            (selectedMethod.includes("api") && mode.includes("api"))
          );
        });
  
        const selectedArea = selectedCSV[0]?.area;
  
        const isSelectedArea =
          selectedData.selectedType ===
          tabInfo?.findIndex((item) => item.area === selectedArea);
  
        if (isSelectedArea === true && filteredCSV.length > 0) {
          return true;
        }
      }
    } catch (error) {
      console.error("Error in handleValidation:", error);
    }
    return false;
  };
  
  console.log("hhe", showPopUpAfterUploadError)

  return (
    <>
      <div className={styles.dataUploadDiv}>
        <div className={styles.methodsDiv}>
          {isStrategyDataUpload ? (
            <div className={styles.backIconDiv}>
              <Icon
                img_src={backIcon}
                onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.stopPropagation();
                  navigate("/strategy");
                }}
                id="auto_dataupload_backIcon"
              />
            </div>
          ) : (
            <></>
          )}
          {methodInfo?.map((data: MethodInfo, index: number) => {
            return (
              <div key={index} className={styles.methodStyling}>
                <MethodDiv
                  methodData={data}
                  method={selectedData?.selectedMethod}
                  sendSelectedMethod={(method: string) =>
                    handleSelectedMethod(method)
                  }
                />
              </div>
            );
          })}
        </div>
        <div className={styles.dataUploadInfoDiv}>
          <Tabs
            selectedIndex={
              selectedData?.selectedType ? selectedData?.selectedType : tabIndex
            }
            onSelect={(tabIndex: number) => {
              if (tabIndex !== selectedData?.selectedType) {
                setSelectedCSV([]);
              }
              setTabIndex(tabIndex);
              dispatch(
                storeSelectedMethod({
                  ...selectedData,
                  selectedType: tabIndex,
                })
              );
            }}
            forceRenderTabPanel
            selectedTabClassName={styles.selectedTabWrapper}
            className={styles.tabsClassStyling}
          >
            <TabList className={styles.tabListWrapper}>
              <div className={styles.leftSideDiv}>
                {tabInfo?.map((tab: TabInfo) => (
                  <Tab
                    className={styles.tabNameStyling}
                    key={tab?.name}
                  //onClick={() => setTabIndex(index)}
                  >
                    {tab?.name}
                  </Tab>
                ))}
              </div>
            </TabList>

            {tabInfo?.map((tab: TabInfo, index: number) => (
              <TabPanel
                className={`${styles.tabPanelStyling} ${index !== selectedData?.selectedType
                  ? styles.hideTabPanelStyling
                  : ""
                  }`}
                key={tab?.area}
              >
                {selectedData?.selectedType === index && (
                  <div className={styles.uploadDataComponentDiv}>
                    {selectedData?.selectedMethod === "Manual .CSV" ? (
                      <UploadComponent
                        onFileSelect={handleFileSelection}
                        onClickSample={handleDownloadSampleCsv}
                        isLoading={isLoadingFile === "loading"}
                        selectedTab={
                          tabInfo?.[selectedData?.selectedType]?.area
                        }
                        isStrategy={isStrategyDataUpload}
                      />
                    ) : null}
                    <UploadedDataTable
                      showSelectedCSV={handleValidation()}
                      selectedCSV={selectedCSV}
                      fileInfo={tabInfo?.[selectedData?.selectedType]?.details}
                      sendSelectedCSVData={(data: any) =>
                        handleSelectedCSVForCampaign(data)
                      }
                      selectedTab={tabInfo?.[selectedData?.selectedType]?.area}
                      isLoading={isLoadingUploadedData === "loading"}
                      isStrategy={isStrategyDataUpload}
                    />
                  </div>
                )}
              </TabPanel>
            ))}
          </Tabs>
          <div className={styles.nextBtnDiv}>
            <Button
              text={"Next"}
              extraClass={`${styles.buttonStyling} ${isCSVValidToSend
                ? styles.nextButtonActiveStyle
                : styles.nextButtonInActiveStyle
                }`}
              onClick={handleSendSelectedCSV}
            />
          </div>
        </div>
      </div>
      {showPopUpAfterUploadError?.show && (
        <Modal
          shown={showPopUpAfterUploadError?.show}
          extraClassStyleModalContent={styles.modalContentDiv}
          close={handleClosePopUpError}
        >
          <PopUpModal
            close={() => handleClosePopUpError()}
            Message={`${showPopUpAfterUploadError?.data?.data}` || ""}
            MsgData={[{name:"Missing Header", value:`${showPopUpAfterUploadError?.data?.data.join(", ")}`}]}
            MsgType="Failed"
            headingImg={""}
            headingName={`File Upload has failed - ${showPopUpAfterUploadError?.data?.message} !`}
            btnName={"Please Reupload"}
            btnClick={() => handleClosePopUpError()}
            data={showPopUpAfterUploadError?.data?.data || showPopUpAfterUploadError?.data}
          />
        </Modal>
      )}
      {
        <Modal
          shown={showPopUpAfterUploadSuccess?.show}
          extraClassStyleModalContent={styles.modalContentDiv}
          close={handleClosePopSuccess}
        >
          <PopUpModal
            close={() => handleClosePopSuccess()}
            MsgType={"Completed"}
            MsgData={showPopUpAfterUploadSuccess?.data?.popUpDetails}
            headingName={`Data Upload - Completed`}
            data={showPopUpAfterUploadSuccess?.data?.popUpDetails}
            errorName="Note : "
            errorNote={showPopUpAfterUploadSuccess?.data?.message}
          />
        </Modal>
      }
      {
        <Modal
          shown={showPopUpAfterCheck?.show}
          extraClassStyleModalContent={styles.modalContentDiv}
          close={handleClosePopUpCheck}
        >
          <PopUpModal
            close={() => handleClosePopUpCheck()}
            MsgType={"Failed"}
            MsgData={showPopUpAfterCheck?.data?.popUpDetails}
            headingName={`Error File Selection`}
            data={showPopUpAfterCheck?.data?.popUpDetails}
            errorName="Note : "
            errorNote={showPopUpAfterCheck?.data?.message}
          />
        </Modal>
      }
      {
        <Modal
          shown={showPopUpMany?.show}
          extraClassStyleModalContent={styles.modalManyFile}
          close={handleClosePopUpMany}
        >
          <div className={styles.closeIcon}>
            <img
              src={CrossIcon}
              onClick={() => {
                handleClosePopUpMany();
              }}
              className={`${styles.IconExtraStyling
                }`}
            />
          </div>
          <div>
            {
              showPopUpMany?.data?.map((detail: any, i: number) => {
                return (
                  <div key={i} className={styles.eachPopUp} onClick={() => setOpenMultiFileNumber(i)} >
                    <ManyFileStatus
                      fileName={detail?.fileName}
                      failMessage={detail?.errorRespose}
                      data={detail?.popUpDetails}
                      errorName={detail?.errorRespose?.length ? "" : "Note :"}
                      errorNote={detail?.message}
                      outsideClose={i === openMultiFileNumber}
                    /></div>
                )
              })
            }
          </div>

        </Modal>
      }
    </>
  );
};
