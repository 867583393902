// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heh4FGSNfbWRSx60rqOw{height:100%;width:100%;background:#fbfbfb;overflow:auto;display:flex;flex-direction:column}.Y4m2vAjGcLwEHyDdjnmB{margin:-2em 15em 3.5em 20em}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/campaign_V2.0/dataUploadScreen/DataUploadScreen.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,UAAA,CACA,kBAAA,CACA,aAAA,CACA,YAAA,CACA,qBAAA,CAGF,sBACE,2BAAA","sourcesContent":[".dataUploadScreenDiv {\n  height: 100%;\n  width: 100%;\n  background: #fbfbfb;\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n}\n\n.stepProgressDiv {\n  margin: -2em 15em 3.5em 20em;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dataUploadScreenDiv": `heh4FGSNfbWRSx60rqOw`,
	"stepProgressDiv": `Y4m2vAjGcLwEHyDdjnmB`
};
export default ___CSS_LOADER_EXPORT___;
