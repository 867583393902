import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ConversationCardsWrapper from "../../../components/generic/conversationCards/ConversationCardsWrapper";
import TranscriptLeft from "../../../components/moduleComponents/transcriptLeft/TranscriptLeft";
import AudioPlayerWrapper from "../../../components/generic/audioPlayer/AudioPlayerWrapper";
import SummaryFeedback from "../../../components/moduleComponents/logger/summaryFeedback/SummaryFeedback";
import "./Transcript.scss";
import { RootState } from "../../../redux";
// import Loader from "../../../components/generic/loader/Loader";
import { useDispatch } from "react-redux";
import { getAllFiltersOptionsForAccount } from "../../../redux/filters/actions";
import {
  startLoggerTour,
  updateTourModuleWise,
  pushOnTranscript,
  setStartTourMain,
  setComeFromExperienceNow,
} from "../../../redux/signUpFlow/productTour/actions";
import {getAudioURLToPlay,clearAudioURL} from "../../../redux/logger/loggerState/action";
import { Mixpanel } from "../../../utils/mixpanelSetup";
import { format } from "date-fns";
import MessageModal from "../../../components/moduleComponents/signUpFlow/maiaMessageModal/MessageModal";
import Modal from "../../../components/generic/modal/Modal";
import iconLeft from "../../../theme/assets/svg/signUpFlow/maiaIconLeftBottom.svg";
import {
  getAllClientDetailByname,
  getUserData,
} from "../../../redux/onboarding/login/actions";
export default function Transcript() {
  const dispatch = useDispatch();

  const summary = useSelector(
    (store: RootState) => store.loggerReducer?.loggerState?.summaryData[0]
  );
  const currentClient = useSelector(
    (store: any) => store?.allClientReducer?.currentSelectedClient
  );
  const channelTab = useSelector(
    (store: RootState) => store.dashboardReducer.channelTab
  );
  let url2 = useSelector(
    (state: RootState) =>
      state.loggerReducer?.loggerState?.summaryData[0]?.information
        ?.recording_file_url
  );
  const selectedSessionAudioURL = useSelector((state: any) => {
    return state.loggerReducer?.loggerState?.selectedSessionAudioURL;
  });

  console.log("SelectedAudioUrl transcript" , selectedSessionAudioURL);
  const reduxPhoneList: [] = useSelector((state: any) => {
    return state.loggerReducer?.transcriptReducer?.allPhoneNumberList;
  });
  const defaultuser = useSelector((state: any) => {
    return state.loginReducer?.userLoginInfo?.accountDetails[0]?.name;
  });
  const loggerTour = useSelector((state: any) => {
    return state.signUpModuleReducer?.productTourReducer?.startLoggerTour;
  });
  const showExpNowModal = useSelector(
    (store: RootState) =>
      store?.signUpModuleReducer?.productTourReducer?.experiencePravid
  );
  const loginUserId = useSelector(
    (store: any) => store.loginReducer.userLoginInfo?.userDetail?._id
  );
  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const isLoggerToShow = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.demoSessionModules
  );
  const loginClientName = useSelector(
    (store: any) =>
      store.loginReducer?.userLoginInfo?.userDetail?.accountDetails[0]?.name
  );
  const iscomeFromExperienceNow = useSelector((state: any) => {
    return state.signUpModuleReducer?.productTourReducer?.comeFromExperienceNow;
  });
  const accountType = useSelector((state: any) => {
    return state.loginReducer?.userLoginInfo?.accountDetails[0]?.type;
  });

  // useEffect(
  //   () => {
  //     const data = {
  //       headers : { userId: loginUserId}
  //     }
  //     dispatch(getUserData(data))
  //   },[]
  // )
  // useEffect(() => {
  //   dispatch(getAllClientDetailByname(loginClientName));
  // }, [loginClientName]);

  const loginUserDetail = useSelector(
    (store: any) => store.loginReducer?.userData
  );
  const isTourModule = useSelector(
    (store: any) => store.loginReducer?.allClientDataByname
  );

  console.log("fgf", isTourModule);

  Mixpanel.track("Logger | Transcript + Report | Time spent", {
    "user Name": defaultuser,
    Date: format(new Date(), "dd-MM-yyyy"),
    Page: "Transcript",
  });
  // for signUp flow
  useEffect(() => {
    // dispatch(startLoggerTour(false))
    dispatch(clearAudioURL())
    setTimeout(() => {
      dispatch(pushOnTranscript(false));
    }, 10000);
  }, []);
  useEffect(() => {
    if (!loginUserDetail?.productShowcase?.includes("Logger")) {
      dispatch(setStartTourMain(true));
    }
  }, [loginUserDetail]);

  useEffect(() => {
    dispatch(getAllClientDetailByname(loginClientName));
    // const data = {
    //   userName: loginUserDetail?.userName,
    // };
    const data = {
      headers: { userId: loginUserId },
    };
    // dispatch(getUserData(data));
  }, [loginClientName]);

  // for signUp flow
  useEffect(() => {
    // currentClient.length &&
    //   dispatch(getAllFiltersOptionsForAccount({},token));
  }, [currentClient]);
  // const accountName = useSelector(
  //   (store: any) =>
  //     store.loginReducer?.userLoginInfo?.userDetail.accountDetails[0]
  // );
  // const userDetail = useSelector(
  //   (store: any) => store.loginReducer?.userLoginInfo?.userDetail
  // );
  // const accountDetails = useSelector(
  //   (store: any) => store.loginReducer?.userLoginInfo?.accountDetails[0]
  // );
  // const calender = useSelector(
  //   (store: RootState) => store.filterReducers.calenderData
  // );
  // useEffect(() => {

  //   currentClient.length &&
  //   dispatch(getAllFiltersOptionsForAccount({username:accountName?.name,type:accountDetails?.type,role:userDetail?.role,start_date:calender.startDate,end_date:calender.endDate}));

  // }, [currentClient]);
  // url2 = url2?.includes("saarthicalls")
  //   ? url2 + `?${process.env.REACT_APP_ACCESS_TOKEN_LOGGER_SAARTHICALL}`
  //   : url2 + `?${process.env.REACT_APP_ACCESS_TOKEN_LOGGER_AMEYOCALL}`;

  const handleClickNext = () => {
    dispatch(startLoggerTour(true));
    const body = {
      userId: loginUserId,
      moduleName: "Logger",
    };
    dispatch(updateTourModuleWise(body));
    dispatch(setComeFromExperienceNow(false));
  };
  return (
    <div className="wrapper__transcript">
      <div className="transcript__left">
        <TranscriptLeft />
      </div>

      <div className="transcript__center">
        {channelTab?.name?.toLowerCase() === "call"
          ? selectedSessionAudioURL &&
          selectedSessionAudioURL !== "" && (
              <div className="audio-player">
                <AudioPlayerWrapper
                  url={selectedSessionAudioURL}
                />
              </div>
            )
          : ""}
        <div className="conversation">
          <ConversationCardsWrapper />
        </div>
      </div>
      {reduxPhoneList.length > 0 && (
        <div className="transcript__right">
          <SummaryFeedback summary={summary} />
        </div>
      )}
      {!loggerTour &&
        !showExpNowModal &&
        !loginUserDetail?.productShowcase?.includes("Logger") && (
          <Modal
            shown={true}
            extraClassStyleModalContent={"transcript__center_tourModal"}
          >
            <MessageModal
              text={
                !loginUserDetail?.isInvited && iscomeFromExperienceNow
                  ? "We had a nice talk! 😊 You can see the transcript of our conversation from this logger module."
                  : "You can see all conversation transcripts from this logger module."
              }
              img={iconLeft}
              btnClick={handleClickNext}
            />
          </Modal>
        )}
    </div>
  );
}
