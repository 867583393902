import { Reducer } from "redux";
import actionType from "../actionTypes";
import { stateInterface} from "../interface";

const initialState: stateInterface = {
   moduleClickCount : {
     "Analytics" : 0,
      "Logger" : 0
   }
};

const analyticsReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionType.SET_COUNT_MODULE_CLICK: {
      return { ...state, moduleClickCount: {...action.payload} };
    }
    case actionType.SET_TRANSCRIPT_SESSIONID: {
      return { ...state, transcriptSessionIdDetails: {...action.payload} };
    }
    case actionType.SET_FORGOT_PASSWORD_COUNT: {
      return { ...state, forgotPasswordCount: action.payload};
    }
    case actionType.SET_CAMPAIGN_DETAILS: {
      return { ...state, campaignDetailds: {...action.payload}};
    }
    case actionType.SET_MIXPANNEL_MODULE_DETAILS: {
      return { ...state, moduleDetails: {...action.payload}};
    }
    case actionType.SET_MIXPANNEL_INTEGRATION_DETAILS: {
      return { ...state, integrationDetails: {...action.payload}};
    }
    case actionType.SET_MIXPANNEL_LOGIN_DETAILS: {
      return { ...state, loginDetails: {...action.payload}};
    }
    default: {
      return state;
    }
  }
};

export default analyticsReducer;
