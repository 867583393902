// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fVFgwtyT2tJ5C1Hp_GdQ{font-family:Roboto;font-size:14px;font-style:normal;font-weight:400;color:#6e7079}`, "",{"version":3,"sources":["webpack://./src/screens/profile/yourAccount/timerForReminder/Timer.module.scss"],"names":[],"mappings":"AAAA,sBACA,kBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,aAAA","sourcesContent":[".timerWrapperOrganisation{\nfont-family: Roboto;\nfont-size: 14px;\nfont-style: normal;\nfont-weight: 400;\ncolor: #6E7079;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timerWrapperOrganisation": `fVFgwtyT2tJ5C1Hp_GdQ`
};
export default ___CSS_LOADER_EXPORT___;
