import actionTypes from "../telephony.actionTypes";

export function getAllTelephonySystem(payload: object) {
  return {
    type: actionTypes.GET_ALL_TELEPHONY_SYSTEM_REQUEST,
    payload: payload,
  };
}
export function getdefaultTelephonySystem(payload: object) {
  return {
    type: actionTypes.GET_DEFAULT_TELEPHONY_SYSTEM_REQUEST,
    payload: payload,
  };
}
export function getAllSIPGateway(payload: object) {
  return {
    type: actionTypes.GET_SIP_GATEWAY_REQUEST,
    payload: payload,
  };
}
export function addTransactionalDetails(payload: object) {
  return {
    type: actionTypes.ADD_TRANSACTION_DETAIL,
    payload: payload,
  };
}
export function addPromotionalDetails(payload: object) {
  return {
    type: actionTypes.ADD_PROMOTIONAL_DETAIL,
    payload: payload,
  };
}
export function getDefaultTelephonyData(payload: object) {
  return {
    type: actionTypes.GET_DEFAULT_TELEPHONY_DATA_REQUEST,
    payload: payload,
  };
}
export function getClientTelephonyData(payload: object) {
  return {
    type: actionTypes.GET_CLIENT_TELEPHONY_DATA_REQUEST,
    payload: payload,
  };
}
export function createTelephonyData(payload: object) {
  return {
    type: actionTypes.SET_TELEPHONY_DATA_REQUEST,
    payload: payload,
  };
}

export function selectedTelephonyTab(payload: string) {
  return {
    type: actionTypes.SET_SELECTED_TELEPHONY_TAB,
    payload: payload,
  };
}

export function setDefaultTelephonyData(payload: any) {
  return {
    type: actionTypes.SET_DEFAULT_TELEPHONY_DATA_REQUEST,
    payload: payload,
  };
}

export function saveSelectedTelephonySystem(payload: any) {
  return {
    type: actionTypes.SET_SELECTED_TELEPHONY_DATA,
    payload: payload,
  };
}

export function clearAllTelephonyData() {
  return {
    type: actionTypes.CLEAR_ALL_TELEPHONY_DATA,
  };
}

export function clearErrorMsgSetTelephony() {
  return {
    type: actionTypes.CLEAR_SET_TELEPHONY_DATA,
  };
}