import React, { useEffect, useState } from "react";
import styles from "./StrategySelectScreen.module.scss";
import StepProgress from "../../../generic/stepProgress/StepProgress";
import { StrategyScreen } from "../../../../screens/strategy/StrategyScreen";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  maxCompletedStep,
  storeCurrentStep,
} from "../../../../redux/campaign_V2.0/progressBar/actions";
import { RootState } from "../../../../redux";
import {
  createStrategyAPIData,
  updateStrategyAPIData,
  getWorkflowsAPIData,
} from "../../../../redux/strategy/strategyCreation/actions";
import AllAgentPage from "../allAgentsPage/AllAgentPage";

export const StrategySelectScreen = () => {
  const [optionsSelected, setOptionsSelected] = useState<any>({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const maxStateCompleted = useSelector(
    (state: RootState) =>
      state.campaignModuleReducer?.progressBar?.maxCompletedStep
  );

  const campaignData = useSelector((store: RootState) => {
    return store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo;
  });

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const stepsTotal: string[] = useSelector(
    (store: RootState) =>
      store?.schedulerReducer?.accountInfoDetails?.campaignProgressBar
  );

  const teamUseCaseId = useSelector(
    (store: RootState) => store?.loginReducer?.currentTeam?.useCaseId
  );
  const currentTeamId = useSelector(
    (state: RootState) => state.loginReducer.currentTeam.id
  );
  const currentAccountId = useSelector(
    (state: RootState) => state.loginReducer.currentTeam?.accountId
  );

  // const stepsTotal = [
  //   "Campaign Details",
  //   "Data Upload",
  //   "Strategy Selection",
  //   "Agent Configuration",
  //   "Scheduler Setting",
  // ];

  useEffect(() => {
    dispatch(
      getWorkflowsAPIData({
        token: token,
        useCaseId: teamUseCaseId,
        teamId: currentTeamId,
        accountId: currentAccountId
      })
    );
  }, [token]);

  useEffect(() => {
    if (location.pathname.includes("strategyselection")) {
      dispatch(storeCurrentStep(stepsTotal?.indexOf("Strategy Selection") + 1));
    }
  }, [location.pathname, stepsTotal]);

  // useEffect(() => {
  //   if (campaignData?.uploadedSuccessfully)
  //     dispatch(setProgressOn({ completed: 3, progress: 3 }));
  // }, [campaignData?.uploadedSuccessfully]);

  useEffect(() => {
    if (campaignData?.uploadedSuccessfully && campaignData?.strategyId)
      if (maxStateCompleted < stepsTotal.indexOf("Strategy Selection") + 1)
        dispatch(
          maxCompletedStep(stepsTotal.indexOf("Strategy Selection") + 1)
        );
  }, [campaignData?.uploadedSuccessfully, campaignData?.strategyId]);

  return (
    <div className={styles.strategySelectScreenDiv}>
      <div className={styles.stepProgressDiv}>
        <StepProgress
          color={"#07B464"}
          arr={[
            {
              name: "Campaign Details",
              fun: () => {
                navigate("/campaignmanager/campaigncreation");
              },
            },
            {
              name: "Data Upload",
              fun: () => {
                navigate("/campaignmanager/dataupload");
              },
            },
            {
              name: "Strategy Selection",
              fun: () => {
                navigate("/campaignmanager/strategyselection");
              },
            },
            {
              name: "Agent Configuration",
              fun: () => {
                navigate("/campaignmanager/allagents");
              },
            },
          ].filter((each) => stepsTotal.includes(each.name))}
          maxStateCompleted={maxStateCompleted}
          currentStepComplete={
            campaignData?.uploadedSuccessfully &&
            campaignData?.strategyId?.length > 0
          }
          inProgressStepFromOut={stepsTotal.indexOf("Strategy Selection") + 1}
        />
      </div>
      <hr className={styles.hrStyling} />
      <div className={styles.createStrategyScreenTopDiv}>
        <StrategyScreen />
      </div>
    </div>
  );
};
