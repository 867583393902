import React, { useEffect, useState } from "react";
import styles from "./MapComponent.module.scss";
import { noPhoneDataIcon } from "../../../../theme/assets/svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { map } from "../../../../theme/assets/svg/index";
import MapDataComponent from "./mapData/MapData";
import InteractiveMap from "./interactionMap/InteractiveMap";
import IndiaMap from "./indiaMap/IndiaMap";

const chartDetails = [
  {
    name: "Low",
    fill: "#90BE6D",
    data: [
      { key: "Gujarat", val: 500 },
      {
        key: "Madhya Pradesh",
        val: 350,
      },
      {
        key: "Tamil Nadu",
        val: 700,
      },
      {
        key: "Assam",
        val: 130,
      },
      {
        key: "Karnataka",
        val: 100,
      },
      {
        key: "Kerla",
        val: 480,
      },
      {
        key: "Chhattisgarh",
        val: 250,
      },
      {
        key: "Arunachal Pradesh",
        val: 300,
      },
      {
        key: "Rajasthan",
        val: 390,
      },
      {
        key: "Andra Pradesh",
        val: 80,
      },
    ],
  },
  {
    name: "Medium",
    fill: "#F9C74F",
    data: [
        { key: "Gujarat", val: 300 },
        {
          key: "Madhya Pradesh",
          val: 150,
        },
        {
          key: "Tamil Nadu",
          val: 150,
        },
        {
          key: "Assam",
          val: 570,
        },
        {
          key: "Karnataka",
          val: 200,
        },
        {
          key: "Kerla",
          val: 280,
        },
        {
          key: "Chhattisgarh",
          val: 300,
        },
        {
          key: "Arunachal Pradesh",
          val: 300,
        },
        {
          key: "Rajasthan",
          val: 280,
        },
        {
          key: "Andra Pradesh",
          val: 260,
        },
      ],
  },
  {
    name: "High",
    fill: "#F94144",
    data: [
        { key: "Gujarat", val: 100 },
        {
          key: "Madhya Pradesh",
          val: 400,
        },
        {
          key: "Tamil Nadu",
          val: 50,
        },
        {
          key: "Assam",
          val: 200,
        },
        {
          key: "Karnataka",
          val: 600,
        },
        {
          key: "Kerla",
          val: 140,
        },
        {
          key: "Chhattisgarh",
          val: 350,
        },
        {
          key: "Arunachal Pradesh",
          val: 300,
        },
        {
          key: "Rajasthan",
          val: 230,
        },
        {
          key: "Andra Pradesh",
          val: 560,
        },
      ],
  },
];

export default function MapComponent(props: any) {
  const [selectedState,setSelectedState]= useState({
    stateName:"",
    amount:"",
    connectionRate:"",
    activeCustomer:""
  })
  return (
    <>
      <div className={styles.mapParentClassDiv}>
        <div className={styles.mapHeaderDiv}>
          Risk category based on customer demographics
        </div>
        <div className={styles.mapBodyDiv}>
          <div className={styles.mapLeftSideComp} style={{position:"relative"}}>
            {/* <InteractiveMap /> */}
            <div className={styles.indiaMap} style={{position:"relative"}}>
            <IndiaMap
              sendSelectedState={(data:any)=>{setSelectedState(data)}}
            />
            </div>
            <div className={styles.btmDiv}>
              {selectedState?.stateName?.length>0 &&
              <div >
              <div className={styles.msg}>
              <div className={styles.stateName}>
                {selectedState?.stateName}
              </div>
              <div>
              Total Amount Collected: <span>{selectedState?.amount}</span>
              </div>
              <div>
                 Connection Rate: <span>{selectedState?.connectionRate}</span>
              </div>
              <div>
                No. of Active Customers: <span>{selectedState?.activeCustomer}</span>
              </div>
              </div>
              </div>
              }

            </div>
            {/* <img src={map} /> */}
          </div>
          <div className={styles.verticalLine}></div>
          <div className={styles.mapRightSideComp}>
            <MapDataComponent chartDetails={chartDetails} />
          </div>
        </div>
      </div>
    </>
  );
}
