import React, { useEffect } from "react";
import "./DevComponent.scss";
import InviteUser from "../../components/moduleComponents/signUpFlow/teamsPage/teamSetUp/intiveUser/InviteUser";
import { useDispatch } from "react-redux";
import ClaimRewardTimer from "../../components/moduleComponents/onBoarding/claimRewardTimer/ClaimRewardTimer";
import { getTeamsData } from "../../redux/onboarding/login/actions";
import moment from "moment";
import { EmailIntegrationPopUp } from "../../components/moduleComponents/integration_V2.0/emailScreen/emailIntegrationPopUp/EmailIntegrationPopUp";
import AgentDetailedView from "../../components/moduleComponents/strategyComponents/agentDetailedView/AgentDetailedView";

export default function DevComponent() {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getTeamsData("640efde7d4bca5ecefcfede8"));
  }, []);
  return (
    <div
      style={{
        height: "80vh",
        width: "40vw",
        margin: "auto",
      }}
    >
      {/* <AgentDetailedView /> */}
      {/* <InviteUser /> */}
    </div>
  );
}
