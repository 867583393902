// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.csANXoHnEXezDQbVTHGq{display:flex;justify-content:space-evenly;align-items:center;margin:2% 0;flex-wrap:wrap;max-width:96.1%}.csANXoHnEXezDQbVTHGq .ulyY0TvO6LOfybnQfWQQ{width:100%;display:flex;justify-content:flex-start;align-items:center}`, "",{"version":3,"sources":["webpack://./src/screens/crm/crmAnalytics/CRMAnalytics.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,4BAAA,CACA,kBAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CAEA,4CACI,UAAA,CACA,YAAA,CACA,0BAAA,CACA,kBAAA","sourcesContent":[".crmAnalyticsScreenDiv{\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n    margin: 2% 0;\n    flex-wrap: wrap;\n    max-width: 96.1%;\n\n    .topRowDiv{\n        width: 100%;\n        display: flex;\n        justify-content: flex-start;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crmAnalyticsScreenDiv": `csANXoHnEXezDQbVTHGq`,
	"topRowDiv": `ulyY0TvO6LOfybnQfWQQ`
};
export default ___CSS_LOADER_EXPORT___;
