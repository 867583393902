import React, { useState } from "react";
import DOMPurify from "dompurify";

import { props } from "./types";
import styles from "./NewConversationCards.module.scss";
import { newDateAndTime } from "../../../../utils/timeAndDate/time.util";
import RootPortal2 from "../../../../utils/rootPortal2/RootPortal2";
import {
  single_grey_tick_icon,
  double_grey_tick_icon,
  double_blue_tick_icon,
} from "../../../../theme/assets/agentDesktopSvg";
import whiteSvgNotch from "./white.svg";
import greySvgNotch from "./grey.svg";
/**
 * 
 * #props
 * - type : "sent" | "received"
 * - message: string
 * - time : Date
 * - staus?: "reached" | "not_seen" | "seen";
 * @example
 *   <NewConversationCards
        type="sent"
        time={new Date()}
        message="nlndslkn"
        staus="not_seen"
      ></NewConversationCards>
 * @param props 
 * @returns 
 */
export default function NewConversationCards(props: props) {
  /* useStates -------- */
  const [imageLink, setImageLink] = useState("");
  // const [PDFLink, setPDFLink] = useState("");
  // const ref = useRef(null);

  // useEffect(() => {
  //   if (ref.current) {
  //     (ref.current as HTMLElement).innerHTML = props.message as string;
  //   }
  // }, []);

  /* handlers -------- */
  const handlePortalOutSideClick = () => {
    setImageLink("");
    // setPDFLink("");
  };

  const handleImageClick = () => {
    setImageLink(props.message as string);
  };

  // const handlePDFClick = () => {
  //   setPDFLink(props.message as string);
  // };

  return (
    <div
      className={styles.wrapper}
      data-isreceived={props.type === "received"}
      data-issent={props.type === "sent"}
    >
      {!props.interactionType ||
      props.interactionType === "text" ||
      props.interactionType.toLowerCase() === "button" ? (
        <div
          className={styles.message}
          // ref={ref}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(props.message as string),
          }}
        ></div>
      ) : (
        <></>
      )}
      {/* {props.interactionType === "document" && (
        <div onClick={handlePDFClick}>
          <embed
            src={`https://www.africau.edu/images/default/sample.pdf?transparent=0#toolbar=0&navpanes=0&scrollbar=0`}
            style={{ pointerEvents: "none", backgroundColor: "transparent" }}
          ></embed>
        </div>
      )} */}
      {props.interactionType === "document" && (
        <a className={styles.linkClass} href={props.message}>
          {props.fileName || "Document.pdf"}
        </a>
      )}
      {props.interactionType === "image" && (
        <img
          className={styles.imgClass}
          src={props.message}
          onClick={handleImageClick}
        />
      )}
      <div className={styles.time} data-time={props.time}>
        {newDateAndTime(props.time as Date)}
        {props.staus && (
          <img
            src={
              props.staus === "reached"
                ? single_grey_tick_icon
                : props.staus === "not_seen"
                ? double_grey_tick_icon
                : double_blue_tick_icon
            }
          ></img>
        )}
      </div>
      {/* <span className={styles.notch}></span> */}
      {props.type === "sent" ? (
        <img className={styles.notchRight} src={whiteSvgNotch} />
      ) : (
        <img className={styles.notchLeft} src={greySvgNotch} />
      )}
      {imageLink && (
        <RootPortal2
          outSideClickFunction={handlePortalOutSideClick}
          extraModelStyle={{
            maxHeight: "90vh",
            maxWidth: "90vw",
          }}
          extraWrapperClass={styles.extraPortalWrapperClass}
        >
          <img src={imageLink} className={styles.portalImage} />
        </RootPortal2>
      )}
      {/* {PDFLink && (
        <RootPortal2
          outSideClickFunction={handlePortalOutSideClick}
          extraModelStyle={{
            maxHeight: "90vh",
            maxWidth: "90vw",
          }}
          extraWrapperClass={styles.extraPortalWrapperClass}
        >
          <embed
            src={`https://www.africau.edu/images/default/sample.pdf#toolbar=0&navpanes=0`}
            // type="application/pdf"
            style={{
              backgroundColor: "white",
              height: "90vh",
              width: "50vw",
            }}
          />
        </RootPortal2>
      )} */}
    </div>
  );
}
//
