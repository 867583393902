import { Reducer } from "redux";
import actionTypes from "../actionTypes";
import actionTypeBaseScreenAD from "../../BaseScreenAD/actionTypes";
import { stateInterface, actionInterface } from "../interface";
import actionTypesChat from "../../chatLogger/actionTypes";

const initialState: stateInterface = {
  summary: {},
  summaryLoading: true,
  summaryError: {},
  transcript: [],
  transcriptLoading: true,
  transcriptError: {},
  summaryDataEditable: {},
  sessionIds: {},
  sessionLoading: true,
  sessionError: {},
  selectedSessionId: "",
  selectedConversationId: "",
  summaryUpdatingLoading: false,
  summaryUpdatingError: {},
  summaryUpdateSuccess: false,
  callTimeStamps: { callAccept: null },
  showCallModule: false,
};

const callLoggerReducer = (
  state: stateInterface = initialState,
  action: actionInterface
): stateInterface => {
  switch (action.type) {
    // summary get
    case actionTypes.GET_SUMMARY_REQUEST_AGENT_DESKTOP: {
      return {
        ...state,
        summary: {},
        summaryError: {},
        summaryLoading: true,
        summaryDataEditable: {},
      };
    }
    case actionTypes.GET_SUMMARY_SUCCESS_AGENT_DESKTOP: {
      return { ...state, summary: action.payload, summaryLoading: false };
    }
    case actionTypes.GET_SUMMARY_FAILURE_AGENT_DESKTOP: {
      return { ...state, summaryError: {}, summaryLoading: false };
    }
    case actionTypes.SET_SUMMARY_DATA_EDITABLE_AGENT_DESKTOP: {
      return { ...state, summaryDataEditable: action.payload };
    }
    // update summary
    case actionTypes.UPDATE_SUMMARY_REQUEST_AGENT_DESKTOP: {
      return {
        ...state,
        summaryUpdatingLoading: true,
        summaryUpdatingError: {},
      };
    }
    case actionTypes.UPDATE_SUMMARY_SUCCESS_AGENT_DESKTOP: {
      return {
        ...state,
        summaryUpdatingLoading: false,
        summaryUpdateSuccess: true,
      };
    }
    case actionTypes.UPDATE_SUMMARY_FAILURE_AGENT_DESKTOP: {
      return {
        ...state,
        summaryUpdatingLoading: false,
        summaryUpdatingError: action.payload,
      };
    }
    case actionTypes.SET_SUMMARY_UPDATE_SUCCESS_AGENT_DESKTOP: {
      return { ...state, summaryUpdateSuccess: action.payload };
    }

    // transcript get
    case actionTypes.GET_TRANSCRIPT_REQUEST_AGENT_DESKTOP: {
      return {
        ...state,
        // transcript: [],
        transcriptError: {},
        transcriptLoading: true,
      };
    }
    case actionTypes.GET_TRANSCRIPT_SUCCESS_AGENT_DESKTOP: {
      return { ...state, transcript: action.payload, transcriptLoading: false };
    }
    case actionTypes.GET_TRANSCRIPT_FAILURE_AGENT_DESKTOP: {
      return { ...state, transcriptError: {}, transcriptLoading: false };
    }
    //session IDs
    // case actionTypes.LOADER_SESSION_ID_AD: {
    //   return { ...state, summaryLoading: true };
    // }
    case actionTypes.GET_SESSION_ID_REQUEST_AGENT_DESKTOP: {
      return {
        ...state,
        sessionIds: {},
        sessionLoading: true,
        sessionError: {},
        transcript: [],
        summary: {},
      };
    }
    case actionTypes.SET_CALL_SESSION_ID_LOADER: {
      return { ...state, sessionLoading: action.payload };
    }
    case actionTypes.GET_SESSION_ID_SUCCESS_AGENT_DESKTOP: {
      return { ...state, sessionIds: action.payload, sessionLoading: false };
    }
    case actionTypes.GET_SESSION_ID_FAILURE_AGENT_DESKTOP: {
      return { ...state, sessionLoading: false, sessionError: action.payload };
    }
    case actionTypes.SET_SELECTED_SESSION_ID_AGENT_DESKTOP: {
      return { ...state, selectedSessionId: action.payload };
    }
    case actionTypes.SET_SELECTED_CONVERSATION_ID_AGENT_DESKTOP: {
      return { ...state, selectedConversationId: action.payload };
    }
    // chat status
    case actionTypes.SET_CHAT_STATUS_AGENT_DESKTOP: {
      return {
        ...state,
        summaryDataEditable: {
          ...state.summaryDataEditable,
          chatObjective: action.payload,
        },
      };
    }
    // case actionTypeBaseScreenAD.HANDLE_CALL_FINISH_AGENT_DESKTOP: {
    //   return { ...state, callTimeStamps: { ...initialState.callTimeStamps } };
    // }
    case actionTypes.SET_TIMESTAMP_CALL_ACCEPT_AGENT_DESKTOP: {
      return {
        ...state,
        callTimeStamps: { ...state.callTimeStamps, callAccept: action.payload },
      };
    }
    case actionTypes.HANDLE_CALL_MODULE_LEAVE_AD: {
      return { ...initialState, showCallModule: state.showCallModule };
    }
    case actionTypesChat.HANDLE_CHAT_PAGE_LEAVE_AD: {
      return { ...state, summaryDataEditable: {} };
    }
    case actionTypes.SET_SHOW_CALL_MODULE_AD: {
      return { ...state, showCallModule: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default callLoggerReducer;
