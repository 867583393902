import { Reducer } from "redux";
import actionTypes from "../strategy.actionTypes";

export interface stateInterface {
  strategyDetails: any;
  isLoadingStrategyDetails: boolean;
  strategyDetailsError: string;

  isLoadingDeleteStrategy: boolean;
  deleteStrategyError: string;

  isLoadingUpdateStrategy: boolean;
  updateStrategyError: string;

  isLoadingCreateStrategy: boolean;
  createStrategyError: string;

  userPersonaDetails: any;
  isLoadingUserPersonaDetails: boolean;
  userPersonaDetailsError: string;

  workflowDetails: any;
  isLoadingWorkflowDetails: boolean;
  workflowDetailsError: string;

  addWorkflowDetails: any;
  isLoadingAddWorkflowDetails: boolean;
  addWorkflowDetailsError: string;

  setWorkflowDetails: any;
  isLoadingSetWorkflowDetails: boolean;
  setWorkflowDetailsError: string;

  addUserPersonaDetails: any;
  isLoadingAddUserPersonaDetails: boolean;
  addUserPersonaDetailsError: string;

  selectedUserPersona: any;
  createdStrategy: any;
  // createdWorkFlows: any;

  strategyOptionsDetails: any;
  isLoadingStrategyOptions: boolean;
  strategyOptionsDetailsError: string;

  userPersonaOptions: any;
  isLoadingUserPersonaOptions: boolean;
  userPersonaOptionsError: string;
  // selectedUserPersonaFromCSV: any;
}

export const initialState: stateInterface = {
  strategyDetails: {},
  isLoadingStrategyDetails: false,
  strategyDetailsError: "",

  isLoadingDeleteStrategy: false,
  deleteStrategyError: "",

  isLoadingUpdateStrategy: false,
  updateStrategyError: "",

  isLoadingCreateStrategy: false,
  createStrategyError: "",

  userPersonaDetails: {},
  isLoadingUserPersonaDetails: false,
  userPersonaDetailsError: "",

  workflowDetails: {},
  isLoadingWorkflowDetails: false,
  workflowDetailsError: "",

  addWorkflowDetails: {},
  isLoadingAddWorkflowDetails: false,
  addWorkflowDetailsError: "",

  setWorkflowDetails: {},
  isLoadingSetWorkflowDetails: false,
  setWorkflowDetailsError: "",

  addUserPersonaDetails: {},
  isLoadingAddUserPersonaDetails: false,
  addUserPersonaDetailsError: "",

  selectedUserPersona: {
    id: [],
    data: [],
  },

  // selectedUserPersonaFromCSV: [],
  createdStrategy: {},

  // createdWorkFlows: [],

  strategyOptionsDetails: {},
  isLoadingStrategyOptions: false,
  strategyOptionsDetailsError: "",

  userPersonaOptions: {},
  isLoadingUserPersonaOptions: false,
  userPersonaOptionsError: "",
};

const strategyReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.GET_STRATEGIES_DATA_API_REQUEST: {
      return {
        ...state,
        strategyDetails: {},
        isLoadingStrategyDetails: true,
        strategyDetailsError: "",
      };
    }
    case actionTypes.GET_STRATEGIES_DATA_API_SUCCESS: {
      return {
        ...state,
        isLoadingStrategyDetails: false,
        strategyDetails: action.payload,
      };
    }
    case actionTypes.GET_STRATEGIES_DATA_API_FAILURE: {
      return {
        ...state,
        isLoadingStrategyDetails: false,
        strategyDetailsError: action.payload,
      };
    }

    case actionTypes.DELETE_STRATEGY_DATA_API_REQUEST: {
      return {
        ...state,
        isLoadingDeleteStrategy: true,
        deleteStrategyError: "",
      };
    }

    case actionTypes.DELETE_STRATEGY_DATA_API_SUCCESS: {
      return {
        ...state,
        isLoadingDeleteStrategy: false,
      };
    }

    case actionTypes.DELETE_STRATEGY_DATA_API_FAILURE: {
      return {
        ...state,
        isLoadingDeleteStrategy: false,
        deleteStrategyError: action.payload,
      };
    }

    case actionTypes.UPDATE_STRATEGY_DATA_API_REQUEST: {
      return {
        ...state,
        isLoadingUpdateStrategy: true,
        updateStrategyError: "",
      };
    }

    case actionTypes.UPDATE_STRATEGY_DATA_API_SUCCESS: {
      return {
        ...state,
        isLoadingUpdateStrategy: false,
      };
    }

    case actionTypes.UPDATE_STRATEGY_DATA_API_FAILURE: {
      return {
        ...state,
        isLoadingUpdateStrategy: false,
        updateStrategyError: action.payload,
      };
    }

    case actionTypes.CREATE_STRATEGY_DATA_API_REQUEST: {
      return {
        ...state,
        isLoadingCreateStrategy: true,
        createStrategyError: "",
      };
    }

    case actionTypes.CREATE_STRATEGY_DATA_API_SUCCESS: {
      return {
        ...state,
        isLoadingCreateStrategy: false,
      };
    }

    case actionTypes.CREATE_STRATEGY_DATA_API_FAILURE: {
      return {
        ...state,
        isLoadingCreateStrategy: false,
        createStrategyError: action.payload,
      };
    }

    case actionTypes.GET_USER_PERSONA_DATA_API_REQUEST: {
      return {
        ...state,
        userPersonaDetails: {},
        isLoadingUserPersonaDetails: true,
        userPersonaDetailsError: "",
      };
    }
    case actionTypes.GET_USER_PERSONA_DATA_API_SUCCESS: {
      return {
        ...state,
        isLoadingUserPersonaDetails: false,
        userPersonaDetails: action.payload,
      };
    }
    case actionTypes.GET_USER_PERSONA_DATA_API_FAILURE: {
      return {
        ...state,
        isLoadingUserPersonaDetails: false,
        userPersonaDetailsError: action.payload,
      };
    }

    case actionTypes.GET_WORK_FLOWS_DATA_API_REQUEST: {
      return {
        ...state,
        isLoadingWorkflowDetails: true,
        workflowDetailsError: "",
      };
    }
    case actionTypes.GET_WORK_FLOWS_DATA_API_SUCCESS: {
      return {
        ...state,
        isLoadingWorkflowDetails: false,
        workflowDetails: action.payload,
      };
    }
    case actionTypes.GET_WORK_FLOWS_DATA_API_FAILURE: {
      return {
        ...state,
        isLoadingWorkflowDetails: false,
        workflowDetailsError: action.payload,
      };
    }

    case actionTypes.ADD_WORK_FLOW_DATA_API_REQUEST: {
      return {
        ...state,
        isLoadingAddWorkflowDetails: true,
        addWorkflowDetailsError: "",
      };
    }

    case actionTypes.ADD_WORK_FLOW_DATA_API_SUCCESS: {
      return {
        ...state,
        isLoadingAddWorkflowDetails: false,
        addWorkflowDetails: action.payload,
      };
    }

    case actionTypes.ADD_WORK_FLOW_DATA_API_FAILURE: {
      return {
        ...state,
        isLoadingAddWorkflowDetails: false,
        addWorkflowDetailsError: action.payload,
      };
    }

    case actionTypes.SET_WORK_FLOW_DATA_API_REQUEST: {
      return {
        ...state,
        isLoadingSetWorkflowDetails: true,
        setWorkflowDetailsError: "",
      };
    }

    case actionTypes.SET_WORK_FLOW_DATA_API_SUCCESS: {
      return {
        ...state,
        isLoadingSetWorkflowDetails: false,
        setWorkflowDetails: action.payload,
      };
    }

    case actionTypes.SET_WORK_FLOW_DATA_API_FAILURE: {
      return {
        ...state,
        isLoadingSetWorkflowDetails: false,
        setWorkflowDetailsError: action.payload,
      };
    }

    case actionTypes.ADD_USER_PERSONA_DATA_API_REQUEST: {
      return {
        ...state,
        isLoadingAddUserPersonaDetails: true,
        addUserPersonaDetailsError: "",
      };
    }

    case actionTypes.ADD_USER_PERSONA_DATA_API_SUCCESS: {
      return {
        ...state,
        isLoadingAddUserPersonaDetails: false,
      };
    }

    case actionTypes.ADD_USER_PERSONA_DATA_API_FAILURE: {
      return {
        ...state,
        isLoadingAddUserPersonaDetails: false,
        addUserPersonaDetailsError: action.payload,
      };
    }

    case actionTypes.SELECT_USER_PERSONA: {
      return { ...state, selectedUserPersona: action.payload };
    }

    // case actionTypes.SELECTED_USER_PERSONA_FROM_CSV: {
    //   return { ...state, selectedUserPersonaFromCSV: action.payload };
    // }

    case actionTypes.STORE_CREATED_STRATEGY: {
      return { ...state, createdStrategy: action.payload };
    }

    // case actionTypes.STORE_CREATED_WORKFLOWS: {
    //   return {
    //     ...state,
    //     createdWorkFlows: action.payload,
    //   };
    // }

    // case actionTypes.GET_UPLOADED_STRATEGY: {
    //   return { ...state, getuploadedfile: action.payload };
    // }

    case actionTypes.GET_STRATEGY_OPTIONS_API_REQUEST: {
      return {
        ...state,
        strategyOptionsDetails: {},
        isLoadingStrategyOptions: true,
        strategyOptionsDetailsError: "",
      };
    }
    case actionTypes.GET_STRATEGY_OPTIONS_API_SUCCESS: {
      return {
        ...state,
        isLoadingStrategyOptions: false,
        strategyOptionsDetails: action.payload,
      };
    }
    case actionTypes.GET_STRATEGY_OPTIONS_API_FAILURE: {
      return {
        ...state,
        isLoadingStrategyOptions: false,
        strategyOptionsDetailsError: action.payload,
      };
    }

    case actionTypes.GET_USER_PERSONA_OPTIONS_API_REQUEST: {
      return {
        ...state,
        userPersonaOptions: {},
        isLoadingUserPersonaOptions: true,
        userPersonaOptionsError: "",
      };
    }
    case actionTypes.GET_USER_PERSONA_OPTIONS_API_SUCCESS: {
      return {
        ...state,
        isLoadingUserPersonaOptions: false,
        userPersonaOptions: action.payload,
      };
    }
    case actionTypes.GET_USER_PERSONA_OPTIONS_API_FAILURE: {
      return {
        ...state,
        isLoadingUserPersonaOptions: false,
        userPersonaOptionsError: action.payload,
      };
    }

    case actionTypes.CLEAR_STRATEGY_DATA: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
};

export default strategyReducer;
