// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vG5dg1vAmOi0iXlZHSkc{padding:1em;width:100%;height:100%}.vG5dg1vAmOi0iXlZHSkc .QDpTfeJP6J0jR4I5_N1_{border-bottom:1px solid #e6e6e6}.vG5dg1vAmOi0iXlZHSkc .QDpTfeJP6J0jR4I5_N1_ .EbhpGxo_ZWET4_08vqpY{font-family:"Roboto";font-style:normal;font-weight:500;font-size:20px;line-height:120%;color:#333;padding:.5em 0em 1em 0em}.vG5dg1vAmOi0iXlZHSkc .nJUjc8kDaiX9ugpsCZZO{display:flex;justify-content:space-between;align-items:flex-start;padding-top:1.75em}.vG5dg1vAmOi0iXlZHSkc .nJUjc8kDaiX9ugpsCZZO .CoNEilql_QZszsEGcIZ4{width:54%}.vG5dg1vAmOi0iXlZHSkc .nJUjc8kDaiX9ugpsCZZO .K_YHtBbRoLeELfroIWEP{width:42%}`, "",{"version":3,"sources":["webpack://./src/screens/profile/paymentScreen/orderSummaryScreen/OrderSummaryScreen.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,UAAA,CACA,WAAA,CAEA,4CACE,+BAAA,CAEA,kEACE,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA,CACA,wBAAA,CAIJ,4CACE,YAAA,CACA,6BAAA,CACA,sBAAA,CACA,kBAAA,CAEA,kEACE,SAAA,CAEF,kEACE,SAAA","sourcesContent":[".orderSummaryScreenWrapper {\n  padding: 1em;\n  width: 100%;\n  height: 100%;\n\n  .orderScreenHeader {\n    border-bottom: 1px solid #e6e6e6;\n\n    .orderScreenHeaderText {\n      font-family: \"Roboto\";\n      font-style: normal;\n      font-weight: 500;\n      font-size: 20px;\n      line-height: 120%;\n      color: #333333;\n      padding: 0.5em 0em 1em 0em;\n    }\n  }\n\n  .orderSummaryScreen {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n    padding-top: 1.75em;\n\n    .plansummaryClass {\n      width: 54%;\n    }\n    .ordersummaryClass {\n      width: 42%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orderSummaryScreenWrapper": `vG5dg1vAmOi0iXlZHSkc`,
	"orderScreenHeader": `QDpTfeJP6J0jR4I5_N1_`,
	"orderScreenHeaderText": `EbhpGxo_ZWET4_08vqpY`,
	"orderSummaryScreen": `nJUjc8kDaiX9ugpsCZZO`,
	"plansummaryClass": `CoNEilql_QZszsEGcIZ4`,
	"ordersummaryClass": `K_YHtBbRoLeELfroIWEP`
};
export default ___CSS_LOADER_EXPORT___;
