import React, { useEffect, useState } from "react";
// import { useReactToPrint } from "react-to-print";
import "./DownloadComponent.scss";
// import { quest } from "./../../../theme/assets/svg/rightSideIcon";
import {
  setDownloadViewAll,
  setDownloadOptionsAndDownload,
  setShowRightSideBar,
  getAllReportCsvData,
} from "../../../redux/filters/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { useCurrentPageTab } from "../../../hooks";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { Mixpanel } from "../../../utils/mixpanelSetup";
import { format } from "date-fns";
import PravidIcons from "../../generic/pravidIcons/PravidIcons";
import {
  setdurationDownloadReport,
  setCustomiseDownloadReport,
} from "../../../redux/logger/report/actions";
import PopupCalenderfilter from "../../moduleComponents/popupCalenderfilter/PopupCalenderfilter";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { ROLES } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#fff",
    borderRadius: "1.20455px",
    color: "#333",
    fontSize: "13px",
    fontFamily: "Roboto",
    fontWeight: 400,
    overflowWrap: "break-word",
    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1)",
  },
  customArrow: {
    color: "#fff",
    fontSize: 9,
  },
}));

interface EachObj {
  name: string;
  about: string;
  format: string;
}
interface Props {
  radioOptions: Array<any>;
  setSelectedIcon: any;
  // downloadFormats: Array<string>;
}

export default function DownloadComponent(props: Props) {
  const classes = useStyles();
  const calender = useSelector((state: any) => {
    return state.filterReducers?.calenderData;
  });
  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );
  const accountDetails = useSelector(
    (store: any) => store.loginReducer?.userLoginInfo?.accountDetails[0]
  );
  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const selectedFilterOptions = useSelector((state: RootState) => {
    return state.filterReducers?.selectedFilterOptions;
  });
  const filterSelectedDate = useSelector(
    (store: RootState) => store?.filterReducers?.calenderData
  );
  const currentSelectedDate = useSelector(
    (store: RootState) => store.filterReducers.calenderData
  );
  const userId = useSelector(
    (state: RootState) => state.loginReducer.userLoginInfo?.userDetail?._id
  );
  const currentUseCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseId
  );
  const activePageType = useSelector(
    (state: RootState) => state.loginReducer.isActivePageType
  );
  const userRole: any = useSelector((state: any) => {
    return state?.loginReducer?.userLoginInfo?.userDetail?.role;
  });
  const currentUser = useSelector((state: RootState) => {
    return state.filterReducers.selectedFilterOptions.clientName;
  });
  const selectedFilters = useSelector(
    (state: RootState) => state?.filterReducers?.selectedFilterOptions
  );
  const currentTeamId = useSelector(
    (state: RootState) => state.loginReducer.currentTeam.id
  );
  const currentUseCaseName = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseName
  );
  const accountType = useSelector((state: any) => {
    return state.loginReducer?.userLoginInfo?.accountDetails[0]?.type;
  });
  const userDetails: any = useSelector((state: any) => {
    return state.loginReducer.userLoginInfo.userDetail;
  });

  const {
    schema,
    isActivePageType,
    subChannelTab,
    subModuleTab,
    channelTab,
    currentLoggerPage,
  } = useCurrentPageTab();

  const conversationId = useSelector(
    (state: RootState) =>
      state.loggerReducer?.transcriptReducer?.currentSession?.conversationId
  );
  const [selectedTypeOfDownload, setSelectedTypeOfDownload] =
    useState<number>(0);
  const [selectedDownloadFormat, setSelectedDownloadFormat] =
    useState<number>(0);
  const [formatOfDownload, setFormatOfDownlaod] = useState<any>();
  const [selectKeyDownload, setselectKeyDownload] = useState<any>("");
  const [customizeDate, setCustomizeDate] = useState<any>({});
  const dispatch = useDispatch();

  console.log("hey", selectKeyDownload);
  /**--for current date download--**/

  const handleDirectDownload = () => {
    //dispatch(setShowRightSideBar(false));
    toast.success("Please Wait...Download In Progress");
    dispatch(
      getAllReportCsvData(
        {
          dategte: moment(new Date()).format("YYYY-MM-DD") + "T00:00:00",
          datelte: moment(new Date()).format("YYYY-MM-DD") + "T23:59:59",
          channelId: [channelTab.id],
          useCaseId: [currentUseCaseId],
          tabId: [subModuleTab.id],
          channel: [channelTab.name],
          subModule: subModuleTab.name,
          ...(channelTab?.name === "Whatsapp" || channelTab?.name === "Chat"
            ? { subChannelId: [subChannelTab.id] }
            : {}),
          ...(channelTab?.name === "Whatsapp" || channelTab?.name === "Chat"
            ? { communicationType: [subChannelTab.name] }
            : {}),
          userId: userId,
          accountType: accountType,
          teamId: currentTeamId,
          ...selectedFilterOptions,
          clientName:
            (userRole.toString().toLowerCase() ===
              ROLES.campaignAnalyst.name.toLowerCase() ||
              userRole.toString().toLowerCase() === ROLES.campaignManager.name.toLowerCase()) &&
            currentUser?.[0]?.name
              ? currentUser[0].name
              : accountName,
          role: userRole,
        },
        dispatch,
        token
      )
    );
  };
  /**--end--* */

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const { typeofdownload, formattype } = e.target as typeof e.target & {
      typeofdownload: { value: number };
      formattype: { value: number };
    };

    dispatch(setShowRightSideBar(false));
    if (activePageType === "Analytics" && typeofdownload.value == 0) {
      toast.success("Please Wait ... Downloading ");
      dispatch(setDownloadViewAll("Active"));
      dispatch(setShowRightSideBar(false));
    } else if (
      currentLoggerPage === "Transcript" &&
      isActivePageType !== "Analytics"
    ) {
      if (typeofdownload.value == 0) {
        dispatch(
          setDownloadOptionsAndDownload(
            {
              conversationId: conversationId,
              dategte:
                moment(filterSelectedDate.startDate).format("YYYY-MM-DD") +
                "T00:00:00",
              datelte:
                moment(filterSelectedDate.endDate).format("YYYY-MM-DD") +
                "T23:59:59",
              channelId: [channelTab.id],
              useCaseId: [currentUseCaseId],
              source: [currentUseCaseName.split(" ")[0]],
              tabId: [subModuleTab.id],

              ...(channelTab === "Whatsapp" || channelTab === "Chat"
                ? { subChannelId: [subChannelTab.id] }
                : {}),
              userId: userId,
              teamId: currentTeamId,
              ...selectedFilters,
              accountName:
                (userRole.toString().toLowerCase() ===
                  ROLES.campaignAnalyst.name.toLowerCase() ||
                  userRole.toString().toLowerCase() === ROLES.campaignManager.name.toLowerCase()) &&
                currentUser?.[0]?.name
                  ? currentUser[0].name
                  : accountName,
              clientName: undefined,
              accountType: accountType,
              role: userRole,
            },
            token
          )
        );
        Mixpanel.track("Logger | Transcript | Download", {
          "user Name": accountName,
          Date: `${format(new Date(), "dd-MM-yyyy")}`,
          Page: "Transcript",
          "Type of Download Description": "Current",
        });
      } else {
        dispatch(
          setDownloadOptionsAndDownload(
            {
              dategte:
                moment(filterSelectedDate.startDate).format("YYYY-MM-DD") +
                "T00:00:00",
              datelte:
                moment(filterSelectedDate.endDate).format("YYYY-MM-DD") +
                "T23:59:59",
              channelId: [channelTab.id],
              useCaseId: [currentUseCaseId],
              source: [currentUseCaseName.split(" ")[0]],
              tabId: [subModuleTab.id],
              channel: [channelTab.name],
              subModule: subModuleTab.name,
              ...(channelTab?.name === "Whatsapp" || channelTab?.name === "Chat"
                ? { subChannelId: [subChannelTab.id] }
                : {}),
              ...(channelTab?.name === "Whatsapp" || channelTab?.name === "Chat"
                ? { communicationType: [subChannelTab.name] }
                : {}),
              userId: userId,
              teamId: currentTeamId,
              ...selectedFilters,
              accountName:
                (userRole.toString().toLowerCase() ===
                  ROLES.campaignAnalyst.name.toLowerCase() ||
                  userRole.toString().toLowerCase() === ROLES.campaignManager.name.toLowerCase()) &&
                currentUser?.[0]?.name
                  ? currentUser[0].name
                  : accountName,
              clientName: undefined,
              accountType: accountType,
              role: userRole,
            },
            token
          )
        );
        Mixpanel.track("Logger | Transcript | Download", {
          "user Name": accountName,
          Date: `${format(new Date(), "dd-MM-yyyy")}`,
          Page: "Transcript",
          "Type of Download Description": "Bulk",
        });
      }

      //
    } else if (
      currentLoggerPage == "Report" &&
      isActivePageType !== "Analytics"
    ) {
      if (typeofdownload.value > 0) {
        if (selectKeyDownload === "Today") {
          handleDirectDownload();
        } else {
          const body = {
            channelId: [channelTab.id],
            channel: [channelTab.name],
            subModule: subModuleTab.name,
            useCaseId: [currentUseCaseId],
            tabId: [subModuleTab.id],
            accountType: accountType,

            ...(channelTab.name === "Whatsapp" || channelTab.name === "Chat"
              ? { subChannelId: [subChannelTab?.id] }
              : {}),
            ...(channelTab?.name === "Whatsapp" || channelTab?.name === "Chat"
              ? { communicationType: [subChannelTab.name] }
              : {}),
            userId: userId,
            teamId: currentTeamId,
            ...selectedFilterOptions,
            clientName:
              (userRole.toString().toLowerCase() ===
                ROLES.campaignAnalyst.name.toLowerCase() ||
                userRole.toString().toLowerCase() === ROLES.campaignManager.name.toLowerCase()) &&
              currentUser?.[0]?.name
                ? currentUser[0].name
                : accountName,
            duration: selectKeyDownload,
            accountId: currentUser?.[0]?.id
              ? currentUser?.[0]?.id
              : accountDetails?.id,
            role: userRole,
          };
          dispatch(setdurationDownloadReport(body, token));
        }
      } else {
        const body = {
          dategte: customizeDate?.startDate,
          datelte: customizeDate?.endDate,
          channelId: [channelTab.id],
          channel: [channelTab.name],
          subModule: subModuleTab.name,
          useCaseId: [currentUseCaseId],
          tabId: [subModuleTab.id],
          accountType: accountType,

          ...(channelTab.name === "Whatsapp" || channelTab.name === "Chat"
            ? { subChannelId: [subChannelTab?.id] }
            : {}),
          ...(channelTab?.name === "Whatsapp" || channelTab?.name === "Chat"
            ? { communicationType: [subChannelTab.name] }
            : {}),
          userId: userId,
          teamId: currentTeamId,
          ...selectedFilterOptions,
          clientName:
            (userRole.toString().toLowerCase() ===
              ROLES.campaignAnalyst.name.toLowerCase() ||
              userRole.toString().toLowerCase() === ROLES.campaignManager.name.toLowerCase()) &&
            currentUser?.[0]?.name
              ? currentUser[0].name
              : accountName,
          shareTo: userDetails?.email,
          role: userRole,
        };
        dispatch(setCustomiseDownloadReport(body, token));
      }
    } else {
      toast.success("Please Wait ... Downloading ");
      dispatch(
        getAllReportCsvData(
          {
            dategte:
              moment(filterSelectedDate.startDate).format("YYYY-MM-DD") +
              "T00:00:00",
            datelte:
              moment(filterSelectedDate.endDate).format("YYYY-MM-DD") +
              "T23:59:59",
            channelId: [channelTab.id],
            channel: [channelTab.name],
            subModule: subModuleTab.name,
            useCaseId: [currentUseCaseId],
            source: [currentUseCaseName.split(" ")[0]],
            tabId: [subModuleTab.id],
            accountType: accountType,

            ...(channelTab.name === "Whatsapp" || channelTab.name === "Chat"
              ? { subChannelId: [subChannelTab?.id] }
              : {}),
            ...(channelTab?.name === "Whatsapp" || channelTab?.name === "Chat"
              ? { communicationType: [subChannelTab.name] }
              : {}),
            userId: userId,
            teamId: currentTeamId,
            ...selectedFilterOptions,
            clientName:
              (userRole.toString().toLowerCase() ===
                ROLES.campaignAnalyst.name.toLowerCase() ||
                userRole.toString().toLowerCase() === ROLES.campaignManager.name.toLowerCase()) &&
              currentUser?.[0]?.name
                ? currentUser[0].name
                : accountName,
            role: userRole,
          },
          dispatch,
          token
        )
      );
    }
    props?.setSelectedIcon("");
  }

  const handleChange = (e: any, i: number) => {
    setSelectedTypeOfDownload(+i);
    setselectKeyDownload(e);
  };
  const handleChangeFormat = (e: any, i: number) => {
    setSelectedDownloadFormat(+i);
  };

  useEffect(() => {
    //  / if//
    setFormatOfDownlaod(props.radioOptions[selectedTypeOfDownload]["format"]);
  }, [props.radioOptions, selectedTypeOfDownload]);

  // useEffect(() => {
  //   //  / if//
  //   setFormatOfDownlaod(props.radioOptions[0]["format"]);
  //   console.log(formatOfDownload, "dfddd", props.radioOptions[0]["format"]);
  // }, [props.radioOptions]);

  // useEffect(() => {
  //   console.log("why this is changeing", currentLoggerPage)
  //   // if (currentLoggerPage === "Report") dispatch(setShowRightSideBar(false));
  // }, [currentLoggerPageFromReducer]);
  /** Report date customization **/
  const getDateChnage = (start: any, end: any) => {
    const dateRanges = {
      startDate: moment(start).format("YYYY-MM-DD") + "T00:00:00",
      endDate: moment(end).format("YYYY-MM-DD") + "T23:59:59",
    };
    setCustomizeDate(dateRanges);
    console.log("fgf", dateRanges);
  };

  return (
    <div className="downloadcompoennetwrapper">
      <form action="" onSubmit={handleSubmit}>
        {props.radioOptions?.map((e, i) => {
          return (
            <>
              <div
                className={
                  "eachoptions" +
                  (selectedTypeOfDownload === i ? " eachoptionsblue " : "")
                }
                key={i}
              >
                <div className={"inputareadiv"}>
                  <input
                    type="radio"
                    name="typeofdownload"
                    value={i}
                    onChange={() => {
                      handleChange(e?.keyName, i);
                    }}
                    defaultChecked={selectedTypeOfDownload === i}
                  />
                  <p>{e?.keyName}</p>
                </div>
                <div className="tooltip">
                  {/* <img src={quest} alt="" /> */}

                  {/* <span className="tooltiptext">
                    <div className="tooltipteaxt-div">
                      <p className="tooltiptext-head">{e?.keyName} </p>
                      <p className="tooltiptext-about">{e?.info}</p>
                    </div>
                  </span> */}
                  <Tooltip
                    title={
                      <div className="downloadToolTipDiv">
                        <p className="downloadToolTipHeaderDiv">
                          {e?.keyName}{" "}
                        </p>
                        <p className="downloadToolTipAbtDiv">{e?.info}</p>
                      </div>
                    }
                    placement={"top-end"}
                    classes={{
                      arrow: classes.customArrow,
                      tooltip: classes.customTooltip,
                    }}
                    arrow={true}
                  >
                    <div className={`${"downloadTooltipStylingWrapper"}`}>
                      <PravidIcons
                        activeIcon={"quest"}
                        extraClass={"iconstyling"}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
              {e?.type === "calendar" && selectedTypeOfDownload === 0 && (
                <PopupCalenderfilter
                  customizeDateFilter={true}
                  handleCustomizeDate={getDateChnage}
                />
              )}
            </>
          );
        })}

        <div className="divideder"></div>
        <p className="formates">Format</p>
        <div className="formatsdivopt">
          {/* {props.downloadFormats.map((e, i) => {
            return (
              <div
                className={
                  "formatsofinput" +
                  (selectedDownloadFormat === i ? " formatsofinputblue" : "")
                }
                key={i}
              >
                {" "}
                <input
                  type="radio"
                  name="formattype"
                  onChange={(e) => {
                    handleChangeFormat(e, i);
                  }}
                  value={i}
                  defaultChecked={selectedDownloadFormat === i}
                />
                <p> {e}</p>
              </div>
            );
          })} */}
          <div className={"formatsofinput" + " formatsofinputblue"}>
            <input
              type="radio"
              name="formattype"
              // onChange={(e) => {
              //   handleChangeFormat(e, i);
              // }}
              //value={i}
              defaultChecked={true}
            />

            <p>{formatOfDownload}</p>
          </div>
        </div>
        <button type="submit" className="blueclorbtn">
          Download
        </button>
      </form>
    </div>
  );
}
