import React, { useState, useEffect } from "react";
import styles from "./CalendarComponent.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setDateFilterData } from "../../../../redux/filter/actions";
import moment from "moment";
import { format } from "date-fns";
import CalendarPopUp from "./calendarPopUp/CalendarPopUp";
import { toConvertCalendarDate } from "../../../../utils/ConvertTime";
import {
  calendarIcon,
  calendarIconGray,
} from "../../../../theme/assets/svg/campaign_v3/index";
import Icon from "../../../generic/icon/Icon";
import { useLocation } from "react-router-dom";

export const CalendarComponent = (props: any) => {
  const filteredDateRangeData = useSelector(
    (store: RootState) => store.filterReducer?.filteredDateRangeData
  );

  const [dateRange, setDateRange] = useState([
    {
      startDate:
        props?.showDefaultTime && filteredDateRangeData
          ? moment(filteredDateRangeData?.fromDate).toDate()
          : null,
      endDate:
        props?.showDefaultTime && filteredDateRangeData
          ? moment(filteredDateRangeData?.toDate).toDate()
          : null,
      key: "selection",
    },
  ]);

  const [isCalendarOn, setIsCalendarOn] = useState(false);
  const [totalDays, setTotalDays] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    // Update dateRange when filteredDateRangeData changes
    setDateRange([
      {
        startDate: filteredDateRangeData
          ? moment(filteredDateRangeData?.fromDate).toDate()
          : null,
        endDate: filteredDateRangeData
          ? moment(filteredDateRangeData?.toDate).toDate()
          : null,
        key: "selection",
      },
    ]);
    setTotalDays(totalDays?.length > 0 ? totalDays : "0 days");
  }, [location.pathname, filteredDateRangeData]);

  const dateFormater = (item: any) => {
    const startDate = moment(item.selection.startDate).toDate();
    const endDate = moment(item.selection.endDate).toDate();
    const daysSelected = moment(endDate).diff(moment(startDate), "days") + 1;
    setTotalDays(`${daysSelected} ${daysSelected === 1 ? "day" : "days"}`);
    setDateRange([item.selection]);
  };
  const dispatch = useDispatch();

  // To Close the calendar
  const handleClickCloseCalendar = (value: boolean) => {
    setIsCalendarOn(value);
    return false;
  };

  const handleSumitDateRange = async () => {
    if (props.onChangeValue) {
      props.getonChangeValue({
        fromDate: format(new Date(dateRange[0].startDate), "yyyy-MM-dd"),
        toDate: format(new Date(dateRange[0].endDate), "yyyy-MM-dd"),
      });

      setDateFilterData({
        fromDate: format(new Date(dateRange[0].startDate), "yyyy-MM-dd"),
        toDate: format(new Date(dateRange[0].endDate), "yyyy-MM-dd"),
      })(dispatch);

      setIsCalendarOn((previousState) => !previousState);
    } else {
      if (dateRange[0].startDate && dateRange[0].endDate) {
        const today = format(new Date(), "yyy-MM-dd");
        const from_date = dateRange[0].startDate
          ? format(new Date(dateRange[0].startDate), "yyyy-MM-dd")
          : today;
        const to_date = dateRange[0].endDate
          ? format(new Date(dateRange[0].endDate), "yyyy-MM-dd")
          : today;
        setDateFilterData({ fromDate: from_date, toDate: to_date })(dispatch);
        setIsCalendarOn((previousState) => !previousState);
      }
    }
  };

  return (
    <>
      <div className={styles.calendarComponentWrapper}>
        <div
          className={styles.dateFilterHeaderDiv}
          onClick={() => {
            setIsCalendarOn((previousState) => !previousState);
          }}
        >
          <Icon
            img_src={
              filteredDateRangeData?.fromDate !== null &&
              filteredDateRangeData?.endDate !== null
                ? calendarIcon
                : calendarIconGray
            }
          />
          <div>
            {filteredDateRangeData?.fromDate !== null &&
            filteredDateRangeData?.endDate !== null ? (
              <div>
                {filteredDateRangeData?.fromDate
                  ? moment(filteredDateRangeData.fromDate).format("DD/MM/YYYY")
                  : toConvertCalendarDate(dateRange[0].startDate)}{" "}
                &nbsp;- &nbsp;
                {filteredDateRangeData?.toDate
                  ? moment(filteredDateRangeData.toDate).format("DD/MM/YYYY")
                  : toConvertCalendarDate(dateRange[0].endDate)}
              </div>
            ) : (
              <div className={styles.placeHolder}>Select Date Range</div>
            )}
          </div>
        </div>

        <div className={styles.calendarWrapper}>
          {isCalendarOn && (
            <CalendarPopUp
              dateRange={dateRange}
              dateFormater={(value) => dateFormater(value)}
              handleClickCloseCalendar={(value: boolean) =>
                handleClickCloseCalendar(value)
              }
              handleSumitDateRange={() => handleSumitDateRange()}
              calenderOpen={isCalendarOn}
              disableRange={props.disableRangeMin}
              typeText={props.typeText}
              totalDays={totalDays}
              extraStyle={props.extraPopUpStyling}
            />
          )}
        </div>
      </div>
    </>
  );
};
