import { takeLatest } from "redux-saga/effects";
import actionTypes from "../actionTypes/completedCampaign.actions";
import { getAllCampaignCompletedApiWorker } from "./completedCampaign.saga";

export default function* completedCampaignWorker(): any {
  yield takeLatest(
    actionTypes.GET_COMPLETED_CAMPAIGNS_API_CALL,
    getAllCampaignCompletedApiWorker
  );
  //yield takeEvery(actionTypes.SET_ALL_PHONE_NUMBER, setAllPhoneList);
}
