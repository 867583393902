import React, { useCallback, useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../charts/colors";

function DonutPieChart(props: any) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  //   const expandedChart = useSelector(
  //     (store) => store?.analyticsReducer?.expandedChart
  //   );

  const onPieEnter = useCallback(
    (_: any, index: any) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const width = parseInt(props.chartDetails?.chartStyle?.width) - 180;
  const height = parseInt(props.chartDetails?.chartStyle?.height) - 220;

  useEffect(() => {
    if (props.donutChartDetails) {
      const temp = props.donutChartDetails.map((each: any, i: number) => {
        return {
          name: each.name,
          value: props.data && props?.data[each?.referenceKeyName],
          fill: Colors[i % Colors.length],
          percent:
            props.totalCallCount &&
            `${(
              (props?.data[each?.referenceKeyName] / props.totalCallCount) *
              100
            ).toFixed(0)}%`,
        };
      });
      setData(temp);
    }
  }, [props.donutChartDetails, props.data]);

  //   useEffect(() => {
  //     if (props.data && props.donutChart) {
  //       let temp = 0;
  //       for (let val of props.donutChart) {
  //         temp = temp + props?.data[val?.referenceKeyName];
  //       }
  //       setTotal(temp);
  //     }
  //   }, [props.data, props.donutChart]);
  // const data = [
  //     { name: "Received Call", value: 45 },
  //     { name: "Missed Call", value: 30 },
  //     { name: "Group C", value: 30 },
  //     { name: "Group D", value: 25 }
  //   ];

  //   const getIsAngleRequired = () => {
  //     let temp = [];
  //     let val = data.map((each, i) => {
  //       if (each.value > 0) {
  //         temp.push(i);
  //       }
  //     });
  //     if (temp.length > 0) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   };

  return (
    <>
      <PieChart width={370} height={210}>
        <Pie
          data={data}
          cx={"60%"}
          cy={"50%"}
          innerRadius={70}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          stroke=""
          // label={renderActiveShape}
        >
          {data.map((entry: any, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Pie>
        <Tooltip content={CustomTooltip} wrapperStyle={{ outline: "none" }} />
      </PieChart>
    </>
  );
}
const CustomTooltip = (props: any) => {
  // if (active) {
  return (
    <div
      className="custom-tooltip"
      style={{
        background: "#ffffff",
        height: "20%",
        width: "100%",
        textAlign: "center",
        padding: "10px",
        fontSize: "14px",
      }}
    >
      <p className="label">{`${props.payload[0]?.name} ${props.payload[0]?.payload?.payload?.percent}`}</p>
    </div>
  );
  // }
  return <></>;
};
export default DonutPieChart;
