import React, { useEffect, useState } from "react";
import styles from "./SimpleTable.module.scss";
import { cursorTo } from "readline";
import { sort } from "../../../../theme/assets/svg";
import _ from "lodash";
import { sortTableData } from "../../../../utils";
type ele = "string" | "number";
type headTransType = Array<{
  key: string;
  displayName: any;
  sort?: boolean;
  type?: ele; //sort type
  transform?: (a: any) => any;
}>;
interface propOfTable {
  dataToShowOnTable: Array<any>; //array of objects from api(data)
  bodyTranformer: Array<{ key: string; transform: (a: any) => any }>; //how particular key value will change and mapped
  headingTranformer: headTransType; // hwo heading will change
  onClickOfRow?: any; //row wise click
  exptraBodyClass?: string;
  extraHeaderClass?: string;
  extraClassWrapper?: string;
  donotDisplayBreak?: boolean;
  centerAlign?: boolean;
}
export { headTransType };
/**
 * @example
 * datas = [
  {
    customerName: "Mango",
    phone: "0839893324",
    loanID: "3847384834380",
    loanAmount: "$209392",
    emiAmount: "$402832",
    emiDate: "12th Aug 2022",
    risk: "Low",
  },
  {
    customerName: "Mango",
    phone: "0839893324",
    loanID: "3847384834380",
    loanAmount: "$209392",
    emiAmount: "$402832",
    emiDate: "12th Aug 2022",
    risk: "Low",
  },]
  ];

export const bodyTransformer = [
  { key: "customerName", transform: (value: any) => value },
  { key: "phone", transform: (value: any) => value },
  { key: "loanID", transform: (value: any) => value },
  { key: "loanAmount", transform: (value: any) => value },
  { key: "emiAmount", transform: (value: any) => value },
  { key: "emiDate", transform: (value: any) => value },
  {
    key: "risk",
    transform: (value: any) => {
      if (value == "Low")
        return (
          <span
            style={{
              color: "#07B464",
              background: "#32936F29",
              padding: " 4px 5px ",
              borderRadius: "3px",
              display: "inline-block",

              width: "65px",
              textAlign: "center",
            }}
          >
            Low
          </span>
        );
      if (value == "High")
        return (
          <span
            style={{
              color: "#ED2121",
              background: "#ED212129",
              padding: " 4px 5px ",
              textAlign: "center",
              display: "inline-block",
              width: "65px",
              borderRadius: "3px",
            }}
          >
            High
          </span>
        );
      if (value == "Medium")
        return (
          <span
            style={{
              color: "#DCA10B",
              background: "#DCA10B29",

              padding: " 4px 5px ",
              display: "inline-block",
              borderRadius: "3px",
              width: "65px",
              textAlign: "center",
            }}
          >
            Medium
          </span>
        );
    },
  },
];
export const headTranformer = [
  { key: "customerName", displayName: "Customer Name" },
  { key: "phone", displayName: "Phone" },
  { key: "loanID", displayName: "Laod Id" },
  { key: "loanAmount", displayName: "Load Amount" },
  { key: "emiAmount", displayName: "EMI Amount" },
  { key: "emiDate", displayName: "EMI Date" },
  { key: "risk", displayName: "Risk" },
];
 * @param props 
 * @returns 
 */
export default function SimpleTable(props: propOfTable) {
  const [data, setData] = useState<any>(props.dataToShowOnTable);
  const [sortList, setSortList] = useState<Record<string, boolean>>({});
  useEffect(() => {
    setData((prev: any) =>
      props.dataToShowOnTable ? props.dataToShowOnTable : []
    );
  }, [props.dataToShowOnTable]);
  function handleSort(key: string, type: string) {
    const datax = _.cloneDeep(data);
    if (type == "number") {
      setData(
        //sorting based on records
        datax.sort((a: any, b: any) => {
          return sortList[key]
            ? Number(a[key]) - Number(b[key])
            : Number(b[key]) - Number(a[key]);
        })
      );
      setSortList({ ...sortList, [key]: !sortList[key] });
    } else {
      setData(
        datax.sort((a: any, b: any) => {
          const fa = a[key].toLowerCase();
          const fb = b[key].toLowerCase();

          if (fa < fb) {
            return sortList[key] ? -1 : 1;
          }
          if (fa > fb) {
            return sortList[key] ? 1 : -1;
          }
          return 0;
        })
      );
      setSortList({ ...sortList, [key]: !sortList[key] });
    }
  }

  return (
    <div className={`${styles.wrapper_table} ${props.extraClassWrapper}`}>
      {props.donotDisplayBreak === true ? (
        <></>
      ) : (
        <div className={styles.divide}></div>
      )}
      <table className={styles.tablex} cellSpacing={0}>
        <thead className={props.extraHeaderClass}>
          <tr className={styles.tablerow}>
            {props.headingTranformer.map((each: any, i) => {
              return (
                <th
                  className={`${styles.cellleft} ${
                    props.centerAlign ? styles.center : ""
                  }`}
                  style={{ ...(each.sort ? { cursor: "pointer" } : {}) }}
                  onClick={() => handleSort(each.key, each.type)}
                  key={i}
                >
                  {each.transform
                    ? each.transform(each.displayName)
                    : each.displayName
                    ? each.displayName
                    : "-"}
                </th>
              );
            })}
          </tr>
        </thead>
        {props.donotDisplayBreak === true ? (
          <></>
        ) : (
          <div className={styles.divide}></div>
        )}

        {data.map((each: any, i: number) => {
          return (
            <tr
              onClick={() =>
                props.onClickOfRow ? props.onClickOfRow(each) : null
              }
              className={styles.rowdata_hover}
              style={{ ...(props.onClickOfRow ? { cursor: "pointer" } : {}) }}
              key={i}
            >
              {props.bodyTranformer.map((eachTransformer, id) => {
                return (
                  <td className={styles.color_for_body_cell} key={id}>
                    {eachTransformer.transform(each[eachTransformer.key])
                      ? eachTransformer.transform(each[eachTransformer.key])
                      : "-"}
                  </td>
                );
              })}
            </tr>
          );
        })}
        {props.donotDisplayBreak === true ? (
          <></>
        ) : (
          <div className={styles.divide}></div>
        )}
      </table>
    </div>
  );
}
