import React from "react";
import "./AnalyticsCard.css";
// import { totalicon2 } from "../../../theme/assets/analyticsLoggerHeaderSvg/index";
import CardComponent from "./cardComponent/CardComponent";
import { cardInterface } from "../../../screens/analytics/analyticsScreen.types";
import CustomBodySkeleton from "../../generic/customSkeleton/CustomBodySkeleton";

interface props {
  cardDetails: cardInterface[];
  data: Record<string, any>;
  isLoadingObject: any;
}

const AnalyticsCard = (props: props) => {
  const deepCardDetails = structuredClone(props.cardDetails) || [];
  console.log("card" , deepCardDetails , props.cardDetails)
  return (
    <>
      <div className="containerAnalyticsDBTop" id="abhi">
        {deepCardDetails?.map((each, i) => {
          const refernceKey = each?.kpiDetails?.referenceKey?.split(".");
          // console.log(each?.kpiDetails, "kpidetails");
          if (!each.isActive) return <></>;
          return (
            <div key={i} className="topCardComponentDiv">
              <CustomBodySkeleton
                isLoading={props.isLoadingObject[each?.kpiDetails?.uniqueId]}
                style={{ transform: "scaleX(0.9) translateX(20px)" }}
              >
                <CardComponent
                  heading={each.customName}
                  data={
                    props.data && props.data[refernceKey[0]]
                      ? props.data[refernceKey[0]][refernceKey[1]]
                      : 0
                  }
                  icon={each.kpiDetails?.icon}
                />
              </CustomBodySkeleton>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AnalyticsCard;
