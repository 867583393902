import React, { useEffect } from "react";
import styles from "./StepProgress.module.scss";
import { tick } from ".";
import { useState } from "react";
interface obj {
  name: string;
  fun: any;
}
interface props {
  arr: obj[];
  inProgressStepFromOut: number;
  color: string;
  extraClass?: string;
  maxStateCompleted: number;
  currentStepComplete: boolean;
}

export default function StepProgress({
  arr,
  inProgressStepFromOut,
  color,
  extraClass,
  maxStateCompleted,
  currentStepComplete,
}: props) {
  const [inProgressStep, setinProgressStep] = useState<number>();

  useEffect(() => {
    setinProgressStep(inProgressStepFromOut);
  }, [inProgressStepFromOut, currentStepComplete, maxStateCompleted]);

  return (
    <div
      className={`${extraClass ? extraClass : ""}  ${
        styles.progress_constainers
      }`}
    >
      <div className={styles.progress_bar_line}>
        <div
          className={styles.progress_bar_actual_progress}
          style={{
            backgroundColor: color,
            width: `${
              (100 / (arr.length - 1)) *
              (Math.max(maxStateCompleted, inProgressStepFromOut) - 1)
            }%`,
          }}
        ></div>
        <div className={styles.circles_div}>
          {arr.map((e: obj, i: number) => {
            if (Math.max(maxStateCompleted, inProgressStepFromOut) < i + 1)
              return (
                <div
                  data-testid="progress-step"
                  id="auto_progress_step"
                  key={i}
                  className={styles.circles}
                >
                  <p>{e.name}</p>
                </div>
              );
            else {
              if (i + 1 === inProgressStepFromOut && !currentStepComplete)
                return (
                  <div
                    data-testid="progress-step"
                    className={styles.circle_inProgres}
                    key={i}
                    id="auto_progress_step_inprogress"
                  >
                    <div
                      style={{ backgroundColor: color }}
                      className={styles.innercircle}
                    ></div>
                    <p style={{ color: color }}>{e.name}</p>
                  </div>
                );
              else
                return (
                  <div
                    data-testid="progress-step"
                    style={{ backgroundColor: color }}
                    className={styles.circles_complte}
                    onClick={() => {
                      setinProgressStep(i + 1);
                      e.fun();
                    }}
                    key={i}
                    id="auto_progress_step_complete"
                  >
                    <img src={tick} alt="" id="auto_complete_icon" />
                    <p style={{ color: color }}>{e.name}</p>
                  </div>
                );
            }
          })}
        </div>
      </div>
    </div>
  );
}
