export default {
  GET_AGENT_DESKTOP_SCHEMA_REQUEST: "GET_AGENT_DESKTOP_SCHEMA_REQUEST",
  GET_AGENT_DESKTOP_SCHEMA_SUCCESS: "GET_AGENT_DESKTOP_SCHEMA_SUCCESS",
  GET_AGENT_DESKTOP_SCHEMA_FAILURE: "GET_AGENT_DESKTOP_SCHEMA_FAILURE",
  SET_SUMMARY_SCHEMA_AGENT_DESKTOP: "SET_SUMMARY_SCHEMA_AGENT_DESKTOP",
  SET_CALL_STATUS_AGENT_DESKTOP: "SET_CALL_STATUS_AGENT_DESKTOP",
  SET_CALLER_DATA_AGENT_DESKTOP: "SET_CALLER_DATA_AGENT_DESKTOP",
  SET_MESSENGER_DATA_AGENT_DESKTOP: "SET_MESSENGER_DATA_AGENT_DESKTOP",
  SET_COMMUNICATION_TYPE_AGENT_DESKTOP: "SET_COMMUNICATION_TYPE_AGENT_DESKTOP",
  SET_IN_QUEUE_CALL_AGENT_DESKTOP: "SET_IN_QUEUE_CALL_AGENT_DESKTOP",
  SET_IN_QUEUE_CHAT_AGENT_DESKTOP: "SET_IN_QUEUE_CHAT_AGENT_DESKTOP",
  SET_SEND_CHAT_SIGNAL_AGENT_DESKTOP: "SET_SEND_CHAT_SIGNAL_AGENT_DESKTOP",
  SET_ONGOING_CALL_CHAT_SESSION_ID: "SET_ONGOING_CALL_CHAT_SESSION_ID",
  SET_ONGOING_CALL_CHAT_CONVERSATION_ID:
    "SET_ONGOING_CALL_CHAT_CONVERSATION_ID",
  HANDLE_CALL_FINISH_AGENT_DESKTOP: "HANDLE_CALL_FINISH_AGENT_DESKTOP",
  HANDLE_CHAT_FINISH_AGENT_DESKTOP: "HANDLE_CHAT_FINISH_AGENT_DESKTOP",
  GET_AGENT_DESKTOP_STATUS_API_CALL: "GET_AGENT_DESKTOP_STATUS_API_CALL",
  GET_AGENT_DESKTOP_STATUS_API_SUCCESS: "GET_AGENT_DESKTOP_STATUS_API_SUCCESS",
  GET_AGENT_DESKTOP_STATUS_API_FAILURE: "GET_AGENT_DESKTOP_STATUS_API_FAILURE",
  // SET_AGENT
  SET_UPDATED_STATUS: "SET_UPDATED_STATUS",
  SET_RING_TIME:"SET_RING_TIME",
  SET_CALL_STATUS_DATA:"SET_CALL_STATUS_DATA"
};
