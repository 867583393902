// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hHKRNLS6PDK7Gqfrd2vw{width:100%}.hHKRNLS6PDK7Gqfrd2vw .SjpTWLg5yHPHzbrFCHyT{width:100%}.hHKRNLS6PDK7Gqfrd2vw .SjpTWLg5yHPHzbrFCHyT .exnFGU36aBqkDDK0H8c8{color:var(--primary-color);text-align:center;font-weight:600;font-size:18px}.hHKRNLS6PDK7Gqfrd2vw .SjpTWLg5yHPHzbrFCHyT .SjpTWLg5yHPHzbrFCHyT{margin:5px}.hHKRNLS6PDK7Gqfrd2vw .SjpTWLg5yHPHzbrFCHyT .MZSs3Ve6nrQy7azxCpdF{text-align:center;font-weight:400;color:var(--primary-grey)}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/transcript/newReportIssue/reportedIssues/NewReportedIssues.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,4CACE,UAAA,CACA,kEACE,0BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CAEF,kEACE,UAAA,CAEF,kEACE,iBAAA,CACA,eAAA,CACA,yBAAA","sourcesContent":[".wrapper {\n  width: 100%;\n  .eachIssue {\n    width: 100%;\n    .title {\n      color: var(--primary-color);\n      text-align: center;\n      font-weight: 600;\n      font-size: 18px;\n    }\n    .eachIssue {\n      margin: 5px;\n    }\n    .list {\n      text-align: center;\n      font-weight: 400;\n      color: var(--primary-grey);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `hHKRNLS6PDK7Gqfrd2vw`,
	"eachIssue": `SjpTWLg5yHPHzbrFCHyT`,
	"title": `exnFGU36aBqkDDK0H8c8`,
	"list": `MZSs3Ve6nrQy7azxCpdF`
};
export default ___CSS_LOADER_EXPORT___;
