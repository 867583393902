import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import styles from "./EmailDetailsScreen.module.scss";
import InputContainer from "../../../../generic/inputContainer/InputContainer";
import Button from "../../../../generic/button/Button";
import { EmailTable } from "../emailTable/EmailTable";
import {
  emailValidation,
  maxLengthValidations,
} from "../../../../../utils/Validation";
import {
  teamNameCrossIcon,
  teamNameTickIcon,
} from "../../../../../theme/assets/svg";
import { emailEditIcon } from "../../../../../theme/assets/svg/integration_V2.0/index";
import { EmailTemplate } from "../emailTemplate/EmailTemplate";
import {
  verifyEmailDNSData,
  setEmailScreen,
  updateEmailDNSData,
} from "../../../../../redux/integration_V2.0/email/actions";
import { verify, failed, loading } from "../../../../../theme/gif/index";
interface EmailDetailsScreenProps {
  emailOfSender?: string;
  emailData?: any;
  onClickClose?: any;
}

export const EmailDetailsScreen = ({
  emailOfSender,
  emailData,
  onClickClose,
}: EmailDetailsScreenProps) => {
  const dispatch = useDispatch();

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const selectedIntegrationEmail = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.selectedIntegrationData
  );

  const screenName = useSelector(
    (store: RootState) => store?.integrationReducer?.emailReducer?.currentScreen
  );

  const verifyData = useSelector(
    (store: RootState) => store?.integrationReducer?.emailReducer?.verifyDNSData
  );

  const emailreducerData = useSelector(
    (store: RootState) => store?.integrationReducer?.emailReducer
  );

  const [senderEmail, setSenderEmail] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [errorTableData, setErrorTableData] = useState([]);

  const handleOnClickVerify = () => {
    if (
      (verifyData?.msg?.length > 0 &&
        verifyData?.msg !== "Verification failed") ||
      selectedIntegrationEmail?.integrationStatus === "Connected"
    ) {
      onClickClose();
    } else {
      dispatch(
        verifyEmailDNSData({
          id: selectedIntegrationEmail?._id
            ? selectedIntegrationEmail?._id
            : selectedIntegrationEmail?.id,
          accountId: accountId,
        })
      );
    }
  };

  const handleShowTemplate = () => {
    // setShowTemplate(true);
    dispatch(setEmailScreen("showTemplate"));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSenderEmail(value.replace(/[^a-zA-Z0-9@._-]/g, ""));
    setIsFocused(true);
  };

  const scrollToBottom = (id: any) => {
    const foo: any = document.getElementById(id);
    foo.value = senderEmail?.slice(0, 128);
    foo.focus();
    foo.setSelectionRange(foo.value.length, foo.value.length);
  };

  const handleIconClick = () => {
    if (isReadOnly) {
      setIsReadOnly(false);
      scrollToBottom("auto_email");
      setIsFocused(false);
    } else if (emailValidation(senderEmail).isValid) {
      // const emailIndex = senderEmail.indexOf("@");
      // const emailDomain = senderEmail.substr(emailIndex + 1);
      if (
        selectedIntegrationEmail?.integrationDetails?.fromEmail !== senderEmail
      ) {
        dispatch(
          updateEmailDNSData({
            accountId: accountId,
            id: selectedIntegrationEmail?._id
              ? selectedIntegrationEmail?._id
              : selectedIntegrationEmail?.id,
            email: senderEmail,
          })
        );
      }
      setIsReadOnly(true);
      setIsFocused(false);
    } else {
      setIsReadOnly(true);
      setIsFocused(false);
      setSenderEmail(selectedIntegrationEmail?.integrationDetails?.fromEmail);
    }
  };

  useEffect(() => {
    if (selectedIntegrationEmail?.integrationDetails !== undefined) {
      setSenderEmail(
        selectedIntegrationEmail?.integrationDetails?.fromEmail
          ? selectedIntegrationEmail?.integrationDetails?.fromEmail
          : ""
      );

      const dnsArr: any =
        selectedIntegrationEmail?.integrationDetails?.dns &&
        Object.values(selectedIntegrationEmail?.integrationDetails?.dns);

      setTableData(dnsArr);
    }
  }, [selectedIntegrationEmail]);

  useEffect(() => {
    if (verifyData && verifyData?.msg === "Verification failed") {
      setError(true);
      const dnsArr: any =
        verifyData?.data?.validation_results &&
        Object.values(verifyData?.data?.validation_results);
      setErrorTableData(dnsArr);
    } else {
      setError(false);
      setErrorTableData([]);
    }
  }, [verifyData]);

  return (
    <div className={styles.emailDetailsScreenWrapper}>
      {screenName === "showTemplate" ? (
        <EmailTemplate />
      ) : screenName === "emailTable" ? (
        <>
          <div className={styles.descriptionDiv}>
            <span>
              We need to set up some security measures in order to reach inboxes
              of your customers rather than spam folders.{" "}
              <a onClick={handleShowTemplate} className={styles.linkStyling}>
                Add the following DNS records to your DNS management tool
              </a>{" "}
              &nbsp;such as Cloudflare, GoDaddy.
            </span>
          </div>
          <div className={styles.editEmailDiv}>
            <InputContainer
              label="Email"
              inputType="text"
              inputPlaceholder="Email"
              inputValue={senderEmail.slice(0, 128)}
              inputName="email"
              inputOnChange={handleInputChange}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraInputClass={`${styles.inputDivStyling} ${
                isReadOnly && styles.readOnlyStyling
              }`}
              extraUserInputClass={`${styles.inputMainDivStyling} ${
                isReadOnly && styles.readOnlyStyling
              }`}
              showLabel={true}
              readOnly={isReadOnly}
              inputIconRight={
                isReadOnly
                  ? emailEditIcon
                  : !emailValidation(senderEmail).isValid || !isFocused
                  ? teamNameCrossIcon
                  : teamNameTickIcon
              }
              onClickRightIcon={handleIconClick}
              extraRightIconClass={styles.extraRightIconClass}
              errorMsg={
                isFocused
                  ? !emailValidation(senderEmail).isValid
                    ? emailValidation(senderEmail).errors.message
                    : !maxLengthValidations(senderEmail, "Email", 128).isValid
                    ? maxLengthValidations(senderEmail, "Email", 128).errors
                        .message
                    : ""
                  : ""
              }
              inputOnFocus={() => {
                setIsFocused(true);
              }}
              inputOnBlur={() => setIsFocused(false)}
              showNewToolTip
              showNewToolTipPosition={"top-end"}
              showNewToolTipMsg={
                "This is the email address that will be used to send emails to your customers."
              }
            />
          </div>
          <div className={styles.emailDetailsTable}>
            <EmailTable
              isError={error}
              emailTableData={tableData}
              emailerrorTable={errorTableData}
            />
          </div>
          <div
            className={`${styles.buttonContainer} ${
              verifyData?.msg !== "Verification failed" && !error
                ? styles.singleButtonContainer
                : ""
            }`}
          >
            {selectedIntegrationEmail?.integrationStatus !== "Connected" &&
            (verifyData?.msg === "Verification failed" ||
              Object.keys(verifyData).length === 0) ? (
              <div className={styles.subDiv}>
                <Button
                  text="Send instructions to a developer"
                  extraClass={`${styles.sendButton} ${styles.buttonStyling}`}
                  onClick={handleShowTemplate}
                />
              </div>
            ) : (
              ""
            )}

            <div className={styles.subDiv}>
              {verifyData && verifyData?.msg?.length > 0 ? (
                <Button
                  text={
                    selectedIntegrationEmail?.integrationStatus === "Connected"
                      ? "Verified"
                      : emailreducerData?.isLoadingDNSData === true
                      ? "Verifying"
                      : error
                      ? "Try Again"
                      : "Verified"
                  }
                  extraClass={`${
                    selectedIntegrationEmail?.integrationStatus === "Connected"
                      ? styles.verifyBtnStyling
                      : emailreducerData?.isLoadingDNSData === true
                      ? loading
                      : error
                      ? styles.tryAgainBtn
                      : styles.verifyBtnStyling
                  } ${styles.buttonStyling}`}
                  onClick={handleOnClickVerify}
                  image_src_left={
                    selectedIntegrationEmail?.integrationStatus === "Connected"
                      ? verify
                      : emailreducerData?.isLoadingDNSData === true
                      ? loading
                      : error
                      ? failed
                      : verify
                  }
                  imgClass={`${
                    selectedIntegrationEmail?.integrationStatus === "Connected"
                      ? styles.verifybtnIconStyling
                      : emailreducerData?.isLoadingDNSData === true
                      ? styles.loadingIconStyling
                      : error
                      ? styles.errorbtnIconStyling
                      : styles.verifybtnIconStyling
                  }`}
                />
              ) : (
                <Button
                  text={
                    selectedIntegrationEmail?.integrationStatus === "Connected"
                      ? "Verified"
                      : emailreducerData?.isLoadingDNSData === true
                      ? "Verifying"
                      : "Verify"
                  }
                  extraClass={`${styles.verifyBtnStyling} ${styles.buttonStyling}`}
                  onClick={handleOnClickVerify}
                  image_src_left={
                    selectedIntegrationEmail?.integrationStatus === "Connected"
                      ? verify
                      : emailreducerData?.isLoadingDNSData === true
                      ? loading
                      : ""
                  }
                  imgClass={`${
                    selectedIntegrationEmail?.integrationStatus === "Connected"
                      ? styles.verifybtnIconStyling
                      : emailreducerData?.isLoadingDNSData === true
                      ? styles.loadingIconStyling
                      : ""
                  }`}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
