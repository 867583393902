import React, { useState, useEffect } from "react";
import styles from "./StrategyDescModal.module.scss";
import Modal from "../../../generic/modal/Modal";
import { createStrategyCloseIcon } from "../../../../theme/assets/svg/strategySvg";

interface StrategyDescProps {
  show?: boolean;
  close?: CallableFunction;
  name?: string;
  deleteModal?: boolean;
  onclick?: CallableFunction;
  description?: string;
}

export const StrategyDescModal = ({
  show,
  close,
  name,
  onclick,
  description,
}: StrategyDescProps) => {
  return (
    <div>
      <Modal
        shown={show}
        close={() => {
          close && close();
        }}
        extraClassStyleModalContent={styles.StrategyDescModalStyling}
      >
        <div
          className={styles.modalInfoDiv}
          id="auto_desc_modal"
          data-testid="test-desc-modal"
        >
          <div className={styles.modalInfoTopDiv}>
            <div className={styles.modalHeadingDiv} id="auto_desc_modal_name">
              {name}
            </div>
            <div className={styles.modalCloseIcon}>
              <img
                src={createStrategyCloseIcon}
                data-testid="close-icon"
                onClick={() => {
                  close && close();
                }}
                id="auto_desc_modal_close_icon"
              />
            </div>
          </div>
          <div className={styles.modalDescDiv} id="auto_modal_desc_field">
            {description}
          </div>
        </div>
      </Modal>
    </div>
  );
};
