
import { call, put } from "redux-saga/effects";
import { DEMO_SIGNUP_URL, config , ONBOARDING_URL } from "../../../../services/ApiRoutes";
import actionTypes from "../actionTypes";
import actionTypeLogin from "../../../onboarding/login/actionTypes/login.actionTypes";

export function* updateTourModuleWorker(action: any): any {
    console.log("saga" )
  try {

    if (action) {
        const result = yield call(
            config.POST_WITH_BODY,
            `${ONBOARDING_URL.UPDATE_USER}`,
            action.payload.payload
          );
      yield put({
        type: actionTypes.UPDATE_TOUR_SHOW_SUCCESS,
        payload: result.data.data,
      });
      if(!action.payload.name){
      yield put({
        type: actionTypeLogin.GET_USER_DATA_SUCCESS,
        payload: result.data.data,
      });
    }
      
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.UPDATE_TOUR_SHOW_ERROR,
      payload: error,
    });
  }
}
export function* getCallStatusWorker(action: any): any {
  console.log("saga" )
try {

  if (action) {
      const result = yield call(
          config.POST_WITH_BODY,
          `${DEMO_SIGNUP_URL.GET_CALL_STATUS}`,
          action.payload
        );
        console.log("status" , result)
    yield put({
      type: actionTypes.GET_CALL_STATUS_SUCCESS,
      payload: result?.data
      ,
    });
    
    
  }
} catch (error) {
  console.error(error);
  yield put({
    type: actionTypes.GET_CALL_STATUS_ERROR,
    payload: error,
  });
}
}
export function* getLanguageApiWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET,
        `${DEMO_SIGNUP_URL.GET_LANGUAGE_EXPERIENCENOW}`
      );
      yield put({
        type: actionTypes.GET_LANGUAGE_EXPERIENCE_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_LANGUAGE_EXPERIENCE_ERROR,
      payload: error,
    });
  }
}
export function* getFlowApiWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET,
        `${DEMO_SIGNUP_URL.GET_FLOW_EXPERIENCENOW}`
      );
      yield put({
        type: actionTypes.GET_FLOW_EXPERIENCE_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_FLOW_EXPERIENCE_ERROR,
      payload: error,
    });
  }
}
