import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import HorizontallProgress from "../../../components/crm/generic/horizontalProgress/HorizontalProgress";
import CRMChartModel from "../../../components/crm/moduleComponent/chartModel/ChartModel";
import CustomerProfileModel from "../../../components/crm/moduleComponent/customerProfileModel/CustomerProfileModel";
import MapComponent from "../../../components/crm/moduleComponent/mapComponent/MapComponent";
import Slide from "../../../components/generic/slide/Slide";
import { AppDispatch, RootState } from "../../../redux/rootStore";
import {chartDetails} from "./chartSchema"

import styles from "./CRMAnalytics.module.scss";

export default function CrmAnalytics(props: any) {
  
  const dispatch = useDispatch();
  
const cardChartDetails = [
  {
    type: "card",
    name: "Total Customers",
    value: 9857,
    isCard: true,
  },
  {
    type: "card",
    name: "Amount collected",
    value: "₹17,102,515",
    isCard: true,
  },
  {
    type: "card",
    name: "Number Of Payments",
    value: 4137,
    isCard:true,
  },
]

const funnelLineChartDetails = [
  {
    legendData: ["Total Customers", "Amount collected", "Number Of Payments"],
    keys: [
      {
        name: "Month",
        icon: "",
        referenceKeyName: "timeSlot",
        shortKey: "",
        fillColor: "#9ADBF9",
        position: 1,
      },
      {
        name: "Customer",
        icon: "",
        referenceKeyName: "customer",
        shortKey: "",
        fillColor: "#F8961E",
        position: 2,
      },
      {
        name: "",
        icon: "",
        referenceKeyName: "collection",
        shortKey: "",
        fillColor: "#F8961E",
        position: 2,
      },
      {
        name: "",
        icon: "",
        referenceKeyName: "payment",
        shortKey: "",
        fillColor: "#F8961E",
        position: 2,
      },
    ],
    chartTitle: "Total Customers",
    chartType: "multiLineChart",
    chartStyle: {
      width: "750px",
      height: "400px",
    },
    isSlider: false,
    isCardData: true,
    data: [
      {
        customer: 1732,
        collection: 3892040,
        payment:787,
        timeSlot: "January",
      },
      {
        customer: 2467,
        collection: 3972070,
        payment:874,
        timeSlot: "February",
      },
      {
        customer: 2397,
        collection: 3782873,
        payment:829,
        timeSlot: "March",
      },
      {
        customer: 2317,
        collection: 3225729,
        payment:1194,
        timeSlot: "April",
      },
      {
        customer: 944,
        collection: 2229803,
        payment:453,
        timeSlot: "May",
      },
    ],
  },
  {
    chartTitle: "Funnel Analysis",
    chartType: "funnelChart",
    chartStyle: {
      width: "390px",
      height: "400px",
    },
    isSlider: true,
    isCardData: false,
    dataCall: [
      {
        name: "Calls Initiated ",
        value: 19672,
      },
      {
        name: "Connected Customers",
        value: 9425,
      },
      {
        name: "PaymentDate Given",
        value: 5789,
      },
      {
        name: "Payment Done",
        value: 3513,
      },
    ],
    dataWhatsApp: [
      {
        name: "Messages Initiated",
        value: 5438,
      },
      {
        name: "Messages Seen",
        value: 2748,
      },
      {
        name: "PTP date given",
        value: 1375,
      },
      {
        name: "Payment Done",
        value: 624,
      },
    ],
  },
]

  return (
    <>
      <div className={styles.crmAnalyticsScreenDiv}>
        <div className={styles.topRowDiv}>
        <div style={{width:"65%"}}>
        <CRMChartModel chartDetails={cardChartDetails} />
        </div>
       <div style={{width:"30%", marginLeft:"1.5%"}}>
         <HorizontallProgress
         title="After PTP Received"
         left={{ title: "paid on time", value: 4441 }}
         right={{ title: "not paid on time", value: 2723 }}
          />
       </div>
        </div>
       <div style={{width:"100%"}}>
        <CRMChartModel chartDetails={funnelLineChartDetails} />
        </div>
        <MapComponent />
        <div style={{width:"100%"}}>
        <CRMChartModel chartDetails={chartDetails} />
        </div>
      </div>
    </>
  );
}
