import { takeLatest } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import {
  getCallLoggerSummaryWorker,
  getCallLoggerTranscriptWorker,
  getSessionID,
  updateSummaryWorker,
} from "./callLogger.saga";

export default function* callLoggerAgentDesktopWatcher(): any {
  yield takeLatest(
    actionTypes.GET_SUMMARY_REQUEST_AGENT_DESKTOP,
    getCallLoggerSummaryWorker
  );
  yield takeLatest(
    actionTypes.GET_TRANSCRIPT_REQUEST_AGENT_DESKTOP,
    getCallLoggerTranscriptWorker
  );
  yield takeLatest(
    actionTypes.GET_SESSION_ID_REQUEST_AGENT_DESKTOP,
    getSessionID
  );
  yield takeLatest(
    actionTypes.UPDATE_SUMMARY_REQUEST_AGENT_DESKTOP,
    updateSummaryWorker
  );
}
