import React, { useEffect } from "react";
import "./LoginSignUpEntry.scss";
import sideImage from "../../../theme/assets/svg/login/sideImage.svg";
import { singUpBanner } from "../../../theme/assets/svg/login";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { userLogoutAction } from "../../../redux/onboarding/login/actions";
import { ToastContainer } from "react-toastify";
import { RootState } from "../../../redux";
import secureLocalStorage from "react-secure-storage";

const LogInSingUpEntry = () => {
  const agentId = useSelector(
    (store: any) => store.loginReducer?.userLoginInfo?.userDetail?.["_id"]
  );
  const userId = useSelector(
    (state: RootState) => state.loginReducer?.userLoginInfo?.userDetail?._id
  );
  const pathName = window.location.pathname;
  //clear redux at login page
  const dispatch = useDispatch();
  useEffect(() => {
    const refreshToken = secureLocalStorage.getItem("refresh_token") as string;
    if (refreshToken && userId) {
      dispatch(
        userLogoutAction({
          userId: agentId,
          refreshToken: refreshToken,
        })
      );
    }
  }, []);

  return (
    <div className="logInSingUpEntryWrapper">
      <div className="loginImage">
        <img
          src={
            pathName === "/signup" || pathName.includes("/createpassword")
              ? singUpBanner
              : sideImage
          }
          alt="SaarthiLogo"
          className="imgLogo"
        />
      </div>
      <div className="fields__container">
        <div className="fields">
          <Outlet />
        </div>
      </div>
      {/* <ToastContainer
        position="top-center"
        // type="success"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={true}
      /> */}
    </div>
  );
};
export default LogInSingUpEntry;
