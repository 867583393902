import actionTypes from "../whatsApp.actionTypes";

export interface stateInterface {
  allIndustryData: object;
  allIndustryDataError: string;
  isLoadingallIndustry: boolean;
  uploadProfilePic: string;
  isLoadingUploadProfilePic: boolean;
  uploadProfilePicError: string;
  isLoadingDeleteProfilePic: boolean;
  deleteProfilePicError: string;
  currentScreen: string;
  whatsAppInputData?: object;
  isDataChanged: boolean;
  // backIconClicked?: boolean;
}

const initialState: stateInterface = {
  uploadProfilePic: "",
  isLoadingUploadProfilePic: false,
  uploadProfilePicError: "",
  isLoadingDeleteProfilePic: false,
  deleteProfilePicError: "",
  currentScreen: "",
  whatsAppInputData: {},
  allIndustryData: {},
  allIndustryDataError: "",
  isLoadingallIndustry: false,
  isDataChanged: false,
  // backIconClicked: false,
};

export default function whatsAppReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_ALL_INDUSTRY_DATA_REQUEST: {
      return {
        ...state,
        allIndustryData: {},
        allIndustryDataError: "",
        isLoadingallIndustry: true,
      };
    }
    case actionTypes.GET_ALL_INDUSTRY_DATA_SUCCESS: {
      return {
        ...state,
        allIndustryData: action.payload,
        allIndustryDataError: "",
        isLoadingallIndustry: false,
      };
    }
    case actionTypes.GET_ALL_INDUSTRY_DATA_FAILURE: {
      return {
        ...state,
        allIndustryData: {},
        allIndustryDataError: action.payload,
        isLoadingallIndustry: false,
      };
    }
    case actionTypes.UPDATE_WHATSAPP_PROFILE_PIC_REQUEST: {
      return {
        ...state,
        uploadProfilePic: "",
        isLoadingUploadProfilePic: true,
        uploadProfilePicError: "",
      };
    }
    case actionTypes.UPDATE_WHATSAPP_PROFILE_PIC_SUCCESS: {
      return {
        ...state,
        uploadProfilePic: action.payload,
        isLoadingUploadProfilePic: false,
        uploadProfilePicError: "",
      };
    }
    case actionTypes.UPDATE_WHATSAPP_PROFILE_PIC_FAILURE: {
      return {
        ...state,
        uploadProfilePic: "",
        isLoadingUploadProfilePic: false,
        uploadProfilePicError: action.payload,
      };
    }
    case actionTypes.DELETE_WHATSAPP_PROFILE_PIC_REQUEST: {
      return {
        ...state,
        isLoadingDeleteProfilePic: true,
        deleteProfilePicError: "",
      };
    }
    case actionTypes.DELETE_WHATSAPP_PROFILE_PIC_SUCCESS: {
      return {
        ...state,
        uploadProfilePic: "",
        isLoadingDeleteProfilePic: false,
        deleteProfilePicError: "",
      };
    }
    case actionTypes.DELETE_WHATSAPP_PROFILE_PIC_FAILURE: {
      return {
        ...state,
        isLoadingDeleteProfilePic: false,
        deleteProfilePicError: action.payload,
      };
    }
    case actionTypes.SET_CURRENT_SCREEN: {
      return {
        ...state,
        currentScreen: action.payload,
      };
    }

    case actionTypes.SET_WHATSAPP_DATA: {
      return {
        ...state,
        whatsAppInputData: action.payload,
      };
    }

    case actionTypes.SET_IS_DATA_CHANGED: {
      return { ...state, isDataChanged: action.payload };
    }

    // case actionTypes.SET_BACK_ICON_CLICKED: {
    //   return {
    //     ...state,
    //     backIconClicked: action.payload,
    //   };
    // }

    case actionTypes.CLEAR_WHATSAPP_DATA: {
      return {
        ...state,
        uploadProfilePic: "",
        isLoadingUploadProfilePic: false,
        uploadProfilePicError: "",
        isLoadingDeleteProfilePic: false,
        deleteProfilePicError: "",
        currentScreen: "",
        whatsAppInputData: {},
        isDataChanged: false,
      };
    }
    default:
      return state;
  }
}
