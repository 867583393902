import React, { useState } from 'react'
import style from "./ToolTipWithChild.module.scss"

interface props{
    children : any
    tootTipComponent : any
    extraStyleTooltip ?: string
}
function ToolTipwithChild({children , tootTipComponent ,extraStyleTooltip } : props) {
    const [isVisible, setIsVisible] = useState(false);

    const showTooltip = () => {
      setIsVisible(true);
    };
  
    const hideTooltip = () => {
      setIsVisible(false);
    };
    return (
        <div className={style.tooltipContainer} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
          {children}
          {isVisible && <div className={`${style.tooltip} ${extraStyleTooltip ? extraStyleTooltip : ""}`}>{tootTipComponent}</div>}
        </div>
      );
    
}

export default ToolTipwithChild