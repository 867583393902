import { takeLatest } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import {
  getAgentDesktopSchemaWorker,
  getAgentStatusWorker,
} from "./baseScreenAD.saga";

export default function* baseScreenADWatcher() {
  yield takeLatest(
    actionTypes.GET_AGENT_DESKTOP_SCHEMA_REQUEST,
    getAgentDesktopSchemaWorker
  );
  yield takeLatest(
    actionTypes.GET_AGENT_DESKTOP_STATUS_API_CALL,
    getAgentStatusWorker
  );
}
