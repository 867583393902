import React, { useEffect, useState } from "react";
import style from "./ToolContainer.module.scss";
import {
  nudgeBox,
  conditionNudge,
  connectorNudge,
  handNudge,
  saveNudge,
  zoomNegative,
  zoomPositive,
  channelNudge,
  channelNudgeActive,
  conditionNudgeActive,
  connectorNudgeActive,
} from "../../../../../theme/assets/svg/strategySvg";
import {
  setWorkingNudgeData,
  getConditionOutcomes,
  getOutComesDispositions,
  getFollowUpsDispositions,
} from "../../../../../redux/strategy/nudgeCreation/actions";
import { useDrag } from "react-dnd";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../../../../redux";

interface props {
  clickTool?: any;
  handleZoomPlus?: any;
  handleZoomMinus?: any;
  handleCreateNudge?: any;
  handleConnectDot?: any;
}
function ToolsContainer(props: props) {
  const [selectedTool, setSelectedTool] = useState("");
  const [showCondition, setShowCondition] = useState(false);
  const [allowDrag, setAllowDrag] = useState(true);
  const [channelForCondition, setChannelForCondition] = useState("");
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "Box",
    item: { id: "3" },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  const dispatch = useDispatch();
  const showConnection = useSelector((store: RootState) => {
    return store?.strategyModuleReducer?.nudgeReducer?.showDot;
  });
  const workingNudgeData = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.workingNudgeData;
  });

  const allNudgeDetail = useSelector((store: RootState) => {
    return store?.strategyModuleReducer?.nudgeReducer?.allNudgeDetail;
  });
  const typeOfMode = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.typeOfstrategyMode;
  });
  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const currentUseCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseId
  );

  const accountId = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const handleClickTool = (data: string) => {
    if (selectedTool?.length > 0 && data === selectedTool) {
      setSelectedTool("");
      props.clickTool("");
      return;
    }
    setSelectedTool(data);
    props.clickTool(data);
  };
  const handleCnnectionClick = () => {
    handleClickTool("connector");
    props.handleConnectDot();
  };
  console.log("la", allowDrag, workingNudgeData);
  const toShowValidCondition = () => {
    allNudgeDetail?.map((e: any) => {
      if (workingNudgeData?.isDaywise) {
        if (workingNudgeData.flow === e?.flow) {
          const rangesArr = e?.ranges;
          rangesArr?.map((d: any) => {
            if (d?.days[0] === workingNudgeData?.days[0]) {
              const nudgeBoxArray = d?.nugeBoxes;
              const checkingWorkingNudge =
                d?.nugeBoxes?.length > 0
                  ? nudgeBoxArray[d?.nugeBoxes?.length - 1]
                  : d?.nugeBoxes?.[0];
              const valid = checkingWorkingNudge?.channel ? true : false;
              setShowCondition(valid);
              if (d?.nugeBoxes?.length > 0) {
                setAllowDrag(
                  checkingWorkingNudge?.channel &&
                  checkingWorkingNudge?.timing?.length == 2 &&
                  checkingWorkingNudge?.acceptedOutcome &&
                  checkingWorkingNudge?.unacceptedOutcome
                );
              }
              if (valid) {
                setChannelForCondition(checkingWorkingNudge?.channel);
              }
              return d;
            }
          });
        }
      } else {
        if (workingNudgeData?.flow === e?.flow) {
          const nudgeBoxArray = e?.nugeBoxes;
          const checkingWorkingNudge =
            e?.nugeBoxes?.length > 0
              ? nudgeBoxArray[e?.nugeBoxes?.length - 1]
              : e?.nugeBoxes?.[0];
          const valid = checkingWorkingNudge?.channel ? true : false;
          setShowCondition(valid);
          console.log("hel", e?.nugeBoxes, workingNudgeData);
          if (e?.nugeBoxes?.length > 0) {
            console.log("la1", checkingWorkingNudge);
            setAllowDrag(
              checkingWorkingNudge?.channel &&
                checkingWorkingNudge?.timing?.length == 2 &&
                checkingWorkingNudge?.acceptedOutcome &&
                checkingWorkingNudge?.unacceptedOutcome
                ? true
                : false
            );
          }
          if (valid) {
            setChannelForCondition(checkingWorkingNudge?.channel);
          }
        }
      }
    });
    if (workingNudgeData === "") {
      setAllowDrag(true);
      // setShowCondition(true)
    }
  };
  const handleShowError = () => {
    allowDrag
      ? toast.error("Please create Nudge first")
      : toast.error("Please select channel");
  };
  const handlerrorDrag = () => {
    toast.error("Please fill created nudge box");
  };
  const handleCloseTollCondition = () => {
    setSelectedTool("");
    props.clickTool("");
  };

  function getChannelForFollowUp(channel: string) {
    const arrOfChannel = ["call", "whatsapp", "sms", "mail"];
    const arrOfChannelForFollowUps = ["Call", "WhatsApp", "SMS", "Mail"];

    const index = arrOfChannel.indexOf(channel.toLowerCase());
    if (index !== -1) {
      return arrOfChannelForFollowUps[index];
    } else {
      return "Not Found";
    }
  }
  // useEffect for show condition enable
  useEffect(() => {
    toShowValidCondition();
  }, [workingNudgeData, allNudgeDetail]);

  useEffect(() => {
    if (typeOfMode !== "create") {
      dispatch(setWorkingNudgeData(""));
    }
  }, []);
  useEffect(() => {
    handleCloseTollCondition();
  }, [workingNudgeData]);
  useEffect(() => {
    if (allowDrag) {
      handleCloseTollCondition();
    }
  }, [allowDrag]);
  useEffect(() => {
    if (channelForCondition?.length) {
      dispatch(
        getOutComesDispositions({
          token: token,
          useCaseId: currentUseCaseId,
          accountId: accountId,
          selectedChannel: getChannelForFollowUp(channelForCondition),
        })
      );
      dispatch(
        getFollowUpsDispositions({
          token: token,
          useCaseId: currentUseCaseId,
          accountId: accountId,
          selectedChannel: getChannelForFollowUp(channelForCondition),
        })
      );
    }
  }, [channelForCondition]);
  console.log("selctCondition", channelForCondition);

  return (
    <div className={style.toolContainerWrapper}>
      <div className={style.toolContainer}>
        <div className={style.columnImg}>
          <div>
            <img
              src={saveNudge}
              onClick={() => props.handleCreateNudge()}
              id="auto_saveNudge"
            ></img>
          </div>
          <div>
            <img src={handNudge} id="auto_handNudge"></img>
          </div>
        </div>
        <div>
          <img
            ref={allowDrag ? drag : null}
            style={{
              opacity: isDragging ? 0.5 : 1,
              cursor: "move",
            }}
            src={nudgeBox}
            id={"dragNudgeBoxTesting"}
            onClick={() =>
              !allowDrag ? handlerrorDrag() : handleCloseTollCondition()
            }
          ></img>
        </div>
        <div onClick={() => handleCnnectionClick()}>
          <img
            src={showConnection ? connectorNudgeActive : connectorNudge}
            id="auto_showConnection"
          ></img>
        </div>
        <div
          onClick={() =>
            showCondition ? handleClickTool("condition") : handleShowError()
          }
        >
          {" "}
          <img
            src={
              selectedTool === "condition"
                ? conditionNudgeActive
                : conditionNudge
            }
            id="auto_condition"
          ></img>{" "}
        </div>
        <div onClick={() => handleClickTool("channel")}>
          <img
            src={selectedTool === "channel" ? channelNudgeActive : channelNudge}
            id="auto_channel"
          ></img>
        </div>
        <div className={style.columnImg}>
          <div>
            {" "}
            <img
              src={zoomPositive}
              onClick={() => props.handleZoomPlus()}
              id="auto_zoomPositive"
            ></img>
          </div>
          <div>
            {" "}
            <img
              src={zoomNegative}
              onClick={() => props.handleZoomMinus()}
              id="auto_zoomNegative"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ToolsContainer;
