import React, { useState, useEffect } from "react";
import styles from "./PersonaPopUpModal.module.scss";
import { closeIcon } from "../../../../theme/assets/svg";
import {
  backIcon,
  editBlueIcon,
} from "../../../../theme/assets/svg/campaign_v3/index";
import { filter, caratDown } from "../../../../theme/assets/genericSvg";
import InputField from "../../../generic/inputField/InputField";
import Icon from "../../uiKit/iconComponent/Icon";
import NewPagination from "../../../generic/pagination/NewPagination";
import Button from "../../../generic/button/Button";
import {
  getUserPersonaAPIData,
  getUserPersonaOptionsAPIData,
  selectUserPersona,
} from "../../../../redux/strategy/strategyCreation/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import Loader from "../../../../components/generic/loader/Loader";
import MultiLevelCheck from "../../../generic/multilevelCheck/MultiLevelCheck";
import ReactPaginate from "react-paginate";
import _ from "lodash";

interface PersonaPopUpModalProps {
  close?: CallableFunction;
  data?: any;
}

export const PersonaPopUpModal = ({ close, data }: PersonaPopUpModalProps) => {
  const [limit, setLimit] = useState(11);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [userPersonaFilters, setUserPersonaFilters] = useState<any>({});

  const dispatch = useDispatch();

  const userPersonaOptions = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer.userPersonaOptions
  );

  const isLoading = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.isLoadingUserPersonaDetails
  );

  const { totalPage, totalCount } = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.userPersonaDetails
  );

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const userPersonaData = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.userPersonaDetails.persona
  );
  const teamUseCaseId = useSelector(
    (store: RootState) => store?.loginReducer?.currentTeam?.useCaseId
  );

  const handlePageChange = (page: any) => {
    if (currentPage !== page) {
      setCurrentPage(Number(page.selected) + 1);
    }
  };

  console.log(data, "kkkkkkkk");

  useEffect(() => {
    // if (isLoading === false) {
    const body: any = {};
    body["campaignId"] = data?.isChildCampaign
      ? data?.parentCampaignId
      : data?.id;
    dispatch(
      getUserPersonaAPIData({
        limit: limit,
        page: currentPage,
        token: token,
        useCaseId : teamUseCaseId,
        ...userPersonaFilters,
        ...body,
      })
    );
    // }
  }, [limit, currentPage, token, userPersonaFilters]);

  useEffect(() => {
    dispatch(getUserPersonaOptionsAPIData({ token: token }));
  }, [token]);

  return (
    <div className={styles.userPersonaPopUpDiv}>
      <div className={styles.userPersonaTopDiv}>
        <MultiLevelCheck
          leftIcon={filter}
          title={"Filter"}
          allOptions={userPersonaOptions}
          defaultOptions={{}}
          getDataOut={setUserPersonaFilters}
          singleIcon={false}
          singleSelectField={[]}
        />
        <Icon
          img_src={closeIcon}
          onClick={() => {
            close && close();
          }}
        />
      </div>
      <div className={styles.userPersonaBtmDiv}>
        {isLoading ? (
          <div className={styles.loader} id="auto_loader">
            <Loader />
          </div>
        ) : (
          <div className={styles.userPersonTableDiv}>
            <div className={styles.userPersonTableSyling}>
              <div
                className={`${styles.userPersonaTable} ${styles.columnHeaderStyling}`}
              >
                {/* <div className={styles.userPersonaCheckBox}>
                  <InputField
                    type="checkbox"
                    className={styles.inputCheckStyling}
                    extraClass={styles.inputCheckBoxStyling}
                    // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    //   !inCampaign ? handleCheckAll(e) : false;
                    // }}
                    // checked={!inCampaign && checkAll ? true : false}
                    id="auto_persona_checkbox"
                  />
                </div> */}
                <div className={styles.userPersonaSrNo}>Sr. No</div>
                <div className={styles.userPersonas}>User Personas</div>
                <div className={styles.userPersonaRecommended}>
                  Saarthi&apos;s Recommended Strategies
                </div>
              </div>
              <div
                className={styles.userPersonaTableInfoDiv}
                id="auto_userPersonaTableInfoDiv"
              >
                {userPersonaData?.map((data: any, index: number) => {
                  return (
                    <>
                      <div
                        className={`${styles.userPersonaTable} ${styles.columnDataStyling}`}
                        key={index}
                        id={`auto_userPersonTableInfo_${index}`}
                      >
                        {/* <div className={styles.userPersonaCheckBox}>
                          <InputField
                            type="checkbox"
                            className={styles.inputCheckStyling}
                            extraClass={styles.inputCheckBoxStyling}
                            // onChange={(e) => {
                            //   !inCampaign
                            //     ? handleCheck(e, data?.id, data)
                            //     : false;
                            // }}
                            checked={true}
                            id={`auto_userPersonaCheckBox_${index}`}
                          />
                        </div> */}
                        <div
                          className={styles.userPersonaSrNo}
                          id={`auto_userPersonaSrNo_${index}`}
                        >
                          {(currentPage - 1) * 11 + index + 1}
                        </div>
                        <div
                          className={styles.userPersonas}
                          id={`auto_userPersonaName_${index}`}
                        >
                          {data.name}
                        </div>
                        <div className={styles.recommendedDataDiv}>
                          <Icon
                            img_src={editBlueIcon}
                            id={`auto_editUserPersonaIcon_${index}`}
                            extraClass={styles.editImageStyling}
                          />
                          <div
                            className={styles.recommendedData}
                            id={`auto_recommendedData_${index}`}
                          >
                            {data.userPersonaRecommended
                              ? data.userPersonaRecommended
                              : "Saarthi Strategy 1"}
                          </div>
                          {/* <Icon
                            img_src={divideIcon}
                            extraClass={styles.divideImageStyling}
                            id={`auto_divideIcon_${index}`}
                          /> */}
                        </div>
                      </div>
                      {/* <hr className={styles.hrStyling} /> */}
                    </>
                  );
                })}
              </div>
            </div>

            {totalPage > 1 && (
              <div
                className={styles.userPersonaPaginationDiv}
                id="auto_userPersonaPaginationDiv"
              >
                <div className={styles.pageTextStyling}>
                  Showing <strong>{(currentPage - 1) * limit + 1}</strong> to{" "}
                  <strong>{Math.min(currentPage * limit, totalCount)}</strong>{" "}
                  of <strong>{totalCount}</strong> results
                </div>
                <div className={styles.userPersonaPagination}>
                  <ReactPaginate
                    nextLabel={">"}
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={totalPage}
                    forcePage={currentPage - 1}
                    previousLabel="<"
                    pageClassName={styles["page-item"]}
                    pageLinkClassName={styles["page-link"]}
                    previousClassName={styles["prev"]}
                    previousLinkClassName={styles["page-link"]}
                    nextClassName={styles["next"]}
                    nextLinkClassName={styles["page-link"]}
                    breakLabel="..."
                    breakClassName={styles["page-item"]}
                    breakLinkClassName={styles["page-link"]}
                    containerClassName={"pagination"}
                    activeClassName={styles["active"]}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
