// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jwgaptpIVzVUXF6pqmsq{background-color:#fff;display:inline-flex;flex-wrap:wrap;gap:10px;align-items:stretch;margin-top:5px;padding:10px}.jwgaptpIVzVUXF6pqmsq .BHmTsvRba6wZGV7fo8W1 .__F8qCCR2l_gTVidnaoU{margin-bottom:6px;color:#7a7979}.jwgaptpIVzVUXF6pqmsq .BHmTsvRba6wZGV7fo8W1 .VpeD4mLJv_gHlugJKZYO{background-color:var(--secondary-color);display:inline-block;border-radius:4px;padding:4px;padding-right:16px}.jwgaptpIVzVUXF6pqmsq .BHmTsvRba6wZGV7fo8W1 .VpeD4mLJv_gHlugJKZYO img{position:relative;bottom:16px;left:10px}.jwgaptpIVzVUXF6pqmsq .BHmTsvRba6wZGV7fo8W1 .WYRv5GNpI1J2TXjo23A_{color:var(--primary-color);margin-left:3px}`, "",{"version":3,"sources":["webpack://./src/components/generic/conversationCards/intents/IntentsTranscript.module.scss"],"names":[],"mappings":"AACA,sBACE,qBAAA,CACA,mBAAA,CACA,cAAA,CACA,QAAA,CACA,mBAAA,CACA,cAAA,CACA,YAAA,CAGE,kEACE,iBAAA,CACA,aAbK,CAeP,kEACE,uCAAA,CACA,oBAAA,CACA,iBAAA,CACA,WAAA,CACA,kBAAA,CACA,sEACE,iBAAA,CACA,WAAA,CACA,SAAA,CAGJ,kEACE,0BAAA,CACA,eAAA","sourcesContent":["$greyText: #7a7979;\n.wrapper {\n  background-color: white;\n  display: inline-flex;\n  flex-wrap: wrap;\n  gap: 10px;\n  align-items: stretch;\n  margin-top: 5px;\n  padding: 10px;\n\n  .each {\n    .title {\n      margin-bottom: 6px;\n      color: $greyText;\n    }\n    .message {\n      background-color: var(--secondary-color);\n      display: inline-block;\n      border-radius: 4px;\n      padding: 4px;\n      padding-right: 16px;\n      img {\n        position: relative;\n        bottom: 16px;\n        left: 10px;\n      }\n    }\n    .variable {\n      color: var(--primary-color);\n      margin-left: 3px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `jwgaptpIVzVUXF6pqmsq`,
	"each": `BHmTsvRba6wZGV7fo8W1`,
	"title": `__F8qCCR2l_gTVidnaoU`,
	"message": `VpeD4mLJv_gHlugJKZYO`,
	"variable": `WYRv5GNpI1J2TXjo23A_`
};
export default ___CSS_LOADER_EXPORT___;
