import React, { useState, useEffect } from "react";
import styles from "./CreateStrategyModal.module.scss";
import Modal from "../../../generic/modal/Modal";
import Button from "../../../generic/button/Button";
import { useNavigate } from "react-router-dom";
import { createStrategyCloseIcon } from "../../../../theme/assets/svg/strategySvg";
import InputField from "../../../generic/inputField/InputField";
import TextAreaInput from "../../../generic/textareainput/TextAreaInput";
import UploadOptionModal from "../../../moduleComponents/campaign_V2.0/uploadOptionPopUp/UploadOptionModal";
import {
  createStrategyAPIData,
  updateStrategyAPIData,
} from "../../../../redux/strategy/strategyCreation/actions";
import { setTypeOfStrategyMode } from "../../../../redux/strategy/nudgeCreation/actions";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { DateSelectionModal } from "../dateSelectionModal/DateSelectionModal";
import WorkSpaceSalesOnBoarding from "../workSpaceForeSalesOnBoarding/WorkSpaceSalesOnBoarding";
import { USECASE } from "../../../../constants";


interface CreateStrategyModalProps {
  show?: boolean;
  close?: CallableFunction;
  data?: Strategy;
  onclick?: CallableFunction;
  editStrategyPopUp?: boolean;
  dontShow?: string;
}

type Strategy = {
  id?: string;
  strategyName: string | undefined;
  strategyDescription: string | undefined;
  useCaseId?: string
};

export const CreateStrategyModal = ({
  show,
  close,
  data,
  onclick,
  editStrategyPopUp,
  dontShow,
}: CreateStrategyModalProps) => {
  const [strategyInput, setStrategyInput] = useState<Strategy>({
    strategyName: "",
    strategyDescription: "",
  });

  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [dataUploadModal, setDataUploadModal] = useState<boolean>(false);
  const [validCreate, setValidCreate] = useState<boolean>(false);
  const [createApiCall, setCreateApiCall] = useState<boolean>(false);
  const [editApiCall, setEditApiCall] = useState<boolean>(false);
  const [workFlowModal, setWorkFlowModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const moduleSelectedScreen = useSelector(
    (store: RootState) => store?.baseScreen?.leftMenu?.highlightedModule
  );

  const campaignData = useSelector((store: RootState) => {
    return store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo;
  });

  const clientName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );

  const strategyInfo = useSelector(
    (store: RootState) => store?.strategyModuleReducer?.strategyReducer
  );

  const createdStrategyResult = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.createdStrategy
  );
  const clientNameByDropdown = useSelector(
    (store: RootState) => store.campaignReducer?.campaignClientName
  );
  const teamUseCaseId = useSelector(
    (store: RootState) => store?.loginReducer?.currentTeam?.useCaseId
  );
  const currentTeamId = useSelector(
    (state: RootState) => state.loginReducer.currentTeam.id
  );

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStrategyInput((prev: Strategy) => {
      return { ...prev, strategyName: event?.target?.value };
    });
  };

  const handleDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStrategyInput((prev: Strategy) => {
      return { ...prev, strategyDescription: event?.target?.value };
    });
  };

  const handleCancel = () => {
    setStrategyInput({
      strategyName: "",
      strategyDescription: "",
    });
  };

  const handleEditCancel = () => {
    setStrategyInput({
      strategyName: data?.strategyName,
      strategyDescription: data?.strategyDescription,
    });
  };

  const handleCreateRuleDetails = () => {
    const data_to_send: any = {
      // clientName: clientNameByDropdown ? clientNameByDropdown : clientName,
      clientName:
        clientNameByDropdown?.length > 0
          ? clientNameByDropdown
          : campaignData?.accountName?.length > 0
            ? campaignData?.accountName
            : clientName,
      strategyName: strategyInput.strategyName,
      strategyDescription: strategyInput.strategyDescription,
      useCaseId: teamUseCaseId,
      teamId: currentTeamId
    };
    if (moduleSelectedScreen !== "Strategy") {
      data_to_send["campaignId"] = campaignData?.id;
    }

    dispatch(
      createStrategyAPIData({
        ...data_to_send,
      })
    );
  };

  // console.log(strategyInput?.strategyDescription.length, "len");

  const handleUpdateRuleDetails = () => {
    const data_to_send: object = {
      // clientName: clientNameByDropdown ? clientNameByDropdown : clientName,
      clientName:
        clientNameByDropdown?.length > 0
          ? clientNameByDropdown
          : campaignData?.accountName?.length > 0
            ? campaignData?.accountName
            : clientName,
      strategyName: strategyInput.strategyName,
      strategyDescription: strategyInput.strategyDescription,
      id: createdStrategyResult?.id,
      useCaseId: teamUseCaseId,
      teamId: currentTeamId

    };
    // if (moduleSelectedScreen !== "Strategy") {
    //   data_to_send["campaignId"] = campaignData?.id;
    // }
    if (
      createdStrategyResult?.strategyName !== strategyInput?.strategyName ||
      createdStrategyResult?.strategyDescription !==
      strategyInput?.strategyDescription
    ) {
      dispatch(
        updateStrategyAPIData({
          ...data_to_send,
        })
      );
    }
  };

  const handleApiCall = () => {
    dispatch(setTypeOfStrategyMode(editStrategyPopUp ? "edit" : "create"));

    return editStrategyPopUp
      ? handleUpdateRuleDetails()
      : handleCreateRuleDetails();
  };

  const handleModalCall = () => {
    if (editStrategyPopUp) {
      if (
        strategyInfo?.isLoadingUpdateStrategy === true ||
        strategyInfo.updateStrategyError !== ""
      ) {
        return false;
      }
    } else {
      if (
        strategyInfo?.isLoadingCreateStrategy === true ||
        strategyInfo.createStrategyError !== ""
      ) {
        return false;
      }
    }
    return true;
  };

  const handleModalCallCampaign = () => {
    // close && close();
    if (editStrategyPopUp) {
      if (
        strategyInfo?.isLoadingUpdateStrategy === true ||
        strategyInfo.updateStrategyError !== ""
      ) {
        return false;
      }
    } else {
      if (
        strategyInfo?.isLoadingCreateStrategy === true ||
        strategyInfo.createStrategyError !== ""
      ) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (createApiCall == true || editApiCall == true) {
      moduleSelectedScreen == "Strategy"
        ? setDataUploadModal(handleModalCall())
        : setWorkFlowModal(handleModalCallCampaign());
    }
  }, [
    strategyInfo?.isLoadingCreateStrategy,
    strategyInfo?.isLoadingUpdateStrategy,
    strategyInfo.updateStrategyError,
    strategyInfo?.createStrategyError,
    createApiCall,
    editApiCall,
  ]);

  const handleValidation = () => {
    if (
      strategyInput.strategyDescription !== "" &&
      strategyInput.strategyName === ""
    ) {
      return true;
    } else if (
      strategyInput.strategyDescription === "" &&
      strategyInput.strategyName !== ""
    ) {
      return true;
    } else if (
      strategyInput.strategyDescription === "" &&
      strategyInput.strategyName === ""
    ) {
      return true;
    } else if (
      strategyInput?.strategyName?.trim().length === 0 ||
      strategyInput?.strategyDescription?.trim().length === 0
    ) {
      return true;
    } else if (
      strategyInput?.strategyName &&
      strategyInput.strategyName.length > 18
    ) {
      return true;
    } else if (
      strategyInput?.strategyDescription &&
      strategyInput?.strategyDescription?.length > 250
    ) {
      return true;
    } else if (strategyInfo.isLoadingCreateStrategy == true) {
      return true;
    } else if (strategyInfo.isLoadingUpdateStrategy == true) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (dataUploadModal === true) {
      setDataUploadModal(false);
      if (editStrategyPopUp) {
        setEditApiCall(false);
      } else {
        setCreateApiCall(false);
      }
      navigate("/strategy/dataupload");
    }
  }, [dataUploadModal]);

  useEffect(() => {
    setValidCreate(handleValidation());
  }, [
    strategyInput.strategyName,
    strategyInput.strategyDescription,
    strategyInfo.isLoadingUpdateStrategy,
    strategyInfo.isLoadingCreateStrategy,
  ]);

  useEffect(() => {
    setStrategyInput((prev) => {
      return {
        ...prev,
        strategyName: data?.strategyName ? data?.strategyName : "",
        strategyDescription: data?.strategyDescription
          ? data?.strategyDescription
          : "",
        id: data?.id ? data?.id : "",
      };
    });
  }, [data]);

  console.log("uuu", teamUseCaseId, data)

  return (
    <div>
      {((moduleSelectedScreen !== "Strategy" && workFlowModal !== true) ||
        (moduleSelectedScreen == "Strategy" && dataUploadModal !== true)) && (
          <Modal
            shown={show}
            close={() => {
              editStrategyPopUp ? handleEditCancel() : handleCancel();
              editStrategyPopUp ? setEditApiCall(false) : setCreateApiCall(false);
              close && close();
            }}
            extraClassStyleModalContent={styles.createStrategyModalStyling}
          >
            <div className={styles.modalInfoDiv} id="auto_createModalInfoDiv">
              <div className={styles.modalHeading} id="auto_createmodalHeading">
                {editStrategyPopUp ? "Update Strategy" : "Create New Strategy"}
              </div>
              <div
                className={styles.closeIcon}
                id="modal-close-icon-div"
                data-testid="close-icon"
              >
                <img
                  src={createStrategyCloseIcon}
                  onClick={() => {
                    editStrategyPopUp ? handleEditCancel() : handleCancel();
                    editStrategyPopUp
                      ? setEditApiCall(false)
                      : setCreateApiCall(false);
                    close && close();
                  }}
                  className={styles.iconStyling}
                  id="modal-close-icon"
                />
              </div>
            </div>

            <div className={styles.strategyFormDiv} id="auto_strategyFormDiv">
              <div
                className={styles.strategyFormNameField}
                id="auto_strategyFormNameField"
              >
                <div
                  className={styles.strategyLabels}
                  id="auto_strategyLabel_Name"
                >
                  Enter Name:
                </div>
                <div
                  className={styles.strategyInputDiv}
                  id="auto_strategyInputDiv_Name"
                >
                  <InputField
                    id="auto_strategyInput_Name"
                    extraClass={styles.inputDivStyling}
                    className={styles.inputFieldStyling}
                    type="text"
                    placeholder="Name"
                    onChange={handleNameChange}
                    onFocus={() => {
                      setIsReadOnly(false);
                    }}
                    onBlur={() => {
                      setIsReadOnly(true);
                    }}
                    value={strategyInput?.strategyName}
                    maxLength={19}
                  />
                  {strategyInput?.strategyName &&
                    strategyInput?.strategyName?.length > 18 ? (
                    <div
                      className={styles.errorMsgStyling}
                      id="auto_errorMsg_name"
                    >
                      Maximum 18 characters are allowed
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div
                className={styles.strategyFormDescField}
                id="auto_strategyFormDescField"
              >
                <div
                  className={styles.strategyLabels}
                  id="auto_strategyLabel_Desc"
                >
                  Enter Description:
                </div>
                <div className={styles.strategyInputDiv}>
                  <TextAreaInput
                    id="auto_strategyInput_Desc"
                    extraTextClass={styles.inputDescDivStyling}
                    extraClass={styles.inputDescStyling}
                    handleChange={handleDescChange}
                    onFocus={() => {
                      setIsReadOnly(false);
                    }}
                    onBlur={() => {
                      setIsReadOnly(true);
                    }}
                    value={strategyInput?.strategyDescription}
                    placeholder="Description"
                    maxLength={251}
                  />
                  {strategyInput?.strategyDescription &&
                    strategyInput?.strategyDescription?.length > 250 ? (
                    <div
                      className={styles.errorMsgStyling}
                      id="auto_errorMsg_desc"
                    >
                      Maximum 250 characters are allowed
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className={styles.strategyButtonDiv}>
              <Button
                text="Cancel"
                extraClass={`${styles.buttonStyling} ${styles.cancelButtonStyle}`}
                onClick={() => {
                  editStrategyPopUp ? handleEditCancel() : handleCancel();
                  close && close();
                }}
                id="auto_createModalCloseBtn"
              // disabled={
              //   strategyInput?.strategyName === "" &&
              //   strategyInput?.strategyDescription === ""
              //     ? isDisabled
              //     : !isDisabled
              // }
              />
              <Button
                text={
                  strategyInfo.isLoadingUpdateStrategy ||
                    strategyInfo.isLoadingCreateStrategy
                    ? "Confirm"
                    : "Confirm"
                }
                extraClass={`${styles.buttonStyling} ${validCreate
                  ? styles.createButtonInActiveStyle
                  : styles.createButtonActiveStyle
                  }`}
                onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleApiCall();
                  e.stopPropagation();
                  show;
                  editStrategyPopUp
                    ? setEditApiCall(true)
                    : setCreateApiCall(true);
                }}
                disabled={validCreate}
                id="auto_createModalConfirmBtn"
              />
            </div>
          </Modal>
        )}
      {/* {dataUploadModal && (
        <UploadOptionModal
          show={dataUploadModal}
          close={() => {
            setDataUploadModal(false);
            close && close();
            editStrategyPopUp ? setEditApiCall(false) : setCreateApiCall(false);
          }}
          module={"strategy"}
          data={data}
        />
      )} */}
      {workFlowModal && (
        teamUseCaseId === USECASE?.debtCollection?.id ?
          <DateSelectionModal
            show={workFlowModal}
            close={() => {
              close && close();
              setWorkFlowModal(false);
              editStrategyPopUp ? setEditApiCall(false) : setCreateApiCall(false);
            }}
          />
          :
          <WorkSpaceSalesOnBoarding
            show={workFlowModal}
            close={() => {
              close && close();
              setWorkFlowModal(false);
              editStrategyPopUp ? setEditApiCall(false) : setCreateApiCall(false);
            }}
          />
      )}
    </div>
  );
};
