import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addCustomer,
  getCustomersDataByApi,
  deleteCustomer,
  updateCustomerData,
  setDisableBtn,
  saveDataByApi,
  executeCallByApi,
  executeWpByApi,
  saveCustomerDataByApi,
  campaignTestingInitialSetup,
  updateCustomerDataObject,
  updateSchemaByAgent,
  setSelectedAgent,
} from "../../../redux/campaignTestingInfo/actions";
import { ToastContainer, toast } from "react-toastify";
import UploadCampaignDetails from "../../../components/moduleComponents/campaign/uploadcampaigndetails/UploadCampaignDetails";
import { tabSelected } from "../../../redux/breadcrum/actions/breadcrum.actions";
import LoaderSaarthi from "../../../components/generic/loader/Loader";
import AddBtn from "../../../components/moduleComponents/demo/addBtn/AddBtn";
import CheckBox from "../../../components/generic/checkbox/Checkbox";
import DropDown from "../../../components/generic/dropdownsaarthi2/DropdownSaarthi";
import ErrorMessage from "../../../components/generic/errorMessage/ErrorMessage";
import CampaignTestingBody from "./subComponents/CampaignTestingBody";
import { callWhite } from "../../../theme/assets/svg/campaignTestingInfo";
import { whatsAppWhite } from "../../../theme/assets/svg/campaignTestingInfo";
import { whatsAppBlack } from "../../../theme/assets/svg/campaignTestingInfo";
import { callBlack } from "../../../theme/assets/svg/campaignTestingInfo";
import { downArrow } from "../../../theme/assets/svg/demo";
import ToggleButton from "../../../components/generic/toggelButton/ToggleButton";
import "react-toastify/dist/ReactToastify.css";
import "./campaignTesting.css";
import { DemoDropdownComp } from "../../../components/moduleComponents/demoDropdownComponent/DemoDropdownComp";
import { RootState } from "../../../redux";
import { ROLES, USECASE } from "../../../constants";
import _ from "lodash";
import {
  getAllClientDetailByname,
  getSchemaOfClientRequest,
} from "../../../redux/onboarding/login/actions";
import { setFilterHasChanged } from "../../../redux/filters/actions";
import { checkTechHealth } from "../../../redux/campaign/actions";
import {
  getStrategiesAPIData,
} from "../../../redux/strategy/strategyCreation/actions";

const CampaignTestingInfo = (props: any) => {
  /* ---- useStates ---- */
  const [showExtraFields, setShowExtraFields] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isExecutingCall, setIsExecutingCall] = useState(false);
  const [isExecutingWp, setIsExecutingWp] = useState(false);
  const [disableExecuteBtn, setDisableExecuteBtn] = useState(false);
  const [moveToUploadData, setmoveToUploadData] = useState(false);
  const [agentNameArr, setAgentNameArr] = useState<string[]>([]);
  const [showChannels, setShowChannels] = useState<Record<string, boolean>>({
    Call: true,
    Whatsapp: true,
  });
  const [dDSelectedAgent, setDDSelectedAgent] = useState("");
  const [selectedCallType, setSelectedCallType] = useState("");
  const [selectedTelephony, setSelectedTelephony] = useState("");
  const [selectedLeadType, setSelectedLeadType] = useState("");
  const [clientList, setClientList] = useState<any[]>([]);
  const [searchClientName, setSearchClientName] = useState("");
  const [resetClientList, setResetClientList] = useState([]);
  const [isVoiceBlast, setIsVoiceBlast] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("91");
  const [selectedCountryCodewithString, setSelectedCountryCodewithString] =
    useState("+91(India)");
  const [selectedStrategyDetails ,setSelectedStrategyDetails] = useState({
    options : [],
    selectedStrategy : "",
    selectedData : {}
  });
  /* ---- useSelectors, dispatch ---- */
  let clientName = useSelector(
    (store: any) =>
      store.loginReducer.userLoginInfo?.userDetail?.accountDetails[0]?.name
  );
  const currentSelectedUser = useSelector((state: RootState) => {
    return state.filterReducers.selectedFilterOptions.clientName;
  });
  let accountDetails = useSelector(
    (store: any) => store.loginReducer.userLoginInfo?.userDetail?.accountDetails
  );
  const currentSelectedAccount = useSelector((state: RootState) => {
    return state.filterReducers.selectedFilterOptions.clientName;
  });
  const data = useSelector(
    (store: any) => store.campaignTestingInfoReducer?.customerDataArr
  );
  const disableBtn = useSelector(
    (store: any) => store.campaignTestingInfoReducer?.disableBtn
  );
  const accessTokenLogin = useSelector(
    (store: any) =>
      store.loginReducer.userLoginInfo?.userSessionDetails?.accessToken
  );
  let channels = useSelector(
    (store: any) =>
      store.loginReducer.userLoginInfo?.accountDetails[0]?.channels
  );
  const filterData = useSelector(
    (store: any) => store.filterReducer?.filterData?.data?.filters
  );
  const jwtToken = useSelector(
    (store: any) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const schemaDataFiltered = useSelector(
    (store: any) => store.campaignTestingInfoReducer?.schemaDataFiltered
  );
  const isInitiallySettingUp = useSelector(
    (store: any) => store.campaignTestingInfoReducer?.initiallySettingUp
  );
  const selectedAgent = useSelector(
    (store: any) => store.campaignTestingInfoReducer?.selectedAgent
  );
  const campaignSelectedAgent = useSelector(
    (store: any) => store.campaignReducer?.campaignSelectedData?.agentId
  );
  let agentIdDropdown = useSelector(
    (store: any) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]
        ?.IsAgentIdDropdownEnable
  );
  let salesOnboardingIdDropdown = useSelector(
    (store: any) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]
        ?.isSalesAndonboarding
  );
  let typeOfCollection = useSelector(
    (store: any) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]
        ?.isSalesAndonboarding
  );
  const useCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam?.useCaseId
  );
  const currentClientData = useSelector(
    (store: any) => store.loginReducer?.allClientDataByname
  );
  const accountType = useSelector(
    (store: RootState) =>
      store.loginReducer.userLoginInfo.accountDetails[0].type
  );
  const reduxClientList = useSelector(
    (store: RootState) => store.filterReducers?.clientName
  );
  const userDetail = useSelector(
    (store: any) => store.loginReducer?.userLoginInfo?.userDetail
  );
  const userRole = useSelector(
    (store: any) => store.loginReducer?.userLoginInfo?.userDetail?.role
  );
  const teamUseCaseId = useSelector(
    (store: any) => store.loginReducer.currentTeam.useCaseId
  );
  const currentTeamId = useSelector(
    (store: any) => store.loginReducer.currentTeam.id
  );
  const healthData = useSelector(
    (store: any) => store?.campaignReducer?.techHealthData?.data?.data
  );
  const newHealth = healthData?.map((e: any) => {
    return e?.["_settledValueField"];
  });

  const strategyInfo = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.strategyDetails
  );
  console.log("hello" , strategyInfo?.strategies  )

  const dispatch = useDispatch();
 
  // conditional values.
  if (currentSelectedUser?.[0]?.name) {
    clientName = currentSelectedUser?.[0]?.name;
    accountDetails = currentSelectedAccount;
    delete accountDetails?.[0]?.type;

    channels = currentClientData?.channels;
    agentIdDropdown = currentClientData?.IsAgentIdDropdownEnable;
    salesOnboardingIdDropdown = currentClientData?.isSalesAndonboarding;
    typeOfCollection = currentClientData?.isSalesAndonboarding;
  }

  /* ---- variables ---- */
  const clientConfig = { ...schemaDataFiltered?.telephonyData };
  const defaultInputFieldData = {
    ...schemaDataFiltered?.defaultInputFieldData,
  };
  defaultInputFieldData["isActive"] = true;

  const _isMount = useRef(false);
  /* ---- useEffects ---- */
  useEffect(() => {
    const body = {
      params: {
        useCaseId: teamUseCaseId,
        type: "Campaign Testing Info",
      },
      headers: {
        "Content-Type": "application/json",
        "x-access-token": jwtToken,
      },
    };
    dispatch(checkTechHealth(body));
    dispatch(setSelectedAgent(""));
  }, []);

  useEffect(() => {
    // if (!schemaDataFiltered?.saveKey) {
    dispatch(
      campaignTestingInitialSetup(
        clientName,
        agentIdDropdown,
        salesOnboardingIdDropdown
      )
    );
    // }
  }, [agentIdDropdown, salesOnboardingIdDropdown]);

  useEffect(() => {
    if (!isInitiallySettingUp && _isMount.current === true) {
      getCustomersDataByApi(
        dispatch,
        clientName,
        defaultInputFieldData,
        data,
        schemaDataFiltered?.saveKey,
        schemaDataFiltered?.backEndKey,
        handleAgentSelection,
        props.sidebarView,
        campaignSelectedAgent,
        schemaDataFiltered.nameToBackEndKeyMapping,
        schemaDataFiltered?.agentsConfigData,
        handleCallTypeSelection,
        handleSelectedTelephonyChange
      );
      
      if (typeof schemaDataFiltered?.agentsConfigData === "object") {
        const agentName = schemaDataFiltered?.agentsConfigData?.map(
          (item: Record<string, any>) => item?.agentId
        );
        setAgentNameArr(agentName);
      }
      setIsLoading(false);
    } else {
      _isMount.current = true;
    }
  }, [isInitiallySettingUp]);
  console.log("hh" , schemaDataFiltered)
  /**for strategy**/
  // useEffect(
  //   () => {
  //     const data_to_send: any = {
  //       clientName:  clientName
  //     };
  //     dispatch(
  //       getStrategiesAPIData({
  //         ...data_to_send,
  //       })
  //     );
  //   },[clientName]
  // )

  // data validation
  useEffect(() => {
    const isValidData = validateData();
    const selectedData = data?.filter((e: any) => e.isActive === true);
    if (
      isValidData &&
      disableBtn &&
      (selectedAgent?.length ||
        !(
          // condition to show drop down
          (
            clientName.includes("Maia") ||
            clientName?.includes("TVS Credit") ||
            clientName?.includes("Bajaj Finance Nach") ||
            clientName?.includes("SmartCoin") ||
            clientName?.includes("Fullerton") ||
            clientName?.includes("Sphot") ||
            clientName?.includes("Saarthi Demo") ||
            clientName?.includes("International Demo") ||
            clientName?.includes("Smartcoin") ||
            agentIdDropdown === true ||
            salesOnboardingIdDropdown === true ||
            useCaseId === USECASE.vehicleService.id
          )
        ))
    ) {
      setDisableBtn(dispatch, false);
    } else if (
      !disableBtn &&
      (!isValidData ||
        ((clientName.includes("Maia") || // condition to show drop down
          clientName?.includes("TVS Credit") ||
          clientName?.includes("Bajaj Finance Nach") ||
          clientName?.includes("SmartCoin") ||
          clientName?.includes("Fullerton") ||
          clientName?.includes("Sphot") ||
          clientName?.includes("Saarthi Demo") ||
          clientName?.includes("International Demo") ||
          clientName?.includes("Smartcoin") ||
          agentIdDropdown === true ||
          salesOnboardingIdDropdown === true ||
          useCaseId === USECASE.vehicleService.id) &&
          !selectedAgent?.length))
    ) {
      setDisableBtn(dispatch, true);
    }
    if (data?.length === 0) {
      setDisableExecuteBtn(true);
    } else if (selectedData?.length === 0) {
      setDisableExecuteBtn(true);
    } else {
      setDisableExecuteBtn(false);
    }
  }, [
    data,
    selectedCallType,
    selectedLeadType,
    selectedTelephony,
    selectedAgent,
  ]);

  useEffect(() => {
    if (
      schemaDataFiltered?.agentsConfigData?.find(
        (item: any) => item.agentId === selectedAgent
      )
    ) {
      setDDSelectedAgent(selectedAgent);
    } else setDDSelectedAgent("");
  }, [selectedAgent, schemaDataFiltered?.agentsConfigData]);

  useEffect(() => {
    if (reduxClientList?.length) {
      const list = reduxClientList.map((item: any) => item.name);
      setClientList(list);
      setResetClientList(list);
    }
  }, [reduxClientList]);

  /* for setting default lead Type  */
  useEffect(() => {
    if (!selectedLeadType) {
      if (typeOfCollection) {
        setSelectedLeadType("Promotional");
      } else {
        setSelectedLeadType("Transactional");
      }
    }
  }, [typeOfCollection]);

  useEffect(() => {
    if (currentSelectedUser?.[0]?.name) {
      dispatch(getAllClientDetailByname(currentSelectedUser?.[0]?.name));
    }
  }, [currentSelectedUser?.[0]?.name]);

/**strategy selection */
// useEffect(
//   () => {
//     if(strategyInfo?.strategies?.length){
//       const startegyNames = strategyInfo?.strategies?.map((e :any) => e?.strategyName)
//       setSelectedStrategyDetails(prev => {return{
//         ...prev , 
//         options : startegyNames,
//         selectedStrategy : "",
//         selectedData : {}
//       }})
//     }
//   },
//   [strategyInfo?.strategies ]
// )







  /* ---- helper functions ---- */
  const getExtraConfigFields = (clientConfig: any) => {
    const extraConfigFields = clientConfig ? clientConfig : {};
    extraConfigFields["accessToken"] = accessTokenLogin;
    extraConfigFields["clientName"] = clientName;
    extraConfigFields["callType"] = selectedCallType;
    extraConfigFields["leadType"] = selectedLeadType;
    extraConfigFields["telephonyName"] = selectedTelephony;
    extraConfigFields["countryCode"] = selectedCountryCode;
    // extraConfigFields["strategy"] = selectedStrategyDetails?.selectedData
    return extraConfigFields;
  };

  const validateData = () => {
    let isValidData = true;
    data &&
      data?.forEach((row: any) => {
        const showFieldsName =
          schemaDataFiltered?.showFieldsByFlow?.[row["primaryInfo.flow"]] ||
          schemaDataFiltered?.showFieldsByFlow?.[""];
        const showFieldsBackEndKey = showFieldsName?.map(
          (item: string) => schemaDataFiltered.nameToBackEndKeyMapping[item]
        );

        showFieldsBackEndKey?.forEach((item: string) => {
          if (schemaDataFiltered?.isRequiredObject[item]) {
            if (
              (item === "contactInfo.primary" ||
                item === "contactInfo.salesManager") &&
              row[item]?.length !== 10
            ) {
              isValidData = false;
            } else if (
              (item === "primaryInfo.clientname" ||
                item === "primaryInfo.agentname") &&
              row[item]?.length < 3
            ) {
              isValidData = false;
            } else if (row[item] === "") {
              isValidData = false;
            }
          }
        });
        // if (
        //   row["loanAccountDetails.currentPaymentStatus"] === "Paid" &&
        //   row["loanAccountDetails.paidDate"] === ""
        // ) {
        //   isValidData = false;
        // }
      });
    if (
      selectedCallType === "" ||
      selectedTelephony === "" ||
      selectedLeadType === ""
    ) {
      isValidData = false;
    }

    return isValidData;
  };

  /* ---- handlers ---- */
  const handleAddBtnClick = () => {
    addCustomer(dispatch, defaultInputFieldData);
  };

  const handleRemoveClick = (index: any) => {
    setShowExtraFields(null);
    deleteCustomer(dispatch, index);
  };

  const handleInputChange = (value: any, key: any, index: any) => {
    if (typeof value === "string" && !value.replace(/\s/g, "").length) {
      value = value.trim();
    }
    const nameRegex = /^[a-zA-Z\s]*$/;
    const numberRegex = /^[0-9]*$/;

    // if(key=== "primaryInfo.flow" && value.length > 0){
    //   updateCustomerData(dispatch, "", "primaryInfo.language", index);
    // }

    if (key === "primaryInfo.flow" && value.length > 0) {
      const customerData = data[index];

      const showFieldsName =
        schemaDataFiltered?.showFieldsByFlow?.[value] ||
        schemaDataFiltered?.showFieldsByFlow?.[""];
      const showFieldsBackEndKey = showFieldsName?.map(
        (item: string) => schemaDataFiltered.nameToBackEndKeyMapping[item]
      );
      console.log("abhi123", customerData, showFieldsBackEndKey);
      const newCustomerData: Record<string, string> = {};
      Object.keys(customerData).forEach((item) => {
        if (showFieldsBackEndKey.includes(item)) {
          newCustomerData[item] = customerData[item];
        } else if (item === "isActive") {
          newCustomerData["isActive"] = customerData["isActive"];
        } else if (item === "demoLoanId") {
          console.log("abhi bhai", customerData?.demoLoanId);
          newCustomerData["demoLoanId"] = customerData["demoLoanId"];
        } else {
          newCustomerData[item] = "";
        }
      });
      newCustomerData["primaryInfo.flow"] = value;
      console.log("abhi12", newCustomerData);
      dispatch(updateCustomerDataObject(newCustomerData, index));
      updateCustomerData(dispatch, "", "primaryInfo.language", index);
      return;
    }
    if (key === "loanAccountDetails.noOfLoans" && value < 1 && value !== "") {
      return;
    }
    if (key === "loanAccountDetails.noOfLoanTaken" && value > 99) {
      return;
    }
    if (key === "contactInfo.primary" && !numberRegex.test(value)) {
      return;
    }

    if (key === "primaryInfo.firstName" && !nameRegex.test(value)) {
      return;
    }
    if (key === "primaryInfo.geographicLocation" && !nameRegex.test(value)) {
      return;
    }
    if (key === "primaryInfo.clientname" && !nameRegex.test(value)) {
      return;
    }
    if (key === "primaryInfo.agentname" && !nameRegex.test(value)) {
      return;
    }
    if (
      (key === "loanAccountDetails.emiAmount" ||
        key === "primaryInfo.age" ||
        key === "noOfLoans") &&
      value === "0"
    ) {
      return;
    }

    if (
      key === "primaryInfo.age" &&
      (parseInt(value) > 99 || parseInt(value) < 0)
    ) {
      return;
    }
    if (
      selectedAgent &&
      key === schemaDataFiltered?.nameToBackEndKeyMapping?.["gender"]
    ) {
      const agentName =
        value === "Male"
          ? schemaDataFiltered?.agentName?.femaleAgentName
          : schemaDataFiltered?.agentName?.maleAgentName;
      updateCustomerData(
        dispatch,
        agentName,
        schemaDataFiltered?.nameToBackEndKeyMapping?.["Agent Name"],
        index
      );
    }
    updateCustomerData(dispatch, value, key, index);
  };

  const handleShowMoreToggle = (index: any, e: any) => {
    setShowExtraFields((prev) => (prev === index ? null : index));

    setTimeout(() => {
      const bodyContainer = document.querySelector(
        ".container__body"
      ) as HTMLElement;
      const bodyDetail = bodyContainer.getBoundingClientRect();
      const bodyRow = e.target.closest(".fields-col");
      const bodyRowDetail = bodyRow?.getBoundingClientRect();

      if (bodyRow) {
        const bodyEndPosition = bodyDetail.y + bodyDetail.height;
        const bodyRowEndPosition = bodyRowDetail.y + bodyRowDetail.height;
        if (bodyRowEndPosition > bodyEndPosition) {
          bodyRow.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
      }
    }, 10);
  };

  const handleCalenderClick = (e: any) => {
    e.target.type = "date";
    e.target.showPicker();
  };
  const handleCalenderBlur = (e: any) => {
    if (e.target.value === "") {
      e.target.type = "text";
    }
  };

  const handleSaveClick = () => {
    const set = async () => {
      setIsSaving(true);
      const isSuccess = await saveDataByApi(
        dispatch,
        clientName,
        data,
        schemaDataFiltered?.saveKey,
        schemaDataFiltered?.backEndKey,
        selectedAgent,
        selectedCallType,
        selectedTelephony
      );
      setIsSaving(false);
      if (props.sidebarView) {
        setIsSaving(true);
        dispatch(tabSelected("API") as any);
        const accountId = accountDetails[0]?.id;
        const datawithId = await getCustomersDataByApi(
          dispatch,
          clientName,
          defaultInputFieldData,
          data,
          schemaDataFiltered?.saveKey,
          schemaDataFiltered?.backEndKey,
          handleAgentSelection,
          props.sidebarView,
          campaignSelectedAgent,
          schemaDataFiltered.nameToBackEndKeyMapping,
          schemaDataFiltered?.agentsConfigData,
          handleCallTypeSelection,
          handleSelectedTelephonyChange
        );

        const isSuccess = await saveCustomerDataByApi(
          dispatch,
          datawithId,
          jwtToken,
          accountId,
          clientName,
          schemaDataFiltered?.backEndKey,
          schemaDataFiltered?.uploadDataKey,
          schemaDataFiltered?.dropDownCallMapping
        );
        setIsSaving(false);
        setmoveToUploadData(true);
      }
    };
    set();
  };
  const handleExecuteCallClick = (isVoiceBlast?: boolean) => {
    const callexecute = async () => {
      setIsExecutingCall(true);
      const extraConfigFields = getExtraConfigFields(clientConfig);
      const isSuccessMsg = await executeCallByApi(
        dispatch,
        data,
        extraConfigFields,
        clientName,
        schemaDataFiltered?.backEndKey,
        schemaDataFiltered?.dropDownCallMapping,
        schemaDataFiltered?.makeValueObject,
        schemaDataFiltered?.agentsConfigData,
        selectedAgent,
        schemaDataFiltered?.createNestedObjectCalling
      );
      setIsExecutingCall(false);
    };
    const voiceBlastexecute = async () => {
      setIsVoiceBlast(true);
      const extraConfigFields = getExtraConfigFields(clientConfig);
      const isSuccessMsg = await executeCallByApi(
        dispatch,
        data,
        extraConfigFields,
        clientName,
        schemaDataFiltered?.backEndKey,
        schemaDataFiltered?.dropDownCallMapping,
        schemaDataFiltered?.makeValueObject,
        schemaDataFiltered?.agentsConfigData,
        selectedAgent,
        schemaDataFiltered?.createNestedObjectCalling,
        isVoiceBlast
      );
      setIsVoiceBlast(false);
    };
    if (isVoiceBlast) {
      voiceBlastexecute();
    } else {
      callexecute();
    }
  };
  const handleExecuteWpClick = () => {
    const whatsAppexecute = async () => {
      setIsExecutingWp(true);
      const extraConfigFields = getExtraConfigFields(clientConfig);
      const isSuccessMsg = await executeWpByApi(
        dispatch,
        data,
        extraConfigFields,
        accountDetails,
        schemaDataFiltered?.backEndKey,
        schemaDataFiltered?.dropDownWhatsAppMapping,
        schemaDataFiltered?.whatsAppToBackendKeyMapping,
        schemaDataFiltered?.agentsConfigData,
        selectedAgent
      );
      setIsExecutingWp(false);
    };
    whatsAppexecute();
  };

  const handleDropdownClick = (e: any) => {
    setTimeout(() => {
      // console.log();
      const bodyContainer = document.querySelector(
        ".container__body"
      ) as HTMLElement;
      const bodyDetail = bodyContainer.getBoundingClientRect();
      const dropdown = e.target.closest(".dropdownTopDiv")?.children[1];
      const dropdownDetail = dropdown?.getBoundingClientRect();

      // console.log(dropdownEndPosition > bodyEndPosition);
      if (dropdown) {
        const bodyEndPosition = bodyDetail.y + bodyDetail.height;
        const dropdownEndPosition = dropdownDetail.y + dropdownDetail.height;
        if (dropdownEndPosition > bodyEndPosition) {
          dropdown.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
      }
    }, 10);
  };

  const handleAgentSelection = (clickedAgent: string, byAction?: boolean) => {
    if (!byAction && clickedAgent === selectedAgent) return;
    dispatch(setSelectedAgent(clickedAgent));
    hideButtonByAgent(clickedAgent);
    const schemaCopy =  schemaDataFiltered?.schema && JSON.parse(JSON.stringify(schemaDataFiltered?.schema));
    const customerData = JSON.parse(JSON.stringify(data));
    console.log("schema" , schemaCopy)
    dispatch(
      updateSchemaByAgent(
        clickedAgent,
        schemaDataFiltered?.agentsConfigData,
        schemaCopy,
        customerData,
        schemaDataFiltered?.nameToBackEndKeyMapping
      )
    );
  };

  const hideButtonByAgent = (selectedAgent: string) => {
    setShowChannels({ Call: false, Whatsapp: false });
    if (selectedAgent) {
      const selectedAgentData = schemaDataFiltered?.agentsConfigData.find(
        (item: any) => item.agentId === selectedAgent
      );
      if (selectedAgentData?.Call || selectedAgentData?.Whatsapp) {
        if (selectedAgentData?.Call)
          setShowChannels((prev) => {
            return { ...prev, Call: true };
          });
        if (selectedAgentData?.Whatsapp)
          setShowChannels((prev) => {
            return { ...prev, Whatsapp: true };
          });
      } else setShowChannels({ Call: true, Whatsapp: true });
    } else setShowChannels({ Call: true, Whatsapp: true });
  };

  const handleCallTypeSelection = (selectedItem: string) => {
    setSelectedCallType(selectedItem);
  };
  const handleSelectedTelephonyChange = (selectedItem: string) => {
    setSelectedTelephony(selectedItem);
  };
  /**select Strategy */
  // const handleSelectedStartegyChange = (selectedItem: string) => {
  //   // setSelectedStrategy(selectedItem);
  //   const selectedStrategyData = strategyInfo?.strategies?.filter((e:any) => e?.strategyName === selectedItem)
  //   setSelectedStrategyDetails(prev => {return{
  //     ...prev , 
  //     selectedStrategy : selectedItem,
  //     selectedData : selectedStrategyData?.[0]
  //   }})
  // };
  console.log("sam" , selectedStrategyDetails)
  const handleLeadTypeSelection = (selectedItem: string) => {
    setSelectedLeadType(selectedItem);
  };
  const handleCountryCodeSelection = (selectedItem: string) => {
    const str = selectedItem?.split("(");
    console.log("**", str, str[0], str[0]?.slice(1));
    setSelectedCountryCode(str[0]?.slice(1));
    setSelectedCountryCodewithString(selectedItem);
  };

  const getSearchItem = (e: any) => {
    setSearchClientName(e.target.value);
    const toSearchSaarthi = e.target.value.toLowerCase();
    const searchSaarthiData = resetClientList;
    if (toSearchSaarthi.length > 0) {
      const filteredSaarthiData = searchSaarthiData.map((d: any, i: any) => {
        if (d == "Select All") {
          return d;
        } else {
          if (d?.toLowerCase().match(toSearchSaarthi) && toSearchSaarthi) {
            return d;
          }
        }
      });
      const finalSaarthiData = filteredSaarthiData.filter((e: any) => {
        return e;
      });

      if (finalSaarthiData.length >= 1) {
        setClientList(finalSaarthiData);
      } else {
        setClientList(resetClientList);
      }
    } else {
      setClientList(resetClientList);
    }
  };

  const onChangeClientName = (data: any) => {
    if (data?.toLowerCase() != "no matches found") {
      const selectedData = [
        reduxClientList.find((item: any) => item.name === data),
      ];
      dispatch(
        getSchemaOfClientRequest(
          {
            id: selectedData[0]?.id,
            originalId: userDetail?._id,
          },
          {
            type: "clientName",
            options: selectedData,
          }
        )
      );
      dispatch(setFilterHasChanged());
    }
  };

  return (
    <>
      {moveToUploadData ? (
        <div className="tab-section">
          <UploadCampaignDetails demoApiPage={"demoapipage"} />
        </div>
      ) : (
        <div className="wrapper-campaign-testing-info">
          {/* <div className="sidebar-div">
            {props.sidebarView ? (
              ""
            ) : (
              <Sidebar
                activeData={(data) => {
                  setIsActive(data);
                }}
              />
            )}
          </div> */}
          <div className="outer-container">
            <div className="campaign-testing-info__container">
              <div
                className={
                  (clientName.includes("Saarthi Demo") ||
                    clientName.includes("International Demo")) &&
                  !isLoading
                    ? "container__head_demo"
                    : "container__head"
                }
              >
                {/* {clientName?.includes("Saarthi Demo") && <div className="demo_api_dropdown">
                  <DemoDropdownComp />
                </div>} */}
                {(clientName.includes("Saarthi Demo") ||
                  clientName.includes("International Demo")) &&
                  !isLoading && (
                    <div className="demo_api_dropdown">
                      <DemoDropdownComp
                        data={newHealth}
                        extraStyle={"Dropdown general-dropdown cursorPointer"}
                        extraTopClass={"healthDDStyle"}
                      />
                    </div>
                  )}
                {clientName.includes("International Demo") && !isLoading && (
                  <div className="callTypeClassDiv demo_api_dropdown">
                    <DropDown
                      droplist={{
                        optionList: ["+1(US)", "+44(UK)", "+91(India)"],
                        placeHolderText: "Select Country Code",
                        imgSrcRight: downArrow,
                      }}
                      editedItem={selectedCountryCodewithString}
                      selectedItem={handleCountryCodeSelection}
                      extraClassPlaceHolder={"extraClassPlaceHolderDD"}
                      extraClassSelectedArea={
                        "Dropdown general-dropdown call-type-dropdown"
                      }
                      extraClassToBeSelectedArea={
                        "dropdowndListArea dropdowndListAreaCampaignTesting"
                      }
                      outerSelected={selectedCountryCodewithString}
                    />
                  </div>
                )}
                {clientName.includes("International Demo") && !isLoading && (
                  <div className="callTypeClassDiv">
                    <DropDown
                      droplist={{
                        optionList: clientName.includes("ICICI")
                          ? ["Exotel"]
                          : ["Epicode", "Internal", "Twilio"],
                        placeHolderText: "Select Telephony",
                        imgSrcRight: downArrow,
                      }}
                      editedItem={selectedTelephony}
                      selectedItem={handleSelectedTelephonyChange}
                      extraClassPlaceHolder={"extraClassPlaceHolderDD"}
                      extraClassSelectedArea={
                        "Dropdown general-dropdown call-type-dropdown"
                      }
                      extraClassToBeSelectedArea={
                        "dropdowndListArea dropdowndListAreaCampaignTesting"
                      }
                      outerSelected={selectedTelephony}
                    />
                  </div>
                )}
                {clientName.includes("Saarthi Demo") ||
                clientName.includes("International Demo")
                  ? ""
                  : !isLoading && (
                      <div className="callTypeClassDiv">
                        <DropDown
                          droplist={{
                            optionList: ["Transactional", "Promotional"],
                            placeHolderText: "Select Lead Type",
                            imgSrcRight: downArrow,
                          }}
                          editedItem={selectedLeadType}
                          selectedItem={handleLeadTypeSelection}
                          extraClassPlaceHolder={"extraClassPlaceHolderDD"}
                          extraClassSelectedArea={
                            "Dropdown general-dropdown call-type-dropdown"
                          }
                          extraClassToBeSelectedArea={
                            "dropdowndListArea dropdowndListAreaCampaignTesting"
                          }
                          outerSelected={selectedLeadType}
                        />
                      </div>
                    )}
                {clientName.includes("Saarthi Demo") ||
                clientName.includes("International Demo")
                  ? ""
                  : !isLoading && (
                      <div className="callTypeClassDiv">
                        <DropDown
                          droplist={{
                            optionList: ["Client UAT", "Internal UAT", "Live"],
                            placeHolderText: "Select Call Type",
                            imgSrcRight: downArrow,
                          }}
                          editedItem={selectedCallType}
                          selectedItem={handleCallTypeSelection}
                          extraClassPlaceHolder={"extraClassPlaceHolderDD"}
                          extraClassSelectedArea={
                            "Dropdown general-dropdown call-type-dropdown"
                          }
                          extraClassToBeSelectedArea={
                            "dropdowndListArea dropdowndListAreaCampaignTesting"
                          }
                          outerSelected={selectedCallType}
                        />
                      </div>
                    )}
                {clientName.includes("Saarthi Demo") ||
                clientName.includes("International Demo")
                  ? ""
                  : !isLoading && (
                      <div className="callTypeClassDiv">
                        <DropDown
                          droplist={{
                            optionList: clientName.includes("ICICI")
                              ? ["Exotel"]
                              : ["Epicode", "Internal", "Twilio"],
                            placeHolderText: "Select Telephony",
                            imgSrcRight: downArrow,
                          }}
                          editedItem={selectedTelephony}
                          selectedItem={handleSelectedTelephonyChange}
                          extraClassPlaceHolder={"extraClassPlaceHolderDD"}
                          extraClassSelectedArea={
                            "Dropdown general-dropdown call-type-dropdown"
                          }
                          extraClassToBeSelectedArea={
                            "dropdowndListArea dropdowndListAreaCampaignTesting"
                          }
                          outerSelected={selectedTelephony}
                        />
                      </div>
                    )}
                       {/* <div className="callTypeClassDiv">
                    <DropDown
                      droplist={{
                        optionList: selectedStrategyDetails?.options,
                        placeHolderText: "Select Strategy",
                        imgSrcRight: downArrow,
                      }}
                      editedItem={selectedStrategyDetails?.selectedStrategy}
                      selectedItem={handleSelectedStartegyChange}
                      extraClassPlaceHolder={"extraClassPlaceHolderDD"}
                      extraClassSelectedArea={
                        "Dropdown general-dropdown call-type-dropdown"
                      }
                      extraClassToBeSelectedArea={
                        "dropdowndListArea dropdowndListAreaCampaignTesting"
                      }
                      outerSelected={selectedStrategyDetails?.selectedStrategy}
                    />
                  </div> */}
                {clientName.includes("Saarthi Demo") ||
                clientName.includes("International Demo")
                  ? ""
                  : !isLoading &&
                    (clientName.includes("Maia") ||
                      clientName?.includes("TVS Credit") ||
                      clientName?.includes("Bajaj Finance Nach") ||
                      clientName?.includes("SmartCoin") ||
                      clientName?.includes("Fullerton") ||
                      clientName?.includes("Sphot") ||
                      clientName?.includes("Saarthi Demo") ||
                      clientName?.includes("Smartcoin") ||
                      agentIdDropdown === true ||
                      salesOnboardingIdDropdown === true ||
                      useCaseId === USECASE.vehicleService.id )
                      && (
                      <div className="agent-selection">
                        <DropDown
                          droplist={{
                            optionList: agentNameArr ? agentNameArr : [],
                            placeHolderText: "Select Agent",
                            imgSrcRight: downArrow,
                          }}
                          editedItem={dDSelectedAgent}
                          selectedItem={handleAgentSelection}
                          extraClassPlaceHolder={"extraClassPlaceHolderDD"}
                          // extraClassSelectedArea={
                          //   "Dropdown general-dropdown agent-selection-dropdown"
                          // }
                          // extraClassToBeSelectedArea={
                          //   "dropdowndListArea dropdowndListAreaCampaignTesting"
                          // }
                          extraClassSelectedArea={
                            "Dropdown general-dropdown agent-selection-dropdown"
                          }
                          extraClassToBeSelectedArea={
                            "dropdowndListArea dropdowndListAreaCampaignTesting"
                          }
                          outerSelected={dDSelectedAgent}
                        />
                      </div>
                    )}
               
                <div
                  className={`add-btn ${
                    isLoading ? "disablePointerEventUniversaljp" : ""
                  }`}
                  onClick={handleAddBtnClick}
                >
                  <AddBtn />
                </div>
              </div>
              <div className="container__head" style={{ marginRight: "92px" }}>
                {!isLoading &&
                  accountType?.toLowerCase() == "internal" &&
                  [
                    ROLES.campaignAnalyst.name,
                    ROLES.campaignManager.name,
                  ].includes(userRole) && (
                    <div
                      className="dropDownDiv"
                      id="client_dropDownDiv_campaign_testing"
                    >
                      <DropDown
                        droplist={{
                          optionList: clientList ? clientList : "",
                          placeHolderText: currentSelectedUser?.[0]?.name
                            ? currentSelectedUser?.[0]?.name
                            : "Select Client",
                          imgSrcRight: downArrow,
                        }}
                        // isFilter={true}
                        searchVal={searchClientName}
                        searchUi={true}
                        handleSearchItem={(data: string) => {
                          getSearchItem(data);
                        }}
                        selectedItem={(item: any) => onChangeClientName(item)}
                        extraClassSelectedArea={"languageDropdown"}
                        extraClassToBeSelectedArea={"dropdowndListArea"}
                      />
                    </div>
                  )}
                {clientName.includes("Saarthi Demo") ||
                clientName.includes("International Demo")
                  ? ""
                  : !isLoading && (
                      <div className="callTypeClassDiv">
                        <DemoDropdownComp
                          data={newHealth}
                          extraStyle={"Dropdown general-dropdown cursorPointer"}
                        />
                      </div>
                    )}
                {/* {clientName.includes("Saarthi Demo") ? "" :
                  !isLoading && (
                 <div className="callTypeClassDiv">
                        <DropDown
                          droplist={{
                            optionList: ["+1(US)" , "+44(UK)" , "+91(India)"],
                            placeHolderText: "Select Country Code",
                            imgSrcRight: downArrow,
                          }}
                          editedItem={selectedCountryCodewithString}
                          selectedItem={handleCountryCodeSelection}
                          extraClassPlaceHolder={"extraClassPlaceHolderDD"}
                          extraClassSelectedArea={
                            "Dropdown general-dropdown call-type-dropdown"
                          }
                          extraClassToBeSelectedArea={
                            "dropdowndListArea dropdowndListAreaCampaignTesting"
                          }
                          outerSelected={selectedCountryCodewithString}
                        />
                      </div>
                       )} */}
              </div>

              <CampaignTestingBody
                isLoading={isLoading}
                customersData={data}
                schema={schemaDataFiltered?.schema}
                handleInputChange={handleInputChange}
                handleRemoveClick={handleRemoveClick}
                showExtraFieldsIndex={showExtraFields}
                handleShowMoreToggle={handleShowMoreToggle}
                handleDropdownClick={handleDropdownClick}
                handleCalenderClick={handleCalenderClick}
                handleCalenderBlur={handleCalenderBlur}
                dropdownCallMapping={schemaDataFiltered?.dropDownCallMapping}
                showFieldsByFlow={schemaDataFiltered?.showFieldsByFlow}
                showCategoriesByFlow={schemaDataFiltered?.showCategoriesByFlow}
                showFieldsByDisposition={
                  schemaDataFiltered?.showFieldsByDisposition
                }
                showCategoriesByDisposition={
                  schemaDataFiltered?.showCategoriesByDisposition
                }
                showLanguageByFlow={schemaDataFiltered.showLanguageByFlow}
              />
              <div className="container__foot">
                <div className="btnContainer">
                  <div
                    className={`exeBtn ${
                      disableBtn || isLoading
                        ? "disablePointerEventUniversaljp"
                        : ""
                    } ${isSaving ? "btn-color-modifier" : ""}`}
                    onClick={handleSaveClick}
                  >
                    <p>{isSaving ? "Saving..." : "Save"}</p>
                  </div>
                  {props.sidebarView ? (
                    ""
                  ) : (
                    <>
                      {channels?.includes("Call") &&
                        clientName?.includes("SBA") && (
                          <div
                            className={`exeBtn ${
                              disableBtn ||
                              isLoading ||
                              disableExecuteBtn ||
                              !showChannels?.Call
                                ? "disablePointerEventUniversaljp"
                                : ""
                            } ${isVoiceBlast ? "btn-color-modifier" : ""}`}
                            onClick={() => handleExecuteCallClick(true)}
                          >
                            <img
                              src={isVoiceBlast ? callWhite : callBlack}
                              width={"25px"}
                            ></img>
                            <p>
                              {" "}
                              {isVoiceBlast ? "Blasting..." : "Voice Blast"}
                            </p>
                          </div>
                        )}

                      {channels?.includes("Call") && (
                        <div
                          className={`exeBtn ${
                            disableBtn ||
                            isLoading ||
                            disableExecuteBtn ||
                            !showChannels?.Call
                              ? "disablePointerEventUniversaljp"
                              : ""
                          } ${isExecutingCall ? "btn-color-modifier" : ""}`}
                          onClick={() => handleExecuteCallClick()}
                        >
                          <img
                            src={isExecutingCall ? callWhite : callBlack}
                            width={"25px"}
                          ></img>
                          <p>
                            {" "}
                            {isExecutingCall ? "Executing..." : "Execute Call"}
                          </p>
                        </div>
                      )}
                      {channels?.includes("Whatsapp") && (
                        <div
                          className={`exeBtn exeBtnWhatApp ${
                            disableBtn ||
                            isLoading ||
                            disableExecuteBtn ||
                            !showChannels?.Whatsapp
                              ? "disablePointerEventUniversaljp"
                              : ""
                          } ${isExecutingWp ? "btn-color-modifier" : ""}`}
                          onClick={handleExecuteWpClick}
                        >
                          <img
                            src={isExecutingWp ? whatsAppWhite : whatsAppBlack}
                            width={"25px"}
                          ></img>
                          <p>
                            {" "}
                            {isExecutingWp
                              ? "Executing..."
                              : "Execute WhatsApp"}
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <ToastContainer
            position="top-center"
            // type="success"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick={false}
            draggable={false}
            rtl={true}
          /> */}
        </div>
      )}
    </>
  );
};

export default CampaignTestingInfo;
