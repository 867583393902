import { Reducer } from "redux";
import actionType from "../actionTypes";

export interface stateInterface {
  myPlanDetails: { data: object; isLoading: boolean; error: string };
}
const initialState: stateInterface = {
  myPlanDetails: { data: {}, isLoading: false, error: "" },
};

const myPlanReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionType.MY_PLAN_DATA_API_REQUEST: {
      return {
        ...state,
        myPlanDetails: {
          data: {},
          isLoading: true,
          error: "",
        },
      };
    }
    case actionType.MY_PLAN_DATA_API_SUCCESS: {
      return {
        ...state,
        myPlanDetails: {
          data: action.payload,
          isLoading: false,
          error: "",
        },
      };
    }
    case actionType.MY_PLAN_DATA_API_FAILURE: {
      return {
        ...state,
        myPlanDetails: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default myPlanReducer;
