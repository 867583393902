import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { INTEGRATION_API, config } from "../../../../services/ApiRoutes";
import actionTypes from "../customIntegration.actionTypes";
import integrationActionTypes from "../../allIntegrations/allIntegrations.actionTypes";
import _ from "lodash";
import { store } from "../../../rootStore";

export function* getSecretKeyDetailsAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_BODY,
        `${INTEGRATION_API.GET_CUSTOM_INTEGRATION_API}`,
        action.payload
      );
      const getResult = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_ALL_INTEGRATIONS}`,
        {
          accountId: action.payload.accountId,
        }
      );
      yield put({
        type: actionTypes.GET_SECRET_API_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: integrationActionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS,
        payload: getResult?.data.data,
      });
      const apiResponse =
        store.getState().integrationReducer?.allIntegrationsReducer
          ?.selectedIntegrationData;
      const filteredData: any = getResult?.data.data?.filter(
        (item: any) => item.integrationName === apiResponse?.integrationName
      );
      yield put({
        type: integrationActionTypes.SET_SELECTED_INTEGRATION_DATA,
        payload: {
          ...filteredData[0],
          msg: result.data.data.msg,
        },
      });
      yield put({
        type: actionTypes.CLEAR_CUSTOM_INTEGRATION_API_DATA,
      });
      toast.dismiss();
      toast.success(result.data.data.msg);
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.GET_SECRET_API_FAILURE,
      payload: error,
    });
    console.error(error);
    toast.dismiss();
    toast.error(error.response?.data?.error);
  }
}

export function* sendToDevEmailInfoDetailsAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_BODY,
        `${INTEGRATION_API.SEND_INFO_TO_DEV_EMAIL_API}`,
        {
          id: action.payload.id,
          toEmail: action.payload.toEmail,
          orgName: action.payload.orgName,
        }
      );
      const getResult = yield call(
        config.GET_WITH_PARAMS,
        `${INTEGRATION_API.GET_ALL_INTEGRATIONS}`,
        {
          accountId: action.payload.accountId,
        }
      );
      yield put({
        type: actionTypes.SEND_INFO_TO_DEV_API_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: integrationActionTypes.GET_ALL_INTEGRATIONS_DATA_SUCCESS,
        payload: getResult?.data.data,
      });
      const apiResponse =
        store.getState().integrationReducer?.allIntegrationsReducer
          ?.selectedIntegrationData;
      const filteredData: any = getResult?.data.data?.filter(
        (item: any) => item.integrationName === apiResponse?.integrationName
      );
      yield put({
        type: integrationActionTypes.SET_SELECTED_INTEGRATION_DATA,
        payload: {
          ...filteredData[0],
          emailMsg: result.data.data.msg,
        },
      });
      // toast.dismiss();
      // toast.success(result.data.data.msg);
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.SEND_INFO_TO_DEV_API_FAILURE,
      payload: error,
    });
    console.error(error.response?.data?.details?.[0]?.message);
    toast.dismiss();
    toast.error(error.response?.data?.details?.[0]?.message);
  }
}
