import React, { useState } from "react";
import styles from "./InvoiceData.module.scss";
import moment from "moment";
import PravidIcons from "../../../../../generic/pravidIcons/PravidIcons";
import { getDownloadInvoiceApiData } from "../../../../../../redux/profile/billing/invoiceDetails/actions";
import Icon from "../../../../../generic/icon/Icon";
import {
  downloadInvoiceIcon,
  viewInvoiceIcon,
} from "../../../../../../theme/assets/profileSectionSvg/paymentSvg/index";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../redux";
import Button from "../../../../../generic/button/Button";
import {verifyOrderPayment} from "../../../../../../redux/profile/billing/orderSummary/action/orderSummary.action";
import DisplayRazorPay from "../../../razorPayIntegration/DisplayRazorPay";
import { makeMaiaPayment } from "../../../../../../redux/profile/billing/orderSummary/action/orderSummary.action";

interface InvoiceProps {
  invoiceData: {
    id: string;
    invoiceNo: string;
    price: number;
    planName: string;
    planStartDate: string;
    planExpireDate: string;
    isMaiaRequestedToPay ?: boolean
  };
  viewFunc?: any;
  invoiceApicall : () => void
}
export const InvoiceData = ({ invoiceData, viewFunc , invoiceApicall }: InvoiceProps) => {
  const dispatch = useDispatch();
  const accessToken = useSelector(
    (store: any) =>
      store?.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const convertToRupees = (number: number) => {
    const rupees = number.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: number % 1 === 0 ? 0 : 2,
      maximumFractionDigits: number % 1 === 0 ? 0 : 2,
    });
    return rupees.replace("₹", "INR ");
  };

  const convertDate = (dateString: string) => {
    const date = moment(dateString);
    const formattedDate = date.format("D MMMM YYYY");
    return formattedDate;
  };

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": accessToken,
  };

  //payment verify handler
  const handlePaymentVerifyApi = (body: object) => {
    const data = {
      body: body,
      headers: headers,
      func: () => invoiceApicall(),
    };

    dispatch(verifyOrderPayment(data));
  };

  const handelPayMaiaPayment = (data : any) => {
     console.log("Pay Now", data)
     const body ={
      currency : data?.currency,
      orderId : data?.orderId,
      amount : Number(data?.price)*100
     }
     console.log("Pay Now b", body)
     DisplayRazorPay(body, handlePaymentVerifyApi)
  }

  return (
    <div className={styles.invoiceDataWrapper}>
      <div
        className={`${styles.invoiceHeaderColM}  ${styles.invoiceHeaderCol}`}
      >
        <div className={`${styles.rowTextStyling} ${invoiceData?.isMaiaRequestedToPay === true && styles.colorTextPayNow}`}>
          {invoiceData?.invoiceNo}
        </div>
      </div>
      <div
        className={`${styles.invoiceHeaderColL}  ${styles.invoiceHeaderCol}`}
      >
        <div className={`${styles.rowTextStyling} ${invoiceData?.isMaiaRequestedToPay === true && styles.colorTextPayNow}`}> {invoiceData?.planName}</div>
      </div>
      <div
        className={`${styles.invoiceHeaderColM}  ${styles.invoiceHeaderCol}`}
      >
        <div className={`${styles.rowTextStyling} ${invoiceData?.isMaiaRequestedToPay === true && styles.colorTextPayNow}`}>
          {convertToRupees(invoiceData?.price)}
          {/* {`INR ${invoiceData?.price}`} */}
        </div>
      </div>
      <div
        className={`${styles.invoiceHeaderColM}  ${styles.invoiceHeaderCol}`}
      >
        <div className={`${styles.rowTextStyling} ${invoiceData?.isMaiaRequestedToPay === true && styles.colorTextPayNow}`}>
          {convertDate(invoiceData?.planStartDate)}
        </div>
      </div>

      <div
        className={`${styles.invoiceHeaderColM}  ${styles.invoiceHeaderCol}`}
      >
        <div className={`${styles.rowTextStyling} ${invoiceData?.isMaiaRequestedToPay === true && styles.colorTextPayNow}`}>
          {convertDate(invoiceData?.planExpireDate)}
        </div>
      </div>

      <div
        className={`${styles.invoiceHeaderColM}  ${styles.invoiceHeaderCol}`}
      >
        <div className={`${styles.rowTextStyling} ${invoiceData?.isMaiaRequestedToPay === true && styles.colorTextPayNow}`}>
          <div
            className={`${styles.downloadStyling}`}
            id="auto_billing_download"
          >
            <Icon
              img_src={downloadInvoiceIcon}
              extraClass={styles.featureIconStyling}
              onClick={() =>
                dispatch(
                  getDownloadInvoiceApiData({
                    id: invoiceData?.id,
                    isDownload: true,
                  })
                )
              }
            />
            <Icon
              img_src={viewInvoiceIcon}
              extraClass={styles.featureIconStyling}
              // onClick={viewFunc}
              onClick={() => {
                viewFunc(invoiceData?.id);
              }}
            />
            {
              invoiceData?.isMaiaRequestedToPay === true ?
              <Button
              text="Pay Now"
              extraClass={`${styles.buttonPay}`}
              onClick ={() => handelPayMaiaPayment(invoiceData)}
              />
              : 
              <></>
            }
              
          </div>
        </div>
      </div>
    </div>
  );
};
