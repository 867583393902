import { disable } from "mixpanel-browser";
import "./Slide.scss";
import React, { useEffect } from "react";
import { useState } from "react";
import { textTransform } from "html2canvas/dist/types/css/property-descriptors/text-transform";
interface Props {
  text: Array<string | number>;
  onChange: (a: string | number) => void;
  defaultIndex?: number;
  disabled: boolean;
  textTransformerFun?: (a: any) => string;
}
export default function Slide({
  text,
  onChange,
  defaultIndex,
  disabled,
  textTransformerFun,
}: Props) {
  const [selected, setSelected] = useState<number>(0);
  useEffect(() => {
    if (!disabled) onChange(text[selected]);
  }, [selected]);
  useEffect(() => {
    if (defaultIndex !== undefined && defaultIndex >= 0 && !disabled) {
      setSelected(defaultIndex);
      onChange(text[defaultIndex]);
    }
  }, [disabled]);
  return (
    <div className="slide-wrapper-div">
      <button
        onClick={() => {
          if (!disabled) setSelected(0);
        }}
        className={`${selected === 0 ? "selected" : ""} ${
          disabled ? "disbaled" : ""
        }`}
      >
        {textTransformerFun ? textTransformerFun(text[0]) : text[0]}
      </button>
      <button
        onClick={() => {
          if (!disabled) setSelected(1);
        }}
        className={`${selected === 1 ? "selected" : ""} ${
          disabled ? "disbaled" : ""
        }`}
      >
        {textTransformerFun ? textTransformerFun(text[1]) : text[1]}
      </button>
    </div>
  );
}
