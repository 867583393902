import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { PRICING_PLAN_API, config } from "../../../../../services/ApiRoutes";
import actionTypes from "../pricingPlan.actionTypes";

export function* getPricingPlanDataAPIWorker(action: any): any {
  // toast.loading("Please wait ...");
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        `${PRICING_PLAN_API.GET_PRICING_PLAN_DETAILS}`,
        action.payload
      );
      yield put({
        type: actionTypes.GET_PRICING_DATA_API_SUCCESS,
        payload: result.data.data,
      });
    }
    // toast.dismiss();
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_PRICING_DATA_API_FAILURE,
      payload: error,
    });
    //toast.dismiss();
  }
}

export function* updatePricingPlanDataAPIWorker(action: any): any {
  toast.dismiss();
  toast.loading("Please wait ...");
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${PRICING_PLAN_API.UPDATE_PRICING_PLAN}`,
        {
          id: action?.payload?.accountId,
          name: action?.payload?.accountName,
          subscription: action?.payload?.subscriptionId,
        },
        {
          "x-access-token": action?.payload?.token,
          "Content-Type": "application/json",
          userId: action.payload?.userId,
        }
      );
      const getResult = yield call(
        config.GET_WITH_PARAMS,
        `${PRICING_PLAN_API.GET_BY_ACCOUNT_API}`,
        { accountName: action?.payload?.accountName }
      );
      yield put({
        type: actionTypes.UPDATE_PRICING_DATA_API_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: actionTypes.GET_PRICING_DATA_API_SUCCESS,
        payload: getResult.data.data,
      });
    }
    toast.dismiss();
    toast.success("Pricing Plan updated successfully");
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.UPDATE_PRICING_DATA_API_FAILURE,
      payload: error,
    });
    toast.dismiss();
  }
}
export function* customPlanRequestAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${PRICING_PLAN_API.CUSTOM_PLAN_REQUEST}`,
        action?.payload?.body,
        action?.payload?.headers
      );
  
      yield put({
        type: actionTypes.CUSTOM_PLAN_API_SUCCESS,
        payload: result.data.data,
      });
    }
    toast.success("Custom Plan Request sent successfully");
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.CUSTOM_PLAN_API_FAILURE,
      payload: error,
    });

  }
}

export function* getCoupanPlanAPIWorker(action: any): any {
  // toast.loading("Please wait ...");
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${PRICING_PLAN_API.GET_COUPAN_PLAN}`,
        action.payload
      );
      yield put({
        type: actionTypes.COUPAN_PLAN_API_SUCCESS,
        payload: result.data.data,
      });
    }
    // toast.dismiss();
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.COUPAN_PLAN_API_FAILURE,
      payload: error,
    });
    //toast.dismiss();
  }
}