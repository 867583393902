const reportTableRowData = {
  _id: "645a2d4f216d625f1069be3b",
  status: "not seen",
  conversationId: "645a2d0e2d2d1c5fc6fa5b07",
  information: {},
  createdAt: "2023-05-09T11:23:59.371Z",
  emiDate: "",
  date: "09/05/23",
  time: "4:52 PM",
  ptpDate: "-",
  talk_time_in_sec: 5,
  ring_time_in_sec: 6,
};

const transcriptSession = {
  phoneNo: "XXXXXX0145",
  index: 1,
  dropDown: [],
};

export { reportTableRowData, transcriptSession };
