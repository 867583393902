import React, { useEffect, useState } from "react";
import styles from "./ExisitingSMSScreen.module.scss";
import InputContainer from "../../../../generic/inputContainer/InputContainer";
import Button from "../../../../generic/button/Button";
import { setconnectExistingAccount } from "../../../../../redux/integration_V2.0/sms/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { verify } from "../../../../../theme/gif/index";
import _ from "lodash";
import { RootState } from "../../../../../redux";
interface ExistingSMSScreenProps {
  onClickClose?: any;
}

export const ExisitingSMSScreen = ({
  onClickClose,
}: ExistingSMSScreenProps) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [refreshBtn, setRefreshBtn] = useState(false);

  const [exisitingSMSData, setExisitingSMSData] = useState({
    principleEntityId: "",
    senderId: "",
  });
  const dispatch = useDispatch();
  /** ***** Redux Data ******  */
  const connectExistingAllDetail = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.smsReducer?.connectExistingAccount
  );

  const accountId = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const selectedIntegrationSMS = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.selectedIntegrationData
  );

  useEffect(() => {
    if (
      selectedIntegrationSMS?.integrationDetails &&
      Object.keys(selectedIntegrationSMS?.integrationDetails).length === 2
    ) {
      const input = {
        senderId: exisitingSMSData?.senderId,
        principleEntityId: exisitingSMSData?.principleEntityId,
      };
      const res: any = {
        senderId: selectedIntegrationSMS?.integrationDetails?.senderId,
        principleEntityId:
          selectedIntegrationSMS?.integrationDetails?.principleEntityId,
      };
      if (_.isEqual(input, res)) {
        setIsInvalid(true);
      } else {
        if (
          exisitingSMSData?.senderId?.length === 0 ||
          exisitingSMSData?.principleEntityId?.length === 0
        ) {
          setIsInvalid(true);
        } else if (
          (exisitingSMSData?.senderId?.length > 0 &&
            exisitingSMSData?.senderId?.length < 6) ||
          (exisitingSMSData?.principleEntityId?.length > 0 &&
            exisitingSMSData?.principleEntityId?.length < 19)
        ) {
          setIsInvalid(true);
        } else {
          setIsInvalid(false);
        }
      }
    } else {
      if (
        exisitingSMSData?.senderId?.length === 0 ||
        exisitingSMSData?.principleEntityId?.length === 0
      ) {
        setIsInvalid(true);
      } else if (
        (exisitingSMSData?.senderId?.length > 0 &&
          exisitingSMSData?.senderId?.length < 6) ||
        (exisitingSMSData?.principleEntityId?.length > 0 &&
          exisitingSMSData?.principleEntityId?.length < 19)
      ) {
        setIsInvalid(true);
      } else {
        setIsInvalid(false);
      }
    }
  }, [exisitingSMSData, selectedIntegrationSMS]);

  //for prfilled data
  useEffect(() => {
    if (selectedIntegrationSMS?.integrationDetails) {
      setExisitingSMSData({
        principleEntityId:
          selectedIntegrationSMS?.integrationDetails?.principleEntityId || "",
        senderId: selectedIntegrationSMS?.integrationDetails?.senderId || "",
      });
    }
  }, [selectedIntegrationSMS]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "principleEntityId") {
      const value = e.target.value;
      setExisitingSMSData({
        ...exisitingSMSData,
        [e.target.name]: value.replace(/[^0-9]/g, ""),
      });
      setRefreshBtn(true);
    }
    if (e.target.name === "senderId") {
      const value = e.target.value;
      setExisitingSMSData({
        ...exisitingSMSData,
        [e.target.name]: value.replace(/[^A-Za-z0-9]/g, ""),
      });
      setRefreshBtn(true);
    }
  };
  const handleClickFinishSetup = () => {
    setRefreshBtn(false);
    dispatch(
      setconnectExistingAccount({
        ...exisitingSMSData,
        accountId: accountId,
        integrationName: selectedIntegrationSMS?.integrationName,
      })
    );
  };

  return (
    <div className={styles.exisitingSMSWrapper}>
      <div className={styles.contentDiv}>
        <div className={styles.subDiv}>
          <InputContainer
            label="Principle Entity ID"
            inputType="text"
            maxLength={19}
            inputPlaceholder="Principle Entity ID"
            inputValue={exisitingSMSData?.principleEntityId}
            inputName="principleEntityId"
            inputOnChange={handleInputChange}
            showLabel={true}
            showNewToolTip
            showNewToolTipPosition={"top-end"}
            showNewToolTipMsg={
              "This is the unique 19 digit account ID provided by your DLT operator (JIO, Airtel, Vodafone Idea etc)"
            }
            toolTipSize={true}
            extraInputClass={`${styles.inputDivStyling}`}
            extraLabelClass={styles.inputLabelStyling}
            extraLabelDivClass={styles.labelDivStyling}
            extraUserInputClass={`${styles.inputMainDivStyling}`}
            errorMsg={
              exisitingSMSData?.principleEntityId?.length > 0 &&
              exisitingSMSData?.principleEntityId?.length < 19
                ? "Please Enter a Valid Principle Entity ID "
                : ""
            }
          />
        </div>
        <div className={styles.subDiv}>
          <InputContainer
            label="Sender ID"
            inputType="text"
            inputPlaceholder="Sender ID"
            maxLength={6}
            inputValue={exisitingSMSData?.senderId}
            inputName="senderId"
            inputOnChange={handleInputChange}
            showLabel={true}
            showNewToolTip
            showNewToolTipPosition={"top-end"}
            showNewToolTipMsg={"Your 6 character Sender ID"}
            toolTipSize={false}
            extraInputClass={`${styles.inputDivStyling}`}
            extraLabelClass={styles.inputLabelStyling}
            extraLabelDivClass={styles.labelDivStyling}
            extraUserInputClass={`${styles.inputMainDivStyling}`}
            errorMsg={
              exisitingSMSData?.senderId?.length > 0 &&
              exisitingSMSData?.senderId?.length < 6
                ? "Please Enter a Valid Sender ID "
                : ""
            }
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {!refreshBtn &&
        connectExistingAllDetail &&
        Object.keys(connectExistingAllDetail.data)?.length > 0 ? (
          <Button
            text="Success"
            extraClass={`${styles.buttonStyling} ${styles.activeBtnStyling}`}
            image_src_left={verify}
            imgClass={`${styles.sentIconStyling}`}
            onClick={onClickClose}
          />
        ) : (
          <Button
            text="Finish Setup"
            extraClass={`${styles.buttonStyling} ${
              isInvalid || connectExistingAllDetail.isLoading
                ? styles.inActiveBtnStyling
                : styles.activeBtnStyling
            }`}
            onClick={handleClickFinishSetup}
          />
        )}

        {connectExistingAllDetail.error.message ? (
          <div className={styles.errorMsgStyling}>
            {connectExistingAllDetail.error.message}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
