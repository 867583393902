import actionTypes from "../actionTypes";

export const setInputField = (key: string, value: string) => {
  return {
    type: actionTypes.SET_FIELD_DATA_SIGNUP,
    payload: { key, value },
  };
};

export const toggleTermsAndPrivacy = (value: boolean) => {
  return {
    type: actionTypes.TOGGLE_TERMS_AND_PRIVACY_SIGNUP,
    payload: value,
  };
};

export const signUpAPIRequest = (data: Record<string, any>) => {
  return {
    type: actionTypes.SIGN_UP_API_REQUEST,
    payload: data,
  };
};

export const setPasswordData = (key: string, value: string) => {
  return {
    type: actionTypes.SET_PASSWORD_DATA_SIGNUP,
    payload: { key, value },
  };
};

export const createPasswordRequest = (password: string, token: string) => {
  return {
    type: actionTypes.CREATE_PASSWORD_REQUEST_SIGNUP,
    payload: { password, token },
  };
};

export const validateCreatePasswordLink = () => {
  return { type: actionTypes.VERIFY_LINK_VALIDITY_REQUEST_SIGNUP };
};

export const agreeToTermsPopUp = (payload: any) => {
  return {
    type: actionTypes.AGREE_TO_TERMS_CLOSE,
    payload: payload,
  };
};

export const sendOTPSignUPRequest = (payload: any) => {
  return {
    type: actionTypes.SEND_OTP_SING_UP_REQUEST,
    payload: payload,
  };
};
export const verifyOTPSignUPRequest = (payload: any) => {
  return {
    type: actionTypes.VERIFY_OTP_SING_UP_REQUEST,
    payload: payload,
  };
};

export const resetExperienceNow = () => {
  return {
    type: actionTypes.RESET_EXPERIENCE_NOW,
  };
};
export const callExperienceNow = (payload: any) => {
  return {
    type: actionTypes.CALL_EXPERIENCE_NOW_REQUEST,
    payload: payload,
  };
};
export const whatsAppExperienceNow = (payload: any) => {
  return {
    type: actionTypes.WHATSAPP_EXPERIENCE_NOW_REQUEST,
    payload: payload,
  };
};
export const setFreeCreditTimer = (payload: any) => {
  return {
    type: actionTypes.SET_TIMER_FOR_FREE_CREADIT,
    payload: payload,
  };
};

export const setExperienceNowCount = (payload: any) => {
  return {
    type: actionTypes.SET_EXP_NOW_COUNT,
    payload: payload,
  };
};

export const setInitialExperienceNowCount = (payload: any) => {
  return {
    type: actionTypes.SET_INTITIAL_EXP_NOW_COUNT,
    payload: payload,
  };
};

export const setIsMaiaPopUp = (payload: any) => {
  return {
    type: actionTypes.IS_MAIA_POPUP_OPEN,
    payload: payload,
  };
};

export const setResetPasswordApi = (payload: any) => {
  return {
    type: actionTypes.RESET_PASSWORD_REQUEST,
    payload: payload,
  };
};
