import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import styles from "./EmailIntegrationPopUp.module.scss";
import {
  pravidLogo,
  emailIntegration,
} from "../../../../../theme/assets/svg/integration_V2.0/index";
import Icon from "../../../../generic/icon/Icon";
import Button from "../../../../generic/button/Button";
import { EmailTable } from "../emailTable/EmailTable";
import { verify, failed, loading } from "../../../../../theme/gif/index";
import {
  getDevEmailDNSData,
  verifyEmailDNSData,
} from "../../../../../redux/integration_V2.0/email/actions";
import Loader from "../../../../generic/loader/Loader";

export const EmailIntegrationPopUp = () => {
  const dispatch = useDispatch();

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const selectedIntegrationEmail = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.emailReducer?.getDevEmailDetails
  );

  const isLoading = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.emailReducer?.isLoadingDevEmailDetails
  );

  const verifyData = useSelector(
    (store: RootState) => store?.integrationReducer?.emailReducer?.verifyDNSData
  );

  const emailreducerData = useSelector(
    (store: RootState) => store?.integrationReducer?.emailReducer
  );

  const [error, setError] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [errorTableData, setErrorTableData] = useState([]);

  const queryPrams = new URLSearchParams(location.search);
  const token = queryPrams.get("token");

  const handleOnClickVerify = () => {
    dispatch(
      verifyEmailDNSData({
        id: selectedIntegrationEmail?._id
          ? selectedIntegrationEmail?._id
          : selectedIntegrationEmail?.id,
        accountId: accountId,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getDevEmailDNSData({
        token: token ? token : "",
      })
    );
  }, []);

  useEffect(() => {
    if (verifyData && verifyData?.msg === "Verification failed") {
      setError(true);
      const dnsArr: any =
        verifyData?.data?.validation_results &&
        Object.values(verifyData?.data?.validation_results);
      setErrorTableData(dnsArr);
    }
  }, [verifyData]);

  useEffect(() => {
    if (selectedIntegrationEmail?.integrationDetails !== undefined) {
      const dnsArr: any =
        selectedIntegrationEmail?.integrationDetails?.dns &&
        Object.values(selectedIntegrationEmail?.integrationDetails?.dns);
      setTableData(dnsArr);
    }
  }, [selectedIntegrationEmail]);

  return (
    <div className={styles.emailIntegrationWrapper}>
      <div className={styles.emailIntegrationLogo}>
        <Icon img_src={pravidLogo} extraClass={styles.logoStyling} />
        <div className={styles.emailHeaderDiv}>
          <Icon img_src={emailIntegration} extraClass={styles.iconStyling} />
          <div className={styles.headerTextStyling}>Email Integration</div>
        </div>
        <div className={styles.emailSubHeaderDiv}>
          A coworker requested your help installing the following DNS records.
          <br />
          These improve deliverability by showing inbox providers that you own
          the domain you&apos;re sending from.
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.contentDiv}>
        {isLoading === false ? (
          <>
            <div className={styles.emailTableDiv}>
              <EmailTable
                extraHeaderStyling={styles.extraHeaderStyling}
                isError={error}
                emailTableData={tableData}
                emailerrorTable={errorTableData}
              />
            </div>
            <div
              className={`${styles.contentButtonDiv} ${
                verifyData?.msg !== "Verification failed" && !error
                  ? styles.singleButtonContainer
                  : ""
              }`}
            >
              <div className={styles.subDiv}>
                {verifyData && verifyData?.msg?.length > 0 ? (
                  <Button
                    text={
                      emailreducerData?.isLoadingDNSData === true
                        ? "Verifying"
                        : error
                        ? "Try Again"
                        : "Verified"
                    }
                    extraClass={`${
                      emailreducerData?.isLoadingDNSData === true
                        ? loading
                        : error
                        ? styles.tryAgainBtn
                        : styles.verifyBtnStyling
                    } ${styles.buttonStyling}`}
                    onClick={handleOnClickVerify}
                    image_src_left={
                      emailreducerData?.isLoadingDNSData === true
                        ? loading
                        : error
                        ? failed
                        : verify
                    }
                    imgClass={`${
                      emailreducerData?.isLoadingDNSData === true
                        ? styles.loadingIconStyling
                        : error
                        ? styles.errorbtnIconStyling
                        : styles.verifybtnIconStyling
                    }`}
                  />
                ) : (
                  <Button
                    text={
                      emailreducerData?.isLoadingDNSData === true
                        ? "Verifying"
                        : "Verify"
                    }
                    extraClass={`${styles.verifyBtnStyling} ${styles.buttonStyling}`}
                    onClick={handleOnClickVerify}
                    image_src_left={
                      emailreducerData?.isLoadingDNSData === true ? loading : ""
                    }
                    imgClass={`${styles.loadingIconStyling}`}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          <div className={styles.dnsVerifyLoader}>
            <Loader />
          </div>
        )}
        <div className={styles.footerDiv}>
          <span>Not sure how to do this?&nbsp;</span>
          <a
            href={"https://help.pravid.io/docs"}
            target="_blank"
            rel="noreferrer"
            className={styles.linkStyling}
          >
            Consult the documentation.
          </a>{" "}
        </div>
      </div>
    </div>
  );
};
