import React from "react";

import styles from "./SlashComponent.module.scss";
export default function SlashComponent(props: {
  data: { key: string; message: string }[];
  onSelect: (text: string) => void;
}) {
  return (
    <>
      <div className={styles.wrapper}>
        <table>
          <tr>
            <th className={styles.c1}>Key Word</th>
            <th className={styles.c2}>Message</th>
          </tr>
          {props.data.map((e, index) => (
            <tr
              key={index}
              onClick={() => {
                props.onSelect(e.message);
              }}
            >
              <td className={styles.c1}>{e.key}</td>
              <td className={styles.c2}>{e.message}</td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
}
