export default {
  UPDATE_TRUECALLER_PROFILE_PIC_REQUEST:
    "UPDATE_TRUECALLER_PROFILE_PIC_REQUEST",
  UPDATE_TRUECALLER_PROFILE_PIC_SUCCESS:
    "UPDATE_TRUECALLER_PROFILE_PIC_SUCCESS",
  UPDATE_TRUECALLER_PROFILE_PIC_FAILURE:
    "UPDATE_TRUECALLER_PROFILE_PIC_FAILURE",
  DELETE_TRUECALLER_PROFILE_PIC_REQUEST:
    "DELETE_TRUECALLER_PROFILE_PIC_REQUEST",
  DELETE_TRUECALLER_PROFILE_PIC_SUCCESS:
    "DELETE_TRUECALLER_PROFILE_PIC_SUCCESS",
  DELETE_TRUECALLER_PROFILE_PIC_FAILURE:
    "DELETE_TRUECALLER_PROFILE_PIC_FAILURE",
  CLEAR_TRUECALLER_DATA: "CLEAR_TRUECALLER_DATA",
  TRUE_CALLER_DATA: "TRUE_CALLER_DATA",
  GET_ALL_CATEGORIES_REQUEST: "GET_ALL_CATEGORIES_REQUEST",
  GET_ALL_CATEGORIES_SUCCESS: "GET_ALL_CATEGORIES_SUCCESS",
  GET_ALL_CATEGORIES_FAILURE: "GET_ALL_CATEGORIES_FAILURE",
  // CREATE_TRUECALLER_DATA_REQUEST: "CREATE_TRUECALLER_DATA_REQUEST",
  // CREATE_TRUECALLER_DATA_SUCCESS: "CREATE_TRUECALLER_DATA_SUCCESS",
  // CREATE_TRUECALLER_DATA_FAILURE: "CREATE_TRUECALLER_DATA_FAILURE",
  // UPDATE_TRUECALLER_DATA_REQUEST: "UPDATE_TRUECALLER_DATA_REQUEST",
  // UPDATE_TRUECALLER_DATA_SUCCESS: "UPDATE_TRUECALLER_DATA_SUCCESS",
  // UPDATE_TRUECALLER_DATA_FAILURE: "UPDATE_TRUECALLER_DATA_FAILURE",
};
