// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cqcRs2NcqOYJK7RIhl7w{height:40px;color:#7a7979;align-items:center;display:flex}`, "",{"version":3,"sources":["webpack://./src/components/agentDesktop/moduleComponents/tableConstantsPerformance/Table.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,aAAA,CACA,kBAAA,CACA,YAAA","sourcesContent":[".eachRowElement {\n  height: 40px;\n  color: #7a7979;\n  align-items: center;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eachRowElement": `cqcRs2NcqOYJK7RIhl7w`
};
export default ___CSS_LOADER_EXPORT___;
