import React from "react";
import styles from "./NewDatePicker.module.scss";
import PopupCalenderfilter from "../../../moduleComponents/popupCalenderfilter/PopupCalenderfilter";
import NewCalendarFilterAgent from "../newCalendarFilterAgent/NewCalendarFilterAgent";
// import { blueCalender } from "../../../../theme/assets/svg/rightSideIcon";
import { firstLetterCapital } from "../../../../utils/stringmodifycase";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";
import { blueCalender } from "../../../../theme/assets/svg/rightSideIcon";
export default function NewDatePicker(props: {
  selectedIcon?: any;
  setSelectedIcon?: any;
  highlighted?: Date[];
}) {
  return (
    <>
      <div className={styles.titleofilter}>
        {/* <img src={blueCalender} alt="" /> */}
        <PravidIcons activeIcon={"blueCalender"} />
        <p className={styles.titletext}>
          {firstLetterCapital(props.selectedIcon)}
        </p>
      </div>
      <div className={styles.divide}></div>
      <div style={{ margin: "auto" }}>
        <NewCalendarFilterAgent setSelectedIcon={props.setSelectedIcon} />
      </div>
      <div className={styles.divide}></div>
    </>
  );
}
