import axios, { AxiosRequestConfig } from "axios";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { useNavigate } from "react-router-dom";
import { ACCESS_TOKEN_EXPIRY_TIME, screenType } from "../constants";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";

const AxiosUtil = () => {
  /*****************************  constants  *****************************/
  const requestMap = new Map();
  const jwt_Token = secureLocalStorage.getItem("jwt_token");

  /*****************************  redux hooks  *****************************/
  const userId = useSelector(
    (state: RootState) => state.loginReducer?.userLoginInfo?.userDetail?._id
  );

  /*****************************  other hooks  *****************************/
  const navigate = useNavigate();

  /*****************************  useEffects  *****************************/
  // axios interceptor for error.
  useEffect(() => {
    if (jwt_Token && userId) {
      axios.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          if (401 === error?.response?.status) {
            navigate(screenType.singOut.routeName);

            setTimeout(() => {
              const jwtTokenTime =
                window.sessionStorage.getItem("jwt_token_time")!;
              const diff = moment().diff(moment(jwtTokenTime));
              const timeSpan = ACCESS_TOKEN_EXPIRY_TIME;
              if (diff < timeSpan) {
                // toast.error("Access token expired! login again.", {
                //   toastId: "accessTokenExpired",
                // });
              }
            }, 500);
          } else return Promise.reject(error);
        }
      );
    }
  }, [userId]);

  // axios interceptor to cancel duplicate requests.
  // useEffect(() => {
  //   axios.interceptors.request.use(cancelDuplicateRequest);
  //   axios.interceptors.response.use((response) => {
  //     const key = `${response.config.method}:${
  //       response.config.url
  //     }:${JSON.stringify(response.config.params || {})}`;
  //     requestMap.delete(key);

  //     return response;
  //   });
  // }, []);

  /*****************************  helper function  *****************************/
  function cancelDuplicateRequest(config: AxiosRequestConfig<any>) {
    const key = `${config.method}:${config.url}:${JSON.stringify(
      config.params || {}
    )}`;
    if (requestMap.has(key)) {
      const source = requestMap.get(key);
      source.cancel(`Duplicate request cancelled: ${key}`);
    }
    const cancelTokenSource = axios.CancelToken.source();
    requestMap.set(key, cancelTokenSource);
    config["cancelToken"] = cancelTokenSource.token;

    return config;
  }

  /*****************************  JSX  *****************************/
  return <></>;
};

export default AxiosUtil;
