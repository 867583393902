import React, { useEffect, useRef, useState } from "react";
import styles from "./EditAllNudge.module.scss";
import { useSelect } from "@mui/base";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { Title } from "../../generic/channel/Channel";
import { setShowRightsideAll } from "../../../redux/strategy/eachNudgeBox/actions/actionCreators";
import { useDispatch } from "react-redux";
import AllNudgeOfFlow from "../allNudgeOfNudge/AllNudgeOfFlow";
import {
  setPropForEachNudge,
  setSelectedNudgeData,
  setClearSelectedNudgeData,
} from "../../../redux/strategy/eachNudgeBox/actions/actionCreators";

export default function EditAllNudge() {
  const dispatch = useDispatch();
  const allNudge = useSelector(
    (state: RootState) =>
      state.strategyModuleReducer?.nudgeReducer.allNudgeDetail
  );
  console.log("allNudge", allNudge);
  const showOptions = useSelector(
    (state: RootState) =>
      state.strategyModuleReducer?.individualNudgeReducer.showRight
  );
  const refD = useRef<any>();
  //   useEffect(() => {
  //     let sidebarToggleListener: (e: MouseEvent) => void;
  //     if (showOptions) {
  //       sidebarToggleListener = (event: MouseEvent) => {
  //         if (refD.current && !refD.current.contains(event.target)) {
  //           dispatch(setShowRightsideAll(false));
  //         }
  //       };

  //       window.addEventListener("click", sidebarToggleListener);

  //       return () => {
  //         window.removeEventListener("click", sidebarToggleListener);
  //       };
  //     }
  //   }, [refD, showOptions]);

  const [selectedOne, setSelectedOne] = useState("");
  const selectedNudgeData = useSelector(
    (store: RootState) =>
      store.strategyModuleReducer?.individualNudgeReducer?.selectedNudgeData
  );

  console.log(selectedOne, selectedNudgeData?.flow, "flowwww");

  return (
    <div className={styles.wrapper} ref={refD}>
      <div className={styles.title_top}>
        <p>Nudge Configuration</p>
        <div></div>
      </div>
      <div className={styles.title_div}>
        {allNudge.map((each: any, i: number) => {
          return (
            <Title
              key={i}
              onClick={setSelectedOne}
              onClickExtraFunc={() => {
                dispatch(setClearSelectedNudgeData());
                dispatch(
                  setSelectedNudgeData({
                    ...each,
                  })
                );
              }}
              open={
                selectedOne == each.flow ||
                selectedNudgeData?.flow === each.flow
              }
              warningIcon={false}
              title={each.flow}
              actualKey={each.flow}
              selectedKey={selectedOne}
            >
              <AllNudgeOfFlow data={each} />
            </Title>
          );
        })}
      </div>
    </div>
  );
}
