import { Reducer } from "redux";
import { orderSummaryActionTypes } from "../orderSummary.actionTypes";

export interface stateInterface {
  selectedAddons: any;
  duration: string;
  generateOrderDetails :{data : object , isLoading : boolean , error : object},
  verifyPaymentDetails :{data : object , isLoading : boolean , error : object}
  discountCoupon: { data: any; error: string, isLoading: boolean };
  validCouponCode:string,
  maiaPaymentDetails :{data : object , isLoading : boolean , error : object},
  getmaiaPaymentStatus :{data : any , isLoading : boolean , error : object},
}

const initialState: stateInterface = {
  selectedAddons: [],
  duration: "",
  generateOrderDetails : {data : {} , isLoading : false , error : {}},
  verifyPaymentDetails : {data : {} , isLoading : false , error : {}},
  discountCoupon: { data: undefined, error: "", isLoading: false },
  validCouponCode: '',
  maiaPaymentDetails : {data : {} , isLoading : false , error : {}},
  getmaiaPaymentStatus:  {data : [] , isLoading : false , error : {}},
};

const orderSummaryReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case orderSummaryActionTypes.GET_ALL_ADD_ONS: {
      return {
        ...state,
        selectedAddons: action.payload,
      };
    }
    case orderSummaryActionTypes.GET_SELECTED_DURATION: {
      return {
        ...state,
        duration: action.payload,
      };
    }
    case orderSummaryActionTypes.GENERATE_ORDERID_REQUEST: {
      return {
        ...state,
        generateOrderDetails: {data : {}, isLoading : true , error : {}},
      };
    }
    case orderSummaryActionTypes.GENERATE_ORDERID_SUCCESS: {
      return {
        ...state,
        generateOrderDetails: {data : action.payload, isLoading : false , error : {}},
      };
    }
    case orderSummaryActionTypes.GENERATE_ORDERID_FAILURE: {
      return {
        ...state,
        generateOrderDetails: {data : {}, isLoading : false , error : action.payload},
      };
    }
    case orderSummaryActionTypes.VERIFY_ORDERID_REQUEST: {
      return {
        ...state,
        verifyPaymentDetails: {data : {}, isLoading : true , error : {}},
      };
    }
    case orderSummaryActionTypes.VERIFY_ORDERID_SUCCESS: {
      return {
        ...state,
        verifyPaymentDetails: {data : action.payload, isLoading : false , error : {}},
      };
    }
    case orderSummaryActionTypes.VERIFY_ORDERID_FAILURE: {
      return {
        ...state,
        verifyPaymentDetails: {data : {}, isLoading : false , error : action.payload},
      }
    }
    case orderSummaryActionTypes.APPLY_COUPON_CODE_REQUEST: {
      return {
        ...state,
        discountCoupon: {
          data: {},
          error: "",
          isLoading: true
        },
      };
    }
    case orderSummaryActionTypes.APPLY_COUPON_CODE_SUCCESS: {
      return {
        ...state,
        discountCoupon: {
          data: action.payload,
          error: "",
          isLoading: false
        },
      };
    }
    case orderSummaryActionTypes.APPLY_COUPON_CODE_FAILURE: {
      return {
        ...state,
        discountCoupon: {
          data: {},
          error: action.payload,
          isLoading: false
        },
      };
    }
    case orderSummaryActionTypes.VALID_COUPON_CODE: {
      return {
        ...state,
        validCouponCode: action.payload,
      };
    }
    case orderSummaryActionTypes.MAIA_PAYMENT_REQUEST: {
      return {
        ...state,
        maiaPaymentDetails: {data : {}, isLoading : true , error : {}},
      };
    }
    case orderSummaryActionTypes.MAIA_PAYMENT_SUCCESS: {
      return {
        ...state,
       maiaPaymentDetails: {data : action.payload, isLoading : false , error : {}},
      };
    }
    case orderSummaryActionTypes.MAIA_PAYMENT_FAILURE: {
      return {
        ...state,
       maiaPaymentDetails: {data : {}, isLoading : false , error : action.payload},
      };
    }
    case orderSummaryActionTypes.GET_MAIA_PAYMENT_STATUS_REQUEST: {
      return {
        ...state,
        getmaiaPaymentStatus: {data : {}, isLoading : true , error : {}},
      };
    }
    case orderSummaryActionTypes.GET_MAIA_PAYMENT_STATUS_SUCCESS: {
      return {
        ...state,
        getmaiaPaymentStatus: {data : action.payload, isLoading : false , error : {}},
      };
    }
    case orderSummaryActionTypes.GET_MAIA_PAYMENT_STATUS_FAILURE: {
      return {
        ...state,
        getmaiaPaymentStatus: {data : {}, isLoading : false , error : action.payload},
      };
    }
    default: {
      return state;
    }
  }
};

export default orderSummaryReducer;
