import React from "react";
import CheckBox from "../../../../components/generic/checkbox/Checkbox";
import DataRow from "./DataRow";
//import styles from "./ComponentName.module.scss";

interface props {
  customerData: any;
  schema: any;
  customerIndex: number;
  handleInputChange: (value: any, key: any, index: any) => void;
  handleRemoveClick: (index: any) => void;
  showExtraFieldsIndex: number | null;
  handleShowMoreToggle: (index: any, e: any) => void;
  handleDropdownClick: (e: any) => void;
  handleCalenderClick: (e: any) => void;
  handleCalenderBlur: (e: any) => void;
  dropdownCallMapping: Record<string, any>;
  showFieldsByFlow: Record<string, string[]>;
  showCategoriesByFlow: Record<string, string[]>;
  showFieldsByDisposition: Record<string, string[]>;
  showCategoriesByDisposition: Record<string, string[]>;
  showLanguageByFlow: Record<string, any>;
}

export default function CustomerCard(props: props) {
  const data = props.customerData;
  let defaultShownRow = props.schema?.filter(
    (item: Record<string, any>) => item.defaultVisible && item?.isVisible
  );
  defaultShownRow = [...defaultShownRow].sort(
    (a, b) => a?.position - b?.position
  );
  let defaultHiddenRow = props.schema?.filter(
    (item: Record<string, any>) => !item.defaultVisible && item?.isVisible
  );
  defaultHiddenRow = [...defaultHiddenRow]?.sort(
    (a, b) => a?.position - b?.position
  );
  const categoriesToShow =
    props.showCategoriesByFlow[props.customerData?.["primaryInfo.flow"]];
  const categoriesToShowByDisposition =
    props.showCategoriesByDisposition[
      props.customerData?.["primaryInfo.callDisposition"]
    ];

  return (
    <div className="body__row">
      <div className="checkbox-col">
        <div className="form-field__checkbox">
          {/* checkBox */}
          <CheckBox
            checked={data.isActive}
            extraSpan="extra-class-checkbox-span"
            onChange={(isChecked: any) =>
              props.handleInputChange(
                isChecked,
                "isActive",
                props.customerIndex
              )
            }
          />
        </div>
      </div>

      <div className="fields-col">
        {defaultShownRow.map(
          (rowSchema: Record<string, any>, index: number) => {
            return (!categoriesToShow && !categoriesToShowByDisposition) ||
              categoriesToShow?.includes(rowSchema.name) ||
              categoriesToShowByDisposition?.includes(rowSchema.name) ? (
              <DataRow
                rowSchema={rowSchema}
                customerData={props.customerData}
                key={index}
                handleShowMoreToggle={props.handleShowMoreToggle}
                customerIndex={props.customerIndex}
                showExtraFieldsIndex={props.showExtraFieldsIndex}
                handleInputChange={props.handleInputChange}
                showExpandOption={
                  rowSchema.position === 1 && defaultHiddenRow.length > 0
                }
                handleDropdownClick={props.handleDropdownClick}
                handleCalenderClick={props.handleCalenderClick}
                handleCalenderBlur={props.handleCalenderBlur}
                rowPosition={rowSchema.position}
                dropdownCallMapping={props.dropdownCallMapping}
                showFieldsByFlow={props.showFieldsByFlow}
                showFieldsByDisposition={props.showFieldsByDisposition}
                showLanguageByFlow={props.showLanguageByFlow}
              />
            ) : (
              <></>
            );
          }
        )}
        <div
          className={`optional__field-rows ${
            props.showExtraFieldsIndex !== props.customerIndex
              ? "hide-extra-fields"
              : ""
          }`}
        >
          {defaultHiddenRow.map(
            (rowSchema: Record<string, any>, index: number) => {
              return (!categoriesToShow && !categoriesToShowByDisposition) ||
                categoriesToShow?.includes(rowSchema.name) ||
                categoriesToShowByDisposition?.includes(rowSchema.name) ? (
                <DataRow
                  rowSchema={rowSchema}
                  customerData={props.customerData}
                  key={index}
                  rowPosition={rowSchema.position}
                  handleShowMoreToggle={props.handleShowMoreToggle}
                  handleInputChange={props.handleInputChange}
                  customerIndex={props.customerIndex}
                  showLanguageByFlow={props.showLanguageByFlow}
                  showExtraFieldsIndex={props.showExtraFieldsIndex}
                  handleDropdownClick={props.handleDropdownClick}
                  handleCalenderClick={props.handleCalenderClick}
                  handleCalenderBlur={props.handleCalenderBlur}
                  dropdownCallMapping={props.dropdownCallMapping}
                  showFieldsByFlow={props.showFieldsByFlow}
                  showFieldsByDisposition={props.showFieldsByDisposition}
                />
              ) : (
                <></>
              );
            }
          )}
        </div>
      </div>
      <div className="remove-col">
        <div className="remove__row">
          <div className="minusSign">
            <div onClick={() => props.handleRemoveClick(props.customerIndex)}>
              -
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
