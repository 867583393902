import React, { CSSProperties } from "react";
import styles from "./Button.module.scss";
import Icon from "../../../../moduleComponents/uiKit/iconComponent/Icon";

interface props {
  // eslint-disable-next-line no-unused-vars
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  text: string;
  dataTest?: string;
  button_type?: string;
  extraClass?: string;
  disabled?: boolean;
  roundedBtn?: boolean;
  dataToggle?: boolean;
  type?: "button" | "submit" | "reset";
  extraStyle?: CSSProperties;
  image_src?: string;
  image_src_left?: string;
  imgClass?: string;
  buttonTextClass?: string;
  shortLoader?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default React.forwardRef(function Button(props: props, ref: any) {
  return (
    <button
      data-testid={props?.dataTest}
      className={
        `${styles.basic} ${styles.button_}` +
        (props.button_type ? props.button_type : "") +
        " " +
        (props.extraClass ? props.extraClass : "") +
        " " +
        (props.disabled ? `${styles.disabled} disableMakeBlurUniversal` : "") +
        " " +
        (props.roundedBtn ? styles.btn_radius : "")
      }
      data-toggle={props.dataToggle ? props.dataToggle : ""}
      ref={ref}
      type={props.type ? props.type : "button"}
      style={props.extraStyle ? props.extraStyle : {}}
      disabled={props.disabled}
      onClick={(e) => {
        if (props.onClick && !props.disabled) {
          props.onClick(e);
        }
      }}
    >
      {props.image_src_left && (
        <Icon
          img_src={props.image_src_left}
          extraClass={props.imgClass ? props.imgClass : ""}
          extraStyle={{
            cursor: props.disabled ? "not-allowed" : "pointer",
            width: "auto !important",
          }}
          disabled={props.disabled}
        />
      )}
      <span
        className={
          styles["btn-text"] +
          (props.buttonTextClass ? props.buttonTextClass : "")
        }
      >
        {props.text ? props.text : ""}
      </span>
      {props.image_src && (
        <Icon
          img_src={props.image_src}
          extraClass={props.imgClass ? props.imgClass : ""}
          extraStyle={{
            cursor: props.disabled ? "not-allowed" : "pointer",
          }}
          disabled={props.disabled}
        />
      )}
      {props.shortLoader ? (
        <span className={`${styles.short_loader} ${styles.ml - 2}`}></span>
      ) : (
        ""
      )}
    </button>
  );
});
