import { Reducer } from "redux";
import actionTypes from "../actionTypes";
import { stateInterface, actionInterface, recentChat } from "../interface";

const initialState: stateInterface = {
  summary: {},
  summaryLoading: true,
  summaryError: {},
  transcript: [],
  transcriptLoading: true,
  transcriptError: {},
  recentChat: [],
  sessionIds: {},
  sessionLoading: false,
  sessionError: {},
  selectedSessionId: "",
  selectedConversationId: "",
  status: {
    data: {
      agentChatStatusDetails: { open: 0, closed: 0, "in-progress": 0 },
    },
  },
  statusError: "",
  statusLoading: false,
  summaryUpdatingLoading: false,
  summaryUpdatingError: {},
  summaryUpdateSuccess: false,
  messageStatusGroup: "Open",
  chatStatus: {
    data: [],
    isLoading: false,
    error: "",
  },
  cannedMessage: {
    data: [],
    isLoading: false,
    error: "",
  },
};

const chatLoggerReducer = (
  state: stateInterface = initialState,
  action: actionInterface
): stateInterface => {
  switch (action.type) {
    case actionTypes.GET_SUMMARY_REQUEST_AGENT_DESKTOP_CHAT: {
      return {
        ...state,
        summary: {},
        summaryError: {},
        // summaryLoading: true,
      };
    }
    case actionTypes.GET_SUMMARY_SUCCESS_AGENT_DESKTOP_CHAT: {
      return { ...state, summary: action.payload, summaryLoading: false };
    }
    case actionTypes.GET_SUMMARY_FAILURE_AGENT_DESKTOP_CHAT: {
      return { ...state, summaryError: {}, summaryLoading: false };
    }
    case actionTypes.GET_TRANSCRIPT_REQUEST_AGENT_DESKTOP_CHAT: {
      return {
        ...state,
        transcript: [],
        transcriptError: {},
        // transcriptLoading: true,
      };
    }
    case actionTypes.GET_TRANSCRIPT_SUCCESS_AGENT_DESKTOP_CHAT: {
      return { ...state, transcript: action.payload, transcriptLoading: false };
    }
    case actionTypes.GET_TRANSCRIPT_FAILURE_AGENT_DESKTOP_CHAT: {
      return { ...state, transcriptError: {}, transcriptLoading: false };
    }
    case actionTypes.GET_SESSION_ID_REQUEST_WITHOUT_REFRESH_AGENT_DESKTOP_CHAT: {
      return {
        ...state,
        // sessionIds: {},
        // sessionLoading: true,
        sessionError: {},
        // transcript: [],
      };
    }
    case actionTypes.GET_SESSION_ID_REQUEST_AGENT_DESKTOP_CHAT: {
      return {
        ...state,
        sessionIds: {},
        sessionLoading: true,
        sessionError: {},
        // transcript: [],
      };
    }
    case actionTypes.SET_SESSION_ID_LOADING_AGENT_DESKTOP_CHAT: {
      return {
        ...state,
        sessionLoading: true,
      };
    }
    case actionTypes.GET_SESSION_ID_SUCCESS_AGENT_DESKTOP_CHAT: {
      return { ...state, sessionIds: action.payload, sessionLoading: false };
    }
    case actionTypes.GET_SESSION_ID_FAILURE_AGENT_DESKTOP_CHAT: {
      return { ...state, sessionLoading: false, sessionError: action.payload };
    }
    case actionTypes.SET_SELECTED_SESSION_ID_AGENT_DESKTOP_CHAT: {
      return { ...state, selectedSessionId: action.payload };
    }
    case actionTypes.GET_STATUS_OF_CHAT_AGENT_DESKTOP_API_CALL: {
      return {
        ...state,
        // status: action.payload,
        statusLoading: true,
        statusError: "",
      };
    }
    case actionTypes.GET_STATUS_OF_CHAT_AGENT_DESKTOP_API_SUCCESS: {
      return { ...state, status: action.payload, statusLoading: false };
    }
    case actionTypes.GET_STATUS_OF_CHAT_AGENT_DESKTOP_API_FAILURE: {
      return { ...state, statusError: action.payload, statusLoading: false };
    }

    // update summary
    case actionTypes.UPDATE_SUMMARY_CHAT_REQUEST_AGENT_DESKTOP: {
      return {
        ...state,
        summaryUpdatingLoading: true,
        summaryUpdatingError: {},
      };
    }
    case actionTypes.UPDATE_SUMMARY_CHAT_SUCCESS_AGENT_DESKTOP: {
      return {
        ...state,
        summaryUpdatingLoading: false,
        summaryUpdateSuccess: true,
      };
    }
    case actionTypes.UPDATE_SUMMARY_CHAT_FAILURE_AGENT_DESKTOP: {
      return {
        ...state,
        summaryUpdatingLoading: false,
        summaryUpdatingError: action.payload,
      };
    }
    case actionTypes.SET_SUMMARY_CHAT_UPDATE_SUCCESS_AGENT_DESKTOP: {
      return { ...state, summaryUpdateSuccess: action.payload };
    }
    case actionTypes.APPEND_RECENT_MESSAGE_FROM_SOCKETS: {
      return {
        ...state,
        recentChat: updateUsingId(action.payload, state.recentChat),
      };
    }
    case actionTypes.APPEND_CHAT_TRANSCRIPT_AGENT_DESKTOP: {
      const newTranscript = {
        text: [action.payload.message],
        createdAt: action.payload.timeStamp,
        speaker: action.payload.speaker,
        tags: {
          interactionType: action.payload.interactionType,
          fileName: action.payload.fileName,
        },
      };
      return { ...state, transcript: [...state.transcript, newTranscript] };
    }
    case actionTypes.SET_MESSAGE_STATUS_GROUP_AGENT_DESKTOP: {
      return { ...state, messageStatusGroup: action.payload };
    }
    case actionTypes.GET_STATUS_FOR_WHATSAPP_CHAT_API_CALL: {
      return { ...state, chatStatus: { data: [], isLoading: true, error: "" } };
    }
    case actionTypes.GET_STATUS_FOR_WHATSAPP_CHAT_API_SUCCESS: {
      return {
        ...state,
        chatStatus: { data: action.payload, isLoading: false, error: "" },
      };
    }
    case actionTypes.GET_STATUS_FOR_WHATSAPP_CHAT_API_FAILURE: {
      return {
        ...state,
        chatStatus: { data: [], isLoading: false, error: action.payload },
      };
    }
    case actionTypes.GET_CANNED_MESSAGES_API_CALL: {
      return {
        ...state,
        cannedMessage: { data: [], isLoading: true, error: "" },
      };
    }
    case actionTypes.GET_CANNED_MESSAGES_API_SUCCESS: {
      return {
        ...state,
        cannedMessage: { data: action.payload, isLoading: false, error: "" },
      };
    }
    case actionTypes.GET_CANNED_MESSAGES_API_FAILURE: {
      return {
        ...state,
        cannedMessage: { data: [], isLoading: false, error: "" },
      };
    }
    case actionTypes.HANDLE_CHAT_PAGE_LEAVE_AD: {
      return { ...initialState, messageStatusGroup: state.messageStatusGroup };
    }
    default: {
      return state;
    }
  }
};
function updateUsingId(
  recentChatFromSockets: recentChat,
  recentChat: recentChat[]
): recentChat[] {
  const recentChat_ = [...recentChat];
  const changeElement = recentChat_.find(
    (e) => e.id === recentChatFromSockets.id
  );
  if (changeElement) {
    changeElement.id = recentChatFromSockets.id;
    changeElement.latestMessage = recentChatFromSockets.id;
  } else {
    recentChat_.push(recentChatFromSockets);
  }
  return recentChat_;
}
export default chatLoggerReducer;
