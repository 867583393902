import { all, fork } from "redux-saga/effects";
import * as signUpSaga from "./signUp/saga";
import * as productSaga from "./productPage/saga";
import * as productTourSaga from "./productTour/saga";
import * as teamsSaga from "./teamsPage/saga";
import * as inviteUserSaga from "./inviteUser/saga";

export default function* signUpFlowRootSaga() {
  yield all([...Object.values(signUpSaga)].map(fork));
  yield all([...Object.values(productSaga)].map(fork));
  yield all([...Object.values(productTourSaga)].map(fork));
  yield all([...Object.values(teamsSaga)].map(fork));
  yield all([...Object.values(inviteUserSaga)].map(fork));
}
