import actionTypes from "../strategy.actionTypes";

export const getStrategiesAPIData = (payload: object) => {
  return {
    type: actionTypes.GET_STRATEGIES_DATA_API_REQUEST,
    payload: payload,
  };
};

export const createStrategyAPIData = (payload: object) => {
  return {
    type: actionTypes.CREATE_STRATEGY_DATA_API_REQUEST,
    payload: payload,
  };
};

export function updateStrategyAPIData(payload: object) {
  return {
    type: actionTypes.UPDATE_STRATEGY_DATA_API_REQUEST,
    payload: payload,
  };
}

export function deleteStrategyAPIData(payload: object) {
  return {
    type: actionTypes.DELETE_STRATEGY_DATA_API_REQUEST,
    payload: payload,
  };
}

export function getUserPersonaAPIData(payload: object) {
  return {
    type: actionTypes.GET_USER_PERSONA_DATA_API_REQUEST,
    payload: payload,
  };
}

export function getWorkflowsAPIData(payload: object) {
  return {
    type: actionTypes.GET_WORK_FLOWS_DATA_API_REQUEST,
    payload: payload,
  };
}

export const addWorkflowAPIData = (payload: object) => {
  return {
    type: actionTypes.ADD_WORK_FLOW_DATA_API_REQUEST,
    payload: payload,
  };
};

export const setWorkflowAPIData = (payload: object) => {
  return {
    type: actionTypes.SET_WORK_FLOW_DATA_API_REQUEST,
    payload: payload,
  };
};

export const addUserPersonaAPIData = (payload: object) => {
  return {
    type: actionTypes.ADD_USER_PERSONA_DATA_API_REQUEST,
    payload: payload,
  };
};

export function selectUserPersona(id: string[], data: any) {
  return {
    type: actionTypes.SELECT_USER_PERSONA,
    payload: {
      id,
      data,
    },
  };
}

export function createdStrategy(payload: object) {
  return {
    type: actionTypes.STORE_CREATED_STRATEGY,
    payload: payload,
  };
}

// export function createdWorkflows(payload: object) {
//   return {
//     type: actionTypes.STORE_CREATED_WORKFLOWS,
//     payload: payload,
//   };
// }

// export function uploadFileStrategy(payload: object) {
//   return {
//     type: actionTypes.UPLOADFILE_API_REQUEST,
//     payload: payload,
//   };
// }

export function getStrategyOptionsAPIData() {
  return {
    type: actionTypes.GET_STRATEGY_OPTIONS_API_REQUEST,
  };
}

export function getUserPersonaOptionsAPIData(payload: object) {
  return {
    type: actionTypes.GET_USER_PERSONA_OPTIONS_API_REQUEST,
    payload: payload,
  };
}

// export function selectedUserPersonaFromCSV(payload: object) {
//   return {
//     type: actionTypes.SELECTED_USER_PERSONA_FROM_CSV,
//     payload: payload,
//   };
// }

export function clearAllStrategyData() {
  return {
    type: actionTypes.CLEAR_STRATEGY_DATA,
  };
}
