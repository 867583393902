// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WjjTWOR0HymWD8d_b8rZ{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center}`, "",{"version":3,"sources":["webpack://./src/components/agentDesktop/moduleComponents/cardComponent/card/Card.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,cAAA,CACA,0BAAA,CACA,kBAAA","sourcesContent":[".cardDiv {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardDiv": `WjjTWOR0HymWD8d_b8rZ`
};
export default ___CSS_LOADER_EXPORT___;
