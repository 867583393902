import React, { ReactElement, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import "./RootPortal2.scss";

interface props {
  children: ReactElement;
  opacity?: number;
  extraModelStyle?: React.CSSProperties;
  outSideClickFunction?: () => void;
  extraWrapperClass?: string;
}

const RootPortal2 = (props: props) => {
  const ref = useRef<any>(null);

  /* useEffects ------ */
  useEffect(() => {
    const model = document.querySelector(".root-portal2__wrapper");
    const listener = (e: Event) => {
      if (ref.current) {
        if (!ref.current?.contains(e.target)) {
          props.outSideClickFunction && props.outSideClickFunction();
        }
      }
    };
    model?.addEventListener("click", listener);
    return () => model?.removeEventListener("click", listener);
  }, []);

  return createPortal(
    <div
      className={`root-portal2__wrapper ${props.extraWrapperClass}`}
      style={props.opacity ? { opacity: props.opacity } : {}}
    >
      <div
        className={`root-portal2__inner-container`}
        style={props.extraModelStyle ? { ...props.extraModelStyle } : {}}
        ref={ref}
      >
        {props.children}
      </div>
    </div>,
    document.getElementById("root-portal2") as HTMLElement
  );
};

export default RootPortal2;
