import React, { useState, useEffect } from "react";
import styles from "./InvoiceTable.module.scss";
import { InvoiceData } from "./invoiceData/InvoiceData";
import { InvoiceHeader } from "./invoiceHeader/InvoiceHeader";
import { crossThinIcon } from "../../../../../theme/assets/genericSvg";
import Modal from "../../../../generic/modal/Modal";
import {
  getDownloadInvoiceApiData,
  clearDownloadInvoiceApiData,
  //   getInvoicesAPIData,
  //   getSortedInvoicesAPIData,
} from "../../../../../redux/profile/billing/invoiceDetails/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
interface InvoiceTableProps {
  invoicesInfo: any;
  userId?: string;
  accountId?: string;
  limit?: number;
  page?: number;
  token?: string;
  funApiCall  : () => void
}

export const InvoiceTable = ({
  invoicesInfo,
  userId,
  accountId,
  limit,
  page,
  token,
  funApiCall
}: InvoiceTableProps) => {
  const dispatch = useDispatch();

  const invoicePDF = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.invoices?.downloadInvoiceDetail?.data
  );

  const [createModal, setCreateModal] = useState(false);
  const [currentPdf, setCurrentPdf] = useState("");
  const [ascending, setAscending] = useState(false);
  // const [sortingKey, setSortingKey] = useState<any>();
  const [data, setData] = useState(invoicesInfo);
  const [sortKey, setSortKey] = useState("");

  const handleViewInvoice = (data: any) => {
    dispatch(
      getDownloadInvoiceApiData({
        id: data,
        isDownload: false,
      })
    );
  };

  useEffect(() => {
    if (invoicePDF?.isDownload === false && invoicePDF?.url.length > 0) {
      setCurrentPdf(invoicePDF?.url);
      setCreateModal(true);
    }
  }, [invoicePDF]);

  const handleSortToggle = (key: string) => {
    if (key === "planStartDate" || key === "planExpireDate") {
      setData((prevData: any) => {
        const newData = [...prevData];
        newData.sort((a, b) => {
          if (ascending) {
            return new Date(a[key]).valueOf() - new Date(b[key]).valueOf();
          } else {
            return new Date(b[key]).valueOf() - new Date(a[key]).valueOf();
          }
        });
        return newData;
      });
    } else {
      setData((prevData: any) => {
        const newData = [...prevData];
        newData.sort((a, b) => {
          if (ascending) {
            return a[key] - b[key];
          } else {
            return b[key] - a[key];
          }
        });
        return newData;
      });
    }

    setAscending((prevAscending) => !prevAscending);
    setSortKey(key);
  };

  //code for sorting using api
  // const handleSortToggle = (key: string) => {
  //   const newAscending = !ascending;
  //   const sortValue = newAscending ? 1 : -1;
  //   const sortOrder = {
  //     [key]: sortValue,
  //   };
  //   setSortingKey(sortOrder);
  //   setAscending((prevAscending) => !prevAscending);
  // };

  // useEffect(() => {
  //   if (sortingKey && Object.keys(sortingKey).length > 0) {
  //     dispatch(
  //       getSortedInvoicesAPIData({
  //         userId: userId,
  //         accountId: accountId,
  //         limit: limit,
  //         page: page,
  //         token: token,
  //         ...sortingKey,
  //       })
  //     );
  //   }
  // }, [sortingKey]);

  return (
    <div className={styles.invoiceTableWrapper}>
      <InvoiceHeader onSortToggle={handleSortToggle} />
      <div className={styles.invoiceRowWrapper}>
        {data?.map((data: any, index: number) => {
          return (
            <div
              key={index}
              className={styles.invoiceDataDiv}
              data-testid="invoices"
            >
              <InvoiceData invoiceData={data} viewFunc={handleViewInvoice}  invoiceApicall ={funApiCall} />
            </div>
          );
        })}
      </div>
      <Modal
        shown={createModal}
        close={() => {
          setCreateModal(false);
          setCurrentPdf("");
          dispatch(clearDownloadInvoiceApiData());
        }}
        extraClassStyleModalContent={styles.ModalPDFContent}
      >
        <div className={styles.closeIcon} data-file={currentPdf}>
          <img
            src={crossThinIcon}
            onClick={() => {
              setCreateModal(false);
              setCurrentPdf("");
              dispatch(clearDownloadInvoiceApiData());
            }}
            className={styles.IconStyling}
          />
        </div>
        <iframe
          src={currentPdf}
          data-file={currentPdf}
          className={styles.PdfViewStyling}
        ></iframe>
      </Modal>
    </div>
  );
};
