import React from "react";
import style from "./SMSsettingPage.module.scss";
import { useDispatch } from "react-redux";
import SMSschedule from "../../../components/moduleComponents/campaign/omniChannel/smsScheduler/SMSschedule";
SMSschedule;

function SMSSettingPage(props: any) {
  const dispatch = useDispatch();
  //store

  //calling api for getting templates

  return (
    <div className={style.smssettingPageWrapper}>
      <div className={style.schedulerSettingPageWp}>
        <div className={style.scheduleSettingwp}>
          <SMSschedule />
        </div>
        {/* <div className={style.previewWp}> 
          <Preview/>
        </div>   */}
      </div>
    </div>
  );
}

export default SMSSettingPage;
