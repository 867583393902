import actionTypes from "../pricingPlan.actionTypes";
import { IPlanData } from "../reducer/pricingPlan.reducer";

export const getPricingPlansAPIData = (payload: object) => {
  return {
    type: actionTypes.GET_PRICING_DATA_API_REQUEST,
    payload: payload,
  };
};

export const updatePricingPlanAPIData = (payload: object) => {
  return {
    type: actionTypes.UPDATE_PRICING_DATA_API_REQUEST,
    payload: payload,
  };
};

export const setSelectedPlanData = (payload: object | undefined) => {
  return {
    type: actionTypes.SET_SELECTED_PLAN,
    payload: payload,
  };
};

export const setAddedToCartRoute = (payload: string) => {
  return {
    type: actionTypes.SET_ADDED_TO_CARD_ROUTER,
    payload: payload,
  };
};

export const setPaidForPlan = (payload: object | undefined) => {
  return {
    type: actionTypes.SET_SELECTED_PAID_PLAN,
    payload: payload,
  };
};
export const sendCustomPlanRequest = (payload: object | undefined) => {
  return {
    type: actionTypes.CUSTOM_PLAN_API_REQUEST,
    payload: payload,
  };
}
export const getCoupanPlan = (payload: object | undefined) => {
  return {
    type: actionTypes.COUPAN_PLAN_API_REQUEST,
    payload: payload,
  };
}
