import React from "react";
import styles from "./OrderSummaryScreen.module.scss";
import OrderSummary from "../../../../components/moduleComponents/paymentModuleComponents/orderSummaryComponent/OrderSummary";
import PlanSummary from "../../../../components/moduleComponents/paymentModuleComponents/planSummaryComponent/PlanSummary";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux";
import FreePlanPage from "../freePlanPage/FreePlanPage";
import { useNavigate } from "react-router-dom";
import { setSelectedPlanData } from "../../../../redux/profile/billing/pricingPlan/actions";

// type Props = {}

const OrderSummaryScreen = () => {
  const selectedPurchasePlan: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.pricingPlan?.selectedPlan
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      {selectedPurchasePlan?.planName?.toLowerCase()?.includes("trial") ? (
        <FreePlanPage />
      ) : (
        <div className={styles.orderSummaryScreenWrapper}>
          <div className={styles.orderScreenHeader}>
            <div className={styles.orderScreenHeaderText}>Billing</div>
          </div>
          <div className={styles.orderSummaryScreen}>
            <div className={styles.plansummaryClass}>
              <PlanSummary
                onClickDelete={() => {
                  if (
                    selectedPurchasePlan &&
                    Object.keys(selectedPurchasePlan).length
                  ) {
                    navigate("/profile/billing/compareplans");
                  } else {
                    setTimeout(() => {
                      dispatch(setSelectedPlanData(undefined));
                    }, 0);
                    navigate("/profile/billing/plandetails");
                  }
                }}
                data={selectedPurchasePlan}
                addon={selectedPurchasePlan?.addons}
              />
            </div>
            <div className={styles.ordersummaryClass}>
              <OrderSummary
                data={selectedPurchasePlan}
                addon={selectedPurchasePlan?.addOns}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderSummaryScreen;
