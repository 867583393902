// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YSij1b5wr0_Fq2afOYE6{background:#fff;display:block;height:100%;min-width:80%;padding:0px;margin:2% 4%}.YSij1b5wr0_Fq2afOYE6 .dV3pBtNxZbz4K2cPEnfi{margin:2% 0}`, "",{"version":3,"sources":["webpack://./src/screens/agentDesktop/agentDashboardScreen/AgentDashboardScreen.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,aAAA,CACA,WAAA,CACA,aAAA,CACA,WAAA,CACA,YAAA,CAEA,4CAII,WAAA","sourcesContent":[".agentDashboardScreenDiv {\n    background: #FFFFFF;\n    display: block;\n    height: 100%;\n    min-width: 80%;\n    padding: 0px;\n    margin: 2% 4%;\n\n    .agentScreenTopDiv{\n        // display: flex;\n        // justify-content: space-between;\n        // align-items: center;\n        margin: 2% 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"agentDashboardScreenDiv": `YSij1b5wr0_Fq2afOYE6`,
	"agentScreenTopDiv": `dV3pBtNxZbz4K2cPEnfi`
};
export default ___CSS_LOADER_EXPORT___;
