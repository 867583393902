import React from "react";
import Modal from "../../../generic/modal/Modal";
import styles from "./removePhoneNumberModal.module.scss";
import Button from "../../../generic/button/Button";
import { useDispatch } from "react-redux";
import { removePhoneNumber } from "../../../../redux/exclusionsList/actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { crossIcon } from "../../../../theme/assets/svg";
import Icon from "../../uiKit/iconComponent/Icon";

interface RemovePhoneNumberModalProps {
  isRemoveNumberModalVisible: boolean;
  handleRemovePhoneNumberModal: () => void;
  phoneNumber?: string;
  selectedRowsFromCheckbox?: any;
  type: string;
}

const RemovePhoneNumberModal = ({
  isRemoveNumberModalVisible,
  handleRemovePhoneNumberModal,
  phoneNumber,
  selectedRowsFromCheckbox,
  type,
}: RemovePhoneNumberModalProps) => {
  // MISC
  const dispatch = useDispatch();

  // USESELECTOR SECTION
  const exclusionsListType: any = useSelector((store: RootState) => {
    return store?.exclusionsListReducer?.exclusionsListType;
  });

  const accountId = useSelector(
    (store: any) => store.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const accountDetails = useSelector(
    (store: RootState) => store?.schedulerReducer?.accountInfoDetails?.id
  );

  // HANDLERS
  const handleYesBtnClick = () => {
    let data: any = {};
    if (type === "single") {
      data = {
        data: [`${phoneNumber}`],
      };
    } else {
      const phoneNumbers = selectedRowsFromCheckbox.map(
        (row: any) => row.phoneNumber
      );
      data = {
        data: [...phoneNumbers],
      };
    }
    if (exclusionsListType === "Global") data["isGlobal"] = true;
    else data["accountId"] = accountDetails;
    dispatch(removePhoneNumber(data));
    handleRemovePhoneNumberModal();
  };

  const handleCrossBtnClick = () => {
    handleRemovePhoneNumberModal();
  };

  return (
    <Modal
      shown={isRemoveNumberModalVisible}
      close={handleRemovePhoneNumberModal}
    >
      <div className={styles.modal}>
        <Icon
          img_src={crossIcon}
          extraClass={styles.crossArrowIcon}
          onClick={handleCrossBtnClick}
        />
        <p className={styles.modalHeading}>Remove</p>
        <p className={styles.modalText}>
          Are you sure you want to remove
          {type === "single" ? (
            <span className={styles.phoneNumber}>
              &nbsp;{`"${phoneNumber}"`}&nbsp;
            </span>
          ) : (
            <span>
              &nbsp;{selectedRowsFromCheckbox?.length} phone number(s)&nbsp;
            </span>
          )}
          from {exclusionsListType === "Global" ? "Global" : ""} exclusions
          list?
        </p>
        <div className={styles.sectionForYesBtn}>
          <Button
            text="Yes"
            extraClass={styles.yesBtn}
            id="yes_btn"
            onClick={handleYesBtnClick}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RemovePhoneNumberModal;
