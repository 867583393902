import actionTypes from "../inviteUser.actionTypes";

export const sendInviteUser = (
  data: {
    email: string;
    role: string;
    isActive: boolean;
    teamId: string;
    accountId: string;
    userId: string;
  }[]
) => {
  return { type: actionTypes.SEND_INVITE_USER_REQUEST, payload: data };
};
