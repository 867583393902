import { takeLatest } from "redux-saga/effects";
import actionTypes from "../strategySelection.actionTypes";
import { addStrategyToCampaignAPIWorker } from "./strategySelection.saga";

export default function* campaignV2DetailsWatcher(): any {
  yield takeLatest(
    actionTypes.ADD_STRATEGY_TO_CAMPAIGN_API_REQUEST,
    addStrategyToCampaignAPIWorker
  );
}
