import React, { useEffect, useState } from "react";
import DropDown from "../../../components/generic/dropdownsaarthi2/DropdownSaarthi";
import moment from "moment";
import {
  campaignNameIcon,
  flowIcon,
  channelIcon,
  languageIcon,
} from "../../../theme/assets/svg/demo";
import {
  validateInputMeta,
  validateKeys,
} from "../../../utils/validators/validators";
import { downArrowIconDown } from "../../../theme/assets/svg/campaign";
import {
  getSalesOnboardingAgentConfig,
  setCamapignSelectedData,
  getDebtCollectionAgentConfig,
} from "../../../redux/campaign/actions/campaign.actions";
import { useSelector, useDispatch } from "react-redux";

import "./SalesAndOnboarding.scss";
import { USECASE } from "../../../constants";
import { toast } from "react-toastify";
interface propsInterface {
  EditData: any;
  disableButton: any;
  toSetHideupload: any;
}

export default function SalesAndOnboarding(props: propsInterface) {
  /* useSelectors ----- */
  const clientName = useSelector(
    (store: any) =>
      store.loginReducer.userLoginInfo?.userDetail?.accountDetails[0]?.name
  );
  const toshowAgentforDebt = useSelector(
    (store: any) => store?.schedulerReducer?.accountInfoDetails?.isAgentEnable
  );
  const useCaseId = useSelector(
    (store: any) => store?.schedulerReducer?.selectedUsecase?.id
  );
  const selectedClientName = useSelector(
    (store: any) => store.campaignReducer?.campaignClientName
  );
  const agentConfigs =
    toshowAgentforDebt === false || USECASE.debtCollection.id !== useCaseId
      ? useSelector(
          (store: any) => store.campaignReducer?.salesOnboarding?.agentConfigs
        )
      : useSelector(
          (store: any) =>
            store.campaignReducer?.debtCollectionAgent?.agentConfigs
        );
  const campaignData = useSelector(
    (store: any) => store.campaignReducer?.campaignSelectedData
  );

  const campaignEditUpdateType = useSelector(
    (store: any) => store.campaignReducer?.campaignEditUpdateType
  );
  const typeOfCollection = useSelector(
    (store: any) =>
      store?.schedulerReducer?.accountInfoDetails?.isSalesAndonboarding
  );

  const typeCheck = useSelector(
    (store: any) => store.loginReducer.userLoginInfo?.accountDetails[0]?.type
  );
  const accountAllInfo = useSelector(
    (store: any) => store?.schedulerReducer?.accountInfoDetails
  );

  const currentSelectedTeamId = useSelector(
    (store: any) => store?.loginReducer?.currentTeam?.id
  );

  const dispatch = useDispatch();

  /* useStates -------- */
  // const [campaignName, setCampaignName] = useState<string>("");
  const [hidebtn, setHidebtn] = useState(props.EditData ? true : true);
  const [errMessageCamp, setErrMessageCamp] = useState<string>("");
  const [changeClick, setChangeClick] = useState(false);
  const [agentIds, setAgentIds] = useState([]);
  const [selectedCallType, setSelectedCallType] = useState();
  const [selectedLeadType, setSelectedLeadType] = useState();
  const selectedClient = selectedClientName ? selectedClientName : clientName;
  // const [selectedAgentId, setSelectedAgentId] = useState();
  const [fieldData, setFieldData] = useState<Record<string, any>>({
    campaignName: "",
    agentId: "",
    flowType: "",
    language: [],
    channels: [],
    voice: "",
    accountName: selectedClient,
    ASR: "Saarthi-ASR_1",
    TTS: "Saarthi-TTS_1",
    telephonyName: "",
    teamId: currentSelectedTeamId,
  });

  /* constants -------- */
  const propsForAgentId = {
    optionList: agentIds ? agentIds : "",
    imgSrcRight: downArrowIconDown,
    imgSrcLeftHide: true,
    placeHolderText: fieldData?.agentId
      ? fieldData?.agentId
      : "Select Agent Id",
  };
  const propsForCallType = {
    optionList:
      typeCheck === "Internal"
        ? ["Client UAT", "Internal UAT", "Live"]
        : ["Client UAT", "Live"],
    imgSrcRight: downArrowIconDown,
    imgSrcLeftHide: true,
    placeHolderText: fieldData?.callType
      ? fieldData?.callType
      : "Select Call Type",
  };
  const propsForLeadType = {
    optionList: ["Transactional", "Promotional", "Default"],
    imgSrcRight: downArrowIconDown,
    imgSrcLeftHide: true,
    placeHolderText: fieldData?.leadType
      ? fieldData?.leadType
      : "Select Lead Type",
  };

  const propsForTelephony = {
    optionList: accountAllInfo?.name?.includes("ICICI Bank")
      ? ["Exotel"]
      : ["Epicode", "Internal", "Twilio"],
    imgSrcRight: downArrowIconDown,
    imgSrcLeftHide: true,
    placeHolderText: fieldData?.telephonyName
      ? fieldData?.telephonyName
      : "Select Telephony",
  };

  /* useEffect -------- */
  useEffect(() => {
    const selectedClient = selectedClientName ? selectedClientName : clientName;
    if (toshowAgentforDebt && USECASE.debtCollection.id === useCaseId) {
      dispatch(getDebtCollectionAgentConfig(selectedClient));
    } else {
      dispatch(getSalesOnboardingAgentConfig(selectedClient));
    }
    const objedit: any = {
      campaignName: campaignData?.campaignName,
      agentId: campaignData?.agentId || campaignData?.selectedAgentId,
      flowType: campaignData?.flowType || campaignData?.flow,
      language: campaignData?.language || campaignData?.languages,
      channels: campaignData?.channels,
      voice: campaignData?.voice || campaignData?.voiceProfile,
      callType: campaignData?.callType,
      ASR: "Saarthi-ASR_1",
      TTS: "Saarthi-TTS_1",
      telephonyName: campaignData?.telephonyName,

      // telephonyName: "Saarthi-Telephony_1",
    };
    if (campaignData?.leadType) {
      objedit["leadType"] = campaignData?.leadType;
    }
    setFieldData(objedit);
  }, []);

  useEffect(() => {
    let newAgentData: Record<string, any> = {};
    if (!props?.EditData?.id && !campaignData?.leadType) {
      if (typeOfCollection) {
        newAgentData = {
          ...fieldData,
          leadType: "Promotional",
        };
      } else {
        newAgentData = {
          ...fieldData,
          leadType: "Transactional",
        };
      }
    }
    if (!props?.EditData?.id && !campaignData?.telephonyName) {
      newAgentData = {
        ...newAgentData,
        telephonyName: accountAllInfo?.name?.includes("ICICI Bank")
          ? "Exotel"
          : "Epicode",
      };
    }
    if (Object.keys(newAgentData)?.length) {
      setFieldData(newAgentData);
    }
  }, [typeOfCollection]);

  //set for update
  useEffect(() => {
    props.toSetHideupload(changeClick);
  }, [changeClick]);

  useEffect(() => {
    if (agentConfigs?.length > 0 && typeof agentConfigs === "object") {
      const agentIdArr = agentConfigs?.map(
        (agent: Record<string, any>) => agent?.agentId
      );
      setAgentIds(agentIdArr);
    }
  }, [agentConfigs]);

  useEffect(() => {
    if (
      fieldData?.campaignName?.length >= 3 &&
      fieldData?.agentId &&
      fieldData?.callType
    ) {
      props.disableButton(false);
    } else {
      props.disableButton(true);
    }
    if (campaignEditUpdateType === "edit") {
      fieldData["id"] = props?.EditData?.id ? [props.EditData.id] : "";
      delete fieldData["accountName"];
    }
    setCamapignSelectedData(fieldData)(dispatch);
  }, [fieldData]);

  /* handlers --------- */
  const onChangeCampaignNewName = (data: any) => {
    if (!validateInputMeta(data)) return;
    if (data?.length > 0) {
      setFieldData({ ...fieldData, campaignName: data?.trim() });
      // setNewCampaignName(data?.trim());
      setHidebtn(true);
      setErrMessageCamp("");
      setChangeClick(true);
    } else {
      // setNewCampaignName(data);
      setFieldData({ ...fieldData, campaignName: data });
      setErrMessageCamp("");
    }
  };
  const onChangeAgentIds = (item: any) => {
    const selectedAgentData = agentConfigs.find(
      (agent: Record<string, any>) => agent.agentId === item
    );
    const newAgentData: Record<string, any> = {
      ...fieldData,
      agentId: selectedAgentData.agentId,
    };

    if (toshowAgentforDebt && USECASE.debtCollection.id === useCaseId) {
      const flows = selectedAgentData?.Call
        ? selectedAgentData?.Call
        : selectedAgentData?.Whatsapp;
      const language = flows?.flowTypes?.[0]?.languages;
      const flowArray = flows?.flowTypes?.map((e: any) => e?.displayValue);

      const channel: any = [];

      if (selectedAgentData?.Call) {
        channel.push("Call");
      }
      if (selectedAgentData?.WhatsApp || selectedAgentData?.Whatsapp) {
        channel.push("WhatsApp");
      }
      console.log("channel", channel);

      newAgentData["flowType"] = { maiaFlow: flowArray };
      newAgentData["channels"] = channel;
      newAgentData["language"] = language;
      newAgentData["voice"] = selectedAgentData?.type;
      newAgentData["agentId"] = item;
      newAgentData["campaignType"] = ["AI Driven"];
      newAgentData["accountName"] = selectedClient;
      newAgentData["endDate"] = moment(new Date()).format("YYYY-MM-DD");
      newAgentData["startDate"] = moment(new Date()).format("YYYY-MM-DD");
      newAgentData["isActive"] = true;
      newAgentData["useCaseId"] = useCaseId;
    } else {
      const activeParent = selectedAgentData.parentFlow.filter(
        (item: any) => item?.isParent
      );
      console.log("nayak", activeParent);
      let agentChannel: any = [];
      let agentLanguage: any = [];
      let agentFlow: any = [];
      let agentTypeVoice: any = [];
      activeParent?.map((item: any) => {
        if (item.channel) {
          if (item.channel?.includes("Whatsapp")) {
            const index = item.channel?.indexOf("Whatsapp");
            if (index > -1) {
              item.channel.splice(index, 1);
              item.channel.push("WhatsApp");
            }
          }
          agentChannel = [...new Set([...agentChannel, ...item.channel])];
        }
        if (item.language) {
          agentLanguage = [...new Set([...agentLanguage, ...item.language])];
        }
        if (item.flow) {
          agentFlow = [...new Set([...agentFlow, item.flow])];
        }
        if (item.type) {
          agentTypeVoice = [...new Set([...agentTypeVoice, item.type])];
        }
      });
      console.log("nayak pro", agentChannel, agentFlow, agentLanguage);
      newAgentData["flowType"] = { maiaFlow: agentFlow };
      newAgentData["channels"] = agentChannel;
      newAgentData["language"] = agentLanguage;
      newAgentData["voice"] = agentTypeVoice?.[0];
      newAgentData["agentId"] = item;
      newAgentData["campaignType"] = ["AI Driven"];
      newAgentData["accountName"] = selectedClient;
      newAgentData["endDate"] = moment(new Date()).format("YYYY-MM-DD");
      newAgentData["startDate"] = moment(new Date()).format("YYYY-MM-DD");
      newAgentData["isActive"] = true;
      newAgentData["useCaseId"] = useCaseId;
    }

    setFieldData(newAgentData);
    setChangeClick(true);
  };
  const onChangeCallType = (item: any) => {
    const newAgentData: Record<string, any> = {
      ...fieldData,
      callType: item,
    };
    setFieldData(newAgentData);
    setSelectedCallType((prev) => item);
    // setHidebtn(true);
    setChangeClick(true);
  };
  const onChangeLeadType = (item: any) => {
    const newAgentData: Record<string, any> = {
      ...fieldData,
      leadType: item,
    };
    setSelectedLeadType((prev) => item);
    setFieldData(newAgentData);
    setChangeClick(true);
  };
  const onChangeTelephony = (item: any) => {
    const newAgentData: Record<string, any> = {
      ...fieldData,
      telephonyName: item,
    };
    setFieldData(newAgentData);
    setChangeClick(true);
  };
  return (
    <div className="wrapper_sale_and_onboarding">
      <div className="sale_and_onboarding_container">
        <div className="ContainDiv">
          <div className="logoNameDiv">
            <img src={campaignNameIcon} width={"50px"} alt="img"></img>
            <h2 className="heading">Campaign Name:</h2>
          </div>
          <div className="heightCampName">
            <input
              type="text"
              className={`inputNameCamp ${
                !fieldData?.campaignName?.length ? "colorPlaceholder" : ""
              }`}
              placeholder="Campaign Name"
              value={fieldData?.campaignName}
              onKeyDown={validateKeys}
              onChange={(e) => onChangeCampaignNewName(e.target.value)}
              readOnly={
                // props.campaignCredentials?.isChildCampaign && "readonly"
                false
              }
            />
            {/* {errMessageCamp?.length &&<div >
                   <ErrorMessage
                          errorMessage={errMessageCamp} />
                   </div>
                } */}
          </div>
        </div>
        <div className="ContainDiv">
          <div className="logoNameDiv">
            <img src={flowIcon} width={"50px"} alt="img"></img>
            <h2 className="heading">Call Type:</h2>
          </div>
          <div className={`dropDownDiv`}>
            <DropDown
              droplist={propsForCallType}
              // isFilter={true}
              searchUi={false}
              handleSearchItem={fieldData?.callType}
              selectedItem={(item: any) => onChangeCallType(item)}
              extraClassSelectedArea={"languageDropdownNew"}
              extraClassToBeSelectedArea={"dropdowndListAreacallType"}
              extraClassPlaceHolder={"placeholderAgentMaia"}
              extraStyleLeftImg={"dropDownImageLeft"}
              maxDisplayLength={25}
              outerSelected={fieldData?.callType}
              ignoreLeftAlign={true}
              extraClassDropDownLi={"dropDownExtraLiStyling"}
              extraHighlight={"styleHighlightLi"}
            />
          </div>
        </div>
        <div className="ContainDiv">
          <div className="logoNameDiv">
            <img src={flowIcon} width={"50px"} alt="img"></img>
            <h2 className="heading">Agent ID:</h2>
          </div>
          <div
            className={`dropDownDiv ${
              props?.EditData?.id ? "disablePointerEvent" : ""
            }`}
          >
            <DropDown
              droplist={propsForAgentId}
              // isFilter={true}
              searchUi={false}
              handleSearchItem={fieldData?.agentId}
              selectedItem={(item: any) => onChangeAgentIds(item)}
              extraClassSelectedArea={"languageDropdownNew"}
              extraClassToBeSelectedArea={"dropdowndListArea"}
              extraClassPlaceHolder={"placeholderAgentMaia"}
              extraStyleLeftImg={"dropDownImageLeft"}
              maxDisplayLength={32}
              outerSelected={fieldData?.agentId}
              ignoreLeftAlign={true}
            />
          </div>
        </div>

        <div className="ContainDiv">
          <div className="logoNameDiv">
            <img src={channelIcon} width={"50px"} alt="img"></img>
            <h2 className="heading">Channels:</h2>
          </div>
          <div className="dropDownDivChannel">
            {fieldData?.channels?.join(", ")}
          </div>
        </div>

        <div className="ContainDiv">
          <div className="logoNameDiv">
            <img src={flowIcon} width={"50px"} alt="img"></img>
            <h2 className="heading">Flow:</h2>
          </div>
          <div className="dropDownDiv">
            {fieldData?.flowType?.maiaFlow?.join(", ")}
          </div>
        </div>

        <div className="ContainDiv">
          <div className="logoNameDiv">
            <img src={languageIcon} width={"50px"} alt="img"></img>
            <h2 className="heading">Language:</h2>
          </div>
          <div className="dropDownDiv">
            {Array.isArray(fieldData?.language)
              ? fieldData?.language.join(", ")
              : fieldData?.language}
          </div>
        </div>
        <div className="ContainDiv">
          <div className="logoNameDiv">
            <img src={flowIcon} width={"50px"} alt="img"></img>
            <h2 className="heading">Lead Type:</h2>
          </div>
          <div
            className={`dropDownDiv  ${
              props?.EditData?.id ? "disablePointerEvent" : ""
            }`}
          >
            <DropDown
              droplist={propsForLeadType}
              // isFilter={true}
              searchUi={false}
              handleSearchItem={fieldData?.leadType}
              selectedItem={(item: any) => onChangeLeadType(item)}
              extraClassPlaceHolderStyling={"dropDownPlaceHolderStyling"}
              extraClassPlaceHolder={"dropDownPlaceHolderStyling"}
              extraClassSelectedArea={"languageDropdownNew"}
              extraClassToBeSelectedArea={"dropdowndListAreacallType"}
              extraStyleLeftImg={"dropDownImageLeft"}
              maxDisplayLength={25}
              outerSelected={fieldData?.leadType}
              ignoreLeftAlign={true}
              extraClassDropDownLi={"dropDownExtraLiStyling"}
              extraHighlight={"styleHighlightLi"}
            />
          </div>
        </div>

        {!selectedClient?.includes("SmartCoin") && (
          <div className="ContainDiv">
            <div className="logoNameDiv">
              <img src={languageIcon} width={"50px"} alt="img"></img>
              <h2 className="heading">Voice Profile:</h2>
            </div>
            <div className="dropDownDiv">{fieldData?.voice}</div>
          </div>
        )}
        <div className="ContainDiv">
          <div className="logoNameDiv">
            <img src={flowIcon} width={"50px"} alt="img"></img>
            <h2 className="heading">Telephony:</h2>
          </div>
          <div className={`dropDownDiv `}>
            <DropDown
              droplist={propsForTelephony}
              // isFilter={true}
              searchUi={false}
              handleSearchItem={fieldData?.telephonyName}
              selectedItem={(item: any) => onChangeTelephony(item)}
              extraClassPlaceHolderStyling={"dropDownPlaceHolderStyling"}
              extraClassPlaceHolder={"dropDownPlaceHolderStyling"}
              extraClassSelectedArea={"languageDropdownNew"}
              extraClassToBeSelectedArea={"dropdowndListAreacallType"}
              extraStyleLeftImg={"dropDownImageLeft"}
              maxDisplayLength={25}
              outerSelected={fieldData?.telephonyName}
              ignoreLeftAlign={true}
              extraClassDropDownLi={"dropDownExtraLiStyling"}
              extraHighlight={"styleHighlightLi"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
