import React, { useState, useEffect } from "react";
import { Tooltip, Legend, FunnelChart, Funnel, LabelList, Label } from "recharts";

import { useDispatch, useSelector } from "react-redux";
import NoDatamodel from "../../generic/noDatamodel/NoDatamodel";
import { noPhoneDataIcon } from "../../../theme/assets/svg";

function FunnelChartComponent(props) {
  const [data, setData] = useState();
  const [nodataFound, setNoDataFound] = useState(true);

  const expandedChart = useSelector(
    (store) => store?.analyticsReducer?.expandedChart
  );

  let color = ["#0082D2", "#00AAE1", "#00D2AA", "#00D2AA", "#8CD26E"];

  let funnelWidth = parseInt(props.chartDetails?.chartStyle?.width) - 40;
  let funnelHeight = parseInt(props.chartDetails?.chartStyle?.height) - 80;
  let expandbarHeight =
    parseInt(props.chartDetails?.expand?.expandStyle?.height) - 100;
  let expandbarWidth = parseInt(props.chartDetails?.expand?.expandStyle?.width);
  let refenceKeyForData = props.chartDetails?.refenceKeyForData;

  let funnelBarDetails =
    props.chartDetails && Object.values(props.chartDetails.keys).length > 0
      ? Object.values(
          props.chartDetails.keys.sort((a, b) => {
            return a.position - b.position;
          })
        )
      : [];

  useEffect(() => {
    if (funnelBarDetails && props.data) {
      let finalData = funnelBarDetails?.map((each, index) => {
        return {
          name: each.name,
          value:
            props?.data[refenceKeyForData] &&
            props?.data[refenceKeyForData][each?.referenceKeyName],
          fill: color[index % color.length],
          height: 60,
          upperWidth:
            expandedChart && expandedChart.includes(props.chartTitle)
              ? 500 - index * 80
              : 400 - index * 80,
          lowerWidth:
            expandedChart && expandedChart.includes(props.chartTitle)
              ? 420 - index * 80
              : 320 - index * 80,
          x: 70 + index * 40,
        };
      });
      setData(finalData);
    }
  }, [props.chartDetails, props.data[refenceKeyForData]]);
  const length = data && data?.length;

  useEffect(() => {
    props.data && props.data[refenceKeyForData]
      ? Object.values(props.data[refenceKeyForData]).every((item) => item === 0)
        ? setNoDataFound(true)
        : setNoDataFound(false)
      : null;
  }, [props.data[refenceKeyForData]]);

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value, name } = props;
  
    return (
      <g>
        <text x={props.parentViewBox.width/2 + 25} y={y+50} fill="white" textAnchor="middle" dominantBaseline="middle" fontSize={13}>
          {name}
        </text>
      </g>
    );
  };

  // const data = [{x: 12, y: 12, upperWidth: 240, lowerWidth: 22, height: 80,}, {x: 12, y: 12, upperWidth: 240, lowerWidth: 22, height: 50,}]
  return (
    <>
      {!nodataFound ? (
        <div
          style={{
            width:
              expandedChart && expandedChart.includes(props.chartTitle)
                ? expandbarWidth
                : funnelWidth,
            height:
              expandedChart && expandedChart.includes(props.chartTitle)
                ? expandbarHeight
                : funnelHeight,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <FunnelChart
            width={
              expandedChart && expandedChart.includes(props.chartTitle)
                ? 600
                : 500
            }
            height={
              // expandedChart && expandedChart.includes(props.chartTitle)
              //   ? 80 * length
                // : 
                75 * length
            }
          >
            <Tooltip />
            <Funnel dataKey="value" data={data} isAnimationActive={false}>
            <LabelList
                position="center"
                stroke="none"
                fill="white"
                dataKey="value"
                dy={-10}
                dx={20}
                
              />
              <LabelList
                // position="insideBottom"
                // fill="white"
                // stroke="none"
                dataKey="name"
                // dx={20}
                // dy={-10}
                // offset={5}
                content={renderCustomizedLabel}
              />
              
            </Funnel>
          </FunnelChart>
        </div>
      ) : (
        <div
          className="noData"
          style={{
            width: "60%",
            margin: "auto",
          }}
        >
          <NoDatamodel srcImg={noPhoneDataIcon}></NoDatamodel>
        </div>
      )}
    </>
  );
}

export default FunnelChartComponent;