import actionTypes from "../actionTypes";

// export const setSelectedSourceTab = (payload: string) => {
//   return {
//     type: actionTypes.SET_SELECTED_SOURCE_TAB,
//     payload: payload,
//   };
// };

export const setSelectedChannelTab = (payload: Record<string, any>) => {
  return {
    type: actionTypes.SET_SELECTED_CHANNEL_TAB,
    payload: payload,
  };
};

export const setSelectedSubChannelTab = (payload: Record<string, any>) => {
  return {
    type: actionTypes.SET_SELECTED_SUB_CHANNEL_TAB,
    payload: payload,
  };
};

export const setSelectedSubmoduleTab = (payload: Record<string, any>) => {
  return {
    type: actionTypes.SET_SELECTED_SUBMODULE_TAB,
    payload: payload,
  };
};

export const setSubHeaderTabs = (
  channelTab: string,
  subChannelTab: string,
  subModuleTab: string
) => {
  if (channelTab === "Voice") {
    channelTab = "Call";
  }
  return {
    type: actionTypes.SET_HEADER_TABS,
    payload: { channelTab, subChannelTab, subModuleTab },
  };
};
