import React, { useEffect, useRef } from "react";
import styles from "./PaymentSuccessfull.module.scss";
import ReactDOM from "react-dom";
import { paymentdone } from "../../../../theme/assets/genericSvg";
interface Props {
  children?: React.ReactNode;
  open: boolean;
  setOpen: CallableFunction;
  onClickOk?: CallableFunction;
  icon?: string;
  title?: string;
  text?: string;
}
const PaymentSuccessfull: React.FC<Props> = (props: Props) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      props?.setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      {props.open ? (
        <div className={styles.wrapper}>
          <div className={styles.wrapper2} ref={dropdownRef}>
            <div className={styles.area}>
              <p>{props.title ? props.title : "Payment Successful"}</p>
              <img src={props.icon ? props.icon : paymentdone} alt="done" />
              <p>
                {props.text ? props.text : "Please check your mail for invoice"}
              </p>
              <button
                className={styles.btn}
                onClick={() => {
                  props.setOpen(false);
                  props.onClickOk && props.onClickOk();
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>,
    document.getElementById("root-portal")!
  );
};

export default PaymentSuccessfull;
