export const USECASE = {
  vehicleService: { id: "6451009fa3029a066498a246", name: "Vehicle Servicing" },
  salesAndOnboarding: {
    name: "Sales & Onboarding",
    id: "639053fb52d33c75af54947a",
  },
  debtCollection: {
    name: "Debt Collection",
    id: "62c81c4a4a0affe91342aaaa",
  },
  loanDisbursal: {
    name: "Disbursal",
    id: "64d08a804ad28b6d2f06b59b",
  },
  political: { id: "64773b463a06fe0a3b2a3cc1", name: "PoliticalEdge" },
  disbursal: { id: "64d08a804ad28b6d2f06b59b", name: "Disbursal" },
  insuranceRenewal : {id : "657ac2ff27a2610052b6e512" , name : "Insurance Renewal"}
};
