import React from "react";
import styles from "./PopUpModal.module.scss";
import { CrossIcon } from "../../../../theme/assets/genericSvg";
import { failedIcon } from "../../../../theme/assets/genericSvg";
import { partialcompleteIcon } from "../../../../theme/assets/genericSvg";
import { uploadIcon } from "../../../../theme/assets/genericSvg";
import { trueSign } from "../../../../theme/assets/genericSvg";
import Button from "../../../generic/button/Button";
import Loader from "../../../generic/loader/Loader";
interface PropsPopUpModal {
  close?: CallableFunction;
  headingImg?: string;
  headingName?: string;
  MsgData?: any;
  Message?: string;
  errorNote?: any;
  errorName?: string;
  btnName?: string;
  btnClick?: CallableFunction;
  MsgType?: string;
  errorShow?: boolean;
  btnIcon?: string;
  data?: any;
  closeIcon?: string;
  animationTrue?: boolean;
}
function PopUpModal({
  close,
  MsgData,
  Message,
  errorName,
  errorNote,
  headingImg,
  headingName,
  btnName,
  btnClick,
  MsgType,
  errorShow,
  btnIcon,
  closeIcon,
  data,
  animationTrue,
}: PropsPopUpModal) {
  const dummyError = "Upload Error";
  const dummmyerrorMsg =
    "This number is calculated considering maximum values, you can continue to add more accounts";

  const ImgHeading =
    MsgType === "Failed"
      ? failedIcon
      : MsgType === "Completed"
      ? trueSign
      : partialcompleteIcon;

  return (
    <div className={styles.popUpModalOuter}>
      <div className={styles.closeIcon}>
        <img
          src={closeIcon ? closeIcon : CrossIcon}
          onClick={() => {
            close && close();
          }}
          className={`${
            animationTrue ? styles.IconExtraStyling : styles.IconStyling
          }`}
        />
      </div>
      { Object.keys(data)?.length || data?.length ? (
        <div className={styles.popUpModalContainer}>
          <div className={styles.popUpModalHeading}>
            <img src={headingImg ? headingImg : ImgHeading}></img>
            <p
              className={
                MsgType === "Failed"
                  ? styles.popUpModalHeadingParaFailed
                  : MsgType === "Completed"
                  ? styles.popUpModalHeadingParaComplete
                  : styles.popUpModalHeadingParaPartialComplete
              }
            >
              {headingName}
            </p>
          </div>
          {MsgData &&
            MsgData?.map((e: any, i: any) => {
              return (
                <div className={styles.popUpModalMsgData} key={i}>
                  <p className={styles.firstPara}>{e?.name}</p>
                  <p className={styles.MsgDataDiffer}>:</p>
                  <p>{e?.value}</p>
                </div>
              );
            })}
          {Message?.length === 0  ? (
            <div className={styles.popUpModalMessage}>
              <span className={styles.failerReson}>Failure Reason : </span>
              {Message}{" "}
              <span className={styles.failerReson}>
                {" "}
                - Mandatory Field is missing in the file
              </span>
            </div>
          ) : ""}
          {(errorName || errorShow) && (
            <div className={styles.popUpModalErrorName}>
              {" "}
              <p>{`${errorName ? errorName : dummyError}  :`} </p>
              <p className={styles.popUpModalErrorNote}>
                {errorNote ? errorNote : dummmyerrorMsg}
              </p>
            </div>
          )}
          {btnName && (
            <div className={styles.popUpModalButtonContainer}>
              {" "}
              <Button
                image_src_left={btnIcon ? btnIcon : uploadIcon}
                text={btnName}
                extraClass={`${styles.buttonStyling} ${styles.yesButtonStyle}`}
                onClick={btnClick}
              />
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default PopUpModal;
