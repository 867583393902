import React, { useEffect, useState } from "react";
import { inviteUserBanner } from "../../../../../../theme/assets/svg";
import InputContainer from "../../../../../generic/inputContainer/InputContainer";
import Button from "../../../../../generic/button/Button";
import { dropDownArrowIcon } from "../../../../../../theme/assets/svg";
import DropDown from "../../../../../generic/dropdownsaarthi2/DropdownSaarthi";
import "./InviteUser.scss";
import signUpStyles from "../../../../../../screens/onBoarding/signUp/SignUp.module.scss";
import { loadingFourDot, tick } from "../../../../../../theme/gif";
import { invitationSentBanner } from "../../../../../../theme/assets/svg";
import { emailValidation } from "../../../../../../utils/Validation";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux";
import { sendInviteUser } from "../../../../../../redux/signUpFlow/inviteUser/actions";
import { useDispatch } from "react-redux";
import { removeIfNoMailId } from "./inviteUser.fn";
import {
  INVITE_MEMBERS_ALLOWED_DOMAIN,
  ROLES,
} from "../../../../../../constants";
import _ from "lodash";

interface userInfo {
  email: string;
  role: string;
}

interface props {
  closePopup: () => void;
}

export default function InviteUser(props: props) {
  /* useStates --------- */
  const [userInfo, setUserInfo] = useState<Array<userInfo>>([
    { email: "", role: "" },
    { email: "", role: "" },
    { email: "", role: "" },
    { email: "", role: "" },
  ]);
  const [errorArr, setErrorArr] = useState([
    { email: "", role: "" },
    { email: "", role: "" },
    { email: "", role: "" },
    { email: "", role: "" },
  ]);
  const [btnStatus, setBtnStatus] = useState<
    "noAction" | "loading" | "success" | "error"
  >("noAction");
  const [invitationSent, setInvitationSent] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  /************************ useSelector ********************************/

  const userId = useSelector(
    (state: RootState) => state.loginReducer.userLoginInfo.userDetail._id
  );
  const accountId = useSelector(
    (state: RootState) =>
      state.loginReducer.userLoginInfo.userDetail.accountDetails[0].id
  );
  const teamId = useSelector(
    (state: RootState) =>
      state.signUpModuleReducer?.teamsPageReducer?.selectedTeamDetails?.id
  );
  const teamUseCaseId = useSelector(
    (state: RootState) =>
      state.signUpModuleReducer?.teamsPageReducer?.selectedTeamDetails.useCaseId
  );
  const isLoading = useSelector(
    (state: RootState) => state.signUpModuleReducer?.inviteUserReducer?.isLoadingSendInvite
  );

  const isSuccess = useSelector(
    (state: RootState) => state.signUpModuleReducer?.inviteUserReducer?.userDetails?.msg
  );

  const isError = useSelector(
    (state: RootState) => state.signUpModuleReducer?.inviteUserReducer?.sendInviteError
  );

  const userEmailId = useSelector(
    (store: RootState) => store.loginReducer.userLoginInfo?.userDetail?.email
  );
  const myPlanData: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  const dispatch = useDispatch();

  const roleOptions = {
    optionList: _.map(myPlanData?.availableRole, getNewRoleFunc),
    imgSrcRight: dropDownArrowIcon,
    imgSrcLeftHide: true,
    placeHolderText: "Select Role",
  };

  function getNewRoleFunc(obj: any) {
    return Object.values(ROLES).find(
      (item) =>
        item.display &&
        item.name === obj &&
        (!item.useCaseId || item.useCaseId === teamUseCaseId)
    )?.displayName;
  }

  /************************ useEffect ********************************/
  useEffect(() => {
    let newDisableBtn = true;
    userInfo.forEach((item) => {
      if (item.email && item.role) {
        newDisableBtn = false;
        return;
      }
    });
    setDisableBtn(newDisableBtn);
  }, [userInfo]);

  useEffect(() => {
    let newDisableBtn = false;
    if (!disableBtn) {
      const email = errorArr.find((item) => item.email !== "");
      const role = errorArr.find((item) => item.role !== "");

      if (email || role) {
        newDisableBtn = true;
      }

      setDisableBtn(newDisableBtn);
    }
  }, [errorArr, disableBtn]);

  useEffect(() => {
    if (isLoading) setBtnStatus("loading");
    else if (isError && !Object.keys(isError).length) {
      if (isSuccess && Object.keys(isSuccess).length > 0) {
        if (isSuccess?.errors?.length > 0) {
          setBtnStatus("noAction");
        } else if (isSuccess?.msg?.length > 0) {
          setBtnStatus("success");
        }
      }
    } else setBtnStatus("noAction");
  }, [isLoading, isSuccess]);

  useEffect(() => {
    setBtnStatus("noAction");
    setInvitationSent(false);
  }, []);

  useEffect(() => {
    if (btnStatus === "success") {
      setTimeout(() => {
        setInvitationSent(true);
        setBtnStatus("noAction");
      }, 2000);
    }
  }, [btnStatus]);

  useEffect(() => {
    const element = document.querySelector(".invite-user__scrollable");
    element?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [userInfo.length]);
  /* handlers */
  const handleAddMoreClick = () => {
    setUserInfo((prev) => {
      return [...prev, { email: "", role: "" }];
    });
    setErrorArr((prev) => {
      return [...prev, { email: "", role: "" }];
    });
  };
  useEffect(() => {
    validate(userInfo);
  }, [userInfo]);

  /*****************************  handlers  *****************************/
  const handleRoleDDChange = (value: string, index?: number) => {
    index = index as number;
    const newData = [
      ...userInfo.slice(0, index),
      { email: userInfo[index].email, role: value },
      ...userInfo.slice(index + 1),
    ];
    setUserInfo(newData);
    // remove role error
    // if (errorArr[index].role) {
    //   const newErrorArr = [
    //     ...errorArr.slice(0, index),
    //     { email: errorArr[index].email, role: "" },
    //     ...errorArr.slice(index + 1),
    //   ];
    //   setErrorArr(newErrorArr);
    // }
  };

  const handleEmailInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    keyName?: string,
    index?: number
  ) => {
    index = index as number;
    const newData = [
      ...userInfo.slice(0, index),
      { email: e.currentTarget.value, role: userInfo[index].role },
      ...userInfo.slice(index + 1),
    ];
    setUserInfo(newData);

    // remove email error
    // if (errorArr[index].email) {
    //   const newErrorArr = [
    //     ...errorArr.slice(0, index),
    //     { email: "", role: errorArr[index].role },
    //     ...errorArr.slice(index + 1),
    //   ];
    //   setErrorArr(newErrorArr);
    // }
  };

  const handleSendInviteClick = () => {
    if (invitationSent) {
      props.closePopup();
      setInvitationSent(false);
      return;
    }
    const modifiedUserInfo = removeIfNoMailId(userInfo);
    setUserInfo(modifiedUserInfo);
    const isValid = validate(modifiedUserInfo);
    if (isValid) {
      // setBtnStatus("loading");
      // setTimeout(() => {
      //   setBtnStatus("success");
      //   setTimeout(() => {
      //     setInvitationSent(true);
      //     setBtnStatus("noAction");
      //   }, 2500);
      // }, 2000);
      const userDetails = modifiedUserInfo
        .map((user) => ({
          ...user,
          userId,
          accountId,
          teamId,
          isActive: false,
        }))
        .filter((user) => user.email && user.role);
      userDetails.forEach((user) => {
        user.role = Object.values(ROLES).find(
          (item) =>
            item.display &&
            item.displayName === user.role &&
            (!item.useCaseId || item.useCaseId === teamUseCaseId)
        )?.name as string;
      });
      dispatch(sendInviteUser(userDetails));
    }
  };

  /* helper function */
  function validate(userInfo: Array<userInfo>) {
    const newErrorArr = JSON.parse(
      JSON.stringify(
        errorArr.map((item) => {
          return { email: "", role: "" };
        })
      )
    );
    let isValid = true;
    const emailSet = new Set();
    userInfo.forEach((item, index) => {
      if (item.email || item.role) {
        const emailValidity = emailValidation(item.email);
        const domain = item.email.split("@")[1];
        const allowedDomains = [
          userEmailId.split("@")[1],
          ...INVITE_MEMBERS_ALLOWED_DOMAIN,
        ];
        if (!allowedDomains.includes(domain) && item.email) {
          isValid = false;
          newErrorArr[index].email = "Email domain not allowed.";
        }
        if (!emailValidity.isValid && item.email) {
          isValid = false;
          newErrorArr[index].email = emailValidity.errors.message;
        }
        if (!item.role) {
          isValid = false;
          newErrorArr[index].role = "Please select role.";
        }
        if (item.email === userEmailId) {
          isValid = false;
          newErrorArr[index].email = "Inviting self not allowed.";
        }
        if (emailSet.has(item.email) && item.email) {
          newErrorArr[index].email = "Duplicate mail id.";
          isValid = false;
        } else emailSet.add(item.email);
      }
    });
    setErrorArr(newErrorArr);
    return isValid;
  }

  return (
    <div className="team-setup__invite-User">
      <span className="close_btn" onClick={props.closePopup}>
        x
      </span>
      <img src={inviteUserBanner} alt="" />
      <div
        className={`${"body"} ${
          isLoading ? "disablePointerEventWithoutOpacity" : ""
        }`}
      >
        {invitationSent ? (
          <div className="invitation-sent__div">
            <p className="invitation-sent__heading">
              Invitation has been sent to the users.
            </p>
            <img src={invitationSentBanner} alt="" />
          </div>
        ) : (
          <div className="invite-user__scrollable">
            <div className="description-text">
              <h4>Send invite to users</h4>
              <p>Invite your team members to schedule a campaign</p>
            </div>
            <div className="user-info">
              {userInfo.map((item: userInfo, index: number) => (
                <div className="row" key={index}>
                  <div className="email-info">
                    <InputContainer
                      showLabel={false}
                      inputPlaceholder="Enter Email Address"
                      index={index}
                      inputOnChange={handleEmailInputChange}
                      inputValue={item.email}
                      extraUserInputClass={"extraInputClass"}
                      errorMsg={errorArr[index].email}
                    />
                  </div>
                  <div className="role-info">
                    <DropDown
                      droplist={roleOptions}
                      selectedItem={(item: any) =>
                        handleRoleDDChange(item, index)
                      }
                      extraClassSelectedArea={"dropDownSelectedStyling"}
                      extraClassToBeSelectedArea={"dropDownToBeSelectedStyling"}
                      extraClassPlaceHolderStyling={
                        "dropDownPlaceHolderStyling"
                      }
                      extraClassPlaceHolder={"defaultPlaceHolderStyling"}
                      extraClassDropDownUl={"dropDownUlStyling"}
                      extraClassDropDownLi={"dropDownLiStyling"}
                      outerSelected={item.role}
                      editedItem={item.role}
                      extraHighlight={"styleHighlightLi"}
                      extraStyleLeftImg={"iconRightStyling"}
                      id="auto_invite_role_dropdown"
                      ignoreLeftAlign={true}
                      errorMsg={errorArr[index].role}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="add-more-btn btn">
              <span onClick={handleAddMoreClick}>+ Add more</span>
            </div>
          </div>
        )}
      </div>
      <div
        className={`${"footer"} ${
          isLoading ? "disablePointerEventWithoutOpacity" : ""
        }`}
      >
        <Button
          btnContents={{
            loading: {
              icon: loadingFourDot,
              class: signUpStyles["extraBtnConditionalClass"],
            },
            noAction: { text: invitationSent ? "OK" : "Send Invite" },
            success: {
              icon: tick,
              class: signUpStyles["extraBtnConditionalClass"],
            },
          }}
          btnStatus={btnStatus}
          text="Send Invite"
          extraBtnImageClass={signUpStyles["extraBtnImageClass"]}
          onClick={handleSendInviteClick}
          extraClass={signUpStyles["extraBtnClass"]}
          disabled={disableBtn && !invitationSent}
          extraDisableClass={"extraDisableClass"}
        />
      </div>
    </div>
  );
}
