import React from "react";
import style from "./MailsettingPage.module.scss";
import { useDispatch } from "react-redux";
import Mailschedule from "../../../components/moduleComponents/campaign/omniChannel/emailScheduler copy/Mailschedule";

function MailSettingPage(props: any) {
  const dispatch = useDispatch();
  //store

  //calling api for getting templates

  return (
    <div className={style.mailsettingPageWrapper}>
      <div className={style.schedulerSettingPageWp}>
        <div className={style.scheduleSettingwp}>
          <Mailschedule />
        </div>
        {/* <div className={style.previewWp}> */}
        {/* <div className='previewNote'>Preview</div> */}
        {/* <Preview/> */}
        {/* </div> */}
      </div>
    </div>
  );
}

export default MailSettingPage;
