// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IR8HGCxzMDNqqiKPJ72d{margin-top:10px}`, "",{"version":3,"sources":["webpack://./src/components/agentDesktop/moduleComponents/summaryAD/fieldsController/FieldsController.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA","sourcesContent":[".fields-header__summary__extraClass {\n  margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fields-header__summary__extraClass": `IR8HGCxzMDNqqiKPJ72d`
};
export default ___CSS_LOADER_EXPORT___;
