export default {
  GET_PRICING_DATA_API_REQUEST: "GET_PRICING_DATA_API_REQUEST",
  GET_PRICING_DATA_API_SUCCESS: "GET_PRICING_DATA_API_SUCCESS",
  GET_PRICING_DATA_API_FAILURE: "GET_PRICING_DATA_API_FAILURE",
  UPDATE_PRICING_DATA_API_REQUEST: "UPDATE_PRICING_DATA_API_REQUEST",
  UPDATE_PRICING_DATA_API_SUCCESS: "UPDATE_PRICING_DATA_API_SUCCESS",
  UPDATE_PRICING_DATA_API_FAILURE: "UPDATE_PRICING_DATA_API_FAILURE",
  SET_SELECTED_PLAN: "SET_SELECTED_PLAN",
  SET_ADDED_TO_CARD_ROUTER: "SET_ADDED_TO_CARD_ROUTER",
  SET_SELECTED_PAID_PLAN: "SET_SELECTED_PAID_PLAN",

  CUSTOM_PLAN_API_REQUEST: "CUSTOM_PLAN_API_REQUEST",
  CUSTOM_PLAN_API_SUCCESS: "CUSTOM_PLAN_API_SUCCESS",
  CUSTOM_PLAN_API_FAILURE: "CUSTOM_PLAN_API_FAILURE",

  COUPAN_PLAN_API_REQUEST: "COUPAN_PLAN_API_REQUEST",
  COUPAN_PLAN_API_SUCCESS: "COUPAN_PLAN_API_SUCCESS",
  COUPAN_PLAN_API_FAILURE: "COUPAN_PLAN_API_FAILURE",
};
