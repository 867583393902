// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eKD3508ttyQfwKptGKfr{font-family:"Roboto";font-style:normal;font-weight:400;font-size:16px;line-height:19px;text-align:left;color:#676767;flex:none;order:1;flex-grow:0;margin:0 3%}.eKD3508ttyQfwKptGKfr ._mbXHGfDdRA9mUQvuEdr .o9WD9_qiLbYvnm0ks6MD{padding:3%}`, "",{"version":3,"sources":["webpack://./src/components/crm/moduleComponent/customerData/CustomerData.module.scss"],"names":[],"mappings":"AAAA,sBACI,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,aAAA,CACA,SAAA,CACA,OAAA,CACA,WAAA,CACA,WAAA,CAGI,kEACG,UAAA","sourcesContent":[".customerData {\n    font-family: 'Roboto';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 19px;\n    text-align: left;\n    color: #676767;\n    flex: none;\n    order: 1;\n    flex-grow: 0;\n    margin: 0 3%;\n\n    .textTitleClass{\n        .text{\n           padding: 3%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customerData": `eKD3508ttyQfwKptGKfr`,
	"textTitleClass": `_mbXHGfDdRA9mUQvuEdr`,
	"text": `o9WD9_qiLbYvnm0ks6MD`
};
export default ___CSS_LOADER_EXPORT___;
