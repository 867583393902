// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WXlYK5rEmehDoSamemYg{width:100%;height:100%;justify-content:center;align-items:center;display:flex;flex-direction:column}.WXlYK5rEmehDoSamemYg .mr59ahuZOx24MCHBz3LV{width:100%;height:100%}.WXlYK5rEmehDoSamemYg .qDhOotgo4Ws3VYZOuUQP{font-family:Roboto;font-size:13px;font-weight:400;line-height:19px;letter-spacing:0em}.WXlYK5rEmehDoSamemYg .qDhOotgo4Ws3VYZOuUQP,.WXlYK5rEmehDoSamemYg .vCGNh_XGCBe2rGwO9T2g{width:100%;text-align:center}.WXlYK5rEmehDoSamemYg .vCGNh_XGCBe2rGwO9T2g{cursor:pointer;color:var(--primary-color)}`, "",{"version":3,"sources":["webpack://./src/components/generic/noDatamodel/NoDatamodel.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,4CACE,UAAA,CACA,WAAA,CAEF,4CACE,kBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CAEF,wFAEE,UAAA,CACA,iBAAA,CAEF,4CACE,cAAA,CACA,0BAAA","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  .img {\n    width: 100%;\n    height: 100%;\n  }\n  .message {\n    font-family: Roboto;\n    font-size: 13px;\n    font-weight: 400;\n    line-height: 19px;\n    letter-spacing: 0em;\n  }\n  .message,\n  .a {\n    width: 100%;\n    text-align: center;\n  }\n  .a {\n    cursor: pointer;\n    color: var(--primary-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `WXlYK5rEmehDoSamemYg`,
	"img": `mr59ahuZOx24MCHBz3LV`,
	"message": `qDhOotgo4Ws3VYZOuUQP`,
	"a": `vCGNh_XGCBe2rGwO9T2g`
};
export default ___CSS_LOADER_EXPORT___;
