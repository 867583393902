import React from "react";
import styles from "./OrderSummaryFree.module.scss";
interface Props {
  children?: React.ReactNode;
  onClick?: CallableFunction;
  numberOfItem: number;
  planName: string;
  planPrice: string;
  currency: string;
 buttonDisable ?:boolean ; 
}
const OrderSummaryFree: React.FC<Props> = (props: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper2}>
        <div className={styles.first}>
          <p>Order Summary</p>
        </div>
        <div className={styles.second}>
          <p>{props.numberOfItem} Item</p>
          <div className={styles.hr1}></div>
        </div>
        <div className={styles.third}>
          <div>
            <p>{props.planName}</p>
            <p>{props.planPrice}</p>
          </div>
          <div className={styles.hr}></div>
        </div>
        <div className={styles.fourth}>
          <p>Subtotal ({props.currency})</p>
          <p>{props.planPrice}</p>
        </div>
        <div className={styles.fifth}>
          <button
           disabled ={props.buttonDisable}
            onClick={() => {
              props.onClick && props.onClick();
            }}
          >
            Experience the difference
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderSummaryFree;
