import React, { useState, useCallback, useMemo } from "react";
import styles from "./EditCSVScreen.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import Icon from "../../../../generic/icon/Icon";
import Button from "../../../../generic/button/Button";

import {
  backIcon,
  saveIcon,
  cutIcon,
  copyIcon,
  pasteIcon,
} from "../../../../../theme/assets/svg/campaign_v3/index";

import {
  postUpdatedCSVInfo,
  setShowEditCSVScreen,
} from "../../../../../redux/campaign_V2.0/dataUpload/actions";

import {
  Spreadsheet,
  CellBase,
  Matrix,
  createEmptyMatrix,
} from "react-spreadsheet";
import debounce from "lodash/debounce";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import Skeleton from "@mui/material/Skeleton";
import { SaveModal } from "./saveModal/SaveModal";

const SkeletonContainer = ({ count }: { count: number }) => {
  const skeletonElements = Array.from({ length: count }, (_, index) => (
    <Skeleton key={index} width={1250} height={50} />
  ));

  return <div>{skeletonElements}</div>;
};

export const EditCSVScreen = () => {
  const defaultRows = 15,
    defaultCols = 8;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    data: { name: csvFileName, id: csvId, csvData: csvInfo },
    status: loadingStatus,
  } = useSelector((store: RootState) => {
    return store.campaignModuleReducer?.dataUploadData?.editScreenData;
  });

  const { status: savingCSVStatus } = useSelector((store: RootState) => {
    return store.campaignModuleReducer?.dataUploadData?.updatedCSVData;
  });

  const strategyId = useSelector(
    (store: RootState) =>
      store?.strategyModuleReducer?.strategyReducer?.createdStrategy?.id
  );

  const accountType = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.accountDetails[0]?.type
  );

  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const campaignData = useSelector(
    (store: RootState) =>
      store?.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
  );

  const selectedTab = useSelector(
    (store: RootState) =>
      store?.campaignModuleReducer?.dataUploadData?.selectedInfo?.selectedType
  );

  const [saveModal, setSaveModal] = useState(false);
  const [tableData, setTableData] = useState<Matrix<CellBase<any>>>(
    csvInfo ? csvInfo : createEmptyMatrix(defaultRows, defaultCols)
  );
  const [shouldSort, setShouldSort] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
  const [selectedColIndex, setSelectedColIndex] = useState<number>(-1);
  const [selectedCell, setSelectedCell] = useState<any>([]);
  const [filteredData, setFilteredData] =
    useState<Matrix<CellBase<any>>>(tableData);
  const [searchText, setSearchText] = useState<string>("");
  const [sortColumnIndex, setSortColumnIndex] = useState<number>(-1);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [isColumnMenuSelected, setIsColumnMenuSelected] = useState(false);

  //For cell value change
  const handleDataChange = (data: any) => {
    setShouldSort(false);
    const currentRow = selectedCell?.[0]?.row;

    if (searchText.trim().length !== 0) {
      setFilteredData(data);
    }

    if (currentRow >= 0) {
      // Update only the changed row
      setTableData((prevTableData) => {
        const updatedTableData = [...prevTableData];
        updatedTableData[currentRow] = data[currentRow];
        return updatedTableData;
      });
    }
  };

  const debouncedSetData = debounce(handleDataChange, 300);

  //To get the active cell index
  const handleCellSelect = (selectedCell: any) => {
    if (selectedCell.length > 0) {
      setSelectedCell(selectedCell);
    } else {
      setSelectedCell([]);
    }
  };

  //To clear all the table content
  const clearTable = () => {
    // Keep the first row with default values, clear the rest
    const updatedTableData: any = tableData?.map((row, index) =>
      index === 0 ? row : row.map(() => ({ value: "" }))
    );

    setTableData(updatedTableData);
  };

  // Copy Functions
  const copyRow = () => {
    setShouldSort(false);
    if (selectedRowIndex >= 1) {
      const selectedRow = tableData?.[selectedRowIndex];
      navigator.clipboard.writeText(JSON.stringify(selectedRow));
    }
  };

  const copyColumn = () => {
    // setShouldSort(false);
    if (selectedColIndex >= 0) {
      const selectedColumn = tableData?.map((col) => {
        const cell = col[selectedColIndex];
        return {
          value: cell?.value || "",
          className: cell?.className || "",
        };
      });
      navigator.clipboard.writeText(JSON.stringify(selectedColumn));
    }
  };

  // Cut Functions
  const cutRow = () => {
    setShouldSort(false);
    if (selectedRowIndex >= 1) {
      const selectedRow = tableData?.[selectedRowIndex];
      navigator.clipboard.writeText(JSON.stringify(selectedRow));
      const newData = [...tableData];
      newData[selectedRowIndex] = Array.from(
        { length: selectedRow.length },
        () => ({ value: "", className: "" })
      );
      setTableData(newData);
    }
  };

  const cutColumn = () => {
    // setShouldSort(false);
    if (selectedColIndex >= 0) {
      const selectedColumn = tableData?.map((col) => {
        const cell = col[selectedColIndex];
        return {
          value: cell?.value || "",
          className: cell?.className || "",
        };
      });
      navigator.clipboard.writeText(JSON.stringify(selectedColumn));
      const newData = tableData?.map((row) => {
        const newRow = [...row];
        newRow[selectedColIndex] = { value: "", className: "" };
        return newRow;
      });
      setTableData(newData);
    }
  };

  // Paste Functions
  const pasteRow = async () => {
    setShouldSort(false);
    if (selectedRowIndex >= 1) {
      const clipboardData = await navigator.clipboard.readText();
      const rowData = JSON.parse(clipboardData);
      const newData = [...tableData];
      newData[selectedRowIndex] = rowData;
      setTableData(newData);
    }
  };

  const pasteColumn = async () => {
    // setShouldSort(false);
    if (selectedColIndex >= 0) {
      const clipboardData = await navigator.clipboard.readText();
      const columnData = JSON.parse(clipboardData);
      const newData = tableData?.map((row, rowIndex) => {
        const newRow = [...row];
        const cell = columnData?.[rowIndex] || {};
        newRow[selectedColIndex] = {
          value: cell?.value || "",
          className: cell.className || "",
        };
        return newRow;
      });
      setTableData(newData);
    }
  };

  // Insert Functions
  const insertNewRow = () => {
    setShouldSort(false);
    if (selectedRowIndex >= 1) {
      const maxRowLength = Math.max(...tableData.map((row) => row.length));
      const newRow = Array.from({ length: maxRowLength }, () => ({
        value: "",
        className: "",
      }));
      const newData = [...tableData];
      newData.splice(selectedRowIndex + 1, 0, newRow);
      setTableData(newData);
    }
  };

  const insertNewColumn = () => {
    // setShouldSort(false);
    if (selectedColIndex >= 0) {
      const newData = tableData?.map((col) => {
        const newColumn = [...col];
        newColumn.splice(selectedColIndex + 1, 0, { value: "", className: "" });
        return newColumn;
      });
      setTableData(newData);
    }
  };

  // Delete Functions
  const deleteRow = () => {
    setShouldSort(false);
    if (selectedRowIndex >= 1) {
      const newData = tableData.filter(
        (_, index) => index !== selectedRowIndex
      );
      setTableData(newData);
    }
  };

  const deleteColumn = () => {
    if (selectedColIndex >= 0) {
      const newData = tableData?.map((row) =>
        row.filter((_, index) => index !== selectedColIndex)
      );
      setTableData(newData);
    }
  };

  // Clear Functions
  const clearRowContent = () => {
    if (selectedRowIndex >= 1) {
      const newData = tableData.map((row, index) =>
        index === selectedRowIndex
          ? row.map(() => ({ value: "", className: "" }))
          : row
      );
      setTableData(newData);
    }
  };

  const clearColumnContent = () => {
    // setShouldSort(false);
    if (selectedColIndex >= 0) {
      const newData = tableData.map((col) => {
        const newColumn = [...col];
        newColumn[selectedColIndex] = { value: "", className: "" };
        return newColumn;
      });
      setTableData(newData);
    }
  };

  // Dropdown Menus
  const cornerItems: MenuProps["items"] = [
    {
      label: "Clear Contents",
      key: "0",
    },
  ];

  const commonKeys: MenuProps["items"] = [
    {
      key: "1",
      label: "Copy",
      icon: <Icon img_src={copyIcon} />,
    },
    {
      key: "2",
      label: "Cut",
      icon: <Icon img_src={cutIcon} />,
    },
    {
      key: "3",
      label: "Paste",
      icon: <Icon img_src={pasteIcon} />,
    },
    {
      type: "divider",
    },
  ];

  const rowItems: MenuProps["items"] = [
    ...commonKeys,
    {
      label: "Insert Rows",
      key: "4",
    },
    {
      label: "Delete Rows",
      key: "5",
    },
    {
      label: "Clear Contents",
      key: "6",
    },
  ];

  const columnItems: MenuProps["items"] = [
    ...commonKeys,
    {
      label: "Insert Columns",
      key: "4",
    },
    {
      label: "Delete Columns",
      key: "5",
    },
    {
      label: "Clear Contents",
      key: "6",
    },
    // {
    //   label: "Sort Contents ↑",
    //   key: "7",
    // },
    // {
    //   label: "Sort Contents ↓",
    //   key: "8",
    // },
  ];

  const handleMenuSelect = (event: any) => {
    const key = event.key;
    switch (key) {
      case "1":
        isColumnMenuSelected ? copyColumn() : copyRow();
        break;
      case "2":
        isColumnMenuSelected ? cutColumn() : cutRow();
        break;
      case "3":
        isColumnMenuSelected ? pasteColumn() : pasteRow();
        break;
      case "4":
        isColumnMenuSelected ? insertNewColumn() : insertNewRow();
        break;
      case "5":
        isColumnMenuSelected ? deleteColumn() : deleteRow();
        break;
      case "6":
        isColumnMenuSelected ? clearColumnContent() : clearRowContent();
        break;
      // case "7":
      //   isColumnMenuSelected && handleSortClick();
      //   break;
      // case "8":
      //   isColumnMenuSelected && handleSortClick();
      //   break;
      default:
        break;
    }
  };

  // corner Indicator
  const CustomCornerIndicator = ({ onSelect, selected }: any) => {
    const handleCustomCornerClick = () => {
      // const numRows = sortedData.length;
      // const numCols = sortedData[0]?.length || 0;

      const numRows = updatedData.length;
      const numCols = updatedData[0]?.length || 0;

      const selectedCells: any[] = [];
      for (let row = 0; row < numRows; row++) {
        for (let col = 0; col < numCols; col++) {
          selectedCells.push({ row, col });
        }
      }

      // setSelectedCell(selectedCells);
      onSelect(selectedCells);
    };

    return (
      <Dropdown
        menu={{
          items: cornerItems,
          onClick: clearTable,
          selectable: true,
        }}
        placement="bottomLeft"
        trigger={selected ? ["hover"] : ["click"]}
        overlayClassName={styles.dropdownStyling}
      >
        <th
          className="Spreadsheet__header"
          style={{ position: "relative" }}
          onClick={() => handleCustomCornerClick()}
        >
          <div
            style={{
              width: 0,
              height: 0,
              borderTop: "3em solid transparent",
              borderBottom: "3em solid transparent",
              borderLeft: "3em solid #DADADA",
              position: "absolute",
              right: "10%",
              bottom: "10%",
              transform: "translate(50%, 50%) rotate(45deg)",
            }}
          />
        </th>
      </Dropdown>
    );
  };

  //Row Indicator
  const RowIndicator = ({ row, onSelect, selected }: any) => {
    console.log(row, "rowww");

    if (row >= 1) {
      const handleClick = useCallback(
        (event: React.MouseEvent) => {
          onSelect(row, event.shiftKey);
          setSelectedRowIndex(row);
          setIsColumnMenuSelected(false);
        },
        [onSelect, row]
      );

      return (
        <Dropdown
          menu={{
            items: rowItems,
            onClick: handleMenuSelect,
            selectable: true,
          }}
          placement="bottomLeft"
          trigger={selected ? ["hover"] : ["click"]}
          overlayClassName={styles.dropdownStyling}
        >
          <th
            className={`${
              !selected
                ? "Spreadsheet__header"
                : "Spreadsheet__header--selected"
            } ${styles.headerBtn} ${styles.rowHeaderDiv} ${
              selected && styles.highlighStyling
            }`}
            onClick={handleClick}
            tabIndex={0}
          >
            <div>{row + 1}</div>
          </th>
        </Dropdown>
      );
    }

    // Render a plain th for rows with index < 1
    return (
      <th
        className={`${
          !selected ? "Spreadsheet__header" : "Spreadsheet__header--selected"
        } ${styles.headerBtn} ${styles.rowFirstHeaderDiv} ${
          selected && styles.highlighStyling
        }`}
        tabIndex={0}
      >
        <div>{row + 1}</div>
      </th>
    );
  };

  //Column Indicator
  const columnIndexToLabel = (column: number): string => {
    let label = "";
    let index = column;
    while (index >= 0) {
      label = String.fromCharCode(65 + (index % 26)) + label;
      index = Math.floor(index / 26) - 1;
    }
    return label;
  };

  const ColumnIndicator = ({ column, onSelect, selected }: any) => {
    const handleClick = useCallback(
      (event: React.MouseEvent) => {
        onSelect(column, event.shiftKey);
        setSelectedColIndex(column);
        setIsColumnMenuSelected(true);
      },
      [onSelect, column]
    );

    return (
      // <Dropdown
      //   menu={{
      //     items: columnItems,
      //     onClick: handleMenuSelect,
      //     selectable: true,
      //   }}
      //   placement="bottomLeft"
      //   trigger={selected ? ["hover"] : ["click"]}
      //   overlayClassName={styles.dropdownStyling}
      // >
      <th
        className={`${
          !selected ? "Spreadsheet__header" : "Spreadsheet__header--selected"
        } ${styles.colHeaderBtn} ${styles.colHeaderDiv} ${
          selected && styles.highlighStyling
        } ${styles.minSizStyling}`}
        onClick={handleClick}
        tabIndex={0}
      >
        <div>{columnIndexToLabel(column)}</div>
      </th>
      // </Dropdown>
    );
  };

  // const handleSearchChange = debounce(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const searchValue = event.target.value;
  //     setShouldSort(false);
  //     setSearchText(searchValue);

  //     const trimSearchText = searchValue.trim().toLowerCase();

  //     if (trimSearchText.length === 0) {
  //       setFilteredData(tableData);
  //     } else {
  //       const headerRow = tableData[0];
  //       const filteredRows = tableData
  //         .slice(1)
  //         .filter((row) =>
  //           row.some(
  //             (cell: any, columnIndex: number) =>
  //               columnIndex !== 0 &&
  //               cell?.value.toString().toLowerCase().includes(trimSearchText)
  //           )
  //         );

  //       const filteredDataWithHeader = [headerRow, ...filteredRows];
  //       setFilteredData(filteredDataWithHeader);
  //     }
  //   },
  //   300
  // );

  // const handleSortClick = () => {
  //   setShouldSort(true);
  //   const isSortingColumn = sortColumnIndex === selectedColIndex;
  //   const isAscendingSort = sortDirection === "asc";
  //   if (isSortingColumn) {
  //     setSortDirection(isAscendingSort ? "desc" : "asc");
  //   } else {
  //     setSortColumnIndex(selectedColIndex);
  //     setSortDirection("asc");
  //   }
  // };

  const updatedData = useMemo(() => {
    const tableInfo = searchText?.length === 0 ? tableData : filteredData;
    let columnIndex = -1;
    if (tableInfo.length > 0) {
      const firstRow = tableInfo[0];
      columnIndex = firstRow?.findIndex(
        (cell: any) => cell?.value === "failureReason"
      );
    }

    const selectedCellRow = selectedCell?.[0]?.row;
    const selectedCellColumn = selectedCell?.[0]?.column;

    if (selectedCellColumn === columnIndex) {
      const cellData = tableInfo[selectedCellRow]?.[selectedCellColumn];

      if (cellData) {
        if (cellData.value !== "") {
          tableInfo[selectedCellRow][selectedCellColumn] = {
            ...cellData,
            className: styles.redBgStyling,
          };
        } else {
          delete tableInfo[selectedCellRow][selectedCellColumn]?.className;
        }
      }
    }

    if (shouldSort && sortColumnIndex !== -1) {
      const sortedDataRows = [...tableInfo];
      const headerRow = sortedDataRows.shift();

      if (headerRow) {
        const newHeaderRow: CellBase<any>[] = headerRow.map((cell: any) => ({
          ...cell,
          value: cell?.value || "",
        }));

        // Separate non-empty and default rows

        const nonEmptyRows = sortedDataRows.filter((row) => {
          const allCellsEmpty = row.every((cell) => {
            if (cell === undefined) return true; // Include if any cell is undefined
            const cellValues = Object.values(cell);
            return cellValues.every(
              (value) => typeof value === "string" && value.trim() === ""
            );
          });
          return !allCellsEmpty;
        });

        const defaultRows = sortedDataRows.filter((row) =>
          row.every((cell) => {
            if (cell === undefined) return true; // Include if any cell is undefined
            const cellValues = Object.values(cell);
            return cellValues.every(
              (value) => typeof value === "string" && value.trim() === ""
            );
          })
        );

        nonEmptyRows.sort((a: any, b: any) => {
          const aValue = a[sortColumnIndex]?.value;
          const bValue = b[sortColumnIndex]?.value;
          return sortDirection === "asc"
            ? aValue?.localeCompare(bValue)
            : bValue?.localeCompare(aValue);
        });

        // Combine non-empty and default rows, ensuring default rows stay at the bottom
        const combinedData = [...nonEmptyRows, ...defaultRows];

        // Add back the header row
        combinedData.unshift(newHeaderRow);
        return combinedData;
      }
    }

    return tableInfo;
  }, [
    shouldSort,
    sortColumnIndex,
    sortDirection,
    searchText,
    tableData,
    filteredData,
  ]);

  return (
    <div className={styles.editCSVScreenWrapper}>
      <div className={styles.editCSVScreenHeader}>
        <div className={styles.editCSVLeftHeader}>
          <div>
            <Icon
              img_src={backIcon}
              extraClass={styles.backIconStyling}
              onClick={() => {
                navigate("/campaignmanager/dataupload");
                dispatch(setShowEditCSVScreen({ isNavigate: false }));
              }}
            />
          </div>
          <div className={styles.headerStyling}>{csvFileName}</div>
        </div>
        <div className={styles.editCSVRightHeader}>
          <Button
            text="Save"
            image_src_left={saveIcon}
            extraClass={`${styles.buttonStyling} ${styles.activeBtnStyling}`}
            onClick={() => {
              setSaveModal(true);
            }}
            disabled={
              tableData.filter((row) =>
                row.some((cell: any) => cell.value !== "")
              )?.length <= 1
            }
          />
        </div>
      </div>
      <div className={styles.spreadSheetWrapper}>
        {loadingStatus === "loading" ? (
          <SkeletonContainer count={12} />
        ) : (
          <Spreadsheet
            data={tableData}
            CornerIndicator={CustomCornerIndicator}
            onChange={debouncedSetData}
            RowIndicator={RowIndicator}
            ColumnIndicator={ColumnIndicator}
            className={styles.spreadSheet}
            onSelect={handleCellSelect}
          />
        )}
      </div>

      {saveModal && (
        <SaveModal
          show={saveModal}
          onClose={() => {
            setSaveModal(false);
          }}
          onClickCancel={() => {
            setSaveModal(false);
          }}
          isLoading={savingCSVStatus === "loading"}
          onClickYes={() => {
            setSaveModal(false);
            dispatch(
              postUpdatedCSVInfo({
                csvId: csvId,
                csvInfo: tableData,
                isStrategy: location.pathname?.includes("/strategy/dataupload"),
                strategyId: strategyId,
                campaignManagerId: campaignData?.id,
                userType: accountType,
                token: token,
                selectedTab: selectedTab,
              })
            );
            navigate("/campaignmanager/dataupload");
            dispatch(setShowEditCSVScreen({ isNavigate: false }));
          }}
        />
      )}
    </div>
  );
};
