import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import {
  STRATEGY_API,
  CAMPAIGN_URL,
  CAMPAIGN_NEW_API,
  config,
} from "../../../../services/ApiRoutes";
import actionTypes from "../nudge.actionTypes";
import _ from "lodash";

export function* createNudgeAPIWorker(action: any): any {
  try {
    console.log("payload Data Create" ,  action.payload)
    if (action) {
      const result = yield call(
        config.POST_WITH_BODY,
        `${STRATEGY_API.CREATE_NUDGE}`,
        action.payload
      );
      yield put({
        type: actionTypes.CREATE_NUDGE_API_SUCCESS,
        payload: result.data.data,
      });
      toast.success("Strategy Created Successfully !");
    }
  } catch (error: any) {
    toast.error(error.response?.data?.error);
    console.error(error);
    yield put({
      type: actionTypes.CREATE_NUDGE_API_FAILURE,
      payload: error,
    });
  }
}
export function* getNudgeAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS,
        `${STRATEGY_API.GET_NUDGE}`,
        action.payload
      );
      console.log("mm", result, result.data.data);
      yield put({
        type: actionTypes.GET_NUDGE_API_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: actionTypes.GET_NUDGE_API_FAILURE,
        payload: "",
      });
      if (result.data.data?.length > 0) {
        yield put({
          type: actionTypes.SET_ALL_NUDGE_DATA,
          payload: result.data.data,
        });
      }
      toast.success(result.data.data.message);
    }
  } catch (error: any) {
    toast.error(error.response?.data?.error);
    console.error(error);
    yield put({
      type: actionTypes.GET_NUDGE_API_FAILURE,
      payload: error,
    });
  }
}

export function* updateNudgeAPIWorker(action: any): any {
  try {
    console.log("payload Data update" ,  action.payload)
    if (action) {
      const result = yield call(
        config.PUT_WITH_BODY,
        `${STRATEGY_API.UPDATE_NUDGE}`,
        action.payload
      );
      yield put({
        type: actionTypes.UPDATE_NUDGE_API_SUCCESS,
        payload: result.data.data,
      });
      toast.success("Strategy Updated Successfully !");
    }
  } catch (error: any) {
    toast.error(error.response?.data?.error);
    console.error(error);
    yield put({
      type: actionTypes.UPDATE_NUDGE_API_FAILURE,
      payload: error,
    });
  }
}
export function* getConditionAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${STRATEGY_API.GET_CONDITION_OUTCOMES}`,
        {
          params: action.payload.body,
          headers: { "x-access-token": action.payload.token },
        }
      );
      console.log("mm", result, result.data.data);
      yield put({
        type: actionTypes.GET_CONDITION_API_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error: any) {
    toast.error(error.response?.data?.error);
    console.error(error);
    yield put({
      type: actionTypes.GET_CONDITION_API_FAILURE,
      payload: error,
    });
  }
}
export function* getConditionEditAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${STRATEGY_API.GET_CONDITION_OUTCOMES}`,
        {
          params: action.payload.body,
          headers: { "x-access-token": action.payload.token },
        }
      );
      console.log("mm", result, result.data.data);
      yield put({
        type: actionTypes.GET_CONDITION_EDIT_API_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error: any) {
    toast.error(error.response?.data?.error);
    console.error(error);
    yield put({
      type: actionTypes.GET_CONDITION_EDIT_API_FAILURE,
      payload: error,
    });
  }
}

export function* getDispositionAlternateAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_HEADER,
        `${CAMPAIGN_URL.GET_DISPLIST_ALTERNATENUMBER}`,
        {
          headers: { "x-access-token": action.payload.token },
        }
      );
      console.log("mm", result, result.data.data);
      yield put({
        type: actionTypes.GET_DISPOSITION_ALTERNATE_API_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error: any) {
    toast.error(error.response?.data?.error);
    console.error(error);
    yield put({
      type: actionTypes.GET_DISPOSITION_ALTERNATE_API_FAILURE,
      payload: error,
    });
  }
}

export function* getDispositionFollowUpsAPIWorker(action: any): any {
  const { token, selectedChannel, ...paramsPayload } = action.payload;
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${CAMPAIGN_NEW_API.GET_FOLLOWUPS_DISPOSITIONS}`,
        {
          params: paramsPayload,
          headers: { "x-access-token": token },
        }
      );

      const dispositions = result.data.data;
      console.log("disp" ,dispositions , selectedChannel)
      let followUps: any;
      let channel =
        selectedChannel === "WhatsApp" ? "Whatsapp" : selectedChannel;
      if (channel) {
        followUps = dispositions?.filter((e: any) => {
          if (e?.channel === channel) {
            return e;
          }
        })?.[0]?.template || []
        followUps = [...followUps];
      } else {
        followUps = [];
      }

      yield put({
        type: actionTypes.GET_FOLLOWUPS_DISPOSITION_API_SUCCESS,
        payload: followUps,
      });
    }
  } catch (error: any) {
    toast.error(error.response?.data?.error);
    console.error(error);
    yield put({
      type: actionTypes.GET_FOLLOWUPS_DISPOSITION_API_FAILURE,
      payload: error,
    });
  }
}

export function* getOutComesDispositionAPIWorker(action: any): any {
  const { token, selectedChannel, ...paramsPayload } = action.payload;
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${CAMPAIGN_NEW_API.GET_FOLLOWUPS_DISPOSITIONS}`,
        {
          params: paramsPayload,
          headers: { "x-access-token": token },
        }
      );

      const dispositions = result.data.data;
      let outcomes: any;
      let channel =
        selectedChannel === "WhatsApp" ? "Whatsapp" : selectedChannel;
      if (channel) {
        outcomes = dispositions?.filter((e: any) => {
          if (e?.channel === channel) {
            return e;
          }
        })?.[0]?.template || []
        outcomes = [...outcomes];
      } else {
        outcomes = [];
      }

      const formattedOutcomes: any = {
        "Accepted Outcome": [],
        "Unaccepted Outcome": [],
      };

      outcomes.forEach((item: any) => {
        if (item?.outcomeType === "Accepted") {
          formattedOutcomes["Accepted Outcome"].push(item.text);
        } else if (item?.outcomeType === "Unaccepted") {
          formattedOutcomes["Unaccepted Outcome"].push(item.text);
        }
      });

      yield put({
        type: actionTypes.GET_OUTCOMES_DISPOSITIONS_API_SUCCESS,
        payload: formattedOutcomes,
      });
    }
  } catch (error: any) {
    toast.error(error.response?.data?.error);
    console.error(error);
    yield put({
      type: actionTypes.GET_OUTCOMES_DISPOSITIONS_API_FAILURE,
      payload: error,
    });
  }
}

export function* getPaymentModebyflowAPIWorker(action: any): any {
  const { token, params } = action.payload;
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${STRATEGY_API.GET_PAYMENTMODE_FLOW}`,
        {
          params: params,
          headers: { "x-access-token": token },
        }
      );
      yield put({
        type: actionTypes.GET_PAYMENT_MODE_API_SUCCESS,
        payload: result?.data?.data,
      });
    }
  } catch (error: any) {
    toast.error(error.response?.data?.error);
    console.error(error);
    yield put({
      type: actionTypes.GET_PAYMENT_MODE_API_FAILURE,
      payload: error,
    });
  }
}
