import { all, fork } from "redux-saga/effects";
import * as mathFunctionWatcher from "./reduxSagaExample/sagas";
import * as filterWatcher from "./filters/saga";
import * as rootLoggerWatcher from "./logger/rootLogger.saga";
import * as allClientRootSaga from "./allClient/allClient.RootSaga";
import * as analyticsWatcher from "./analytics/saga";
import * as rootBaseScreenSaga from "./baseScreen/rootBaseScreen.saga";
import * as loginSagas from "../redux/onboarding/login/sagas/index";
import * as campaignTestingInfoWatcher from "./campaignTestingInfo/saga";
import * as profileSaga from "./profile/profile.rootSaga";
import * as campaignSaga from "./campaign/saga";
import * as completedCampaignSaga from "./completedCampaign/saga";
import * as rootAgentDesktopSaga from "./agentDesktop/agentDesktop.rootSaga";
import * as strategyModuleSaga from "./strategy/strategy.rootSaga";
import * as campaignV2ModuleSaga from "./campaign_V2.0/campaignV2.rootSaga";
import * as signUpFlowRootSaga from "./signUpFlow/signUpFlow.rootSaga";
import * as integrationRootSaga from "./integration_V2.0/integration.rootSaga";
import * as exclusionsListSaga from "./exclusionsList/saga";

export default function* rootSaga() {
  yield all([...Object.values(mathFunctionWatcher)].map(fork));
  yield all([...Object.values(filterWatcher)].map(fork));
  yield all([...Object.values(rootLoggerWatcher)].map(fork));
  yield all([...Object.values(allClientRootSaga)].map(fork));
  yield all([...Object.values(analyticsWatcher)].map(fork));
  yield all([...Object.values(rootBaseScreenSaga)].map(fork));
  yield all([...Object.values(loginSagas)].map(fork));
  yield all([...Object.values(campaignTestingInfoWatcher)].map(fork));
  yield all([...Object.values(profileSaga)].map(fork));
  yield all([...Object.values(campaignSaga)].map(fork));
  yield all([...Object.values(completedCampaignSaga)].map(fork));
  yield all([...Object.values(rootAgentDesktopSaga).map(fork)]);
  yield all([...Object.values(strategyModuleSaga)].map(fork));
  yield all([...Object.values(campaignV2ModuleSaga)].map(fork));
  yield all([...Object.values(signUpFlowRootSaga)].map(fork));
  yield all([...Object.values(integrationRootSaga)].map(fork));
  yield all([...Object.values(exclusionsListSaga)].map(fork));
}
