import actionTypes from "../actionTypes";

export const getOrganizationAPIData = (data: string) => {
  return {
    type: actionTypes.ORGANIZATION_DATA_API_REQUEST,
    payload: data,
  };
};

export const saveOrganizationAPIData = (data: object) => {
  return {
    type: actionTypes.UPDATE_ORGANIZATION_DATA_API_REQUEST,
    payload: data,
  };
};

export const updateOrgStatus = (data: boolean) => {
  return {
    type: actionTypes.UPDATE_ORG_DATA_STATUS,
    payload: data,
  };
};

export function getCurrentTab(payload: string) {
  return {
    type: actionTypes.SELECTED_TAB,
    payload: payload,
  };
}

export function sendOrgDataOTP(payload: object) {
  return {
    type: actionTypes.SEND_OTP_ORG_REQUEST,
    payload: payload,
  };
}

export function clearSentOTP() {
  return {
    type: actionTypes.CLEAR_SENT_OTP_ORG,
  };
}

export function clearVerifyOTP() {
  return {
    type: actionTypes.CLEAR_VERIFY_OTP_ORG,
  };
}

export function verifyOrgDataOTP(payload: object) {
  return {
    type: actionTypes.VERIFY_OTP_ORG_REQUEST,
    payload: payload,
  };
}

export function firstTimeOrgSaved(payload: boolean) {
  return {
    type: actionTypes.FIRST_TIME_ORG_SAVED,
    payload: payload,
  };
}
export function resetGstVerification() {
  return {
    type: actionTypes.VERIFY_GST_RESET,
  };
}
export function resetPanVerification() {
  return {
    type: actionTypes.VERIFY_PAN_RESET,
  };
}
export function getGstVerification(payload: { gst: string; orgName: string }) {
  return {
    type: actionTypes.VERIFY_GST_ORG_REQUEST,
    payload,
  };
}
export function getPanVerification(payload: { pan: string; orgName: string }) {
  return {
    type: actionTypes.VERIFY_PAN_ORG_REQUEST,
    payload,
  };
}

export function saveTempGSTPanData(payload: object) {
  return {
    type: actionTypes.STORE_GST_PAN_DATA,
    payload,
  };
}
export function sendSignEmail(payload: object , func : any) {
  return {
    type: actionTypes.SEND_SIGN_EMAIL_REQUEST,
    payload: {payload ,func },
  };
}

export const getOrganizationAPIDataRefress = (data: string) => {
  return {
    type: actionTypes.ORGANIZATION_DATA_API_REFRESS_REQUEST,
    payload: data,
  };
};