import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/rootStore";
import HeaderTabComponent from "../headerTabComponent/HeaderTabComponent";
import styles from "./AnalyticsLoggerHeader.module.scss";
import RadioButton from "../../generic/radioButton/RadioButton";
import { setCurrentPage } from "../../../redux/logger/loggerState/action";
import {
  additionWorker,
  substractionWorker,
  restAPICallWorker,
} from "../../../redux/reduxSagaExample/actions";
import SubModuleHeader from "../subModuleHeaderTab/SubModuleHeader";
import {
  setSelectedChannelTab,
  setSelectedSubChannelTab,
  setSelectedSubmoduleTab,
} from "../../../redux/dashboard/actions";
import { getDayInCorrectFormat } from "../../../utils/ConvertTime";
import { getChannelsToShow, getTabsToShow } from "./analyticsLoggerHeader.fn";
import screenType from "../../../constants/screenType";
import {
  setShowRightSideBar,
  setSelectedIcon,
} from "../../../redux/filters/actions";
interface props {
  default: string;
}

export default function AnalyticsLoggerHeader(props: any) {
  /*****************************  redux hooks  ******************************/
  const activePage = useSelector(
    (store: RootState) => store?.loginReducer?.isActivePageType
  );
  const teamTabs = useSelector(
    (store: RootState) => store.loginReducer.teamTabs.data
  );
  const teamChannel = useSelector(
    (store: RootState) => store.loginReducer.teamChannels.data
  );
  const currentPage = useSelector(
    (store: RootState) => store?.loggerReducer?.loggerState?.currentPage
  );
  const isActivePageTypes = useSelector(
    (store: RootState) => store.loginReducer.isActivePageType
  );
  const currentSelectedDate = useSelector(
    (store: RootState) => store.filterReducers.calenderData
  );
  const currentDomain = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.domainId
  );
  const currentChannel = useSelector(
    (store: RootState) => store.dashboardReducer.channelTab
  );
  const currentSubChannel = useSelector(
    (store: RootState) => store.dashboardReducer.subChannelTab
  );
  const currentSubModuleTab = useSelector(
    (store: RootState) => store.dashboardReducer.subModuleTab
  );
  const dispatch = useDispatch();

  /*****************************  useStates  ******************************/
  const [showTabName, setShowTabName] = useState<string>();
  const [showChannels, setShowChannels] =
    useState<Array<Record<string, any>>>();
  const [showTabs, setShowTabs] = useState<Array<Record<string, any>>>();

  /*****************************  useEffects  *****************************/

  //console.log(showChannels, "channels");
  useEffect(() => {
    if (currentDomain !== undefined && teamChannel?.length) {
      const result = getChannelsToShow(
        currentDomain,
        JSON.parse(JSON.stringify(teamChannel)),
        currentChannel,
        currentSubChannel
      );

      setShowChannels(result.channelsToShow);
      if (result.defaultSelectedChannel?.id !== currentChannel?.id) {
        dispatch(
          setSelectedChannelTab(
            result.defaultSelectedChannel as Record<string, any>
          )
        );
      }
      if (result.defaultSelectedSubChannel?.id !== currentSubChannel?.id) {
        dispatch(setSelectedSubChannelTab(result.defaultSelectedSubChannel));
      }
    }
  }, [teamChannel, isActivePageTypes, currentChannel]);

  useEffect(() => {
    if (currentDomain !== undefined) {
      if (currentChannel) {
        const result = getTabsToShow(
          currentDomain,
          JSON.parse(JSON.stringify(teamTabs)),
          currentChannel.id,
          isActivePageTypes.toLowerCase(),
          currentSubModuleTab
        );
        setShowTabs(result.tabsToShow);
        if (
          result.defaultSelectedTab?.id !== currentSubModuleTab?.id &&
          result.defaultSelectedTab
        ) {
          dispatch(setSelectedSubmoduleTab(result.defaultSelectedTab));
        }
      }
    }
  }, [currentDomain, teamTabs, currentChannel, isActivePageTypes]);

  /*****************************  handlers  *****************************/
  const radioChangeHandler = (e: any) => {
    dispatch(setCurrentPage(e.target.value));
  };

  return (
    <div className={styles.analyticsLoggerHeaderWrapper}>
      <div className={styles.sourceTab}>
        {activePage === "Logger" && (
          <div className={styles.loggerRadioButton}>
            <div id="reportRadio">
              <RadioButton
                changed={radioChangeHandler}
                id="1"
                isSelected={currentPage === "Report"}
                label="Report"
                value="Report"
              />
            </div>
            <div>
              <RadioButton
                changed={radioChangeHandler}
                id="2"
                isSelected={currentPage === "Transcript"}
                label="Transcript"
                value="Transcript"
              />
            </div>
          </div>
        )}
        {
          <div
            className={styles.datearea}
            onClick={() => {
              // console.log("calender clidked on date");
              setTimeout(() => {
                dispatch(setSelectedIcon("Calendar"));
                dispatch(setShowRightSideBar(true));
              }, 100);
            }}
          >
            <p className={styles.dateArea}>
              {getDayInCorrectFormat(currentSelectedDate)}
            </p>
          </div>
        }
      </div>
      <div className={styles.subHeaderPart2}>
        {showChannels?.length && showTabs?.length ? (
          <SubModuleHeader showChannels={showChannels} showTabs={showTabs} />
        ) : null}
      </div>
    </div>
  );
}
