import React, { useEffect, useRef, useState } from "react";
import "./ToggleButton.scss";

interface props {
  options: string[];
  getSelectedData: (active: string) => void;
  img1?: any[];
  img2?: any[];
  outSideSelected?: string;
  extraClass?: string;
  updateSuccess?: boolean;
  alwaysTakeOutSideValue?: boolean;
}

function ToggleButton(props: props) {
  const { options, getSelectedData, img1, img2, outSideSelected } = props;
  const [active, setActive] = useState(
    (props.alwaysTakeOutSideValue
      ? outSideSelected
      : outSideSelected || options[0]) as string
  );

  const setActiveButton = (data: string) => {
    getSelectedData(data);
    setActive(data);
  };

  useEffect(() => {
    setActive(outSideSelected as string);
  }, [outSideSelected]);

  return (
    <div className={`toggleButton-wrapper__agent ${props.extraClass} `}>
      <div className="toggleButtonOuterDiv">
        <div
          className={`option1 ${
            active === options[0] ? "activeDiv" : "notActiveDiv"
          }`}
          onClick={() => setActiveButton(options[0])}
        >
          {img1 && (
            <img
              src={img1 ? (active === options[0] ? img1[1] : img1[0]) : ""}
              className="imgCampaignTesting"
            ></img>
          )}
          <p> {options[0]}</p>
        </div>
        <div
          className={`option2 ${
            active === options[1] ? "activeDiv" : "notActiveDiv"
          }`}
          onClick={() => setActiveButton(options[1])}
        >
          {img2 && (
            <img
              src={img2 ? (active === options[1] ? img2[1] : img2[0]) : ""}
              alt="img"
              className="imgCampaignTesting"
            ></img>
          )}
          <p> {options[1]}</p>
        </div>
      </div>
    </div>
  );
}

export default ToggleButton;
