import React, { useCallback, useState, useEffect } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import { useDispatch, useSelector } from "react-redux";

function CRMDonutChart(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  let barWidth = parseInt(props.chartDetails?.chartStyle?.width)-40;
  let barHeight = parseInt(props.chartDetails?.chartStyle?.height) - 100;
//   useEffect(() => {
//     if (props.donutChart && props.data) {
//       let temp = props.donutChart.map((each, i) => {
//         return {
//           name: each.shortKey,
//           value: props.data[each?.referenceKeyName],
//           fill: props.data[each?.referenceKeyName] == 0 ? null : each.fillColor,
//         };
//       });
//       setData(temp);
//     }
//   }, [props.donutChart, props.data]);

//   useEffect(() => {
//     if (props.data && props.donutChart) {
//       let temp = 0;
//       for (let val of props.donutChart) {
//         temp = temp + props?.data[val?.referenceKeyName];
//       }
//       setTotal(temp);
//     }
//   }, [props.data, props.donutChart]);

useEffect(() => {
        if (props.data) {
          let temp = props.data.map((each, i) => {
            return {
              name: each.name,
              value: each.value,
              fill: each.fillColor,
            };
          });
          setData(temp);
        }
      }, [props.data]);

      useEffect(() => {
            if (props.data) {
              let temp = 0;
              for (let val of props.data) {
                temp = temp + val.value;
              }
              setTotal(temp);
            }
          }, [props.data]);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
      name,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return parseInt(value) === 0 || value === "" ? (
      ""
    ) : (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill="ffffff">
          {`${total}`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        {value ? (
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        ) : (
          ""
        )}
        {
          <>
            
              <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#333"
                style={{
                  textTransform: "capitalize",
                  fontSize: "10px",
                  fontWeight: "500"
                }}
              >{`${value}(${(percent * 100).toFixed(2)}%)`}</text>
           
          </>
        }
      </g>
    );
  };

 
  return (
    <>
      <PieChart
        width={barWidth}
        height={barHeight}
      >
        <Pie
          // activeIndex={activeIndex}
          label={renderActiveShape}
          data={data}
          cx={"45%"}
          cy="50%"
          innerRadius={55}
          // {props.chartDetails?.expand?.isExpanded ? 120 : props.chartDetails?.chartStyle?.innerRadius}
          outerRadius={80}
          // {props.chartDetails?.expand?.isExpanded ? 180 : props.chartDetails?.chartStyle?.outerRadius}
          fill="#0066FF"
          dataKey="value"
          labelLine={true}
          isAnimationActive={false}
          paddingAngle={0}
          minAngle={2}
          
        />
      </PieChart>
    </>
  );
}

export default CRMDonutChart;
