import {
  AGENT_DESKTOP_API,
  config,
  CONVERSATION_URL,
  SERVER_URL2,
} from "../../../../services/ApiRoutes";
import { put, call } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import screenType from "../../../../constants/screenType";
import { toast } from "react-toastify";
import { getChatStatus } from "../interface";
import axios, { AxiosPromise } from "axios";

export function* getCallLoggerSummaryWorker(action: any): any {
  try {
    const result = yield call(
      config.GET_WITH_PARAMS,
      `${SERVER_URL2}${CONVERSATION_URL.SUMMARY_URL}`,
      {
        conversationId: action.payload,
      }
    );
    yield put({
      type: actionTypes.GET_SUMMARY_SUCCESS_AGENT_DESKTOP_CHAT,
      payload: result.data.data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_SUMMARY_FAILURE_AGENT_DESKTOP_CHAT,
      payload: error,
    });
  }
}

export function* getCallLoggerTranscriptWorker(action: any): any {
  try {
    const id = action.payload; // this is the id for  call summary inside here to fetch
    const url = `${SERVER_URL2}${CONVERSATION_URL.MESSAGE_URL}`;
    const result = yield call(config.GET_WITH_PARAMS, url, {
      conversationId: id,
    });
    yield put({
      type: actionTypes.GET_TRANSCRIPT_SUCCESS_AGENT_DESKTOP_CHAT,
      payload: result.data.data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_TRANSCRIPT_FAILURE_AGENT_DESKTOP_CHAT,
      payload: error,
    });
  }
}

let cancelTokenRD: any;
export function* getSessionID(action: any): any {
  if (typeof cancelTokenRD != typeof undefined) {
    cancelTokenRD.cancel("Operation canceled due to new request.");
  }
  cancelTokenRD = axios.CancelToken.source();
  try {
    const url = `${AGENT_DESKTOP_API.GET_SESSION_ID_CHAT}`;
    const result = yield call(
      config.POST_WITH_CANCEL_TOKEN,
      url,
      action.payload,
      cancelTokenRD
    );
    const modifiedData = addRecentSessions(result.data.data);
    yield put({
      type: actionTypes.GET_SESSION_ID_SUCCESS_AGENT_DESKTOP_CHAT,
      payload: modifiedData,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_SESSION_ID_FAILURE_AGENT_DESKTOP_CHAT,
      payload: error,
    });
  }
}
export function* getStatusForChat(action: any): any {
  try {
    const url = `${SERVER_URL2}${CONVERSATION_URL.FETCH_STATUS}`;

    const result = yield call(config.POST, url, action.payload);
    yield put({
      type: actionTypes.GET_STATUS_OF_CHAT_AGENT_DESKTOP_API_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_STATUS_OF_CHAT_AGENT_DESKTOP_API_FAILURE,
      payload: error,
    });
  }
}

export function* updateSummaryWorker(action: any): any {
  const body = JSON.parse(JSON.stringify(action.payload.data));
  if (action?.payload?.chatObjective) {
    body["chatObjective"] = action?.payload?.chatObjective;
  }
  const apiBody = { id: action.payload.summaryId, information: body };
  try {
    yield call(
      config.POST_WITH_BODY,
      `${AGENT_DESKTOP_API.UPDATE_SUMMARY}`,
      apiBody
    );
    yield put({ type: actionTypes.UPDATE_SUMMARY_CHAT_SUCCESS_AGENT_DESKTOP });
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.UPDATE_SUMMARY_CHAT_FAILURE_AGENT_DESKTOP,
      payload: error,
    });
    toast.error(new String(error));
  }
}
/**
 * what
 * @param action
 */
export function* getStatusForWhatsAppChatWorker(action: {
  type: any;
  payload: getChatStatus[];
}): any {
  try {
    const apiCall: AxiosPromise<any>[] = [];
    for (const eachId of action.payload) {
      apiCall.push(
        config.POST_WITH_BODY(
          AGENT_DESKTOP_API.GET_WHATSAPP_MESSAGE_STATUS,
          eachId
        )
      );
    }
    const results = yield Promise.all(apiCall);
    yield call(
      config.POST_WITH_BODY,
      AGENT_DESKTOP_API.GET_WHATSAPP_MESSAGE_STATUS
    );
  } catch (error) {
    console.error(error);
  }
}
export function* getCannedMessage(action: {
  type: string;
  payload: string;
}): any {
  try {
    const result: any = yield call(
      config.GET_WITH_PARAMS,
      AGENT_DESKTOP_API.GET_CANNED_MESSAGES,
      { messageKey: action.payload }
    );
    yield put({
      type: actionTypes.GET_CANNED_MESSAGES_API_SUCCESS,
      payload: result.data.data,
    });
    // console.log(result, "canned message");
  } catch (err) {
    yield put({
      type: actionTypes.GET_CANNED_MESSAGES_API_FAILURE,
      payload: err,
    });
    console.error(err);
  }
}
/* helper functions */
const addRecentSessions = (data: Record<string, any>) => {
  data.results.forEach((phone: Record<string, any>) => {
    phone["recentSessionId"] = findMostRecent(phone.sessionIds);
  });
  return data;
};

const findMostRecent = (sessionIds: Record<string, any>) => {
  let recent = sessionIds[0];
  sessionIds.forEach((session: Record<string, any>) => {
    if (session?.createdAt > recent?.createdAt) {
      recent = session;
    }
  });
  return recent;
};
