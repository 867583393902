import { takeLatest } from "redux-saga/effects";
import { orderSummaryActionTypes } from "../orderSummary.actionTypes";
import {
generateOrderIdAPIWorker,
verifyOrderPaymentAPIWorker,
postApplyDiscountCouponCodeAPIWorker,
maiPaymentAPIWorker,
getMaiaPaymentStatusAPIWorker

} from "./orderSummary.workerSaga";

export default function* orderSummaryWatcher(): any {
  yield takeLatest(
    orderSummaryActionTypes.GENERATE_ORDERID_REQUEST,
    generateOrderIdAPIWorker
  );
  yield takeLatest(
    orderSummaryActionTypes.VERIFY_ORDERID_REQUEST,
    verifyOrderPaymentAPIWorker
  );
  yield takeLatest(
    orderSummaryActionTypes.APPLY_COUPON_CODE_REQUEST,
    postApplyDiscountCouponCodeAPIWorker
  );
  yield takeLatest(
    orderSummaryActionTypes.MAIA_PAYMENT_REQUEST,
    maiPaymentAPIWorker
  );
  yield takeLatest(
    orderSummaryActionTypes.GET_MAIA_PAYMENT_STATUS_REQUEST,
    getMaiaPaymentStatusAPIWorker
  );
  
}