import React from "react";
import { Alert, Space } from "antd";
import styles from "./TopBanner.module.scss";

interface TopBannerProps {
  message: string;
  onClickClose: () => void;
}

export const TopBanner: React.FC<TopBannerProps> = ({
  message,
  onClickClose,
}) => (
  <Space direction="vertical" style={{ width: "100%" }}>
    <Alert
      description={message}
      type="error"
      closable
      onClose={onClickClose}
      className={styles.customAlert}
    />
  </Space>
);
