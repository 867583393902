import React from "react";
import styles from "./InvoiceHeader.module.scss";
import Icon from "../../../../../generic/icon/Icon";
import { filterInvoiceIcon } from "../../../../../../theme/assets/profileSectionSvg/paymentSvg/index";

export const InvoiceHeader = ({ onSortToggle }: any) => {
  return (
    <div className={styles.invoiceHeaderWrapper}>
      <div
        className={`${styles.invoiceHeaderColM}  ${styles.invoiceHeaderCol}`}
      >
        <div className={styles.columnHeaderStyling}>Invoice ID</div>
        {/* <Icon img_src={filterInvoiceIcon} extraClass={styles.iconStyling} /> */}
      </div>

      <div
        className={`${styles.invoiceHeaderColL}  ${styles.invoiceHeaderCol}`}
      >
        <div className={styles.columnHeaderStyling}>Plans &amp; Ad-Ons</div>
        {/* <Icon img_src={filterInvoiceIcon} extraClass={styles.iconStyling} /> */}
      </div>
      <div
        className={`${styles.invoiceHeaderColM}  ${styles.invoiceHeaderCol}`}
      >
        <div className={styles.columnHeaderStyling}>Amount</div>
        <Icon
          img_src={filterInvoiceIcon}
          extraClass={styles.iconStyling}
          onClick={() => {
            onSortToggle("price");
          }}
        />
      </div>
      <div
        className={`${styles.invoiceHeaderColM}  ${styles.invoiceHeaderCol}`}
      >
        <div className={styles.columnHeaderStyling}>Start Date</div>
        <Icon
          img_src={filterInvoiceIcon}
          extraClass={styles.iconStyling}
          onClick={() => {
            onSortToggle("planStartDate"); // Pass the desired keyName to the parent component
          }}
        />
      </div>
      <div
        className={`${styles.invoiceHeaderColM}  ${styles.invoiceHeaderCol}`}
      >
        <div className={styles.columnHeaderStyling}>Due Date</div>
        <Icon
          img_src={filterInvoiceIcon}
          extraClass={styles.iconStyling}
          onClick={() => {
            onSortToggle("planExpireDate");
          }}
        />
      </div>
      <div
        className={`${styles.invoiceHeaderColM}  ${styles.invoiceHeaderCol}`}
      >
        <div className={styles.columnHeaderStyling}>Action</div>
      </div>
    </div>
  );
};
