import React, { ReactNode, useEffect, useState } from "react";
import PageToggler from "../../../../components/generic/pageToggler/PageToggler";
import { useSelector, useDispatch } from "react-redux";
import profileConfig from "../../Profile.config";
import { RootState } from "../../../../redux";
import { getCurrentTab } from "../../../../redux/profile/yourAccount/organizationDetails/actions/organizationDetails.actions";
import styles from "./BillingInvoiceScreen.module.scss";
import { InvoiceTab } from "../../../../components/moduleComponents/paymentModuleComponents/invoiceTab/InvoiceTab";
import { MyPlan } from "../../../../components/moduleComponents/paymentModuleComponents/myPlan/MyPlan";

export const BillingInvoiceScreen = () => {
  const tabs = useSelector(
    (state: RootState) =>
      state.profile.navigationReducer.leftSideNavigation.data
  );

  const selectedTab = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization?.selectedProfileTab
  );

  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState<string>("");
  const [tab, setTab] = useState<{ name: string; element: ReactNode }[]>([]);

  useEffect(() => {
    if (selectedTab === "My Plan") {
      setCurrentTab(selectedTab);
      dispatch(getCurrentTab("My Plan"));
    } else if (tab.length > 0) {
      setCurrentTab(tab[0].name);
    }
  }, [tab, selectedTab]);

  useEffect(() => {
    if (selectedTab !== "My Plan") {
      dispatch(getCurrentTab(""));
    }
  }, []);

  useEffect(() => {
    if (tabs && tabs.length > 0) {
      const settings = tabs?.filter((e: any) => {
        return e.name === profileConfig.billing.name;
      });
      const modules = settings[0]?.moduleTabs.flatMap((e: any) => {
        if (e.isVisible) {
          switch (e.name) {
            case profileConfig.billing.subModule.invoices.name:
              return [{ name: e.name, element: <InvoiceTab /> }];
            case profileConfig.billing.subModule.pricingPlan.name:
              return [{ name: e.name, element: <MyPlan /> }];
            default:
              return [{ name: e.name, element: <></> }];
          }
        }
        return [];
      });
      setTab(modules ? modules : []);
    }
  }, [tabs]);

  return (
    <div className={styles.billingInvoiceScreenWrapper}>
      <div className={styles.billingScreenHeader}>
        <div className={styles.billingScreenHeaderText}>
          Billing and Invoice
        </div>
      </div>

      <PageToggler
        current={currentTab}
        innerComponents={tab}
        tabStyling={styles.billingScreenTabWrapper}
        extraHeaderStyling={styles.billingScreenTabHeader}
        extraTextStyling={styles.billingScreenText}
        switchTab={(e: string) => {
          setCurrentTab(e);
        }}
        blueStyles={true}
      />
    </div>
  );
};
