import React from "react";
import "./ProgressBarComponent.css";

const SingleProgressBar = (props) => {
  const {
    isLoading = props.percent === "loading",
    percent,
    size = "small",
    showInfo = false,
    backgroundColor
  } = props;

  return (
    <>
      <div className="progress-outer">
        <div
          className={`progress ${size ? "progress--" + size : ""} ${
            isLoading ? "progress--" + "loading" : ""
          } ${props.extraProgress ? props.extraProgress : ""}`}
        >
          <div
            className={`${
              props.extraClass ? props.extraClass : "progress-bar"
            }`}
            style={{ width: percent + "%", background: `${backgroundColor ? backgroundColor : ""}`  }}
          ></div>
        </div>

        {isLoading == false && showInfo ? (
          <span className="progress-info">{percent}%</span>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default SingleProgressBar;
