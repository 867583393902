// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rnEQhuvT3eT0lYR52cp2{margin-left:18%;margin-right:18%}`, "",{"version":3,"sources":["webpack://./src/screens/profile/yourAccount/YourAccountScreen.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,gBAAA","sourcesContent":[".accountsTabHeader {\n  margin-left: 18%;\n  margin-right: 18%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accountsTabHeader": `rnEQhuvT3eT0lYR52cp2`
};
export default ___CSS_LOADER_EXPORT___;
