import React, { useEffect } from "react";
import styles from "./MyPlan.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { AddOns } from "./addOnsComponent/AddOns";
import { PlanDetails } from "./planDetails/PlanDetails";

export const MyPlan = () => {
  const orderSummaryData = useSelector(
    (state: RootState) => state.profile?.billingReducer?.orderSummary
  );

  const selectedPlanDetails: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  return (
    // <div className={styles.myPlanWrapper}>
    <>
      {selectedPlanDetails && Object.keys(selectedPlanDetails).length > 0 ? (
        <>
          <PlanDetails
            planData={selectedPlanDetails}
            orderSummary={orderSummaryData}
          />
          {!selectedPlanDetails?.planName?.includes("Trial") && (
            <AddOns
              planData={selectedPlanDetails}
              orderSummary={orderSummaryData}
            />
          )}
        </>
      ) : (
        ""
      )}
    </>
    // </div>
  );
};
