// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i50oLoRXp2j6K_mrKumc{display:flex;align-items:center;justify-content:space-between;border:none;border-radius:0px;height:auto;padding:.5em 1em;background-color:#fbeaec !important;color:#db303f;font-size:12px;font-weight:400;font-family:"Roboto";box-shadow:0px 1.89924px 2.84886px rgba(0,0,0,.1)}.i50oLoRXp2j6K_mrKumc .TOiQlCBdhdm2jzhs5Ja4{margin-left:1em}`, "",{"version":3,"sources":["webpack://./src/components/generic/topBanner/TopBanner.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,WAAA,CACA,iBAAA,CACA,WAAA,CACA,gBAAA,CACA,mCAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CACA,oBAAA,CACA,iDAAA,CAGF,4CACE,eAAA","sourcesContent":[".customAlert {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border: none;\n  border-radius: 0px;\n  height: auto;\n  padding: 0.5em 1em;\n  background-color: #fbeaec !important;\n  color: #db303f;\n  font-size: 12px;\n  font-weight: 400;\n  font-family: \"Roboto\";\n  box-shadow: 0px 1.89924px 2.84886px rgba(0, 0, 0, 0.1);\n}\n\n.customAlert .ant-alert-close-icon {\n  margin-left: 1em; /* Adjust the spacing as needed */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customAlert": `i50oLoRXp2j6K_mrKumc`,
	"ant-alert-close-icon": `TOiQlCBdhdm2jzhs5Ja4`
};
export default ___CSS_LOADER_EXPORT___;
