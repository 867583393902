import React,{useState} from 'react'
import India from "@svg-maps/india";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import "./IndiaMap.css";

export default function IndiaMap(props) {
    const [state,setSelectedState]= useState("")
    const [dataObj,setDataObj]=useState({
        "mp":{
            stateName:"Madhya Pradesh",
            amount:"876543",
            connectionRate:"60%",
            activeCustomer:"76543"
        },
        "ap":{
            stateName:"Andhra Pradesh",
            amount:"876541",
            connectionRate:"40%",
            activeCustomer:"54675"
        },
        "ar":{
            stateName:"Arunachal Pradesh",
            amount:"876540",
            connectionRate:"80%",
            activeCustomer:"54635"
        },
        "as":{
            stateName:"Assam",
            amount:"876539",
            connectionRate:"70%",
            activeCustomer:"54635"
        },
        "br":{
            stateName:"Bihar",
            amount:"342",
            connectionRate:"65%",
            activeCustomer:"24267"
        },
        "ch":{
            stateName:"Chandigarh",
            amount:"4323",
            connectionRate:"67%",
            activeCustomer:"20"
        },
        "ct":{
            stateName:"Chhattisgarh",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "dn":{
            stateName:"Dadra and Nagar Haveli",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "ga":{
            stateName:"Goa",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "dl":{
        stateName:"Delhi",
        amount:"78903",
        connectionRate:"89%",
        activeCustomer:"1890"
        },
        "gj":{
            stateName:"Gujarat",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "hr":{
            stateName:"Haryana",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "hp":{
            stateName:"Himachal Pradesh",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "jk":{
            stateName:"Jammu and Kashmir",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "jh":{
            stateName:"Jharkhand",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "ka": {
            stateName:"Karnataka",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "kl":{
            stateName:"Kerala",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "ld":{
            stateName:"Lakshadweep",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "mh":{
            stateName:"Maharashtra",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "mn":{
            stateName:"Manipur",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "ml":{
            stateName:"Meghalaya",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "mz":{
            stateName:"Mizoram",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "nl":{
            stateName:"Nagaland",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "or":{
            stateName:"Odisha",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "py":{
            stateName:"Puducherry",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "pb":{
            stateName:"Punjab",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "rj":{
            stateName:"Rajasthan",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "sk":{
            stateName:"Sikkim",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "tn":{
            stateName:"Tamil Nadu",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "tg":{
            stateName:"Telangana",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "tr":{
            stateName:"Tripura",
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "up":{
            stateName:'Uttar Pradesh',
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "ut":{
            stateName:'Uttarakhand',
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        },
        "wb":{
            stateName:'West Bengal',
            amount:"78903",
            connectionRate:"89%",
            activeCustomer:"1890"
        }

    })
   const renderIndiaMap = () => {

        const onLocationMouseOver = (event) => {
            var decide = event.target;
          const clickedState = event.target.id.toUpperCase();
          setSelectedState(clickedState)
          let stateData= dataObj[clickedState.toLowerCase()]
          console.log(stateData,"stDat")
          props.sendSelectedState(stateData)
        //   this.setState({ stateCode: clickedState }, this.componentDidMount);
        };

        const onLocationBlur=()=>{
            setSelectedState("")
        }

        return (
          <div className='Map'>
            <SVGMap
            map={India}
            onLocationMouseOver={onLocationMouseOver}
            onLocationMouseOut={onLocationBlur}
            onLocationBlur={onLocationBlur}
            isLocationSelected={(event)=>{
                console.log(event,"loop")
                    return state&& event.id==state
            }}
            />
          </div>
        );
      };

  return (
    <div className="india-map-container">{renderIndiaMap()}</div>
  )
}
