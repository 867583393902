import React from "react";
import { Navigate, Outlet } from "react-router-dom";

interface propsInterface {
  isAuthorized: boolean;
  redirect?: string;
  children?: any;
}

const ProtectedRoute = (props: propsInterface) => {
  if (!props.isAuthorized) return <Navigate to={props.redirect || "/404"} />;

  return props.children ? props.children : <Outlet />;
};

export default ProtectedRoute;
