import { Reducer } from "redux";
import actionTypes from "../inviteUser.actionTypes";

export interface stateInterface {
  userDetails: any;
  isLoadingSendInvite: boolean;
  sendInviteError: any;
}

export const initialState: stateInterface = {
  userDetails: {},
  isLoadingSendInvite: false,
  sendInviteError: {},
};

const inviteUserReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.SEND_INVITE_USER_REQUEST: {
      return {
        ...state,
        userDetails: {},
        isLoadingSendInvite: true,
        sendInviteError: {},
      };
    }
    case actionTypes.SEND_INVITE_USER_SUCCESS: {
      return {
        ...state,
        userDetails: action.payload,
        isLoadingSendInvite: false,
        sendInviteError: {},
      };
    }
    case actionTypes.SEND_INVITE_USER_FAILURE: {
      return {
        ...state,
        userDetails: {},
        isLoadingSendInvite: false,
        sendInviteError: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default inviteUserReducer;
