import React, { useEffect, useState } from "react";
import styles from "./AddonComponent.module.scss";
import { SingleSelectDD } from "../../../agentDesktop/generic/fields";
import { deleteIcon } from "../../../../theme/assets/genericSvg/index";
import moment from "moment";
import AddButton from "../../../generic/addButton/AddButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import {
  setSelectedAddons,
  setSelectedDuration,
} from "../../../../redux/profile/billing/orderSummary/action/orderSummary.action";
import { toast } from "react-toastify";

type Props = {
  addon: any;
  onClickDelete: () => void;
  data: any;
};

const AddonComponent = (props: Props) => {
  const dispatch = useDispatch();

  const planData: any = useSelector(
    (store: RootState) =>
      store?.profile?.billingReducer?.myPlanReducer?.myPlanDetails?.data
  );

  const [month, setMonth] = useState("3");
  const [date, setDate] = useState();
  const [addons, setAddons] = useState<any>([]);

  const handleOnChange = (values: any) => {
    const value = values?.split(" ")[0];
    setMonth(values);
    const currentDate = moment();
    const futureDate = currentDate.add(value, "months");
    setDate(futureDate.format("DD MMM YYYY"));
  };

  useEffect(() => {
    const currentDate = moment();
    const futureDate = currentDate.add(3, "months");
    setDate(futureDate.format("DD MMM YYYY"));
  }, []);

  const convertToRupees = (number: string) => {
    const rupees = parseInt(number).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    const formattedRupees = rupees; // Remove decimal part if present
    return formattedRupees.replace("₹", "");
  };

  const handleDeleteButton = () => {
    // navigate()
  };

  const handleAddons = (count: number, value: any) => {
    console.log("hh", count, value, planData);
    if (
      value?.valueName === "Data Access" &&
      count > planData?.limitOnDataAccess &&
      count !== 0
    ) {
      toast.error("You can't add data access more than plan limit");
      return;
    }
    if (
      value?.valueName === "Additional User Licenses" &&
      count > planData?.limitOnUserLicenses &&
      count !== 0
    ) {
      toast.error("You can't add user licenses more than plan limit");
      return;
    }
    if (count > 0) {
      const temp = [...addons];
      const existingIndex = temp.findIndex(
        (addon) => addon.valueName === value.valueName
      );

      if (existingIndex !== -1) {
        temp[existingIndex].quantity = count;
      } else {
        const data = {
          valueName: value.valueName,
          price: value.price,
          quantity: count,
          valueAddonID: value.id,
        };
        temp.push(data);
      }
      setAddons(temp);
      dispatch(setSelectedAddons(temp));
    } else {
      const temp = addons.filter(
        (addon: { valueName: any }) => addon.valueName !== value.valueName
      );
      setAddons(temp);
      dispatch(setSelectedAddons(temp));
    }
  };

  useEffect(() => {
    if (planData?.duration) {
      const planDuration = planData?.duration.replace(/months|month/gi, "");
      setMonth(planData?.duration);
    }
  }, [planData?.duration]);

  useEffect(() => {
    if (month) {
      dispatch(setSelectedDuration(month));
    }
  }, [month]);

  return (
    <div className={styles.addonPlanWrapper}>
      <div className={styles.planDetailsClass}>
        <div className={styles.planNameClass}>ADD Ons</div>
      </div>
      <div className={styles.planExpireDateClass}>
        <div className={styles.dropdownClass}>
          <div className={styles.addonPlanName}>{props?.data?.planName}</div>
          <div className={styles.extraDropdownClass}>
            {month} {Number(month) === 1 ? "Month" : "Months"}
          </div>
          {/* <SingleSelectDD
            data={planDurationsData}
            handleChange={handleOnChange}
            hideSearchBox={true}
            selectedItem={month}
            title={""}
            extraWrapperClass={styles.extraDropdownWrapper}
            extraDropdownClass={styles.extraDropdownClass}
          /> */}
        </div>

        <div className={styles.deleteImageClass} onClick={handleDeleteButton}>
          <img
            src={deleteIcon}
            onClick={() => {
              props.onClickDelete();
            }}
          />
        </div>
      </div>
      <hr className={styles.dashedLine} />
      <div className={styles.addonClass}>
        <p className={styles.addonTitle}>Add ons</p>
        {props.addon &&
          props.addon?.map((each: any, i: number) => {
            return (
              <div key={i}>
                <div className={styles.addonDetails}>
                  <div className={styles.addonValueDetails}>
                    <p className={styles.valueNameClass}>{each?.valueName}</p>
                    <p className={styles.valueEntityClass}>
                      {props?.data?.currency} <span>{each?.price}</span>/
                      <span>{each?.addonType}</span>
                      <span>{each?.duration === "" ? "" : "/"}</span>
                      <span>{each?.duration?.toLowerCase()}</span>
                    </p>
                  </div>
                  <div>
                    <AddButton
                      text={"Get it"}
                      onCountChange={(e) => handleAddons(e, each)}
                      // getCount={each.quantity ? each.quantity : 0}
                    />
                  </div>
                </div>
                <hr className={styles.dashedLine} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AddonComponent;
