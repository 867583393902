import React, { useState, useEffect } from "react";
import styles from "./StrategyModalPopUp.module.scss";
import Modal from "../../../generic/modal/Modal";
import Button from "../../../generic/button/Button";
import { useNavigate } from "react-router-dom";
import { createStrategyCloseIcon } from "../../../../theme/assets/svg/strategySvg";
import Icon from "../../uiKit/iconComponent/Icon";
import { doubleTickIcon } from "../../../../theme/assets/svg/campaign_v3/index";
import { deleteBig } from "../../../../theme/assets/genericSvg";

interface StrategyModalProps {
  show?: boolean;
  close?: CallableFunction;
  name?: string;
  deleteModal?: boolean;
  userPersonaModal?: boolean;
  onclick?: CallableFunction;
  noOfUserPersonasSelected?: number;
  isLoading?: boolean;
}

export const StrategyModalPopUp = ({
  show,
  close,
  name,
  deleteModal,
  userPersonaModal,
  onclick,
  noOfUserPersonasSelected,
  isLoading,
}: StrategyModalProps) => {
  return (
    <div>
      <Modal
        shown={show}
        close={() => {
          close && close();
        }}
        extraClassStyleModalContent={
          userPersonaModal
            ? styles.personaModalStyling
            : styles.deleteStrategyModalStyling
        }
      >
        {/* {!userPersonaModal ? (
          <div
            className={styles.modalInfoDiv}
            id="auto_strategy_modal_div"
            data-testid="strategy-modal"
          >
            <div className={styles.modalCloseIcon}>
              <img
                src={createStrategyCloseIcon}
                onClick={() => {
                  close && close();
                }}
                className={styles.iconStyling}
                id="auto_strategy_modal_close_icon"
              />
            </div>
          </div>
        ) : null} */}
        {deleteModal ? (
          <div
            className={styles.modalInfoHeading}
            data-testid="delete-modal"
            id="auto_strategy_delete_modal"
          >
            <img src={deleteBig} alt="" />
            <span className={styles.message_delete}>
              <span>Are you sure you want to Delete</span>
              <span> {name}?</span>
            </span>
          </div>
        ) : userPersonaModal ? (
          <div
            className={styles.userPersonaDiv}
            data-testid="persona-modal"
            id="auto_strategy_persona_modal_div"
          >
            <div className={styles.userPersonaTopDiv}>
              <div className={styles.userPersonaIconDiv}>
                <Icon
                  img_src={doubleTickIcon}
                  alt="tickIcon"
                  extraClass={styles.personIconStyling}
                />
              </div>
              <div className={styles.userPersonaInfoDiv}>
                <div
                  id="auto_strategy_persona_modal_text"
                  className={styles.userPersonaTextDiv}
                >
                  {noOfUserPersonasSelected} Unique User Personas identified as
                  per your data.
                </div>
                <div
                  className={styles.userPersonaSubTextDiv}
                  id="auto_strategy_persona_modal"
                >
                  Do you really want to proceed?
                </div>
              </div>
            </div>
            <div className={styles.userPersonaBtmDiv}>
              <div className={styles.cancelBtn}>
                <Button
                  text="Cancel"
                  extraClass={`${styles.buttonStyling} ${styles.cancelBtnStyling}`}
                  onClick={() => {
                    close && close();
                  }}
                  id="auto_strategy_close_button"
                  // disabled={deleteModal && isLoading ? true : false}
                />
              </div>
              <div className={styles.yesBtn}>
                <Button
                  text="Yes"
                  extraClass={`${styles.buttonStyling} ${styles.yesButtonStyling}`}
                  onClick={() => {
                    onclick && onclick();
                    close && close();
                  }}
                  id="auto_strategy_yes_button"
                  // disabled={deleteModal && isLoading ? true : false}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={styles.modalInfoHeading}
            data-testid="edit-modal"
            id="auto_strategy_edit_modal"
          >
            {/* Are you sure you want to Edit {name} ? */}
            <span>
              Editing a strategy may impact your existing campaigns, <br />
              are you sure you want to proceed ?
            </span>
          </div>
        )}
        {!userPersonaModal ? (
          <div className={styles.startegyButtonDiv}>
            <Button
              text="Cancel"
              extraClass={`${styles.buttonStyling} ${styles.yesButtonStyle}`}
              onClick={() => {
                close && close();
              }}
              extraStyle={{ backgroundColor: "#F6F6F6", color: "#6E7079" }}
              id="auto_strategy_yes_button"
              // disabled={deleteModal && isLoading ? true : false}
            />
            <Button
              text={deleteModal ? "Delete" : "Yes"}
              extraClass={`${styles.buttonStyling} ${styles.yesButtonStyle}`}
              onClick={() => {
                onclick && onclick();
                close && close();
              }}
              extraStyle={{ background: deleteModal ? "#DB303F" : "" }}
              id="auto_strategy_yes_button"
              // disabled={deleteModal && isLoading ? true : false}
            />
          </div>
        ) : null}
      </Modal>
    </div>
  );
};
