import style from "./ChargeRate.module.scss"

interface props{
    heading : string
    data : Array<object>
 }
function ChargeRate(props : props) {
  return (
    <div className={style.chargeRateWrapper}>
      <div className={style.heading}>{props.heading}</div>
       <div className={style.container}>
           {props.data?.map((obj:any , i :number) => {
              return <div className={style.containerRow} key={i} >
                <span className={style.spanone}>{obj.name}: </span>  
                <span>{obj.value}</span> 
              </div>
           })}
       </div>
     </div>
  )
}

export default ChargeRate