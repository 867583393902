import React, { useState, useEffect } from "react";
import { Tooltip, Legend, FunnelChart, Funnel, LabelList } from "recharts";

import { useDispatch, useSelector } from "react-redux";
import NoDatamodel from "../../../generic/noDatamodel/NoDatamodel";
import { noPhoneDataIcon } from "../../../../theme/assets/svg";

function FunnelChartComponent(props) {
  const [data, setData] = useState();
  const [nodataFound, setNoDataFound] = useState(true);

  let color = ["#2D9CDB", "#F8961E", "#F9C74F", "#90BE6D", "#8CD26E"];
  let funnelWidth = parseInt(props.chartDetails?.chartStyle?.width) - 40;
  let funnelHeight = parseInt(props.chartDetails?.chartStyle?.height) - 80;
// console.log(props.channel,"999")
  //   let refenceKeyForData = props.chartDetails?.refenceKeyForData;

  //   let funnelBarDetails =
  //     props.chartDetails && Object.values(props.chartDetails.keys).length > 0
  //       ? Object.values(
  //           props.chartDetails.keys.sort((a, b) => {
  //             return a.position - b.position;
  //           })
  //         )
  //       : [];

  //   useEffect(() => {
  //     if (funnelBarDetails && props.data) {
  //       let finalData = funnelBarDetails?.map((each, index) => {
  //         return {
  //           name: each.name,
  //           value:
  //             props?.data[refenceKeyForData] &&
  //             props?.data[refenceKeyForData][each?.referenceKeyName],
  //           fill: color[index % color.length],
  //           height: 60,
  //           upperWidth:
  //             expandedChart && expandedChart.includes(props.chartTitle)
  //               ? 500 - index * 80
  //               : 400 - index * 80,
  //           lowerWidth:
  //             expandedChart && expandedChart.includes(props.chartTitle)
  //               ? 420 - index * 80
  //               : 320 - index * 80,
  //           x: 70 + index * 40,
  //         };
  //       });
  //       setData(finalData);
  //     }
  //   }, [props.chartDetails, props.data[refenceKeyForData]]);
  const length = data && data?.length;

  //   useEffect(() => {
  //     props.data && props.data[refenceKeyForData]
  //       ? Object.values(props.data[refenceKeyForData]).every((item) => item === 0)
  //         ? setNoDataFound(true)
  //         : setNoDataFound(false)
  //       : null;
  //   }, [props?.data]);
  useEffect(() => {
    if (props.data) {
      let finalData = props.data?.map((each, index) => {
        return {
          name: each.name,
          value: each.value,
          fill: color[index % color.length],
          height: 57,
          upperWidth: 320 - index * 55,
          lowerWidth: 280 - index * 55,
          x: 40 + index * 28,
        };
      });
      setData(finalData);
    }
  }, [props.data]);

  // const data = [{x: 12, y: 12, upperWidth: 240, lowerWidth: 22, height: 80,}, {x: 12, y: 12, upperWidth: 240, lowerWidth: 22, height: 50,}]
  return (
    <>
      {data ? (
        <div
          style={{
            width: funnelWidth,
            height: funnelHeight,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <FunnelChart width={380} height={65 * length}>
            <Tooltip />
            <Funnel dataKey="value" data={data} isAnimationActive={false}>
              <LabelList
                position="center"
                stroke="none"
                fill="white"
                dataKey="value"
                dy={-10}
                dx={20}
              />
              <LabelList
                position="insideBottom"
                fill="white"
                stroke="none"
                dataKey="name"
                fontSize={10}
                dx={20}
                dy={-10}
                offset={2}
              />
            </Funnel>
          </FunnelChart>
        </div>
      ) : (
        <div
          className="noData"
          style={{
            width: "60%",
            margin: "auto",
          }}
        >
          <NoDatamodel srcImg={noPhoneDataIcon}></NoDatamodel>
        </div>
      )}
    </>
  );
}

export default FunnelChartComponent;
