import React, { useEffect } from "react";
import styles from "./RightsideStrategy.module.scss";
import {
  startegyedit,
  startegysave,
  startegysetting,
} from "../../../theme/assets/svg";
import {
  setSelectedItemOnRight,
  setShowNudgeSetting,
  setShowRightsideAll,
} from "../../../redux/strategy/eachNudgeBox/actions/actionCreators";
import { useDispatch } from "react-redux";
import { RootState } from "../../../redux";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { backIcon } from "../../../theme/assets/svg/strategySvg";
import { setIsScriptSelectVisited } from "../../../redux/strategy/nudgeCreation/actions";
export default function RightsideStrategy() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showRight = useSelector(
    (state: RootState) =>
      state.strategyModuleReducer?.individualNudgeReducer.showRight
  );
  const show = useSelector(
    (state: RootState) =>
      state.strategyModuleReducer?.individualNudgeReducer.show
  );
  const selectedItem = useSelector(
    (state: RootState) =>
      state.strategyModuleReducer?.individualNudgeReducer.itemSelected
  );

  const moduleSelectedScreen = useSelector(
    (store: any) => store?.baseScreen?.leftMenu?.highlightedModule
  );

  const handleRouting = () => {
    if (moduleSelectedScreen !== "Strategy") {
      navigate("/campaignmanager/strategyselection");
    }
    if (moduleSelectedScreen == "Strategy") {
      navigate("/strategy");
    }
    dispatch(setIsScriptSelectVisited(false));
  };

  function handleClickOnICon(name: string) {
    dispatch(setSelectedItemOnRight(name === selectedItem ? "" : name));
    dispatch(setShowRightsideAll(name === selectedItem ? false : true));
  }

  return (
    <div
      className={styles.wrapper_rightss}
    // onClick={(e) => {
    //   e.stopPropagation();
    // }}
    >
      <div
        className={styles.iconlist}
      // onClick={(e) => {
      //   e.stopPropagation();
      // }}
      >
        <img
          onClick={(e) => {
            e.stopPropagation();
            handleClickOnICon("save");
          }}
          src={startegysave}
          alt=""
          id="auto_startegysave"
        />
        <img
          onClick={(e) => {
            e.stopPropagation();
            handleClickOnICon("edit");
          }}
          src={startegyedit}
          alt=""
          id="auto_startegyedit"
        />
        <img onClick={handleRouting} src={backIcon} alt="" id="auto_backIcon" />
        {/* <img
          onClick={() => handleClickOnICon("setting")}
          src={startegysetting}
          alt=""
        /> */}
      </div>
    </div>
  );
}
