const actionTypes = {
  PROFILE_PERSONAL_GET_DETAILS_API_CALL:
    "PROFILE_PERSONAL_GET_DETAILS_API_CALL",
  PROFILE_PERSONAL_GET_DETAILS_API_SUCCESS:
    "PROFILE_PERSONAL_GET_DETAILS_API_SUCCESS",
  PROFILE_PERSONAL_GET_DETAILS_API_FAIL:
    "PROFILE_PERSONAL_GET_DETAILS_API_FAIL",
  UPDATE_PROFILE_PIC: "UPDATE_PROFILE_PIC",
  UPDATE_PROFILE_PIC_FAILURE: "UPDATE_PROFILE_PIC_FAILURE",
  DELETE_PROFILE_PIC: "DELETE_PROFILE_PIC_XXX",
  PROFILE_UPDATE_USERNAME_PHONE: "PROFILE_UPDATE_USERNAME_PHONE",
  USER_LOGOUT: "USER_LOGOUT",
};
export default actionTypes;
//
