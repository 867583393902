import { combineReducers } from "@reduxjs/toolkit";
import strategyReducer from "./strategyCreation/reducer/strategy.reducer";
import nudgeReducer from "./nudgeCreation/reducer/nudge.reducer";
import individualNudgeReducer from "./eachNudgeBox/reducer/individualNudge";

const rootStrategyReducer = combineReducers({
  strategyReducer: strategyReducer,
  nudgeReducer: nudgeReducer,
  individualNudgeReducer: individualNudgeReducer,
});
export default rootStrategyReducer;
