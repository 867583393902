import React, { useReducer, useEffect, useState } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useNavigate } from "react-router-dom";
import { setCurrentPage } from "../../../../redux/logger/loggerState/action";
import {
  setSelectedIcon,
  setShowRightSideBar,
} from "../../../../redux/filters/actions";
import { useDispatch } from "react-redux";
import icon from "../../../../theme/assets/svg/signUpFlow/maiaIconRightBottom.svg";
import iconLeft from "../../../../theme/assets/svg/signUpFlow/maiaIconLeftBottom.svg";
import TourModal from "../tourPopUp/TourModal";
import {
  openFilterswithoutTransition,
  startLoggerTour,
  setStartTourMain,
} from "../../../../redux/signUpFlow/productTour/actions";

const TOUR_STEPS = [
  {
    target: "#rightSideBarPopUp",
    content:
      "Use filters to bucket individual conversations into useful segments, for viewing and future actions.",
    disableBeacon: true, // This makes the tour to start automatically without clicking
    name: "abhishek",
    placement: "left",
    styleMiaa: {
      position: "absolute",
      left: "-130px",
      bottom: "-184px",
    },
    img: iconLeft,
  },
  {
    target: "#reportRadio",
    content:
      'You can switch to the "Report" view to see the Logger report for your campaigns holistically.',
    // disableBeacon: true,
    placement: "left-start",
    styleMiaa: {
      position: "absolute",
      right: "-154px",
      bottom: "-174px",
    },
    img: icon,
    spotlightPadding: 8,
  },
  {
    target: "#auto_left_Analytics",
    content:
      "View crucial performance metrics of campaigns, payments and user behavior from the Insights module to make better business decisions. ",
    placement: "right-start",
    styleMiaa: {
      position: "absolute",
      right: "-154px",
      bottom: "-174px",
    },
    img: icon,
    //disableBeacon: true // This makes the tour to start automatically without clicking
  },
];

const INITIAL_STATE = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};
// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "CLOSE_START":
      return { ...state, ...action.payload };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};
const TourToolTip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}: any) => {
  return (
    <TourModal
      tooltipProps={tooltipProps}
      step={step}
      primaryProps={primaryProps}
    />
  );
};
// Tour component
const TourLogger = (props: any) => {
  // Tour state is the state which control the JoyRide component
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [start, setStartTour] = useState(false);
  const dispatchRedux = useDispatch();
  const navigation = useNavigate();
  useEffect(() => {
    // Auto start the tour if the tour is not viewed before
    if (!localStorage.getItem("tour")) {
      dispatch({ type: "START" });
    }
    dispatchRedux(setSelectedIcon("Filter"));
    dispatchRedux(setShowRightSideBar(true));
    setStartTour(true);
    dispatchRedux(openFilterswithoutTransition(true));
    dispatchRedux(setStartTourMain(true));
    console.log("use");
  }, []);
  console.log("rebder");
  // Set once tour is viewed, skipped or closed
  const setTourViewed = () => {
    // localStorage.setItem("tour", "1");
  };

  const callback = (data: any) => {
    const { action, index, type, status } = data;

    console.log("jj", data, index);
    // if(index ==1){
    //   dispatchRedux(setCurrentPage("Report"));
    // }
    if (index == 0) {
      dispatchRedux(setSelectedIcon("Filter"));
      dispatchRedux(setShowRightSideBar(true));
      setTimeout(() => {
        console.log("abhi");
      }, 1000);
      // navigation("/dashboard")
    }
    if (index == 1) {
      dispatchRedux(setSelectedIcon(""));
      dispatchRedux(setShowRightSideBar(false));
      // navigation("/dashboard")
    }

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: "STOP" });
      dispatchRedux(openFilterswithoutTransition(false));
      dispatchRedux(startLoggerTour(false));
      dispatchRedux(setStartTourMain(false));
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.
      dispatch({ type: "STOP" });
      setTimeout(() => {
        dispatch({
          type: "CLOSE_START",
          payload: {
            stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
            run: true,
          },
        });
      }, 3000);
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
      if (index === 2) {
        navigation("/dashboard");
        setStartTour(false);
        dispatchRedux(startLoggerTour(false));
        dispatchRedux(setStartTourMain(false));
      }
      if (index == 1) {
        dispatchRedux(setCurrentPage("Report"));
      }
      // dispatch({
      //   type: "NEXT_OR_PREV",
      //   payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
      // });
    }
  };

  const startTour = () => {
    // Start the tour manually
    dispatch({ type: "RESTART" });
  };

  return (
    <>
      {start && (
        <JoyRide
          {...tourState}
          callback={callback}
          showSkipButton={false}
          hideCloseButton
          hideBackButton
          spotlightPadding={0.5}
          tooltipComponent={TourToolTip}
          styles={{
            tooltipContainer: {
              textAlign: "left",
            },
            buttonNext: {
              backgroundColor: "#0174ff",
              width: "75px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              fontSize: "15px",
              border: "1px solid #0174FF",
            },
          }}
          locale={{
            last: "Next",
          }}
        />
      )}
    </>
  );
};

export default TourLogger;
