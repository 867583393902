export const channelData = {
  whatsAppChat: { id: "640984bb395170fe4a8b261c" },
  chat: { id: "64098a4612a7c0017b9c40a7" },
};

export const channelIdBasedOnName = {
  Call: "640989b312a7c0017b9c40a3",
  WhatsApp: "640984bb395170fe4a8b261c",
  SMS: "64098ac712a7c0017b9c40ad",
  Mail: "64098afa12a7c0017b9c40b1",
};
