import actionTypes from "../teamsPage.actionTypes";

export const getAllTeamsData = (payload: object) => {
  return {
    type: actionTypes.GET_ALL_TEAMS_DATA_REQUEST,
    payload: payload,
  };
};

export const getAllRolesData = (payload: object) => {
  return {
    type: actionTypes.GET_ALL_ROLES_DATA_REQUEST,
    payload: payload,
  };
};

export const updateTeamData = (payload: any) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_PORTFOLIO_TEAM_REQUEST,
    payload: payload,
  };
};

export const selectedTeamDetails = (payload: any) => {
  return {
    type: actionTypes.SELECTED_TEAM_DETAILS,
    payload: payload,
  };
};

export const clearTeamsData = () => {
  return {
    type: actionTypes.CLEAR_TEAMS_DATA,
  };
};

export const sendInviteAPIRequest = (
  data: { email: string; role: string }[]
) => {
  return { type: actionTypes.SEND_INVITE_TEAM_SETUP_REQUEST, payload: data };
};

export const getTeamMemberData = (payload: object) => {
  return {
    type: actionTypes.GET_TEAM_MEMBER_DATA_REQUEST,
    payload: payload,
  };
};

export const setIsTeamPageVisited = (data: boolean) => {
  return { type: actionTypes.IS_TEAM_PAGE_VISITED, payload: data };
};

export const updateInviteMemberOnPlan = (payload: any) => {
  return {
    type: actionTypes.UPDATE_INVITE_MEMBER_ON_PLAN_REQUEST,
    payload: payload,
  };
};