export default {
  GET_ALL_TEAMS_DATA_REQUEST: "GET_ALL_TEAMS_DATA_REQUEST",
  GET_ALL_TEAMS_DATA_SUCCESS: "GET_ALL_TEAMS_DATA_SUCCESS",
  GET_ALL_TEAMS_DATA_FAILURE: "GET_ALL_TEAMS_DATA_FAILURE",
  GET_ALL_ROLES_DATA_REQUEST: "GET_ALL_ROLES_DATA_REQUEST",
  GET_ALL_ROLES_DATA_SUCCESS: "GET_ALL_ROLES_DATA_SUCCESS",
  GET_ALL_ROLES_DATA_FAILURE: "GET_ALL_ROLES_DATA_FAILURE",
  UPDATE_PRODUCT_PORTFOLIO_TEAM_REQUEST:
    "UPDATE_PRODUCT_PORTFOLIO_TEAM_REQUEST",
  UPDATE_PRODUCT_PORTFOLIO_TEAM_SUCCESS:
    "UPDATE_PRODUCT_PORTFOLIO_TEAM_SUCCESS",
  UPDATE_PRODUCT_PORTFOLIO_TEAM_FAILURE:
    "UPDATE_PRODUCT_PORTFOLIO_TEAM_FAILURE",
  SELECTED_TEAM_DETAILS: "SELECTED_TEAM_DETAILS",
  SEND_INVITE_TEAM_SETUP_REQUEST: "SEND_INVITE_TEAM_SETUP_REQUEST",
  SEND_INVITE_TEAM_SETUP_SUCCESS: "SEND_INVITE_TEAM_SETUP_SUCCESS",
  SEND_INVITE_TEAM_SETUP_FAILURE: "SEND_INVITE_TEAM_SETUP_FAILURE",
  CLEAR_TEAMS_DATA: "CLEAR_TEAMS_DATA",
  GET_TEAM_MEMBER_DATA_REQUEST: "GET_TEAM_MEMBER_DATA_REQUEST",
  GET_TEAM_MEMBER_DATA_SUCCESS: "GET_TEAM_MEMBER_DATA_SUCCESS",
  GET_TEAM_MEMBER_DATA_FAILURE: "GET_TEAM_MEMBER_DATA_FAILURE",
  IS_TEAM_PAGE_VISITED: "IS_TEAM_PAGE_VISITED",

  UPDATE_INVITE_MEMBER_ON_PLAN_REQUEST: "UPDATE_INVITE_MEMBER_ON_PLAN_REQUEST",
  UPDATE_INVITE_MEMBER_ON_PLAN_SUCCESS: "UPDATE_INVITE_MEMBER_ON_PLAN_SUCCESS",
  UPDATE_INVITE_MEMBER_ON_PLAN_FAILURE: "UPDATE_INVITE_MEMBER_ON_PLAN_FAILURE",
};
