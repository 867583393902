const routes = {
  /* login and onboarding */
  HOME_PAGE: "",
  LOGIN: "login",
  RESET_PASSWORD: "resetpassword",
  SIGNUP: "signup",
  SIGNUP_WITH_TOKEN: "signup/:token",
  CREATE_PASSWORD: "createpassword",
  CREATE_PASSWORD_WITH_TOKEN: "createpassword/:token",
  SIGN_OUT: "signout",

  /* agent desktop routes */
  AGENT_DESKTOP: "agent-desktop",
  AGENT_DASHBOARD: "agent-dashboard",
  AGENT_CALL: "agent-call",
  AGENT_CHAT: "agent-chat",
  ADMIN_DASHBOARD: "admin-dashboard",
  ADMIN_PERFORMANCE: "admin-performance",

  /* main app */
  DASHBOARD: "dashboard",
  CALL_LOGGER: "calllogger",
  CONNECTOR_PAGE: "ConnectorPage",
  DEMO: "demo", // not added in config
  ADMIN: "admin", // userlist - not under use
  CAMPAIGN_TESTING_INFO: "campaign-testing-info", // not added in config
  BOT_MANAGER: "botmanager", // not added in config - not in use
  OPERATION: "operation", // not added in config - not in use
  USER_LIST: "userlist", // teampage
  UPLOAD: "upload", // dummy route
  PRODUCT_PAGE: "productPage",
  TEAMS_PAGE: "teamPage",
  CRM: "crm",
  CUSTOMER: "customer",
  MAIA_ANALYTICS: "maia-analytics",
  INTEGRATION: "integration",
  EMAIL_DNS_VERIFY_PAGE: "dnsVerification",

  /* dev routes */
  DEV_ROUTE: "dev",

  /* exclusion routes */
  EXCLUSIONS_LIST: "exclusions-list",
  EXCLUSIONS_LIST_CLIENT: "exclusions-list-client",
};

const campaignManagerRoutes = {
  CAMPAIGN_MANGER: "campaignmanager",
  CAMPAIGN_CREATION: "campaignmanager/campaigncreation",
  CAMPAIGN_DATA_UPLOAD: "campaignmanager/dataupload",
  STRATEGY_SELECTION: "campaignmanager/strategyselection",
  CREATE_AGENT: "campaignmanager/createagent",
  ALL_AGENT_PAGE: "campaignmanager/allagents",
  SCHEDULER_SETTING: "campaignmanager/schedulersetting",
  NUDGE_CREATIONC: "campaignmanager/nudgecreation",
  USER_PERSONAC: "campaignmanager/userpersona",
  DIALER_SECTION: "campaignmanager/dailerSection",
  EDIT_CSV_SCREEN: "campaignmanager/dataupload/editscreen",
  CALLING_LIST: "campaignmanager/callinglist",
};
export const profileRoutes = {
  BILLING: "billing",
  PROFILE: "profile",
  PROFILE_ALL_SUB_ROUTES: "profile/*",
  PLAN_DETAILS: "profile/billing/plandetails",
  COMPARE_PLANS: "profile/billing/compareplans",
  ADD_TO_CART: "profile/billing/addtocart",
  FREE_PLAN: "profile/billing/free",
  INVOICES_SCREEN: "profile/billing/invoices",
  ADD_ONS_INVOICES_SCREEN: "profile/billing/addonsinvoices",
  MAIA_SUMMARY: "profile/billing/maiaSummary",
};
const strategyRoutes = {
  STRATEGY: "strategy",
  STRATEGY_DATA_UPLOAD: "strategy/dataupload",
  USER_PERSONA: "strategy/userpersona",
  NUDGE_CREATION: "strategy/nudgecreation",
  SCRIPT_SELECTION: "strategy/scriptselection",
  // SCRIPT_CONFIGURATION: "strategy/scriptconfig",
  SCRIPT_CONFIGURATION_SCREEN: "strategy/scriptconfigscreen",
};
export default {
  ...routes,
  ...campaignManagerRoutes,
  ...profileRoutes,
  ...strategyRoutes,
};
