import React, { useEffect, useRef, useState, ReactNode } from "react";
import styles from "./TelephonyScreen.module.scss";
import PageToggler from "../../../generic/pageToggler/PageToggler";
import { ClustersScreen } from "./clustersScreen/ClustersScreen";
import {
  closeIntegrationIcon,
  arrowDDIcon,
  telephonyGreen,
  telephonyRed,
} from "../../../../theme/assets/svg/integration_V2.0/index";
import Icon from "../../../generic/icon/Icon";
import Modal from "../../../generic/modal/Modal";
import Dropdown from "react-multilevel-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { clearIntegration } from "../../../../redux/integration_V2.0/allIntegrations/actions/allIntegrations.actions";
import {
  getDefaultTelephonyData,
  getClientTelephonyData,
  selectedTelephonyTab,
  saveSelectedTelephonySystem,
  getdefaultTelephonySystem,
  clearAllTelephonyData,
} from "../../../../redux/integration_V2.0/telephony/actions";
import { InfoToolTip } from "../../../generic/infoToolTip/InfoToolTip";
import { AdvancedSettings } from "./advSettings/AdvancedSettings";
import PopUpImg from "../../campaign/omniChannel/popUpImage/PopUpImg";
import LoaderSaarthi from "../../../generic/loader/Loader";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";
interface TelephonyScreenProps {
  shown?: boolean;
  close?: any;
  integrationIcon?: string;
}

export const TelephonyScreen = ({
  shown,
  close,
  integrationIcon,
}: TelephonyScreenProps) => {
  const dispatch = useDispatch();
  const [telephonyInfo, setTelephonyInfo] = useState([]);
  const [telephonySystem, setTelephonySystem] = useState("");
  const [currentTab, setCurrentTab] = useState<string>("");
  const [tab, setTab] = useState<{ name: string; element: ReactNode }[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [communicationTabs, setCommunicationTabs] = useState<any>([
    {
      name: "CommunicationTabs",
      moduleTabs: [
        {
          name: "Transactional",
        },
        {
          name: "Promotional",
        },
        {
          name: "Advanced Settings",
        },
        // {
        //   name: "Config",
        // },
      ],
    },
  ]);
  const dropdownRef = useRef<any>(null);

  const allTelephonyInfo = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.telephonyReducer?.allTelephonySystem?.data
  );

  const selectedIntegration = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.selectedIntegrationData
  );

  const defaultTelephonyData = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.telephonyReducer?.telephonyDataDefault?.data
  );
  const clientTelephonyData = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.telephonyReducer?.telephonyDataClient?.data
  );
  const accountDetails = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]
  );
  const accessToken = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );
  const clientAllTelephonySystem = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.telephonyReducer?.deafultTelephonySystem?.data
  );
  const clientTelephonyDataLoading = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.telephonyReducer?.telephonyDataClient
        ?.isLoading
  );

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": accessToken,
  };

  const tabConfig = {
    communicationTabs: {
      name: "CommunicationTabs",
      subModule: {
        transactional: { name: "Transactional" },
        promotional: { name: "Promotional" },
        advSettings: { name: "Advanced Settings" },
        // config: { name: "Config" },
      },
    },
  };

  const handleCloseScreen = () => {
    setTelephonyInfo([]);
    setTelephonySystem("");
    setCurrentTab("");
    setIsOpen(false);
    setCommunicationTabs([
      {
        name: "CommunicationTabs",
        moduleTabs: [
          {
            name: "Transactional",
          },
          {
            name: "Promotional",
          },
          {
            name: "Advanced Settings",
          },
          // {
          //   name: "Config",
          // },
        ],
      },
    ]);
    dispatch(clearIntegration());
    dispatch(clearAllTelephonyData());
    close();
  };

  const handleCategorySelect = (
    telephonyName: string,
    telephonyInfo: object
  ) => {
    setTelephonySystem(telephonyName);
    dispatch(saveSelectedTelephonySystem(telephonyInfo));
    setIsOpen(false);
    dropdownRef.current?.toggle(false);
  };

  /** initial Setup  */
  // console.log("mnmn", communicationTabs);
  useEffect(() => {
    dispatch(clearAllTelephonyData());
  }, []);

  useEffect(() => {
    if (allTelephonyInfo?.length > 0) {
      // setTelephonyInfo(
      //   allTelephonyInfo.concat([
      //     { telephony: "SIP Line" },
      //     { telephony: "Exotel" },
      //     { telephony: "Twillio" },
      //   ])
      // );
      setTelephonyInfo(allTelephonyInfo);

      setTelephonySystem(
        telephonySystem?.length > 0
          ? telephonySystem
          : allTelephonyInfo?.[0]?.telephony
      );
      if (telephonySystem?.length > 0) {
        const filtered = allTelephonyInfo?.filter(
          (data: any) => data?.telephony === telephonySystem
        );
        // console.log(filtered?.[0], "jjjj");
        dispatch(saveSelectedTelephonySystem(filtered?.[0]));
      } else {
        dispatch(saveSelectedTelephonySystem(allTelephonyInfo?.[0]));
      }
      //dispatch(saveSelectedTelephonySystem(allTelephonyInfo?.[0]));
      // console.log(allTelephonyInfo, telephonyInfo, "lll");
    }
  }, [allTelephonyInfo]);

  useEffect(() => {
    if (defaultTelephonyData?.schema?.telephonyTabs) {
      setCommunicationTabs([
        {
          name: "CommunicationTabs",
          moduleTabs: defaultTelephonyData?.schema?.telephonyTabs,
        },
      ]);
    }
  }, [defaultTelephonyData, telephonySystem]);

  /** Api calls **/
  useEffect(() => {
    dispatch(
      getdefaultTelephonySystem({
        params: { name: `${accountDetails?.name}` },
        headers: headers,
        name: "default",
      })
    );
    ///dispatch(getAllTelephonySystem({ name: "default" }));
    // dispatch(
    //   getdefaultTelephonySystem({
    //     params: { name: `${accountDetails?.name}` },
    //     headers: headers,
    //   })
    // );
    if (telephonySystem?.length) {
      dispatch(
        getDefaultTelephonyData({
          params: { name: "default", telephony: telephonySystem },
          headers: headers,
        })
      );
      dispatch(
        getClientTelephonyData({
          params: {
            name: `${accountDetails?.name}`,
            telephony: telephonySystem,
          },
          headers: headers,
        })
      );
    }
  }, [selectedIntegration, telephonySystem]);

  useEffect(() => {
    if (!telephonySystem?.length) {
      clientAllTelephonySystem &&
        clientAllTelephonySystem?.map((e: any) => {
          if (e.isDefault) {
            // console.log("hhe", e);
            setTelephonySystem(e?.telephony);
            dispatch(saveSelectedTelephonySystem(e));
          }
        });
    }
  }, [clientAllTelephonySystem]);

  /** onChnage Tab  */
  useEffect(() => {
    if (currentTab.length) {
      dispatch(selectedTelephonyTab(currentTab));
      if (telephonySystem?.length > 0) {
        dispatch(
          getClientTelephonyData({
            params: {
              name: `${accountDetails?.name}`,
              telephony: telephonySystem,
            },
            headers: headers,
          })
        );
      }
    }
  }, [currentTab]);

  useEffect(() => {
    if (tab.length > 0) {
      setCurrentTab(tab[0].name);
    }
  }, [tab]);

  useEffect(() => {
    if (communicationTabs && communicationTabs?.length > 0) {
      const settings = communicationTabs?.filter((e: any) => {
        return e.name === tabConfig?.communicationTabs.name;
      });
      const modules = settings?.[0]?.moduleTabs.flatMap((e: any) => {
        switch (e.name) {
          case tabConfig?.communicationTabs.subModule.transactional.name:
            return [
              {
                name: e.name,
                element: <ClustersScreen />,
              },
            ];
          case tabConfig?.communicationTabs.subModule.promotional.name:
            return [
              {
                name: e.name,
                element: <ClustersScreen />,
              },
            ];
          case tabConfig.communicationTabs.subModule.advSettings.name:
            return [{ name: e.name, element: <AdvancedSettings /> }];
          // case tabConfig.communicationTabs.subModule.config.name:
          //   return [{ name: e.name, element: <Config /> }];
          default:
            return [{ name: e.name, element: <></> }];
        }
      });
      setTab(modules ? modules : []);
    }
  }, [selectedIntegration, communicationTabs]);

  return (
    <Modal
      shown={shown}
      close={handleCloseScreen}
      extraClassStyleModalBackDrop={styles.integrationModalStyling}
      extraClassStyleModalContent={styles.integrationModalContentStyling}
    >
      <div
        className={`${styles.telephonyWrapper} ${
          currentTab === "Advanced Settings" || currentTab === "Config"
            ? styles.telephonyASWrapper
            : ""
        }`}
      >
        <div className={styles.telephonyHeaderDiv}>
          <div className={styles.telephonyLeftHeaderDiv}>
            {/* <Icon
              img_src={telephonyIntegration}
              extraClass={styles.headerIconStyling}
            /> */}
            <PravidIcons
              activeIcon={integrationIcon}
              extraClass={styles.headerIconStyling}
            />
            <div className={styles.headerTextStyling}>Telephony</div>
          </div>
          <div className={styles.telephonyRightHeaderDiv}>
            <div className={styles.closeIconStyling}>
              <Icon
                img_src={closeIntegrationIcon}
                extraClass={styles.iconCloseStyling}
                onClick={handleCloseScreen}
              />
            </div>
          </div>
        </div>
        <div className={styles.subHeaderDiv}>
          You can now easily update and manage your telephony system ports and
          DIDs.
        </div>
        <div className={styles.telephonyDropDown}>
          <div className={styles.ddLabelStyling}>
            <div className={styles.ddLabelNameText}>Telephony System</div>
            <InfoToolTip
              message={"telephony info"}
              position="top-end"
              isError={false}
            />
          </div>
          <Dropdown
            title={
              <div className={styles.dropDownTitle}>
                <div className={styles.dropDownTitleText}>
                  {telephonySystem?.length === 0 ? (
                    <span className={styles.categoryPlaceholder}>Select </span>
                  ) : (
                    <span>{telephonySystem}</span>
                  )}
                </div>
                <Icon
                  img_src={arrowDDIcon}
                  extraClass={styles.buttonIconStyling}
                />
              </div>
            }
            wrapperClassName={styles.telephonyDDStyling}
            buttonClassName={styles.telephonyDDBtnStyling}
            menuClassName={styles.telephonyMenuStyling}
            ref={dropdownRef}
            onClick={(isOpen: boolean) => setIsOpen(isOpen)}
          >
            {telephonyInfo?.map((category: any, index: number) => {
              return [
                <Dropdown.Item
                  className={`${styles.itemx} item ${styles.itemStyling}`}
                  onClick={() =>
                    handleCategorySelect(category?.telephony, category)
                  }
                  key={index}
                >
                  <span id={"auto_" + category?.telephony}>
                    {category?.telephony}
                    {category?.isDefault === true ? (
                      <span className={styles.defaultTelephonyStyling}>
                        (Default)
                      </span>
                    ) : (
                      ""
                    )}
                  </span>

                  {category?.schema?.isHealthy === true ? (
                    <Icon
                      img_src={telephonyGreen}
                      extraClass={styles.buttonSubIconStyling}
                    />
                  ) : (
                    <Icon
                      img_src={telephonyRed}
                      extraClass={styles.buttonSubIconStyling}
                    />
                  )}
                </Dropdown.Item>,
              ];
            })}
          </Dropdown>
        </div>
        <div className={styles.communicationTabDiv}>
          <PageToggler
            current={currentTab}
            innerComponents={tab}
            switchTab={(e: string) => {
              setCurrentTab(e);
            }}
            blueStyles={true}
            extraWrapperClass={styles.wrapperStyling}
            tabStyling={styles.tabStyling}
            extraTextStyling={styles.extraTextStyling}
          ></PageToggler>
        </div>
        {clientTelephonyDataLoading && (
          <PopUpImg>
            <LoaderSaarthi />
          </PopUpImg>
        )}
      </div>
    </Modal>
  );
};
