// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lS9me8aa55AWH9FLN5Jz{position:relative;display:inline-block}.R89TusacbHlRwPRKJ4Zm{position:absolute;color:#fff;z-index:999;top:100%;left:3px;opacity:0;transition:opacity .3s ease-in-out;pointer-events:none;width:auto}.lS9me8aa55AWH9FLN5Jz:hover .R89TusacbHlRwPRKJ4Zm{opacity:1}`, "",{"version":3,"sources":["webpack://./src/components/generic/toolTipWithChildComponent/ToolTipWithChild.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,oBAAA,CAGF,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,QAAA,CACA,QAAA,CACA,SAAA,CACA,kCAAA,CACA,mBAAA,CACA,UAAA,CAGF,kDACE,SAAA","sourcesContent":[".tooltipContainer {\n    position: relative;\n    display: inline-block;\n  }\n  \n  .tooltip {\n    position: absolute;\n    color: #fff;\n    z-index: 999;\n    top: 100%;\n    left: 3px;\n    opacity: 0;\n    transition: opacity 0.3s ease-in-out;\n    pointer-events: none;\n    width: auto;\n  }\n  \n  .tooltipContainer:hover .tooltip {\n    opacity: 1;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipContainer": `lS9me8aa55AWH9FLN5Jz`,
	"tooltip": `R89TusacbHlRwPRKJ4Zm`
};
export default ___CSS_LOADER_EXPORT___;
