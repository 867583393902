import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import styles from "./FinalWhatsAppScreen.module.scss";
import { timer } from "../../../../../theme/gif/index";
import InputContainer from "../../../../generic/inputContainer/InputContainer";
import TextAreaInput from "../../../../generic/textareainput/TextAreaInput";
import { InfoToolTip } from "../../../../generic/infoToolTip/InfoToolTip";
import Button from "../../../../generic/button/Button";
import {
  maxLengthValidations,
  FBManagerIdValidation,
  emailValidation,
  webSiteValidation,
} from "../../../../../utils/Validation";
import Dropdown from "react-multilevel-dropdown";
import {
  createIntegration,
  clearIntegration,
  updateIntegration,
} from "../../../../../redux/integration_V2.0/allIntegrations/actions/allIntegrations.actions";
import {
  setWhatsAppInputData,
  setIsDataChanged,
} from "../../../../../redux/integration_V2.0/whatsApp/actions/whatsApp.actions";
import _ from "lodash";
import { arrowDDIcon } from "../../../../../theme/assets/svg/integration_V2.0/index";
import Icon from "../../../../generic/icon/Icon";

interface finalScreenProps {
  closeScreenFunc?: any;
  onShowFinalCreateScreenData?: any;
  // selectedIntegrationWA?: any;
}
export const FinalWhatsAppScreen = ({
  closeScreenFunc,
  onShowFinalCreateScreenData,
}: // selectedIntegrationWA,
finalScreenProps) => {
  const initialState = {
    industry: "",
    facebookManagerId: "",
    email: "",
    website: "",
    businessDescription: "",
    businessAddress: "",
  };
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [refreshBtn, setRefreshBtn] = useState(false);
  const [finalWhatsAppInfo, setFinalWhatsAppInfo] = useState({
    industry: "",
    facebookManagerId: "",
    email: "",
    website: "",
    businessDescription: "",
    businessAddress: "",
  });

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const selectedIntegrationWA = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.selectedIntegrationData
  );

  const isCreateLoading = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.isCreateLoading
  );

  const isUpdateLoading = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.isUpdateLoading
  );

  const createdData = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.createIntegrationData
  );

  const updatedData = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.updateIntegrationData
  );

  const createdDataError = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.createIntegrationError
  );

  const updatedDataError = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.updateIntegrationError
  );

  const screenName = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.whatsAppReducer?.currentScreen
  );

  const dataChanged = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.whatsAppReducer?.isDataChanged
  );

  const industryList = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.whatsAppReducer?.allIndustryData?.[0]
        ?.categories
  );

  // const isBackIconClicked = useSelector(
  //   (store: RootState) =>
  //     store?.integrationReducer?.whatsAppReducer?.backIconClicked
  // );

  const userInputData = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.whatsAppReducer?.whatsAppInputData
  );

  const dropdownRef = useRef<any>(null);

  const handleIndustrySelect = (industryName: string) => {
    setRefreshBtn(true);
    setFinalWhatsAppInfo({
      ...finalWhatsAppInfo,
      industry: industryName,
    });
    setIsOpen(false);
    dropdownRef.current?.toggle(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRefreshBtn(true);
    if (e.target.name === "facebookManagerId") {
      const value = e.target.value;
      setFinalWhatsAppInfo({
        ...finalWhatsAppInfo,
        [e.target.name]: value.replace(/[^0-9]/g, ""),
      });
    } else if (e.target.name === "email") {
      const value = e.target.value;
      setFinalWhatsAppInfo({
        ...finalWhatsAppInfo,
        [e.target.name]: value.replace(/[^a-zA-Z0-9@._-]/g, ""),
      });
    } else if (e.target.name === "website") {
      const value = e.target.value;
      setFinalWhatsAppInfo({
        ...finalWhatsAppInfo,
        [e.target.name]: value.replace(/[^a-zA-Z0-9./:]/g, ""),
      });
    } else if (e.target.name === "businessAddress") {
      const value = e.target.value;
      setFinalWhatsAppInfo({
        ...finalWhatsAppInfo,
        [e.target.name]: value.replace(/[^a-zA-Z0-9#.\- ]/g, ""),
      });
    }
  };

  const handleDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRefreshBtn(true);
    setFinalWhatsAppInfo((prev) => {
      return { ...prev, businessDescription: event?.target?.value };
    });
  };

  const handleValidation = () => {
    if (_.isEqual(initialState, finalWhatsAppInfo)) {
      return false;
    }
    if (
      !FBManagerIdValidation(finalWhatsAppInfo?.facebookManagerId).isValid ||
      !emailValidation(finalWhatsAppInfo?.email).isValid ||
      !webSiteValidation(finalWhatsAppInfo?.website).isValid
    ) {
      return false;
    }
    if (
      finalWhatsAppInfo?.industry?.length === 0 ||
      finalWhatsAppInfo?.facebookManagerId?.length === 0 ||
      finalWhatsAppInfo?.email?.length === 0 ||
      finalWhatsAppInfo?.website?.length === 0 ||
      finalWhatsAppInfo?.businessDescription?.length === 0 ||
      finalWhatsAppInfo?.businessAddress?.length === 0
    ) {
      return false;
    }
    if (
      selectedIntegrationWA?.integrationDetails &&
      Object.keys(selectedIntegrationWA?.integrationDetails).length > 1
    ) {
      const input = {
        registeredCompanyName: userInputData?.registeredName,
        displayName: userInputData?.displayName,
        businessPhoneNumber: userInputData?.businessPhoneNumber,
        testPhoneNumber: userInputData?.testPhoneNumber,
        briefAboutBusiness: userInputData?.briefAboutBusiness,
        industry: finalWhatsAppInfo?.industry,
        facebookManagerId: finalWhatsAppInfo?.facebookManagerId,
        email: finalWhatsAppInfo?.email,
        website: finalWhatsAppInfo?.website,
        businessDescription: finalWhatsAppInfo?.businessDescription,
        businessAddress: finalWhatsAppInfo?.businessAddress,
      };
      const res: any = {
        registeredCompanyName:
          selectedIntegrationWA?.integrationDetails?.registeredName,
        displayName: selectedIntegrationWA?.integrationDetails?.displayName,
        businessPhoneNumber:
          selectedIntegrationWA?.integrationDetails?.businessPhoneNumber,
        testPhoneNumber:
          selectedIntegrationWA?.integrationDetails?.testPhoneNumber,
        briefAboutBusiness:
          selectedIntegrationWA?.integrationDetails?.briefAboutBusiness,
        industry: selectedIntegrationWA?.integrationDetails?.industry,
        facebookManagerId:
          selectedIntegrationWA?.integrationDetails?.facebookManagerId,
        email: selectedIntegrationWA?.integrationDetails?.email,
        website: selectedIntegrationWA?.integrationDetails?.website,
        businessDescription:
          selectedIntegrationWA?.integrationDetails?.businessDescription,
        businessAddress:
          selectedIntegrationWA?.integrationDetails?.businessAddress,
      };
      if (_.isEqual(input, res)) {
        return false;
      }

      // const differentKeys: any = [];

      // _.each(input, function (value: any, key: any) {
      //   if (!_.isEqual(value, res[key])) {
      //     differentKeys.push(key);
      //   }
      // });
      // console.log(differentKeys, "kkkkkkkk");

      return true;
    }
    return true;
  };

  const handleDataChange = () => {
    setRefreshBtn(false);
    dispatch(setIsDataChanged(false));
    const bodyToSend = {
      integrationDetails: {
        ...userInputData,
        industry: finalWhatsAppInfo?.industry?.slice(0, 128),
        facebookManagerId: finalWhatsAppInfo?.facebookManagerId,
        email: finalWhatsAppInfo?.email?.slice(0, 128),
        website: finalWhatsAppInfo?.website?.slice(0, 256),
        businessDescription: finalWhatsAppInfo?.businessDescription?.slice(
          0,
          256
        ),
        businessAddress: finalWhatsAppInfo?.businessAddress?.slice(0, 256),
      },
    };

    dispatch(setWhatsAppInputData(bodyToSend?.integrationDetails));
    if (
      selectedIntegrationWA?.integrationDetails &&
      Object.keys(selectedIntegrationWA?.integrationDetails).length > 1
    ) {
      dispatch(
        updateIntegration({
          integrationName: selectedIntegrationWA?.integrationName,
          accountId: accountId,
          ...bodyToSend,
        })
      );
    } else {
      dispatch(
        createIntegration({
          integrationName: selectedIntegrationWA?.integrationName,
          type: "account",
          accountId: accountId,
          ...bodyToSend,
        })
      );
    }
  };

  const handleCloseScreen = () => {
    setFinalWhatsAppInfo(initialState);
    setIsOpen(false);
    dispatch(clearIntegration());
    closeScreenFunc();
    setRefreshBtn(false);
  };

  useEffect(() => {
    setIsValid(handleValidation());
  }, [finalWhatsAppInfo, userInputData]);

  // useEffect(() => {
  //   if (clearData === false) {
  //     setFinalWhatsAppInfo(initialState);
  //   }
  // }, [clearData]);

  useEffect(() => {
    if (screenName === "") {
      setFinalWhatsAppInfo(initialState);
      setIsError(false);
    }
  }, [screenName]);

  // To render data from GET API
  useEffect(() => {
    if (Object.keys(userInputData)?.length > 0) {
      setFinalWhatsAppInfo((prev) => {
        return {
          ...prev,
          industry: userInputData?.industry
            ? userInputData?.industry
            : finalWhatsAppInfo?.industry,
          facebookManagerId: userInputData?.facebookManagerId
            ? userInputData?.facebookManagerId
            : finalWhatsAppInfo?.facebookManagerId,
          email: userInputData?.email
            ? userInputData?.email
            : finalWhatsAppInfo?.email,
          website: userInputData?.website
            ? userInputData?.website
            : finalWhatsAppInfo?.website,
          businessDescription: userInputData?.businessDescription
            ? userInputData?.businessDescription
            : finalWhatsAppInfo?.businessDescription,
          businessAddress: userInputData?.businessAddress
            ? userInputData?.businessAddress
            : finalWhatsAppInfo?.businessAddress,
        };
      });
    }
  }, [userInputData]);

  useEffect(() => {
    //To send data to redux

    const bodyToSend = {
      ...userInputData,
      industry: finalWhatsAppInfo?.industry
        ? finalWhatsAppInfo?.industry
        : userInputData?.industry,
      facebookManagerId: finalWhatsAppInfo?.facebookManagerId
        ? finalWhatsAppInfo?.facebookManagerId
        : userInputData?.facebookManagerId,
      email: finalWhatsAppInfo?.email
        ? finalWhatsAppInfo?.email
        : userInputData?.email,
      website: finalWhatsAppInfo?.website
        ? finalWhatsAppInfo?.website
        : userInputData?.website,
      businessDescription: finalWhatsAppInfo?.businessDescription
        ? finalWhatsAppInfo?.businessDescription
        : userInputData?.businessDescription,
      businessAddress: finalWhatsAppInfo?.businessAddress
        ? finalWhatsAppInfo?.businessAddress
        : userInputData?.businessAddress,
    };
    onShowFinalCreateScreenData(bodyToSend);
  }, [userInputData, finalWhatsAppInfo]);

  return (
    <div className={styles.finalWhatsAppScreen}>
      <div className={styles.contentDiv}>
        <div className={styles.subDiv}>
          <div className={styles.industryDropDown}>
            <div className={styles.ddLabelStyling}>
              {finalWhatsAppInfo?.industry?.length !== 0 ? (
                <>
                  <div className={styles.ddLabelNameText}>Industry</div>
                  <InfoToolTip
                    message={
                      "This is the industry of your company such as BFSI (Banking, Financial Services, & Insurace)"
                    }
                    position="top-end"
                    isError={false}
                  />
                </>
              ) : (
                ""
              )}
            </div>
            <Dropdown
              title={
                <div className={styles.dropDownTitle}>
                  <div
                    className={styles.dropDownTitleText}
                    // data-title={finalWhatsAppInfo?.industry}
                    //title={finalWhatsAppInfo?.industry}
                  >
                    {finalWhatsAppInfo?.industry?.length === 0 ? (
                      <span className={styles.industryPlaceholder}>
                        Industry
                      </span>
                    ) : (
                      <InputContainer
                        inputType="text"
                        inputValue={finalWhatsAppInfo?.industry}
                        extraInputClass={`${styles.inputDivStyling}`}
                        extraLabelClass={styles.inputLabelStyling}
                        extraLabelDivClass={styles.labelDivStyling}
                        extraUserInputClass={`${styles.inputMainDivStyling}`}
                        extraWrapperClass={styles.wrapperStyling}
                        noErrorMsg
                        readOnly={true}
                        showOverFlowToolTip={true}
                      />
                      // <span>{finalWhatsAppInfo?.industry}</span>
                    )}
                  </div>
                  <Icon
                    img_src={arrowDDIcon}
                    extraClass={styles.buttonIconStyling}
                  />
                </div>
              }
              wrapperClassName={styles.industryDDStyling}
              buttonClassName={styles.industryDDBtnStyling}
              menuClassName={styles.industryMenuStyling}
              ref={dropdownRef}
              onClick={(isOpen: boolean) => setIsOpen(isOpen)}
            >
              {industryList?.map((industry: any, index: number) => {
                return [
                  <Dropdown.Item
                    className={`${styles.itemx} item ${styles.itemStyling}`}
                    onClick={() => handleIndustrySelect(industry)}
                    key={index}
                  >
                    <span id={"auto_" + industry}>{industry}</span>
                  </Dropdown.Item>,
                ];
              })}
            </Dropdown>
          </div>
        </div>

        <div className={styles.subDiv}>
          <InputContainer
            label="Facebook Business Manager ID"
            inputType="text"
            maxLength={16}
            inputPlaceholder="Facebook Business Manager ID"
            inputValue={finalWhatsAppInfo?.facebookManagerId}
            inputName="facebookManagerId"
            inputOnChange={handleInputChange}
            showLabel={true}
            showNewToolTip
            showNewToolTipPosition={"top-end"}
            showNewToolTipMsg={
              "This is a 15- or 16-digit numeric account ID that is available in your Facebook Business Manager. If you do not have a FB Business Manager account, please create a new account at https://business.facebook.com "
            }
            toolTipSize={true}
            extraInputClass={`${styles.inputDivStyling}`}
            extraLabelClass={styles.inputLabelStyling}
            extraLabelDivClass={styles.labelDivStyling}
            extraUserInputClass={`${styles.inputMainDivStyling}`}
            errorMsg={
              finalWhatsAppInfo?.facebookManagerId.length > 0 &&
              isFocused === "facebookManagerId"
                ? !FBManagerIdValidation(finalWhatsAppInfo?.facebookManagerId)
                    .isValid
                  ? FBManagerIdValidation(finalWhatsAppInfo?.facebookManagerId)
                      .errors.message
                  : ""
                : ""
            }
            inputOnFocus={() => setIsFocused("facebookManagerId")}
            inputOnBlur={() => setIsFocused("")}
          />
        </div>

        <div className={styles.subDiv}>
          <InputContainer
            label="Email"
            inputType="text"
            inputPlaceholder="Email"
            inputValue={finalWhatsAppInfo?.email?.slice(0, 128)}
            inputName="email"
            inputOnChange={handleInputChange}
            showLabel={true}
            showNewToolTip
            showNewToolTipPosition={"top-end"}
            showNewToolTipMsg={"Your official business email"}
            toolTipSize={false}
            extraInputClass={`${styles.inputDivStyling}`}
            extraLabelClass={styles.inputLabelStyling}
            extraLabelDivClass={styles.labelDivStyling}
            extraUserInputClass={`${styles.inputMainDivStyling}`}
            errorMsg={
              finalWhatsAppInfo?.email.length > 0
                ? !emailValidation(finalWhatsAppInfo?.email).isValid
                  ? emailValidation(finalWhatsAppInfo?.email).errors.message
                  : isFocused === "email"
                  ? !maxLengthValidations(
                      finalWhatsAppInfo?.email,
                      "Email",
                      128
                    ).isValid
                    ? maxLengthValidations(
                        finalWhatsAppInfo?.email,
                        "Email",
                        128
                      ).errors.message
                    : ""
                  : ""
                : ""
            }
            inputOnFocus={() => setIsFocused("email")}
            inputOnBlur={() => setIsFocused("")}
          />
        </div>

        <div className={styles.subDiv}>
          <InputContainer
            label="Website"
            inputType="text"
            inputPlaceholder="Website"
            inputValue={finalWhatsAppInfo?.website?.slice(0, 256)}
            inputName="website"
            inputOnChange={handleInputChange}
            showLabel={true}
            showNewToolTip
            showNewToolTipPosition={"top-end"}
            showNewToolTipMsg={"Official website of your business "}
            toolTipSize={false}
            extraInputClass={`${styles.inputDivStyling}`}
            extraLabelClass={styles.inputLabelStyling}
            extraLabelDivClass={styles.labelDivStyling}
            extraUserInputClass={`${styles.inputMainDivStyling}`}
            errorMsg={
              finalWhatsAppInfo?.website.length > 0
                ? !webSiteValidation(finalWhatsAppInfo?.website).isValid
                  ? webSiteValidation(finalWhatsAppInfo?.website).errors.message
                  : isFocused === "website"
                  ? !maxLengthValidations(
                      finalWhatsAppInfo?.website,
                      "Website",
                      256
                    ).isValid
                    ? maxLengthValidations(
                        finalWhatsAppInfo?.website,
                        "Website",
                        256
                      ).errors.message
                    : ""
                  : ""
                : ""
            }
            inputOnFocus={() => setIsFocused("website")}
            inputOnBlur={() => setIsFocused("")}
          />
        </div>
        <div className={styles.textAreaDiv}>
          <div
            className={`${styles.inputLabelStyling} ${styles.textAreaLabel}`}
          >
            {finalWhatsAppInfo?.businessDescription &&
            finalWhatsAppInfo?.businessDescription.length > 0 ? (
              <>
                <div className={styles.labelDivStyling}>
                  Business Description
                </div>
                <InfoToolTip
                  message={
                    "This is the description text that will be displayed on your whatsapp profile."
                  }
                  isError={false}
                  position="top-end"
                  toolTipLarge={true}
                />
              </>
            ) : null}
          </div>
          <div
            className={`${styles.textAreaInputDivStyling} ${
              maxLengthValidations(
                finalWhatsAppInfo?.businessDescription,
                "Business Description",
                256
              ).errors.message?.length > 0 &&
              isFocused === "businessDescription"
                ? styles.errorBorderStyling
                : ""
            }`}
          >
            <TextAreaInput
              extraTextClass={`${styles.textInputDescDivStyling}`}
              extraClass={styles.inputDescStyling}
              handleChange={handleDescChange}
              value={finalWhatsAppInfo?.businessDescription?.slice(0, 256)}
              placeholder="Business Description"
              inputOnFocus={() => setIsFocused("businessDescription")}
              inputOnBlur={() => setIsFocused("")}
              name="businessDescription"
            />
            {finalWhatsAppInfo?.businessDescription?.length === 0 && (
              <InfoToolTip
                message={
                  "This is the description text that will be displayed on your whatsapp profile."
                }
                isError={false}
                position={"top-end"}
                toolTipLarge={true}
                isIconSmall={false}
              />
            )}
          </div>
          <div className={styles.errorMsgStyling}>
            {finalWhatsAppInfo?.businessDescription.length > 0 &&
            isFocused === "businessDescription"
              ? !maxLengthValidations(
                  finalWhatsAppInfo?.businessDescription,
                  "Business Description",
                  256
                ).isValid
                ? maxLengthValidations(
                    finalWhatsAppInfo?.businessDescription,
                    "Business Description",
                    256
                  ).errors.message
                : ""
              : ""}
          </div>
        </div>
      </div>
      <div className={styles.addressDiv}>
        <InputContainer
          label="Business Address"
          inputType="text"
          inputPlaceholder="Business Address"
          inputValue={finalWhatsAppInfo?.businessAddress?.slice(0, 256)}
          inputName="businessAddress"
          inputOnChange={handleInputChange}
          showLabel={true}
          showNewToolTip
          showNewToolTipPosition={"top-end"}
          showNewToolTipMsg={
            "This is the officially registered businessAddress of your company."
          }
          toolTipSize={false}
          extraInputClass={`${styles.inputDivStyling}`}
          extraLabelClass={styles.inputLabelStyling}
          extraLabelDivClass={styles.labelDivStyling}
          extraUserInputClass={`${styles.inputMainDivStyling}`}
          errorMsg={
            finalWhatsAppInfo?.businessAddress.length > 0 &&
            isFocused === "businessAddress"
              ? !maxLengthValidations(
                  finalWhatsAppInfo?.businessAddress,
                  "Business Address",
                  256
                ).isValid
                ? maxLengthValidations(
                    finalWhatsAppInfo?.businessAddress,
                    "Business Address",
                    256
                  ).errors.message
                : ""
              : ""
          }
          inputOnFocus={() => setIsFocused("businessAddress")}
          inputOnBlur={() => setIsFocused("")}
        />
      </div>
      <div className={styles.whatsAppbtnDiv}>
        {refreshBtn ||
        dataChanged ||
        (updatedData &&
          Object.keys(updatedData)?.length === 0 &&
          createdData &&
          Object.keys(createdData)?.length === 0) ? (
          <Button
            text="Setup WhatsApp"
            extraClass={`${styles.buttonStyling} ${
              !isValid || isCreateLoading || isUpdateLoading
                ? styles.inActiveBtnStyling
                : styles.activeBtnStyling
            }`}
            onClick={handleDataChange}
          />
        ) : (
          <Button
            text="In Progress"
            image_src_left={timer}
            extraClass={`${styles.buttonStyling} ${styles.progressStyling}`}
            // imgClass={`${styles.sendIconStyling}`}
            buttonTextClass={`${styles.movingText}`}
            onClick={handleCloseScreen}
          />
        )}
      </div>

      <div className={styles.mainErrorMsgStyling}>
        {createdDataError && createdDataError.length > 0
          ? createdDataError
          : updatedDataError
          ? updatedDataError && updatedDataError.length > 0
          : ""}
      </div>
    </div>
  );
};
