import { takeLatest } from "redux-saga/effects";
import {
  getLeftsideModuleNavigationDetailsSaga,
  getDropDownHomeDetails,
  getDropDownSettingDetails,
  setCurrentTeamProfile,
} from "./navigation.saga";
import navigationActionTypes from "../navigation.actionTypes";

export default function* navigationWatcherSaga() {
  yield takeLatest(
    navigationActionTypes.PROFILE_MODULE_GET_API_CALL,
    getLeftsideModuleNavigationDetailsSaga
  );
  yield takeLatest(
    navigationActionTypes.DROPDOWN_HOME_GET_API_CALL,
    getDropDownHomeDetails
  );
  yield takeLatest(
    navigationActionTypes.DROPDOWN_SETTING_GET_API_CALL,
    getDropDownSettingDetails
  );
  yield takeLatest(
    navigationActionTypes.SET_CURRENT_TEAM_PROFILE,
    setCurrentTeamProfile
  );
}
