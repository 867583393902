import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardComponent from "./agentCardComponent/AgentCardComponent";
import styles from "./Card.module.scss";

// interface props {
//   default: string;
// }

export default function Card(props: any) {
  const cardData = useSelector(
    (state: any) =>
      state?.agentDesktopReducer?.dashboardReducer?.dashboardChartDetails
  );

  return (
    <>
      <div className={styles.cardDiv}>
        <CardComponent
          cardDetails={props.cardDetails}
          data={cardData}
          width={props.width}
          height={props.height}
          fontSize={props.fontSize}
        />
      </div>
    </>
  );
}
