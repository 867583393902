import { Reducer } from "redux";
import actionTypes from "../actionTypes";

export interface stateInterface {
  startLoggerTour: boolean;
  experiencePravid: boolean;
  claimAndPurchase: boolean;
  hideFilterTransition: boolean;
  pushOnTranscriptPage: boolean;
  getExperienceCallStatus: { data: any; isLoading: boolean; error: any };
  startTour: boolean;
}

export const initialState: stateInterface = {
  startLoggerTour: false,
  experiencePravid: false,
  claimAndPurchase: false,
  hideFilterTransition: false,
  pushOnTranscriptPage: false,
  getExperienceCallStatus: { data: {}, isLoading: false, error: {} },
  startTour: false,
};

const productTourReducer: Reducer<stateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.START_LOGGER_TOUR: {
      return {
        ...state,
        startLoggerTour: action.payload,
      };
    }
    case actionTypes.EXPERIENCE_PRAVID: {
      return {
        ...state,
        experiencePravid: action.payload,
      };
    }
    case actionTypes.PURCHASE_AND_CLAIM_REWARD: {
      return {
        ...state,
        claimAndPurchase: action.payload,
      };
    }
    case actionTypes.UPDATE_TOUR_SHOW_SUCCESS: {
      return {
        ...state,
        updateTourModule: action.payload,
      };
    }
    case actionTypes.GET_CALL_STATUS_REQUEST: {
      return {
        ...state,
        getExperienceCallStatus: { data: {}, isLoading: true, error: {} },
      };
    }
    case actionTypes.GET_CALL_STATUS_SUCCESS: {
      return {
        ...state,
        getExperienceCallStatus: {
          data: action.payload,
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.GET_CALL_STATUS_ERROR: {
      return {
        ...state,
        getExperienceCallStatus: {
          data: {},
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.GET_CALL_STATUS_RESET: {
      return {
        ...state,
        getExperienceCallStatus: {
          data: {},
          isLoading: false,
          error: {},
        },
      };
    }
    case actionTypes.HIDE_FILTER_TRANSITION: {
      return {
        ...state,
        hideFilterTransition: action.payload,
      };
    }
    case actionTypes.PUSH_ON_TRANSCRIPT: {
      return {
        ...state,
        pushOnTranscriptPage: action.payload,
      };
    }
    case actionTypes.GET_FLOW_EXPERIENCE_SUCCESS: {
      return {
        ...state,
        flowsExperienceNow: action.payload,
      };
    }
    case actionTypes.GET_LANGUAGE_EXPERIENCE_SUCCESS: {
      return {
        ...state,
        languagesExperienceNow: action.payload,
      };
    }
    case actionTypes.START_TOUR: {
      return {
        ...state,
        startTour: action.payload,
      };
    }
    case actionTypes.SET_COME_FROM_EXPERIENCE_NOW: {
      return {
        ...state,
        comeFromExperienceNow: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default productTourReducer;
