import React, { useEffect, useState } from "react";
import style from "./Timer.module.scss";

interface props {
  baseTimer?: string;
  actionAfterTime?: () => void;
}

function Timer(props: props) {
  const [timerFromProps, setTimerFromProps] = useState<number>(0);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (typeof props.baseTimer !== "string" || !props.baseTimer) return;

    const timeDifference =
      (new Date(props.baseTimer).getTime() - new Date().getTime()) / 1000;

    setTimerFromProps(timeDifference);
  }, [props.baseTimer]);

  // useEffect(
  //     () => {
  //         setTimerFromProps(3600*24)
  //     },[]
  // )

  useEffect(() => {
    if (!timerFromProps) return;

    setTimer(timerFromProps);

    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
      if (timer < 0) {
        clearInterval(interval);
        if (props.actionAfterTime) {
          props.actionAfterTime();
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timerFromProps]);

  const hours = Math.floor(timer / 3600);
  const minutes = Math.floor((timer % 3600) / 60);

  return (
    <div className={style.timerWrapperOrganisation}>
      {`Send reminder in ${hours} Hours ${
        minutes < 10 ? `0${minutes}` : minutes
      } Minutes`}
    </div>
  );
}

export default Timer;
