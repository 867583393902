/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useEffect, useImperativeHandle, useState, useRef } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { props, rangeForCalender } from "./DatePickermodule.interface";
import styles from "./DatePickermodule.module.scss";
import { numberOfDays } from "./DatePickermodule.util";
import "./generalstyle.scss";
const minDate = new Date(2015, 0);
const maxDate = new Date(2035, 0);
const month_picker = ".rdrMonthPicker";
const year_piker = ".rdrYearPicker";
const passive_date_class = ".rdrDayPassive";
const active_date_class = ".rdrDayNumber";
const DatePickermodule = React.forwardRef((props: props, ref) => {
  const calenderRef = useRef<any>();
  const [range, setRange] = useState<rangeForCalender>(
    props.range
      ? {
          startDate: new Date(props.range.startDate),
          endDate: new Date(props.range.endDate),
          key: "key",
        }
      : {
          startDate: new Date(),
          endDate: new Date(),
          key: "key",
        }
  );
  useEffect(() => {
    // console.log(range, "changed");
    props.onChange();
  }, [range]);
  useImperativeHandle(ref, () => ({
    getNumberOfDays: () => {
      return numberOfDays(range.startDate, range.endDate);
    },
    getUpdatedRange: () => {
      return {
        startDate: range.startDate,
        endDate: range.endDate,
        totalDays: numberOfDays(range.startDate, range.endDate),
      };
    },
  }));

  const addTitle = (el: HTMLElement) => {
    if (el.classList.contains("rdrDayDisabled"))
      el.setAttribute(
        "title",
        "Select due date from the allowed range or change the allowed range from the genral calendar !"
      );
  };

  // it is used to highlight the days when porp
  function HighlightingDates(e?: MouseEvent) {
    // console.log("calender highlighting", props.highlightedDates);
    setTimeout(() => {
      //add timeout of atleast .3s to wait for update
      if (!calenderRef?.current) {
        return;
      }
      const allDays: NodeListOf<Element> =
        document.querySelectorAll(active_date_class);
      allDays.forEach((elem) => {
        elem.classList.remove("highlighted");
      });
      if (props.highlightedDates) {
        // get all dater

        const allDays: NodeListOf<Element> =
          document.querySelectorAll(active_date_class);
        const current_month_selector: any =
          document.querySelector(month_picker)?.childNodes[0];
        const current_year_selector: any =
          document.querySelector(year_piker)?.childNodes[0];
        if (!current_month_selector || !current_year_selector) {
          return;
        }
        const month = current_month_selector.selectedOptions[0].value;

        const year = current_year_selector.selectedOptions[0].value;
        // console.log("calender month year", month, year);

        // const current year =document.
        //cleanup function first

        allDays.forEach((elem, index) => {
          elem.classList.remove("highlighted");
          const span_element: any = elem.childNodes[0].textContent;
          // console.log("calender span day", span_element);

          if (!props.highlightedDates) {
            return;
          }

          const validdate = props?.highlightedDates.filter((each) => {
            // console.log("calender dummy dates", each.getDay());
            if (
              each.getMonth().toString() === month &&
              each.getDate().toString() === span_element &&
              each.getFullYear().toString() === year
            ) {
              return true;
            } else {
              return false;
            }
          });
          if (
            validdate.length > 0 &&
            !elem.classList.contains(passive_date_class)
          ) {
            elem.classList.add("highlighted");
          }
        });
      }
    }, 300);
  }
  function onMonthYearChangeSender() {
    //
    HighlightingDates();
    if (props.onMonthYearChange) {
      const current_month_selector: any =
        document.querySelector(month_picker)?.childNodes[0];
      const current_year_selector: any =
        document.querySelector(year_piker)?.childNodes[0];
      if (!current_month_selector || !current_year_selector) {
        return;
      }
      const month = current_month_selector.selectedOptions[0].value;

      const year = current_year_selector.selectedOptions[0].value;
      setTimeout(() => {
        props.onMonthYearChange && props.onMonthYearChange(month, year);
      }, 300);
    }
  }
  useEffect(() => {
    if (calenderRef.current) {
      // console.log("calender");
      // add
      HighlightingDates();
      const current_month_selector: any =
        document.querySelector(month_picker)?.childNodes[0];
      const current_year_selector: any =
        document.querySelector(year_piker)?.childNodes[0];
      // current_month_selector.addEventListener(
      //   "change",
      //   onMonthYearChangeSender
      // );
      // current_year_selector.addEventListener("change", onMonthYearChangeSender);
      // calenderRef.current.addEventListener("mousedown", HighlightingDates);
    }
    return () => {
      // calenderRef.current.removeEventListener("mousedown", HighlightingDates);
    };
  }, [props.highlightedDates, calenderRef]);
  useEffect(() => {
    const current_month_selector: any =
      document.querySelector(month_picker)?.childNodes[0];
    const current_year_selector: any =
      document.querySelector(year_piker)?.childNodes[0];
    current_month_selector.addEventListener("change", onMonthYearChangeSender);
    current_year_selector.addEventListener("change", onMonthYearChangeSender);
    const next_prev_button: any =
      document.querySelectorAll(".rdrNextPrevButton");
    for (const i of next_prev_button) {
      i.addEventListener("click", onMonthYearChangeSender);
    }
    onMonthYearChangeSender();
    console.log("calender rerendering");
    return () => {
      current_month_selector.removeEventListener(
        "change",
        onMonthYearChangeSender
      );
      current_year_selector.removeEventListener(
        "change",
        onMonthYearChangeSender
      );
      for (const i of next_prev_button) {
        i.removeEventListener("click", onMonthYearChangeSender);
      }
    };
  }, []);

  useEffect(() => {
    document
      .querySelectorAll(".rdrDay")
      .forEach((node: Node) => addTitle(node as HTMLElement));
  }, [range]);

  return (
    <div ref={calenderRef} className={styles.wrapper}>
      <DateRangePicker
        preventSnapRefocus={true}
        ranges={[range]}
        minDate={!props.isDueDateFilter ? props.minDate : minDate}
        maxDate={!props.isDueDateFilter ? props.maxDate : maxDate}
        onChange={(e: any) => {
          const newrange: rangeForCalender = {
            startDate: new Date(e.key.startDate),
            endDate: new Date(e.key.endDate),
            key: "key",
          };
          // console.log(newrange);
          setRange(newrange);
        }}
        showSelectionPreview={false}
      />
    </div>
  );
});
DatePickermodule.displayName = "DatePickermodule";
export default DatePickermodule;
