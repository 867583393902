import React, { useEffect, useState } from "react";
import WhatsAppSchedulerPage from "../../../components/moduleComponents/campaign_V2.0/whatsAppNewScheduler/whatsAppSchedulerSetting/WhatsAppSchedulerPage";
import Preview from "../../../components/moduleComponents/campaign_V2.0/whatsAppNewScheduler/whatsAppNewPreview/Preview";
import "./WpSettingPage.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getTemplatesWhatsApp } from "../../../redux/omniChannel/actions";
import { getAllNudgeDetails } from "../../../redux/strategy/nudgeCreation/actions";

function WpSettingPage(props) {
  const [templateIdPreview, setTemplateIdPreview] = useState("");
  const [flowsWhatsApp, setFlowWhatsApp] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //store
  const campaignData = useSelector(
    (store) =>
      store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
  );
  const schedulerDetails = useSelector((store) => {
    return store.campaignModuleReducer?.schedulerData?.getSchedularDetails;
  });
  const allNudgeDetail = useSelector((store) => {
    return store?.strategyModuleReducer?.nudgeReducer?.allNudgeDetail;
  });

  console.log("dataS", schedulerDetails, allNudgeDetail, flowsWhatsApp);
  //calling api for getting templates
  useEffect(() => {
    if (templateIdPreview) {
      let obj = {
        id: templateIdPreview,
      };
      dispatch(getTemplatesWhatsApp(obj));
    }
  }, [templateIdPreview?.length]);
  useEffect(() => {
    dispatch(getAllNudgeDetails({ strategyId: campaignData?.strategyId }));
  }, [campaignData?.strategyId]);

  /**geting flows whatsApp */
  const getWhatsAppFlows = (data) => {
    const flowChannel = [];
    const newUpdated = data?.map((e) => {
      if (e.ranges) {
        const rangesArr = e?.ranges;
        rangesArr?.map((d) => {
          const nudgeBoxArray = d?.nugeBoxes;
          const containsWhatsApp = nudgeBoxArray?.some(
            (chanel) => chanel.channel === "whatsApp"
          );
          if (containsWhatsApp) {
            flowChannel.push(e?.flow);
          }
        });
      }
      if (e?.nugeBoxes) {
        const containsWhatsApp = e?.nugeBoxes?.some(
          (chanel) => chanel.channel === "whatsApp"
        );
        if (containsWhatsApp) {
          flowChannel.push(e?.flow);
        }
      }
    });
    setFlowWhatsApp(flowChannel);
  };
  useEffect(() => {
    if (allNudgeDetail) {
      getWhatsAppFlows(allNudgeDetail);
    }
  }, [allNudgeDetail]);
  //onchange
  const submitDatawhatsApp = () => {
    props.onSubmit();
  };

  return (
    <div className="wpsettingPageWrapper">
      <div className="schedulerSettingPageWp">
        <div className="scheduleSettingwp">
          <WhatsAppSchedulerPage
            onClickTemplateId={setTemplateIdPreview}
            onSubmit={submitDatawhatsApp}
            nudgeScheduleData={schedulerDetails?.dialTimeData?.data}
            campaignData={campaignData}
            whatsAppFlowUnique={[...new Set(flowsWhatsApp)]}
          />
        </div>
        <div className="previewWp">
          {/* <div className='previewNote'>Preview</div> */}
          <Preview />
        </div>
      </div>
    </div>
  );
}

export default WpSettingPage;
