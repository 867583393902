import React, { useRef, useEffect } from "react";
import { Chart, registerables } from "chart.js";
import DoughnutLabel from "chartjs-plugin-doughnutlabel-v3";

Chart.register(...registerables, DoughnutLabel);

const DoughNutChart = ({
  colorsArray,
  dataArray,
  labelsArray,
  donutLabels,
  doneTooltip,
}: any) => {
  const chartRef = useRef<any>(null);

  const data = {
    datasets: [
      {
        data: dataArray,
        backgroundColor: colorsArray,
        borderWidth: 0,
        // borderRadius: [10, 10, 10, 10, 0],
        spacing: 0,
      },
    ],
    labels: labelsArray,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    cutout: 67,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    layout: { padding: 5, autoPadding: true },
    plugins: {
      legend: {
        display: false,
        position: "right" as const,
        align: "start" as const,
        labels: {
          usePointStyle: true, // Set the legend color shape to a circle
          padding: 15,
          color: "#838383",
          font: "13px",
        },
        onClick: () => {
          console.log("");
        }, // Disable onclick function on legends
      },
      doughnutLabel: {
        labels: donutLabels,
      },
      tooltip: {
        enabled: false,
        displayColors: false,
        backgroundColor: "#B6B6B6",
        titleColor: "#fff",
        titleFont: { weight: 400, size: 13 },
        titleAlign: "left",
        bodyColor: "#fff",
        bodyFont: { weight: 400, size: 13 },
        bodyAlign: "left",
        callbacks: {
          label: (tooltipItem: any) => {
            const value = tooltipItem.dataset.data[tooltipItem.dataIndex];
            const label = tooltipItem.label;
            return `${label}: ${value}`;
          },
          title: () => {
            return "";
          },
        },
      },
    },
  };

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    const chartInstance = new Chart(ctx, {
      type: "doughnut",
      data: data,
      options: options as any,
    });

    return () => {
      chartInstance.destroy(); // Cleanup the chart instance when the component unmounts
    };
  }, [data, options]);

  return (
    <div
      className={"doughNutChartWrapper"}
      style={{ width: "200px", height: "200px" }}
    >
      <canvas ref={chartRef} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default DoughNutChart;
