import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  callEnd,
  callMute,
  callHold,
  callHoldTrue,
  callMuteTrue,
} from "../../../../theme/assets/agentDesktopSvg";
import "./Dialer.scss";

interface props {
  callerData: {
    name: string;
    number: string;
    isOnHold: boolean;
    isOnMute: boolean;
  };
  // eslint-disable-next-line no-unused-vars
  handleDialerBtnClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export default function Dialer(props: props) {
  /* useState --------------- */
  const [callTimer, setCallTimer] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );

  /* useEffect ------------- */
  useEffect(() => {
    const interval = setInterval(() => {
      const newTime = callTimer.setSeconds(callTimer.getSeconds() + 1);
      setCallTimer(new Date(newTime));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="dialer__outer-container">
      <div className="dialer__inner-container">
        <div className="dialer__caller-information">
          <div className="caller-information__name">
            {props.callerData.name}
          </div>
          <div className="caller-information__masked-number">
            {props.callerData.number}
          </div>
          <div className="caller-information__call-timer">
            {moment(callTimer).format("HH:mm:ss")}
          </div>
        </div>
        <div className="dialer__hold-btn dialer__btn">
          <img
            src={props.callerData.isOnHold ? callHoldTrue : callHold}
            alt=""
            data-button-type="hold"
            onClick={props.handleDialerBtnClick}
          />
          <span>Hold</span>
        </div>
        <div className="dialer__mute-btn dialer__btn">
          <img
            src={props.callerData.isOnMute ? callMuteTrue : callMute}
            alt=""
            data-button-type="mute"
            onClick={props.handleDialerBtnClick}
          />
          <span>Mute</span>
        </div>
        <div className="dialer__end-btn dialer__btn">
          <img
            src={callEnd}
            alt=""
            data-button-type="end"
            onClick={props.handleDialerBtnClick}
          />
          <span>End</span>
        </div>
      </div>
    </div>
  );
}
