import { takeLatest } from "redux-saga/effects";
import actionTypes from "../email.actionTypes";
import {
  getEmailDNSDetailsAPIWorker,
  verifyEmailDNSDetailsAPIWorker,
  sendToDevEmailDNSDetailsAPIWorker,
  updateEmailDNSDetailsAPIWorker,
  getDevEmailDNSDetailsAPIWorker,
} from "./email.saga";

export default function* emailDataWatcher(): any {
  yield takeLatest(
    actionTypes.GET_EMAIL_DNS_DETAILS_API_REQUEST,
    getEmailDNSDetailsAPIWorker
  );
  yield takeLatest(
    actionTypes.VERIFY_EMAIL_DNS_DETAILS_API_REQUEST,
    verifyEmailDNSDetailsAPIWorker
  );
  yield takeLatest(
    actionTypes.SEND_DNS_TO_DEV_EMAIL_API_REQUEST,
    sendToDevEmailDNSDetailsAPIWorker
  );
  yield takeLatest(
    actionTypes.UPDATE_EMAIL_DNS_DETAILS_API_REQUEST,
    updateEmailDNSDetailsAPIWorker
  );
  yield takeLatest(
    actionTypes.GET_DEV_EMAIL_DETAILS_API_REQUEST,
    getDevEmailDNSDetailsAPIWorker
  );
}
