import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux";
import { useNavigate } from "react-router-dom";
import { editPencilBlue } from "../../../../theme/assets/genericSvg";
import LabelValue from "../../../generic/labelValue/LabelValue";
import FileViewDownload from "../../../generic/fileviewDownlaod/FileViewDownload";
import {
  strategyCallIcon,
  strategyWhatsAppIcon,
  strategyMailIcon,
  strategySMSIcon,
} from "../../../../theme/assets/svg/campaign_v3/index";
import Button from "../../../generic/button/Button";
import Icon from "../../../generic/icon/Icon";
import styles from "./AgentDetailedView.module.scss";
import moment from "moment";
import { downloadUploadedCSVZip } from "../../../../redux/campaign_V2.0/agentConfiguration/actions";

interface props {
  agent: object;
  summaryData: any;
  onCancel: () => void;
  onConfirm: (agentDetail: object) => void;
}

const AgentDetailedView = (props: props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accountDetails = useSelector(
    (store: RootState) => store?.schedulerReducer?.accountInfoDetails
  );

  const stepsTotal: string[] = useSelector(
    (store: RootState) =>
      store?.schedulerReducer?.accountInfoDetails?.campaignProgressBar
  );

  const campaignData = useSelector(
    (store: any) =>
      store.campaignModuleReducer?.createCampaignData?.savedCampaignInfo
  );

  const NameShowAgent = [];
  props?.summaryData?.agentDetails?.maleAgentName?.length > 1 &&
    NameShowAgent.push(props?.summaryData?.agentDetails?.maleAgentName);
  props?.summaryData?.agentDetails.femaleAgentName?.length &&
    NameShowAgent.push(props?.summaryData?.agentDetails.femaleAgentName);

  const telephonyDisplayOptions: any = {
    Epicode: "Saarthi Telephony 1",
    Internal: "Saarthi Telephony 2",
    Twilio: "Saarthi Telephony 3",
  };

  const latestUploadedFile = props?.summaryData?.customerUploadInfo?.reduce(
    (latest: any, obj: any) => {
      const updatedAt = new Date(obj.updatedAt);

      if (!latest || updatedAt > new Date(latest.updatedAt)) {
        return obj;
      } else {
        return latest;
      }
    },
    null
  );

  const handleModes = (data: object) => {
    const arr = [];
    const modesArr = data ? Object.keys(data) : undefined;
    if (modesArr && (modesArr.includes("call") || modesArr.includes("Call"))) {
      arr.push(strategyCallIcon);
    }
    if (
      modesArr &&
      (modesArr.includes("whatsApp") || modesArr.includes("whatsapp"))
    ) {
      arr.push(strategyWhatsAppIcon);
    }
    if (modesArr && modesArr.includes("sms")) {
      arr.push(strategySMSIcon);
    }
    if (modesArr && modesArr.includes("mail")) {
      arr.push(strategyMailIcon);
    }
    return arr;
  };

  const handleChannels = (data: string[]) => {
    const arr = [];
    const modesArr = data?.length > 0 ? data : undefined;
    if (modesArr && (modesArr.includes("call") || modesArr.includes("Call"))) {
      arr.push(strategyCallIcon);
    }
    if (
      modesArr &&
      (modesArr.includes("whatsApp") || modesArr.includes("whatsapp"))
    ) {
      arr.push(strategyWhatsAppIcon);
    }
    if (modesArr && modesArr.includes("sms")) {
      arr.push(strategySMSIcon);
    }
    if (modesArr && modesArr.includes("mail")) {
      arr.push(strategyMailIcon);
    }
    return arr;
  };

  const handleDownloadZip = () => {
    dispatch(
      downloadUploadedCSVZip({
        campaignId: campaignData?.id,
        fileName: latestUploadedFile?.downloadCsvFileName?.replace(
          ".csv",
          ".zip"
        ),
      })
    );
  };

  return (
    <div className={styles.agentDetailedView}>
      <div className={styles.content}>
        {stepsTotal?.includes("Campaign Details") ? (
          <div className={`${styles.campaignDetails} ${styles.detailGroup}`}>
            <div className={`${styles.header} ${styles.row}`}>
              <div className={styles.heading}>Campaign Details</div>
              <div
                className={styles.editButton}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onCancel();
                  navigate("/campaignmanager/campaigncreation");
                }}
              >
                <img src={editPencilBlue} alt="" />
                <span>Edit</span>
              </div>
            </div>
            <div className={styles.row}>
              <LabelValue
                label={"Campaign Name"}
                value={props?.summaryData?.campaignDetails?.campaignName}
              />
            </div>
            {accountDetails?.type === "Internal" ? (
              <div className={styles.row}>
                <LabelValue
                  label={"TTS"}
                  value={props?.summaryData?.campaignDetails?.TTS}
                />
                <LabelValue
                  label={"ASR"}
                  value={props?.summaryData?.campaignDetails?.ASR}
                />
                <LabelValue
                  label={"Telephony"}
                  value={
                    telephonyDisplayOptions[
                      props?.summaryData?.campaignDetails?.telephonyName
                    ]
                  }
                />
              </div>
            ) : null}
            <div className={styles.row}>
              <LabelValue
                label={"Campaign Description"}
                value={props?.summaryData?.campaignDetails?.description}
              />
            </div>
          </div>
        ) : null}
        {stepsTotal?.includes("Data Upload") ? (
          <div className={`${styles.dataUpload} ${styles.detailGroup}`}>
            <div className={`${styles.header} ${styles.row}`}>
              <div className={styles.heading}>Data Upload</div>
              <div
                className={styles.editButton}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onCancel();
                  navigate("/campaignmanager/dataupload");
                }}
              >
                <img src={editPencilBlue} alt="" />
                <span>Edit</span>
              </div>
            </div>
            <div className={styles.row}>
              <LabelValue
                label={"Methods Used"}
                value={
                  latestUploadedFile?.mode === "Manual"
                    ? "Manual CSV"
                    : latestUploadedFile?.mode
                }
              />
            </div>
            <div className={styles.row}>
              <LabelValue
                label={"Latest Uploaded File"}
                value={
                  <FileViewDownload
                    fileName={latestUploadedFile?.downloadCsvFileName?.replace(
                      ".csv",
                      ".zip"
                    )}
                    date={moment(latestUploadedFile?.updatedAt).format(
                      "DD MMM YYYY"
                    )}
                    onDownload={handleDownloadZip}
                  />
                }
              />
            </div>
          </div>
        ) : null}
        {stepsTotal?.includes("Strategy Selection") ? (
          <div className={`${styles.strategySelection} ${styles.detailGroup}`}>
            <div className={`${styles.header} ${styles.row}`}>
              <div className={styles.heading}>Strategy Selection</div>
              <div
                className={styles.editButton}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onCancel();
                  navigate("/campaignmanager/strategyselection");
                }}
              >
                <img src={editPencilBlue} alt="" />
                <span>Edit</span>
              </div>
            </div>
            <div className={styles.row}>
              <LabelValue
                label={"Strategy Name"}
                value={props?.summaryData?.strategyDetails?.strategyName}
              />
              <LabelValue
                label={"Channels"}
                value={handleModes(
                  props?.summaryData?.strategyDetails?.mode
                ).map((data: string, index: number) => {
                  return (
                    <Icon
                      img_src={data}
                      key={index}
                      extraClass={styles.strategyCardIcon}
                    />
                  );
                })}
              />
            </div>
            <div className={styles.row}>
              <LabelValue
                label={"Strategy Description"}
                value={props?.summaryData?.strategyDetails?.strategyDescription}
              />
            </div>
          </div>
        ) : null}
        {stepsTotal?.includes("Agent Configuration") ? (
          <div className={`${styles.agentConfiguration} ${styles.detailGroup}`}>
            <div className={`${styles.header} ${styles.row}`}>
              <div className={styles.heading}>Agent Configuration</div>
              <div
                className={styles.editButton}
                // onClick={() => navigate("/campaignmanager/createagent")}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onCancel();
                }}
              >
                <img src={editPencilBlue} alt="" />
                <span>Edit</span>
              </div>
            </div>
            <div className={styles.row}>
              <LabelValue
                label={"Agent Name"}
                value={
                  NameShowAgent?.length > 1
                    ? NameShowAgent.join(" & ")
                    : NameShowAgent
                }
              />
            </div>
            <div className={styles.row}>
              <LabelValue
                label={"Channels"}
                value={handleChannels(
                  props?.summaryData?.agentDetails?.channels
                ).map((data: string, index: number) => {
                  return (
                    <Icon
                      img_src={data}
                      key={index}
                      extraClass={styles.strategyCardIcon}
                    />
                  );
                })}
              />
              <LabelValue
                label={"Languages"}
                value={props?.summaryData?.agentDetails?.languages.join(", ")}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.footer}>
        <Button
          text="Cancel"
          extraClass={styles.buttonStyling}
          onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation();
            props.onCancel();
          }}
          extraStyle={{
            width: "110px",
            backgroundColor: "white",
            color: "#6E7079",
          }}
          id="auto_createButton"
        />
        <Button
          text="Confirm"
          extraClass={styles.buttonStyling}
          onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation();
            props.onConfirm(props.agent);
          }}
          extraStyle={{ width: "110px" }}
          id="auto_createButton"
        />
      </div>
    </div>
  );
};

export default AgentDetailedView;
