import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
// import { crossThinIcon } from "../../../../theme/assets/genericSvg";
// import { downloadIcon } from "../../../../theme/assets/svg/rightSideIcon";
import {
  getMessages,
  getSummaryByAPI,
} from "../../../../redux/logger/loggerState/action";
import SummaryFeedback from "../../logger/summaryFeedback/SummaryFeedback";
import ConversationCardsWrapper from "../../../generic/conversationCards/ConversationCardsWrapper";
import "./SummaryPortal.scss";
import { RootState } from "../../../../redux";
import { setRootPortalScreen } from "../../../../redux/baseScreen/baseScreenState/actions";
import {
  portalTranscriptDownloadCSV,
  clearTranscriptSummaryData,
} from "../../../../redux/logger/loggerState/action";
import portalTypes from "../../../../screens/rootPortal/portalTypes";
import { DateToLocalString } from "../../../../utils/timeAndDate/time.util";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";
import LoaderSaarthi from "../../../generic/loader/Loader";
import NoDatamodel from "../../../generic/noDatamodel/NoDatamodel";
import { noTranscriptDataIcon } from "../../../../theme/assets/svg";
import moment from "moment";
import { useCurrentPageTab } from "../../../../hooks";
import { ROLES } from "../../../../constants";
export default function SummaryPortal() {
  /* redux hooks */
  const summary = useSelector(
    (store: RootState) => store.loggerReducer?.loggerState?.summaryData[0]
  );
  const conversationId = useSelector(
    (store: RootState) =>
      store.baseScreen.baseScreenState.conversationIdSummaryPortal
  );
  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );
  // const channelTab = useSelector(
  //   (store: RootState) => store.dashboardReducer?.channelTab
  // );
  const transcriptData: any[] = useSelector((state: any) => {
    return state.loggerReducer?.loggerState?.transcriptData;
  });
  const transcriptLoading: any[] = useSelector((state: any) => {
    return state.loggerReducer?.loggerState?.transcriptLoading;
  });
  const filterSelectedDate = useSelector(
    (store: RootState) => store?.filterReducers?.calenderData
  );
  const userId = useSelector(
    (state: RootState) => state.loginReducer.userLoginInfo?.userDetail?._id
  );
  const selectedFilters = useSelector(
    (state: RootState) => state?.filterReducers?.selectedFilterOptions
  );
  const userRole: any = useSelector((state: any) => {
    return state.loginReducer.userLoginInfo.userDetail.role;
  });
  const dispatch = useDispatch();
  const accountDetails = useSelector(
    (store: any) => store.loginReducer?.userLoginInfo?.accountDetails[0]
  );
  const currentUseCaseId = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseId
  );
  const currentSelectedTeam = useSelector(
    (store: RootState) => store.loginReducer.currentTeam
  );
  const currentUser = useSelector((state: RootState) => {
    return state.filterReducers.selectedFilterOptions.clientName;
  });
  const currentUseCaseName = useSelector(
    (store: RootState) => store.loginReducer.currentTeam.useCaseName
  );

  const { subChannelTab, subModuleTab, channelTab } = useCurrentPageTab();
  /* useEffect */
  useEffect(() => {
    if (conversationId) {
      dispatch(getMessages(conversationId));
      dispatch(getSummaryByAPI({ id: conversationId, role: userRole }));
    }
  }, [conversationId]);

  /* handlers */
  const handleCrossIconClick = () => {
    dispatch(setRootPortalScreen(portalTypes.NULL_PORTAL));
    dispatch(clearTranscriptSummaryData());
  };
  const handleDownloadClick = () => {
    const bodyData = {
      conversationId,
      dategte:
        moment(filterSelectedDate.startDate).format("YYYY-MM-DD") + "T00:00:00",
      datelte:
        moment(filterSelectedDate.endDate).format("YYYY-MM-DD") + "T23:59:59",
      useCaseId: [currentUseCaseId],
      source: [currentUseCaseName.split(" ")[0]],
      channelId: [channelTab.id],
      tabId: [subModuleTab.id],
      ...(channelTab === "Whatsapp" || channelTab === "Chat"
        ? { subChannelId: [subChannelTab.id] }
        : {}),
      userId: userId,
      teamId: currentSelectedTeam.id,
      ...selectedFilters,
      accountName:
        (userRole.toString().toLowerCase() ===
          ROLES.campaignAnalyst.name.toLowerCase() ||
          userRole.toString().toLowerCase() === ROLES.campaignManager.name.toLowerCase()) &&
        currentUser?.[0]?.name
          ? currentUser[0].name
          : accountName,
      role: userRole,
    };
    delete bodyData["clientName"];
    dispatch(portalTranscriptDownloadCSV(bodyData));
  };
  return createPortal(
    <>
      <div className="wrapper__summary-portal">
        <div className="portal__container">
          {transcriptLoading ? (
            <div className="loader">
              <LoaderSaarthi />
            </div>
          ) : (
            <>
              <div className="cross-icon-div">
                {/* <img
              className="clickAbleCursorPointerU"
              src={downloadIcon}
              alt="download Icon"
              onClick={handleDownloadClick}
            /> */}
                {/* <img
              className="clickAbleCursorPointerU"
              src={crossThinIcon}
              alt="cross Icon"
              onClick={handleCrossIconClick}
            /> */}
                {transcriptData.length > 0 && (
                  <PravidIcons
                    activeIcon={"downloadIconGray"}
                    extraClass={"clickAbleCursorPointerU"}
                    onClick={() => {
                      if (
                        userRole.toString().toLowerCase() ===
                          ROLES.campaignAnalyst.name.toLowerCase() &&
                        accountDetails.type === "Internal"
                      ) {
                        return;
                      }

                      handleDownloadClick();
                    }}
                  />
                )}

                <PravidIcons
                  activeIcon={"crossThinIcon"}
                  extraClass={"clickAbleCursorPointerU"}
                  onClick={handleCrossIconClick}
                />
              </div>
              <div className="body__summary-portal">
                {transcriptData?.length > 0 ? (
                  <>
                    <div className="summary-portal__left">
                      {channelTab === "Chat" ? (
                        <div className="basic-info">
                          <span>
                            <span>User ID: </span>
                            <span className="color-pravidBlueU">
                              {summary?.information?.user_id}
                            </span>
                          </span>
                        </div>
                      ) : (
                        <div className="basic-info">
                          <span>
                            <span>Phone number: </span>
                            <span className="color-pravidBlueU">
                              {summary?.information?.phone_number}
                            </span>
                          </span>
                          <span>
                            <span>Date: </span>
                            <span className="color-pravidBlueU">
                              {DateToLocalString(
                                summary?.information?.dialed_time
                              )}
                            </span>
                          </span>
                        </div>
                      )}
                      <div className="transcript__container">
                        <ConversationCardsWrapper />
                      </div>
                    </div>

                    <div className="summary-feedback__container">
                      <SummaryFeedback summary={summary} />
                    </div>
                  </>
                ) : (
                  <div className="no-data">
                    <NoDatamodel
                      message="No Data"
                      srcImg={noTranscriptDataIcon}
                      extraCss={{ message: "message", img: "extraClassImg" }}
                    ></NoDatamodel>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>,
    document.getElementById("root-portal")!
  );
}
