import actionTypes from "../schedularSetting.actionTypes";

export function getSchedularAPIData(payload: object) {
  return {
    type: actionTypes.GET_SCHEDULAR_DATA_API_REQUEST,
    payload: payload,
  };
}

export function saveSchedularAPIData(payload: object) {
  return {
    type: actionTypes.SCHEDULAR_DATA_API_REQUEST,
    payload: payload,
  };
}
