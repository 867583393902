import React, { useState, useEffect } from "react";
import NoDatamodel from "../../../generic/noDatamodel/NoDatamodel";
import { noPhoneDataIcon } from "../../../../theme/assets/svg";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

function SimpleLineChart(props) {
  const [data, setData] = useState([]);
  const [timeSlotData, setTimeSlotData] = useState([]);

  const expandedChart = useSelector(
    (store) => store?.analyticsReducer?.expandedChart
  );

  const isSort = useSelector((store) => store?.analyticsReducer?.isSort);

  const sortedChart = useSelector(
    (store) => store?.analyticsReducer?.sortedChart
  );

  let key = props.chartDetails?.legendData[0];
  let value1 = props.chartDetails?.keys[0].referenceKeyName;
  let value2 = props.chartDetails?.keys[1].referenceKeyName;
//   let refenceKeyForData = props.chartDetails?.refenceKeyForData;
  let barWidth = parseInt(props.chartDetails?.chartStyle?.width) - 30;
  let barHeight = parseInt(props.chartDetails?.chartStyle?.height) - 80;
  let expandbarHeight = parseInt(props.chartDetails?.expand?.expandStyle?.height)-150;

  useEffect(() => {
    if (props.data) {
      var sortedTimeList = [];
      var tempTimesList = [];
      let tempData = props?.data
    //   props?.data[refenceKeyForData];
      tempData?.map(function (each, index) {
        var tempSingleTimeArr = each[value1].split("-");
        tempTimesList.push({
          time: moment(tempSingleTimeArr[0].trim(), "hh:mm a").unix(),
          index: index,
          count: each[value2],
        });
      });
      tempTimesList.sort(function (a, b) {
        return a.time - b.time;
      });

      tempTimesList.forEach(function (t) {
        sortedTimeList.push(tempData[t.index]);
      });
      setTimeSlotData(sortedTimeList);
    }
  }, [props.chartDetails, props.data]);

  useEffect(() => {
    if (timeSlotData) {
      let finalData = timeSlotData.map((each, i) => {
        return {
          name: each[value1],
          [key]: each[value2],
          amt: each[value1],
        };
      });
      setData(finalData);
      // setMonth(temp)
    }
  }, [timeSlotData]);
  // console.log(props.chartDetails,"12345")
//   const setSortedData = (data) => {
//     let finalData = data?.map((each, i) => {
//       return {
//         name: each[value1],
//         [key]: each[value2],
//         amt: each[value1],
//       };
//     });
//     setData(finalData);
//   };

//   useEffect(() => {
//     if (props.chartTitle === sortedChart) {
//       let tempdata = timeSlotData;
//       if (isSort) {
//         tempdata.reverse();
//         setSortedData(tempdata);
//       } else {
//         let tempdata = timeSlotData;
//         // tempdata.reverse();
//         setSortedData(tempdata);
//       }
//     }
//   }, [isSort, timeSlotData]);

 
  return data?.length > 0 ? (
    <div
      className="sd-chart"
      style={{
        minWidth: `${data.length * 140}px`,
        height: `${
        //   expandedChart && expandedChart.includes(props.chartTitle)
        //     ? `${expandbarHeight}px`
        //     : 
            `${barHeight}px`
        }`,
        width: `${
        //   expandedChart && expandedChart.includes(props.chartTitle)
        //     ? "100%"
        //     :
             `${barWidth}px`
        }`,
        
      }}
    >
      <LineChart
        width={barWidth}
        height={
        //   expandedChart && expandedChart.includes(props.chartTitle) ? 500 
        //   : 
        barHeight
        }
        data={data}
        margin={{
            top: 5,
            right: 30,
            left: 30,
            bottom: 5
          }}
          isAnimationActive={true}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name">
          <Label
            // angle={200}
            position="insideBottomMiddle"
            style={{
              textAnchor: "middle",
              fill: "#000000",
            }}
            dy={14}
          >
            {props.chartDetails?.keys[0].name}
          </Label>
        </XAxis>
        <YAxis>
          <Label
            angle={270}
            offset={10}
            position="left"
            style={{ textAnchor: "middle", fill: "#000000" }}
          >
            {props.chartDetails?.keys[1].name}
          </Label>
        </YAxis>
        <Tooltip />
        
        <Line
          type="monotone"
          dataKey={key}
          stroke={"#F8961E"}
          activeDot={{ r: 3 }}
        // strokeWidth={2}
          dot={{ r: 4, stroke: "#F8961E", fill: "#F8961E" }}
          
        />
      </LineChart>
    </div>
  ) : (
    <div
      className="no-data"
      style={{
        height: `${
        //   expandedChart && expandedChart.includes(props.chartTitle)
        //     ? "510px"
        //     : 
            "310px"
        }`,
      }}
    >
      <NoDatamodel srcImg={noPhoneDataIcon}></NoDatamodel>
    </div>
  );
}

export default SimpleLineChart;