/**
 * 
 *
    - mysite.ourearth.com [@ is not present]
    - mysite@.com.my [ tld (Top Level domain) can not start with dot "." ]
    - @you.me.net [ No character before @ ]
    - mysite123@gmail.b [ ".b" is not a valid tld ]
    - mysite@.org.org [ tld can not start with dot "." ]
    - .mysite@mysite.org [ an email should not be start with "." ]
    - mysite()*@gmail.com [ here the regular expression only allows character, digit, underscore, and dash ]
    - mysite..1234@yahoo.com [double dots are not allowed]
 * @param email 
 * @returns 
 */
export function validateEmailString(email: string): boolean {
  if (!email?.includes("@") || !email?.includes(".")) {
    return false;
  }
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}
/**
 * extract the domain
 * - nithin@gmail.com -> gmail.com
 * - if the entered email is not valid then it returns false
 * @param email
 * @returns string | false
 */
export function getDomain(email: string): string | false {
  if (!validateEmailString(email)) return false;
  return email.split("@")[1];
}
/**
 * match the domain of both email
 * if either of the email is not valid it returns false
 * if the both domain matches it returns true
 * donot validate domain Email
 * @param email
 * @param domainEmail
 * @returns boolean
 */
export function validateEmailDomain(
  email: string,
  domainEmail: string
): boolean {
  if (!validateEmailString(email)) return false;
  if (getDomain(email) === getDomain(domainEmail)) return true;
  return false;
}
