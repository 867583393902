import React from "react";
import { glowingDotGreen } from "../../../../../theme/assets/agentDesktopSvg";
import "./OnGoingCallIndicator.scss";

interface props {
  callerName: string;
  isSessionsHovered: boolean;
  sessionId: string;
  handleSessionIdClick: (id: string, sessionId: string) => void;
  currentSelected: string;
  id: string;
}

export default function OnGoingCallIndicator(props: props) {
  /* handlers */
  const handleSessionClick = () =>
    props.handleSessionIdClick(props.id, props.sessionId);

  return (
    <div className="agent-on-going-call-indicator" onClick={handleSessionClick}>
      <div className="caller-name">{props.callerName}</div>
      <img
        src={glowingDotGreen}
        alt=""
        className={`green-dot__indicator ${
          props.isSessionsHovered ? "" : "visibilityHiddenGlobal"
        }`}
      />
    </div>
  );
}
