import React, { useState, useEffect, useRef } from "react";
import {
  setSelectedIconAgent,
  setShowRightSideBarAgent,
} from "../../../../redux/agentDesktop/filterReducer/rightSidePopup/action/rightSidePopup.action";
// import getDate
// import {
//   getAllReportCsvData,
//   setSelectedIcon,
//   refreshAllFilters,
//   refreshApi,
//   refreshFilters,
//   setLastUpdatedTime,
//   setSelectedFilterType,
//   setShowColumnCustomizePop,
//   setShowRightSideBar,
//   toggleRefresh,
//   toggleShowColumnCustomizePop,
// } from "../../../redux/filters/actions";
import NewRightSidebarpop from "./rightSidebarpop/NewRightSidebarpop";
import { getDayInCorrectFormat } from "../../../../utils/ConvertTime";
// import RightSidebarpop from "../rightSidebarpop/RightSidebarpop";
import { useDispatch, useSelector } from "react-redux";
import styles from "./NewRightSidebarComp.module.scss";
// import { RootState } from "../../../redux";
import { RootState } from "../../../../redux";
import axios from "axios";
import {  AGENT_DESKTOP_API } from "../../../../services/ApiRoutes";
import {
  getDashboardAPIData,
  getPerformanceTableData
} from "../../../../redux/agentDesktop/dashboard/actions";
// import {setDashboardCardDetails} from "../../../../redux/agentDesktop/dashboard/actions/dashboard.actions"

// import { lastUpdatedText } from "../../../utils/timeAndDate/time.util";
// import KPIWrapper from "../kpiWrapper/KPIWapper";
// import ColumnCustomizeWrapper from "../../moduleComponents/columCustomize/ColumnCustomizeWrapper";
// import ColumnEditName from "../../moduleComponents/columCustomize/columnEditName/ColumnEditName";
// import { useCurrentPageTab } from "../../../hooks";
// import {
//   getMessages,
//   getSummaryByAPI,
// } from "../../../redux/logger/loggerState/action";
// import { getLoggerReportDataAPI } from "../../../redux/logger/report/actions";
// import { getAnalyticsAPIData } from "../../../redux/analytics/actions";
// import moment from "moment";
// import Loader from "../loader/Loader";
// import { getDataFromSchema } from "../../../utils";
// import { getDayInCorrectFormat } from "../../../utils/ConvertTime";
// import { toast, ToastContainer } from "react-toastify";
// import { loader } from "../../../theme/assets/svg/rightSideIcon";
// import { each } from "lodash";
// import screenType from "../../../screens/screenType";
// import { channel } from "diagnostics_channel";
// import PravidIcons from "../pravidIcons/PravidIcons";
// import { Mixpanel } from "../../../utils/mixpanelSetup";
// import { format } from "date-fns";
// import { someFiltersExist } from "../../../utils/validators/validators";
import PravidIcons from "../../generic/pravidIcons/PravidIcons";
interface IconObj {
  name: string;
  firstIcon: any;
  secondIcon?: any;
  isActive?: boolean;
}
interface props {
  /**
   * - name
   * - firstIcon
   * - secondIcon
   * - isActive?
   */
  icons: Array<IconObj>;
}
//
export default function NewRightSidebarComp(props: props) {
  //const [selectedIcon, setSelectedIcon] = useState<string | null>(null);
  const selectedIcon = useSelector((state: RootState) => {
    return state.agentDesktopReducer.rootFilterReducer.rightSidePopupReducer
      .selectedIcon;
  });

  const agentId = useSelector((store:RootState)=>store.loginReducer.userLoginInfo.userDetail["_id"])
  const accountId = useSelector((store:RootState)=>store.loginReducer.userLoginInfo.userDetail.accountDetails[0]["id"])
  const show = useSelector(
    (state: RootState) =>
      state.agentDesktopReducer.rootFilterReducer.rightSidePopupReducer
        .showRightSideBar
  );
  const dashboardCardDetails = useSelector(
    (state: RootState)=>
    state.agentDesktopReducer.dashboardReducer.dashboardCardDetails
  )
  const refP = useRef<any>(null);
  const refDash = useRef<any>(null);
  // const iconUrl = useSelector(
  //   (state: RootState) => state?.loginReducer?.userLoginInfo?.iconsBaseUrl
  // );
  const iconUrl = useSelector(
    (state: RootState) => "/images/pravidicons/"
  );

  const loginSessionTime = useSelector(
    (state:RootState)=> state?.leftMenuReducer?.loginTime
  )
  // const lastUpdated = useSelector(
  //   (state: RootState) => state.filterReducers.lastUpdated
  // );
  const showPopupForColumnCustomize = useSelector(
    (state: RootState) => state.filterReducers.showColumnCustomizePopup
  );
const role = useSelector(
  (state: RootState) => state.loginReducer.userLoginInfo.userDetail.role);

  const channelTab = useSelector(
    (store: any) => store?.agentDesktopReducer?.tabReducer?.backendRefenceKey
  );

  const startDate = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer?.rootFilterReducer?.calenderFilterReducer
        ?.formatedStartDate
  );

  const endDate = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer?.rootFilterReducer?.calenderFilterReducer
        ?.formatedEndDate
  );

  const dispatch = useDispatch();
  async function handleClickOnIcons(e: IconObj) {
      if (
        (selectedIcon === e.name && show) ||
        e.name === "Last Updated" ||
        e.name === "Shuffle Column"
      ) {
        if(role == "Agent Desktop"){
        const currentTime= new Date()
        const url=AGENT_DESKTOP_API.UPDATE_STATUS
        const data={
          "previousState":"refresh","currentState":"refresh",agentId:agentId, accountId:accountId
        }
        const res =axios
        .post(url,data)
      } else {
        if (channelTab) {
            dispatch(
              getDashboardAPIData(
                {
                  start_date: startDate,
                  end_date: endDate,
                  channel: channelTab,
                  adminId: agentId,
                },
              )
            );

            dispatch(
              getPerformanceTableData({
                start_date: startDate,
                end_date: endDate,
                channel: channelTab,
                adminId: agentId,
              })
            );
        }
    }
        // dispatch(setRefreshData(loginSessionTime,currentTime))
        dispatch(setSelectedIconAgent(""));
        dispatch(setShowRightSideBarAgent(false));
      
      } else {
        dispatch(setSelectedIconAgent(e.name));
        dispatch(setShowRightSideBarAgent(true));
  
        // if(isActivePageType === screenType.logger.name && currentLoggerPage=="Report"&&selectedIcon==="Download"){
        //   dispatch(setShowRightSideBar(false));
        // }
      }
   
    
  }
  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const filterSelectedDate = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.rootFilterReducer.calenderFilterReducer
  );
  const currentSelectedDate = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.rootFilterReducer.calenderFilterReducer
  );

  const [showBlue, setShowBlue] = useState<any>(false);

  const [directDownload, setDataforoptions] = useState<any>(true);

  // useEffect(() => {
  //   dispatch(setShowRightSideBarAgent(false));
  // }, [isActivePageType]);

  return (
    <div ref={refP} className={styles.rightsidebarcontianer}>
      {props.icons.map((e, i) => {
        if (e.isActive)
          return (
            <div
              key={i}
              className={
                styles.iconswrapperrightside + " " + styles.tooltiparea
              }
              id={"auto_" + e.name?.trim().replace(/\s/g, "")}
            >
              <button
                onClick={() => {
                  handleClickOnIcons(e);
                  // e.name === "Last Updated" && handleRefresh(e);
                  // e.name === "Shuffle Column" &&
                  //   showPopupForColumnCustomization();
                  // e.name === "Download" &&
                  //   isActivePageType !== "Analytics" &&
                  //   directDownload &&
                  //   currentLoggerPage == "Report" &&
                  //   handleDirectDownload();
                }}
                className={`${styles.rightsidebaricosn} `}
              >
                {/* {e.name == "Filter" &&
                  selectedIcon != "Filter" &&
                  someFiltersExist(selectedFilterOptions) && (
                    <div className={styles.notificationode}></div>
                  )} */}
                {/* {e.name !== "Last Updated" ? (
                  <img
                    src={
                      selectedIcon === e.name
                        ? `${iconUrl}${e.secondIcon}.svg`
                        : `${iconUrl}${e.firstIcon}.svg`
                    }
                    alt="io"
                  />
                ) : ( */}
                  <PravidIcons
                    activeIcon={showBlue ? e.secondIcon : e.firstIcon}
                    alt="alt"
                  />
                {/* )} */}
              </button>
              {e.name === "Last Updated" ? (
                <div className={styles.tooltiptextsp}>
                  {/* {lastUpdatedText(new Date(lastUpdated))} */}
                </div>
              ) : (
                <div
                  className={styles.tooltiptext}
                  style={e.name === "Calendar" ? { width: "175px" } : {}}
                >
                  {e.name === "Shuffle Column"
                    ? "Customize Table"
                    : e.name === "Calendar"
                    ? getDayInCorrectFormat(currentSelectedDate)
                    : e.name === "KPI customisation"
                    ? "KPI Selection"
                    : e.name}
                </div>
              )}
            </div>
          );
        else return null;
      })}

      <NewRightSidebarpop
        selectedIcon={selectedIcon}
        setSelectedIcon={(e: any) => {
          dispatch(setSelectedIconAgent(e));
        }}
        ref={refP}
      />

      {/* {showPopupForColumnCustomize && <ColumnCustomizeWrapper />}
      {showColumnEditName && <ColumnEditName />}
      {isSchemaUpdating && (
        <div className={styles.loader}>
          <div>
            <Loader></Loader>
          </div>
        </div> */}
      {/* )} */}
    </div>
  );
}
