import { Reducer } from "redux";
import actionType from "../actionTypes";
import { stateInterface, actionInterface } from "../interface";

const initialState: stateInterface = {
  dashboardChartDetails: { card_details: {} },
  isLoadingDashboardChart: false,
  dashboardChartError: "",
  dashboardCardDetails: {},
  isLoadingDashboardCard: false,
  dashboardCardError: "",
  performanceTableChart: {},
  isLoadingPerformanceTableChart: false,
  performanceTableChartError: "",
};

const dashboardReducer: Reducer<stateInterface> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case actionType.DASHBOARD_API_DATA_REQUEST: {
      // const loadingkey: any = {};
      // loadingkey[action.payload.bodyData.uniqueId] = true;
      return {
        ...state,
        // isLoadingDashboardChart: true,
      };
    }
    // for making all cards details and charts in single object
    case actionType.DASHBOARD_API_DATA_SUCCESS: {
      // const loadingkey: any = {};
      // loadingkey[action.uniqueId] = false;
      const data = action.payload;
      data["card_details"]["callsInQueue"] = state?.dashboardChartDetails?.card_details?.callsInQueue;
      data["card_details"]["callsRinging"] = state?.dashboardChartDetails?.card_details?.callsRinging;

      return {
        ...state,
        isLoadingDashboardChart: false,
        dashboardChartDetails: {
          // ...action.payload,
          ...data,
        },
      };
    }
    case actionType.DASHBOARD_API_DATA_FAILURE: {
      // const loadingkey: any = {};
      // loadingkey[action.uniqueId] = false;
      return {
        ...state,
        isLoadingDashboardChart: true,
        dashboardChartError: action.payload,
      };
    }
    case actionType.SET_WEBSOCKET_ADMIN_DATA: {
      const newCardDetails = {
        ...state.dashboardChartDetails.card_details,
        ...action.payload,
      };
      return {
        ...state,
        dashboardChartDetails: {
          ...state.dashboardChartDetails,
          card_details: newCardDetails,
        },
      };
    }
    // case actionType.DASHBOARD_API_CARD_DATA_REQUEST: {
    //   return {
    //     ...state,
    //     isLoadingDashboardChart: true,
    //   };
    // }
    // for making single object for cardDetails
    // case actionType.DASHBOARD_API_CARD_DATA_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoadingDashboardCard: false,
    //     dashboardCardDetails: {
    //       card_details: {
    //         ...state.dashboardCardDetails?.card_details,
    //         ...action.payload,
    //       },
    //     },
    //   };
    // }

    // case actionType.UPDATE_DASHBOARD_API_CARD_DATA_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoadingDashboardCard: false,
    //     dashboardCardDetails: {
    //       card_details: {
    //         ...action.payload.dashboardCardDetails?.card_details,
    //       },
    //     },
    //   };
    // }

    // case actionType.DASHBOARD_API_CARD_DATA_FAILURE: {
    //   return {
    //     ...state,
    //     isLoadingDashboardCard: false,
    //     dashboarCardError: action.payload,
    //   };
    // }
    case actionType.PERFORMANCE_TABLE_DATA_REQUEST: {
      return {
        ...state,
        isLoadingPerformanceTableChart: true
      };
    }
    
    case actionType.PERFORMANCE_TABLE_DATA_SUCCESS: {
      
      return {
        ...state,
        isLoadingPerformanceTableChart: false,
        performanceTableChart:{
          ...action.payload,
        }
         
      };
    }
    case actionType.PERFORMANCE_TABLE_DATA_FAILURE: {
      
      return {
        ...state,
        isLoadingPerformanceTableChart: true,
        performanceTableChartError: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default dashboardReducer;
