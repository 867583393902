export enum orderSummaryActionTypes {
    GET_ALL_ADD_ONS = "GET_ALL_ADD_ONS",
    GET_SELECTED_DURATION ="GET_SELECTED_DURATION",

   GENERATE_ORDERID_REQUEST = "GENERATE_ORDERID_REQUEST",
   GENERATE_ORDERID_SUCCESS = "GENERATE_ORDERID_SUCCESS",
   GENERATE_ORDERID_FAILURE = "GENERATE_ORDERID_FAILURE",

   VERIFY_ORDERID_REQUEST = "VERIFY_ORDERID_REQUEST",
   VERIFY_ORDERID_SUCCESS = "VERIFY_ORDERID_SUCCESS",
   VERIFY_ORDERID_FAILURE = "VERIFY_ORDERID_FAILURE",

    APPLY_COUPON_CODE_REQUEST = "APPLY_COUPON_CODE_REQUEST",
    APPLY_COUPON_CODE_SUCCESS = "APPLY_COUPON_CODE_SUCCESS",
    APPLY_COUPON_CODE_FAILURE = "APPLY_COUPON_CODE_FAILURE",
    VALID_COUPON_CODE= "VALID_COUPON_CODE",

    MAIA_PAYMENT_REQUEST = "MAIA_PAYMENT_REQUEST",
    MAIA_PAYMENT_SUCCESS = "MAIA_PAYMENT_SUCCESS",
    MAIA_PAYMENT_FAILURE = "MAIA_PAYMENT_FAILURE",

    GET_MAIA_PAYMENT_STATUS_REQUEST = "GET_MAIA_PAYMENT_STATUS_REQUEST",
    GET_MAIA_PAYMENT_STATUS_SUCCESS = "GET_MAIA_PAYMENT_STATUS_SUCCESS",
    GET_MAIA_PAYMENT_STATUS_FAILURE = "GET_MAIA_PAYMENT_STATUS_FAILURE",
  }