import React from "react";
import styles from "./ProductCreated.module.scss";
import { deleteProductIcon } from "../../../../../../theme/assets/svg";
import Icon from "../../../../../generic/icon/Icon";

interface ProductCreatedProps {
  productCreatedData?: any;
  productCreatedIndex: number;
  onClickDeleteProduct?: any;
}

export const ProductCreated = ({
  productCreatedData,
  productCreatedIndex,
  onClickDeleteProduct,
}: ProductCreatedProps) => {
  return (
    <div
      className={styles.productCreatedWrapper}
      id={`auto_product_${productCreatedIndex}`}
      data-testid="product-card"
    >
      <div
        className={styles.productCreatedIndex}
        id={`auto_product_index_${productCreatedIndex}`}
      >
        {productCreatedIndex + 1}
      </div>
      <div className={styles.productCreatedDetails}>
        <div
          className={styles.productCreatedName}
          id={`auto_product_name_${productCreatedIndex}`}
        >
          {productCreatedData?.productType?.type}
        </div>
        <div
          className={styles.productCreatedDesc}
          id={`auto_useCase_name_${productCreatedIndex}`}
        >
          {productCreatedData?.useCase?.useCasename}
        </div>
      </div>
      <div className={styles.productDeleteIcon}>
        <Icon img_src={deleteProductIcon} onClick={onClickDeleteProduct} />
      </div>
    </div>
  );
};
