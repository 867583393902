import { takeLatest } from "redux-saga/effects";
import actionTypes from "../inviteUser.actionTypes";
import { sendInviteUserAPIWorker } from "./inviteUser.saga";

export default function* sendInviteUserWatcher(): any {
  yield takeLatest(
    actionTypes.SEND_INVITE_USER_REQUEST,
    sendInviteUserAPIWorker
  );
}
