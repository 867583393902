import actionTypes from "../trueCaller.actionTypes";
export function setTrueCallerData(payload: object) {
  return {
    type: actionTypes.TRUE_CALLER_DATA,
    payload: payload,
  };
}

export function updateTrueCallerProfilePic(payload: object) {
  return {
    type: actionTypes.UPDATE_TRUECALLER_PROFILE_PIC_REQUEST,
    payload: payload,
  };
}

export function deleteTrueCallerProfilePic(payload: object) {
  return {
    type: actionTypes.DELETE_TRUECALLER_PROFILE_PIC_REQUEST,
    payload: payload,
  };
}

export function clearTrueCallerData() {
  return {
    type: actionTypes.CLEAR_TRUECALLER_DATA,
  };
}

export function getAllCategories() {
  return {
    type: actionTypes.GET_ALL_CATEGORIES_REQUEST,
  };
}
// export function createTrueCaller(payload: object) {
//   return {
//     type: actionTypes.CREATE_TRUECALLER_DATA_REQUEST,
//     payload: payload,
//   };
// }

// export function updateTrueCaller(payload: object) {
//   return {
//     type: actionTypes.UPDATE_TRUECALLER_DATA_REQUEST,
//     payload: payload,
//   };
// }
