import { all, fork } from "redux-saga/effects";
import * as yourAccountWatcherSaga from "./yourAccount/yourAccount.rootSaga";
import * as billingWatcherSaga from "./billing/billing.rootSaga";
import * as settingsWatcherSaga from "./settings/settings.rootSaga";
import * as navigationWatcherSaga from "./navigation/saga";
export default function* profileRootSaga() {
  yield all([...Object.values(yourAccountWatcherSaga)].map(fork));
  yield all([...Object.values(billingWatcherSaga)].map(fork));
  yield all([...Object.values(settingsWatcherSaga)].map(fork));
  yield all([...Object.values(navigationWatcherSaga)].map(fork));
}
