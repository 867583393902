import React, { useEffect, useRef, useState } from "react";
import styles from "./DDWithInputOption.module.scss";
import { dropdownDownArrow } from "../../../theme/assets/svg";
import { searchIcon } from "../../../theme/assets/svg";
import SearchInputBox from "./searchInputBox/SearchInputBox";
import { validateInputMeta } from "../../../utils/validators/validators";

interface props {
  data: Array<{
    option: string;
    icon?: any;
    isExtraMargin?: boolean;
    isDoubleExtraMargin?: boolean;
  }>;
  handleChange: (value: string, key?: string, index?: number) => void;
  title: string; // label/placeHolder
  showLabel: boolean;
  titleIcon?: any;
  selectedItem: string;
  hasInputOption?: { isActive: boolean; value?: string; icon?: any };
  extraDisplayElement?: JSX.Element;
  showSearchBox?: boolean;
  extraWrapperClass?: string;
  extraEachDropdownClass?: string;
  extraOptionIconClass?: string;
  objectKey?: string;
  limitSelectedTextLength?: number;
  icon?: any;
  extraDropdownClass?: string;
  disableField?: boolean;
  error?: string;
  label?: string;
  index?: number;
  maxLength?: number;
}

export default function SingleDropdown(props: props) {
  /* useStates ----------- */
  const [showDropdown, setShowDropdown] = useState(false);
  const [selected, setSelected] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>([]);
  const [title, setTitle] = useState("");
  const [inputFocus, setInputFocus] = useState(false);

  /* other hooks ---------- */
  const ref = useRef<any>();
  const inputBoxRef = useRef<any>();

  /* useEffects ----------- */
  useEffect(() => {
    setFilteredData(props.data);
  }, [props.data]);

  useEffect(() => {
    if (selected !== "") {
      setTitle(selected);
      props.handleChange(selected, props.objectKey, props.index);
    } else {
      setTitle(props.title);
      props.handleChange(selected, props.objectKey, props.index);
    }
  }, [selected]);

  // clear the filter
  useEffect(() => {
    if (!showDropdown) {
      setFilteredData(props.data);
    }
  }, [showDropdown]);

  useEffect(() => {
    const listenerFunction = (e: any) => {
      if (!ref?.current?.contains(e.target)) {
        setShowDropdown(false);
      }
    };
    if (ref) {
      document.addEventListener("click", listenerFunction);
    }
    return () => {
      document.removeEventListener("click", listenerFunction);
    };
  }, [ref]);

  /* helper function --------- */
  function filterSearch(searchText: any) {
    const val = searchText.target.value;
    if (!validateInputMeta(val)) return;
    const filter_data_temp = props.data.filter((e) => {
      return e.option
        .toLowerCase()
        .includes(searchText.target.value.toLowerCase());
    });
    setFilteredData(filter_data_temp);
  }

  const formateSelectedValue = (text: string) => {
    if (props.limitSelectedTextLength) {
      if (text.length > props.limitSelectedTextLength) {
        return text.substring(0, props.limitSelectedTextLength) + "...";
      }
    }
    return text;
  };

  /* handlers -------- */
  const handleOtherOptionChange = (e: React.ChangeEvent) => {
    const elm = e.target as HTMLInputElement;
    setSelected(elm.value);
  };

  const handleInputBoxClick = () => {
    if (inputBoxRef?.current) inputBoxRef.current.focus();
  };

  const handleOtherOptionKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setShowDropdown(false);
    }
  };

  return (
    <>
      <span
        ref={ref}
        className={`wrapper ${
          props.disableField ? "disablePointerEventWithoutOpacity" : ""
        }`}
      >
        {props.showLabel && (
          <div className={styles.ddTitle}>
            {!props.extraDisplayElement && props.selectedItem && (
              <span className={styles["single-select-dropdown__label"]}>
                {props.title}
              </span>
            )}
          </div>
        )}
        <div
          className={`${styles.wrapper} ${props.extraWrapperClass} ${
            props.error ? styles.dropBoxError : ""
          }`}
        >
          {props.extraDisplayElement ? (
            <span
              onClick={() => {
                setShowDropdown((prev) => !prev);
              }}
            >
              {props.extraDisplayElement}
            </span>
          ) : (
            <div
              className={styles.dropBox}
              onClick={() => {
                setShowDropdown((prev) => !prev);
              }}
            >
              <span
                title={
                  props.limitSelectedTextLength
                    ? props.selectedItem?.length > props.limitSelectedTextLength
                      ? props.selectedItem
                      : ""
                    : ""
                }
                className={`${
                  props.selectedItem ? styles["item-selected__class"] : ""
                } ${styles.selectedDiv}`}
              >
                {props.titleIcon && (
                  <img
                    src={props.titleIcon}
                    className={` ${styles["icon-default"]} ${styles["icon-default-alt-three"]} `}
                  />
                )}
                <span>
                  {formateSelectedValue(props.selectedItem || props.title)}
                </span>
              </span>
              <img src={props.icon ? props.icon : dropdownDownArrow} alt="" />
            </div>
          )}

          {showDropdown ? (
            <div className={`${styles.dropdown} ${props.extraDropdownClass}`}>
              {props.showSearchBox && (
                <SearchInputBox
                  searchIcon={searchIcon}
                  placeholder="Search"
                  onChange={filterSearch}
                ></SearchInputBox>
              )}
              <div className={styles.dropdownData}>
                {filteredData.map((each: any, index: any) => {
                  return (
                    <div
                      key={index}
                      data-selected={selected === each}
                      className={`${styles.eachDropdown} ${props.extraEachDropdownClass}`}
                      onClick={() => {
                        setSelected((prev) => {
                          return each.option;
                        });
                        setShowDropdown(false);
                      }}
                    >
                      <>
                        {each.icon && (
                          <img
                            src={each.icon}
                            className={` ${styles["icon-default"]} ${
                              props.extraOptionIconClass
                            } ${
                              each.isExtraMargin
                                ? styles["icon-default-alt"]
                                : each.isDoubleExtraMargin
                                ? styles["icon-default-alt-two"]
                                : ""
                            }`}
                          />
                        )}
                        <span>{each.option}</span>
                      </>
                    </div>
                  );
                })}
                {props.hasInputOption?.isActive && (
                  <span
                    className={`${styles.otherDiv} ${styles.eachDropdown} ${
                      inputFocus ? styles.inputFocus : ""
                    }`}
                    onClick={handleInputBoxClick}
                  >
                    <img
                      src={props.hasInputOption.icon}
                      className={` ${styles["icon-default"]} ${props.extraOptionIconClass} ${styles["icon-default-alt-two"]} `}
                    />
                    <span className={styles.value}>
                      {props.hasInputOption.value}
                    </span>
                    <input
                      ref={inputBoxRef}
                      className={` ${styles.otherOptionInput}`}
                      onChange={handleOtherOptionChange}
                      onFocus={() => setInputFocus(true)}
                      onBlur={() => setInputFocus(false)}
                      maxLength={props.maxLength}
                    />
                  </span>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className={styles.errorMsgStyling}
          id={`auto_${props.label}_errMsg`}
        >
          {props.error}
        </div>
      </span>
    </>
  );
}
