import React, { useState, useEffect, useRef } from "react";
import styles from "./OrganizationComponent.module.scss";
import ToolTip from "../../../../components/generic/toolTip/ToolTip";
import Button from "../../../../components/generic/button/Button";
import {
  downloadIcon,
  viewIcon,
  crossIcon,
  signedIcon,
  sentEmailTime,
  refresh,
} from "../../../../theme/assets/profileSectionSvg";

import Modal from "../../../../components/generic/modal/Modal";
import { RootState } from "../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationAPIData,
  sendSignEmail,
  getOrganizationAPIDataRefress,
} from "../../../../redux/profile/yourAccount/organizationDetails/actions/organizationDetails.actions";
import { downloadPdfFile } from "../../../../utils/directDownloadPdf";
import { OrganizationForm } from "../../../../components/moduleComponents/organizationComponents/organizationForm/OrganizationForm";
import SendEmail from "../../../../components/generic/sendEmailPopUp/SendEmail";
import Timer from "../timerForReminder/Timer";
useRef
export default function OrganizationComponent() {
  const [createModal, setCreateModal] = useState(false);
  const [currentPdf, setCurrentPdf] = useState("");
  const [clickForSendEmail, setClickForSendEmail] = useState(false);
  const [kycFileName, setKycFileName] = useState<string>();

  const refKyc: any = useRef(null)
  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.id
  );

  const organizationInfo = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization.organizationDetails
  );

  const personalEmail = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo?.userDetail?.email
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      accountId &&
      (organizationInfo?.organizationName?.length === 0 ||
        organizationInfo?.gstNumber?.length === 0 ||
        Object.keys(organizationInfo).length === 0)
    ) {
      console.log("me hu line 57 orga")
      dispatch(getOrganizationAPIData(accountId));
    }
  }, [accountId]);
  // remove dependency organisationInfo
  //handle chnage
  const handleCloseEmailSend = () => {
    setClickForSendEmail(false);
    setKycFileName("");
  };

  const handleSendEmail = (data: any) => {
    let body = {
      fileName: kycFileName,
      email: data,
      accountId: accountId,
    };
    dispatch(sendSignEmail(body, handleCloseEmailSend));
  };
  const handleRefressData = () => {
    dispatch(getOrganizationAPIDataRefress(accountId));
  };

  const handleShowEmailPopUp = (data: string) => {
    setKycFileName(data);
    setClickForSendEmail(true);
  };

  /* for scroll */
  const handleClick = () => {
    console.log("hello scroll", refKyc)

    console.log("hello scroll hhgg")
    refKyc?.current.scrollIntoView({ behavior: 'smooth' });

  };

  return (
    <>
      <div className={styles.organizationComponentWrapper}>
        <OrganizationForm scroll={handleClick} />
        <div ref={refKyc}></div>
        {organizationInfo?.kycDocPath?.length > 0 ? (
          <div className={styles.organizationKycDiv}>
            <div className={styles.organizationHeadingContainer}>
              <div className={styles.organizationLabelText}>KYC Documents</div>
              <img
                src={refresh}
                className={styles.refressIcon}
                onClick={() => handleRefressData()}
              ></img>
            </div>
            {organizationInfo?.kycDocPath?.map((doc: any, i: number) => {
              return (
                <div className={styles.organizationKycInfoDiv} key={i}>
                  <div className={styles.organizationKycInfoRight}>
                    <div
                      className={styles.organizationKycinputType}
                      data-testid="file-name"
                    >
                      {doc.fileName}
                    </div>
                    <div id={"auto_kyc_tooltip"}>
                      <ToolTip about={doc.fileInfo} />
                    </div>

                    {doc?.isRequested &&
                      (new Date(doc?.expiredOn).getTime() -
                        new Date().getTime()) /
                      1000 >
                      0 &&
                      doc?.signatureStatus !== "SIGNED" && (
                        <Button
                          image_src_left={sentEmailTime}
                          text="Sent for Signing"
                          extraClass={styles.sentforSignBtn}
                          imgClass={styles.imgBtnsign}
                        />
                      )}
                    {doc?.filePath?.length > 0 && (
                      <Button
                        image_src_left={signedIcon}
                        text="Signed"
                        extraClass={styles.signedBtn}
                        imgClass={styles.imgBtnsign}
                      />
                    )}
                  </div>
                  <div className={styles.organizationKycInfoLeft}>
                    {doc?.filePath?.length > 0 ? (
                      <img
                        src={downloadIcon}
                        alt="DownloadIcon"
                        onClick={() =>
                          downloadPdfFile(doc?.filePath, doc?.fileName)
                        }
                        className={styles.IconStyling}
                      />
                    ) : doc?.isRequested &&
                      (new Date(doc?.expiredOn).getTime() -
                        new Date().getTime()) /
                      1000 >
                      0 ? (
                      <Timer baseTimer={doc?.expiredOn} />
                    ) : (
                      <Button
                        text="Request Sign"
                        extraClass={styles.requestSendEmailButtonStyle}
                        onClick={() => handleShowEmailPopUp(doc.fileName)}
                      />
                    )}

                    {doc?.filePath?.length > 0 && (
                      <Button
                        text="View"
                        extraClass={styles.viewButtonStyle}
                        image_src_left={viewIcon}
                        onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                          e.stopPropagation();
                          setCurrentPdf(`${doc.filePath}`);
                          setCreateModal(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        <Modal
          shown={createModal}
          close={() => {
            setCreateModal(false);
          }}
          extraClassStyleModalContent={styles.ModalPDFContent}
        >
          <div className={styles.closeIcon} data-file={currentPdf}>
            <img
              src={crossIcon}
              onClick={() => {
                setCreateModal(false);
              }}
              className={styles.IconStyling}
            />
          </div>
          <iframe
            src={currentPdf}
            data-file={currentPdf}
            className={styles.PdfViewStyling}
          ></iframe>
        </Modal>
      </div>
      {clickForSendEmail && (
        <SendEmail
          close={handleCloseEmailSend}
          onClickSend={handleSendEmail}
          personalEmail={personalEmail}
        />
      )}

    </>
  );
}
