import React, { PureComponent, useState, useEffect } from "react";
import NoDatamodel from "../../../../components/generic/noDatamodel/NoDatamodel";
import { noPhoneDataIcon } from "../../../../theme/assets/svg";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  Cell,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

function VerticalBarChart(props: any) {
  const [data, setData] = useState<any>([]);
  const [noData, setNoData] = useState<boolean>(true);

  const referenceKey = props.chartDetails?.referenceKey;
  const apiData = Object.entries(props?.chartData[referenceKey]);
  const key = props.chartDetails.cardName;

  useEffect(() => {
    if (props.chartDetails && props.chartData) {
      apiData.sort(function (a: any, b: any) {
        return b[1] - a[1];
      });
      if (apiData && apiData?.length < 6) {
        const finalData = apiData?.map((each: any, i: number) => {
          return {
            name: each[0],
            Count: each[1],
            amt: 0,
          };
        });
        setData(finalData);
      }
    }
  }, [props.chartDetails, props.chartData]);

  useEffect(() => {
    if (props?.chartData && props?.chartData[referenceKey]) {
      props?.chartData && props?.chartData[referenceKey]
        ? Object.values(props?.chartData[referenceKey]).every(
            (item) => item === 0
          )
          ? setNoData(true)
          : setNoData(false)
        : null;
    }
  }, [props?.chartData[referenceKey]]);

  return !noData ? (
    <div>
      <ComposedChart
        layout="vertical"
        width={280}
        height={270}
        data={data}
        margin={{
          top: 10,
          right: 10,
          bottom: 20,
          left: 12,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis type="number" padding={{ right: 20 }} />
        <YAxis
          dataKey="name"
          type="category"
          stroke="#00000"
          style={{ fontSize: "11px", fontWeight: "bold"}}
          dx={6}
          
        />
        <Tooltip />
        {/* <Legend /> */}
        <Bar
          dataKey="Count"
          barSize={12}
          fill="#0174FF"
          radius={[10, 10, 10, 10]}
        />
        {/* <Line dataKey="uv" stroke="#ff7300" /> */}
      </ComposedChart>
    </div>
  ) : (
    <div
      style={{
        width: "300px",
        height: "270px",
      }}
    >
      <NoDatamodel srcImg={noPhoneDataIcon}></NoDatamodel>
    </div>
  );
}
export default VerticalBarChart;
