import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../generic/loader/Loader";
import GaugeChart from "../charts/GaugeChart";
import CustomerData from "../customerData/CustomerData";

import styles from "./CustomerProfile.module.scss";
import { height } from "@mui/system";
import ProductDetail from "../productDetail/ProductDetail";

// interface props {
//   default: string;
// }
const chartDetails = [
  {
    title: "Ashish Kabade",
    type: "customerData",
    chartStyle: {
      width: "360px",
      height: "340px",
    },
    data: [
      {
        email: "ashishkabade@gmail.com",
        phone: "+91 8645356351",
        "KYC Status": "Verified",
        Gender: "Male",
        Age: "25",
        Location: "Bengaluru",
      },
    ],
  },
  {
    title: "Risk Category",
    type: "gaugeChart",
    chartStyle: {
      width: "450px",
      height: "340px",
    },
    data: [
      {
        name: "low",
        value: 50,
        color: "#25D366",
      },
      {
        name: "medium",
        value: 50,
        color: "#FBBC05",
      },
      {
        name: "high",
        value: 50,
        color: "#FF4034",
      },
    ],
  },
  {
    title: "Product Details",
    type: "productDetail",
    chartStyle: {
      width: "390px",
      height: "340px",
    },
    data: [
      {
        key: "sanctionedAmount",
        displayName: "Sanctioned Amount",
        value: "100000",
      },
      {
        key: "disbursedAmount",
        displayName: "Disbursed Amount",
        value: "50000",
      },
      {
        key: "loanID",
        displayName: "Loan ID",
        value: "2392839029043",
      },
      {
        key: "loanType",
        displayName: "Loan Type",
        value: "Car",
      },
      {
        key: "loanTenure",
        displayName: "Loan Tenure",
        value: "36 Months",
      },
      {
        key: "disbursementDate",
        displayName: "Disbursement Date",
        value: "11-09-2022",
      },
      {
        key: "sanctionedAmount",
        displayName: "Sanctioned Amount",
        value: "1282783",
      },
      {
        key: "interestRate",
        displayName: "Interest Rate",
        value: "7%",
      },
      {
        key: "penaltyInterst",
        displayName: "Penalty Interest Rate",
        value: "10%",
      },
      {
        key: "sanctionedAmount",
        displayName: "Next Due Date",
        value: "12 Feb 2023",
      },
      {
        key: "sanctionedAmount",
        displayName: "EMI Amount",
        value: "1535/-",
      },
    ],
  },
];

export default function CustomerProfileModel(props: any) {
  return (
    <div className={styles.wrapers}>
      {chartDetails &&
        chartDetails.map((each: any, i: number) => {
          return (
            <div
              className={styles.customerProfileDiv}
              key={i}
              style={{
                width: `${each.chartStyle.width}`,
                height: `${each.chartStyle.height}`,
              }}
            >
              <div className={styles.customerProfileHeaderDiv}>
                <p>{each.title}</p>
              </div>
              <div className={styles.customerProfilebodyDiv}>
                {each.type == "gaugeChart" ? (
                  <GaugeChart data={each.data} chartDetails={each} />
                ) : each.type == "customerData" ? (
                  <CustomerData data={each.data} />
                ) : (
                  <ProductDetail data={each.data} />
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
}
