import React, { useEffect, useRef, useState } from "react";
import style from "./NudgeBox.module.scss";
import {
  dragPlusSign,
  whatsAppNudge,
  infoNudge,
  callNudge,
  smsNudge,
  connectDot,
  connectDotActive,
  editNudgeIcon,
  emailNudge,
  humanHandOffRight,
} from "../../../../../theme/assets/svg/strategySvg";
import { useDrop } from "react-dnd";
import { useSelector, useDispatch } from "react-redux";
import {
  setAllNudgeData,
  updateNudgeExpected,
  updateConnectedNudgeStart,
  removeConnectedNudgeLine,
} from "../../../../../redux/strategy/nudgeCreation/actions";
import lineDotIcon from "../../../../../theme/assets/svg/campaign/lineDotIcon.svg";
import {
  setIdOfNudge,
  setPropForEachNudge,
  setPropForEachNudgeAll,
  setShowNudgeSetting,
} from "../../../../../redux/strategy/eachNudgeBox/actions/actionCreators";
import { ArcherContainer, ArcherElement } from "react-archer";
import moment from "moment";
import { toast } from "react-toastify";
import Xarrow from "react-xarrows";

interface props {
  data?: any;
  index: number;
  addFun: any;
}

function NudgeBox(props: props) {
  const [expected, setExpected] = useState(false);
  const [unexpected, setUnExpected] = useState(false);
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "channel",
      drop: (item) => addChannel(item, props.data),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [props.data]
  );
  const [{ isOver1 }, drop2] = useDrop(
    () => ({
      accept: "Accepted Outcome",
      drop: (item) => addExpected(item, props.data),
      collect: (monitor) => ({
        isOver1: !!monitor.isOver(),
      }),
    }),
    [props.data]
  );
  const [{ isOver2 }, drop3] = useDrop(
    () => ({
      accept: "Unaccepted Outcome",
      drop: (item) => addUnExpected(item, props.data),
      collect: (monitor) => ({
        isOver2: !!monitor.isOver(),
      }),
    }),
    [props.data]
  );

  const addChannel = (item: any, data: any) => {
    updateNudgeBoxchannel(data, props.index, item.channel);
  };

  const addExpected = (item: any, data: any) => {
    const obj: any = {};
    item.data?.map((e: string) => {
      obj[`${e}`] = "";
    });
    console.log("mere", obj);
    console.log("il", data);
    if (!data.timing) {
      dispatch(updateNudgeExpected(data, props.index, obj, "acceptedOutcome"));
    } else {
      toast.error(
        "Can not update by drag and drop ,Please update by pressing  edit icon"
      );
    }
  };

  const addUnExpected = (item: any, data: any) => {
    console.log("Unexp", item);
    const obj: any = {};
    item.data?.map((e: string) => {
      obj[`${e}`] = "";
    });
    console.log("mere", obj);
    if (!data.timing) {
      dispatch(
        updateNudgeExpected(data, props.index, obj, "unacceptedOutcome")
      );
    } else {
      toast.error(
        "Can not update by drag and drop .Please update by pressing  edit icon"
      );
    }
  };

  const dispatch = useDispatch();
  const boxStyle = { padding: "10px", border: "1px solid black" };

  //redux
  const allNudgeDetail = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.allNudgeDetail;
  });

  const nudgeStartEnd = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.connectedNudgeStartEnd;
  });

  const showConnection = useSelector((store: any) => {
    return store?.strategyModuleReducer?.nudgeReducer?.showDot;
  });

  console.log("bbn", allNudgeDetail, props.data);
  // logic for update nudge
  const updateNudgeBoxchannel = (data: any, index: number, channel: any) => {
    console.log("update 1", data);
    const toUpdate = allNudgeDetail;
    const newUpdated = toUpdate?.map((e: any) => {
      if (data.isDaywise) {
        if (data.flow === e?.flow) {
          const rangesArr = e?.ranges;
          rangesArr?.map((d: any) => {
            if (d?.days[0] === data?.days[0]) {
              const nudgeBoxArray = d?.nugeBoxes;
              if (nudgeBoxArray[index]["channel"] !== channel) {
                delete nudgeBoxArray[index]["acceptedOutcome"];
                delete nudgeBoxArray[index]["unacceptedOutcome"];
                delete nudgeBoxArray[index]["communicationWays"];
              }
              nudgeBoxArray[index]["channel"] = channel;
              d["nugeBoxes"] = nudgeBoxArray;
              return d;
            }
          });
          return e;
        } else {
          return e;
        }
      } else {
        if (data.flow === e?.flow) {
          const nudgeBoxArray = e?.nugeBoxes;
          if (nudgeBoxArray[index]["channel"] !== channel) {
            delete nudgeBoxArray[index]["acceptedOutcome"];
            delete nudgeBoxArray[index]["unacceptedOutcome"];
            delete nudgeBoxArray[index]["communicationWays"];
          }
          nudgeBoxArray[index]["channel"] = channel;
          e["nugeBoxes"] = nudgeBoxArray;
          return e;
        } else {
          return e;
        }
      }
    });
    console.log("update", newUpdated);
    dispatch(setAllNudgeData(newUpdated));

    //  dispatch(updateNudgeExpected(data , index , ["shh" , "hhd"] ,"expectedOutcome"))
    //  dispatch(updateNudgeExpected(data , index , ["jai ho" , "banja"] ,"unexpectedOutcome"))
  };

  const channelImg: any = {
    whatsApp: whatsAppNudge,
    call: callNudge,
    sms: smsNudge,
    mail: emailNudge,
    human: humanHandOffRight,
  };

  const handleclickforedit = (data: any, index: number) => {
    // console.log("edit", data, index, props.data);
    dispatch(setShowNudgeSetting(true));
    dispatch(
      setPropForEachNudgeAll({
        ...data,
        channel: data?.channel || "call",
        communicationWays: data?.communicationWays || "Two Way",
        scriptConfiguration: {
          ...data.scriptConfiguration,
          channel: data.scriptConfiguration?.channel || "call",
          communicationWays:
            data.scriptConfiguration?.communicationWays || "Two Way",
          humanHandOff: data.scriptConfiguration?.humanHandOff || false,
          paymentMethod: data?.scriptConfiguration?.paymentMethod || [],
          payment: {
            paymentType: data?.scriptConfiguration?.payment?.paymentType || "",
            paymentMode: data?.scriptConfiguration?.payment?.paymentMode || "",
          },
          partialPayment: data?.scriptConfiguration?.partialPayment || {
            [data?.flow]: false,
          },
          levelOfNegotiation:
            data?.scriptConfiguration?.levelOfNegotiation || {},
          minimumNegotiationPercentage:
            data?.scriptConfiguration?.minimumNegotiationPercentage || {},
        },
      })
    );
    dispatch(setPropForEachNudgeAll(data));
    dispatch(setIdOfNudge(index));
  };

  const onclickNudge = (id: any) => {
    dispatch(updateConnectedNudgeStart({ start: id, end: "" }));
  };
  const onclickNudgeBox = (id: any) => {
    const start = nudgeStartEnd?.start;
    if(id?.split("&")[0] == start?.split("&")[0]){
      toast.error("You can not connect same Nudge Box")
    }else{
      dispatch(updateConnectedNudgeStart({ start: start, end: id }));
    }
   
  };

  const onclickRemoveConnectedLine = (data: any) => {
    console.log("removed line clicked ");
    dispatch(removeConnectedNudgeLine(data));
  };

  // for line
  const findPositionToendLine = (id: any) => {
    let connectToTop = false;
    const toUpdate = allNudgeDetail;
    const newUpdated = toUpdate?.map((e: any) => {
      if (props.data.isDaywise) {
        if (props.data.flow === e?.flow) {
          const rangesArr = e?.ranges;
          rangesArr?.map((d: any) => {
            if (d?.days[0] === props.data?.days[0]) {
              const nudgeBoxArray = d?.nugeBoxes;
              nudgeBoxArray?.map((g: any) => {
                if (g.uniqueId == id) {
                  connectToTop = true;
                }
              });
              return d;
            }
          });
          return e;
        } else {
          return e;
        }
      } else {
        if (props.data?.flow === e?.flow) {
          const nudgeBoxArray = e?.nugeBoxes;
          nudgeBoxArray?.map((g: any) => {
            if (g.uniqueId == id) {
              connectToTop = true;
            }
          });
          return e;
        } else {
          return e;
        }
      }
    });
    return connectToTop;
  };

  useEffect(() => {
    const id =
      props.data?.acceptedOutcome &&
      Object.values(props.data?.acceptedOutcome)?.[0];
    if (id) {
      const result = findPositionToendLine(id);
      setExpected(result);
    }
  }, [props]);

  useEffect(() => {
    const id =
      props.data?.unacceptedOutcome &&
      Object.values(props.data?.unacceptedOutcome)?.[0];

    if (id) {
      const result = findPositionToendLine(id);

      setUnExpected(result);
    }
  }, [props]);

  //

  const idsAccept: any = props.data?.acceptedOutcome
    ? Object.values(props.data?.acceptedOutcome)
    : [];
  const idUnAccept: any = props.data?.unacceptedOutcome
    ? Object.values(props.data?.unacceptedOutcome)
    : [];

  const refStaticForNudgeOne = useRef<any>();
  const refMovingForNudgeOne = useRef<any>();
  const refStaticForNudgeTwo = useRef<any>();
  const refMovingForNudgeTwo = useRef<any>();

  const [positionOne, setPositionOne] = useState({});
  const [beingDraggedOne, setBeingDraggedOne] = useState(false);
  const [positionTwo, setPositionTwo] = useState({});
  const [beingDraggedTwo, setBeingDraggedTwo] = useState(false);

  console.log("index", props?.index);
  console.log("props.data?.uniqueId", props.data?.uniqueId);
  console.log("props.data?.acceptedOutcome", props.data?.acceptedOutcome);

  return (
    <ArcherElement
      id={props.data?.uniqueId}
      relations={[
        {
          targetId: "",
          targetAnchor: "left",
          sourceAnchor: "right",
          style: { strokeColor: "blue", strokeWidth: 1 },
        },
      ]}
    >
      <div className={style.nudgeBoxWrapper} id="auto_nudgeBoxWrapper">
        <div ref={drop3}>
          <div ref={drop}>
            <div
              ref={drop2}
              className={style.nudgeBoxOuter}
              id="auto_nudgeBoxOuter"
            >
              {showConnection ? (
                <p
                  className={style.dotEnd}
                  onClick={() =>
                    onclickNudgeBox(
                      `${props.data?.uniqueId}&${props.data?.flow}&${
                        props.index
                      }&${props.data?.days}&${
                        props?.data?.isDaywise
                      }&${"acceptedOutcome"}`
                    )
                  }
                >
                  {" "}
                </p>
              ) : (
                ""
              )}
              <div className={style.iconContainer}>
                {props.data?.channel &&
                props.data?.timing?.length == 2 &&
                props.data?.acceptedOutcome &&
                props.data?.unacceptedOutcome ? (
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      handleclickforedit(props.data, props.index);
                    }}
                    src={editNudgeIcon}
                    id="auto_editNudgeIcon"
                  ></img>
                ) : (
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      handleclickforedit(props.data, props.index);
                    }}
                    src={infoNudge}
                    id="auto_infoNudgeIcon"
                  ></img>
                )}
              </div>
              <div className={style.containerFlex}>
                <div className={style.imgDragDrop}>
                  {props.data?.channel && (
                    <img
                      src={channelImg[props.data?.channel]}
                      id="auto_channelImg"
                    ></img>
                  )}
                </div>

                <p className={style.headingPara}>{`${props.data?.flow}${
                  props.data?.isDaywise ? ` ${props.data?.days}` : ""
                }`}</p>
              </div>

              {props.data?.acceptedOutcome &&
              Object.keys(props.data?.acceptedOutcome)?.length ? (
                <div className={style.outcomesDiv}>
                  <div className={style.outcomeHeadingImg}>
                    <p className={style.paraheading} id="auto_paraheading">
                      Accepted
                    </p>
                    <ArcherElement
                      id={`${props.data?.uniqueId}_acceptedOutcome`}
                      relations={[
                        {
                          targetId: props.data?.acceptedOutcome
                            ? `${
                                Object.values(props.data?.acceptedOutcome)?.[0]
                              }`
                            : "",
                          targetAnchor: expected ? "top" : "left",
                          sourceAnchor: "right",
                          style: { strokeColor: "#0174FF", strokeWidth: 1 },
                        },
                      ]}
                    >
                      <img
                        onClick={() => {
                          idsAccept?.[0]?.length
                            ? onclickRemoveConnectedLine(
                                `${props.data?.uniqueId}&${props.data?.flow}&${
                                  props.index
                                }&${props.data?.days}&${
                                  props?.data?.isDaywise
                                }&${"acceptedOutcome"}`
                              )
                            : onclickNudge(
                                `${props.data?.uniqueId}&${props.data?.flow}&${
                                  props.index
                                }&${props.data?.days}&${
                                  props?.data.isDaywise
                                }&${"acceptedOutcome"}`
                              );
                        }}
                        src={
                          idsAccept?.[0]?.length ? connectDotActive : connectDot
                        }
                        id={"acc_dot"}
                      ></img>
                    </ArcherElement>
                  </div>
                  <div className={style.outcomesScroll}>
                    {Object.keys(props.data?.acceptedOutcome)?.map(
                      (e: any, i: any) => {
                        return (
                          <div
                            key={i}
                            className={`${style.optionList} ${style.optionList_line}`}
                          >
                            <img
                              className={style.iconStyling}
                              src={lineDotIcon}
                              id={`auto_lineDotIcon_acceptedOutcome_${i}`}
                            />
                            <li
                              className={style.optionPara}
                              id={`auto_optionPara_acceptedOutcome_${i}`}
                            >
                              {" "}
                              {e}
                            </li>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {props.data?.unacceptedOutcome &&
              Object.keys(props.data?.unacceptedOutcome)?.length ? (
                <div className={style.outcomesDiv}>
                  <div className={style.outcomeHeadingImg}>
                    <p className={style.paraheading}>Unaccepted</p>
                    <ArcherElement
                      id={`${props.data?.uniqueId}_unacceptedOutcome`}
                      relations={[
                        {
                          targetId: props.data?.unacceptedOutcome
                            ? `${
                                Object.values(
                                  props.data?.unacceptedOutcome
                                )?.[0]
                              }`
                            : "",
                          targetAnchor: unexpected ? "top" : "left",
                          sourceAnchor: "right",
                          style: { strokeColor: "#0174FF", strokeWidth: 1 },
                        },
                      ]}
                    >
                      <img
                        onClick={() => {
                          idUnAccept?.[0]?.length
                            ? onclickRemoveConnectedLine(
                                `${props.data?.uniqueId}&${props.data?.flow}&${
                                  props.index
                                }&${props.data?.days}&${
                                  props?.data?.isDaywise
                                }&${"unacceptedOutcome"}`
                              )
                            : onclickNudge(
                                `${props.data?.uniqueId}&${props.data?.flow}&${
                                  props.index
                                }&${props.data?.days}&${
                                  props?.data.isDaywise
                                }&${"unacceptedOutcome"}`
                              );
                        }}
                        src={
                          idUnAccept?.[0]?.length
                            ? connectDotActive
                            : connectDot
                        }
                        id={"unacc_dot"}
                      ></img>
                    </ArcherElement>
                  </div>
                  <div className={style.outcomesScroll}>
                    {Object.keys(props.data?.unacceptedOutcome)?.map(
                      (e: any, i: any) => {
                        return (
                          <div
                            key={i}
                            className={`${style.optionList} ${style.optionList_line}`}
                          >
                            <img
                              className={style.iconStyling}
                              src={lineDotIcon}
                              id={`auto_lineDotIcon_unacceptedOutcome_${i}`}
                            />
                            <li
                              className={style.optionPara}
                              id={`auto_optionPara_acceptedOutcome_${i}`}
                            >
                              {" "}
                              {e}
                            </li>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {!props.data?.acceptedOutcome &&
              !props.data?.unacceptedOutcome ? (
                <div className={style.dragBoxouter}>
                  <img src={dragPlusSign} id="auto_dragPlusSign"></img>
                </div>
              ) : (
                ""
              )}
              <div className={style.timeDiv} id="auto_timeDiv">
                {props.data?.timing
                  ? moment(props.data?.timing, "hh :mm").format("LT")
                  : ""}
              </div>

              {/* <Box
          text="drag "
          {...{
            addArrow: props.addFun,
            handler: "right",
            boxId: `${props.data?.uniqueId}&${props.data?.flow}&${
              props.index
            }&${props.data?.days}&${"acceptedOutcome"}`,
            boxData: {
              id: props.data?.uniqueId,
              flow: props.data?.flow,
              index: props.index,
              days: props.data?.days,
              key: "acceptedOutcome",
            },
          }}
        /> */}
              {/* <ArcherElement
            id= {props.data?.uniqueId}
            relations={[
              {
                targetId: props.data?.acceptedOutcome ? `${Object.values(props.data?.acceptedOutcome)?.[0]}` : "",
                targetAnchor: 'left',
                sourceAnchor: 'right',
                style: { strokeColor: 'blue', strokeWidth: 1 },
               
              },
            ]}
          >
            <div onClick={() => onclickNudge(`${props.data?.uniqueId}&${props.data?.flow}&${
              props.index
            }&${props.data?.days}&${"acceptedOutcome"}`)} style={boxStyle}>Element 2</div>
          </ArcherElement> */}
            </div>
          </div>
        </div>
      </div>
    </ArcherElement>
  );
}

export default NudgeBox;
