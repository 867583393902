import actionTypes from "../actionTypes";

export const getDashboardAPIData = (bodyData: any) => {
  return {
    type: actionTypes.DASHBOARD_API_DATA_REQUEST,
    payload: { bodyData },
  };
};


//for cards
export const getDashoardApiCards = (bodyData: any,token:any) => {
  return {
    type: actionTypes.DASHBOARD_API_CARD_DATA_REQUEST,
    payload: { bodyData,token },
  };
};

export const setWebSocketAdminData = (obj: object) => {
  return {
    type: actionTypes.SET_WEBSOCKET_ADMIN_DATA,
    payload: {...obj},
  };
};
// export const setDashboardCardDetails= (bodyData: any)=>{
//   return {
//     type: actionTypes.UPDATE_DASHBOARD_API_CARD_DATA_SUCCESS,
//     payload: bodyData
//   };
// }

export const getPerformanceTableData = (bodyData: any) => {
  return {
    type: actionTypes.PERFORMANCE_TABLE_DATA_REQUEST,
    payload: { bodyData },
  };
};