// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q41tFfY_jHlLLGwq7MIQ{align-items:center;border:.930687px solid #d8d8d8;box-sizing:border-box;border-radius:6.51481px;justify-content:center;background-color:#fff}.Q41tFfY_jHlLLGwq7MIQ img{margin-left:10px;width:20px;background-color:#fff}.Q41tFfY_jHlLLGwq7MIQ input{width:90%;padding:7px;border:none;outline:none;border-radius:5px}`, "",{"version":3,"sources":["webpack://./src/components/generic/searchInputBoxControlled/SearchInputBoxControlled.module.scss"],"names":[],"mappings":"AAAA,sBAEE,kBAAA,CAEA,8BAAA,CACA,qBAAA,CACA,uBAAA,CACA,sBAAA,CAEA,qBAAA,CAGF,0BACE,gBAAA,CACA,UAAA,CACA,qBAAA,CAGF,4BACE,SAAA,CACA,WAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA","sourcesContent":[".searchAreaDivLLP {\n  //   display: flex;\n  align-items: center;\n  /* border: 1px solid #000; */\n  border: 0.930687px solid #d8d8d8;\n  box-sizing: border-box;\n  border-radius: 6.51481px;\n  justify-content: center;\n  /* border-radius: 7px; */\n  background-color: #ffffff;\n}\n\n.searchAreaDivLLP img {\n  margin-left: 10px;\n  width: 20px;\n  background-color: #ffffff;\n}\n\n.searchAreaDivLLP input {\n  width: 90%;\n  padding: 7px;\n  border: none;\n  outline: none;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchAreaDivLLP": `Q41tFfY_jHlLLGwq7MIQ`
};
export default ___CSS_LOADER_EXPORT___;
