import { takeLatest } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import {
  updateOrganizationDataAPIWorker,
  getOrganizationDataAPIWorker,
  sendOTPDataAPIWorker,
  verifyOTPDataAPIWorker,
  verifyGstWorker,
  verifyPanWorker,
  sendSignEmailAPIWorker
} from "./organizationDetails.saga";

export default function* organizationDetailsWatcher(): any {
  yield takeLatest(
    actionTypes.ORGANIZATION_DATA_API_REQUEST,
    getOrganizationDataAPIWorker
  );
  yield takeLatest(
    actionTypes.UPDATE_ORGANIZATION_DATA_API_REQUEST,
    updateOrganizationDataAPIWorker
  );
  yield takeLatest(actionTypes.SEND_OTP_ORG_REQUEST, sendOTPDataAPIWorker);
  yield takeLatest(actionTypes.VERIFY_OTP_ORG_REQUEST, verifyOTPDataAPIWorker);
  yield takeLatest(actionTypes.VERIFY_GST_ORG_REQUEST, verifyGstWorker);
  yield takeLatest(actionTypes.VERIFY_PAN_ORG_REQUEST, verifyPanWorker);
  yield takeLatest(actionTypes.SEND_SIGN_EMAIL_REQUEST, sendSignEmailAPIWorker);
  yield takeLatest(actionTypes.ORGANIZATION_DATA_API_REFRESS_REQUEST, getOrganizationDataAPIWorker);
  
}
