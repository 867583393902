import React, { useState } from "react";
import CallDispositionTable from "../../../components/crm/moduleComponent/maiaCallTable/callDispositionTable/CallDispositionTable";
import DropRateAnalysisTable from "../../../components/crm/moduleComponent/maiaCallTable/dropRateAnalysisTable/DropRateAnalysisTable";
import styles from "./MaiaCall.module.scss";
import HorizontallProgress from "../../../components/crm/generic/horizontalProgress/HorizontalProgress";
import CrmCardComponent from "../../../components/crm/moduleComponent/crmCardComp/CrmCardComponent";
import CRMChartModel from "../../../components/crm/moduleComponent/chartModel/ChartModel";
import CRMDonutChart from "../../../components/crm/moduleComponent/charts/DonutChart";
import CRMDonutChartComponent from "../../../components/crm/moduleComponent/crmdonutChartComponent/CRMDonutChartComp";
import BiaxialBarChart from "../../../components/crm/moduleComponent/charts/BiaxialBarChart";
import Slide from "../../../components/generic/slide/Slide";
import CrmSimpleBarChart from "../../../components/crm/moduleComponent/charts/SimpleBarChart";

const blueColor = "#24BEE5";
const yellowColor = "#F9C74F";
const cardData = [
  { data: { name: "Total Unique Accounts Connected", value: "7382", isCard:false } },
  { data: { name: "Total Connected Calls", value: "10631", isCard:false } },
  { data: { name: "Connection Rate", value: "73.4%", isCard:false } },
  { data: { name: "Total Unhappy Path", value: "1852", isCard:false } },
  { data: { name: "AHT", value: "27 Sec", isCard:false } },
  { data: { name: "Initial Response Avg. Time", value: "5 sec", isCard:false } },
  { data: { name: "No. Of Fallback", value: "1153", isCard:false } },
  { data: { name: "No Message - Transcript", value: "500", isCard:false } },
];
const donutChat = {
  chartTitle: "KYC Status",
  chartType: "donutChart",
  chartStyle: {
    width: "700px",
    height: "400px",
  },
  isSlider: true,
  isCardData: false,
  dataPredue: [
    {
      name: "Acceptable",
      value: 3413,
      fillColor: blueColor,
    },
    {
      name: "Unacceptable",
      value: 2908,
      fillColor: yellowColor,
    },
  ],
  dataPostdue: [
    {
      name: "Acceptable",
      value: 3103,
      fillColor: blueColor,
    },
    {
      name: "Unacceptable",
      value: 1207,
      fillColor: yellowColor,
    },
  ],
};
const barChat = {
  legendData: ["Total Connected", "Negative to positive disposition change"],
  keys: [
    {
      name: "",
      icon: "",
      referenceKeyName: "risk",
      shortKey: "",
      fillColor: "#9ADBF9",
      position: 1,
    },
    {
      name: "Total Connected",
      icon: "",
      referenceKeyName: "june22",
      shortKey: "",
      fillColor: blueColor,
      position: 2,
    },
    {
      name: "Negative to positive disposition change",
      icon: "",
      referenceKeyName: "june23",
      shortKey: "",
      fillColor: yellowColor,
      position: 2,
    },
  ],
  chartStyle: {
    width: "500px",
    height: "400px",
    barSize: "55",
  },
  isSlider: false,
  isCardData: false,
  data: [
    {
      risk: "",
      june22: 10631,
      june23: 2471,
    },
  ],
};
const barChat1 = {
  legendData: ["Talk Time", "Connected Calls Count"],
  keys: [
    {
      name: "Talk Time",
      icon: "",
      referenceKeyName: "attempt_count",
      shortKey: "",
      fillColor: "#24BEE5",
      position: 1,
    },
    {
      name: "Connected Calls Count",
      icon: "",
      referenceKeyName: "attempt_count_number",
      shortKey: "",
      fillColor: "#24BEE5",
      position: 2,
    },
  ],
  chartStyle: {
    width: "1200px",
    height: "450px",
    barSize: "45",
  },
  data: [
    {
      attempt_count: 5,
      attempt_count_number: 10972,
    },
    {
      attempt_count: 10,
      attempt_count_number: 4089,
    },
    {
      attempt_count: 15,
      attempt_count_number: 2075,
    },
    {
      attempt_count: 20,
      attempt_count_number: 1357,
    },
    {
      attempt_count: 25,
      attempt_count_number: 1034,
    },
    {
      attempt_count: 30,
      attempt_count_number: 10478,
    },
    {
      attempt_count: 35,
      attempt_count_number: 752,
    },
    {
      attempt_count: 40,
      attempt_count_number: 520,
    },
    {
      attempt_count: 45,
      attempt_count_number: 950,
    },
    {
      attempt_count: 50,
      attempt_count_number: 2160,
    },
    {
      attempt_count: 55,
      attempt_count_number: 1100,
    },
    {
      attempt_count: 60,
      attempt_count_number: 2372,
    },
    {
      attempt_count: 65,
      attempt_count_number: 1930,
    },
    {
      attempt_count: 70,
      attempt_count_number: 1930,
    },
    {
      attempt_count: 75,
      attempt_count_number: 1930,
    },
    {
      attempt_count: 80,
      attempt_count_number: 2330,
    },
    {
      attempt_count: 85,
      attempt_count_number: 2330,
    },
    {
      attempt_count: 90,
      attempt_count_number: 778,
    },
    {
      attempt_count: 95,
      attempt_count_number: 4330,
    },
    {
      attempt_count: 100,
      attempt_count_number: 3030,
    },
  ],
};
export default function MaiaCallPage() {
  const [flow, setFlow] = useState<any>("Pre Due");
  return (
    <div className={styles.wrapper}>
      <div className={styles.cards}>
        {cardData.map((e, index) => {
          return (
            <div key={index} className={styles.eachCard}>
              {" "}
              <CrmCardComponent data={e.data} />
            </div>
          );
        })}
      </div>
      <div className={styles.objective}>
        <div className={styles.title}>Call Objective</div>
        <div className={styles.graph}>
          <div className={styles.preDue}>
            <HorizontallProgress
              title="Pre Due"
              left={{ title: "Acheived", value: 3413 }}
              right={{ title: "Unacheived", value: 2908 }}
            ></HorizontallProgress>
          </div>
          <div className={styles.postDue}>
            <HorizontallProgress
              title="Post Due"
              left={{ title: "Acheived", value: 3103 }}
              right={{ title: "Unacheived", value: 1207 }}
            ></HorizontallProgress>
          </div>
        </div>
      </div>
      <div className={styles.charts}>
        <div className={styles.eachBarChartBar}>
          <div className={styles.title}>
            <p>Talk Time Distribution Dec 22</p>
            <div style={{ fontSize: "14px", color: "#838383" }}>
              <p className={styles.dashedDataDiv}><hr style={{border: "1px dashed #0047FF", width:"50px", marginRight:"5px"}} />Mean: 20</p>
              <p className={styles.dashedDataDiv}><hr style={{border: "1px dashed #F94144", width:"50px", marginRight:"5px"}} />Median: 40</p>
            </div>
          </div>
          <hr className={styles.horizontalLine} />
          <CrmSimpleBarChart data={barChat1.data} chartDetails={barChat1} />
          <div className={styles.barchartBottomClass}>
            <p style={{ padding: "10px 0" }}>
            37381 calls had a talk time greater than the mean value (20)
            </p>
            <p>
            25117 calls had a talk time greater than the median value (40){" "}
            </p>
          </div>
        </div>
        <div className={styles.eachChartBar}>
          <div className={styles.title}>Payment Behaviour Change</div>
          <hr className={styles.horizontalLine} />
          <BiaxialBarChart data={barChat.data} chartDetails={barChat} />
        </div>
        <div className={styles.eachChartDonut}>
          <div className={styles.title}>
            <p>Disposition Outcome</p>
            <div style={{ padding: "0 10px" }}>
              <Slide
                text={["Pre Due", "Post Due"]}
                defaultIndex={0}
                onChange={(a: string | number) => {
                  setFlow(a);
                }}
                disabled={false}
              />
            </div>
          </div>
          <hr className={styles.horizontalLine} />
          <CRMDonutChartComponent
            data={
              flow == "Pre Due" ? donutChat.dataPredue : donutChat.dataPostdue
            }
            chartDetails={donutChat}
          />
        </div>
      </div>
      {/* <BiaxialBarChart data={barChat1.data} chartDetails={barChat1} /> */}
      <div className={styles.table}>
        <div>
          <div className={styles.title}>Call Disposition</div>
          <CallDispositionTable />
        </div>
        <div>
          <div className={styles.title}>Drop Rate Analysis</div>
          <DropRateAnalysisTable />
        </div>
      </div>
    </div>
  );
}
