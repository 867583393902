import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { STRATEGY_API, config } from "../../../../services/ApiRoutes";
import actionTypes from "../strategy.actionTypes";
import _ from "lodash";

export function* getStrategyOptionsAPIWorker(action: any): any {
  try {
    toast.dismiss();
    //toast.loading("Loading Data");
    if (action) {
      const result = yield call(
        config.GET,
        `${STRATEGY_API.GET_STRATEGY_OPTIONS}`
      );
      yield put({
        type: actionTypes.GET_STRATEGY_OPTIONS_API_SUCCESS,
        payload: result.data.data,
      });
      //toast.success(result.data.data.message);
      // toast.dismiss();
    }
  } catch (error: any) {
    toast.dismiss();
    toast.error(error.response?.data?.error?.message);
    console.error(error.response?.data?.error?.message);
    yield put({
      type: actionTypes.GET_STRATEGY_OPTIONS_API_FAILURE,
      payload: error,
    });
  }
}

export function* getUserPersonaOptionsAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_HEADER,
        `${STRATEGY_API.GET_USER_PERSONA_OPTIONS}`,
        {
          headers: { "x-access-token": action.payload.token },
        }
      );

      yield put({
        type: actionTypes.GET_USER_PERSONA_OPTIONS_API_SUCCESS,
        payload: result?.data?.data,
      });
      // console.log(result.data.data, "hhh");
      toast.success(result?.data?.data?.message);
    }
  } catch (error: any) {
    toast.dismiss();
    toast.error(error.response?.data?.error?.message);
    console.error(error.response?.data?.error?.message);
    yield put({
      type: actionTypes.GET_USER_PERSONA_OPTIONS_API_FAILURE,
      payload: error,
    });
  }
}

export function* getStrategiesDataAPIWorker(action: any): any {
  try {
    if (action) {
      toast.dismiss();
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${STRATEGY_API.GET_STRATEGIES_BY_CLIENT_NAME}`,
        {
          params: action.payload,
          // headers: { "x-access-token": action.payload.token },
        }
      );

      yield put({
        type: actionTypes.GET_STRATEGIES_DATA_API_SUCCESS,
        payload: result?.data?.data,
      });
      toast.dismiss();
      toast.success(result.data.data.message);
    }
  } catch (error: any) {
    toast.dismiss();
    toast.error(error.response?.data?.error?.message);
    console.error(error.response?.data?.error?.message);
    yield put({
      type: actionTypes.GET_STRATEGIES_DATA_API_FAILURE,
      payload: error,
    });
  }
}

export function* getUserPersonasDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${STRATEGY_API.GET_USER_PERSONAS_BY_ID}`,
        {
          params: _.omit(action.payload, "token"),
          headers: { "x-access-token": action.payload.token },
        }
      );

      yield put({
        type: actionTypes.GET_USER_PERSONA_DATA_API_SUCCESS,
        payload: result.data.data,
      });
      toast.success(result.data.data.message);
    }
  } catch (error: any) {
    toast.dismiss();
    toast.error(error.response?.data?.error?.message);
    console.error(error.response?.data?.error?.message);
    yield put({
      type: actionTypes.GET_USER_PERSONA_DATA_API_FAILURE,
      payload: error,
    });
  }
}

export function* deleteStrategyDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.DELETE_WITH_BODY,
        `${STRATEGY_API.DELETE_STRATEGY_BY_ID}`,
        { id: action.payload.id }
      );
      const res = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${STRATEGY_API.GET_STRATEGIES_BY_CLIENT_NAME}`,
        {
          params: action.payload,
          // headers: { "x-access-token": action.payload.token },
        }
      );
      yield put({
        type: actionTypes.DELETE_STRATEGY_DATA_API_SUCCESS,
        payload: result?.data?.data,
      });
      yield put({
        type: actionTypes.GET_STRATEGIES_DATA_API_SUCCESS,
        payload: res?.data?.data,
      });
      toast.dismiss();
      toast.success(result?.data?.data?.message);
    }
  } catch (error: any) {
    console.error(error.response?.data?.error?.message);
    yield put({
      type: actionTypes.DELETE_STRATEGY_DATA_API_FAILURE,
      payload: error,
    });
    toast.error(error.response?.data?.error);
  }
}

export function* updateStrategyDataAPIWorker(action: any): any {
  // console.log("action", action.payload);
  toast.dismiss();
  toast.loading("Please wait ...");
  try {
    if (action) {
      const result = yield call(
        config.PUT_WITH_BODY,
        `${STRATEGY_API.UPDATE_STRATEGY_BY_ID}`,
        action.payload
      );
      const res = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${STRATEGY_API.GET_STRATEGIES_BY_CLIENT_NAME}`,
        {
          params: action.payload,
          // headers: { "x-access-token": action.payload.token },
        }
      );
      yield put({
        type: actionTypes.UPDATE_STRATEGY_DATA_API_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: actionTypes.GET_STRATEGIES_DATA_API_SUCCESS,
        payload: res.data.data,
      });
      toast.dismiss();
      toast.success(result.data.data.message, { autoClose: 400 });
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.UPDATE_STRATEGY_DATA_API_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    toast.error(error.response?.data?.error);
    console.error(error.response?.data?.error);
  }
}

export function* createStrategyDataAPIWorker(action: any): any {
  toast.dismiss();
  toast.loading("Please wait ...");
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_BODY,
        `${STRATEGY_API.CREATE_STRATEGY_BY_CLIENT_NAME}`,
        action.payload
      );
      const res = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${STRATEGY_API.GET_STRATEGIES_BY_CLIENT_NAME}`,
        {
          params: action.payload,
          // headers: { "x-access-token": action.payload.token },
        }
      );
      yield put({
        type: actionTypes.CREATE_STRATEGY_DATA_API_SUCCESS,
        payload: result.data.data,
      });
      yield put({
        type: actionTypes.STORE_CREATED_STRATEGY,
        payload: result.data.data.strategy[0],
      });
      yield put({
        type: actionTypes.GET_STRATEGIES_DATA_API_SUCCESS,
        payload: res.data.data,
      });
      toast.dismiss();
      // toast.success("Please wait ...");
      toast.success(result.data.data.message, { autoClose: 500 });
    }
  } catch (error: any) {
    console.error(error.response?.data?.error?.message);
    yield put({
      type: actionTypes.CREATE_STRATEGY_DATA_API_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    toast.error(error.response?.data?.error?.message);
  }
}

export function* getWorkspaceFlowsDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${STRATEGY_API.GET_WORK_FLOWS}`,
        {
          params: _.omit(action.payload, "token"),
          headers: { "x-access-token": action.payload.token },
        }
      );
      yield put({
        type: actionTypes.GET_WORK_FLOWS_DATA_API_SUCCESS,
        payload: result.data.data,
      });
      toast.success(result.data.data.message);
    }
  } catch (error: any) {
    toast.dismiss();
    toast.error(error.response?.data?.error?.message);
    console.error(error.response?.data?.error?.message);
    yield put({
      type: actionTypes.GET_WORK_FLOWS_DATA_API_FAILURE,
      payload: error,
    });
  }
}

export function* addWorkspaceFlowDataAPIWorker(action: any): any {
  // console.log("action", action.payload);
  toast.dismiss();
  toast.loading("Please wait ...");
  try {
    //toast.loading("Loading Data");
    if (action) {
      const result = yield call(
        config.PUT_WITH_HEADER_BODY,
        `${STRATEGY_API.ADD_WORK_FLOW}`,
        {
          strategyId: action.payload.strategyId,
          flows: action.payload.addFlows,
        },
        { "x-access-token": action.payload.token }
      );

      const setResult = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${STRATEGY_API.SET_WORK_FLOW}`,
        { data: action.payload.setFlows, 
          accountId: action.payload.accountId,
          useCaseId : action.payload.useCaseId,
          teamId : action.payload.teamId
        },
        {
          "x-access-token": action.payload.token,
        }
      );
      const getResult = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${STRATEGY_API.GET_STRATEGIES_BY_CLIENT_NAME}`,
        {
          params: { 
            clientName: action.payload.clientName,
            useCaseId : action.payload.useCaseId,
            teamId : action.payload.teamId
          },
          // headers: { "x-access-token": action.payload.token },
        }
      );
      yield put({
        type: actionTypes.ADD_WORK_FLOW_DATA_API_SUCCESS,
        payload: result.data.data,
      });
      const selectedFlow = result.data.data;
      const flowss = {
        ...selectedFlow,
        flows: selectedFlow.flows,
        addedFlows: true,
        bucketCreationSuccess: true,
      };
      yield put({
        type: actionTypes.STORE_CREATED_STRATEGY,
        payload: flowss,
      });
      yield put({
        type: actionTypes.SET_WORK_FLOW_DATA_API_SUCCESS,
        payload: setResult.data.data,
      });
      yield put({
        type: actionTypes.GET_STRATEGIES_DATA_API_SUCCESS,
        payload: getResult.data.data,
      });
      // toast.success(result.data.data.message);
      toast.dismiss();
      toast.success("Added WorkFlow SuccessFully");
    }
  } catch (error: any) {
    toast.dismiss();
    yield put({
      type: actionTypes.ADD_WORK_FLOW_DATA_API_FAILURE,
      payload: error?.response?.data?.error,
    });
    error.response?.data?.error
      ? toast.error(error.response?.data?.error)
      : toast.error("Adding WorkFlow was unsuccessful");
    console.error(error.response?.data?.error?.message);
  }
}

export function* setWorkspaceFlowDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${STRATEGY_API.SET_WORK_FLOW}`,
        _.omit(action.payload, "token"),
        {
          "x-access-token": action.payload.token,
        }
      );
      yield put({
        type: actionTypes.SET_WORK_FLOW_DATA_API_SUCCESS,
        payload: result.data.data,
      });
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.SET_WORK_FLOW_DATA_API_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    toast.error(error.response?.data?.error);
    console.error(error.response?.data?.error);
  }
}

export function* addUserPersonaDataAPIWorker(action: any): any {
  // console.log("action", action.payload);
  try {
    if (action) {
      const result = yield call(
        config.PUT_WITH_HEADER_BODY,
        `${STRATEGY_API.ADD_USER_PERSONAS_BY_ID}`,
        _.omit(action.payload, "token"),
        {
          "x-access-token": action.payload.token,
          "Content-Type": "application/json",
        }
      );
      yield put({
        type: actionTypes.ADD_USER_PERSONA_DATA_API_SUCCESS,
        payload: result.data.data,
      });
      toast.dismiss();
      toast.success(result.data.data.message);
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.ADD_USER_PERSONA_DATA_API_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    toast.error(error.response?.data?.error);
    console.error(error.response?.data?.error);
  }
}
