import { orderSummaryActionTypes } from "../orderSummary.actionTypes";

export function setSelectedAddons(payload: any) {
  return {
    type: orderSummaryActionTypes.GET_ALL_ADD_ONS,
    payload,
  };
}

export function setSelectedDuration(payload: any) {
  return {
    type: orderSummaryActionTypes.GET_SELECTED_DURATION,
    payload,
  };
}

export function getCouponApplied(payload: any) {
  return {
    type: orderSummaryActionTypes.APPLY_COUPON_CODE_REQUEST,
    payload,
  };
}

export function getValidCouponCode(payload: string) {
    return {
      type: orderSummaryActionTypes.VALID_COUPON_CODE,
      payload,
    };
  }

  export function generateOrderId(payload: any , isBeginerPlan ?:boolean) {
    return {
      type: orderSummaryActionTypes.GENERATE_ORDERID_REQUEST,
      payload,
      isBeginerPlan
    };
  }
  export function verifyOrderPayment(payload: any) {
    return {
      type: orderSummaryActionTypes.VERIFY_ORDERID_REQUEST,
      payload,
    };
  }

  export function makeMaiaPayment(payload: string) {
    return {
      type: orderSummaryActionTypes.MAIA_PAYMENT_REQUEST,
      payload,
    };
  }

  export function getMaiaPaymentStatus(payload: object) {
    return {
      type: orderSummaryActionTypes.GET_MAIA_PAYMENT_STATUS_REQUEST,
      payload,
    };
  }