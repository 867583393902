// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vdUhOJyBOELV8YCMwzil{width:20px;margin-right:5px}.vdUhOJyBOELV8YCMwzil:hover:not(.uZ0z5qZziXa2f8Vkkqbj){cursor:pointer;opacity:1}.vdUhOJyBOELV8YCMwzil:focus{outline:1px dotted #999}.vdUhOJyBOELV8YCMwzil.uZ0z5qZziXa2f8Vkkqbj{cursor:not-allowed;opacity:.3}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/uiKit/iconComponent/Icon.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,gBAAA,CAEF,uDACE,cAAA,CACA,SAAA,CAEF,4BACE,uBAAA,CAEF,2CACE,kBAAA,CACA,UAAA","sourcesContent":[".icon {\n    width: 20px;\n    margin-right: 5px;\n  }\n  .icon:hover:not(.disabled) {\n    cursor: pointer;\n    opacity: 1;\n  }\n  .icon:focus {\n    outline: 1px dotted #999999;\n  }\n  .icon.disabled {\n    cursor: not-allowed;\n    opacity: 0.3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `vdUhOJyBOELV8YCMwzil`,
	"disabled": `uZ0z5qZziXa2f8Vkkqbj`
};
export default ___CSS_LOADER_EXPORT___;
