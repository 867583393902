import { toast } from "react-toastify";
import {
  config,
  STRATEGY_API,
  CAMPAIGN_NEW_API,
} from "../../../../services/ApiRoutes";
import { put, call } from "redux-saga/effects";
import agentActions from "../actionTypes";
import createCampaignActionTypes from "../../createCampaign/createCampaign.actionTypes";

export function* getAllAgentsWorker(data: any): any {
  try {
    const response = yield call(
      config.POST_WITH_BODY,
      `${STRATEGY_API.GET_AGENTS_NEW}`,
      data.payload
    );
    // const actual = response.data.data.sort((a: any, b: any) => {
    //   return (
    //     -Number(extractNumber(a.agentId)) + Number(extractNumber(b.agentId))
    //   );
    // });
    yield put({
      type: agentActions.GET_ALL_AGENTS_API_SUCCESS,
      payload: response.data.data,
    });
  } catch (err) {
    yield put({
      type: agentActions.GET_ALL_AGENTS_API_FAILURE,
      error: err,
    });
  }
}

export function* createAgentsWorker(data: any): any {
  try {
    const response = yield call(
      config.POST_WITH_BODY,
      `${STRATEGY_API.CREATE_AGENTS}`,
      data.payload
    );
    yield put({
      type: agentActions.CREATE_AGENT_API_SUCCESS,
      payload: response.data.data,
    });
    toast.success("Agent Created Successfully");
  } catch (err: any) {
    yield put({
      type: agentActions.CREATE_AGENT_API_FAILURE,
      error: err,
    });
    toast.error(
      `Agent Creation Failed - ${
        err?.response?.data?.error?.message?.includes("bankName")
          ? "Invalid Client Display Name"
          : err?.response?.data?.error?.message
      }`
    );
  }
}

export function* deleteAgentWorker(data: any): any {
  const { agentId, ...getApiPayload } = data.payload;
  try {
    const response = yield call(
      config.DELETE_WITH_BODY,
      `${STRATEGY_API.DELETE_AGENT}`,
      { agentId: agentId }
    );
    const getResult = yield call(
      config.POST_WITH_BODY,
      `${STRATEGY_API.GET_AGENTS_NEW}`,
      getApiPayload
    );
    yield put({
      type: agentActions.DELETE_AGENT_API_SUCCESS,
      payload: response.data.data,
    });
    // const actual = getResult.data.data.sort((a: any, b: any) => {
    //   return (
    //     -Number(extractNumber(a.agentId)) + Number(extractNumber(b.agentId))
    //   );
    // });
    yield put({
      type: agentActions.GET_ALL_AGENTS_API_SUCCESS,
      payload: getResult.data.data,
    });
    toast.success("Agent Deleted Successfully");
  } catch (err: any) {
    yield put({
      type: agentActions.DELETE_AGENT_API_FAILURE,
      payload: err.response?.data?.error,
    });
    toast.error(err.response?.data?.error);
  }
}

export function* editAgentsWorker(data: any): any {
  try {
    const response = yield call(
      config.PUT_WITH_BODY,
      `${STRATEGY_API.UPDATE_AGENT}`,
      data.payload
    );
    yield put({
      type: agentActions.EDIT_AGENT_API_SUCCESS,
      payload: response?.data?.data,
    });
    toast.success("Agent Updated Successfully");
  } catch (err) {
    yield put({
      type: agentActions.EDIT_AGENT_API_FAILURE,
      error: err,
    });
    toast.error("Agent Updation Failed");
  }
}

export function* addAgentToCampaignAPIWorker(action: any): any {
  toast.dismiss();
  toast.loading("Please wait ...");
  try {
    if (action) {
      const {
        agentId,
        campaignId,
        personas,
        token,
        selectedAgentId,
        campaignData,
      } = action.payload;
      const body = {
        agentId: agentId,
        campaignId: campaignId,
      };
      const result = yield call(
        config.PUT_WITH_HEADER_BODY,
        `${CAMPAIGN_NEW_API.UPDATE_AGENT_CAMPAIGN}`,
        body,
        {
          "x-access-token": token,
          "Content-Type": "application/json",
        }
      );
      const getResult = yield call(
        config.GET_WITH_PARAMS,
        `${CAMPAIGN_NEW_API.GET_CAMPAIGN_SUMMARY_DATA}?campaignId=${campaignId}`
      );

      yield put({
        type: agentActions.SET_SELECTED_AGENT_API_SUCCESS,
        payload: result.data.data?.[0],
      });
      yield put({
        type: createCampaignActionTypes.SET_CAMPAIGN_DATA,
        payload: {
          data: {
            ...campaignData,
            uploadedSuccessfully: true,
            personas: personas,
            agentId: result.data.data?.[0]?.agentId,
          },
        },
      });

      yield put({
        type: agentActions.GET_ALL_CAMPAIGN_SUMMARY_DATA_SUCCESS,
        payload: getResult?.data?.data,
      });
      // console.log("resssss", resultForSchedular.data.data);
      toast.dismiss();
      toast.success("Agent Updated Successfully");
      // toast.success(result.data.data.message);
    }
  } catch (error: any) {
    yield put({
      type: agentActions.SET_SELECTED_AGENT_API_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    toast.error(error.response?.data?.error);
    console.error(error);
  }
}

export function* getCampaignSummaryDataWorker(action: any): any {
  try {
    const result = yield call(
      config.GET_WITH_PARAMS,
      `${CAMPAIGN_NEW_API.GET_CAMPAIGN_SUMMARY_DATA}?campaignId=${action?.payload?.campaignId}`
    );
    yield put({
      type: agentActions.GET_ALL_CAMPAIGN_SUMMARY_DATA_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error: any) {
    yield put({
      type: agentActions.GET_ALL_CAMPAIGN_SUMMARY_DATA_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    toast.error(error.response?.data?.error);
    console.error(error);
  }
}

export function* getDownloadCSVZipApiDataWorker(action: any): any {
  try {
    const result = yield call(
      config.GET_WITH_PARAMS_RES_TYPE,
      `${CAMPAIGN_NEW_API.GET_CSV_ZIP_DATA}`,
      {
        params: { campaignId: action.payload.campaignId },
        responseType: "blob",
      }
    );
    const file = new Blob([result.data], { type: "application/zip" });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", `${action.payload.fileName}`);
    link.click();
    URL.revokeObjectURL(fileURL);
    link.parentNode?.removeChild(link);
    yield put({
      type: agentActions.GET_DOWNLOAD_CSV_ZIP_API_SUCCESS,
      payload: fileURL,
    });
    yield put({
      type: agentActions.CLEAR_DOWNLOAD_ZIP,
    });

    toast.dismiss();
    toast.success("CSV File(s) Downloaded Successfully");
  } catch (error: any) {
    yield put({
      type: agentActions.GET_DOWNLOAD_CSV_ZIP_API_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    toast.error(error.response?.data?.error);
    console.error(error);
  }
}
