import actionTypes from "../actionTypes";

export function setToggleNudge() {
  return { type: actionTypes.TOGGLE_SHOW_INDIVIDUAL_NUDGE_SETTING };
}

export function setShowNudgeSetting(data: boolean) {
  return {
    type: actionTypes.SET_SHOW_INDIVIDUAL_NUDGE_SETTING,
    payload: data,
  };
}

export function setPropForEachNudge(data: any) {
  return { type: actionTypes.SET_EACH_PROP_OF_NUDGE, payload: data };
}

export function clearNudgeSetings() {
  return { type: actionTypes.CLEAR_SETTING_OF_NUDGE };
}
export function setIdOfNudge(data: any) {
  return {
    type: actionTypes.SET_NUDGE_ID,
    payload: data,
  };
}
export function setPropForEachNudgeAll(data: boolean) {
  return {
    type: actionTypes.SET_EACH_PROP_OF_NUDGE_ALL,
    payload: data,
  };
}
export function setSelectedItemOnRight(data: string) {
  return {
    type: actionTypes.SET_SELECTED_ITEM_ON_RIGHT,
    payload: data,
  };
}
export function setShowRightsideAll(data: boolean) {
  return {
    type: actionTypes.SET_SHOW_EDIT_ALL_POP,
    payload: data,
  };
}

export function setIsBackIconClicked(data: boolean) {
  return {
    type: actionTypes.SET_IS_BACK_FROM_SCRIPT_SELECT,
    payload: data,
  };
}

export function setSelectedNudgeData(data: object) {
  return {
    type: actionTypes.SET_SELECTED_NUDGE_DATA,
    payload: data,
  };
}

export function setClearSelectedNudgeData() {
  return {
    type: actionTypes.CLEAR_SELECTED_NUDGE_DATA,
  };
}

export function tempStoreScriptDataForBack(payload: object) {
  return {
    type: actionTypes.SET_TEMP_SCRIPT_DATA,
    payload: payload,
  };
}
