import actionTypes from "../actionTypes";

export const setRootPortalScreen = (screenName: string) => {
  return { type: actionTypes.SET_ROOT_PORTAL, payload: screenName };
};

export const setConversationIdSummaryPortal = (id: string) => {
  return { type: actionTypes.SET_CONVERSATION_ID_SUMMARY_PORTAL, payload: id };
};

export const setIsShowTopBanner = (showBanner: boolean) => {
  return { type: actionTypes.SET_IS_SHOW_TOP_BANNER, payload: showBanner };
};

export const setTopBannerMessage = (bannerMsg: string) => {
  return { type: actionTypes.SET_IS_SHOW_BANNER_MSG, payload: bannerMsg };
};
