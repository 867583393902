import { call, put, takeLatest } from "redux-saga/effects";
import { config, PROFILE_API } from "../../../../../services/ApiRoutes.js";
import actionTypes from "../personalDetails.actionTypes";
import { toast } from "react-toastify";
// import axios from "axios";
import { UPLOAD_IMAGE_LIMIT } from "../personalDetails.config";
export default function* personalDetailsWatcher(): any {
  yield takeLatest(
    actionTypes.PROFILE_PERSONAL_GET_DETAILS_API_CALL,
    getDetailsSaga
  );
  yield takeLatest(actionTypes.UPDATE_PROFILE_PIC, updateProfilePic);
  yield takeLatest(
    actionTypes.PROFILE_UPDATE_USERNAME_PHONE,
    updateUserDetails
  );
  yield takeLatest(actionTypes.DELETE_PROFILE_PIC, deleteProfilePicSaga);
}

function* getDetailsSaga(action: any): any {
  try {
    const headers = {
      userId: action.payload.userId,
    };
    const response = yield call(
      config.GET_WITH_HEADER,
      PROFILE_API.get_by_name,
      { headers: headers }
    );
    yield put({
      type: actionTypes.PROFILE_PERSONAL_GET_DETAILS_API_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.error(error);
  }
}
function* updateUserDetails(action: any): any {
  try {
    const { id, name, phoneNo } = action.payload;
    // console.log("nithin update username", action.payload);
    yield call(config.PUT_WITH_PARAMS, PROFILE_API.update_name_and_phone + id, {
      name,
      phoneNo,
    });

    const headers = {
      userId: action.payload.id,
    };
    const response = yield call(
      config.GET_WITH_HEADER,
      PROFILE_API.get_by_name,
      { headers: headers }
    );
    yield put({
      type: actionTypes.PROFILE_PERSONAL_GET_DETAILS_API_SUCCESS,
      payload: response,
    });
    // toast.success("Profile Pic Updated")
  } catch (error) {
    console.error(error);
    toast.error(String(error));
  }
}
function* deleteProfilePicSaga(action: any): any {
  try {
    const id = action.payload.id;
    const response = yield call(
      config.DELETE,
      PROFILE_API.delete_profile_picture + id
    );
    //yield axios.put(PROFILE_API.delete_profile_picture + action.payload.id,);
    // const headers = {
    //   userId: action.payload.id,
    // };
    // const response = yield call(
    //   config.GET_WITH_HEADER,
    //   PROFILE_API.get_by_name,
    //   { headers: headers }
    // );
    yield put({
      type: actionTypes.PROFILE_PERSONAL_GET_DETAILS_API_SUCCESS,
      payload: response,
    });
    toast.success("Successfully deleted");
  } catch (error) {
    toast.error(String(error));
    console.error(error);
  }
}

function* updateProfilePic(action: any): any {
  try {
    // const uri: any = action.payload.file;
    // const file = new FormData();
    // file.append("name", uri);
    // yield call(
    //   config.PUT_WITH_CUSTOM_HEADER,
    //   PROFILE_API.upload_profile_picture + action.payload.id,
    //   file
    // );
    // yield axios.put(PROFILE_API.upload_profile_picture + action.payload.id);
    const file1 = action.payload.file;
    // console.log("Upload Image", file1);
    if (file1.size > UPLOAD_IMAGE_LIMIT) {
      throw "File too large";
    }
    const formData = new FormData();
    formData.append("file", file1);
    formData.append("destination", "images");
    // formData.append("create_thumbnail", "true");

    // const result = yield axios.put(
    //   PROFILE_API.upload_profile_picture + action.payload.id,
    //   formData,
    //   config
    // );
    const imgResponse = yield call(
      config.PUT_WITH_PARAMS,
      PROFILE_API.upload_profile_picture + action.payload.id,

      // method: "PUT",
      // headers: {
      //   "Content-Type": file1.type,
      //   "Content-Length": file1.size,
      // },
      formData
    );
    // console.log(imgResponse, "nithin img upload");

    // const headers = {
    //   userId: action.payload.id,
    // };
    // const response = yield call(
    //   config.GET_WITH_HEADER,
    //   PROFILE_API.get_by_name,
    //   { headers: headers }
    // );
    yield put({
      type: actionTypes.PROFILE_PERSONAL_GET_DETAILS_API_SUCCESS,
      payload: imgResponse,
    });
    toast.success("Successfully uploaded");
    // console.log("REsult: ", result);
  } catch (error: any) {
    // error.data.message
    // console.log(error, "nithin img");
    yield put({
      type: actionTypes.UPDATE_PROFILE_PIC_FAILURE,
      payload: error,
    });
    toast.error(String(error?.response?.data?.message || error));
    console.error(error);
  }
}
//
