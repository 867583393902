import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/rootStore";
import styles from "./AdminDashboardScreen.module.scss";
import CardImg from "../../../theme/assets/agentDesktopSvg/dashboardSvg/cardSvg/dummyImg.svg";
import TabComponent from "../../../components/agentDesktop/moduleComponents/tabComponent/TabComponent";
import ChartView from "../../../components/agentDesktop/moduleComponents/chartView/ChartView";
import Card from "../../../components/agentDesktop/moduleComponents/cardComponent/card/Card";
import {
  // getDashoardApiCards,
  getDashboardAPIData,
} from "../../../redux/agentDesktop/dashboard/actions";

// interface props {
//   default: any;
// }

const cardArr = [
  {
    name: "Avg. Talk Time Per Call",
    icon: CardImg,
    value: "1h 20m 30s",
    tooltip: true,
  },
  {
    name: "Available Duration",
    icon: CardImg,
    value: "2h 20m 30s",
    tooltip: true,
  },
  {
    name: "Overall CSAT",
    icon: CardImg,
    value: "7.5/10",
    tooltip: true,
  },
  {
    name: "Campaign Assigns",
    icon: CardImg,
    value: "5",
    tooltip: false,
  },
];

const topCardArr = [
  {
    name: "Active Call",
    icon: "",
    value: "07",
    tooltip: false,
  },
  {
    name: "Calls Ringing",
    icon: "",
    value: "02",
    tooltip: false,
  },
  {
    name: "Calls In Queue",
    icon: "",
    value: "10",
    tooltip: true,
  },
  {
    name: "Active Chats",
    icon: "",
    value: "05",
    tooltip: false,
  },
];

export default function AdminDashboardScreen(props: any) {
  const dispatch = useDispatch();
  const token = useSelector(
    (store: RootState) =>
      store.loginReducer?.userLoginInfo?.userSessionDetails?.accessToken
  );

  const accountName = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails[0]?.name
  );

  const agentId = useSelector(
    (store: RootState) => store?.loginReducer?.userLoginInfo?.userDetail?._id
  );

  const channelTab = useSelector(
    (store: any) => store?.agentDesktopReducer?.tabReducer?.backendRefenceKey
  );

  const startDate = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer?.rootFilterReducer?.calenderFilterReducer
        ?.formatedStartDate
  );

  const endDate = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer?.rootFilterReducer?.calenderFilterReducer
        ?.formatedEndDate
  );

  const cardSchema = useSelector(
    (state: any) => state.agentDesktopReducer?.baseScreenAD?.schema?.cards
  );

  const schema = useSelector(
    (state: RootState) => state.agentDesktopReducer?.baseScreenAD?.schema
  );

  const loading = useSelector(
    (store: RootState) =>
      store?.agentDesktopReducer?.dashboardReducer?.isLoadingDashboardChart
  );

  const callCardSchema = useSelector(
    (state: RootState) =>
      state.agentDesktopReducer?.baseScreenAD?.schema?.calls?.cards
  );

  const chatCardSchema = useSelector(
    (state: RootState) =>
      state.agentDesktopReducer?.baseScreenAD?.schema?.chats?.cards
  );

  const selectedTab = useSelector(
    (state: any) => state.agentDesktopReducer?.tabReducer?.selectedTab
  );

  const callChartrSchema = useSelector(
    (state: RootState) =>
      state.agentDesktopReducer?.baseScreenAD?.schema?.calls?.charts
  );

  const chatChartrSchema = useSelector(
    (state: RootState) =>
      state.agentDesktopReducer?.baseScreenAD?.schema?.chats?.charts
  );

  const cardDetails =
    cardSchema && Object.values(cardSchema)?.length > 0
      ? cardSchema.sort((a: any, b: any) => {
          return a?.position - b?.position;
        })
      : [];

  const channelCardSchema =
    selectedTab == "Call" ? callCardSchema : chatCardSchema;
  const chartSchema =
    selectedTab == "Call" ? callChartrSchema : chatChartrSchema;

  const channelCardDetails =
    channelCardSchema && Object.values(channelCardSchema)?.length > 0
      ? channelCardSchema.sort((a: any, b: any) => {
          return a?.position - b?.position;
        })
      : [];

  const chartDetails =
    chartSchema && Object.values(chartSchema)?.length > 0
      ? chartSchema.sort((a: any, b: any) => {
          return a?.position - b?.position;
        })
      : [];

  // useEffect(() => {
  //   if (channelTab) {
  //     schema?.cards?.map((e: any) => {
  //       dispatch(
  //         getDashoardApiCards(
  //           {
  //             username: accountName,
  //             start_date: startDate,
  //             end_date: endDate,
  //             channel: [channelTab],
  //             uniqueId: e?.cardData?.uniqueId,
  //             agentIds: [agentId],
  //           },
  //           token
  //         )
  //       );
  //     });
  //   }
  // }, [startDate, endDate, channelTab, schema]);

  // useEffect(() => {
  //   if (channelTab) {
  //     channelCardSchema?.map((e: any) => {
  //       dispatch(
  //         getDashoardApiCards(
  //           {
  //             username: accountName,
  //             start_date: startDate,
  //             end_date: endDate,
  //             channel: [channelTab],
  //             uniqueId: e?.cardData?.uniqueId,
  //             agentIds: [agentId],
  //           },
  //           token
  //         )
  //       );
  //     });
  //   }
  // }, [startDate, endDate, channelTab, channelCardSchema, agentId]);

  useEffect(() => {
    let interval:any;
    if (channelTab) {
      interval = setInterval(() => {
        dispatch(
          getDashboardAPIData({
            start_date: startDate,
            end_date: endDate,
            channel: channelTab,
            adminId: agentId,
          })
        );
      }, 2000);
    }
    return () => clearInterval(interval)
  }, [
    startDate,
    endDate,
    channelTab,
    chartSchema,
    agentId,
    schema,
    channelCardSchema,
  ]);

  return (
    <>
      <div className={styles.adminDashboardScreenDiv}>
        <div className={styles.adminScreenTopDiv}>
          <p className={styles.headerTitleDiv}>Live Metrics</p>
          <div className={styles.headerCardDiv}>
            <Card
              cardDetails={cardDetails}
              width={"257px"}
              height={"120px"}
              fontSize={"18px"}
            />
          </div>
        </div>
        <div className={styles.adminScreenBottomDiv}>
          <p className={styles.bottomTitleDiv}>Channel Metrics</p>
          <TabComponent />
          <div className={styles.bottomCardDiv}>
            <Card
              cardDetails={channelCardDetails}
              width={"205px"}
              height={"120px"}
              fontSize={"16px"}
            />
          </div>
          <div className={styles.chartDiv}>
            <ChartView chartDetails={chartDetails} isLoading={loading} />
          </div>
        </div>
      </div>
    </>
  );
}
