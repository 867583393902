import MaiaCallTable from "../MaiaCallTable";
import { sortIcon } from "../../../../../theme/assets/genericSvg";
import React from "react";
const data = [
  {
    slNo: "1",
    stage: "Right-party confirmation",
    dropRate: "459 (19%)",
  },
  {
    slNo: "2",
    stage: "Conveying Payment Information",
    dropRate: "872 (35%)",
  },
  {
    slNo: "3",
    stage: "Counselling",
    dropRate: "621 (25%)",
  },
  {
    slNo: "4",
    stage: "Delay Reason check",
    dropRate: "519 (21%)",
  },
];
const bodyTranformer = [
  { key: "slNo", transform: (value: any) => value },
  { key: "stage", transform: (value: any) => value },
  { key: "dropRate", transform: (value: any) => value },
];
const headTranformer = [
  {
    key: "slNo",
    displayName: (
      <>
        S.No.{" "}
        <span>
          {/* <img src={sortIcon}></img> */}
          {""}
        </span>
      </>
    ),
  },
  {
    key: "stage",
    displayName: (
      <>
        Stage{" "}
        <span>
          <img src={sortIcon}></img>
        </span>
      </>
    ),
  },
  {
    key: "dropRate",
    displayName: (
      <>
        Droprate{" "}
        <span>
          <img src={sortIcon}></img>
        </span>
      </>
    ),
  },
];
export default function DropRateAnalysisTable() {
  return (
    <>
      <MaiaCallTable
        data={data}
        bodyTranformer={bodyTranformer}
        headingTransformer={headTranformer}
      />
    </>
  );
}
