// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AhS0so_YRRnfOwIycl2C{display:flex;flex-direction:column}.AhS0so_YRRnfOwIycl2C .hp7N8lJfwNH3epr10GWa{font-family:"Roboto";font-size:14px;font-weight:400;line-height:16px;letter-spacing:0em;text-align:left;color:#676767;padding:10px 0px}`, "",{"version":3,"sources":["webpack://./src/components/generic/inputField/InputField.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAEA,4CACE,oBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CACA,eAAA,CACA,aAAA,CACA,gBAAA","sourcesContent":[".inputDiv {\n  display: flex;\n  flex-direction: column;\n\n  .labelText {\n    font-family: \"Roboto\";\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 16px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #676767;\n    padding: 10px 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputDiv": `AhS0so_YRRnfOwIycl2C`,
	"labelText": `hp7N8lJfwNH3epr10GWa`
};
export default ___CSS_LOADER_EXPORT___;
