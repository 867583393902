import React, { useState, ReactNode, useEffect } from "react";
import styles from "./SettingsScreen.module.scss";
import { RootState } from "../../../redux";
import PageToggler from "../../../components/generic/pageToggler/PageToggler";
import SecurityComponent from "./securityComponent/SecurityComponent";
import ReportAutomationComponent from "./reportAutomationComponent/ReportAutomationComponent";
import { useSelector, useDispatch } from "react-redux";
import profileConfig from "../Profile.config";
import { getCurrentTab } from "../../../redux/profile/yourAccount/organizationDetails/actions/organizationDetails.actions";

export default function ProfileSettingScreen() {
  const tabs = useSelector(
    (state: any) => state.profile.navigationReducer.leftSideNavigation.data
  );

  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState<string>("");
  const [tab, setTab] = useState<{ name: string; element: ReactNode }[]>([]);

  useEffect(() => {
    if (tab.length > 0) {
      setCurrentTab(tab[0].name);
    }
  }, [tab]);

  useEffect(() => {
    dispatch(getCurrentTab(""));
  }, []);

  useEffect(() => {
    if (tabs && tabs.length > 0) {
      const settings = tabs?.filter((e: any) => {
        return e.name === profileConfig.settings.name;
      });
      const modules = settings[0]?.moduleTabs.flatMap((e: any) => {
        if (e.isVisible) {
          switch (e.name) {
            case profileConfig.settings.subModule.security.name:
              return [{ name: e.name, element: <SecurityComponent /> }];
            case profileConfig.settings.subModule.reportAutomation.name:
              return [{ name: e.name, element: <ReportAutomationComponent /> }];
            default:
              return [{ name: e.name, element: <></> }];
          }
        }
        return [];
      });
      setTab(modules ? modules : []);
    }
  }, [tabs]);

  return (
    <div>
      <PageToggler
        current={currentTab}
        innerComponents={tab}
        switchTab={(e: string) => {
          setCurrentTab(e);
        }}
        extraHeaderStyling={styles.settingsTabHeader}
      ></PageToggler>
    </div>
  );
}
