import React from "react";
import styles from "./PaymentTable.module.scss";
import SimpleTable from "../simpleTable/SimpleTable";
import {
  datas,
  bodyTranformer,
  headTranformers,
} from "./tableDetailsAndFunctions";
export default function PaymentTable() {
  return (
    <div className={styles.wrpaper}>
      <SimpleTable
        dataToShowOnTable={datas}
        headingTranformer={headTranformers}
        bodyTranformer={bodyTranformer}
      />
    </div>
  );
}
