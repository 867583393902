// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HJM4lyHSRwHR9QBT0mng{display:flex;justify-content:space-evenly;align-items:center;flex-wrap:wrap}.HJM4lyHSRwHR9QBT0mng .WU4LvxO8SYocZi0QwkbC{background:#fff;border:1px solid #e1e2e9;border-radius:5px;margin:3% .5% 3% .5%}.HJM4lyHSRwHR9QBT0mng .WU4LvxO8SYocZi0QwkbC .o6IuW1W2EDtI0NW44GtQ{padding:20px;font-family:"Roboto";font-style:normal;font-weight:400;font-size:18px;line-height:21px;color:#2c2d33;display:flex;justify-content:space-between}.HJM4lyHSRwHR9QBT0mng .WU4LvxO8SYocZi0QwkbC .eLdGEySJzoNSkV3TWaEw{width:80%;border:.947987px solid #e4e5e7;margin-left:20px}`, "",{"version":3,"sources":["webpack://./src/components/crm/moduleComponent/chartModel/ChartModel.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,4BAAA,CACA,kBAAA,CACA,cAAA,CACJ,4CACI,eAAA,CACA,wBAAA,CACA,iBAAA,CACA,oBAAA,CAEA,kEACI,YAAA,CACA,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,YAAA,CACA,6BAAA,CAIJ,kEACI,SAAA,CACA,8BAAA,CACA,gBAAA","sourcesContent":[".chartModelParentComp{\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n    flex-wrap: wrap;\n.crmChartModelComponentWrapper {\n    background: #FFFFFF;\n    border: 1px solid #E1E2E9;\n    border-radius: 5px;\n    margin: 3% 0.5% 3% 0.5%;\n\n    .chartModelHeader {\n        padding: 20px;\n        font-family: 'Roboto';\n        font-style: normal;\n        font-weight: 400;\n        font-size: 18px;\n        line-height: 21px;\n        color: #2C2D33;\n        display: flex;\n        justify-content: space-between;\n\n    }\n\n    .horizontalLine{\n        width: 80%;\n        border: 0.947987px solid #E4E5E7;\n        margin-left: 20px;\n    }\n}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartModelParentComp": `HJM4lyHSRwHR9QBT0mng`,
	"crmChartModelComponentWrapper": `WU4LvxO8SYocZi0QwkbC`,
	"chartModelHeader": `o6IuW1W2EDtI0NW44GtQ`,
	"horizontalLine": `eLdGEySJzoNSkV3TWaEw`
};
export default ___CSS_LOADER_EXPORT___;
