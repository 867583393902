// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ef9ECJG_FHXYcMdVQFsi{width:200px}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/charts/StackedBarChat.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA","sourcesContent":[".img {\n  width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": `ef9ECJG_FHXYcMdVQFsi`
};
export default ___CSS_LOADER_EXPORT___;
