// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jCB5JR1xWENFobxNMaOS{display:inline-flex;justify-content:center;align-items:center}.jCB5JR1xWENFobxNMaOS .WrO6b57uzdBqu9MXq2g0{width:18px;height:18px;border-radius:50%;background-color:#fff;border:#d8d8d8 1px solid;display:inline-block;cursor:pointer}.jCB5JR1xWENFobxNMaOS .WrO6b57uzdBqu9MXq2g0[data-isselected=true]{background-color:#0174ff;border:none}.jCB5JR1xWENFobxNMaOS .bn35EpFsbzkj6EgLgij_{text-align:center;display:flex;align-items:center;justify-content:center;flex-direction:row;margin-left:12px;font-style:normal;font-weight:400;font-size:14px;line-height:17px}`, "",{"version":3,"sources":["webpack://./src/components/generic/newRadio/NewRadio.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,sBAAA,CACA,kBAAA,CACA,4CACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,qBAAA,CACA,wBAAA,CACA,oBAAA,CACA,cAAA,CACA,kEACE,wBAAA,CACA,WAAA,CAGJ,4CACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CACA,gBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".wrapper {\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  .radio {\n    width: 18px;\n    height: 18px;\n    border-radius: 50%;\n    background-color: white;\n    border: #d8d8d8 1px solid;\n    display: inline-block;\n    cursor: pointer;\n    &[data-isselected=\"true\"] {\n      background-color: #0174ff;\n      border: none;\n    }\n  }\n  .name {\n    text-align: center;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: row;\n    margin-left: 12px;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 17px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `jCB5JR1xWENFobxNMaOS`,
	"radio": `WrO6b57uzdBqu9MXq2g0`,
	"name": `bn35EpFsbzkj6EgLgij_`
};
export default ___CSS_LOADER_EXPORT___;
