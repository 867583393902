import React, { useState } from "react";
import Button from "../../../generic/button/Button";
import style from "./ExperienceCampaign.module.scss";
import {
  call,
  whatsappIcon,
  sms,
  mail,
} from "../../../../theme/assets/svg/signUpFlow";
import DropDown from "../../../generic/dropdownsaarthi2/DropdownSaarthi";
import { dropDownArrowIcon } from "../../../../theme/assets/svg";
import { getCurrentTab } from "../../../../redux/profile/yourAccount/organizationDetails/actions/organizationDetails.actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface props {
  onClickBtn?: CallableFunction;
  selectedLanguage: (data: string) => void;
  selectedFlow: (data: string) => void;
  outerSelectedLanguage?: string;
  outerSelectedFlow?: string;
  toShowInprogress?: boolean;
  disableBtn?: boolean;
  closeFunc?: CallableFunction;
}

function ExperienceCampaign(props: props) {
  const [language, setLanguage] = useState(
    props.outerSelectedLanguage ? props.outerSelectedLanguage : ""
  );
  const [flow, setFlow] = useState(
    props.outerSelectedFlow ? props.outerSelectedFlow : ""
  );

  /* handle change*/
  const onChangeLanguage = (data: string) => {
    setLanguage(data);
    props.selectedLanguage(data);
  };
  const onChangeFlow = (data: string) => {
    setFlow(data);
    props.selectedFlow(data);
  };

  //addition data for dropdown
  const propsForLanguage = {
    optionList: ["Hindi", "English", "Kannada"],
    imgSrcRight: dropDownArrowIcon,
    imgSrcLeftHide: true,
    placeHolderText: language ? language : "Select Language",
  };
  const propsForFlow = {
    optionList: ["Pre-Due", "post_due_30+"],
    imgSrcRight: dropDownArrowIcon,
    imgSrcLeftHide: true,
    placeHolderText: flow ? flow : "Select Flow",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    // <VerifyPopUp
    //   show={true}
    //   contentToDisplay={
    <div className={style.experienceCampaignWrapper}>
      <p className={style.paraExperienceCamapign}>
        I can give you a test call from here so you can experience my Voice AI
        capabilities.
      </p>
      <div className={style.dropdownChannelContainer}>
        <div className={style.dropdownDiv}>
          <div className={style.dropDownDivLab}>
            <label className={style.labelName}> Language </label>
            <div>
              {" "}
              <DropDown
                droplist={propsForLanguage}
                selectedItem={(item: string) => onChangeLanguage(item)}
                extraClassSelectedArea={style.dropDownSelectedStyling}
                extraClassToBeSelectedArea={style.dropDownToBeSelectedStyling}
                extraClassPlaceHolderStyling={style.dropDownPlaceHolderStyling}
                extraClassPlaceHolder={style.defaultPlaceHolderStyling}
                extraClassDropDownUl={style.dropDownUlStyling}
                extraClassDropDownLi={style.dropDownLiStyling}
                outerSelected={language}
                extraHighlight={style.styleHighlightLi}
                id="auto_language_type_dropdown"
              />
            </div>
          </div>
          <div className={style.dropDownDivLab}>
            <label className={style.labelName}>Flow</label>
            <div>
              {" "}
              <DropDown
                droplist={propsForFlow}
                selectedItem={(item: string) => onChangeFlow(item)}
                extraClassSelectedArea={style.dropDownSelectedStyling}
                extraClassToBeSelectedArea={style.dropDownToBeSelectedStyling}
                extraClassPlaceHolderStyling={style.dropDownPlaceHolderStyling}
                extraClassPlaceHolder={style.defaultPlaceHolderStyling}
                extraClassDropDownUl={style.dropDownUlStyling}
                extraClassDropDownLi={style.dropDownLiStyling}
                outerSelected={flow}
                extraHighlight={style.styleHighlightLi}
                id="auto_flow_type_dropdown"
              />
            </div>
          </div>
        </div>
        <div>
          <label className={style.labelName}>Channels</label>
          <div className={style.channelContainer}>
            <div className={style.buttonWidth}>
              <Button
                image_src_left={call}
                text="Call"
                extraClass={style.experienceCall}
                buttonTextClass={style.spanText}
              />
            </div>
            <div className={style.buttonWidth}>
              <Button
                image_src_left={whatsappIcon}
                text="WhatsApp"
                extraClass={style.experienceWhatsApp}
              />
            </div>
            <div className={style.buttonWidth}>
              <Button
                image_src_left={mail}
                text="Mail"
                extraClass={style.experienceMail}
                buttonTextClass={style.spanText}
              />
            </div>
            <div className={style.buttonWidth}>
              <Button
                image_src_left={sms}
                text="SMS"
                extraClass={style.experienceSms}
                buttonTextClass={style.spanText}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={style.btnContainer}>
        {props.toShowInprogress ? (
          <p className={style.inprogress}> {`In Progress...`}</p>
        ) : (
          <Button
            text="Experience Now"
            extraClass={
              language && flow && !props.disableBtn
                ? style.experienceNowBtn
                : style.experienceInActiveButtonStyle
            }
            onClick={() => props.onClickBtn && props.onClickBtn()}
          />
        )}
      </div>
      <div className={style.lastLine}>
        <p
          className={style.paraClaim}
          onClick={() => {
            dispatch(getCurrentTab("Organization"));
            navigate("/profile/your-account");
            props.closeFunc && props.closeFunc();
          }}
        >
          Complete your account to claim free credits
        </p>
        <div className={style.line}></div>
      </div>
    </div>
    //   }
    // />
  );
}

export default ExperienceCampaign;
