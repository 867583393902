import React from "react";
import styles from "./NewRadio.module.scss";
export interface props {
  isSelected: boolean;
  id?: string;
  testId?: string;
  name: string;
  onClick: CallableFunction;
}
export default function NewRadio(props: props) {
  return (
    <span className={styles.wrapper}>
      <span
        data-testid={props.testId}
        id={props.id || ""}
        className={styles.radio}
        data-isselected={props.isSelected}
        onClick={() => {
          props.onClick();
        }}
      ></span>
      <span className={styles.name}>{props.name}</span>
    </span>
  );
}
