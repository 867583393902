import React, { useState, useEffect } from "react";
import styles from "./CustomIntegrationInputScreen.module.scss";
import { verify, failed, loading } from "../../../../../theme/gif/index";
import Button from "../../../../generic/button/Button";
import InputContainer from "../../../../generic/inputContainer/InputContainer";
import { TagsInput } from "../../../../generic/tagsInput/TagsInput";
import { InfoToolTip } from "../../../../generic/infoToolTip/InfoToolTip";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../redux";
import { validateEmailDomain } from "../../../../../utils/emailValidation";
import {
  getSecretAPIDetails,
  sendInfoToDevEmailData,
} from "../../../../../redux/integration_V2.0/customIntegration/actions/customIntegration.actions";
import _ from "lodash";
interface customIntegrationProps {
  onClickClose?: any;
}

export const CustomIntegrationInputScreen = ({
  onClickClose,
}: customIntegrationProps) => {
  const dispatch = useDispatch();
  const [refreshBtn, setRefreshBtn] = useState(false);

  const organizationInfo = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization.organizationDetails
  );

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const selectedIntegrationCustomAPI = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.selectedIntegrationData
  );

  const customIntegrationData = useSelector(
    (store: RootState) => store?.integrationReducer?.customIntegrationReducer
  );

  const isLoadingDetails = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.customIntegrationReducer
        ?.isLoadingSecretApiData
  );

  const [isValid, setIsValid] = useState(false);
  const [secretApiData, setSecretApiData] = useState({ key: "", secret: "" });
  const [showEmailField, setShowEmailField] = useState(false);
  const [emails, setEmails] = useState([]);
  const [errorTags, setErrorTags] = useState<{
    repeated: boolean;
    invalid: boolean;
  }>({ repeated: false, invalid: false });

  const handleOnClickSendInstruction = () => {
    if (isValid) {
      dispatch(
        sendInfoToDevEmailData({
          id: selectedIntegrationCustomAPI?._id,
          toEmail: emails,
          orgName: organizationInfo?.organizationName,
          accountId: accountId,
        })
      );
    } else {
      setShowEmailField(true);
    }
  };

  const handleSetEmails = (e: any) => {
    setEmails(e);
  };

  const handleUpdateKeys = () => {
    if (
      selectedIntegrationCustomAPI?.integrationDetails &&
      Object.keys(selectedIntegrationCustomAPI?.integrationDetails).length > 0
    ) {
      dispatch(
        getSecretAPIDetails({
          accountId: accountId,
          integrationListId: selectedIntegrationCustomAPI?.integrationListId,
          regenerate: true,
        })
      );
    }
  };

  const handleSendToDevValidation = () => {
    if (errorTags.repeated) {
      return false;
    }
    if (errorTags.invalid) {
      return false;
    }
    if (emails?.length === 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setIsValid(handleSendToDevValidation());
  }, [emails, errorTags]);

  useEffect(() => {
    if (
      _.isEqual(
        emails,
        selectedIntegrationCustomAPI?.integrationDetails?.devMails
      )
    ) {
      setRefreshBtn(false);
    } else {
      setRefreshBtn(true);
    }
  }, [emails, selectedIntegrationCustomAPI?.integrationDetails?.devMails]);

  useEffect(() => {
    if (
      selectedIntegrationCustomAPI?.integrationDetails &&
      Object.keys(selectedIntegrationCustomAPI?.integrationDetails)?.length > 0
    ) {
      setSecretApiData({
        key: selectedIntegrationCustomAPI?.integrationDetails?.key,
        secret: selectedIntegrationCustomAPI?.integrationDetails?.secret,
      });

      if (selectedIntegrationCustomAPI?.integrationDetails?.devMails) {
        setShowEmailField(true);
        setEmails(selectedIntegrationCustomAPI?.integrationDetails?.devMails);
      } else {
        setShowEmailField(false);
        setEmails([]);
      }
    }
  }, [
    selectedIntegrationCustomAPI?.integrationDetails,
    selectedIntegrationCustomAPI?.integrationDetails?.devMails,
  ]);

  useEffect(() => {
    let invalid = false;
    let repeated = false;
    // check fro valid email
    for (const i of emails) {
      if (!validateEmailDomain(i, organizationInfo?.organizationEmail)) {
        invalid = true;
        break;
      }
    }
    // check for repeated email
    for (const i of emails) {
      if (emails.filter((e) => e === i).length > 1) {
        repeated = true;
        break;
      }
    }
    setErrorTags({ invalid, repeated });
  }, [emails]);

  return (
    <div className={styles.customApiWrapper}>
      <div className={styles.ApiDataContainer}>
        <div className={styles.ApiDiv}>
          <div className={styles.ApiKeyDiv}>
            <InputContainer
              label="API Key"
              inputType="text"
              inputPlaceholder="API Key"
              inputValue={secretApiData?.key}
              inputName="apiKey"
              showLabel={true}
              showNewToolTip
              showNewToolTipPosition={"top-end"}
              showNewToolTipMsg={
                "This is the API key which will uniquely identify you while using our APIs."
              }
              toolTipSize={false}
              extraInputClass={`${styles.inputDivStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${styles.inputMainDivStyling}`}
              showCopyIcon={true}
              readOnly={true}
              showOverFlowToolTip={true}
            />
          </div>
          <div className={styles.ApiKeyDiv}>
            <InputContainer
              label="API Secret"
              inputType="password"
              inputPlaceholder="API Secret"
              inputValue={secretApiData?.secret}
              inputName="apiSecret"
              showLabel={true}
              showNewToolTip
              showNewToolTipPosition={"top-end"}
              showNewToolTipMsg={
                "This is the secret value which will authorise you to use our APIs. Kindly do not share with anyone outside your organisation."
              }
              toolTipSize={false}
              extraInputClass={`${styles.inputDivStyling} ${styles.secretStyling}`}
              extraLabelClass={styles.inputLabelStyling}
              extraLabelDivClass={styles.labelDivStyling}
              extraUserInputClass={`${styles.inputMainDivStyling}`}
              showCopyIcon={true}
              readOnly={true}
              showOverFlowToolTip={false}
            />
          </div>
        </div>
        <div className={styles.emailDiv}>
          {showEmailField ? (
            <>
              <div
                className={`${styles.tagsLabelDiv} ${styles.inputLabelStyling}`}
              >
                {emails.length > 0 ? (
                  <>
                    <div className={styles.labelDivStyling}>Email</div>
                    <InfoToolTip
                      message={
                        "Provide an email address where we can send the credentials and instructions to use our APIs. This can be someone you trust from your tech team."
                      }
                      isError={false}
                      position="top-end"
                    />
                  </>
                ) : null}
              </div>

              <TagsInput
                extraClass={`${styles.tagsInputExtraStyling} ${
                  errorTags.repeated || errorTags.invalid
                    ? styles.tagsInputErrorStyling
                    : ""
                }`}
                extraClassInput={styles.tagsTextStyling}
                extraTagsStyling={styles.tagsTextStyling}
                validEmailDomain={organizationInfo?.organizationEmail}
                selectedTags={handleSetEmails}
                tags={emails}
                placeholder={"Enter Email"}
                showNewToolTip={true}
                showNewToolTipPosition={"top-end"}
                growListDirection="horizontal"
                showNewToolTipMsg={
                  "Provide an email address where we can send the credentials and instructions to use our APIs. This can be someone you trust from your tech team."
                }
              />
              <div className={styles.tagsErrorDiv}>
                {errorTags.repeated ? (
                  <p className={styles.errorMessage}>
                    Email entered is repeated.
                  </p>
                ) : errorTags.invalid ? (
                  <p className={styles.errorMessage}>
                    Instructions can be shared only to Email IDs from your
                    company.
                  </p>
                ) : null}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className={styles.buttonContainer}>
        {(customIntegrationData &&
          Object.keys(customIntegrationData?.sendInfoToDevData)?.length ===
            0) ||
        refreshBtn ? (
          <Button
            text={
              customIntegrationData?.isLoadingsendInfoToDevData
                ? "Sending"
                : "Send Instructions to developer"
            }
            extraClass={`${styles.buttonStyling} ${
              isValid ? styles.activeBtnStyle : styles.sendButton
            }`}
            image_src_left={
              customIntegrationData?.isLoadingsendInfoToDevData && loading
            }
            imgClass={`${styles.loadingIconStyling}`}
            onClick={handleOnClickSendInstruction}
          />
        ) : (
          <Button
            text="Sent Successfully"
            image_src_left={verify}
            extraClass={`${styles.buttonStyling} ${styles.sentButton}`}
            imgClass={`${styles.sentIconStyling}`}
            onClick={onClickClose}
          />
        )}
      </div>
      <div
        className={`${styles.generateNewDiv} ${
          isLoadingDetails ? styles.disabledDiv : ""
        }`}
        onClick={handleUpdateKeys}
      >
        <p>
          {isLoadingDetails
            ? "Generating New API Credentials"
            : "Generate New API Credentials"}
        </p>
      </div>
    </div>
  );
};
