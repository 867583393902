// validating email
// import { PUBLIC_DOMAINS } from "./data";
export const emailValidation = (value) => {
  let errors = {};
  let isValid = true;
  if (!value) {
    isValid = false;
    errors["message"] = "Please Enter a Valid Email.";
  } else if (typeof value !== "undefined") {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!pattern.test(value)) {
      isValid = false;
      errors["message"] = "Please Enter a Valid Email.";
    }
  }

  return {
    isValid,
    errors,
  };
};

// Validating password   /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/

export const passwordValidation = (value) => {
  let errors = {};
  let isValid = true;
  if (!value) {
    isValid = false;
    errors["message"] = "Please enter your password.";
  }
  // else if( value.length < 8){
  //     isValid = false;
  //     errors["message"] = "Password should be of 8 character";
  // }
  else {
    let pattern = new RegExp(/^(?=.*[!@#$%^&*\d])(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
    if (!pattern.test(value)) {
      isValid = false;
      errors["message"] =
        "Password should be min 8 characters, with upper and lower case letters, at least a special character or a number.";
    }
  }
  return {
    isValid,
    errors,
  };
};

// Name validation
export const nameValidation = (value) => {
  let trimmedValue = value.trim();
  let errors = {};
  let isValid = true;
  if (!trimmedValue) {
    isValid = false;
    errors["message"] = "Please Enter your Name.";
  } else if (trimmedValue.length < 3) {
    isValid = false;
    errors["message"] = "Name should be of length atleast 3 and all alphabet.";
  } else if (trimmedValue.length > 35) {
    isValid = false;
    errors["message"] = "Name should not be of length more than 50.";
  } else {
    let pattern = new RegExp(/^[a-zA-Z ]{3,35}$/);
    if (!pattern.test(trimmedValue)) {
      isValid = false;
      errors["message"] = "Name should be only alphabet.";
    }
  }
  return {
    isValid,
    errors,
  };
};

export const webValidation = (value) => {
  var a = value;
  var re =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  // var pattern = new RegExp(/^[a-zA-Z0-9+&_.-]+@[a-zA-Z0-9.-]+$/)
  return re.test(a);
};

//Phone Number validation
export const phoneNumberValidation = (digit) => {
  let errors = {};
  let isValid = true;
  let number = digit;
  if (!number) {
    isValid = false;
    errors["message"] = "Please enter your phone number.";
  }
  // else if((number.match(phoneno))){
  //   console.log(number.match(phoneno))
  //   isValid = true;
  //   errors["message"] = null;
  // }
  else if (number?.length === 10) {
    isValid = true;
    errors["message"] = null;
  } else if (number?.length !== 10 || number.length > 10) {
    isValid = false;
    errors["message"] = "Please Enter 10 Digit Phone Number";
  } else {
    var pattern = new RegExp(
      /^((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/
    );
    if (!pattern.test(number)) {
      isValid = false;
      errors["message"] = "Please Enter Correct Phone Number ";
    }
  }
  return {
    isValid,
    errors,
  };
};

export const orgPhoneNumberValidation = (digit) => {
  let errors = {};
  let isValid = true;
  let number = digit;
  if (!number) {
    isValid = false;
    errors["message"] = "Please Enter a Valid Contact Number.";
  }
  // else if((number.match(phoneno))){
  //   console.log(number.match(phoneno))
  //   isValid = true;
  //   errors["message"] = null;
  // }
  else if (number?.length === 10) {
    isValid = true;
    errors["message"] = null;
  } else if (number?.length !== 10 || number.length > 10) {
    isValid = false;
    errors["message"] = "Please Enter a Valid Contact Number.";
  } else {
    var pattern = new RegExp(
      /^((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/
    );
    if (!pattern.test(number)) {
      isValid = false;
      errors["message"] = "Please Enter a Valid Contact Number.";
    }
  }
  return {
    isValid,
    errors,
  };
};

// validating username
export const userNameValidation = (value) => {
  let errors = {};
  let isValid = true;
  if (!value) {
    isValid = false;
    errors["message"] = "Please Enter your Username.";
  } else if (typeof value !== "undefined") {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!pattern.test(value)) {
      isValid = false;

      errors["message"] = "Please Enter a Valid Username.";
    }
  }

  return {
    isValid,
    errors,
  };
};

// Domain validation
export const domainValidation = (orgValue, prsnValue) => {
  let trimmedOrgValue = orgValue?.trim();
  let trimmedPrsnValue = prsnValue?.trim();

  let errors = {};
  let isValid = true;
  const orgIndex = trimmedOrgValue.indexOf("@");
  const orgDomain = trimmedOrgValue.substr(orgIndex + 1);
  const prsnIndex = trimmedPrsnValue.indexOf("@");
  const prsnDomain = trimmedPrsnValue.substr(prsnIndex + 1);
  // const validDomain = "saarthi.ai";
  // const validDomain = PUBLIC_DOMAINS;

  if (!trimmedOrgValue) {
    isValid = false;
    errors["message"] = "Please Enter a Valid Email.";
  }
  // else if (validDomain.includes(domain.trim()))
  else if (orgDomain !== prsnDomain) {
    isValid = false;
    errors["message"] = "Only " + prsnDomain + " Domain is accepted.";
  } else if (typeof orgValue !== "undefined") {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(trimmedOrgValue)) {
      isValid = false;
      errors["message"] = "Please Enter a Valid Email.";
    }
  }

  // else if (trimmedValue.length > 100) {
  //   isValid = false;
  //   errors["message"] =
  //     "Organization Email should not have more than 100 characters.";
  // }
  return {
    isValid,
    errors,
  };
};

// Organization Name Validation
export const orgNameValidation = (value) => {
  let trimmedValue = value.trim();
  let errors = {};
  let isValid = true;
  if (trimmedValue.length < 3) {
    isValid = false;
    errors["message"] = "Organization Name must contain atleast 3 characters.";
  }
  // else if (trimmedValue.length > 50) {
  //   isValid = false;
  //   errors["message"] =
  //     "Organization Name must not contain more than 50 characters.";
  // }
  else if (!trimmedValue) {
    isValid = false;
    errors["message"] = "Please Enter a Valid Organization Name.";
  }
  return {
    isValid,
    errors,
  };
};

export const orgDisplayNameValidation = (value) => {
  let trimmedValue = value.trim();
  let errors = {};
  let isValid = true;
  if (trimmedValue.length < 3) {
    isValid = false;
    errors["message"] =
      "Organization Display Name must contain atleast 3 characters.";
  }
  // else if (trimmedValue.length > 150) {
  //   isValid = false;
  //   errors["message"] =
  //     "Organization Display Name must not contain more than 150 characters.";
  // }
  else if (!trimmedValue) {
    isValid = false;
    errors["message"] = "Please Enter a Valid Organization Display Name.";
  }
  return {
    isValid,
    errors,
  };
};

export const registeredNameValidation = (value) => {
  let trimmedValue = value.trim();
  let errors = {};
  let isValid = true;
  if (trimmedValue.length < 3) {
    isValid = false;
    errors["message"] = "Registered Name must contain atleast 3 characters.";
  } else if (!trimmedValue) {
    isValid = false;
    errors["message"] = "Please Enter a Valid Registered Name.";
  }
  return {
    isValid,
    errors,
  };
};

export const displayNameValidation = (value) => {
  let trimmedValue = value.trim();
  let errors = {};
  let isValid = true;
  if (trimmedValue.length < 3) {
    isValid = false;
    errors["message"] = "Display Name must contain atleast 3 characters.";
  } else if (!trimmedValue) {
    isValid = false;
    errors["message"] = "Please Enter a Valid Display Name.";
  }
  return {
    isValid,
    errors,
  };
};

export const gstNumberValidation = (value) => {
  let trimmedValue = value.trim();
  let errors = {};
  let isValid = true;
  if (trimmedValue.length < 2) {
    isValid = false;
    // errors["message"] = "Please Enter your 15 characters GST Number.";
    errors["message"] = "Please Enter a Valid GST Number.";
  } else {
    var pattern = new RegExp(
      /^[0-9]{2}[a-zA-Z]{3}[ABCFGHLJPTFabcfghljptf]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}Z[0-9a-zA-Z]{1}$/
    );

    if (!pattern.test(trimmedValue)) {
      isValid = false;
      errors["message"] = "Please Enter a Valid GST Number.";
    }
  }
  return {
    isValid,
    errors,
  };
};

export const panNumberValidation = (value) => {
  let trimmedValue = value.trim();
  let errors = {};
  let isValid = true;
  if (trimmedValue.length < 2) {
    isValid = false;
    // errors["message"] = "Please Enter your 10 characters PAN Number.";
    errors["message"] = "Please Enter a Valid PAN Number.";
  } else {
    var pattern = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);
    if (!pattern.test(trimmedValue)) {
      isValid = false;
      errors["message"] = "Please Enter a Valid PAN Number.";
    }
  }
  return {
    isValid,
    errors,
  };
};

export const FBManagerIdValidation = (value) => {
  let trimmedValue = value.trim();
  let errors = {};
  let isValid = true;
  if (trimmedValue.length < 15) {
    isValid = false;
    errors["message"] = "Please Enter a Valid Facebook Manager Id Number.";
  }
  return {
    isValid,
    errors,
  };
};

export const pinCodeValidation = (value) => {
  let trimmedValue = value.trim();
  let errors = {};
  let isValid = true;
  if (trimmedValue.length < 3) {
    isValid = false;
    // errors["message"] = "Please Enter a Valid 6 digit Pincode.";
    errors["message"] = "Please Enter a Valid Pincode.";
  } else {
    var pattern = new RegExp(/^[1-9][0-9]{5}$/);
    if (!pattern.test(trimmedValue)) {
      isValid = false;
      errors["message"] = "Please Enter a Valid Pincode.";
    }
  }
  return {
    isValid,
    errors,
  };
};

export const webSiteValidation = (value) => {
  let trimmedValue = value.trim();
  let errors = {};
  let isValid = true;
  // if (trimmedValue.length > 50) {
  //   isValid = false;
  //   errors["message"] =
  //     "Website Address Name should not have more than 50 characters.";
  // } else
  if (trimmedValue.length <= 50) {
    var pattern = new RegExp(
      /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#?&\\=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );

    if (!pattern.test(trimmedValue)) {
      isValid = false;
      errors["message"] = "Please Enter a Valid Website Address.";
    }
  }
  return {
    isValid,
    errors,
  };
};

export const orgAddressValidation = (value) => {
  let trimmedValue = value.trim();
  let errors = {};
  let isValid = true;

  if (trimmedValue.length < 3) {
    isValid = false;
    errors["message"] =
      "Organization Address must contain atleast 3 characters.";
  }
  // else if (trimmedValue.length > 80) {
  //   isValid = false;
  //   errors["message"] =
  //     "Organization Address must not contain more than 80 characters.";
  // }
  else if (!trimmedValue) {
    isValid = false;
    errors["message"] = "Please Enter a Valid Organization Address.";
  }
  return {
    isValid,
    errors,
  };
};

export const stateValidation = (value) => {
  let trimmedValue = value.trim();
  let errors = {};
  let isValid = true;

  if (trimmedValue.length < 3) {
    isValid = false;
    errors["message"] = "State Name must contain atleast 3 characters.";
  }
  // else if (trimmedValue.length > 50) {
  //   isValid = false;
  //   errors["message"] = "State Name must not contain more than 50 characters.";
  // }
  else if (!trimmedValue) {
    isValid = false;
    errors["message"] = "Please Enter a Valid State Name.";
  }

  return {
    isValid,
    errors,
  };
};

export const teamNameValidation = (value) => {
  let trimmedValue = value.trim();
  let errors = {};
  let isValid = true;

  if (!trimmedValue) {
    isValid = false;
    errors["message"] = "Please Enter a Valid Team Name.";
  } else if (trimmedValue.length < 3) {
    isValid = false;
    errors["message"] = "Team Name must contain atleast 3 characters.";
  }
  //  else if (trimmedValue.length > 100) {
  //   isValid = false;
  //   errors["message"] = "Team Name must not contain more than 100 characters.";
  // }
  return {
    isValid,
    errors,
  };
};

export const maxLengthValidations = (value, label, maxLimit) => {
  let trimmedValue = value?.trim();
  let errors = {};
  let isValid = true;
  if (label) {
    if (trimmedValue?.length > maxLimit) {
      isValid = false;
      errors["message"] =
        label + " can contain maximum of " + maxLimit + " characters.";
    }
  }
  return {
    isValid,
    errors,
  };
};

export const cinNoValidation = (value) => {
  let trimmedValue = value.trim();
  let errors = {};
  let isValid = true;

  if (trimmedValue.length == 21) {
    var pattern = new RegExp(
      // /^[A-Z0-9]+$/g
      /^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]+$/g
    );
    if (!pattern.test(trimmedValue)) {
      isValid = false;
      errors["message"] = "Please Enter a Valid CIN Number.";
    }
  }
  else {
    isValid = false
    errors["message"] = "CIN Number must contain 21  alphanumeric character."
  }
  return {
    isValid,
    errors,
  };
};
