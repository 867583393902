import { takeLatest } from "redux-saga/effects";
import actionTypes from "../teamsPage.actionTypes";
import {
  getAllTeamsDataAPIWorker,
  updateTeamDataAPIWorker,
  getAllRolesDataAPIWorker,
  getTeamMemberDataAPIWorker,
  updateInviteMemberOnPlanAPIWorker
} from "./teamsPage.saga";

export default function* teamsPageDataWatcher(): any {
  yield takeLatest(
    actionTypes.GET_ALL_TEAMS_DATA_REQUEST,
    getAllTeamsDataAPIWorker
  );

  yield takeLatest(
    actionTypes.GET_ALL_ROLES_DATA_REQUEST,
    getAllRolesDataAPIWorker
  );

  yield takeLatest(
    actionTypes.UPDATE_PRODUCT_PORTFOLIO_TEAM_REQUEST,
    updateTeamDataAPIWorker
  );

  yield takeLatest(
    actionTypes.GET_TEAM_MEMBER_DATA_REQUEST,
    getTeamMemberDataAPIWorker
  );
  yield takeLatest(
    actionTypes.UPDATE_INVITE_MEMBER_ON_PLAN_REQUEST,
    updateInviteMemberOnPlanAPIWorker
  );
}
