import React, { useState } from "react";
import styles from "./ReportRuleCards.module.scss";
import PravidIcons from "../../../components/generic/pravidIcons/PravidIcons";
import ToolTip from "../../../components/generic/toolTip/ToolTip";
import { ReportRuleModal } from "../../../components/moduleComponents/reportRuleModal/ReportRuleModal";
interface ReportRuleCardsProps {
  index?: number;
  onSelect: CallableFunction;
  currentSelectedId: string;
  data?: {
    reportType?: string;
    id?: string;
    ruleName?: string;
    frequency?: string;
    time?: string;
  };
  accountId?: string;
  openModel: CallableFunction;
  onDelete: CallableFunction;
  reportRulesData?: {
    reportType?: string;
    id?: string;
    ruleName?: string;
    frequency?: string;
    time?: string;
  }[];
}
export const ReportRuleCards = ({
  index,
  data,
  accountId,
  currentSelectedId,
  openModel,
  onDelete,
  onSelect,
  reportRulesData,
}: ReportRuleCardsProps) => {
  const [deleteModal, setDeleteModal] = useState(false);

  const handleTimeFormat = (time: string) => {
    const timeArr = time.split(":");
    const times = {
      hasdata: true,
      am: Number(timeArr[0].split(":")[0]) < 12,
      hour:
        Number(timeArr[0]) === 0
          ? timeArr[0].replace(timeArr[0], "12")
          : Number(timeArr[0]) <= 12
          ? ("0" + timeArr[0]).slice(-2)
          : String(Number(timeArr[0]) - 12),
      minute: timeArr[1],
    };
    // console.log("%02d", ("0" + timeArr[0]).slice(-2));
    return String(
      `${
        Number(times.hour) <= 12
          ? ("0" + times.hour).slice(-2)
          : Number(times.hour) - 12
      }:${times.minute} ${times.am ? "AM" : "PM"}`
    );
  };
  const [currentId, setCurrentId] = useState("");
  const [currentName, setCurrentName] = useState("");
  return (
    <div className={styles.exisitingRuleWrapper}>
      <div className={styles.reportAutomationHeadingDiv}>
        <h4 className={styles.reportAutomationRuleHeading}>
          Existing Automation Rules
        </h4>
        <div id="auto_Existing_Automation_Rules">
          <ToolTip about="You can click on a rule card to view the details of the automation rule or to make any edits" />
        </div>
      </div>
      <div className={styles.reportAutomationRules}>
        <ReportRuleModal
          show={deleteModal}
          close={() => {
            setDeleteModal(false);
          }}
          onclick={() => {
            onDelete(currentId, accountId);
            setDeleteModal(false);
          }}
          deletePopUp={true}
          ruleName={currentName}
        />
        {reportRulesData && reportRulesData.length === 0 ? (
          <div className={styles.noDataDiv}>
            <p className={styles.noDataText}>
              There is no rule created yet. Create one now to start getting
              automated reports.
            </p>
          </div>
        ) : (
          reportRulesData &&
          reportRulesData.map((data: any, index: any) => {
            return (
              <div
                className={styles.reportAutomationRuleDiv}
                key={index}
                onClick={() => {
                  onSelect(data?.id);
                }}
                data-selected={data.id === currentSelectedId}
              >
                <div className={styles.reportDiv}>
                  <div className={styles.reportTypeDiv}>{data?.reportType}</div>
                  <PravidIcons
                    activeIcon={"delete"}
                    extraClass={styles.IconStyling}
                    onClick={(e: any) => {
                      setCurrentName(data.ruleName);
                      e.stopPropagation();
                      setDeleteModal(true);
                      setCurrentId(data.id);
                      // openModel(true, data?.id);
                    }}
                  />
                </div>

                <div className={styles.reportDiv}>
                  <div className={styles.ruleNameDiv}>{data?.ruleName}</div>
                </div>
                <div className={styles.reportDiv}>
                  <div className={styles.ruleFreqDiv}>{data?.frequency}</div>
                  <div className={styles.ruleTimeDiv}>
                    {data?.time && handleTimeFormat(data?.time)}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
