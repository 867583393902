import React, { useEffect, useRef, useState } from "react";
import NewConversationCards from "../../../generic/newConversationCards/NewConversationCards";
import TranscriptHeader from "../../callLogger/transcript/transcriptHeader/TranscriptHeader";
import CannedMessageComponent from "../../cannedPopupComponent/CannedMessageComponent";
import ChatTextBoxComponent from "../../chatTextBox/ChatTextBoxComponent";

import { props as conversationProps } from "../../../generic/newConversationCards/types";
import { appendChatTranscript } from "../../../../../redux/agentDesktop/chatLogger/action";
import { getStatusForChat } from "../../../../../redux/agentDesktop/chatLogger/saga/chatLogger.saga";
import "./ChatLoggerCenter.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setSendChatSignal } from "../../../../../redux/agentDesktop/BaseScreenAD/action";
import { getCannedMessages } from "../../../../../redux/agentDesktop/chatLogger/action";
import { replace } from "lodash";
import { msg } from "../../../../../theme/assets/svg";

interface props {
  hideChatBox: boolean;
}

export default function ChatLoggerCenter(props: props) {
  const transcript: any = useSelector<RootState>(
    (state) => state.agentDesktopReducer.chatLogger.transcript
  );
  const selectedSessionId = useSelector(
    (store: RootState) => store.agentDesktopReducer.chatLogger.selectedSessionId
  );
  const chatBoxRef = useRef();
  const updatedAtChat = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.chatLogger.summary?.[0]?.information
        ?.agentAssignedTime
  );
  const cannedMsgData = useSelector(
    (state: RootState) =>
      state.agentDesktopReducer?.chatLogger?.cannedMessage?.data
  );
  const summary = useSelector(
    (state: RootState) => state.agentDesktopReducer.chatLogger.summary
  );
  const sendChatSignal = useSelector(
    (store: RootState) => store.agentDesktopReducer.baseScreenAD.sendChatSignal
  );
  const chatStatus = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.chatLogger.summary?.[0]?.information?.chatStatus
  );
  const onGoingCallChatSessionId = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD.onGoingCallChatSessionId
  );
  const agentName = useSelector(
    (state: RootState) => state.loginReducer.userLoginInfo.userDetail.name
  );
  const agentStatus = useSelector(
    (store: RootState) =>
      store.agentDesktopReducer.baseScreenAD?.status?.currentState
  );

  const dispatch = useDispatch();
  const [cannedMessage, setCannedMessage] = useState(cannedMsgData);
  const [conversation, setConversation] = useState<conversationProps[]>([]);

  useEffect(() => {
    //[Customer Name] [Agent Name]

    const customerinfo = { customerName: undefined, dueDate: undefined };
    if (summary.length > 0) {
      customerinfo.customerName = summary[0]?.information?.customerName;
      customerinfo.dueDate = summary[0]?.information?.dueDate;
    }
    const replaceData: any = {
      "[Customer Name]": customerinfo?.customerName || "[Customer Name]",
      "[Agent Name]": agentName || "[Agent Name]",
      "[due_date]": customerinfo.dueDate || "[due_date]",
      // "[dpt]" :
    };
    setCannedMessage(() => {
      let tempCannedMsg = JSON.parse(JSON.stringify(cannedMsgData));
      if (cannedMsgData) {
        tempCannedMsg = tempCannedMsg.map((e: any) => {
          for (const i of Object.keys(replaceData)) {
            e.messageValue = (e.messageValue as string).replace(
              i,
              replaceData[i]
            );
            // console.log(e.messageValue, i, replaceData[i], "canned msg");
          }
          return e;
        });
      }
      // console.log("canned msg", { tempCannedMsg }, { cannedMsgData });
      return tempCannedMsg;
    });
  }, [cannedMsgData, summary, agentName]);
  // use effects
  useEffect(() => {
    dispatch(getCannedMessages(""));
  }, []);
  useEffect(() => {
    //
    if (transcript) {
      const conversationFromTranscript: conversationProps[] = transcript.map(
        (e: any) => {
          return {
            type:
              e.speaker === "Bot" || e.speaker === "Agent"
                ? "sent"
                : "received",
            message: e?.text.join(" "),
            time: new Date(e.createdAt),
            interactionType: e.tags?.interactionType,
            fileName: e.tags?.fileName,
          };
        }
      );

      // adding handover time
      let pushed = false;
      let i = 0;
      const dataForSorting = new Date(updatedAtChat).setMilliseconds(
        new Date(updatedAtChat).getMilliseconds() + 1000
      );
      const newArray: conversationProps[] = [];
      while (i < conversationFromTranscript.length) {
        if (
          new Date(dataForSorting) <
            (conversationFromTranscript[i]?.time as Date) &&
          !pushed
        ) {
          newArray.push({ toShow: "chatHandover" });
          pushed = true;
        } else {
          newArray.push(conversationFromTranscript[i]);
          i++;
        }
      }
      if (!pushed) newArray.push({ toShow: "chatHandover" });
      // end
      setConversation(newArray);
    } else {
      setConversation([]);
    }
  }, [transcript, updatedAtChat]);

  useEffect(() => {
    const element = document.querySelector(".scrollable");
    const timer = setTimeout(() => {
      element?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 300);
    return () => clearTimeout(timer);
  }, [conversation]);

  /* handlers --------- */
  const handleSendMessage = (messageHtml: string) => {
    if (messageHtml.replace(/<[^>]+>/g, "").length > 0) {
      dispatch(
        appendChatTranscript(messageHtml, moment(new Date()).format(), "Agent")
      );
      dispatch(
        setSendChatSignal(sendChatSignal === "send" ? "sendAgain" : "send")
      );
    }
  };

  return (
    <div className="chat-logger__center__agent-desktop">
      <div className="transcript-header">
        {transcript.length > 0 && (
          <TranscriptHeader
            sessionId={onGoingCallChatSessionId}
            dateTime={updatedAtChat}
            botTransferred={true}
            agentTransferred={false}
            tooltipContent={"Chat handled by AI"}
          />
        )}
      </div>
      <div className="transcript-chats">
        <div className="scrollable">
          {conversation.map((item, index) => {
            if (item?.toShow === "chatHandover") {
              if (updatedAtChat) {
                return (
                  <div className="handover-details" key={index}>
                    <span className="handover__by">
                      {"Call Transferred from AI to Agent | "}
                    </span>
                    <span className="handover__at">
                      {moment(updatedAtChat).format("DD MMM YY | h:mm A")}
                    </span>
                  </div>
                );
              }
            } else {
              return (
                <div
                  className="conversation-card"
                  key={index}
                  data-type={item.type}
                >
                  {item.message && (
                    <NewConversationCards
                      type={item.type}
                      time={item.time}
                      message={item.message}
                      interactionType={item.interactionType}
                      fileName={item.fileName}
                    />
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
      <div
        className={`chat-box ${
          chatStatus === "closed" || agentStatus !== "Available"
            ? "disablePointerEventUniversalLowOpacity"
            : ""
        } ${
          transcript.length <= 0 || props.hideChatBox
            ? "disablePointerEventUniversalLowOpacity"
            : ""
        }`}
      >
        <CannedMessageComponent
          data={cannedMessage}
          onChange={(messageKey, messageValue) => {
            // console.log({ messageKey, messageValue }, "quillll");
            if (chatBoxRef.current) {
              // console.log(chatBoxRef, "quill ref");
              (chatBoxRef?.current as any).appendText(messageValue);
            }
          }}
        />
        <ChatTextBoxComponent
          ref={chatBoxRef}
          cannedData={cannedMessage}
          handleSendMessage={handleSendMessage}
        />
      </div>
    </div>
  );
}

//  const conversation = [
//    {
//      type: "received",
//      message: "can you speak clearly, i can't hear you.",
//      time: new Date("2023-01-06T06:03:23.993Z"),
//    },
//    {
//      type: "sent",
//      message:
//        "I'm maia, calling from Saarthi. Your EMI of Rs.3000 is due since 02 January 2023. May i know if you can make the payment today?",
//      time: new Date("2023-01-06T06:09:25.898Z"),
//    },
//    {
//      type: "sent",
//      message: "Hello, am I speaking to sheetal sir?",
//      time: new Date("2023-01-06T06:09:25.898Z"),
//    },
//    {
//      type: "received",
//      message: "yes.",
//      time: new Date("2023-01-06T06:09:25.898Z"),
//    },
//  ];
