import { toast } from "react-toastify";
import _ from "lodash";
import EachNudgeRightSidePop from "../../components/moduleComponents/rightSidebarStrategy/EachNudgeRightSidePop";
export function validateInputMeta(value: any) {
  const newValue = value.toString();
  const reg = /^(?=[a-zA-Z0-9~@#$!^*_+=[\]|\\,.?: -]*$)/g;
  return reg.test(newValue);
}
export function validateInputMetaColumnName(value: any) {
  const newValue = value.toString();
  const reg = /^(?=[a-zA-Z0-9~@#$&!^*_+=[\]|\\,.?: -]*$)/g;
  return reg.test(newValue);
}
export function validateKeys(e: any) {
  [
    "<",
    ">",
    ";",
    ":",
    "?",
    "/",
    "\\",
    "%",
    '"',
    "'",
    "{",
    "}",
    "(",
    ")",
    "*",
  ].includes(e.key) && e.preventDefault();
  return false;
}
export function shouldIncludeInOptions(
  toShow: Array<any>,
  allOptions: Array<any>,
  refrence: string
): boolean {
  if (refrence !== "campaignName") {
    return true;
  }
  let x = true;
  toShow.forEach((e) => {
    if (x && !allOptions.find((eh) => eh === e)) {
      x = false;
    }
  });

  return x;
}

export function everyCampaingIsInOptions(
  allOptions: Array<any>,
  currentCampaings: Array<any>
) {
  const allCamp = allOptions.map((e: any) => e["campaignName"]);
  return currentCampaings.every((e: string) => allCamp.includes(e));
}

export function getOptionsRemaining(
  allOptions: Array<any>,
  reduxOptions: Array<any>
) {
  if (!allOptions) return [];
  if (!reduxOptions) return [];
  const allCamp = allOptions.map((e: any) => e["campaignName"]);
  const x: any = [];

  reduxOptions.forEach((e: string) => {
    if (allCamp.includes(e)) x.push(e);
  });
  return x;
}

export function someFiltersExist(selectedFilterObj: any) {
  if (!selectedFilterObj) {
    return false;
  }
  let x = 0;
  Object.keys(selectedFilterObj).forEach((e: any) => {
    x = x + selectedFilterObj[e].length;
  });
  return x > 0;
}
export const validateString = (event: any) => {
  [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "!",
    "@",
    "#",
    "$",
    "%",
    "&",
    "^",
    "`",
    "~",
    "*",
    "(",
    ")",
    "<",
    ">",
    "?",
    ".",
    '"',
    "'",
    "\\",
    "/",
    "{",
    "}",
    "+",
    "=",
    "-",
    "_",
    "|",
    ":",
    ";",
    ",",
  ].includes(event.key) && event.preventDefault();
  return;
};
export const validatePtpString = (event: any) => {
  [
    "!",
    "@",
    "#",
    "$",

    "&",
    "^",
    "`",
    "~",
    "*",
    "(",
    ")",
    "<",
    ">",
    "?",

    '"',
    "'",
    "\\",
    "/",
    "{",
    "}",
    "+",
    "=",
    "-",
    "_",
    "|",
    ":",
    ";",
    ",",
  ].includes(event.key) && event.preventDefault();
  return;
};
export const validateInteger = (event: any) => {
  [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "!",
    "@",
    "#",
    "$",
    "%",
    "&",
    "^",
    "`",
    "~",
    "*",
    "(",
    ")",
    "<",
    ">",
    "?",
    ".",
    '"',
    "'",
    "\\",
    "/",
    "{",
    "}",
    "+",
    "=",
    "-",
    "_",
    "|",
    ":",
    ";",
    ",",
    "e",
  ].includes(event.key) && event.preventDefault();
  return;
};

export function validateInfoCall(
  agentInfo: any,
  ptpAndOthePolicy: any,
  thresholdCallBackTimeandDate: any,
  paymentMethods: any,
  callPaymentMode: any
) {
  if (callPaymentMode.length == 1 && callPaymentMode[0] == "Auto Debit") {
    return (
      agentInfo?.clientDisplayName.trim() &&
      agentInfo.maleAgentName.trim() &&
      agentInfo.femaleAgentName.trim() &&
      ptpAndOthePolicy.thresholdPTPDays.toString().trim() &&
      ptpAndOthePolicy.latePaymentPolicy.toString().trim() &&
      thresholdCallBackTimeandDate.hour.toString().trim() &&
      thresholdCallBackTimeandDate.minute.toString().trim()
    );
  }

  return (
    agentInfo?.clientDisplayName.trim() &&
    agentInfo.maleAgentName.trim() &&
    agentInfo.femaleAgentName.trim() &&
    ptpAndOthePolicy.thresholdPTPDays.toString().trim() &&
    ptpAndOthePolicy.latePaymentPolicy.toString().trim() &&
    thresholdCallBackTimeandDate.hour.toString().trim() &&
    thresholdCallBackTimeandDate.minute.toString().trim() &&
    paymentMethods.length
  );
}
export function validateInfoWhatsapp(
  agentInfo: any,
  ptpAndOthePolicyWhataspp: any,
  followUpTime: any,
  paymentMethods: any,
  whatsappPaymentMode: any
) {
  if (
    whatsappPaymentMode.length == 1 &&
    whatsappPaymentMode[0] == "Auto Debit"
  ) {
    return (
      agentInfo?.clientDisplayName.trim() &&
      followUpTime?.firstFollowup.toString().trim() &&
      agentInfo.maleAgentName.trim() &&
      agentInfo.femaleAgentName.trim() &&
      followUpTime.secondFollowup.toString().trim() &&
      ptpAndOthePolicyWhataspp.thresholdPTPDays.toString().trim() &&
      ptpAndOthePolicyWhataspp.latePaymentPolicy.trim()
    );
  }
  return (
    agentInfo?.clientDisplayName.trim() &&
    followUpTime?.firstFollowup.toString().trim() &&
    agentInfo.maleAgentName.trim() &&
    agentInfo.femaleAgentName.trim() &&
    followUpTime.secondFollowup.toString().trim() &&
    ptpAndOthePolicyWhataspp.thresholdPTPDays.toString().trim() &&
    ptpAndOthePolicyWhataspp.latePaymentPolicy.trim() &&
    paymentMethods.length
  );
}
export function onlyContains(array: string[], a: string, b: string) {
  if (array?.length === 2 && array.includes(a) && array.includes(b)) {
    return true;
  }

  if (array?.length === 1 && array.includes(a)) {
    return true;
  }
  if (array?.length === 1 && array.includes(b)) {
    return true;
  }
  if (array.length == 0) return true;
  return false;
}

export function hasOnlyElements(arr1: any, arr2: any) {
  for (let i = 0; i < arr1.length; i++) {
    if (!arr2.includes(arr1[i])) {
      return false;
    }
  }
  return true;
}
export function generatePaymentObjecFromArray(
  allMethods: Array<string>,
  methodSelected: Array<string>
) {
  const x: any = {};
  allMethods.forEach((each: string) => {
    x[each] = methodSelected.includes(each);
  });
  return x;
}

export function generatePaymentArrayFromObject(obj: any) {
  const x: string[] = [];
  Object.keys(obj).forEach((each) => {
    if (obj[each]) x.push(each);
  });
  return x;
}
export function changeText(text: string) {
  const x = text.match(/\d+/g);

  return x ? "Stage " + x[0] : "";
}
export function getCountOfStep(campaignData: any, stepAll: string[]) {
  if (campaignData?.agentId) return stepAll.length;
  if (campaignData?.strategyId)
    return stepAll.indexOf("Strategy Selection") + 1;
  if (campaignData?.isFilePicked) return stepAll.indexOf("Data Upload") + 1;
  if (campaignData?.campaignId) return 1;

  return 1;
}

export function channelSearch(each: any) {
  if ("channel" in each) return each.channel;
  if ("channels" in each) return each.channels;

  if ("Call" in each && "Whatsapp" in each) return ["Call", "Whatsapp"];
  if ("Call" in each) return ["Call"];
  if ("Whatsapp" in each) return ["Whatsapp"];
}

export function genenerateObjects(
  a: any[],
  b: any[],
  key1: string,
  key2: string
) {
  let res: any = { acceptedOutcome: {}, unacceptedOutcome: {} };
  if (a.length) res = { acceptedOutcome: {} };
  a.forEach((e) => {
    res.acceptedOutcome[e] = key1;
  });
  if (b.length) {
    res = { ...res, unacceptedOutcome: {} };
  }
  b.forEach((e) => {
    res.unacceptedOutcome[e] = key2;
  });
  //  console.log(res, a.length, b.length,  "result");
  return res;
}

export const handleScriptValidation = (data: any, flow: string) => {
  if (data?.channel?.length === 0 || data?.communicationWays?.length === 0) {
    return false;
  }
  if (
    data?.payment?.paymentType?.length === 0 ||
    data?.payment?.paymentMode?.length === 0
  ) {
    return false;
  }
  if (data?.partialPayment?.[flow]) {
    if (
      Object.keys(data?.levelOfNegotiation).length === 0 ||
      Object.keys(data?.minimumNegotiationPercentage).length === 0
    ) {
      return false;
    }
  }
  if (
    data?.payment?.paymentMode === "Manual" ||
    data?.payment?.paymentMode === "Auto Debit with Manual Payment"
  ) {
    if (data?.paymentMethod?.length === 0) {
      return false;
    }
  }
  return true;
};

export function hasAllEssentials(
  setting: any,
  checkOutComes: boolean,
  checkFollowUps: boolean,
  checkUsecaseValidation ?:boolean
) {
  const channelx = setting?.channel;
  const a = "channel" in setting && setting?.channel?.length > 0;
  const b =
    "communicationWays" in setting && setting?.communicationWays?.length > 0;
  const c =
    "timing" in setting &&
    setting.timing[0]?.split(":").join() < setting.timing[1]?.split(":").join();
  let d =
    "callBackThreshold" in setting &&
    Object.keys(setting.callBackThreshold).length > 1 &&
    setting.callBackThreshold.days >= 0;
  let e =
    "promiseToPayThreshold" in setting &&
    Object.keys(setting.promiseToPayThreshold).length > 1 &&
    setting.promiseToPayThreshold.days >= 0;
  const f = "reattempt" in setting && setting?.reattempt > 0;
  let g =
    "scriptConfiguration" in setting &&
    handleScriptValidation(setting?.scriptConfiguration, setting?.flow);
  // const f = "variation" in setting && setting?.variation?.length;
  // const g =
  //   "agentDesktop" in setting &&
  //   (setting.agentDesktop === true || setting.agentDesktop === false);

  // const j =
  //   "alternateCalling" in setting &&
  //   (setting.alternateCalling === true || setting.alternateCalling === false);

   // force fully true because we not need this for other usecase

   if(checkUsecaseValidation){
      d = true 
      e = true 
   }


  if (checkOutComes) {
    const h =
      "acceptedOutcome" in setting &&
      Object.keys(setting.acceptedOutcome).length &&
      "unacceptedOutcome" in setting &&
      Object.keys(setting.unacceptedOutcome).length;

    if (checkFollowUps) {
      const i =
        "followUps" in setting &&
        Object.values(setting.followUps).length > 0 &&
        !_.some(setting.followUps, _.isUndefined);

      if (channelx === "call" || channelx === "sms" || channelx === "mail") {
        return a && b && c && d && e && f && g && h && i;
      } else {
        return a && b && c && e && f && g && h && i;
      }
    }
    return a && b && c && d && e && f && g;
  }

  if (channelx === "call" || channelx === "sms" || channelx === "mail") {
    return a && b && c && d && e && f && g;
  } else {
    return a && b && c && e && f && g;
  }
}

export function seperateByComma(array: string[] | any) {
  if (!array) return "";
  return array.join(",");
}

export function convertTo24Hour(
  hour: number,
  minute: number,
  amPm: string
): string {
  if (amPm === "PM" && Number(hour) !== 12) {
    hour = Number(hour) + 12;
  }
  if (amPm === "AM" && Number(hour) === 12) {
    hour = 0;
  }
  const newHour = Number(hour) < 10 ? "0" + Number(hour) : Number(hour);
  const newMinute = Number(minute) < 10 ? "0" + Number(minute) : Number(minute);

  return `${newHour}:${newMinute}`;
}

export function convertTo12Hour(time: string): string[] {
  let [hour, minute] = time?.split(":").map((x) => parseInt(x));
  const amPm = Number(hour) >= 12 ? "PM" : "AM";
  hour %= 12;
  hour = hour || 12;
  minute = Number(minute) + 1 - 1;
  const newHour = Number(hour) < 10 ? "0" + Number(hour) : Number(hour);
  const newMinute = Number(minute) < 10 ? "0" + Number(minute) : Number(minute);

  return [`${newHour}:${newMinute}`, `${amPm}`];
}

export function generateFlow(arr: string[], languageFlow: any) {
  const obj: any = {};
  for (const key in languageFlow) {
    if (key == "oit" || key == "noc" || key == "npa" || key == "femi") {
      obj[key.toUpperCase()] = languageFlow[key];
    } else {
      obj[key] = languageFlow[key];
    }
  }

  return obj;
}
export function getChannelsFromAgent(obj: any) {
  const arr = Object.keys(obj);
  if (arr.includes("Call") && arr.includes("Whatsapp")) {
    return ["Call", "Whatsapp"];
  }
  if (arr.includes("Call")) {
    return ["Call"];
  }
  if (arr.includes("Whatsapp")) {
    return ["Whatsapp"];
  }
  return [];
}

export function isSorted(arr: Array<string>, ascending = true) {
  if (!ascending) {
    let x = true;
    let last = arr[0];

    arr.forEach((each: string) => {
      if (each < last) {
        x = false;
      } else {
        last = each;
      }
    });
    return x;
  } else {
    let x = true;
    let last = arr[0];
    if (arr.length == 1) return true;
    arr.forEach((each: string, index: number) => {
      if (index > 0 && last >= each) {
        x = false;
      } else {
        last = each;
      }
    });
    return x;
  }
}

export const warn = (allData: any) => {
  console.log(allData, "ALLLISBELL");
  //level 1
  allData.forEach((EachFlow: any) => {
    const timeArray: string[] = [];
    //level 2
    if ("nugeBoxes" in EachFlow) {
      let hasTime = true;
      EachFlow.nugeBoxes.forEach((eachNudge: any) => {
        if (eachNudge.timing)
          timeArray.push(eachNudge.timing[0].split(":").join(""));
        else {
          hasTime = false;
        }
      });
      if (hasTime && !isSorted(timeArray)) {
        toast.error("Improper start time for nudges in " + EachFlow.flow);
      }
    } else {
      if ("ranges" in EachFlow) {
        //level 2
        EachFlow.ranges.forEach((EachFlowDayWise: any) => {
          const timeArray: string[] = [];

          //level 3
          if ("nugeBoxes" in EachFlowDayWise) {
            let hasTime = true;
            EachFlowDayWise.nugeBoxes?.forEach((eachNudge: any) => {
              if (eachNudge.timing) {
                timeArray.push(eachNudge.timing[0].split(":").join(""));
              } else {
                hasTime = false;
              }
            });
            if (!isSorted(timeArray) && hasTime) {
              toast.error(
                "Improper start time for nudges " + EachFlowDayWise.flow
              );
            }
          }
        });
      }
    }
  });
};
