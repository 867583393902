// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RKQbcyitZxAt6RaFqmMi div{width:200px;height:30px;border-radius:4px;background-color:var(--primary-color);color:#fff;text-align:center;display:flex;justify-content:center;align-items:center;margin-bottom:18px}.RKQbcyitZxAt6RaFqmMi img{margin:3px}`, "",{"version":3,"sources":["webpack://./src/components/moduleComponents/transcript/newReportIssue/newNoIssue/NewNoIssue.module.scss"],"names":[],"mappings":"AACE,0BACE,WAAA,CACA,WAAA,CACA,iBAAA,CACA,qCAAA,CACA,UAAA,CACA,iBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,kBAAA,CAEF,0BACE,UAAA","sourcesContent":[".wrapper {\n  div {\n    width: 200px;\n    height: 30px;\n    border-radius: 4px;\n    background-color: var(--primary-color);\n    color: white;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 18px;\n  }\n  img {\n    margin: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `RKQbcyitZxAt6RaFqmMi`
};
export default ___CSS_LOADER_EXPORT___;
