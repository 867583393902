import axios from "axios";
import { call, put, select } from "redux-saga/effects";
import actionTypes from "../actionTypes/login.actionTypes";
import dashboardAction from "../../../dashboard/actionTypes";
import actionTypesDashboard from "../../../dashboard/actionTypes";
import { config, ONBOARDING_URL } from "../../../../services/ApiRoutes";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FilterActionTypes } from "../../../filters/actions/actionTypes";
import { screenType } from "../../../../constants";
import { ACCESS_TOKEN_EXPIRY_TIME } from "../../../../constants";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { setSelectedIcon, setShowRightSideBar } from "../../../filters/actions";
import { setCampaignClientName } from "../../../campaign/actions";
import campaignActions from "../../../campaign/actionTypes/campaign.actionTypes";

// export function* loginWorker() {
//  try {
//       // let response = yield call(config.POST_WITH_HEADER,);

//    } catch (error) {

//    }
// }
let cancelTokenRD: any;
export function* schemaUpdateWorker(data: any): any {
  try {
    const response: any = yield call(
      config.PATCH,
      ONBOARDING_URL.UPDATE_SCHEMA,
      data.payload
    );

    // yield put({                                      // not returning any schema, just msg
    //   type: actionTypes.SET_SCHEMA_UPDATE_SUCCESS,
    //   payload: response.data.data.schema,
    // });
  } catch (err) {
    yield put({
      type: actionTypes.SET_SCHEMA_UPDATE_FAILURE,
      payload: "error",
    });
    //
  }
}

export function* requestFeatureWorker(data: any): any {
  try {
    const response: any = yield call(
      config.POST_WITH_HEADER,
      `https://${process.env.REACT_APP_SERVER_URL}${ONBOARDING_URL.REQUEST_FEATURE}`,
      data.payload
    );

    yield put({
      type: actionTypes.SET_REQUEST_FEATURE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: actionTypes.SET_REQUEST_FEATURE_FAILURE,
      payload: err,
    });
    //
  }
}
let id: any;
export function* requestSchemaOfClientWorker(data: any): any {
  try {
    // toast.dismiss()
    // id=toast.loading("Please wait! configuring  ...");
    // if (typeof cancelTokenRD != typeof undefined) {
    //   cancelTokenRD.cancel("Operation canceled due to new request.");
    // }
    // cancelTokenRD = axios.CancelToken.source();
    // const response: any = yield call(
    //   config.GET_WITH_PARAMS_CANCEL_TOKEN,
    //   `https://${process.env.REACT_APP_SERVER_URL}${ONBOARDING_URL.GET_SCHEMA}${data.payload.id}`,
    //   { originalId: data.payload.originalId },
    //   cancelTokenRD.token
    // );
    // toast.dismiss();
    // toast.success("Successfully  configured")
    // toast.update(id, { render: "Successfully  configured", type: "success", isLoading: false ,toastId:id,autoClose:2000});
    // setTimeout(()=>{toast.dismiss()},2000)
    yield put({
      type: campaignActions.SET_CLIENT_NAME,
      payload: data.payload.filterObj.options[0].name,
    });
    yield put(setShowRightSideBar(false));
    yield put(setSelectedIcon(""));
    yield put({
      type: actionTypes.GET_SCHEMA_OF_CLIENT_REQUEST_SUCCESS,
    });
    yield put({
      type: FilterActionTypes.PUT_FILTER_OPTIONS_SELECTED_IN_REDUX,
      payload: data.payload.filterObj,
    });
    // yield put({
    //   type: actionTypesDashboard.SET_SELECTED_SOURCE_TAB,
    //   payload: "",
    // });
    // yield put({
    //   type: dashboardAction.SET_HEADER_TABS,
    //   payload: { channelTab: {}, subChannelTab: {}, subModuleTab: {} },
    // });
  } catch (err) {
    // toast.dismiss()
    // toast.error("Couldn't configure!")
    // toast.update(id, { render: "Fialed to configure", type: "error", isLoading: false ,toastId:id,autoClose:2000});
    // setTimeout(()=>{toast.dismiss()},2000)
    yield put({
      type: actionTypes.GET_SCHEMA_OF_CLIENT_REQUEST_FAILURE,
      payload: "error",
    });
    //  yield put ({
    //   type: "PUT_FILTER_OPTIONS_SELECTED_IN_REDUX",
    //   payload: {
    //     type: "clientName",
    //     options: [{id:"62623fda3b3b877936e3bd16",
    //     name:"Cred Testing",
    //     type:"Internal"}],
    //   },
    //  })
  }
}

export function* getTeamDataWorker(action: any): any {
  try {
    const res = yield call(
      config.GET_WITH_PARAMS,
      ONBOARDING_URL.TEAMS_DATA,
      action.payload
    );
    yield put({
      type: actionTypes.GET_TEAM_DATA_SUCCESS,
      payload: res.data.data,
    });
    //console.log(res.data.data, action.payload, "teams");
    // yield put({type: action.SET_CURRENT_TEAM_PROFILE, payload: {}})
  } catch (error) {
    yield put({ type: actionTypes.GET_TEAM_DATA_FAILURE, payload: error });
  }
}
export function* getAccountDetailsByClientWorker(action: any): any {
  try {
    const res = yield call(
      config.GET_WITH_PARAMS,
      ONBOARDING_URL.GET_ALL_DETAILS_CLIENT,
      {
        accountName: action.payload,
      }
    );
    yield put({
      type: actionTypes.GET_ALL_CLIENT_DATA_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    yield put({ type: actionTypes.GET_ALL_CLIENT_DATA_ERROR, payload: error });
  }
}
export function* updateUserDataWorker(action: any): any {
  try {
    const res = yield call(
      config.POST_WITH_BODY,
      ONBOARDING_URL.UPDATE_ACCOUNT,
      action.payload
    );
    yield put({
      type: actionTypes.UPDATE_USER_DATA_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_USER_DATA_ERROR, payload: error });
  }
}
export function* getUserDataWorker(action: any): any {
  try {
    const res = yield call(
      config.GET_WITH_HEADER,
      ONBOARDING_URL.FETCH_USER_DETAIL,
      { ...action.payload }
    );
    yield put({
      type: actionTypes.GET_USER_DATA_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    yield put({ type: actionTypes.GET_USER_DATA_ERROR, payload: error });
  }
}

export function* getTeamSchemaWorker(action: any): any {
  try {
    const res = yield call(
      config.GET_WITH_PARAMS,
      ONBOARDING_URL.TEAM_SCHEMA,
      action.payload
    );
    yield put({
      type: actionTypes.GET_TEAM_SCHEMA_SUCCESS,
      payload: res.data?.[0],
    });
  } catch (err) {
    yield put({ type: actionTypes.GET_TEAM_SCHEMA_FAILURE, payload: err });
  }
}

export function* getTeamTabsWorker(action: any): any {
  try {
    const res = yield call(
      config.GET_WITH_PARAMS,
      ONBOARDING_URL.GET_TEAM_TABS
    );
    yield put({
      type: actionTypes.GET_TEAM_TAB_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    yield put({ type: actionTypes.GET_TEAM_TAB_FAILURE, payload: err });
  }
}

export function* getTeamChannelsWorker(action: any): any {
  try {
    const res = yield call(
      config.GET_WITH_PARAMS,
      ONBOARDING_URL.GET_TEAM_CHANNELS,
      action.payload
    );
    yield put({
      type: actionTypes.GET_TEAM_CHANNEL_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    yield put({ type: actionTypes.GET_TEAM_CHANNEL_FAILURE, payload: error });
  }
}

export function* getSidebarModulesWorker(action: any): any {
  try {
    const res = yield call(config.GET, ONBOARDING_URL.GET_SIDEBAR_MODULES);
    yield put({
      type: actionTypes.GET_SIDEBAR_MODULE_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    yield put({ type: actionTypes.GET_SIDEBAR_MODULE_FAILURE, payload: err });
  }
}

export function* logOutDataApiWorker(action: any): any {
  const jwtTokenTime = secureLocalStorage.getItem("jwt_token_time")!;
  const diff = moment().diff(moment(jwtTokenTime));
  const timeSpan = ACCESS_TOKEN_EXPIRY_TIME;
  if (diff < timeSpan) {
    // toast.dismiss();
    // toast.loading("Logging Out ...");
    try {
      if (action.payload?.refreshToken) {
        const result = yield call(
          config.POST_WITH_BODY,
          `${ONBOARDING_URL.LOG_OUT}`,
          action.payload
        );

        yield put({
          type: actionTypes.USER_LOGOUT_SUCCESS,
          payload: result.data.data,
        });
        window.sessionStorage.clear();
        secureLocalStorage.clear();

        // toast.dismiss();
        // toast.success("Logged Out Successfully");
      }
    } catch (error) {
      yield put({
        type: actionTypes.USER_LOGOUT_FAILURE,
        payload: error,
      });
      // toast.dismiss();
      // toast.error("Log Out Unsuccessful");
      console.error(error);
    }
  }
}
export function* refreshTokenAPIworker(action: any): any {
  const jwtTokenTime = secureLocalStorage.getItem("jwt_token_time")!;
  const diff = moment().diff(moment(jwtTokenTime));
  const timeSpan = ACCESS_TOKEN_EXPIRY_TIME;
  if (diff < timeSpan) {
    try {
      const res = yield call(
        config.POST_WITH_BODY,
        `${ONBOARDING_URL.REFRESH_TOKEN}`,
        action.payload
      );
      // console.log("got axios", res.data.data.accessToken);
      yield put({
        type: actionTypes.REFRESH_TOKEN_API_SUCCESS,
        payload: res.data.data,
      });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.data.accessToken}`;
      // console.log("set axios", axios.defaults.headers.common["Authorization"]);
      secureLocalStorage.setItem("jwt_token", res.data.data.accessToken);
      secureLocalStorage.setItem("refresh_token", res.data.data.refreshToken);
      secureLocalStorage.setItem(
        "jwt_token_time",
        new Date() as never as string
      );
    } catch (err) {
      console.error(err);
      yield put({ type: actionTypes.REFRESH_TOKEN_API_FAILURE, payload: err });
    }
  }
}

export function* verifyInviteTokenWorker(action: any): any {
  try {
    const res = yield call(
      config.GET_WITH_PARAMS,
      `${ONBOARDING_URL.INVITE_TOKEN_VERIFICATION}`,
      { token: action.payload }
    );
    yield put({
      type: actionTypes.VERIFY_INVITE_SUCCESS,
      payload: res.data.data,
    });
    if (
      "TOKEN_EXPIRED" === res.data.data.status ||
      "LOGIN" === res.data.data.status
    ) {
      window.location.replace(
        `/${screenType.login.routeName}?linkExpired=true`
      );
    }
  } catch (err) {
    console.error(err);
    yield put({ type: actionTypes.VERIFY_INVITE_FAILURE, payload: err });
  }
}
