import React, { useEffect, useState } from 'react'
import style from  "./Preview.module.scss"
import logo from "../../../../../theme/assets/svg/campaign/saarthiLogo.svg"
import backArrow from "../../../../../theme/assets/svg/campaign/backArrow.svg"
import forwardArrow from "../../../../../theme/assets/svg/campaign/forwardArrow.svg"
import { useSelector } from "react-redux";
import TemplateMsg from '../../../campaign/omniChannel/templatMsg/TemplateMsg'
function Preview(props :any) {
    const [showTemplates , setShowTemplates] = useState("")
    const [initial , setInitial] = useState(0)
    const [templateName , setTemplateName] = useState()

    const templatesArray = useSelector(
        (store :any) => store?.omniChannelReducer?.getTemplates
    )
   
    useEffect(
        () => {
         if(templatesArray){
             setShowTemplates(templatesArray?.[0]?.templates?.templateBody)
             setTemplateName(templatesArray?.[0]?.templates?.templateName)
         }
        },[templatesArray]
    )
    const moveImg = () => {
        if(initial < templatesArray?.length){
            setShowTemplates(templatesArray?.[initial +1]?.templates?.templateBody)
            setInitial(prev => (initial +1))
            setTemplateName(templatesArray?.[initial +1]?.templates?.templateName)
        }
    }
const moveToback =() => {
    if(initial >0){
        setShowTemplates(templatesArray?.[initial -1]?.templates?.templateBody)
            setInitial(prev => (initial -1))
            setTemplateName(templatesArray?.[initial -1]?.templates?.templateName)
    }
}
  return (
    <div className={style.outerSMSPreviewDivWrapper}>
        <div className={style.previewNote}>
        <div className={`${style.cursor} ${style.arrowbackwidth}` } onClick={() => moveToback()}> {initial >0 ?<img src={backArrow}></img>: "" } </div>
          <div className={style.templateName}>  {templateName ?templateName : "Preview" }</div> 
          <div className={style.forwardArrow} onClick={() => moveImg()}>
                 {initial < templatesArray?.length-1 ? <img src={forwardArrow}  ></img> : ""}
               </div>
            </div>
        <div className={style.outerPreviewDiv}>
            <div className={style.header}>
               <div className={style.imgLogo}> <img src={logo}></img></div>
               <div className={style.tittle}>
                   <div className={style.tittleHead}>Saarthi</div>
                   <div className={style.tittleSecond}>tap here for contact info </div>
               </div>
            </div>
            <div className={style.backgroundWhatsApp}
            > 
            <div className={style.todayDiv}>
                <div className={style.today}>Today</div>
            </div>
            {
                 showTemplates?.length ?
                <TemplateMsg template ={showTemplates}/>:""
            }
    
            </div>
        </div>
    </div>
  )
}

export default Preview