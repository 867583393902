import React, { useEffect, useState } from "react";
import "./MapData.css";
import { noPhoneDataIcon } from "../../../../../theme/assets/svg";
import { useSelector } from "react-redux";
// import { RootState } from "../../../../../../redux";
import Checkbox from "../../../../generic/checkbox/Checkbox";
import SingleProgressBar from "../../../generic/progressBarComponent/ProgressBarComponent";
import MultiProgress from "react-multi-progress";

export default function MapDataComponent(props) {
  const [isLow, seIsLow] = useState(true);
  const [isMedium, setIsMedium] = useState(false);
  const [isHigh, setIsHigh] = useState(false);
  const [riskName, setRiskName] = useState("");

  function getLowChecked() {
    seIsLow(true);
    setIsMedium(false);
    setIsHigh(false);
  }

  function getMediumChecked() {
    seIsLow(false);
    setIsMedium(true);
    setIsHigh(false);
  }

  function getHighChecked() {
    seIsLow(false);
    setIsMedium(false);
    setIsHigh(true);
  }

  useEffect(()=>{
      if(isHigh){
          setRiskName("High")
      } else if(isMedium){
        setRiskName("Medium")
      } else {
          setRiskName("Low")
      }

  },[isLow, isMedium, isHigh])

  return (
    <>
      <div className="mapDataClassDiv">
        <div className="mapDataHeader">
          <p>India</p>
          <div style={{width:"200px"}}>
          <MultiProgress
        transitionTime={0.2}
        elements={[
          {
            value: 15,
            color: `${props.chartDetails[0].fill}`
          },
          {
            value: 45,
            color: `${props.chartDetails[1].fill}`
          },
        ]}
        height={20}
        backgroundColor={props.chartDetails[2].fill}
        border={"1px solid solid"}
        round={false}
        roundLastElement={false}
      />
          </div>
        </div>
        <hr className="horizontalLine" />
        <div style={{height:"400px", marginTop:"5%"}}>
        {props.chartDetails &&
          props.chartDetails?.map((each, i) => {
            
            return (
              <div style={{maxHeight:"380px", overflowY:"scroll"}} key={i}>
                {riskName==each.name && Object.keys(each.data).length > 0 && Object.values(each.data)?.map((e,index)=>{
                   return <div className="mapDataBodyDiv" key={index}>
                    <div style={{width:"40%"}}>{e.key}</div>
                    <div><SingleProgressBar
                    isLoading={false}
                    // percent={((100*props.data[refenceKeyForData][each.referenceKeyName])/total).toFixed(0)}
                    percent={((100*e.val)/900).toFixed(0)}
                    size={"medium"}
                    showInfo={true}
                    backgroundColor={each.fill}
                  /></div>
                  </div>
                })
                  
                }
              </div>
            );
          })}
          </div>
        <div className="mapDataFooterDiv">
          <div className="checkboxClass">
            <Checkbox
              checked={isLow}
              onChange={getLowChecked}
              extraSpan={
                isLow ? "extraSpanCheckBox" : "extraSpanCheckBoxBefore"
              }
              background={props.chartDetails[0].fill}
            />
            <span style={{ margin: "0 5px" }}>
              {props.chartDetails[0].name}
            </span>
          </div>
          <div className="checkboxClass">
            <Checkbox
              checked={isMedium}
              onChange={getMediumChecked}
              extraSpan={
                isMedium ? "extraSpanCheckBox" : "extraSpanCheckBoxBefore"
              }
              background={props.chartDetails[1].fill}
            />
            <span style={{ margin: "0 5px" }}>
              {props.chartDetails[1].name}
            </span>
          </div>
          <div className="checkboxClass">
            <Checkbox
              checked={isHigh}
              onChange={getHighChecked}
              extraSpan={
                isHigh ? "extraSpanCheckBox" : "extraSpanCheckBoxBefore"
              }
              background={props.chartDetails[2].fill}
            />
            <span style={{ margin: "0 5px" }}>
              {props.chartDetails[2].name}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
