export default {
  SET_FIELD_DATA_SIGNUP: "SET_FIELD_DATA_SIGNUP",
  TOGGLE_TERMS_AND_PRIVACY_SIGNUP: "TOGGLE_TERMS_AND_PRIVACY_SIGNUP",
  SIGN_UP_API_REQUEST: "SIGN_UP_API_REQUEST",
  SIGN_UP_API_SUCCESS: "SIGN_UP_API_SUCCESS",
  SIGN_UP_API_FAILURE: "SIGN_UP_API_FAILURE",
  SET_PASSWORD_DATA_SIGNUP: "SET_PASSWORD_DATA_SIGNUP",
  VERIFY_LINK_VALIDITY_REQUEST_SIGNUP: "VERIFY_LINK_VALIDITY_REQUEST_SIGNUP",
  VERIFY_LINK_VALIDITY_SUCCESS_SIGNUP: "VERIFY_LINK_VALIDITY_SUCCESS_SIGNUP",
  VERIFY_LINK_VALIDITY_FAILURE_SIGNUP: "VERIFY_LINK_VALIDITY_FAILURE_SIGNUP",
  CREATE_PASSWORD_REQUEST_SIGNUP: "CREATE_PASSWORD_REQUEST_SIGNUP",
  CREATE_PASSWORD_SUCCESS_SIGNUP: "CREATE_PASSWORD_SUCCESS_SIGNUP",
  CREATE_PASSWORD_FAILURE_SIGNUP: "CREATE_PASSWORD_FAILURE_SIGNUP",
  AGREE_TO_TERMS_CLOSE: "AGREE_TO_TERMS_CLOSE",

  SEND_OTP_SING_UP_REQUEST: "SEND_OTP_SING_UP_REQUEST",
  SEND_OTP_SING_UP_SUCCESS: "SEND_OTP_SING_UP_SUCCESS",
  SEND_OTP_SING_UP_FAILURE: "SEND_OTP_SING_UP_FAILURE",

  VERIFY_OTP_SING_UP_REQUEST: "VERIFY_OTP_SING_UP_REQUEST",
  VERIFY_OTP_SING_UP_SUCCESS: "VERIFY_OTP_SING_UP_SUCCESS",
  VERIFY_OTP_SING_UP_FAILURE: "VERIFY_OTP_SING_UP_FAILURE",

  RESET_EXPERIENCE_NOW: "RESET_EXPERIENCE_NOW",

  CALL_EXPERIENCE_NOW_REQUEST: "CALL_EXPERIENCE_NOW_REQUEST",
  CALL_EXPERIENCE_NOW_SUCCESS: "CALL_EXPERIENCE_NOW_SUCCESS",
  CALL_EXPERIENCE_NOW_FAILURE: "CALL_EXPERIENCE_NOW_FAILURE",
  SET_TIMER_FOR_FREE_CREADIT: "SET_TIMER_FOR_FREE_CREADIT",

  WHATSAPP_EXPERIENCE_NOW_REQUEST: "WHATSAPP_EXPERIENCE_NOW_REQUEST",
  WHATSAPP_EXPERIENCE_NOW_SUCCESS: "WHATSAPP_EXPERIENCE_NOW_SUCCESS",
  WHATSAPP_EXPERIENCE_NOW_FAILURE: "WHATSAPP_EXPERIENCE_NOW_FAILURE",

  EMAIL_EXPERIENCE_NOW_REQUEST: "EMAIL_EXPERIENCE_NOW_REQUEST",
  EMAIL_EXPERIENCE_NOW_SUCCESS: "EMAIL_EXPERIENCE_NOW_SUCCESS",
  EMAIL_EXPERIENCE_NOW_FAILURE: "EMAIL_EXPERIENCE_NOW_FAILURE",
  IS_MAIA_POPUP_OPEN: "IS_MAIA_POPUP_OPEN",
  SET_EXP_NOW_COUNT: "SET_EXP_NOW_COUNT",
  SET_INTITIAL_EXP_NOW_COUNT: "SET_INTITIAL_EXP_NOW_COUNT",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
};
