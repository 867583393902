import React, { useEffect, useState } from "react";
import styles from "./CountdownChip.module.scss";
import { getCurrentTab } from "../../../redux/profile/yourAccount/organizationDetails/actions/organizationDetails.actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ribbonSvg from "./ribbon.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: "#676767",
    borderRadius: "5px",
    color: "#fff",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
    maxWidth: "300px",
    overflowWrap: "break-word",
  },
  customArrow: {
    color: "#676767",
    fontSize: 9,
  },
}));

const CountdownChip = (props: {
  isCountdown: boolean;
  credits?: number;
  baseTimer?: string | number;
  showComponent?: boolean;
  price?: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [timerFromProps, setTimerFromProps]: any = useState("");
  const [timer, setTimer]: any = useState(0);

  useEffect(() => {
    if (typeof props.baseTimer !== "string" || !props.baseTimer) return;

    const timeDifference =
      (new Date(props.baseTimer).getTime() - new Date().getTime()) / 1000;

    setTimerFromProps(timeDifference);
  }, [props.baseTimer]);

  useEffect(() => {
    if (!timerFromProps) return;

    setTimer(timerFromProps);

    const interval = setInterval(() => {
      setTimer((prevTimer: any) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timerFromProps]);

  const hours = Math.floor(timer / 3600);
  const minutes = Math.floor((timer % 3600) / 60);
  // const seconds = timer % 60;

  // const formatTimer = (timer: number): string => {
  //   let hours = parseInt(String((timer / 60) % 24), 10);
  //   let minutes = parseInt(String(timer % 60), 10);

  //   console.warn(hours, minutes);

  //   hours = Number(timer === 1440 ? "24" : hours < 10 ? "0" + hours : hours);
  //   minutes = Number(minutes < 10 ? "0" + minutes : minutes);

  //   return `${String(hours)}h ${String(minutes)}m`;
  // };

  // const startCountdownTimer = (duration: number) => {
  //   setTimer(formatTimer(duration));

  //   const id = setInterval(() => {
  //     --duration;
  //     setTimer(formatTimer(duration));
  //     if (timer === "0h 0m") clearInterval(id);
  //   }, 60000);

  //   return () => {
  //     clearInterval(id);
  //   };
  // };

  // useEffect(() => {
  //   let oldTime;
  //   if (props?.baseTimer) {
  //     const ans = Math.floor(
  //       (Number(new Date(props?.baseTimer)?.getTime()) -
  //         Number(new Date()?.getTime())) /
  //         1000
  //     );
  //     oldTime = Math.floor(ans / 60);
  //   }

  //   const timer: any = props.baseTimer ? oldTime : 24 * 60;
  //   startCountdownTimer(timer);
  // }, [props.baseTimer]);

  return (
    <div>
      {props.showComponent ? (
        <div
          className={styles.chip}
          onClick={() => {
            if (props.isCountdown) {
              dispatch(getCurrentTab("Organization"));
              navigate("/profile/your-account");
            }
          }}
        >
          {props.isCountdown ? (
            `${hours}h ${
              minutes < 10 ? `0${minutes}` : minutes
            }m to claim free credits`
          ) : (
            <Tooltip
              title={`You can use these ${
                props?.price === "Free" ? "free" : ""
              } credits to run live communication campaigns.`}
              placement={"top"}
              classes={{
                arrow: classes.customArrow,
                tooltip: classes.customTooltip,
              }}
              arrow={true}
            >
              <div className={styles.countDownToolTip}>
                <img className={styles.ribbonImage} src={ribbonSvg} />{" "}
                <span className={styles.ribbonTextNumber}>
                  {props?.credits && props?.credits > 0
                    ? props?.credits?.toFixed(2)
                    : 0}
                </span>{" "}
                Credits
              </div>
            </Tooltip>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CountdownChip;
