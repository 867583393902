import { eachDayOfInterval } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import "./CardComponent.css";
import PravidIcons from "../../../generic/pravidIcons/PravidIcons";
import Loader from "../../../generic/loader/Loader";

const CardComponent = ({ heading, data, icon, isCardLoading }) => {
  const selectedCardChannel = useSelector((state) => {
    return state.dashboardReducer?.channelTab?.name;
  });

  const roundToTwoDecimalPlaces = (number) => {
    if (Number.isInteger(number)) {
      return number;
    } else {
      return  typeof number=== 'string' ? number :  number?.toFixed(2);
    }
  };

  return (
    <div>
      <div className="containerACard" style={{ padding: "20px" }}>
        {" "}
        <div style={{ width: "20%" }}>
          <PravidIcons
            extraClass={
              selectedCardChannel === "SMS" || selectedCardChannel === "Mail"
                ? "cardAIconStyling"
                : "cardAIcon"
            }
            activeIcon={icon}
          />
        </div>
        <div className="boxACard" style={{ width: "80%" }}>
          <div className="headACard" data-testid="data_test">
            {heading}
          </div>

          {heading === "Call In Progress" ? (
            data.map((each, i) => {
              return (
                <div className="dataACard" key={i}>
                  {isCardLoading ? (
                    <div>
                      <Loader isExtraGifClass="cardLoaderStyling" />
                    </div>
                  ) : (
                    <div>
                      {
                        <div className="cardDataACard">
                          {`${Object.keys(each)}:`} &nbsp;&nbsp;
                          <p className="innerDataACard">{`${Object.values(
                            each
                          )}`}</p>
                        </div>
                        // })
                      }
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className="dataACard">
              {isCardLoading ? (
                <div>
                  <Loader isExtraGifClass="cardLoaderStyling" />
                </div>
              ) : (
                <p>{data && roundToTwoDecimalPlaces(data)}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
