import React, { useState, useEffect } from "react";
import styles from "./EmailTemplate.module.scss";
import { InfoToolTip } from "../../../../generic/infoToolTip/InfoToolTip";
import { TagsInput } from "../../../../generic/tagsInput/TagsInput";
import Button from "../../../../generic/button/Button";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../redux";
import { validateEmailDomain } from "../../../../../utils/emailValidation";
import {
  sendDNSToDevEmailDNSData,
  setEmailScreen,
} from "../../../../../redux/integration_V2.0/email/actions";
import { verify, loading } from "../../../../../theme/gif/index";
import _ from "lodash";

export const EmailTemplate = () => {
  const [refreshBtn, setRefreshBtn] = useState(false);
  const [showEmailField, setShowEmailField] = useState(false);
  const [emails, setEmails] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [errorTags, setErrorTags] = useState<{
    repeated: boolean;
    invalid: boolean;
  }>({ repeated: false, invalid: false });

  const organizationInfo = useSelector(
    (store: RootState) =>
      store?.profile?.yourAccountReducer?.organization.organizationDetails
  );

  const accountId = useSelector(
    (store: RootState) =>
      store?.loginReducer?.userLoginInfo?.accountDetails?.[0]?.id
  );

  const selectedIntegrationEmail = useSelector(
    (store: RootState) =>
      store?.integrationReducer?.allIntegrationsReducer?.selectedIntegrationData
  );

  const emailSendReducerData = useSelector(
    (store: RootState) => store?.integrationReducer?.emailReducer
  );

  const dispatch = useDispatch();

  const handleSendToDev = () => {
    dispatch(
      sendDNSToDevEmailDNSData({
        id: selectedIntegrationEmail?._id
          ? selectedIntegrationEmail?._id
          : selectedIntegrationEmail?.id,
        toEmail: emails,
        orgName: organizationInfo?.organizationName,
        accountId: accountId,
      })
    );
    dispatch(setEmailScreen("showTemplate"));
  };

  useEffect(() => {
    if (
      _.isEqual(emails, selectedIntegrationEmail?.integrationDetails?.devMails)
    ) {
      setRefreshBtn(false);
    } else {
      setRefreshBtn(true);
    }
  }, [emails, selectedIntegrationEmail?.integrationDetails?.devMails]);

  const handleSetEmails = (e: any) => {
    setEmails(e);
  };

  const handleSendToDevValidation = () => {
    if (errorTags.repeated) {
      return false;
    }
    if (errorTags.invalid) {
      return false;
    }
    if (emails?.length === 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setIsValid(handleSendToDevValidation());
  }, [emails, errorTags]);

  useEffect(() => {
    let invalid = false;
    let repeated = false;
    // check fro valid email
    for (const i of emails) {
      if (!validateEmailDomain(i, organizationInfo?.organizationEmail)) {
        invalid = true;
        break;
      }
    }
    // check for repeated email
    for (const i of emails) {
      if (emails.filter((e) => e === i).length > 1) {
        repeated = true;
        break;
      }
    }
    setErrorTags({ invalid, repeated });
  }, [emails]);

  useEffect(() => {
    if (
      selectedIntegrationEmail?.integrationDetails &&
      Object.keys(selectedIntegrationEmail?.integrationDetails)?.length > 0
    ) {
      if (selectedIntegrationEmail?.integrationDetails?.devMails) {
        setShowEmailField(true);
        setEmails(selectedIntegrationEmail?.integrationDetails?.devMails);
      } else {
        setShowEmailField(false);
        setEmails([]);
      }
    }
  }, [selectedIntegrationEmail?.integrationDetails]);

  return (
    <div className={styles.emailTemplateWrapper}>
      <div className={styles.descriptionDiv}>
        Enter coworker&apos;s email address and we&apos;ll send them everything
        they need to install these records
      </div>
      <div className={styles.emailTemplateDiv}>
        <div className={`${styles.labelStylingDiv}`}>
          <div className={styles.labelTextStyling}>Email Template</div>
          <div className={styles.labelInfoStyling}>
            <InfoToolTip
              message={
                "Add an email of a teammate who can help you technically in this setup. We will send all the necessary instructions to them."
              }
              isError={false}
              position="top-end"
              isIconSmall
            />
          </div>
        </div>
        <div className={`${styles.templateDiv}`}>
          <div className={styles.emailLabelDiv}>
            <div className={styles.emailLabelText}>To Email</div>
            <div className={styles.inputContainerDiv}>
              <TagsInput
                extraClass={`${styles.tagsInputExtraStyling} ${
                  errorTags.repeated || errorTags.invalid
                    ? styles.tagsInputErrorStyling
                    : ""
                }`}
                extraClassInput={styles.tagsTextStyling}
                extraTagsStyling={styles.tagsTextStyling}
                validEmailDomain={organizationInfo?.organizationEmail}
                selectedTags={handleSetEmails}
                tags={emails}
                placeholder={"Enter Email"}
                growListDirection="horizontal"
              />
              <div className={styles.tagsErrorDiv}>
                {errorTags.repeated ? (
                  <p className={styles.errorMessage}>
                    Email entered is repeated.
                  </p>
                ) : errorTags.invalid ? (
                  <p className={styles.errorMessage}>
                    Instructions can be shared only to Email IDs from your
                    company.
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className={`${styles.templateDescDiv}`}>
            <span>
              Hi, A co-worker needs your help in installing DNS records for
              domain identity verification. This will allow you to run email
              campaigns using&nbsp;
              <a
                href={"https://saarthi.ai/"}
                target="_blank"
                rel="noreferrer"
                className={styles.linkStyling}
              >
                Saarthi.ai
              </a>
              &apos;s AI enabled platform - Pravid. Use this&nbsp;
              <a>temporarily accessible link</a> to access the DNS records and
              click on verify after you are done. If you are unable to access
              the above link, paste following in a browser ➝{" "}
              <a
                href={selectedIntegrationEmail?.integrationDetails?.url}
                target="_blank"
                rel="noreferrer"
                className={styles.linkStyling}
              >
                {selectedIntegrationEmail?.integrationDetails?.url}
              </a>
            </span>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          {(selectedIntegrationEmail &&
            selectedIntegrationEmail?.integrationDetails?.devMails?.length ===
              0) ||
          refreshBtn ? (
            <Button
              text={
                emailSendReducerData?.isLoadingsendDNSToDevData
                  ? "Sending"
                  : "Send Instructions to developer"
              }
              extraClass={`${styles.buttonStyling} ${
                isValid ? styles.activeBtnStyle : styles.inActiveBtnStyling
              }`}
              image_src_left={
                emailSendReducerData?.isLoadingsendDNSToDevData && loading
              }
              imgClass={`${styles.loadingIconStyling}`}
              onClick={handleSendToDev}
            />
          ) : (
            <Button
              text="Sent Successfully"
              image_src_left={verify}
              extraClass={`${styles.buttonStyling} ${styles.sentButton}`}
              imgClass={`${styles.sentIconStyling}`}
              // onClick={onClickClose}
            />
          )}
        </div>
      </div>
    </div>
  );
};
