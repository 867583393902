import { put, call } from "redux-saga/effects";
import actionTypes from "../actionTypes/campaign.actionTypes";
import { CAMPAIGN_URL, config } from "../../../services/ApiRoutes";
import { FilterActionTypes } from "../../filters/actions/actionTypes";
import { downloadCsvFile } from "../../../utils/downloadCsvFile";

export function* getSalesOnboardingAgentConfigWorker(action: any): any {
  try {
    const response = yield call(
      config.GET_WITH_PARAMS,
      `${CAMPAIGN_URL.SALES_ONBOARDING_AGENT_CONFIG}`,
      { clientName: action.payload.clientName }
    );
    yield put({
      type: actionTypes.CAMPAIGN_SALES_ONBOARDING_AGENT_CONFIG_API_SUCCESS,
      payload: response?.data?.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.CAMPAIGN_SALES_ONBOARDING_AGENT_CONFIG_API_FAILURE,
      payload: error,
    });
  }
}
export function* getDebtCollectionAgentConfigWorker(action: any): any {
  try {
    const response = yield call(
      config.GET_WITH_PARAMS,
      `${CAMPAIGN_URL.GET_AGENT_CONFIGURATION}`,
      { clientName: action.payload.clientName }
    );
    yield put({
      type: actionTypes.CAMPAIGN_DEBT_AGENT_CONFIG_API_SUCCESS,
      payload: response?.data?.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.CAMPAIGN_DEBT_AGENT_CONFIG_API_FAILURE,
      payload: error,
    });
  }
}
export function* setClientNameWorker(action: any): any {
  yield put({ type: FilterActionTypes.REFRESH_FILTER_OPTIONS });
}
export function* getSampleCsvdownloadWorker(action: any): any {
  try {
    const response = yield call(
      config.GET_WITH_PARAMS,
      `${CAMPAIGN_URL.SAMPLE_CSV_DOWNLOAD_URL}`,
      action.payload
    );
    yield put({
      type: actionTypes.SAMPLE_CSV_DOWNLOAD_API_SUCCESS,
      payload: response?.data?.data,
    });
    downloadCsvFile(response?.data, "SampleFile");
  } catch (error) {
    yield put({
      type: actionTypes.SAMPLE_CSV_DOWNLOAD_API_FAILURE,
      payload: error,
    });
  }
}
export function* getCheckTechApiHealthWorker(action: any): any {
  try {
    const response = yield call(
      config.GET_WITH_PARAMS_HEADER,
      `${CAMPAIGN_URL.TECH_HEALTH}`,
      action.payload
    );
    yield put({
      type: actionTypes.CHECK_TECH_HEALTH_API_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHECK_TECH_HEALTH_API_FAILURE,
      payload: error,
    });
  }
}

export function* getPortTelephonyWorker(action: any): any {
  try {
    const response = yield call(
      config.GET_WITH_PARAMS_HEADER,
      `${CAMPAIGN_URL.GET_PORT}`,
      action.payload
    );
    yield put({
      type: actionTypes.GET_PORT_API_SUCCESS,
      payload: response?.data?.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_PORT_API_FAILURE,
      payload: error,
    });
  }
}
export function* getSampleCsvPaymentdownloadWorker(action: any): any {
  try {
    const response = yield call(
      config.GET_WITH_PARAMS,
      `${CAMPAIGN_URL.SAMPLE_CSV_PAYMENT_DOWNLOAD_URL}`,
      action.payload
    );
    yield put({
      type: actionTypes.SAMPLE_CSV_PAYMENT_DOWNLOAD_API_SUCCESS,
      payload: response?.data?.data,
    });
    downloadCsvFile(response?.data, "SampleFile");
  } catch (error) {
    yield put({
      type: actionTypes.SAMPLE_CSV_PAYMENT_DOWNLOAD_API_FAILURE,
      payload: error,
    });
  }
}
