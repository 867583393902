import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { CAMPAIGN_NEW_API, config } from "../../../../services/ApiRoutes";
import actionTypes from "../strategySelection.actionTypes";
import actionType from "../../createCampaign/createCampaign.actionTypes";

export function* addStrategyToCampaignAPIWorker(action: any): any {
  toast.dismiss();
  toast.loading("Please wait ...");
  try {
    if (action) {
      const res = action.payload;
      const { campaignData, token, ...updatedPayload } = res;
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${CAMPAIGN_NEW_API.ADD_STRATEGY_TO_CAMPAIGN}`,
        updatedPayload,
        {
          "x-access-token": token,
          "Content-Type": "application/json",
        }
      );
      yield put({
        type: actionTypes.ADD_STRATEGY_TO_CAMPAIGN_API_SUCCESS,
        payload: result.data.data,
      });

      yield put({
        type: actionType.SET_CAMPAIGN_DATA,
        payload: {
          data: {
            ...campaignData,
            channels: result.data.data?.channels,
            strategyId: result.data.data?.strategyId,
            flowType : result.data.data?.flowType?.maiaFlow
          },
          isNavigate: true,
        },
      });
      // console.log("resssss", resultForSchedular.data.data);
      toast.dismiss();
      toast.success("Campaign Updated Successfully");
      // toast.success(result.data.data.message);
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.ADD_STRATEGY_TO_CAMPAIGN_API_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    toast.error(error.response?.data?.error);
    console.error(error);
  }
}
