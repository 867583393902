// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vd2SdI5Me85E1pxdwqVX{display:flex;justify-content:space-between;align-items:flex-start}.X18jkvZnjyWRCDg3Ad4r{padding:1em;width:100%;height:100%}.YO97MXu9hJ2_6LuqSukm{font-family:"Roboto";font-size:24px;font-weight:500;line-height:29px;letter-spacing:0em;text-align:left;padding:.5em 0em 1em 0em}.tK9DqH_f3yPy0W7IRNgt{width:100%;border-top:1px solid #e6e6e6;margin-bottom:30px}`, "",{"version":3,"sources":["webpack://./src/screens/profile/paymentScreen/freePlanPage/FreePlanPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,sBAAA,CAGF,sBAEE,WAAA,CACA,UAAA,CACA,WAAA,CAGF,sBAEE,oBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CACA,eAAA,CAGA,wBAAA,CAIF,sBACE,UAAA,CACA,4BAAA,CACA,kBAAA","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n\n.wrapperheader {\n  // margin: 20px 25px;\n  padding: 1em;\n  width: 100%;\n  height: 100%;\n}\n\n.header {\n  //styleName: Heading 3/medium;\n  font-family: \"Roboto\";\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 29px;\n  letter-spacing: 0em;\n  text-align: left;\n  // margin-top: 16px;\n  // margin-bottom: 12px;\n  padding: 0.5em 0em 1em 0em;\n  // border-bottom: 1px solid #e6e6e6;\n}\n\n.hr {\n  width: 100%;\n  border-top: 1px solid #e6e6e6;\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `vd2SdI5Me85E1pxdwqVX`,
	"wrapperheader": `X18jkvZnjyWRCDg3Ad4r`,
	"header": `YO97MXu9hJ2_6LuqSukm`,
	"hr": `tK9DqH_f3yPy0W7IRNgt`
};
export default ___CSS_LOADER_EXPORT___;
