import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import iconCenter from "../../../../theme/assets/svg/signUpFlow/maiaIconCenter.svg";
import TourModal from "../tourPopUp/TourModal";
import { updateTourModuleWise } from "../../../../redux/signUpFlow/productTour/actions";

const TOUR_STEPS = [
  // {
  //     target:  "#auto_left_Logger",
  //     content: "This is where you can search the dashboard.",
  //     disableBeacon: true, // This makes the tour to start automatically without clicking
  //     name : "abhishek",

  //   },
  // {
  //   target:  "#raju",
  //   content: "This is where you can search the dashboard.",

  // },
  // {
  //   target:  "#auto_left_Analytics",
  //   content: "This is where you can search the dashboard.",
  //   placement : "right-end",
  //   disableBeacon: true // This makes the tour to start automatically without clicking
  // },
  {
    target: "#campaignTour",
    content:
      "Welcome to the Campaign Manager module. You’ll use this to create, edit, pause or archive your campaigns.",
    placement: "bottom-end",
    disableBeacon: true,
    img: iconCenter,
    styleMiaa: {
      position: "absolute",
      left: "29%",
    },
  },
];

const INITIAL_STATE = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};
const TourToolTip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}: any) => {
  return (
    <TourModal
      tooltipProps={tooltipProps}
      step={step}
      primaryProps={primaryProps}
    />
  );
};
// Tour component
const TourCampaign = (props: any) => {
  // Tour state is the state which control the JoyRide component
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const dispatchRedux = useDispatch();
  const loginUserId = useSelector(
    (store: any) => store.loginReducer.userLoginInfo?.userDetail?._id
  );
  useEffect(() => {
    // Auto start the tour if the tour is not viewed before
    if (!localStorage.getItem("tour")) {
      dispatch({ type: "START" });
    }
  }, []);

  const updateModuleTour = () => {
    const body = {
      userId: loginUserId,
      moduleName: "Campaign",
    };
    dispatchRedux(updateTourModuleWise(body));
  };

  const setTourViewed = () => {
    // localStorage.setItem("tour", "1");
  };

  const callback = (data: any) => {
    const { action, index, type, status } = data;
    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: "STOP" });
      updateModuleTour();
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
      updateModuleTour();
      props.nextClickFunc();
    }
  };

  const startTour = () => {
    // Start the tour manually
    dispatch({ type: "RESTART" });
  };

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton={false}
        spotlightPadding={1}
        hideCloseButton
        hideBackButton
        tooltipComponent={TourToolTip}
        styles={{
          tooltipContainer: {
            textAlign: "left",
          },
        }}
        locale={{
          last: "End",
        }}
      />
    </>
  );
};

export default TourCampaign;
