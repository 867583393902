import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import {
  CAMPAIGN_NEW_API,
  STRATEGY_API,
  config,
} from "../../../../services/ApiRoutes";
import actionTypes from "../dataUpload.actionTypes";
import actionType from "../../createCampaign/createCampaign.actionTypes";
import strategyActionTypes from "../../../strategy/strategyCreation/strategy.actionTypes";
import _ from "lodash";
import axios from "axios";
import { store } from "../../../rootStore";
import {
  // formatCSVToDataArray,
  mergeWithDefaultData,
  convertToCSV,
} from "../../../../utils/spreadSheetFunctions/index";

export function* getUploadedCSVDataAPIWorker(action: any): any {
  // console.log("action", action.payload);
  //let cancelTokenRD: any;
  try {
    if (action) {
      // if (typeof cancelTokenRD != typeof undefined) {
      //   cancelTokenRD.cancel("Operation canceled due to new request.");
      // }
      // cancelTokenRD = axios.CancelToken.source();
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${CAMPAIGN_NEW_API.GET_UPLOADED_CSV_INFO}`,
        {
          params: _.omit(action.payload, "token"),
          headers: { "x-access-token": action.payload.token },
        }
      );
      yield put({
        type: actionTypes.GET_UPLOADED_INFO_API_SUCCESS,
        payload: result.data.data,
      });
      toast.dismiss();
      toast.success(result.data.data.message);
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.GET_UPLOADED_INFO_API_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    toast.error(error.response?.data?.error);
    console.error(error);
  }
}

export function* uploadCampaignCSVSagaWorker(action: any): any {
  toast.dismiss();
  toast.loading("Please wait ...");
  try {
    if (action) {
      // let cancelToken = axios.CancelToken.source();
      // if (typeof cancelToken != typeof undefined) {
      //   cancelToken.cancel("Operation canceled due to new request.");
      // }

      const payloadData = action.payload;
      const formData = new FormData();
      payloadData.file.forEach((data: any, index: number) => {
        formData.append("uploadFile", data);
      });
      // formData.append("uploadFile", payloadData.file);
      if (payloadData?.isStrategy) {
        formData.append("strategyId", payloadData.strategyId);
        formData.append("accountId", payloadData.accountId);
        formData.append("useCaseId", payloadData.useCaseId);
      } else {
        formData.append("campaignManagerId", payloadData.id);
      }

      formData.append("area", payloadData.area);
      formData.append("userType", payloadData?.accountType);

      const getApiPayload = payloadData?.isStrategy
        ? {
            strategyId: payloadData?.strategyId,
            userType: payloadData?.accountType,
          }
        : {
            campaignManagerId: payloadData?.id,
            userType: payloadData?.accountType,
          };

      const apiEndpoint = payloadData?.isStrategy
        ? STRATEGY_API.UPLOAD_CSV_STRATEGY
        : CAMPAIGN_NEW_API.UPLOAD_CSV_API;

      const result = yield call(
        config.POST_WITH_CANCEL_TOKEN_HEADER,
        apiEndpoint,
        formData,
        // cancelToken,
        {
          "Content-Type": "application/json",
          "x-access-token": payloadData.token,
        }
      );

      const getResult = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${CAMPAIGN_NEW_API.GET_UPLOADED_CSV_INFO}`,
        {
          params: getApiPayload,
          headers: { "x-access-token": payloadData.token },
        }
      );

      yield put({
        type: actionTypes.UPLOAD_CSV_FILE_API_SUCCESS,
        payload: result?.data.data,
      });

      yield put({
        type: actionTypes.GET_UPLOADED_INFO_API_SUCCESS,
        payload: getResult.data.data,
      });

      if (payloadData?.isStrategy) {
        const createdStrategyData =
          store.getState()?.strategyModuleReducer?.strategyReducer
            ?.createdStrategy;
        const createdStrategyDataResult = {
          ...createdStrategyData,
          personas: result?.data.data,
        };
        yield put({
          type: strategyActionTypes.STORE_CREATED_STRATEGY,
          payload: createdStrategyDataResult,
        });
      }

      toast.dismiss();
      action.payload.func(result?.data?.data);
      if (result?.data?.data?.error) {
        toast.error(result?.data?.data?.error);
      } else if (result?.data?.data?.message && result?.data?.data?.data) {
        toast.error(result?.data?.data?.message);
      }
      // else {
      //   toast.success("CSV uploaded successfully");
      // }
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.UPLOAD_CSV_FILE_API_FAILURE,
      payload: error,
    });
    toast.dismiss();

    if (error?.response?.data?.error?.message) {
      action.payload.func(error?.response?.data);
    } else if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("CSV Upload Unsuccessful");
    }
    console.error(error);
  }
}

export function* uploadPaymentCSVSagaWorker(action: any): any {
  toast.dismiss();
  toast.loading("Please wait ...");
  try {
    if (action) {
      const payloadData = action.payload;
      const formData = new FormData();
      // formData.append("uploadFile", payloadData.file);
      payloadData.file.forEach((data: any, index: number) => {
        formData.append("uploadFile", data);
      });

      if (payloadData?.isStrategy) {
        formData.append("strategyId", payloadData.strategyId);
      } else {
        formData.append("campaignManagerId", payloadData.id);
      }

      formData.append("accountId", payloadData?.accountId);

      const getApiPayload = payloadData?.isStrategy
        ? {
            strategyId: payloadData?.strategyId,
            userType: payloadData?.accountType,
          }
        : {
            campaignManagerId: payloadData?.id,
            userType: payloadData?.accountType,
          };

      const result = yield call(
        config.POST_WITH_CANCEL_TOKEN_HEADER,
        CAMPAIGN_NEW_API.UPLOAD_PAYMENT_CSV_API,
        formData,
        {
          "Content-Type": "application/json",
          "x-access-token": payloadData.token,
        }
      );

      const getResult = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${CAMPAIGN_NEW_API.GET_UPLOADED_CSV_INFO}`,
        {
          params: getApiPayload,
          headers: { "x-access-token": payloadData.token },
        }
      );

      yield put({
        type: actionTypes.UPLOAD_PAYMENT_CSV_FILE_API_SUCCESS,
        payload: result?.data.data,
      });

      yield put({
        type: actionTypes.GET_UPLOADED_INFO_API_SUCCESS,
        payload: getResult.data.data,
      });

      toast.dismiss();
      if (result?.data?.data?.message) {
        toast.success(result?.data?.data?.message);
      }
      // else {
      //   toast.success("CSV Uploaded Successfully");
      // }
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.UPLOAD_PAYMENT_CSV_FILE_API_FAILURE,
      payload: error,
    });
    toast.dismiss();
    // console.log(error?.response?.data?.error?.message, "kkkkk");

    if (Object.keys(error?.response?.data?.error)?.length > 1) {
      action?.payload?.func(error?.response?.data);
    } else if (error?.response?.data?.error?.message) {
      toast.error(error?.response?.data?.error?.message);
    } else if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("CSV Upload Unsuccessful");
    }
    console.error(error);
  }
}

export function* uploaddunningCSVSagaWorker(action: any): any {
  toast.dismiss();
  toast.loading("Please wait ...");
  try {
    if (action) {
      const payloadData = action.payload;
      const formData = new FormData();
      // formData.append("uploadFile", payloadData.file);
      payloadData.file.forEach((data: any, index: number) => {
        formData.append("uploadFile", data);
      });

      if (payloadData?.isStrategy) {
        formData.append("strategyId", payloadData.strategyId);
      } else {
        formData.append("campaignManagerId", payloadData.id);
      }
      formData.append("area", payloadData.area);
      formData.append("accountId", payloadData?.accountId);

      const getApiPayload = payloadData?.isStrategy
        ? {
            strategyId: payloadData?.strategyId,
            userType: payloadData?.accountType,
          }
        : {
            campaignManagerId: payloadData?.id,
            userType: payloadData?.accountType,
          };

      const result = yield call(
        config.POST_WITH_CANCEL_TOKEN_HEADER,
        CAMPAIGN_NEW_API.UPLOAD_DUNNING_CSV_API,
        formData,
        {
          "Content-Type": "application/json",
          "x-access-token": payloadData.token,
        }
      );

      const getResult = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${CAMPAIGN_NEW_API.GET_UPLOADED_CSV_INFO}`,
        {
          params: getApiPayload,
          headers: { "x-access-token": payloadData.token },
        }
      );

      yield put({
        type: actionTypes.UPLOAD_DUNNIG_CSV_FILE_API_SUCCESS,
        payload: result?.data.data,
      });

      yield put({
        type: actionTypes.GET_UPLOADED_INFO_API_SUCCESS,
        payload: getResult.data.data,
      });

      toast.dismiss();
      action.payload.func(result?.data?.data);
      if (result?.data?.data?.error) {
        toast.error(result?.data?.data?.error);
      } else if (result?.data?.data?.message && result?.data?.data?.data) {
        toast.error(result?.data?.data?.message);
      }
      // else {
      //   toast.success("CSV Uploaded Successfully");
      // }
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.UPLOAD_DUNNIG_CSV_FILE_API_FAILURE,
      payload: error,
    });
    toast.dismiss();
    // console.log(error?.response?.data?.error?.message, "kkkkk");

    if (Object.keys(error?.response?.data?.error)?.length > 1) {
      action?.payload?.func(error?.response?.data);
    } else if (error?.response?.data?.error?.message) {
      toast.error(error?.response?.data?.error?.message);
    } else if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("CSV Upload Unsuccessful");
    }
    console.error(error);
  }
}

export function* APICSVCampaignDataSagaWorker(action: any): any {
  toast.dismiss();
  let cancelTokenRD: any;
  try {
    if (action) {
      if (typeof cancelTokenRD !== typeof undefined) {
        cancelTokenRD.cancel("Operation canceled due to new request.");
      }
      cancelTokenRD = axios.CancelToken.source();
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${CAMPAIGN_NEW_API.GET_API_CSV_DATA}`,
        {
          params: _.omit(action.payload, "token"),
          headers: { Authorization: `JWT ${action.payload.token}` },
        }
      );

      yield put({
        type: actionTypes.GET_API_CSV_DATA_SUCCESS,
        payload: result.data.data,
      });
      toast.dismiss();
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.GET_API_CSV_DATA_FAILURE,
      payload: error,
    });
    toast.dismiss();
    toast.error("API CSV Data Unsuccessful");
    console.error(error);
  }
}

export function* salesforceCSVCampaignDataSagaWorker(action: any): any {
  toast.dismiss();
  let cancelTokenRD: any;
  try {
    if (action) {
      if (typeof cancelTokenRD !== typeof undefined) {
        cancelTokenRD.cancel("Operation canceled due to new request.");
      }
      cancelTokenRD = axios.CancelToken.source();
      const result = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${CAMPAIGN_NEW_API.GET_API_CSV_DATA}`,
        {
          params: _.omit(action.payload, "token"),
          headers: { Authorization: `JWT ${action.payload.token}` },
        }
      );

      yield put({
        type: actionTypes.GET_SALESFORCE_CSV_DATA_SUCCESS,
        payload: result.data.data,
      });
      toast.dismiss();
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.GET_SALESFORCE_CSV_DATA_FAILURE,
      payload: error,
    });
    toast.dismiss();
    toast.error("Salesforce API CSV Data Unsuccessful");
    console.error(error);
  }
}

export function* selectedCSVDataAPIWorker(action: any): any {
  try {
    console.log("action ", action.payload);
    toast.dismiss();
    const id = toast.loading("Please Wait ... ");
    const payloadData = action.payload?.isStrategy
      ? {
          ids: action.payload.ids,
          strategyId: action.payload?.strategyId,
        }
      : {
          ids: action.payload.ids,
          parentCampaignManagerId: action.payload?.parentCampaignManagerId,
        };
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${CAMPAIGN_NEW_API.SET_SELECTED_CSV_CAMPAIGN}`,
        payloadData,
        {
          "Content-Type": "application/json",
        }
      );
      yield put({
        type: actionTypes.POST_SELECTED_CSV_DATA_SUCCESS,
        payload: result.data.data?.[0],
      });
      if (!action.payload?.isStrategy) {
        const createdCampaignData =
          store.getState()?.campaignModuleReducer?.createCampaignData
            ?.savedCampaignInfo;
        const createdCampaignDataResult = {
          ...createdCampaignData,
          personas: action.payload?.personas,
          language: action.payload?.language,
          flowType: action.payload?.flowType,
          uploadedSuccessfully: true,
          isPaymentLink: action.payload?.isPaymentLink,
        };
        yield put({
          type: actionType.SET_CAMPAIGN_DATA,
          payload: {
            data: {
              ...createdCampaignDataResult,
            },
          },
        });
      } else {
        const createdStrategyData =
          store.getState()?.strategyModuleReducer?.strategyReducer
            ?.createdStrategy;
        const createdStrategyDataResult = {
          ...createdStrategyData,
          personas: action.payload?.personas,
          isPaymentLink: action.payload?.isPaymentLink,
        };
        yield put({
          type: strategyActionTypes.STORE_CREATED_STRATEGY,
          payload: createdStrategyDataResult,
        });
      }
      toast.dismiss(id);
      toast.success(
        result.data?.data?.message
          ? result.data?.data?.message
          : "CSV(s) Selected Successfully"
      );
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.POST_SELECTED_CSV_DATA_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    if (error?.response?.data?.error?.data) {
      action.payload.func(error?.response?.data?.error?.data);
    } else {
      toast.error(
        error.response?.data?.error?.message
          ? error.response?.data?.error?.message
          : "CSV(s) Selection Unsuccessful"
      );
    }
    console.error(error);
  }
}

export function* selectedAPIDataAPIWorker(action: any): any {
  try {
    console.log("action ", action.payload);
    toast.dismiss();
    const id = toast.loading("Please Wait ... ");
    const payloadData = action.payload?.isStrategy
      ? {
          strategyId: action.payload?.strategyId,
          accountId: action.payload?.accountId,
          requestIds: action.payload?.requestIds
        }
      : {
          parentCampaignManagerId: action.payload?.parentCampaignManagerId,
          accountId: action.payload?.accountId,
          requestIds: action.payload?.requestIds
        }
        
        
    if (action) {
      const result = yield call(
        config.POST_WITH_HEADERS_BODY,
        `${CAMPAIGN_NEW_API.SET_SELECTED_API_CAMPAIGN}`,
        payloadData,
        {
          "Content-Type": "application/json",
        }
      );
      yield put({
        type: actionTypes.POST_SELECTED_API_DATA_SUCCESS,
        payload: result.data.data?.[0],
      });
      if (!action.payload?.isStrategy) {
        const createdCampaignData =
          store.getState()?.campaignModuleReducer?.createCampaignData
            ?.savedCampaignInfo;
        const createdCampaignDataResult = {
          ...createdCampaignData,
          personas: action.payload?.personas,
          language: action.payload?.language,
          flowType: action.payload?.flowType,
          isPaymentLink: action.payload?.isPaymentLink,
        };
        yield put({
          type: actionType.SET_CAMPAIGN_DATA,
          payload: {
            data: {
              ...createdCampaignDataResult,
            },
          },
        });
      } else {
        const createdStrategyData =
          store.getState()?.strategyModuleReducer?.strategyReducer
            ?.createdStrategy;
        const createdStrategyDataResult = {
          ...createdStrategyData,
          personas: action.payload?.personas,
          isPaymentLink: action.payload?.isPaymentLink,
        };
        yield put({
          type: strategyActionTypes.STORE_CREATED_STRATEGY,
          payload: createdStrategyDataResult,
        });
      }
      toast.dismiss(id);
      toast.success(
        result.data?.data?.message
          ? result.data?.data?.message
          : "API Selected Successfully"
      );
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.POST_SELECTED_API_DATA_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    if (error?.response?.data?.error?.data) {
      action.payload.func(error?.response?.data?.error?.data);
    } else {
      toast.error(
        error.response?.data?.error?.message
          ? error.response?.data?.error?.message
          : "API Selection Unsuccessful"
      );
    }
    console.error(error);
  }
}

export function* deleteCSVDataAPIWorker(action: any): any {
  try {
    if (action) {
      const result = yield call(
        config.DELETE_WITH_HEADER,
        `${CAMPAIGN_NEW_API.DELETE_CSV_API}`,
        { customerUploadInfoId: action.payload.csvId },
        {
          "Content-Type": "application/json",
        }
      );

      const getPayload = action.payload?.isStrategy
        ? {
            strategyId: action.payload?.strategyId,
            userType: action.payload?.accountType,
          }
        : {
            campaignManagerId: action.payload?.campaignId,
            userType: action.payload?.accountType,
          };

      const res = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${CAMPAIGN_NEW_API.GET_UPLOADED_CSV_INFO}`,
        {
          params: getPayload,
          headers: { "x-access-token": action.payload.token },
        }
      );

      yield put({
        type: actionTypes.DELETE_CSV_API_SUCCESS,
        payload: result?.data?.data,
      });
      yield put({
        type: actionTypes.GET_UPLOADED_INFO_API_SUCCESS,
        payload: res?.data?.data,
      });
      toast.dismiss();
      toast.success(
        result.data?.data?.message
          ? result.data?.data?.message
          : "CSV Deleted Successfully"
      );
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.DELETE_CSV_API_FAILURE,
      payload: error,
    });
    console.error(error.response?.data?.error?.message);
    toast.error(
      error.response?.data?.error?.message
        ? error.response?.data?.error?.message
        : "CSV Deletion Unsuccessful"
    );
  }
}

export function* getCSVTobeEditedDataAPIWorker(action: any): any {
  toast.dismiss();
  try {
    if (action) {
      const { name, selectedTab, ...updatedPayload } = action.payload;
      console.log(selectedTab === "allocation", selectedTab, "jjjj");
      const result = yield call(
        config.GET_WITH_PARAMS,
        selectedTab === "allocation"
          ? `${CAMPAIGN_NEW_API.GET_SELECTED_CSV_DATA_API}`
          : `${CAMPAIGN_NEW_API.GET_SELECTED_PAYMENT_CSV_DATA_API}`,
        updatedPayload
      );

      const mergedData = yield call(
        mergeWithDefaultData,
        result.data.data,
        15,
        8
      );

      yield put({
        type: actionTypes.GET_EDIT_SCREEN_CSV_SUCCESS,
        //payload: result.data.data,
        payload: {
          name: action.payload.name,
          id: action.payload.customerUploadInfoId,
          csvData: mergedData,
        },
      });

      // toast.dismiss();
      // toast.success(result.data.data.message);
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.GET_EDIT_SCREEN_CSV_FAILURE,
      payload: error?.response?.data?.error,
    });
    toast.dismiss();
    toast.error(error.response?.data?.error);
    console.error(error);
  }
}

export function* updateCSVDataAPIWorker(action: any): any {
  try {
    toast.dismiss();
    if (action) {
      const formattedData = convertToCSV(action.payload.csvInfo);

      const result = yield call(
        config.PUT_WITH_HEADER_BODY,
        action.payload.selectedTab === 0
          ? `${CAMPAIGN_NEW_API.POST_EDITED_CSV_API}/${action.payload.csvId}`
          : `${CAMPAIGN_NEW_API.POST_EDITED_PAYMENT_CSV_API}/${action.payload.csvId}`, // change URL Here
        { data: formattedData },
        {
          "Content-Type": "application/json",
        }
      );

      const getResult = yield call(
        config.GET_WITH_PARAMS_HEADER,
        `${CAMPAIGN_NEW_API.GET_UPLOADED_CSV_INFO}`,
        {
          params: {
            ...(action.payload.isStrategy
              ? { strategyId: action.payload.strategyId }
              : { campaignManagerId: action.payload.campaignManagerId }),
            userType: action.payload.userType,
          },
          headers: { "x-access-token": action.payload.token },
        }
      );

      yield put({
        type: actionTypes.POST_UPDATED_CSV_API_SUCCESS,
        payload: result?.data?.data,
      });

      yield put({
        type: actionTypes.GET_UPLOADED_INFO_API_SUCCESS,
        payload: getResult.data.data,
      });

      toast.dismiss();
      toast.success(
        result.data.data.message
          ? result.data.data.message
          : "CSV Updated Successfully"
      );
    }
  } catch (error: any) {
    yield put({
      type: actionTypes.POST_UPDATED_CSV_API_FAILURE,
      payload: error,
    });
    toast.dismiss();
    toast.error(error.response?.data?.error?.message);
    console.error(error);
  }
}
