import React from "react";

import "./FieldsHeader.scss";

interface props {
  headerName: string;
  extraClass?: string;
  icon?: any;
  handleEditClick?: () => void;
}

export default function FieldsHeader(props: props) {
  return (
    <div
      className={`fields-header ${props.extraClass ? props.extraClass : ""}`}
    >
      <span className="header-name">{props.headerName}</span>
      <img
        src={props.icon}
        alt=""
        className={`edit-btn ${props.icon ? "" : "visibilityHiddenGlobal"}`}
        onClick={props.handleEditClick}
      />
    </div>
  );
}
