import { Reducer } from "react";
import actionTypes from "../actionTypes";
import { stateInterface, actionInterface } from "../interface";

const initialState: stateInterface = {
  selectedTab: "",
  backendRefenceKey: "",
};

const tabReducer: Reducer<stateInterface, actionInterface> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case actionTypes.SET_BACKEND_REFERNCE_KEY:
        return {
          ...state,
          backendRefenceKey: action.payload,
        };
    default: {
      return state;
    }
  }
};

export default tabReducer;
