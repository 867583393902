//Function to find the route based on the index
export const getRouteByIndex = (
  index: number,
  stepsTotal: string[]
): { name: string; route: string; nextStepName: string } | undefined => {
  const campaignProgressBarData = [
    {
      name: "Campaign Details",
      route: "/campaignmanager/campaigncreation",
    },
    {
      name: "Data Upload",
      route: "/campaignmanager/dataupload",
    },
    {
      name: "Strategy Selection",
      route: "/campaignmanager/strategyselection",
    },
    {
      name: "Agent Configuration",
      route: "/campaignmanager/allagents",
    },
  ];

  if (index >= 0 && index < stepsTotal.length) {
    const stepName = stepsTotal[index];
    const matchingStep = campaignProgressBarData.find(
      (step: { name: string; route: string }) => step.name === stepName
    );

    if (matchingStep) {
      return { ...matchingStep, nextStepName: stepsTotal[index] };
    }
  }

  return undefined;
};
