import { takeEvery } from "redux-saga/effects";
import agentActions from "../actionTypes";
import {
  getAllAgentsWorker,
  createAgentsWorker,
  deleteAgentWorker,
  editAgentsWorker,
  addAgentToCampaignAPIWorker,
  getCampaignSummaryDataWorker,
  getDownloadCSVZipApiDataWorker,
} from "./agentConfig.saga";

export default function* agentWatcher() {
  yield takeEvery(agentActions.GET_ALL_AGENTS_API_REQUEST, getAllAgentsWorker);
  yield takeEvery(agentActions.CREATE_AGENT_API_REQUEST, createAgentsWorker);
  yield takeEvery(agentActions.DELETE_AGENT_API_REQUEST, deleteAgentWorker);
  yield takeEvery(agentActions.EDIT_AGENT_API_REQUEST, editAgentsWorker);
  yield takeEvery(
    agentActions.SET_SELECTED_AGENT_API_REQUEST,
    addAgentToCampaignAPIWorker
  );
  yield takeEvery(
    agentActions.GET_ALL_CAMPAIGN_SUMMARY_DATA_REQUEST,
    getCampaignSummaryDataWorker
  );
  yield takeEvery(
    agentActions.GET_DOWNLOAD_CSV_ZIP_API_REQUEST,
    getDownloadCSVZipApiDataWorker
  );
}
