export default {
  UPDATE_WHATSAPP_PROFILE_PIC_REQUEST: "UPDATE_WHATSAPP_PROFILE_PIC_REQUEST",
  UPDATE_WHATSAPP_PROFILE_PIC_SUCCESS: "UPDATE_WHATSAPP_PROFILE_PIC_SUCCESS",
  UPDATE_WHATSAPP_PROFILE_PIC_FAILURE: "UPDATE_WHATSAPP_PROFILE_PIC_FAILURE",
  DELETE_WHATSAPP_PROFILE_PIC_REQUEST: "DELETE_WHATSAPP_PROFILE_PIC_REQUEST",
  DELETE_WHATSAPP_PROFILE_PIC_SUCCESS: "DELETE_WHATSAPP_PROFILE_PIC_SUCCESS",
  DELETE_WHATSAPP_PROFILE_PIC_FAILURE: "DELETE_WHATSAPP_PROFILE_PIC_FAILURE",
  SET_CURRENT_SCREEN: "SET_CURRENT_SCREEN",
  SET_WHATSAPP_DATA: "SET_WHATSAPP_DATA",
  CLEAR_WHATSAPP_DATA: "CLEAR_WHATSAPP_DATA",
  GET_ALL_INDUSTRY_DATA_REQUEST: "GET_ALL_INDUSTRY_DATA_REQUEST",
  GET_ALL_INDUSTRY_DATA_SUCCESS: "GET_ALL_INDUSTRY_DATA_SUCCESS",
  GET_ALL_INDUSTRY_DATA_FAILURE: "GET_ALL_INDUSTRY_DATA_FAILURE",
  SET_IS_DATA_CHANGED: "SET_IS_DATA_CHANGED",
  // SET_BACK_ICON_CLICKED: "SET_BACK_ICON_CLICKED",
};
