import { takeLatest } from "redux-saga/effects";
import actionTypes from "../actionTypes/campaignTestingInfo.actionTypes";
import { getCampaignTestingSchema,updateSchemaByAgent, modifyCustomerDataDemo } from "./campaignTestingInfo.saga";

export default function* campaignTestingInfoWatcher(): any {
  yield takeLatest(
    actionTypes.CAMPAIGN_TESTING_GET_SCHEMA_API_REQUEST,
    getCampaignTestingSchema
  );
  yield takeLatest(actionTypes.MAKE_SCHEMA_BY_AGENT_CAMPAIGN_TESTING,updateSchemaByAgent);
  yield takeLatest(actionTypes.MODIFY_CUSTOMER_DATA_DEMO_CAMPAIGN_TESTING, modifyCustomerDataDemo);
}
